import * as types from '../types'
import * as timecards from '../../../api/TimeCards'
import moment from 'moment-timezone'
import { dataURLtoFile } from 'containers/MainContent/Orcatec/Settings/components/CompanyInfo/ImgCropper/ImgCropper'

export const setTimeCards = (response, handleAction) => {
  handleAction()
  return {
    type: types.timecards.TIMECARDS_SET,
    response,
  }
}

export const changeLimit = response => {
  return {
    type: types.timecards.CHANGE_LIMIT,
    response,
  }
}

export const changePage = response => {
  return {
    type: types.timecards.CHANGE_PAGE,
    response,
  }
}

export const seTimeRangeAction = response => {
  return {
    type: types.timecards.TIMECARDS_RANGE_SET,
    response,
  }
}

export const setTimeType = response => {
  return {
    type: types.timecards.TIMETYPE_SET,
    response,
  }
}
export const setCurrentUserTimeCards = response => {
  return {
    type: types.timecards.CURRENT_USER_TIMECARD_SET,
    response,
  }
}
export const fetchTimeCardsList = (data, handleAction) => dispatch => {
  return timecards.fetchTimeCardsList(data).then(response => dispatch(setTimeCards(response, handleAction)))
}

export const fetchUserCurrentTimeStatus = () => dispatch => {
  return timecards.fetchUserCurrentTimeStatus().then(response => dispatch(setTimeType(response)))
}

export const fetchUserCurrentUserTimeCard = () => dispatch => {
  return timecards.fetchTimeCardByUserId().then(response => dispatch(setCurrentUserTimeCards(response)))
}

export const setUserClockInOrOut = () => (dispatch, getState) => {
  const timezone = moment.tz.guess()
  let { timeType } = getState().orcatec.timecards.currentTimeType
  timeType = timeType === 1 ? 2 : 1
  return timecards.setUserClockInOrOut({ timeType, timezone }).then(response => {
    if (response?.entity?.code !== 500) {
      return dispatch(setTimeType({ ...response.entity, timeType: response.timeType }))
    } else return
  })
}

export const setUserClockOut = () => (dispatch, getState) => {
  const timezone = moment.tz.guess()
  const timeType = 2
  return timecards.setUserClockInOrOut({ timeType, timezone })
}

//
const getTimeCard = () => ({
  type: types.timecards.GET_TIMECARD,
})

const setTimeCard = timecard => ({
  type: types.timecards.SET_TIMECARD,
  payload: timecard,
})

const timeCardError = message => ({
  type: types.timecards.TIMECARD_FAILURE,
  payload: message,
})

const getTimeCardList = () => ({
  type: types.timecards.GET_TIMECARD_LIST,
})

const setTimeCardList = (timecardList, meta) => ({
  type: types.timecards.SET_TIMECARD_LIST,
  payload: { list: timecardList, meta },
})

const getTimeCardListError = message => ({
  type: types.timecards.GET_TIMECARD_LIST_FAILURE,
  payload: message,
})

const addTimeTrack = () => ({
  type: types.timecards.ADD_TIMECARD_TRACK,
})

const updateTimeTrack = () => ({
  type: types.timecards.EDIT_TIMECARD_TRACK,
})

const deleteTimeTrack = () => ({
  type: types.timecards.DELETE_TIMECARD_TRACK,
})

export const addNewDay = date => ({
  type: types.timecards.ADD_TIMECARD_DAY,
  payload: date,
})

export const removeDay = date => ({
  type: types.timecards.DELETE_TIMECARD_DAY,
  payload: date,
})

export const resetErrors = () => ({
  type: types.timecards.RESET_TIMECARDS_ERRORS,
})

export const getTimeCards = props => async dispatch => {
  dispatch(getTimeCardList())

  try {
    const { data, meta } = await timecards.fetchTimeCardsList(props)
    dispatch(setTimeCardList(data, meta))
  } catch (error) {
    dispatch(getTimeCardListError(error.message))
  }
}

export const getTimeCardByUserId = (userId, startDate, endDate, timezone) => async dispatch => {
  dispatch(getTimeCard())

  try {
    const response = await timecards.fetchTimeCardByUserId(userId, startDate, endDate, timezone)
    dispatch(setTimeCard(response))
  } catch (error) {
    dispatch(timeCardError(error?.response?.data?.message || error?.message))
  }
}

export const addTimeCardTrack = (
  { userId, timezone, clock_in, clock_out, startDate, endDate },
  cb,
) => async dispatch => {
  dispatch(addTimeTrack())

  try {
    await timecards.createTimeCardTrack({
      userId,
      timezone,
      clock_in,
      clock_out,
    })
    dispatch(getTimeCardByUserId(userId, startDate, endDate, timezone))
    if (cb) cb()
  } catch (error) {
    // dispatch(addTimeTrackFailure(error?.response?.data?.message || error?.message))
    dispatch(timeCardError(error?.response?.data?.message || error?.message))
  }
}

export const updateTimeCardTrack = (
  { userId, timeTrackId, timezone, clock_in, clock_out, startDate, endDate },
  cb,
) => async dispatch => {
  dispatch(updateTimeTrack())

  try {
    await timecards.updateTimeCardTrack(userId, timeTrackId, {
      timezone,
      clock_in,
      clock_out,
    })
    dispatch(getTimeCardByUserId(userId, startDate, endDate, timezone))
    if (cb) cb()
  } catch (error) {
    // dispatch(updateTimeTrackFailure(error?.response?.data?.message || error?.message))
    dispatch(timeCardError(error?.response?.data?.message || error?.message))
  }
}

export const deleteTimeCardTrack = ({ userId, timeTrackId, timezone, startDate, endDate }) => async dispatch => {
  dispatch(deleteTimeTrack())

  try {
    await timecards.deleteTimeCardTrack({ userId, timeTrackId })
    dispatch(getTimeCardByUserId(userId, startDate, endDate, timezone))
  } catch (error) {
    // dispatch(deleteTimeTrackFailure(error?.response?.data?.message || error?.message))
    dispatch(timeCardError(error?.response?.data?.message || error?.message))
  }
}
