import axiosOrcatec from './axiosInstance'

export const getProposalInsightsData = id =>
  axiosOrcatec.get(`/proposals/${id}/accounting`).then(res => res && res.data)

export const putProposalInsightsData = (id, data) =>
  axiosOrcatec.put(`/proposals/${id}/accounting`, data).then(res => res && res.data)

export const deleteProposalInsightsItem = (id, data) =>
  axiosOrcatec.post(`/proposals/${id}/accounting`, data).then(res => res && res.data)

export const getProposalInsightsPayouts = id =>
  axiosOrcatec.get(`/proposals/${id}/proposal-payouts`).then(res => res && res?.data)

export const putProposalInsightsPayouts = (id, payoutId, data) =>
  axiosOrcatec.put(`/proposals/${id}/proposal-payouts/${payoutId}`, data).then(res => res && res?.data)

export const postProposalInsightsPayouts = (id, data) =>
  axiosOrcatec.post(`/proposals/${id}/proposal-payouts`, data).then(res => res && res?.data)
export const deleteProposalInsightsPayouts = (id, payoutId) =>
  axiosOrcatec.delete(`/proposals/${id}/proposal-payouts/${payoutId}`).then(res => res && res?.data)

export const getSuggestionsItems = (params: { type: number, search: string, page: number }) =>
  axiosOrcatec
    .get(
      `/accounting/insight/items?type=${params.type}&search=${window.encodeURIComponent(params.search)}&page=${
        params.page
      }`,
    )
    .then(res => res && res?.data)

export const postSuggestionsItems = (data: { type: 1 | 2, name: string }) =>
  axiosOrcatec.post(`/accounting/insight/items`, data).then(res => res && res?.data?.data)

export const deleteSuggestionsItems = (id: number) =>
  axiosOrcatec.delete(`/accounting/insight/items/${id}`).then(res => res && res?.data?.data)
