import { useState, FC } from 'react'
import { Tag } from 'antd'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { TextField, TextFieldProps } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 10px;
  max-width: 316px;
  min-width: 200px;
  .table-date-picker {
    width: 100%;
  }
  .filter-buttons {
    margin: 10px 0 0;
    display: flex;
    justify-content: space-between;
  }
  .tag-wrapper {
    display: flex;
    gap: 3px;
    flex-wrap: wrap;
    padding: 7px 3px;
  }
  .ant-tag-close-icon {
    vertical-align: middle;
    padding-bottom: 2px;
  }
`
interface IOption {
  id: number
  name: string
}
interface IFilterProps extends FilterDropdownProps {
  title: string
  values: IOption[]
  objKey: string
}

const AutocompliteFilter: FC<IFilterProps> = ({
  title,
  values,
  objKey = 'name',
  clearFilters,
  confirm,
  selectedKeys,
  setSelectedKeys,
}) => {
  const [value, setValue] = useState(
    values?.filter(v => selectedKeys?.includes(v?.[objKey])),
  )
  const handleSearch = () => {
    confirm?.()
  }
  const handleReset = () => {
    clearFilters?.()
    setValue([])
    confirm?.()
  }

  const onChangeInputHeandler = (e: unknown, value: IOption[]) => {
    setValue(value)

    setSelectedKeys(value?.map(o => o?.[objKey]))
  }
  const heandleDeleteItem = (option: IOption[]) => {
    const filteredItems = value?.filter(p => p?.id !== option?.id)
    setValue(filteredItems)
    setSelectedKeys(filteredItems?.map(e => e?.[objKey]))
    if (!filteredItems?.length) {
      confirm?.()
    }
  }

  return (
    <Wrapper>
      <Autocomplete
        multiple
        id='tags-standard'
        size='small'
        options={values}
        getOptionLabel={(option: IOption) => option?.name}
        value={value}
        disableClearable
        onChange={onChangeInputHeandler}
        renderTags={(value: IOption[]) => {
          return (
            <div className='tag-wrapper'>
              {value?.map((option: IOption) => (
                <Tag
                  color='#108ee9'
                  closable
                  className='atn-tag'
                  onClose={e => {
                    e.preventDefault()
                    heandleDeleteItem(option)
                  }}
                  key={option?.id}
                >
                  {option?.name}
                </Tag>
              ))}
            </div>
          )
        }}
        renderInput={(option: TextFieldProps) => (
          <TextField {...option} variant='outlined' label={title} />
        )}
      />

      <div className='filter-buttons'>
        <MainButton title='Search' onClick={handleSearch} />
        <MainButton title='Reset' type='cancel' onClick={handleReset} />
      </div>
    </Wrapper>
  )
}

export default AutocompliteFilter
