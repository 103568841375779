import { Progress as AtndProgress, ProgressProps } from 'antd'
import { Text, Wrapper } from './Progress.styles'

type Props = ProgressProps & {
  percent: number
  isCompleted?: boolean
}

export const Progress = ({
  type = 'circle',
  percent,
  isCompleted,
  ...rest
}: Props) => {
  const progressColor = getProgressColor(percent, !!isCompleted)

  return (
    <Wrapper color={progressColor}>
      {!isCompleted ? (
        <AtndProgress
          type={type}
          showInfo={!!isCompleted}
          percent={isCompleted ? 100 : percent}
          strokeWidth={15}
          strokeColor={progressColor}
          {...rest}
        />
      ) : (
        <i
          className='mdi mdi-check-circle'
          style={{
            color: '#30be89',
            fontSize: 23,
            lineHeight: 1,
            position: 'relative',
            top: 1,
          }}
        />
      )}
      <Text color={progressColor}>
        {isCompleted ? 'Completed' : percent + '%'}
      </Text>
    </Wrapper>
  )
}

const getProgressColor = (value: number, isCompleted: boolean) => {
  if (isCompleted) return '#39BD8A'

  return value < 50 ? '#F22735' : '#FF9B10'

  /*  switch (true) {
    case value < 50:
      return '#F22735'
    case value < 100:
      return '#FF9B10'

    case value === 100:
      return '#97C70D'

    default:
      return '#F22735'
  } */
}
