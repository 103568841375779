import React, { useState } from 'react'
import Tags from 'containers/MainContent/Orcatec/components/Tag/Tag'
import ToggleButton from 'containers/MainContent/Orcatec/components/buttons/ToggleButton/ToggleButton'

const TagsBlock = ({ id }) => {
  const [showContent, setShowContent] = useState(false)

  const toggleShowContent = () => {
    setShowContent(prev => !prev)
  }

  return (
    <div className='information_for_workers'>
      <p className='label'>Project tags</p>
      <div style={{ display: 'flex' }}>
        <div style={{ padding: '8px 8px 8px 0' }}>
          <ToggleButton show={showContent} toggle={toggleShowContent} />
        </div>
        <Tags route={`proposals/${id}`} showContent={showContent} />
      </div>
    </div>
  )
}

export default TagsBlock
