import * as types from '../types'
import * as appointment from '../../../api/Appointment'

export const setAppointments = data => {
  return {
    type: types.appointment.SET_APPOINTMENTS,
    data,
  }
}

export const setCurrentAppointmentId = id => {
  return {
    type: types.appointment.SET_CURRENT_APPOINTMENT_ID,
    payload: id,
  }
}

export const changeType = bool => {
  return {
    type: types.appointment.CHANGE_TYPE,
    bool,
  }
}

export const pasteData = data => {
  return {
    type: types.appointment.PASTE_DATA,
    data,
  }
}

export const setTypes = data => {
  return {
    type: types.appointment.SET_TYPES,
    data,
  }
}
export const setSources = data => {
  return {
    type: types.appointment.SET_SOURCES,
    data,
  }
}

const typeAdded = type => {
  return {
    type: types.appointment.TYPE_ADDED,
    payload: type,
  }
}

const typeDeleted = id => {
  return {
    type: types.appointment.TYPE_DELETED,
    payload: id,
  }
}

const sourceDeleted = id => {
  return {
    type: types.appointment.SOURCE_DELETED,
    payload: id,
  }
}

const sourceAdded = source => {
  return {
    type: types.appointment.SOURCE_ADDED,
    payload: source,
  }
}
export const sidebarIsOpen = isOpen => {
  return {
    type: types.appointment.SET_OPEN_SIDEBAR,
    payload: isOpen,
  }
}
export const setFreePlaceInView = width => {
  return {
    type: types.appointment.SET_FREE_PLACE,
    payload: width,
  }
}

export const createAppointment = data => appointment.createAppointment(data).then(r => r)

export const fetchAppointmentsByPropertyId = id => dispatch => {
  return appointment.fetchAppointmentById(id)
}

export const createAppointmentType = data => dispatch => {
  return appointment.postAppoinmentTypes(data).then(res => {
    dispatch(setTypes(res))
  })
}

export const updateAppoinments = (id, data) => dispatch => {
  return appointment.updateAppoinment(id, data)
}

export const postAppoinmentTypes = data => dispatch => {
  return appointment.postAppoinmentTypes(data)
}

export const deleteAppointment = id => dispatch => {
  return appointment.deleteAppointment(id)
}
export const postAppoinmentSources = data => dispatch => {
  return appointment.postAppoinmentSources(data)
}

///Thunk

///////Sources///////
export const addSource = name => dispatch => {
  return appointment.postAppoinmentSources({ name }).then(source => dispatch(sourceAdded(source)))
}

export const deleteSource = id => dispatch => {
  return appointment.deleteSource(id).then(() => dispatch(sourceDeleted(id)))
}

export const getAppoinmentSources = () => dispatch => {
  return appointment.getAppoinmentSources().then(res => dispatch(setSources(res)))
}

///////Types///////
export const addType = name => dispatch => {
  return appointment.postAppoinmentTypes({ name }).then(type => dispatch(typeAdded(type)))
}

export const deleteType = id => dispatch => {
  return appointment.deleteType(id).then(() => dispatch(typeDeleted(id)))
}

export const getAppoinmentTypes = () => dispatch => {
  return appointment.getAppoinmentTypes().then(res => dispatch(setTypes(res)))
}
