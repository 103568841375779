import * as types from '../../types'

const initialState = {
  attempts: Number,
  days_until_change_status: Number,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.subscription.SUBSCRIPTION_GET_ITEM:
      return action.item //{...action.item, trial_period: Number.isInteger(action.item.trial_period) ? action.item.trial_period : 90}
    case types.subscription.SUBSCRIPTION_CHANGE_FIELD:
      return { ...state, [action.field]: action.value }
    default:
      return state
  }
}

//Selectors
export const getModuleStatus = (state, moduleName) =>
  state.orcatec.company?.subscription?.modules?.[moduleName]?.status
export const selectSubscription = state => state.orcatec.subscription
// export const getModuleStatus = (state, moduleName) => state.orcatec.subscription.modules?.[moduleName]?.status
