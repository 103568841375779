import React from 'react'
import { TextField } from '@material-ui/core'
import './Tags.scss'

const tagsInputProps = { maxLength: 30, autoComplete: 'off' }

const defaultAction = () => {}

const Tags = ({
  id = 'tags',
  label = 'Property Tags',
  name = 'tempTagValue',
  tempTagValue = '',
  tempTagValueError = '',
  values = [],
  onAddTag = defaultAction,
  onDeleteTag = defaultAction,
  handleFieldChange = defaultAction,
  disabled = false,
  placeholder = 'Type name of the tag',
  history,
}) => {
  const onKeyUp = e => {
    if (e.keyCode === 13) onAddTag()
  }
  const onDelete = (i, item) => () => {
    if (!disabled) onDeleteTag(i, item)
  }

  return (
    <div className='tags-wrapper' disabled={disabled}>
      <TextField
        // id={id}
        name={name}
        label={label}
        inputProps={tagsInputProps}
        variant='outlined'
        size='small'
        fullWidth
        onKeyUp={onKeyUp}
        onBlur={onAddTag}
        value={tempTagValue}
        disabled={disabled}
        onChange={handleFieldChange}
        error={!!tempTagValueError}
        helperText={tempTagValueError}
        placeholder={placeholder}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {!!values && !!values.length && (
        <div className='tags-wrapper-list' disabled={disabled}>
          {values.map((item, i) => (
            <div className='tags-wrapper-list-item' key={`${item}-${i}`}>
              <span>{item}</span>
              <b>
                <i className='mdi mdi-close' onClick={onDelete(i, item)} />
              </b>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Tags
