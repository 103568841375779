import React, { Component } from 'react'
import './style.scss'
//import { signUp } from '../../../../../api/SignUp'
import validator from 'validator'
import { Link, withRouter } from 'react-router-dom'
import PagesLegal from '../Legal/Legal'
import RegisterFinishPage from '../Finish'
import { connect } from 'react-redux'
import * as actions from '../../../../../store/Orcatec/actions'
import { confirmEmail } from '../../../../../api/User'
//import { refreshPaymentToken } from '../../../../../api/Payment'
import { compose } from 'redux'
import TermOfUseAndPrivacyLink from '../TermOfUseLink'
import SureModal from '../../components/SureModal'
import MainButton from '../../components/buttons/MainButton'
import AuthLayout from '../../Layouts/AuthLayout/AuthLayout'
import { Error } from '../../components/ErrorMessage/ErrorMessage'
import { BackToLoginBtn } from '../../Layouts/AuthLayout/BackToLoginBtn'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import CloseIcon from '@material-ui/icons/Close'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import MenuItem from '@material-ui/core/MenuItem'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import CancelRegistrationModal from '../CancelRegistrationModal/CancelRegistrationModal'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import ReactInputMask from 'react-input-mask'
import { beautifyEmailInput } from 'helpers/beautifyEmailInput'

import PagesRegisterCard from '../Card'
import * as subscription from 'api/settings/Subscription'
const filter = createFilterOptions()
/* import { Select } from 'antd'

const OPTIONS = [
  'Plumbing',
  'HVAC',
  'Pest Control',
  'Electrician',
  'General Constractor',
  'Other',
] */

//export const Error = ({ children }) => <span className='error-message'>{children}</span>
const inputProps100 = {
  maxLength: 100,
}
const inputProps300 = {
  maxLength: 300,
}
const inputProps32 = {
  maxLength: 32,
}
const initialState = {
  company_name: {
    value: '',
    error_message: 'Field is required',
    async_error_message: '',
    show: false,
  },
  owner_name: {
    value: '',
    error_message: 'Field is required',
    show: false,
  },
  industry: {
    value: [],
    error_message: 'Field is required',
    show: false,
  },
  initIndustry: ['Plumbing', 'HVAC', 'Pest Control', 'Electrician', 'General Contractor'],
  email: {
    value: '',
    error_message: 'Invalid email address',
    async_error_message: '',
    show: false,
  },
  phone: {
    value: '',
    error_message: 'Please input full phone number',
    show: false,
  },
  password: {
    value: '',
    error_message:
      'Password must contain at least one capital letter, one regular letter, one digit and be minimum 6 symbols length',
    show: false,
  },
  password_show: false,
  confirm_password: {
    value: '',
    error_message: 'Passwords do not match',
    show: false,
  },
  confirm_password_show: false,
  isCreatingCompany: false,
  isCreatingCardProfile: false,

  registerComplete: false, //false
  emailConfirmed: false, //false
  showCreditCardForm: true, //true - if card needed //false - while card no needed
  showTermsAndCondForm: false, //false - if card needed //true - while card no needed
  showFinishPage: false, //false
  paymentId: '',
  token: '',
  sureModalForPaymentPage: false, //false
  //selectedItems: [],
}

class PagesRegisterInfo extends Component {
  constructor(props) {
    super(props)
    this.state = JSON.parse(JSON.stringify(initialState))
  }

  componentDidMount() {
    window.location.href = 'https://orcatec.com/get-started'

    if (
      !!this.props.history &&
      !!this.props.history.location &&
      !!this.props.history.location.state &&
      !!this.props.history.location.state.reconfirmEmail
    ) {
      this.setState({
        registerComplete: true,
        email: {
          ...this.state.email,
          value: this.props.history.location.state.reconfirmEmail,
        },
      })
    }
    const confirmEmailToken = localStorage.getItem('confirmEmailToken')
    if (this.props.location.pathname === '/register/info/email-confirm' && this.props.location.search) {
      const token = this.props.location.search.replace(/\?token=/, '')
      const isTermsConfirmedToken = localStorage.getItem(token)
      if (isTermsConfirmedToken && isTermsConfirmedToken === 'confirmed') {
        this.props.history.push('/login')
      } else {
        localStorage.setItem('confirmEmailToken', token)
        this.props.location.search = ''
        this.setState(
          {
            registerComplete: true,
            emailConfirmed: true,
            showCreditCardForm: true, //true //false - while card no needed
            showTermsAndCondForm: false, //false //true - while card no needed
            showFinishPage: false,
            token,
          },
          () => {
            this.props.history.push('/register/info')
          },
        )
      }
    }
    if (this.props.location.pathname === '/register/info' && confirmEmailToken) {
      this.setState({
        registerComplete: true,
        emailConfirmed: true,
        showCreditCardForm: true, //true //false - while card no needed
        showTermsAndCondForm: false, //false //true - while card no needed
        showFinishPage: false,
        token: confirmEmailToken,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.registerComplete !== prevState.registerComplete && this.state.registerComplete) ||
      (this.state.emailConfirmed !== prevState.emailConfirmed && this.state.emailConfirmed) ||
      (this.state.showCreditCardForm !== prevState.showCreditCardForm && this.state.showCreditCardForm) ||
      (this.state.showTermsAndCondForm !== prevState.showTermsAndCondForm && this.state.showTermsAndCondForm) ||
      (this.state.showFinishPage !== prevState.showFinishPage && this.state.showFinishPage)
    ) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  resetState = () => {
    this.setState(initialState)
  }

  toggleSureModalForPaymantPage = () => {
    this.setState(prev => ({
      sureModalForPaymentPage: !prev.sureModalForPaymentPage,
    }))
  }

  confirmEmailOnFinish = () => {
    this.props.history.push('/login')
    this.resetState()
  }

  onChangeField = e => {
    let { name, value } = e.target
    let isValid = true
    const newState = { ...this.state }

    switch (name) {
      case 'email':
        isValid = validator.isEmail(value)
        newState[name].async_error_message = ''
        break
      case 'phone': {
        isValid = value.length !== 0 && value.replace(/\D/g, '').length === 10
        break
      }
      case 'company_name':
        isValid = value.length !== 0
        newState[name].async_error_message = ''
        break
      case 'owner_name':
        const isFullName = /^[a-zA-Z0-9- ']+$/.test(value)
        isValid = !!value.length && !!isFullName
        if (!isFullName && !!value.length) newState[name].error_message = 'Please fill the full name'
        if (!value.length) newState[name].error_message = 'Please fill the full name'
        break
      case 'other':
        isValid = value.length !== 0
        break

      case 'password':
        if (value.length > 32) value = value.substring(0, 32)
        let newReg = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,32}$/g)
        isValid = value.length !== 0 && newReg.test(value)
        newState.confirm_password.show =
          !!newState.confirm_password.value && value !== newState.confirm_password.value && isValid
        break
      case 'confirm_password':
        if (value.length > 32) value = value.substring(0, 32)
        isValid = this.state.password.value === value
        break
      default:
        break
    }
    if (!isValid) {
      newState[name].show = true
    } else {
      newState[name].show = false
    }
    newState[name].value = beautifyEmailInput(name, value)
    this.setState(newState)
  }
  onClickBack = e => {
    e.preventDefault()
    e.currentTarget.parentElement.parentElement.classList.add('select')
    e.currentTarget.parentElement.parentElement.classList.remove('other')
    const newState = JSON.parse(JSON.stringify(this.state))
    newState.industry.value = ''
    newState.other.value = ''
    newState.other.show = false
    this.setState(newState)
  }
  onSubmit = e => {
    e.preventDefault()
    let newReg = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/g)
    let formValid = true
    const newState = { ...this.state }
    if (this.state.company_name.value.length === 0) {
      formValid = false
      newState.company_name.show = true
    }
    if (this.state.owner_name.value.length === 0) {
      formValid = false
      newState.owner_name.show = true
    }

    if (this.state.email.value.length === 0) {
      formValid = false
      newState.email.show = true
    }
    if (this.state.phone.value.length === 0 || this.state.phone.value.length !== 14) {
      formValid = false
      newState.phone.show = true
    }

    if (!newReg.test(this.state.password.value)) {
      formValid = false
      newState.password.show = true
    }

    if (!this.state.industry.value.length) {
      formValid = false
      newState.industry.show = true
    }
    if (this.state.confirm_password.value !== this.state.password.value) {
      formValid = false
      newState.confirm_password.show = true
    }
    if (!validator.isEmail(this.state.email.value)) {
      formValid = false
      newState.email.show = true
    }

    if (formValid) {
      this.setState({ isCreatingCompany: true })
      this.props
        .signUp({
          company_name: this.state.company_name.value,
          owner_name: this.state.owner_name.value,
          email: this.state.email.value,
          phone: this.state.phone.value,
          password: this.state.password.value,
          password_confirmation: this.state.confirm_password.value,
          industry: this.state.industry.value.map(value => value.inputValue || value),
        })
        .then(response => {
          this.setState({
            registerComplete: true,
            isCreatingCompany: false,
          })
        })
        .catch(err => {
          if (err.response) {
            const errors = err.response.data
            const state = { ...this.state }
            state.isCreatingCompany = false
            Object.keys(errors).forEach(key => {
              state[key].async_error_message = errors[key][0]
              state[key].show = true
            })
            this.setState(state)
          }
        })
    } else {
      this.setState(newState)
    }
  }

  goToCredit = () => {
    this.toggleSureModalForPaymantPage()
  }

  goToTerms = () => {
    this.setState({
      isCreatingCardProfile: false,
      showCreditCardForm: false,
      showTermsAndCondForm: true,
      showFinishPage: false,
    })
  }

  submitCard = data => {
    data.token_confirmation = this.state.token
    this.setState({ isCreatingCardProfile: true })
    return subscription
      .createSubscription(data)
      .then(() => {
        this.goToTerms()
        return 'ok'
      })
      .finally(() => {
        this.setState({ isCreatingCardProfile: false })
      })
  }

  goToFinish = () => {
    const istermsConfirmedToken = localStorage.getItem('confirmEmailToken')
    return confirmEmail(this.state.token)
      .then(() => {
        this.setState({
          registerComplete: true,
          emailConfirmed: true,
          showCreditCardForm: false,
          showTermsAndCondForm: false,
          showFinishPage: true,
        })
        localStorage.setItem(istermsConfirmedToken, 'confirmed')
        localStorage.removeItem('confirmEmailToken')
      })
      .catch(err => {
        const { response } = err
        if (response) {
          if (response.status === 400 && response.data && response.data.error && response.data.error.code === 10002) {
            localStorage.setItem(istermsConfirmedToken, 'confirmed')
            localStorage.removeItem('confirmEmailToken')
            return this.props.history.push('/login')
          }
        }
        this.props.history.push('/register/info')
        this.setState({
          registerComplete: true,
          emailConfirmed: true,
          showCreditCardForm: false,
          showTermsAndCondForm: true,
          showFinishPage: false,
        })
      })
  }

  backToLogin = e => {
    e.preventDefault()
    this.props.history.push('/login')
  }

  onFinish = e => {}

  handleOnBlurIndustry = (e, newValue = '') => {
    if (newValue.trim().length > 0) {
      const newState = { ...this.state }
      newState.industry.value.push(newValue)
    }
  }

  onChangeIndustry = (event, newValue) => {
    const newState = { ...this.state }
    if (newValue && newValue.inputValue) {
      newState.industry.value = newValue.inputValue
      newState.industry.show = !newValue.inputValue.length
    } else {
      newState.industry.value = newValue
      newState.industry.show = !newValue.length
    }
    this.setState(newState)
  }
  getIndustryOptionLabel = option => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue
    }
    // Regular option
    return option.title
  }

  filterIndustryOptions = (options, params) => {
    const filtered = filter(options, params)

    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        title: `Add "${params.inputValue}"`,
      })
    }

    return filtered
  }

  renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        key={option.inputValue || option}
        label={option.inputValue || option}
        {...getTagProps({ index })}
        deleteIcon={<ClearOutlinedIcon fontSize='inherit' />}
      />
    ))

  renderInput = params => (
    <TextField
      {...params}
      label='Industry'
      className={'industry-field'}
      name='industry'
      variant='outlined'
      error={this.state.industry.show}
      helperText={this.state.industry.show ? this.state.industry.error_message : ''}
      size='small'
      placeholder='Start typing...'
      inputProps={{ ...params.inputProps, ...inputProps100 }}
    />
  )

  returnToLanding = e => {
    e.preventDefault()
    this.props.history.push('/')
  }

  cancelRegistration = () => {
    localStorage.removeItem('confirmEmailToken')
    this.toggleSureModalForPaymantPage()
    this.props.history.push('/login')
  }

  handleClickShowPassword = key => () => {
    this.setState({ [`${key}_show`]: !this.state[`${key}_show`] })
  }
  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  renderPhoneTextField = () => (
    <TextField
      id='register-phone'
      type='phone'
      label='Phone Number'
      name='phone'
      variant='outlined'
      error={this.state.phone.show}
      helperText={this.state.phone.show ? this.state.phone.error_message : ''}
      fullWidth={true}
      size='small'
    />
  )

  render() {
    return (
      <AuthLayout>
        <div className='register-page'>
          {!this.state.registerComplete ? (
            <>
              <div className='main-text text-nowrap'>Get Started - It`s FREE!</div>
              <form className='custom-register-form' onSubmit={this.onSubmit} autoComplete='off' noValidate>
                <TextField
                  id='register-company-name'
                  type='text'
                  label='Company Name'
                  name='company_name'
                  value={this.state.company_name.value}
                  onChange={this.onChangeField}
                  variant='outlined'
                  error={this.state.company_name.show}
                  helperText={
                    this.state.company_name.show
                      ? this.state.company_name.async_error_message
                        ? this.state.company_name.async_error_message
                        : this.state.company_name.error_message
                      : ''
                  }
                  fullWidth={true}
                  inputProps={inputProps100}
                  size='small'
                />

                <TextField
                  id='register-owner-name'
                  type='text'
                  label='Owner Name'
                  name='owner_name'
                  value={this.state.owner_name.value}
                  onChange={this.onChangeField}
                  variant='outlined'
                  error={this.state.owner_name.show}
                  helperText={this.state.owner_name.show ? this.state.owner_name.error_message : ''}
                  fullWidth={true}
                  inputProps={inputProps100}
                  size='small'
                />
                <Autocomplete
                  multiple
                  value={this.state.industry.value}
                  onChange={this.onChangeIndustry}
                  filterOptions={this.filterIndustryOptions}
                  // autoSelect
                  // selectOnFocus
                  clearOnBlur
                  disableCloseOnSelect
                  handleHomeEndKeys
                  id='register-industry'
                  options={this.state.initIndustry}
                  renderOption={option => option.title || option}
                  getOptionLabel={this.getIndustryOptionLabel}
                  renderTags={this.renderTags}
                  freeSolo
                  renderInput={this.renderInput}
                  onBlur={e => this.handleOnBlurIndustry(e, e.target.value)}
                />

                <TextField
                  id='register-email'
                  type='email'
                  label='E-mail'
                  name='email'
                  value={this.state.email.value}
                  onChange={this.onChangeField}
                  variant='outlined'
                  error={this.state.email.show}
                  helperText={
                    this.state.email.show
                      ? this.state.email.async_error_message
                        ? this.state.email.async_error_message
                        : this.state.email.error_message
                      : ''
                  }
                  fullWidth={true}
                  inputProps={inputProps300}
                  size='small'
                />

                <ReactInputMask
                  mask='(999) 999-9999'
                  value={this.state.phone.value}
                  onChange={this.onChangeField}
                  disabled={false}
                  maskChar='X'
                >
                  {this.renderPhoneTextField}
                </ReactInputMask>

                <TextField
                  id='register-password'
                  type={this.state.password_show ? 'text' : 'password'}
                  label='Password'
                  name='password'
                  value={this.state.password.value}
                  onChange={this.onChangeField}
                  variant='outlined'
                  error={this.state.password.show}
                  helperText={this.state.password.show ? this.state.password.error_message : ''}
                  fullWidth={true}
                  size='small'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={this.handleClickShowPassword('password')}
                          onMouseDown={this.handleMouseDownPassword}
                          edge='end'
                        >
                          {this.state.password_show ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    ...inputProps32,
                  }}
                />

                <TextField
                  id='register-password-confirm'
                  type={this.state.confirm_password_show ? 'text' : 'password'}
                  label='Confirm Password'
                  name='confirm_password'
                  value={this.state.confirm_password.value}
                  onChange={this.onChangeField}
                  variant='outlined'
                  error={this.state.confirm_password.show}
                  helperText={this.state.confirm_password.show ? this.state.confirm_password.error_message : ''}
                  fullWidth={true}
                  size='small'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={this.handleClickShowPassword('confirm_password')}
                          onMouseDown={this.handleMouseDownPassword}
                          edge='end'
                        >
                          {this.state.confirm_password_show ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    ...inputProps32,
                  }}
                />

                <MainButton
                  title='Next'
                  isFetching={this.state.isCreatingCompany}
                  className='w-100 waves-effect waves-light main-button'
                  htmltype='submit'
                />
                <BackToLoginBtn title='Back to Login' onClick={this.backToLogin} />
              </form>
            </>
          ) : (
            <>
              {!this.state.emailConfirmed ? (
                <>
                  <div className='main-text'>Please confirm your email address.</div>
                  <div className='email-text'>
                    We have sent you a confirmation message to
                    <div>{this.state.email.value}</div>
                  </div>

                  <MainButton title='Home Page' className='main-button w-100 mt-5' onClick={this.returnToLanding} />
                </>
              ) : (
                <div>
                  {this.state.showCreditCardForm && (
                    <PagesRegisterCard
                      submitCard={this.submitCard}
                      isCreatingCardProfile={this.state.isCreatingCardProfile}
                      onCancel={this.toggleSureModalForPaymantPage}
                    />
                  )}

                  {this.state.showTermsAndCondForm && (
                    <PagesLegal
                      goToFinish={this.goToFinish}
                      goToCredit={this.goToCredit}
                      onCancel={this.toggleSureModalForPaymantPage}
                    />
                  )}
                  {this.state.showFinishPage && <RegisterFinishPage confirmEmailOnFinish={this.confirmEmailOnFinish} />}
                </div>
              )}
            </>
          )}
          <CancelRegistrationModal
            modal={this.state.sureModalForPaymentPage}
            close={this.toggleSureModalForPaymantPage}
            apply={this.cancelRegistration}
          />
        </div>
      </AuthLayout>
    )
  }
}

const mapStateToProps = state => ({
  meme: state.orcatec.user.meme,
})

const mapDispatchToProps = {
  signUp: actions.user.signUp,
  createCreditCard: actions.user.createCreditCard,
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PagesRegisterInfo)
