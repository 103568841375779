import { useEffect, useState } from 'react'
import { getTaxRateByZip } from 'api/settings/Proposal'
import { Property } from 'types/Property'

export const useTaxRateByZip = (
  primaryProperty: Property,
): { rateByZIP: number; loading: boolean } => {
  const [rateByZIP, setRateByZIP] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getRateByZipCode = async () => {
      setLoading(true)
      const { tax } = await getTaxRateByZip(primaryProperty?.postcode, {
        city: primaryProperty.city,
        address: primaryProperty.address,
      })

      setRateByZIP(tax.estimated_combined_rate)
      setLoading(false)
    }

    if (primaryProperty?.postcode) {
      getRateByZipCode()
    }
  }, [primaryProperty])

  return {
    rateByZIP,
    loading,
  }
}
