import { Button, Input, Table, Tooltip } from 'antd'
import { PaginationConfig } from 'antd/lib/pagination'
import { ColumnProps } from 'antd/lib/table'
import { SorterResult } from 'antd/lib/table/interface'
// import {
//   getAttachedTemplatesToProject,
//   getFormTemplates,
// } from 'api/CustomForms'
import { Search } from 'components/UIKit'
import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { CustomFormTemplate, ISubmittedTable } from 'features/Forms/types'
// import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import useDebounce from 'hooks/useDebounce'
// import { getValueFromLS } from 'hooks/useLocalStorage'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompanyTimezone } from 'store/Orcatec/selectors/company'
import styled from 'styled-components'

interface Props {
  data: CustomFormTemplate[]
  loading: boolean
  // showAllowableTemplates: boolean
  pagination: { total: number; per_page: number }
  getTemplateList: (params: GeneralTableParams) => void
  onSave: (
    templates: CustomFormTemplate[],
    counts: { [id: number]: number },
  ) => void
  onClose: () => void
}

const isValidNumber = value => typeof value === 'number' && !isNaN(value)
const isNonZero = value => value < 0
const isNotThreeDigitNumber = value => value < 100 && value > -100

export const TemplatesListModal = ({
  // entityId,
  // showAllowableTemplates,
  data,
  pagination,
  loading,
  getTemplateList,
  onSave,
  onClose,
}: Props) => {
  const timezone = useAppSelector(selectCompanyTimezone)
  // const settingsFromLC = getValueFromLS(`$ololo-table_v1`)
  // const [loading, setLoading] = useState(false)
  // const [formList, setFormList] = useState([])

  const [sortField, setSortField] = useState<string | null>(null)

  const [sortOrder, setSortOrder] = useState<string | null>(null)

  // const [pagination, setPagination] = useState({
  //   page: 1,
  //   per_page: settingsFromLC?.page_size || 25,
  //   total: 10,
  // })
  // const [tableFilters, setTableFilters] = useState({
  //   ...settingsFromLC?.filters,
  // })
  const [search, setSearch] = useState('')

  const debouncedSearchValue = useDebounce(search, 500)

  const [selectedTemplates, setSelectedTemplates] = useState<
    CustomFormTemplate[]
  >([])

  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const [counts, setCounts] = useState<{ [key: number]: number }>({})

  useEffect(() => {
    getTemplateList({
      per_page: 10,
      search: debouncedSearchValue || undefined,
      sort_field: sortField,
      order: sortOrder,
    })
  }, [debouncedSearchValue])

  //   const getTemplateList = async params => {
  //     setLoading(true)
  //
  //     try {
  //       const res = showAllowableTemplates
  //         ? await getAttachedTemplatesToProject(entityId)
  //         : await getFormTemplates(params)
  //
  //       setPagination(res.meta)
  //       setFormList(res?.data.map(form => ({ ...form, key: form.id })))
  //     } catch (error) {
  //       if (error?.response?.status === 403) {
  //         openNotificationWithIcon('warning', {
  //           message: 'You do not have permissions',
  //         })
  //       }
  //       console.error(error)
  //     } finally {
  //       setLoading(false)
  //     }
  //   }

  const handleTableChange = (
    pagination: PaginationConfig,
    filters: Partial<Record<keyof ISubmittedTable, string[]>>,
    sorter: SorterResult<ISubmittedTable>,
  ) => {
    getTemplateList({
      search: debouncedSearchValue || undefined,
      page: pagination.current,
      sort_field: sorter.order ? sorter.field : null,
      order:
        sorter.order === 'ascend'
          ? 'asc'
          : sorter.order === 'descend'
          ? 'desc'
          : null,
      per_page: pagination.pageSize,
      ...filters,
    })

    setSortOrder(
      sorter.order === 'ascend'
        ? 'asc'
        : sorter.order === 'descend'
        ? 'desc'
        : null,
    )
    setSortField(sorter.order ? sorter.field : null)
    // setTableFilters({ ...filters })
  }

  const handleSave = () => {
    onSave?.(selectedTemplates, counts)
  }

  const onChangeCount = (value, record) => {
    const parsedValue = parseInt(value || 0, 10)

    if (!isValidNumber(parsedValue)) {
      return
    }

    if (!isNotThreeDigitNumber(parsedValue)) {
      return
    }

    if (isNonZero(parsedValue)) {
      return
    }

    setCounts(prev => ({ ...prev, [record.id]: parsedValue }))
  }

  const rowSelection = {
    onChange: (checkedRows: number[], selectedRows: CustomFormTemplate[]) => {
      setSelectedTemplates(selectedRows)
      setSelectedIds(checkedRows)

      const newCounts = selectedRows.reduce((acc, item) => {
        if (!!counts?.[item.id]) {
          return acc
        }
        acc[item.id] = 1
        return acc
      }, {})

      setCounts(prev => ({ ...prev, ...newCounts }))
    },
  }

  const columns: ColumnProps<CustomFormTemplate>[] = [
    {
      title: 'Qty',
      dataIndex: 'count',
      key: 'count',
      sorter: false,
      width: 70,
      render: (value, record) => {
        const isActive = selectedIds.includes(record.id)
        return (
          <Input
            disabled={!isActive}
            value={isActive ? counts?.[record.id] : null}
            onChange={e => onChangeCount(e.target.value, record)}
            size='small'
            type='number'
            min={1}
            max={99}
            onBlur={e => {
              if (!+e.target.value) {
                onChangeCount(1, record)
              }
            }}
          />
        )
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
    },

    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      width: 200,

      render: value =>
        moment(value, 'MM/DD/YYYY HH:mm:ss')
          .tz(timezone)
          .format('MM/DD/YYYY hh:mm a'),
    },
    {
      title: 'Created by',
      dataIndex: 'created_by',
      key: 'created_by',
      width: 200,
      sorter: true,
      render: value => value || '--',
    },
  ]

  return (
    <Modal
      title='Choose template(s)'
      visible
      // onOk={onSave}
      onCancel={onClose}
      width={1000}
      style={{ overflow: 'hidden' }}
      footer={
        <Buttons>
          <Button onClick={onClose}>Cancel</Button>
          <Tooltip
            title={
              !selectedTemplates?.length
                ? 'Please, select the template(s)'
                : undefined
            }
          >
            <Button
              type='primary'
              onClick={handleSave}
              loading={loading}
              disabled={!selectedTemplates?.length}
            >
              Create
            </Button>
          </Tooltip>
        </Buttons>
      }
    >
      <Heading>
        <Search
          placeholder='Search'
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          width='300px'
        />
      </Heading>

      <Table
        size='small'
        columns={columns}
        loading={loading}
        dataSource={data}
        onChange={handleTableChange}
        rowSelection={rowSelection}
        pagination={
          pagination?.total < pagination?.per_page ? false : pagination
        }
        style={{ marginBottom: 20, maxHeight: '70vh', overflow: 'auto' }}
      />
    </Modal>
  )
}

const Heading = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`
