import { Table as AntdTable, Tooltip } from 'antd'
import { round } from 'helpers/Math'
import { priceToView } from 'helpers/thousandSeparator'

export const TableSummary = ({ data, columns, selectedColumns, total }) => {
  const dataKeys = Object.keys(data)

  return (
    selectedColumns?.length && (
      <>
        <AntdTable.Summary.Row className='divider'>
          <AntdTable.Summary.Cell colSpan={selectedColumns?.length || 10}>
            <div style={{ display: 'flex', gap: '50px' }}>
              {' '}
              <span>Total for {total} items </span>
            </div>
          </AntdTable.Summary.Cell>
        </AntdTable.Summary.Row>
        <AntdTable.Summary.Row>
          {window.location.pathname === '/accounting' && (
            <td key={'0'} title='test'></td>
          )}

          {columns
            ?.filter(column => selectedColumns?.includes(column?.dataIndex))
            .map(column => {
              const columnData = dataKeys?.includes(column?.dataIndex)
                ? column?.dataIndex === 'transaction_id'
                  ? `сount of transactions: ${data?.[column?.dataIndex] || 0}`
                  : ['duration', 'installed_qty', 'estimated_qty'].includes(
                      column?.dataIndex,
                    )
                  ? data?.[column?.dataIndex]
                  : priceToView(data?.[column?.dataIndex])
                : ''

              return (
                <td key={column?.dataIndex} title={columnData}>
                  <Tooltip title={`${column?.name}: ${columnData}`}>
                    {columnData}
                  </Tooltip>
                </td>
              )
            })}
        </AntdTable.Summary.Row>
        <AntdTable.Summary.Row className='divider'>
          <AntdTable.Summary.Cell colSpan={selectedColumns?.length || 10}>
            <div style={{ display: 'flex', gap: '50px' }}>
              {' '}
              <span>Average </span>
            </div>
          </AntdTable.Summary.Cell>
        </AntdTable.Summary.Row>
        <AntdTable.Summary.Row>
          {window.location.pathname === '/accounting' && (
            <td key={'0'} title='test'></td>
          )}

          {columns
            ?.filter(column => selectedColumns?.includes(column?.dataIndex))
            .map(column => {
              const columnData = dataKeys?.includes(column?.dataIndex)
                ? column?.dataIndex === 'transaction_id'
                  ? `сount of transactions: ${(
                      data?.[column?.dataIndex] / total
                    ).toFixed(2) || 0}`
                  : ['duration', 'installed_qty', 'estimated_qty'].includes(
                      column?.dataIndex,
                    )
                  ? round(data?.[column?.dataIndex] / total)
                  : priceToView(data?.[column?.dataIndex] / total)
                : ''

              return (
                <td key={column?.dataIndex} title={columnData}>
                  <Tooltip title={`${column?.name}: ${columnData}`}>
                    {columnData}
                  </Tooltip>
                </td>
              )
            })}
        </AntdTable.Summary.Row>
      </>
    )
  )
}
