export const ActionType = {
  MC_LOGIN_INIT: 'MC_LOGIN_INIT',
  MC_LOGIN_SUCCESS: 'MC_LOGIN_SUCCESS',
  MC_LOGIN_FAILURE: 'MC_LOGIN_FAILURE',
  MC_LOGIN_NEED_CHANGE_PASSWORD: 'MC_LOGIN_NEED_CHANGE_PASSWORD',
  MC_LOGIN_NEED_CHANGE_PASSWORD_INIT: 'MC_LOGIN_NEED_CHANGE_PASSWORD_INIT',
  MC_LOGIN_NEED_CHANGE_PASSWORD_FAILURE: 'MC_LOGIN_NEED_CHANGE_PASSWORD_FAILURE',
  MC_LOGOUT: 'MC_LOGOUT',
  MC_AUTH_CHECK: 'MC_AUTH_CHECK',
}
