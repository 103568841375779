import { IMessage } from '../../../../types'
import ChatMessage from '../ChatMessage'

import Preloader from 'containers/MainContent/Orcatec/components/Preloader'
import { Divider, Empty, Spin } from 'antd'
import moment from 'moment'
import React from 'react'

export const MessagesList = ({
  isFetchingNextPage,
  isChatLoading,
  handleSetUnseen,
  groupArrays,
  deleteMessage,
  resendMessage,
  chatInfo,
}) => {
  return (
    <div
      className='chat-container scrollable'
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isFetchingNextPage && !isChatLoading && <Preloader />}
      {!groupArrays.length && !isChatLoading && (
        <Empty description='No messages yet' />
      )}
      <Spin spinning={isChatLoading}>
        {groupArrays?.map(({ date, messages }) => (
          <React.Fragment key={date.toString()}>
            <Divider
              orientation='center'
              className='some class name'
              style={{ fontSize: '12px' }}
            >
              <span>{moment(date).format('MMM D, yyyy')}</span>
            </Divider>
            {messages.map((message: IMessage) => (
              <ChatMessage
                key={message.id}
                unseenMessages={handleSetUnseen}
                deleteMessage={deleteMessage}
                resendMessage={resendMessage}
                chatInfo={chatInfo}
                {...message}
              />
            ))}
          </React.Fragment>
        ))}
      </Spin>
    </div>
  )
}

export default MessagesList
