import axios from 'axios'
import axiosOrcatec from './axiosInstance'
import { globalHistory } from '../App'
import { User, UserRole } from 'types/User'
import { ITechnician } from 'types/Appointment'

/**
 * User authorization.
 *
 * @param email
 * @param password
 * @return {Promise<any>}
 */
export const authorization = (email, password, remember) => {
  return axiosOrcatec
    .post('login', {
      email: email,
      password: password,
    })
    .then(response => {
      const { data } = response
      data.rememberMe = remember
      return data
    })
}

export const sendRegisterForm = data => {
  return axiosOrcatec
    .post('registration', data)
    .then(response => response?.data)
}

/**
 * Get auth user id.
 *
 * @return {*}
 */
export const getAuthUserId = () => {
  const authData = localStorage.getItem('auth')
  const auth = authData ? JSON.parse(authData) : null
  if (!auth) {
    globalHistory.push('/auth/signin')
  }

  return auth.user_id
}

/**
 * Fetch user.
 *
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const fetchUsersList = (
  page = 1,
  limit = 10,
  search,
  column,
  sortType,
  inactive,
) => {
  const params = {}

  if (search) {
    params.search = search
  }
  if (column && sortType) {
    params.column = column
    params.sort = sortType
  }
  if (inactive) {
    params['statuses[0]'] = inactive[0]
    params['statuses[1]'] = inactive[1]
    params['statuses[2]'] = inactive[2]
  }

  params.page = page
  params.limit = limit
  return axiosOrcatec.get('users', { params }).then(response => response.data)
}

export const getUsersList = params => {
  return axiosOrcatec.get('users', { params }).then(response => response.data)
}

/**
 * Fetch user.
 *
 * @param id
 * @return {Promise<AxiosResponse<any> | never>}
 */

export const getUser = (id: string) => {
  return axiosOrcatec.get<User>(`users/${id}`).then(response => response.data)
}

export const getTechnicians = () => {
  return axiosOrcatec
    .get<ITechnician[]>('users/get-technicians')
    .then(response => response.data)
}

export const getActiveUsers = () => {
  return axiosOrcatec
    .get<ITechnician[]>('users/active')
    .then(response => response.data)
}
/**
 * Fetch user.
 *
 *
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const fetchUserByToken = () => {
  return axiosOrcatec.post(`get/auth-user`).then(res => res.data)
}

/**
 * Create user.
 *
 * @param data
 * @return {Promise<T | never>}
 */
export const createUser = data => {
  return axiosOrcatec.post('users', data).then(response => response.data)
}

/**
 * Update user.
 *
 * @param id
 * @param data
 * @return {Promise<T | never>}
 */
export const updateUser = (id, data, confirm) => {
  return axiosOrcatec
    .put(`users/${id}${confirm ? `?confirm=${confirm}` : ''}`, data)
    .then(response => response.data)
}
/**
 * Update user temlate id.
 *
 * @param data
 * @return {Promise<T | never>}
 */
export const updateUserTemplate = (id, data) => {
  return axiosOrcatec
    .put(`/users/update-template-id/${id}`, data)
    .then(response => response.data)
}
/**
 * Update user.
 *
 * @param id
 * @param data
 * @return {Promise<T | never>}
 */
export const confirmEmail = tempToken => {
  return axiosOrcatec.get(`/users/confirm?token=${tempToken}`)
}

export interface Tech {
  id: number
  role_id: UserRole
  full_name: string
}

type TechReponse = {
  template_name: string
  users: Tech[]
}

/**
 * Delete user.
 *
 * @param id
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const deleteUser = (id, confirm) => {
  return axiosOrcatec
    .delete(`users/${id}${confirm ? `?confirm=${confirm}` : ''}`)
    .then(response => response.data)
}
export const fetchUsersForTemplate = () => {
  return axiosOrcatec
    .get<TechReponse>(`/users/get-users-for-templates`)
    .then(response => response.data)
}

export const fetchUsersListForProposals = () => {
  return axiosOrcatec
    .get('/users/get-users-for-proposal')
    .then(response => response.data)
}

export const updateUserPassword = (id, data) => {
  return axiosOrcatec
    .put(`/users/change-password/${id}`, data)
    .then(response => response.data)
}

export const resetUserPassword = (
  userId: number,
  data: { password: string; password_confirmation: string },
) =>
  axiosOrcatec
    .put(`users/${userId}/password-reset`, data)
    .then(response => response.data)

// const url = 'https://geoip-db.com/json/'
export const fetchMyClientIp = () => {
  const url = 'https://api.ipify.org/'
  return axios.get(url).then(response => response.data)
}

export const getProposalIdentifier = () =>
  axiosOrcatec.get('/users/proposal-identifier').then(response => response.data)

export const generateInviteLink = userId =>
  axiosOrcatec
    .get(`/users/generate_new_register_link/${userId}`)
    .then(response => response.data)
export const verifyInviteLink = token =>
  axiosOrcatec.get(`/users/check_register_link?${token}`)

export const logoutAccountManagerToAdmin = () =>
  axiosOrcatec.post('/back-to-admin').then(r => r)

export const changeUserStatus = (userID, data) =>
  axiosOrcatec.put(`users/${userID}/status`, data).then(({ data }) => data)

export const changeNavigationInterface = (
  userID: number,
  data: { is_vertical_sidebar: boolean; header_view: number },
) =>
  axiosOrcatec
    .put(`/users/${userID}/ui-settings`, data)
    .then(({ data }) => data)

export const getUsers = <T>(params: any) =>
  axiosOrcatec
    .get<T>('/users', { params })
    .then(({ data }) => data)

export const getCurrentJournal = () =>
  axiosOrcatec
    .get('/setting/subscription/get-current-journal')
    .then(({ data }) => data)

export const getPermissionsByGroupId = groupId =>
  axiosOrcatec
    .get(`/permission-groups/${groupId}/permissions`)
    .then(({ data }) => data)

export const createPermissionGroup = data =>
  axiosOrcatec.post(`/permission-groups`, data).then(({ data }) => data)

export const getPermissionGroupInfo = groupId =>
  axiosOrcatec.get(`/permission-groups/${groupId}`).then(({ data }) => data)

export const getRelatedUserToGroup = (groupId, params) =>
  axiosOrcatec
    .get(`/permission-groups/${groupId}/users`, { params })
    .then(({ data }) => data)

export const getListPermissionsGroup = params =>
  axiosOrcatec.get(`/permission-groups`, { params }).then(({ data }) => data)

export const attachUserToGroup = (groupId, data) =>
  axiosOrcatec
    .post(`/permission-groups/${groupId}/users/attach`, data)
    .then(({ data }) => data)

export const detachUserFromGroup = (groupId, userId) =>
  axiosOrcatec
    .post(`/permission-groups/${groupId}/users/${userId}/detach`)
    .then(({ data }) => data)

export const deleteUserFromGroup = (groupId, userId) =>
  axiosOrcatec
    .post(`/users/${userId}/groups/${groupId}/detach  `)
    .then(({ data }) => data)

export const updateGroupInfo = (groupId, data) =>
  axiosOrcatec
    .patch(`/permission-groups/${groupId}`, data)
    .then(({ data }) => data)

export const getUnassignedUsers = params =>
  axiosOrcatec
    .get(`/permission-groups/unassigned-users`, { params })
    .then(({ data }) => data)

export const deletePermissionsGroup = groupId =>
  axiosOrcatec.delete(`/permission-groups/${groupId}`).then(({ data }) => data)

export const updateGroupPermissions = (groupId, data) =>
  axiosOrcatec
    .patch(`/permission-groups/${groupId}/permissions`, { permissions: data })
    .then(({ data }) => data)

export const updateUserPermissions = (userId, data) =>
  axiosOrcatec
    .patch(`/users/${userId}/permissions`, { permissions: data })
    .then(({ data }) => data)

export const getUserPermission = userId =>
  axiosOrcatec.get(`/users/${userId}/permissions`).then(({ data }) => data)

export const reassignUserToGroup = (userId, groupId) =>
  axiosOrcatec
    .post(`/permission-groups/${groupId}/users/${userId}/attach`)
    .then(({ data }) => data)

export const getListOfPhones = () =>
  axiosOrcatec.get(`/messaging/phone-numbers`).then(({ data }) => data)

export const getSettingsListOfPhones = () =>
  axiosOrcatec.get(`/setting/messaging/phone-numbers`).then(({ data }) => data)

export const getRelatedPhonesToUser = userId =>
  axiosOrcatec.get(`/users/${userId}/phones`).then(({ data }) => data)

export const getRelatedPhonesToGroup = groupId =>
  axiosOrcatec
    .get(`/permission-groups/${groupId}/phones`)
    .then(({ data }) => data)

export const syncPhonesToUser = (userId, phones) =>
  axiosOrcatec
    .post(`/users/${userId}/phones/sync`, { phones })
    .then(({ data }) => data)

export const syncPhonesToGroup = (groupId, phones) =>
  axiosOrcatec
    .post(`/permission-groups/${groupId}/phones/sync`, { phones })
    .then(({ data }) => data)

export const getGroupPermissionByKey = (groupId, key) =>
  axiosOrcatec
    .get(`/permission-groups/${groupId}/permissions/${key}`)
    .then(({ data }) => data)

export const getUserPermissionByKey = (userId, key) =>
  axiosOrcatec
    .get(`users/${userId}/permissions/${key}`)
    .then(({ data }) => data)
