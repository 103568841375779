//FOLDERS

import axiosOrcatec from 'api/axiosInstance'

export const config = {
  prefix: 'mail-client',
}

const getEmailAccounts = () => {
  return axiosOrcatec.get(`${config.prefix}/accounts`).then(({ data }) => data)
}

const loginToEmailAccount = (id: number) =>
  axiosOrcatec
    .post(`${config.prefix}/accounts/${id}/login`)
    .then(({ data }) => data)

const foldersList = () => {
  return axiosOrcatec
    .get(`${config.prefix}/folders`)
    .then(response => response.data)
}
const createFolder = data => {
  return axiosOrcatec
    .post(`${config.prefix}/folders`, data)
    .then(response => response.data)
}

const attachEmailAccount = data => {
  return axiosOrcatec
    .post(`${config.prefix}/attach-account`, data)
    .then(response => response.data)
}

const updateFolder = (data: { from: string; to: string }) => {
  return axiosOrcatec
    .put(`${config.prefix}/folder`, data)
    .then(response => response.data)
}

const getFolderMessages = params => {
  return axiosOrcatec
    .get(`${config.prefix}/messages`, { params })
    .then(response => response.data)
}

const getEmailDetails = (params: { f_path: string; m_uid: string }) => {
  return axiosOrcatec
    .get(`${config.prefix}/messages/show`, {
      params,
    })
    .then(response => response.data)
}

const deleteMessages = (params: { f_path: string; m_uid: string[] }) => {
  return axiosOrcatec.post(`${config.prefix}/messages/delete`, params)
}

const moveTo = (params: {
  f_path: string
  m_uid: string[]
  f_mv_path: string
}) => {
  return axiosOrcatec.put(`${config.prefix}/messages/move`, params)
}

const sendMail = (
  data: { body: object; header: object; d_uid: number },
  token: string,
) => {
  const formData = makeFromDataFromObject(data)

  const headers = {
    'mail-client-auth': `${token}`,
  }

  return axiosOrcatec.post(`${config.prefix}/messages/send`, formData, {
    headers,
  })
}

const saveDraft = (data: {
  headers: object
  body: object
  d_uid: string
  f_path: string
}) => {
  const formData = makeFromDataFromObject(data)

  return axiosOrcatec.post(`${config.prefix}/messages/draft`, formData)
}

const deleteFolder = data => {
  return axiosOrcatec.post(`${config.prefix}/folder/delete`, data)
}

const setFavorite = ({ f_path, m_uid, flagged }) => {
  return axiosOrcatec.post(`${config.prefix}/messages/favorite`, {
    f_path,
    m_uid,
    flagged,
  })
}

const changeFlag = data =>
  axiosOrcatec.post(`${config.prefix}/messages/flag`, data)

const getUnseenMessages = id =>
  axiosOrcatec
    .get(`/mail-client/accounts/${id}/count-unseen`)
    .then(({ data }) => data)

const makeFromDataFromObject = (obj, form, namespace) => {
  const formData = form || new FormData()
  let formKey

  for (const property in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']'
      } else {
        formKey = property
      }

      if (
        typeof obj[property] === 'object' &&
        !(obj[property] instanceof File) &&
        obj[property] !== null
      ) {
        makeFromDataFromObject(obj[property], formData, formKey)
      } else {
        let value = obj[property]

        if (value === undefined) {
          continue
        }

        if (value === null) {
          value = ''
        }

        formData.append(formKey, value)
      }
    }
  }

  return formData
}

export {
  foldersList,
  getEmailAccounts,
  loginToEmailAccount,
  createFolder,
  getFolderMessages,
  getEmailDetails,
  deleteMessages,
  moveTo,
  sendMail,
  saveDraft,
  setFavorite,
  changeFlag,
  updateFolder,
  deleteFolder,
  getUnseenMessages,
  attachEmailAccount,
}
