import { ProjectDiscount, ProjectDiscountType } from 'features/Project/types'
import {
  AdditionalInfo,
  AttachmentsWrapper,
  Content,
  Description,
  Diff,
  ItemWrapper,
  Price,
  PriceSection,
  Qty,
  StyledRadio,
  Title,
  Total,
} from '../ItemsList/Item.styles'
import { Attachments } from 'features/Items/components/Item/components/Attachments/Attachments'
import { priceToView } from 'helpers/thousandSeparator'
import { round } from 'helpers/Math'

interface Props {
  data: ProjectDiscount
  disabled: boolean
  checkedItem?: ProjectDiscount
  pricePermission: boolean
  sectionSubtotal: number
  onChange: (discount: ProjectDiscount) => void
}

export const Discount = ({
  data,
  disabled,
  checkedItem,
  pricePermission,
  sectionSubtotal,
  onChange,
}: Props) => {
  const getDifference = (item: ProjectDiscount) => {
    if (!checkedItem) return 0

    return round(item.amount - checkedItem?.amount)
  }

  return (
    <ItemWrapper
      inactive={!data.active || (!!checkedItem && !data.checked_option)}
    >
      {!!checkedItem && (
        <StyledRadio
          disabled={disabled}
          checked={data.checked_option}
          onClick={() => {
            onChange({
              ...data,
              checked_option: true,
            })
          }}
        />
      )}

      <Title>{data.name}</Title>

      <Content>
        <Description expired={!data.active}>
          {data.expiration
            ? `${data.active ? 'Expires' : 'Expired'} on ${data.expiration}`
            : 'No expiration'}
        </Description>

        <AdditionalInfo>
          <AttachmentsWrapper>
            <Attachments imageSize={35} data={data?.attachments} />
          </AttachmentsWrapper>

          <PriceSection>
            <Diff>
              {!!getDifference(data) &&
                `${getDifference(data) > 0 ? '+' : ''}${priceToView(
                  getDifference(data),
                )}`}
            </Diff>
            <Price></Price>
            <Qty></Qty>
            <Total>
              {pricePermission && (
                <>
                  -
                  {priceToView(
                    data.discount_type === ProjectDiscountType.Amount
                      ? data.amount
                      : round(data.amount * sectionSubtotal) / 100,
                  )}
                </>
              )}
            </Total>
          </PriceSection>
        </AdditionalInfo>
      </Content>
    </ItemWrapper>
  )
}
