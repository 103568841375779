import React from 'react'

const iframeRenderer = ({ state }) => {
    const { currentDocument } = state

    return (
        <div className='iframe-wrapper'>
            <iframe className='renderer-iframe' src={currentDocument?.source || currentDocument?.linkToFile || currentDocument}></iframe>
        </div>
    )
}

export default iframeRenderer

iframeRenderer.fileTypes = 'iframe'
iframeRenderer.headerType = 'iframe'