import React from 'react'

const SupportIcon = () => {
  return (
    <svg
      style={{ display: 'block', margin: '0 auto 4px' }}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.9869 6.69853L16.0012 6.59981C15.9013 6.309 15.7832 6.02437 15.6471 5.74734L16.2759 4.97841L16.9203 4.19062L16.2008 3.47091L14.5313 1.80028L13.8111 1.07972L13.0227 1.72519L12.2566 2.35238C11.9793 2.21597 11.6947 2.09728 11.4033 1.99716L11.3048 1.01306L11.2039 0H10.1857H9.00534H7.81481H6.79669L6.69516 1.01306L6.59672 1.99687C6.30534 2.09728 6.02016 2.21569 5.74284 2.35238L4.97728 1.72547L4.18894 1.08L3.46866 1.80056L1.79916 3.47119L1.08 4.19119L1.72434 4.97897L2.35294 5.74734C2.21681 6.02466 2.09869 6.30928 1.99856 6.60038L1.01306 6.69909L0 6.80034V7.81847V10.181V11.1991L1.01306 11.3003L1.99828 11.3991C2.09813 11.6904 2.21653 11.9753 2.35294 12.2529L1.72462 13.0213L1.08 13.8088L1.79944 14.5285L3.46894 16.1992L4.18922 16.9197L4.97756 16.2742L5.74313 15.6476C6.02072 15.7843 6.30591 15.903 6.59756 16.0034L6.696 16.9872L6.79725 18H7.81538H8.99606H10.1866H11.2047L11.3063 16.9869L11.4047 16.0028C11.6963 15.9024 11.9812 15.7837 12.2586 15.6471L13.0244 16.2742L13.8127 16.9197L14.533 16.1992L16.2025 14.5285L16.9217 13.8088L16.2776 13.021L15.6485 12.2515C15.7843 11.9745 15.9024 11.6899 16.0023 11.3991L16.9881 11.2998L18 11.198V10.1804V7.81791V6.79978L16.9869 6.69853ZM16.875 10.1804L15.1279 10.3562C14.9678 11.0832 14.681 11.7616 14.2926 12.3708L15.4066 13.7332L13.7371 15.4038L12.377 14.2903C11.7678 14.6804 11.0888 14.9687 10.3613 15.1298L10.1863 16.875H9.00563H8.99578H7.81509L7.64044 15.1298C6.91228 14.9692 6.23334 14.681 5.62416 14.2909L4.26459 15.4041L2.59509 13.7334L3.70856 12.3722C3.31987 11.7627 3.03216 11.0843 2.87212 10.3562L1.125 10.181V7.81847L2.87212 7.64353C3.03272 6.91594 3.32016 6.237 3.70856 5.62809L2.59509 4.26684L4.26459 2.59622L5.62416 3.70912C6.23278 3.31959 6.91172 3.03131 7.63987 2.87016L7.81481 1.125H8.9955H9.00534H10.1857L10.3604 2.87016C11.0883 3.03131 11.7672 3.31959 12.3758 3.70912L13.736 2.59566L15.4055 4.26628L14.2914 5.62809C14.6796 6.237 14.9673 6.91538 15.1273 7.64325L16.875 7.81791V10.1804Z'
        fill='white'
      />
    </svg>
  )
}

export default SupportIcon
