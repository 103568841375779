import {
  NEXT_DOCUMENT,
  PREVIOUS_DOCUMENT,
  SET_ALL_DOCUMENTS,
  SET_DOCUMENT_LOADING,
  SET_MAIN_CONFIG,
  SET_RENDERER_RECT,
  UPDATE_CURRENT_DOCUMENT,
  OPEN_SIDEBAR,
  SET_ACTIVE_IDX,
  CHANGE_DOC,
  ZOOM_IN,
  ZOOM_OUT,
  ROTATE_LEFT,
  ROTATE_RIGHT,
  RESET_TRANSFORM,
  SET_ZOOM_LEVEL,
  SET_PDF_PAGINATED,
  SET_NUM_PAGES,
  SET_CURRENT_PAGE,
  SET_INITIAL_STATE,
  SET_HEADER_TYPE,
  SET_FILE_DATA,
  SET_ERROR,
  
} from './actions'

export const initialState = {
  documents: [],
  currentDocument: null,
  currentDATA:'',
  loading: false,
  error: {hasError:false, text:''},
  rendererRect: null,
  pluginRenderers: [],
  type: null,
  headerType: null,
  currentFileNo: 0,
  isOpenSideBar: false,
  activeIdx: null,
  rotate: 0,
  scale: 1,
  maxScale: 3,
  minScale: 0,
  rotateAngle:90,
  scaleStep:0.2,
  zoomLevel: 1,
  paginated: true,
  numPages: 0,
  currentPage: 1,
}

export const mainStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_DOCUMENTS: {
      const { documents,activeIdx } = action.payload
      return { ...state, documents,activeIdx, currentDocument: documents[activeIdx] || []}
    }

    case SET_DOCUMENT_LOADING: {
      const { value } = action
      return { ...state, loading: value }
    }

    case NEXT_DOCUMENT: {
      if (state.currentFileNo >= state.documents.length - 1) return state

      const nextDocumentNo = state.currentFileNo + 1
      return {
        ...state,
        currentFileNo: nextDocumentNo,
        currentDocument: state.documents[nextDocumentNo],
        documentLoading: true,
      }
    }

    case PREVIOUS_DOCUMENT: {
      if (state.currentFileNo <= 0) return state

      const prevDocumentNo = state.currentFileNo - 1
      return {
        ...state,
        currentFileNo: state.currentFileNo - 1,
        currentDocument: state.documents[prevDocumentNo],
        documentLoading: true,
      }
    }

    case UPDATE_CURRENT_DOCUMENT: {
      const { document } = action
      return {
        ...state,
        currentDocument: document,
      }
    }

    case SET_RENDERER_RECT: {
      const { rect } = action
      return {
        ...state,
        rendererRect: rect,
      }
    }

    case SET_MAIN_CONFIG: {
      const { config } = action
      return {
        ...state,
        config,
      }
    }

    case OPEN_SIDEBAR: {
      return {
        ...state,
        isOpenSideBar: !state.isOpenSideBar,
      }
    }

    case SET_ACTIVE_IDX: {
      return {
        ...state,
        activeIdx: action.idx,
      }
    }
    case CHANGE_DOC: {
      return {
        ...state,
        currentDocument: state.documents[action.idx],
        activeIdx: action.idx,
      }
    }

    case ZOOM_IN: {
      let scale = state.scale

      if (scale < state.maxScale) {
        scale = scale + state.scaleStep
      }
      return {
        ...state,
        scale,
      }
    }

    case ZOOM_OUT: {
      let scale = state.scale

      if (scale > state.minScale) {
        scale = scale - state.scaleStep
      }
      return {
        ...state,
        scale,
      }
    }

    case ROTATE_LEFT: {
      return {
        ...state,
        rotate: state.rotate - state.rotateAngle
      }
    }

    case ROTATE_RIGHT: {
      return {
        ...state,
        rotate: state.rotate + state.rotateAngle
      }
    }

    case RESET_TRANSFORM: {
      return {
        ...state,
        rotate:0,
        scale:1,
      }

    }

    case SET_ZOOM_LEVEL: {
      const { value } = action

      return { ...state, zoomLevel: value }
    }

    case SET_PDF_PAGINATED: {
      const { value } = action;
      return { ...state, paginated: value }
    }

    case SET_NUM_PAGES: {
      const { value } = action;
      return { ...state, numPages: value }
    }

    case SET_CURRENT_PAGE: {
      const { value } = action;
      return { ...state, currentPage: value }
    }

    case SET_INITIAL_STATE: {
      return {
        ...initialState
      }
    }

    case SET_HEADER_TYPE: {
      const { value } = action
      return {
        ...state,
        headerType:value,
      }
    }
    case SET_FILE_DATA: {
      const { fileData } = action
      return {
        ...state,
        currentDATA:fileData,
      }
    }
    case SET_ERROR: {
      const { isError,text} = action.payload
      return {
        ...state,
        error: {hasError:isError,text}
      }
    }


    default:
      return state
  }
}
