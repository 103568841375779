import { TypeNotificationNum, TypeNotificationTitle } from '../types'
import moment from 'moment-timezone'

export const showTypeTitle = (type: number) => {
  if (type === TypeNotificationNum.TYPE_APPOINTMENT)
    return TypeNotificationTitle.TYPE_APPOINTMENT
  if (type === TypeNotificationNum.TYPE_PROPOSAL)
    return TypeNotificationTitle.TYPE_PROPOSAL
  if (type === TypeNotificationNum.TYPE_GENERAL)
    return TypeNotificationTitle.TYPE_GENERAL
  if (type === TypeNotificationNum.TYPE_WORK_ORDER)
    return TypeNotificationTitle.TYPE_WORK_ORDER
}

export const decimalToTime = (num: number) => {
  const decimalToTime = moment.duration(num, 'hour')
  return { hour: decimalToTime.hours(), minutes: decimalToTime.minutes() }
}
export const timeToDecimals = (hour: number, minutes: number) => {
  const hourForTransform = hour ? hour : 0
  const minutesForTransform = minutes ? minutes : 0

  const timeStr = `${hourForTransform}:${minutesForTransform}`

  const decimalToTime = moment.duration(timeStr).asHours()
  return +decimalToTime.toFixed(2)
}

export const parsePreviewEmail = (str, companyName) => {
  let newMessage = str
  const obj = {
    '[date]': moment().format('MM/DD/YYYY'),
    '[appointment_date]': moment().format('MM/DD/YYYY'),
    '[company_name]': `${companyName}`,
    '[link_to_project]': `<span style='color: #0000ee;'>link</span>`,
    '[tech_name]': 'John Doe',
    '[project_id]': 'XXXXXXXXXXX',
    '[ProjectHeader (Estimate, Invoice, etc.)]': 'Estimate',
    '[ProposalHeader(Estimate || Invoice || Receipt)]': 'Estimate',
    '[company_email]': 'example@email.com',
    '[company_phone_number]': '+1 (123) 456-7890',
    '[tech_phone_number]': '+1 (123) 456-7890',
    '[tech_email]': 'example@email.com',
    '[user_name]': 'John Doe',
    '[user_email]': 'example@email.com',
    '[user_phone]': '+1 (123) 456-7890',
    '[promised_arrival_time]': '30min.',
  }

  Object.keys(obj).forEach(key => {
    if (newMessage?.includes(key)) {
      newMessage = newMessage.replaceAll(key, obj[key])
    }
  })
  return newMessage
}
