import React from 'react'

const SVG = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect opacity='0.15' width='40' height='40' rx='4' fill='#F7F7F7' />
    <rect x='10' y='13' width='20' height='2' fill='white' />
    <rect x='10' y='19' width='20' height='2' fill='white' />
    <rect x='10' y='25' width='20' height='2' fill='white' />
  </svg>
)

export default SVG
