import * as types from '../../../types'
export const wasEditField = bool => {
  return {
    type: types.modals.WAS_EDIT_FIELD,
    payload: bool,
  }
}

export const isCloseModal = bool => {
  return {
    type: types.modals.IS_CLOSE_MODAL,
    payload: bool,
  }
}
