import React from 'react'
import styled from 'styled-components'
import { Tooltip, Popconfirm } from 'antd'
import { ExitToApp } from '@material-ui/icons'

export const IconWrapper = styled.div`
  & svg {
    cursor: pointer;
    transition: all 0.2s;
    fill: ${props => (props['aria-disabled'] ? 'grey' : '')};
  }
  &:hover svg {
    fill: ${props => (props['aria-disabled'] ? '' : '#4c53ef')};
    transition: all 0.2s;
  }
`
interface IProp {
  button?: Node
  loading: boolean
  limit?: number
  disabled?: boolean
  currentCount: number | undefined
  handleExportTable: () => void
}

const defautCountItems = 10000
const defaultLimit = 10001

export const TableExport = ({
  button,
  loading,
  disabled,
  limit = defaultLimit,
  currentCount = defautCountItems,
  handleExportTable,
}: IProp) => {
  const canToExport = currentCount <= limit && currentCount !== 0
  return (
    <IconWrapper aria-disabled={disabled || !currentCount || !canToExport}>
      <Tooltip
        placement='left'
        title={
          !currentCount
            ? 'Select items for export'
            : canToExport
            ? 'Export table'
            : `Export limit - up to ${limit}  items. Selected quantity of items is ${currentCount}. Reduce the number of entries`
        }
      >
        {loading ? (
          <span
            className='spinner-border spinner-border-sm'
            role='status'
            aria-hidden='true'
          />
        ) : (
          <Popconfirm
            onConfirm={handleExportTable}
            okText='Yes'
            cancelText='No'
            placement='bottomRight'
            disabled={!canToExport || disabled}
            title='Do you want to export table?'
          >
            {button || <ExitToApp />}
          </Popconfirm>
        )}
      </Tooltip>
    </IconWrapper>
  )
}
