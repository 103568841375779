import styled from 'styled-components'

export const Label = styled.label`
  font-family: sans-serif;
  font-size: 12px;
  margin-bottom: 0;
`

export const Input = styled.input<{ error: boolean }>`
  height: 35px;
  font-size: 14px;
  outline: none;
  border: 1px solid;
  border-color: ${props => (props.error ? 'red' : 'transparent')};
  padding: 0px 20px;
  width: 100%;
  color: #000000b3;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
`

export const Wrapper = styled.div`
  margin-bottom: 10px;
  width: 100%;

  span {
    font-size: 0.6rem;
    margin-left: 20px;
    color: red;
  }
`
