export const PROPOSAL_CORE_SET_DATA_FORM = 'PROPOSAL_SET_DATA_FORM'
export const PROPOSAL_CORE_SET_ITEM = 'PROPOSAL_SET_ITEM'
export const UPDATE_CREATE_MODAL = 'UPDATE_CREATE_MODAL'

export const SET_PROPOSAL_TABLE = 'SET_PROPOSAL_TABLE'
export const DELETE_PROPOSAL = 'DELETE_PROPOSAL'

export const PROPOSAL_SET_TEMPLATES = 'PROPOSAL_SET_TEMPLATES'
export const PROPOSAL_SET_TEMPLATE = 'PROPOSAL_SET_TEMPLATE'
export const PROPOSAL_DELETE_TEMPLATE = 'PROPOSAL_DELETE_TEMPLATE'
