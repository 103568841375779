import { forwardRef, Ref } from 'react'
import { Autocomplete, AutocompleteProps } from '@material-ui/lab'
import { makeStyles, createStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import InputField from '../Input'

const useStyles = makeStyles(() =>
  createStyles({
    listbox: {
      padding: 0,
    },
    option: {
      display: 'flex',
      flexWrap: 'nowrap',
      '&>span': {
        flex: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
      },
      // Hover
      '&[data-focus="true"]': {
        backgroundColor: '#edeef5',
        borderColor: 'transparent',
      },
      // Selected
      '&[aria-selected="true"]': {
        backgroundColor: '#E6E8FD',
        borderColor: 'transparent',
      },
    },
  }),
)

type Option = number | string

type Props<T extends Option> = AutocompleteProps<T, true, true, false> & {
  id: string
  name: string
  label: string
  filterActive: boolean
  error?: { [key: string]: string }
  checkIsChoosen: (value: T) => boolean
}

export const CustomMultyAutocomplete = forwardRef(
  <T extends Option>(
    {
      getOptionLabel,
      checkIsChoosen,
      inputValue = '',
      name = '',
      id = '',
      label = '',
      error,
      customOnClose,
      ...rest
    }: Props<T>,
    ref: Ref<HTMLDivElement>,
  ) => {
    const cs = useStyles()
    return (
      <Autocomplete
        multiple
        disableCloseOnSelect
        disableClearable
        onClose={customOnClose}
        renderTags={() => null}
        classes={{
          option: cs.option,
          listbox: cs.listbox,
        }}
        blurOnSelect={false}
        filterOptions={x => x}
        // getOptionLabel={option => {
        //   const optionText = getOptionLabel(option)
        //   return optionText ? optionText : ''
        // }}
        renderOption={option => {
          const optionText = getOptionLabel(option)
          const optionJSX = (
            <>
              <span>{optionText ? optionText : ''}</span>
              <CloseIcon
                style={{
                  visibility: checkIsChoosen(option) ? 'visible' : 'hidden',
                  width: 14,
                  height: 14,
                  color: '#4C53EF',
                  marginLeft: '10px',
                }}
              />
            </>
          )
          return optionJSX
        }}
        renderInput={params => (
          <InputField
            {...params}
            size='small'
            name={name}
            id={id}
            label={label}
            ref={ref}
            fullWidth
            error={error}
            // helperText={error}
            inputProps={{
              ...params?.inputProps,
              value: inputValue,
              autoComplete: 'new-password',
            }}
          />
        )}
        {...rest}
      />
    )
  },
)

CustomMultyAutocomplete.displayName = 'CustomMultyAutocomplete'
