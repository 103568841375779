import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { DatePicker } from 'antd'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { useSelector } from 'react-redux'
import './CustomDatePicker.scss'
import { seTimeRangeAction } from '../../../../../../store/Orcatec/actions/timecards'

moment.locale('us', {
  week: {
    dow: 1,
  },
})

// const startDayOfWeek = 3
// const startDate = moment().day(startDayOfWeek)
// .format('YYYY-MM-DD')

const timePatternNames = ['Current Week', 'Last Week', 'Today', 'Yesterday', 'Last 30 days', 'This Month']
// const timePatterns = [
//   [
//     startDate,
//     moment(startDate).add(6, 'd'),
//     // .format('YYYY-MM-DD'),
//   ],
//   [
//     moment(startDate).subtract(1, 'weeks'),
//     // .startOf('isoWeek'),
//     moment(startDate)
//       .subtract(1, 'weeks')
//       .add(6, 'd'),
//   ],
//   [moment(), moment()],
//   [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
//   [moment().subtract(30, 'days'), moment()],
//   [moment().startOf('month'), moment().endOf('month')],
// ]

const CustomDatePicker = ({ maxDate, timeState, setTimeState }) => {
  const companyRegistrationDate = useSelector(state => state.orcatec.company.created_at)
  const [nameOfDateRange, setNameOfDateRange] = useState('Current Week')

  const startDayOfWeek = useSelector(state => state.orcatec.company.start_day_of_week ?? 0)

  useEffect(() => {
    let isNotCustom = null

    timePatterns.forEach((pattern, idx) => {
      if (
        moment(timeState.startDate).isSame(pattern[0], 'date') &&
        moment(timeState.endDate).isSame(pattern[1], 'date')
      ) {
        setNameOfDateRange(timePatternNames[idx])
        isNotCustom = true
      }
    })

    if (!isNotCustom) {
      setNameOfDateRange(`${timeState.startDate} ~ ${timeState.endDate}`)
    }
  }, [timeState])

  const startDate = moment().day(startDayOfWeek)

  const timePatterns = [
    [
      startDate,
      moment(startDate).add(6, 'd'),
      // .format('YYYY-MM-DD'),
    ],
    [
      moment(startDate).subtract(1, 'weeks'),
      // .startOf('isoWeek'),
      moment(startDate)
        .subtract(1, 'weeks')
        .add(6, 'd'),
    ],
    [moment(), moment()],
    [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    [moment().subtract(30, 'days'), moment()],
    [moment().startOf('month'), moment().endOf('month')],
  ]

  const handleDateChange = dates => {
    setTimeState({
      startDate: dates[0].format('YYYY-MM-DD'),
      endDate: dates[1].format('YYYY-MM-DD'),
    })
  }

  function onChange(dates) {
    handleDateChange(dates)
    seTimeRangeAction(dates)
  }

  const disabledDate = current => {
    if (
      moment(current).isAfter(moment(maxDate)) ||
      moment(current).isBefore(moment(companyRegistrationDate, 'YYYY-MM-DD hh:mm:ss').startOf('day'))
    ) {
      return current
    } else {
      return null
    }
  }

  const buttonsFooter = () =>
    timePatterns.map((cb, idx) => {
      return (
        <span
          key={idx}
          className={`ant-tag ant-tag-${nameOfDateRange === timePatternNames[idx] ? 'blue' : ''}`}
          onClick={() => {
            handleDateChange(cb)
          }}
        >
          {timePatternNames[idx]}
        </span>
      )
    })

  return (
    <div
      className={`custom-date-picker ${
        !timePatternNames.includes(nameOfDateRange) ? 'custom-date-picker--custom-date' : ''
      }`}
    >
      <div className='view-main-title'>
        <span>{nameOfDateRange}</span>
        <KeyboardArrowDown />
      </div>
      <DatePicker.RangePicker
        renderExtraFooter={buttonsFooter}
        className='custom-date-picker-color'
        onChange={onChange}
        // size='small'
        value={[moment(timeState.startDate), moment(timeState.endDate)]}
        defaultValue={[moment(timeState.startDate), moment(timeState.endDate)]}
        disabledDate={disabledDate}
        style={{ top: '-1px', left: '1px' }}
        allowClear={false}
      />
    </div>
  )
}

export default CustomDatePicker
