import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { notification } from 'antd'
import { useSelector } from 'react-redux'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { openNotificationWithIcon } from '../../../../helpers/notifications/openNotificationWithIcon'
// Components
import PageLayout from '../Layouts/PageLayout'
import ClientForm from '../InstantAppointment/components/ClientForm'
import PublicAppointmentsAddressForm from './Сomponents/PublicAppointmentsAddressForm'
import MainButton from '../components/buttons/MainButton'
import InputField from '../components/Input'
// Api
import {
  checkCompanyHash,
  createPostAppointment,
} from '../../../../api/PublicAppointment'
import { AppStateType } from '../../../../store'
import { getAddressWithUnit } from '../../../../helpers/getAddressWithUnit'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'
import { Row } from './PublicAppointment.styles'
import CustomTimePicker from '../components/CustomTimePicker'
import './PublicAppointment.scss'

type PublicAppointmentProps = {
  viewMode: boolean
}

const initialState = {
  address: '',
  type: 1,
  unit: '',
  postcode: '',
  city: '',
  state: '',
  lat: '',
  lng: '',
  full_address: '',
}

interface ITimeValues {
  start_date: moment.Moment | string | null
  time_start: moment.Moment | undefined | null
  time_end: moment.Moment | undefined | null
}

type Errors = {
  time_start: string | undefined
  time_end: string | undefined
}

const PublicAppointment: React.FC<PublicAppointmentProps> = ({ viewMode }) => {
  const companyHash = window.location.search.substring(1)

  const stateCompany = useSelector(
    (state: AppStateType) => state.orcatec?.company,
  )

  const [loading, setLoading] = useState(true)
  const [companyStatus, setCompanyStatus] = useState(true)
  const [success, setSuccess] = useState(false)
  const [company, setCompany] = useState({ company_name: '' })
  const [companyLogo, setCompanyLogo] = useState(
    `${process.env.REACT_APP_API_MEDIA}`,
  )
  const [chosenAddress, setChosenAddress] = useState({
    address: '',
    chosen: false,
  })
  const [customFields, setCustomFields] = useState({ source: '', notes: '' })
  const [
    publicAppointmentAddressValue,
    setPublicAppointmentAddressValue,
  ] = useState('')
  const [showManualInput, setShowManualInput] = useState(false)
  const [timeValues, setTimeValues] = useState<ITimeValues>({
    start_date: null,
    time_start: null,
    time_end: null,
  })
  const [errors, setErrors] = useState<Errors>({
    time_start: undefined,
    time_end: undefined,
  })
  const [client, setClient] = useState({
    name: '',
    phone: '',
    email: '',
  })

  const [property, setProperty] = useState(initialState)

  const handleChangeClientFields: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target
    setClient({
      ...client,
      [name]: value,
    })
  }

  const handleChangeAddressFields: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target

    if (name === 'postcode' && (isNaN(Number(value)) || value.length >= 6)) {
      return
    }

    setProperty({
      ...property,
      [name]: value,
    })
  }

  const handleChooseAddress = (chosenAddress: {
    address: string
    postcode: string
    city: string
    state: string
    lat: string
    lng: string
  }) => {
    setProperty(prev => ({
      ...prev,
      address: chosenAddress.address,
      postcode: chosenAddress.postcode,
      city: chosenAddress.city,
      state: chosenAddress.state,
      lat: chosenAddress.lat,
      lng: chosenAddress.lng,
      full_address: chosenAddress.address,
    }))

    setChosenAddress({ address: chosenAddress.address, chosen: true })
  }

  const handleTypeOrSourceChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target

    setCustomFields(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmitForm = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (client.phone.length <= 13) {
      return notification.error({ message: 'invalid phone number' })
    }

    const data = {
      client: {
        name: client.name,
        phone: client.phone,
        email: client.email,
      },
      property: chosenAddress.chosen
        ? property
        : {
            ...property,
            address: publicAppointmentAddressValue,
            full_address: `${getAddressWithUnit({
              ...property,
              address: property.address
                ? property.address
                : publicAppointmentAddressValue,
            })}, ${property.postcode}`,
          },
      appointment: {
        ...customFields,
        date: timeValues.start_date,
        time_start: timeValues.time_start
          ? moment(timeValues.time_start).format('HH:mm ')
          : null,
        time_end: timeValues.time_end
          ? moment(timeValues.time_end).format('HH:mm ')
          : null,
      },
    }
    createPostAppointment(data, companyHash)
      .then(() => setSuccess(true))
      .catch(e => {
        const errors = e.response.data.errors || {}
        const firstErrorKey = Object.keys(errors)?.[0]
        const splitErrorKey = firstErrorKey?.split('.')?.[1]
        const firstErrorValue = errors[firstErrorKey] || ''

        if (splitErrorKey === 'time_end') {
          setErrors(prev => ({ ...prev, time_end: firstErrorValue }))
        } else if (splitErrorKey === 'time_start') {
          setErrors(prev => ({ ...prev, time_start: firstErrorValue }))
        }

        notification.error({
          message:
            e.response?.data?.error?.message || e.response?.data?.message,
        })
      })
  }

  const heandleManualInput = () => {
    if (!showManualInput) {
      setProperty(initialState)
    }
    setProperty(prev => ({ ...prev, address: '' }))
    setShowManualInput(prev => !prev)
  }

  useEffect(() => {
    if (!viewMode) {
      setLoading(true)
      checkCompanyHash(companyHash)
        .then(res => {
          setCompanyStatus(res?.status)
          setCompany(res?.company)
          if (res.company?.logo)
            setCompanyLogo(prev => `${prev + res?.company?.logo}`)
        })
        .catch(({ response }) => {
          openNotificationWithIcon('error', {
            message: 'Error:',
            description: `${response?.data?.message}`,
          })
          setCompanyStatus(false)
        })
        .finally(() => setLoading(false))
    } else {
      setCompanyLogo(prev => `${prev + stateCompany.logo}`)
      setCompany(stateCompany)
    }
  }, [])

  useEffect(() => {
    viewMode && setLoading(false)
  }, [])

  useEffect(() => {
    if (timeValues.time_start && !timeValues.time_end) {
      setErrors({ time_end: 'Promised time is required' })
    } else if (!timeValues.time_start && timeValues.time_end) {
      setErrors({ time_start: 'Promised time is required' })
    } else if (timeValues.time_start && timeValues.time_end) {
      setErrors({ time_end: undefined, time_start: undefined })
    }
  }, [timeValues])

  const handleChangeStartDate = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined,
  ) => {
    setTimeValues(prev => ({
      ...prev,
      start_date: moment(value).format('YYYY-MM-DD'),
    }))
  }

  const handleChangeTime = (time: moment.Moment | null, type: string) => {
    setTimeValues(prev => ({ ...prev, [type]: time }))
  }

  return (
    <PageLayout
      fullsize={undefined}
      customHeader={undefined}
      actions={undefined}
      preloader={undefined}
    >
      <div className={'public-appointment-wrapper'}>
        <h1 className={'title'}>Contact Us</h1>
        <img className={'company-logo'} src={companyLogo} alt='Company Logo' />
        <h3 className={'company-sub-title'}>{company?.company_name}</h3>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {!success && companyStatus && (
              <div className='public-appointment-container'>
                {viewMode && <div className='disable' />}
                <form onSubmit={handleSubmitForm}>
                  <div className='block contact-block'>
                    <ClientForm
                      type={client.type}
                      client={client}
                      onChange={handleChangeClientFields}
                      publicAppointmentView
                    />
                  </div>
                  <Row className='public-appointment__inputs-block'>
                    <KeyboardDatePicker
                      clearable
                      autoOk
                      value={timeValues.start_date}
                      label='Preferred appointment date'
                      placeholder='Select date start'
                      inputVariant='outlined'
                      format='MM/DD/YYYY'
                      size='small'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChangeStartDate}
                      // required
                      // error={!!error?.start_date}
                      // helperText={error?.start_date}
                      disablePast
                      cancelLabel=''
                      okLabel=''
                    />
                    {/* <TimePicker
                      label='From'
                      value={timeValues.time_start}
                      onSelect={time => handleChangeTime(time, 'time_start')}
                      minuteStep={15}
                      className='time-start'
                      error={errors.time_start}
                    />
                    <TimePicker
                      label='To'
                      className='time-end'
                      value={timeValues.time_end}
                      minuteStep={15}
                      onSelect={time => handleChangeTime(time, 'time_end')}
                      error={errors.time_end}
                    /> */}
                    <CustomTimePicker
                      label='Preferred time start'
                      name='time_start'
                      onChange={(value, valueNum, name) =>
                        handleChangeTime(moment(value, 'HH:mm '), name)
                      }
                      value={
                        timeValues.time_start
                          ? moment(timeValues.time_start).format('HH:mm')
                          : null
                      }
                      endWorkTime={23}
                      startWorkTime={0}
                      error={errors?.time_start}
                      placeholder='Select time'
                      withMinute={true}
                    />

                    <CustomTimePicker
                      label='Preferred time end'
                      name='time_end'
                      onChange={(value, valueNum, name) =>
                        handleChangeTime(moment(value, 'HH:mm '), name)
                      }
                      value={
                        timeValues.time_end
                          ? moment(timeValues.time_end).format('HH:mm')
                          : null
                      }
                      endWorkTime={23}
                      startWorkTime={0}
                      error={errors?.time_end}
                      // required={!!appointment.assign_to_matrix}
                      placeholder='Select time'
                      withMinute={true}
                    />
                  </Row>
                  <div className='block address-block'>
                    <PublicAppointmentsAddressForm
                      companyHash={companyHash}
                      publicAppointmentView
                      type={client.type}
                      property={property}
                      onChange={handleChangeAddressFields}
                      onChooseAddress={handleChooseAddress}
                      chosenAddress={chosenAddress}
                      setChosenAddress={setChosenAddress}
                      setPublicAppointmentAddressValue={
                        setPublicAppointmentAddressValue
                      }
                      toggleManualInput={heandleManualInput}
                      showManualInput={showManualInput}
                    />
                  </div>
                  <div className='block'>
                    <p className='block-input_label'>
                      How Did You Find Us?<sup>*</sup>
                    </p>
                    <InputField
                      required
                      value={customFields.source}
                      name={'source'}
                      onChange={handleTypeOrSourceChange}
                      inputProps={{ maxLength: 255 }}
                    />
                    <p className='block-input_label'>
                      How Can We Help?<sup>*</sup>
                    </p>
                    <InputField
                      required
                      value={customFields.notes}
                      name={'notes'}
                      onChange={handleTypeOrSourceChange}
                    />
                  </div>
                  <div className='block btn-block'>
                    <MainButton
                      title={'Submit'}
                      className={'btn btn-primary submit-btn'}
                      type={'submit'}
                    />
                  </div>
                </form>
              </div>
            )}
            {!companyStatus && (
              <h4 className={'error-letter'}>
                Sorry, something went wrong. Please try again later.
              </h4>
            )}

            {success && (
              <h4 className={'success-letter'}>
                Thank you for your request. We will get back to you shortly!
              </h4>
            )}
          </>
        )}
      </div>
    </PageLayout>
  )
}

export default PublicAppointment
