// import { connect } from 'react-redux'
import TabSection from './TabSection/TabSection'
// import * as actions from 'store/Orcatec/actions'
// import TabSectionTab from './TabSection/TabSectionTab/TabSectionTab'
// import NewSectionTab from './NewSectionTab/NewSectionTab'
// import { priceToState } from '../../../../../../../../helpers/thousandSeparator'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  reorderProjectSections,
  sectionUpdated,
  updateProjectSection,
} from 'features/Project/slices/projectSectionsSlice'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { reorderEntities } from 'features/Project/helpers'
import {
  selectProject,
  selectProjectActiveTab,
  selectProjectSections,
} from 'features/Project/projectSelectors'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { ProjectSection } from 'features/Project/types'

const TabSectionsContainer = ({
  deleteSection,
  copySection,
  isEditContract,
  toggleSaveTemplateSectionModal,
  toggleLoadTemplateSectionModal,
  onSubmit,
  getFileForModalDocViewer,
  showPrice,
}) => {
  const dispatch = useAppDispatch()

  const sections = useAppSelector(selectProjectSections)
  const {
    status: projectStatus,
    signed_tab_id: signedTabId,
    user_id,
  } = useAppSelector(selectProject)
  const me = useAppSelector(selectAuthUser)
  const currentTab = useAppSelector(selectProjectActiveTab)

  const isMyProposal = user_id === me.id

  const onDragEnd = result => {
    if (!result.destination) return

    const newSections = reorderEntities(
      sections,
      result.source.index,
      result.destination.index,
      'position',
    )
    dispatch(reorderProjectSections(newSections))
  }

  const sectionActionGenerator = (idx, key: keyof ProjectSection, section) => {
    if (key === 'title') {
      return newTitle => {
        try {
          dispatch(
            updateProjectSection({
              sectionId: section.id,
              fields: { [key]: newTitle },
            }),
          )
        } catch (error) {
          console.error(error)
        }
      }
    }
    if (key === 'description') {
      return e => {
        const { value } = e.target

        try {
          dispatch(
            updateProjectSection({
              sectionId: section.id,
              fields: { [key]: value },
            }),
          )
        } catch (error) {
          console.error(error)
        }
      }
    }
    if (key === 'installation') {
      return value => {
        try {
          dispatch(
            updateProjectSection({
              sectionId: section.id,
              fields: { [key]: value },
            }),
          )
        } catch (error) {
          console.error(error)
        }
      }
    }
    if (key === 'delete') {
      return () => deleteSection(idx)
    }
    if (key === 'copy') {
      return () => copySection(idx)
    }
  }

  const toogleSectionContent = (sectionId, showSectionContent) => {
    dispatch(
      sectionUpdated({
        sectionId,
        fields: { show: !showSectionContent },
      }),
    )
  }

  const getItemStyle = (_, draggableStyle) => ({
    ...draggableStyle,
  })

  const freezeAll =
    (projectStatus === 2 && !isEditContract) ||
    (projectStatus === 2 && signedTabId && currentTab?.id !== signedTabId) ||
    projectStatus === 3 ||
    projectStatus === 4

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`dnd-proposal-section-droppable-container`}>
          {provided => (
            <div
              className='dnd-items-container'
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ minWidth: 800 }}
            >
              {sections.map((section, idx, self) => (
                <Draggable
                  draggableId={`section-${
                    section.id ? section.id : section.tempId
                  }`}
                  index={idx}
                  key={section.id ? section.id : section.tempId}
                >
                  {(provide, snapshot) => (
                    <div
                      ref={provide.innerRef}
                      {...provide.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provide.draggableProps.style,
                      )}
                    >
                      <TabSection
                        freezeAll={freezeAll}
                        section={section}
                        sectionIdx={idx}
                        onDeleteSection={sectionActionGenerator(
                          idx,
                          'delete',
                          section,
                        )}
                        onCopySection={sectionActionGenerator(
                          idx,
                          'copy',
                          section,
                        )}
                        applySectionNewTitle={sectionActionGenerator(
                          idx,
                          'title',
                          section,
                        )}
                        toggleSectionContent={toogleSectionContent}
                        onChangeSectionDescription={sectionActionGenerator(
                          idx,
                          'description',
                          section,
                        )}
                        onChangeSectionInstallation={sectionActionGenerator(
                          idx,
                          'installation',
                          section,
                        )}
                        onChangeGrossMargin={sectionActionGenerator(
                          idx,
                          'gross_margin',
                          section,
                        )}
                        onBlurSectionInstallation={sectionActionGenerator(
                          idx,
                          'installation',
                          section,
                        )}
                        dragHandleProps={provide.dragHandleProps}
                        isDraggable={self?.length > 1}
                        isLastSection={self?.length - 1 === idx}
                        isJustOneSection={self?.length === 1}
                        isMyProposal={isMyProposal}
                        isEditContract={isEditContract}
                        toggleSaveTemplateSectionModal={
                          toggleSaveTemplateSectionModal
                        }
                        toggleLoadTemplateSectionModal={
                          toggleLoadTemplateSectionModal
                        }
                        onSubmit={onSubmit}
                        getFileForModalDocViewer={getFileForModalDocViewer}
                        showPrice={showPrice}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

export default TabSectionsContainer
