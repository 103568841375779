import { Media } from 'types/Media'
import React from 'react'

export enum ClientType {
  ORGANIZATION = 1,
  INDIVIDUAL,
}

export interface IChatTableItem {
  id: number
  from: string
  to: string
  user: Client
  client: Client
  body: string
  status: number
  created_at: Date
  media: string[]
  count_unread: number
}

export interface Client {
  name: null | string
  phone: string
}

export interface IPhone {
  number: string
  friendly_number: string
  region: Region
  postcode: null | string
  iso_country: ISOCountry
  capabilities: ICapabilities
}

interface ICapabilities {
  voice: boolean
  SMS: boolean
  MMS: boolean
}

enum ISOCountry {
  Us = 'US',
}

enum Region {
  Tx = 'TX',
}

export interface ICountry {
  country: string
  iso_country: string
}

export interface IFormValues {
  fromNumber: string
  toNumber: string
  body: string
  media: Media[]
}

export interface IMessage {
  id: number
  api_id: string
  from: string
  to: string
  message: string
  chats?: number[]
  num_media: number
  created_at: string
  status: number
  isAuthSeen: boolean
  created_by: {
    id: number
    full_name: string
    profile_picture: string
    company_id: number
  } | null
  seen: {
    id: number
    full_name: string
    profile_picture: string
  }[]
  media?: {
    url: string
    contentType: string
  }[]
}

export interface ChatInfo {
  client: null | { type: number }
  client_name: null
  from: string
  from_alias: string
  id: number
  to: string
  // technicians: AttachedTechnicianInterface[]
}

export interface IGetNewNumberParams {
  countryCode: string
  areaCode: string
  phoneType: string
  smsEnabled?: boolean
  mmsEnabled?: boolean
  voiceEnabled?: boolean
  pageIndex: number
}

export interface Links {
  first: string
  last: string
  prev: null
  next: null
}

export interface MessagesPagination {
  current_page: number
  from: number
  last_page: number
  path: string
  per_page: number
  to: number
  total: number
}

export interface Meta {
  current_page: number
  from: number
  last_page: number
  path: string
  per_page: number
  to: number
  total: number
}

export interface ChangeStatusEvent {
  id: number
  status: number
  chats: number[]
}

export interface UserInterface {
  id: number
  name: string
  title: string
}

export interface ChatHeaderProps {
  chatInfo: ChatInfo
  chatID: number
  isNewChatView: boolean
  formValues: { fromNumber: string; toNumber: string }
  handleChange: (value: React.ChangeEvent<HTMLInputElement>) => void
  attachedTechnicians: number[]
  importedPhones: []
  setImportedPhones: () => void
  isConversationStarted: boolean
}

export interface IDeleteMessageEvent {
  chat_id: number
  deleted_message: DeletedMessage
  message: Message
  socket: null
}

export interface DeletedMessage {
  id: number
  body: string
  media: any[]
}

export interface Message {
  id: number
  api_id: string
  chats: number[]
  from: string
  to: string
  message: string
  media: any[]
  created_at: Date
  status: number
  created_by: CreatedBy
  seen: CreatedBy[]
  isAuthSeen: boolean
}

export interface CreatedBy {
  id: number
  full_name: string
  profile_picture: string
  company_id: number
}

export interface IExistingPhone {
  id: number
  api_id: string
  number: string
  alias: string
  users: IAttachedUsers[]
  checked?: boolean // old logic, need to remove
}

export interface IAttachedUsers {
  id: number
  full_name: string
  profile_picture: string
  company_id: number
}

export interface ITechnician {
  id: number
  name: string
  title: string
}

export interface RelatedClientInterface {
  name: string
  entity_id: number
  type: number
  notes: string
  is_primary: boolean
}

export interface BalanceInterface {
  balance: string
  currency: string
}
export enum FiltersEnum {
  SORT_FIELD = 'sort_field',
  ORDER = 'order',
}
export enum FiltersEnumValues {
  CREATED_AT = 'created_at',
  UNREAD_FIRST = 'unread_first',
}

export interface ChatFilters {
  order: 'asc' | 'desc' | null
  sort_field: FiltersEnumValues | null
}

export enum ChatFilterLSName {
  FILTER_NAME_IN_LS = 'chat-filter',
}
