import { Column } from 'features/Dispatch/types'
// import { ITechnician } from 'types/Appointment'

export const reorderTechs = (list: Column[], startIndex: number, endIndex: number): Column[] => {
  const result = Array.from(list)?.sort((a, b) => a.sort_order - b.sort_order)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  const newList = result.map((item, index) => ({
    ...item,
    sort_order: index,
  }))

  return newList
}
