import { /* Tooltip as AntdTooltip, */ Modal } from 'antd'
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import styled from 'styled-components'

import ClientForm from 'containers/MainContent/Orcatec/Clients/components/Form/components/Form/index.js'
import PropertyForm from 'containers/MainContent/Orcatec/Properties/components/PropertyForm'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'

import { useDispatch } from 'react-redux'

/* import {
  getProjectContacts,
  addContactToProject,
  attachChildToProject,
  detachContactFromProject,
  toggleContactSection,
  onChangeSelectedItem,
  updateProjectContactRelation,
  addChildContactToProject,
  invalidateParentTree,
  selectProjectModalOpen,
  // selectIsProjectHasContacts,
  setItemInProcess,
  onChangeSelectedParentItem,
  selectParentContact,
  getRelationContactToProject,
  // selectContactStatus,
} from '../slices/projectContactsSlice' */

import { useAppSelector } from 'store/Orcatec/hooks'
// import { EntityType, IItem } from '../types'

// import { Table } from './components/Table/Table'

import { ClientType } from 'types/Client'
// import { Loader } from './components/Loader/Loader'

import { useDebounce } from 'containers/MainContent/Orcatec/components/SearchHook'

// import { Search } from './components/Search/Search'

// import { BREAK_POINT } from './constants'
import { CloseOutlined } from '@ant-design/icons'
import {
  addChildContactToProjectWO,
  addContactToProjectWO,
  attachChildToProjectWO,
  detachContactFromProjectWO,
  getProjectWOContacts,
  getRelationContactToProjectWorkOrder,
  invalidateWOParentTree,
  onChangeSelectedItem,
  onChangeSelectedParentItem,
  selectParentWOContact,
  selectProjectWOModalOpen,
  setItemInProcess,
  toggleContactSection,
  updateProjectWOContactRelation,
} from 'features/Project/slices/projectWorkOrderContactsSlice'
import { EntityType, IItem } from 'features/Project/types'
import { Loader } from 'features/Project/Contacts/components/Loader/Loader'
import { Search } from 'features/Project/Contacts/components/Search/Search'
import { BREAK_POINT } from 'features/Project/Contacts/constants'
import { Table } from './Table/Table'

interface IProps {
  projectWOId: number
}

export interface TreeHandler {
  onAttach: (entity: EntityType) => void
  onManage: () => void
}

const WOContactsTree = forwardRef<TreeHandler, IProps>(
  ({ projectWOId }, ref) => {
    const dispatch = useDispatch()

    // const freezed = useIsFreezed(isEditContract)

    const isModalOpen = useAppSelector(selectProjectWOModalOpen)

    useImperativeHandle(ref, () => ({
      onAttach(entity: EntityType) {
        handleOpenForm({ entity_type: entity })
      },
      onManage() {
        toggleSection()
      },
    }))

    const [clientModal, setClientModal] = useState(false)
    const [propertyModal, setPropertyModal] = useState(false)
    const [currentPropertyId, setCurrentPropertyId] = useState<number>(0)
    const [currentClientId, setCurrentClientId] = useState<number>(0)
    const [currentProperty, setCurrentProperty] = useState<IItem | null>(null)
    const [currentClient, setCurrentClient] = useState<IItem | null>(null)
    const [search, setSearch] = useState('')

    const [detached_contacts_ids, setDetached_contacts_ids] = useState([])
    const [detached_properties_ids, setDetached_properties_ids] = useState([])

    const searchValue = useDebounce(search, 400)

    const handleOpenForm = (item: IItem | { entity_type: EntityType }) => {
      if (item?.entity_type === EntityType.PROPERTY) {
        setCurrentPropertyId(item?.id)
        setCurrentProperty(item)
        setPropertyModal(true)
      }
      if (item?.entity_type === EntityType.CLIENT) {
        localStorage.setItem('contacts-type', ClientType.CONTACT)
        setCurrentClientId(item?.id)
        setCurrentClient(item)
        setClientModal(true)
      }
    }

    const handleCloseForm = () => {
      const parent_entity_type =
        currentClient?.entity_parent_type || currentProperty?.entity_parent_type
      const parent_entity_id =
        currentClient?.entity_id || currentProperty?.entity_id

      if (parent_entity_id && parent_entity_type) {
        dispatch(
          invalidateWOParentTree({ parent_entity_id, parent_entity_type }),
        )

        dispatch(
          getRelationContactToProjectWorkOrder({ projectId: projectWOId }),
        )
      }

      if (detached_contacts_ids?.length || detached_properties_ids?.length) {
        dispatch(getProjectWOContacts(projectWOId))
        setDetached_contacts_ids([])
        setDetached_properties_ids([])
      }

      setPropertyModal(false)
      setClientModal(false)
      setCurrentPropertyId(null)
      setCurrentProperty(null)
      setCurrentClientId(null)
      setCurrentClient(null)
    }

    const handleAttachClient = ({ id, type }: { id: number; type: number }) => {
      if (currentClient?.is_child_modal) {
        dispatch(
          addChildContactToProjectWO({
            ...currentClient,
            idToAttach: id,
            typeToAttach: type,
          }),
        )
      } else {
        dispatch(
          addContactToProjectWO({
            projectId: projectWOId,
            data: { entity_id: id, entity_type: EntityType.CLIENT },
          }),
        )
      }

      handleCloseForm()
    }

    const handleAttachProperty = ({ id: propertyId }: { id: number }) => {
      if (currentProperty?.is_child_modal) {
        dispatch(
          addChildContactToProjectWO({
            ...currentProperty,
            idToAttach: propertyId,
          }),
        )
      } else {
        dispatch(
          addContactToProjectWO({
            projectId: projectWOId,
            data: { entity_id: propertyId, entity_type: EntityType.PROPERTY },
          }),
        )
      }

      handleCloseForm()
    }

    const toggleSection = () => {
      dispatch(toggleContactSection())
    }

    const handleSelectItem = (checked: boolean, item: IItem) => {
      dispatch(setItemInProcess(item))
      if (!item.checked) {
        dispatch(
          attachChildToProjectWO({
            projectId: projectWOId,
            item,
          }),
        )
      } else {
        handleDetachContact(item)
      }

      dispatch(
        onChangeSelectedItem({
          checked,
          item,
        }),
      )
    }

    const handleSelectParentItem = (checked: boolean, item: IItem) => {
      dispatch(setItemInProcess(item))
      dispatch(onChangeSelectedParentItem({ item, checked }))
      dispatch(selectParentWOContact({ item, checked, projectId: projectWOId }))
    }

    const handleDetachContact = (item: IItem) => {
      dispatch(detachContactFromProjectWO({ projectId: projectWOId, item }))
    }

    const handleUpdateProjectRelation = ({
      item,
      proposal_relation,
    }: {
      item: IItem
      proposal_relation: string
    }) => {
      const data = {
        proposal_relation_id: item.proposal_relation_id,
        proposal_relation_type: item.entity_type,
        proposal_relation,
      }

      dispatch(setItemInProcess(item))
      dispatch(
        updateProjectWOContactRelation({
          projectId: projectWOId,
          data,
        }),
      )
    }

    useEffect(() => {
      const fetchProjectContacts = async () => {
        try {
          dispatch(getProjectWOContacts(projectWOId))
        } catch (error) {
          console.error(error)
        }
      }
      if (projectWOId) fetchProjectContacts()
    }, [projectWOId])

    return (
      <>
        {clientModal && (
          <Modal
            open={clientModal}
            onCancel={handleCloseForm}
            title={<h4>{currentClientId ? 'Edit contact' : 'Add contact'}</h4>}
            className='client-modal'
            footer={null}
            width='auto'
            style={{ top: 50, maxWidth: 1100 }}
            maskClosable={false}
          >
            <ClientForm
              closeForm={handleCloseForm}
              clientId={currentClientId}
              changeModalTitle={true}
              isContact={currentClient?.item?.type !== ClientType.CONTACT}
              attachClientAfterSave={handleAttachClient}
              isProposal={true}
              setDetached_contacts_ids={setDetached_contacts_ids}
              setDetached_properties_ids={setDetached_properties_ids}
              isClient={currentClient?.item?.type === ClientType.ORGANIZATION}
            />
          </Modal>
        )}

        {propertyModal && (
          <Modal
            open={propertyModal}
            onCancel={handleCloseForm}
            title={
              <h4>{currentPropertyId ? 'Edit Property' : 'Add Property'} </h4>
            }
            className='client-modal'
            footer={null}
            width='auto'
            style={{ top: 50, maxWidth: 1100 }}
            maskClosable={false}
          >
            <PropertyForm
              isProposal
              propertyId={currentPropertyId}
              setEntityId={handleAttachProperty}
              onCloseForm={handleCloseForm}
              setDetached_contacts_ids={setDetached_contacts_ids}
              setDetached_properties_ids={setDetached_properties_ids}
              proposalId={projectWOId}
            />
          </Modal>
        )}

        {isModalOpen && (
          <Modal
            open={isModalOpen}
            onCancel={toggleSection}
            destroyOnClose
            className='contacts-modal'
            closeIcon={
              <div style={{ paddingTop: '10px' }}>
                <CloseOutlined style={{ color: 'rgba(53, 57, 69, 1)' }} />
              </div>
            }
            title={
              <ModalHeading>
                <ModalTitleWrapper>
                  <ModalHeadingTitle>Manage Contacts</ModalHeadingTitle>
                  <Loader />
                </ModalTitleWrapper>

                <SearchWrapper>
                  <Search search={search} setSearch={setSearch} />
                </SearchWrapper>
              </ModalHeading>
            }
            centered
            bodyStyle={{ padding: '0px', height: '546px', overflowX: 'scroll' }}
            footer={
              <Controls>
                <MainButton
                  type='custom-secondary'
                  onClick={() =>
                    handleOpenForm({ entity_type: EntityType.PROPERTY })
                  }
                  title='+ Attach Property'
                  style={{
                    height: '40px',
                    width: '140px',
                    borderRadius: '4px',
                  }}
                />
                <MainButton
                  type='custom-secondary'
                  onClick={() =>
                    handleOpenForm({ entity_type: EntityType.CLIENT })
                  }
                  title='+ Attach Contact'
                  style={{
                    height: '40px',
                    width: '140px',
                    borderRadius: '4px',
                  }}
                />

                <MainButton
                  title='Done'
                  style={{
                    marginLeft: 'auto',
                    height: '40px',
                    width: '126px',
                    borderRadius: '4px',
                  }}
                  onClick={toggleSection}
                />
              </Controls>
            }
          >
            <Table
              handleDetachContact={handleDetachContact}
              freezed={false}
              onOpenForm={handleOpenForm}
              handleSelectItem={handleSelectItem}
              handleSelectParentItem={handleSelectParentItem}
              handleUpdateProjectRelation={handleUpdateProjectRelation}
              search={searchValue}
            />
          </Modal>
        )}
      </>
    )
  },
)

export default React.memo(WOContactsTree)

WOContactsTree.displayName = 'WOContactsTree'

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 16px;
  height: 60px;
`

const ModalHeading = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  min-height: 35px;

  @media screen and (min-width: ${BREAK_POINT}px) {
    flex-direction: row;
    align-items: center;
    padding-right: 30px;
  }
`

const ModalTitleWrapper = styled.h3`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  min-height: 40px;
  margin: 0px;
  @media screen and (min-width: ${BREAK_POINT}px) {
    justify-content: space-between;
    padding-right: 20px;
  }
`

const ModalHeadingTitle = styled.h3`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.01em;
  text-align: left;
  margin: 0px;
`

const SearchWrapper = styled.div`
  width: 100%;
  text-align: start;
  @media screen and (min-width: ${BREAK_POINT}px) {
    text-align: end;
  }

  & input {
    height: 26px;
  }
`
