export const handleTableDataChange = (setCurrentTableData, setIsHaveChange) => (
  type,
  idx,
  firstIdx,
  name,
  secondIdx,
  parentName,
  inputValue,
) => {
  setIsHaveChange && setIsHaveChange(true)
  const setCheck = prev => {
    const state = [...prev]
    if (parentName) {
      const target = state[firstIdx][parentName][secondIdx][name][idx].checkbox
      if (target) {
        if (name && !parentName) {
          state[firstIdx][name][idx].proposal_relation = ''
        }
        if (parentName) {
          state[firstIdx][parentName][secondIdx][name][idx].proposal_relation = ''
        }
        if (name === null) {
          state[firstIdx].proposal_relation = ''
        }
      }
      state[firstIdx][parentName][secondIdx][name][idx].checkbox = !target
    } else {
      if (state[firstIdx][name][idx].checkbox) {
        if (name && !parentName) {
          state[firstIdx][name][idx].proposal_relation = ''
        }
        if (parentName) {
          state[firstIdx][parentName][secondIdx][name][idx].proposal_relation = ''
        }
        if (name === null) {
          state[firstIdx].proposal_relation = ''
        }
      }
      state[firstIdx][name][idx].checkbox = !state[firstIdx][name][idx].checkbox
    }

    return state
  }

  const setInput = prev => {
    const state = [...prev]
    if (name && !parentName) {
      state[firstIdx][name][idx].proposal_relation = inputValue
    }
    if (parentName) {
      state[firstIdx][parentName][secondIdx][name][idx].proposal_relation = inputValue
    }
    if (name === null) {
      state[firstIdx].proposal_relation = inputValue
    }
    return state
  }

  if (type === 'check') {
    setCurrentTableData(setCheck)
  }
  if (type === 'input') {
    setCurrentTableData(setInput)
  }
}
