import React, { useState, useEffect } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import ReactInputMask from 'react-input-mask'
import MenuItem from '@material-ui/core/MenuItem'
import { DeleteIcon } from '../../../../../components/Icons/CommonIcons'
import SureModal from '../../../../../components/SureModal'
import { Popconfirm } from 'antd'

const StyledRadio = withStyles({
  root: {
    color: '#191919',
    '&$checked': {
      color: '#191919',
    },
  },
  checked: {},
})(props => <Radio {...props} /* style={{ marginTop: '3px' }} */ />)

const RadioType = ({ value, onCheckPhonePref, index, disabled }) => {
  const [preference, setPreference] = useState(value ? value : '')

  useEffect(() => {
    onCheckPhonePref(index, preference)
  }, [preference])

  const handleClick = e => {
    if (preference === e.target.value) return setPreference('')
    setPreference(e.target.value)
  }

  /* const [checked1, changeChecked1] = useState(false)
  const [checked2, changeChecked2] = useState(false)
  useEffect(() => {
    checked1 && changeChecked2(false)
  }, [checked1])
  useEffect(() => {
    checked2 && changeChecked1(false)
  }, [checked2])
  useEffect(() => {
    value === 'voice' ? changeChecked1(true) : value === 'text' && changeChecked2(true)
  }, []) */
  return (
    <RadioGroup
      aria-label='preference'
      name='preference'
      value={preference}
      /* onChange={handleClick} */ classes={{ root: 'radio-group' }}
    >
      <FormControlLabel
        classes={{ root: 'voice', label: 'label' }}
        value='voice'
        control={
          <StyledRadio
            /* checked={checked1}
            onChange={e => onCheckPhonePref(e, 'voice', index, checked1, checked2)}
            onDoubleClick={e => {
              changeChecked1(false)
              onCheckPhonePref(e, 'voice', index)
            }} */
            onClick={handleClick}
            disabled={disabled}
          />
        }
        // labelPlacement='top'
        label='Voice Preference'
      />
      <FormControlLabel
        classes={{ root: 'text' }}
        value='text'
        control={
          <StyledRadio
            onClick={handleClick}
            disabled={disabled}
            /*  checked={checked2}
           onChange={e => onCheckPhonePref(e, 'text', index, checked1, checked2)}
           onClick={() => changeChecked2(!checked2)}
           onDoubleClick={e => {
             changeChecked2(false)
             onCheckPhonePref(e, 'text', index)
           }} */
          />
        }
        // labelPlacement='top'
        label='Text Preference'
      />
    </RadioGroup>
  )
}

export const PhoneRow = ({
  isOne,
  item,
  index,
  onChange,
  onChangeSelect,
  removeRow,
  onCheckPhonePref,
  refProps,
  validateField,
}) => {
  const [remove, changeRemove] = useState(false)

  //const stylePhone = propertyRelation ? '10px' : '5px'

  const renderPhoneField = () => {
    return (
      <TextField
        error={!!item.error}
        helperText={item.error}
        size='small'
        label='Phone'
        variant='outlined'
        name='phones'
        className='phoneInput'
        maxLength={100}
        ref={refProps}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  }
  return (
    <div className='phoneWrapper'>
      <div className='phone-actions'>
        {/* <button
          disabled={isOne && !item.value.phone}
          className='btn delete'
          // style={{ fontSize: '24px', fontWeight: '800' }}
          onClick={() => changeRemove(true)}
        >
          <DeleteIcon />
        </button> */}

        {/* <SureModal
        modal={remove}
        close={() => {
          changeRemove(false)
        }}
        apply={() => {
          changeRemove(false)
          removeRow()
        }}
        okTitle='Yes'
        cancelTitle='No'
      /> */}

        <Popconfirm
          onConfirm={() => {
            changeRemove(false)
            removeRow()
          }}
          onCancel={() => {
            changeRemove(false)
          }}
          title='Are you sure to delete this phone?'
          disabled={isOne && !item.value.phone}
        >
          <button
            disabled={isOne && !item.value.phone}
            className='btn delete'
            // style={{ fontSize: '24px', fontWeight: '800' }}
            // onClick={() => changeRemove(true)}
          >
            <DeleteIcon />
          </button>
        </Popconfirm>
        {/* {isLast && checkIsPhoneValid === true && item.value.phone && ( */}
      </div>
      <div className='phone__main-group'>
        <ReactInputMask
          mask='(999) 999-9999'
          value={item.value.phone}
          onChange={onChange}
          onBlur={validateField}
          disabled={false}
          maskChar='X'
        >
          {renderPhoneField}
        </ReactInputMask>

        <div className='phoneSelect'>
          <TextField
            size='small'
            variant='outlined'
            name='phone_type'
            label='Phone Type'
            onChange={e => onChangeSelect(e.target.value)}
            value={item.value.type}
            select
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!item.value.phone}
            fullWidth
          >
            <MenuItem className='fontStyle' value='cell'>
              Cell
            </MenuItem>
            <MenuItem className='fontStyle' value='work'>
              Work
            </MenuItem>
            <MenuItem className='fontStyle' value='home'>
              Home
            </MenuItem>
            <MenuItem className='fontStyle' value='other'>
              Other
            </MenuItem>
          </TextField>
        </div>
      </div>
      <RadioType
        index={index}
        onCheckPhonePref={onCheckPhonePref}
        value={item.value.preference}
        disabled={!item.value.phone}
      />
      <SureModal
        modal={remove}
        close={() => {
          changeRemove(false)
        }}
        apply={() => {
          changeRemove(false)
          removeRow()
        }}
        okTitle='Yes'
        cancelTitle='No'
      />
      {/* <Modal show={remove}>
        <Modal.Body>
          <h4>Are you shure?</h4>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-custom-info'
            onClick={() => {
              changeRemove(false)
              removeRow()
            }}
          >
            Yes
          </button>
          <button
            className='btn btn-custom-cancel'
            onClick={() => {
              changeRemove(false)
            }}
          >
            No
          </button>
        </Modal.Footer>
      </Modal> */}
    </div>
  )
}

export default PhoneRow
