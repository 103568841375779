import React, { memo } from 'react'
import { Popover, Tooltip } from 'antd'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'

import { isMobileDevise } from 'helpers/isMobileDevice'
import { getEventStatus } from './helpers'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'

const queueEventStyle = { height: '100%' }
const popoverStyle = { height: '100%' }
const popoveroverlayStyle = { width: '200px' }
const isMobileDevice = isMobileDevise()

const Queue = memo(
  ({
    event,
    isLead,
    getAmericanTime,
    dragEventStart,
    dragEventEnd,
    visible,
    handleVisibleChange,
    onDublicate,
    history,
    index,
    type,
    currentAppointmentId,
    isWorker,
    activeEvent,
    onChangeActiveEvent,
  }) => {
    const goToAppointment = e => {
      if (e.target.classList.contains('btn')) return

      if (isMobileDevice) {
        return handleVisibleChange(!visible.visible, event.id)
      }

      history.push(`/appointment/${event.id}`)
    }

    const onDragStart = e => {
      dragEventStart(e)
      handleVisibleChange(false, event.id)
    }
    const onDragEnd = e => {
      dragEventEnd(e, index, type)
      onChangeActiveEvent('')
    }
    const onVisibleChange = visible => handleVisibleChange(visible, event.id)

    // const handleToggleDnd = () => {
    //   onChangeActiveEvent(event.id)
    //   handleVisibleChange(false, event.id)
    // }

    const dragNDropDenied = isMobileDevice && activeEvent !== event.id

    return (
      <div
        className={`queue-event${
          !!event.preferred_technician && !!event.preferred_technician.name
            ? ' prefered'
            : ''
        }${isLead ? ' lead' : ''}${event.parent_id ? ' recurring' : ''}`}
        key={`event-${event.id}`}
        resourceid={'queue'}
      >
        <Tooltip
          defaultVisible={true}
          title={`${currentAppointmentId === event.id ? 'Current' : ''}`}
        >
          <div onClick={goToAppointment} style={queueEventStyle}>
            <div
              className={`draggable-event ${getEventStatus(event)}`}
              draggable={dragNDropDenied ? false : true}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
            >
              <Popover
                onVisibleChange={onVisibleChange}
                visible={visible}
                event={event}
              >
                <span className='event-postcode'>
                  {event.entity_type === 2 || event.entity_type === 3
                    ? 'Office'
                    : event.postcode}
                </span>
                {event.client_name && (
                  <div className='client-name'>{event.client_name}</div>
                )}
                <div>{event.appointment_type}</div>
                <div>
                  {event.preferred_technician &&
                    event.preferred_technician.name &&
                    event.preferred_technician.name}
                </div>
                <div>
                  {event.time_start === event.time_end
                    ? event.time_start && getAmericanTime(event.time_start)
                    : `${event.time_start &&
                        getAmericanTime(event.time_start)}${event.time_start &&
                        event.time_end &&
                        ' - '} ${event.time_end &&
                        getAmericanTime(event.time_end)}`}
                </div>
              </Popover>
            </div>
          </div>
        </Tooltip>

        {/* {getStatus(event)} */}
      </div>
    )
  },
)

const connectedEvent = withRouter(Queue)
export { connectedEvent as Queue }
