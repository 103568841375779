import { createListenerMiddleware, addListener } from '@reduxjs/toolkit'
import type { TypedStartListening, TypedAddListener } from '@reduxjs/toolkit'
import { AppDispatch, AppStateType } from 'store'



export const listenerMiddleware = createListenerMiddleware()

export type AppStartListening = TypedStartListening<AppStateType, AppDispatch>

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening

export const addAppListener = addListener as TypedAddListener<
AppStateType,
  AppDispatch
>