import { ChangeEvent, CSSProperties } from 'react'
import { Input, Wrapper } from './InputWithLabel.styles'

type Props = React.HTMLProps<HTMLInputElement> & {
  name: string
  label: string
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  style?: CSSProperties
  error: string
}

export const InputWithLabel = ({
  name,
  label,
  style,
  error,
  ...rest
}: Props) => {
  return (
    <Wrapper style={style}>
      <Input
        type='text'
        placeholder={label}
        name={name}
        error={!!error}
        {...rest}
      />
      {error && <span>{error}</span>}
    </Wrapper>
  )
}
