import React, { useState, FC } from 'react'
import { useDispatch } from 'react-redux'
import { Badge, Drawer, Tooltip } from 'antd'
import styled from 'styled-components'
import SubMenu from 'containers/MainContent/Orcatec/components/SubMenu'
import { IncomingCallsTable } from 'features/Calls/components/Tables/IncomingCallsTable'
import { Form } from 'features/Contacts/components/Form/Form'
import {
  selectCurrentContact,
  resetContact,
  selectContactSlice,
  toggleAppointmentFormAction,
} from 'features/Contacts/slice/contactSlice'
import { useAppSelector } from 'store/Orcatec/hooks'
import { IIncomingCall } from 'features/Calls/types'
import { KeyboardBackspace } from '@material-ui/icons'
import {
  OrganizationIcon,
  ClientIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { ClientType } from 'types/Client'
import {
  selectAppointmentSlice,
  onChangeFieldAction,
} from 'features/Appointment/appointmentSlice'
import { postAssignUserToCallRecord } from 'api/Calls'
import { meSelector } from 'store/SuperAdmin/selectors'

interface IProps {
  isOpen: boolean
  toggleDrawer: () => void
  callsList: IIncomingCall[]
  setCallsList: () => void
}
export const CallDrawer: FC<IProps> = ({
  isOpen,
  toggleDrawer,
  callsList,
  setCallsList,
}) => {
  const dispatch = useDispatch()
  const me = useAppSelector(meSelector)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [choosenCall, setChoosenCall] = useState<
    IIncomingCall['clients'] | null
  >(null)
  const currentContact = useAppSelector(selectCurrentContact)
  const { touched } = useAppSelector(selectAppointmentSlice)
  const currentContactSlice = useAppSelector(selectContactSlice)

  const handleChangeTabs = (tabId: number) => setActiveTab(tabId)

  const onTakeCallHandler = (
    call: IIncomingCall['clients'],
    record: IIncomingCall,
  ) => {
    dispatch(resetContact())

    if (Array.isArray(record?.source_id) && record?.source_id?.length) {
      dispatch(
        onChangeFieldAction({
          field: 'appointment_source_id',
          value: record?.source_id?.[0],
        }),
      )
    }
    if (record?.id && me?.id) {
      postAssignUserToCallRecord(record.id, me.id)
    }

    setChoosenCall(call)
    setActiveTab(1)
  }

  const onCloseFormTab = () => {
    dispatch(resetContact())
    setActiveTab(0)
    setChoosenCall(null)
    dispatch(onChangeFieldAction({ field: 'new', value: false }))
  }

  const menuItems = [
    {
      id: 0,
      title: 'Calls',
    },
    {
      id: 1,
      title: `${currentContact?.name || 'Create new contact'}`,
    },
  ]

  const currentTab = () => {
    if (activeTab === 0) {
      return (
        <IncomingCallsTable
          setCallsList={setCallsList}
          onTakeCallHandler={onTakeCallHandler}
          tableData={callsList}
        />
      )
    }
    if (activeTab === 1) {
      return <Form contact={choosenCall} />
    }
  }
  return (
    <Wrapper>
      <Drawer
        className='calls-drawer'
        title={
          <SubMenu
            activeItem={activeTab}
            menuItemsList={menuItems}
            closeIcon={!!currentContact?.id}
            onClose={onCloseFormTab}
            onChange={handleChangeTabs}
          />
        }
        placement='right'
        size='large'
        onClose={toggleDrawer}
        visible={isOpen}
        bodyStyle={{ padding: '10px' }}
        destroyOnClose={false}
        mask={false}
        width={
          currentContactSlice.showAppointmentForm
            ? 560
            : window.innerWidth > 900
            ? 760
            : window.innerWidth - 50
        }
        closeIcon={
          <KeyboardBackspace style={{ transform: 'rotate(180deg)' }} />
        }
      >
        {!isOpen && touched && (
          <LabelWrapper
            isMobile={window.innerWidth < 500}
            onClick={() => {
              toggleDrawer()
              if (touched) dispatch(toggleAppointmentFormAction(true))
            }}
          >
            <Badge
              style={{ top: '-20px', right: '35px' }}
              count={touched ? 'Draft appt.' : null}
            >
              <span>
                {currentContact?.type === ClientType.ORGANIZATION ? (
                  <OrganizationIcon />
                ) : (
                  <ClientIcon />
                )}{' '}
                {currentContact?.name?.length > 7 ? (
                  <Tooltip placement='left' title={currentContact?.name}>
                    {currentContact?.name.slice(0, 5) + '...'}
                  </Tooltip>
                ) : (
                  currentContact?.name
                )}
              </span>
            </Badge>
          </LabelWrapper>
        )}
        {currentTab()}
      </Drawer>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const LabelWrapper = styled.div<{ isMobile: boolean; onClick: () => void }>`
  position: fixed;
  top: ${props => (props.isMobile ? '77px' : '160px')};
  left: -105px;
  height: 40px;
  width: 105px;
  background-color: var(--main-color);
  z-index: 9999999;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px;
  cursor: pointer;
  color: white;
  & svg path {
    stroke: white;
  }
`
