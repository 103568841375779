import * as types from '../../types'
import * as matrix from '../../../../api/settings/Matrix'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { FETCHING_SETTINGS } from 'store/Orcatec/types/matrix/settings'

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  // result.map((item, index) => (item.sort_order = index))
  const lol = result.map((item, index) => ({ ...item, sort_order: index }))

  return lol
}

export const settingsSet = response => {
  return {
    type: types.settings.SETTINGS_FETCHED,
    payload: response,
  }
}

export const updateGlobalSettings = calendar => {
  return {
    type: types.settings.UPDATE_GLOBAL_SETTINGS,
    payload: calendar,
  }
}
export const getRequestSettings = date => dispatch => {
  dispatch({ type: FETCHING_SETTINGS })

  return matrix.getRequestSettings(date).then(response => {
    dispatch(settingsSet(response))
    return response
  })
}

export const updateRequestSettings = (data, confirm) => async dispatch => {
  // dispatch({ type: FETCHING_SETTINGS })

  return await matrix.updateRequestSettings(data, confirm)

  // try {

  //   dispatch(updateSettings(data))
  //   console.log(res)
  // } catch (error) {
  //   console.log(error.response)
  // }

  // return matrix
  //   .updateRequestSettings(data, confirm)
  //   .then(response => {
  //     return response
  //   })
  //   .catch(error => console.log(error.response))
}
export const changeColor = (i, color) => {
  return {
    type: types.settings.LOCAL_UPDATE_SETTINGS_COLOR,
    i,
    color,
  }
}

export const settingsColumnOrderChaned = (columns /* workers, draggableIndex, targetIndex */) => {
  return {
    type: types.settings.GLOBAL_COLUMNS_ORDER_CHANGED,
    payload: columns,
    // workers,
    // draggableIndex,
    // targetIndex,
  }
}

const globalColumnCreated = column => ({
  type: types.settings.GLOBAL_COLUMN_CREATED,
  payload: column,
})

const globalColumnUpdated = column => ({
  type: types.settings.GLOBAL_COLUMN_UPDATED,
  payload: column,
})

const globalColumnDeleted = columnId => ({
  type: types.settings.GLOBAL_COLUMN_DELETED,
  payload: columnId,
})

export const updateColumnsOrderSettings = (columns, source, target) => async dispatch => {
  const sortedTechnitians = reorder(columns, source, target)

  try {
    dispatch(settingsColumnOrderChaned(sortedTechnitians))

    await matrix.updateGlobalColumnsOrder(sortedTechnitians)

    openNotificationWithIcon('success', { message: 'Columns order has been updated' })
  } catch (error) {
    dispatch(settingsColumnOrderChaned(columns))
    openNotificationWithIcon('error', { message: 'Something went wrong' })
  }
}

export const createGlobalColumn = data => async dispatch => {
  try {
    const createdColumn = await matrix.createGlobalColumn(data)

    dispatch(globalColumnCreated(createdColumn))
    return createdColumn
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateGlobalColumn = data => async dispatch => {
  try {
    const updatedColumn = await matrix.updateGlobalColumn(data)

    dispatch(globalColumnUpdated(data))
    return updatedColumn
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteGlobalColumn = (id, confirm) => async dispatch => {
  try {
    const res = await matrix.deleteGlobalColumn(id, { confirm: !!confirm })

    dispatch(globalColumnDeleted(id))
    return res
  } catch (error) {
    return Promise.reject(error)
  }
}
