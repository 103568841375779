import React, { useState, useEffect } from 'react'
import { Checkbox, Row, Col, Divider } from 'antd'
import { TextField, InputAdornment } from '@material-ui/core'
import ModalWrapper from '../../../../containers/MainContent/Orcatec/Proposals/components/Modals/ModalWrapper/ModalWrapper'
import './CardModal.scss'
import PagesRegisterCard from '../../../../containers/MainContent/Orcatec/Register/Card'

const CardModal = props => {
  const [isSaving, setIsSaving] = useState(false)
  useEffect(() => {
    if (props.modal) props.fetchSubscription()
  }, [props.modal])
  const onSubmit = data => {
    if (!!data.token && !!data.expiry) {
      setIsSaving(true)
      return props
        .updateSubscription(data.id, data)
        .then(() => {
          props.apply()
          return 'ok'
        })
        .finally(() => {
          setIsSaving(false)
        })
    }
  }

  const onCancel = () => {
    props.close()
    setIsSaving(false)
  }

  return (
    <>
      <ModalWrapper
        dialogClassName='no_card-modal'
        modal={props.modal}
        close={onCancel}
        hideCancelBtn={true}
        disableCloseСompletely={true}
      >
        <div className='no_card-modal-content'>
          <PagesRegisterCard
            submitCard={onSubmit}
            isCreatingCardProfile={isSaving}
            onCancel={onCancel}
            visible={props.modal}
            cancelBtnText='Cancel'
            hideCancelBtn={true}
          />
        </div>
      </ModalWrapper>
    </>
  )
}

export default CardModal
