import Time from './components/time'
import Resources from './components/resources'
import { Column, DispatchSettings } from 'features/Dispatch/types'
import './style.scss'
import {
  createGlobalColumn,
  deleteGlobalColumn,
  updateColumnsOrderSettings,
  updateGlobalColumn,
} from 'store/Orcatec/actions/matrix/settings'
import { useAppDispatch } from 'store/Orcatec/hooks'
import { useEffect, useMemo, useState } from 'react'
import ColumnSettings from 'features/Dispatch/Calendar/components/ColumnSettings'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { Button, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

export enum MatrixAddressType {
  'ZIP code' = 1,
  City,
  'City and State',
}
interface IProps {
  columns: Column[]
  settings: DispatchSettings
  // settings: {
  //   handleStateChange: () => void
  //   calendar: ICalendar
  // }
}

export const Calendar = ({ settings, columns }: IProps) => {
  const [columnId, setColumnId] = useState<number | null>(null)
  const [columnCreating, setColumnCreating] = useState(false)
  const [conflictEvents, setConflictEvents] = useState(0)
  const [confirmDelete, setConfirmDelete] = useState(0)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (confirmDelete) {
      dispatch(deleteGlobalColumn(columnId, confirmDelete))
      openNotificationWithIcon('success', {
        message: 'Column has been deleted',
      })

      setColumnId(null)
      setConfirmDelete(0)
    }
  }, [confirmDelete])

  const handleChangeColumnOrder = async (
    columns: Column[],
    draggableIndex: number,
    targetIndex: number,
  ) => {
    dispatch(updateColumnsOrderSettings(columns, draggableIndex, targetIndex))
  }

  const handleColumnSettingsClick = (id: number) => {
    setColumnId(id)
  }

  const handleSaveColumnSettings = async (data: Column) => {
    try {
      const response = await dispatch(updateGlobalColumn(data))

      openNotificationWithIcon('success', {
        message: 'Column has been updated successfully',
      })

      setColumnId(null)

      return response
    } catch (error) {
      openNotificationWithIcon('error', {
        message: error?.response?.data?.message || 'Something went wrong',
      })
    }
  }

  const currentColumn = useMemo(() => {
    if (!columnId) return

    return columns?.find(column => column.id === columnId)
  }, [columnId])

  const handleAddColumn = async () => {
    setColumnCreating(true)

    try {
      await dispatch(
        createGlobalColumn({
          technicians: [],
          title: `Column ${columns.length + 1}`,
          sort_order: columns.length,
          main_tech_id: null,
          background_color: null,
          matrix_id: settings.id,
        }),
      )

      openNotificationWithIcon('success', {
        message: 'Column has been created successfully',
      })
    } catch (error) {
      openNotificationWithIcon('error', {
        message: error.response?.data?.message || 'Something went wrong',
      })
    } finally {
      setColumnCreating(false)
    }
  }

  const handleDeleteColumn = async () => {
    if (!columnId) return

    try {
      await dispatch(deleteGlobalColumn(columnId))

      openNotificationWithIcon('success', {
        message: 'Column has been deleted',
      })

      setColumnId(null)
    } catch (error) {
      if (error?.response?.data?.count) {
        return setConflictEvents(error?.response?.data?.count)
      }
      openNotificationWithIcon('error', {
        message: error.response?.data?.message || 'Something went wrong',
      })
    }
  }

  return (
    <div className='matrix-settings'>
      <div className='body'>
        <Time settings={settings} />
        <Resources
          columns={columns}
          settings={settings}
          onDragEnd={handleChangeColumnOrder}
          onColumnSettingsClick={handleColumnSettingsClick}
        />

        <Button
          style={{ position: 'absolute', left: 10, top: 10, borderRadius: 2 }}
          icon={<PlusOutlined />}
          loading={columnCreating}
          onClick={handleAddColumn}
        >
          Add New Column
        </Button>
      </div>

      {!!currentColumn && (
        <ColumnSettings
          data={currentColumn}
          onClose={() => setColumnId(null)}
          onSave={handleSaveColumnSettings}
          // options={{ hideUpdateFutureDates: true }}
          onDeleteColumn={handleDeleteColumn}
          globalSettings
        />
      )}

      <Modal
        visible={!!conflictEvents}
        onOk={() => {
          setConfirmDelete(1)
          setConflictEvents(0)
        }}
        onCancel={() => setConflictEvents(0)}
        zIndex={1001}
      >
        <p>
          {conflictEvents} column events will be transferred to queue. Are you
          sure you want to proceed?
        </p>
      </Modal>
    </div>
  )
}
