import Echo from 'laravel-echo'
import io from 'socket.io-client'

declare global {
  interface Window {
    io: any
    Echo: any
  }
}

window.io = io

export function createSocketConnection(token: string) {
  if (window.Echo || !token) return

  window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: process.env.REACT_APP_SOCKET_URL,
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ['websocket', 'polling', 'flashsocket'],
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  })
}
