import { ProjectDiscount, ProjectDiscountType } from 'features/Project/types'
import { priceToView } from 'helpers/thousandSeparator'
import styled from 'styled-components'

interface Props {
  data: ProjectDiscount[]
  sectionSubtotal: number
  showPrice: boolean
}

export const SectionDiscounts = ({
  data,
  sectionSubtotal,
  showPrice,
}: Props) => {
  return (
    <Wrapper>
      <Title>Discounts:</Title>
      <div>
        {data.map(item => (
          <ListItem key={item.id} expired={!item.active}>
            <p>{item.name}</p>
            <Expiration>
              {!item.expiration
                ? 'no expiration'
                : `${item.active ? 'Expires' : 'Expired'} on ${
                    item.expiration
                  }`}
            </Expiration>
            <Price>
              {showPrice &&
                `-${priceToView(
                  item.discount_type === ProjectDiscountType.Percentage
                    ? (item.amount * sectionSubtotal) / 100
                    : item.amount,
                )}`}
            </Price>
          </ListItem>
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  font-size: 0.8rem;
`
const ListItem = styled.div<{ expired: boolean }>`
  display: grid;
  grid-template-columns: 1fr minmax(150px, 1fr) minmax(100px, 1fr);
  gap: 20px;
  color: ${({ expired }) => (expired ? '#aeadad' : 'initial')};

  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
const Title = styled.p`
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #191919;
  opacity: 0.5;
`

const Price = styled.p`
  font-weight: 500;
  justify-self: flex-end;
`

const Expiration = styled.p`
  text-align: right;
`
