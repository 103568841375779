import React, { useEffect, useState } from 'react'
import './EmployeeInfoMarkerPopap.scss'
import PopapWrapper from '../PopapWrapper'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'

const EmployeeInfoMarkerPopap = ({
  employee,
  className,
  timeToNextAppointment,
  remainingAppointments,
  distance,
  time,
  nextAppointment,

}) => {
  const [timeInRepair, setTimeInRepair] = useState(null)

  useEffect(() => {
    if (distance && distance < 30000) {
      setTimeInRepair(Number(moment().diff(moment(time, 'HH:mm:ss'), 'seconds')).toFixed(0))

      const timeInRepairInterval = () => {
        setTimeInRepair(Number(moment().diff(moment(time, 'HH:mm:ss'), 'seconds')).toFixed(0))
      }

      setInterval(timeInRepairInterval, 60000)

      return () => {
        clearInterval(timeInRepairInterval)
      }
    }
  }, [distance])

  let repair = null

  if (timeInRepair) {
    const minutes = timeInRepair / 60
    const hours = minutes / 60

    if (hours > 0) {
      repair = (
        <span>
          <strong>{Number(hours).toFixed(0)}</strong> h{' '}
          <strong>{Number(minutes).toFixed(0) - Number(hours).toFixed(0) * 60}</strong> min
        </span>
      )
    } else if (minutes > 0) {
      repair = (
        <span>
          <strong>{Number(minutes).toFixed(0)}</strong> min{' '}
          <strong>{Number(timeInRepair).toFixed(0) - Number(minutes).toFixed(0) * 60}</strong> sec
        </span>
      )
    } else {
      repair = (
        <span>
          <strong>{timeInRepair}</strong> sec
        </span>
      )
    }
  }

  return (
    <PopapWrapper className={className}>
      <div className='row d-flex justify-content-between px-2'>
        <span>
          <strong>{employee.full_name}</strong>
        </span>
        <span>{employee.title}</span>
      </div>
      {repair ? (
        <div className='row d-flex justify-content-between px-2'>
          <span>
            On the <strong>Repair</strong>
          </span>
          {repair}
        </div>
      ) : (
        ''
      )}
      {timeToNextAppointment === 0 || timeToNextAppointment ? (
        <div className='row d-flex justify-content-between px-2'>
          <div className='col-6 p-0'>Approx. time to next destination</div>
          <span>
            <strong>{Number(timeToNextAppointment).toFixed(0)}</strong> min
          </span>
        </div>
      ) : (
        ''
      )}
      <div className='row d-flex justify-content-between px-2'>
        <button className='btn btn-info btn-sm py-0 px-1 remaining-appointments'>
          Stop remaining ({remainingAppointments})
        </button>
        {nextAppointment ? (
          <Link to={{ pathname: 'appointment', edit: true, params: nextAppointment }}>
            <button className='btn btn-info btn-sm py-0 px-1'>View  ttt appointment</button>
          </Link>
        ) : (
          ''
        )}
      </div>
    </PopapWrapper>
  )
}

export default EmployeeInfoMarkerPopap
