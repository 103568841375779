import moment from 'moment-timezone'

import { IAppointment } from '../../../../../../../types/Appointment'
import { IWorker } from './AppointmentPreferredTech'

export const prepareDataForMatrix = (
  appointment: IAppointment,
  worker: IWorker,
  columns,
) => {
  const id = columns?.find(
    ({ main_tech_name }) => main_tech_name === worker?.full_name,
  )?.column_template_id
  return {
    ...appointment,
    ...worker?.sortByTime[0],
    // appointed_technician_id: worker?.id,
    column_template_id: [id],
    assign_to_matrix: true,
    time_start: moment()
      .set('hour', worker?.sortByTime[0].current_start_time)
      .set('minute', 0)
      .format('HH:mm'),
    time_end: moment()
      .set(
        'hour',
        worker?.sortByTime[0].current_start_time + appointment.times_needed,
      )
      .set('minute', 0)
      .format('HH:mm'),
    date: moment(worker.date || new Date()).format('MM/DD/YYYY'),
    action: 'created',
    event_created_by: 'route',
  }
}

export const prepareDataForAppt = (
  appointment: IAppointment,
  worker: IWorker,
  columns,
) => {
  const id = columns?.find(
    ({ main_tech_name }) => main_tech_name === worker?.full_name,
  )?.column_template_id
  return {
    ...appointment,
    ...worker?.sortByTime[0],
    // appointed_technician_id: appointment?.id ? worker?.id : [worker?.id],
    column_template_id: appointment?.id ? id : [id],
    preferred_technician_id: worker?.id,
    assign_to_matrix: true,
    time_start: moment()
      .set('hour', worker?.sortByTime[0].current_start_time)
      .set('minute', 0),
    time_end: moment()
      .set(
        'hour',
        worker?.sortByTime[0].current_start_time + appointment.times_needed,
      )
      .set('minute', 0),
    matrix_time_start: moment()
      .set('hour', worker?.sortByTime[0].current_start_time)
      .set('minute', 0),
    matrix_time_end: moment()
      .set(
        'hour',
        worker?.sortByTime[0].current_start_time + appointment.times_needed,
      )
      .set('minute', 0),
    date: moment(worker.date || new Date()).format('YYYY-MM-DD'),
  }
}

export const timeToView = (start, end) => {
  const isValue = start !== undefined && end !== undefined
  return (
    isValue &&
    `${
      start
        ? moment()
            .set('hour', start)
            .set('minute', 0)
            .format('hh:mm a')
        : '12:00 am'
    } - ${
      end
        ? moment()
            .set('hour', end)
            .set('minute', 0)
            .format('hh:mm a')
        : '12:00 am'
    }`
  )
}

export const mileArr = [...Array(30).keys()].map((e, i) => ({
  name: `${i + 1} ml.`,
  id: i + 1,
}))
export const timeArr = [...Array(12).keys()].map((e, i) => ({
  name: `${i + 1} hr.`,
  id: i + 1,
}))
export const dayArr = [...Array(31).keys()].map((e, i) => ({
  name: `${i} day.`,
  id: i,
}))
export const timeArrBySettingsTime = (start, end) => {
  return new Array(end - start)
    ?.fill('')
    .map((e, i) => ({ name: `${i + 1} hr.`, id: i + 1 }))
}

export const createBusinessDays = (
  date,
  exclude_days,
  daysAhead,
  days_behind,
) => {
  const DATE_FORMAT = 'YYYY-MM-DD'

  const getFinalDate = (date, days, excluded) => {
    const dates = []

    let count = +days_behind
    for (let index = 0; dates.length < +days; index++) {
      const formatForCheck = moment(date)
        .add(count, 'days')
        .format('ddd')
      const nextDate = moment(date).add(count, 'days')
      if (!excluded.includes(formatForCheck)) {
        dates.push(nextDate.format(DATE_FORMAT))
      }

      count += 1
    }

    return dates
  }

  return getFinalDate(date, +daysAhead, exclude_days)
}
