import axiosOrcatec from '../axiosInstance'

/**
 * Get subscription.
 *
 * @return {*}
 */
export const fetchPaymentSetup = data => {
  return axiosOrcatec.get('setting/merchants', data).then(response => response.data)
}

/**
 * Create subscription.
 *
 * @param data
 * @return {*}
 */
export const savePaymentSetup = data => {
  return axiosOrcatec.post('setting/merchants', data).then(response => response.data)
}
