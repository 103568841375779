import { ModuleName } from './constants/module_permissions/types'

export enum UniteType {
  FIRST_VIEW = 1,
  SECOND_VIEW = 2,
  THIRD_VIEW = 3,
  MESSAGING_VIEW = 4,
}

export enum UserStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  PENDINNG = 2,
}

export interface IGroupList {
  id: number
  name: string
  description: string
  created_at: string
  updated_at: string
  key: number
}

export interface IRelatedUsersList {
  id: number
  full_name: string
  description: string
  created_at: string
  updated_at: string
  title: string
  status: number
  key: number
}

export interface IPermission {
  module_name: string
  permission_key: string
  value: number
}

export interface ISettings {
  name: string
  control_type: UniteType
  description: string
  with_access_checkbox: boolean
  with_access_scope: boolean
  permission_key: string
  children?: {
    name: string
    permission_key: string
    with_access_checkbox: boolean
    with_access_scope: boolean
    control_type: UniteType
    description: string
    children?: {
      name: string
      permission_key: string
      with_access_checkbox: boolean
      with_access_scope: boolean
      control_type: UniteType
      description: string
    }[]
  }[]
}

export interface IModule {
  moduleName: string
  onChange: (
    moduleName: IPermission['module_name'],
    permission_key: IPermission['permission_key'],
    value: IPermission['value'],
  ) => void
  settings?: { [key: string]: ISettings }
  permissions?: IPermission[]
  item: ISettings
  with_access_checkbox?: boolean
  permissionsObj: { [key: string]: number }
  searchValue: string
  modulePermission: IPermission['permission_key']
  type?: UniteType
  moduleTitle: string
  disabled: boolean
  custom_access_scope?: []
  subscriptionOff?: boolean
}

export enum IScope {
  DENIED = 0,
  ALL = 1,
  OWN = 2,
  RELATED = 3,
  OWN_RELATED = 4,
}

type Permission = {
  permission_key: string
  value: number
}

type ModulePermissions = {
  [module in ModuleName]: Permission[]
}

export interface UserPermissions {
  module_permissions: ModulePermissions
}

export interface IPermissionKeys {
  [key: string]: string
}

export interface IPermissionGroupKey {
  [category: string]: IPermissionKeys
}
