import moment from 'moment-timezone'
import { IAppointment } from 'types/Appointment'

export const normalizeObj = (appointment: IAppointment) => {
  if (!Array.isArray(appointment.column_template_id)) return // works only new appointments (need to edit for update)

  return {
    ...appointment,
    date: appointment.date
      ? moment(appointment.date).format('YYYY-MM-DD')
      : appointment.is_recurring
      ? moment(appointment.start_date).format('YYYY-MM-DD')
      : null,
    start_date: appointment?.start_date ? moment(appointment.start_date).format('YYYY-MM-DD') : null,
    column_template_id: appointment.column_template_id.filter(id => typeof id === 'number'),
    queue_id: appointment.column_template_id.filter(id => typeof id === 'string').map(id => +id),
  }
}

export const transformArrayOfObj = (array: { day: number; weekNumber: number }[]) => {
  const outputMap = new Map()

  for (const obj of array) {
    const { day, weekNumber } = obj

    outputMap.has(weekNumber)
      ? outputMap.get(weekNumber).days.push(day)
      : outputMap.set(weekNumber, { weekNumber, days: [day] })
  }

  return Array.from(outputMap.values())
}

export const weekDays: { [key: number]: string } = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
}

export const weekPrefix = {
  1: '1st',
  2: '2nd',
  3: '3rd',
  4: '4th',
}
