import {
  CheckOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { DragIndicator } from '@material-ui/icons'
import { Popconfirm, Spin, Tooltip } from 'antd'
import {
  // selectGroupTotalAfterDiscount,
  selectItemsGroupTotal,
  selectProject,
} from 'features/Project/projectSelectors'
import {
  deleteItemsGroup,
  updateItemsGroup,
} from 'features/Project/slices/projectGroupsSlice'
import { IProjectItemsGroup } from 'features/Project/types'
import { priceToView } from 'helpers/thousandSeparator'
import React, { useEffect, useState } from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  Actions,
  DeleteIcon,
  Title,
  TitlePlaceholder,
  Total,
  Wrapper,
  Heading,
  GroupContent,
  GroupTotals,
} from './ProjectItemsGroup.styles'
import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'
import { TextArea } from 'components/UIKit'
// import ProjectDiscounts from '../ProjectDiscounts'

// const { TextArea } = Input
interface Props {
  group: IProjectItemsGroup
  children: React.ReactNode
  disabled: boolean
  provided: DraggableProvided
  sectionId: number
  onGroupDeleted?: () => void
}

export const ProjectItemsGroup = ({
  children,
  group,
  disabled,
  provided,
  // sectionId,
  onGroupDeleted,
}: Props) => {
  const [title, setTitle] = useState(group?.name || '')
  const [edit, setEdit] = useState(!!group.isNew)
  const [error, setError] = useState(false)
  const [showContent, setShowContent] = useState(true)

  const dispatch = useAppDispatch()

  const groupTotal = useAppSelector(selectItemsGroupTotal(group.id))
  // const groupTotalAfterDiscounts = useAppSelector(
  //   selectGroupTotalAfterDiscount(sectionId, group.id),
  // )

  const { user_id } = useAppSelector(selectProject)
  const isVisiblePrice = useAppSelector(selectIsPriceVisible(user_id))

  useEffect(() => {
    if (group?.name) setTitle(group.name)
  }, [group?.name])

  const handleChangeTitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (error) setError(false)

    setTitle(e.target.value)
  }

  const handleUpdateTitle = () => {
    setTitle(title.trim())
    dispatch(updateItemsGroup({ ...group, name: title }))
    setEdit(false)
  }

  const handleDeleteGroup = async () => {
    await dispatch(deleteItemsGroup(group.id))
    onGroupDeleted?.()
  }

  return (
    <Wrapper ref={provided?.innerRef} {...provided?.draggableProps}>
      <Spin spinning={false}>
        <Heading onClick={() => setShowContent(!showContent)}>
          {!disabled && (
            <span
              {...provided?.dragHandleProps}
              style={{
                position: 'absolute',
                left: -25,
                top: 6,
              }}
            >
              <Tooltip
                title={disabled ? null : 'Move Group'}
                mouseLeaveDelay={0}
              >
                <DragIndicator
                  style={{
                    cursor: disabled ? 'not-allowed' : 'grab',
                    color: '#aaa',
                  }}
                />
              </Tooltip>
            </span>
          )}

          <Title>
            {!edit ? (
              <>
                {title ? (
                  <p
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      wordWrap: 'normal',
                      border: '1px solid transparent',
                      padding: '4px 11px',
                    }}
                  >
                    {title}
                  </p>
                ) : (
                  <TitlePlaceholder>(No title)</TitlePlaceholder>
                )}

                {!disabled && (
                  <Actions>
                    <EditOutlined
                      style={{
                        color: '#1890ff',
                        marginRight: 10,
                      }}
                      onClick={e => {
                        e.stopPropagation()
                        setEdit(true)
                      }}
                    />

                    <Popconfirm
                      title='Are you sure you want to delete this group'
                      onConfirm={handleDeleteGroup}
                    >
                      <DeleteIcon onClick={e => e.stopPropagation()}>
                        <DeleteOutlined />
                      </DeleteIcon>
                    </Popconfirm>
                  </Actions>
                )}
              </>
            ) : (
              <>
                <TextArea
                  autoFocus
                  autoSize
                  value={title}
                  onChange={handleChangeTitle}
                  onBlur={handleUpdateTitle}
                  onFocus={e => {
                    const val = e.target.value
                    e.target.value = ''
                    e.target.value = val
                  }}
                  onClick={e => e.stopPropagation()}
                  placeholder='Group title'
                />

                <CheckOutlined
                  style={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    handleUpdateTitle()
                  }}
                />
              </>
            )}
          </Title>

          <Total>
            {isVisiblePrice && (
              <>
                Group Total: <span>{priceToView(groupTotal)}</span>
              </>
            )}
          </Total>

          {showContent ? <DownOutlined /> : <RightOutlined />}
        </Heading>

        {showContent && (
          <GroupContent>
            {children}

            {isVisiblePrice && (
              <GroupTotals>
                <p>
                  Group Total: <span>{priceToView(groupTotal)}</span>
                </p>
              </GroupTotals>
            )}
          </GroupContent>
        )}
      </Spin>
    </Wrapper>
  )
}

export default ProjectItemsGroup
