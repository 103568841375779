export const MAP_SET_PATH = 'MAP_SET_PATH'

export const MAP_FETCH_EMPLOYEES = 'MAP_FETCH_EMPLOYEES'

export const MAP_FETCH_EMPLOYEES_TITLES = 'MAP_FETCH_EMPLOYEES_TITLES'

export const MAP_FETCH_QUEUE = 'MAP_FETCH_QUEUE'

export const MAP_FETCH_TODO = 'MAP_FETCH_TODO'

export const MAP_FETCH_EMPLOYEES_ROUTES = 'MAP_FETCH_EMPLOYEES_ROUTES'

export const UPDATE_EMPLOYE_COLOR = 'UPDATE_EMPLOYE_COLOR'
export const MAP_UPDATE_NEEDED = 'MAP_UPDATE_NEEDED'
