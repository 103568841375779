import { useState, useEffect } from 'react'
import { DatePicker } from 'antd'
import moment, { Moment } from 'moment-timezone'
import { MenuItem } from '@material-ui/core'
import { InputField } from 'containers/MainContent/Orcatec/components/Input/InputField'
import styled from 'styled-components'
import useLocalStorage, { getValueFromLS } from 'hooks/useLocalStorage'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'

const { RangePicker, MonthPicker, YearPicker } = DatePicker
const yearFormat = 'YYYY'
const dateFormat = 'MM/DD/YYYY'
const monthFormat = 'MMM, YYYY'
const Wrapper = styled.div`
  padding: 10px;
  background-color: white;
  min-width: 250px;
  & .ant-picker {
    width: 100%;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
`

const InputWrapper = styled.div`
  margin-bottom: 10px;
`
const date = [
  {
    label: '1 day',
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    type: 'day',
  },
  {
    label: '1 week',
    start_date: moment()
      .days(moment().days() - 6)
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    type: 'range',
  },
  {
    label: 'Month to date',
    start_date: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    type: 'range',
  },
  {
    label: '1 month',
    start_date: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    type: 'month',
  },
  {
    label: '3 month',
    start_date: moment()
      .month(moment().month() - 3)
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    type: 'range',
  },
  {
    label: '6 month',
    start_date: moment()
      .month(moment().month() - 6)
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    type: 'range',
  },
  {
    label: 'Year to date',
    start_date: moment()
      .startOf('year')
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    type: 'range',
  },
  {
    label: '1 year',
    start_date: moment()
      .startOf('year')
      .format('YYYY-MM-DD'),
    end_date: moment()
      .endOf('year')
      .format('YYYY-MM-DD'),
    type: 'year',
  },
  {
    label: 'Custom',
    start_date: moment()
      .days(moment().days() - 6)
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    type: 'range',
  },
]
const defaultData = {
  label: 'Month to date',
  start_date: moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
  end_date: moment().format('YYYY-MM-DD'),
  type: 'range',
}

type Label =
  | '1 month'
  | '1 day'
  | 'Month to date'
  | 'Custom'
  | '6 month'
  | '3 month'
  | '1 year'
  | 'Month to date'
  | 'Custom'
type Type = 'range' | 'month' | 'year' | 'day'
export interface IPeriod {
  label: Label
  start_date: Moment | string
  end_date: Moment | string
  type: Type
}
// interface IProp {
//   name?: string
//   nameLS: string
// }

const DateRangeFilter = (props: FilterDropdownProps) => {
  const [, setFilter] = useLocalStorage(props.nameLS, {})
  const valueFromLS = getValueFromLS(props.nameLS)?.[props?.name]
  const [period, setPeriod] = useState<IPeriod>(
    valueFromLS?.type ? valueFromLS : defaultData,
  )

  const heandleChangePicker = (
    data: IPeriod,
    dataArrString: [string, string],
  ) => {
    props.setSelectedKeys(dataArrString)
    setPeriod(data)
    setFilter({ [props.name]: { ...data, filter: dataArrString } })
  }

  const heandleSubmit = () => {
    props.confirm()
    setFilter({ [props.name]: period, filter: props.selectedKeys })
  }
  const handleReset = async () => {
    await setFilter({})
    await setPeriod(defaultData)
    await props.clearFilters?.()
    await props.confirm?.()
  }

  useEffect(() => {
    if (props.visible && !valueFromLS) {
      setPeriod(defaultData)
      props.setSelectedKeys([
        `${defaultData.start_date}|${defaultData.end_date}`,
      ])
    }
  }, [props.visible])

  return (
    <Wrapper>
      <InputWrapper>
        <InputField
          className={'date-range-select'}
          style={{ width: '100%' }}
          value={period.label}
          name='select'
          select
          error={undefined}
          inputProps={undefined}
        >
          {date.map((item, idx) => (
            <MenuItem
              onClick={() =>
                heandleChangePicker(item, [
                  `${item.start_date}|${item.end_date}`,
                ])
              }
              key={idx}
              value={item.label}
            >
              {item.label}
            </MenuItem>
          ))}
        </InputField>
      </InputWrapper>

      {period.type === 'day' && (
        <DatePicker
          defaultValue={moment()}
          value={period?.start_date ? moment(period?.start_date) : moment()}
          onChange={(date, string) =>
            heandleChangePicker(
              {
                start_date: moment(date).format(dateFormat),
                end_date: moment(date).format(dateFormat),
                type: period.type,
                label: period.label,
              },
              [`${string}|${string}`],
            )
          }
          format={dateFormat}
          allowClear={false}
        />
      )}
      {period.type === 'month' && (
        <MonthPicker
          defaultValue={moment()}
          value={period?.start_date ? moment(period?.start_date) : moment()}
          onChange={date =>
            heandleChangePicker(
              {
                start_date: moment(date)
                  .startOf('month')
                  .format(dateFormat),
                end_date: moment(date)
                  .endOf('month')
                  .format(dateFormat),
                type: period.type,
                label: period.label,
              },
              [
                `${moment(date)
                  .startOf('month')
                  .format(dateFormat)}|${moment(date)
                  .endOf('month')
                  .format(dateFormat)}`,
              ],
            )
          }
          format={monthFormat}
          allowClear={false}
        />
      )}
      {period.type === 'year' && (
        <YearPicker
          defaultValue={
            period?.start_date ? moment(period?.start_date) : moment()
          }
          onChange={(date, string) => {
            heandleChangePicker(
              {
                start_date: moment(string)
                  .startOf('year')
                  .format(dateFormat),
                end_date: moment(string)
                  .endOf('year')
                  .format(dateFormat),
                type: period.type,
                label: period.label,
              },

              [
                `${moment(string)
                  .startOf('year')
                  .format(dateFormat)}|${moment(string)
                  .endOf('year')
                  .format(dateFormat)}`,
              ],
            )
          }}
          value={period?.start_date ? moment(period?.start_date) : moment()}
          format={yearFormat}
          allowClear={false}
        />
      )}
      {period.type === 'range' && (
        <RangePicker
          defaultValue={[moment(period?.start_date), moment(period?.end_date)]}
          onChange={(date, string) =>
            heandleChangePicker(
              {
                start_date: string[0],
                end_date: string[1],
                type: period.type,
                label: period.label,
              },
              [`${string[0]}|${string[1]}`],
            )
          }
          format={dateFormat}
          allowClear={false}
          value={[moment(period?.start_date), moment(period?.end_date)]}
        />
      )}

      <ButtonWrapper>
        <MainButton onClick={handleReset} type='cancel' title='Reset' />
        <MainButton onClick={heandleSubmit} title='Ok' />
      </ButtonWrapper>
    </Wrapper>
  )
}

export default DateRangeFilter
