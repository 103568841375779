import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as action from '../store/Orcatec/actions/routing'

export const RouterProvider = ({ children }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname,
  })

  useEffect(() => {
    dispatch(action.setUserRouting({ to: location.pathname, from: route.to }))
    setRoute(prev => ({ to: location.pathname, from: prev.to }))
  }, [location])

  return { ...children }
}
