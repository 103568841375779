import axiosOrcatec from './axiosInstance'

/**
 * Appointment types.
 *
 * @return {*}
 */
export const fetchAppointmentTypes = () => {
  return axiosOrcatec.get('appointment-types').then(response => response.data)
}

export const fetchAppointmentType = id => {
  return axiosOrcatec
    .get(`appointment-types/${id}`)
    .then(response => response.data)
}

export const fetchAppointmentTypeForEntity = type => {
  return axiosOrcatec
    .get(`/used/types?usedIn=${type}`)
    .then(response => response.data)
}
