import {
  AvatarBlock,
  ClockInButtonStyle,
  CompactClockBtnStyle,
  HeaderWrapper,
  NavbarBlock,
  ClockInStyle,
  AvatarWrapper,
} from './Header.styles'
import ClockOutInBtn from '../../../containers/MainContent/Orcatec/components/ClockOutInBtn/ClockOutInBtn'
import { ClockInBtn } from 'containers/MainContent/Orcatec/components/ClockOutInBtn/ClockInBtn'
import UserAvatar from './components/UserAvatar'
import Navigation from './components/Navigation'
import React, { useEffect, useState } from 'react'
import useWindowSize from '../../../hooks/useWindowSize'
import { Badge, Drawer, Dropdown, Menu, Modal } from 'antd'
import { useRoutes } from './components/Navigation/routes'
import { useHistory } from 'react-router-dom'
import { TimezonePicker } from '../../../containers/MainContent/Orcatec/TimeCards/components/TimezonePicker/TimezonePicker'
import CustomDatePicker from '../../../containers/MainContent/Orcatec/TimeCards/components/CustomDatePicker/CustomDatePicker'
import TimeCardList from '../../../containers/MainContent/Orcatec/TimeCards/components/TimeCardsList/TimeCardList'
import { useAppSelector } from '../../../store/Orcatec/hooks'
import { logout as logoutAction } from '../../../store/Orcatec/actions/app'
import { logoutAccountManagerToAdmin } from '../../../api/User'
import { separateSetUserAndCompany } from '../../../store/Orcatec/actions/settings/user'
import Support from '../../../containers/MainContent/Orcatec/Support'
import { useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import ModalUserForm from '../../../containers/MainContent/Orcatec/Settings/components/users/ModalUserForm'
import {
  initialTimeState,
  MenuIcon,
  selectMe,
  selectSupportTickets,
} from './utils'
import './Header.scss'
import { isUserFullSettings } from 'helpers/isUserFullSettings'
import { IHeaderType } from './components/types'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ModuleName,
  TimeCardsPermissions,
} from 'features/Settings/UsersAndGroups'
import WelcomeModal from 'features/Auth/WelcomeModal'

// import { CompanyLogo } from './components/Navigation/NavigationIcons'

export interface IDropdownItems {
  key: string
  isVisible: boolean
  title: React.ReactNode | string
  icon: React.ReactNode
  onClickHandler: () => void
}

export const Header = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const [routes] = useRoutes()

  const [isCompactView, setIsCompactView] = useState(false)
  const [compactViewDropdown, setCompactViewDropdown] = useState(false)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const isAccessToTimeCards = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.TIME_CARDS,
      TimeCardsPermissions.TIME_CARDS_LIST_CAN_READ,
    ),
  )

  const me = useAppSelector(selectMe)
  const { unseenCount = 0 } = useAppSelector(selectSupportTickets)

  const {
    is_admin,
    login_from_admin,
    full_name,
    email,
    info: { profile_picture },
    background_color,
    ui_settings,
    paid_modules,
  } = me

  const [welcomeModal, showWelcomeModal] = useState(false)
  // Support Modal
  const [showSupportModal, setShowSupportModal] = useState(false)
  // Timecards modal
  const [showTimecardsModal, setShowTimecardsModal] = useState(false)
  const [timecardsState, setTimecardsState] = useState({
    timeState: initialTimeState,
    timezone:
      JSON.parse(localStorage?.getItem?.(me?.parent_id))?.[me.id]?.timezone ||
      moment?.tz?.guess(),
  })
  const [showUserFormModal, setShowUserFormModal] = useState(false)

  useEffect(() => {
    if (!me?.login_from_admin) {
      if (!isUserFullSettings(me)) {
        showWelcomeModal(true)
      }
    }
  }, [])

  //UserForm Modal

  const toggleUserFormModal = () => {
    setShowUserFormModal(prev => !prev)
  }

  const handleSetTimeState = (timeState: moment.Moment | string) =>
    setTimecardsState(prev => ({ ...prev, timeState }))
  const handleSetTimezone = (timezone: moment.Moment) =>
    setTimecardsState(prev => ({ ...prev, timezone }))

  // Logic
  const toggleTimecardsModal = () => {
    setShowTimecardsModal(prev => !prev)
  }

  const logout = () => {
    window.localStorage.removeItem('auth')
    document.cookie = 'orcatecJWT= ;'
    history.push('/auth/signin')
    dispatch(logoutAction())
  }

  const returnToSA = () => {
    try {
      logoutAccountManagerToAdmin()
        .then(({ data }) => separateSetUserAndCompany(data, dispatch))
        .then(() => localStorage.removeItem('isFromSA'))
        .then(() => history.push('/companies'))
        .catch(error => console.error(error))
    } catch (e) {
      console.error(e)
    }
  }

  const toggleSupportModal = () => {
    setShowSupportModal(prev => !prev)
  }

  const items: IDropdownItems[] = [
    {
      key: 'Get Help',
      isVisible: !is_admin,
      title: <Badge count={unseenCount}>Get Help</Badge>,
      icon: <i className='mdi mdi-help-circle' />,
      onClickHandler: () => {
        toggleSupportModal()
        setCompactViewDropdown(false)
      },
    },
    {
      key: 'Profile Settings',
      isVisible: true,
      title: 'Profile Settings',
      icon: <i className='mdi mdi-account-circle m-r-5' />,
      onClickHandler: () => {
        toggleUserFormModal()
        setCompactViewDropdown(false)
      }, //custom function to ckech userRole, subscriptionOff and other things
    },
    {
      key: 'Timecards',
      isVisible: paid_modules?.time_cards && !!isAccessToTimeCards,
      title: 'Timecards',
      icon: <i className='mdi mdi-av-timer m-r-5' />,
      onClickHandler: () => {
        toggleTimecardsModal()
        setCompactViewDropdown(false)
      },
    },
    {
      key: 'Return to admin panel',
      isVisible: login_from_admin,
      title: 'Return to admin panel',
      icon: <i className='mdi mdi-logout' />,
      onClickHandler: () => {
        returnToSA()
        setCompactViewDropdown(false)
      },
    },
    {
      key: 'Logout',
      isVisible: !login_from_admin,
      title: 'Logout',
      icon: <i className='mdi mdi-power text-danger' />,
      onClickHandler: logout,
    },
  ]

  const dropdownMenu = (
    <Menu>
      {items.map(({ key, icon, isVisible, title, onClickHandler }) =>
        isVisible ? (
          <Menu.Item key={key} icon={icon} onClick={onClickHandler}>
            {title}
          </Menu.Item>
        ) : null,
      )}
    </Menu>
  )

  useEffect(() => {
    if (width) {
      setIsCompactView(width <= 768)
    }
  }, [width])

  const onClose = () => {
    setIsDrawerVisible(false)
  }
  const handleClick = ({ key }: { key: string }) => {
    history.push(key)
    onClose()
  }

  const toggleCompactViewDropdown = () => {
    setCompactViewDropdown(true)
    setTimeout(() => setCompactViewDropdown(false), 2500)
  }

  const minimalHeaderView = [
    IHeaderType.HEADER_HORIZONTAL_MIN,
    IHeaderType.HEADER_HORIZONTAL_MIN_WITH_TEXT,
  ].includes(ui_settings?.header_view)
  return (
    <>
      {isCompactView ? (
        <HeaderWrapper id='header'>
          <MenuIcon onClick={() => setIsDrawerVisible(true)} />
          <CompactClockBtnStyle>
            <ClockOutInBtn />
          </CompactClockBtnStyle>
          <div
            onTouchStart={toggleCompactViewDropdown}
            onClick={toggleCompactViewDropdown}
          >
            <Dropdown overlay={dropdownMenu} visible={compactViewDropdown}>
              <UserAvatar
                isCompactView={isCompactView}
                profile_picture={profile_picture}
                full_name={full_name}
                background_color={background_color}
              />
            </Dropdown>
          </div>
          <Drawer
            className='compact-dropdown'
            title='Menu'
            placement='left'
            onClose={onClose}
            visible={isDrawerVisible}
          >
            <Menu
              className='navigation-menu'
              mode='vertical'
              onClick={handleClick}
            >
              {routes.map(({ icon, title, route }) => (
                <Menu.Item key={route} icon={icon} title={title}>
                  {'  '} {title}
                </Menu.Item>
              ))}
            </Menu>
          </Drawer>
        </HeaderWrapper>
      ) : (
        <HeaderWrapper minimizeView={minimalHeaderView} id='header'>
          {!minimalHeaderView && (
            <ClockInButtonStyle>
              <ClockOutInBtn />
            </ClockInButtonStyle>
          )}

          {/* {minimalHeaderView && <CompanyLogo width={50} />} */}
          <NavbarBlock>
            <Navigation />
          </NavbarBlock>
          <ClockInStyle>{minimalHeaderView && <ClockInBtn />}</ClockInStyle>
          <Dropdown overlay={dropdownMenu} trigger={['click']}>
            {minimalHeaderView ? (
              <AvatarWrapper>
                <UserAvatar
                  profile_picture={profile_picture}
                  full_name={minimalHeaderView ? null : full_name}
                  background_color={background_color}
                  minimizeView={minimalHeaderView}
                />
              </AvatarWrapper>
            ) : (
              <AvatarBlock>
                <UserAvatar
                  profile_picture={profile_picture}
                  full_name={minimalHeaderView ? null : full_name || email}
                  background_color={background_color}
                />
              </AvatarBlock>
            )}
          </Dropdown>
        </HeaderWrapper>
      )}

      {showSupportModal ? <Support onClose={toggleSupportModal} /> : null}

      {showUserFormModal && (
        <ModalUserForm
          // modal={showUserFormModal}
          close={toggleUserFormModal}
          user={me}
          isProfileSettings={true}
          isMapFixingNeeded={true}
          currentPage={location.pathname}
        />
      )}

      <Modal
        className='timecards-modal'
        visible={showTimecardsModal}
        onCancel={toggleTimecardsModal}
        width='auto'
        footer={null}
        dialogClassName='timecard-modal'
        destroyOnClose
        title={
          <div className='modal-title'>
            <div className='header-wrapper-left'>
              <div className='modal-title h4'>{`Time card for: ${full_name}`}</div>
            </div>
            <div className='header-wrapper-right d-flex'>
              <TimezonePicker
                timezone={timecardsState.timezone}
                setTimezone={handleSetTimezone}
              />
              <CustomDatePicker
                timeState={timecardsState.timeState}
                setTimeState={handleSetTimeState}
              />
            </div>
          </div>
        }
      >
        <TimeCardList
          timeState={timecardsState.timeState}
          timezone={timecardsState.timezone}
          modalValues={me}
          readOnly
        />
      </Modal>

      {welcomeModal && <WelcomeModal onClose={() => showWelcomeModal(false)} />}
    </>
  )
}
