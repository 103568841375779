import moment from 'moment-timezone'
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import { deleteProposalInsightsItem } from '../../../../../../../../../api/ProposalAccounting'
import { getProposalInsightsData } from 'store/Orcatec/actions/proposal/proposalForm'

import MainButton from '../../../../../../components/buttons/MainButton'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import {
  JobsIcon,
  DispatchIcon,
} from 'layouts/MainLayout/Header/components/Navigation/NavigationIcons'
import { StarRate } from '@material-ui/icons'
import Card from './components/Card'
import Rate from './components/Rate'
import CardTotal from './components/CardTotal'
import CardHeader from './components/CardHeader'
import './BlockItem.scss'
import { Collapse } from 'antd'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { ITechnician } from 'types/Appointment'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'

const { Panel } = Collapse

const cardStyle = {
  color: '#000',
  padding: '4px',
  display: 'flex',
  gap: '20px',
  boxShadow: '3px 3px 3px 3px #ddd;',
}
const contentArrowStyle = { borderRight: '7px solid  rgb(33, 150, 243)' }
const iconStyle = {
  background: 'rgb(88, 173, 243)',
  color: '#000',
  cursor: 'pointer',
}
const dateFilterFormat = 'YYYYMMDDHHMM'
export interface IHistory {
  cost: number
  date: string
  id: number
  job_code: string
  status_logs: []
  time_end: string
  time_start: string
  user: { id: number; labor_rate: string; name: string }
  workTime: { day: number; hours: number; minutes: number }
  time: string
}

export interface IRate {
  id?: number
  cost_per_hour: number
  isMy?: boolean
  natural_time: { hours: number; minutes: number }
  time: number
  total: number
  note?: string
}

interface IProps {
  blockTitle: string
  title: string
  blockData: { rates: IRate[]; history: IHistory[] }
  setBlockData: Dispatch<any>
  total: number
  setIsProposalInsightsTouched: Dispatch<SetStateAction<boolean>>
  proposalID: number
  setIsLoading: Dispatch<SetStateAction<boolean>>
  saveItemsBeforeDeleting: () => void
  role: string
  canEdit: boolean
  isJob?: boolean
  createFromAppointment: number
  createdUserId: number
  message: string
  isProjectCompleted: boolean
  calcTotalLaborTime: () => void
  users: ITechnician
  canAdd: boolean
  canView: boolean
}

const BlockItem = ({
  blockTitle = '',
  blockData = { rates: [{ note: '' }], history: [] },
  setBlockData,
  setIsProposalInsightsTouched,
  proposalID = 0,
  setIsLoading,
  role = '',
  canEdit = false,
  isJob,
  createFromAppointment,
  calcTotalLaborTime,
  isProjectCompleted,
  message,
  users,
  createdUserId,
  canAdd,
  createdUserRate,
}: // canView,
IProps) => {
  const dispatch = useDispatch()
  const dictionary = useAppSelector(selectJobDictionary)
  const handleChangeWithPermissions = handler => {
    return (event, idx, id, user) => {
      if (isProjectCompleted) {
        setIsProposalInsightsTouched(false)
        return openNotificationWithIcon('warning', {
          message,
          key: 'commission',
          maxCount: 2,
        })
      }
      if (canEdit || (canAdd && !id)) {
        return handler(event, idx, id, user)
      }
      setIsProposalInsightsTouched(false)
      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
  }
  const handleDeleteWithPermissions = handler => {
    return (itemID, itemIDX) => {
      if (isProjectCompleted) {
        setIsProposalInsightsTouched(false)
        return openNotificationWithIcon('warning', {
          message,
          key: 'commission',
          maxCount: 2,
        })
      }
      if (canEdit || (canAdd && !itemID)) {
        return handler(itemID, itemIDX)
      }
      setIsProposalInsightsTouched(false)
      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
  }
  const handleFocusWithPermissions = handler => {
    return (e, itemIDX, value, id) => {
      if (isProjectCompleted) {
        setIsProposalInsightsTouched(false)
        return openNotificationWithIcon('warning', {
          message,
          key: 'commission',
          maxCount: 2,
        })
      }
      if (canEdit || (canAdd && !id)) {
        return handler(e, itemIDX, value, id)
      }
      setIsProposalInsightsTouched(false)
      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
  }
  const handleBlurWithPermissions = handler => {
    return (e, itemIDX, value, id) => {
      if (isProjectCompleted) {
        setIsProposalInsightsTouched(false)
        return openNotificationWithIcon('warning', {
          message,
          key: 'commission',
          maxCount: 2,
        })
      }
      if (canEdit || (canAdd && !id)) {
        return handler(e, itemIDX, value, id)
      }
      setIsProposalInsightsTouched(false)
      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
  }

  const handleAddRow = (
    jobId: number | null = null,
    // rate: number | null = 0,
    natural_time = { hours: 0, minutes: 0 },
  ) => {
    if (isProjectCompleted || !canAdd) {
      setIsProposalInsightsTouched(false)
      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
    setIsProposalInsightsTouched(true)
    const rateToCreate = {
      time:
        Math.floor((+natural_time.hours + +natural_time.minutes / 60) * 100) /
        100,
      cost_per_hour: createdUserRate,
      total: 0,
      isMy: true,
      natural_time,
      user_id: createdUserId,
    }
    isJob && (rateToCreate.job_id = jobId)
    setBlockData(prev => ({
      ...prev,
      rates: [...prev.rates, rateToCreate],
    }))
  }

  const heandleOnFocus = (
    e: ChangeEvent<HTMLInputElement>,
    itemIDX: number,
    value: number | string,
  ) => {
    if (!!value && value !== '0') return
    const { name } = e.target
    const newItem = [...blockData.rates]
    newItem.map((item, index) => {
      if (itemIDX === index) {
        if (name === 'cost_per_hour') {
          newItem[index].cost_per_hour = ''
        }
        if (name === 'time-hours') {
          newItem[index].natural_time.hours = ''
        }
        if (name === 'time-minutes') {
          newItem[index].natural_time.minutes = ''
        }
      }
    })
    setBlockData(prev => ({ ...prev, rates: newItem }))
  }

  const heandleOnBlur = (
    e: ChangeEvent<HTMLInputElement>,
    itemIDX: number,
    value: number | string,
  ) => {
    if (value) return
    const { name } = e.target
    const newItem = [...blockData.rates]
    newItem.map((item, index) => {
      if (itemIDX === index) {
        if (name === 'cost_per_hour') {
          newItem[index].cost_per_hour = 0
        }
        if (name === 'time-hours') {
          newItem[index].natural_time.hours = 0
        }
        if (name === 'time-minutes') {
          newItem[index].natural_time.minutes = 0
        }
      }
    })
    setBlockData(prev => ({ ...prev, rates: newItem }))
  }

  const handleChangeItem = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    id: number,
    user?: ITechnician,
  ) => {
    setIsProposalInsightsTouched(true)

    let { name, value } = event.target

    const isNumberInput = input => {
      const rgx = /^[0-9]*\.?[0-9]*$/
      return input.match(rgx)
    }

    const regExp = new RegExp('^[0-9]+$')
    if (name === 'cost_per_hour' && !isNumberInput(value)) {
      value = ''
    }

    if (!regExp.test(value) && name !== 'cost_per_hour') value = ''

    const splitName = name.split('-')
    if (splitName[0] === 'time' && splitName[1] === 'minutes') {
      if (value?.length >= 3) value = value.slice(0, -1)
      if (+value >= 60) value = 59
    }
    if (splitName[0] === 'time' && splitName[1] === 'hours') {
      if (value?.length >= 4) value = value.slice(0, -1)
    }

    const newItem = [...blockData.rates]
    newItem.map((item, newIDX) => {
      if (newIDX === index) {
        if (splitName[0] === 'time') {
          item.natural_time[splitName[1]] = value

          const timeToDecimals =
            +item.natural_time.hours +
            ((+item.natural_time.minutes / 60) * 100) / 100

          return (item.time = Number(timeToDecimals.toString().slice(0, 7)))
        } else if (name === 'user_id') {
          item.cost_per_hour = user?.labor_rate || 0
          return (item[name] = value)
        } else return (item[name] = value)
      } else return item
    })

    setBlockData(prev => ({ ...prev, rates: newItem }))
  }

  const handleDeleteItem = (itemID: number, itemIDX: number) => {
    if (itemID === undefined) {
      setIsLoading(true)
      setBlockData(prevState => ({
        ...prevState,
        rates: prevState.rates.filter((el, elIDX) => elIDX !== itemIDX),
      }))
      setIsLoading(false)
    } else {
      setBlockData(prevState => ({
        ...prevState,
        rates: prevState.rates.filter(el => el.id !== itemID),
      }))

      const data = {
        expenses: [],
        advertising: [],
        appointment_rates: [],
        work_date_rates: [],
      }
      if (blockTitle === dictionary.plural) data.work_date_rates = [itemID]
      else data.appointment_rates = [itemID]
      deleteProposalInsightsItem(proposalID, data)
        .then(() => {
          openNotificationWithIcon('success', {
            message: 'Deleted successfully',
          })
        })
        .catch(() => {
          openNotificationWithIcon('error', { message: 'Something went wrong' })
          dispatch(getProposalInsightsData(proposalID))
        })
    }

    setIsProposalInsightsTouched(true)
  }

  const handleChangeNotes = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    setIsProposalInsightsTouched(true)
    setBlockData(p => {
      const newState = { ...p }
      newState.rates[i].note = e.target.value
      return newState
    })
  }

  const iconOnClickHeandler = (id: number) => {
    return id
      ? window.open(`/${isJob ? 'jobs' : 'appointment'}/${id}`, '_blank')
      : null
  }

  const decoratedHandleChange = handleChangeWithPermissions(handleChangeItem)
  const decoratedHandleChangeNotes = handleChangeWithPermissions(
    handleChangeNotes,
  )
  const decoratedHandleDelete = handleDeleteWithPermissions(handleDeleteItem)
  const decoratedHandleFocus = handleFocusWithPermissions(heandleOnFocus)
  const decoratedHandleBlur = handleBlurWithPermissions(heandleOnBlur)

  return (
    <>
      <div className={'block-date-container'}>
        {!!blockData?.id && !isJob && (
          <div className='date-body'>
            <p className={'block-title'}>Labor Rates</p>
            <VerticalTimeline
              position={'left'}
              className='first-timeline-element'
              layout={'1-column-left'}
              lineColor={'black'}
            >
              <VerticalTimelineElement
                className='vertical-timeline-element--work first-timeline-element'
                contentStyle={cardStyle}
                contentArrowStyle={contentArrowStyle}
                iconStyle={iconStyle}
                icon={isJob ? <JobsIcon /> : <DispatchIcon />}
                iconOnClick={() => iconOnClickHeandler(blockData?.id)}
              >
                <Collapse
                  className='item-panel'
                  defaultActiveKey={['1']}
                  expandIconPosition='right'
                >
                  <Panel
                    className='item-panel__item'
                    header={
                      <CardHeader
                        canEdit={canEdit}
                        item={blockData}
                        iconOnClickHeandler={iconOnClickHeandler}
                        isJob={isJob}
                        additionalSum={blockData?.rates?.reduce((acc, e) => {
                          acc += +e?.time * +e?.cost_per_hour
                          return acc
                        }, 0)}
                      />
                    }
                    key='1'
                  >
                    <Card
                      title={blockTitle}
                      isJob={isJob}
                      logs={blockData.history}
                    />
                    {!!blockData?.rates?.length &&
                      blockData.rates?.map((rate, itemIDX) => (
                        <Rate
                          users={users}
                          key={itemIDX}
                          handleChangeItem={decoratedHandleChange}
                          // canEdit={isProjectCompleted || !canEdit}
                          handleDeleteItem={decoratedHandleDelete}
                          handleChangeNotes={decoratedHandleChangeNotes}
                          item={rate}
                          itemIDX={itemIDX}
                          role={role}
                          isJob={isJob}
                          title={blockTitle}
                          heandleOnFocus={decoratedHandleFocus}
                          heandleOnBlur={decoratedHandleBlur}
                          canAdd={canAdd}
                          isProjectCompleted={isProjectCompleted}
                          canEdit={canEdit}
                          handleAddRow={() =>
                            handleAddRow(
                              blockData?.id,
                              // blockData?.user?.labor_rate,
                              {
                                hours: blockData?.workTime?.hours || 0,
                                minutes: blockData?.workTime?.minutes || 0,
                              },
                            )
                          }
                        />
                      ))}
                    <CardTotal
                      additionalSum={blockData?.rates?.reduce((acc, e) => {
                        acc += +e?.time * +e?.cost_per_hour
                        return acc
                      }, 0)}
                      totalTime={calcTotalLaborTime(blockData?.rates)}
                      title={blockTitle}
                      disabled={isProjectCompleted || !canEdit}
                      item={blockData}
                      handleAddRow={() =>
                        handleAddRow(
                          blockData?.id,
                          // blockData?.user?.labor_rate,
                          {
                            hours: blockData?.workTime?.hours || 0,
                            minutes: blockData?.workTime?.minutes || 0,
                          },
                        )
                      }
                    />
                  </Panel>
                </Collapse>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        )}

        {!!blockData?.history?.length && isJob && (
          <VerticalTimeline
            position={'left'}
            classname='first-timeline-element'
            layout={'1-column-left'}
            lineColor={'black'}
          >
            <>
              {blockData?.history
                ?.sort(
                  (a, b) =>
                    moment(a.date).format(dateFilterFormat) -
                    moment(b.date).format(dateFilterFormat),
                )
                ?.map((item, index) => (
                  <VerticalTimelineElement
                    key={index}
                    className='vertical-timeline-element--work first-timeline-element'
                    contentStyle={cardStyle}
                    contentArrowStyle={contentArrowStyle}
                    iconStyle={iconStyle}
                    icon={isJob ? <JobsIcon /> : <DispatchIcon />}
                    iconOnClick={() => iconOnClickHeandler(item.id)}
                  >
                    <Collapse
                      className='item-panel'
                      defaultActiveKey={[index]}
                      expandIconPosition='right'
                    >
                      <Panel
                        className='item-panel__item'
                        header={
                          <CardHeader
                            canEdit={canEdit}
                            item={item}
                            iconOnClickHeandler={iconOnClickHeandler}
                            isJob={isJob}
                            additionalSum={blockData?.rates
                              ?.filter(r => r?.job_id === item?.id)
                              ?.reduce((acc, e) => {
                                acc += +e?.time * +e?.cost_per_hour
                                return acc
                              }, 0)}
                          />
                        }
                        key={index}
                      >
                        <Card
                          title={blockTitle}
                          isJob={isJob}
                          logs={item.status_logs}
                        />
                        {!!blockData?.rates?.length &&
                          blockData.rates?.map(
                            (rate, itemIDX) =>
                              rate?.job_id === item?.id && (
                                <Rate
                                  users={users}
                                  key={itemIDX}
                                  handleChangeItem={decoratedHandleChange}
                                  canAdd={canAdd}
                                  isProjectCompleted={isProjectCompleted}
                                  canEdit={canEdit}
                                  handleDeleteItem={decoratedHandleDelete}
                                  handleChangeNotes={decoratedHandleChangeNotes}
                                  item={rate}
                                  itemIDX={itemIDX}
                                  role={role}
                                  isJob={isJob}
                                  title={blockTitle}
                                  heandleOnFocus={decoratedHandleFocus}
                                  heandleOnBlur={decoratedHandleBlur}
                                  handleAddRow={() =>
                                    handleAddRow(
                                      item?.id,
                                      // item.user.labor_rate,
                                      {
                                        hours: item?.workTime?.hours || 0,
                                        minutes: item?.workTime?.minutes || 0,
                                      },
                                    )
                                  }
                                />
                              ),
                          )}
                        <CardTotal
                          additionalSum={blockData?.rates
                            ?.filter(r => r?.job_id === item?.id)
                            ?.reduce((acc, e) => {
                              acc += +e?.time * +e?.cost_per_hour
                              return acc
                            }, 0)}
                          title={blockTitle}
                          item={item}
                          disabled={isProjectCompleted || !canEdit}
                          totalTime={calcTotalLaborTime(
                            blockData?.rates?.filter(
                              r => r?.job_id === item?.id,
                            ),
                          )}
                          handleAddRow={() =>
                            handleAddRow(
                              item?.id,
                              // item?.user?.labor_rate || 0,
                              {
                                hours: item?.workTime?.hours || 0,
                                minutes: item?.workTime?.minutes || 0,
                              },
                            )
                          }
                        />
                      </Panel>
                    </Collapse>
                  </VerticalTimelineElement>
                ))}
            </>
          </VerticalTimeline>
        )}

        {isJob && (
          <>
            {!createFromAppointment && (
              <p className={'block-title'}>Labor Rates</p>
            )}
            {!!blockData?.rates?.length && (
              <VerticalTimeline
                position={'left'}
                classname='first-timeline-element'
                layout={'1-column-left'}
                lineColor={'black'}
              >
                {blockData?.rates?.map(
                  (rate, itemIDX) =>
                    !rate?.job_id && (
                      <VerticalTimelineElement
                        key={itemIDX}
                        className='vertical-timeline-element--work first-timeline-element'
                        contentStyle={cardStyle}
                        contentArrowStyle={contentArrowStyle}
                        iconStyle={iconStyle}
                        icon={<StarRate />}
                        // iconOnClick={() => iconOnClickHeandler(rate.id)}
                      >
                        <Rate
                          key={itemIDX}
                          users={users}
                          handleChangeItem={decoratedHandleChange}
                          canAdd={canAdd}
                          isProjectCompleted={isProjectCompleted}
                          canEdit={canEdit}
                          handleDeleteItem={decoratedHandleDelete}
                          handleChangeNotes={decoratedHandleChangeNotes}
                          item={rate}
                          itemIDX={itemIDX}
                          role={role}
                          isJob={isJob}
                          handleAddRow={handleAddRow}
                          heandleOnFocus={decoratedHandleFocus}
                          heandleOnBlur={decoratedHandleBlur}
                        />
                      </VerticalTimelineElement>
                    ),
                )}
              </VerticalTimeline>
            )}

            <MainButton
              disabled={isProjectCompleted}
              title={`Add rate`}
              onClick={() => handleAddRow()}
            />
          </>
        )}
      </div>
    </>
  )
}

export default BlockItem
