import { CustomFormsOperation } from 'features/Forms/types'
import { selectProjectSettings } from 'features/Project/projectSelectors'
import { selectProjectSlice } from 'features/Project/slices/projectSlice'
import { ProjectStatusGroup } from 'features/Project/types'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompany } from 'store/Orcatec/selectors/company'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { Companies } from 'types/Company'

const useProjectSettings = () => {
  const me = useAppSelector(selectAuthUser)
  const { project, editContract } = useAppSelector(selectProjectSlice)
  const company = useAppSelector(selectCompany)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const projectSettings = useAppSelector(selectProjectSettings)
  const { READ } = usePermissionsByModule(CustomFormsOperation)

  const companyFinancing = company?.financing?.enable_financing

  const canEditGeneral = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_SETTINGS_CAN_EDIT_GENERAL],
    project.user_id,
    me.id,
  )

  const canEditPayment = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_SETTINGS_CAN_EDIT_PAYMENT],
    project.user_id,
    me.id,
  )

  const canEditCustomerView = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_SETTINGS_CAN_EDIT_CUSTOMER_VIEW],
    project.user_id,
    me.id,
  )

  const disabled =
    ![ProjectStatusGroup.Estimate, ProjectStatusGroup.Cold].includes(
      project.status,
    ) && !editContract

  const PROJECT_SETTINGS = [
    {
      title: 'General',
      hasPermissions: canEditGeneral,
      settings: [
        {
          title: 'Add-on items',
          description:
            'Enable to make items optional. Click the lock icon next to an item to let clients include/exclude it from the project scope with a checkbox',
          field_name: 'multiple_choice_items',
          helperText: '',
          disabled: disabled,
        },
        {
          title: 'Show information for customer',
          description:
            'Enable section for extra information for your client, media uploads, and customer comments',
          field_name: 'show_information',
        },
        {
          title: 'Show completion progress',
          description: 'Enable tracking progress of completion per item',
          field_name: 'completion_progress',
          hide: ![
            Companies.BuildX,
            Companies.HVAC,
            Companies.AirLoop,
            Companies.DivineEnergySolutions,
          ].includes(company.id),
        },
        {
          title: 'Multiple tabs for project',
          description:
            'Enable multiple customizable tabs to offer clients more choices, including options like "Good-Better-Best"',
          field_name: 'multiple_tabs',
          disabled: disabled,
          hide: false,
        },
        {
          title: 'Show tags ',
          description: '',
          field_name: 'show_tags',
        },
        {
          title: 'Show forms',
          description: 'Show custom forms attached to the project',
          field_name: 'show_forms',
          helperText:
            !READ &&
            'You do not have permission to view this section. Please contact your administrator',
        },
        {
          title: 'Show templates',
          description:
            'Enable section to create project templates and apply them to projects',
          field_name: 'show_templates',
        },
        {
          title: 'Show notes',
          description: '',
          field_name: 'show_notes',
        },
        {
          title: 'Show modules',
          description:
            'Include extra modules for added actions and alerts in the project',
          field_name: 'show_modules',
          disabled: disabled,
        },
        {
          title: 'Enable groups',
          description: 'Enable to group  single line items',
          field_name: 'show_groups',
        },
        {
          title: 'Enable sections',
          description: 'Enable to create multiple sections in the project',
          field_name: 'show_sections',
        },

        {
          title: 'Show additional labor cost',
          field_name: 'show_installation',
          disabled: disabled,
        },
        /*  {
          title: 'Simple item view',
          description:
            'Items will only include name, description, quantity, and price',
          field_name: 'simple_item_view',
        }, */
      ],
    },
    {
      title: 'Customer view',
      hasPermissions: canEditCustomerView,
      settings: [
        {
          title: 'Show tech contacts',
          description:
            'Technician contact information will be visible to customers',
          field_name: 'show_tech',
        },
        {
          title: 'Show QTY',
          description: 'Show quantity of items',
          field_name: 'show_items_qty',
        },
        {
          title: 'Show description only',
          description: 'Only item description will be visible',
          field_name: 'show_item_description_only',
        },
        {
          title: 'Show price per item',
          description: 'Display price per item in customer view',
          field_name: 'show_price',
          disabled: disabled,
        },
        {
          title: 'Show $0 prices',
          description:
            'When you turn off this option, only the name and description of items priced at $0 will show up for customers',
          field_name: 'hide_zero_price',
          disabled: !projectSettings?.show_price,
        },
        {
          title: 'Require signature',
          description:
            'To accept the project, clients must provide an electronic signature, their name, and agree to your Terms and Conditions',
          field_name: 'require_signature',
          disabled: project?.signature,
        },
      ],
    },
    {
      title: 'Payment',
      hasPermissions: canEditPayment,
      settings: [
        {
          title: 'Enable discounts',
          description: 'Add/Remove ability to add Discount to Project',
          field_name: 'show_discounts',
          disabled: disabled,
        },
        {
          title: 'Enable rebates',
          description: 'Add/Remove ability to add Rebate to Project',
          field_name: 'show_rebates',
          disabled: disabled,
        },
        {
          title: 'Enable taxes',
          description: 'Add/Remove ability to add Tax to Project',
          field_name: 'show_taxes',
          disabled: disabled,
        },
        {
          title: 'Add tips to payouts',
          description:
            'Automatically add tips to tech payouts in project insights',
          field_name: 'include_tips_to_insight',
          disabled: disabled,
        },
        {
          title: 'Auto-create balance in Contract',
          description:
            'Automatically generate balance upon Estimate acceptance',
          field_name: 'balance_auto_creation',
          disabled: disabled,
        },
        {
          title: 'Show tips',
          description: 'Enable tips for customer payments',
          field_name: 'show_tips',
        },
        {
          title: 'Enable Financing',
          description:
            'Allow your clients to apply for consumer financing directly from the project',
          field_name: 'financing',
          hide: !companyFinancing,
        },
        {
          title: 'Payment processing fee',
          description:
            'Automatically add a service fee to every credit card payment',
          field_name: 'payment_processing_fee',
          field_type: 'input',
        },
        {
          title: 'Default payment due',
          description: '',
          field_name: 'default_payment_due_date',
          field_type: 'input',
        },
      ],
    },
  ]

  return PROJECT_SETTINGS
}

export default useProjectSettings
