import React, { useState, useEffect } from 'react'
import * as actions from '../../../../../store/Orcatec/actions'
import moment from 'moment-timezone'
import './ClockOutInBtn.scss'

import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useAppSelector } from 'store/Orcatec/hooks'
import { meSelector } from 'store/SuperAdmin/selectors'
import { useDispatch } from 'react-redux'
import {
  PlayIcon,
  StopIcon,
} from 'layouts/MainLayout/icons/IconSubscriptionBtn'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />
// import { Stop } from '@material-ui/icons'

// import { Pause } from '@material-ui/icons'

let intervalHeaderTime

enum TimeType {
  CLOCK_IN = 1,
  CLOCK_OUT = 2,
}

export const ClockInBtn = () => {
  const dispatch = useDispatch()
  const [time, setTime] = useState()
  const { timeType, clock_in } = useAppSelector(
    state => state.orcatec.timecards.currentTimeType,
  )
  const me = useAppSelector(meSelector)
  const [loading, setLoading] = useState(false)

  const setUserClockInOrOut = () => {
    return dispatch(actions.timecards.setUserClockInOrOut())
  }

  useEffect(() => {
    clearInterval(intervalHeaderTime)
    if (timeType === TimeType.CLOCK_IN) {
      timefunc()
      intervalHeaderTime = setInterval(() => {
        timefunc()
      }, 10000)
    }
  }, [clock_in, timeType])

  const timefunc = () => {
    function getHours(dur) {
      return dur.asHours() < 10
        ? `0${Math.floor(dur.asHours())}`
        : Math.floor(dur.asHours())
    }

    const now = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    const clockedInTime = moment(clock_in)
    const ms =
      moment(now).diff(clockedInTime) < 0 ? 0 : moment(now).diff(clockedInTime)
    const dur = moment.duration(ms)
    const fromatedTime =
      getHours(dur) + 'h ' + moment.utc(ms).format('mm') + 'm'

    setTime(fromatedTime)
  }

  const handleClick = () => {
    setLoading(true)
    if (me.use_clock_in_with_gps && timeType === TimeType.CLOCK_OUT) {
      openNotificationWithIcon('error', {
        message: 'Please use mobile app with geo location enabled',
        description: '',
        key: 'request-for-review-success',
      })
      setLoading(false)
    } else {
      if (loading) {
        return
      }
      setLoading(true)
      setUserClockInOrOut().then(() => setLoading(false))
    }
  }

  return (
    <>
      <Wrapper>
        <Button onClick={handleClick} onDoubleClick={() => null}>
          <span>
            {timeType === TimeType.CLOCK_IN ? (
              <StopIcon />
            ) : (
              <PlayIcon fill='#4285f4' />
            )}
          </span>
          <span style={{ color: '#4285f4' }}>
            {timeType === TimeType.CLOCK_IN ? (
              <Description>
                <small className='blueSmall'>{`Clocked in ${time}`}</small>
              </Description>
            ) : (
              'Clock in'
            )}
          </span>
          <div style={{ width: '30px' }}>
            <Spin spinning={loading} indicator={antIcon} />
          </div>
        </Button>
        {/* {timeType === TimeType.CLOCK_IN && (
          <Description>
            <small className='blueSmall'>{`Clocked in ${time}`}</small>
          </Description>
        )} */}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  & .redSmall {
    color: #cd3535;
    white-space: nowrap;
  }
  & .blueSmall {
    color: #4285f4;
    white-space: nowrap;
    font-size: 12px;
  }
`

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

const Description = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
