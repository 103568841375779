import React from 'react'
import styled from 'styled-components'
import { Divider } from 'antd'
import moment from 'moment-timezone'
import { parsePhoneNumber } from 'features/Messaging/utils'

export const JobPublicInfo = ({ proposalCode, columnsInfo, contacts }) => {
  return (
    <Wrapper>
      <div>
        <span>PO #: </span>
        <b>{proposalCode}</b>
      </div>
      <div>
        <span>Dispatch Name: </span>
        <b>{columnsInfo?.column?.name}</b>
      </div>
      <div>
        <span>Date: </span>
        <b>{moment(columnsInfo?.data).format('MM/DD/YYYY')}</b>
      </div>

      <Divider />
      <h6>Dispatched to: </h6>
      <ContactsWrapper>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            flexWrap: 'wrap',
            paddingLeft: '10px',
          }}
        >
          {contacts.map(contact => (
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={contact?.full_name}
            >
              <Items>
                <b>{contact?.full_name || 'No name'}</b>
              </Items>
              <Items>
                <ContactsWrapperInner style={{ fontSize: '12px' }}>
                  <span>{`Phone:${!!contact?.phones?.length ? '' : '-'}`}</span>
                  <div>
                    {contact?.phones?.map(phone => (
                      <p style={{ fontSize: '12px' }} key={phone.id}>
                        {parsePhoneNumber(phone?.phone)}
                      </p>
                    ))}
                  </div>
                </ContactsWrapperInner>
              </Items>
              <Items>
                <ContactsWrapperInner style={{ fontSize: '12px' }}>
                  <span>{`Email:${!!contact?.emails?.length ? '' : '-'}`}</span>
                  <div>
                    {contact?.emails?.map(email => (
                      <p style={{ fontSize: '12px' }} key={email.id}>
                        {email?.email}
                      </p>
                    ))}
                  </div>
                </ContactsWrapperInner>
              </Items>
            </div>
          ))}
        </div>
      </ContactsWrapper>
    </Wrapper>
  )
}
const Wrapper = styled.div``
const Items = styled.div`
  display: flex;
  flex-direction: column;
`
const ContactsWrapper = styled.div`
  display: flex;
  gap: 5px;
`
const ContactsWrapperInner = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  gap: 2px;
`
