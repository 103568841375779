import React, { FC } from 'react'
import styled from 'styled-components'
import { Rate, Tooltip } from 'antd'
import { IReview } from 'features/Reviews/types'
// import Avatar from 'containers/MainContent/Orcatec/components/Avatar'

import { formatRelativeDate } from 'features/Reviews/helpers/formatRelativeDate'
import { ReviewSource } from '../ReviewSource/ReviewSource'
import { FULL_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'

interface IProps {
  item: IReview
}

export const FeedbackItem: FC<IProps> = ({ item }) => {
  return (
    <Wrapper>
      <HeaderItem>
        <HeaderItemInner>
          <RateItemRow>
            <div>
              <Rate value={item.feedback_rating} disabled />
            </div>
            <p className='feedback-data'>
              <Tooltip
                title={moment(item?.feedback_created_at).format(
                  FULL_DATE_FORMAT,
                )}
              >
                {formatRelativeDate(item?.feedback_created_at)}
              </Tooltip>
            </p>
          </RateItemRow>
        </HeaderItemInner>
        <HeaderItemInner>
          {item.feedback_action !== 1 && (
            <ReviewSource type={item.feedback_action} />
          )}
        </HeaderItemInner>
      </HeaderItem>
      {(item?.name || item?.feedback_name) && (
        <p style={{ textAlign: 'left', color: 'gray' }}>
          {item?.name || item?.feedback_name}
        </p>
      )}
      {item?.feedback && (
        <BodyItem className='review-body'>{item.feedback}</BodyItem>
      )}

      <FooterItem></FooterItem>
    </Wrapper>
  )
}

const Wrapper = styled.article`
  //border-bottom: 1px solid #e7e1e1;
  width: 100%;
  max-width: 500px;
  height: 100%;
  overflow: scroll;
`

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 10px; */
`
const HeaderItemInner = styled.div`
  display: flex;
  align-items: start;
  /* gap: 10px; */
  & .feedback-data {
    color: gray;
  }
`
const BodyItem = styled.div`
  /* padding: 0 20px; */
  max-height: 150px;
  overflow: scroll;
  text-align: left;
`
const FooterItem = styled.div``

const RateItemRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
