import styled from 'styled-components'

const ProposalStatusWrapper = styled.span`
  display: flex;
  justify-content: center;
  padding: 2px 10px;
  color: #fff;
  border-radius: 4px;
  text-transform: capitalize;

  &.contract {
    background-color: #2ebda3;
  }

  &.cancelled {
    background-color: #e53655;
  }

  &.completed {
    background-color: #4285f4;
  }

  &.estimate {
    background-color: rgb(247, 247, 247);
    border: 1px solid rgb(247, 247, 247);
    color: rgb(25, 25, 25);
    width: 100%;
  }

  &.cold {
    background-color: grey;
  }
`

export const ProposalStatus = ({ status }: { status: number }) => {
  let statusName

  switch (status) {
    case 2:
      statusName = 'contract'
      break
    case 3:
      statusName = 'completed'
      break
    case 4:
      statusName = 'cancelled'
      break
    case 5:
      statusName = 'cold'
      break

    default:
      statusName = 'estimate'
  }
  return <ProposalStatusWrapper className={`${statusName}`}>{statusName}</ProposalStatusWrapper>
}
