import styled from 'styled-components'
import { EmailCard } from '../components/EmailCard/EmailCard'
import { OtherEmailCard } from '../components/OtherEmailCard/OtherEmailCard'
import { TemplateSelect } from '../components/TemplateSelect/TemplateSelect'
import { EditorBlock } from '../components/EditorBlock/EditorBlock'

export const NotificationForm = ({
  contacts,
  event,
  template,
  setTemplate,
  isMessagingUse,
  onChangeSelected,
  handleChangePhone,
  phone,
  phonesList,
  handleOtherEmailsInputChange,
  otherEmails,
  handleChangeOtherPhoneInput,
  otherPhone,
  handleChangeTemplate,
  emailValidation,
  smsValidation,
}) => {
  return (
    <Wrapper>
      {contacts.map((contact, idx) => (
        <EmailCard
          key={idx}
          contact={contact}
          isMessagingUse={isMessagingUse}
          onChangeSelected={onChangeSelected}
        />
      ))}
      <OtherEmailCard
        handleOtherEmailsInputChange={handleOtherEmailsInputChange}
        otherEmails={otherEmails}
        isMessagingUse={isMessagingUse}
        handleChangeOtherPhoneInput={handleChangeOtherPhoneInput}
        otherPhone={otherPhone}
      />

      <TemplateSelect
        template={template}
        setTemplate={setTemplate}
        event={event}
      />
      <EditorBlock
        isMessagingUse={isMessagingUse}
        template={template}
        setTemplate={setTemplate}
        handleChangePhone={handleChangePhone}
        phone={phone}
        phonesList={phonesList}
        handleChangeTemplate={handleChangeTemplate}
        emailValidation={emailValidation}
        smsValidation={smsValidation}
        industryId={event.industry_id}
      />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
