import { Wrapper } from './ProjectActions.styles'
import { Tooltip } from 'antd'
import {
  IconActivity,
  IconCopy,
  IconEnvelope,
  IconTime,
  ProjectInsightsIcon,
  SettingsIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import {
  duplicateProject,
  selectProjectSlice,
} from 'features/Project/slices/projectSlice'
import { ModuleName } from 'features/Settings/UsersAndGroups'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import {
  selectIsPriceVisible,
  selectUserPermissionsByModule,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { ProjectSettingsModal } from '../ProjectSettingsModal/ProjectSettingsModal'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { ModalDuplicateProject } from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalDuplicateProject/ModalDuplicateProject'
import ModalClientActivity from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalClientActivity'
import { ModalProjectHistory } from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalProjectHistory/ModalProjectHistory'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import ModalAccounting from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalAccounting'
import { selectProjectTotal } from 'features/Project/projectSelectors'
import {
  NotificationModalType,
  NotificationTemplateType,
} from 'features/Notification/types'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import { QuickbooksIcon } from 'layouts/MainLayout/Header/components/Navigation/NavigationIcons'
import { ProjectQuickbooks } from 'features/Project/Quickbooks/ProjectQuickbooks'
import { selectSummaryContact } from 'features/Project/slices/projectContactsSlice'

// import { ProjectQuickbooks } from 'features/Project/Quickbooks/ProjectQuickbooks'

export const ProjectActions = () => {
  const dispatch = useAppDispatch()

  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const me = useAppSelector(selectAuthUser)
  const { project } = useAppSelector(selectProjectSlice)
  const { client: contacts } = useAppSelector(selectSummaryContact)
  const isPriceVisible = useAppSelector(selectIsPriceVisible(project?.user_id))
  const projectTotal = useAppSelector(selectProjectTotal)

  const [settingsModal, showSettingsModal] = useState(false)
  const [duplicateModal, showDuplicateModal] = useState(false)
  const [activityModal, showActivityModal] = useState(false)
  const [historyModal, showHistoryModal] = useState(false)
  const [accountingModal, showAccountingModal] = useState(false)
  const [emailModal, showEmailModal] = useState(false)
  const [quickbooksModal, showQuickbooksModal] = useState(false)
  const [quickbooksIcon, showQuickbooksIcon] = useState(false)

  const openSettingsModal = () => {
    showSettingsModal(true)
  }

  const handleProjectDuplicate = async (
    projectId: number,
    projectCode: string,
  ) => {
    const res = await dispatch(duplicateProject({ projectId, projectCode }))

    if (res.meta.requestStatus === 'rejected') {
      return openNotificationWithIcon('error', {
        message:
          res?.payload?.response?.data?.message || 'something went wrong',
      })
    }

    return res.payload
  }

  const ACTIONS = {
    settings: {
      name: 'settings',
      icon: <SettingsIcon />,
      tooltip: 'Project Settings',
      hasPermissions: checkAccessControl(
        permissions.project_settings_can_read,
        project.user_id,
        me.id,
      ),
      visible: true,
      onClick: openSettingsModal,
    },
    projectInsights: {
      name: 'projectInsights',
      icon: <ProjectInsightsIcon />,
      tooltip: 'Project Insights',
      hasPermissions:
        checkAccessControl(
          permissions.project_insights_can_read,
          project.user_id,
          me.id,
        ) && isPriceVisible,
      visible: true,
      onClick: () => showAccountingModal(true),
    },
    projectCopy: {
      name: 'projectCopy',
      icon: <IconCopy />,
      tooltip: 'Copy Project',
      hasPermissions: checkAccessControl(
        permissions.project_can_duplicate,
        project.user_id,
        me.id,
      ),
      visible: true,
      onClick: () => showDuplicateModal(true),
    },
    clientActivity: {
      name: 'clientActivity',
      icon: (
        <IconActivity
          style={{
            color: project.had_been_viewed ? '#28a745' : '#4D4D4D',
          }}
        />
      ),
      tooltip: 'Viewing History',
      hasPermissions: true,
      visible: true,
      onClick: () => showActivityModal(true),
    },
    projectHistory: {
      name: 'projectHistory',
      icon: <IconTime />,
      tooltip: 'Project History',
      hasPermissions: true,
      visible: true,
      onClick: () => showHistoryModal(true),
    },
    projectSend: {
      name: 'projectSend',
      icon: (
        <IconEnvelope
          style={{
            color: project.had_been_sent ? '#28a745' : '#4D4D4D',
          }}
        />
      ),
      tooltip: 'Send Project',
      hasPermissions: !!isPriceVisible,
      visible: true,
      onClick: () => showEmailModal(true),
    },
    quickbooks: {
      name: 'quickbooks',
      icon: <QuickbooksIcon width={20} />,
      tooltip: 'QuickBooks',
      hasPermissions: true,
      visible: quickbooksIcon,
      onClick: () => showQuickbooksModal(true),
    },
  }

  const handleQuickbooksChange = (value: boolean) => showQuickbooksIcon(value)

  const handleActionClick = (actionName: string) => {
    if (!ACTIONS[actionName]?.hasPermissions)
      return openNotificationWithIcon('warning', {
        message: 'You do not have permission to perform this action',
      })

    ACTIONS[actionName]?.onClick()
  }

  return (
    <Wrapper>
      {Object.values(ACTIONS)
        .filter(action => action.visible)
        .map(action => (
          <Tooltip key={action.name} title={action.tooltip}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleActionClick(action.name)}
            >
              {action.icon}
            </span>
          </Tooltip>
        ))}

      {settingsModal && (
        <ProjectSettingsModal
          onClose={() => showSettingsModal(false)}
          projectId={project.id}
        />
      )}
      {duplicateModal && (
        <ModalDuplicateProject
          visible
          onClose={() => showDuplicateModal(false)}
          proposalID={project.id}
          handleDuplicate={handleProjectDuplicate}
          proposalCode={project?.code}
          assignedTo={project?.user_id}
        />
      )}

      {activityModal && (
        <ModalClientActivity
          activity={project.activity}
          onCancel={() => showActivityModal(false)}
        />
      )}

      {historyModal && (
        <ModalProjectHistory
          handleCancel={() => showHistoryModal(false)}
          handleOk={() => showHistoryModal(false)}
          isModalOpen
          proposalId={project.id}
        />
      )}

      {accountingModal && (
        <Modal
          className={'accounting-modal'}
          width={'90%'}
          closable={false}
          footer={null}
          visible
          centered
          destroyOnClose
          bodyStyle={{
            marginTop: '10px',
            overflow: 'scroll',
            height: '97vh',
          }}
        >
          <ModalAccounting
            totalToPay={projectTotal}
            proposalID={project?.id}
            closeModal={() => showAccountingModal(false)}
            user={project?.user}
            projectStatus={project?.status}
          />
        </Modal>
      )}

      {emailModal && (
        <NotificationModal
          title='Send Project'
          showModal
          setShowModal={() => showEmailModal(false)}
          templateType={NotificationTemplateType.PROJECT}
          notificationType={NotificationModalType.PROJECT}
          proposalForm={project}
          clients={contacts.map(contact => ({
            ...contact,
            emails: contact.emails?.map(email => ({ email })),
          }))}
        />
      )}

      <ProjectQuickbooks
        visible={quickbooksModal}
        onClose={() => showQuickbooksModal(false)}
        onChange={handleQuickbooksChange}
      />
    </Wrapper>
  )
}
