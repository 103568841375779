import * as types from './../../types/mail-client/folder'

export default (
  state = {
    isError: false,
    process: false,
    folders: [],
    aliases: {},
  },
  action,
) => {
  switch (action.type) {
    case types.FETCH_FOLDERS_START:
      return {
        ...state,
        isError: false,
        process: true,
      }
    case types.FETCH_FOLDERS_SUCCESS:
    case types.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        isError: false,
        process: false,
        folders: action.payload.folders,
        aliases: action.payload.aliases,
      }
    case types.FETCH_FOLDERS_ERROR:
      return {
        ...state,
        isError: true,
        process: false,
        error: action.payload,
        folders: [],
        aliases: [],
      }
    case types.DECREASE_UNSEEN_COUNT:
      return {
        ...state,
        folders: action.payload,
      }
    default:
      return state
  }
}
