import { Button, Progress, Spin } from 'antd'
import { Modal } from 'containers/MainContent/Orcatec/components/UI/Modal'
import { ItemProgressEntry, ProjectItem } from 'features/Project/types'
import { useEffect, useState } from 'react'
import { ProgressEntry } from './components/ProgressEntry'
import { getProgressStrokeColor } from '../../helpers'
import { ItemEntriesAPI } from 'api/Project'
import {
  Content,
  ItemDescription,
  ItemInfo,
  ItemName,
  ProgressInfo,
} from './ProjectItemProgress.styles'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { round } from 'helpers/Math'
import { ProgressTable } from './components/ProgressTable'
// import { InfoBlock } from 'containers/MainContent/Orcatec/components/UI/InfoBlock/InfoBlock'

interface Props {
  disabled?: boolean
  item: ProjectItem
  onClose: (updatedItem: ProjectItem) => void
}

export const ProjectItemProgress = ({ disabled, item, onClose }: Props) => {
  const [progressEntries, setProgressEntries] = useState<
    Record<number, ItemProgressEntry>
  >([])
  const [entryId, setEntryId] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)

  const totalInstalled = round(
    Object.values(progressEntries).reduce(
      (sum, value) => (sum += value.qty),
      0,
    ),
  )

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const data = await ItemEntriesAPI.getItemEntriesList({ item_id: item.id })
      setProgressEntries(transformArrayToObj(data))
      setLoading(false)
    }
    getData()
  }, [item.id])

  const handleEntrySave = async (
    entry: ItemProgressEntry,
    entryNotes: string,
  ) => {
    setLoading(true)
    const savedEntry = entry.id
      ? await ItemEntriesAPI.updateItemEntry(entry)
      : await ItemEntriesAPI.createItemEntry({
          ...entry,
          item_id: item.id,
        })

    if (!entry.id && entryNotes) {
      await ItemEntriesAPI.addEntryNotes(savedEntry.id, entryNotes)
    }

    setProgressEntries(prev => ({
      ...prev,
      [savedEntry.id]: savedEntry,
    }))
    setLoading(false)
    setEntryId(null)
  }

  const handleEntryDelete = async (entryId: number) => {
    await ItemEntriesAPI.deleteItemEntry(entryId)

    const newProgressEntries = { ...progressEntries }
    delete newProgressEntries[entryId]

    setProgressEntries(newProgressEntries)
    setEntryId(null)
  }

  const handleCloseModal = () =>
    onClose({
      ...item,
      progress: !item.qty ? 0 : Math.round((totalInstalled / item.qty) * 100),
      installed: totalInstalled,
    })

  return (
    <Modal
      visible={true}
      // title={`${item.name} Progress`}
      title={<ItemName>{item.name}</ItemName>}
      width={700}
      onCancel={handleCloseModal}
      footer={[
        <Button key='close' onClick={handleCloseModal}>
          Close
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Content>
          <ItemInfo>
            <ItemName>{item.name}</ItemName>
            <ItemDescription>{item.description}</ItemDescription>
            <p style={{ marginTop: 20, fontWeight: 500 }}>
              Estimated Qty: {item.qty} {item.unit || 'Qty'}
            </p>

            <div>
              <p>% Complete:</p>
              <ProgressInfo>
                <Progress
                  percent={Math.round((totalInstalled / item.qty) * 100)}
                  strokeColor={getProgressStrokeColor(
                    (totalInstalled / item.qty) * 100,
                  )}
                  trailColor={
                    Math.round((totalInstalled / item.qty) * 100) < 0
                      ? '#ff6f70'
                      : '#e8e8e8'
                  }
                  showInfo={false}
                />
                <p>{Math.round((totalInstalled / item.qty) * 100)}%</p>
              </ProgressInfo>
            </div>
          </ItemInfo>

          {/* {disabled && (
            <InfoBlock
              type='info'
              style={{
                marginBottom: 20,
              }}
            >
              You can&apos;t apply any changes to the progress unless the
              project is in{' '}
              <span style={{ fontWeight: 500 }}>&quot;Contract&quot;</span>{' '}
              status
            </InfoBlock>
          )} */}

          <ProgressTable
            data={Object.values(progressEntries)}
            onClickEntry={(id: number) => setEntryId(id)}
            totalInstalled={totalInstalled}
            estimatedQty={item.qty}
            disabled={!!disabled}
          />

          {entryId !== null && (
            <ProgressEntry
              id={entryId}
              onClose={() => setEntryId(null)}
              estimatedQty={item.qty}
              installedQty={totalInstalled}
              itemName={item.name}
              unit={item.unit}
              onSave={handleEntrySave}
              onEntryDelete={handleEntryDelete}
              loading={loading}
              disabled={!!disabled}
            />
          )}
        </Content>
      </Spin>
    </Modal>
  )
}
