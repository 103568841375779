import { Input } from 'antd'
import withLabelAndError from '../helpers/Wrapper'
import {
  InputProps,
  SearchProps,
  TextAreaProps,
  PasswordProps,
} from 'antd/lib/input'

const {
  TextArea: TextAreaAnt,
  Search: AntSearch,
  Password: AntPassword,
} = Input

const TextField = ({ ...props }) => (
  <Input
    status={props.error ? 'error' : undefined}
    style={{ width: '100%' }}
    autoComplete='off'
    {...props}
  />
)

const TextArea = ({ ...props }) => (
  <TextAreaAnt
    autoSize
    status={props.error ? 'error' : undefined}
    style={{ width: '100%' }}
    {...props}
  />
)

const Password = ({ ...props }) => (
  <AntPassword
    status={props.error ? 'error' : undefined}
    style={{ width: '100%' }}
    {...props}
  />
)

const Search = ({ ...props }) => (
  <AntSearch
    status={props.error ? 'error' : undefined}
    style={{ width: '100%' }}
    {...props}
  />
)

export const TextFieldWithLabelAndError = withLabelAndError<InputProps>(
  TextField,
)
export const TextAreaWithLabelAndError = withLabelAndError<TextAreaProps>(
  TextArea,
)
export const PasswordWithLabelAndError = withLabelAndError<PasswordProps>(
  Password,
)
export const SearchWithLabelAndError = withLabelAndError<SearchProps>(Search)
