import React from 'react'

import { downloadAttachment } from '../../../../../../api/supportTickets'

const MessageAttachment = ({ attachment }) => {
  const handleDownload = () => {
    if (attachment) {
      downloadAttachment(attachment.id).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: attachment.mime_type }))

        if (attachment.mime_type.startsWith('image/') || attachment.mime_type === 'application/pdf') {
          window.open(url, attachment.file_name)
        } else {
          const link = document.createElement('a')
          link.href = url
          link.download = attachment.file_name

          document.body.appendChild(link)
          link.click()

          document.body.removeChild(link)
        }

        setTimeout(function() {
          window.URL.revokeObjectURL(url)
        }, 0)
      })
    }
  }

  return (
    <div className='ticket-message-attachment__file' onClick={handleDownload}>
      {!!attachment && attachment.file_name}
    </div>
  )
}

export default MessageAttachment
