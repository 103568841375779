import axiosOrcatec from 'api/axiosInstance'

export const getCallsData = params => axiosOrcatec.get('/calls', { params }).then(r => r && r.data)
export const getCallDataByClient = clientId =>
  axiosOrcatec.get(`/calls/clients/${clientId}`).then(r => r && r.data.data)

export const postAssignUserToCallRecord = (recordId, userId) => {
  return axiosOrcatec.post(`/calls/${recordId}/attach-user/${userId}`).then(r => r && r.data)
}
export const postDetachUserToCallRecord = (recordId, userId) => {
  return axiosOrcatec.post(`/calls/${recordId}/detach-user/${userId}`).then(r => r && r.data)
}
export const getActiveCall = () => {
  return axiosOrcatec.get(`/calls/active`).then(r => r && r.data.data)
}

export const setPrimaryClient = (callRecordId: number, data: { client_id: number[] }) => {
  return axiosOrcatec.post(`calls/${callRecordId}/primary-clients`, data).then(r => r && r.data.data)
}
export const getListNumbers = (params) => {
  return axiosOrcatec.get(`/setting/call-tracking/voice-proxy/phone-numbers/purchase`, { params }).then(r => r.data)
}
export const buyNumber = (params) => {
  return axiosOrcatec.post(`/setting/call-tracking/voice-proxy/phone-numbers/purchase`, { ...params }).then(r => r.data)
}



export const getSettingsListTable = () => {
  return axiosOrcatec.get(`setting/call-tracking/voice-proxy/phone-numbers/internal`).then(r => r.data)
}


export const forwardToNumber = (numberId: number, params: { forward_to_number: string }) => {
  return axiosOrcatec.post(`/setting/call-tracking/voice-proxy/phone-numbers/internal/${numberId}/forward-to`, { ...params }).then(r => r.data)
}

export const deleteInternalNumber = (numberId: number) => {
  return axiosOrcatec.delete(`/setting/call-tracking/voice-proxy/phone-numbers/${numberId}/delete`).then(r => r.data)
}


export const addSourcesToNumber = (numberId: number, params: { source_id: number[] }) => {
  return axiosOrcatec.put(`/setting/call-tracking/voice-proxy/phone-numbers/internal/${numberId}/sources`, { ...params }).then(r => r.data)
}

export const getCallTrackingSettings = () => {
  return axiosOrcatec.get(`/setting/call-tracking`).then(r => r.data)
}
export const putCallTrackingSettings = (value: boolean | number, key: string) => {
  return axiosOrcatec.put(`/setting/call-tracking/${key} `, { [key]: value }).then(r => r.data)
}




