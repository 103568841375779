
export const openFileInNewTab=(fullFileUrl)=>{
    if (/^(data:).+(base64).+/g.test(fullFileUrl)) {
      const newTab = window.open()

      newTab.document.body.style.cssText =
        'margin: 0px; background: #0e0e0e; height: 100%;display: flex;justify-content: center;align-items: center'
      newTab.document.body.innerHTML = `<img style="-webkit-user-select: none;margin: auto;" src="${fullFileUrl}">`
    } else {
      window.open(`${fullFileUrl}`, '_blank')
    }
}

