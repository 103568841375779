import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'antd'
import MainButton from '../../components/buttons/MainButton'
import './CancelRegistrationModal.scss'

const cancelButtonStyle = { color: '#191919', border: '1px solid #D6D6D6', marginRight: '10px' }

const CancelRegistrationModal = props => {
  return (
    <Modal show={props.modal} backdrop='static' onHide={props.close} className='cancel-registration-modal'>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='w-100 d-flex justify-content-center'>
          <div className='cancel-registration-modal-title'>Are you sure you want to cancel registration process?</div>
        </div>
        <div className='d-flex justify-content-center buttons'>
          <Button
            variant='default'
            onClick={props.close}
            className='main-button user-cancel-btn'
            style={cancelButtonStyle}
          >
            No
          </Button>
          <MainButton title='Yes' onClick={props.apply} className='main-button' />
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default CancelRegistrationModal
