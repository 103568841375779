import React from 'react'

import './styles.scss'

const ModuleStatus = ({ statusList, currentStatus, onChangeStatus, payment = false }) => {
  const notEditable = payment || !onChangeStatus
  const handleClick = id => {
    if (notEditable) return
    onChangeStatus(id)
  }

  return (
    <ul className={`module-statuses${payment ? ' module-payment' : !onChangeStatus ? ' read-only' : ''}`}>
      {statusList.map(status => (
        <li
          key={status.id}
          className={`status${currentStatus === status.id ? ' active' : ''}`}
          onClick={() => handleClick(status.id)}
        >
          {status.name}
        </li>
      ))}
    </ul>
  )
}

export default ModuleStatus
