import styled from 'styled-components'
import ProjectSettings from './components/ProjectSettings'
import SidebarBlock from 'features/Project/components/EditProject/components/ProjectSidebar/components/SidebarBlock'

export const ProjectPreviewSidebar = () => {
  return (
    <Wrapper>
      <SidebarBlock>
        <Section>
          <SectionTitle>Settings</SectionTitle>
          <ProjectSettings />
        </Section>
      </SidebarBlock>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Section = styled.div``
const SectionTitle = styled.h6``
