import React from 'react'
import { connect } from 'react-redux'
import * as actions from 'store/Orcatec/actions'
import ModalReminder from './ModalReminder'
import { Companies } from 'types/Company'
import { UserRole } from 'types/User'
class ModalReminderContainer extends React.Component {
  state = {
    firstChange: false,
  }

  componentDidMount() {
    this.setState({ ...this.props.values })
  }

  toggleModal = () => {
    if (this.props.module === 0) this.props.openModalOptional1()
    if (this.props.module === 1) this.props.openModalOptional2()
    if (this.props.module === 2) this.props.openModalOptional3()
  }

  modal = () => {
    if (this.props.module === 0) return this.props.modalOptional1.isOpen
    if (this.props.module === 1) return this.props.modalOptional2.isOpen
    if (this.props.module === 2) return this.props.modalOptional3.isOpen
  }

  onChange = (field, e) => {
    const value =
      /* field === 'status' ? (e.target.checked ? 3 : 2) : */ e.target.value
    this.props.onChange(this.props.activeTab, this.props.module, field, value)
  }

  onChangeStatus = value => {
    if (this.props.proposalStatus === 3) return

    // if (
    // this.props.module === 2 &&
    // this.props.company.id === Companies['Big Joe Appliance Repair Service'] && // HARDCODE for Big Joe
    //   ![1337, 1339, 1340].includes(this.props.me.id) &&
    //   ![
    //     UserRole.OWNER,
    //     UserRole.SUPER_ADMIN,
    //     UserRole.DEFAULT_SUPER_ADMIN,
    //   ].includes(this.props.me.role_id)
    // )
    //   return

    this.props.onChange(
      this.props.activeTab,
      this.props.module,
      'status',
      value,
    )
  }

  // onReset = () => {
  //   this.props.onReset(this.props.activeTab, this.props.module)
  // }

  onCancel = () => {
    // this.props.onChange(this.props.activeTab, this.props.module, {
    //   notes: this.state.notes,
    //   status: this.state.status,
    // })
    this.setState({ firstChange: false })
    this.toggleModal()
  }

  onSave = () => {
    if (!this.props?.onSave) return this.toggleModal()

    this.props.onSave().then(() => {
      this.setState({ firstChange: false, ...this.props.values })
      this.toggleModal()
    })
  }

  render() {
    return (
      <ModalReminder
        show={this.modal()}
        onHide={this.onCancel}
        module={this.props.module}
        values={this.props.values}
        // onReset={this.onReset}
        onChange={this.onChange}
        onChangeStatus={this.onChangeStatus}
        onSave={this.onSave}
        proposalStatus={this.props.proposalStatus}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    me: state.orcatec.user.me,
    company: state.orcatec.company,
    modalOptional1: state.orcatec.modalOptional1,
    modalOptional2: state.orcatec.modalOptional2,
    modalOptional3: state.orcatec.modalOptional3,
  }
}
const mapDispatchToProps = {
  openModalOptional1: actions.modalOptional1.openModal,
  openModalOptional2: actions.modalOptional2.openModal,
  openModalOptional3: actions.modalOptional3.openModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalReminderContainer)
