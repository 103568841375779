import PageLayout from 'containers/MainContent/Orcatec/Layouts/PageLayout'
import { Result } from 'antd'

export const NoAccessResult = ({ message }: { message?: string }) => (
  <PageLayout>
    <Result
      status='403'
      subTitle={
        !message
          ? 'You don’t have access to this module, please contact your administrator'
          : message
      }
    />
  </PageLayout>
)
