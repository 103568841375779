import styled from 'styled-components'
import { useAppSelector } from 'store/Orcatec/hooks'
import { ContactForm } from './components/ContactForm/ContactForm'
import { PropertyForm } from './components/PropertyForm/PropertyFrom'
import { AppointmentForm, AppointmentsHistoryTable } from 'features/Appointment'
import { ProjectForm } from './components/ProjectForm/ProjectForm'
import { Collapse, Badge, Drawer } from 'antd'
import { useState } from 'react'
import { IContacts } from 'features/Contacts/types'
import {
  ContactsIcon,
  PropertiesIcon,
  DispatchIcon,
  ProjectsIcon,
  MessagingIcon,
} from 'layouts/MainLayout/Header/components/Navigation/NavigationIcons'
import {
  AddIcon,
  OrganizationIcon,
  ClientIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { Phone, KeyboardBackspace } from '@material-ui/icons'
import { ProposalHistoryTable } from 'features/Project'
import { PropertiesHistoryTable } from 'features/Properties'
import { CallsHistoryTable } from 'features/Calls/components/Tables/CallsHistoryTable'

import { MessagingHistoryTable } from 'features/Messaging/Tables/MessagingHistoryTable'
import MembershipEntries from 'features/Membership/MembershipEntries'
import {
  selectCurrentContact,
  selectContactSlice,
  togglePropertyFormAction,
  toggleAppointmentFormAction,
} from 'features/Contacts/slice/contactSlice'
import { ContactsTable } from './components/Tables/ContactsTable'
import { ClientType } from 'types/Client'
import { MembershipForm } from './components/MembershipForm/MembershipForm'
import MembershipLabels from 'features/Membership/components/MembershipLabels'
import { OrganizationsTable } from './components/Tables/OrganizationsTable'
import { useDispatch } from 'react-redux'
import { selectModulePermissions } from 'features/Settings/UsersAndGroups/permissionSlice'

const { Panel } = Collapse

const Button = ({ handler }) => {
  return (
    <button className='btn add color_gray' onClick={handler}>
      <AddIcon disabled={false} />
    </button>
  )
}

export const Form = ({ contact }: IContacts) => {
  const dispatch = useDispatch()
  const currentContact = useAppSelector(selectCurrentContact)
  const { showPropertyForm, showAppointmentForm } = useAppSelector(
    selectContactSlice,
  )
  const {
    messaging: isMessagingPermission,
    memberships: isActiveMemberships,
  } = useAppSelector(selectModulePermissions())
  const [childrenDrawer, setChildrenDrawer] = useState(false)
  // const [showAppointmentForm, setShowAppointmentForm] = useState(false)
  // const [showPropertyForm, setShowPropertyForm] = useState(false)
  const [showProjectForm, setShowProjectForm] = useState(false)
  const [showContactForm, setShowContactForm] = useState(false)
  const [showMembershipForm, setShowMembershipForm] = useState(false)
  const [title, setTitle] = useState('')

  const onClosePropertyForm = () => {
    dispatch(togglePropertyFormAction(false))
    if (!showAppointmentForm) {
      setChildrenDrawer(false)
      setTitle('')
    }
  }
  const onCloseProjectForm = () => {
    setShowProjectForm(false)
    setChildrenDrawer(false)
    setTitle('')
  }
  const onCloseContactForm = () => {
    setShowContactForm(false)
    setChildrenDrawer(false)
    setTitle('')
  }
  const onCloseAppointmentForm = () => {
    dispatch(toggleAppointmentFormAction(false))
    // setShowAppointmentForm(false)
    setChildrenDrawer(false)
    setTitle('')
  }

  const onCloseMembershipForm = () => {
    setShowMembershipForm(false)
    setChildrenDrawer(false)
    setTitle('')
  }

  const onOpenPropertyForm = () => {
    dispatch(togglePropertyFormAction(true))
    setChildrenDrawer(true)
    setTitle('New Property')
  }
  const onOpenProjectForm = () => {
    setShowProjectForm(true)
    setChildrenDrawer(true)
    setTitle('New Project')
  }
  const onOpenContactForm = () => {
    setShowContactForm(true)
    setChildrenDrawer(true)
    setTitle('New Contact')
  }
  const onOpenAppointmentForm = () => {
    // setShowAppointmentForm(true)
    dispatch(toggleAppointmentFormAction(true))
    setChildrenDrawer(true)
    setTitle('New Appointment')
  }
  const onOpenMembershipForm = () => {
    setShowMembershipForm(true)
    setChildrenDrawer(true)
    setTitle('New Membership')
  }

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false)
    dispatch(toggleAppointmentFormAction(false))
    dispatch(togglePropertyFormAction(false))
    setShowProjectForm(false)
    setShowContactForm(false)
    setShowMembershipForm(false)
  }

  const isContactInSystem: boolean = currentContact?.id
  const isContact: boolean =
    currentContact?.type === ClientType.INDIVIDUAL ||
    currentContact?.type === ClientType.CONTACT
  const isOrganization: boolean =
    currentContact?.type === ClientType.ORGANIZATION

  return (
    <Wrapper>
      <Collapse defaultActiveKey={contact?.id ? [] : ['1']} bordered={false}>
        <Panel
          forceRender
          header={
            <HeaderIconWrapper>
              {isContact ? <ClientIcon /> : <OrganizationIcon />}

              <b>{isContact ? 'Contact info' : 'Organization info'}</b>
            </HeaderIconWrapper>
          }
          key='1'
        >
          <ContactForm contact={contact} />
        </Panel>

        {isContactInSystem && (
          <>
            {isOrganization && (
              <Panel
                header={
                  <HeaderIconWrapper>
                    <ContactsIcon />
                    <Badge
                      style={{ right: '-10px' }}
                      color='#007bff'
                      count={currentContact?.contacts?.length}
                    >
                      <b>Contacts</b>
                    </Badge>
                    <Button handler={onOpenContactForm} />
                  </HeaderIconWrapper>
                }
                key='2'
              >
                <ContactsTable tableData={currentContact?.contacts} />
              </Panel>
            )}

            <Panel
              header={
                <HeaderIconWrapper>
                  <PropertiesIcon />
                  <Badge
                    style={{ right: '-10px' }}
                    color='#007bff'
                    count={currentContact?.properties?.length}
                  >
                    <b>Properties</b>
                  </Badge>
                  <Button handler={onOpenPropertyForm} />
                </HeaderIconWrapper>
              }
              key='3'
            >
              <PropertiesHistoryTable tableData={currentContact.properties} />
            </Panel>
            <Panel
              header={
                <HeaderIconWrapper>
                  <ProjectsIcon />
                  <Badge
                    style={{ right: '-10px' }}
                    color='#007bff'
                    count={currentContact?.proposals?.length}
                  >
                    <b>Projects</b>
                  </Badge>
                  <Button handler={onOpenProjectForm} />
                </HeaderIconWrapper>
              }
              key='4'
            >
              <ProposalHistoryTable tableData={currentContact.proposals} />
            </Panel>
            <Panel
              header={
                <HeaderIconWrapper>
                  <DispatchIcon />
                  <Badge
                    style={{ right: '-10px' }}
                    color='#007bff'
                    count={currentContact.appointments?.length}
                  >
                    <b>Appointments</b>
                  </Badge>
                  <Button handler={onOpenAppointmentForm} />
                </HeaderIconWrapper>
              }
              key='5'
            >
              <AppointmentsHistoryTable
                tableData={currentContact?.appointments}
              />
            </Panel>

            {isContact && (
              <Panel
                header={
                  <HeaderIconWrapper>
                    <OrganizationIcon />
                    <Badge
                      style={{ right: '-10px' }}
                      color='#007bff'
                      count={currentContact.organizations?.length}
                    >
                      <b>Organizations</b>
                    </Badge>
                    <Button handler={onOpenContactForm} />
                  </HeaderIconWrapper>
                }
                key='6'
              >
                <OrganizationsTable tableData={currentContact.organizations} />
              </Panel>
            )}

            {/* {isContact && (
              <Panel
                header={
                  <HeaderIconWrapper>
                    <ContactsIcon />
                    <Badge
                      style={{ right: '-10px' }}
                      color='#007bff'
                      count={currentContact.client_associations?.length}
                    >
                      <b>Associations</b>
                    </Badge>
                    <Button handler={onOpenContactForm} />
                  </HeaderIconWrapper>
                }
                key='7'
              >
                <AssociationsTable tableData={currentContact.client_associations} />
              </Panel>
            )} */}
            {isMessagingPermission && (
              <Panel
                header={
                  <HeaderIconWrapper>
                    <MessagingIcon />
                    <b>Messaging</b>
                  </HeaderIconWrapper>
                }
                key='8'
              >
                <MessagingHistoryTable tableData={[]} />
              </Panel>
            )}

            <Panel
              header={
                <HeaderIconWrapper>
                  <Phone />
                  <Badge
                    style={{ right: '-10px' }}
                    color='#007bff'
                    count={currentContact.calls?.length}
                  >
                    <b>Calls</b>
                  </Badge>
                </HeaderIconWrapper>
              }
              key='9'
            >
              <CallsHistoryTable tableData={currentContact?.calls} />
            </Panel>
            {isActiveMemberships && (
              <Panel
                header={
                  <HeaderIconWrapper
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <ContactsIcon />

                    <b>Memberships</b>
                    <Button handler={onOpenMembershipForm} />
                    <MembershipLabels
                      memberships={currentContact.memberships}
                    />
                  </HeaderIconWrapper>
                }
                key='10'
              >
                <MembershipEntries
                  entityType='clients'
                  entityId={currentContact.id}
                  hideAddBtn={true}
                  data={currentContact.memberships}
                />
              </Panel>
            )}
          </>
        )}
      </Collapse>
      <Drawer
        destroyOnClose={false}
        closeIcon={
          <KeyboardBackspace style={{ transform: 'rotate(180deg)' }} />
        }
        title={title}
        mask={false}
        width={window.innerWidth > 900 ? 650 : window.innerWidth - 50}
        onClose={onChildrenDrawerClose}
        visible={childrenDrawer || showAppointmentForm}
      >
        {showPropertyForm && <PropertyForm onCancel={onClosePropertyForm} />}
        {showAppointmentForm && (
          <AppointmentForm onCancel={onCloseAppointmentForm} />
        )}
        {showProjectForm && <ProjectForm onCancel={onCloseProjectForm} />}
        {showContactForm && (
          <ContactForm
            parentContact={currentContact}
            openFromParent={showContactForm}
            onCancel={onCloseContactForm}
          />
        )}
        {showMembershipForm && (
          <MembershipForm onCancel={onCloseMembershipForm} />
        )}
      </Drawer>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const HeaderIconWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 50px;
  align-items: center;
  gap: 4px;
`
