import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormRow } from 'features/Contacts/common/FormRow'
import styled from 'styled-components'
import SelectField from 'containers/MainContent/Orcatec/components/Select'
import InputField from 'containers/MainContent/Orcatec/components/UI/InputField'
import SearchAddress from 'containers/MainContent/Orcatec/components/SearchAddress'
import Notes from 'containers/MainContent/Orcatec/components/Notes'
import Tags from 'containers/MainContent/Orcatec/Properties/components/PropertyForm/components/Tags'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import {
  selectCurrentContact,
  updateProperty,
} from 'features/Contacts/slice/contactSlice'
import { useAppSelector } from '../../../../../../store/Orcatec/hooks/index'
import { putContact } from 'api/Contacts/Contacts'
import { Property, PropertyType } from 'types/Property'
import { createProperty } from 'api/Property'
import Map from 'containers/MainContent/Orcatec/components/Map'
import { getAddressWithUnit } from 'helpers/getAddressWithUnit'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

const Wrapper = styled.div`
  & .client-properties {
    overflow: scroll;
  }
  & .leaflet-container {
    height: 300px;
  }
  & .search-input__table {
    position: fixed;
    right: 0;
    top: unset;
    min-width: 650px;
    max-width: 650px;
  }
`
const ControlsRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: end;
  gap: 10px;
`
const options = [
  {
    id: 1,
    name: 'Commercial',
  },
  {
    id: 2,
    name: 'Residential',
  },
]

const initialState: Property = {
  address: '',
  postcode: '',
  unit: '',
  city: '',
  state: '',
  type: PropertyType.RESIDENTIAL,
  notes: '',
  tags: [],
  full_address: '',
  is_manual: false,
  owner_user_id: 0,
  lat: null,
  lng: null,
  id: 0,
}

export const PropertyForm = ({ onCancel }) => {
  const dispatch = useDispatch()
  const ownerId = useAppSelector(state => state.orcatec.company.owner_id)
  const [loading, setLoading] = useState(false)
  const [property, setProperty] = useState(initialState)
  const [manualInput, setManualInput] = useState(false)
  const [chooseProperty, setChooseProperty] = useState(false)
  const [propertyToSave, setPropertyToSave] = useState(null)
  const currentContact = useAppSelector(selectCurrentContact)
  const handleChangeManual = () => {
    setManualInput(!manualInput)
    setProperty(prev => ({
      ...prev,
      is_manual: !manualInput,
    }))
  }
  const handleChangeSearchValue = value =>
    setProperty(prev => ({
      ...prev,
      address: value,
    }))

  const onChooseAddress = obj => {
    const data = {
      address: obj.address ? obj.address : '',
      postcode: obj.postcode ? obj.postcode : '',
      city: obj.city ? obj.city : '',
      state: obj.state ? obj.state : '',
      unit: obj.unit ? obj.unit : '',
      lat: obj.lat ? obj.lat : '',
      lng: obj.lng ? obj.lng : '',
      id: obj?.id,
      full_address: `${obj?.address}${obj?.zip && `, ${obj.zip}`}${obj?.city &&
        `, ${obj.city}`}${obj?.postcode && `, ${obj.postcode}`}`,
    }
    setPropertyToSave(obj)
    setChooseProperty(!obj?.id)
    setProperty(prev => ({ ...prev, ...data }))
  }

  const onChange = e => {
    const { name, value } = e.target
    setProperty(prev => ({ ...prev, [name]: value }))
  }

  const onAddPropertyToContact = async () => {
    setLoading(true)
    const newValues = currentContact.properties.reduce((acc, item) => {
      acc[item.id] = {
        note: item.relation_note
          ? item.relation_note
          : item.relation
          ? item.relation
          : item.note
          ? item.note
          : item.property_relation
          ? item.property_relation
          : '',
      }
      return acc
    }, {})

    try {
      let id = property?.id
      if (!id) {
        const res = await createProperty({
          ...propertyToSave,
          ...property,
          owner_user_id: ownerId,
          property_relation: property?.relation,
          full_address: getAddressWithUnit(property),
        })
        id = res.id
      }
      const contact = await putContact(currentContact.id, currentContact.type, {
        ...currentContact,
        relations_with_property: [
          { ...newValues, [id]: { note: property?.relation || '' } },
        ],
      })
      dispatch(updateProperty(contact.properties))
      openNotificationWithIcon('success', {
        message: 'Property has been added',
      })
      onCancel()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleChangeMarker = ({ lat, lng }: { lat: number; lng: number }) => {
    setProperty(prev => ({ ...prev, lat, lng }))
  }

  // const isInstantAppointment = false
  // const isNew = true

  return (
    <Wrapper>
      <>
        <FormRow loading={loading} title='Property Relation'>
          <InputField
            value={property.relation}
            onChange={e => {
              setProperty(prev => ({ ...prev, relation: e.target.value }))
            }}
            className='relation-input'
          />
        </FormRow>
        <FormRow loading={loading} required title='Address'>
          <SearchAddress
            // disabled={!editMode && !manualInput}
            initialValue={property?.full_address}
            label='Property Address'
            onChooseAddress={onChooseAddress}
            onClickManualInput={handleChangeManual}
            onChangeSearchValue={handleChangeSearchValue}
          />
        </FormRow>
        {chooseProperty && (
          <>
            {' '}
            <FormRow loading={loading} title='ZIP'>
              {' '}
              <InputField
                // label='ZIP'
                name='postcode'
                value={property?.postcode || ''}
                onChange={onChange}
                className='zip'
                disabled={property?.id}
                inputProps={{
                  maxLength: 5,
                }}
                // error={errors}
                // disabled={!editMode && !manualInput}
              />
            </FormRow>
            <FormRow loading={loading} title='City'>
              {' '}
              <InputField
                // label='City'
                name='city'
                value={property?.city || ''}
                onChange={onChange}
                className='city'
                disabled={property?.id}
                // disabled={!manualInput}
                // error={errors}
              />
            </FormRow>
            <FormRow loading={loading} title='State'>
              {' '}
              <InputField
                // label='State'
                name='state'
                value={property?.state || ''}
                onChange={onChange}
                disabled={property?.id}
                // disabled={!manualInput}
                // error={errors}
              />
            </FormRow>
            <FormRow loading={loading} title='Unit'>
              {' '}
              <InputField
                name='unit'
                value={property?.unit || ''}
                onChange={onChange}
                className='unit'
                inputProps={{ maxLength: 30 }}
                disabled={property?.id}
                // onBlur={onBlurUnitField}
                // error={errors}
              />
            </FormRow>
            <FormRow loading={loading} title='Type'>
              <SelectField
                // label='Property Type'
                name='type'
                value={property?.type || ''}
                onChange={onChange}
                options={options}
              />
            </FormRow>
            <FormRow loading={loading} title='Tags'>
              <Tags tags={property?.tags} onChange={onChange} />
            </FormRow>
            <FormRow loading={loading} title='Notes'>
              {property?.id ? (
                <Notes
                  label='Property Notes'
                  route={`properties/${property.id}/notes`}
                />
              ) : (
                <InputField
                  helperText={''}
                  size='small'
                  // label='Notes'
                  variant='outlined'
                  name='notes'
                  onChange={onChange}
                  maxLength={500}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </FormRow>
          </>
        )}
      </>
      {!!property?.lat && !!property?.lng && (
        <FormRow loading={loading} title='Map'>
          <Map
            marker={
              !!property?.lat && !!property?.lng
                ? [+property.lat, +property.lng]
                : null
            }
            onChangeMarkerPosition={handleChangeMarker}
            isMarkerDaggable={chooseProperty}
          />
        </FormRow>
      )}

      <ControlsRow>
        <MainButton onClick={onCancel} type='cancel' title='Cancel' />
        <MainButton onClick={onAddPropertyToContact} title='Add property' />
      </ControlsRow>
    </Wrapper>
  )
}
