import { QueueRowHeading } from '../QueueRow/components/QueueRowHeading/QueueRowHeading'
import { DispatchQueue } from '../types'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectSortedEventsByQueue } from 'features/Dispatch/dispatchSlice'
import { getQueueIcon } from '../helpers'

interface Props {
  data: DispatchQueue
  onClick: () => void
}

export const ClosedQueue = ({ data, onClick }: Props) => {
  const { id, title, icon } = data

  const events = useAppSelector(selectSortedEventsByQueue(id))

  return (
    <QueueRowHeading
      title={title}
      icon={getQueueIcon(icon)}
      counter={events?.length}
      onClick={onClick}
    />
  )
}
