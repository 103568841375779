import { Result } from 'antd'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { useEffect, useState } from 'react'
import CardContainer from './components/Card'
import Tips from './components/Tips'
import { priceToView } from 'helpers/thousandSeparator'

import { Buttons, Wrapper } from './PaymentProcess.style'
import { runPaymentTransaction, sendACHPayment } from 'api/Payment'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { Props, Steps } from './types'
import Totals from './components/Totals'
import InfoBlock from 'containers/MainContent/Orcatec/components/UI/InfoBlock'
import { round } from 'helpers/Math'

export const PaymentProcess = ({
  balance,
  cardInfo,
  onCancel,
  onPaid,
  transactionFee,
  settings,
  payer,
}: Props) => {
  const [step, setStep] = useState<Steps>(Steps.tips)
  const [tips, setTips] = useState({ name: 'No Tips', value: 0 })
  const [cardData, setCardData] = useState({
    card_holder: '',
    check_number: '',
    expiry: '',
    token: '',
    accttype: 'ECHK',
    billing_address: {
      address: '',
      city: '',
      region: '',
      postal: '',
    },
  })
  const [isPaying, setIsPaying] = useState(false)
  const [paid, setPaid] = useState(false)
  const [achPayment, setAchPayment] = useState(false)

  //Effects

  useEffect(() => {
    if (cardInfo)
      setCardData(prev => ({
        ...prev,
        ...cardInfo,
      }))
  }, [])

  useEffect(() => {
    if (!balance) return

    if (availiblePaymentMethods === 'check') setAchPayment(true)

    setStep(
      settings?.disableTips
        ? availiblePaymentMethods === 'both'
          ? Steps.method
          : Steps.card
        : Steps.tips,
    )
  }, [])

  //Methods

  const handleChangeCardData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target

    if (!['card_holder', 'accttype', 'check_number']?.includes(name)) {
      return setCardData(prev => ({
        ...prev,
        billing_address: {
          ...prev.billing_address,
          [name]: value,
        },
      }))
    }

    const regex = /^[a-zA-Z0-9]/

    if (!value || regex.test(value)) {
      setCardData(prev => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  const handleSetToken = (token: { message: string; expiry: string }) => {
    setCardData(prev => ({
      ...prev,
      token: token.message,
      expiry: token.expiry,
    }))
  }

  const availiblePaymentMethods =
    balance?.accept_card && balance?.can_pay_by_check
      ? 'both'
      : balance?.accept_card
      ? 'card'
      : 'check'

  const handleAddTips = ({ name, value }: { name: string; value: number }) => {
    setTips({ name, value: +value.toFixed(2) })

    if (name === 'No Tips') {
      handleClickNext()
    }
  }

  const handleClickNext = () => {
    switch (step) {
      case Steps.tips:
        return setStep(
          availiblePaymentMethods === 'both' ? Steps.method : Steps.card,
        )

      case Steps.method:
        return setStep(Steps.card)

      case Steps.card:
        return handlePay()

      default:
        return setStep(Steps.tips)
    }
  }

  const handleClickBack = () => {
    switch (step) {
      case Steps.method:
        return settings?.disableTips ? onCancel?.() : setStep(Steps.tips)

      case Steps.card:
        setCardData(prev => ({
          ...prev,
          token: '',
          expiry: '',
        }))

        if (availiblePaymentMethods !== 'both' && settings?.disableTips)
          return onCancel?.()

        setAchPayment(false)
        return setStep(
          availiblePaymentMethods === 'both' ? Steps.method : Steps.tips,
        )

      default:
        return onCancel?.()
    }
  }

  const handlePay = async () => {
    if (!balance) return

    const data = {
      ...cardData,
      payer,
      amount: +balance?.amount,
      balance_id: balance?.id,
      tips: tips.value,
      payment_processing_fee: achPayment
        ? 0
        : round(+balance.amount * ((transactionFee || 0) / 100)),
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { expiry, owner_user_id, ...rest } = cardData

    const achData = {
      ...rest,
      payer,
      tips: tips.value,
      check_number: cardData.check_number ? +cardData.check_number : null,
    }

    setIsPaying(true)

    try {
      achPayment
        ? await sendACHPayment(balance.id, achData)
        : await runPaymentTransaction(data)
      setPaid(true)
    } catch (error) {
      // setError(true)
      if (error.response.data) {
        const { status, message, details } = error.response.data

        openNotificationWithIcon('error', {
          message: `${status || 'Declined'}: ${details || message}.`,
        })
      } else {
        openNotificationWithIcon('error', {
          message: `Declined: Sorry, something went wrong. Please try again later.`,
        })
      }
    } finally {
      onPaid?.({ id: balance.id, ...achData })
      setIsPaying(false)
    }
  }

  return (
    <Wrapper>
      {step === Steps.tips ? (
        <div style={{ width: 400 }}>
          <div className='total_amount'>
            {tips.value ? (
              <>
                {`${priceToView(+(balance?.amount || 0))} + ${priceToView(
                  tips.value,
                )} (tips) = `}
                <span>{priceToView(+(balance?.amount || 0) + tips.value)}</span>
              </>
            ) : (
              <span>{priceToView(+(balance?.amount || 0))}</span>
            )}
            Amount to pay
          </div>
          <Tips
            amount={+(balance?.amount || 0)}
            onOptionChose={handleAddTips}
            choosenTips={tips.name}
          />
        </div>
      ) : step === Steps.method ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: 400,
            height: 200,
          }}
        >
          <div
            style={{
              marginBottom: 30,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 5,
            }}
          >
            <MainButton
              title='Pay by Credit Card'
              onClick={() => {
                setStep(Steps.card)
              }}
            />

            {!!transactionFee && (
              <InfoBlock>
                A service fee of <span>{transactionFee}%</span> will be added
                for credit card payment
              </InfoBlock>
            )}
          </div>

          <div
            style={{
              marginBottom: 30,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 5,
            }}
          >
            <MainButton
              title='Pay by Bank Check'
              onClick={() => {
                setAchPayment(true)
                setStep(Steps.card)
              }}
            />
            <InfoBlock type='warning'>
              For transactions that fail to process due to non-sufficient funds
              or returned check (ACH) a fee of $25 may apply.
            </InfoBlock>
          </div>
        </div>
      ) : (
        <div>
          {!paid ? (
            <>
              <Totals
                amount={+balance.amount}
                tips={+tips.value}
                transactionFee={achPayment ? 0 : transactionFee}
              />

              <CardContainer
                cardData={cardData}
                onChangeCardData={handleChangeCardData}
                onGotToken={handleSetToken}
                achPayment={achPayment}
              />
            </>
          ) : (
            <Result status='success' title='Payment Successfull' />
          )}
        </div>
      )}

      <Buttons>
        {!paid ? (
          <>
            <MainButton type='cancel' title='Back' onClick={handleClickBack} />
            {Steps.method !== step && (
              <MainButton
                onDoubleClick={() => null}
                title={step === Steps.card ? 'Pay' : 'Next'}
                onClick={handleClickNext}
                disabled={
                  (step === Steps.card &&
                    (!cardData.token || !cardData.card_holder)) ||
                  isPaying
                }
                isFetching={isPaying}
              />
            )}
          </>
        ) : (
          <MainButton type='cancel' title={'Close'} onClick={onCancel} />
        )}
      </Buttons>
    </Wrapper>
  )
}
