import React from 'react'
import { NoAccessResult } from '../NoAccessResult/NoAccessResult'
import { selectUserPermissionsByScope } from '../../permissionSlice'
import { useAppSelector } from '../../../../../store/Orcatec/hooks'
import { IScope } from '../../types'
import { meSelector } from 'store/SuperAdmin/selectors'

interface IProps {
  children: React.ReactNode
  scopes: string[]

  additionalAccess?: boolean
  visible?: boolean
  author?: null | number
  renderResult?: null | number | boolean
}

const isUserAuthor = (userId, authUser) => userId === authUser

const hasPermission = ({ permissions, scopes, author, me }) => {
  const scopesMap = {}
  scopes?.forEach(scope => {
    scopesMap[scope] = permissions[scope]
  })

  const permissionsArray = Object.values(scopesMap || {})
  const anyPermissionGranted = permissionsArray.some(permission => !!permission)

  const maxPermissionLevel = Math.max(...permissionsArray)

  if (maxPermissionLevel === IScope.ALL) {
    return anyPermissionGranted
  }

  if (maxPermissionLevel === IScope.OWN) {
    return isUserAuthor(author, me.id)
  }
  if (maxPermissionLevel === IScope.OWN_RELATED) {
    return anyPermissionGranted
  }

  return false
}

export const AccessControl = ({
  children,
  scopes,
  additionalAccess = true,
  visible = false,
  author = null,
  renderResult = false,
}: IProps): React.ReactNode => {
  const permissions = useAppSelector(selectUserPermissionsByScope(scopes))

  const me = useAppSelector(meSelector)

  const permissionGranted = hasPermission({ permissions, scopes, author, me })

  if (visible) return children || null

  if (additionalAccess && permissionGranted) return children || null

  if (!(additionalAccess && permissionGranted) && renderResult)
    return <NoAccessResult />

  return <p></p>
}
