import { PropertyPermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'
import { NotesPermissions } from '../notes/types'
import { ModuleName } from '../../module_permissions/types'

export const properties = {
  properties_list_can_read: {
    name: 'View Properties List',
    control_type: UniteType.FIRST_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.PROPERTIES_LIST_CAN_READ,
    related_key: ['all'],
    is_module: true,
  },

  properties_can_delete: {
    name: 'Delete Property',
    control_type: UniteType.FIRST_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.PROPERTIES_CAN_DELETE,
    related_key: [],
  },
  notes_can_read: {
    name: 'View Notes',
    with_access_scope: false,
    with_access_checkbox: true,
    control_type: UniteType.THIRD_VIEW,
    permission_key: NotesPermissions.NOTES_CAN_READ,
    parent_permission_key: NotesPermissions.NOTES_CAN_READ,
    description: '',
    is_module: false,
    related_key: [
      NotesPermissions.NOTES_CAN_CREATE,
      NotesPermissions.NOTES_CAN_DELETE,
      NotesPermissions.NOTES_CAN_EDIT,
    ],
    related_modules: ModuleName.NOTES,
    children: [
      {
        name: 'Create a New Note',
        with_access_scope: false,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: NotesPermissions.NOTES_CAN_CREATE,
        parent_permission_key: NotesPermissions.NOTES_CAN_READ,
        description: '',
        related_key: [],
      },
      {
        name: 'Edit Note',
        with_access_scope: true,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: NotesPermissions.NOTES_CAN_EDIT,
        parent_permission_key: NotesPermissions.NOTES_CAN_READ,
        description: '',
        related_key: [],
        custom_access_scope: [
          {
            key: 1,
            label: 'All Notes  ',
            activeColor: '#adf7b6',
            color: 'white',
            disabled: false,
          },
          {
            key: 2,
            label: 'Own Notes ',
            color: 'white',
            activeColor: '#adf7b6',
            disabled: false,
          },
        ],
      },
      {
        name: 'Delete Note',
        with_access_scope: true,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: NotesPermissions.NOTES_CAN_DELETE,
        parent_permission_key: NotesPermissions.NOTES_CAN_READ,
        description: '',
        related_key: [],
        custom_access_scope: [
          {
            key: 1,
            label: 'All Notes ',
            activeColor: '#adf7b6',
            color: 'white',
            disabled: false,
          },
          {
            key: 2,
            label: 'Own Notes ',
            color: 'white',
            activeColor: '#adf7b6',
            disabled: false,
          },
        ],
      },
    ],
  },
  project_can_read_custom_form: {
    name: 'Show Custom Forms',
    control_type: UniteType.THIRD_VIEW,
    description: 'Ability to use custom form module',
    with_access_scope: false,
    with_access_checkbox: true,
    permission_key: IKey.PROPERTIES_CAN_READ_CUSTOM_FORMS,
    related_key: [
      IKey.PROPERTIES_CAN_CREATE_CUSTOM_FORMS,
      IKey.PROPERTIES_CAN_EDIT_CUSTOM_FORMS,
      IKey.PROPERTIES_CAN_DELETE_CUSTOM_FORMS,
    ],
    // main_key: FormsPermissions.FORMS_LIST_CAN_READ,
    // related_modules: ModuleName.FORMS,
    children: [
      {
        name: 'Create Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.PROPERTIES_CAN_CREATE_CUSTOM_FORMS,
        parent_permission_key: IKey.PROPERTIES_CAN_READ_CUSTOM_FORMS,
      },
      {
        name: 'Edit Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.PROPERTIES_CAN_EDIT_CUSTOM_FORMS,
        parent_permission_key: IKey.PROPERTIES_CAN_READ_CUSTOM_FORMS,
      },
      {
        name: 'Delete Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.PROPERTIES_CAN_DELETE_CUSTOM_FORMS,
        parent_permission_key: IKey.PROPERTIES_CAN_READ_CUSTOM_FORMS,
      },
    ],
  },
}
