import { useState } from 'react'

import InputField from 'containers/MainContent/Orcatec/components/Input'

import './styles.scss'

const Tags = ({ tags, onChange }) => {
  const [inputValue, setInputValue] = useState('')

  const handleChange = e => setInputValue(e.target.value)

  const handleAddTag = e => {
    const { value } = e.target

    if (!value || tags?.includes(value)) return

    onChange({
      target: {
        name: 'tags',
        value: [...tags, value],
      },
    })
    setInputValue('')
  }

  const handleDeleteTag = item =>
    onChange({
      target: {
        name: 'tags',
        value: tags.filter(tag => tag !== item),
      },
    })

  return (
    <div className='tags'>
      <InputField
        value={inputValue}
        onChange={handleChange}
        onBlur={handleAddTag}
        label='Property Tags'
        onKeyUp={e => e.keyCode === 13 && handleAddTag(e)}
      />

      {!!tags?.length && (
        <ul>
          {tags?.map((tag, idx) => (
            <li key={idx}>
              {tag}
              <span onClick={() => handleDeleteTag(tag)}>
                <i className='mdi mdi-close' />
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Tags
