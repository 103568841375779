import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MainInfo } from './components/MainInfo/MainInfo'
import SubMenu from 'containers/MainContent/Orcatec/components/SubMenu'
import { Visits } from './components/Content/Visits/Visits'
import { ScopeOfWork } from './components/Content/ScopeOfWork/ScopeOfWork'
import { Forms } from './components/Content/Forms/Forms'
import { AdditionalInformation } from './components/Content/AdditionalInformation/AdditionalInformation'
import { CustomerInfo } from './components/Content/CustomerInfo/CustomerInfo'
import { Contacts } from './components/Content/Contacts/Contacts'
import { Heading } from './components/Heading/Heading'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  getWorkOrder,
  selectWorkOrderSlice,
} from 'features/Project/slices/projectWorkOrdersSlice'
import { Spin } from 'antd'
import { getRelateJobs, setRelatedJobs } from 'store/Orcatec/actions/jobs'
import {
  resetWoContactSliceReducer,
  selectWOSummaryContact,
} from 'features/Project/slices/projectWorkOrderContactsSlice'
import { selectRelatedJobs } from 'store/Orcatec/reducers/jobs'
import { getRelatedFormToProject } from 'api/CustomForms'

enum Tabs {
  CONTACTS,
  VISITS,
  SCOPE_OF_WORK,
  FORMS,
  ADDITIONAL_INFORMATIONS,
  CUSTOMER_INFO,
}

interface Props {
  id: number
  onGoBack: () => void
}

export const WorkOrderPage = ({ id, onGoBack }: Props) => {
  const dispatch = useAppDispatch()

  const { status } = useAppSelector(selectWorkOrderSlice)
  const { client, property } = useAppSelector(selectWOSummaryContact)
  const { relatedJobs: jobs } = useAppSelector(selectRelatedJobs)

  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.CONTACTS)
  const [formsCount, setFormsCount] = useState(0)

  useEffect(() => {
    dispatch(getWorkOrder(id))

    dispatch(getRelateJobs({ wo_proposal_id: id }))

    return () => {
      dispatch(resetWoContactSliceReducer)
      dispatch(setRelatedJobs([]))
    }
  }, [])

  useEffect(() => {
    const getData = async () => {
      const res = await getRelatedFormToProject(id)
      setFormsCount(res.length)
    }

    if (id) getData()
  }, [])

  const handleChangeTab = (id: Tabs) => {
    setActiveTab(id)
  }

  const renderContent = (activeTab: Tabs) => {
    if (activeTab === Tabs.CONTACTS) {
      return (
        <div>
          <Contacts workOrderId={id} />
        </div>
      )
    }

    if (activeTab === Tabs.VISITS) {
      return (
        <div>
          <Visits workOrderId={id} />
        </div>
      )
    }
    if (activeTab === Tabs.SCOPE_OF_WORK) {
      return (
        <div>
          <ScopeOfWork />
        </div>
      )
    }
    if (activeTab === Tabs.FORMS) {
      return (
        <div>
          <Forms workOrderId={id} />
        </div>
      )
    }
    if (activeTab === Tabs.ADDITIONAL_INFORMATIONS) {
      return (
        <div>
          <AdditionalInformation />
        </div>
      )
    }
    if (activeTab === Tabs.CUSTOMER_INFO) {
      return (
        <div>
          <CustomerInfo />
        </div>
      )
    }
  }

  const menuItems = [
    {
      id: Tabs.CONTACTS,
      title: 'Contacts',
      counter: [...client, ...property].filter(contact => !!contact.display)
        .length,
    },
    {
      id: Tabs.VISITS,
      title: 'Visits',
      counter: jobs?.length,
    },
    {
      id: Tabs.SCOPE_OF_WORK,
      title: 'Scope of Work',
    },
    {
      id: Tabs.FORMS,
      title: 'Forms',
      counter: formsCount,
    },
    {
      id: Tabs.ADDITIONAL_INFORMATIONS,
      title: 'For Workers',
    },
    {
      id: Tabs.CUSTOMER_INFO,
      title: 'Customer’s info',
    },
  ]

  return (
    <Spin spinning={status === 'loading'}>
      <Wrapper>
        <Heading onGoBack={onGoBack} />

        <WrapperInnner>
          <MainInfo />
        </WrapperInnner>

        <div style={{ marginLeft: 16 }}>
          <SubMenu
            activeItem={activeTab}
            menuItemsList={menuItems}
            onChange={handleChangeTab}
          />
        </div>

        <Content>{renderContent(activeTab)}</Content>
      </Wrapper>
    </Spin>
  )
}

const Wrapper = styled.div``
const Content = styled.div`
  border-top: 1px solid #e6e8ec;
`
const WrapperInnner = styled.div`
  padding: 16px;
  border: 1px solid #f1f2fd;
  border-radius: 4px;
  margin-bottom: 16px;
`
