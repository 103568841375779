import {
  selectProject,
  selectProjectItemsBySection,
  selectProjectItemsSlice,
} from 'features/Project/projectSelectors'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import { Item } from './Item'
import { ProjectItem, ProjectStatusGroup } from 'features/Project/types'
import { updateProjectItem } from 'features/Project/slices/projectItemsSlice'
import OptionGroup from '../OptionGroup'

interface Props {
  sectionId: number
  groupId: number | null
  pricePermission: boolean
}

export const ItemsList = ({ sectionId, groupId, pricePermission }: Props) => {
  const dispatch = useAppDispatch()

  const project = useAppSelector(selectProject)
  const { status } = useAppSelector(selectProjectItemsSlice)

  const itemsList = useAppSelector(
    selectProjectItemsBySection(sectionId, groupId),
  )

  const handleItemChange = (item: ProjectItem) => {
    dispatch(updateProjectItem(item))
  }

  const filteredItems = itemsList
    .filter(item => !item.is_hidden)
    .filter(item => (item.option_group_id ? item.checked_option : item))

  return (
    <Wrapper>
      {filteredItems.map(item =>
        item.checked_option &&
        item.option_group_id &&
        project.status === ProjectStatusGroup.Estimate ? (
          <OptionGroup key={item.id}>
            {itemsList
              .filter(option => option.option_group_id === item.option_group_id)
              .toSorted((a, b) => a.order_option - b.order_option)
              .map(option => (
                <Item
                  key={option.id}
                  data={option}
                  disabled={status === 'loading'}
                  onChange={handleItemChange}
                  checkedItem={itemsList.find(
                    option =>
                      option.option_group_id === item.option_group_id &&
                      option.checked_option,
                  )}
                  pricePermission={pricePermission}
                />
              ))}
          </OptionGroup>
        ) : (
          <Item
            key={item.id}
            data={item}
            disabled={
              status === 'loading' ||
              project.status !== ProjectStatusGroup.Estimate
            }
            onChange={handleItemChange}
            pricePermission={pricePermission}
          />
        ),
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
