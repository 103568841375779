import { priceToView } from 'helpers/thousandSeparator'
import { useState } from 'react'
import { TaxForm } from './TaxForm'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  selectProjectSettings,
  selectProjectTaxAndFeesSlice,
  selectProjectTaxTotal,
  selectProjectTotalAfterDiscounts,
} from '../projectSelectors'
import { Button } from 'antd'
import { ProjectTax } from '../types'
import {
  createProjectTax,
  deleteProjectTax,
  updateProjectTax,
} from '../slices/projectTaxAndFeesSlice'
import {
  AdditionalTaxInfo,
  StyledEditIcon,
  TaxContent,
  TaxInfo,
  Wrapper,
} from './Taxes.style'
import { Error } from 'types/Error'
import { round } from 'helpers/Math'

interface Props {
  disabled: boolean
  tabId: number
}

export const Taxes = ({ disabled, tabId }: Props) => {
  const dispatch = useAppDispatch()

  const { tax: projectTax, fees } = useAppSelector(selectProjectTaxAndFeesSlice)
  const TAX_TOTAL = useAppSelector(selectProjectTaxTotal)
  // const investmentTotal = useAppSelector(selectInvestmentTotal)
  const totalAfterDiscounts = useAppSelector(selectProjectTotalAfterDiscounts)
  const { show_taxes } = useAppSelector(selectProjectSettings)

  const [showTaxForm, setShowTaxForm] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const handleTaxDelete = async () => {
    if (!projectTax?.id) return

    const res = await dispatch(deleteProjectTax(projectTax))

    if (res.meta.requestStatus === 'rejected') return

    setShowTaxForm(false)
  }

  const handleTaxSave = async (tax: ProjectTax) => {
    if (!tax.name) return setError({ name: 'Label is required' })

    const res = tax.id
      ? await dispatch(updateProjectTax(tax))
      : await dispatch(createProjectTax({ ...tax, tab_id: tabId }))

    if (res.meta.requestStatus === 'rejected') return

    setShowTaxForm(false)
  }

  const handleTaxChange = (e: { target: { name: string; value: unknown } }) => {
    const { name } = e.target

    if (error?.[name]) setError(null)
  }

  const TAX_INFO = {
    material: `On Taxable Items - ${projectTax?.rate}%`,
    investment: `On Total after Discounts - ${projectTax?.rate}%`,
    custom: 'Custom amount',
  }

  if ((!show_taxes || disabled) && !projectTax) return null

  return (
    <Wrapper>
      {!projectTax ? (
        <Button
          style={{ display: 'flex', marginLeft: 'auto' }}
          onClick={() => setShowTaxForm(true)}
        >
          + Add Tax
        </Button>
      ) : (
        <TaxContent>
          <TaxInfo>
            <p>
              {!disabled && (
                <StyledEditIcon onClick={() => setShowTaxForm(true)} />
              )}
              Charge Tax:{' '}
            </p>
            <AdditionalTaxInfo>({TAX_INFO[projectTax.type]})</AdditionalTaxInfo>
          </TaxInfo>

          <p>{priceToView(TAX_TOTAL)}</p>

          {!!Object.values(fees.data).length && (
            <>
              <p>Total after Tax: </p>
              <p>{priceToView(round(totalAfterDiscounts + TAX_TOTAL))}</p>
            </>
          )}
        </TaxContent>
      )}

      {showTaxForm && (
        <TaxForm
          data={projectTax}
          disabled={disabled}
          error={error}
          onClose={() => setShowTaxForm(false)}
          onSave={handleTaxSave}
          onDelete={handleTaxDelete}
          onChange={handleTaxChange}
        />
      )}
    </Wrapper>
  )
}
