import { Modal, Switch, Table } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import {
  ChangeStatusEvent,
  IChatTableItem,
  IMessage,
} from 'features/Messaging/types'
import {
  MESSAGE_STATUSES,
  parsePhoneNumber,
  MESSAGING_SERVICE_IS_NOT_SETUP_TEXT,
} from 'features/Messaging/utils'
import MessageStatus from 'features/Messaging/components/MessageStatus'
import moment from 'moment-timezone'
import Chat from 'features/Messaging/components/Chat'
import { AppStateType } from '../../../../../../../../store'
import { useAppSelector } from '../../../../../../../../store/Orcatec/hooks'
import { isBrandSubmitted } from 'store/Orcatec/selectors/messaging'

export interface HistoryTableProps {
  tableData: any
  isOrganization: boolean
}
const linkStyle = {
  color: '#1890ff',
  textDecoration: 'underline',
  cursor: 'pointer',
}

const HistoryTable: React.FC<HistoryTableProps> = props => {
  const { tableData, isOrganization } = props

  const [chats, setChats] = useState<IChatTableItem[]>([])
  const [pagination, setPagination] = useState({})
  const [chatModalVisible, setChatModalVisible] = useState(false)
  const [chatID, setChatID] = useState<number | null>(null)

  const [showAllHistory, setShowAllHistory] = useState(false)
  const isBrandChecked: boolean = useAppSelector(isBrandSubmitted)
  const companyID: number = useAppSelector(
    (state: AppStateType) => state.orcatec.company.id,
  )
  // const hasTwilioPhones = useAppSelector(s => s.orcatec.company.twilio?.has_phone_numbers)

  // const { brands, brandsLoading } = useBrands()

  useEffect(() => {
    setChats(tableData?.chats || [])
    setPagination(tableData.meta)
  }, [])

  const handleOpenChatModal = (id: number): void => {
    setChatModalVisible(p => !p)
    setChatID(id)
  }

  const updateTable = (message: IMessage | ChangeStatusEvent) => {
    Array.isArray(message?.chats) &&
      message.chats?.forEach(chatID => {
        if ('created_at' in message) {
          const { created_at, message: lastMessage }: IMessage = message
          setChats(p =>
            p?.map(el =>
              el.id === chatID ? { ...el, body: lastMessage, created_at } : el,
            ),
          )
        } else {
          const { status }: ChangeStatusEvent = message
          setChats(p =>
            p.map(el => (el.id === chatID ? { ...el, status } : el)),
          )
        }
      })
  }

  useEffect(() => {
    window.Echo?.private(
      `company-message.${companyID}`,
    ).listen('.sms.message-created', ({ message }: { message: IMessage }) =>
      updateTable(message),
    )

    window.Echo?.private(
      `company-message-status-changed.${companyID}`,
    ).listen('.sms.status-changed', (changedStatus: ChangeStatusEvent) =>
      updateTable(changedStatus),
    )
  }, [])

  useEffect(() => {
    return () => {
      window.Echo.leaveChannel(`company-message.${companyID}`)
      window.Echo.leaveChannel(`company-message-status-changed.${companyID}`)
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: false,
        width: '5em',
        onCell: () => ({ style: { verticalAlign: 'middle' } }),
        render: (text: string) => <span style={linkStyle}>{text}</span>,
      },
      {
        title: 'User',
        dataIndex: 'from',
        sorter: false,
        onCell: () => ({ style: { verticalAlign: 'middle' } }),
        render: (number: string) => <span>{parsePhoneNumber(number)}</span>,
      },
      {
        title: 'Client',
        dataIndex: 'to',
        sorter: false,
        onCell: () => ({ style: { verticalAlign: 'middle' } }),
        render: (text: string, record: IChatTableItem) => (
          <>
            <p>{record.client.name}</p>
            <p>{parsePhoneNumber(text)}</p>
          </>
        ),
      },
      {
        title: 'Last Message',
        dataIndex: 'body',
        sorter: false,
        onCell: () => ({ style: { verticalAlign: 'middle' } }),
        render: (text: string) => <span>{text}</span>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: false,
        width: '3em',
        onCell: () => ({ style: { verticalAlign: 'middle' } }),
        render: (text: MESSAGE_STATUSES) => (
          <MessageStatus status={MESSAGE_STATUSES?.[text]} />
        ),
      },
      {
        title: 'Date',
        dataIndex: 'created_at',
        sorter: false,
        onCell: () => ({ style: { verticalAlign: 'middle' } }),
        render: (text: string) => (
          <span>{moment(text).format('MM/DD/YYYY hh:mm A')}</span>
        ),
      },
    ],
    [],
  )

  const handleSwitchChange = (checked: boolean) => {
    setShowAllHistory(checked)
  }

  return isBrandChecked ? (
    <>
      {isBrandChecked ? (
        <div
          className='switch-container'
          style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}
        >
          <Switch checked={showAllHistory} onChange={handleSwitchChange} />
          <span>Show all history</span>
        </div>
      ) : (
        <MESSAGING_SERVICE_IS_NOT_SETUP_TEXT />
      )}

      {showAllHistory ? (
        <Chat allHistoryMode isOrganization={isOrganization} />
      ) : (
        <>
          {/*{hasTwilioPhones ? (*/}
          <Table
            columns={columns}
            dataSource={chats}
            onRow={(record: IChatTableItem) => {
              return { onClick: () => handleOpenChatModal(record.id) }
            }}
            pagination={pagination}
          />
          {/*) : (*/}
          {/*  <EmptyMessaging callback={redirect} />*/}
          {/*)}*/}

          <Modal
            visible={chatModalVisible}
            onCancel={() => setChatModalVisible(p => !p)}
            footer={null}
            style={{ top: 20 }}
            destroyOnClose
          >
            {chatID && <Chat chatID={chatID} />}
          </Modal>
        </>
      )}
    </>
  ) : (
    <MESSAGING_SERVICE_IS_NOT_SETUP_TEXT />
  )
}

export default HistoryTable
