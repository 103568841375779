import * as types from '../../types'

export const openModal = () => {
  return {
    type: types.modalOptional1.OPEN_MODAL_OPTIONAL_FIRST,
  }
}

export const actionChangeModalNamesOne = item => {
  return {
    type: types.modalOptional1.PROPOSAL_SET_MODAL_NAMES_ONE,
    item,
  }
}
