import React from 'react'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import './style.scss'

export const Buttons = ({
  handleButtonClick,
  firstBtn,
  secondBtn,
  style,
  buttonsDisabled,
  showSecondBtn = true,
  showFirstBtn = true,
  disableMargin,
}) => {
  return (
    <div
      className={`proposal-contact-section__buttons ${disableMargin ? 'proposal-contact-section__buttons--m-0' : ''}`}
      style={style?.style}
    >
      {showFirstBtn && (
        <MainButton
          title={firstBtn.title ? firstBtn.title : 'Cancel'}
          type='cancel'
          onClick={() => handleButtonClick(firstBtn.action)}
        />
      )}
      {showSecondBtn && (
        <MainButton
          title={secondBtn.title}
          className='ml-3'
          onClick={() => handleButtonClick(secondBtn.action)}
          isFetching={buttonsDisabled}
        />
      )}
    </div>
  )
}
