import { getTimeSettings } from 'api/NotificationModal'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompanyTimezone } from 'store/Orcatec/selectors/company'

interface TimeSettings {
  time_from: string
  time_to: string
  timezone: string
}

export const useTimeSettings = () => {
  const timezone = useAppSelector(selectCompanyTimezone)

  const [timeSettings, setTimeSettings] = useState<TimeSettings>({
    time_from: '',
    time_to: '',
    timezone,
  })

  useEffect(() => {
    const getData = async () => {
      try {
        const settings = await getTimeSettings()

        setTimeSettings(settings)
      } catch (error) {
        console.error(error)
      }
    }

    getData()
  }, [])

  return { timeSettings }
}
