export const PROPOSAL_TOGGLE_MODAL = 'PROPOSAL_TOGGLE_MODAL'
export const PROPOSAL_DELETE_NEW_ITEM_FILE = 'PROPOSAL_DELETE_NEW_ITEM_FILE'
export const PROPOSAL_ADD_NEW_ITEM_FILE = 'PROPOSAL_ADD_NEW_ITEM_FILE'
export const ACCOUNTING_ADD_NEW_ITEM_FILE = 'ACCOUNTING_ADD_NEW_ITEM_FILE'

export const SET_UPLOAD_FILES = 'SET_UPLOAD_FILES'
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS'
export const SUCCESS_UPLOAD_FILE = 'SUCCESS_UPLOAD_FILE'
export const FAILURE_UPLOAD_FILE = 'FAILURE_UPLOAD_FILE'
export const PROPOSAL_PROPERTY_TOGGLE_MODAL = 'PROPOSAL_PROPERTY_TOGGLE_MODAL'
export const PROPOSAL_CLEAR_FILE_IN_PROGRESS = 'PROPOSAL_CLEAR_FILE_IN_PROGRESS'
