import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { logout } from '../../../store/Orcatec/actions/app'

import Drawer from '../Drawer'

import IconRing from '../../static/icons/headerIcons/Ring'
import IconArrow from '../../static/icons/headerIcons/Arrow'
import IconDefaultAvatar from '../../static/icons/headerIcons/DefaultAvatar'
import { ViewFile } from '../../../helpers/viewFile/viewFile'

import s from './style.module.css'
import { meSelector } from '../../../store/SuperAdmin/selectors'
//import ModalWrapper from '../ModalWrapper'
import EditUserModal from '../UserList/Modals/EditUserModal'
import ModalWrapper from '../../../containers/MainContent/Orcatec/Proposals/components/Modals/ModalWrapper/ModalWrapper'

import { BarChart } from '@material-ui/icons'
import { SubscriptionGlobalInfo } from '../SubscriptionGlobalInfo/SubscriptionGlobalInfo'

const avaStyle = {
  width: '40px',
  height: '40px',
  cursor: 'pointer',
  backgroundSize: 'cover',
  borderRadius: '50%',
}
const fileStyle = { width: '100px', height: '50px', borderRadius: '4px' }

const Header = ({ userLogout, history, fullName, myId, info }) => {
  const [isOpen, setOpen] = useState(false)
  const [isEditModal, setIsEditModal] = useState(false)
  const [profileInfo, setProfileInfo] = useState({})
  const [openInfoModal, setOpenInfoModal] = useState(false)

  useEffect(() => {
    setProfileInfo({ fullName, info })
  }, [fullName, info])

  const togleEditModal = () => {
    setOpen(false)
    setIsEditModal(!isEditModal)
  }
  const handleToggleDropDown = () => {
    setOpen(!isOpen)
  }
  const handleLogOut = () => {
    setOpen(false)
    window.localStorage.removeItem('auth')
    document.cookie = 'orcatecJWT= ;'
    history.push('/auth/signin')
    userLogout()
  }
  const handleUpdate = (fullName, info) => {
    setProfileInfo({ fullName, info })
  }

  const renderDropDown = isOpen ? (
    <div className={s.DropDown}>
      <button
        style={{ width: '100%' }}
        type='button'
        className={s.DropDownBtn}
        onClick={togleEditModal}
      >
        Profile
      </button>
      <button
        style={{ width: '100%' }}
        type='button'
        onClick={handleLogOut}
        className={s.DropDownBtn}
      >
        Logout
      </button>
    </div>
  ) : null

  const renderEditModal = isEditModal ? (
    <ModalWrapper
      dialogClassName={s.UserModal}
      modal={true}
      close={togleEditModal}
      isSaving={false}
      hideCancelBtn={true}
      header='Profile Settings'
    >
      <EditUserModal
        onClose={togleEditModal}
        currentId={myId}
        isProfileSettings={true}
        docsStyle={fileStyle}
        handleUpdate={handleUpdate}
      />
    </ModalWrapper>
  ) : null

  return (
    <header className={s.Header}>
      <div className={s.Drawer}>
        <Drawer />
      </div>
      <div className={s.UserBlock}>
        <div className={s.UserTools}>
          <div
            onClick={() => setOpenInfoModal(true)}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          >
            <BarChart />
          </div>

          <IconRing />
          <p
            style={{ cursor: 'pointer' }}
            className={s.Name}
            onClick={handleToggleDropDown}
          >
            {profileInfo.fullName}
          </p>
          <div className={s.DropDownBlock}>
            <button
              type='button'
              onClick={handleToggleDropDown}
              className={s.Button}
            >
              <IconArrow />
              {profileInfo.info && profileInfo.info.profile_picture ? (
                <ViewFile
                  file={
                    profileInfo.info.profile_picture
                      ? process.env.REACT_APP_API_MEDIA +
                        profileInfo.info.profile_picture
                      : '/assets/images/users/user-4.jpg'
                  }
                  fileStyle={avaStyle}
                  isLogo={true}
                />
              ) : (
                <IconDefaultAvatar />
              )}
            </button>
            {renderDropDown}
          </div>
        </div>
      </div>
      {renderEditModal}
      <SubscriptionGlobalInfo
        visible={openInfoModal}
        onClose={() => setOpenInfoModal(false)}
      />
    </header>
  )
}

const mapStateToProps = state => {
  const me = meSelector(state)
  return {
    fullName: me.full_name,
    myId: me.id,
    info: me.info,
  }
}

const mapDispatchToProps = dispatch => ({
  userLogout: () => dispatch(logout()),
})

Header.propTypes = {
  fullName: PropTypes.string.isRequired,
  userLogout: PropTypes.func.isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
