import React, {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
//import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import ImgDialog from './ImgDialog'
import getCroppedImg from './cropImage'
import { getOrientation } from 'get-orientation/browser'
import { getRotatedImage } from './rotateImage'
import { styles } from './styles'
import { Button } from 'antd'
import Preloader from '../../../../components/Preloader'
import { RotateLeft, RotateRight, Add, Remove } from '@material-ui/icons'
import { SmallPreloader } from '../../../../components/Preloader/SmallPreloader'

function blobToFile(theBlob, fileName) {
  theBlob.lastModifiedDate = new Date()
  theBlob.name = fileName
  return theBlob
}

export const dataURLtoFile = (b64Data, filename) => {
  let arr = b64Data.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length
  //u8arr = new Uint8Array(n)
  const byteArray = new Uint8Array(n)
  while (n--) {
    byteArray[n] = bstr.charCodeAt(n)
  }
  const blob = new Blob([byteArray], { type: mime })
  const file = new File([blob], filename) /*  blobToFile(blob, filename) */
  return file
}

const urltoFile = (url, filename, mimeType) => {
  return fetch(url)
    .then(function(res) {
      return res.arrayBuffer()
    })
    .then(function(buf) {
      return new File([buf], filename, { type: mimeType })
    })
}

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}

function readFile(file) {
  return new Promise(resolve => {
    if (typeof file === 'string') {
      resolve(file)
    } else {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    }
  })
}

const ImgCropper = ({
  classes,
  file,
  setCroppedLogo,
  label,
  cropShape = 'rect',
  onCancelCrop,
  hideButtons = false,
  ////////////////////if state comes from parent
  setCropFromParent,
  setRotationFromParent,
  setZoomFromParent,
  setCroppedAreaPixelsFromParent,
  setImgSrcFromParent,
  setCroppedImageFromParent,
  setIsCroppingFromParent,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [imgSrc, setImgSrc] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [isCropping, setIsCropping] = useState(false)

  const setFIleAsUrl = async file => {
    let imageDataUrl =
      typeof file === 'string' &&
      /^data:((?:\w+\/(?:(?!;).)+)?)((?:;[\w\W]*?[^;])*),(.+)$/.test(file)
        ? file
        : await readFile(file)

    // apply rotation if needed

    let orientation
    try {
      orientation = typeof file === 'string' ? 0 : await getOrientation(file)
    } catch (error) {
      orientation = 0
    }
    const rotation = ORIENTATION_TO_ANGLE[orientation]
    if (rotation) {
      imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
    }
    setImgSrc(imageDataUrl)
    setCrop({ x: 0, y: 0 })
    setZoom(1)
    if (setImgSrcFromParent) setImgSrcFromParent(imageDataUrl)
    if (setCropFromParent) setCropFromParent({ x: 0, y: 0 })
    if (setZoomFromParent) setZoomFromParent(1)
  }

  useEffect(() => {
    if (file) setFIleAsUrl(file)
  }, [file])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    if (setCroppedAreaPixelsFromParent)
      setCroppedAreaPixelsFromParent(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      setIsCropping(true)
      //const [croppedImage, croppedFile] = await getCroppedImg(imgSrc, croppedAreaPixels, rotation)
      const croppedImageInBase64 = await getCroppedImg(
        imgSrc,
        croppedAreaPixels,
        rotation,
      )
      //const newFile = dataURLtoFile(croppedImageInBase64, file.name)
      const newFile = dataURLtoFile(
        croppedImageInBase64,
        file.name,
        file.mime_type,
      )
      setCroppedImage(croppedImageInBase64)
      if (setCroppedLogo) {
        setCroppedLogo(newFile)
        //setCroppedLogo(croppedImage)
      }
      setIsCropping(false)
    } catch (e) {
      setIsCropping(false)
    }
  }, [croppedAreaPixels, rotation])

  const cancelChanges = useCallback(e => {
    if (!!e && !!e.preventDefault) e.preventDefault()
    setRotation(0)
    setZoom(1)
    setCrop({ x: 0, y: 0 })
    setCroppedImage(null)
    setCroppedAreaPixels(null)
    if (onCancelCrop) onCancelCrop()
  })

  const isInProps = item => item !== undefined

  const onCropChange = value => {
    setCrop(value)
    if (isInProps(setCropFromParent)) setCropFromParent(value)
  }
  const onRotationChange = value => {
    setRotation(value)
    if (setRotationFromParent) setRotationFromParent(value)
  }
  const onZoomChange = value => {
    setZoom(value)
    if (setZoomFromParent) setZoomFromParent(value)
  }

  return (
    <div className={classes.cropWrapper}>
      <div className={classes.logoPlaceholder}>
        <div className={classes.dragLabelContainer}>
          <div className={classes.dragLabel}>{label}</div>
        </div>
        {imgSrc ? (
          <div className={classes.cropContainer}>
            <Cropper
              image={imgSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              onCropChange={onCropChange}
              //onRotationChange={onRotationChange}
              onCropComplete={onCropComplete}
              //onZoomChange={onZoomChange}
              cropShape={cropShape}
              aspect={1 / 1}
              cropSize={{ width: 200, height: 200 }}
              showGrid={false}
              zoomWithScroll={true}
              restrictPosition={false}
            />
          </div>
        ) : (
          <Preloader />
        )}
      </div>
      {!!imgSrc && (
        <div className={classes.controls}>
          <div className={classes.sliderContainer}>
            <Typography classes={{ root: classes.sliderLabel }}>
              Zoom
            </Typography>
            <div className={classes.root}>
              <div
                onClick={() =>
                  setZoom(prev => {
                    if (prev - 0.1 > 0) {
                      return prev - 0.1
                    }
                    return prev
                  })
                }
                className={classes.controlsBtn}
              >
                <Remove />
              </div>
              <Slider
                value={zoom}
                min={0}
                max={2}
                step={0.1}
                aria-labelledby='Zoom'
                classes={{ root: classes.slider }}
                onChange={(e, zoom) => {
                  onZoomChange(zoom)
                }}
              />
              <div
                onClick={() =>
                  setZoom(prev => {
                    if (prev + 0.1 < 2) {
                      return prev + 0.1
                    }
                    return prev
                  })
                }
                className={classes.controlsBtn}
              >
                <Add />
              </div>
            </div>
          </div>
          <div className={classes.sliderContainer}>
            <Typography classes={{ root: classes.sliderLabel }}>
              Rotate
            </Typography>
            <div className={classes.root}>
              <div
                onClick={() =>
                  setRotation(prev => {
                    if (prev - 90 !== -270) {
                      return prev - 90
                    }
                    return prev
                  })
                }
                className={classes.controlsBtn}
              >
                <RotateLeft />
              </div>
              <Slider
                value={rotation}
                min={-180}
                max={180}
                step={1}
                aria-labelledby='Rotation'
                classes={{ root: classes.slider }}
                onChange={(e, rotation) => {
                  onRotationChange(rotation)
                }}
              />
              <div
                onClick={() =>
                  setRotation(prev => {
                    if (prev + 90 !== 270) {
                      return prev + 90
                    }
                    return prev
                  })
                }
                className={classes.controlsBtn}
              >
                <RotateRight />
              </div>
            </div>
          </div>
          <div
            className={classes.buttonsBlock + (hideButtons ? ' d-none' : '')}
          >
            <Button type='link' onClick={cancelChanges} disabled={isCropping}>
              Cancel
            </Button>
            <Button
              type='link'
              onClick={showCroppedImage}
              disabled={isCropping}
            >
              {isCropping && <SmallPreloader />} Save
            </Button>
            {/* <Button
              onClick={showCroppedImage}
              variant='contained'
              color='primary'
              classes={{ root: classes.cropButton }}
              disabled={isCropping}
            >
              Show Result
            </Button> */}
          </div>
        </div>
      )}
      {/* <ImgDialog img={croppedImage} onClose={onClose} /> */}
    </div>
  )
}

export default withStyles(styles)(ImgCropper)
