
import PDFPages from "./components/pages/PDFPages"
import {dataURLFileLoader} from '../../utils/fileLoaders.tsx'

const PDFRenderer= () => {
  return (
      <div id="pdf-renderer" className="pdf-wrapper">
        <PDFPages />
      </div>
  );
};

PDFRenderer.fileTypes = ["pdf", "application/pdf"]
PDFRenderer.headerType ='pdf'
PDFRenderer.fileLoader = dataURLFileLoader
export default PDFRenderer



  


