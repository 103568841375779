import React from 'react'
import MainButton from '../buttons/MainButton'

import './ErrorBoundary.scss'

const Fallback = () => {
  const handleButtonClick = () => {
    window.location.href = '/projects'
  }

  return (
    <div className={'fallback-body'}>
      <h2 className={'fallback-title'}>
        Oops, something went wrong, please try to reload or go to{' '}
        <MainButton
          className={'fallback-home-button'}
          title={'home page'}
          onClick={handleButtonClick}
        />
      </h2>
      <div className='gears'>
        <div className='gear one'>
          <div className='bar' />
          <div className='bar' />
          <div className='bar' />
        </div>
        <div className='gear two'>
          <div className='bar' />
          <div className='bar' />
          <div className='bar' />
        </div>
        <div className='gear three'>
          <div className='bar' />
          <div className='bar' />
          <div className='bar' />
        </div>
      </div>
    </div>
  )
}

export default Fallback
