import { IAppointment } from './Appointment'
import { INote } from 'containers/MainContent/Orcatec/components/Notes/types'
import { Media } from './Media'
import { Proposal } from './Proposal'
import { Client } from './Client'
import { MembershipLabel } from 'features/Membership/types'

export enum PropertyType {
  COMMERCIAL = 1,
  RESIDENTIAL = 2,
}
export interface Property {
  address: string
  appointments?: IAppointment[]
  city: string
  clients?: Client[]
  created_at?: string
  deleted_at?: string | null
  full_address: string
  id?: number
  is_primary?: boolean
  is_manual: false
  lat: number | null
  lng: number | null
  media?: Media[]
  memberships: MembershipLabel[]
  notes: INote[] | string
  organizations?: Client[]
  owner_user_id: number
  postcode: string
  proposals?: Proposal[]
  proposal_relation?: string
  service_contracts?: []
  state: string
  tags: []
  type: PropertyType
  unit: string
  updated_at?: string
}
