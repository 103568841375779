import { useState } from 'react'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'

import AddressForm from '../AddressForm'
import Map from 'containers/MainContent/Orcatec/components/Map'
import Tags from '../Tags'

import './styles.scss'
import CustomAddressAutocompleteContainer from 'containers/MainContent/Orcatec/components/CustomAddressAutocomplete/CustomAddressAutocompleteContainer'
import Notes from '../../../../../components/Notes'
import MembershipLabels from 'features/Membership/components/MembershipLabels'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { NotesPermissions } from 'features/Settings/UsersAndGroups'

const MainInfo = ({
  property,
  onChange,
  onChooseAddress,
  onChangeMarker,
  onBlurUnitField,
  onAddressError,
  errors,
  setOnSaveNotesCallback,
  required = false,
  resetFromParent = false,
  isAppointment,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [manualInput, setManualInput] = useState(false)
  const [searchString, setSearchString] = useState('')

  const isNew = !property.id

  const toogleEditMode = () => setEditMode(!editMode)

  const handleChangeManual = () => {
    setManualInput(!manualInput)

    onChange({
      target: {
        name: 'is_manual',
        value: !manualInput,
      },
    })
  }

  return (
    <div className='main-info'>
      <div className='general-info'>
        {!!property?.memberships?.length && (
          <div style={{ display: 'inline-flex' }}>
            <p>Memberships: </p>
            <MembershipLabels memberships={property?.memberships} />
          </div>
        )}
        <AddressForm
          property={property}
          onChange={onChange}
          onChooseAddress={onChooseAddress}
          errors={errors}
          canEdit={!isAppointment}
          editMode={editMode}
          toogleEditMode={toogleEditMode}
          isNew={isNew}
          manualInput={manualInput}
          toggleManualInput={handleChangeManual}
          onAddressError={onAddressError}
          onBlurUnitField={onBlurUnitField}
          required={required}
          resetFromParent={resetFromParent}
          isAppointment={isAppointment}
        />

        {(!isNew || manualInput) && (
          <>
            <AccessControl scopes={[NotesPermissions.NOTES_CAN_READ]}>
              {!!property.id && (
                <Notes
                  setOnSaveNotesCallback={setOnSaveNotesCallback}
                  route={`properties/${property.id}/notes`}
                  entity='Property'
                />
              )}
            </AccessControl>

            <Tags tags={property?.tags} onChange={onChange} />

            {isNew && manualInput && (
              <MainButton
                type='cancel'
                title='Cancel manual input'
                onClick={handleChangeManual}
              />
            )}
          </>
        )}
      </div>

      {(!isNew || manualInput) && (
        <div className='map'>
          {manualInput && isNew && (
            <>
              <CustomAddressAutocompleteContainer
                value={searchString}
                onChangeValue={e => setSearchString(e.target.value)}
                customPrefixToSearchValue=''
                customSuffixToSearchValue={
                  !!property?.postcode && /^\d{5}$/.test(property?.postcode)
                    ? `, ${property?.postcode}`
                    : ''
                }
                onChoose={onChangeMarker}
                addressType='pelias'
                placeholder='ex. Rancho Bernardo Road & Bernardo Center Drive, San Diego, CA, USA'
                label='Search nearby or intersection'
              />
            </>
          )}
          {(!property?.lat || !property?.lng) && (
            <span>Please place the pin on the map for GPS routing</span>
          )}
          <Map
            marker={
              !!property?.lat && !!property?.lng
                ? [+property.lat, +property.lng]
                : null
            }
            onChangeMarkerPosition={onChangeMarker}
            isMarkerDaggable={editMode || manualInput}
          />
        </div>
      )}
    </div>
  )
}

export default MainInfo
