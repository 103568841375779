import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../../store/Orcatec/actions'
import Login from './Login'

const PagesLogin = props => {
  return (
    <>
      <Login authorization={props.authorization} />
    </>
  )
}

export default connect(null, { authorization: actions.user.authorization })(PagesLogin)
