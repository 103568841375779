import * as types from '../../types'

const initialState = {
  minTime: 5,
  maxTime: 20,
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
