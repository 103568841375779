//GET
import { Client } from 'types/Client'
import { AxiosResponse } from 'axios'
import axiosOrcatec from 'api/axiosInstance'
import { apiV2Config } from 'api/Property'

const getContactById = (id: number, type: number) => {
  const path = type === 1 ? 'organizations/' : 'clients/'
  return axiosOrcatec
    .get<AxiosResponse<Client>>(`${path}${id}`)
    .then(response => response.data)
}

const postContact = (data: Client, type) => {
  const path = type === 1 ? 'organizations' : 'clients'
  return axiosOrcatec
    .post<AxiosResponse<Client>>(path, data)
    .then(response => response.data)
}

const putContact = (id: number, type: number, data: Client) => {
  const path = type === 1 ? 'organizations/' : 'clients/'
  return axiosOrcatec
    .put<AxiosResponse<Client>>(`${path}${id}`, data)
    .then(response => response.data)
}

const deletingContact = (id: number, type: number) => {
  const path = type === 1 ? 'organizations/' : 'clients/'
  return axiosOrcatec
    .delete<AxiosResponse<Client>>(`${path}${id}`)
    .then(response => response?.data)
}

const getContactFeedback = (clientId, clientType) =>
  axiosOrcatec
    .get(`client-feedbacks/?entity_id=${clientId}&entity_type=${clientType}`)
    .then(({ data }) => data)

const createContactFeedback = (id, data) => {
  return axiosOrcatec
    .post(`client-feedbacks`, { ...data, entity_id: id })
    .then(response => response.data)
}

const updateContactFeedback = (id, data) => {
  return axiosOrcatec
    .put(`client-feedbacks/${id}`, data)
    .then(response => response.data)
}

const deleteContactFeedback = id => {
  return axiosOrcatec
    .delete(`client-feedbacks/${id}`)
    .then(response => response.data)
}

const attachContactToOrganization = (orgId, clientId, relation) => {
  const data = { client_id: clientId, contact_role: relation }
  return axiosOrcatec
    .put(`organizations/attach_contact/${orgId}`, data)
    .then(response => response.data)
}

const detachContactFromOrganization = (organizationId, data) => {
  return axiosOrcatec
    .put(`/organizations/detach_contact/${organizationId}`, data)
    .then(response => response.data)
}
const attachOrganizationToContact = (clientId, data) => {
  return axiosOrcatec
    .put(`/clients/attach_organization/${clientId}`, data)
    .then(response => response.data)
}

const detachOrganizationsFromContact = (clientId, data) => {
  return axiosOrcatec
    .put(`/clients/detach_organization/${clientId}`, data)
    .then(response => response.data)
}

const attachPropertyToContact = (
  contactId: number,
  propertyId: number,
  data: { note: string },
): Promise<Client> => {
  return axiosOrcatec
    .patch(
      `/clients/${contactId}/properties/${propertyId}/attach`,
      data,
      apiV2Config,
    )
    .then(response => response.data)
}

const detachPropertyFromContact = (
  contactId: number,
  propertyId: number,
): Promise<Client> => {
  return axiosOrcatec
    .patch(
      `/clients/${contactId}/properties/${propertyId}/detach`,
      {},
      apiV2Config,
    )
    .then(response => response.data)
}

export {
  getContactById,
  postContact,
  putContact,
  getContactFeedback,
  createContactFeedback,
  updateContactFeedback,
  deleteContactFeedback,
  deletingContact,
  attachContactToOrganization,
  detachContactFromOrganization,
  attachOrganizationToContact,
  detachOrganizationsFromContact,
  attachPropertyToContact,
  detachPropertyFromContact,
}
