import * as types from '../../types'
import * as company from '../../../../api/Company'
export const setCompany = item => {
  return {
    type: types.company.COMPANY_SET_ITEM,
    item,
  }
}

export const updateCompanyItem = (key, data) => {
  return {
    type: types.company.COMPANY_UPDATE_ITEM,
    payload: {
      key,
      data,
    },
  }
}
export const updateCompanyAppointmentSettings = (key, data) => {
  return {
    type: types.company.COMPANY_UPDATE_APPOINTMENT_SETTINGS,
    payload: {
      key,
      data,
    },
  }
}

export const updateCompanyDictionarySettings = (key, data) => {
  return {
    type: types.company.COMPANY_SET_DICTIONARY_ITEM,
    payload: {
      key,
      data,
    },
  }
}

export const getItem = () => {
  return {
    type: types.company.COMPANY_GET_ITEM,
  }
}

export const fetchCompany = () => dispatch => {
  company.fetchCompany().then(response => {
    dispatch(setCompany(response))
  })
}

export const saveCompany = (info, companyId) => (dispatch, getState) => {
  return company
    .saveCompanyInfo(info, companyId)
    .then(response => {
      dispatch(setCompany(response))
      return response
    })
    .catch(error => error)
}

export const saveCompanyDictionary = (key, data) => dispatch => {
  return company.saveJobDictionary(data).then(response => {
    dispatch(updateCompanyDictionarySettings(key, data))
    return response
  })
}

export const enableOrDisablePublicAppointments = status => ({
  type: types.company.ENABLE_OR_DISABLE_PUBLIC_APPOINTMENTS,
  payload: status,
})

// type and source

export const setCompanySourceAction = source => {
  return {
    type: types.company.SET_COMPANY_SOURCE,
    payload: source,
  }
}

export const updateCompanySourceAction = source => {
  return {
    type: types.company.UPDATE_COMPANY_SOURCE,
    payload: source,
  }
}

export const deleteSourcesAction = id => {
  return {
    type: types.company.DELETE_COMPANY_SOURCE,
    payload: id,
  }
}

export const setCompanyTypesAction = type => {
  return {
    type: types.company.SET_COMPANY_TYPES,
    payload: type,
  }
}
export const updateCompanyTypesAction = type => {
  return {
    type: types.company.UPDATE_COMPANY_TYPES,
    payload: type,
  }
}
export const deleteCompanyTypesAction = id => {
  return {
    type: types.company.DELETE_COMPANY_TYPES,
    payload: id,
  }
}

export const getCompanyTechniciansAction = tech => {
  return {
    type: types.company.GET_COMPANY_TECHNICIANS,
    payload: tech,
  }
}

export const deleteCompanyBrandId = () => {
  return {
    type: types.company.DELETE_COMPANY_BRAND,
  }
}
