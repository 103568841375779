import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Tooltip } from 'antd'

import InputField from 'containers/MainContent/Orcatec/components/Input'

import { messagingAPI } from 'api/MessagingAPI'

import ModalFileUploadContainer from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalFileUploadContainer'
import { useDispatch } from 'react-redux'
import * as actions from 'store/Orcatec/actions'
import {
  ChatInfo,
  IFormValues,
  IMessage,
  MessagesPagination,
  IExistingPhone,
} from '../../types'
import {
  initialChatInfoState,
  initialFormValuesState,
  initialMessagesPaginationState,
} from '../../utils'

import { Media } from 'types/Media'

import { ChatHeader } from './components/ChatHeader/ChatHeader'

import { AppStateType } from 'store'
import MediaPreviewList from './components/MediaPreviewList'
import { useAppSelector } from 'store/Orcatec/hooks'
import './Chat.scss'

import {
  LoadingOutlined,
  PaperClipOutlined,
  SendOutlined,
} from '@ant-design/icons'
import { InputRow } from './Chat.styles'
import { MessagesList } from './components/MessagesList/MessagesList'

import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { clearPhoneNumber, shouldAddOne } from './utils'
import MessagesCount from '../MessagesCount/MessagesCount'
import moment from 'moment-timezone'

interface ChatProps {
  chatID?: null | number
  isNewChatView?: boolean
  allHistoryMode?: boolean
  isOrganization?: boolean
  addMessageToList: (arg) => void
  fetchAllChats?: () => void
}

declare global {
  interface Window {
    Echo: any
  }
}

const isNull = (el: any): boolean => el === null

const Chat: React.FC<ChatProps> = ({
  chatID = null,
  addMessageToList,
  isNewChatView = false,
  allHistoryMode = false,
  isOrganization = false,
  fetchAllChats,
  // countUnread = 0,
}) => {
  const containerRef: React.RefObject<HTMLDivElement> = useRef(null)
  const entityID = useAppSelector(
    (state: AppStateType) =>
      state.orcatec.client.currentClient.id ||
      state.orcatec.organizations.currentOrganization.id,
  )

  const dispatch = useDispatch()

  const [chatInfo, setChatInfo] = useState<ChatInfo>(initialChatInfoState)
  const [chatMessages, setChatMessages] = useState<IMessage[]>([])
  const [formValues, setFormValues] = useState<IFormValues>(
    initialFormValuesState,
  )
  const [messagesPagination, setMessagesPagination] = useState<
    MessagesPagination
  >(initialMessagesPaginationState)
  const [importedPhones, setImportedPhones] = useState<IExistingPhone[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isMessageSending, setIsMessageSending] = useState(false)
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false)
  // const [isSendDisabled, setIsSendDisabled] = useState(false)
  const [unseenM, setUnseenM] = useState<number[]>([])

  const [isConversationStarted, setIsConversationStarted] = useState(false)
  const timezone = useAppSelector(state => state.orcatec.company.timezone)

  // loaders
  const [isChatLoading, setIsChatLoading] = useState(false)

  const handleSetUnseen = (id: number) => {
    if (unseenM.includes(id)) {
      return
    }

    setUnseenM(p => [...p, id])
  }

  const loadPreviousMessages = () => {
    const allChecks =
      isNull(currentPage) ||
      messagesPagination.current_page === currentPage + 1 ||
      currentPage >= messagesPagination.last_page
    if (allChecks) {
      return
    }

    const content = containerRef.current

    const curScrollPos = content.scrollTop
    const oldScroll = content.scrollHeight - content.clientHeight

    const scroll = () => {
      const newScroll = content.scrollHeight - content.clientHeight
      content.scrollTop = curScrollPos + (newScroll - oldScroll)
    }

    setIsFetchingNextPage(true)
    allHistoryMode
      ? messagingAPI
          .getRelatedChatWithAllMessages(
            isOrganization,
            entityID,
            currentPage + 1,
          )
          .then(r => {
            setChatMessages(p => [...r.messages.reverse(), ...p])
            setCurrentPage(p => p + 1)
            setMessagesPagination(r.meta)
            setIsFetchingNextPage(false)
          })
          .then(() => scroll())
          .catch(e => console.error(e))
      : messagingAPI
          .getChatMessages(chatID, currentPage + 1)
          .then(r => {
            setChatMessages(p => [...r.messages.reverse(), ...p])
            setCurrentPage(p => p + 1)
            setMessagesPagination(r.meta)
            setIsFetchingNextPage(false)
          })
          // .then(() => scroll())
          .then(() => currentPage !== 1 && scroll())
          .catch(e => console.error(e))
  }

  const handleAttachFile = () => {
    dispatch(actions.proposalModalFiles.toggleModal())
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (value === ' ') return

    setFormValues(p => ({ ...p, [name]: value }))
  }

  const handleUploadMedia = (media: Media[]) => {
    setFormValues(p => ({ ...p, media }))
  }

  const deleteMediaUnit = (mediaID: number | undefined) => {
    if (mediaID !== undefined) {
      setFormValues(p => ({
        ...p,
        media: p.media.filter(item => item.id !== mediaID),
      }))
    }
  }

  const sendMessage = () => {
    setIsMessageSending(true)

    const data = {
      fromNumber: formValues.fromNumber,
      toNumber: shouldAddOne(clearPhoneNumber(formValues.toNumber)),
      body: formValues.body,
      media: formValues.media.map(({ source }) => source),
    }

    messagingAPI
      .sendSMS(data)
      .then(({ data }) => {
        const message = data?.[0]
        if (!chatID) {
          addMessageToList(message)
        }
        setFormValues(p => ({ ...p, body: '', media: [] }))
        setChatMessages(p => [...p, ...data])
        fetchAllChats?.()
        scrollToBottom()
        setIsConversationStarted(true)
        openNotificationWithIcon('success', {
          message: 'Success!',
          description: 'The message was sent!',
        })
      })
      .catch(e => {
        openNotificationWithIcon('error', {
          message: 'Error!',
          description: `${e.response?.data?.errors?.[
            Object?.keys?.(e.response?.data?.errors)[0]
          ] ?? e.response?.data?.message}`,
        })
      })
      .finally(() => setIsMessageSending(false))
  }

  const deleteMessage = (messageID: number) => {
    if (chatID)
      messagingAPI
        .deleteMessage(chatID, messageID)
        .then(() => {
          openNotificationWithIcon('success', {
            description: 'Message successfully delete!',
          })
          setChatMessages(prev => prev.filter(el => el.id !== messageID))
        })
        .catch(() =>
          openNotificationWithIcon('error', {
            description: 'Failed to delete message!',
          }),
        )
  }

  const resendMessage = (messageID: number) => {
    if (!chatID) return
    const messageToResend: IMessage = chatMessages?.find(
      message => message?.id === messageID,
    )
    const { media, from, to, message } = messageToResend
    const newMessage = {
      body: message,
      fromNumber: from,
      media: media,
      toNumber: to,
    }

    messagingAPI
      .deleteMessage(chatID, messageID)
      .then(() => messagingAPI.sendSMS(newMessage))
      .then(() => {
        setChatMessages(prev => prev.filter(el => el.id !== messageID))
        openNotificationWithIcon('success', {
          description: 'Message sent successfully!',
        })
      })
      .catch(() =>
        openNotificationWithIcon('error', {
          description: 'Failed to resent message!',
        }),
      )
  }

  const handleOnScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (
      e.target.scrollTop >= e.target.scrollHeight * 0.1 ||
      isFetchingNextPage
    ) {
      return
    }
    loadPreviousMessages()
  }

  const renderFilesModal = (
    <ModalFileUploadContainer
      formats={['jpeg', 'png', 'gif']}
      customFilesLimit={9}
      files={formValues.media}
      handleSave={handleUploadMedia}
      disableAddByLink
    />
  )

  const scrollToBottom = () => {
    setTimeout(() => {
      containerRef.current?.scrollTo({
        top: containerRef.current?.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    }, 0)
  }
  const handleClientAssignedEvent = (event: {
    chat_id: number
    client_name: string
  }) => {
    setChatInfo(prev => ({ ...prev, client_name: event.client_name }))
  }

  useEffect(() => {
    if (allHistoryMode) {
      messagingAPI
        .getRelatedChatWithAllMessages(isOrganization, entityID)
        .then(r => {
          setChatMessages(r.messages.reverse())
          setMessagesPagination(r.meta)
        })
        .then(
          () =>
            (containerRef.current.scrollTop =
              containerRef.current.scrollHeight),
        )
        .catch(e => console.error(e))
    }
  }, [])

  //   useEffect(() => {
  //     const isPhoneValid =
  //       !formValues.toNumber.includes('X') && !!formValues.toNumber.length
  //
  //     if (
  //       (!!formValues.body.length || !!formValues.media.length) &&
  //       isPhoneValid &&
  //       formValues.fromNumber?.length
  //     ) {
  //       setIsSendDisabled(false)
  //     } else {
  //       setIsSendDisabled(true)
  //     }
  //   }, [formValues])

  useEffect(() => {
    if (!chatID) return
    const MESSAGE_CREATED_EVENT = '.sms.message-created'
    const CHAT_CLIENT_ASSIGNED_EVENT = '.client-assigned'

    window.Echo.leaveChannel(`sms-chat.${chatID}`)
    localStorage.removeItem('isNewChatView')

    window.Echo?.private(`sms-chat.${chatID}`)
      .listen(MESSAGE_CREATED_EVENT, (event: { message: IMessage }) => {
        setChatMessages(prev => {
          const allMessages = [...prev, event.message]
          const arrChatsIds = prev?.map(({ chats }) => chats)?.flat(1)
          // debugger
          if (arrChatsIds?.includes(chatID)) {
            return [
              ...allMessages?.filter(m => m?.id !== event?.message?.id),
              event?.message,
            ]
          } else {
            return prev
          }
        }) // setChatMessages(p => [...p, message])
        scrollToBottom()
      })
      .listen('.sms.status-changed', (e: any) => {
        setChatMessages(p => {
          const newState = [...p]

          newState.map(item => {
            if (item.id === e.id) {
              item.status = e.status
              return item
            } else return item
          })

          return newState
        })
      })
      .listen(
        '.sms.message-seen',
        ({
          data,
        }: {
          id: number
          seen: { id: number; full_name: string; profile_picture: string }[]
        }) => {
          setChatMessages(p => {
            const newState = [...p]

            data.map(el => {
              newState.map(i => {
                if (el.id === i.id) {
                  i.seen = el.seen
                  return i
                } else {
                  return i
                }
              })
            })

            return newState
          })
        },
      )
      .listen(CHAT_CLIENT_ASSIGNED_EVENT, handleClientAssignedEvent)
  }, [chatID])

  useEffect(() => {
    messagingAPI
      .getAvailablePhoneNumbers()
      .then(({ phones }) => {
        setImportedPhones(phones)
        setFormValues(p => ({ ...p, ['fromNumber']: phones?.[0]?.number }))
      })
      .catch(e => console.error(e))
  }, [chatID])

  useEffect(() => {
    if (isNull(chatID)) {
      return
    }

    setIsChatLoading(true)

    messagingAPI
      .getChatInfo(chatID)
      .then(r => {
        setChatInfo(r)
        setFormValues(p => ({ ...p, fromNumber: r.from, toNumber: r.to }))
      })
      .catch(e => console.error(e))

    messagingAPI
      .getChatMessages(chatID)
      .then(r => {
        setChatMessages(r.messages.reverse())
        setMessagesPagination(r.meta)
        setCurrentPage(r.meta.current_page)
        scrollToBottom()
        // if (messagesPagination.current_page === 1) {
        //scrollToBottom()
        // }
      })

      // .then(
      //   () =>
      //     (containerRef.current.scrollTop = containerRef.current.scrollHeight),
      // )
      .catch(e => console.error(e))
      .finally(() => setTimeout(() => setIsChatLoading(false), 100))
  }, [chatID])

  const groups = useMemo(
    () =>
      chatMessages.reduce((groups, game) => {
        // const date = game.created_at.split('T')[0]
        const date = moment(game.created_at)
          .tz(timezone)
          .format('YYYY-MM-DD')

        if (!groups[date]) {
          groups[date] = []
        }

        groups[date].push(game)

        return groups
      }, {}),
    [chatMessages],
  )

  const groupArrays = useMemo(
    () => Object.keys(groups).map(date => ({ date, messages: groups[date] })),
    [groups],
  )
  const attachedTechnicians = useMemo(
    () => chatInfo.technicians?.map?.(({ id }) => id) || [],
    [chatInfo],
  )

  const phoneNumber = formValues.toNumber

  const isSendButtonDisabled =
    !(formValues.body.trim().length || formValues.media.length) ||
    phoneNumber.includes('X') ||
    !phoneNumber.length

  const isUserHasAbilityToSend =
    importedPhones?.map(phone => phone.number).includes(chatInfo.from) ||
    (isNewChatView && !!importedPhones.length)

  return (
    <div className='chat-wrapper'>
      <div className='chat-header'>
        {!allHistoryMode && (
          <ChatHeader
            chatID={chatID}
            chatInfo={chatInfo}
            isNewChatView={isNewChatView}
            formValues={formValues}
            handleChange={handleChange}
            attachedTechnicians={attachedTechnicians}
            importedPhones={importedPhones}
            setImportedPhones={setImportedPhones}
            isConversationStarted={isConversationStarted}
          />
        )}
      </div>

      <div className='chat-list' ref={containerRef} onScroll={handleOnScroll}>
        <MessagesList
          deleteMessage={deleteMessage}
          resendMessage={resendMessage}
          isFetchingNextPage={isFetchingNextPage}
          isChatLoading={isChatLoading}
          handleSetUnseen={handleSetUnseen}
          groupArrays={groupArrays}
          chatInfo={chatInfo}
        />
      </div>

      <div className='chat-footer'>
        {!allHistoryMode && isUserHasAbilityToSend && (
          <>
            <InputRow>
              <Tooltip title='Attach File'>
                <PaperClipOutlined
                  className={`${
                    formValues.body.trim().length > 0 ? 'disabledIcon' : ''
                  }`}
                  onClick={handleAttachFile}
                  style={{ fontSize: 24 }}
                />
              </Tooltip>
              <InputField
                className='chat-input'
                name='body'
                value={formValues.body}
                onChange={handleChange}
                label='Message'
                placeholder={
                  formValues.media.length > 0
                    ? 'You can send only sms or only mms'
                    : 'Message'
                }
                maxRows={5}
                inputProps={{ maxLength: 918 }}
                disabled={formValues.media?.length > 0}
                multiline
              />
              {!allHistoryMode ? (
                <Tooltip title='Send'>
                  {isMessageSending ? (
                    <LoadingOutlined style={{ fontSize: 24 }} />
                  ) : (
                    <SendOutlined
                      className={`${
                        isSendButtonDisabled ? 'disabledIcon' : ''
                      }`}
                      style={{ fontSize: 24 }}
                      onClick={sendMessage}
                    />
                  )}
                </Tooltip>
              ) : null}
            </InputRow>
            <MessagesCount value={formValues.body} />
          </>
        )}

        <MediaPreviewList
          mediaList={formValues.media}
          deleteMediaUnit={deleteMediaUnit}
        />
      </div>
      {renderFilesModal}
    </div>
  )
}

export default Chat
