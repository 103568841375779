import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
  position: relative;
  top: 7px;

  span {
    font-size: 24px;
    line-height: 0;

    &:hover {
      color: #4285f4;
    }
  }
`
