import React, { FC } from 'react'
import { IReview, REVIEWS_STATUS } from 'features/Reviews/types'
import { Email } from '@material-ui/icons'
import { Tooltip } from 'antd'
import styled from 'styled-components'

import { makeStyles, Theme } from '@material-ui/core'

interface IProps {
  status: IReview['event_type']
  review: IReview
}

const useStyles = makeStyles<Theme, { status: number }>(theme => ({
  emailIcon: {
    color: props => {
      switch (props.status) {
        case REVIEWS_STATUS.ALREADY_SEND:
          return theme.palette.primary.main
        case REVIEWS_STATUS.DONT_SEND:
          return theme.palette.error.main
        case REVIEWS_STATUS.TO_SEND:
          return theme.palette.common.black
        case REVIEWS_STATUS.REPLIED:
          return theme.palette.success.main
        default:
          return theme.palette.text.primary
      }
    },
  },
}))

const statusText = {
  [REVIEWS_STATUS.ALREADY_SEND]: 'Review already sent',
  [REVIEWS_STATUS.DONT_SEND]: 'Do not send request',
  [REVIEWS_STATUS.TO_SEND]: 'Request Not Sent',
  [REVIEWS_STATUS.REPLIED]: 'Replied',
}

export const ReviewStatus: FC<IProps> = ({ status }) => {
  const classes = useStyles({ status })
  return (
    <Wrapper>
      <Tooltip title={statusText[status]}>
        <Email className={classes.emailIcon} />
      </Tooltip>
    </Wrapper>
  )
}

const Wrapper = styled.div``
