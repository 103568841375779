import React, { memo } from 'react'

export const Time = memo(({ maxTime = 18, minTime = 9 }) => (
  <div className='time-container'>
    {new Array(maxTime - minTime)?.fill('').map((time, i) => {
      return (
        <div key={i} className='time'>
          {i + minTime > 12 && i + minTime < 24
            ? `${i + minTime - 12}p`
            : i + minTime === 24
            ? `12a`
            : i + minTime === 12
            ? `12p`
            : i === 0 && minTime === 0
            ? `12a`
            : `${i + minTime < 12 ? i + minTime : i + minTime - 24}a`}
          {/* : `${i + Number(minTime)}a`} */}
        </div>
      )
    })}
  </div>
))
