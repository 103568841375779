import React, { memo, useCallback, useEffect, useState } from 'react'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import DropdawnTable from './DropdawnTable/DropdawnTable'
import useSearch from '../../SearchHook'
import { addressBuilder } from '../../../../../../helpers/OSMAddressBuilder'
//import { fetchOpenStreetMapAddressFromCoordinates } from '../../../../../../api/map'
import { SmallPreloader } from '../../Preloader/SmallPreloader'
import isMobile from '../../../Mobile/helpers/isMobile'
import { transformStringIfAddressInHere } from 'helpers/transformStringIfAddressInHere'
import InputField from '../../Input'
import { Switch } from 'antd'

import './CustomAddressAutocomplete.scss'

const maxTableSize = { maxHeight: '260px' }
const disabledAction = e => {
  if (e) {
    if (e.preventDefault) e.preventDefault()
    if (e.stopImmediatePropagation) e.stopImmediatePropagation()
  }
}

const CustomAddressAutocomplete = memo(
  ({
    value = '',
    onChangeValue,
    error = '',
    searchFn,
    onClickRow,
    onClickManual,
    id = 'custom-autocomplete-address',
    label = 'Property Address*',
    name = 'address',
    className = '',
    disabled = false,
    required,
    addressType = 'both', // both || pelias || database
    placeholder = '',
    customPrefixToSearchValue = '',
    customSuffixToSearchValue = '',
    noTable = false,
    searchType = 'address',
    showSearchTypeSwitcher,
    searchByClientName,
    setSearchByClientName,
    autoFocus,
  }) => {
    const [isSearching, setIsSearching] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [showDropdown, setShowDropdown] = useState(false)
    const onBlur = useCallback(e => {
      if (!e.target.matches('.custom-address-autocomplete-dropdown')) setShowDropdown(false)
    }, [])

    useEffect(() => {
      window.addEventListener('click', onBlur)
      return () => {
        window.removeEventListener('click', onBlur)
      }
    }, [])

    //result = {DB: [], Pelias: []}
    const [result, setResult] = useSearch(
      searchValue,
      debouncedValue => {
        setIsSearching(true)
        return searchFn(debouncedValue, searchType === 'postcode' ? 'search' : '').finally(() => {
          setIsSearching(false)
        })
      },
      700, //delay
      'address',
      1,
      10,
      '',
      true,
      searchByClientName,
    )

    const DBResult = !!result && !!result.DB && !!result.DB.length ? result.DB : []
    const peliasResult =
      !!result && !!result.Pelias && !!result.Pelias.length
        ? result.Pelias.filter(item => item?.properties?.name !== item?.properties?.postalcode).reduce(
            (acc, item, idx) => {
              let normalizedAddress = addressBuilder(item)
              const isValidData =
                !!normalizedAddress.address &&
                !!normalizedAddress.city &&
                !!normalizedAddress.state &&
                !!normalizedAddress.postcode

              if (isValidData) acc.push(normalizedAddress)
              return acc
            },
            [],
          )
        : []

    const options =
      addressType === 'both'
        ? [...DBResult, ...peliasResult]
        : addressType === 'database'
        ? DBResult
        : addressType === 'pelias'
        ? peliasResult
        : []

    const onFocus = useCallback(
      e => {
        e.stopPropagation()
        e.preventDefault()
        if (value && !disabled) setShowDropdown(true)
      },
      [value, disabled],
    )

    /* const setParamOfSearch = e => {
      if (e.keyCode === 13 && (searchType !== 'postcode' || (searchType === 'postcode' && searchValue.length === 5))) {
        searchFn(searchValue, 'search').then(data => setResult(data))
      }
    } */

    const onChange = useCallback(
      e => {
        const value =
          searchType !== 'postcode'
            ? e.target.value
            : e.target.value
                .trim()
                .replace(/\D/g, '')
                .slice(0, 5)
        const isCanSearch = (searchType !== 'postcode' && !!value) || (searchType === 'postcode' && value.length === 5)
        if (isCanSearch) setShowDropdown(true)
        setSearchValue(isCanSearch ? customPrefixToSearchValue + value + customSuffixToSearchValue : searchValue)
        setIsSearching(isCanSearch)
        onChangeValue(e, value)
      },
      [onChangeValue, customPrefixToSearchValue, customSuffixToSearchValue, searchType],
    )

    const onChooseOption = useCallback(
      data => e => {
        if (isMobile && e.target.nodeName === 'I') return
        onClickRow(data)
        setShowDropdown(false)
      },
      [onClickRow],
    )

    const onClickManualOption = useCallback(
      e => {
        setShowDropdown(false)
        onClickManual()
      },
      [onClickManual],
    )

    const onClickDropdownContainer = useCallback(e => {
      e.stopPropagation()
      e.preventDefault()
    }, [])

    const notTable = noTable || searchType === 'postcode'

    return (
      <div
        onClick={disabled ? disabledAction : onFocus}
        onFocus={disabled ? disabledAction : onFocus}
        className={`custom-address-autocomplete-wrapper${className ? ` ${className}` : ''}`}
        disabled={disabled}
      >
        <div /* onKeyUp={setParamOfSearch} */>
          <InputField
            id={id}
            label={transformStringIfAddressInHere(label)}
            name={name}
            value={value}
            onChange={disabled ? disabledAction : onChange}
            error={error}
            fullWidth
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            onClick={() => setShowDropdown(true)}
            autoFocus={autoFocus}
          />
          {/*  <TextField
            id={id}
            label={transformStringIfAddressInHere(label)}
            name={name}
            value={value}
            onChange={disabled ? disabledAction : onChange}
            error={!!error}
            helperText={error}
            fullWidth={true}
            type='text'
            variant='outlined'
            size='small'
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            inputProps={{
              autoComplete: 'off',
            }}
            InputLabelProps={{
              shrink: true,
            }}
          /> */}
        </div>

        {!!showDropdown && (
          <div
            className={
              'custom-address-autocomplete-dropdown custom-address-autocomplete-dropdown-normal' +
              (notTable ? ' custom-address-autocomplete-dropdown-no_table' : '')
            }
            onClick={onClickDropdownContainer}
          >
            <SimpleBar style={maxTableSize} forceVisible='y' autoHide={false}>
              {showSearchTypeSwitcher && (
                <div className='search-type'>
                  <span>Search by Address</span>
                  <Switch checked={searchByClientName} onChange={(checked, e) => setSearchByClientName(checked)} />
                  <span>by Name</span>
                </div>
              )}
              {!!searchValue && (
                <>
                  {!isSearching ? (
                    options.length ? (
                      <DropdawnTable
                        rows={options}
                        onClickRow={onChooseOption}
                        showInfo={addressType === 'database' || (addressType === 'both' && !!DBResult.length)}
                        noTable={notTable}
                        searchType={searchType}
                      />
                    ) : (
                      <div className='custom-address-autocomplete-dropdown-no-data'>So far no results.</div>
                    )
                  ) : (
                    <div className='custom-address-autocomplete-dropdown-preloader'>
                      <SmallPreloader />
                    </div>
                  )}
                </>
              )}
            </SimpleBar>
            {!!onClickManual && (
              <div className='custom-address-autocomplete-dropdown-no-address'>
                {`Can't find your address? `}
                <u onClick={onClickManualOption}>Manual Input</u>
              </div>
            )}
          </div>
        )}
      </div>
    )
  },
)

export default CustomAddressAutocomplete
