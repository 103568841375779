import styled from 'styled-components'

export const Wrapper = styled.div`
  & > * {
    margin-bottom: 16px !important;
  }

  .rmdp-container {
    width: 100%;
  }

  /* .styled-datepicker {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 32px;
    padding: 4px 25px 4px 11px;
    cursor: pointer;
  } */
`

export const AssagneeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: center;

  .time-start {
    position: relative;

    &::before {
      position: relative;
      content: 'Promised arrival time:';
      left: 0;
      top: -23px;
      font-size: 0.8rem;
      width: 200px;
    }
  }

  .matrix-time__start {
    position: relative;

    &::before {
      position: absolute;
      content: 'Schedule time:';
      left: 0;
      top: -23px;
      font-size: 0.8rem;
      width: 200px;
    }
  }

  & > * {
    width: 100%;
  }
`

export const DateSection = styled(AssagneeSection)`
  margin-bottom: 25px !important;
`

export const TimeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: end;
`

export const Row = styled.div`
  display: flex;
  gap: 10px;
`

export const Type = styled.div``
export const Date = styled.div<{ error?: boolean }>`
  position: relative;

  & > span {
    position: absolute;
    right: 5px;
    top: 28px;
    font-size: 18px;
    pointer-events: none;
    color: #0000008a;
  }

  .styled-datepicker {
    width: 100%;
    border: 1px solid;
    border-color: ${props => (props.error ? '#f12832' : '#d9d9d9')};
    border-radius: 2px;
    height: 32px;
    padding: 4px 25px 4px 11px;
    cursor: pointer;
  }
  .rmdp-toolbar {
    & > div {
      visibility: hidden;

      &:nth-child(3) {
        visibility: visible;
      }
    }
  }
`
export const Label = styled.p`
  font-size: 12px;
  color: #808080;
  line-height: 1.5;
  margin-bottom: 3px;
`
