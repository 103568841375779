import axiosOrcatec from './axiosInstance'

export const getEmailHistory = (id, type) => {
  return axiosOrcatec
    .get(`/proposal-service-contract-emails?entity_id=${id}&entity_type=${type}`)
    .then(response => response.data)
}

export const getEmailData = (id, type) => {
  return axiosOrcatec
    .get(`/proposal-service-contract-emails/data-for-email?entity_id=${id}&entity_type=${type}`)
    .then(response => response.data)
}
