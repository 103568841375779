import axiosOrcatec from '../../../api/axiosInstance'

export const fetch = requestParams => {
  const defaultParams = {
    perPage: 10,
    page: 1,
    sort: '',
    order: '',
    search: '',
  }

  const params = { ...defaultParams, ...requestParams }

  const { perPage, page, sort, order, search } = params

  return axiosOrcatec.get(
    `/admin/support/tickets?&perPage=${perPage}&page=${page}&sort=${sort}&order=${order}&search=${search}`,
  )
}

export const ticket = id => axiosOrcatec.get(`/admin/support/tickets/${id}`)

export const availableAssistants = id => axiosOrcatec.get(`/admin/support/tickets/${id}/assistants`)

export const assignAssistant = (ticketId, assistantId) =>
  axiosOrcatec.post(`/admin/support/tickets/${ticketId}/assistants`, {
    assistant_id: assistantId,
  })

export const resolveTicket = id => axiosOrcatec.put(`/admin/support/tickets/${id}/resolve`)

export const uploadReplyAttachment = file => {
  const formData = new FormData()
  formData.append('file', file)

  return axiosOrcatec.post('/admin/support/attachments', formData)
}

export const replyMessage = (id, data) => axiosOrcatec.post(`/admin/support/tickets/${id}/messages`, data)

export const downloadAttachment = id => axiosOrcatec.get(`/admin/support/attachments/${id}`, { responseType: 'blob' })

export const deleteMessage = (ticketId, messageId) =>
  axiosOrcatec.delete(`/admin/support/tickets/${ticketId}/messages/${messageId}`)

export const updateMessage = (ticketId, messageId, data) =>
  axiosOrcatec.put(`/admin/support/tickets/${ticketId}/messages/${messageId}`, data)

export const unseenTicketsCount = () => axiosOrcatec.get(`/admin/support/tickets/unseen/count`)
