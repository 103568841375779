import moment from 'moment-timezone'
import { IAppointment } from 'types/Appointment'

const errorMsg = {
  column_template_id: 'Assign to field is required',
  date: 'The date is required.',
  timeNoWork: 'Selected time is not working or the employee is busy',
  timeInvalid: 'The time is invalid',
  dateInvalid: 'The date is invalid',
  timeSame: 'Time cannot be the same!',
  fieldReqiure: 'This field is reqiure',
  time_end: 'Promised time is required',
  time_start: 'Promised time is required',
  matrixTimeRequire: 'Dispatch time is required.',
  errorBefore: 'The time end must be a time after to time start.',
  errorAfter: 'The time start must be a time before to time end.',
}
const isSameTime = (timeStart, timeEnd) => {
  if (moment(timeStart).hours() === 0 && moment(timeEnd).hours() === 0) {
    return false
  }
  return moment(timeStart).hours() === moment(timeEnd).hours()
}
const isSameDate = (prevDate, nextDate) => {
  return moment(prevDate).isSame(moment(nextDate))
}
const isFirstTimeAfterSecond = (timeStart, timeEnd) => {
  return moment(timeStart)
    .set('minute', 0)
    .isAfter(moment(timeEnd).set('minute', 0))
}
const isSecondTimeBeforeFirst = (timeStart, timeEnd) => {
  return moment(timeEnd)
    .set('minute', 0)
    .isBefore(moment(timeStart).set('minute', 0))
    ? errorMsg.timeInvalid
    : null
}
const isWorkingTime = (time, timeArr) => {
  return timeArr?.includes(moment(time).hours())
}
const validateMatrixTime = (appointment, key, disabledTime) => {
  const { matrix_time_start, matrix_time_end } = appointment
  const isWorkTimeStart = isWorkingTime(
    matrix_time_start,
    disabledTime.matrixTimeStart,
  )
  const isValidStart = isFirstTimeAfterSecond(
    matrix_time_start,
    matrix_time_end,
  )
  const isSame = isSameTime(matrix_time_start, matrix_time_end)
  const isWorkTimeEnd = isWorkingTime(
    matrix_time_end,
    disabledTime.matrixTimeEnd,
  )
  const isValidEnd = isSecondTimeBeforeFirst(matrix_time_start, matrix_time_end)
  const isError = {
    start: false,
    matrix_time_end: '',
    end: false,
    matrix_time_start: '',
  }
  if (isSame) {
    isError.end = true
    isError.start = true
    isError.matrix_time_end = errorMsg.timeSame
    isError.matrix_time_start = errorMsg.timeSame
  }
  if (isWorkTimeStart && !isError.start) {
    isError.start = true
    isError.matrix_time_start = errorMsg.timeNoWork
  }
  if (isWorkTimeEnd && !isError.end) {
    isError.end = true
    isError.matrix_time_end = errorMsg.timeNoWork
  }
  if (isValidEnd && !isError.end) {
    isError.end = true
    isError.matrix_time_end = errorMsg.errorBefore
  }

  if (isValidStart && !isError.start) {
    isError.start = true
    isError.matrix_time_start = errorMsg.errorAfter
  }
  return isError[key]
}
const makeDisabledTime = (times, appointment) => {
  const {
    current_end_time,
    current_start_time,
    company_start_time,
    company_end_time,
  } = appointment
  const acc = { matrixTimeStart: [], matrixTimeEnd: [] }
  const newTimeSlots = times?.data?.slots?.reduce((time, el) => {
    if (el <= company_start_time || el >= company_end_time) {
      // time.matrixTimeStart.push(el)
      // time.matrixTimeEnd.push(el + 1)
      return time
    }
    if (el >= current_start_time && el <= current_end_time) {
      return time
    }
    time.matrixTimeStart.push(el)
    time.matrixTimeEnd.push(el + 1)
    return time
  }, acc)
  return newTimeSlots
}
const validate = (value: string, callback: (value: any) => any) => {
  const result = callback(value)
  return result || ''
}
const rules = (appt: IAppointment, disabledTime, errorMsg) => ({
  // appointed_technician_id: value =>
  //   validate(value, value => {
  //     return appt.assign_to_matrix && !value ? errorMsg.tech : ''
  //   }),
  /* date: value =>
    validate(value, value => {
      return !appt.is_recurring && !appt.column_template_id && !value
        ? null
        : value && !moment(value).isValid()
        ? errorMsg.dateInvalid
        : !value && Array.isArray(appt.column_template_id)
        ? !!appt.column_template_id.filter(val => val !== 'queue').length
        : !!appt.column_template_id
        ? errorMsg.date
        : null
    }), */
  column_template_id: value =>
    validate(value, value => {
      return !value.length ? errorMsg.column_template_id : null
    }),
  time_start: value =>
    validate(value, value => {
      return appt.assign_to_matrix && !value ? errorMsg.time_start : null
    }),
  time_end: value =>
    validate(value, value => {
      return appt.assign_to_matrix && !value ? errorMsg.time_end : null
    }),
  ending_options: value =>
    validate(value, value => {
      if (value?.end_type === 1 && appt.is_recurring)
        return !value?.value ? true : null
      if (value?.end_type === 2 && appt.is_recurring)
        return !Number(value?.value) ? true : null
      return false
    }),
  start_date: value =>
    validate(value, value => {
      return appt.is_recurring && !value ? errorMsg.date : null
    }),
  matrix_time_start: value =>
    validate(value, value => {
      return appt.assign_to_matrix && !value
        ? errorMsg.matrixTimeRequire
        : validateMatrixTime(appt, 'matrix_time_start', disabledTime)
    }),
  matrix_time_end: value =>
    validate(value, value => {
      return appt.assign_to_matrix && !value
        ? errorMsg.matrixTimeRequire
        : validateMatrixTime(appt, 'matrix_time_end', disabledTime)
    }),
})
const validateAll = (appointment, error, disabledTime) => {
  const newErrors = { ...error }
  let validated = true
  if (!error) return
  Object.keys(error).forEach(field => {
    if (rules()[field]) {
      const result = rules(appointment, disabledTime, errorMsg)[field](
        appointment[field],
      )
      newErrors[field] = result
      if (result) {
        validated = false
      }
    }
  })

  return { newErrors, validated }
}
const onCreateYearsPeriods = n => {
  return {
    start: new Date().setFullYear(new Date().getFullYear() - n),
    end: new Date().setFullYear(new Date().getFullYear() + n),
  }
}

const makePromisedTimeStart = (isHide, appointment) => {
  if (isHide && appointment.matrix_time_start) {
    return moment(appointment.matrix_time_start)
      .set('minute', 0)
      .format('HH:mm')
  }

  if (isHide && !appointment.matrix_time_start) {
    return null
  }

  return appointment.time_start
    ? moment(appointment.time_start).format('HH:mm')
    : null
}

const makePromisedTimeEnd = (isHide, appointment) => {
  if (isHide && appointment.matrix_time_end) {
    return moment(appointment.matrix_time_end)
      .set('minute', 0)
      .format('HH:mm')
  }

  if (isHide && !appointment.matrix_time_end) {
    return null
  }

  return appointment.time_end
    ? moment(appointment.time_end).format('HH:mm')
    : null
}

export {
  errorMsg,
  isSameTime,
  isFirstTimeAfterSecond,
  isSecondTimeBeforeFirst,
  isWorkingTime,
  validateMatrixTime,
  isSameDate,
  makeDisabledTime,
  validate,
  rules,
  validateAll,
  onCreateYearsPeriods,
  makePromisedTimeStart,
  makePromisedTimeEnd,
}
