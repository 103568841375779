import { Collapse } from 'antd'
import { ContactWithMessage } from '../../types'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompany } from 'store/Orcatec/selectors/company'
import { MessagePreview } from '../MessagePreview/MessagePreview'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Fragment } from 'react/jsx-runtime'

const { Panel } = Collapse

interface Props {
  title: string
  data: ContactWithMessage[]
  logo: string | null
  onMessageChange: (
    type: 'emailMessage' | 'phoneMessage',
    contactId: number,
    message: string,
  ) => void
}

export const MessagesList = ({ title, data, logo, onMessageChange }: Props) => {
  const company = useAppSelector(selectCompany)

  if (
    !data.flatMap(contact => contact.emails || []).length &&
    !data.flatMap(contact => contact.phones || []).length
  )
    return null

  return (
    <Wrapper>
      <h6>{title}</h6>

      <Collapse accordion>
        {data.map(contact => (
          <Fragment key={contact.id}>
            {!!contact.emails?.length && (
              <Panel
                header={
                  <Heading>
                    <span>{contact.name}</span>
                    <MailOutlined style={{ fontSize: 18, color: '#1890ff' }} />
                  </Heading>
                }
                key={contact?.id + 'email'}
              >
                <MessagePreview
                  logo={
                    logo || `${process.env.REACT_APP_API_MEDIA}${company.logo}`
                  }
                  message={contact.emailMessage}
                  onChange={(message: string) =>
                    onMessageChange('emailMessage', contact.id, message)
                  }
                />
              </Panel>
            )}

            {!!contact.phones?.length && (
              <Panel
                header={
                  <Heading>
                    <span>{contact.name}</span>
                    <PhoneOutlined style={{ fontSize: 18, color: '#1890ff' }} />
                  </Heading>
                }
                key={contact?.id + 'phone'}
              >
                <MessagePreview
                  message={contact.phoneMessage}
                  onChange={(message: string) =>
                    onMessageChange('phoneMessage', contact.id, message)
                  }
                />
              </Panel>
            )}
          </Fragment>
        ))}
      </Collapse>
    </Wrapper>
  )
}

const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-weight: 500;
  }
`

const Wrapper = styled.div`
  margin-bottom: 20px;

  .ant-collapse-expand-icon {
    line-height: 1.2;
  }
`
