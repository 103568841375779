import { useMemo } from 'react'
import { isMatch } from '../helpers/isMatch'

export const useSearch = (contacts, parent, search) => {
  const filteredContacts = useMemo(() => {
    if (!search) {
      return contacts
    }

    return contacts?.filter(item => {
      const filteredNested = contacts.filter(
        i => i?.parent_id === item?.id && isMatch(i, search),
      )
      const isShowChildren = isMatch(item, search) || filteredNested?.length
      return isShowChildren
    })
  }, [contacts, search, isMatch])

  const parentVisible = useMemo(() => {
    if (!search) {
      return true
    }

    return filteredContacts?.length || isMatch(parent, search)
  }, [search, filteredContacts, parent])

  return { filteredContacts, parentVisible }
}

// export const useSearch = (contacts, parent, search) => {
//   const [filteredContacts, setFilteredContacts] = useState(contacts)
//   const [parentVisible, setParentVisible] = useState(true)

//   useEffect(() => {
//     if (!search) {
//       setFilteredContacts(contacts)
//       setParentVisible(true)
//     }

//     if (search) {
//       const filterdItems = contacts?.filter(item => {
//         const filteredNested = contacts.filter(i => {
//           if (i?.parent_id === item?.id) {
//             return isMatch(i, search)
//           }
//         })

//         const isShowChildren = isMatch(item, search) || filteredNested?.length

//         return isShowChildren
//       })

//       setParentVisible(filterdItems?.length || isMatch(parent, search))

//       setFilteredContacts(filterdItems)
//     }
//   }, [search])

//   return { filteredContacts, parentVisible }
// }
