import moment from 'moment-timezone'
import { BalanceTransaction } from 'types/Proposal'

interface Props {
  data: BalanceTransaction[]
  isCustomerView: boolean
}

export const BalanceTransactions = ({ data, isCustomerView }: Props) => {
  return (
    <div className='payment-transactions'>
      <div className='payment-transactions__row heading'>
        <p className='id'>Transaction ID</p>
        <p className='acc_holder'>Account Holder</p>
        <p className='acc_number'>Account #</p>
        <p className='check_number'>Check #</p>
        <p className='method'>Method</p>
        {!isCustomerView && <p className='processed_by'>Processed by</p>}
        {!isCustomerView && <p className='date'>Date</p>}
        <p className='status'>Status</p>
      </div>
      {data.map(transaction => (
        <div className='payment-transactions__row' key={transaction.id}>
          <p className='id'>{transaction.transaction_id ?? ''}</p>
          <p className='acc_holder'>{transaction.card_holder || '-'}</p>
          <p className='acc_number'>{`#*******${transaction.card}`}</p>
          <p className='check_number'>{transaction.check_number || '-'}</p>
          <p className='method'>{transaction?.type === 1 ? 'Card' : 'ACH'}</p>
          {!isCustomerView && (
            <p className='processed_by'>{transaction?.payer || '-'}</p>
          )}
          {!isCustomerView && (
            <p className='date'>
              {moment
                .utc(transaction.created_at)
                .local()
                .format('MM/DD/YYYY, h:mm a')}
            </p>
          )}
          <p className='status'>{transaction.transaction_status}</p>
        </div>
      ))}
    </div>
  )
}
