import axiosOrcatec from 'api/axiosInstance'
import axios from 'axios'
import {
  GeneralTableParams,
  TablePagination,
} from 'containers/MainContent/Orcatec/components/Table/types'
import {
  CustomForm,
  CustomFormEntityType,
  CustomFormTemplate,
} from 'features/Forms/types'
import moment from 'moment-timezone'
//SETTINGS
export const getTableFormTemplates = params =>
  axiosOrcatec
    .get<CustomFormTemplate[]>('setting/custom-form-templates', { params })
    .then(res => res.data)

export const getFormTemplates = (params: GeneralTableParams) =>
  axiosOrcatec
    .get<{ data: CustomFormTemplate[]; meta: TablePagination }>(
      'custom-form-templates/all',
      { params },
    )
    .then(res => res.data)

export const getFormTemplate = (templateId: number) =>
  axiosOrcatec.get(`setting/custom-form-templates/${templateId}`)

export const createFormTemplate = data =>
  axiosOrcatec.post<CustomFormTemplate>('setting/custom-form-templates', data)

export const updateFormTemplate = data =>
  axiosOrcatec.put<CustomFormTemplate>(
    `setting/custom-form-templates/${data.id}`,
    data,
  )

export const deleteFormTemplate = (templateId: number) =>
  axiosOrcatec.delete(`setting/custom-form-templates/${templateId}`)

//PROPOSAL

export const attachTemplatesToProject = (id: number, template_ids: number[]) =>
  axiosOrcatec.put<CustomFormTemplate>(
    `custom-form-templates/attach/proposal/${id}`,
    { template_ids },
  )
export const getAttachedTemplatesToProject = (
  id: number,
  params: GeneralTableParams,
) =>
  axiosOrcatec
    .get<{ data: CustomFormTemplate[]; meta: TablePagination }>(
      `custom-form-templates/list/proposal/${id}`,
      {
        params,
      },
    )
    .then(res => res.data)

export const getTemplatesList = (params?: { withTrashed: boolean }) =>
  axiosOrcatec.get<CustomFormTemplate[]>(`custom-form-templates/list`, {
    params,
  })

export const getRelatedFormToProject = (id: number) =>
  axiosOrcatec
    .get<CustomFormTemplate[]>(`custom-forms/list/proposal/${id}`)
    .then(res => res.data)

export const createRelatedFormToProject = (id, data: CustomFormTemplate) =>
  axiosOrcatec
    .post<CustomFormTemplate[]>(`custom-forms/store/proposal/${id}`, data)
    .then(res => res.data)
export const getFormById = (id: number) =>
  axiosOrcatec
    .get<CustomFormTemplate[]>(`custom-forms/${id}`)
    .then(res => res.data)

export const deleteFormById = (id: number) =>
  axiosOrcatec
    .delete<CustomFormTemplate[]>(`custom-forms/${id}`)
    .then(res => res.data)

export const updateFormById = (data: CustomForm) =>
  axiosOrcatec
    .put<CustomFormTemplate[]>(`custom-forms/${data.id}`, data)
    .then(res => res.data)

export const updateFormField = (
  formId: number,
  field: Record<string, string>,
) =>
  axiosOrcatec
    .patch<CustomFormTemplate[]>(`custom-forms/${formId}/partial-update`, field)
    .then(res => res.data)

export const getCountFormsProject = (id: number) =>
  axiosOrcatec
    .get<CustomFormTemplate[]>(`/custom-forms/count-data/proposal/${id}`)
    .then(res => res.data)

export const getFormByPreview = (id: number) =>
  axiosOrcatec
    .get<CustomFormTemplate[]>(`/custom-forms/${id}/preview`)
    .then(res => res.data)

//JOB
export const createRelatedFormToJob = (id, data: CustomFormTemplate) =>
  axiosOrcatec
    .post<CustomFormTemplate[]>(`/custom-forms/store/job/${id}`, data)
    .then(res => res.data)

export const attachRelatedFormsToEvent = (
  id: number,
  data: { template_ids: number[]; type: CustomFormEntityType },
) =>
  axiosOrcatec
    .put<CustomFormTemplate[]>(`/custom-forms/attach/event/${id}`, data)
    .then(res => res.data)

export const attachRelatedFormsToProject = (
  id: number,
  data: { template_ids: number[]; type: CustomFormEntityType },
) =>
  axiosOrcatec
    .put<CustomFormTemplate[]>(`/custom-forms/attach/proposal/${id}`, data)
    .then(res => res.data)

export const getRelatedFormToJob = (id: number) =>
  axiosOrcatec
    .get<CustomFormTemplate[]>(`custom-forms/list/event/${id}`)
    .then(res => res.data)

export const getCountFormsJob = (id: number) =>
  axiosOrcatec
    .get<CustomFormTemplate[]>(`/custom-forms/count-data/event/${id}`)
    .then(res => res.data)

//
export const downloadAsPDF = (formId: number) =>
  axiosOrcatec
    .get(`custom-forms/${formId}/pdf`, { responseType: 'blob' })
    .then(res => res.data)

export const downloadAsPDF2 = (url: number) =>
  axios
    .post(
      `print`,
      { url },
      { baseURL: 'http://api-dev.orcatec.com:8081', responseType: 'blob' },
    )
    .then(res => res.data)

//FORMS PAGE

export const getSubmittedForms = params =>
  axiosOrcatec.get('/custom-forms', { params }).then(res => res.data)

export const getRelatedProposalIdsToForm = (templateId: number) =>
  axiosOrcatec
    .get(`custom-form-templates/${templateId}/used-proposals`)
    .then(res => res.data)

export const duplicateForm = (templateId: number, proposal_id: []) =>
  axiosOrcatec
    .post(`custom-form-templates/${templateId}/proposals/sync`, { proposal_id })
    .then(res => res.data)

export const getReportByTemplateId = (templateId: number, params) =>
  axiosOrcatec
    .get(`/custom-form-templates/${templateId}/report`, { params })
    .then(res => res.data)

export const exportSummayTable = (templateId: number, params: any) =>
  axiosOrcatec
    .get(`/custom-form-templates/${templateId}/report/export`, {
      responseType: 'blob',
      params,
    })
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `${'Summary_Table_'}${moment().format('DD-MM')}.xls`,
      )
      document.body.appendChild(link)
      link.click()
    })

export const verifyForm = (formId: number, params: { is_verified: boolean }) =>
  axiosOrcatec
    .put(`/custom-forms/${formId}/verify`, params)
    .then(res => res.data)
