import React, { useEffect, useRef, useState } from 'react'
import Route from './Route'
import moment from 'moment-timezone'
import { Polyline } from 'react-leaflet'
import {
  EmployeeHomeMarker,
  ClockedMarker,
  EmployeeMarker,
  AppointmentMarker,
} from '../StreetMap/components/Markers'
import { statuses } from '../StreetMap/components/Popaps/AppointmentMarkerPopap/AppointmentMarkerPopap'
import { isValidCoordinates } from '../../../../../../helpers/map'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import RoutingMachine from '../Map/components/RoutingMachine'

const EmployeeRouting = ({
  employee,
  date,
  showRoutes,
  onOpenAppointment,
  isAppointment,
  openQueuePopup,
  setClosePopup,
  map,
}) => {
  const [components, setComponents] = useState([])
  const [waypoints, setWaypoints] = useState([])
  const [routesPlan, setRoutesPlan] = useState([])
  const hasHomeLocation =
    employee.info &&
    employee.info.address &&
    isValidCoordinates([employee.info.address.lat, employee.info.address.lng])
  const isToday = moment(date).isSame(new Date(), 'day')
  const isTodayOrFeature = moment(date).isSameOrAfter(new Date(), 'day')
  const rMachine = useRef()

  // console.log(routesPlan)
  useEffect(() => {
    if (rMachine.current) {
      rMachine.current.setWaypoints(waypoints)
    }
  }, [waypoints, rMachine, employee])

  useEffect(() => {
    const routing = employee.routes.reduce(
      (data, route, idx, arr) => {
        const isLastRoute = idx === arr.length - 1
        const hasRoutes = route.routes && route.routes.length

        const inDurationProps = { withoutDuration: false, toTime: null }
        if (route.out) {
          inDurationProps.toTime = route.out.time
        } else if ((!isToday || !isLastRoute) && hasRoutes) {
          inDurationProps.toTime = route.routes[route.routes.length - 1].time
        } else if (!isToday || !isLastRoute) {
          inDurationProps.withoutDuration = true
        }

        const outDurationProps = { withoutDuration: false, toTime: null }
        if (!isLastRoute && arr[idx + 1].in) {
          outDurationProps.toTime = arr[idx + 1].in.time
        } else if (!isToday || (!isLastRoute && !arr[idx + 1].in)) {
          outDurationProps.withoutDuration = true
        }

        if (!hasRoutes) {
          // If has previous clucked-out set polyline to this clocked-in
          if (
            data.lastOut &&
            route.in &&
            isValidCoordinates(route.in.coordinates)
          ) {
            data.components.push(
              <Polyline
                color='violet'
                dashArray='5'
                positions={[
                  [
                    Number(route.in.coordinates[0]),
                    Number(route.in.coordinates[1]),
                  ],
                  [Number(data.lastOut[0]), Number(data.lastOut[1])],
                ]}
                key={data.components.length}
              />,
            )
          }

          // Calculate clocked-in and clocked-out if we hasn't any points of the user routing
          if (
            route.in &&
            isValidCoordinates(route.in.coordinates) &&
            route.out &&
            isValidCoordinates(route.out.coordinates)
          ) {
            // Has clocked-in and clocked-out coordinates to make route and set clocked markers
            data.components.push(
              <Route
                waypoints={[route.in.coordinates, route.out.coordinates]}
                key={data.components.length}
              />,
            )
            data.components.push(
              <ClockedMarker
                type='in'
                time={route.in.time}
                lat={route.in.coordinates[0]}
                lng={route.in.coordinates[1]}
                toTime={route.out.time}
                {...inDurationProps}
                employee={employee}
                key={data.components.length}
              />,
            )
            data.components.push(
              <ClockedMarker
                type='out'
                time={route.out.time}
                lat={route.out.coordinates[0]}
                lng={route.out.coordinates[1]}
                {...outDurationProps}
                employee={employee}
                key={data.components.length}
              />,
            )

            // Set previous clocked-out coordinates for the next user routes
            data.lastOut = route.out.coordinates
            // Set user current location to null cause we has clocked-out
            data.currentLocation = null
          } else {
            // Set previous clocked-out to null cause we hasn't this time track route
            data.lastOut = null

            if (
              route.in &&
              isValidCoordinates(route.in.coordinates) &&
              !route.out
            ) {
              // If we has clocked-in coordinates - set current user location
              data.currentLocation = {
                coordinates: route.in.coordinates,
                time: route.in.time,
              }

              data.components.push(
                <ClockedMarker
                  type='in'
                  time={route.in.time}
                  lat={route.in.coordinates[0]}
                  lng={route.in.coordinates[1]}
                  {...inDurationProps}
                  employee={employee}
                  key={data.components.length}
                />,
              )
            }
          }
        } else {
          // If has points of the user routing
          const waypoints = route.routes.map(item => item.coordinates)

          // User can hasn't clocked-in cause clocked-in can be set yesterday and bla bla bla
          if (route.in) {
            // If user has clocked-in coordinates - start route from it
            // If user hasn't clocked-in coordinates - start route from first route point
            if (isValidCoordinates(route.in.coordinates)) {
              waypoints.unshift(route.in.coordinates)
              data.components.push(
                <ClockedMarker
                  type='in'
                  time={route.in.time}
                  lat={route.in.coordinates[0]}
                  lng={route.in.coordinates[1]}
                  {...inDurationProps}
                  employee={employee}
                  key={data.components.length}
                />,
              )
            } else {
              data.components.push(
                <ClockedMarker
                  type='in'
                  time={route.in.time}
                  lat={waypoints[0][0]}
                  lng={waypoints[0][1]}
                  {...inDurationProps}
                  employee={employee}
                  key={data.components.length}
                />,
              )
            }
          }

          // Set polyline from previous clocked-out coordinates to this start route
          if (data.lastOut && route.in) {
            data.components.push(
              <Polyline
                color='violet'
                dashArray='5'
                positions={[waypoints[0], data.lastOut]}
                key={data.components.length}
              />,
            )
          }

          // If user has clocked-out - set clocked marker and set current user location to null
          // If user hasn't clocked-out - user not end his route and we set current user location to last point in route
          // and set previous clocked-out to null for next time track routes(if has him)
          if (route.out) {
            if (isValidCoordinates(route.out.coordinates)) {
              waypoints.push(route.out.coordinates)
              data.components.push(
                <ClockedMarker
                  time={route.out.time}
                  type='out'
                  lat={route.out.coordinates[0]}
                  lng={route.out.coordinates[1]}
                  {...outDurationProps}
                  employee={employee}
                  key={data.components.length}
                />,
              )
              data.lastOut = route.in.coordinates
            } else {
              data.components.push(
                <ClockedMarker
                  time={route.out.time}
                  type='out'
                  lat={waypoints[waypoints.length - 1][0]}
                  lng={waypoints[waypoints.length - 1][1]}
                  {...outDurationProps}
                  employee={employee}
                  key={data.components.length}
                />,
              )
              data.lastOut = waypoints[waypoints.length - 1]
            }

            data.currentLocation = null
          } else {
            data.lastOut = null
            data.currentLocation = route.routes[route.routes.length - 1]
          }

          // Set route
          data.components.push(
            <Route waypoints={waypoints} key={data.components.length} />,
          )
        }

        return data
      },
      { components: [], lastOut: null, currentLocation: null },
    )

    // If selected date is today - we set current user location marker and calculate next route for appointments
    const nextAppointments = employee.appointments.filter(appointment => {
      const status = statuses[appointment.status]
      return (
        !status ||
        status.toLowerCase() === 'active' ||
        status.toLowerCase() === 'in progress'
      )
    })

    // if (isToday) {
    if (
      nextAppointments.length &&
      (routing.lastOut || routing.currentLocation || hasHomeLocation)
    ) {
      nextAppointments.sort(
        (a, b) => Number(a.current_start_time) - Number(b.current_start_time),
      )
      const featureRoute = nextAppointments.map(
        appointment => appointment.coordinates,
      )

      featureRoute.unshift(
        routing.currentLocation
          ? routing.currentLocation.coordinates
          : routing.lastOut
          ? routing.lastOut
          : [employee.info.address.lat, employee.info.address.lng],
      )

      routing.components.push(
        <Route
          waypoints={featureRoute}
          polyLineColor={employee.color || '#626ed4'}
          key={routing.components.length}
          // setLoading={setLoading}
        />,
      )
    }

    if (routing.currentLocation) {
      routing.components.push(
        <EmployeeMarker
          lat={routing.currentLocation.coordinates[0]}
          lng={routing.currentLocation.coordinates[1]}
          time={routing.currentLocation.time}
          employee={employee}
          profilePicture={employee.info.profile_picture || ''}
          nextAppointmentLocation={
            nextAppointments.length ? nextAppointments[0].coordinates : null
          }
          nextAppointment={nextAppointments.length ? nextAppointments[0] : null}
          remainingAppointments={nextAppointments.length}
          key={routing.components.length}
        />,
      )
    }
    /*     } else if (isTodayOrFeature && hasHomeLocation && nextAppointments.length) {
          nextAppointments.sort((a, b) => Number(a.current_start_time) - Number(b.current_start_time))
          const featureRoute = nextAppointments.map(appointment => appointment.coordinates)
    
          featureRoute.unshift([employee.info.address.lat, employee.info.address.lng])
          routing.components.push(<Route waypoints={featureRoute} polyLineColor={employee.color || '#626ed4'} key={routing.components.length} setLoading={setLoading} />)
        } */

    setComponents(routing.components)

    // if (hasHomeLocation) {
    //   setMapCenter([employee.info.address.lat, employee.info.address.lng])
    // }
  }, [employee.routes])

  useEffect(() => {
    const lastCompleted = [...employee?.appointments]
      .reverse()
      .find(app => app.status === 3 || app.status === 4)?.coordinates

    const coordinates = [
      ...employee?.appointments
        ?.filter(app => app.status !== 3 && app.status !== 4)
        ?.filter(app => app?.coordinates?.length)
        ?.map(app => app.coordinates),
    ]

    if (lastCompleted)
      return setWaypoints([
        lastCompleted,
        ...coordinates,
        employee?.coordination,
      ])

    setWaypoints([...coordinates, employee?.coordination])
  }, [employee])

  return (
    <>
      {showRoutes && map && (
        <RoutingMachine
          color={employee?.color}
          setRoutesPlan={setRoutesPlan}
          waypoints={waypoints}
          ref={rMachine}
        />
      )}

      <MarkerClusterGroup maxClusterRadius={30} key={Date.now()}>
        {employee.appointments.map((appointment, idx) => {
          if (
            isValidCoordinates(appointment.coordinates) &&
            !appointment?.timeOff
          ) {
            return (
              <AppointmentMarker
                key={idx}
                lat={appointment.coordinates[0]}
                lng={appointment.coordinates[1]}
                task={appointment}
                color={
                  employee?.pointIndex === idx
                    ? 'red'
                    : employee.color || '#626ed4'
                }
                sn={idx + 1}
                onOpenAppointment={onOpenAppointment}
                isAppointment={isAppointment}
                openQueuePopup={openQueuePopup}
                setClosePopup={setClosePopup}
              />
            )
          }
        })}
      </MarkerClusterGroup>

      {hasHomeLocation ? (
        <EmployeeHomeMarker
          lat={employee.info.address.lat}
          lng={employee.info.address.lng}
          employee={employee}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default EmployeeRouting
