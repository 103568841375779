import {
  DragIcon,
  LocationIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { ProjectProperty } from 'features/Project/types'
import states from 'helpers/states'
import { DraggableProvided } from 'react-beautiful-dnd'
import styled from 'styled-components'

interface Props {
  data: ProjectProperty
  disabled: boolean
  isPrimary: boolean
  provided: DraggableProvided
}

export const PropertyItem = ({
  data,
  disabled,
  provided,
  isPrimary,
}: Props) => {
  return (
    <Wrapper ref={provided?.innerRef} {...provided?.draggableProps}>
      <Title>
        <Icon>
          <LocationIcon width={20} height={20} />
        </Icon>
        {data.proposal_relation ? (
          <Relation>{data.proposal_relation}</Relation>
        ) : (
          <Name>
            {data.address}
            {data.unit ? `, Unit ${data.unit}` : ''}
            {data.city ? `, ${data.city}` : ''}
            {data.state ? `, ${states[data.state] || data.state}` : ''}
            {data.postcode ? `, ${data.postcode}` : ''}
          </Name>
        )}

        {isPrimary && <Primary>Primary</Primary>}
        {!disabled && (
          <span {...provided?.dragHandleProps} style={{ marginLeft: 'auto' }}>
            <DragIcon style={{ fontSize: 20, cursor: 'pointer' }} />
          </span>
        )}
      </Title>

      {data.proposal_relation && (
        <AddInfo>
          <p>
            {data.address}
            {data.unit ? `, Unit ${data.unit}` : ''}
            {data.city ? `, ${data.city}` : ''}
            {data.state ? `, ${states[data.state] || data.state}` : ''}
            {data.postcode ? `, ${data.postcode}` : ''}
          </p>
        </AddInfo>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const AddInfo = styled.div`
  color: #4d4d4d;
  margin-left: 32px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
const Primary = styled.p`
  color: #fff;
  background-color: #1ab2e2;
  padding: 0px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
`
const Relation = styled.p`
  font-weight: 500;
`

const Name = styled.p`
  color: #4d4d4d;
`
const Icon = styled.div`
  flex: 0 0 20px;
  position: relative;
  top: -2px;
`
