import * as types from '../types'

const initialState = {
  routes: {},
  employees: [],
  titles: [],
  queue: [],
  todo: [],
  employeesRoutes: [],
  updateNeeded: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.map.MAP_SET_PATH:
      return {
        ...state,
        routes: action.response,
      }
    case types.map.MAP_FETCH_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      }
    case types.map.MAP_FETCH_EMPLOYEES_TITLES:
      return {
        ...state,
        titles: action.payload,
      }
    case types.map.MAP_FETCH_QUEUE:
      return {
        ...state,
        queue: action.payload,
      }
    case types.map.MAP_FETCH_TODO:
      return {
        ...state,
        todo: action.payload,
      }
    case types.map.MAP_FETCH_EMPLOYEES_ROUTES:
      return {
        ...state,
        employeesRoutes: action.payload,
      }
    case types.map.MAP_UPDATE_NEEDED:
      return {
        ...state,
        updateNeeded: action.payload,
      }
    default:
      return state
  }
}
