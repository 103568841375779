import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProjectDiscount } from '../types'
import { AsyncThunkAPI } from 'store/types'
import { Error } from 'types/Error'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import ProjectItemsAPI from 'api/Project'
import { deleteErrorKeys } from 'features/Items/helpers'

const initialState: {
  status: 'idle' | 'loading' | 'error'
  discounts: Record<number, ProjectDiscount>
  error: Error | null
} = {
  status: 'idle',
  discounts: {},
  error: null,
}

const projectDiscounts = createSlice({
  name: 'projectDiscounts',
  initialState,
  reducers: {
    discountsSet: (state, { payload }) => {
      state.discounts = {
        ...state.discounts,
        ...transformArrayToObj(payload),
      }
    },
    discountUpdated: (state, action: PayloadAction<ProjectDiscount>) => {
      if (!action.payload.id) return

      state.discounts[action.payload.id] = action.payload
    },
    optionChecked: (state, { payload }) => {
      state.discounts[payload.id] = { ...payload, option_group_id: payload.id }

      const options = Object.values(state.discounts)
        .filter(
          option =>
            option.option_group_id === payload.option_group_id &&
            option.id !== payload.id,
        )
        .map(option => ({
          ...option,
          option_group_id: payload.id,
          checked_option: false,
          group_id: payload.group_id,
          position: payload.position,
        }))
      state.discounts = {
        ...state.discounts,
        ...transformArrayToObj(options),
      }
    },
    discountOptionGroupCreated: (state, { payload }) => {
      state.discounts[payload.option_group_id] = {
        ...state.discounts[payload.option_group_id],
        option_group_id: payload.option_group_id,
        checked_option: true,
      }
    },
    discountOptionGroupReset: (
      state,
      { payload }: PayloadAction<ProjectDiscount>,
    ) => {
      if (!payload.id) return

      state.discounts[payload.id] = {
        ...payload,
        option_group_id: null,
        checked_option: false,
      }
    },
    resetProjectDiscountsErrors: (
      state,
      action: PayloadAction<string[] | undefined>,
    ) => {
      state.error = deleteErrorKeys(state.error, action.payload)
      state.status = 'idle'
    },
  },
  extraReducers: builder => {
    builder.addCase(addProjectDiscount.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(addProjectDiscount.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.discounts[payload.id] = payload
    })
    builder.addCase(addProjectDiscount.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })

    builder.addCase(updateProjectDiscount.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateProjectDiscount.fulfilled, state => {
      state.status = 'idle'
    })
    builder.addCase(updateProjectDiscount.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'idle'
    })

    builder.addCase(deleteProjectDiscount.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteProjectDiscount.fulfilled, (state, action) => {
      state.status = 'idle'
      const item = action.meta.arg
      if (!item.id) return

      delete state.discounts[item.id]

      if (item.option_group_id) {
        if (
          Object.values(state.discounts).filter(
            option => option.option_group_id === item.option_group_id,
          ).length < 2
        ) {
          state.discounts[item.option_group_id] = {
            ...state.discounts[item.option_group_id],
            option_group_id: null,
            checked_option: false,
          }
        }
      }
    })
    builder.addCase(deleteProjectDiscount.rejected, state => {
      state.status = 'idle'
    })
  },
})

export default projectDiscounts.reducer

export const {
  discountsSet,
  discountUpdated,
  optionChecked,
  discountOptionGroupCreated,
  discountOptionGroupReset,
  resetProjectDiscountsErrors,
} = projectDiscounts.actions

export const addProjectDiscount = createAsyncThunk<
  ProjectDiscount,
  ProjectDiscount,
  AsyncThunkAPI
>(
  'projectDiscounts/addDiscount',
  async (newItem, { rejectWithValue, getState }) => {
    const items = Object.values(
      getState().orcatec.projectDiscountsSlice.discounts,
    ).filter(item => item.group_id === newItem.group_id)

    const itemsWithoutOptions = items.filter(item =>
      item.option_group_id ? item.checked_option : item,
    )

    const position = newItem.option_group_id
      ? itemsWithoutOptions.find(
          item => item.option_group_id === newItem.option_group_id,
        )?.position || 0
      : itemsWithoutOptions.length

    const order_option =
      Object.values(items).filter(
        option =>
          option.option_group_id &&
          option.option_group_id === newItem.option_group_id,
      ).length || 0

    try {
      const res = await ProjectItemsAPI.addDiscount({
        ...newItem,
        order_option,
        position,
      })

      return res
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateProjectDiscount = createAsyncThunk<
  ProjectDiscount,
  ProjectDiscount,
  AsyncThunkAPI
>(
  'projectDiscounts/updateDiscount',
  async (item, { rejectWithValue, dispatch }) => {
    try {
      if (item.option_group_id && item.checked_option) {
        dispatch(optionChecked(item))
      } else {
        dispatch(discountUpdated(item))
      }

      return await ProjectItemsAPI.updateDiscount(item)
    } catch (error) {
      return rejectWithValue(error.response?.data)
    }
  },
)

export const deleteProjectDiscount = createAsyncThunk<
  void,
  ProjectDiscount,
  AsyncThunkAPI
>('projectDiscounts/deleteDiscount', async (item, { rejectWithValue }) => {
  try {
    if (!item.id) return

    return await ProjectItemsAPI.deleteDiscount(item.id)
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Something went wrong',
    })

    return rejectWithValue(error.response.data)
  }
})
