import axiosOrcatec from './axiosInstance'

export const fetch = requestParams => {
  const defaultParams = {
    perPage: 10,
    page: 1,
    sort: '',
    order: '',
  }

  const params = { ...defaultParams, ...requestParams }

  const { perPage, page, sort, order } = params

  return axiosOrcatec.get(`/support/tickets?&perPage=${perPage}&page=${page}&sort=${sort}&order=${order}`)
}

export const uploadReplyAttachment = file => {
  const formData = new FormData()
  formData.append('file', file)

  return axiosOrcatec.post('/support/attachments', formData)
}

export const createTicket = data => axiosOrcatec.post(`/support/tickets`, data)

export const ticket = id => axiosOrcatec.get(`/support/tickets/${id}`)

export const replyMessage = (id, data) => axiosOrcatec.post(`/support/tickets/${id}/messages`, data)

export const changeTicketStatus = (id, status) => axiosOrcatec.put(`/support/tickets/${id}/status`, { status })

export const downloadAttachment = id => axiosOrcatec.get(`/support/attachments/${id}`, { responseType: 'blob' })

export const unseenTicketsCount = () => axiosOrcatec.get(`/support/tickets/unseen/count`)
