import * as types from '../../types'
export const toggleModal = (
  file_type,
  files,
  section,
  item_type,
  item_num,
  optionIdx,
  addFile,
  deleteFile,
  rowTitle = '',
) => {
  return {
    type: types.proposalModalFiles.PROPOSAL_TOGGLE_MODAL,
    payload: {
      file_type,
      files,
      section,
      item_num,
      item_type,
      optionIdx,
      addFile,
      deleteFile,
      rowTitle,
    },
  }
}
export const toggleModalProperty = (file_type, files, item_type, rowTitle = '') => {
  return {
    type: types.proposalModalFiles.PROPOSAL_PROPERTY_TOGGLE_MODAL,
    payload: {
      file_type,
      files,
      item_type,
      rowTitle,
    },
  }
}

export const deleteNewItemFile = index => {
  return {
    type: types.proposalModalFiles.PROPOSAL_DELETE_NEW_ITEM_FILE,
    index,
  }
}

export const addNewItemFile = file => {
  return {
    type: types.proposalModalFiles.PROPOSAL_ADD_NEW_ITEM_FILE,
    obj: file,
  }
}
export const accountingAddNewItemFile = file => {
  return {
    type: types.proposalModalFiles.ACCOUNTING_ADD_NEW_ITEM_FILE,
    obj: file,
  }
}

export const setUploadFiles = data => {
  return {
    type: types.proposalModalFiles.SET_UPLOAD_FILES,
    payload: data,
  }
}

export const setUploadProgress = (id, progress) => ({
  type: types.proposalModalFiles.SET_UPLOAD_PROGRESS,
  payload: {
    id,
    progress,
  },
})

export const successUploadFile = id => ({
  type: types.proposalModalFiles.SUCCESS_UPLOAD_FILE,
  payload: id,
})

export const failureUploadFile = id => ({
  type: types.proposalModalFiles.FAILURE_UPLOAD_FILE,
  payload: id,
})
export const clearFileInProgress = idx => ({
  type: types.proposalModalFiles.PROPOSAL_CLEAR_FILE_IN_PROGRESS,
  payload: idx,
})
