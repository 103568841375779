import React, { FC } from 'react'
import { Input, Checkbox, Form } from 'antd'
import styled from 'styled-components'
import InputMask from 'react-input-mask'

interface IProps {
  isMessagingUse: boolean
  handleOtherEmailsInputChange: (e: React.ChangeEvent) => void
  handleChangeOtherPhoneInput: (e: React.ChangeEvent) => void
}

export const OtherEmailCard: FC<IProps> = ({
  isMessagingUse,
  handleOtherEmailsInputChange,
  otherEmails,
  handleChangeOtherPhoneInput,
  otherPhone,
}) => {
  return (
    <Wrapper>
      <InputWrapper>
        <InputTitle>Other email address:</InputTitle>
        <InputBlock>
          <Form.Item
            validateStatus={otherEmails.isValid ? 'success' : 'error'}
            labelAlign='left'
            required
            style={{ marginBottom: '6px', width: '100%' }}
            rules={[
              {
                message: otherEmails.isValid
                  ? 'Input a valid email address'
                  : '',
              },
            ]}
          >
            <InputBlock>
              <Checkbox
                onChange={e => {
                  handleOtherEmailsInputChange({
                    type: 'checkbox',
                    target: {
                      checked: e.target.checked,
                    },
                  })
                }}
                checked={otherEmails.isChecked}
                // disabled={!otherEmails?.inputValue}
              />
              <Input
                placeholder='first@example.com, next@example.com'
                style={{ width: '100%' }}
                onChange={handleOtherEmailsInputChange}
                value={otherEmails?.inputValue}
                className={'ant-input-error'}
              />
            </InputBlock>
          </Form.Item>
        </InputBlock>
      </InputWrapper>

      {isMessagingUse && (
        <InputWrapper>
          <InputTitle>Other phone:</InputTitle>
          <InputBlock>
            <Form.Item
              validateStatus={!otherPhone.error ? 'success' : 'error'}
              labelAlign='left'
              style={{ marginBottom: '6px', width: '100%' }}
            >
              <InputBlock>
                <Checkbox
                  onChange={e =>
                    handleChangeOtherPhoneInput({
                      type: 'checkbox',
                      target: {
                        checked: e.target.checked,
                      },
                    })
                  }
                  checked={otherPhone.isChecked}
                  disabled={!isMessagingUse}
                />

                <InputMask
                  value={otherPhone.phone}
                  onChange={handleChangeOtherPhoneInput}
                  mask='(999) 999-9999'
                  maskChar='X'
                  disabled={!isMessagingUse}
                >
                  {inputProps => (
                    <Input
                      placeholder='Phone'
                      {...inputProps}
                      disabled={!isMessagingUse}
                    />
                  )}
                </InputMask>
              </InputBlock>
            </Form.Item>
          </InputBlock>
        </InputWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 186px auto;
  align-items: center;
`
const InputTitle = styled.div``
const InputBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`
