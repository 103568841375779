import React from 'react'
import { Rating } from '@material-ui/lab'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import './style.scss'

interface IFeedbackRating {
  value: number
  size: 'small' | 'medium'
  readOnly: boolean
  precision: number
  name?: string
  onChange?: () => void
}

const FeedbackRating: React.FC<IFeedbackRating> = ({
  value,
  size = 'medium',
  readOnly,
  precision = 1,
  onChange,
  name,
}) => (
  <Rating
    name={name}
    value={value}
    readOnly={readOnly}
    precision={precision}
    size={size}
    onChange={onChange}
    emptyIcon={<StarBorderIcon fontSize='inherit' htmlColor='#F4D931' />}
  />
)

export default FeedbackRating
