import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { priceToView } from 'helpers/thousandSeparator'
import { IItem } from '../BlockItem'

export interface IProps {
  item: IItem
  handleAddRow: (jobId?: number, rate?: number) => void
  title: string
  additionalSum: number
  totalTime: { hours: number; minutes: number }
  disabled: boolean
}
const CardTotal = ({
  handleAddRow,
  title,
  additionalSum,
  totalTime,
}: IProps) => {
  return (
    <div className='block-date__wrapper'>
      <MainButton title='Add rate' onClick={handleAddRow} />
      <div>
        {(!!totalTime?.hours || !!totalTime?.minutes) && (
          <p className='block-date-line__secondary'>
            {`Total Work Time:  ${totalTime?.hours +
              'h:'} ${totalTime?.minutes + 'm'}`}
          </p>
        )}

        {!!additionalSum && (
          <p className='block-date-line__secondary'>
            {' '}
            {`${title} Cost:  ${priceToView(additionalSum)}`}
          </p>
        )}
      </div>
    </div>
  )
}

export default CardTotal
