import styled from 'styled-components'

export const PrintContainer = styled.div`
  width: 100%;
  max-width: 210mm;
  height: auto;
  margin: 0 auto;
  padding: 5mm;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  @media screen and (max-width: 800px) {
    padding: 0;
  }

  @media screen {
    &::after {
      content: '';
      position: absolute;
      top: 100%;
    }
  }

  @media print {
    body {
      visibility: hidden;
    }

    visibility: visible;

    page-break-after: always;

    &::after {
      content: '';
      page-break-before: always;
    }

    @page {
      size: A4;
      margin: 1cm;
    }
  }
`

export const Wrapper = styled.div`
  padding: 10px;
  & .appointmentStatus i {
    margin-right: 0;
    font-size: 45px !important;
  }

  & .section-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const PrintContainerInner = styled.div``
interface RowProps {
  blocked: boolean
}

export const Row = styled.div<RowProps>`
  margin-bottom: 20px;
  pointer-events: ${props => (props.blocked ? 'none' : 'all')};
`
