import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 0 15px 20px;
`

export const Actions = styled.div``
export const DeleteIcon = styled.span`
  &:hover {
    color: red;
  }
`

export const Heading = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 200px 30px;
  align-items: baseline;
  gap: 5px;
  padding: 5px 20px 5px 10px;
  background-color: #efefef;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
`

export const GroupContent = styled.div`
  border-bottom: 1px solid #efefef;
  padding-bottom: 20px;
`

export const GroupTotals = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 500;

  span {
    display: inline-block;
    width: 100px;
    text-align: right;
  }
`

export const TitlePlaceholder = styled.span`
  font-style: italic;
  opacity: 0.2;
`
export const Title = styled.div`
  position: relative;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  align-items: baseline;
  max-width: 800px;
  gap: 20px;
  white-space: pre-wrap;

  textarea {
    font-size: inherit;
    font-weight: inherit;
    overflow: hidden;
  }
`

export const Total = styled.p`
  span {
    font-weight: 500;
  }
`
