import * as types from '../types'
import * as timeoff from '../../../api/TimeOff'
import { ThunkAction } from 'redux-thunk'
import { AppStateType } from 'store'
import { AnyAction } from 'redux'

export const setTimeOff = response => ({
  type: types.timeoff.GET_TIMEOFF,
  response,
})

const updateTimeoff = response => {
  return {
    type: types.timeoff.UPDATE_TIMEOFF,
    response,
  }
}

const deleteTimeoffState = id => {
  return {
    type: types.timeoff.DELETE_TIMEOFF,
    id,
  }
}

const addTimeoff = response => {
  return {
    type: types.timeoff.ADD_TIMEOFF,
    response,
  }
}
export const getTimeOff = (): ThunkAction<
  void,
  AppStateType,
  unknown,
  AnyAction
> => dispatch => {
  return timeoff.getTimeOff().then(res => {
    dispatch(setTimeOff(res))
  })
}

export const postTimeOff = data => dispatch => {
  return timeoff.postTimeOff(data).then(response => {
    dispatch(addTimeoff(response))
    return response
  })
}

export const putTimeOff = (id, data) => dispatch => {
  return timeoff.putTimeOff(id, data).then(response => {
    dispatch(updateTimeoff(response))
    return response
  })
}

export const deleteTimeoff = id => dispatch => {
  return timeoff.deleteTimeoff(id).then(() => dispatch(deleteTimeoffState(id)))
}
