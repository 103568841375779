import axiosOrcatec from './../axiosInstance'

/**
 * Fetch clients list.
 *
 * @return {*}
 */
export const fetchSuppliersList = (page = 1, limit = 10, search, sort, column) => {
  const params = {
    page,
    limit,
  }

  if (search) {
    params.search = search
  }
  if (!!sort && !!column) {
    params.sort = sort
    params.column = column
  }
  return axiosOrcatec.get('setting/suppliers', { params }).then(response => response.data)
}

export const getAllSuppliers = () => {
  return axiosOrcatec.get('/suppliers').then(response => response.data)
}

/**
 * Fetch client.
 *
 * @param id
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const fetchSupplier = id => {
  return axiosOrcatec.get(`setting/suppliers/${id}`).then(response => response.data)
}

/**
 * Create supplier.
 *
 * @param data
 * @return {*}
 */
export const createSupplier = data => {
  return axiosOrcatec.post('setting/suppliers', data).then(response => response.data)
}

/**
 * Update Supplier.
 *
 * @param id
 * @param data
 * @return {Promise<T | never>}
 */
export const updateSupplier = (id, data) => {
  return axiosOrcatec.put(`setting/suppliers/${id}`, data).then(response => response.data)
}

/**
 * Delete Supplier.
 *
 * @param id
 * @return {*}
 */
export const deleteSupplier = id => {
  return axiosOrcatec.delete(`setting/suppliers/${id}`).then(response => response.data)
}
