import { ContactSummary } from 'features/Project/Contacts'
import TabsLayout from '../TabsLayout'
import { Button, Empty } from 'antd'
import styled from 'styled-components'
import { useEffect, useRef } from 'react'
import { TreeHandler } from 'features/Project/Contacts/ContactsTree'
import { EntityType } from 'features/Project/types'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  getRelationContactToProjectWorkOrder,
  // selectProjectWOModalOpen,
  selectWOSummaryContact,
} from 'features/Project/slices/projectWorkOrderContactsSlice'
import WOContactsTree from './components/WOContactsTree'

interface Props {
  workOrderId: number
}

export const Contacts = ({ workOrderId }: Props) => {
  const ref = useRef<TreeHandler>(null)
  const dispatch = useAppDispatch()

  const { client, property } = useAppSelector(selectWOSummaryContact)

  // console.log(client, property)
  // const isModalOpen = useAppSelector(selectProjectWOModalOpen)

  useEffect(() => {
    if (workOrderId)
      dispatch(getRelationContactToProjectWorkOrder({ projectId: workOrderId }))
  }, [workOrderId])

  const onAttach = (entity: EntityType) => () => ref.current?.onAttach(entity)

  const onManage = () => ref.current?.onManage()

  // const handleModalClose = () =>
  //   dispatch(getRelationContactToProjectWorkOrder({ projectId: workOrderId }))

  return (
    <TabsLayout
      title='Contacts'
      controls={
        <Controls>
          <Button onClick={onManage}>Manage</Button>
          <Button type='primary' onClick={onAttach(EntityType.CLIENT)}>
            Attach Contact
          </Button>
          <Button type='primary' onClick={onAttach(EntityType.PROPERTY)}>
            Attach Property
          </Button>
        </Controls>
      }
    >
      <WOContactsTree ref={ref} projectWOId={workOrderId} />
      {![...client, ...property].filter(contact => !!contact.display).length ? (
        <Empty
          style={{ marginTop: '10px' }}
          description='There are not attached contacts yet'
        />
      ) : (
        <ContactSummary
          clients={client}
          properties={property}
          disabled={false}
          isWorkOrder
        />
      )}
    </TabsLayout>
  )
}

const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
