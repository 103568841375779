import React from 'react'
// import ToggleButton from 'containers/MainContent/Orcatec/components/buttons/ToggleButton/ToggleButton'

import { Collapse } from 'antd'
import styled from 'styled-components'

const { Panel } = Collapse

const MediaGroup = ({
  mediaList,
  title,
  // toggleMedia,
  // showMedia,
  quantityItems,
}) => {
  return (
    !!mediaList.length && (
      <Collapse defaultActiveKey={[]} ghost>
        <Panel
          header={
            <Heading>
              <span className='media-title'>{title}</span>
              <span className='media-qty'>+{quantityItems}</span>
            </Heading>
          }
          key='1'
        >
          {mediaList}
        </Panel>
      </Collapse>
      // <div className='property-media_from_proposals'>
      //   <div
      //     className='property-media_from_proposals-tab'
      //     onClick={toggleMedia}
      //   >
      //     <span>{title}</span>
      //     <span>{quantityItems}</span>
      //     <span onClick={e => e.stopPropagation()}>
      //       <ToggleButton show={showMedia} toggle={toggleMedia} />
      //     </span>
      //   </div>
      //   {showMedia ? mediaList : null}
      // </div>
    )
  )
}

export default MediaGroup

const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  & .media-title {
    font-weight: 500;
    font-size: 22px;
  }
  & .media-qty {
    padding: 3px 3px;
    margin-left: 5px;
    color: #4285f4;
    background: rgba(76, 83, 239, 0.08);
    font-size: 14px;
    font-variant: tabular-nums;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    height: auto;
    border-radius: 4px;
    white-space: nowrap;
    cursor: default;
  }
`
