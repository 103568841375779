import * as types from '../../types'
import * as user from '../../../../api/User'
import * as register from '../../../../api/SignUp'
import * as payment from '../../../../api/Payment'
import * as media from '../../../../api/Media'
// import * as app from '../app'
import { setCompany } from './company'
import { setSubscription } from './subscription'
import { initializeApp, setSubscriptionStatus } from '../app'
// import { fetchUserPermissions } from 'features/Settings/UsersAndGroups/permissionSlice'
import { getBilling } from 'features/Settings/Subscription/slices/subscriptionSlice'

export const setItem = item => {
  return {
    type: types.user.USER_SET_ITEM,
    item,
  }
}
export const setMe = me => {
  return {
    type: types.user.USER_SET_ME,
    me,
  }
}

export const addIndustry = (industry: { id: number; name: string }) => {
  return {
    type: types.user.USER_ADD_INDUSTRY,
    payload: industry,
  }
}

export const removeIndustry = (id: number) => {
  return {
    type: types.user.USER_REMOVE_INDUSTRY,
    payload: id,
  }
}

export const changeMatrixWeek = mode => {
  return {
    type: types.user.CHANGE_MATRIX_WEEK,
    payload: mode,
  }
}
/* export const setMeMe = me => {
  return {
    type: types.user.USER_SET_MEME,
    me,
  }
} */

export const setList = list => {
  return {
    type: types.user.USER_SET_LIST,
    list,
  }
}
export const setCreditCard = creditCard => {
  return {
    type: types.user.USER_SET_CREDIT_CARD,
    creditCard,
  }
}

export const deleteItem = item => {
  return {
    type: types.user.USER_DELETE_ITEM,
    item,
  }
}

export const createItem = () => {
  return {
    type: types.user.USER_CREATE_ITEM,
  }
}
export const updateItem = () => {
  return {
    type: types.user.USER_UPDATE_ITEM,
  }
}
export const setProposalTemplateId = templateId => {
  return {
    type: types.user.USER_SET_PROPOSAL_TEMPLATE_ID,
    templateId,
  }
}
export const setMeOnBoared = onboarded => {
  return {
    type: types.user.USER_SET_ONBOARDED,
    onboarded,
  }
}
export const setOwnerMerchantStatus = (
  boardingProcessStatusCd,
  signatureUrl,
) => {
  return {
    type: types.user.USER_SET_MERCHANT_STATUS,
    boardingProcessStatusCd,
    signatureUrl,
  }
}

export const updateUserTemplate = (id, data) => dispatch => {
  return user
    .updateUserTemplate(id, data)
    .then(() => dispatch(setProposalTemplateId(data.template_id)))
}

export const updateUnreadTaskCounter = counter => {
  return {
    type: types.user.UPDATE_UNREAD_TASK_COUNTER,
    payload: counter,
  }
}

/**
 * Fetch users list.
 *
 * @param roleCode
 * @param page
 * @param limit
 * @param search
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const fetchUsersList = (
  page = 1,
  limit = 10,
  search,
  column,
  sortType,
  inactive,
) => dispatch => {
  //const userId = user.getAuthUserId()

  return user
    .fetchUsersList(page, limit, search, column, sortType, inactive)
    .then(response => {
      dispatch(setList(response))
    })
}
export const fetchUser = id => dispatch => {
  return user.getUser(id).then(data => {
    dispatch(setItem(data))
    return data
  })
}

export const separateSetUserAndCompany = (data, dispatch) => {
  const { company, subscription_status, ...me } = data
  dispatch(setCompany(company || null))
  // dispatch(setCompany({ ...company, address: null } || null))
  dispatch(setSubscriptionStatus(subscription_status))
  if (!data.is_admin) {
    dispatch(setSubscription(company.subscription))
    dispatch(getBilling())
  }

  return dispatch(setMe(me))
  // return dispatch(setMe({ ...me, phone: '', title: '', full_name: '' }))
}

export const fetchUserByToken = () => dispatch => {
  return user
    .fetchUserByToken()
    .then(data => separateSetUserAndCompany(data, dispatch))
}

export const signUp = data => () => {
  return register.signUp(data)
}

export const createCreditCard = (id, data) => dispatch => {
  return payment
    .createCreditCard(id, data)
    .then(res => dispatch(setCreditCard(res)))
}

export const authorization = (email, password, remember) => dispatch => {
  return (
    user
      .authorization(email, password, remember)
      .then(data => {
        // eslint-disable-next-line no-unused-vars
        const { user, ...auth } = data
        const expiryDate = new Date()
        expiryDate.setMonth(expiryDate.getMonth() + 1)
        auth.expTime = Date.now() / 1000 + data.expires_in
        localStorage.setItem('auth', JSON.stringify(auth))
        return separateSetUserAndCompany(user, dispatch)
      })
      // .then(user => dispatch(fetchUserPermissions(user.me.id)))
      .then(() => dispatch(initializeApp('from login')))
  )
}

export const deleteUser = (id, confirm) => () => {
  return user.deleteUser(id, confirm)
}

export const createUser = data => () => {
  return user.createUser(
    data,
  ) /* .then(response => {
    dispatch(createItem(response))
  }) */
}

export const updateUser = (id, data, confirm) => (dispatch, getState) => {
  return user.updateUser(id, data, confirm).then(response => {
    //dispatch(createItem(response))
    const {
      user: { me },
    } = getState().orcatec
    if (id === me.id) {
      dispatch(setMe(response))
    }
  })
}

export const uploadMedia = file => () => {
  return media.upload(file)
}
export const updateMatrixWorkTime = payload => ({
  type: types.company.UPDATE_WORK_TIME,
  payload,
})
export const updateTootipMode = payload => ({
  type: types.user.UPDATE_TOOLTIP_MODE,
  payload,
})
