import * as types from '../../../types'
const initialState = { wasEdit: false, isCloseModal: false }

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case types.modals.WAS_EDIT_FIELD: {
      return { ...state, wasEdit: payload }
    }
    case types.modals.IS_CLOSE_MODAL: {
      return { ...state, isCloseModal: payload }
    }

    default:
      return initialState
  }
}
