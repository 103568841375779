import React, { useState, useEffect } from 'react'
import { MediaTab } from '../../../Properties/components/Form/Tabs/Media/Media'
import { Modal } from 'react-bootstrap'
import MediaPopap from '../../../Properties/components/Form/MediaPopap/MediaPopap'

const Media = ({
  media,
  onDeleteMedia,
  onDropMedia,
  isClear,
  showMediaPopup,
  toggleShowMediaPopup,
  setProperty,
  property,
  onDeleteSelected,
  proposals,
  proposalsMediaArray,
  onAddMediaClick,
  onChange,
}) => {
  const [isOpen, toggle] = useState(false)
  const [showMediaPopap, toggleMediaPopap] = useState(false)
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false)
  useEffect(() => {
    toggle(false)
    setIsAddButtonClicked(false)
    toggleMediaPopap(false)
  }, [isClear])
  return (
    <div className='table-style'>
      <Modal show={showMediaPopup}>
        <Modal.Body>
          <MediaPopap
            showMediaPopap={true}
            isAppointment={true}
            toggleMediaPopap={toggleShowMediaPopup}
            setFieldValues={setProperty}
          />
        </Modal.Body>
      </Modal>

      <MediaTab
        show={true}
        fieldValues={property}
        media={media && Array.isArray(media) ? media : []}
        setFieldValues={onDropMedia}
        onDeleteMedia={onDeleteMedia}
        onDeleteSelected={onDeleteSelected}
        proposals={proposals}
        proposalsMediaArray={proposalsMediaArray}
        onAddMediaClick={onAddMediaClick}
        onChange={onChange}
      />
    </div>
  )
}

export default Media
