import { createSlice } from '@reduxjs/toolkit'
import { AppStateType } from 'store'
import { User } from 'types/User'
import { authApiSlice } from './authApiSlice'

type AuthState = {
  user: User | null
  auth: string | null
}

const auth = localStorage.getItem('auth')
  ? JSON.parse(localStorage.getItem('auth'))
  : null

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    auth: auth,
  } as AuthState,
  reducers: {
    credintialsSet: (state, action) => {
      const { user, auth } = action.payload
      state.user = user
      state.auth = auth

      localStorage.setItem('auth', JSON.stringify(auth))
    },
    logOut: state => {
      state.user = null
      state.auth = null
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      authApiSlice.endpoints.login.matchFulfilled,
      (state, action) => {
        const { user, ...rest } = action.payload
        const authData = {
          ...rest,
          expTime: Date.now() / 1000 + rest.expires_in,
        }

        state.auth = authData
        state.user = user

        localStorage.setItem('auth', JSON.stringify(authData))
      },
    )
  },
})

export const { credintialsSet, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state: AppStateType) =>
  state.orcatec.authSlice.user
export const selectAuth = (state: AppStateType) => state.orcatec.authSlice.auth
