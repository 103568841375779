import React from 'react'

import { Input, Button, Result } from 'antd'
import { resetPassword, createPassword } from '../../../../../api/Auth'
import MainButton from '../../components/buttons/MainButton'
import './style.scss'
import { BackToLoginBtn } from '../../Layouts/AuthLayout/BackToLoginBtn'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import { verifyInviteLink } from 'api/User'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

class CreatePasswordForm extends React.Component {
  state = {
    //confirmDirty: false,
    password: '',
    password_error: '',
    password_show: false,
    password_confirmation: '',
    password_confirmation_show: false,
    password_confirmation_error: '',
    isFetching: false,
    expired: false,
  }

  componentDidMount() {
    if (this.props.token) {
      verifyInviteLink(this.props.token).catch(() => {
        this.setState({ expired: true })
        // openNotificationWithIcon(
        //   'error',
        //   {
        //     message: 'The link has been expired. Please contact your administrator.',
        //     description: '',
        //     key: 'invite-link-expired',
        //   },
        //   '',
        //   0,
        // )
      })
    }
  }

  validateFieldsAndScroll = () => {
    const newState = { ...this.state }
    if (!!this.state.password_error || !!this.state.password_confirmation_error)
      return false
    const errors = []
    let newReg = new RegExp(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,32}$/g,
    )
    newState.password_error = newState.password
      ? newReg.test(newState.password)
        ? ''
        : 'Password must contain at least one capital letter, one regular letter, one digit and be minimum 6 symbols length'
      : 'Please enter your new password'
    errors.push(newState.password_error)
    newState.password_confirmation_error =
      newState.password_confirmation === newState.password
        ? ''
        : 'Passwords do not match'
    errors.push(newState.password_confirmation_error)
    if (errors.some(err => !!err)) {
      this.setState(newState)
      return false
    }
    return true
  }

  handleSubmit = e => {
    e.preventDefault()
    const isValid = this.validateFieldsAndScroll()
    if (isValid) {
      const data = {
        token: this.props.token,
        // email: this.props.email,
        password: this.state.password,
        // password_confirmation: this.state.password_confirmation,
      }

      createPassword(data)
        .then(() => {
          this.props.resetForm()
          this.props.history.push({
            pathname: '/login/credentials-changed',
            state: { name: 'password', credential: data.password },
          })
        })
        .catch(error => {
          const errorTokenMessage =
            error?.response?.data?.errors?.token?.[0] ||
            error?.response?.data?.errors?.message ||
            'The given data was invalid.'
          const errorPasswordMessage =
            error?.response?.data?.errors?.password?.[0] ||
            error?.response?.data?.errors?.message ||
            'The given data was invalid.'
          this.setState(prev => ({
            ...prev,
            password_error: errorTokenMessage || errorPasswordMessage,
            password_confirmation_error:
              errorTokenMessage || errorPasswordMessage,
          }))
        })
    }
  }

  onChangeField = e => {
    let { name, value } = e.target
    if (value.length > 32) value = value.substring(0, 32)
    const newState = { ...this.state }
    switch (name) {
      case 'password':
        const newReg = new RegExp(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,32}$/g,
        )
        newState[`${name}_error`] = value
          ? newReg.test(value)
            ? ''
            : 'Password must contain at least one capital letter, one regular letter, one digit and be minimum 6 symbols length'
          : 'Please enter your new password'
        newState.password_confirmation_error =
          !!newState.password_confirmation &&
          value !== newState.password_confirmation &&
          !newState[`${name}_error`]
            ? 'Passwords do not match'
            : ''
        break
      case 'password_confirmation':
        newState[`${name}_error`] =
          value === newState.password ? '' : 'Passwords do not match'
        break
      default:
        break
    }
    newState[name] = value
    this.setState(newState)
  }

  backToLogin = e => {
    e.preventDefault()
    this.props.resetForm()
    this.props.history.push('/login')
  }

  handleClickShowPassword = key => () => {
    this.setState({ [`${key}_show`]: !this.state[`${key}_show`] })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  render() {
    return this?.state?.expired ? (
      <Result
        status='403'
        title='Error'
        subTitle={
          <p>
            The link has been expired.
            <br /> Please contact your administrator.
          </p>
        }
      />
    ) : (
      <>
        <div className='please-text'>{`Please enter your ${
          this?.props?.createPassword ? 'new' : ''
        }  password.`}</div>
        <form
          className='custom-login-form reset-password-form new-password'
          onSubmit={this.handleSubmit}
          autoComplete='off'
          noValidate
        >
          <div>
            <TextField
              id='forgot-password'
              type={this.state.password_show ? 'text' : 'password'}
              label={'Password'}
              name='password'
              value={this.state.password}
              onChange={this.onChangeField}
              variant='outlined'
              error={!!this.state.password_error}
              helperText={this.state.password_error}
              fullWidth={true}
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={this.handleClickShowPassword('password')}
                      onMouseDown={this.handleMouseDownPassword}
                      edge='end'
                    >
                      {this.state.password_show ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <TextField
              id='forgot-new-password'
              type={this.state.password_confirmation_show ? 'text' : 'password'}
              label={'Confirm Password'}
              name='password_confirmation'
              value={this.state.password_confirmation}
              onChange={this.onChangeField}
              variant='outlined'
              error={!!this.state.password_confirmation_error}
              helperText={this.state.password_confirmation_error}
              fullWidth={true}
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={this.handleClickShowPassword(
                        'password_confirmation',
                      )}
                      onMouseDown={this.handleMouseDownPassword}
                      edge='end'
                    >
                      {this.state.password_confirmation_show ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <MainButton
            title='Set Password'
            htmltype='submit'
            className='w-100 waves-effect waves-light main-button'
            disabled={this.state.expired}
          />
          <BackToLoginBtn title='Cancel' onClick={this.backToLogin} />
        </form>
      </>
    )
  }
}

export default CreatePasswordForm
