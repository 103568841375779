export enum FEEDBACK_TYPES {
  NEGATIVE = 1,
  YELP = 2,
  LINKEDIN = 3,
  FACEBOOK = 4,
  GOOGLE = 5,
  TWITTER = 6,
}

export enum REVIEWS_STATUS {
  TO_SEND = 1,
  ALREADY_SEND = 2,
  DONT_SEND = 3,
  REPLIED = 4,
}

export enum RECEPIENT_TYPE {
  RECIPIENT_TYPE_EMAIL = 1,
  RECIPIENT_TYPE_PHONE = 2,
}
export interface IFeedback {
  client: string
  count_feedbacks: number
  event_created_at: string
  event_date: string
  event_property: string
  event_status: REVIEWS_STATUS
  event_type: number
  id: number
  review_status: number
  technician_name: string
  name?: string
  feedback_name?: string
}

export interface IReview {
  event_date: string
  event_id: number
  event_property: string
  event_type: number
  feedback: string
  feedback_action: FEEDBACK_TYPES
  feedback_created_at: string
  feedback_rating: number
  recipient: string
  request_created_at: string
  request_id: number
  review_feedback_id: number
  review_id: number
  technician_name: string
  recipient_type: RECEPIENT_TYPE
}

export interface IReviewsTemplate {
  negative_rating_text: string
  positive_after_links_text: string
  positive_below_links_text: string
  positive_header_text: string
  positive_thank_you_screen_text: string
  set_rating_text: string
}
