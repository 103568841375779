import styled from 'styled-components'

export const Content = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 20px;
`

export const ItemInfo = styled.div`
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 5px;
  background-color: #fafafa;
  max-height: 200x;
`

export const ItemName = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-weight: 500;
`

export const ItemDescription = styled(ItemName)`
  font-size: 0.8rem;
  -webkit-line-clamp: 3;
  font-weight: 400;
`

export const ProgressInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    font-weight: 500;
  }
`
