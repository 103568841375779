import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProjectRebate } from '../types'
// import { ProjectGroupsAPI } from 'api/Project'
import { AsyncThunkAPI } from 'store/types'
import { Error } from 'types/Error'
import { transformArrayToObj } from 'features/Dispatch/helpers'
// import { resetGroupItems } from './projectItemsSlice'
// import { AppStateType } from 'store'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import ProjectItemsAPI from 'api/Project'
import { deleteErrorKeys } from 'features/Items/helpers'

const initialState: {
  status: 'idle' | 'loading' | 'error'
  rebates: Record<number, ProjectRebate>
  error: Error | null
} = {
  status: 'idle',
  rebates: {},
  error: null,
}

const projectRebates = createSlice({
  name: 'projectRebates',
  initialState,
  reducers: {
    rebatesSet: (state, { payload }) => {
      state.rebates = {
        ...state.rebates,
        ...transformArrayToObj(payload),
      }
    },
    rebateUpdated: (state, action: PayloadAction<ProjectRebate>) => {
      if (!action.payload.id) return

      state.rebates[action.payload.id] = action.payload
    },
    optionChecked: (state, { payload }) => {
      state.rebates[payload.id] = { ...payload, option_group_id: payload.id }

      const options = Object.values(state.rebates)
        .filter(
          option =>
            option.option_group_id === payload.option_group_id &&
            option.id !== payload.id,
        )
        .map(option => ({
          ...option,
          option_group_id: payload.id,
          checked_option: false,
          group_id: payload.group_id,
          position: payload.position,
        }))
      state.rebates = {
        ...state.rebates,
        ...transformArrayToObj(options),
      }
    },
    rebateOptionGroupCreated: (state, { payload }) => {
      state.rebates[payload.option_group_id] = {
        ...state.rebates[payload.option_group_id],
        option_group_id: payload.option_group_id,
        checked_option: true,
      }
    },
    rebateOptionGroupReset: (
      state,
      { payload }: PayloadAction<ProjectRebate>,
    ) => {
      if (!payload.id) return

      state.rebates[payload.id] = {
        ...payload,
        option_group_id: null,
        checked_option: false,
      }
    },
    resetProjectRebatesErrors: (
      state,
      action: PayloadAction<string[] | undefined>,
    ) => {
      state.error = deleteErrorKeys(state.error, action.payload)
      state.status = 'idle'
    },
  },
  extraReducers: builder => {
    builder.addCase(addProjectRebate.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(addProjectRebate.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.rebates[payload.id] = payload
    })
    builder.addCase(addProjectRebate.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })

    builder.addCase(updateProjectRebate.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateProjectRebate.fulfilled, state => {
      state.status = 'idle'
    })
    builder.addCase(updateProjectRebate.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'idle'
    })

    builder.addCase(deleteProjectRebate.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteProjectRebate.fulfilled, (state, action) => {
      state.status = 'idle'
      const item = action.meta.arg
      if (!item.id) return

      delete state.rebates[item.id]

      if (item.option_group_id) {
        if (
          Object.values(state.rebates).filter(
            option => option.option_group_id === item.option_group_id,
          ).length < 2
        ) {
          state.rebates[item.option_group_id] = {
            ...state.rebates[item.option_group_id],
            option_group_id: null,
            checked_option: false,
          }
        }
      }
    })
    builder.addCase(deleteProjectRebate.rejected, state => {
      state.status = 'idle'
    })
  },
})

export default projectRebates.reducer

export const {
  rebatesSet,
  rebateUpdated,
  optionChecked,
  rebateOptionGroupCreated,
  rebateOptionGroupReset,
  resetProjectRebatesErrors,
} = projectRebates.actions

export const addProjectRebate = createAsyncThunk<
  ProjectRebate,
  ProjectRebate,
  AsyncThunkAPI
>(
  'projectRebates/addRebate',
  async (newItem, { rejectWithValue, getState }) => {
    const items = Object.values(
      getState().orcatec.projectRebatesSlice.rebates,
    ).filter(item => item.group_id === newItem.group_id)

    const itemsWithoutOptions = items.filter(item =>
      item.option_group_id ? item.checked_option : item,
    )

    const position = newItem.option_group_id
      ? itemsWithoutOptions.find(
          item => item.option_group_id === newItem.option_group_id,
        )?.position || 0
      : itemsWithoutOptions.length

    const order_option =
      Object.values(items).filter(
        option =>
          option.option_group_id &&
          option.option_group_id === newItem.option_group_id,
      ).length || 0

    try {
      const res = await ProjectItemsAPI.addRebate({
        ...newItem,
        order_option,
        position,
      })

      return res
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateProjectRebate = createAsyncThunk<
  ProjectRebate,
  ProjectRebate,
  AsyncThunkAPI
>(
  'projectRebates/updateRebate',
  async (item, { rejectWithValue, dispatch }) => {
    try {
      if (item.option_group_id && item.checked_option) {
        dispatch(optionChecked(item))
      } else {
        dispatch(rebateUpdated(item))
      }

      return await ProjectItemsAPI.updateRebate(item)
    } catch (error) {
      return rejectWithValue(error.response?.data)
    }
  },
)

export const deleteProjectRebate = createAsyncThunk<
  void,
  ProjectRebate,
  AsyncThunkAPI
>('projectRebates/deleteRebate', async (item, { rejectWithValue }) => {
  try {
    if (!item.id) return

    return await ProjectItemsAPI.deleteRebate(item.id)
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Something went wrong',
    })

    return rejectWithValue(error.response.data)
  }
})
