import { useMemo, ChangeEvent, useRef } from 'react'
import {
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
  makeStyles,
  createStyles,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { EditIcon } from '../Icons/CommonIcons'
import styled, { css } from 'styled-components'
import moment from 'moment-timezone'

const useStylesAutocomplete = makeStyles(() =>
  createStyles({
    listbox: {
      padding: 0,
    },
    option: {
      padding: 0,
    },
  }),
)
const useStylesInput = makeStyles(() =>
  createStyles({
    root: {
      outline: 'none',
      borderRadius: '2px',
      cursor: 'pointer',
      zIndex: 1,
    },
  }),
)
const useStylesIconButton = makeStyles(() =>
  createStyles({
    edgeEnd: {
      marginRight: 0,
      paddingLeft: 0,
      position: 'absolute',
      right: '10px',
      zIndex: 0,
      pointerEvents: 'none',
    },
  }),
)

interface StyleProps {
  error: string
  required: boolean
  short: boolean
  disabled: boolean
}
const Wrapper = styled.div<StyleProps>(
  ({ error, required, short, disabled }) => `
position: relative;
width: 100%;
max-width:${short ? '115px' : '130px'};
min-width:${short ? '115px' : '130px'};
background-color: ${disabled ? '#f5f5f5' : 'white'};

& .MuiOutlinedInput-notchedOutline {
  border: ${short ? 'none !important' : ''};
}

.MuiAutocomplete-root {
 ${!disabled &&
   css`
     &:hover {
       background-color: rgba(0, 0, 0, 0.1);
     }
   `}
}
.MuiFormHelperText-root.Mui-error {
  margin-left: 0;
  line-height: 1.33;
  // position: relative;
  // top: -5px;
}
.error {
  font-size: 9px;
  margin: 4px 14px 0;
}
& input {
  width:'100%';
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  font-size: 14px;
}

& .label {
  position: absolute;
  display: block;
  top: -7px;
  left: 10px;
  background-color: ${short ? 'transparent' : 'white'} ;
  font-size: 8px;
  z-index: 2;
  padding: 0 5px;
  line-height: 14px;
  color: ${error ? '#f44336' : 'rgba(0, 0, 0, 0.54)'};
  &:after {
    content: '${required ? '*' : ''}';
    color: ${error ? '#f44336' : 'rgba(0, 0, 0, 0.54)'};
    margin-left: 2px;
  }
}
& .title{
  // position: absolute;
  // top:-15px;
  // left:0;
  // font-size:11px;
  font-size: 12px;
  color: #808080;
  line-height: 1.5;
  margin-bottom: 3px;
  white-space: nowrap;
}
/* ${!disabled &&
    css`
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    `} */

& .picker-edit-icon{
  // position: absolute;
  // right: 20px;
  // z-index:2;
}
// & .MuiInputAdornment-positionEnd{
//   position: absolute;
//   right: -10px;
//   z-index: 0;
// }

@media screen and (min-width: 1020px) {
  .label {
    font-size: 11.5px;
  }
}
`,
)

// const mock = {
//   value: '15:00',
//   label: '15:00 pm',
//   isDisable: false,
// }
interface IProps {
  onChange: (
    valueStr: string,
    valueNum: number,
    name: string,
    event: ChangeEvent<HTMLInputElement>,
  ) => void
  value: number | string
  name: string
  startWorkTime: number
  endWorkTime: number
  label: string
  disabledTime?: number[]
  error?: string
  required?: boolean
  placeholder?: string
  withMinute?: boolean
  minuteStep?: boolean
  disabled?: boolean
  title?: string
  onBlur?: () => void
  short?: boolean
  sufix?: string
  showTimeFrom: number | string
  step: number
}
interface IOption {
  value: string | number
  label: string
  isDisabled: boolean
}

export const CustomTimePicker = ({
  onChange,
  value,
  name,
  startWorkTime = 0,
  endWorkTime = 24,
  label,
  disabledTime = [],
  error,
  required,
  placeholder = '',
  withMinute = false,
  disabled = false,
  title = '',
  short = false,
  sufix = '',
  // transform,
  step,
  ...props
}: IProps) => {
  // const [defaultValue, setDefaultValue] = useState(mock)
  // const [isEditable, setIsEditable] = useState(!transform)
  const ref = useRef()

  const styleAutocomplete = useStylesAutocomplete()
  const styleInput = useStylesInput()
  const styleIconButton = useStylesIconButton()

  const minuteStep = increment => {
    const arr = []
    for (let i = 0; i < 60; i += increment) {
      if (i + increment < 60) arr.push(i + increment)
    }
    return arr
  }

  const memoWorkTime = useMemo(() => {
    const range = (start, end) => {
      const arr = []
      for (let i = start; i <= end; i++) {
        arr.push(i > 24 ? i - 24 : i)
      }
      return arr
    }
    const markupTime = el => {
      const label =
        el <= 11
          ? el === 0
            ? '12:00 am'
            : `${el < 10 ? '0' + el : el}:00 ${sufix || 'am'}`
          : el === 12
          ? `${el}:00 ${sufix || 'pm'}`
          : el === 24
          ? `12:00 am`
          : `${el - 1 - 11}:00 ${sufix || 'pm'}`

      const value =
        el <= 11
          ? el === 0
            ? '00:00'
            : `${el < 10 ? '0' + el : el}:00`
          : el === 12
          ? `${el}:00`
          : el === 24
          ? `24:00`
          : `${el}:00`
      return {
        label,
        value,
        number: el,
        isDisabled: disabledTime.includes(el),
      }
    }
    const murkupMinute = (hour, min) => {
      const label = `${
        hour <= 11
          ? hour === 0
            ? `12:${min} am`
            : `${hour < 10 ? '0' + hour : hour}:${min} am`
          : hour === 12
          ? `${hour}:${min} pm`
          : hour === 24
          ? `12:${min} am`
          : `${hour - 1 - 11}:${min} pm`
      }`
      const value = `${
        hour <= 11
          ? hour === 0
            ? `00:${min}`
            : `${hour < 10 ? '0' + hour : hour}:${min}`
          : hour === 12
          ? `${hour}:${min}`
          : hour === 24
          ? `12:${min}`
          : `${hour}:${min}`
      }`
      return {
        label,
        value,
        isDisabled: disabledTime.includes(hour),
      }
    }
    const minDefault = ['15', '30', '45']
    const min = step ? minuteStep(+step || 15) : minDefault

    const workCompanyHours = range(Number(startWorkTime), Number(endWorkTime))
    const workTimeArray = workCompanyHours.reduce((acc, el, i) => {
      acc.push(markupTime(el))
      if (withMinute) {
        min.forEach(m => {
          if (el === 24) return acc
          acc.push(murkupMinute(el, m))

          return acc
        })
      }
      if (i === workCompanyHours.length - 1 && withMinute) {
        acc.push(markupTime(el + 1))
      }
      return acc
    }, [])
    if (
      (name === 'time_start' || name === 'time_end') &&
      typeof value === 'string' &&
      !!value &&
      !workTimeArray.map(w => w.value).includes(value)
    ) {
      workTimeArray.push({
        value,
        label: moment(value, 'HH:mm').format('HH:mm a'),
        isDisabled: false,
      })
    }
    return workTimeArray
  }, [disabledTime, startWorkTime, endWorkTime, value, withMinute])

  const getOptionLabel = (option: IOption) => {
    return option.label ? option.label : ''
  }
  const getOptionDisabled = (option: IOption) => {
    return option.isDisabled
  }
  const getValue = () => {
    return (
      memoWorkTime?.find(time => time?.value.includes(value)) ||
      startWorkTime.toString()
    )
  }

  return (
    <Wrapper
      className='custom_time-picker'
      // onBlur={() => setIsEditable(false)}
      short={short}
      error={error}
      required={required}
      disabled={disabled}
    >
      {title && <span className='title'>{title}</span>}
      {label && <span className='label'>{label}</span>}

      <Autocomplete
        disabled={disabled}
        ref={ref}
        freeSolo
        value={getValue()}
        options={memoWorkTime}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
        onChange={(event: ChangeEvent<HTMLInputElement>, option: IOption) => {
          onChange(option?.value, option.number, name, event)
          // setDefaultValue(option?.value)
        }}
        classes={{
          option: styleAutocomplete.option,
          listbox: styleAutocomplete.listbox,
        }}
        onOpen={() => {
          const currentTime = moment()
            .set('hour', moment().hour())
            .set('minute', 0)
            .format('HH:mm')
            .toString()

          setTimeout(() => {
            const optionEl = document.querySelector(
              `[data-name="${
                value === 'Invalid date' || !value ? currentTime : value
              }"]`,
            )
            optionEl?.scrollIntoView({ block: 'end' })
          }, 0)
        }}
        renderOption={({ value, label, isDisabled }: IOption) => (
          <MenuItem
            data-name={value}
            disabled={isDisabled}
            key={value}
            value={value}
          >
            <div>{label}</div>
          </MenuItem>
        )}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant='outlined'
            size='small'
            name={name}
            error={!!error}
            helperText={error}
            required={required}
            disabled={disabled}
            classes={{
              root: styleInput.root,
            }}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'off',
              type: 'search',
              readOnly: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    classes={{
                      edgeEnd: styleIconButton.edgeEnd,
                    }}
                  >
                    <EditIcon size={12} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        {...props}
      />
    </Wrapper>
  )
}
