import { useEffect, useState } from 'react'
import {
  DiscountExpirationType,
  ProjectDiscount,
  ProjectDiscountType,
} from '../../../../../types'
import { Tooltip, Spin, Button } from 'antd'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  Table,
  TableContent,
  TableHeading,
  Wrapper,
} from './ProjectDiscountsTable.styles'
import {
  selectItemsGroupTotal,
  selectProjectDiscountsSlice,
  selectSectionDiscounts,
  selectSectionSubtotal,
} from 'features/Project/projectSelectors'
import ProjectDiscountTableRow from './components/ProjectDiscountTableRow'
import {
  addProjectDiscount,
  deleteProjectDiscount,
  discountOptionGroupCreated,
  discountOptionGroupReset,
  discountsSet,
  resetProjectDiscountsErrors,
  updateProjectDiscount,
} from 'features/Project/slices/projectDiscountsSlice'
import { OptionGroup } from '../../../ProjectItemsTable/components/OptionGroup/OptionGroup'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import { reorderEntities } from 'features/Project/helpers'
import ProjectItemsAPI from 'api/Project'
import { reorderItemsWithOptions } from 'features/Project/Items/helpers'

const initialDiscount: ProjectDiscount = {
  amount: 0,
  attachments: [],
  checked_option: false,
  discount_type: ProjectDiscountType.Amount,
  expiration_type: DiscountExpirationType['No expiration'],
  expiration: null,
  global_item_id: null,
  group_id: null,
  id: 0,
  name: '',
  option_group_id: null,
  order_option: 0,
  position: 0,
  section_id: 0,
}

interface Props {
  disabled: boolean
  groupId: number | null
  restrictAdd: boolean
  sectionId: number
  isNewRowtriggered: boolean
  onItemAdd: (value: boolean) => void
}

const TABLE_HEADINGS = ['Name', 'Expiration', 'Amount', 'Price', 'Actions']

export const ProjectDiscountsTable = ({
  sectionId,
  groupId,
  disabled,
  restrictAdd,
}: Props) => {
  const dispatch = useAppDispatch()
  const discounts = useAppSelector(selectSectionDiscounts(sectionId, groupId))
  const sectionSubtotal = useAppSelector(selectSectionSubtotal(sectionId))
  const groupTotal = useAppSelector(selectItemsGroupTotal(groupId))

  const { status, error } = useAppSelector(selectProjectDiscountsSlice)

  const [editingItem, setEditingItem] = useState<ProjectDiscount | null>(null)
  const [newRow, setNewRow] = useState(false)

  useEffect(() => {
    if (error) {
      dispatch(resetProjectDiscountsErrors())
    }
  }, [newRow])

  const onDiscountAdd = (optionGroupId?: number) => {
    setEditingItem({
      ...initialDiscount,
      option_group_id: optionGroupId || null,
    } as ProjectDiscount)

    if (optionGroupId) {
      dispatch(
        discountOptionGroupCreated({
          ...initialDiscount,
          option_group_id: optionGroupId,
        }),
      )
    }

    setNewRow(true)
  }

  const handleItemChange = (event: {
    target: { name: string; value: unknown }
  }) => {
    const { name, value } = event.target

    setEditingItem(prev => ({ ...prev, [name]: value, global_item_id: null }))

    if (error?.[name]) {
      dispatch(resetProjectDiscountsErrors([name]))
    }
  }

  const handleItemChoose = (item: ProjectDiscount) => {
    setEditingItem(prev => ({
      ...prev,
      ...item,
    }))
  }

  const handleItemSave = async (item: ProjectDiscount) => {
    if (!item) return

    const res = item.id
      ? await dispatch(
          updateProjectDiscount({ ...item, amount: item.amount || 0 }),
        )
      : await dispatch(
          addProjectDiscount({
            ...item,
            section_id: sectionId,
            group_id: groupId,
            checked_option: false,
            amount: item.amount || 0,
          }),
        )

    if (res.meta.requestStatus === 'rejected') return

    setNewRow(false)
    setEditingItem(null)
  }

  const handleItemDelete = async (item: ProjectDiscount) => {
    // if (!editingItem) return

    await dispatch(deleteProjectDiscount(item))

    setEditingItem(null)
  }

  const handleOptionsReorder = (
    result: DropResult,
    options: ProjectDiscount[],
  ) => {
    if (!result.destination) return

    const reorderedItems = reorderEntities(
      options,
      result.source.index,
      result.destination.index,
      'order_option',
    )

    dispatch(discountsSet(reorderedItems))

    ProjectItemsAPI.reorderDiscounts(reorderedItems)
  }

  const handleDragEnd = async (result: DropResult) => {
    const allItems = reorderItemsWithOptions(discounts, result)

    dispatch(discountsSet(allItems))

    ProjectItemsAPI.reorderDiscounts(allItems)
  }

  return (
    <Wrapper>
      {(!!discounts.length || newRow) && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <h6>{groupId ? 'Group ' : ''}Discounts</h6>
          <Spin spinning={false}>
            <Table>
              <TableHeading>
                <p></p>

                {TABLE_HEADINGS.map((item, index) => (
                  <Tooltip mouseLeaveDelay={0} title={item} key={index}>
                    <p>{item}</p>
                  </Tooltip>
                ))}
              </TableHeading>

              <Droppable droppableId={'discounts'}>
                {provided => (
                  <TableContent
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {discounts
                      ?.filter(discount =>
                        discount.option_group_id
                          ? discount.checked_option
                          : discount,
                      )
                      ?.map((item, itemIndex) =>
                        item?.checked_option &&
                        !!item.option_group_id &&
                        !disabled ? (
                          <Draggable
                            key={item.option_group_id}
                            draggableId={String(
                              item.option_group_id || itemIndex,
                            )}
                            index={itemIndex}
                            isDragDisabled={disabled}
                          >
                            {provided => (
                              <OptionGroup
                                key={item.id}
                                disabled={disabled || newRow || !!editingItem}
                                provided={provided}
                                optionGroupId={item.option_group_id}
                                options={discounts.filter(
                                  option =>
                                    option.option_group_id ===
                                    item.option_group_id,
                                )}
                                onAddOption={
                                  () =>
                                    onDiscountAdd(
                                      item?.option_group_id || undefined,
                                    )
                                  // {
                                  //   console.warn('fdsfds')
                                  // }
                                }
                                onOptionsReorder={handleOptionsReorder}
                              >
                                {discounts
                                  .filter(
                                    option =>
                                      option.option_group_id ===
                                      item.option_group_id,
                                  )
                                  ?.sort(
                                    (a, b) => a.order_option - b.order_option,
                                  )
                                  ?.map((option: ProjectDiscount) => (
                                    <ProjectDiscountTableRow
                                      error={error}
                                      subtotal={
                                        groupId ? groupTotal : sectionSubtotal
                                      }
                                      isOption
                                      data={option}
                                      disabled={disabled || !!editingItem}
                                      editingItem={editingItem}
                                      key={option.id}
                                      loading={status === 'loading'}
                                      onCancel={() => setEditingItem(null)}
                                      onChangeItem={handleItemChange}
                                      onDelete={handleItemDelete}
                                      onEdit={option => setEditingItem(option)}
                                      onItemSet={handleItemChoose}
                                      onSave={handleItemSave}
                                    />
                                  ))}

                                {newRow &&
                                  editingItem?.option_group_id ===
                                    item.option_group_id && (
                                    <ProjectDiscountTableRow
                                      error={error}
                                      subtotal={
                                        groupId ? groupTotal : sectionSubtotal
                                      }
                                      isOption
                                      data={editingItem}
                                      disabled={disabled}
                                      editingItem={editingItem}
                                      loading={status === 'loading'}
                                      onChangeItem={handleItemChange}
                                      onDelete={handleItemDelete}
                                      onEdit={option => setEditingItem(option)}
                                      onItemSet={handleItemChoose}
                                      onSave={handleItemSave}
                                      onCancel={() => {
                                        setNewRow(false)

                                        if (
                                          !editingItem.id &&
                                          discounts.filter(
                                            item =>
                                              item.option_group_id ===
                                              editingItem.option_group_id,
                                          ).length < 2
                                        ) {
                                          dispatch(
                                            discountOptionGroupReset(item),
                                          )
                                        }

                                        setEditingItem(null)
                                      }}
                                    />
                                  )}
                              </OptionGroup>
                            )}
                          </Draggable>
                        ) : (
                          <Draggable
                            key={item.id}
                            draggableId={String(item.id || itemIndex)}
                            index={itemIndex}
                            isDragDisabled={disabled}
                          >
                            {provided => (
                              <ProjectDiscountTableRow
                                error={error}
                                subtotal={
                                  groupId ? groupTotal : sectionSubtotal
                                }
                                data={item}
                                disabled={disabled || !!editingItem}
                                editingItem={editingItem}
                                key={item.id}
                                loading={status === 'loading'}
                                provided={provided}
                                onCancel={() => setEditingItem(null)}
                                onChangeItem={handleItemChange}
                                onDelete={handleItemDelete}
                                onEdit={item => setEditingItem(item)}
                                onItemSet={handleItemChoose}
                                onSave={handleItemSave}
                                onAddOption={() => onDiscountAdd(item.id)}
                              />
                            )}
                          </Draggable>
                        ),
                      )}

                    {newRow && !editingItem?.option_group_id && (
                      <ProjectDiscountTableRow
                        error={error}
                        subtotal={groupId ? groupTotal : sectionSubtotal}
                        data={editingItem as ProjectDiscount}
                        disabled={false}
                        editingItem={editingItem}
                        loading={status === 'loading'}
                        onCancel={() => {
                          setNewRow(false)

                          setEditingItem(null)
                        }}
                        onChangeItem={handleItemChange}
                        onDelete={handleItemDelete}
                        onEdit={item => {
                          setNewRow(false)
                          setEditingItem(item)
                        }}
                        onItemSet={handleItemChoose}
                        onSave={handleItemSave}
                      />
                    )}

                    {provided.placeholder}
                  </TableContent>
                )}
              </Droppable>
            </Table>
          </Spin>
        </DragDropContext>
      )}

      {!newRow && !restrictAdd && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => onDiscountAdd()} disabled={!!editingItem}>
            + Add Discount
          </Button>
        </div>
      )}
    </Wrapper>
  )
}
