import { PaymentBalance } from 'types/Proposal'
import { CardInfo } from './components/Card/CardContainer'

export type Props = {
  balance: PaymentBalance
  cardInfo?: CardInfo
  settings?: {
    disableTips: boolean
  }
  transactionFee?: number
  onCancel?: () => void
  onPaid?: () => void
  payer: string | null
}

export enum Steps {
  tips = 0,
  method = 1,
  card = 2,
}
