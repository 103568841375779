import axiosOrcatec from './axiosInstance'
import { configV2API } from './helpers'

/**
 * Fetch schedule list.
 *
 * @return {*}
 */
export const fetchSchedule = date => {
  return axiosOrcatec.get(`/schedules?date=${date}`, configV2API).then(response => response.data)
}

export const getScheduleById = id => {
  return axiosOrcatec.get(`/schedules/${id}`).then(response => response.data)
}
