import { priceToView } from 'helpers/thousandSeparator'
import styled from 'styled-components'
import { ProjectFee } from '../types'
import { Popconfirm, Spin, Tooltip } from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { TextField } from 'components/UIKit'
import NumberFormat from 'react-number-format'
import { formatStringToNumber } from 'helpers/numbers'
import { Error } from 'types/Error'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useRef } from 'react'

interface Props {
  data: ProjectFee
  disabled?: boolean
  editMode: boolean
  error: Error | null
  isLoading: boolean
  onSave: (fee: ProjectFee) => void
  onEdit?: (fee: ProjectFee) => void
  onDelete: (fee: ProjectFee) => void
  onCancel: () => void
  onChange: (e: { target: { name: keyof ProjectFee; value: unknown } }) => void
}

export const Fee = ({
  data,
  disabled,
  editMode,
  error,
  isLoading,
  onSave,
  onEdit,
  onCancel,
  onDelete,
  onChange,
}: Props) => {
  const ref = useRef(null)

  const handleRowBlur = () => {
    if (!editMode) return

    onSave(data)
  }

  useOnClickOutside(ref, handleRowBlur)

  const handleZeroFieldFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e?.target
    if (data?.[name as keyof ProjectFee] === 0)
      onChange({
        target: {
          name,
          value: '',
        },
      })
  }

  return (
    <Spin spinning={isLoading && editMode}>
      <Wrapper ref={ref}>
        {editMode ? (
          <TextField
            name='description'
            onChange={e =>
              onChange({
                target: { name: 'description', value: e.target.value },
              })
            }
            value={data.description}
            error={error?.description}
          />
        ) : (
          <Description>{data.description}</Description>
        )}
        {editMode ? (
          <NumberFormat
            name='price'
            value={data.price}
            thousandSeparator
            prefix='$'
            customInput={TextField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange({
                target: {
                  name: 'price',
                  value: formatStringToNumber(e),
                },
              })
            }
            onBlur={() =>
              onChange({
                target: {
                  name: 'price',
                  value: data.price || 0,
                },
              })
            }
            onFocus={handleZeroFieldFocus}
          />
        ) : (
          <p>{priceToView(data.price)}</p>
        )}
        <Actions>
          {editMode ? (
            <>
              <Tooltip title='Save fee' mouseLeaveDelay={0}>
                <CheckOutlined
                  style={{ color: 'green' }}
                  onClick={() => {
                    // if (disabled) return

                    onSave(data)
                  }}
                />
              </Tooltip>
              <Tooltip title='Cancel' mouseLeaveDelay={0}>
                <CloseOutlined style={{ color: 'red' }} onClick={onCancel} />
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title={disabled ? null : 'Edit fee'} mouseLeaveDelay={0}>
                <EditOutlined
                  style={{
                    cursor: disabled ? 'not-allowed' : 'pointer',
                  }}
                  onClick={() => {
                    if (disabled) return

                    onEdit?.(data)
                  }}
                />
              </Tooltip>

              <Tooltip title={disabled ? null : 'Delete'} mouseLeaveDelay={0}>
                <Popconfirm
                  placement='left'
                  getPopupContainer={trigger =>
                    trigger.parentElement || document.body
                  }
                  title='Delete fee?'
                  onConfirm={() => onDelete(data)}
                  disabled={disabled}
                >
                  <DeleteOutlined
                    style={{
                      cursor: disabled ? 'not-allowed' : 'pointer',
                    }}
                  />
                </Popconfirm>
              </Tooltip>
            </>
          )}
        </Actions>
      </Wrapper>
    </Spin>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px 55px;
  align-items: baseline;
  gap: 10px;
  margin-bottom: 5px;
  padding-bottom: 5px;

  border-bottom: 1px solid #d6d6d657;

  & > p {
    padding: 4px 0 5px 12px;
  }
`

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  font-size: 16px;
  color: initial;

  & > * {
    cursor: pointer;
  }
`

const Description = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
