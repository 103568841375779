import * as types from '../../types'
import * as subscription from '../../../../api/settings/Subscription'
import * as actions from '../index'
import { notification } from 'antd'
import { initializeApp } from '../app'
import { fetchUserByToken } from './user'

const openNotificationWithIcon = (type, data) => {
  notification[type]({
    ...data,
  })
}

export const setItem = (item, trialPeriod) => {
  return {
    type: types.subscription.SUBSCRIPTION_GET_ITEM,
    item: {
      ...item,
      trial_period: trialPeriod !== undefined ? trialPeriod : item.trial_period,
    },
  }
}

export const setSubscription = (item, trialPeriod) => {
  return {
    type: types.subscription.SUBSCRIPTION_GET_ITEM,
    item,
  }
}

export const changeSubscriptionField = (field, value) => {
  return {
    type: types.subscription.SUBSCRIPTION_CHANGE_FIELD,
    field,
    value,
  }
}

export const fetchSubscription = (company_id, trialPeriod) => (
  dispatch,
  getState,
) => {
  const company = getState().orcatec.company
  const companyId = company ? company.id : company_id
  return subscription
    .fetchSubscription(companyId)
    .then(response => {
      dispatch(setItem(response, trialPeriod))
      return response
    })
    .then(resp => {
      dispatch(actions.system.hideSpinner())
      return resp
    })
}

export const updateSubscription = (id, data, trial_period, company_id) => (
  dispatch,
  getState,
) => {
  let subscriptionData = data ? data : getState().orcatec.subscription
  const company = getState().orcatec.company
  const companyId = company ? company.id : company_id
  const subscriptionId = id
    ? id
    : subscriptionData.id
    ? subscriptionData.id
    : getState().orcatec.subscription.id
  if (!id && !data && !!trial_period) subscriptionData = { trial_period }
  return subscription
    .updateSubscription(subscriptionId, subscriptionData)
    .then(response => {
      dispatch(fetchSubscription(companyId, trial_period))
      openNotificationWithIcon('success', {
        message: 'Successfully updated',
        description: '',
        key: 'property-save-in-process',
        duration: 4,
      })
      return response
    })
    .catch(error => {
      openNotificationWithIcon('error', {
        message: error?.response?.data?.message || error?.message,
      })
      dispatch(actions.system.hideSpinner())
    })
}

export const switchStateModule = (data, trial_period, id) => (
  dispatch,
  getState,
) => {
  const message = !data.status
    ? 'Module was deactivated'
    : 'Module was activated'
  const company = getState().orcatec.company
  const companyId = company ? company.id : data.company_id
  return subscription
    .switchStateModule(data, id)
    .then(() => {
      dispatch(fetchSubscription(companyId, trial_period))
      openNotificationWithIcon('success', {
        message: message,
        description: '',
        key: 'property-save-in-process',
        duration: 4,
      })
    })
    .then(() => {
      dispatch(fetchUserByToken())
    })
}

export const switchSubscription = (data, company_id, trialPeriod) => (
  dispatch,
  getState,
) => {
  const message = data.cancel_charge
    ? 'Subscription was turn on'
    : 'Subscription was turn off'
  const company = getState().orcatec.company
  const companyId = company ? company.id : company_id
  return subscription.switchSubscription(data).then(response => {
    dispatch(fetchSubscription(companyId, trialPeriod))
    openNotificationWithIcon('success', {
      message: message,
      description: '',
      key: 'property-save-in-process',
      duration: 4,
    })
  })
}
