import * as types from './../../types/mail-client/message'

const initialState = {
  isError: false,
  process: false,
  message: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MESSAGE_FETCH_START:
      return {
        ...state,
        isError: false,
        process: true,
      }
    case types.MESSAGE_FETCH_SUCCESS:
      return {
        ...state,
        isError: false,
        process: false,
        message: action.payload,
      }
    case types.MESSAGE_FETCH_ERROR:
      return {
        ...state,
        isError: true,
        process: false,
        error: action.payload,
        message: null,
      }
    case types.MESSAGE_CLOSE:
      return initialState
    default:
      return state
  }
}
