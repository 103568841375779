import React, { memo } from 'react'
import { TextField } from '@material-ui/core'
import { Tooltip } from 'antd'
import './DropdawnTable.scss'
import moment from 'moment-timezone'
import states from '../../../../../../../helpers/states'
import { getAddressWithUnit } from '../../../../../../../helpers/getAddressWithUnit'

const limitOfItemsForTooltip = 9
const DropdawnTable = memo(({ rows, onClickRow, showInfo, noTable, searchType }) => {
  const filterRowsByPostcode = (row, idx, array) => array.findIndex(item => item.postcode === row.postcode) === idx
  const resultRows = searchType === 'postcode' ? rows.filter(filterRowsByPostcode) : rows
  const options = resultRows.map((item, rowIdx) => {
    const address = !!item.id && !!item.unit ? getAddressWithUnit(item) : item.address
    const postcode = item.postcode ? item.postcode : item.zip ? item.zip : ''
    const city = item.city
    const state = item.state ? states[item.state] : ''
    const clients = [
      ...(item.clients ? item.clients.filter(client => !client.deleted_at) : []),
      ...(item.organizations ? item.organizations.filter(org => !org.deleted_at) : []),
    ]
    return noTable ? (
      <div
        key={rowIdx}
        className='custom-address-autocomplete-dropdown-table-wrapper-no_table-row'
        onClick={onClickRow(item)}
      >
        {searchType !== 'postcode' ? (
          <>
            <span className='d-block'>
              <b>{item.full_address ? item.full_address : `${address}, ${postcode}`}</b>
            </span>
            <span>{`${city}, ${state}`}</span>
          </>
        ) : (
          <span className='d-block'>
            <b>{`${postcode}`}</b>
          </span>
        )}
      </div>
    ) : (
      <tr key={rowIdx} onClick={onClickRow(item)} className={!item.postcode ? 'no-postcode' : ''}>
        <td>{address}</td>
        <td>{city}</td>
        <td>{postcode}</td>
        <td>{state}</td>
        {showInfo &&
          ['mdi-account-circle', 'mdi-calendar-blank', 'mdi-clipboard-text'].map((icon, idx) => {
            const currentInfo =
              idx === 0
                ? clients
                : idx === 1
                ? item.appointments
                  ? item.appointments
                  : []
                : item.proposals
                ? item.proposals
                : []
            const renderIcon = (
              <div className='d-flex align-items-center'>
                <i className={'mdi ' + icon} />
                {`(${currentInfo.length})`}
              </div>
            )
            const title = currentInfo.slice(0, 9).map((currentInfoElement, currentInfoElementIdx) => {
              let value = ''
              if (idx === 0) value = currentInfoElement.name
              else if (idx === 1)
                value = (
                  <div
                    className='w-100 d-flex flex-nowrap justify-content-between'
                    key={`${rowIdx}-${icon}-${limitOfItemsForTooltip}-datetime`}
                  >
                    <span className='mr-2'>
                      {currentInfoElement.date ? moment(currentInfoElement.date).format('MM/DD/YYYY') : 'no date'}
                    </span>
                    <span>
                      {moment(currentInfoElement.time_start, 'hh:mm:ss')
                        .format('h:mma')
                        .replace(/m$/, '') +
                        '-' +
                        moment(currentInfoElement.time_end, 'hh:mm:ss')
                          .format('h:mma')
                          .replace(/m$/, '')}
                    </span>
                  </div>
                )
              else value = currentInfoElement.code
              return <div key={`${rowIdx}-${icon}-${currentInfoElementIdx}`}>{value}</div>
            })
            if (currentInfo.length > limitOfItemsForTooltip)
              title.push(
                <div key={`${rowIdx}-${icon}-${limitOfItemsForTooltip}`} className='text-right'>
                  + {currentInfo.length - limitOfItemsForTooltip} more
                </div>,
              )
            return (
              <td key={`td-${rowIdx}-${icon}-${idx}`}>
                {item.id ? currentInfo.length ? <Tooltip title={title}>{renderIcon}</Tooltip> : renderIcon : ''}
              </td>
            )
          })}
      </tr>
    )
  })
  return (
    <div
      className={
        'custom-address-autocomplete-dropdown-table-wrapper' +
        (noTable ? ' custom-address-autocomplete-dropdown-no_table-wrapper' : '')
      }
    >
      {noTable ? (
        options
      ) : (
        <table className='custom-address-autocomplete-dropdown-table-wrapper-table'>
          <thead>
            <tr>
              <th>Address</th>
              <th>City</th>
              <th>ZIP</th>
              <th>State</th>
              {showInfo && <th colSpan='3'>Info</th>}
            </tr>
          </thead>
          <tbody>{options}</tbody>
        </table>
      )}
    </div>
  )
})

export default DropdawnTable
