import React, { useState } from 'react'
import { Checkbox } from 'antd'
// import ValidateField from 'containers/MainContent/Orcatec/components/Form/ValidateField'
// import { useAppSelector } from 'store/Orcatec/hooks'
// import { selectProjectItems } from 'features/Project/projectSelectors'
import { InputFieldV2 } from 'containers/MainContent/Orcatec/components/UI/InputField'

const Items = ({
  section,
  sectionIdx,
  // itemsType,
  itemsName,
  state,
  onChangeItems,
  // onChangeSection,
  // items,
}) => {
  // const getFullItemString = item => item.full_name ?? ''
  return (
    <>
      {!!section?.items?.length && (
        <label htmlFor={itemsName + sectionIdx}>Items:</label>
      )}
      <div id={itemsName + sectionIdx}>
        {section?.items?.map((item, idx) => {
          // const itemString = getFullItemString(item)
          const itemString = item.name
          const onChangeItemsHeadler = e =>
            onChangeItems(e, sectionIdx, idx, itemsName)
          // const onChangeSectionHeandler = e => {
          //   return onChangeSection(
          //     Number(e.currentTarget.value),
          //     sectionIdx,
          //     idx,
          //     itemsType,
          //     'qty',
          //   )
          // }

          return itemString ? (
            <div
              key={idx}
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 35px 60px',
                marginBottom: '5px',
                alignItems: 'center',
              }}
            >
              <div
                style={{ whiteSpace: 'normal' }}
                className='d-flex flex-grow-1 align-items-center'
              >
                <Checkbox
                  key={idx}
                  checked={
                    !!state[`checked${itemsName}`].find(
                      item =>
                        item.section === sectionIdx && item.itemNum === idx,
                    )
                  }
                  value={item}
                  onChange={onChangeItemsHeadler}
                >
                  {itemString}
                </Checkbox>
              </div>
              <div className='d-flex align-items-center'>QTY:</div>
              <div style={{ width: '50px' }} className='ml-2'>
                <p style={{ fontWeight: 500 }}>{item.qty}</p>
                {/* <InputFieldV2 value={item.qty} disabled /> */}
                {/*  // <ValidateField
                //   name='qty'
                //   value={item.qty}
                //   onChange={onChangeSectionHeandler}
                //   vertical
                //   disabled
                // /> */}
              </div>
            </div>
          ) : null
        })}
      </div>
    </>
  )
}
const SectionItems = ({
  proposalSections,
  onChangeItems,
  onChangeSection,
  state,
}) => {
  // const [newItemRow, setNewItemRow] = useState(false)
  // const [newItem, setNewItem] = useState({
  //   full_name: '',
  //   qty: 1,
  // })
  // const [customItems, setCustomItems] = useState([])

  // const items = useAppSelector(selectProjectItems)

  // const handleAddButtonClick = () => {
  //   setNewItemRow(true)
  // }

  //   const handleChangeItem = e => {
  //     const { name, value } = e.target.value
  //
  //     setNewItem(prev => ({
  //       ...prev,
  //       [name]: value,
  //     }))
  //   }
  return (
    <>
      {proposalSections?.map((section, i) => (
        <React.Fragment key={i}>
          <label htmlFor={section.section_title + i}>
            {section.section_title}:
          </label>
          <div id={section.section_title + i}>
            {!!section?.items?.length && (
              <Items
                section={section}
                sectionIdx={i}
                // itemsType='materials'
                state={state}
                onChangeItems={onChangeItems}
                onChangeSection={onChangeSection}
                itemsName='Equipment'
                // items={items}
              />
            )}
          </div>
        </React.Fragment>
      ))}
      {/* Custom items
      {newItemRow && (
        <div key={idx} style={{ display: 'grid', gridTemplateColumns: '1fr 35px 60px', marginBottom: '5px' }}>
          <InputField name='full_name' value={newItem.full_name} onChange={handleChangeItem} />
          <div className='d-flex align-items-center'>QTY:</div>
          <div style={{ width: '50px' }} className='ml-2'>
            <ValidateField name='qty' value={newItem.qty} onChange={handleChangeItem} vertical />
          </div>
        </div>
      )}
      <MainButton title='Add custom item' onClick={handleAddButtonClick} disabled={newItemRow} /> */}
    </>
  )
}

export default SectionItems
