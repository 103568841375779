import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { useState } from 'react'
import styled from 'styled-components'
import CompanyForm from '../CompanyForm'
import UserForm from '../UserForm'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validation'
import { Button } from 'components/UIKit'
import { CompanyInfo } from '../types'
import { AuthAPI } from '../api'
import { separateSetUserAndCompany } from 'store/Orcatec/actions/settings/user'
import { useAppDispatch } from 'store/Orcatec/hooks'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

enum Step {
  CompanyInfo,
  UserInfo,
}

const defaultValues = {
  company_logo: null,
  company_name: '',
  industry: [],
  // manual_industry: '',
  company_phone: '',
  company_email: '',
  address: '',
  profile_picture: null,
  profile_picture_id: null,
  full_name: '',
  title: '',
  phone: '',
  timezone: 'America/Los_Angeles',
}

interface Props {
  onClose: () => void
}

export const WelcomeModal = ({ onClose }: Props) => {
  const dispatch = useAppDispatch()

  const [step, setStep] = useState(Step.CompanyInfo)
  const [loading, setLoading] = useState(false)

  const currentValidationSchema = validationSchema[step]

  const methods = useForm<CompanyInfo>({
    resolver: yupResolver(currentValidationSchema),
    defaultValues,
    mode: 'onChange',
  })
  const { trigger, handleSubmit } = methods

  const onSubmit = async (data: CompanyInfo) => {
    setLoading(true)
    try {
      if (data.company_logo) {
        const formData = new FormData()
        formData.append('logo', data.company_logo)
        await AuthAPI.updateCompanyLogo(formData)
      }

      const { data: userData } = await AuthAPI.updateCompanyInfo({
        ...data,
        industry: data.manual_industry || data.industry,
      })

      separateSetUserAndCompany(userData, dispatch)

      openNotificationWithIcon('success', {
        message:
          "Congratulations! Your account setup is complete. Now you're all set to start exploring the system",
      })

      onClose?.()
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }

    handleNext()
  }

  const handleNext = async () => {
    const isStepValid = await trigger()
    // console.error(isStepValid, formState)

    if (isStepValid) setStep(Step.UserInfo)
  }

  const title = (
    <Heading>
      <h4>Welcome to Orcatec</h4>
      <Steps>step {step + 1} of 2</Steps>
    </Heading>
  )

  const content = step === Step.CompanyInfo ? <CompanyForm /> : <UserForm />

  const footer = (
    <Footer>
      {step === Step.UserInfo && (
        <Button
          onClick={() => setStep(Step.CompanyInfo)}
          disabled={loading}
          style={{ marginRight: 8 }}
        >
          Back
        </Button>
      )}

      {step === Step.CompanyInfo ? (
        <Button onClick={handleNext} type='primary' disabled={loading}>
          Next
        </Button>
      ) : (
        <Button
          onClick={handleSubmit(onSubmit)}
          type='primary'
          loading={loading}
        >
          Submit
        </Button>
      )}
    </Footer>
  )

  return (
    <Wrapper>
      <StyledModal
        open
        title={title}
        footer={footer}
        width={550}
        closable={false}
      >
        <Title>
          {step === Step.CompanyInfo ? 'Company info' : 'Profile info'}
        </Title>
        <FormProvider {...methods}>
          <Form autoComplete='off'>{content}</Form>
        </FormProvider>
      </StyledModal>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 10px 24px;
  }

  .ant-modal-body {
    padding: 10px 24px;
  }
`

const Heading = styled.div``
const Form = styled.form`
  min-height: 545px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`
const Steps = styled.p`
  text-transform: uppercase;
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 400;
  margin-top: -8px;
`
const Footer = styled.div`
  display: flex;
  justify-content: end;
  padding: 0 10px;

  button {
    width: 100px;
  }
`

const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`
