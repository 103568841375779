import { ProjectStatus } from 'features/Project/types'
import styled from 'styled-components'
import { StatusBadge } from './StatusBadge'

interface Props {
  parentId: number
  statusInfo: { title: string; description: string; icon: React.ReactNode }
  list: ProjectStatus[]
  onClick: (status?: ProjectStatus) => void
}

export const StatusGroup = ({ statusInfo, list, parentId, onClick }: Props) => {
  return (
    <Wrapper>
      <div>
        <Title>{statusInfo.title}</Title>
        <Description>{statusInfo.description}</Description>
      </div>

      <div>
        <StatusList>
          {list.map(status => (
            <StatusBadge
              key={status.id}
              icon={statusInfo.icon}
              status={status}
              onClick={onClick}
            />
          ))}
        </StatusList>

        <Button
          onClick={() => onClick({ parent_status: parentId } as ProjectStatus)}
        >
          + Create Status
        </Button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 24px;
  padding: 20px 0;
`
const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`
const Description = styled.p`
  color: #4d4d4d;
  line-height: 20px;
`

const StatusList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

const Button = styled.p`
  display: inline-block;
  font-weight: 500;
  color: #4285f4;
  margin-top: 16px;
  cursor: pointer;
`
