import { useRef } from 'react'
// import styled from 'styled-components'

import { ItemsList } from './components/ItemsList'
import { EntityType } from 'features/Project/types'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectSummaryContact } from 'features/Project/slices/projectContactsSlice'

import { selectProjectSlice } from 'features/Project/slices/projectSlice'
import { ContactsTree } from 'features/Project/Contacts'
import { TreeHandler } from 'features/Project/Contacts/ContactsTree'
import { useIsFreezed } from 'features/Project/Contacts/hooks/useIsFreezed'
import { Tooltip } from 'antd'
import { Warning } from '@material-ui/icons'
// import FeatureHint from 'components/UI/FeatureHint'
// import { STATUS } from 'react-joyride'
import {
  Address,
  Button,
  Content,
  Data,
  Heading,
  Image,
  StyledIframe,
  Title,
  Wrapper,
} from './ProjectContacts.styles'

export const ProjectContacts = () => {
  const childRef = useRef<TreeHandler>(null)

  const { project, status, editContract } = useAppSelector(selectProjectSlice)
  const { client, property } = useAppSelector(selectSummaryContact)
  const isFreezed = useIsFreezed(editContract)

  const isHasContacts = client.length || property.length

  const onAttach = (entity: EntityType) => () =>
    childRef.current?.onAttach(entity)

  const onManage = () => childRef.current?.onManage()

  /* const steps = [
    {
      target: '#project-sidebar-contacts',
      title: (
        <p>
          <img
            src='/assets/images/wave_hand.png'
            style={{ width: 20, marginRight: 10 }}
          />
          We are getting better
        </p>
      ),
      content: 'Now you can attach contacts or properties on the right side',
      disableBeacon: true,
      placement: 'left' as const,
    },
  ] */

  const noContacts = !client.length && !property.length

  return (
    <Wrapper id='project-sidebar-contacts'>
      {!!property.length && (
        <Image>
          <Address>{property[0].full_address}</Address>
          <StyledIframe
            className='map'
            referrerPolicy='no-referrer-when-downgrade'
            src={`https://www.google.com/maps/embed/v1/place?key=${
              process.env.REACT_APP_GOOGLE_API_KEY
            }&q=${`${property[0]?.address}, ${property[0]?.state}, ${property[0]?.city}, ${property[0]?.postcode}`}&zoom=17&language=en&maptype=satellite`}
            allowFullScreen
          ></StyledIframe>
        </Image>
      )}

      <Data>
        <Heading>
          <Title>Project Contacts</Title>
          {status === 'loading' ? null : isHasContacts ? (
            <Button onClick={onManage}>Manage</Button>
          ) : (
            <Tooltip title='There are no contacts associated with this project. Please add contacts and properties to establish relationships and keep your network up to date.'>
              <Warning style={{ color: 'faad14', fontSize: '1.2rem' }} />
            </Tooltip>
          )}
        </Heading>

        <Content>
          {noContacts && (
            <p style={{ color: '#4D4D4D' }}>
              There are no contacts or properties yet
            </p>
          )}

          <ItemsList
            entityType={EntityType.PROPERTY}
            data={property?.filter(item => item?.display)}
            onAttach={onAttach(EntityType.PROPERTY)}
            disabled={isFreezed}
            noContacts={noContacts}
          />
          {!noContacts && <hr />}
          <ItemsList
            entityType={EntityType.CLIENT}
            data={client?.filter(item => item?.display)}
            onAttach={onAttach(EntityType.CLIENT)}
            disabled={isFreezed}
            noContacts={noContacts}
          />
        </Content>

        <ContactsTree
          ref={childRef}
          isEditContract={editContract}
          projectId={project.id}
        />
      </Data>

      {/*  <FeatureHint
        steps={steps}
        run={
          status !== 'loading' &&
          !JSON.parse(localStorage.getItem('hideContactsHint'))
        }
        callback={({ status }) => {
          if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            localStorage.setItem('hideContactsHint', 'true')
          }
        }}
        disableOverlayClose
      /> */}
    </Wrapper>
  )
}
