import { SchedulePermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'
import { NotesPermissions } from '../notes/types'
import { ModuleName } from '../../module_permissions/types'

export const createSchedulePermissions = ({ /* singular, */ plural }) => {
  return {
    schedule_can_read: {
      name: 'View Schedule',
      control_type: UniteType.SCHEDULE_VIEW,
      description:
        'You have the ability to limit visibility of schedule for this user/group for future dates ',
      with_access_scope: true,
      with_access_checkbox: true,
      permission_key: IKey.SCHEDULE_CAN_READ,
      related_key: ['all'],
      is_module: true,
      custom_access_scope: [
        {
          key: 1,
          label: 'Show all Future Events on Schedule ',
          customLabel: true,
          activeColor: '#adf7b6',
          color: 'white',
          disabled: false,
        },
        {
          key: 2,
          label: 'Restrict Access to Future Events on Schedule ',
          customLabel: true,
          color: 'white',
          activeColor: '#adf7b6',
          disabled: false,
        },
      ],
    },
    schedule_payment_module_can_change_status: {
      name: 'Progress Modules',
      control_type: UniteType.THIRD_VIEW,
      description:
        'Allows changing the status of  project modules (e.g., Supplies module, etc.)',
      with_access_scope: false,
      with_access_checkbox: false,
      permission_key: IKey.SCHEDULE_PAYMENT_MODULE_CAN_CHANGE_STATUS,
      related_key: [
        IKey.SCHEDULE_PAYMENT_MODULE_CAN_CHANGE_STATUS,
        IKey.SCHEDULE_SUPPLY_MODULE_CAN_CHANGE_STATUS,
        IKey.SCHEDULE_CUSTOM_MODULE_1_CAN_CHANGE_STATUS,
        IKey.SCHEDULE_CUSTOM_MODULE_3_CAN_CHANGE_STATUS,
      ],
      children: [
        // {
        //   name: 'Edit Payment Module',
        //   permission_key: IKey.SCHEDULE_PAYMENT_MODULE_CAN_CHANGE_STATUS,
        //   with_access_scope: true,
        //   with_access_checkbox: true,
        // },
        {
          name: 'Edit Supplies Module',
          permission_key: IKey.SCHEDULE_SUPPLY_MODULE_CAN_CHANGE_STATUS,
          with_access_scope: true,
          with_access_checkbox: true,
        },
        {
          name: 'Edit Custom Module 1',
          permission_key: IKey.SCHEDULE_CUSTOM_MODULE_1_CAN_CHANGE_STATUS,
          with_access_scope: true,
          with_access_checkbox: true,
        },
        {
          name: 'Edit Custom Module 2',
          permission_key: IKey.SCHEDULE_CUSTOM_MODULE_2_CAN_CHANGE_STATUS,
          with_access_scope: true,
          with_access_checkbox: true,
        },
        {
          name: 'Edit Custom Module 3',
          permission_key: IKey.SCHEDULE_CUSTOM_MODULE_3_CAN_CHANGE_STATUS,
          with_access_scope: true,
          with_access_checkbox: true,
        },
      ],
    },
    schedule_can_change_event_frame: {
      name: 'Change Dispatch Time on Schedule',
      with_access_scope: false,
      with_access_checkbox: true,
      control_type: UniteType.FIRST_VIEW,
      permission_key: IKey.SCHEDULE_CAN_CHANGE_EVENT_FRAME,
      description:
        'Allows to edit dispatch time for event by resizing and moving the event on schedule',
    },

    schedule_hide_event_after_completion: {
      name: 'Hide Event after Completion',
      with_access_scope: false,
      with_access_checkbox: true,
      control_type: UniteType.FIRST_VIEW,
      permission_key: IKey.SCHEDULE_HIDE_EVENT_AFTER_COMPLETION,
      description:
        'If this setting is on, events will automatically be locked in schedule once marked as "Completed"',
    },

    schedule_can_view_event_status: {
      name: 'Show Event Status on Schedule',
      control_type: UniteType.THIRD_VIEW,
      with_access_scope: false,
      with_access_checkbox: true,
      permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_STATUS,
      related_key: [IKey.SCHEDULE_CAN_CHANGE_EVENT_STATUS],
      children: [
        {
          name: 'Change Event Status from Schedule',
          with_access_scope: false,
          with_access_checkbox: true,
          control_type: UniteType.FIRST_VIEW,
          permission_key: IKey.SCHEDULE_CAN_CHANGE_EVENT_STATUS,
          parent_permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_STATUS,
          description: '',
        },
      ],
    },
    schedule_can_view_event_status: {
      name: 'Show Event Status on Schedule',
      control_type: UniteType.THIRD_VIEW,
      with_access_scope: false,
      with_access_checkbox: true,
      permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_STATUS,
      related_key: [IKey.SCHEDULE_CAN_CHANGE_EVENT_STATUS],
      children: [
        {
          name: 'Change Event Status from Schedule',
          with_access_scope: false,
          with_access_checkbox: true,
          control_type: UniteType.FIRST_VIEW,
          permission_key: IKey.SCHEDULE_CAN_CHANGE_EVENT_STATUS,
          parent_permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_STATUS,
          description: '',
        },
      ],
    },

    schedule_can_read_client_address: {
      name: 'Show Primary Client Address on Schedule',
      with_access_scope: false,
      with_access_checkbox: true,
      control_type: UniteType.FIRST_VIEW,
      permission_key: IKey.SCHEDULE_CAN_READ_CLIENT_ADDRESS,
      description: '',
    },

    notes_can_read: {
      name: 'View Notes',
      with_access_scope: false,
      with_access_checkbox: true,
      control_type: UniteType.THIRD_VIEW,
      permission_key: NotesPermissions.NOTES_CAN_READ,
      parent_permission_key: NotesPermissions.NOTES_CAN_READ,
      description: '',
      is_module: false,
      related_key: [
        NotesPermissions.NOTES_CAN_CREATE,
        NotesPermissions.NOTES_CAN_DELETE,
        NotesPermissions.NOTES_CAN_EDIT,
      ],
      related_modules: ModuleName.NOTES,
      children: [
        {
          name: 'Create a New Note',
          with_access_scope: false,
          with_access_checkbox: true,
          related_modules: ModuleName.NOTES,
          permission_key: NotesPermissions.NOTES_CAN_CREATE,
          parent_permission_key: NotesPermissions.NOTES_CAN_READ,
          description: '',
          related_key: [],
        },
        {
          name: 'Edit Note',
          with_access_scope: true,
          with_access_checkbox: true,
          related_modules: ModuleName.NOTES,
          permission_key: NotesPermissions.NOTES_CAN_EDIT,
          parent_permission_key: NotesPermissions.NOTES_CAN_READ,
          description: '',
          related_key: [],
          custom_access_scope: [
            {
              key: 1,
              label: 'All Notes  ',
              activeColor: '#adf7b6',
              color: 'white',
              disabled: false,
            },
            {
              key: 2,
              label: 'Own Notes ',
              color: 'white',
              activeColor: '#adf7b6',
              disabled: false,
            },
          ],
        },
        {
          name: 'Delete Note',
          with_access_scope: true,
          with_access_checkbox: true,
          related_modules: ModuleName.NOTES,
          permission_key: NotesPermissions.NOTES_CAN_DELETE,
          parent_permission_key: NotesPermissions.NOTES_CAN_READ,
          description: '',
          related_key: [],
          custom_access_scope: [
            {
              key: 1,
              label: 'All Notes ',
              activeColor: '#adf7b6',
              color: 'white',
              disabled: false,
            },
            {
              key: 2,
              label: 'Own Notes ',
              color: 'white',
              activeColor: '#adf7b6',
              disabled: false,
            },
          ],
        },
      ],
    },

    schedule_can_view_event_details: {
      name: 'View Additionals Event Details on Schedule',
      with_access_scope: false,
      with_access_checkbox: true,
      control_type: UniteType.THIRD_VIEW,
      permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
      description: '',
      related_key: [
        IKey.SCHEDULE_CAN_READ_CLIENT_NAME,
        IKey.SCHEDULE_CAN_READ_CLIENT_CONTACTS,
        IKey.SCHEDULE_CAN_DUPLICATE_EVENT,
        IKey.SCHEDULE_CAN_VIEW_EVENT_SOURCE,
        IKey.SCHEDULE_CAN_REQUEST_FOR_REVIEW,
        IKey.SCHEDULE_CAN_READ_NOTIFICATION_HISTORY,
        IKey.SCHEDULE_CAN_READ_RELATED_ENTITY_MAIN_INFO,
        IKey.SCHEDULE_CAN_READ_RELATED_ENTITY_APPOINTMENTS,
        IKey.SCHEDULE_CAN_READ_RELATED_ENTITY_PROJECTS,
        IKey.SCHEDULE_CAN_READ_RELATED_ENTITY_MEMBERSHIPS,
        IKey.SCHEDULE_CAN_READ_RELATED_ENTITY,
        IKey.SCHEDULE_CAN_READ_RELATED_PROPERTY_CONTACTS,
        IKey.SCHEDULE_CAN_READ_RELATED_PROPERTY_MEDIA,
        IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_PROPERTIES,
        IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_ORGANIZATIONS,
        IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_ASSOCIATIONS,
        IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_MESSAGING,
        IKey.SCHEDULE_CAN_READ_CUSTOM_FORMS,
        IKey.SCHEDULE_CAN_CREATE_CUSTOM_FORMS,
        IKey.SCHEDULE_CAN_EDIT_CUSTOM_FORMS,
        IKey.SCHEDULE_CAN_DELETE_CUSTOM_FORMS,
        IKey.SCHEDULE_CAN_EDIT_PROJECT_CONTACTS,
        IKey.SCHEDULE_CAN_CREATE_PROJECT,
        IKey.SCHEDULE_CAN_READ_INFO_FOR_WORKERS,
        IKey.SCHEDULE_CAN_EDIT_INFO_FOR_WORKERS,
        IKey.SCHEDULE_CAN_DELETE_INFO_FOR_WORKERS,
        IKey.SCHEDULE_CAN_VIEW_PROJECT_DETAILS,
        IKey.SCHEDULE_CAN_VIEW_RELATED_JOBS,
        IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_CALLS,
      ],
      children: [
        {
          name: 'Show  Client Name on Schedule',
          with_access_scope: false,
          with_access_checkbox: true,
          control_type: UniteType.FIRST_VIEW,
          permission_key: IKey.SCHEDULE_CAN_READ_CLIENT_NAME,
          parent_permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
          description:
            'If access to client contact information is blocked, users cannot send notifications to the customer',
        },
        {
          name: 'Show Client Contacts on Schedule',
          with_access_scope: false,
          with_access_checkbox: true,
          control_type: UniteType.FIRST_VIEW,
          permission_key: IKey.SCHEDULE_CAN_READ_CLIENT_CONTACTS,
          parent_permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
          description:
            'If access to client contact information is blocked, users cannot send notifications to the customer',
        },
        {
          name: 'Edit Project Contacts on Schedule',
          with_access_scope: false,
          with_access_checkbox: true,
          control_type: UniteType.FIRST_VIEW,
          permission_key: IKey.SCHEDULE_CAN_EDIT_PROJECT_CONTACTS,
          parent_permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
          description: '',
        },

        {
          name: 'Show Event Source on Schedule',
          with_access_scope: false,
          with_access_checkbox: true,
          control_type: UniteType.FIRST_VIEW,
          permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_SOURCE,
          parent_permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
        },
        {
          name: 'Show Notification History',
          with_access_scope: false,
          with_access_checkbox: true,
          control_type: UniteType.FIRST_VIEW,
          permission_key: IKey.SCHEDULE_CAN_READ_NOTIFICATION_HISTORY,
          parent_permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
        },
        {
          name: 'Create Request for Review from Schedule',
          with_access_scope: false,
          with_access_checkbox: true,
          control_type: UniteType.FIRST_VIEW,
          permission_key: IKey.SCHEDULE_CAN_REQUEST_FOR_REVIEW,
          parent_permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
        },
        {
          name: 'Create Event from Schedule',
          with_access_scope: false,
          with_access_checkbox: true,
          control_type: UniteType.FIRST_VIEW,
          permission_key: IKey.SCHEDULE_CAN_DUPLICATE_EVENT,
          parent_permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
        },
        {
          name: 'Create Project from Schedule',
          with_access_scope: false,
          with_access_checkbox: true,
          control_type: UniteType.FIRST_VIEW,
          permission_key: IKey.SCHEDULE_CAN_CREATE_PROJECT,
          parent_permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
        },
        {
          name: 'Show Info for workers',
          control_type: UniteType.THIRD_VIEW,
          with_access_scope: false,
          with_access_checkbox: true,
          permission_key: IKey.SCHEDULE_CAN_READ_INFO_FOR_WORKERS,
          pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
          related_key: [
            IKey.SCHEDULE_CAN_EDIT_INFO_FOR_WORKERS,
            IKey.SCHEDULE_CAN_DELETE_INFO_FOR_WORKERS,
          ],
          children: [
            {
              name: 'Edit Info for workers',
              with_access_scope: false,
              with_access_checkbox: true,
              control_type: UniteType.FIRST_VIEW,
              permission_key: IKey.SCHEDULE_CAN_EDIT_INFO_FOR_WORKERS,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_INFO_FOR_WORKERS,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
            },
            {
              name: 'Delete Files for workers',
              with_access_scope: false,
              with_access_checkbox: true,
              control_type: UniteType.FIRST_VIEW,
              permission_key: IKey.SCHEDULE_CAN_DELETE_INFO_FOR_WORKERS,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_INFO_FOR_WORKERS,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
            },
          ],
        },
        {
          name: 'Show Custom Forms',
          control_type: UniteType.THIRD_VIEW,
          with_access_scope: false,
          with_access_checkbox: true,
          permission_key: IKey.SCHEDULE_CAN_READ_CUSTOM_FORMS,
          pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
          related_key: [
            IKey.SCHEDULE_CAN_CREATE_CUSTOM_FORMS,
            IKey.SCHEDULE_CAN_EDIT_CUSTOM_FORMS,
            IKey.SCHEDULE_CAN_DELETE_CUSTOM_FORMS,
          ],
          // main_key: FormsPermissions.FORMS_LIST_CAN_READ,
          children: [
            {
              name: 'Create Custom Forms',
              with_access_scope: false,
              with_access_checkbox: true,
              control_type: UniteType.FIRST_VIEW,
              permission_key: IKey.SCHEDULE_CAN_CREATE_CUSTOM_FORMS,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_CUSTOM_FORMS,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
            },
            {
              name: 'Edit Custom Forms',
              with_access_scope: false,
              with_access_checkbox: true,
              control_type: UniteType.FIRST_VIEW,
              permission_key: IKey.SCHEDULE_CAN_EDIT_CUSTOM_FORMS,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_CUSTOM_FORMS,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
            },
            {
              name: 'Delete Custom Forms',
              with_access_scope: false,
              with_access_checkbox: true,
              control_type: UniteType.FIRST_VIEW,
              permission_key: IKey.SCHEDULE_CAN_DELETE_CUSTOM_FORMS,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_CUSTOM_FORMS,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
            },
          ],
        },
        {
          name: 'Show Project Details on Schedule',
          with_access_scope: false,
          with_access_checkbox: true,
          control_type: UniteType.FIRST_VIEW,
          permission_key: IKey.SCHEDULE_CAN_VIEW_PROJECT_DETAILS,
          parent_permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
        },
        {
          name: `Show Related ${plural} on Schedule`,
          with_access_scope: false,
          with_access_checkbox: true,
          control_type: UniteType.FIRST_VIEW,
          permission_key: IKey.SCHEDULE_CAN_VIEW_RELATED_JOBS,
          parent_permission_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
        },

        /*  {
        name: 'Show Project Details on Schedule',
        control_type: UniteType.THIRD_VIEW,
        with_access_scope: false,
        with_access_checkbox: true,
        permission_key: IKey.SCHEDULE_CAN_VIEW_PROJECT_DETAILS,
        related_key: [IKey.SCHEDULE_CAN_VIEW_RELATED_JOBS],
        children: [
          {
            name: 'Show Related Jobs on Schedule',
            with_access_scope: false,
            with_access_checkbox: true,
            control_type: UniteType.FIRST_VIEW,
            permission_key: IKey.SCHEDULE_CAN_VIEW_RELATED_JOBS,
            parent_permission_key: IKey.SCHEDULE_CAN_VIEW_PROJECT_DETAILS,
            pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
          },
        ],
      }, */
        {
          name: 'Show Contact/Property Related Information on Schedule',
          with_access_scope: false,
          with_access_checkbox: true,
          control_type: UniteType.THIRD_VIEW,
          permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY,
          pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
          related_key: [
            IKey.SCHEDULE_CAN_READ_RELATED_ENTITY_MAIN_INFO,
            IKey.SCHEDULE_CAN_READ_RELATED_ENTITY_APPOINTMENTS,
            IKey.SCHEDULE_CAN_READ_RELATED_ENTITY_PROJECTS,
            IKey.SCHEDULE_CAN_READ_RELATED_ENTITY_MEMBERSHIPS,
            IKey.SCHEDULE_CAN_READ_RELATED_PROPERTY_CONTACTS,
            IKey.SCHEDULE_CAN_READ_RELATED_PROPERTY_MEDIA,
            IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_PROPERTIES,
            IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_ORGANIZATIONS,
            IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_ASSOCIATIONS,
            IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_MESSAGING,
            IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_CALLS,
          ],
          children: [
            {
              name: 'View Main Info',
              permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY_MAIN_INFO,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
              with_access_scope: false,
              with_access_checkbox: true,
            },
            {
              name: 'View Appointments',
              permission_key:
                IKey.SCHEDULE_CAN_READ_RELATED_ENTITY_APPOINTMENTS,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
              with_access_scope: false,
              with_access_checkbox: true,
            },
            {
              name: 'View Projects',
              permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY_PROJECTS,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
              with_access_scope: false,
              with_access_checkbox: true,
            },
            {
              name: 'View Memberships',
              permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY_MEMBERSHIPS,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
              with_access_scope: false,
              with_access_checkbox: true,
            },
            {
              name: 'View Property Contacts',
              permission_key: IKey.SCHEDULE_CAN_READ_RELATED_PROPERTY_CONTACTS,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
              with_access_scope: false,
              with_access_checkbox: true,
            },
            {
              name: 'View Property Media ',
              permission_key: IKey.SCHEDULE_CAN_READ_RELATED_PROPERTY_MEDIA,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
              with_access_scope: false,
              with_access_checkbox: true,
            },

            {
              name: 'View Contact Properties',
              permission_key: IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_PROPERTIES,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
              with_access_scope: false,
              with_access_checkbox: true,
            },
            {
              name: 'View Contact Organizations',
              permission_key:
                IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_ORGANIZATIONS,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
              with_access_scope: false,
              with_access_checkbox: true,
            },
            {
              name: 'View Contact Associations',
              permission_key:
                IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_ASSOCIATIONS,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
              with_access_scope: false,
              with_access_checkbox: true,
            },
            {
              name: 'View Contact Messaging ',
              permission_key: IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_MESSAGING,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
              with_access_scope: false,
              with_access_checkbox: true,
            },
            {
              name: 'View Contact Calls',
              permission_key: IKey.SCHEDULE_CAN_READ_RELATED_CONTACT_CALLS,
              parent_permission_key: IKey.SCHEDULE_CAN_READ_RELATED_ENTITY,
              pre_parent_permissions_key: IKey.SCHEDULE_CAN_VIEW_EVENT_DETAILS,
              with_access_scope: false,
              with_access_checkbox: true,
            },
          ],
        },
      ],
    },

    /*  project_can_read_custom_form: {
    name: 'Show Custom Forms',
    control_type: UniteType.THIRD_VIEW,
    description: 'Ability to use custom form module',
    with_access_scope: false,
    with_access_checkbox: true,
    permission_key: IKey.SCHEDULE_CAN_READ_CUSTOM_FORMS,
    related_key: [
      IKey.SCHEDULE_CAN_CREATE_CUSTOM_FORMS,
      IKey.SCHEDULE_CAN_EDIT_CUSTOM_FORMS,
      IKey.SCHEDULE_CAN_DELETE_CUSTOM_FORMS,
    ],
    // main_key: FormsPermissions.FORMS_LIST_CAN_READ,
    children: [
      {
        name: 'Create Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.SCHEDULE_CAN_CREATE_CUSTOM_FORMS,
        parent_permission_key: IKey.SCHEDULE_CAN_READ_CUSTOM_FORMS,
      },
      {
        name: 'Edit Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.SCHEDULE_CAN_EDIT_CUSTOM_FORMS,
        parent_permission_key: IKey.SCHEDULE_CAN_READ_CUSTOM_FORMS,
      },
      {
        name: 'Delete Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.SCHEDULE_CAN_DELETE_CUSTOM_FORMS,
        parent_permission_key: IKey.SCHEDULE_CAN_READ_CUSTOM_FORMS,
      },
    ],
  }, */
  }
}

export const schedule = createSchedulePermissions({
  singular: 'Job',
  plural: 'Jobs',
})
