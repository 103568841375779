import { useEffect, useMemo, useRef, useState } from 'react'
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  useMapEvents,
  Popup,
} from 'react-leaflet'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import sateliteIcon from './satellite.png'
import defaultIcon from './default.png'

const sateliteLayer =
  'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
const simpleLayer =
  'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=4c3b17fc-bee1-42cc-9b17-b4b51480d8c5'

const IconWrapper = styled.div`
  z-index: 500;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 76px;
  left: 11px;
  & img {
    width: 100%;
    height: auto;
    display: block;
  }
`
const ChangeView = ({ center, zoom }) => {
  const map = useMap()

  useEffect(() => {
    if (center) map.setView(center, zoom)
  }, [])
  return null
}

const DraggableMarker = ({ draggable, position, onChangeMarkerPosition }) => {
  const markerRef = useRef(null)

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          onChangeMarkerPosition(marker.getLatLng())
        }
      },
    }),
    [],
  )

  return (
    <Marker
      autoPan={true}
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
    >
      {!!draggable && <Popup>Click Edit button to move the pin</Popup>}
    </Marker>
  )
}

const Map = ({
  marker,
  isMarkerDaggable,
  onChangeMarkerPosition,
  zoom = 10,
}) => {
  const layerRef = useRef()
  const companyAddress = useSelector(state => state.orcatec.company.address)
  const [sateliteView, setSateliteView] = useState(false)
  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        onChangeMarkerPosition(e.latlng)
      },
    })

    return null
  }
  useEffect(() => {
    if (layerRef?.current) {
      layerRef.current.setUrl(sateliteView ? sateliteLayer : simpleLayer)
    }
  }, [sateliteView])

  return (
    <MapContainer attributionControl={false} scrollWheelZoom={true}>
      <ChangeView
        center={marker || [+companyAddress.lat, +companyAddress.lng]}
        zoom={zoom}
      />
      <TileLayer
        ref={layerRef}
        url={sateliteView ? sateliteLayer : simpleLayer}
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org">OpenMapTiles</a>, &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      />
      {marker && (
        <DraggableMarker
          position={marker}
          draggable={isMarkerDaggable}
          onChangeMarkerPosition={onChangeMarkerPosition}
        />
      )}

      {!marker && isMarkerDaggable && <LocationMarker />}
      <IconWrapper onClick={() => setSateliteView(prev => !prev)}>
        {sateliteView ? <img src={defaultIcon} /> : <img src={sateliteIcon} />}
      </IconWrapper>
    </MapContainer>
  )
}

export default Map
