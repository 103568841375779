import React, { useState } from 'react'
import { Card } from 'antd'
import moment from 'moment-timezone'
import '../ModalOrderEquipment.scss'
import { MailOutlined } from '@ant-design/icons'

const EmailHistory = ({ history }) => {
  const [isOpen, setIsOpen] = useState(false)
  const openHeandler = id => {
    setIsOpen(prev => {
      if (prev === id) {
        return false
      }
      return id
    })
  }
  return history?.map((item, idx) => (
    <div key={idx} className='card-wrapper'>
      <Card
        hoverable
        onClick={() => openHeandler(item.id)}
        extra={<MailOutlined />}
        bodyStyle={{ padding: '0px' }}
        title={
          <>
            <div>{`Order sent on ${moment
              .utc(item.created_at)
              .local()
              .format('MM/DD/YYYY')} at ${moment
              .utc(item.created_at)
              .local()
              .format('hh:mma')} by ${item.username}`}</div>
            <div className='d-flex'>
              <div className='mr-1'>{`Sent to:`}</div>
              <div>
                {item.emails.map((email, i) => (
                  <div key={i}>{email + (item.distributor ? ' (' + item.distributor + ')' : '')}</div>
                ))}
              </div>
            </div>
          </>
        }
        style={{ width: '100%' }}
      >
        <div className={isOpen === item.id ? 'email-content__open ' : 'email-content__close '}>
          <div dangerouslySetInnerHTML={{ __html: item.body }} />
        </div>
      </Card>
    </div>
  ))
}

export default EmailHistory
