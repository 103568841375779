import { User } from 'types/User'
import axiosOrcatec from './axiosInstance'

export type TimeOff = {
  id: number
  user_id: number
  owner_user_id: number
  start_date: string
  end_date: string
  reason: string
  note: null
  is_vacation: boolean
  user: User
}

/**
 * Fetch todo list.
 *
 * @return {*}
 */

interface ReturnData {
  data: TimeOff[]
}
export const getTimeOff = () => {
  return axiosOrcatec.get<ReturnData>('/users/timeoff').then(response => response.data)
}

export const postTimeOff = data => {
  return axiosOrcatec.post('/users/timeoff', data).then(response => response.data)
}

export const putTimeOff = (id, data) => {
  return axiosOrcatec.put(`/users/timeoff/${id}`, data).then(response => response.data)
}

export const deleteTimeoff = id => {
  return axiosOrcatec.delete(`/users/timeoff/${id}`)
}
