import React, { FC, Dispatch, SetStateAction, useMemo } from 'react'
import { AutoComplete, Input } from 'antd'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectProjectContacts } from 'features/Project/slices/projectContactsSlice'
import { EntityType } from 'features/Project/types'
import { ClientType } from 'types/Client'
import { SearchIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { renderIcon } from '../../helpers/renderIcons'

interface IProps {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
}

const renderTitle = (title: string) => (
  <span>
    {title}
    {/* <span style={{ float: 'right', cursor: 'pointer', color: '#1890ff' }}>
      + {`${type}`}
    </span> */}
  </span>
)

const renderItem = (item: string) => {
  const title = item?.name || item?.text || item?.full_address

  return {
    value: title,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
        <span>{renderIcon(item.entity_type, item.type)}</span>
      </div>
    ),
  }
}

export const Search: FC<IProps> = ({ search, setSearch }) => {
  const contacts = useAppSelector(selectProjectContacts)

  const clients = contacts
    .map(item =>
      Object.values(item).filter(
        item =>
          item.entity_type === EntityType.CLIENT &&
          item.type !== ClientType.ORGANIZATION,
      ),
    )
    .flat()
    .map(renderItem)

  const organizations = contacts
    .map(item =>
      Object.values(item).filter(
        item =>
          item.entity_type === EntityType.CLIENT &&
          item.type === ClientType.ORGANIZATION,
      ),
    )
    .flat()
    .map(renderItem)

  const properties = contacts
    .map(item =>
      Object.values(item).filter(
        item => item.entity_type === EntityType.PROPERTY,
      ),
    )
    .flat()
    .map(renderItem)

  const options = useMemo(
    () => [
      {
        label: renderTitle('Contacts', 'client'),
        options: clients,
      },
      {
        label: renderTitle('Organizations', 'organization'),
        options: organizations,
      },
      {
        label: renderTitle('Properties', 'property'),
        options: properties,
      },
    ],
    [contacts, search],
  )

  return (
    <AutoComplete
      onChange={setSearch}
      dropdownMatchSelectWidth={300}
      options={
        !!search?.length && options?.filter(item => item?.options?.length)
      }
      value={search}
      allowClear
      filterOption={(inputValue, option) =>
        option?.value?.toLowerCase().includes(inputValue.toLowerCase())
      }
      size='small'
      style={{ width: '100%', maxWidth: '332px' }}
    >
      <Input
        style={{ width: '100%', height: '36px', borderRadius: '4px' }}
        placeholder='Search'
        prefix={<SearchIcon />}
      />
    </AutoComplete>
  )
}
