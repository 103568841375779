import styled from 'styled-components'

type Props = {
  type: 'info' | 'warning' | 'error'
}

export const Wrapper = styled.div<Props>`
  border: 1px solid transparent;
  padding: 5px;
  border-radius: 4px;
  display: inline-flex;
  gap: 5px;
  background-color: ${props =>
    props.type === 'warning' ? '#f6e3d2' : '#d3f5ff'};
  font-size: 0.7rem;

  p {
    /* font-size: 0.7rem; */
    line-height: 1.33;

    span {
      font-weight: 500;
    }
  }
`
