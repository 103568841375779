export enum WOStatus {
  'In Progress' = 1,
  Scheduled,
  Completed,
  Invoiced,
  Cancelled,
}

export const WOStatusInfo = {
  1: {
    id: 1,
    display_name: 'In Progress',
    color: '#F3A533',
  },
  2: {
    id: 2,
    display_name: 'Scheduled',
    color: '#206FED',
  },
  3: {
    id: 3,
    display_name: 'Completed',
    color: '#00A643',
  },
  4: {
    id: 4,
    display_name: 'Invoiced',
    color: '#12B6AC',
  },
  5: {
    id: 5,
    display_name: 'Cancelled',
    color: '#F1453D',
  },
}
