import { getTechnicians } from 'api/User'
import { useEffect, useState } from 'react'
import { ITechnician } from 'types/Appointment'

const useTechnicians = () => {
  const [technicians, setTechnicians] = useState<ITechnician[]>([])

  useEffect(() => {
    const fetchTechs = async () => {
      const data = await getTechnicians()

      setTechnicians(data)
    }

    fetchTechs()
  }, [])

  return { technicians }
}

export default useTechnicians
