import React, { useState, useEffect } from 'react'
import Table from '../../../components/CustomTable'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { withRouter } from 'react-router-dom'
import * as actions from 'store/Orcatec/actions'
import AppointmentStatus from '../../../components/AppointmentStatus/AppointmentStatus'
import { truncateWithToolTip } from '../../../../../../helpers/truncateStrWithTooltip'
import { renderNotes } from '../../../Clients/components/Form/components/utils'
import { Tooltip } from 'antd'

const Appointment = ({
  appointments,
  disabled,
  isClear,
  appId,
  history,
  getMatrixLog,
  setCurrentAppointmentId,
  emptyText = 'No other appointments yet',
  toggleAppointmentForm,
  appointmentForm,
  isAppointment = false,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [isOpen, toggle] = useState(false)
  useEffect(() => {
    toggle(false)
  }, [isClear])

  const setPlacment = (type, item) => {
    if (type === 'misc') {
      return 'Misc'
    } else if (type === 'used') {
      return item.matrix_user
    } else {
      return 'Queue'
    }
  }
  // function truncateWithToolTip(str, n) {
  //
  //   return str && str.length > n ? <Tooltip title={str}>{str.substr(0, n - 3) + '...'} </Tooltip> : str
  // }

  const goTo = appointment => {
    isAppointment
      ? window.open(`/appointment/${appointment.id}`, '_self')
      : history.push(`/appointment/${appointment.id}`)

    /* setCurrentAppointmentId(appointment.id)
    if (appointment.matrix_date !== null) {
      getMatrixLog(moment(appointment.matrix_date).format('MM/DD/YYYY')).then(res => {
        history.push('/matrix')
      })
    } else {
      history.push('/matrix')
    } */
  }
  /* const check = () => {
    if (appId) {
      if (appointments.length > 0) {
        return appointments.length - 1
      }
    }
    return appointments.length
  } */
  return (
    <div style={{ overflow: 'hidden' }} className='table-style'>
      {/*  <button className='btn btn-primary btn-block collapse-button' disabled={disabled} onClick={() => toggle(!isOpen)}>
        <span>Other Appointments </span>
        <span>{check()}</span>
      </button> */}
      {/*<Tab*/}
      {/*disabled={disabled}*/}
      {/*toggleTab={() => toggle(!isOpen)}*/}
      {/*itemsQuantity={check()}*/}
      {/*title='Other Appointments'*/}
      {/*isOpen={isOpen}*/}
      {/*>*/}
      {/*{isOpen && (*/}
      <Table
        emptyText={emptyText}
        rows={appointments ? appointments : []}
        search={false}
        paggination={false}
        showEntries={false}
        className='appointment-table'
        toggleAppointmentForm={toggleAppointmentForm}
        modalOpened={appointmentForm}
        head={[
          {
            label: '',
          },
          {
            label: 'Date',
          },
          {
            label: 'Type',
          },
          {
            label: 'Tech',
          },
          {
            label: 'Source',
          },
          {
            label: 'Appointment Notes',
          },
        ]}
      >
        {appointments
          ?.sort((a, b) => b.id - a.id)
          ?.map((item, i) => {
            if (appId && Number(item.id) !== Number(appId)) {
              return (
                <tr key={item.id} style={{ cursor: 'pointer' }} onClick={() => goTo(item)}>
                  <td>{<AppointmentStatus statusId={item.status} withStatusName={false} />}</td>
                  <td>{item.date && moment(item.date).format('MM/DD/YYYY')}</td>
                  <td>{item.is_lead ? 'Public Appointment' : item.appointment_type?.name || '-'}</td>
                  <td>
                    <span className='ml-1'>{setPlacment(item.matrix_type, item)}</span>
                  </td>
                  <td>{item.appointment_source && item.appointment_source.name}</td>
                  <Tooltip
                    overlayStyle={{ whiteSpace: 'pre-line' }}
                    title={item?.notes?.map(n => n.text).join?.('\n\n')}
                  >
                    <td style={{ textAlign: 'left' }}>{renderNotes(item.notes)}</td>
                  </Tooltip>
                </tr>
              )
            } else if (!appId) {
              return (
                <tr key={item.id} style={{ cursor: 'pointer' }} onClick={() => goTo(item)}>
                  <td>{<AppointmentStatus statusId={item.status} withStatusName={false} />}</td>
                  <td>{item.date && moment(item.date).format('MM/DD/YYYY')}</td>
                  <td>{item.is_lead ? 'Public Appointment' : item.appointment_type?.name || '-'}</td>
                  <td>
                    <span className='ml-1'>{setPlacment(item.matrix_type, item)}</span>
                  </td>
                  <td>{item.appointment_source && item.appointment_source.name}</td>
                  <Tooltip
                    overlayStyle={{ whiteSpace: 'pre-line' }}
                    title={item?.notes?.map(n => n.text).join?.('\n\n')}
                  >
                    <td style={{ textAlign: 'left' }}>{renderNotes(item.notes)}</td>
                  </Tooltip>
                </tr>
              )
            }
          })}
      </Table>
      {/*)}*/}
      {/*</Tab>*/}
    </div>
  )
}

const mapDispatchToProps = {
  getMatrixLog: actions.matrixLog.getMatrixLog,
  setCurrentAppointmentId: actions.appointment.setCurrentAppointmentId,
}
export default withRouter(connect(null, mapDispatchToProps)(Appointment))
