export const getRealContractStatus = status => {
  status = +status
  switch (status) {
    case 1:
      return 'Estimate'
    case 2:
      return 'Contract'
    case 3:
      return 'Completed'
    case 4:
      return 'Cancelled'
    case 5:
      return 'Cold'
    default:
      return 'Estimate'
  }
}
