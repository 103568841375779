import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { RecurringInterval, RecurringMonthlyType } from 'types/Appointment'

function calculateEventQuantity(startDate, endDate, eventConfig) {
  // Convert start and end date strings to Moment.js objects
  const start = moment(startDate)
  const end = moment(endDate)

  // Initialize counters for events
  let eventCount = 0

  // Iterate over dates within the specified range
  while (start.isSameOrBefore(end)) {
    // Check if the current date falls on any of the specified days of the week and week numbers
    const currentDayOfWeek = start.day()
    eventConfig.forEach(config => {
      if (
        config.weekNumber &&
        config.day === currentDayOfWeek &&
        start.isoWeek() ===
          moment(start)
            .date(1)
            .isoWeek() +
            config.weekNumber /* - 1 */
      ) {
        eventCount++
      }
    })

    // Move to the next day
    start.add(1, 'day')
  }

  // Return the total quantity of events
  return eventCount
}

const getNumberOfIntervalDaysBetween = (startDate, endDate, intervalDay, repeatEvery, intervalType) => {
  startDate = moment(startDate)
  endDate = moment(endDate)

  const getNextIntervalDay = day => {
    if (intervalType === 'weeks') {
      const daysToAdd = (7 + day - startDate.isoWeekday()) % 7
      return startDate.clone().add(daysToAdd, 'days')
    } else {
      return day >= startDate.date()
        ? startDate.clone().date(day)
        : startDate
            .clone()
            .add(1, intervalType)
            .date(day)
    }
  }

  const nextIntervalDay = getNextIntervalDay(intervalDay)

  if (nextIntervalDay.isAfter(endDate)) {
    return 0
  }

  const intervalsBetween = Math.floor(endDate.diff(nextIntervalDay, intervalType) / repeatEvery)

  return intervalsBetween + 1 - (repeatEvery !== 1 && !nextIntervalDay.isSame(startDate, intervalType) ? 1 : 0)
}

const getEndDateAndCounter = (startDate, endDate, repeatEvery, selectedDays = [], intervalType, monthType, counter) => {
  const [firstAppointmentDate, setFirstAppointmentDate] = useState(null)
  const [totalEntries, setTotalEntries] = useState(0)
  const [lastAppointmentDate, setLastAppointmentDate] = useState(null)

  // selectedDays.forEach(day => console.log(day, weekdaysBetween(startDate, endDate, day)))
  // console.log(selectedDays.reduce((acc, val) => acc + monthdaysBetween(startDate, endDate, val, repeatEvery), 0))

  useEffect(() => {
    if (counter) {
      const now = moment(startDate)
      let endDate
      let firstAppointmentDate
      if (intervalType === 2) {
        const thisDay = now.day()
        const firstDay = selectedDays?.find(day => day >= thisDay)

        firstAppointmentDate = firstDay ? moment(now).day(firstDay) : moment(now).day(+selectedDays[0] + 7)

        const newCounter = counter - selectedDays?.filter(i => i >= firstDay)?.length
        //Start

        const isSameWeek = now.isSame(firstAppointmentDate, 'week')

        // console.log('isSameWeek', isSameWeek)
        // console.log('newCounter', newCounter)

        if (newCounter >= 0) {
          const weeks =
            Math.ceil(newCounter / selectedDays?.length - 1) * repeatEvery < 0
              ? 0
              : Math.ceil(newCounter / selectedDays?.length) * repeatEvery
          const day = newCounter % selectedDays?.length

          // console.log('weeks', weeks, 'day', day)

          endDate = moment(firstAppointmentDate)
            .week(moment(firstAppointmentDate).week() + (weeks - (!isSameWeek ? repeatEvery : 0)))
            .day(selectedDays[selectedDays?.length - 1])
          // console.log(weeks - (!isSameWeek ? repeatEvery : 0))
          if (day) endDate.day(selectedDays[day - 1])
        } else {
          endDate = moment(firstAppointmentDate).day(selectedDays[selectedDays?.length - 1])
        }
      } else if (intervalType === RecurringInterval.MONTH) {
        if (monthType === RecurringMonthlyType.WEEKLY) {
          setFirstAppointmentDate(null)
          setLastAppointmentDate(null)
          return setTotalEntries(0) //TEMP Hide Totals string for Weekly type
        }

        const thisDate = now.date()
        const firstDay = selectedDays.find(i => i >= thisDate)
        firstAppointmentDate = firstDay
          ? moment(now).date(firstDay)
          : moment(now)
              .month(moment(now).month() + repeatEvery)
              .date(selectedDays[0])

        const newCounter = counter - selectedDays.filter(i => i >= firstDay)?.length
        const isSameMonth = now.isSame(firstAppointmentDate, 'month')

        if (newCounter >= 0) {
          const months =
            Math.ceil(newCounter / selectedDays?.length - 1) * repeatEvery < 0
              ? 0
              : Math.ceil(newCounter / selectedDays?.length) * repeatEvery
          const day = newCounter % selectedDays?.length

          endDate = moment(firstAppointmentDate)
            .month(moment(firstAppointmentDate).month() + (months - (!isSameMonth ? repeatEvery : 0)))
            .date(selectedDays[selectedDays?.length - 1])

          if (counter === 1) endDate = moment(firstAppointmentDate)

          if (day) endDate.date(selectedDays[day - 1])
        } else endDate = moment(firstAppointmentDate).date(selectedDays[counter - 1])
      } else {
        const thisDate = now.date()
        firstAppointmentDate = moment(now)

        endDate = moment(firstAppointmentDate).date(thisDate + repeatEvery * counter - repeatEvery)

        if (counter === 1) endDate = moment(firstAppointmentDate)
      }

      setFirstAppointmentDate(firstAppointmentDate)
      setTotalEntries(counter)
      setLastAppointmentDate(endDate)
      return
    }

    const now = moment(startDate)
    let total
    let firstAppointmentDate

    if (intervalType === 2) {
      const thisDay = now.day()
      const firstDay = selectedDays.find(day => day >= thisDay)

      firstAppointmentDate = firstDay ? moment(now).day(firstDay) : moment(now).day(+selectedDays[0] + 7)

      total = selectedDays.reduce(
        (acc, val) => acc + getNumberOfIntervalDaysBetween(startDate, endDate, val, repeatEvery, 'weeks'),
        0,
      )
    } else if (intervalType === 3) {
      const thisDate = +now.format('D')
      const firstDay = selectedDays.find(i => i >= thisDate)

      if (monthType === RecurringMonthlyType.WEEKLY) {
        setFirstAppointmentDate(null)
        setLastAppointmentDate(null)
        return setTotalEntries(0) //TEMP Hide Totals string for Weekly type
      }

      // if (monthType === 1) {
      firstAppointmentDate = firstDay
        ? moment(now).date(firstDay)
        : moment(now)
            .add(1, 'month')
            .date(selectedDays[0])

      total = selectedDays.reduce(
        (acc, val) => acc + getNumberOfIntervalDaysBetween(startDate, endDate, val, repeatEvery, 'months'),
        0,
      )
      // } else {
      //   firstAppointmentDate = startDate
      //   total = calculateEventQuantity(startDate, endDate, selectedDays)
      // }
    } else {
      firstAppointmentDate = moment(now)
      total = Math.floor((moment(endDate).dayOfYear() - moment(firstAppointmentDate).dayOfYear()) / repeatEvery) + 1
    }

    setFirstAppointmentDate(firstAppointmentDate)
    setTotalEntries(total)
    setLastAppointmentDate(endDate)
  }, [startDate, endDate, selectedDays?.length, repeatEvery, counter, intervalType])

  return [firstAppointmentDate, totalEntries, lastAppointmentDate]
}

export default getEndDateAndCounter
