import React from 'react'
import { Link } from 'react-router-dom'
import './AppointmentMarkerPopap.scss'
import PopapWrapper from '../PopapWrapper'
import moment from 'moment-timezone'
import { meSelector } from 'store/SuperAdmin/selectors'
import { useAppSelector } from 'store/Orcatec/hooks'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { DispatchPermission } from 'features/Settings/UsersAndGroups'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'

export const statuses = {
  1: 'Active',
  2: 'In progress',
  3: 'Completed',
  4: 'Cancelled',
  5: 'En Route',
}

const AppointmentMarkerPopap = props => {
  const dictionary = useAppSelector(selectJobDictionary)

  const parseTime = (start, end) => {
    if (!start || !end) return '—'
    if (start === end) {
      return getTime(start)
    } else {
      return `${getTime(start)}-${getTime(end)}`
    }
  }

  const getTime = time => {
    return moment(time, 'HH:mm')
      .format('LT')
      .replace(' ', '')
      .slice(0, -1)
      .toLowerCase()
  }

  return (
    <PopapWrapper className={props.className}>
      <div className='row d-flex px-2'>
        <span>Type:</span>
        <span>
          <strong>{props.task.type || 'Instant'}</strong>
        </span>
      </div>
      {props.task.time_start && props.task.time_end && (
        <div className='row d-flex px-2' style={{ flexWrap: 'nowrap' }}>
          <span>Time:</span>
          <span className='text-right'>
            <strong>
              {parseTime(props.task.time_start, props.task.time_end)}
            </strong>
          </span>
        </div>
      )}
      <div className='row d-flex px-2'>
        <span>Status:</span>
        <span>
          <strong>{statuses[props.task.status]}</strong>
        </span>
      </div>
      <div className='row d-flex px-2'>
        <span>Address:</span>
        <span>
          <strong>{props.task?.address + ', ' + props.task?.postcode}</strong>
        </span>
      </div>
      <AccessControl scopes={[DispatchPermission.DISPATCH_CAN_EDIT_EVENT]}>
        {!props?.isAppointment && (
          <>
            <div className='row d-flex justify-content-end px-2'></div>
            <div className='row d-flex justify-content-end px-2'>
              {props?.onOpenAppointment ? (
                <button
                  onClick={() => props?.onOpenAppointment?.(props.task)}
                  className='btn btn-info btn-sm py-0 px-1'
                >
                  {props?.task?.kind === 2
                    ? `View ${dictionary.singular}`
                    : 'View Appointment'}
                </button>
              ) : (
                <Link
                  to={{
                    pathname: `appointment/${props.task.id}`,
                    edit: true /* params: props.task */,
                  }}
                >
                  <button className='btn btn-info btn-sm py-0 px-1'>
                    {props?.task?.kind === 2
                      ? `View ${dictionary.singular}`
                      : 'View Appointment'}
                  </button>
                </Link>
              )}
            </div>
          </>
        )}
      </AccessControl>
    </PopapWrapper>
  )
}

export default AppointmentMarkerPopap
