const formRelatedData = (obj, attachParent) => {
  const res = []
  const resObj = {
    parent_id: obj.id,
    proposal_relation: obj.proposal_relation,
    checked: obj.checkbox,
    clients: [],
    organizations: [],
  }

  obj?.clients?.every(el => {
    resObj.clients.push({
      id: el.id,
      proposal_relation: el.proposal_relation,
      checked: attachParent ? true : el.checkbox,
      properties: el.properties.reduce((acc, curr) => {
        acc.push({
          id: curr.id,
          proposal_relation: curr.proposal_relation,
          checked: curr.checkbox,
        })
        return acc
      }, []),
    })

    // debugger
    return !attachParent
  })

  obj?.organizations?.forEach(el => {
    resObj.organizations.push({
      id: el.id,
      proposal_relation: el.proposal_relation,
      checked: attachParent ? true : el.checkbox,
      properties: el.properties.reduce((acc, curr) => {
        acc.push({
          id: curr.id,
          proposal_relation: curr.proposal_relation,
          checked: curr.checkbox,
        })
        return acc
      }, []),
      contacts: el.contacts.reduce((acc, curr) => {
        acc.push({
          id: curr.id,
          proposal_relation: curr.proposal_relation,
          checked: curr.checkbox,
        })
        return acc
      }, []),
    })
  })

  res.push(resObj)
  return res
}

const formRelatedIds = obj => {
  const res = []
  if (obj.clients)
    res.push(
      ...obj.clients.reduce((acc, curr) => [...acc, ...curr.properties.filter(el => el.checkbox).map(el => el.id)], []),
    )
  if (obj.organizations)
    res.push(
      ...obj.organizations.reduce(
        (acc, curr) => [...acc, ...curr.properties.filter(el => el.checkbox).map(el => el.id)],
        [],
      ),
    )
  if (obj.id) res.push(obj.id)
  return res
}

const formRelatedContactsIds = obj => {
  const res = []
  if (obj.organizations)
    res.push(
      ...obj.organizations.reduce(
        (acc, curr) => [...acc, ...curr.contacts.filter(el => el.checkbox).map(el => el.id)],
        [],
      ),
    )
  return res
}

export const formatTableDataProperties = (currentTableData, attachParent) => {
  return {
    properties: currentTableData.reduce((acc, curr) => {
      const respondData = {
        property_relation_id: curr.property_relation_id,
        proposal_relation: curr.proposal_relation || '',
        related_data: formRelatedData(curr, attachParent),
        property_ids: formRelatedIds(curr),
        client_ids: attachParent
          ? curr?.clients?.[0]?.id
            ? [curr?.clients?.[0]?.id]
            : []
          : [...curr?.clients?.filter(el => el.checkbox).map(el => el.id), ...formRelatedContactsIds(curr)],
        organization_ids: attachParent
          ? curr?.organizations?.[0]?.id
            ? [curr?.organizations?.[0]?.id]
            : []
          : curr?.organizations?.filter(el => el.checkbox).map(el => el.id),
      }
      return acc.concat(respondData)
    }, []),
  }
}
