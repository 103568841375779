import { Tooltip } from 'antd'
import { DraggableIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import styled from 'styled-components'

interface Props {
  counter?: number
  icon: JSX.Element
  draggable?: boolean
  title: string
  onClick?: () => void
}

export const QueueRowHeading = ({
  onClick,
  icon,
  title,
  counter,
  draggable,
}: Props) => {
  return (
    <Wrapper
      className='queue-row__heading'
      onClick={onClick}
      clickable={!!onClick}
    >
      {draggable && <DraggableIcon />}
      <Icon>{icon}</Icon>

      <Tooltip title={title}>
        <Title>{title}</Title>
      </Tooltip>

      {!!counter && <span className='counter'>{counter}</span>}
      {!!onClick && <i className='mdi mdi-chevron-up' />}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ clickable: boolean }>`
  margin-bottom: 5px;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'grab')};
  display: inline-flex;
  gap: 2px;
  align-items: center;
`

const Icon = styled.div`
  width: 26px;
  height: 26px;

  svg {
    width: 100%;
    height: auto;
  }
`
const Title = styled.span`
  font-weight: 700;
  white-space: nowrap;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
`
