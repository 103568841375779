import { MessagingPermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'

export const messaging = {
  messaging_can_read: {
    name: 'View Messages',
    control_type: UniteType.MESSAGING_VIEW,
    description: `
    To link or unlink this phone number from specific users or groups, navigate to Users & Groups > Permissions, and update the assigned phone numbers in the Messaging module.
    `,
    with_access_scope: true,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.MESSAGING_CAN_READ,
    related_key: ['all'],
    is_module: true,
    custom_access_scope: [
      {
        key: 1,
        label: 'All Chats',
        customLabel: true,
        color: 'white',
        activeColor: '#adf7b6',
      },
      {
        key: 2,
        label: 'Own Chats',
        customLabel: true,
        activeColor: '#adf7b6',
        color: 'white',
      },
    ],
  },
}
