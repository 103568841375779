import axiosOrcatec from './axiosInstance'

/**
 * Fetch company info.
 *
 * @param id
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const fetchCompany = id => {
  return axiosOrcatec
    .get('setting/company-info')
    .then(response => response.data)
}

/**
 * Save company info.
 *
 * @param data
 * @return {*}
 */
export const saveCompanyInfo = (data, companyId) => {
  return axiosOrcatec
    .post(`setting/company-info`, data)
    .then(response => response.data)
}

export const saveJobDictionary = data => {
  return axiosOrcatec
    .patch(`/setting/dictionary/job`, data)
    .then(response => response.data)
}
