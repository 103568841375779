import axiosOrcatec from 'api/axiosInstance'
import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'
import { Project } from 'features/Project/types'

export interface createWOBody {
  proposal_id: number
  related_entities: {
    forms: boolean
    contacts: boolean
    scope_of_work: boolean
    notes: boolean
    info_for_workers: boolean
    customer_info: boolean
  }
}

const getList = (params?: GeneralTableParams & { proposal_id: number }) =>
  axiosOrcatec.get(`/proposal/work-orders`, { params }).then(res => res.data)

const getWorkOrderById = (id: number) =>
  axiosOrcatec.get(`proposal/work-orders/${id}`).then(res => res.data)

const create = (data: createWOBody) =>
  axiosOrcatec.post(`/proposal/work-orders`, data).then(res => res.data)

const updateField = (woId: number, data: Partial<Project>) =>
  axiosOrcatec
    .patch(`/proposal/work-orders/${woId}`, data)
    .then(res => res.data)

const deleteWorkOrder = (id: number) =>
  axiosOrcatec.delete(`proposal/work-orders/${id}`).then(res => res.data)

export const WorkOrderAPI = {
  getList,
  getWorkOrderById,
  create,
  deleteWorkOrder,
  updateField,
}
