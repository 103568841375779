import React, { ChangeEvent, useMemo } from 'react'
import { InputAdornment } from '@material-ui/core'
import { Popconfirm } from 'antd'
import { priceToView } from 'helpers/thousandSeparator'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import { AccessTime, AttachMoney } from '@material-ui/icons'
import { DeleteIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { IRate } from '../BlockItem'

import SelectField from 'containers/MainContent/Orcatec/components/Select'
import '../BlockItem.scss'
import { ITechnician } from 'types/Appointment'
import { UserStatus } from 'types/User'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'

export interface IProps {
  handleChangeItem: (
    event: ChangeEvent<HTMLInputElement>,
    itemIDX: number,
    id: number | null,
  ) => void
  handleChangeNotes: (
    event: ChangeEvent<HTMLInputElement>,
    itemIDX: number,
    id: number | null,
  ) => void
  handleDeleteItem: (id: number, itemIDX: number) => void
  heandleOnFocus: (
    e: ChangeEvent<HTMLInputElement>,
    itemIDX: number,
    value: number | string,
    id: number | null,
  ) => void
  heandleOnBlur: (
    e: ChangeEvent<HTMLInputElement>,
    itemIDX: number,
    value: number | string,
    id: number | null,
  ) => void
  item: IRate
  itemIDX: number
  role: string
  isJob: boolean
  users: ITechnician[]
}

const Rate = ({
  handleChangeItem,
  handleDeleteItem,
  handleChangeNotes,
  item,
  itemIDX,
  isJob,
  heandleOnFocus,
  heandleOnBlur,
  users,
  isProjectCompleted,
  // canView,
  canAdd,
  canEdit,
}: IProps) => {
  const dictionary = useAppSelector(selectJobDictionary)
  const checkIsDisabled = id => {
    if (isProjectCompleted) false
    if (canEdit) return false
    if (canAdd && canEdit) false
    if (!canEdit && canAdd && !id) return false
    return true
  }

  const inactiveUser = useMemo(
    () =>
      users
        ?.filter(
          user =>
            user.status === UserStatus.Inactive && user.id === item?.user_id,
        )
        ?.map(item => ({
          id: item.id,
          name: `${item.full_name} (Deactivated)`,
        })),
    [users],
  )

  return (
    <div className='block-cost-wrapper'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          marginBottom: '10px',
        }}
      >
        <span className={'item-title__wrapper'}>{`Time spent on the ${
          isJob ? dictionary.singular : 'appointment'
        }:`}</span>

        {users?.length && (
          <SelectField
            value={item?.user_id}
            onChange={event => {
              const user = users.find(item => item.id === event.target.value)

              return handleChangeItem(event, itemIDX, item?.id, user)
            }}
            options={[
              ...users
                ?.filter(item => item.status !== UserStatus.Inactive)
                .map(item => ({ id: item.id, name: item.full_name })),
              ...inactiveUser,
            ]}
            name='user_id'
            label='User'
            placeholder='User'
            style={{ width: '300px' }}
          />
        )}
      </div>
      <div className={'block-cost-container'}>
        <div className={'grid-item time-block'}>
          <div className='time-inputs'>
            <InputField
              name={'time-hours'}
              type={'number'}
              variant={'outlined'}
              label='Hours'
              size={'small'}
              value={item?.natural_time?.hours}
              onChange={event => handleChangeItem(event, itemIDX, item?.id)}
              onFocus={e =>
                heandleOnFocus(e, itemIDX, item?.natural_time?.hours, item?.id)
              }
              onBlur={e =>
                heandleOnBlur(e, itemIDX, item?.natural_time?.hours, item?.id)
              }
              autoComplete='off'
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>
                    <AccessTime />{' '}
                  </InputAdornment>
                ),
              }}
            />
            <InputField
              name={'time-minutes'}
              type={'text'}
              variant={'outlined'}
              size={'small'}
              label='Minutes'
              autoComplete='off'
              onFocus={e =>
                heandleOnFocus(
                  e,
                  itemIDX,
                  item?.natural_time?.minutes,
                  item?.id,
                )
              }
              onBlur={e =>
                heandleOnBlur(e, itemIDX, item?.natural_time?.minutes, item?.id)
              }
              value={item?.natural_time?.minutes}
              onChange={event => handleChangeItem(event, itemIDX, item?.id)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>
                    <AccessTime />{' '}
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className={'grid-item cost-block'}>
          <InputField
            onChange={event => handleChangeItem(event, itemIDX, item?.id)}
            type={'text'}
            label='Cost per hour'
            variant={'outlined'}
            size={'small'}
            autoComplete='off'
            value={item.cost_per_hour}
            name={'cost_per_hour'}
            onFocus={e =>
              heandleOnFocus(e, itemIDX, item.cost_per_hour, item?.id)
            }
            onBlur={e =>
              heandleOnBlur(e, itemIDX, item.cost_per_hour, item?.id)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <AttachMoney />{' '}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={'grid-item total-block'}>
          <span className={'item-title'}>Total</span>
          <span className={'item-total'}>
            {priceToView(item?.time * item?.cost_per_hour)}
          </span>
          <Popconfirm
            title={'Are you sure to delete this item?'}
            onConfirm={() => handleDeleteItem(item?.id, itemIDX)}
            okText={'Yes'}
            cancelText={'No'}
          >
            <div className={'delete-action'}>
              <DeleteIcon />
            </div>
          </Popconfirm>
        </div>
      </div>
      <div className='notes-block'>
        <InputField
          value={item.note}
          onChange={e => handleChangeNotes(e, itemIDX, item?.id)}
          label='Notes'
          type='text'
          name='note'
          multiline
          autoComplete='off'
          disabled={checkIsDisabled(item?.id)}
          InputProps={{
            readOnly: checkIsDisabled(item?.id),
          }}
        />
      </div>
    </div>
  )
}

export default Rate
