import { forwardRef } from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'
import { Label, Wrapper } from './InputField.styles'

export type IProps = {
  edit?: boolean
}
export type InputFieldProps = TextFieldProps & IProps

export const InputFieldV2 = forwardRef<HTMLDivElement, InputFieldProps>(
  ({ label, inputProps, fullWidth = true, edit = true, ...rest }, ref) => (
    <Wrapper edit={edit} className={rest.className}>
      <Label>
        {label}
        {rest.required && <span> *</span>}
      </Label>

      <TextField
        autoComplete='off'
        fullWidth={fullWidth}
        inputProps={{
          ...inputProps,
          autoComplete: 'off',
        }}
        size='small'
        variant='outlined'
        inputRef={ref}
        {...rest}
      />
    </Wrapper>
  ),
)

InputFieldV2.displayName = 'InputFieldV2'
