import { Moment } from 'moment-timezone'
import { Client } from './Client'

export interface IAppointmentStatusLog {
  status: keyof typeof AppointmentStatusID
  created_at?: string
}

export enum AppointmentEntityType {
  PROPERTY = 1,
  CLIENT = 2,
  ORGANIZATION = 3,
}

export enum EventKind {
  APPOINTMENT = 1,
  JOB = 2,
}

export enum AppointmentStatus {
  Active = 1,
  'In Progress' = 2,
  Completed = 3,
  Cancelled = 4,
  'En Route' = 5,
}

export interface IAppointment {
  address?: string
  timezone:string|null
  appointment_source_id: number | null
  appointment_type_id: number | null
  appointment_type: string
  column_template_id: number | string | (string | number)[] | null
  assign_to_matrix: boolean
  background_color?: string
  canceled_info: null | CanceledInfo
  children_appointments?: IAppointment[]
  city: string
  client_name: string
  clients: Client[]
  created_at: string
  date: string | string[] | null
  each?: number[] | RecurringSelectedDay[]
  ending_options?: {
    end_type: number
    value: null | string
  }
  entity_id: number
  entity_type: AppointmentEntityType
  full_address: string
  id: number
  industry_id: number
  interval?: RecurringInterval
  is_lead: boolean
  is_recurring: boolean
  kind: EventKind
  matrix_time_end: Moment | string | number| null 
  matrix_time_start: Moment | string | number| null 
  make_first_appointment: boolean
  monthly_type: RecurringMonthlyType
  number: string
  notes: string | string[] | null
  parent_id: number
  postcode: string
  preferred_technician: { name: string }
  preferred_technician_id: number|null
  repeat_every: number
  start_date: string | null
  state: string
  status: AppointmentStatusID
  status_logs: IAppointmentStatusLog[]
  time_start: Moment | string | null
  time_end: Moment | string | null
  username: string
  current_end_time: number | null
  current_start_time: number | null
  primary_entity_id: number | null
  company_start_time:number | null
  company_end_time:number | null
  primary_entity_type :number | null
  times_needed:number | null
  relation?:string|undefined
  queue_id: number | null
  updated_at?: string
  count_public_links:number
}


export enum AppointmentStatusID {
  'Active' = 1,
  'En Route' = 5,
  'In Progress' = 2,
  'Completed' = 3,
  'Cancelled' = 4,
  
}

type CanceledInfo = {
  reason: string
  date: string
  canceled_by: {
    name: string
  }
}

export enum RecurringInterval {
  DAY = 1,
  WEEK,
  MONTH,
  // MONTHDAYS
}
export enum RecurringMonthlyType {
  DAILY = 1,
  WEEKLY,
}

type RecurringSelectedDay = {
  weekNumber: 1 | 2 | 3 | 4
  day: number
};



export interface AppointmentType {
  id?: number | null
  deleted_at?: string
  inputValue?:string
  name?:string
  setEdited?:(val:boolean)=>void
  color?: {
    hex: string
  }
}

export type AppointmentSource = Omit<AppointmentType, 'color'>

export interface ITechnician {
  background_color: string | null
  column_template_id: number
  description: string
  disable?: boolean
  email: string
  id: number
  name: string
  full_name: string
  phone: string
  profile_picture: string | null
  sort_order: number
  title: string
  time_status: number
  user_id: number
}

export interface IIndustries {
  id: number
  name: string
}

type Getters<Type> = {
  [Property in keyof Type as Property]: (value:string,appt:IAppointment) => Type[Property]
};
interface RulesKey {
  matrix_time_end: (value: string, callback: (value: any) => boolean)=>string | null | boolean
  matrix_time_start: (value: string, callback: (value: string) => boolean)=>string | null | boolean
  appointed_technician_id:(value: string, callback: (value: string) => boolean)=>string | null | boolean
  time_start: (value: string, callback: (value: string) => boolean)=>string | null | boolean
  time_end: (value: string, callback: (value: string) => boolean)=>string | null | boolean
  date: (value: string, callback: (value: string) => boolean)=>string | null | boolean
  start_date:(value: string, callback: (value: string) => boolean)=>string | null | boolean
  ending_options: (value: string, callback: (value: string) => boolean)=>string | null | boolean
}

export type IRules= Getters<RulesKey>