import React from 'react'
import { Tooltip } from 'antd'

const cutText = text => {
  if (text?.length < 20) return `${text}`

  return <Tooltip title={text}>{`${text.slice(0, 20)}...`}</Tooltip>
}

export const PropertyItem = ({ item }) => {
  return (
    <div>
      <p className='proposal-contact-summary__item-title'>
        {item.proposal_relation ? cutText(item.proposal_relation) : ''}
      </p>
      <p>{item.address}</p>
      <p>{item.unit ? `Unit: ${item.unit}` : ''}</p>
      <p>
        {item.city}
        {item.postcode ? `, ${item.postcode}` : ''}
      </p>
    </div>
  )
}
