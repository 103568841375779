import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  gap: 15px;
`

export const Section = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`
export const Info = styled.div``
export const Title = styled.p`
  font-weight: 500;
`

export const SectionTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  margin: 15px 0 10px;
`

export const Text = styled.p`
  color: #777e91;
`

export const AddressWrapper = styled.div<{ error: boolean }>`
  & > div > div > p {
    font-size: 0.75rem;
    color: #808080;
    line-height: 1.5;
    margin-bottom: 3px;

    span {
      color: #f1453d;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${props => (props.error ? '#f44336' : '#d9d9d9')};
  }

  .MuiOutlinedInput-root {
    font-size: 14px;
    height: 32px;
    border-radius: 2px;

    &:hover:not(.Mui-error):not(.Mui-disabled) fieldset {
      border-color: ${props => (props.error ? '#f44336' : '#40a9ff')};
    }
  }
`

export const TimezoneWrapper = styled.div`
  .ant-select {
    width: 100%;
  }
`
export const Error = styled.p`
  margin-top: 2px;
  font-size: 0.7rem;
  color: #f12832;
`
