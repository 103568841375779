import React from 'react'

const CompaniesIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.99 5.00045e-06C8.01262 0.00198239 6.0802 0.590152 4.43703 1.69017C2.79385 2.79018 1.51369 4.35266 0.758342 6.18009C0.00299907 8.00753 -0.193608 10.0179 0.193372 11.957C0.580353 13.8962 1.53355 15.6771 2.93247 17.0746C4.33139 18.4721 6.11324 19.4235 8.05278 19.8086C9.99231 20.1936 12.0025 19.995 13.8291 19.2378C15.6558 18.4807 17.217 17.1989 18.3154 15.5547C19.4138 13.9104 20 11.9774 20 10C20 8.68594 19.741 7.38477 19.2378 6.17086C18.7347 4.95695 17.9972 3.85412 17.0675 2.9254C16.1379 1.99668 15.0343 1.2603 13.8199 0.758338C12.6055 0.256379 11.3041 -0.00130906 9.99 5.00045e-06ZM16.92 6H13.97C13.657 4.76146 13.1936 3.5659 12.59 2.44001C14.4141 3.06801 15.9512 4.33173 16.92 6ZM10 2.04C10.84 3.25191 11.4845 4.58818 11.91 6H8.09C8.51549 4.58818 9.16001 3.25191 10 2.04ZM2.26 12C1.91332 10.6892 1.91332 9.3108 2.26 8H5.64C5.55288 8.66322 5.50612 9.33112 5.5 10C5.50612 10.6689 5.55288 11.3368 5.64 12H2.26ZM3.08 14H6.03C6.34297 15.2386 6.80642 16.4341 7.41 17.56C5.58397 16.9354 4.04584 15.6708 3.08 14ZM6.03 6H3.08C4.04584 4.32918 5.58397 3.06457 7.41 2.44001C6.80642 3.5659 6.34297 4.76146 6.03 6ZM10 17.96C9.16001 16.7481 8.51549 15.4118 8.09 14H11.91C11.4845 15.4118 10.84 16.7481 10 17.96ZM12.34 12H7.66C7.56122 11.3378 7.50777 10.6695 7.5 10C7.50736 9.33046 7.56082 8.66221 7.66 8H12.34C12.4392 8.66221 12.4926 9.33046 12.5 10C12.4922 10.6695 12.4388 11.3378 12.34 12ZM12.59 17.56C13.1936 16.4341 13.657 15.2386 13.97 14H16.92C15.9512 15.6683 14.4141 16.932 12.59 17.56ZM14.36 12C14.4471 11.3368 14.4939 10.6689 14.5 10C14.4939 9.33112 14.4471 8.66322 14.36 8H17.74C18.0867 9.3108 18.0867 10.6892 17.74 12H14.36Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CompaniesIcon
