import * as types from '../types'

export default (state = { clients: [], currentClient: {} }, action) => {
  switch (action.type) {
    case types.client.CLIENT_SET_ITEM: {
      let { data, ...rest } = action.item
      rest.total = rest.total ? +rest.total : 0
      rest.page = rest.page ? +rest.page : rest.current_page ? +rest.current_page : 1
      rest.limit = rest.limit ? +rest.limit : rest.per_page ? +rest.per_page : 10
      if (!Array.isArray(data)) {
        data = [...Object.values(data)]
      }
      return { ...state, clients: data, pagination: { ...rest } }
    }
    case types.client.CLIENT_GET_ITEM:
      return state
    case types.client.CLIENT_CHANGE_FIELD:
      return { ...state, currentClient: { ...state.currentClient, [action.field]: action.value } }
    case types.client.SET_CURRENT_CLIENT:
      return { ...state, currentClient: action.res }
    case types.client.REMOVE_CURRENT_CLIENT:
      return { ...state, currentClient: {} }
    case types.client.ADD_CLIENT_ASSOCIATION:
      return {
        ...state,
        currentClient: {
          ...state.currentClient,
          client_associations: [...state.currentClient.client_associations, action.payload],
        },
      }
    case types.client.DELETE_CLIENT_ASSOCIATION:
      return {
        ...state,
        currentClient: {
          ...state.currentClient,
          client_associations: state.currentClient.client_associations.filter(ass => ass.id !== action.payload),
        },
      }
    default:
      return state
  }
}
