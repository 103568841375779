import { Wrapper } from './ProjectPreviewActions.styles'
import { Spin, Tooltip } from 'antd'
import { getProposalLink } from 'api/NotificationModal'
import { getProposalPdf } from 'api/Proposal'
import {
  IconEnvelope,
  IconEye,
  IconPDF,
  IconPrint,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import {
  selectProject,
  selectProjectActiveTab,
} from 'features/Project/projectSelectors'
import { downloadBlob } from 'helpers/downloadBlob'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'store/Orcatec/hooks'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import {
  NotificationModalType,
  NotificationTemplateType,
} from 'features/Notification/types'
// import { getSelectedClients } from 'containers/MainContent/Orcatec/Proposals/helpers/getSelectedClients'
import { selectSummaryContact } from 'features/Project/slices/projectContactsSlice'

export const ProjectPreviewActions = () => {
  const project = useAppSelector(selectProject)
  const currentTab = useAppSelector(selectProjectActiveTab)
  const { client: contacts } = useAppSelector(selectSummaryContact)
  const [publicLink, setPublicLink] = useState('')
  const [downloadPdfLoader, setDownloadPdfLoader] = useState(false)
  const [generatePdf, setGeneratePdf] = useState(false)
  const [emailModal, setEmailModal] = useState(false)

  useEffect(() => {
    const getPublicLink = async () => {
      const link = await getProposalLink(project.id)
      setPublicLink(link)
    }

    if (project.id) getPublicLink()
  }, [project.id])

  const tabId = currentTab?.id || ''
  // const selectedClients = getSelectedClients(project)

  const downloadPdf = async () => {
    const pdfName = `${project?.code}${tabId ? '-' + currentTab?.title : ''}`

    setDownloadPdfLoader(true)

    try {
      if (!project.id) return
      const dataURI = await getProposalPdf(project.id, tabId)

      await downloadBlob(dataURI, pdfName)
    } catch (error) {
      console.error(error)
    } finally {
      setDownloadPdfLoader(false)
    }
  }

  const handlePrint = async () => {
    const pdfName =
      project?.code +
      '-' +
      (currentTab?.title || '') +
      '-' +
      moment().toISOString()

    setGeneratePdf(true)
    const iframe = await document.createElement('iframe')

    try {
      const dataURI = await getProposalPdf(project.id, tabId)

      iframe.src = window.URL.createObjectURL(dataURI)
      iframe.name = pdfName
      iframe.id = 'print_frame'

      iframe.onload = () => {
        const pdfFrame = window.frames[pdfName]
        pdfFrame.focus()
        pdfFrame.print()
      }
      document.body.appendChild(iframe)
    } catch (error) {
      openNotificationWithIcon('error', {
        message:
          'Access to the frame is blocked. Please allow access in your browser settings.',
      })
      window.open(iframe.src, '_blank')

      console.error(error)
    } finally {
      setGeneratePdf(false)
    }
  }

  const handleCustomerViewOpen = () => {
    if (!publicLink) return

    window.open(publicLink, '_blank')
  }

  const toggleProposalEmailModal = () => setEmailModal(!emailModal)
  const ACTIONS = [
    {
      name: 'projectPrint',
      icon: <IconPrint />,
      tooltip: 'Print Project',
      visible: true,
      loading: generatePdf,
      click: handlePrint,
    },
    {
      name: 'projectDownload',
      icon: <IconPDF />,
      tooltip: 'Download Project as PDF',
      visible: true,
      loading: downloadPdfLoader,
      click: downloadPdf,
    },
    {
      name: 'projectPreview',
      icon: <IconEye />,
      tooltip: 'Customer View',
      visible: true,
      click: handleCustomerViewOpen,
    },
    {
      name: 'projectSend',
      icon: (
        <IconEnvelope
          style={{
            color: project.had_been_sent ? '#28a745' : '#4D4D4D',
          }}
        />
      ),
      tooltip: 'Send Project',
      visible: true,
      click: toggleProposalEmailModal,
    },
  ]

  return (
    <Wrapper>
      {ACTIONS.filter(action => action.visible).map(action => (
        <Tooltip key={action.name} title={action.tooltip}>
          <Spin spinning={!!action.loading}>
            <span style={{ cursor: 'pointer' }} onClick={action.click}>
              {action.icon}
            </span>
          </Spin>
        </Tooltip>
      ))}

      {emailModal && (
        <NotificationModal
          title='Send Project'
          showModal={emailModal}
          setShowModal={toggleProposalEmailModal}
          proposalForm={project}
          templateType={NotificationTemplateType.PROJECT}
          notificationType={NotificationModalType.PROJECT}
          clients={contacts?.map(contact => ({
            ...contact,
            emails: contact.emails?.map(email => ({ email })),
          }))}
          // clients={selectedClients}
        />
      )}
    </Wrapper>
  )
}
