import React, { forwardRef } from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'

import './style.scss'

const Modal = ({ isShow, title, titleComponent, onHide, children, size }, ref) => {
  return (
    <BootstrapModal
      ref={ref}
      show={isShow}
      backdrop='static'
      onHide={onHide}
      size={size && size === 'xl' ? 'lg' : size ? size : 'xs'}
      dialogClassName={`${size && size === 'xl' ? 'xl-modal-w' : ''}`}
    >
      <BootstrapModal.Header closeButton={true}>
        {titleComponent ? titleComponent() : <BootstrapModal.Title>{title}</BootstrapModal.Title>}
      </BootstrapModal.Header>
      <BootstrapModal.Body>{children}</BootstrapModal.Body>
    </BootstrapModal>
  )
}

export default forwardRef(Modal)
