import React, { FC } from 'react'
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd'

import { useDispatch } from 'react-redux'
import { DraggableIconBlack } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { ContactItem } from './components/ItemContainer/ContactItem/ContactItem'
import { PropertyItem } from './components/ItemContainer/PropertyItem/PropertyItem'
import { Section, Wrapper, ItemWrapper } from './SummaryItems.styles'
import { EntityType } from 'features/Project/types'
import {
  projectPropertiesSetPosition,
  setProjectClientsPosition,
} from 'features/Project/slices/projectContactsSlice'
import {
  projectWOPropertiesSetPosition,
  setProjectWOClientsPosition,
} from 'features/Project/slices/projectWorkOrderContactsSlice'

interface IProps {
  items: []
  freezed: boolean
  entityType: EntityType
  title: string
  isWorkOrder?: boolean
}

export const SummaryItems: FC<IProps> = ({
  freezed,
  entityType,
  items,
  title,
  isWorkOrder,
}) => {
  const dispatch = useDispatch()

  const handleMoveEnd = result => {
    const { destination, source } = result

    if (!destination) return

    if (entityType === EntityType.CLIENT) {
      isWorkOrder
        ? dispatch(
            setProjectWOClientsPosition({
              sourceIndex: source.index,
              destinationIndex: destination.index,
              entity_type: entityType,
            }),
          )
        : dispatch(
            setProjectClientsPosition({
              sourceIndex: source.index,
              destinationIndex: destination.index,
              entity_type: entityType,
            }),
          )
    } else {
      isWorkOrder
        ? dispatch(
            projectWOPropertiesSetPosition({
              sourceIndex: source.index,
              destinationIndex: destination.index,
              entity_type: entityType,
            }),
          )
        : dispatch(
            projectPropertiesSetPosition({
              sourceIndex: source.index,
              destinationIndex: destination.index,
              entity_type: entityType,
            }),
          )
    }
  }

  function getStyle(style, snapshot) {
    if (!snapshot.isDragging) return {}
    if (!snapshot.isDropAnimating) {
      return style
    }

    return {
      ...style,
      transitionDuration: `0.001s`,
    }
  }

  return items?.length ? (
    <DragDropContext onDragEnd={handleMoveEnd}>
      <Droppable
        isDropDisabled={freezed}
        direction='horizontal'
        droppableId='tiles'
      >
        {provided => (
          <Section ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((el, idx) => {
              return (
                <Draggable
                  isDragDisabled={freezed}
                  key={idx}
                  draggableId={idx.toString()}
                  index={idx}
                >
                  {(provided, snapshot) => (
                    <Wrapper
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getStyle(provided.draggableProps.style, snapshot)}
                    >
                      <ItemWrapper
                        isDragging={snapshot.isDragging}
                        label={`Primary ${title}`}
                        freezed={freezed}
                        isPrimary={idx === 0}
                      >
                        {!freezed ? (
                          <span {...provided.dragHandleProps}>
                            <DraggableIconBlack />
                          </span>
                        ) : (
                          <span></span>
                        )}
                        <div>
                          {entityType === EntityType.CLIENT ? (
                            <ContactItem item={el} />
                          ) : (
                            <PropertyItem item={el} />
                          )}
                        </div>
                      </ItemWrapper>
                    </Wrapper>
                  )}
                </Draggable>
              )
            })}
            <div style={{ visibility: 'hidden', height: 0 }}>
              {provided.placeholder}
            </div>
          </Section>
        )}
      </Droppable>
    </DragDropContext>
  ) : null
}
