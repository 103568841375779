import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  right: 20px;
  top: 12px;
  & span {
    cursor: pointer;
  }
  & .notification_spin {
  }
  & .ant-collapse-content-box {
    padding: 16px 40px;
  }
`
export const ModalContentWrapper = styled.div``
export const ModalContentWrapperInner = styled.div``
export const DeviderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  & .history-item strong {
    margin-right: 5px;
  }
`
export const HistoryWrapper = styled.div`
  & .history-item strong {
    margin-right: 5px;
  }

  & .ant-image {
    display: block;
  }
`
export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`
