import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getContactById,
  postContact,
  putContact,
  deletingContact,
} from 'api/Contacts/Contacts'
import { AppStateType } from 'store'
import { AsyncThunkAPI } from 'store/Orcatec/reducers/matrix/types'
import { IContacts } from '../types'
import { ClientType } from 'types/Client'
import { apiNotes } from 'api/Notes'

const normalizeClient = client => {
  if (typeof client.phones === 'string') {
    client.phones = JSON.parse(client.phones)
  }
  if (typeof client.emails === 'string') {
    client.emails = JSON.parse(client.emails)
  }
  if (!Array.isArray(client.phones)) client.phones = []
  if (!Array.isArray(client.emails)) client.emails = []
  if (!client.feedBackList) client.feedBackList = []
  if (!client.feedbacks)
    client.feedbacks = client.feedBackList ? client.feedBackList : []
  if (!client.properties) client.properties = []
  if (!client.tags) client.tags = []
  if (!client.client_associations) client.client_associations = []
  if (!client.client_properties) client.client_properties = []
  client.client_properties = client.properties.map(item => ({
    ...item,
    property_id: item.id,
  }))
  if (!client.contacts) client.contacts = []
  if (!client.organizations) client.organizations = []

  client.relations_with_property = [
    client.properties.reduce((acc, property) => {
      acc[property.id] = {
        note: property.relation_note
          ? property.relation_note
          : property.relation
          ? property.relation
          : property.note
          ? property.note
          : property.property_relation
          ? property.property_relation
          : '',
      }
      return acc
    }, {}),
  ]

  if (!client.vote) client.vote = 0
  if (!client.note) client.note = ''

  return client
}

const initialContact: IContacts = {
  appointments: [],
  messagings: [],
  client_associations: [],
  created_at: '',
  deleted_at: '',
  emails: [],
  phones: [],
  calls: [],
  feedBackList: [],
  id: 0,
  industry: null,
  is_individual: true,
  memberships: [],
  name: '',
  note: '',
  notes: [],
  organization_id: null,
  organizations: [],
  other_company_client_id: [],
  owner_user_id: 0,
  properties: [],
  proposals: [],
  relative_company_id: null,
  tags: [],
  type: 2,
  updated_at: '',
  user_id: 0,
  vote: 0,
  website: null,
}

const GET_CONTACTS = 'contacts/get_contact'
const CREATE_CONTACT = 'contacts/create_contact'
const UPDATE_CONTACT = 'contacts/update_contact'
const DELETE_CONTACT = 'contacts/delete_contact'

const fetchGetContact = async ({ id, type }: { id: number; type: number }) => {
  const res = await getContactById(id, type)
  const normalizedValue = normalizeClient(res)
  return normalizedValue
}

const fetchCreateContact = async (
  { contact, type }: { contact: IContacts; type: number },
  { rejectWithValue },
) => {
  try {
    const res = await postContact(contact, type)
    if (res?.note) {
      await apiNotes.postEntityNote(
        `/${type === ClientType.ORGANIZATION ? 'organizations' : 'clients'}/${
          res.id
        }/notes`,
        { text: res?.note },
      )
    }
    return res
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
}

const fetchUpdateContact = async (
  { contact }: { contact: IContacts },
  { rejectWithValue },
) => {
  try {
    const res = await putContact(contact.id, contact.type, contact)

    return res
  } catch (error) {
    rejectWithValue(error.response.data)
  }
}

const fetchDeleteContact = async (
  { contact }: { contact: IContacts },
  { rejectWithValue },
) => {
  try {
    await deletingContact(contact.id, contact.type)
  } catch (error) {
    rejectWithValue(error.response.data)
  }
}

export const getContact = createAsyncThunk<
  IContacts,
  { id: number; type: number },
  AsyncThunkAPI
>(GET_CONTACTS, fetchGetContact)
export const createContact = createAsyncThunk<
  IContacts,
  { contact: IContacts; type: number },
  AsyncThunkAPI
>(CREATE_CONTACT, fetchCreateContact)
export const updateContact = createAsyncThunk<
  IContacts,
  { contact: IContacts },
  AsyncThunkAPI
>(UPDATE_CONTACT, fetchUpdateContact)
export const deleteContact = createAsyncThunk<
  IContacts,
  { contact: IContacts },
  AsyncThunkAPI
>(DELETE_CONTACT, fetchDeleteContact)

const initialState: {
  status: 'idle' | 'loading' | 'success' | 'error'
  currentContact: IContacts | []
  error: { [key: string]: string } | null
  showPropertyForm: boolean
  showAppointmentForm: boolean
} = {
  status: 'idle',
  currentContact: initialContact,
  error: null,
  showPropertyForm: false,
  showAppointmentForm: false,
}

const contactSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    resetContact: state => {
      state.error = null
      state.status = 'idle'
      state.currentContact = initialContact
    },

    addContact: (state, { payload }) => {
      state.error = null
      state.status = 'idle'
      state.currentContact = payload
    },

    addContactToOrganization: (state, { payload }) => {
      state.error = null
      state.status = 'idle'
      state.currentContact.contacts = [
        ...state.currentContact.contacts,
        payload,
      ]
    },
    addOrganizationToContact: (state, { payload }) => {
      state.error = null
      state.status = 'idle'
      state.currentContact.organizations = [
        ...state.currentContact.organizations,
        payload,
      ]
    },
    updatingContact: (state, { payload }) => {
      state.error = null
      state.status = 'idle'
      state.currentContact = { ...state.currentContact, ...payload }
    },
    addCalls: (state, { payload }) => {
      state.error = null
      state.status = 'idle'
      state.currentContact.calls = payload
    },
    addMessagings: (state, { payload }) => {
      state.error = null
      state.status = 'idle'
      state.currentContact.messagings = payload
    },

    addNewProperty: (state, { payload }) => {
      state.error = null
      state.status = 'idle'
      state.currentContact.properties = {
        ...state.currentContact.properties,
        payload,
      }
    },
    updateProperty: (state, { payload }) => {
      state.error = null
      state.status = 'idle'
      state.currentContact.properties = payload
    },
    addNewProject: (state, { payload }) => {
      state.error = null
      state.status = 'idle'
      state.currentContact.proposals = [
        ...state.currentContact.proposals,
        payload,
      ]
    },
    addNewAppointment: (state, { payload }) => {
      state.error = null
      state.status = 'idle'
      state.currentContact.appointments = [
        ...state.currentContact.appointments,
        payload,
      ]
    },
    addNewMembership: (state, { payload }) => {
      state.error = null
      state.status = 'idle'
      state.currentContact.memberships = [
        ...state.currentContact.memberships,
        payload,
      ]
    },
    removeMembership: (state, { payload }) => {
      state.error = null
      state.status = 'idle'
      state.currentContact.memberships = state.currentContact.memberships.filter(
        m => m?.id !== payload?.label_id,
      )
    },
    togglePropertyFormAction: (state, { payload }) => {
      state.showPropertyForm = payload
    },
    toggleAppointmentFormAction: (state, { payload }) => {
      state.showAppointmentForm = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getContact.fulfilled, (state, { payload }) => {
      state.currentContact = payload
      state.status = 'success'
    })
    builder.addCase(getContact.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(createContact.fulfilled, state => {
      state.status = 'success'
    })
    builder.addCase(createContact.rejected, state => {
      state.error = 'error'
    })
    builder.addCase(updateContact.fulfilled, (state, { payload }) => {
      state.status = 'success'
      state.currentContact = payload
      state.error = null
    })
    builder.addCase(updateContact.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload
    })
    builder.addCase(deleteContact.fulfilled, state => {
      state.status = 'success'
      state.currentContact = []
      state.error = null
    })
    builder.addCase(deleteContact.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload
    })
  },
})

export default contactSlice.reducer
export const {
  resetContact,
  addContact,
  addNewProperty,
  addNewProject,
  addNewAppointment,
  addCalls,
  updatingContact,
  addNewMembership,
  updateProperty,
  removeMembership,
  addMessagings,
  togglePropertyFormAction,
  toggleAppointmentFormAction,
  addContactToOrganization,
  addOrganizationToContact,
} = contactSlice.actions

export const selectCurrentContact = (state: AppStateType) =>
  state.orcatec.contactSlice.currentContact
export const selectContactSlice = (state: AppStateType) =>
  state.orcatec.contactSlice
