import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProjectFee, ProjectTax, TaxChargeType, TaxRateSource } from '../types'
import { ProjectFeeAPI, ProjectTaxAPI } from 'api/Project'
import { AsyncThunkAPI } from 'store/types'
import { Error } from 'types/Error'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { startAppListening } from 'store/listenerMiddleware'
import {
  selectProjectTaxAndFeesSlice,
  selectProjectTotalAfterDiscounts,
  selectTaxableTotal,
} from '../projectSelectors'
import { selectPrimaryProjectProperty } from './projectContactsSlice'
import { getTaxRateByZip } from 'api/settings/Proposal'
import { round } from 'helpers/Math'

const initialState: {
  status: 'idle' | 'loading' | 'error'
  tax: ProjectTax | null
  fees: {
    data: Record<number, ProjectFee>
    ids: number[]
  }
  error: Error | null
} = {
  status: 'idle',
  tax: null,
  fees: {
    data: {},
    ids: [],
  },
  error: null,
}

const projectTaxAndFeesSlice = createSlice({
  name: 'projectTax',
  initialState,
  reducers: {
    taxSet: (state, { payload }) => {
      state.tax = payload
    },
    feesSet: (state, { payload }) => {
      state.fees = {
        data: transformArrayToObj(payload),
        ids: payload.map((fee: ProjectFee) => fee.id),
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(createProjectTax.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(createProjectTax.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.tax = payload
    })
    builder.addCase(createProjectTax.rejected, (state, action) => {
      state.status = 'error'
      state.error = action.payload?.errors || null
    })
    builder.addCase(updateProjectTax.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateProjectTax.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.tax = payload
    })
    builder.addCase(updateProjectTax.rejected, (state, action) => {
      state.status = 'error'
      state.error = action.payload?.errors || null
    })
    builder.addCase(deleteProjectTax.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteProjectTax.fulfilled, state => {
      state.status = 'idle'
      state.tax = null
    })
    builder.addCase(deleteProjectTax.rejected, (state, action) => {
      state.status = 'error'
      state.error = action.payload?.errors || null
    })

    //Fees
    builder.addCase(createProjectFee.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(createProjectFee.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.fees = {
        ids: [...state.fees.ids, payload.id],
        data: {
          ...state.fees.data,
          [payload.id]: payload,
        },
      }
    })
    builder.addCase(createProjectFee.rejected, (state, action) => {
      state.status = 'error'
      state.error = action.payload?.errors || null
    })
    builder.addCase(updateProjectFee.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateProjectFee.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.fees = {
        data: {
          ...state.fees.data,
          [payload.id]: payload,
        },
        ids: state.fees.ids,
      }
    })
    builder.addCase(updateProjectFee.rejected, (state, action) => {
      state.status = 'error'
      state.error = action.payload?.errors || null
    })
    builder.addCase(deleteProjectFee.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteProjectFee.fulfilled, (state, { meta }) => {
      state.status = 'idle'
      state.fees = {
        data: state.fees.data,
        ids: state.fees.ids.filter(feeId => feeId !== meta.arg.id),
      }
      delete state.fees.data[meta.arg.id]
    })
    builder.addCase(deleteProjectFee.rejected, (state, action) => {
      state.status = 'error'
      state.error = action.payload?.errors || null
    })
  },
})

export default projectTaxAndFeesSlice.reducer
export const { taxSet, feesSet } = projectTaxAndFeesSlice.actions

export const createProjectTax = createAsyncThunk<
  ProjectTax,
  ProjectTax,
  AsyncThunkAPI
>('projectTax/addTax', async tax => {
  const res = await ProjectTaxAPI.createTax(tax)

  return res
})

export const updateProjectTax = createAsyncThunk<
  ProjectTax,
  ProjectTax,
  AsyncThunkAPI
>('projectTax/updateTax', async tax => {
  const res = await ProjectTaxAPI.updateTax(tax)

  return res
})

export const deleteProjectTax = createAsyncThunk<
  ProjectTax,
  ProjectTax,
  AsyncThunkAPI
>('projectTax/deleteTax', async tax => {
  const res = await ProjectTaxAPI.deleteTax(tax.id)

  return res
})

export const createProjectFee = createAsyncThunk<
  ProjectFee,
  ProjectFee,
  AsyncThunkAPI
>('projectTax/addFee', async fee => {
  const res = await ProjectFeeAPI.createFee(fee)

  return res
})

export const updateProjectFee = createAsyncThunk<
  ProjectFee,
  ProjectFee,
  AsyncThunkAPI
>('projectTax/updateFee', async fee => {
  const res = await ProjectFeeAPI.updateFee(fee)

  return res
})

export const deleteProjectFee = createAsyncThunk<
  ProjectFee,
  ProjectFee,
  AsyncThunkAPI
>('projectTax/deleteFee', async fee => {
  const res = await ProjectFeeAPI.deleteFee(fee.id)

  return res
})

//listeners

startAppListening({
  predicate: (action, currentState, originalState) => {
    // console.log(action)

    const propertyUpdateAction = [
      'projectContacts/updateOrder',
      'projectContacts/setEntitiesToTiles',
      'projectContacts/removeItemFromTiles',
      'project/changeParentContact/fulfilled',
    ].includes(action.type)

    const prevPrimaryProperty = originalState.orcatec.projectContactsSlice.data.tiles.property.filter(
      property => property.display,
    )?.[0]
    const currentPrimaryProperty = currentState.orcatec.projectContactsSlice.data.tiles.property.filter(
      property => property.display,
    )?.[0]
    const tax = originalState.orcatec.projectTaxAndFeesSlice.tax

    // console.log(
    //   propertyUpdateAction,
    //   prevPrimaryProperty?.id !== currentPrimaryProperty?.id,
    // )

    return prevPrimaryProperty?.id && !currentPrimaryProperty?.id
      ? tax?.rate_source === TaxRateSource['Based on ZIP']
      : tax?.rate_source === TaxRateSource['Based on ZIP'] &&
          propertyUpdateAction &&
          prevPrimaryProperty?.id !== currentPrimaryProperty?.id
  },
  effect: async (_, { getState, dispatch }) => {
    // console.log('effect')
    const projectTax = selectProjectTaxAndFeesSlice(getState()).tax
    const taxableItemsTotal = selectTaxableTotal(getState())
    const totalAfterDiscounts = selectProjectTotalAfterDiscounts(getState())
    const primaryProperty = selectPrimaryProjectProperty(getState())

    // console.log(primaryProperty)

    const getRateByZipCode = async () => {
      if (!primaryProperty?.postcode) return 0

      try {
        const { tax } = await getTaxRateByZip(primaryProperty?.postcode, {
          city: primaryProperty.city,
          address: primaryProperty.address,
        })

        return tax.estimated_combined_rate
      } catch (error) {
        return 0
      }
    }

    const taxRate = await getRateByZipCode()

    if (taxRate === projectTax?.rate) return

    const taxableSum =
      projectTax?.type === TaxChargeType.MATERIALS
        ? taxableItemsTotal
        : totalAfterDiscounts

    const updatedTax = {
      ...projectTax,
      rate: taxRate,
      total: round(taxableSum * (taxRate / 100)),
    }

    // console.log(projectTax, updatedTax)

    dispatch(updateProjectTax(updatedTax))
  },
})
