import { Tooltip } from 'antd'
import {
  DragIcon,
  IconUserv2,
  OrganizationsIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { Contact } from 'features/Project/types'
import { DraggableProvided } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { ClientType } from 'types/Client'

interface Props {
  data: Contact
  disabled: boolean
  isPrimary: boolean
  provided: DraggableProvided
}

export const ContactItem = ({ data, disabled, isPrimary, provided }: Props) => {
  return (
    <Wrapper ref={provided?.innerRef} {...provided?.draggableProps}>
      <Title>
        <Icon>
          {data.type === ClientType.ORGANIZATION ? (
            <OrganizationsIcon width={20} height={20} />
          ) : (
            <IconUserv2
              style={{ fontSize: 20, position: 'relative', top: 3 }}
            />
          )}
        </Icon>
        {data.proposal_relation ? (
          <Relation>{data.proposal_relation}</Relation>
        ) : (
          <Name>{data.name}</Name>
        )}
        {isPrimary && <Primary>Primary</Primary>}
        {!disabled && (
          <span {...provided?.dragHandleProps} style={{ marginLeft: 'auto' }}>
            <DragIcon style={{ fontSize: 20, cursor: 'pointer' }} />
          </span>
        )}
      </Title>

      <AddInfo>
        {data.proposal_relation && <p>{data.name}</p>}

        {data.emails?.map((email, idx) => (
          <Tooltip title={email} key={idx}>
            <Email>{email}</Email>
          </Tooltip>
        ))}

        {data.phones?.map((phone, idx) => (
          <p key={idx}>{phone.phone}</p>
        ))}
      </AddInfo>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Icon = styled.div`
  flex: 0 0 20px;
`
const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;
`
const Primary = styled.p`
  color: #fff;
  background-color: #1ab2e2;
  padding: 0px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
`
const Name = styled.p`
  line-height: 1.33;

  span {
    font-weight: 500;
  }
`

const AddInfo = styled.div`
  color: #4d4d4d;
  margin-left: 32px;
`

const Relation = styled.p`
  font-weight: 500;
`

const Email = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
