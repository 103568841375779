import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export const SureModal = ({ modal, close, apply, body, title = 'Are you sure?' }) => {
  return (
    <Modal show={modal} onHide={close} backdrop='static' className='modal-sure-matrix'>
      <Modal.Header closeButton></Modal.Header>
      {body && (
        <Modal.Body>
          <p>{body}</p>
        </Modal.Body>
      )}
      <Modal.Footer>
        <button className='btn btn-custom-cancel' onClick={close}>
          No
        </button>
        <button className='btn btn-custom-info' onClick={apply}>
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  )
}
