import { InfoIcon, WarningIcon } from './Icons'
import { Wrapper } from './InfoBlock.styles'

type Props = {
  children: React.ReactNode
  type?: 'info' | 'warning' | 'error'
  style?: React.CSSProperties
}

export const InfoBlock = ({ children, type = 'info', style }: Props) => {
  const icon = type === 'warning' ? <WarningIcon /> : <InfoIcon />

  return (
    <Wrapper type={type} style={style}>
      {icon}
      <p>{children}</p>
    </Wrapper>
  )
}
