export enum DispatchPermission {
  DISPATCH_CAN_READ = 'dispatch_can_read',
  DISPATCH_CAN_CHANGE_EVENT_FRAME = 'dispatch_can_change_event_frame',
  DISPATCH_CAN_READ_EVENT_DETAILS = 'dispatch_can_read_event_details',
  DISPATCH_CAN_CREATE_EVENT = 'dispatch_can_create_event',
  DISPATCH_CAN_EDIT_EVENT = 'dispatch_can_edit_event',
  DISPATCH_CAN_DELETE_EVENT = 'dispatch_can_delete_event',
  DISPATCH_CAN_CHANGE_STATUS = 'dispatch_can_change_status',
  DISPATCH_CAN_DUPLICATE_EVENT = 'dispatch_can_duplicate_event',

  DISPATCH_CAN_CREATE_CUSTOM_FORMS = 'dispatch_can_create_custom_forms',
  DISPATCH_CAN_READ_CUSTOM_FORMS = 'dispatch_can_read_custom_forms',
  DISPATCH_CAN_EDIT_CUSTOM_FORMS = 'dispatch_can_edit_custom_forms',
  DISPATCH_CAN_DELETE_CUSTOM_FORMS = 'dispatch_can_delete_custom_forms',

  //Information for Workers
  DISPATCH_CAN_READ_INFO_FOR_WORKERS = 'dispatch_can_read_info_for_workers',
  DISPATCH_CAN_EDIT_INFO_FOR_WORKERS = 'dispatch_can_edit_info_for_workers',
  DISPATCH_CAN_DELETE_INFO_FOR_WORKERS = 'dispatch_can_delete_info_for_workers',
}
