import { useEffect, useState } from 'react'
import L from 'leaflet'
import moment, { Moment } from 'moment-timezone'
import { useAppSelector } from 'store/Orcatec/hooks'
import { fetchEmployeesRoutes } from 'api/map'
import { isValidCoordinates } from 'helpers/map'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import {
  IWorker,
  IProperty,
} from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentPreferredTech/AppointmentPreferredTech'
import { IAppointment } from 'types/Appointment'
import { createBusinessDays } from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentPreferredTech/helpers'

interface IProps {
  date: Moment | string
  coordinates: [number | string, number | string]
  appointment: IAppointment
  employees: IWorker[]
  property: IProperty
  company_end_time: string | number
  company_start_time: string | number
  daysAhead: number
  exclude_days: []
  radius: string | number
  timesNeeded: string | number
  days_behind: number
}

const calculateDistance = (latA, latB) => {
  if (latA !== undefined && latB !== undefined) {
    const dis = latA.distanceTo(latB)
    return dis
  } else {
    return 0
  }
}

const calculateDistanceBetweenAppointments = (
  coordinates,
  employeesRoutes,
  // timeFrom = 0,
  timeTo = 1,
  company_end_time,
  company_start_time,
  dateArr,
  appointment,
  property,
  type,
  date,
  radius,
  timeOff,
) => {
  // debugger
  if (!isValidCoordinates(coordinates)) return
  if (!employeesRoutes?.length) return
  // delete property?.id
  const employees = JSON.parse(JSON.stringify(employeesRoutes))
  const choosenEventCoord = L.latLng(+coordinates[0], +coordinates[1])

  const nearesEvent = employees?.reduce((acc, worker) => {
    const findTimeOffByWorker = timeOff?.filter(
      t =>
        t.user_id === worker.id &&
        (moment(date).isSame(t.start_date, 'day') ||
          moment(date).isBetween(t.start_date, t.end_date, 'day') ||
          moment(date).isSame(t.end_date, 'day')),
    )

    const waypoints = [
      worker?.info?.coordination,
      ...worker?.appointments.map(app => app.coordinates),
    ]

    const distanceBetweenTwoPoints = waypoints?.map((w, i) => {
      const distance = {
        waypointsBetweenTwoPointStart: null,
        waypointsBetweenTwoPointsEnd: null,
        distanceBetweenTwoPoints: null,
        distanseWithEvent: null,
        margin: null,
        distanceFromStartToEvent: null,
        distanceFromEventToEnd: null,
        endPointIdx: i + 1,
        startPointIdx: i,
        startPointId: worker?.appointments?.[i]?.id,
        endPointId: worker?.appointments?.[i + 1]?.id,
        workerId: worker.id,
        distance: 0,
        timeIntervalBetweenTwoAppointments: null,
        // timeIntervalNeeded: timeTo - timeFrom,
        timeIntervalNeeded: timeTo,
        mayInsert: true,
        isTimeOff: !!findTimeOffByWorker?.length,
      }

      if (waypoints?.length === 1) {
        //if no appointments
        distance.mayInsert =
          distance.timeIntervalNeeded <= company_end_time - company_start_time
        distance.timeIntervalBetweenTwoAppointments =
          company_end_time - company_start_time
        distance.current_start_time = company_start_time
        distance.current_end_time =
          company_start_time + distance.timeIntervalNeeded
        distance.startPointIdx = 0
      } else if (waypoints?.[i + 1]?.length) {
        distance.mayInsert =
          distance.timeIntervalNeeded <=
            worker?.appointments?.[i + 1]?.current_start_time -
              worker?.appointments?.[i]?.current_end_time &&
          worker?.appointments?.[i]?.current_start_time >= company_start_time &&
          worker?.appointments?.[i]?.current_end_time <= company_end_time
        distance.timeIntervalBetweenTwoAppointments =
          worker?.appointments?.[i + 1]?.current_start_time -
          worker?.appointments?.[i]?.current_end_time

        distance.current_start_time =
          worker?.appointments?.[i]?.current_end_time
        distance.current_end_time =
          worker?.appointments?.[i]?.current_end_time +
          distance.timeIntervalNeeded
        distance.startPointIdx =
          worker?.appointments.findIndex(
            a => worker?.appointments?.[i]?.id === a.id,
          ) + 1
      } else {
        distance.mayInsert =
          distance.timeIntervalNeeded <=
            company_end_time -
              worker?.appointments?.[i - 1]?.current_end_time &&
          worker?.appointments?.[i - 1]?.current_start_time >=
            company_start_time &&
          worker?.appointments?.[i - 1]?.current_end_time <= company_end_time
        distance.timeIntervalBetweenTwoAppointments =
          company_end_time - worker?.appointments?.[i - 1]?.current_end_time
        distance.current_start_time =
          worker?.appointments?.[i - 1]?.current_end_time
        distance.current_end_time =
          worker?.appointments?.[i - 1]?.current_end_time +
          distance.timeIntervalNeeded
        distance.startPointIdx =
          worker?.appointments.findIndex(
            a => worker?.appointments?.[i - 1]?.id === a.id,
          ) + 1
      }

      //==============================================================================

      if (waypoints?.[i + 1]?.length && waypoints?.[i]?.length) {
        distance.waypointsBetweenTwoPointStart = L.latLng(
          +waypoints[i][0],
          +waypoints[i][1],
        )
        distance.waypointsBetweenTwoPointsEnd = L.latLng(
          +waypoints[i + 1][0],
          +waypoints[i + 1][1],
        )
        distance.distanceBetweenTwoPoints = calculateDistance(
          distance.waypointsBetweenTwoPointStart,
          distance.waypointsBetweenTwoPointsEnd,
        )
        distance.distanceFromStartToEvent = calculateDistance(
          distance.waypointsBetweenTwoPointStart,
          choosenEventCoord,
        )
        distance.distanceFromEventToEnd = calculateDistance(
          choosenEventCoord,
          distance.waypointsBetweenTwoPointsEnd,
        )
        distance.margin =
          distance.distanceFromStartToEvent +
          distance.distanceFromEventToEnd -
          distance.distanceBetweenTwoPoints
      } else if (waypoints?.length === 1 && isValidCoordinates(waypoints)) {
        //if no appointments

        distance.waypointsBetweenTwoPointStart = L.latLng(
          +waypoints[0][0],
          +waypoints[0][1],
        )
        distance.waypointsBetweenTwoPointsEnd = L.latLng(choosenEventCoord)
        distance.distanceBetweenTwoPoints = calculateDistance(
          distance.waypointsBetweenTwoPointStart,
          distance.waypointsBetweenTwoPointsEnd,
        )

        distance.distanceFromStartToEvent = calculateDistance(
          distance.waypointsBetweenTwoPointStart,
          choosenEventCoord,
        )
        distance.margin = distance.distanceFromStartToEvent
      } else {
        if (isValidCoordinates(waypoints?.[waypoints?.length - 1])) {
          distance.waypointsBetweenTwoPointStart = L.latLng(
            +waypoints?.[waypoints?.length - 1][0],
            +waypoints?.[waypoints?.length - 1][1],
          )
          distance.waypointsBetweenTwoPointsEnd = L.latLng(
            +waypoints[0][0],
            +waypoints[0][1],
          )
          distance.distanceBetweenTwoPoints = calculateDistance(
            distance.waypointsBetweenTwoPointStart,
            distance.waypointsBetweenTwoPointsEnd,
          )
          distance.distanceFromStartToEvent = calculateDistance(
            distance.waypointsBetweenTwoPointStart,
            choosenEventCoord,
          )
          distance.distanceFromEventToEnd = calculateDistance(
            choosenEventCoord,
            distance.waypointsBetweenTwoPointsEnd,
          )
          // distance.margin =
          //   distance.distanceFromStartToEvent + distance.distanceFromEventToEnd - distance.distanceBetweenTwoPoints
          distance.margin = distance.distanceFromStartToEvent
        }
      }
      return distance
    })

    if (!isValidCoordinates(worker?.info?.coordination)) {
      acc.push({
        ...worker,
        date,
        distanceAppt: [],
        sortByTime: [],

        distance: [],
      })
      return acc
    }
    const appIds = worker?.appointments?.map(a => a?.id)
    acc.push({
      ...worker,
      date,
      distanceAppt: [...distanceBetweenTwoPoints].sort(
        (a, b) => a.margin - b.margin,
      ),
      sortByTime: worker?.appointments?.length
        ? distanceBetweenTwoPoints
            .filter(
              d =>
                d?.mayInsert &&
                !d?.isTimeOff &&
                (!appIds.includes(d?.startPointId) ||
                  !appIds.includes(d?.endPointId)),
            )
            .sort((a, b) => a.margin - b.margin)
        : distanceBetweenTwoPoints
            ?.map(d => d)
            ?.filter(f => !f?.isTimeOff)
            ?.sort((a, b) => a.margin - b.margin),

      distance: distanceBetweenTwoPoints.reduce(
        (acc, e) => (acc += e.margin),
        0,
      ),
    })

    return acc
  }, [])
  const filteredRoutes = JSON.parse(JSON.stringify(nearesEvent))?.map(route => {
    route?.appointments?.splice(route?.sortByTime?.[0]?.startPointIdx, 0, {
      ...property,
      ...appointment,
      coordinates,
      type,
    })

    return {
      ...route,
      pointIndex: route?.sortByTime[0]?.startPointIdx,
    }
  })

  const filteredRoutesByRadius = JSON.parse(
    JSON.stringify(filteredRoutes),
  ).filter(r => {
    const d = (r?.sortByTime?.[0]?.margin * 0.75) / 1000

    return +d.toFixed(1) <= +radius
  }) //filter by settings miles

  return filteredRoutesByRadius
}

const usePreferredRoute = ({
  date,
  coordinates,
  appointment,
  property,
  company_end_time,
  company_start_time,
  daysAhead,
  exclude_days,
  radius,
  timesNeeded,
  days_behind,
  timeOff,
  isFetching,
}: IProps) => {
  delete property?.id
  const employeesIds = useAppSelector(state =>
    state.orcatec?.company?.technicians?.map(e => e?.id),
  )
  const type = useAppSelector(
    state =>
      state.orcatec.company?.types?.find(
        s => s?.id === appointment?.appointment_type_id,
      )?.name,
  )
  const [employeesRoutes, setEmployeesRoutes] = useState([])
  const [
    employeesWithDistanceByRoutes,
    setEmployeesWithDistanceByRoutes,
  ] = useState<
    {
      date: string
      routes: IWorker[]
    }[]
  >([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [prevData, setPrevData] = useState({
    date: '',
    property: '',
    tech: '',
    daysAhead: '',
    days_behind: '',
  })
  const dataToSearch = appointment.date
    ? moment(appointment.date).format('MM/DD/YYYY')
    : moment().format('MM/DD/YYYY')

  // const current_start_time = appointment?.matrix_time_start
  //   ? moment(appointment.matrix_time_start).hour()
  //   : 0
  const current_end_time = moment(timesNeeded, 'hh:mm').hour() || 1
  const dateArr = createBusinessDays(
    dataToSearch,
    exclude_days,
    daysAhead,
    days_behind,
  )

  // EFFECTS

  useEffect(() => {
    const getEmployeesRoutes = async (
      date: Moment | string[],
      employees: number[],
    ): Promise<any | Error> => {
      try {
        setLoading(true)

        const routes = await fetchEmployeesRoutes(date, employees)
        setEmployeesRoutes(routes)
      } catch (error) {
        setError(error)
        openNotificationWithIcon('error', { message: error })
      } finally {
        setLoading(false)
      }
    }

    if (
      isValidCoordinates(coordinates) &&
      !isFetching &&
      (prevData.date !== appointment?.date ||
        prevData?.property?.address !== property?.address ||
        prevData?.tech !== appointment?.preferred_technician_id ||
        prevData.daysAhead !== daysAhead ||
        prevData.days_behind !== days_behind)
    ) {
      getEmployeesRoutes(
        dateArr || moment().format('MM/DD/YYYY'),
        appointment?.preferred_technician_id
          ? [appointment?.preferred_technician_id]
          : employeesIds,
      )
      setPrevData({
        date: appointment?.date,
        tech: appointment?.preferred_technician_id,
        property,
        daysAhead,
        days_behind,
      })
    }
  }, [
    appointment?.date,
    appointment?.preferred_technician_id,
    property,
    daysAhead,
    days_behind,
    isFetching,
  ])

  useEffect(() => {
    if (!property) {
      setEmployeesWithDistanceByRoutes([])
      setEmployeesRoutes([])
      setPrevData(prev => ({ ...prev, property: null }))
    }
  }, [property])

  useEffect(() => {
    if (
      !isValidCoordinates(coordinates) &&
      !employeesRoutes?.length &&
      !loading &&
      !isFetching
    )
      return setEmployeesWithDistanceByRoutes([])
    const distanceByPreferredTech = Object.entries(employeesRoutes)?.reduce(
      (acc, [date, employeeRoute]) => {
        const isToday = moment()
          // .local()
          .isSame(date, 'day')

        const companyStartTime = isToday ? moment().hours() : company_start_time
        const bestRouteWithEvent = calculateDistanceBetweenAppointments(
          coordinates,
          employeeRoute,
          // current_start_time,
          current_end_time,
          company_end_time,
          // company_start_time,
          companyStartTime,
          dateArr,
          appointment,
          property,
          type,
          date,
          radius,
          timeOff,
        )
        acc.push({ date, routes: bestRouteWithEvent })

        return acc
      },
      [],
    )
    if (distanceByPreferredTech?.length) {
      setEmployeesWithDistanceByRoutes(distanceByPreferredTech)
    }
  }, [
    appointment?.preferred_technician_id,
    date,
    employeesRoutes?.length,
    timesNeeded,
    loading,
    current_end_time,
    radius,
    daysAhead,
    days_behind,
    isFetching,
  ])

  return { employeesWithDistanceByRoutes, loading, error }
}

export default usePreferredRoute
