import React, { useEffect } from "react"
import { pdfjs } from "react-pdf"
import { setHeaderType } from "../state/actions"
import { useDocumentLoader } from "../utils/useDocumentLoader"
import { openFileInNewTab } from '../utils/openFileInTab'
import { Spin } from "antd"
import NotFound from "./common/NotFound"

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export const Renderer = () => {
  const { state, dispatch, CurrentPlagin } = useDocumentLoader()
  const { loading, error, currentDocument} = state
  const pathToRedirect = currentDocument?.source || currentDocument?.linkToFile || currentDocument 
  useEffect(() => {
    dispatch(setHeaderType(CurrentPlagin?.headerType))
  }, [CurrentPlagin, error, loading])

  if(error.hasError){
    return <NotFound errorText={error.text}/>
  }


  const Contents = () => {
    if (CurrentPlagin === undefined) {
      return null
    } else if (loading && !error.hasError) {
      return <Spin size='large' />
    } else if (!loading && !error.hasError) {
      return <CurrentPlagin state={state} />
    } else {
      return <NotFound errorText={error.text}  redirectHeandler={() => openFileInNewTab(pathToRedirect)} />
    }
  }

  return (
    <div className="renderer-container" id="proxy-renderer">
      <Contents />
    </div>
  )
}


