import React from "react"
import '../../styles/styles.scss'

const ImageRenderer= ({state}) => {
  const { currentDocument,rotate, scale} = state
  return (
    <div style={{transform:`rotate(${rotate}deg)`}} className="image-renderer">
        <img style={{transform:`scale(${scale})`}} className="image-img" src={currentDocument?.source || currentDocument?.linkToFile || currentDocument } />
    </div>
  )
}

ImageRenderer.fileTypes = 'image'
ImageRenderer.headerType='image'

export default ImageRenderer








