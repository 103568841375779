import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  controls?: React.ReactNode
  children: React.ReactNode
}

export const TabsLayout = ({ title, controls, children }: Props) => {
  return (
    <Wrapper>
      <Heading>
        <Title>{title}</Title>
        <Controls>{controls}</Controls>
      </Heading>

      <Content>{children}</Content>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`
const Controls = styled.div``
const Content = styled.div``
const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
`
