import React from 'react'
import { Tooltip } from 'antd'
import moment from 'moment-timezone'
import { priceToView } from 'helpers/thousandSeparator'
import {
  JobsIcon,
  ScheduleIcon,
  DispatchIcon,
} from 'layouts/MainLayout/Header/components/Navigation/NavigationIcons'
import { PersonOutline } from '@material-ui/icons'
import { MoneyIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { IHistory } from '../BlockItem'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'

interface IProps {
  item: IHistory
  iconOnClickHeandler: (id: number) => void
  isJob: boolean
  canEdit: boolean
  additionalSum: number
}

const CardHeader = ({
  item,
  iconOnClickHeandler,
  isJob,
  additionalSum,
}: IProps) => {
  const dictionary = useAppSelector(selectJobDictionary)
  return (
    <div className='item-info'>
      <Tooltip title={`Open ${isJob ? dictionary.singular : 'Appointment'}`}>
        <div
          className='link'
          onClick={() => {
            iconOnClickHeandler(item.id)
          }}
        >
          {isJob ? <JobsIcon /> : <DispatchIcon />}
          {` ${isJob ? item.job_code : 'Appointment #' + item.id}`}
        </div>
      </Tooltip>
      <Tooltip title='Date'>
        <span>
          <ScheduleIcon />
          {moment(item?.date).format('MM/DD/YYYY')} {item?.time}
        </span>
      </Tooltip>{' '}
      <Tooltip title='Worker'>
        <span>
          <PersonOutline /> {item?.user?.name}
        </span>
      </Tooltip>
      {/* {canEdit && (
        <span>
          <Tooltip title='Cost Per Hour'>
            <StarRate /> {priceToView(item?.user?.labor_rate)}
          </Tooltip>
        </span>
      )} */}
      <span>
        <Tooltip title={`${dictionary.singular} Cost`}>
          {/* <MoneyIcon /> {priceToView(item?.cost)} */}
          <MoneyIcon /> {priceToView(additionalSum)}
        </Tooltip>
      </span>
    </div>
  )
}

export default CardHeader
