import styled from 'styled-components'

export const Wrapper = styled.div``

export const Actions = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  span {
    cursor: pointer;
    font-size: 16px;

    &.anticon-delete {
      &:hover {
        color: red;
      }
    }

    &.anticon-edit {
      &:hover {
        color: #4285f4;
      }
    }
  }
`

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: flex-end;
`
export const Title = styled.span`
  color: #1890ff;
  cursor: pointer;

  &:hover {
    color: #67a0ff;
  }
`
