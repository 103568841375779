import { useState } from 'react'
import { useDispatch } from 'react-redux'
import './TabsContent.scss'
import TabActionButtonsBlockContainer from './TabActionButtonsBlock/TabActionButtonsBlockContainer'
import TabInfoBlockContainer from './TabInfoBlock/TabInfoBlockContainer'
import TabStatus from './TabStatus/TabStatus'
import TabPaymentDescription from './TabPaymentDescription/TabPaymentDescription'
import TabTotalBlock from '../Tabs/EndOfSystem'
import { addProjectSection } from 'features/Project/slices/projectSectionsSlice'
import {
  selectProject,
  selectProjectActiveTab,
  selectProjectPayment,
  selectProjectSections,
  selectProjectSettings,
  selectProjectTotal,
} from 'features/Project/projectSelectors'
import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'
import { useAppSelector } from 'store/Orcatec/hooks'
import { Button } from 'antd'
import TabSectionsContainer from './TabSections'
// import { getProject } from 'features/Project/slices/projectSlice'
import { updateProjectTabField } from 'features/Project/slices/projectTabsSlice'
import PaymentSchedule from '../../Modals/PaymentSchedule'
import { hasActiveMerchant } from 'store/Orcatec/reducers/settings/company'

const TabsContent = props => {
  const dispatch = useDispatch()

  const {
    id: projectId,
    user_id,
    signed_tab_id: proposalSignedTabId,
    // priority_tab_id: proposalPriorityTabId,
    status: projectStatus,
  } = useAppSelector(selectProject)
  const currentTab = useAppSelector(selectProjectActiveTab)
  const isPriceVisible = useAppSelector(selectIsPriceVisible(user_id))
  const sections = useAppSelector(selectProjectSections)
  const projectTotal = useAppSelector(selectProjectTotal)
  const hasMerchant = useAppSelector(hasActiveMerchant)
  const payments = useAppSelector(selectProjectPayment)
  const projectSettings = useAppSelector(selectProjectSettings)

  const [loading, setLoading] = useState(false)
  const [
    isPaymentModuleStatusChanging,
    setIsPaymentModuleStatusChanging,
  ] = useState(false)
  const [paymentModal, setPaymentModal] = useState(false)

  const contractId = proposalSignedTabId ? proposalSignedTabId : currentTab.id
  const isTabContract = currentTab.id === contractId

  const onAddSectionHeandler = async () => {
    setLoading(true)

    await dispatch(
      addProjectSection({
        proposal_tab_id: currentTab.id,
      }),
    )

    setLoading(false)
  }

  // const handleTabChange = async (_: number, tabId: number) => {
  //   await dispatch(getProject({ projectId, tabId }))
  // }

  const openModalPayment = async () => {
    if (!isPriceVisible) return

    if (currentTab.payment_status === 1 || !currentTab.payment_status) {
      setIsPaymentModuleStatusChanging(true)
      await dispatch(
        updateProjectTabField({
          payment_status: 2,
        }),
      )
      setIsPaymentModuleStatusChanging(false)
    }

    setPaymentModal(true)
  }

  if (!currentTab?.id || !projectId) return null

  return (
    <div
      className={`proposal-form-tabs_content${
        proposalSignedTabId && currentTab?.id !== proposalSignedTabId
          ? ' view-only'
          : ''
      }`}
    >
      {projectSettings.multiple_tabs && (
        <TabActionButtonsBlockContainer
          // onChangeTab={handleTabChange}
          onMove={props.onTabPositionChange}
          isTabLoading={props.isTabLoading}
          onEditTabInfo={props.onEditTabInfo}
          onCopyTab={props.onCopyTab}
          onDeleteTab={props.onDeleteTab}
          isTabCopying={props.isTabCopying}
          isTabDeleting={props.isTabDeleting}
          isTabContract={isTabContract}
        />
      )}
      <TabInfoBlockContainer
        openModalOptional={props.openModalOptional}
        openModalEquipment={props.openModalEquipment}
        openModalPayment={openModalPayment}
        isEquipmentModuleStatusChanging={props.isEquipmentModuleStatusChanging}
        isPaymentModuleStatusChanging={isPaymentModuleStatusChanging}
        isTabContract={isTabContract}
        getFileForModalDocViewer={props.getFileForModalDocViewer}
      />

      <div className='status-with-button'>
        {(projectStatus === 1 || props.isEditContract) &&
          projectSettings?.show_sections && (
            <Button onClick={onAddSectionHeandler} loading={loading}>
              + Add Section
            </Button>
          )}
        <TabStatus />
      </div>

      <TabSectionsContainer
        toggleSaveTemplateSectionModal={props.toggleSaveTemplateSectionModal}
        toggleLoadTemplateSectionModal={props.toggleLoadTemplateSectionModal}
        isEditContract={props.isEditContract}
        onSubmit={props.onSubmit}
        getFileForModalDocViewer={props.getFileForModalDocViewer}
        showPrice={isPriceVisible}
      />
      <TabTotalBlock
        sections={sections}
        currentTab={currentTab}
        showPrice={isPriceVisible}
        proposalStatus={projectStatus}
        isEditContract={props.isEditContract}
        canShowTaxesFromSettings={props.canShowTaxesFromSettings}
        contractDate={props.contractDate}
      />
      {(projectStatus === 1 || isTabContract) && isPriceVisible && (
        <TabPaymentDescription
          openModalPayment={openModalPayment}
          proposalStatus={projectStatus}
          paymentStatus={currentTab.payment_status}
          isPaymentModuleStatusChanging={props.isPaymentModuleStatusChanging}
          payments={payments}
          isTabContract={isTabContract}
          showPrice={isPriceVisible}
        />
      )}

      {paymentModal && (
        <PaymentSchedule
          isOpen
          openCloseModalPayment={() => setPaymentModal(false)}
          close={() => setPaymentModal(false)}
          tabId={currentTab.id}
          entityType={1}
          totalToPay={projectTotal}
          entityId={projectId}
          payments={payments}
          changePaymentStatus={(payment_status: number) =>
            dispatch(
              updateProjectTabField({
                payment_status,
              }),
            )
          }
          hasActiveMerchant={hasMerchant}
          proposalStatus={projectStatus}
        />
      )}
    </div>
  )
}

export default TabsContent
