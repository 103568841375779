import { TextField } from 'components/UIKit'
import EditableBlock from 'containers/MainContent/Orcatec/components/UI/EditableBlock'
import { ProjectSettings } from 'features/Project/types'
import { formatStringToNumber } from 'helpers/numbers'
import { useState } from 'react'
import NumberFormat from 'react-number-format'

interface Props {
  hasPermission: boolean
  value: number
  disabled: boolean
  onChange: (field: Partial<ProjectSettings>) => void
}

const PaymentProcessingFee = ({
  hasPermission,
  value,
  disabled,
  onChange,
}: Props) => {
  const [fee, setFee] = useState(value)

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFee(e.target.value)
  }

  const handleFeeSave = () => {
    onChange({
      payment_processing_fee: fee || 0,
    })
  }

  const handleFeeBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) setFee(0)
  }

  return (
    <EditableBlock
      value={`${fee} %`}
      onSave={handleFeeSave}
      disabled={disabled || !hasPermission}
      disabledText={
        !hasPermission
          ? 'You do not have permissions'
          : 'Can be changed if project is in Estimate status or Edit Contract mode'
      }
      onCancel={() => setFee(value)}
    >
      <NumberFormat
        value={fee}
        thousandSeparator
        suffix='%'
        customInput={TextField}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleValueChange({
            target: {
              name: 'amount',
              value: formatStringToNumber(e),
            },
          })
        }
        onBlur={handleFeeBlur}
        maxLength={6}
      />
    </EditableBlock>
  )
}

export default PaymentProcessingFee
