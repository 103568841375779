import styled from 'styled-components'

export const Table = styled.div``

export const TableHeading = styled.p`
  display: grid;
  grid-template-columns: 30px 1fr 45px 40px 95px 95px 95px 50px 50px 100px 55px;
  gap: 5px;
  background-color: #fafafa;
  font-weight: 500;
  padding: 8px 0;
  color: gray;

  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-right: 1px solid #ededed;
    padding: 0 3px;
  }
`
export const TableContent = styled.p``

// export const TableRow = styled.div`
//   display: grid;
//   grid-template-columns: 30px 1fr 45px 40px 85px 85px 85px 50px 50px 100px 55px;
//   gap: 5px;
//   border-bottom: 1px solid #fafafa;
//   padding: 5px 0;
//   position: relative;
// `

export const Wrapper = styled.div`
  table thead tr {
    th {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .v-align {
    vertical-align: baseline;
  }
`
export const SemiBold = styled.span`
  font-weight: 500;
`

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 25px;
  gap: 5px;
  font-size: 16px;
  margin-top: 5px;

  & > * {
    cursor: pointer;
  }
`
