import React, { useMemo, useState } from 'react'
import { Checkbox, Tooltip, Input } from 'antd'
import './style.scss'
import TableAction from 'containers/MainContent/Orcatec/components/TableActionsNew/index.js'
import Rating from 'containers/MainContent/Orcatec/Clients/components/Rating'
import FeedbackModal from 'containers/MainContent/Orcatec/Clients/components/Feedback/index.js'
import * as actions from 'store/Orcatec/actions'
import { useDispatch } from 'react-redux'
import {
  getClientFeedback,
  updateClientFeedback,
  deleteClientFeedback,
  createClientFeedback,
} from 'api/ClientFeedbacks'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import Joyride, { STATUS } from 'react-joyride'

const Row = ({ children, className }) => (
  <div className={`proposal-table__row ${className ? className : ''}`}>
    {children}
  </div>
)

const Col = ({ children, classes = [] }) => {
  const defaultClass = 'proposal-table__column'
  const classesList = classes.reduce(
    (acc, curr) => acc + ' ' + curr,
    defaultClass,
  )
  return <div className={classesList}>{children}</div>
}

const mapObj = {
  ies: 'y',
  s: '',
  Other: '',
}

const Separator = ({ title, type, onClick }) => (
  <Row
    className={`proposal-table__row--${type} proposal-table__row--${type}-title`}
  >
    <Col>
      {title}
      {(type === 'second' || type === 'third' || title !== 'Organizations') && (
        <MainButton
          title={`Add ${title.replace(
            /ies$|s$|Other/,
            matched => mapObj[matched],
          )}`}
          onClick={onClick}
        />
      )}
    </Col>
    {/* <Col>
      </Col> */}
  </Row>
)

const Underline = ({ type }) => (
  <Row
    className={`proposal-table__row--${type} proposal-table__row--${type} proposal-table__row--${type}-row`}
  >
    <Col classes={['proposal-table__column-underline']}></Col>
    <Col classes={['proposal-table__column-underline']}></Col>
    <Col classes={['proposal-table__column-underline']}></Col>
    <Col classes={['proposal-table__column-underline']}></Col>
    <Col classes={['proposal-table__column-underline']}></Col>
    <Col classes={['proposal-table__column-underline']}></Col>
    <Col classes={['proposal-table__column-underline']}></Col>
  </Row>
)

const ClientRating = ({ item, showFeedbacksModal }) => {
  return typeof item.vote !== 'undefined' ? (
    <div className='proposal-table__rating-container'>
      <Tooltip title={`Rating: ${item.vote ? item.vote : 0}`}>
        <div
          className='proposal-table__rating'
          onClick={() => showFeedbacksModal(item)}
        >
          <Rating
            size='small'
            value={item.vote ? item.vote : 0}
            readOnly
            precision={0.5}
          />
          {!!item.feedBackList && item.feedBackList?.length > 0 && (
            <p className='rating-count'> ({item.feedBackList?.length})</p>
          )}
        </div>
      </Tooltip>
    </div>
  ) : null
}

export const Table = ({
  data,
  setTableData,
  handleTableItemDelete,
  handleOpenItem,
  rowKeys: { titleRow, firstRow, secondRow, thirdRow },
  tableType,
  freezed,
  updateTableData,
  onOpenForm,
  isHaveChange,
}) => {
  const dispatch = useDispatch()

  const [clientId, setClientId] = useState(null)
  const [clientFeedbacks, setClientFeedbacks] = useState(null)
  const [clientType, setClientType] = useState(null)
  const [toggleToShow, setToggleToShow] = useState(false)
  const getClient =
    clientType === 1
      ? actions.organizations.getOrganizationById
      : actions.client.getClientById
  // const [prop, setProp] = useState([''])
  // const [client, setClient] = useState([''])
  const transformType = (typeValue, type) => {
    if (type === 'properties') {
      return (
        <Tooltip title={typeValue === 1 ? 'Commercial' : 'Residential'}>
          <p>{typeValue === 1 ? 'Com' : 'Res'}</p>
        </Tooltip>
      )
    } else
      return (
        <Tooltip title={typeValue === 1 ? 'Organization' : 'Individual'}>
          <p>{typeValue === 1 ? 'Org' : 'Ind'}</p>
        </Tooltip>
      )
  }
  // const transformType = (typeValue, type) => {
  //   if (type === 'properties') {
  //     return typeValue === 1 ? 'Commercial' : 'Residential'
  //   } else return typeValue === 1 ? 'Organization' : 'Individual'
  // }
  const toggleHeandle = () => {
    setToggleToShow(prev => {
      if (prev === true) {
        window.scrollTo({ top: 300, behavior: 'smooth' })
      }
      return !prev
    })
  }
  const transformTextForButton = word => {
    if (word === 'properties') return 'property'
    if (word === 'organizations') return 'organization'
    if (word === 'clients') return 'client'
  }

  const showFeedbacksModal = client => {
    setClientFeedbacks(client.feedBackList)
    setClientId(client.id)
    setClientType(client.type)
  }

  const onCreateFeedback = (id, data) => {
    const requestData = {
      entity_id: data.id,
      comment: data.comment,
      vote: data.vote,
      entity_type: clientType,
    }

    return createClientFeedback(id, requestData).then(() =>
      dispatch(getClient(id)).then(res => setClientFeedbacks(res.feedbacks)),
    )
  }

  const onDeleteFeedback = (id, userId) => {
    return deleteClientFeedback(id).then(() =>
      dispatch(getClient(userId)).then(res =>
        setClientFeedbacks(res.feedbacks),
      ),
    )
  }

  const onUpdateFeedback = (id, data, userId) => {
    return updateClientFeedback(id, data).then(() =>
      dispatch(getClient(userId)).then(res =>
        setClientFeedbacks(res.feedbacks),
      ),
    )
  }

  const renderTitleRow = (
    <Row className='proposal-table__row-title'>
      <Col>{titleRow[0]}</Col>
      <Col>{titleRow[1]}</Col>
      <Col>{titleRow[2]}</Col>
      <Col>{titleRow[3]}</Col>
      <Col>{titleRow[4]}</Col>
      <Col>{titleRow[5]}</Col>
      <Col>{titleRow[6]}</Col>
    </Row>
  )

  const renderFirstRow = (first, firstIdx) => {
    return (
      <Row className='proposal-table__row--first'>
        <Col>
          <div className='proposal-table__column-check'>
            {/* <Checkbox defaultChecked={true} disabled className='proposal-table__column-check-checkbox' /> */}
            <span
              className='proposal-table__column-check-link'
              onClick={() => {
                if (first.property_relation_id)
                  onOpenForm('Properties', first.id, first.type)
                else if (first.type === 1) {
                  onOpenForm(
                    'Organizations',
                    first.id,
                    first.type,
                    first.deleted_at,
                  )
                } else {
                  onOpenForm('Clients', first.id, first.type, first.deleted_at)
                }
              }}
            >
              {/* {first[firstRow[0]]} */}
              {first?.address
                ? first.full_address || first.address
                : first[firstRow[0]]}
            </span>
            <ClientRating
              item={first}
              showFeedbacksModal={showFeedbacksModal}
            />
          </div>
        </Col>
        <Col>{transformType(first[firstRow[1].key], firstRow[1].type)}</Col>
        <Col>{first[firstRow[2]]}</Col>
        <Col>
          <div className='proposal-table__column-qty'>
            {first[firstRow[3]]?.length ? first[firstRow[3]]?.length - 1 : 0}
          </div>
        </Col>
        <Col>{first[firstRow[4]]}</Col>
        <Col>
          <Input
            value={first.proposal_relation}
            variant='outlined'
            size='small'
            onChange={e =>
              setTableData(
                'input',
                null,
                firstIdx,
                null,
                null,
                null,
                e.target.value,
              )
            }
          />
        </Col>
        <Col>
          <div className='proposal-table__column-qty'>
            {first[firstRow[6]] ? first[firstRow[6]]?.length : 0}
          </div>
        </Col>
        <Col>
          <div className='proposal-table__column-delete' role='button'>
            {freezed && first.freezed ? null : (
              <TableAction
                todos={['detach']}
                callbacks={[
                  () => handleTableItemDelete(first.id, first.type, first),
                ]}
                tooltips={[
                  `Detach ${transformTextForButton(firstRow[1].type)}`,
                ]}
                popConfirms={[
                  `Are you sure you want to detach this ${transformTextForButton(
                    firstRow[1].type,
                  )}?`,
                ]}
                isConfirmationNeeded={true}
                showDelete={true}
              />
            )}
          </div>
        </Col>
      </Row>
    )
  }

  const renderSecondRow = (second, title, idx, firstIdx, length) => {
    return (
      <React.Fragment key={idx}>
        <Row className='proposal-table__row--second'>
          <Col>
            <div className='proposal-table__column-check'>
              <Checkbox
                checked={second.checkbox}
                onChange={() =>
                  setTableData('check', idx, firstIdx, title.toLowerCase())
                }
                className='proposal-table__column-check-checkbox'
                disabled={freezed && second.freezed}
              />
              <span
                //className={title !== 'Contacts' ? 'proposal-table__column-check-link' : ''}
                className='proposal-table__column-check-link'
                onClick={() => {
                  // if (title === 'Organizations' || title === 'Clients' || title === 'Contacts')
                  //   onOpenForm('Clients', second.id, second.type, second.deleted_at)
                  // if (title === 'Properties') onOpenForm('Properties', second.id, second.type)

                  if (title === 'Properties') {
                    onOpenForm('Properties', second.id, second.type)
                  } else if (second.type === 1) {
                    onOpenForm(
                      'Organizations',
                      second.id,
                      second.type,
                      second.deleted_at,
                    )
                  } else {
                    onOpenForm(
                      'Clients',
                      second.id,
                      second.type,
                      second.deleted_at,
                    )
                  }
                }}
              >
                {title === 'Contacts' ||
                title === 'Organizations' ||
                title === 'Clients'
                  ? second[secondRow[0].name]
                  : second[secondRow[0].address]}
                {/* {second.unit ? `, ${second.unit}` : ''} */}
              </span>
              <ClientRating
                item={second}
                showFeedbacksModal={showFeedbacksModal}
              />
            </div>
          </Col>
          <Col>{transformType(second[secondRow[1]], title.toLowerCase())}</Col>
          <Col>{second[secondRow[2]]}</Col>
          <Col>
            <div className='proposal-table__column-qty'>
              {second[secondRow[3]]?.length
                ? second[secondRow[3]]?.length - 1
                : 0}
            </div>
          </Col>
          <Col>{second[secondRow[4]]}</Col>
          <Col>
            <Input
              variant='outlined'
              size='small'
              value={second.proposal_relation}
              disabled={!second.checkbox}
              onChange={e =>
                setTableData(
                  'input',
                  idx,
                  firstIdx,
                  title.toLowerCase(),
                  null,
                  null,
                  e.target.value,
                )
              }
            />
          </Col>
          <Col>
            <div className='proposal-table__column-qty'>
              {second[secondRow[6]] ? second[secondRow[6]]?.length : 0}
            </div>
          </Col>
          <Col>
            <div className='proposal-table__column-delete'></div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  const renderThirdRow = (
    third,
    title,
    idx,
    firstIdx,
    secondIdx,
    parentName,
  ) => {
    return (
      <React.Fragment key={idx}>
        <Row className='proposal-table__row--third'>
          <Col>
            <div className='proposal-table__column-check'>
              <Checkbox
                checked={third.checkbox}
                onChange={() =>
                  setTableData(
                    'check',
                    idx,
                    firstIdx,
                    title.toLowerCase(),
                    secondIdx,
                    parentName,
                  )
                }
                className='proposal-table__column-check-checkbox'
                disabled={freezed && third.freezed}
              />
              <span
                className='proposal-table__column-check-link'
                onClick={() => {
                  if (title === 'Properties') {
                    onOpenForm('Properties', third.id, third.type)
                  } else if (third.type === 1) {
                    onOpenForm(
                      'Organizations',
                      third.id,
                      third.type,
                      third.deleted_at,
                    )
                  } else {
                    onOpenForm(
                      'Clients',
                      third.id,
                      third.type,
                      third.deleted_at,
                    )
                  }
                  // if (title === 'Organizations' || title === 'Clients' || title === 'Contacts')
                }}
              >
                {title === 'Properties'
                  ? third[thirdRow[0].address]
                  : third[thirdRow[0].name]}
                {/* {third.unit ? `, ${third.unit}` : ''} */}
              </span>
              <ClientRating
                item={third}
                showFeedbacksModal={showFeedbacksModal}
              />
            </div>
          </Col>
          <Col>{transformType(third[thirdRow[1]], title.toLowerCase())}</Col>
          <Col>{third[thirdRow[2]]}</Col>
          <Col>
            <div className='proposal-table__column-qty'>
              {third[thirdRow[3]]?.length ? third[thirdRow[3]]?.length - 1 : 0}
            </div>
          </Col>
          <Col>{third[thirdRow[4]]}</Col>
          <Col>
            <Input
              variant='outlined'
              size='small'
              value={third.proposal_relation}
              disabled={!third.checkbox}
              onChange={e =>
                setTableData(
                  'input',
                  idx,
                  firstIdx,
                  title.toLowerCase(),
                  secondIdx,
                  parentName,
                  e.target.value,
                )
              }
            />
          </Col>
          <Col>
            <div className='proposal-table__column-qty'>
              {third[thirdRow[6]] ? third[thirdRow[6]]?.length : 0}
            </div>
          </Col>
          <Col></Col>
        </Row>
      </React.Fragment>
    )
  }

  const renderSeparator = (title, type, parent) => (
    <Separator
      title={title}
      type={type}
      onClick={() => onOpenForm(title.replace(/Other /, ''), null, parent)}
    />
  )

  const renderUnderline = (type, length, idx, contentLength) => {
    if (length > 1 && idx + 1 < length && contentLength === 0) {
      return <Underline type={type} />
    }
  }

  const isNeedShoweMoreBtn = useMemo(() => {
    let counter = 0
    if (data) {
      data.forEach(el => {
        counter += 1
        if (el.properties?.length) {
          el.properties.forEach(e => {
            counter += 1
            if (e.clients && e.clients.length !== 0) {
              counter += e.clients.length
            }
          })
        }

        if (el.clients?.length) {
          counter += el.clients.length
          el.clients.forEach(elem => {
            counter += elem.properties.length
          })
        }

        if (el.organizations?.length) {
          counter += el.organizations.length
          el.organizations.forEach(elem => {
            counter += elem.properties.length
          })
        }
      })
    }

    return counter > 10
  }, [data])

  const renderTable = data.map((first, firstIdx) => (
    <React.Fragment key={firstIdx}>
      {renderFirstRow(first, firstIdx)}

      {first.properties && (
        <>
          {renderSeparator('Properties', 'second', first)}
          {first.properties.map((second, secondIdx) => (
            <React.Fragment key={secondIdx}>
              {renderSecondRow(
                second,
                'Properties',
                secondIdx,
                firstIdx,
                first.properties?.length,
              )}
              {renderUnderline(
                'second',
                first.properties?.length,
                secondIdx,
                0,
              )}
              {second?.clients && (
                <>
                  {renderSeparator('Other Clients', 'third', second)}
                  {second.clients.map((third, idx) => (
                    <React.Fragment key={idx}>
                      {renderThirdRow(
                        third,
                        'Clients',
                        idx,
                        firstIdx,
                        secondIdx,
                        'properties',
                      )}
                      {renderUnderline('third', second.clients?.length, idx, 0)}
                    </React.Fragment>
                  ))}
                </>
              )}
              {second?.organizations?.length > 0 && (
                <>
                  {/* {renderSeparator('Organizations', 'third', second)} */}
                  {second.organizations.map((third, idx) => (
                    <React.Fragment key={idx}>
                      {renderThirdRow(
                        third,
                        'Organizations',
                        idx,
                        firstIdx,
                        secondIdx,
                        'properties',
                      )}
                      {renderUnderline(
                        'third',
                        second.organizations?.length,
                        idx,
                        0,
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
            </React.Fragment>
          ))}
        </>
      )}

      {first?.contacts && (
        <>
          {renderSeparator('Contacts', 'second', first)}
          {first.contacts.map((second, secondIdx) => (
            <React.Fragment key={secondIdx}>
              {renderSecondRow(
                second,
                'Contacts',
                secondIdx,
                firstIdx,
                first.contacts?.length,
              )}
              {renderUnderline('second', first.contacts?.length, secondIdx, 0)}
            </React.Fragment>
          ))}
        </>
      )}

      {first.clients && (
        <>
          {renderSeparator('Clients', 'second', first)}
          {first?.clients?.map((second, secondIdx) => (
            <React.Fragment key={secondIdx}>
              {renderSecondRow(
                second,
                'Clients',
                secondIdx,
                firstIdx,
                first.clients?.length,
              )}
              {renderUnderline(
                'second',
                first.clients?.length,
                secondIdx,
                second.properties?.length || second.contacts?.length,
              )}
              {second.properties && (
                <>
                  {renderSeparator('Other Properties', 'third', second)}
                  {second.properties.map((third, idx) => (
                    <React.Fragment key={idx}>
                      {renderThirdRow(
                        third,
                        'Properties',
                        idx,
                        firstIdx,
                        secondIdx,
                        'clients',
                      )}
                      {renderUnderline(
                        'third',
                        second.properties?.length,
                        idx,
                        0,
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
              {second.contacts && (
                <>
                  {renderSeparator('Contacts', 'third', second)}
                  {second.contacts.map((third, idx) => (
                    <React.Fragment key={idx}>
                      {renderThirdRow(
                        third,
                        'Contacts',
                        idx,
                        firstIdx,
                        secondIdx,
                        'clients',
                      )}
                      {renderUnderline(
                        'third',
                        second.contacts?.length,
                        idx,
                        0,
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
            </React.Fragment>
          ))}
          {first.organizations.map((second, secondIdx) => (
            <React.Fragment key={secondIdx}>
              {renderSecondRow(
                second,
                'Organizations',
                secondIdx,
                firstIdx,
                first.organizations?.length,
              )}
              {renderUnderline(
                'second',
                first.organizations?.length,
                secondIdx,
                second.properties?.length || second.contacts?.length,
              )}
              {second.properties && (
                <>
                  {renderSeparator('Other Properties', 'third', second)}
                  {second.properties.map((third, idx) => (
                    <React.Fragment key={idx}>
                      {renderThirdRow(
                        third,
                        'Properties',
                        idx,
                        firstIdx,
                        secondIdx,
                        'organizations',
                      )}
                      {renderUnderline(
                        'third',
                        second.properties?.length,
                        idx,
                        0,
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
              {second.contacts && (
                <>
                  {renderSeparator('Contacts', 'third', second)}
                  {second.contacts.map((third, idx) => (
                    <React.Fragment key={idx}>
                      {renderThirdRow(
                        third,
                        'Contacts',
                        idx,
                        firstIdx,
                        secondIdx,
                        'organizations',
                      )}
                      {renderUnderline(
                        'third',
                        second.contacts?.length,
                        idx,
                        0,
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
            </React.Fragment>
          ))}
        </>
      )}

      {first.organizations && tableType === 'clients' && (
        <>
          {/* {renderSeparator('Contacts', 'second', first)} */}
          {first.organizations.map((second, secondIdx) => (
            <React.Fragment key={secondIdx}>
              {renderSecondRow(
                second,
                'Organizations',
                secondIdx,
                firstIdx,
                first.organizations?.length,
              )}
              {renderUnderline(
                'second',
                first.organizations?.length,
                secondIdx,
                second.properties?.length || second.contacts?.length,
              )}
              {second.properties && (
                <>
                  {renderSeparator('Properties', 'third', second)}
                  {second.properties.map((third, idx) => (
                    <React.Fragment key={idx}>
                      {renderThirdRow(
                        third,
                        'Properties',
                        idx,
                        firstIdx,
                        secondIdx,
                        'organizations',
                      )}
                      {renderUnderline(
                        'third',
                        second.properties?.length,
                        idx,
                        0,
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
              {second.contacts && (
                <>
                  {renderSeparator('Contacts', 'third', second)}
                  {second.contacts.map((third, idx) => (
                    <React.Fragment key={idx}>
                      {renderThirdRow(
                        third,
                        'Contacts',
                        idx,
                        firstIdx,
                        secondIdx,
                        'organizations',
                      )}
                      {renderUnderline(
                        'third',
                        second.contacts?.length,
                        idx,
                        0,
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </React.Fragment>
  ))

  const steps = [
    {
      target: '.proposal-contact-info',
      content:
        'Here you can add or attach existing properties, clients and organizations to the project',
      placement: 'top',
      // disableBeacon: true,
      title: 'Project Contacts',
    },
    {
      target: '.proposal-table__row--second .proposal-table__column-check',
      content:
        'Choose the items you want to attach with a checkbox and click "Save" button',
      placement: 'top',
      disableBeacon: true,
      title: 'Project Contacts',
    },
    // {
    //   target: '.proposal-contact-section__buttons ',
    //   content: 'And click "Save" button',
    //   placement: 'top',
    //   disableBeacon: true,
    //   title: 'Project Contacts',
    // },
  ]

  return (
    <>
      <Joyride
        steps={steps}
        continuous
        disableScrolling
        hideCloseButton
        run={!JSON.parse(localStorage.getItem('tutorialPassed'))}
        callback={({ status }) => {
          if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            localStorage.setItem('tutorialPassed', 'true')
          }
        }}
        styles={{
          options: {
            primaryColor: '#4c53ef',
          },
        }}
        locale={{
          last: 'Finish',
        }}
      />

      <div
        className={`proposal-table ${
          toggleToShow ? 'proposal-table__active' : 'proposal-table__noactive'
        } 
          ${!isNeedShoweMoreBtn ? 'proposal-table__isNotNeeded ' : ''}
        ${
          data &&
          data.filter(el => el.freezed)?.length === data?.length &&
          freezed
            ? 'proposal-table--no-icon'
            : ''
        }`}
      >
        {renderTitleRow}
        {renderTable}
      </div>
      {isNeedShoweMoreBtn && (
        <div
          className={`button-load-wrapper ${
            toggleToShow ? 'button-load-wrapper__active' : ''
          }`}
        >
          <button
            onClick={toggleHeandle}
            className='load-more-btn btn btn-primary my-button-class my-button-class--spinner'
          >
            {!toggleToShow ? 'Show more' : 'Hide'}
          </button>
        </div>
      )}

      {clientFeedbacks && (
        <FeedbackModal
          feedback={clientFeedbacks}
          modal={clientId ? true : false}
          clientId={clientId}
          close={() => {
            setClientId(null)
            updateTableData()
          }}
          onSaveFeedback={onUpdateFeedback}
          onCreateFeedback={onCreateFeedback}
          onDeleteFeedback={onDeleteFeedback}
        />
      )}
    </>
  )
}
