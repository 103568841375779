import * as yup from 'yup'

export const schemaACH = yup.object({
  routingNumber: yup
    .string()
    .length(9, 'Must be 9 digits long')
    .required('Required field'),
  repeatRoutingNumber: yup
    .string()
    .length(9, 'Must be 9 digits long')
    .required('Required field')
    .oneOf([yup.ref('routingNumber')], 'Numbers do not match'),
  accountNumber: yup
    .string()
    .min(3, 'Must be at least 3 chars')
    .required('Required field'),
  repeatAccountNumber: yup
    .string()
    .min(3, 'Must be at least 3 chars')
    .required('Required field')
    .oneOf([yup.ref('accountNumber')], 'Numbers do not match'),
})
