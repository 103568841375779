import { notification } from 'antd'

import {
  fetch,
  ticket,
  assignAssistant,
  resolveTicket,
  replyMessage,
  deleteMessage,
  updateMessage,
  unseenTicketsCount,
} from '../api/supportApi'
import { RESOLVED_STATUS } from '../../../helpers/supportTickets'

import { ActionType } from '../constants/supportConstants'

const {
  SUPPORT_TICKETS_LIST_INIT,
  SUPPORT_TICKETS_LIST_SUCCESS,
  SUPPORT_TICKETS_LIST_FAILURE,
  CLEAR_TICKET,
  CLEAR_MESSAGE,
  SHOW_TICKET_INIT,
  SHOW_TICKET_SUCCESS,
  SHOW_TICKET_FAILURE,
  TICKET_LOADING_DATA,
  TICKET_LOADED_DATA,
  ASSIGNED_TICKET_ASSISTANT,
  NEW_TICKET_MESSAGE,
  TICKET_MESSAGE_DELETED,
  TICKET_MESSAGE_UPDATED,
  TICKET_CHANGED_STATUS,
  REPLY_MESSAGE_INIT,
  REPLY_MESSAGE_SUCCESS,
  REPLY_MESSAGE_FAILURE,
  UPDATE_MESSAGE_INIT,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAILURE,
  SET_UNSEEN_TICKETS_COUNT,
  INCREMENT_UNSEEN_TICKETS_COUNT,
  DECREMENT_UNSEEN_TICKETS_COUNT,
} = ActionType

const openNotificationWithIcon = (type, data) => {
  notification[type]({
    ...data,
  })
}

export const list = params => dispatch => {
  dispatch({
    type: SUPPORT_TICKETS_LIST_INIT,
  })

  fetch(params)
    .then(response => {
      dispatch({
        type: SUPPORT_TICKETS_LIST_SUCCESS,
        payload: response.data,
      })
    })
    .catch(err => {
      dispatch({
        type: SUPPORT_TICKETS_LIST_FAILURE,
        payload: err.response,
      })
    })
}

export const clearTicket = () => dispatch => {
  dispatch({
    type: CLEAR_TICKET,
  })
}

export const clearMessage = () => dispatch => {
  dispatch({
    type: CLEAR_MESSAGE,
  })
}

export const show = id => dispatch => {
  dispatch({
    type: SHOW_TICKET_INIT,
  })

  return ticket(id)
    .then(response => {
      dispatch({
        type: SHOW_TICKET_SUCCESS,
        payload: response.data,
      })
    })
    .catch(err => {
      dispatch({
        type: SHOW_TICKET_FAILURE,
        payload: err.response,
      })
    })
}

export const assign = (ticketId, assistant) => dispatch => {
  dispatch({
    type: TICKET_LOADING_DATA,
  })

  assignAssistant(ticketId, assistant.id)
    .then(() => {
      dispatch({
        type: ASSIGNED_TICKET_ASSISTANT,
        payload: assistant,
      })
    })
    .finally(() =>
      dispatch({
        type: TICKET_LOADED_DATA,
      }),
    )
}

export const resolve = id => dispatch => {
  dispatch({
    type: TICKET_LOADING_DATA,
  })

  resolveTicket(id)
    .then(() => {
      dispatch({
        type: TICKET_CHANGED_STATUS,
        payload: RESOLVED_STATUS,
      })
    })
    .finally(() =>
      dispatch({
        type: TICKET_LOADED_DATA,
      }),
    )
}

export const reply = (id, data) => dispatch => {
  dispatch({
    type: REPLY_MESSAGE_INIT,
  })

  return replyMessage(id, data)
    .then(response => {
      const payload = response.data

      dispatch({
        type: REPLY_MESSAGE_SUCCESS,
        payload,
      })
      dispatch({
        type: NEW_TICKET_MESSAGE,
        payload,
      })
    })
    .catch(err => {
      dispatch({
        type: REPLY_MESSAGE_FAILURE,
        payload: err.response,
      })

      return new Promise((resolve, reject) => reject(err))
    })
}

export const deleteTicketMessage = (ticketId, messageId) => dispatch => {
  deleteMessage(ticketId, messageId).then(() => {
    dispatch({
      type: TICKET_MESSAGE_DELETED,
      payload: messageId,
    })

    openNotificationWithIcon('success', {
      message: 'Message deleted',
      key: 'property-save-in-process',
      duration: 4,
    })
  })
}

export const editMessage = (ticketId, messageId, data) => dispatch => {
  dispatch({
    type: UPDATE_MESSAGE_INIT,
  })

  return updateMessage(ticketId, messageId, data)
    .then(response => {
      const payload = response.data

      dispatch({
        type: UPDATE_MESSAGE_SUCCESS,
        payload,
      })
      dispatch({
        type: TICKET_MESSAGE_UPDATED,
        payload,
      })
    })
    .catch(err => {
      dispatch({
        type: UPDATE_MESSAGE_FAILURE,
        payload: err.response,
      })

      return new Promise((resolve, reject) => reject(err))
    })
}

export const fetchUnseenTickets = () => dispatch => {
  return unseenTicketsCount().then(response => {
    dispatch({
      type: SET_UNSEEN_TICKETS_COUNT,
      payload: response.data.unseen_count,
    })
  })
}

export const incrementUnseenTickets = () => dispatch => {
  dispatch({
    type: INCREMENT_UNSEEN_TICKETS_COUNT,
  })
}

export const decrementUnseenTickets = () => dispatch => {
  dispatch({
    type: DECREMENT_UNSEEN_TICKETS_COUNT,
  })
}
