export const COMPANY_SET_ITEM = 'COMPANY_SET_ITEM'
export const COMPANY_GET_ITEM = 'COMPANY_GET_ITEM'
export const COMPANY_SET_DICTIONARY_ITEM = 'COMPANY_SET_DICTIONARY_ITEM'
export const COMPANY_UPDATE_ITEM = 'COMPANY_UPDATE_ITEM'
export const COMPANY_UPDATE_APPOINTMENT_SETTINGS =
  'COMPANY_UPDATE_APPOINTMENT_SETTINGS'
export const COMPANY_FETCH_ITEM = 'COMPANY_FETCH_ITEM'
export const ENABLE_OR_DISABLE_PUBLIC_APPOINTMENTS =
  'ENABLE_OR_DISABLE_PUBLIC_APPOINTMENTS'
export const UPDATE_WORK_TIME = 'UPDATE_WORK_TIME'
// type and source
export const SET_COMPANY_SOURCE = 'SET_COMPANY_SOURCE'
export const GET_COMPANY_SOURCE = 'GET_COMPANY_SOURCE'
export const UPDATE_COMPANY_SOURCE = 'UPDATE_COMPANY_SOURCE'
export const DELETE_COMPANY_SOURCE = 'DELETE_COMPANY_SOURCE'

export const SET_COMPANY_TYPES = 'SET_COMPANY_TYPES'
export const GET_COMPANY_TYPES = 'GET_COMPANY_TYPES'
export const UPDATE_COMPANY_TYPES = 'UPDATE_COMPANY_TYPES'
export const DELETE_COMPANY_TYPES = 'DELETE_COMPANY_TYPES'

export const GET_COMPANY_TECHNICIANS = 'GET_COMPANY_TECHNICIANS'

export const DELETE_COMPANY_BRAND = 'DELETE_COMPANY_BRAND'
