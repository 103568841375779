import { IHeaderType } from 'layouts/MainLayout/Header/components/types'

export enum UserRole {
  OWNER = 1,
  ADMIN = 2,
  DISPATCHER = 3,
  TECHNICIAN = 4,
  WORKER = 5,
  ACCOUNTANT = 6,
  DEFAULT_SUPER_ADMIN = 7,
  SUPER_ADMIN = 8,
  ACCOUNT_MANAGER = 11,
}
export interface User {
  description: string
  background_color: string | null
  commission_type: number
  commission_percent: number
  email: string
  full_name: string
  id: number
  info: Info
  invite_email?: null
  last_login?: string
  login_from_admin?: boolean
  labor_rate: string
  password_changed_at?: string
  permissions: { [key: string]: boolean }
  phone: string
  project_status_change: boolean
  restrict_contract_access: boolean
  role_id: number
  status: UserStatus
  title: string
  token_confirmation?: null
  ui_settings?: UISettings
  useAsTech: boolean
  use_clock_in_with_gps: boolean
  can_open_proposal_insights: boolean
  has_access_to_proposal_insights: boolean
  proposal_insights_open_level: number | null
  proposal_insights_action_level: number | null
  group_id: number | null
}

export enum UserStatus {
  Inactive,
  Active,
  Pending,
}

export interface Info {
  use_office_address: boolean
  number_prefix: string
  profile_picture: string
  address: IAddress
  change_date_status?: string
  coordination: string[]
  profile_picture_id?: number
}
export interface IAddress {
  address: string
  city: string
  lat: number | string
  lng: number | string
  state: string
  unit: string | null
  zip: string
}

export enum CommisionType {
  'Potential Profit' = 1,
  'Gross Sale',
  'Current Profit',
}

export interface UISettings {
  id: number
  user_id: number
  is_vertical_sidebar: boolean
  header_view: IHeaderType
  created_at: string
  updated_at: string
}
