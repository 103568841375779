import axiosOrcatec, { CancelToken } from './axiosInstance'

export let cancelUploadRequest = () => {}

/* const onUploadProgress = loadCallback => progressEvent => {
  const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
  loadCallback({ percentCompleted, loaded: progressEvent.loaded, total: progressEvent.total })
} */
/**
 * Upload media.
 *
 * @return {*}
 */
export const upload = (data, onUploadProgress) => {
  const config = {
    cancelToken: new CancelToken(c => {
      cancelUploadRequest = c
    }),
    onUploadProgress /* : onUploadProgress(loadCallback), */,
  }
  return axiosOrcatec
    .post('media/upload', data, config)
    .then(response => response.data)
}

export const publicUpload = (data, eventHash, onUploadProgress) => {
  const config = {
    cancelToken: new CancelToken(c => {
      cancelUploadRequest = c
    }),
    onUploadProgress,
  }
  return axiosOrcatec
    .post(`/public/events/${eventHash}/media/upload`, data, config)
    .then(response => response.data)
}

/**
 * Upload by link.
 *
 * @param link
 * @return {*}
 */
export const uploadByLink = (link /* , loadCallback */) => {
  const config = {
    cancelToken: new CancelToken(c => {
      cancelUploadRequest = c
    }),
    // onUploadProgress: onUploadProgress(loadCallback),
  }
  return axiosOrcatec
    .post(`media/upload_by_link?link=${link}`, null, config)
    .then(response => response.data)
}

/**
 * Fetch media.
 *
 * @param id
 * @return {*}
 */
export const fetchMedia = id => {
  return axiosOrcatec.get(`media/${id}`).then(response => response.data)
}
/**
 * Fetch media.
 *
 * @param file_path
 * @return {*}
 */
export const downloadFile = (file_path, source) => {
  const config = {
    cancelToken: source && source.token,
    responseType: 'blob',
  }
  return axiosOrcatec
    .get(`media/download?filename=${file_path}`, config)
    .then(response => response.data)
}

/**
 * Delete media.
 *
 * @param id
 * @return {*}
 */
export const deleteMedia = id => {
  return axiosOrcatec.delete(`media/${id}`).then(response => response.data)
}

/**
 * Download media by link.
 *
 * @param path
 * @return {type:string, file:BinaryType}
 */
export const downloadByPablicLink = path => {
  return axiosOrcatec
    .post('/file/download', { path }, { responseType: 'blob' })
    .then(res => ({ type: res.headers?.['content-type'], file: res.data }))
}
