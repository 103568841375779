import { MatrixAddressType } from 'containers/MainContent/Orcatec/Settings/components/calendar/CalendarSettings'
import { EventColorType } from 'features/Dispatch/types'
import * as types from '../../types'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  result.map((item, index) => (item.sort_order = index))

  return result
}

export default (
  state = {
    loading: false,
    columns: [],
    calendar: {
      start_hour: 8,
      end_hour: 20,
      event_color_type: EventColorType.User,
      matrix_address_type: MatrixAddressType['ZIP code'],
      tooltip_mod: true,
      restrict_crud_source_type: false,
    },
  },
  action,
) => {
  switch (action.type) {
    case types.settings.FETCHING_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case types.settings.SETTINGS_FETCHED:
      const fetchState = action.payload
      if (fetchState.columns) {
        fetchState?.columns?.sort?.(compare)?.map((item, i) => (item.sort_order = i))
      }
      return {
        ...fetchState,
        loading: false,
      }

    case types.settings.UPDATE_GLOBAL_SETTINGS:
      return {
        ...state,
        calendar: action.payload,
        promised_time_increment: action.payload.promised_time_increment,
        industry_id: action.payload.industry_id,
      }

    case types.settings.GLOBAL_COLUMNS_ORDER_CHANGED:
      return {
        ...state,
        columns: action.payload,
      }

    case types.settings.GLOBAL_COLUMN_CREATED:
      return {
        ...state,
        columns: [...state.columns, action.payload],
      }

    case types.settings.GLOBAL_COLUMN_UPDATED:
      const newColumns = [...state.columns]
      const index = state.columns?.findIndex(column => column.id === action.payload.id)

      newColumns[index] = action.payload

      return {
        ...state,
        columns: newColumns,
      }

    case types.settings.GLOBAL_COLUMN_DELETED:
      return {
        ...state,
        columns: state.columns.filter(column => column.id !== action.payload),
      }

    case types.settings.SETTINGS_UPDATED:
      return {
        ...state,
        loading: false,
        calendar: action.payload,
      }

    case types.settings.LOCAL_UPDATE_SETTINGS_COLOR:
      let newState = [...state.columns]
      newState[action.i].background_color = action.color
      newState = {
        calendar: state.calendar,
        columns: newState,
      }
      return newState

    default:
      return state
  }
}

const compare = (a, b) => {
  if (a.sort_order < b.sort_order) {
    return -1
  }
  if (a.sort_order > b.sort_order) {
    return 1
  }
  return 0
}
