import { /* DatePicker, */ Modal, Popconfirm } from 'antd'
import { TimeOff } from 'api/TimeOff'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import CustomSwitcher from 'containers/MainContent/Orcatec/components/CustomSwitcher'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import SelectField from 'containers/MainContent/Orcatec/components/Select'
import DatePicker from 'containers/MainContent/Orcatec/components/UI/DatePicker'
import useTechnicians from 'hooks/useTechnicians'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCurrentMatrixLog } from 'features/Dispatch/dispatchSlice'
import {
  createTimeOff,
  deleteTimeOff,
  overlapsReseted,
  toggleTimeOffModal,
  updateTimeOff,
} from 'store/Orcatec/reducers/timeoff'
import { conver24to12HourFormat } from '../utils'
import { DateRow, DateSection, TimeSection, Wrapper } from './TimeOffModa.style'

const timeOptions = Array.from(Array(24).keys()).map(number => ({
  id: number,
  name: conver24to12HourFormat(number),
}))

interface Error {
  [key: string]: string
}

export const TimeOffModal = () => {
  const { item, showModal, error, overlaps } = useAppSelector(
    state => state.orcatec.timeoff,
  )
  const matrixLog = useAppSelector(selectCurrentMatrixLog)
  const dispatch = useDispatch()
  const { technicians } = useTechnicians()

  const [timeOff, setTimeOff] = useState<TimeOff>(item)
  const [localError, setLocalError] = useState<Error | null>(null)
  const [confirmModal, setConfirmModal] = useState(false)

  const getMatrixEndTime = () => {
    return matrixLog?.time_end === 24
      ? { hour: 23, minute: 59 }
      : { hour: matrixLog?.time_end, minute: 0 }
  }

  const initialItem = {
    id: 0,
    reason: '',
    note: '',
    is_vacation: false,
    start_date: moment(item.start_date)
      .set({ hour: matrixLog?.time_start, minute: 0 })
      .format('MM/DD/YY HH:mm'),
    end_date: moment(item.end_date)
      .set(getMatrixEndTime())
      .format('MM/DD/YY HH:mm'),
  }

  useEffect(() => {
    if (!showModal) {
      setTimeOff(initialItem)
    }
  }, [showModal])

  useEffect(() => {
    const timeoff = item.id ? item : initialItem
    setTimeOff(timeoff)
  }, [item, matrixLog?.time_start, matrixLog?.time_end])

  useEffect(() => {
    setLocalError(error)
  }, [error])

  useEffect(() => {
    if (overlaps) setConfirmModal(true)
  }, [overlaps])

  const onChangeSwitcher = (checked: boolean) => {
    handleChangeTimeOff({
      target: {
        name: 'is_vacation',
        value: checked,
      },
    })
  }

  const handleChangeDate = (name: string, dateString: string) => {
    handleChangeTimeOff({
      target: {
        name,
        value: dateString,
      },
    })

    if (localError?.[name]) {
      const newError = { ...localError }
      delete newError?.[name]
      setLocalError(newError)
    }
  }

  const handleChangeTime = (name: 'start_date' | 'end_date', time: number) => {
    handleChangeDate(
      name,
      moment(timeOff[name])
        .set({ hour: time, minute: 0 })
        .format('MM/DD/YY HH:mm'),
    )
  }

  const handleChangeTimeOff = (e: { target: { name: string; value: any } }) => {
    const { name, value } = e.target

    setTimeOff(prev => ({
      ...prev,
      [name]: value,
    }))

    if (localError) setLocalError(null)
  }

  const validateField = () => {
    const fields = ['reason', 'user_id']
    const errors = {}
    fields.forEach(field => {
      !timeOff[field] ? (errors[field] = 'This field is required') : null
    })

    const isValid = !Object.keys(errors)?.length

    return { errors, isValid }
  }

  const handleUpdateTimeOff = (
    e: React.MouseEvent<HTMLElement>,
    confirm?: number,
  ) => {
    const { errors, isValid } = validateField()

    if (!isValid) {
      return setLocalError(prev => ({ ...prev, ...errors }))
    }
    if (!timeOff.id) {
      // if (!timeOff.reason) setLocalError(prev => ({ ...prev, reason: 'Field is required' }))
      // if (!timeOff.user_id) setLocalError(prev => ({ ...prev, user_id: 'Field is required' }))

      return dispatch(
        createTimeOff({
          ...timeOff,
          start_date: moment(timeOff.start_date).format('MM/DD/YY HH:mm'),
          end_date: moment(timeOff.end_date).format('MM/DD/YY HH:mm'),
          confirm,
        }),
      )
    }

    dispatch(
      updateTimeOff({
        ...timeOff,
        start_date: moment(timeOff.start_date).format('MM/DD/YY HH:mm'),
        end_date: moment(timeOff.end_date).format('MM/DD/YY HH:mm'),
        confirm,
      }),
    )

    dispatch(toggleTimeOffModal('reset'))
  }

  const handleCancel = () => {
    dispatch(toggleTimeOffModal('reset'))
  }

  return (
    <>
      <Modal
        visible={showModal}
        title='Add Time Off'
        width={750}
        onCancel={handleCancel}
        onOk={handleUpdateTimeOff}
        destroyOnClose
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', gap: 10 }}>
              <MainButton type='cancel' title='Cancel' onClick={handleCancel} />

              {!!timeOff?.id && (
                <Popconfirm
                  title='Are you sure you want to delete this time off?'
                  onConfirm={() => {
                    dispatch(deleteTimeOff(timeOff.id))
                    // setTimeOff(initialItem)
                  }}
                  okText='Yes'
                  cancelText='No'
                >
                  <MainButton type='delete' title='Delete' />
                </Popconfirm>
              )}
            </div>
            <MainButton
              title='Save'
              onClick={handleUpdateTimeOff}
              style={{ marginLeft: 'auto' }}
            />
          </div>
        }
      >
        <Wrapper>
          <CustomSwitcher
            value={timeOff.is_vacation}
            onChange={onChangeSwitcher}
            leftOptionName={'Time Off'}
            rightOptionName={'Vacation'}
          />

          <TimeSection>
            <SelectField
              label='Select Employee'
              name='user_id'
              options={technicians}
              value={timeOff.user_id || ''}
              onChange={handleChangeTimeOff}
              error={localError}
            />

            <DateSection>
              <DateRow>
                <span className={'label'}>
                  Select Start Date{!timeOff.is_vacation ? '/Time' : ''}
                </span>

                <div
                  style={{
                    display: 'grid',
                    gap: 10,
                    gridTemplateColumns: '1fr 1fr',
                  }}
                >
                  <DatePicker
                    allowClear={false}
                    onChange={(date, dateString) =>
                      handleChangeDate('start_date', dateString)
                    }
                    value={moment(timeOff.start_date)}
                    disabledDate={currentDate =>
                      currentDate.isBefore(moment().startOf('day'))
                    }
                    // format='MM/DD/YYYY'
                    error={localError?.start_date}
                    className='start_date'
                  />
                  {!timeOff.is_vacation && (
                    <SelectField
                      options={timeOptions}
                      value={moment(timeOff.start_date).format('H')}
                      onChange={e =>
                        handleChangeTime('start_date', +e.target.value)
                      }
                      className='start_time'
                    />
                  )}
                </div>
              </DateRow>
              <DateRow>
                <span className={'label'}>
                  Select End Date{!timeOff.is_vacation ? '/Time' : ''}
                </span>

                <div
                  style={{
                    display: 'grid',
                    gap: 10,
                    gridTemplateColumns: '1fr 1fr',
                  }}
                >
                  <DatePicker
                    allowClear={false}
                    onChange={(date, dateString) =>
                      handleChangeDate('end_date', dateString)
                    }
                    value={moment(timeOff.end_date)}
                    disabledDate={currentDate =>
                      currentDate.isBefore(moment(timeOff.start_date))
                    }
                    error={localError?.end_date}
                    className='end_date'
                  />
                  {!timeOff.is_vacation && (
                    <SelectField
                      options={timeOptions}
                      value={moment(timeOff.end_date).format('H')}
                      onChange={e =>
                        handleChangeTime('end_date', +e.target.value)
                      }
                      className='emd_time'
                    />
                  )}
                </div>
              </DateRow>
            </DateSection>
          </TimeSection>

          <InputField
            name='reason'
            label='Reason for Time Off'
            value={timeOff.reason}
            onChange={checked => handleChangeTimeOff(checked)}
            error={localError}
            required
          />
          <InputField
            name='note'
            label='Additional Notes'
            value={timeOff.note}
            onChange={handleChangeTimeOff}
            multiline
          />
          {localError?.time && (
            <p style={{ color: 'red' }}>Error: {localError.time}</p>
          )}
        </Wrapper>

        <Modal
          visible={confirmModal}
          onCancel={() => {
            dispatch(overlapsReseted())

            setConfirmModal(false)
          }}
          onOk={e => {
            setConfirmModal(false)

            dispatch(overlapsReseted())
            handleUpdateTimeOff(e, 1)
          }}
          centered
        >
          <p style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>
            {overlaps} appointment card{overlaps !== 1 ? 's' : ''} on this user
            will be transferred to queue.
            <br />
            Are you sure you want to proceed?
          </p>
        </Modal>
      </Modal>
    </>
  )
}
