import ProjectPreviewActions from '../ProjectPreviewActions'
import { Title, Wrapper } from './Heading.styles'

export const Heading = () => {
  return (
    <Wrapper>
      <Title>Project Preview</Title>

      <ProjectPreviewActions />
    </Wrapper>
  )
}
