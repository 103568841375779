import * as types from '../types'

/**
 * Show spinner.
 *
 * @returns {{type: *}}
 */
export const showSpinner = () => {
  return {
    type: types.system.SYSTEM_SHOW_SPINNER,
  }
}

/**
 * Hide spinner.
 *
 * @returns {{type: *}}
 */
export const hideSpinner = () => {
  return {
    type: types.system.SYSTEM_HIDE_SPINNER,
  }
}

/**
 * Toggle spinner.
 *
 * @returns {{type: *}}
 */
export const toggleSpinner = () => {
  return {
    type: types.system.SYSTEM_TOGGLE_SPINNER,
  }
}
