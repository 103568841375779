import { DatePicker } from 'antd'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import moment, { Moment } from 'moment-timezone'
import { useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 10px;

  .filter-buttons {
    display: flex;
    gap: 10px;
  }

  hr {
    margin-bottom: 0;
  }
`

const DateFilter: React.FC<FilterDropdownProps> = ({
  clearFilters,
  confirm,
  setSelectedKeys,
  selectedKeys,
}) => {
  const defaultValue = selectedKeys ? moment(selectedKeys?.[0]) : null
  const [date, setDate] = useState<Moment | null>(defaultValue)
  const handleSearch = () => {
    confirm?.()
  }
  const handleReset = () => {
    setDate(null)
    clearFilters?.()
    confirm?.()
  }

  const onChange = (date: Moment | null, dateString: string) => {
    setSelectedKeys([dateString])
    setDate(date)
  }

  return (
    <Wrapper>
      <DatePicker
        onChange={onChange}
        value={date}
        allowClear={false}
        format='MM/DD/YYYY'
      />
      <hr />
      <div className='filter-buttons'>
        <MainButton title='Reset' type='cancel' onClick={handleReset} />
        <MainButton title='Search' onClick={handleSearch} />
      </div>
    </Wrapper>
  )
}

export default DateFilter
