export const ActionType = {
  GET_COMPANIES_LIST_INIT: 'GET_COMPANIES_LIST_INIT',
  GET_COMPANIES_LIST_SUCCESS: 'GET_COMPANIES_LIST_SUCCESS',
  GET_COMPANIES_LIST_FAILURE: 'GET_COMPANIES_LIST_FAILURE',
  GET_CURRENT_COMPANY_INIT: 'GET_CURRENT_COMPANY_INIT',
  GET_CURRENT_COMPANY_SUCCESS: 'GET_CURRENT_COMPANY_SUCCESS',
  GET_CURRENT_COMPANY_FAILURE: 'GET_CURRENT_COMPANY_FAILURE',
  CLEAR_STORE: 'CLEAR_STORE',
  DELETE_COMPANY_INIT: 'DELETE_COMPANY_INIT',
  DELETE_COMPANY_SUCCESS: 'DELETE_COMPANY_SUCCESS',
  DELETE_COMPANY_FAILURE: 'DELETE_COMPANY_FAILURE',
  SET_EDIT_COMPANY_FIELDS: 'SET_EDIT_COMPANY_FIELDS',
  UPDATE_COMPANY_INIT: 'UPDATE_COMPANY_INIT',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAILURE: 'UPDATE_COMPANY_FAILURE',
}
