import React from 'react'
import { Popconfirm, Tooltip } from 'antd'
// import { DeleteIcon, EditIcon, EyeIcon2, CopyIcon, FileIcon, ConfirmIcon, AddIcon } from '../Icons/CommonIcons'

import {
  DeleteIcon,
  CloseIcon,
  EditIcon,
  // EyeIcon,
  EyeIcon1,
  // EyeIcon2,
  CopyIcon,
  FileIcon,
  UploadIcon,
  UploadCloudIcon,
  ConfirmIcon,
  ConfirmIcon2,
  EmailIcon,
  AddIcon,
  CopyIconThin,
  CustomerViewIcon,
  SplitIcon,
  PaperClipIcon,
  CalculatorIcon,
  SaveIcon,
  DetachIcon,
  ActivityIcon,
  IconPrint,
  IconEye,
} from '../Icons/CommonIcons'
import { Mail } from '@material-ui/icons'
import SettingsIcon from '@material-ui/icons/Settings'
import IconTimeCards from 'layouts/MainLayout/Sidebar/icons/IconTimeCards'
import { SmallPreloader } from '../Preloader/SmallPreloader'
import { QuickbooksIcon } from 'layouts/MainLayout/Header/components/Navigation/NavigationIcons'

// const style = { cursor: 'pointer' }

export const Button = ({
  type,
  action,
  isConfirmationNeeded,
  preloader,
  tooltip,
  popConfirm,
  tooltipPlacement = 'top',
  disabled,
  getPopupContainer,
  popConfirmPosition,
}) => {
  let buttonColor = ''
  let icon = ''
  switch (type) {
    case 'delete': {
      icon = <DeleteIcon />
      break
    }
    case 'detach': {
      icon = <CloseIcon />
      break
    }
    case 'unasign': {
      icon = <DetachIcon />
      break
    }
    case 'proposal insights': {
      icon = <CalculatorIcon />
      break
    }
    case 'close': {
      icon = <CloseIcon />
      break
    }
    case 'add': {
      icon = <AddIcon />

      break
    }
    case 'edit': {
      icon = <EditIcon />
      break
    }
    case 'mail': {
      icon = <Mail />
      break
    }
    case 'mail-green': {
      icon = <Mail htmlColor='#28a745' />
      break
    }
    case 'logs': {
      icon = <ActivityIcon />
      break
    }
    case 'logs-green': {
      icon = <ActivityIcon color='#28a745' />
      break
    }
    case 'showDetails': {
      icon = 'mdi mdi-chevron-down p-1'
      buttonColor = 'btn-outline-secondary'
      break
    }
    case 'hideDetails': {
      icon = 'mdi mdi-chevron-up p-1'
      buttonColor = 'btn-outline-secondary'
      break
    }
    case 'email': {
      icon = <EmailIcon />
      break
    }
    case 'email-green': {
      icon = <EmailIcon color='#28a745' />
      break
    }
    case 'view': {
      icon = <EyeIcon1 />
      break
    }
    case 'copy': {
      icon = <CopyIcon />
      break
    }
    case 'copyThin': {
      icon = <CopyIconThin />
      break
    }
    case 'file': {
      icon = <FileIcon />
      break
    }
    case 'upload': {
      icon = <UploadIcon />
      break
    }
    case 'uploadCloud': {
      icon = <UploadCloudIcon />
      break
    }
    case 'confirm': {
      icon = <ConfirmIcon />
      break
    }
    case 'confirm2': {
      icon = <ConfirmIcon2 />
      break
    }
    case 'activity': {
      icon = <CustomerViewIcon />
      break
    }
    case 'quickbooks': {
      icon = <QuickbooksIcon width={20} />
      break
    }
    case 'activity-green': {
      icon = <CustomerViewIcon color='#28a745' />
      break
    }
    case 'calendar': {
      icon = <IconTimeCards />
      break
    }
    case 'separate': {
      icon = <SplitIcon />
      break
    }
    case 'paperClip': {
      icon = <PaperClipIcon />
      break
    }
    case 'save': {
      icon = <SaveIcon />
      break
    }
    case 'settings': {
      icon = <SettingsIcon />
      break
    }
    case 'settings-blue': {
      icon = <SettingsIcon htmlColor='#4c53ef' />
      break
    }
    case 'print': {
      icon = <IconPrint />
      break
    }
    case 'eye-new': {
      icon = <IconEye />
      break
    }
    default: {
      break
    }
  }
  const onCancelConfirm = () => {}
  const onClickWhenPopConfirm = e => e.preventDefault()
  //const isPopap = isConfirmationNeeded && type === 'delete'
  return !preloader ? (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      <Popconfirm
        placement={popConfirmPosition ? popConfirmPosition : 'rightTop'}
        title={
          /\?$/.test(popConfirm)
            ? popConfirm
            : `Are you sure you want to ${type} ${popConfirm}?`
        }
        onConfirm={action}
        onCancel={onCancelConfirm}
        okText='Yes'
        cancelText='No'
        disabled={!popConfirm || disabled}
        getPopupContainer={getPopupContainer ? getPopupContainer : undefined}
      >
        <button
          className={`btn btn-light ${type}${disabled ? ' disabled' : ''}`}
          onClick={!popConfirm ? action : onClickWhenPopConfirm}
          disabled={!!preloader || disabled}
        >
          {icon}
        </button>
      </Popconfirm>
    </Tooltip>
  ) : (
    <button className={`btn btn-light ${type}`}>{<SmallPreloader />}</button>
  )
}
