import styled from 'styled-components'

export const Wrapper = styled.div`
  border: 1px solid #d6d6d6;
  border-radius: 4px;
`

export const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 10px;
  max-width: 1366px;
  overflow: scroll;

  & > p {
    font-size: 12px;

    span {
      font-weight: 700;
    }
  }

  .proposal-modules {
    margin-left: auto;
  }
`

export const Content = styled.div`
  border-top: 1px solid #d6d6d6;
  padding: 10px;
`

export const ProjectStatus = styled.span<{ color: string }>`
  background-color: ${props => props.color}33;
  color: ${props => props.color};
  padding: 4px 8px;
  border-radius: 4px;
`
