export enum SchedulePermissions {
  SCHEDULE_CAN_READ = 'schedule_can_read',
  SCHEDULE_CAN_VIEW_EVENT_DETAILS = 'schedule_can_view_event_details',
  SCHEDULE_CAN_VIEW_EVENT_STATUS = 'schedule_can_view_event_status',
  SCHEDULE_CAN_CHANGE_EVENT_STATUS = 'schedule_can_change_event_status',
  SCHEDULE_CAN_READ_NOTIFICATION_HISTORY = 'schedule_can_read_notification_history',
  SCHEDULE_CAN_CREATE_PROJECT = 'schedule_can_create_project',
  SCHEDULE_CAN_VIEW_PROJECT_DETAILS = 'schedule_can_view_project_details',
  SCHEDULE_CAN_VIEW_RELATED_JOBS = 'schedule_can_view_related_jobs',
  SCHEDULE_CAN_REQUEST_FOR_REVIEW = 'schedule_can_request_for_review',
  SCHEDULE_CAN_DUPLICATE_EVENT = 'schedule_can_duplicate_event',
  SCHEDULE_CAN_READ_CLIENT_NAME = 'schedule_can_read_client_name',
  SCHEDULE_CAN_READ_CLIENT_ADDRESS = 'schedule_can_read_client_address',
  SCHEDULE_CAN_EDIT_PROJECT_CONTACTS = 'schedule_can_edit_project_contacts',
  SCHEDULE_CAN_READ_CLIENT_CONTACTS = 'schedule_can_read_client_contacts',
  SCHEDULE_CAN_READ_RELATED_ENTITY = 'schedule_can_read_related_entity',
  SCHEDULE_CAN_CHANGE_EVENT_FRAME = 'schedule_can_change_event_frame',
  SCHEDULE_CAN_VIEW_EVENT_SOURCE = 'schedule_can_view_event_source',
  SCHEDULE_CAN_READ_RELATED_ENTITY_MAIN_INFO = 'schedule_can_read_related_entity_main_info',
  SCHEDULE_CAN_READ_RELATED_ENTITY_APPOINTMENTS = 'schedule_can_read_related_entity_appointments',
  SCHEDULE_CAN_READ_RELATED_ENTITY_PROJECTS = 'schedule_can_read_related_entity_projects',
  SCHEDULE_CAN_READ_RELATED_ENTITY_MEMBERSHIPS = 'schedule_can_read_related_entity_memberships',
  SCHEDULE_CAN_READ_RELATED_ENTITY_CONTACTS = 'schedule_can_read_related_entity_contacts',
  SCHEDULE_CAN_READ_RELATED_PROPERTY_CONTACTS = 'schedule_can_read_related_property_contacts',
  SCHEDULE_CAN_READ_RELATED_PROPERTY_MEDIA = 'schedule_can_read_related_property_media',
  SCHEDULE_CAN_READ_RELATED_CONTACT_PROPERTIES = 'schedule_can_read_related_contact_properties',
  SCHEDULE_CAN_READ_RELATED_CONTACT_ORGANIZATIONS = 'schedule_can_read_related_contact_organizations',
  SCHEDULE_CAN_READ_RELATED_CONTACT_ASSOCIATIONS = 'schedule_can_read_related_contact_associations',
  SCHEDULE_CAN_READ_RELATED_CONTACT_MESSAGING = 'schedule_can_read_related_contact_messaging',
  SCHEDULE_CAN_READ_RELATED_CONTACT_CALLS = 'schedule_can_read_related_contact_calls',
  SCHEDULE_RESTRICT_FEATURE_DAYS = 'schedule_restrict_future_days',
  SCHEDULE_HIDE_EVENT_AFTER_COMPLETION = 'schedule_hide_event_after_completion',

  //Information for Workers
  SCHEDULE_CAN_READ_INFO_FOR_WORKERS = 'schedule_can_read_info_for_workers',
  SCHEDULE_CAN_EDIT_INFO_FOR_WORKERS = 'schedule_can_edit_info_for_workers',
  SCHEDULE_CAN_DELETE_INFO_FOR_WORKERS = 'schedule_can_delete_info_for_workers',

  SCHEDULE_CAN_CREATE_CUSTOM_FORMS = 'schedule_can_create_custom_forms',
  SCHEDULE_CAN_READ_CUSTOM_FORMS = 'schedule_can_read_custom_forms',
  SCHEDULE_CAN_EDIT_CUSTOM_FORMS = 'schedule_can_edit_custom_forms',
  SCHEDULE_CAN_DELETE_CUSTOM_FORMS = 'schedule_can_delete_custom_forms',
  SCHEDULE_PAYMENT_MODULE_CAN_CHANGE_STATUS = 'schedule_project_payment_module_can_change_status',
  SCHEDULE_SUPPLY_MODULE_CAN_CHANGE_STATUS = 'schedule_project_supply_module_can_change_status',
  SCHEDULE_CUSTOM_MODULE_1_CAN_CHANGE_STATUS = 'schedule_project_custom_module_1_can_change_status',
  SCHEDULE_CUSTOM_MODULE_2_CAN_CHANGE_STATUS = 'schedule_project_custom_module_2_can_change_status',
  SCHEDULE_CUSTOM_MODULE_3_CAN_CHANGE_STATUS = 'schedule_project_custom_module_3_can_change_status',
}
