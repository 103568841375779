import { TimeCardsPermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'

export const time_cards = {
  time_cards_list_can_read: {
    name: 'View Time Cards List',
    control_type: UniteType.FIRST_VIEW,
    description: '',
    with_access_scope: true,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.TIME_CARDS_LIST_CAN_READ,
    related_key: [],
    is_module: true,
    custom_access_scope: [
      {
        key: 1,
        label: 'All',
        color: 'white',
        activeColor: '#adf7b6',
      },
      {
        key: 2,
        label: 'Own',
        activeColor: '#adf7b6',
        color: 'white',
      },
    ],
  },

  time_cards_user_time_card_can_read: {
    name: 'View User Timecard Details ',
    control_type: UniteType.THIRD_VIEW,
    permission_key: IKey.TIME_CARDS_USER_TIME_CARD_CAN_READ,
    description: '',
    with_access_scope: true,
    with_access_checkbox: true,
    is_module: true,
    related_key: [
      IKey.TIME_CARDS_USER_TIME_CARD_CAN_READ,
      IKey.TIME_CARDS_USER_TIME_CARD_CAN_EDIT,
      IKey.TIME_CARDS_USER_TIME_CARD_CAN_DELETE,
    ],
    custom_access_scope: [
      {
        key: 1,
        label: 'All',
        color: 'white',
        activeColor: '#adf7b6',
      },
      {
        key: 2,
        label: 'Own',
        activeColor: '#adf7b6',
        color: 'white',
      },
    ],

    children: [
      {
        name: 'Edit User Timecard',
        permission_key: IKey.TIME_CARDS_USER_TIME_CARD_CAN_EDIT,
        parent_permission_key: IKey.TIME_CARDS_USER_TIME_CARD_CAN_READ,
        with_access_scope: true,
        with_access_checkbox: true,
        custom_access_scope: [
          {
            key: 1,
            label: 'All',
            color: 'white',
            activeColor: '#adf7b6',
          },
          {
            key: 2,
            label: 'Own',
            activeColor: '#adf7b6',
            color: 'white',
          },
        ],
      },
      {
        name: 'Delete User Timecard',
        permission_key: IKey.TIME_CARDS_USER_TIME_CARD_CAN_DELETE,
        parent_permission_key: IKey.TIME_CARDS_USER_TIME_CARD_CAN_READ,
        with_access_scope: true,
        with_access_checkbox: true,
        custom_access_scope: [
          {
            key: 1,
            label: 'All',
            color: 'white',
            activeColor: '#adf7b6',
          },
          {
            key: 2,
            label: 'Own',
            activeColor: '#adf7b6',
            color: 'white',
          },
        ],
      },
    ],
  },
}
