import axiosOrcatec from '../../../api/axiosInstance'

export const getUsersApi = requestParams => {
  const defaultParams = {
    searchValue: '',
    perPage: 10,
    page: 1,
    listStatus: '0',
    sortName: '',
    order: '',
  }

  const params = { ...defaultParams, ...requestParams }

  const { searchValue, perPage, page, listStatus, sortName, order } = params

  return axiosOrcatec.get(
    `/admin/users?search=${searchValue}&perPage=${perPage}&page=${page}&show_inactive=${listStatus}&sort=${sortName}&order=${order}`,
  )
}

export const createUserApi = data => axiosOrcatec.post('/admin/users', data)
export const editUserApi = (id, data) => axiosOrcatec.put(`/admin/users/${id}`, data)
export const deleteUserApi = id => axiosOrcatec.delete(`/admin/users/${id}`)
export const setUserStatusApi = (id, value) =>
  axiosOrcatec.post(`/admin/users/${id}/status/`, { _method: 'patch', active: value })

export const getCurrentUserApi = id => axiosOrcatec.get(`/admin/users/${id}`)

export const resendUserInviteApi = (id, data) => axiosOrcatec.post(`/admin/users/${id}/re-invite`, data)

export const getUserRolesApi = () => axiosOrcatec.get('/admin/roles')
