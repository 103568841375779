export const propertiesTab = [
  { title: 'address', isCounter: false, isDefault: true },
  { title: 'clients' },
  { title: 'organizations' },
  { title: 'appointments' },
  { title: 'media' },
  { title: 'proposals' },
]

export const rowKeys = {
  titleRow: [
    'Address',
    'Type',
    'Relation',
    'Other Projects',
    'Notes',
    'Project Relation',
    'Tags',
    'Icon',
  ],
  firstRow: [
    'full_address || address',
    { key: 'type', type: 'properties' },
    'relation',
    'proposals',
    'notes',
    'proposal_relation',
    'tags',
  ],
  secondRow: [
    { address: 'full_address', name: 'name' },
    'type',
    'property_relation',
    'proposals',
    'note',
    'proposal_relation',
    'tags',
  ],
  thirdRow: [
    { address: 'full_address', name: 'name' },
    'type',
    'property_relation',
    'proposals',
    'notes',
    'proposal_relation',
    'tags',
  ],
}
