import { ProjectSection } from 'features/Project/types'
import styled from 'styled-components'
import { WoProjectSection } from './WoProjectSection'
import { Button } from 'components/UIKit'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  addWorkOrderSection,
  selectMaterialsTotal,
  selectWoInvestmentTotal,
  selectWorkOrder,
  // selectWoSettings,
  // selectWorkOrder,
  selectWorkOrderTab,
  // selectWorkOrderTaxTotal,
  selectWorkOrderTotal,
  // selectWorkOrderTotalAfterDiscounts,
} from 'features/Project/slices/projectWorkOrdersSlice'
import { priceToView } from 'helpers/thousandSeparator'
// import { Taxes } from './Taxes'
import {
  selectIsPriceVisible,
  // selectIsPriceVisible,
  selectUserPermissionsByName,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'

interface Props {
  data: ProjectSection[]
}

export const WoProjectSectionsList = ({ data }: Props) => {
  const dispatch = useAppDispatch()

  const { user_id } = useAppSelector(selectWorkOrder)
  const tab = useAppSelector(selectWorkOrderTab)
  const materialsTotal = useAppSelector(selectMaterialsTotal())
  // const totalAfterDiscounts = useAppSelector(selectWorkOrderTotalAfterDiscounts)
  // const taxTotal = useAppSelector(selectWorkOrderTaxTotal)
  const workOrderTotal = useAppSelector(selectWorkOrderTotal)
  const investmentTotal = useAppSelector(state =>
    selectWoInvestmentTotal(state),
  )
  // const { show_taxes } = useAppSelector(selectWoSettings)

  const isCostVisible = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.PROJECT,
      ProjectPermissions.PROJECT_CAN_READ_COST,
    ),
  )
  const showItemDescription = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.PROJECT,
      ProjectPermissions.PROJECT_CAN_VIEW_ITEM_DESCRIPTON,
    ),
  )

  const isPriceVisible = useAppSelector(selectIsPriceVisible(user_id))

  const [loading, setLoading] = useState(false)
  const [newRowTriggered, setNewRorTriggered] = useState(false)

  const projectSections = data.filter(section => section.is_from_proposal)
  const woSections = data.filter(section => !section.is_from_proposal)

  const handleSectionAdd = async () => {
    setLoading(true)

    await dispatch(
      addWorkOrderSection({
        proposal_tab_id: tab.id,
      }),
    )

    setLoading(false)
  }

  const onItemAdd = (value: boolean) => setNewRorTriggered(value)

  return (
    <Wrapper>
      {!!projectSections.length &&
        projectSections.map(section => (
          <WoProjectSection
            key={section.id}
            data={section}
            isNewRowtriggered={newRowTriggered}
            onItemAdd={onItemAdd}
            isProjectSection
            hidePrices={!isPriceVisible}
            hideCost={!isCostVisible}
            hideItemDescription={!showItemDescription}
          />
        ))}
      {!!woSections.length ? (
        <AdditionalSection>
          <Title>Additional</Title>

          {woSections.map(section => (
            <WoProjectSection
              key={section.id}
              data={section}
              isNewRowtriggered={newRowTriggered}
              onItemAdd={onItemAdd}
              hidePrices={!isPriceVisible}
              hideCost={!isCostVisible}
              hideItemDescription={!showItemDescription}
            />
          ))}
        </AdditionalSection>
      ) : (
        <Button
          disabled={loading}
          onClick={handleSectionAdd}
          style={{ marginTop: 16 }}
        >
          + Add item
        </Button>
      )}

      <Totals>
        {!!isPriceVisible && (
          <Total>
            <span>Materials subtotal:</span>
            <span>{priceToView(materialsTotal)}</span>
          </Total>
        )}
        {!!isPriceVisible && (
          <Total>
            <span>Investment total:</span>
            <span>{priceToView(investmentTotal)}</span>
          </Total>
        )}
      </Totals>

      {/* {tab.tax && !!isCostVisible && (
        <Taxes
          projectTax={tab.tax}
          fees={tab.fees}
          show_taxes={false}
          taxTotal={taxTotal}
          totalAfterDiscounts={totalAfterDiscounts}
        />
      )} */}

      {!!isPriceVisible && (
        <Total size={21}>
          <span>Total:</span>
          <span>{priceToView(workOrderTotal)}</span>
        </Total>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & > * {
    margin-top: 16px;
  }
`
const AdditionalSection = styled.div`
  & > * {
    margin-top: 16px;
  }
`
const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`

const Totals = styled.div`
  margin-top: 30px;
`

const Total = styled.p<{ size?: number }>`
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: ${props => props.size || 18}px;
  margin: 10px 0;

  span {
    &:nth-of-type(2) {
      flex: 0 0 150px;
      text-align: right;
    }
  }
`
