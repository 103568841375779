import styled from 'styled-components'

export const Title = styled.p`
  margin-bottom: 10px;
  font-weight: 700;
`

export const ContactBlock = styled.div<{ isProperty: boolean }>`
  /* display: flex;
  flex-direction: column; */
  display: grid;
  grid-template-columns: ${props =>
    props.isProperty ? '20px auto 28px' : '20px auto'};
  gap: 10px;
  /* flex: 0 0 300px;
  max-width: 300px; */
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  padding: 5px 10px;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      /* width: 50px; */
      display: inline-block;
      text-transform: capitalize;
    }
  }
`

export const Icon = styled.div``
export const Info = styled.div`
  /* width: 100%; */
  overflow: hidden;
`

export const PropertyBlock = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 8px;
  padding: 5px 10px;
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  gap: 15px;
  margin-bottom: 20px;

  & > svg {
    position: relative;
    top: 5px;
    /* margin-right: 10px; */
  }
`

export const Wrapper = styled.div`
  margin-bottom: 10px;
`
