import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ImportTableStatus,
  GlobalItem,
  ItemCategory,
  Breadcrumb,
} from './types'
import ItemsAPI, { ItemTableParams } from 'api/Items'
import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'
import { AppStateType } from 'store'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { Error } from 'types/Error'
import { AsyncThunkAPI } from 'store/types'
import { deleteErrorKeys } from './helpers'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { ProjectDiscount, ProjectRebate } from 'features/Project/types'

interface InitialState {
  status: 'idle' | 'loading' | 'success' | 'error'
  categoryStatus: 'idle' | 'loading' | 'success' | 'error'
  itemsList: GlobalItem[]
  categories: {
    byId: Record<number, ItemCategory>
    ids: number[]
  }
  discounts: ProjectDiscount[]
  rebates: ProjectRebate[]
  choosenCategory: ItemCategory | null
  breadcrumbs: Record<number, Breadcrumb>
  pagination: GeneralTableParams
  error: Error | null
  categoryError: Error | null
  importStatus: ImportTableStatus
}

const initialState: InitialState = {
  status: 'idle',
  categoryStatus: 'idle',
  itemsList: [],
  choosenCategory: null,
  pagination: {
    current_page: 1,
    per_page: 25,
    total: 10,
  },
  categories: {
    byId: {},
    ids: [],
  },
  discounts: [],
  rebates: [],
  breadcrumbs: {},
  error: null,
  categoryError: null,
  importStatus: {
    errors: [],
    status: 'idle',
    progress: 0,
    start: '',
    end: '',
  },
}

const pricePagesSlice = createSlice({
  name: 'pricepagesItems',
  initialState,
  reducers: {
    resetErrors: (state, action: PayloadAction<string[] | undefined>) => {
      state.error = deleteErrorKeys(state.error, action.payload)
      state.status = 'idle'
    },
    importStatusUpdated: (
      state,
      action: PayloadAction<Record<keyof ImportTableStatus, any>>,
    ) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state.importStatus[key as keyof ImportTableStatus] = value
      })
    },
    setChoosenCategory: (state, action: PayloadAction<ItemCategory | null>) => {
      state.choosenCategory = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAllItems.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchAllItems.fulfilled, (state, { payload }) => {
      state.itemsList = payload.data
      state.pagination = payload.meta
      state.status = 'success'
    })
    builder.addCase(fetchAllItems.rejected, state => {
      state.status = 'error'
      state.error = { error: 'error' }
    })

    builder.addCase(fetchItemsByCategory.pending, state => {
      state.status = 'loading'
      state.categoryStatus = 'loading'
    })
    builder.addCase(fetchItemsByCategory.fulfilled, (state, { payload }) => {
      state.itemsList = payload.data.items
      // state.categories = payload.data.categories
      state.categories = {
        byId: transformArrayToObj(payload.data.categories),
        ids: payload.data.categories.map(cat => cat.id),
      }
      state.breadcrumbs = transformArrayToObj(payload.data.breadcrumbs)
      state.pagination = payload.meta
      state.status = 'success'
      state.categoryStatus = 'success'
    })
    builder.addCase(fetchItemsByCategory.rejected, state => {
      state.status = 'error'
      state.categoryStatus = 'error'
      state.error = { error: 'error' }
    })

    builder.addCase(createItem.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(createItem.fulfilled, (state, { payload }) => {
      state.itemsList = [...state.itemsList, payload]
      state.status = 'success'
    })
    builder.addCase(createItem.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload?.errors || null
    })

    builder.addCase(duplicateItem.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(duplicateItem.fulfilled, (state, { payload }) => {
      state.itemsList = [payload, ...state.itemsList]
      state.status = 'success'
    })
    builder.addCase(duplicateItem.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload?.errors || null
    })

    builder.addCase(updateItem.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateItem.fulfilled, (state, { payload }) => {
      const updatedItem = state.itemsList?.findIndex(
        item => item.id === payload.id,
      )

      state.itemsList[updatedItem] = payload
      state.status = 'success'
    })
    builder.addCase(updateItem.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload?.errors || null
    })

    builder.addCase(deleteItem.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
      state.itemsList = state.itemsList.filter(item => item.id === payload.id)
      state.status = 'success'
    })
    builder.addCase(deleteItem.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload?.errors || null
    })

    //Categories

    builder.addCase(createCategory.pending, state => {
      state.categoryStatus = 'loading'
    })
    builder.addCase(createCategory.fulfilled, (state, { payload }) => {
      state.categories = {
        byId: {
          ...state.categories.byId,
          [payload.id]: payload,
        },
        ids: [...state.categories.ids, payload.id],
      }
      state.categoryStatus = 'success'
    })
    builder.addCase(createCategory.rejected, (state, { payload }) => {
      state.categoryStatus = 'error'
      state.categoryError = payload?.errors || null
    })

    builder.addCase(updateCategory.pending, state => {
      state.categoryStatus = 'loading'
    })
    builder.addCase(updateCategory.fulfilled, (state, { payload }) => {
      state.categories.byId = {
        ...state.categories.byId,
        [payload.id]: payload,
      }
      state.categoryStatus = 'success'
    })
    builder.addCase(updateCategory.rejected, (state, { payload }) => {
      state.categoryStatus = 'error'
      state.categoryError = payload?.errors || null
    })

    builder.addCase(deleteCategory.pending, state => {
      state.categoryStatus = 'loading'
    })
    builder.addCase(deleteCategory.fulfilled, (state, { payload }) => {
      state.categories.ids = state.categories.ids.filter(
        id => id !== payload.id,
      )
      state.categoryStatus = 'success'
    })
    builder.addCase(deleteCategory.rejected, (state, { payload }) => {
      state.categoryStatus = 'error'
      state.categoryError = payload?.errors || null
    })

    //Import Table

    builder.addCase(getImportStatus.fulfilled, (state, { payload }) => {
      state.importStatus = payload
    })

    //Discounts

    builder.addCase(fetchDiscounts.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchDiscounts.fulfilled, (state, { payload }) => {
      state.discounts = payload.data
      state.pagination = payload.meta
      state.status = 'success'
    })
    builder.addCase(fetchDiscounts.rejected, state => {
      state.status = 'error'
      state.error = { error: 'error' }
    })

    builder.addCase(createDiscount.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(createDiscount.fulfilled, (state, { payload }) => {
      state.discounts = [payload, ...state.discounts]
      state.status = 'success'
    })
    builder.addCase(createDiscount.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload?.errors || null
    })

    builder.addCase(updateDiscount.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateDiscount.fulfilled, (state, { payload }) => {
      const updatedDiscount = state.discounts?.findIndex(
        item => item.id === payload.id,
      )

      state.discounts[updatedDiscount] = payload
      state.status = 'success'
    })
    builder.addCase(updateDiscount.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload?.errors || null
    })

    builder.addCase(deleteDiscount.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteDiscount.fulfilled, (state, { payload }) => {
      state.discounts = state.discounts.filter(
        discount => discount.id !== payload.id,
      )
      state.status = 'success'
    })
    builder.addCase(deleteDiscount.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload?.errors || null
    })

    //Rebates

    builder.addCase(fetchRebates.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchRebates.fulfilled, (state, { payload }) => {
      state.rebates = payload.data
      state.pagination = payload.meta
      state.status = 'success'
    })
    builder.addCase(fetchRebates.rejected, state => {
      state.status = 'error'
      state.error = { error: 'error' }
    })

    builder.addCase(createRebate.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(createRebate.fulfilled, (state, { payload }) => {
      state.rebates = [payload, ...state.rebates]
      state.status = 'success'
    })
    builder.addCase(createRebate.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload?.errors || null
    })

    builder.addCase(updateRebate.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateRebate.fulfilled, (state, { payload }) => {
      const updatedDiscount = state.rebates?.findIndex(
        item => item.id === payload.id,
      )

      state.rebates[updatedDiscount] = payload
      state.status = 'success'
    })
    builder.addCase(updateRebate.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload?.errors || null
    })

    builder.addCase(deleteRebate.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteRebate.fulfilled, (state, { payload }) => {
      state.rebates = state.rebates.filter(
        discount => discount.id !== payload.id,
      )
      state.status = 'success'
    })
    builder.addCase(deleteRebate.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload?.errors || null
    })
  },
})

export const {
  resetErrors,
  importStatusUpdated,
  setChoosenCategory,
} = pricePagesSlice.actions
export default pricePagesSlice.reducer

//Selectors
export const selectPricePagesSlice = (state: AppStateType) =>
  state.orcatec.pricePagesSlice

export const selectImportStatus = (state: AppStateType) =>
  state.orcatec.pricePagesSlice.importStatus

///

//Tnunks

export const fetchDiscounts = createAsyncThunk<
  { data: ProjectDiscount[]; meta: Record<string, unknown> },
  GeneralTableParams
>('items/getDiscounts', async (params, { rejectWithValue }) => {
  try {
    return await ItemsAPI.getDiscounts(params)
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const createDiscount = createAsyncThunk<
  ProjectDiscount,
  ProjectDiscount,
  AsyncThunkAPI
>('items/createDiscount', async (discount, { rejectWithValue }) => {
  try {
    const data = await ItemsAPI.createDiscount(discount)

    openNotificationWithIcon('success', {
      message: `Discount has been successfully created'`,
    })

    return data
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

export const updateDiscount = createAsyncThunk<
  ProjectDiscount,
  ProjectDiscount,
  AsyncThunkAPI
>('items/updateDiscount', async (discount, { rejectWithValue }) => {
  try {
    const data = await ItemsAPI.updateDiscount(discount)
    openNotificationWithIcon('success', {
      message: `Discount has been successfully updated`,
    })

    return data
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

export const duplicateDiscount = createAsyncThunk<
  ProjectDiscount,
  ProjectDiscount,
  AsyncThunkAPI
>('items/duplicateDiscount', async (discount, { rejectWithValue }) => {
  try {
    const updatedDiscount = await ItemsAPI.duplicateDiscount(discount)

    const data = await ItemsAPI.duplicateDiscount(updatedDiscount)

    openNotificationWithIcon('success', {
      message: `Discount has been successfully duplicated`,
    })

    return data
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

export const deleteDiscount = createAsyncThunk<
  ProjectDiscount,
  ProjectDiscount,
  AsyncThunkAPI
>('items/deleteDiscount', async (discount, { rejectWithValue }) => {
  try {
    await ItemsAPI.deleteDiscount(discount)
    openNotificationWithIcon('success', {
      message: `Discount has been successfully deleted`,
    })

    return discount
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

export const fetchRebates = createAsyncThunk<
  { data: ProjectDiscount[]; meta: Record<string, unknown> },
  GeneralTableParams
>('items/getRebates', async (params, { rejectWithValue }) => {
  try {
    return await ItemsAPI.getRebates(params)
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const createRebate = createAsyncThunk<
  ProjectRebate,
  ProjectRebate,
  AsyncThunkAPI
>('items/createRebate', async (rebate, { rejectWithValue }) => {
  try {
    const data = await ItemsAPI.createRebate(rebate)

    openNotificationWithIcon('success', {
      message: `Rebate has been successfully created'`,
    })

    return data
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

export const updateRebate = createAsyncThunk<
  ProjectRebate,
  ProjectRebate,
  AsyncThunkAPI
>('items/updateRebate', async (rebate, { rejectWithValue }) => {
  try {
    const data = await ItemsAPI.updateRebate(rebate)
    openNotificationWithIcon('success', {
      message: `Rebate has been successfully updated`,
    })

    return data
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

export const duplicateRebate = createAsyncThunk<
  ProjectRebate,
  ProjectRebate,
  AsyncThunkAPI
>('items/duplicateRebate', async (rebate, { rejectWithValue }) => {
  try {
    const updatedRebate = await ItemsAPI.duplicateRebate(rebate)

    const data = await ItemsAPI.duplicateRebate(updatedRebate)

    openNotificationWithIcon('success', {
      message: `Rebate has been successfully duplicated`,
    })

    return data
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

export const deleteRebate = createAsyncThunk<
  ProjectRebate,
  ProjectRebate,
  AsyncThunkAPI
>('items/deleteRebate', async (rebate, { rejectWithValue }) => {
  try {
    await ItemsAPI.deleteRebate(rebate)
    openNotificationWithIcon('success', {
      message: `Rebate has been successfully deleted`,
    })

    return rebate
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

export const fetchAllItems = createAsyncThunk<
  { data: GlobalItem[]; meta: Record<string, unknown> },
  GeneralTableParams
>('items/getAllItems', async (params, { rejectWithValue }) => {
  try {
    return await ItemsAPI.getItemsList(params)
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const fetchItemsByCategory = createAsyncThunk<
  {
    data: {
      items: GlobalItem[]
      categories: ItemCategory[]
      breadcrumbs: Breadcrumb[]
    }
    meta: Record<string, unknown>
  },
  ItemTableParams
>('items/getItemsByCategory', async (params, { rejectWithValue }) => {
  try {
    return await ItemsAPI.getItemsListByCategory(params)
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const createItem = createAsyncThunk<
  GlobalItem,
  GlobalItem,
  AsyncThunkAPI
>('items/crateItem', async (item, { rejectWithValue }) => {
  try {
    const data = await ItemsAPI.createItem(item)

    openNotificationWithIcon('success', {
      message: `Item has been successfully created'`,
    })

    return data
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

export const updateItem = createAsyncThunk<
  GlobalItem,
  GlobalItem,
  AsyncThunkAPI
>('items/updateItem', async (item, { rejectWithValue }) => {
  try {
    const data = await ItemsAPI.updateItem(item)
    openNotificationWithIcon('success', {
      message: `Item has been successfully updated`,
    })

    return data
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

export const duplicateItem = createAsyncThunk<
  GlobalItem,
  GlobalItem,
  AsyncThunkAPI
>('items/duplicateItem', async (item, { rejectWithValue }) => {
  try {
    const updatedItem = await ItemsAPI.updateItem(item)

    const data = await ItemsAPI.duplicateItem(updatedItem)

    openNotificationWithIcon('success', {
      message: `Item has been successfully duplicated`,
    })

    return data
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

export const deleteItem = createAsyncThunk<
  GlobalItem,
  GlobalItem,
  AsyncThunkAPI
>('items/deleteItem', async (item, { rejectWithValue }) => {
  try {
    await ItemsAPI.deleteItem(item)
    openNotificationWithIcon('success', {
      message: `Item has been successfully deleted`,
    })

    return item
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

//Categories

export const createCategory = createAsyncThunk<
  ItemCategory,
  ItemCategory,
  AsyncThunkAPI
>('items/createCategory', async (category, { rejectWithValue }) => {
  try {
    const data = await ItemsAPI.createCategory(category)

    openNotificationWithIcon('success', {
      message: `Category has been successfully created'`,
    })

    return data
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

export const updateCategory = createAsyncThunk<
  ItemCategory,
  ItemCategory,
  AsyncThunkAPI
>('items/updateCategory', async (category, { rejectWithValue }) => {
  try {
    const data = await ItemsAPI.updateCategory(category)

    openNotificationWithIcon('success', {
      message: `Category has been successfully updated`,
    })

    return data
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

export const deleteCategory = createAsyncThunk<
  ItemCategory,
  ItemCategory,
  AsyncThunkAPI
>('items/deleteCategory', async (category, { rejectWithValue }) => {
  try {
    await ItemsAPI.deleteCategory(category)

    openNotificationWithIcon('success', {
      message: `Item has been successfully deleted`,
    })

    return category
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return rejectWithValue(error.response.data)
  }
})

//Import Table

export const getImportStatus = createAsyncThunk<
  ImportTableStatus,
  void,
  AsyncThunkAPI
>('items/importStatus', async (_, thunkAPI) => {
  try {
    const { status } = await ItemsAPI.getImportStatus()

    return status
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Ivalid data',
    })

    return thunkAPI.rejectWithValue(error.response.data)
  }
})
