import styled from 'styled-components'

import { useAppSelector } from 'store/Orcatec/hooks'
import moment from 'moment-timezone'

import { useDispatch } from 'react-redux'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { AppointmentStatusID } from 'types/Appointment'
import {
  selectCurrentContact,
  togglePropertyFormAction,
} from 'features/Contacts/slice/contactSlice'

import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

import { useQueryClient } from '@tanstack/react-query'

import { getNotificationSettings } from 'api/NotificationModal'

import {
  resetAppointmentAction,
  onChangeField,
  toggleDropModalAction,
  toggleUpcomingModalAction,
  selectCurrentAppointment,
  selectAppointmentSlice,
  fetchCreateAppointment,
} from '../appointmentSlice'

import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'

import {
  NotificationTemplateType,
  NotificationModalType,
} from 'features/Notification/types'
import { AppointmentSection } from './components/AppointmentSection'
import { useColumns } from 'hooks/useColumns'
import { DropModal } from './modals/DropModal'
import useEventSettings from 'hooks/useEventSettings'

export const AppointmentForm = ({ onCancel }) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const eventSettings = useEventSettings()
  const currentAppointment = useAppSelector(selectCurrentAppointment)
  const currentContact = useAppSelector(selectCurrentContact)
  const {
    error,
    showDropModal,
    loading,
    showUpcomingNotification,
  } = useAppSelector(selectAppointmentSlice)

  const { columns } = useColumns(
    currentAppointment.date
      ? moment(currentAppointment).format('MM/DD/YYYY')
      : moment().format('MM/DD/YYYY'),
  )
  const dispatchColumns = columns.map(column => ({
    name: column.main_tech_name || column.title,
    id: column.column_template_id,
  }))

  const onChange = (e: {
    target: { name: string; value: any; checked: boolean }
  }) => {
    const { name, value, checked } = e.target
    if (name === 'entity_type') {
      dispatch(onChangeField({ field: 'status', value: checked ? 2 : 1 }))
    }
    if (name === 'time_start') {
      dispatch(
        onChangeField({
          field: 'time_end',
          value: moment(value, 'HH:mm')
            .add(eventSettings?.arrival_window || 1, 'h')
            .format('HH:mm'),
        }),
      )
    }
    if (name === 'matrix_time_start') {
      dispatch(onChangeField({ field: 'matrix_time_start', value }))
      dispatch(onChangeField({ field: 'matrix_time_end', value: value + 1 }))
    }
    if (name === 'appointment-status') {
      dispatch(onChangeField({ field: 'status', value: value }))
      dispatch(
        onChangeField({
          field: 'status_logs',
          value: [
            {
              status: AppointmentStatusID[value],
              created_at: moment.utc().format('YYYY/MM/DD hh:mm A'),
            },
          ],
        }),
      )
    }
    if (name === 'start_date') {
      dispatch(
        onChangeField({
          field: name,
          value: moment(value).format('YYYY-MM-DD'),
        }),
      )
    }
    if (name === 'date') {
      dispatch(
        onChangeField({
          field: name,
          value: moment(value).format('YYYY-MM-DD'),
        }),
      )
    }
    if (name === 'column_template_id') {
      // const isQueueChecked = Array.isArray(value) && value.length === 1 && value.includes('queue')
      const isColumnChecked =
        Array.isArray(value) &&
        value.filter((id: string | number) => typeof id === 'number').length

      dispatch(onChangeField({ field: name, value: value }))
      dispatch(
        onChangeField({ field: 'assign_to_matrix', value: !!isColumnChecked }),
      )
    }
    dispatch(onChangeField({ field: name, value: value }))
  }

  const handleClearFrom = () => {
    dispatch(resetAppointmentAction())
  }

  const togglePropertyFormHandle = bool => {
    dispatch(togglePropertyFormAction(bool))
  }

  const createAppointmentHandle = async () => {
    try {
      const res = await dispatch(fetchCreateAppointment())

      if (res?.error) return

      openNotificationWithIcon('success', {
        message: 'Appointment has been added',
      })
      if (res?.payload?.assign_to_matrix) {
        const notificationSettings = await getNotificationSettings(
          res?.payload?.industry_id,
        )

        return (
          notificationSettings?.should_display_upcoming &&
          dispatch(toggleUpcomingModalAction(true))
        )
      }
      // const queueType = res?.payload?.is_recurring ? 'appointments_recurring' : 'appointments_queue'
      // queryClient.invalidateQueries({ queryKey: [queueType] })
      handleClearFrom()
      onCancel()
    } catch (error) {
      openNotificationWithIcon('error', { message: 'Something went wrong!' })
      console.error(error)
    }
  }

  const onConfirmDropModal = () => {
    dispatch(onChangeField({ field: 'confirm', value: true }))
    const queueType = appointment.is_recurring
      ? 'appointments_recurring'
      : 'appointments_queue'
    dispatch(toggleDropModalAction(false))
    createAppointmentHandle().then(() => {
      queryClient.invalidateQueries({ queryKey: [queueType] })
      handleClearFrom()
      onCancel()
    })
  }
  const onCancelDropModal = () => {
    dispatch(onChangeField({ field: 'confirm', value: false }))
    dispatch(toggleDropModalAction(false))
  }

  const clients = currentContact?.properties
    ?.filter(p => p?.id === currentAppointment?.entity_id)
    ?.map(p => p?.clients)
    .flat()
    .filter(Boolean)

  return (
    <FormWrapper>
      <AppointmentSection
        properties={currentContact?.properties || []}
        clients={clients}
        contact={currentContact}
        togglePropertyFormHandle={togglePropertyFormHandle}
        appointment={currentAppointment}
        onChange={onChange}
        error={error}
        loading={loading}
        eventSettings={eventSettings}
      />
      <ControlsRow>
        <MainButton onClick={handleClearFrom} type='delete' title='Clear' />
        <MainButton
          onClick={() => {
            onCancel()
            handleClearFrom()
          }}
          type='cancel'
          title='Cancel'
        />
        <MainButton onClick={createAppointmentHandle} title='Create' />
      </ControlsRow>

      {showUpcomingNotification && (
        <NotificationModal
          title={`Upcoming Appointment Notification`}
          templateType={NotificationTemplateType.APPOINTMENT}
          notificationType={NotificationModalType.UPCOMING}
          showModal={showUpcomingNotification}
          setShowModal={() => {
            dispatch(toggleUpcomingModalAction(false))
            handleClearFrom()
            onCancel()
          }}
          appointment={currentAppointment}
          customSubmit
          customSubmitFunc={onCancel}
        />
      )}

      <DropModal
        showDropModal={showDropModal}
        onConfirmDropModal={onConfirmDropModal}
        onCancelDropModal={onCancelDropModal}
        count_days={error?.count_days}
        dispatchColumns={dispatchColumns}
      />
    </FormWrapper>
  )
}

const FormWrapper = styled.div``
const ControlsRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: end;
  gap: 10px;
`
