import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useFormatAttachedClients = ([properties, clients, organizations]) => {
  const attachedClients = useSelector(state => state.orcatec.proposalContact.attachedClients)
  const [currentTableData, setCurrentTableData] = useState(null)

  const primaryPropertyId = useMemo(() => Array.isArray(properties) && properties.find(el => el?.is_primary)?.id, [
    properties,
  ])
  const allClients = [].concat(clients, organizations)
  const primaryClientId = useMemo(() => Array.isArray(allClients) && allClients.find(el => el?.is_primary)?.id, [
    allClients,
  ])

  useEffect(() => {
    const tableData = [...attachedClients]

    const getRelatedDataValue = (type, first_id, firstKey, secondKey, second_id, third_id) => {
      const relatedData = tableData.reduce((acc, curr) => {
        const parse = curr.related_data && JSON.parse(curr.related_data)
        Array.isArray(parse) ? acc.push(...parse) : acc.push({})
        return acc
      }, [])

      const relatedDataCurrentValue = relatedData.find(obj => obj.parent_id === first_id)

      if (type === 'check' && relatedDataCurrentValue) {
        if (firstKey && !secondKey) return relatedDataCurrentValue?.[firstKey]?.find(el => el.id === second_id)?.checked
        if (firstKey && secondKey)
          return relatedDataCurrentValue?.[firstKey]
            ?.find(el => el.id === second_id)
            ?.[secondKey]?.find(el => el.id === third_id)?.checked
      }

      if (type === 'input' && relatedDataCurrentValue) {
        if (!firstKey & !secondKey) return relatedDataCurrentValue.proposal_relation
        if (firstKey && !secondKey)
          return relatedDataCurrentValue[firstKey]?.find(el => el.id === second_id)?.proposal_relation
        if (firstKey && secondKey) {
          return relatedDataCurrentValue[firstKey]
            ?.find(el => el.id === second_id)
            ?.[secondKey]?.find(el => el.id === third_id)?.proposal_relation
        }
      }
    }

    const formatAttachedClients = tableData => {
      const targets = ['properties', 'contacts']

      tableData.forEach(client => {
        client.freezed = false
        client.freezed = !!(client.id === primaryClientId)
        client.proposal_relation = getRelatedDataValue('input', client.id)
        targets.forEach(value => {
          client[value] &&
            client[value].forEach(obj => {
              obj.freezed = false
              if (obj.id === primaryPropertyId) {
                obj.freezed = true
                client.freezed = true
              }
              if (typeof obj.checkbox === 'undefined')
                obj.checkbox = getRelatedDataValue('check', client.id, value, null, obj.id)
              obj.proposal_relation = getRelatedDataValue('input', client.id, value, null, obj.id)

              if (obj.deleted_at && !obj.checkbox) return (obj.hideElement = true)

              obj?.clients?.forEach(third => {
                if ((third.id === client.id && third.type === client.type) || (!third.checkbox && third.deleted_at)) {
                  third.hideElement = true
                  return
                }

                if (typeof third.checkbox === 'undefined') {
                  third.checkbox = getRelatedDataValue('check', client.id, value, 'clients', obj.id, third.id)
                  third.proposal_relation = getRelatedDataValue('input', client.id, value, 'clients', obj.id, third.id)
                }
              })
              obj.clients = obj?.clients?.filter(el => !el.hideElement)

              obj?.organizations?.forEach(third => {
                if ((third.id === client.id && third.type === client.type) || (!third.checkbox && third.deleted_at)) {
                  third.hideElement = true
                  return
                }

                if (typeof third.checkbox === 'undefined') {
                  third.checkbox = getRelatedDataValue('check', client.id, value, 'organizations', obj.id, third.id)
                  third.proposal_relation = getRelatedDataValue(
                    'input',
                    client.id,
                    value,
                    'organizations',
                    obj.id,
                    third.id,
                  )
                }
              })
              obj.organizations = obj?.organizations?.filter(el => !el.hideElement)
            })

          client[value] = client?.[value]?.filter(obj => !obj.hideElement)
        })
      })
      setCurrentTableData(tableData?.length ? tableData.reverse() : null)
    }

    attachedClients && Array.isArray(attachedClients) && formatAttachedClients(tableData)
  }, [attachedClients, primaryPropertyId, primaryClientId])

  return [currentTableData, setCurrentTableData]
}
