import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../store/Orcatec/hooks'
import { messagingAPI } from '../../../api/MessagingAPI'
import { AppStateType } from '../../../store'

const selectCompanyID = (state: AppStateType) => state.orcatec.company.id
const selectUserID = (state: AppStateType) => state.orcatec.user.me.id

interface IEvent {
  count: number
}

export const useHeaderSocket = () => {
  const companyID = useAppSelector(selectCompanyID)
  const userID = useAppSelector(selectUserID)

  const [unreadChatsCount, setUnreadChatsCount] = useState(0)

  const handleChatUnseenEvent = (event: IEvent) => {
    setUnreadChatsCount(event.count)
  }

  useEffect(() => {
    messagingAPI
      .getUnreadChatsCount()
      .then(({ count_unread }) => setUnreadChatsCount(count_unread))
      .catch(e => console.error(e))
  }, [])

  useEffect(() => {
    const COMPANY_CHATS_CHANNEL = `company-message.${companyID}.${userID}`
    const CHAT_UNSEEN_EVENT = '.sms.chats.count-unread'

    window.Echo.private(COMPANY_CHATS_CHANNEL).listen(
      CHAT_UNSEEN_EVENT,
      handleChatUnseenEvent,
    )

    // return () => window.Echo.leaveChannel(COMPANY_CHATS_CHANNEL)
  }, [companyID])

  return { unreadChatsCount }
}
