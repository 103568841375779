import styled from 'styled-components'
import { generateMargin } from 'features/Project/Contacts/helpers'
import { BREAK_POINT } from 'features/Project/Contacts/constants'

interface ItemStyle {
  level?: number
}

export const ItemWrapper = styled.div<ItemStyle>`
  padding-left: ${props => `${generateMargin(props.level || 0)}px`};
  font-size: 14px;
  border-top: 1px solid rgba(237, 239, 247, 1);
`

export const ContactWrapper = styled.article`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 10px;
  width: 96%;

  &.contact-item_level-1 {
    padding-left: 10px;
  }

  &.contact-item_level-2 {
    padding-left: 14px;
  }

  &.contact-item_level-3 {
    padding-left: 26px;
    border-bottom: 1px solid rgba(237, 239, 247, 1);
  }

  @media screen and (min-width: ${BREAK_POINT}px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    &.contact-item_level-1 {
      padding-left: 10px;
    }

    &.contact-item_level-2 {
      padding-left: 46px;
    }

    &.contact-item_level-3 {
      padding-left: 70px;
    }

    & .contact-item_info-1 {
      flex: 0 0 370px;
    }
    & .contact-item_info-2 {
      flex: 0 0 348px;
    }

    & .contact-item_info-3 {
      flex: 0 0 324px;
    }
  }
`
export const ContactWrapperInner = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  &:last-child {
    padding-top: 6px;
  }
`

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const RelationBlock = styled.div`
  width: 100%;
  & textarea {
    border-radius: 6px;
  }
  & .ant-typography-edit {
    margin-top: -2px;
  }
`

export const ItemInfo = styled.span`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const ItemInfoButtons = styled.span`
  display: none;
  @media screen and (min-width: ${BREAK_POINT}px) {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`

export const ItemInfoButtonDetach = styled.span`
  display: none;
  @media screen and (max-width: ${BREAK_POINT}px) {
    display: flex;
    align-items: center;
    padding-right: 20px;
    padding-bottom: 10px;
  }
`

export const ItemInfoCount = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  & div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`

export const RemoveItemBlock = styled.div`
  display: none;

  @media screen and (min-width: ${BREAK_POINT}px) {
    display: block;
  }
`

export const SelectItemBlock = styled.div`
  & .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    /* &::after {
      left: 5px;
    } */
  }
  & .ant-checkbox {
    margin-right: 0;
  }
`
export const IconItemBlock = styled.div`
  & svg {
    vertical-align: baseline;
  }
`
export const InfoItemBlock = styled.div`
  @media screen and (min-width: ${BREAK_POINT}px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
