import React from 'react'
import './index.scss'

const Status = props => {
  const renderSwitch = () => {
    switch (props.status) {
      case 1:
        return <div className='active'>Active</div>
      case 2:
        return <div className='pending'>Pending</div>
      case 3:
        return <div className='view_only'>View Only</div>
      case 4:
        return <div className='inactive'>Inactive</div>
      case 5:
        return <div className='disabled'>Disabled</div>
      default:
        return null
    }
  }

  return <div className='status_box'>{renderSwitch()}</div>
}

export default Status
