import { ITechnician } from 'types/Appointment'
import { DispatQueueSortField, DispatchQueue } from '../Queue/types'
import { DispatchEvent, MatrixLogAPI } from '../types'
import moment from 'moment-timezone'

export const transformArrayToObj = <T>(
  array: T[],
  key = 'id',
  aditionalFields?: object,
) => {
  const obj: Record<string, T> = array?.reduce((acc, val) => {
    acc[val[key]] = { ...val, ...aditionalFields }
    return acc
  }, {})

  return obj
}

export const getAllEvents = (data: MatrixLogAPI) => {
  let events: DispatchEvent[] = []

  data?.columns?.forEach(col => {
    events = [...events, ...col.events]
  })
  return events
}

export const getAllQueueEvents = (data: DispatchQueue[]) => {
  let events: DispatchEvent[] = []

  data.forEach(queue => (events = [...events, ...queue.events]))

  return events
}

export const getAllTechnicians = (data: MatrixLogAPI) => {
  let technicians: ITechnician[] = []

  data?.columns?.forEach(col => {
    technicians = [
      ...technicians,
      ...col.technicians.map(tech => ({
        ...tech,
        column_template_id: col.column_template_id,
      })),
    ]
  })

  return technicians
}

export const sortObjectsByDate = (
  objects: DispatchEvent[],
  field: DispatQueueSortField = 'updated_at',
  direction: 'asc' | 'desc' = 'desc',
) => {
  const nullDateObjects: DispatchEvent[] = []
  const sortedObjects: DispatchEvent[] = objects
    .filter(obj => {
      const date = obj?.[field]
      if (date === null) {
        nullDateObjects.push(obj)
        return false
      }
      return true
    })
    .sort((a, b) => {
      const dateA = moment(a[field])
      const dateB = moment(b[field])
      return direction === 'asc' ? dateA.diff(dateB) : dateB.diff(dateA)
    })

  return sortedObjects.concat(nullDateObjects)
}
