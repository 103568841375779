import { EditOutlined } from '@ant-design/icons'
import styled from 'styled-components'

export const Wrapper = styled.div``

export const TaxContent = styled.div`
  text-align: end;
  display: grid;
  grid-template-columns: 1fr 150px;
  font-weight: 500;
  font-size: 1.15rem;
`
export const TaxInfo = styled.div``

export const AdditionalTaxInfo = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  color: grey;
  margin-top: -7px;
`

export const StyledEditIcon = styled(EditOutlined)`
  color: #1890ff;
  font-size: 1rem;
  margin-right: 5px;
  margin-top: 2px;
  cursor: pointer;
`
