import axiosOrcatec from './axiosInstance'

import { ContactI } from 'features/Notification/modals/NotificationModal/types'
import {
  ITemplate,
  INotificationSettings,
  NotificationModalType,
} from 'features/Notification/types'
import { AxiosResponse } from 'axios'
import { configV2API } from './helpers'

export const getEmailNotificationTemplate = (type: string) =>
  axiosOrcatec.get(`setting/notifications/${type}`).then(({ data }) => data)

export const getSMSNotificationTemplate = (type: string) =>
  axiosOrcatec.get(`setting/notifications/sms/${type}`).then(({ data }) => data)

export const getAppointmentsNotificationSetting = (id: number, type: string) =>
  axiosOrcatec
    .get(`appointments/${id}/notification-types/${type}/settings`)
    .then(({ data }) => data)

export const postAppointmentsNotificationSetting = (
  id: number,
  type: string,
  settings: object,
) =>
  axiosOrcatec
    .post(`appointments/${id}/notification-types/${type}/settings`, settings)
    .then(({ data }) => data)

export const getEmailNotificationHistory = (id: number, type: string) =>
  axiosOrcatec
    .get(`/appointments/${id}/notifications?type=${type}`)
    .then(({ data }) => data)

export const getSMSNotificationHistory = (id: number, type: string) =>
  axiosOrcatec
    .get(`/appointments/${id}/sms-notifications?type=${type}`)
    .then(({ data }) => data)

export const updateClientEmails = (data: object) =>
  axiosOrcatec
    .post<{ email: string }>(`/clients-emails`, data)
    .then(({ data }) => data)

export const sendEmailNotification = (id: number, data: any) =>
  axiosOrcatec
    .post(`/appointments/${id}/notifications`, data)
    .then(({ data }) => data)

export const sendEmailNotificationV2 = (id: number, data: any) =>
  axiosOrcatec
    .post(`/events/${id}/notifications`, data)
    .then(({ data }) => data)

export const sendSMSNotificationV2 = (id: number, data: any) =>
  axiosOrcatec
    .post(`/events/${id}/sms-notifications`, data)
    .then(({ data }) => data)

export const sendSMSNotification = (id: number, data: any) =>
  axiosOrcatec
    .post(`/appointments/${id}/sms-notifications`, data)
    .then(({ data }) => data)

export const sendRequestForReviewNotification = (id: number, data: any) =>
  axiosOrcatec
    .post(`/events/${id}/review-request`, data, configV2API)
    .then(({ data }) => data)

export const sendFormsNotification = (data: any) =>
  axiosOrcatec.post(`/custom-forms/email/send`, data).then(({ data }) => data)

export const getContacts = (id: number, type: string) =>
  axiosOrcatec
    .get<{ contacts: ContactI[] }>(`/${type}/${id}/contacts`)
    .then(({ data }) => data)

export const getProjectSMSNotification = <T>(id: number) =>
  axiosOrcatec
    .get<T>(`proposals/${id}/notifications/sms`)
    .then(({ data }) => data)

export const sendProjectSMSNotification = <T>(id: number, data: any) =>
  axiosOrcatec
    .post<T>(`proposals/${id}/notifications/sms`, data)
    .then(({ data }) => data)

export const getAllEmailNotificationHistory = (id: number) =>
  axiosOrcatec
    .get(`/appointments/${id}/notifications`)
    .then(({ data }) => data?.data && data.data)

export const getAllSmsNotificationHistory = (id: number) =>
  axiosOrcatec
    .get(`/appointments/${id}/sms-notifications`)
    .then(({ data }) => data?.data && data.data)

export const sendBulkSms = data =>
  axiosOrcatec
    .post(`/foreman_log_sheets/notifications/bulk-sms`, {
      notification_items: data,
    })
    .then(data => data?.data)

///new flow
export const getAllTemplates = () =>
  axiosOrcatec
    .get(`/setting/notification/templates`, { ...configV2API })
    .then(data => data?.data)

export const createTemplate = (params: ITemplate) =>
  axiosOrcatec
    .post(`/setting/notification/templates`, params, configV2API)
    .then(data => data?.data)

export const deleteTemplate = (templateId: number) =>
  axiosOrcatec
    .delete(`/setting/notification/templates/${templateId}`, configV2API)
    .then(data => data?.data)

export const updateTemplate = (templateId: number, params: ITemplate) =>
  axiosOrcatec
    .put(`/setting/notification/templates/${templateId}`, params, configV2API)
    .then(data => data?.data)

export const getTemplateById = (templateId: number) =>
  axiosOrcatec
    .get(`/setting/notification/templates/${templateId}`, { ...configV2API })
    .then(data => data?.data?.data)

export const getTimeSettings = () =>
  axiosOrcatec
    .get(`/setting/notification/time`, { ...configV2API })
    .then(data => data?.data?.data)
export const updateTimeSettings = params =>
  axiosOrcatec
    .put(`/setting/notification/time`, params, configV2API)
    .then(data => data?.data?.data)

export const getTemplateSettingByIndusty = (industryId: number) =>
  axiosOrcatec
    .get(`/setting/notification/industries/${industryId}/settings`, {
      ...configV2API,
    })
    .then(data => data?.data?.data)

export const getTemplateLogoByIndusty = (industryId: number) =>
  axiosOrcatec
    .get(`/setting/notification/industries/${industryId}/logo`, {
      ...configV2API,
    })
    .then(data => data?.data)

export const getTemplatesListByType = (
  type: 'appointment' | 'proposal' | 'general' | 'work_order',
) =>
  axiosOrcatec
    .get<ITemplate[]>(`/notification-templates/types/${type}`, {
      ...configV2API,
    })
    .then(data => data?.data?.templates)

export const updateTemplateSettingsByIndustry = (industryId: number, params) =>
  axiosOrcatec
    .patch(
      `/setting/notification/industries/${industryId}/settings`,
      params,
      configV2API,
    )
    .then(data => data?.data?.data)

export const getTemplatesByType = (
  type: 'appointment' | 'proposal' | 'general',
) =>
  axiosOrcatec
    .get(`/notification-templates/${type}`, { ...configV2API })
    .then(data => data?.data?.data)

export const getTemplateByTypyAndIndustry = (
  // type: 'appointment' | 'proposal' | 'general',
  type: NotificationModalType,
  industryId: number,
) =>
  axiosOrcatec
    .get<ITemplate[]>(
      `notification-templates/industries/${industryId}/${type}`,
      {
        ...configV2API,
      },
    )
    .then(data => data?.data?.data)

export const getProposalLink = (proposalId: number) =>
  axiosOrcatec
    .get(`/proposals/${proposalId}/public-link`, { ...configV2API })
    .then(data => data?.data?.link)

export const fetchEmailHistory = (id: number, type: string) =>
  axiosOrcatec
    .get(`/appointments/${id}/notifications?type=${type}`, { ...configV2API })
    .then(({ data }) => data?.data)

export const fetchReviewsHistory = (id: number) =>
  axiosOrcatec
    .get(`/events/${id}/review-requests`, { ...configV2API })
    .then(({ data }) => data?.data)

export const fetchSMSHistory = (id: number, type: string) =>
  axiosOrcatec
    .get(`/appointments/${id}/sms-notifications?type=${type}`, {
      ...configV2API,
    })
    .then(({ data }) => data?.data)

export const getNotificationSettings = (industryId: number) =>
  axiosOrcatec
    .get<AxiosResponse<INotificationSettings>>(
      `/setting/notification/industries/${industryId}/settings`,
      {
        ...configV2API,
      },
    )
    .then(({ data }) => data?.data)

export const updateNotifyStatus = (proposalId: number, status: boolean) =>
  axiosOrcatec
    .patch(`/proposals/${proposalId}/notify-status`, { status: status })
    .then(({ data }) => data?.data)

export const storeNotificationLog = (proposalId: number, data: object) =>
  axiosOrcatec.post(`/proposals/${proposalId}/logs`, data)
