import React, { FC } from 'react'
import { useLocation , RouteComponentProps } from 'react-router-dom'
import { Result } from 'antd'
import AuthLayout from '../../Layouts/AuthLayout/AuthLayout'
import MainButton from '../../components/buttons/MainButton'
import './style.scss'

const ChangedCrendentials: FC<RouteComponentProps> = ({ history }) => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const status = query.get('status') //used or success or expired
  const cred: { name: string; credential: string } = history.location.state

  const Title = () => {
    return status === 'used' ? (
      <div>Your credentials has been successfully edited before</div>
    ) : status === 'expired' ? (
      <div>The link has expired</div>
    ) : (
      <div>Your {cred?.name ? cred.name : 'credentials'} has been successfully changed </div>
    )
  }

  return (
    <AuthLayout>
      <Result
        status={status === 'used' ? 'info' : status === 'expired' ? 'warning' : 'success'}
        title={<Title />}
        subTitle={
          status !== 'expired' && `Go to the login page and log in using new ${cred?.name ? cred?.name : 'credentials'}`
        }
        extra={[
          <MainButton
            onClick={() => history.push('/login')}
            style={{ margin: '0 auto' }}
            key='btn'
            title='Go to login page'
          />,
        ]}
      />
    </AuthLayout>
  )
}

export default ChangedCrendentials
