import { useEffect, useState } from 'react'
import {
  Input as AntInput,
  Modal,
  notification,
  Popconfirm,
  Tooltip,
} from 'antd'
import { fetchUsersForTemplate } from '../../../../../../../api/User'
import {
  deleteProposalTemplate,
  fetchProposalTemplate,
  fetchProposalTemplates,
  putProposalTemplate,
} from '../../../../../../../api/Proposal'
import { CheckOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import CustomTable from '../../../../components/CustomTable'
import useSearch from '../../../../components/SearchHook'
import { IconPlaceholder } from '../../../../components/ItemsTable/utilsV2'

// import { defaultProposalModuleNames } from '../../../../../../../common/constants'
import { SmallPreloader } from '../../../../components/Preloader/SmallPreloader'
import CustomMultyAutocomplete from 'containers/MainContent/Orcatec/components/CustomMultyAutocomplete'
import Preloader from 'containers/MainContent/Orcatec/components/Preloader'

import './ModalProposalSectionTemplateLoad.scss'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { IScope } from 'features/Settings/UsersAndGroups/types'
import { ModuleName } from 'features/Settings/UsersAndGroups'
import { useAppDispatch } from 'store/Orcatec/hooks'
import { useAppSelector } from 'store/Orcatec/hooks'
// import { itemsUpdates } from 'features/Project/slices/projectItemsSlice'

const { Search } = AntInput

const head = [
  { label: '', sort: false },
  { label: 'Name of template', sort: true, sort_name: 'title' },
  { label: 'Creator', sort: true, sort_name: 'owner_template_name' },
  { label: 'Date', sort: true, sort_name: 'created_at' },
  { label: 'Sharing', sort: false },
  { label: 'Actions', sort: false },
]

const defaultAction = () => {}

export const ModalProposalSectionTemplateLoad = ({
  // onSubmit,
  onLoadSectionTemplate,
  modal,
  title,
  proposalForm,
  initForm,
  initProposalForm,
  setSectionTemplate,
  activeTab,
  sectionId,
  onDeleteSection,
  sectionIdx,
  ...props
}) => {
  const [isSaving, setisSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [templates, setTemplates] = useState({
    data: [],
  })
  const [, setNewTemplateName] = useState('')
  const [usersForShare, setUsersForShare] = useState([])
  const [, setIsFetchingUsers] = useState(true)
  const [, setSharedIds] = useState([])
  const [, setIsNewTemplateDefault] = useState(false)
  const [, setCurrentTemplateName] = useState(null)

  const [searchValue, changeSearchValue] = useState('')
  const [, changeSort] = useState('')
  const [sort_type, changeSortType] = useState('asc')
  const [editTitleIdx, setEditTitleIdx] = useState(null)
  const [loading, setLoading] = useState(false)
  // const [confirmModal, setConfirmModal] = useState({ visible: false, template: null })
  // const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [chosenRow, setChosenRow] = useState({ id: Number })
  const limit = 10
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )

  // const dispatch = useAppDispatch()

  useSearch(
    searchValue,
    (page, limit, debouncedValue) => {
      if (debouncedValue) {
        return fetchProposalTemplates({
          user_id: props.me.id,
          type: 2,
          page: 1,
          limit: limit,
          search: debouncedValue,
        }).then(data => {
          setTemplates(data)
        })
      } else {
        return fetchProposalTemplates({
          user_id: props.me.id,
          type: 2,
          page: 1,
          limit: limit,
        }).then(data => {
          setTemplates(data)
        })
      }
    },
    200,
    'table',
    1,
    limit,
  )

  const onSort = sort_name => {
    let sort_order = sort_type === 'asc' ? 'desc' : 'asc'
    fetchProposalTemplates({
      user_id: props.me.id,
      type: 2,
      page: templates.page,
      limit: limit,
      search: searchValue,
      column: sort_name,
      sort: sort_order,
    }).then(data => {
      setTemplates(data)
      changeSortType(sort_order)
      changeSort(sort_name)
    })
  }

  const onChangePage = page => {
    fetchProposalTemplates({
      user_id: props.me.id,
      type: 2,
      page,
      limit: limit,
      search: searchValue,
    }).then(data => {
      setTemplates(data)
    })
  }

  const resetState = () => {
    setisSaving(false)
    setNewTemplateName('')
    setSharedIds([])
    setIsNewTemplateDefault(false)
    setUsersForShare([])
    setIsFetchingUsers(true)
    setTemplates([])
    setTemplates({
      data: [],
    })
    setCurrentTemplateName(null)
  }

  const onCancel = () => {
    setChosenRow({ id: Number })
    props.onCancel()
  }

  useEffect(() => {
    if (modal) {
      setLoading(true)
      Promise.all([
        fetchUsersForTemplate(),
        fetchProposalTemplates({
          user_id: props.me.id,
          type: 2,
          page: 1,
          limit: limit,
          search: '',
          sort: '',
          column: '',
        }),
      ])
        .then(([usersData, templates]) => {
          const { users, template_name } = usersData
          const usersToState = users.filter(user => user.id !== props.me.id)
          setCurrentTemplateName(template_name)
          setUsersForShare(usersToState)
          setTemplates(templates)
        })
        .finally(() => {
          setIsFetchingUsers(false)
          setLoading(false)
        })
    } else {
      resetState()
    }
  }, [modal])

  const onSearch = value => {
    changeSearchValue(value)
  }

  const onChangeName = idx => e => {
    const { value } = e.currentTarget
    const newTemplatesData = { ...templates }
    newTemplatesData.data[idx].title = value
    setTemplates(newTemplatesData)
  }

  const onSave = () => {
    const { title, id } = templates.data[editTitleIdx]
    if (!title) return
    putProposalTemplate(id, { title })
      .then(() => notification.success({ message: 'Saved!' }))
      .catch(e => notification.error({ message: e?.message }))
    setEditTitleIdx(null)
  }

  const onSelectUsers = idx => (e, selectedUsersIds) => {
    const { value } = e.target
    const newTemplatesData = { ...templates }

    newTemplatesData.data[idx].is_shared =
      typeof value === 'string'
        ? newTemplatesData.data[idx].is_shared
        : selectedUsersIds
    setTemplates(newTemplatesData)
    putProposalTemplate(newTemplatesData.data[idx].id, {
      is_shared: newTemplatesData.data[idx].is_shared,
    })
      .then(() => notification.success({ message: 'Success' }))
      .catch(e => notification.error({ message: e?.message }))
  }

  const onDeleteTemplate = (idx, isMyTemplate) => () => {
    const newTemplatesData = { ...templates }
    const template = newTemplatesData.data[idx]
    const isLastTemplate = newTemplatesData.data?.length === 1
    const notFirstPage = +newTemplatesData.page !== 1
    const is_shared = isMyTemplate
      ? template.is_shared
      : [...template.is_shared]
    if (!isMyTemplate) is_shared.splice(is_shared.indexOf(props.me.id), 1)
    const request = isMyTemplate
      ? deleteProposalTemplate(template.id)
      : putProposalTemplate(template.id, { is_shared })
    const requestPage = +newTemplatesData.page
      ? isLastTemplate && notFirstPage
        ? +newTemplatesData.page - 1
        : +newTemplatesData.page
      : 1
    setIsDeleting(template.id)
    request
      .then(() => {
        return fetchProposalTemplates({
          user_id: props.me.id,
          type: 2,
          page: requestPage,
          limit: limit,
          search: '',
          sort: '',
          column: '',
        })
      })
      .then(data => {
        if (props.me.proposal_template_id === template.id)
          selectDefaultTemplate(null)
        setTemplates(data)
        return data
      })
      .then(() => notification.success({ message: 'Successfully deleted!' }))
      .catch(e => notification.error({ message: e?.message }))
      .finally(() => {
        setIsDeleting(false)
      })
  }

  const onLoadTemplate = async ({ id }) => {
    setLoading(true)

    await onLoadSectionTemplate(id)

    setLoading(false)
    onCancel()
    // return fetchProposalTemplate(id)
    //   .then(data => {
    //     setSectionTemplate(data.body, sectionId, sectionIdx)
    //   })
    //   .then(() => onSubmit())
    //   .catch(e => notification.error({ message: e?.message }))
    //   .finally(() => {
    //     onCancel()
    //     setLoading(false)
    //   })
  }

  const selectDefaultTemplate = id => e => {
    e.preventDefault()
    e.stopPropagation()
    if (id === props.me.proposal_template_id) id = null
    props
      .updateUserTemplate(props.me.id, { template_id: id })
      .then(() =>
        notification.success({ message: 'Template was chosen as default' }),
      )
      .catch(e => notification.error({ message: e?.message }))
  }

  const setTitleIdx = idx => () => setEditTitleIdx(idx)

  const stopPropagation = e => e.stopPropagation()

  const checkVisibility = (sharedArray, isMyTemplate, perm) => {
    if (!perm) return false
    if (perm === IScope.ALL) return true
    return isMyTemplate || sharedArray.includes(props.me.id)
  }

  const rows = !templates.data?.length
    ? []
    : templates.data.reduce((acc, template, idx) => {
        const sharedArray =
          Array.isArray(template.is_shared) && !!template.is_shared?.length
            ? template.is_shared.filter(
                id =>
                  usersForShare.findIndex(user => user.id === id) >= 0 ||
                  props.me.id === id,
              )
            : []

        const isMyTemplate = template.user_id === props.me.id

        const visible = checkVisibility(
          sharedArray,
          isMyTemplate,
          permissions.project_can_read_templates,
        )

        const canEdit = checkVisibility(
          sharedArray,
          isMyTemplate,
          permissions.project_can_edit_template,
        )
        const canDelete = checkVisibility(
          sharedArray,
          isMyTemplate,
          permissions.project_can_delete_template,
        )

        if (visible)
          acc.push(
            <tr
              className={
                'closed' +
                (props.proposalStatus === 2 ||
                props.proposalStatus === 4 ||
                props.proposalStatus === 5 ||
                isSaving === template.id ||
                isDeleting === template.id
                  ? ' contract'
                  : '') +
                `${chosenRow.id === idx && ' chosen'}`
              }
              key={idx}
              onClick={() => setChosenRow({ id: idx })}
            >
              <td onClick={stopPropagation}></td>
              <td>
                {editTitleIdx !== idx ? (
                  template.title
                ) : (
                  <AntInput
                    value={template.title}
                    onChange={onChangeName(idx)}
                    suffix={<CheckOutlined style={{ cursor: 'pointer' }} />}
                    onBlur={onSave}
                    autoFocus={true}
                    style={{ paddingRight: '2px' }}
                  />
                )}
              </td>
              <td>{isMyTemplate ? 'You' : template.owner_template_name}</td>
              <td>
                {moment
                  .utc(template.created_at)
                  .local()
                  .format('MM/DD/YYYY, hh:mm A')}
              </td>
              <td onClick={stopPropagation}>
                {isMyTemplate ? (
                  <>
                    <CustomMultyAutocomplete
                      onChange={onSelectUsers(idx)}
                      value={sharedArray}
                      filterOptions={x => x}
                      options={usersForShare.map(user => user.id)}
                      getOptionLabel={id => {
                        const user = usersForShare.find(u => u.id === +id)
                        return user ? `${user.full_name} - ${user.title}` : ''
                      }}
                      checkIsChoosen={id => sharedArray.includes(+id)}
                      inputValue={
                        sharedArray?.length
                          ? `Shared with ${sharedArray?.length +
                              (sharedArray?.length > 1 ? ' users' : ' user')}`
                          : 'Not shared'
                      }
                    />
                  </>
                ) : (
                  '--'
                )}
              </td>
              <td onClick={stopPropagation}>
                <div className='w-100 d-flex justify-content-between align-items-center'>
                  {isSaving !== template.id ? (
                    <Tooltip
                      title='Choose this template'
                      visible={chosenRow.id === idx}
                    >
                      <i
                        className='mdi mdi-check-circle-outline px-1 py-0'
                        style={{
                          cursor: 'pointer',
                          color: chosenRow.id === idx ? 'green' : '',
                        }}
                        // onClick={onLoadTemplate(template)}
                        onClick={
                          // showConfirmModal
                          () => onLoadTemplate(template, idx)
                          // : () => setConfirmModal({ template: template, visible: true })
                        }
                      />
                    </Tooltip>
                  ) : (
                    <SmallPreloader />
                  )}

                  {canEdit && (
                    <i
                      className='mdi mdi-pencil px-1 py-0'
                      style={{ cursor: 'pointer' }}
                      onClick={setTitleIdx(idx)}
                    />
                  )}

                  {isDeleting !== template.id ? (
                    canDelete && (
                      <Popconfirm
                        title={'Are you sure to delete this template?'}
                        onConfirm={onDeleteTemplate(idx, isMyTemplate)}
                      >
                        <i
                          className='mdi mdi mdi-delete px-1 py-0'
                          style={{ cursor: 'pointer' }}
                        />
                      </Popconfirm>
                    )
                  ) : (
                    <SmallPreloader />
                  )}
                </div>
              </td>
            </tr>,
          )
        return acc
      }, [])

  return (
    <>
      <Modal
        centered
        width={'1078px'}
        visible={modal}
        title={title}
        onCancel={onCancel}
        footer={null}
        wrapClassName='modal-proposal-section-template-load'
      >
        <div className='w-100 d-flex justify-content-end'>
          <Search
            placeholder='input search text'
            onSearch={onSearch}
            onChange={e => onSearch(e.currentTarget.value)}
            style={{ width: 250 }}
          />
        </div>
        {!loading ? (
          <CustomTable
            parentClass='load-template-custom-table'
            showEntries={false}
            search={false}
            head={head}
            rows={rows}
            sortOrder={sort_type}
            total={+templates.total}
            pageSize={+templates.limit}
            page={+templates.page}
            onSort={onSort}
            onChangePage={onChangePage}
            onSearch={onSearch}
            arrowUp='mdi mdi-chevron-up'
            arrowDown='mdi mdi-chevron-down'
            hideTotal={true}
            disableClientsTableClass={true}
          >
            {rows}
          </CustomTable>
        ) : (
          <Preloader />
        )}
      </Modal>
    </>
  )
}
