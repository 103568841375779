import './style.scss'
import { Select } from 'antd'
import { timezones } from 'containers/MainContent/Orcatec/TimeCards/timezone.js'
import { useSelector } from 'react-redux'

const { Option } = Select

export const TimezonePicker = ({ timezone, setTimezone, error }) => {
  const me = useSelector(state => state.orcatec.user.me)
  const lc = JSON.parse(localStorage.getItem(me?.parent_id))
  const [value] = timezones.filter(({ utc }) => utc.includes(timezone))
  const onChange = value => {
    const [findedTimezone] = timezones.filter(({ name }) =>
      name.includes(value),
    )
    const [timezonToLS] = findedTimezone.utc
    setTimezone(timezonToLS)
    localStorage.setItem(
      me.parent_id,
      JSON.stringify({
        ...lc,
        [me.id]: {
          ...lc?.[me.id],
          timezone: timezonToLS,
        },
      }),
    )
  }

  return (
    <div className='timezone-picker'>
      <Select
        placeholder='Select timezone'
        onChange={onChange}
        value={value?.name}
      >
        {timezones.map(({ name, abbr, text }, idx) => {
          return (
            <Option key={idx} value={name}>
              {text} {abbr} {name}
            </Option>
          )
        })}
      </Select>
      {!!error && <p style={{ color: 'red', fontSize: '12px' }}>{error}</p>}
    </div>
  )
}
