import { Component, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { UserRole } from 'types/User'
// import PagesLanding from '../MainContent/Orcatec/Landing'
import PagesLogin from '../MainContent/Orcatec/Login'
import PagesRegisterInfo from '../MainContent/Orcatec/Register/Info'
import PagesRegisterCard from '../MainContent/Orcatec/Register/Card'
import RegisterFinishPage from '../MainContent/Orcatec/Register/Finish'
// import PageProposalForm from '../MainContent/Orcatec/Proposals/components/Form'
import ChangedCrendentialsPage from '../MainContent/Orcatec/Login/ChangedCredndentials/ChangedCrendentials'

// import ReviewPage from '../MainContent/Orcatec/Review'
import ReviewPage from 'pages/ReviewRequest'

import PageLayout from '../MainContent/Orcatec/Layouts/PageLayout'
import PageProposalPreview from '../MainContent/Orcatec/Proposals/components/PageProposalPreview/PageProposalPreview'
import PublicAppointment from '../MainContent/Orcatec/PublicAppointment'
import PublicEventPage from 'pages/PublicEvent'

import { Result } from 'antd'
//import withSubscription from '../../hoc/withSubscription'
import Preloader from '../MainContent/Orcatec/components/Preloader'
// import WorkerPageContainer from 'containers/MainContent/Orcatec/WorkSchedule_old/components/WorkerPage'
import lazyWithRetry from './lazyWithRetry'
import MainButton from '../MainContent/Orcatec/components/buttons/MainButton'
// import PageMembershipSettings from 'pages/Settings/Membership/PageMembershipSettings'
// import PageMembershipFormSettings from 'pages/Settings/Membership/PageMembershipFormSettings'
import { PageMemberships } from 'pages/Membership/PageMemberships'
// import CustomFormPage from 'pages/Forms/index'
import CustomFormPage from 'features/Forms/FormToPrint'
import { connectModulePermissions } from 'features/Settings/UsersAndGroups/permissionSlice'
import ProjectPage from 'pages/ProjectPage'
const PageTimeCards = lazyWithRetry(() =>
  import('../MainContent/Orcatec/TimeCards'),
)
//const PreviewContract = lazyWithRetry(() => import('../MainContent/Orcatec/PreviewContract'))
const PagesAppointment = lazyWithRetry(() =>
  import('../MainContent/Orcatec/CreateAppointmentV2'),
)
const JobPage = lazyWithRetry(() =>
  import('../MainContent/Orcatec/Jobs/components/JobPage'),
)
// const PageAppointment = lazyWithRetry(() => import('../MainContent/Orcatec/CreateAppointmentV2'))
//const SettingsPreview = lazyWithRetry(() => import('./../MainContent/Orcatec/Settings/PreviewPage'))
//const PreviewProposal = lazyWithRetry(() => import('./../MainContent/Orcatec/Settings/Proposal/PreviewProposal'))

//const SettingsCompanyWebsite = lazyWithRetry(() => import('./../MainContent/Orcatec/Settings/CompanyWebsite/Index'))
const SettingsCompanyInfo = lazyWithRetry(() =>
  import('../../pages/Settings/index'),
)
// const SettingsPaymentSetup = lazyWithRetry(() => import('./../MainContent/Orcatec/Settings/PaymentSetup/index.js'))
// const SettingsPaymentSetup = lazyWithRetry(() =>
//   import('./../MainContent/Orcatec/Settings/ConsumerPayments'),
// )
// const SettingsProposalOld = lazyWithRetry(() => import('../MainContent/Orcatec/Settings/Proposal'))
// const SettingsProposal = lazyWithRetry(() =>
//   import('pages/Settings/Project/ProjectSettingsPage'),
// )
/* const SettingsSubscription = lazyWithRetry(() =>
  import('../MainContent/Orcatec/Settings/Subscription/index.js'),
)
const SettingsSuppliers = lazyWithRetry(() =>
  import('./../MainContent/Orcatec/Settings/Suppliers'),
)
//const SettingsServiceContract = lazyWithRetry(() => import('./../MainContent/Orcatec/Settings/ServiceContract'))
const SettingsMatrix = lazyWithRetry(() =>
  import('../MainContent/Orcatec/Settings/Dispatch/Dispatch.tsx'),
)
const SettingsUsers = lazyWithRetry(() => import('pages/Settings/Users'))
const UserPage = lazyWithRetry(() => import('pages/Settings/Users/UserPage'))
const SettingsForms = lazyWithRetry(() =>
  import(
    '../MainContent/Orcatec/Settings/PublicAppointmentsForms/PublicAppointmentsForms'
  ),
)
// const SettingsNotificationsOld = lazyWithRetry(() => import('../MainContent/Orcatec/Settings/Notifications'))
const SettingsNotifications = lazyWithRetry(() =>
  import('../../pages/Settings/Notification/index'),
)
const SettingsTags = lazyWithRetry(() =>
  import('../MainContent/Orcatec/Settings/Tags/Tags.jsx'),
)
// const SettingsMessaging = lazyWithRetry(() => import('../../pages/business/settings/SettingsMessaging'))
const SettingsMessaging = lazyWithRetry(() =>
  import('../../pages/Settings/Messaging/index'),
)
const SettingsCalls = lazyWithRetry(() =>
  import('../../pages/Settings/Calls/index'),
)

const SettingsItemsEquipment = lazyWithRetry(() =>
  import('./../MainContent/Orcatec/Settings/ItemsV2/Equipment'),
)
const SettingsItemsParts = lazyWithRetry(() =>
  import('./../MainContent/Orcatec/Settings/ItemsV2/Parts'),
)
const SettingsItemsSupplies = lazyWithRetry(() =>
  import('./../MainContent/Orcatec/Settings/ItemsV2/Supplies'),
)
const SettingsItemsInstants = lazyWithRetry(() =>
  import('./../MainContent/Orcatec/Settings/ItemsV2/Instants'),
)
const SettingsItemsRebates = lazyWithRetry(() =>
  import('./../MainContent/Orcatec/Settings/ItemsV2/Rebates'),
)
const SettingsItemsScopeOfWork = lazyWithRetry(() =>
  import('./../MainContent/Orcatec/Settings/ItemsV2/ScopeOfWork'),
)
const SettingsItemsWarranty = lazyWithRetry(() =>
  import('./../MainContent/Orcatec/Settings/ItemsV2/Warranty'),
) */

const PagesMatrix = lazyWithRetry(() =>
  import('./../MainContent/Orcatec/Matrix/index.tsx'),
)
const PageInstantAppointment = lazyWithRetry(() =>
  import('./../MainContent/Orcatec/InstantAppointment'),
)
const PagesMail = lazyWithRetry(() => import('../../pages/Email'))
// const PageArticles = lazyWithRetry(() => import('../MainContent/Orcatec/Articles/Index'))
// const PageAddArticle = lazyWithRetry(() => import('../MainContent/Orcatec/Articles/AddArticle/Index'))
const PageClients = lazyWithRetry(() =>
  import('../../pages/Contacts/ContactsPage'),
)
// const PageClientForm = lazyWithRetry(() =>
//   import('../MainContent/Orcatec/Clients/components/Form/PageClientForm'),
// )
// const PageDashboard = lazyWithRetry(() => import('../MainContent/Orcatec/Dashboard/Index'))
const PageMap = lazyWithRetry(() =>
  import('../MainContent/Orcatec/Map' /* webpackChunkName: 'LoginPageM' */),
)
const PageProperties = lazyWithRetry(() =>
  import('../MainContent/Orcatec/Properties'),
)
const PagePropertiesForm = lazyWithRetry(() =>
  import('../MainContent/Orcatec/Properties/components/PagePropertyForm'),
)
const PageProposals = lazyWithRetry(() =>
  import('../MainContent/Orcatec/Proposals/Index'),
)
const FastPayment = lazyWithRetry(() =>
  import('../MainContent/Orcatec/FastPayment'),
)
const Accounting = lazyWithRetry(() => import('../../pages/Accounting/index'))
const Expenses = lazyWithRetry(() => import('../../pages/Expenses/index'))

const Balances = lazyWithRetry(() => import('../../pages/Balances/index'))
const Progress = lazyWithRetry(() => import('pages/Progress/index'))
const Dashboard = lazyWithRetry(() => import('../../pages/Dashboard'))
// const PageProposalForm = lazyWithRetry(() =>
//   import('../MainContent/Orcatec/Proposals/components/PaymentSetupForm'),
// )
const PageSchedule = lazyWithRetry(() =>
  import('../MainContent/Orcatec/Schedule/Index'),
)
// const PageToDo = lazyWithRetry(() => import('../MainContent/Orcatec/ToDo'))
const PageToDo = lazyWithRetry(() => import('pages/ToDo'))
const PageReviews = lazyWithRetry(() => import('pages/Reviews'))
// const PageQuickbooks = lazyWithRetry(() => import('./../MainContent/Orcatec/QuickBooks'))
const PageQuickbooks = lazyWithRetry(() =>
  import('pages/Quickbooks/PageQuickbooks'),
)
// const PageWorkSchedule = lazyWithRetry(() => import('./../MainContent/Orcatec/WorkSchedule'))
const PageJobsSchedule = lazyWithRetry(() =>
  import('./../MainContent/Orcatec/Jobs'),
)
// const Messaging = lazyWithRetry(() => import('../../pages/business/Messaging'))
const Messaging = lazyWithRetry(() => import('../../pages/Messaging/index'))
const CallsPage = lazyWithRetry(() => import('../../pages/Calls/CallsPage'))
// const PageFormBuilder = lazyWithRetry(() =>
//   import('pages/Settings/FormBuilder'),
// )
const CustomForm = lazyWithRetry(() => import('../../pages/Forms/index'))

// const ProjectScheduleView = lazyWithRetry(() =>
//   import('../../pages/ProjectScheduleView/index'),
// )
// const EmailCompaign = lazyWithRetry(() => import('./../MainContent/Orcatec/EmailCompaign'))
// import AppointmentToPDF from '../MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentPrintPDF/AppointmentPrintPDF'
// import SettingsEmail from '../MainContent/Orcatec/Settings/Email'
// import AppointmentToPDF from '../MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentPrintPDF/AppointmentPrintPDF'
// const noRightsStyle = { width: '100%', fontSize: '1.5rem' }

// import { Companies } from 'types/Company'
import AuthPage from 'pages/AuthPage'
import { isEmailModuleAvaliable } from 'store/Orcatec/selectors/company'

const NoRights = ({ message }) => (
  <PageLayout>
    <Result
      status='403'
      subTitle={
        !message
          ? 'You don’t have access to this module, please contact your administrator'
          : message
      }
    />
  </PageLayout>
)

const ProtectedRoute = ({
  component: Component,
  isAllowed,
  redirectTo,
  messageIfNotAllowed,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAllowed ? (
          !redirectTo ? (
            <Component {...props} />
          ) : (
            <Redirect to={redirectTo} />
          )
        ) : (
          <NoRights message={messageIfNotAllowed} />
        )
      }
    />
  )
}

class mainbuilder extends Component {
  render() {
    const {
      initialized,
      modulePermissions,
      company,
      me,
      isEmailAvaliable,
    } = this.props

    const isActiveStatus = !!this.props.me.status

    const {
      project,
      schedule,
      accounting,
      contacts,
      dashboard,
      dispatch,
      forms,
      forms_template,
      forms_detailed,
      map,
      properties,
      time_cards,
      settings,
      fast_payment,
      todo,
      jobs,
      quickbooks,
      reviews,
      instant_appointment,
      memberships,
      messaging,
      call_tracking,
      balances,
      expenses,
      work_progress,
      estimates,
    } = modulePermissions() || {}

    // const isJuanMilanFromSafetyNet = this.props.me.id === 1450

    // const emailsForAlexCompany = [
    //   Companies.AirLoop,
    //   Companies.BuildX,
    //   Companies.HVAC,
    //   Companies.Sony,
    //   Companies.OrcatecCorporate,
    // ].includes(company.id)

    const isSA = [UserRole.SUPER_ADMIN, UserRole.DEFAULT_SUPER_ADMIN].includes(
      me?.role_id,
    )

    const renderRoutesUser = () => (
      <Switch>
        <Route
          exact
          path='/reviews/review'
          render={props => <ReviewPage {...props} />}
        />
        <Route
          exact
          path='/'
          render={props => (
            <>
              <Redirect to='/auth/signin' />
            </>
          )}
        />

        <Suspense fallback={<Preloader />}>
          <Route path='/matrix' render={() => <Redirect to='/dispatch' />} />
          <ProtectedRoute
            path='/dispatch/:date?'
            isAllowed={dispatch && isActiveStatus}
            component={PagesMatrix}
          />

          <ProtectedRoute
            path='/instant-appointment'
            isAllowed={instant_appointment && isActiveStatus}
            component={PageInstantAppointment}
          />

          <ProtectedRoute
            path='/map'
            isAllowed={map && isActiveStatus}
            component={PageMap}
          />

          <ProtectedRoute
            path='/emails'
            isAllowed={isActiveStatus && (isEmailAvaliable || isSA)}
            component={PagesMail}
          />

          <ProtectedRoute
            exact
            path='/projects'
            isAllowed={project && isActiveStatus}
            component={PageProposals}
          />
          <ProtectedRoute
            exact
            path='/estimates'
            isAllowed={estimates && isActiveStatus}
            component={PageProposals}
          />
          {/* <ProtectedRoute
            path='/proposals-form/:id?'
            isAllowed={project && isActiveStatus}
            component={
              isJuanMilanFromSafetyNet ? ProjectScheduleView : PageProposalForm
            }
          /> */}

          {/* <Redirect from='/proposals-form' to='/projects' /> */}

          <ProtectedRoute
            path='/projects/:id/:preview?'
            isAllowed={project && isActiveStatus}
            component={ProjectPage}
          />
          {/* <ProtectedRoute
            exact
            path='/proposal/preview/:id?'
            isAllowed={project && isActiveStatus}
            component={PageProposalPreview}
          /> */}

          <ProtectedRoute
            exact
            path='/fast-payment'
            isAllowed={fast_payment && isActiveStatus}
            component={FastPayment}
          />

          <ProtectedRoute
            exact
            path='/clients/:id?'
            isAllowed={contacts && isActiveStatus}
            component={PageClients}
          />

          <ProtectedRoute
            path='/properties'
            isAllowed={properties && isActiveStatus}
            component={PageProperties}
          />
          <ProtectedRoute
            path='/properties-form/:id?'
            isAllowed={properties && isActiveStatus}
            component={PagePropertiesForm}
          />

          <ProtectedRoute
            path='/schedule'
            isAllowed={schedule && isActiveStatus && this.props.me.useAsTech}
            messageIfNotAllowed={
              !schedule || !isActiveStatus
                ? ''
                : `To have access to this module, you have to be present as technician on the Dispatch Board. Please contact your administrator.`
            }
            component={PageSchedule}
          />

          <ProtectedRoute
            path='/to-do'
            isAllowed={todo && isActiveStatus}
            component={PageToDo}
          />

          <ProtectedRoute
            path='/time-cards'
            isAllowed={time_cards && isActiveStatus}
            component={PageTimeCards}
          />

          <ProtectedRoute
            path='/appointment/:id?'
            isAllowed={isActiveStatus}
            component={PagesAppointment}
          />
          <ProtectedRoute
            path='/jobs/:id?'
            isAllowed={isActiveStatus}
            component={JobPage}
          />

          <Route path='/norigts' render={props => <NoRights />} />

          <ProtectedRoute
            path='/reviews'
            isAllowed={reviews && isActiveStatus}
            component={PageReviews}
          />

          <ProtectedRoute
            path='/quickbooks'
            isAllowed={quickbooks && isActiveStatus}
            component={PageQuickbooks}
          />

          <ProtectedRoute
            exact
            path='/memberships'
            isAllowed={memberships && isActiveStatus}
            component={PageMemberships}
          />

          <ProtectedRoute
            exact
            path='/calls'
            isAllowed={call_tracking && isActiveStatus}
            component={CallsPage}
          />

          <ProtectedRoute
            exact
            path='/jobs-list'
            isAllowed={jobs && isActiveStatus}
            component={PageJobsSchedule}
          />
          {/* <ProtectedRoute
            path='/work-schedule/:id'
            isAllowed={jobs && isActiveStatus}
            component={WorkerPageContainer}
          /> */}

          <ProtectedRoute
            exact
            path='/accounting'
            isAllowed={
              (accounting || balances || expenses || work_progress) &&
              isActiveStatus
            }
            component={Accounting}
          />

          <ProtectedRoute
            exact
            path='/balances'
            isAllowed={
              (accounting || balances || expenses || work_progress) &&
              isActiveStatus
            }
            component={Balances}
          />

          <ProtectedRoute
            exact
            path='/expenses'
            isAllowed={
              (accounting || balances || expenses || work_progress) &&
              isActiveStatus
            }
            component={Expenses}
          />

          <ProtectedRoute
            exact
            path='/progress'
            isAllowed={
              (accounting || balances || expenses || work_progress) &&
              isActiveStatus
            }
            component={Progress}
          />

          <ProtectedRoute
            exact
            path='/messaging'
            isAllowed={messaging}
            component={Messaging}
          />

          <ProtectedRoute
            exact
            path='/custom-form'
            isAllowed={
              (forms || forms_template || forms_detailed) && isActiveStatus
            }
            component={CustomForm}
          />

          {/* <ProtectedRoute
            path='/settings/email'
            isAllowed={settings}
            component={SettingsEmail}
          /> */}

          <ProtectedRoute
            exact
            path='/project/preview'
            isAllowed={settings && project && isActiveStatus}
            component={PageProposalPreview}
          />

          <ProtectedRoute
            path='/settings/:page'
            // isAllowed={settings[Module.SETTINGS] && isActiveStatus}
            isAllowed={isActiveStatus}
            component={SettingsCompanyInfo}
          />

          <ProtectedRoute
            path='/dashboard'
            isAllowed={dashboard && isActiveStatus}
            component={Dashboard}
          />

          <Route
            exact
            path='/404/:item?'
            render={props => (
              <>
                <Result
                  status='404'
                  title='404'
                  subTitle={
                    props.match.params.item
                      ? `Sorry, the ${props.match.params.item} does not exist.`
                      : 'Sorry, the page you visited does not exist.'
                  }
                />
                <MainButton
                  style={{ margin: '0 auto' }}
                  title={'Home page'}
                  onClick={() => props.history.push('/projects')}
                />
              </>
            )}
          />

          <Route
            exact
            path='/403/:item?'
            render={props => (
              <>
                <Result
                  status='403'
                  title='Access denied'
                  subTitle='You have no permission to access this page. Please contact your administrator.'
                />
                <MainButton
                  style={{ margin: '0 auto' }}
                  title={'Home page'}
                  onClick={() => props.history.push('/projects')}
                />
              </>
            )}
          />

          <Route
            path='/lead-appointments'
            render={props => <PublicAppointment {...props} />}
          />

          {/* <Redirect to='/dispatch' /> */}
        </Suspense>
      </Switch>
    )

    const renderRoutesGuest = () => {
      if (window.location.pathname === '/')
        this.props.history.push('/auth/signin')

      return (
        <Switch>
          <Route exact path='/' render={() => <Redirect to='/auth/signin' />} />
          {/* <Route
            exact
            path='/login'
            render={props => <PagesLogin {...props} />}
          /> */}
          <Route path='/auth/:page' render={props => <AuthPage {...props} />} />
          <Route
            exact
            path='/login/reset-password'
            render={props => <PagesLogin {...props} />}
          />
          <Route
            exact
            path='/login/create-password'
            render={props => <PagesLogin {...props} />}
          />
          <Route
            exact
            path='/login/credentials-changed'
            render={props => <ChangedCrendentialsPage {...props} />}
          />
          <Route
            exact
            path='/register/info'
            render={props => <PagesRegisterInfo {...props} />}
          />
          <Route
            exact
            path='/register/info/email-confirm'
            render={props => <PagesRegisterInfo {...props} />}
          />
          <Route
            exact
            path='/register/card'
            render={props => <PagesRegisterCard {...props} />}
          />
          <Route
            exact
            path='/register/finish'
            render={props => <RegisterFinishPage {...props} />}
          />
          <Route
            exact
            path='/reviews/review'
            render={props => <ReviewPage {...props} />}
          />
          <Route
            exact
            path='/preview'
            render={props => <PageProposalPreview {...props} />}
          />
          <Route
            path='/custom-forms/:id'
            render={props => <CustomFormPage {...props} />}
          />
          <Route
            path='/public/events/:hash?'
            render={props => <PublicEventPage {...props} />}
          />
          <Route
            exact
            path='/public-appointments'
            render={props => <PublicAppointment {...props} />}
          />
          <Route
            exact
            path='/404/:item?'
            render={props => (
              <Result
                status='404'
                title='404'
                subTitle={
                  props.match.params.item
                    ? `Sorry, the ${props.match.params.item} does not exist.`
                    : 'Sorry, the page you visited does not exist.'
                }
              />
            )}
          />
          {/* <Route exact path='*' render={() => <Redirect to='/auth/signin' />} /> */}
          <Redirect to='/auth/signin' />
        </Switch>
      )
    }

    return initialized ? renderRoutesUser() : renderRoutesGuest()
  }
}
const mapStateToProps = state => {
  const modulePermissions = connectModulePermissions(state)
  const isEmailAvaliable = isEmailModuleAvaliable(state)
  return {
    modulePermissions,
    isEmailAvaliable,
  }
}
export default connect(mapStateToProps)(mainbuilder)
