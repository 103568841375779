import InputField from 'containers/MainContent/Orcatec/components/UI/InputField'
import { Popconfirm, Radio, Modal } from 'antd'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import React, { useState } from 'react'

import { IPhone } from 'features/Messaging/types'

import { notificationMessage, parsePhoneNumber } from 'features/Messaging/utils'
import { getListNumbers, buyNumber } from 'api/Calls'

import styled from 'styled-components'
import { useQueryClient } from '@tanstack/react-query'

interface IGetNewNumberProps {
  toggleModal: (isBuyNumber?: boolean) => void
  visible: boolean
  updatePhones: (isDispatch?: boolean) => void
  title: string
}

const INITIAL_AREA_CODE = '619'
const DEFAULT_COUNTRY = 'US'
const PHONE_TYPE = 'local'
// const ATTACHED_USERS_INFO_TEXT =
//   'Users attached to this number will be able to see chats associated with this phone number, as well as participate in messaging and send notifications.'

export const BuyNumberModal: React.FC<IGetNewNumberProps> = ({
  toggleModal,
  visible,
  title,
}) => {
  const queryClient = useQueryClient()
  const [areaCode, setAreaCode] = useState(INITIAL_AREA_CODE)
  const [allNumbers, setAllNumbers] = useState<IPhone[]>([])
  const [selectedNumber, setSelectedNumber] = useState('')
  const [pageIndex, setPageIndex] = useState(1)
  // loaders
  const [getNewNumberLoader, setGetNewNumberLoader] = useState(false)
  const [getNumbersLoader, setGetNumbersLoader] = useState(false)
  const [isSearch, setIsSearch] = useState(false)

  const getNewPhoneNumbers = () => {
    setGetNumbersLoader(true)
    setSelectedNumber('')

    const params = {
      countryCode: DEFAULT_COUNTRY,
      areaCode: `1${areaCode}`,
      phoneType: PHONE_TYPE,
      pageIndex,
    }

    getListNumbers(params)
      .then(({ phones }) => {
        setIsSearch(true)
        if (phones) {
          setAllNumbers(phones)
          setPageIndex(prev => prev + 1)
        } else {
          getListNumbers({ ...params, pageIndex: pageIndex - 1 })
            .then(() => setAllNumbers(phones))
            .catch(e => console.error(e))
        }
      })
      .catch(e => notificationMessage('error', `${e.response?.data?.message}`))
      .finally(() => setGetNumbersLoader(false))
  }

  const handleChangeAreaCode = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (value.length >= 4 || value === 'e') {
      return
    }
    setAreaCode(value)
    setIsSearch(false)
  }

  const buyNewNumber = () => {
    setGetNewNumberLoader(true)
    const data = { phoneNumber: selectedNumber }
    buyNumber(data)
      .then(res => {
        queryClient.invalidateQueries({ queryKey: ['calls/settings'] })
        notificationMessage('success', 'Number successfully purchased!')
        return res
      })
      .then(res => {
        toggleModal(res)
      })
      .catch(e => notificationMessage('error', `${e.response?.data?.message}`))
      .finally(() => setGetNewNumberLoader(false))
  }

  return (
    <Modal
      visible={visible}
      onCancel={toggleModal}
      footer={null}
      title={title}
      zIndex={100}
      destroyOnClose
      centered={true}
      afterClose={() => {
        setAllNumbers([])
        setIsSearch(false)
      }}
    >
      <div className='new-number-modal-container'>
        <Row>
          <label htmlFor="{'num'}">Area Code</label>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <b>+1</b>
            <span>(</span>
            <InputField
              className='number-input'
              style={{ width: 80 }}
              id='num'
              type='number'
              value={areaCode}
              onChange={handleChangeAreaCode}
            />
            <span>)</span>
            <MainButton
              disabled={areaCode.length <= 2}
              isFetching={getNumbersLoader}
              title='Search'
              onClick={getNewPhoneNumbers}
            />
          </div>
        </Row>

        <Row>
          {!allNumbers.length ? (
            isSearch && !getNumbersLoader ? (
              <p style={{ textAlign: 'center' }}>No available phone numbers</p>
            ) : null
          ) : (
            <>
              <span>Numbers:</span>
              <Radio.Group
                value={selectedNumber}
                onChange={e => setSelectedNumber(e.target.value)}
                className='customClassName'
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  width: '100%',
                }}
              >
                {allNumbers.map(({ number, friendly_number }, i) => (
                  <div key={i}>
                    <Radio value={number}>
                      <p>{parsePhoneNumber(friendly_number)}</p>
                    </Radio>
                  </div>
                ))}
              </Radio.Group>
            </>
          )}
        </Row>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
            marginTop: '20px',
          }}
        >
          <MainButton
            title='Cancel'
            disabled={getNewNumberLoader}
            type='cancel'
            onClick={toggleModal}
          />
          <Popconfirm
            title='Are you sure to buy this phone number?'
            onConfirm={buyNewNumber}
          >
            <MainButton
              title='Buy Number'
              disabled={!selectedNumber}
              isFetching={getNewNumberLoader}
            />
          </Popconfirm>
        </div>
      </div>
    </Modal>
  )
}

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  grid-gap: 1rem;
  gap: 1rem;
`
