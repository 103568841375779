import * as types from '../types'

export default (
  state = {
    edit: false,
    sidebarIsOpen: false,
    place_free: 1000,
  },
  action,
) => {
  switch (action.type) {
    case types.appointment.CREATE_APPOINTMENT:
      return action.data
    case types.appointment.SET_APPOINTMENTS:
      return { ...state, propertyAppointments: action.data }
    case types.appointment.SET_OPEN_SIDEBAR: {
      return { ...state, sidebarIsOpen: action.payload }
    }
    case types.appointment.SET_FREE_PLACE: {
      return { ...state, place_free: action.payload }
    }
    case types.appointment.CHANGE_TYPE:
      const changeEditState = state
      changeEditState.edit = action.bool
      return changeEditState
    case types.appointment.PASTE_DATA:
      return { ...state, appointment: action.data }
    case types.appointment.SET_TYPES:
      return { ...state, types: action.data }
    case types.appointment.SET_SOURCES:
      return { ...state, sources: action.data }

    case types.appointment.SOURCE_ADDED:
      return { ...state, sources: [...state.sources, action.payload] }
    case types.appointment.SOURCE_DELETED:
      return Array.isArray(state.sources)
        ? { ...state, sources: state.sources?.filter(source => source.id !== action.payload) }
        : state
    case types.appointment.TYPE_ADDED:
      return { ...state, types: [...state.types, action.payload] }
    case types.appointment.TYPE_DELETED:
      return Array.isArray(state.types)
        ? { ...state, types: state.types.filter(type => type.id !== action.payload) }
        : state

    case types.appointment.SET_CURRENT_APPOINTMENT_ID:
      return { ...state, currentAppointmentId: action.payload }
    default:
      return state
  }
}
