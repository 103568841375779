import React from 'react'
import { MapContainer as LeafletMap, TileLayer, Marker, Polygon, Rectangle } from 'react-leaflet'

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mapLoaded: true,
    }
    this.mapRef = React.createRef()
    this.markerRef = React.createRef()
  }
  componentDidMount() {
    if (this.props.isMapFixingNeeded) {
      const map = this.mapRef.current.leafletElement
      setTimeout(() => {
        map.invalidateSize()
      }, 0)
    }
  }

  componentWillUnmount() {
    if (this.props.isMapFixingNeeded) {
      const map = this.mapRef.current.leafletElement
      clearTimeout(
        setTimeout(() => {
          map.invalidateSize()
        }, 0),
      )
    }
  }

  updatePosition = () => {
    const marker = this.markerRef.current
    if (marker != null) {
      const latLng = marker.leafletElement.getLatLng()
      this.props.setMarker(latLng)
    }
  }

  onClickSetMarker = e => {
    this.props.setMarker(e.latlng)
  }

  render() {
    return (
      <>
        <LeafletMap
          ref={this.mapRef}
          center={
            this.props.marker
              ? /* this.props.isCoordsChoosenFromMap && !this.props.isCoordsWasChoosenFromOutOfManualAddress
                ? undefined
                : */ this
                  .props.marker
              : [37, -95]
          }
          zoom={
            this.props.marker
              ? (this.props.isCoordsChoosenFromMap &&
                  !this.props.isCoordsWasChoosenFromOutOfManualAddress &&
                  !this.props.choosenFromHelperSearch) ||
                /* ? undefined
                : */ this.props.choosenFromHelperSearch
                ? 14
                : 10
              : 3
          }
          maxZoom={18}
          attributionControl={true}
          zoomControl={true}
          doubleClickZoom={true}
          scrollWheelZoom={false}
          dragging={true}
          animate={false}
          easeLinearity={0.35}
          onClick={this.onClickSetMarker}
          //bounds={this.props.bbox ? this.props.bbox : undefined}
        >
          <TileLayer
            url='https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=4c3b17fc-bee1-42cc-9b17-b4b51480d8c5'
            attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org">OpenMapTiles</a>, &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
          />
          {!!this.props.polygon && !!this.props.polygon.length && (
            <Polygon color='#4c53ef' positions={this.props.polygon} />
          )}
          {/* !this.props.polygon && !!this.props.rectangle && !!this.props.rectangle.length && <Rectangle bounds={this.props.rectangle} color="#4c53ef" /> */}
          {!!this.props
            .marker /* && (this.props.isCoordsChoosenFromMap || this.props.isCoordsWasChoosenFromOutOfManualAddress || this.props.choosenFromHelperSearch) */ && (
            <Marker
              position={this.props.marker}
              draggable={true}
              onDragend={this.updatePosition}
              ref={this.markerRef}
            />
          )}
        </LeafletMap>
      </>
    )
  }
}

export default Map
