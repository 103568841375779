import React, { useState, useEffect } from 'react'
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined'
import moment from 'moment-timezone'
import { TimePicker, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'

import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import TableActions from '../../../../components/TableActionsNew'

import {
  addTimeCardTrack,
  deleteTimeCardTrack,
  updateTimeCardTrack,
} from 'store/Orcatec/actions/timecards'

import './TimeCardRow.scss'

const TimeCardRow = React.memo(
  ({
    timeline,
    user_id,
    timeCollision,
    fetchCard,
    date,
    allRows,
    currentRowIndex,
    startDate,
    endDate,
    timezone,
    error,
    resetErrors,
    readOnly,
    canEdit,
    canDelete,
  }) => {
    const [isTimeRow, setIsTimeRow] = useState(false)

    const [addEdit, setAddEdit] = useState('')

    const [from, setFrom] = useState(moment().startOf('day'))
    const [to, setTo] = useState(moment().endOf('day'))
    const [startTime, setStartTime] = useState(moment().startOf('day'))
    const [endTime, setEndTime] = useState(moment().endOf('day'))
    // const [isLoading, setIsLoading] = useState(false)
    const [isNextDay, setIsNextDay] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
      isOvelapTimeLine()
    }, [timeline])

    /* 
    const getDisabledHours = () => [
      ...Array(moment(from).hours()).keys(),
      ...Array.from({ length: 24 - moment(to).hours() }, (_, i) => i + moment(to).hours() + 1),
    ]
    const getDesabledMinutes = () => [
      ...Array(moment(from).minutes()).keys(),
      ...Array.from({ length: 59 - moment(to).minutes() }, (_, i) => i + moment(to).minutes() + 1),
    ]
    const getDesabledSeconds = () => [
      ...Array(moment(from).seconds()).keys(),
      ...Array.from({ length: 59 - moment(to).seconds() }, (_, i) => i + moment(to).seconds() + 1),
    ] */

    const handleAddTime = (timeFrom, timeTo) => {
      setIsTimeRow(true)
      if (timeFrom) {
        setFrom(moment(timeFrom, 'YYYY-MM-DD h:mm:ss A'))
        setStartTime(moment(timeFrom, 'YYYY-MM-DD h:mm:ss A'))
      } else {
        setFrom(moment().startOf('day'))
        setStartTime(moment().startOf('day'))
      }
      if (timeTo) {
        setTo(moment(timeTo, 'YYYY-MM-DD h:mm:ss A'))
        setEndTime(moment(timeTo, 'YYYY-MM-DD h:mm:ss A'))
      } else {
        setTo(moment().endOf('day'))
        setEndTime(moment().endOf('day'))
      }
    }

    const handleEditTime = (
      timeFrom,
      timeTo,
      timeCurrentFrom,
      timeCurrentTo,
    ) => {
      setAddEdit('edit')
      setIsTimeRow(true)
      if (timeFrom) {
        setFrom(moment(timeFrom, 'YYYY-MM-DD h:mm:ss A'))
        setStartTime(moment(timeCurrentFrom, 'YYYY-MM-DD h:mm:ss A'))
      } else {
        setFrom(moment().startOf('day'))
        setStartTime(moment().startOf('day'))
      }
      if (timeTo) {
        setTo(moment(timeTo, 'YYYY-MM-DD h:mm:ss A'))
        setEndTime(moment(timeCurrentTo, 'YYYY-MM-DD h:mm:ss A'))
      } else {
        setTo(moment().endOf('day'))
        setEndTime(moment().endOf('day'))
      }
    }

    const handleTimeChange = type => value => {
      resetErrors()
      if (!value) return
      const newDate = moment(date).set({
        hour: moment(value).get('hour'),
        minute: moment(value).get('minute'),
        second: moment(value).get('second'),
      })
      if (type === 'start') {
        setStartTime(newDate)
      }
      if (type === 'end') {
        setEndTime(newDate)
      }
    }

    const handleDeleteTimeTrack = () =>
      dispatch(
        deleteTimeCardTrack({
          userId: user_id,
          timeTrackId: timeline.id,
          timezone,
          startDate,
          endDate,
        }),
      )

    const isValidEndDate = () => {
      const difference = moment(endTime).diff(moment(startTime))
      return difference > 999 ? true : false
    }

    const isFutureDates = () => {
      const differenceStartTime = moment.utc().diff(moment.utc(startTime))
      const differenceEndTime = moment.utc().diff(moment.utc(endTime))
      return differenceStartTime < 0 || differenceEndTime < 0 ? true : false
    }

    const onSaveChanges = () => {
      if (!isValidEndDate()) {
        openNotificationWithIcon('error', {
          message:
            'Time of your clock-out must be later than the time of the respective clock-in',
          description: '',
          key: 'timecards-add-failure',
        })
        return
      }
      if (isFutureDates()) {
        openNotificationWithIcon('error', {
          message:
            'Adding or updating a time-record for a future time is impossible',
          description: '',
          key: 'timecards-add-failure',
        })
        return
      }

      // setIsLoading(true)

      if (addEdit === 'edit') {
        dispatch(
          updateTimeCardTrack(
            {
              userId: user_id,
              timeTrackId: timeline.id,
              timezone: timezone,
              clock_in: moment(startTime).format('Y-MM-DD HH:mm:ss'),
              clock_out: moment(endTime).format('Y-MM-DD HH:mm:ss'),
              startDate,
              endDate,
            },
            () => setIsTimeRow(false),
          ),
        )
      } else if (addEdit === 'add') {
        dispatch(
          addTimeCardTrack(
            {
              userId: user_id,
              timezone: timezone,
              clock_in: moment(startTime).format('Y-MM-DD HH:mm:ss'),
              clock_out: moment(endTime).format('Y-MM-DD HH:mm:ss'),
              startDate,
              endDate,
            },
            () => setIsTimeRow(false),
          ),
        )
      }
    }

    const findClockInForEdit = (idx, arr) => {
      let res = null
      for (let i = idx; i >= 0; i--) {
        if (arr[i] && arr[i].clock_in && !arr[i].deleted) {
          res = arr[i].clock_in
          break
        }
      }
      return res
    }

    const findClockOutForEdit = (idx, arr) => {
      let res = null
      for (let i = idx; i < arr.length; i++) {
        if (arr[i] && arr[i].clock_out && !arr[i].deleted) {
          res = arr[i].clock_out
          break
        }
      }
      return res
    }

    const isOvelapTimeLine = () => {
      const differenceClockOut = moment(timeline.clock_out).diff(
        moment(date).endOf('day'),
      )
      setIsNextDay(differenceClockOut > -999)
    }

    const renderRow = timeline => {
      const renderTooltip = (timeline, timeType) => {
        const getTitle = type => {
          switch (type) {
            case 'deleted':
              return (
                <span style={{ fontSize: '11px' }}>
                  Deleted by: {timeline.deleted.name},
                  <br /> on{' '}
                  {moment(timeline.deleted.deleted_at).format('MM/DD/YY ')}
                  at {moment(timeline.deleted.deleted_at).format('h:mm:ss a')}
                </span>
              )

            case 'manual':
              return (
                <span style={{ fontSize: '11px' }}>
                  Added by: {timeline?.added_by?.user?.full_name},
                  <br /> on{' '}
                  {moment(timeline?.added_by?.created_at).format('MM/DD/YY ')}
                  at{' '}
                  {moment(timeline?.added_by?.created_at).format('h:mm:ss a')}
                </span>
              )

            case 'edited':
              return (
                <span style={{ fontSize: '11px' }}>
                  Corrected by: {timeline.history.user?.full_name},
                  <br /> on{' '}
                  {moment(timeline.history.created_at).format('MM/DD/YY ')}
                  at {moment(timeline.history.created_at).format('h:mm:ss a')},
                  <br /> Original:{' '}
                  {moment(timeline.history?.[timeType]).format('h:mm:ss a')}
                </span>
              )

            default:
              return (
                <span style={{ fontSize: '11px' }}>
                  Corrected by: {timeline.history.user?.full_name},
                  <br /> on{' '}
                  {moment(timeline.history.created_at).format('MM/DD/YY ')}
                  at {moment(timeline.history.created_at).format('h:mm:ss a')},
                  <br /> Original:{' '}
                  {moment(timeline.history?.[timeType]).format('h:mm:ss a')}
                </span>
              )
          }
        }

        return (
          <Tooltip
            className='toolTip'
            title={getTitle(
              timeline.deleted
                ? 'deleted'
                : timeline.is_manual
                ? 'manual'
                : timeline.is_edited
                ? 'edited'
                : '',
            )}
          >
            <InfoCircleOutlined />
          </Tooltip>
        )
      }

      const wasChanged = (timeline, timeType) =>
        !moment(timeline?.[timeType]).isSame(timeline?.history?.[timeType])

      if (isTimeRow) {
        return (
          <tr className={`${error ? 'error' : ''}`}>
            <td>
              <TimePicker
                getPopupContainer={trigger => trigger.parentElement}
                use12Hours
                format='h:mm:ss a'
                value={startTime}
                onChange={handleTimeChange('start')}
                // disabledHours={getDisabledHours}
                // disabledMinutes={getDesabledMinutes}
                // disabledSeconds={getDesabledSeconds}
                // hideDisabledOptions
              />
              {!!error && (
                <p style={{ fontSize: 8 }}>
                  Choose time between: {moment(from).format('hh:mm:ss A')}{' '}
                </p>
              )}
            </td>
            <td>
              <TimePicker
                getPopupContainer={trigger => trigger.parentElement}
                use12Hours
                format='h:mm:ss a'
                value={endTime}
                onChange={handleTimeChange('end')}
                // disabledHours={getDisabledHours}
                // disabledMinutes={getDesabledMinutes}
                // disabledSeconds={getDesabledSeconds}
                // hideDisabledOptions
              />
              {!!error && (
                <p style={{ fontSize: 8 }}>
                  and {moment(to).format('hh:mm:ss A')}
                </p>
              )}
            </td>
            <td></td>
            <td></td>
            <td>
              {!readOnly && (
                <TableActions
                  tooltips={['Cancel', 'Save']}
                  todos={['close', 'confirm']}
                  callbacks={[
                    () => {
                      setIsTimeRow(false)
                      resetErrors()
                    },
                    onSaveChanges,
                  ]}
                />
              )}
            </td>
          </tr>
        )
      }

      if (timeline.break_time) {
        return (
          <tr style={{ background: 'rgba(0,0,0, 0.1)' }}>
            <td>{moment(timeline.clock_in).format('h:mm:ss A')}</td>
            <td>{moment(timeline.clock_out).format('h:mm:ss A')}</td>
            <td>break</td>
            <td>{timeline.break_time}</td>
            <td>
              {!timeCollision && !readOnly && (
                <TableActions
                  todos={['add']}
                  disabled={[timeCollision]}
                  tooltips={['Add time']}
                  visible={[canEdit]}
                  callbacks={[
                    () => {
                      setAddEdit('add')
                      handleAddTime(timeline.clock_in, timeline.clock_out)
                    },
                  ]}
                />
              )}
            </td>
          </tr>
        )
      } else {
        return (
          <tr>
            <td>
              <div>
                <span
                  style={{
                    color: timeline.deleted
                      ? 'red'
                      : timeline.history &&
                        !timeline.is_manual &&
                        wasChanged(timeline, 'clock_in')
                      ? 'blue'
                      : !timeline.is_manual
                      ? '#41cc08'
                      : '',
                  }}
                >
                  {moment(timeline.clock_in).format('h:mm:ss A')}
                </span>
                {(timeline.deleted ||
                  (timeline.history && wasChanged(timeline, 'clock_in')) ||
                  timeline.is_manual) &&
                  renderTooltip(timeline, 'clock_in')}
              </div>
            </td>
            <td>
              <div>
                <span
                  style={{
                    color: timeline.deleted
                      ? 'red'
                      : timeline.history &&
                        !timeline.is_manual &&
                        wasChanged(timeline, 'clock_out')
                      ? 'blue'
                      : !timeline.is_manual
                      ? '#41cc08'
                      : '',
                  }}
                >
                  {timeline.clock_out ? (
                    moment(timeline.clock_out).format('h:mm:ss A')
                  ) : (
                    <b style={{ color: 'blue' }}>Clocked in</b>
                  )}
                </span>
                {(timeline.deleted ||
                  (timeline.history && wasChanged(timeline, 'clock_out')) ||
                  timeline.is_manual) &&
                  renderTooltip(timeline, 'clock_out')}
                {isNextDay && (
                  <div className='overlapppingMessage'>Next day</div>
                )}
              </div>
            </td>
            <td>
              <span>
                {timeline.deleted
                  ? ''
                  : timeline.time_worked
                  ? timeline.time_worked
                  : 'less than 1 minute'}
              </span>
            </td>
            <td></td>
            <td>
              {!timeline.deleted && timeline.clock_out && !readOnly ? (
                <TableActions
                  todos={['edit', 'delete']}
                  tooltips={['Edit time', 'Delete time']}
                  popConfirms={['', 'Delete this time?']}
                  visible={[!!canEdit, !!canDelete]}
                  callbacks={[
                    () => {
                      handleEditTime(
                        findClockInForEdit(currentRowIndex, allRows),
                        findClockOutForEdit(currentRowIndex, allRows),
                        timeline.clock_in,
                        timeline.clock_out,
                      )
                    },
                    () => {
                      handleDeleteTimeTrack()
                    },
                  ]}
                />
              ) : null}
            </td>
          </tr>
        )
      }
    }

    return <>{renderRow(timeline)}</>
  },
)
TimeCardRow.displayName = 'TimeCardRow'

export default TimeCardRow
