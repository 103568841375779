import { ProjectItem } from 'features/Project/types'

export const initialItem = {
  attachments: [],
  barcode: '',
  category_id: 0,
  checked_option: false,
  description: '',
  global_item_id: null,
  gross_margin: null,
  group_id: 0,
  id: 0,
  is_hidden: false,
  is_material: false,
  name: '',
  net_price: 0,
  option_group_id: null,
  order_option: 0,
  position: 0,
  qty: 1,
  required_to_pay: true,
  retail_price: 0,
  section_id: 0,
  tab_id: 0,
  taxable: false,
  unit_id: null,
  unit: '',
  use_calculation: true,
} as ProjectItem
