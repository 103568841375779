import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button, Popover } from 'antd'
import MainButton from '../../../../components/buttons/MainButton'
import './ModalWrapper.scss'

//const cancelButtonStyle = { color: '#191919', border: '1px solid #D6D6D6', marginRight: '10px', height: '40px' }
const defaultAction = () => {}
const ModalWrapper = ({
  modal,
  close,
  children,
  apply,
  isSaving,
  cancelText = 'Cancel',
  okText = 'Save',
  customCancelBtnAction,
  disableCancelBtn,
  disableOkBtn,
  helperBtnAction,
  helperBtnText = 'Reset',
  dialogClassName = '',
  hideCancelBtn = false,
  afterButtonsBody = null,
  header = '',
  disableCloseСompletely = false,
  sendProposalByEmailModal = false,
  secondHelperBtnAction,
  secondHelperBtnText = '',
  sendLetterWithNativeEmail,
}) => {
  const onTimesClose = disableCloseСompletely ? defaultAction : close
  const onButtonClose = disableCloseСompletely ? defaultAction : customCancelBtnAction ? customCancelBtnAction : close
  return (
    <Modal
      dialogClassName={dialogClassName}
      show={modal}
      backdrop='static'
      onHide={onTimesClose}
      className='proposal-form-modal_wrapper'
      centered
    >
      <Modal.Header closeButton>{header}</Modal.Header>
      <Modal.Body>
        <div className='w-100'>{children}</div>
        {(!hideCancelBtn || !!helperBtnAction || !!apply) && (
          <div className='buttons'>
            {!hideCancelBtn && (
              <MainButton
                onClick={onButtonClose}
                // className='proposal-form-modal_wrapper-cancel_btn'
                disabled={disableCancelBtn}
                title={cancelText}
                type='cancel'
              />
            )}

            {!!helperBtnAction && (
              <MainButton
                type='info'
                title={helperBtnText}
                onClick={helperBtnAction}
                isFetching={false}
                disabled={false}
                className='proposal-form-modal_wrapper-helperBtn'
              />
            )}
            {!!apply && <MainButton title={okText} onClick={apply} isFetching={isSaving} disabled={disableOkBtn} />}
            {sendProposalByEmailModal && (
              <>
                <Popover content={'Copied to clipboard!'} trigger={'click'}>
                  <MainButton title={secondHelperBtnText} onClick={secondHelperBtnAction} />
                </Popover>
                <MainButton title={'Open Email Client'} onClick={sendLetterWithNativeEmail} />
              </>
            )}
          </div>
        )}
        {afterButtonsBody}
      </Modal.Body>
    </Modal>
  )
}
export default ModalWrapper
