import { ProjectSection as IProjectSection } from 'features/Project/types'
import styled from 'styled-components'
import ProjectItems from '../ProjectItems'
import SectionDiscounts from '../SectionDiscounts'
import SectionRebates from '../SectionRebates'
import { priceToView } from 'helpers/thousandSeparator'
import {
  selectProject,
  selectProjectSettings,
} from 'features/Project/projectSelectors'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'

interface Props {
  data: IProjectSection
  hideTitle: boolean
}

export const ProjectSection = ({ data, hideTitle }: Props) => {
  const project = useAppSelector(selectProject)
  const projectSettings = useAppSelector(selectProjectSettings)

  const isVisiblePrice =
    useAppSelector(selectIsPriceVisible(project?.user_id)) &&
    projectSettings?.show_price

  return (
    <Wrapper>
      {!hideTitle && <Title>{data.title}</Title>}
      <Descriptoin>{data.description}</Descriptoin>

      <SectionContent>
        <ProjectItems sectionId={data?.id} pricePermission={isVisiblePrice} />

        <SectionDiscounts
          sectionId={data?.id}
          pricePermission={isVisiblePrice}
        />
        <SectionRebates sectionId={data?.id} pricePermission={isVisiblePrice} />

        {!!data.installation &&
          isVisiblePrice &&
          (projectSettings?.show_installation ||
            (!projectSettings.show_installation && !!data.installation)) && (
            <Labor>
              <span>Additional Labor Cost:</span>
              <span>{priceToView(data.installation)}</span>
            </Labor>
          )}
      </SectionContent>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Title = styled.h5`
  background: #f7f7f7;
  border-radius: 4px;
  padding: 5px;
  margin-left: -5px;
  font-size: 18px;
  font-weight: 600;
`
const Descriptoin = styled.p`
  font-size: 16px;
  margin-bottom: 7px;
  white-space: pre-wrap;
`

const SectionContent = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`
const Labor = styled.p`
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 16px;
  margin: 10px 0 20px;

  span {
    &:nth-of-type(2) {
      flex: 0 0 150px;
      text-align: right;
    }
  }
`
