import React from 'react'
import { GoogleCircleFilled } from '@ant-design/icons'
import { Twitter, LinkedIn, Facebook, ThumbDown } from '@material-ui/icons'
import { YelpIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { FEEDBACK_TYPES, IReview } from 'features/Reviews/types'
import styled from 'styled-components'
import { Tooltip } from 'antd'

const showSocialIcons = (type: number) => {
  if (type === FEEDBACK_TYPES.NEGATIVE) {
    return <ThumbDown style={{ fontSize: '25px' }} />
  }
  if (type === FEEDBACK_TYPES.GOOGLE) {
    return (
      <GoogleCircleFilled style={{ fontSize: '20px', marginLeft: '3px' }} />
    )
  }
  if (type === FEEDBACK_TYPES.FACEBOOK) {
    return <Facebook />
  }
  if (type === FEEDBACK_TYPES.TWITTER) {
    return <Twitter />
  }
  if (type === FEEDBACK_TYPES.LINKEDIN) {
    return <LinkedIn />
  }
  if (type === FEEDBACK_TYPES.YELP) {
    return <YelpIcon />
  }
}

const titles = {
  [FEEDBACK_TYPES.NEGATIVE]: 'Negative',
  [FEEDBACK_TYPES.GOOGLE]: 'Google',
  [FEEDBACK_TYPES.FACEBOOK]: 'Facebook',
  [FEEDBACK_TYPES.LINKEDIN]: 'Linkedin',
  [FEEDBACK_TYPES.YELP]: 'Yelp',
  [FEEDBACK_TYPES.TWITTER]: 'Twitter',
}

export const ReviewSource = ({
  type,
}: {
  type: IReview['last_feedback_type']
}) => {
  return (
    <Wrapper>
      {type ? (
        <Tooltip title={titles[type]}>{showSocialIcons(type)}</Tooltip>
      ) : (
        ''
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & svg {
    color: #1890ff;
  }
`
