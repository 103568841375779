import * as types from '../types'
import { fromJS } from 'immutable'

const initState = fromJS({
  columns: [],
  rows: [],
  checkedRows: [],
  newRow: {},
})

/**
 * This reducer is deprecated and should be remove, not use it
 * @param state
 * @param action
 * @return {*}
 * @deprecated
 */
export default (state = initState, action) => {
  const table = fromJS(state)
  let index = 0

  if (typeof action.id !== 'undefined') {
    index = table.get('rows').findIndex(curRow => curRow.id === action.id)
  }

  switch (action.type) {
    case types.table.TABLE_SET_TABLE:
      return action.table.toJS()

    case types.table.TABLE_SET_ROW:
      return table.set('rows', table.get('rows').unshift(action.row)).toJS()

    case types.table.TABLE_SET_ROWS:
      return table.set('rows', action.rows).toJS()

    case types.table.TABLE_DEL_ROW:
      if (index !== -1) {
        return table.set('rows', table.get('rows').splice(index, 1)).toJS()
      } else {
        return state
      }
    case types.table.TABLE_REMOVE_FIRST_ROW:
      return table.set('rows', table.get('rows').shift()).toJS()

    case types.table.TABLE_CHANGE_NEW_ROW:
      return table.set('newRow', action.value).toJS()

    default:
      return state
  }
}
