import * as types from '../types/routing'
export default (state = { from: '', to: '' }, action) => {
  switch (action.type) {
    case types.LOCATION_BEFORE_TRANSITION:
      return {
        ...state,
        ...action.payload,
        difference: action.payload.from !== action.payload.to,
      }

    default:
      return state
  }
}
