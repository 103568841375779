import React, { useState } from 'react'
import { ManualAddress } from './ManualAddress/ManualAddress'
import { addTag } from '../../../../../helpers/tags/addTag'
import { revertOfTransformStringIfAddressInHere } from 'helpers/transformStringIfAddressInHere'

const initialAddressData = {
  address: '',
  postcode: '',
  city: '',
  state: '',
  unit: '',
  type: '',
  lat: '',
  lng: '',
  notes: '',
  tags: [],
  bbox: null,
  temporary_notes: '',
}

const initialAddressDataErrors = {
  ...initialAddressData,
  tags: '',
}

const scrollToFirstError = errorsObj => {
  let firstErrorKey = Object.keys(errorsObj).find(key => !!errorsObj[key])
  if (firstErrorKey && document.getElementById('manual-' + firstErrorKey)) {
    const element = document.getElementById('manual-' + firstErrorKey)
    element.scrollTop += 10
    element?.scrollIntoView?.({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    })
  }
  return firstErrorKey
}

export const ManualAddressContainer = ({
  isMapFixingNeeded = false,
  onSave,
  onCancel,
  property = {},
  onChangeTemporaryNotes,
  temporary_notes = '',
  okButtonText,
}) => {
  const [addressData, setAddressData] = useState({ ...initialAddressData, ...property })
  const [addressDataErrors, setAddressDataErrors] = useState(initialAddressDataErrors)
  const [isCoordsChoosenFromMap, setIsCoordsChoosenFromMap] = useState(!!+property.lat && !!+property.lng)
  const [choosenFromHelperSearch, setChoosenFromHelperSearch] = useState(false)
  const [poligon, setPoligon] = useState(null)
  const [tempTagValue, setTempTagValue] = useState('')
  const [tempAddressValue, setTempAddressValue] = useState('')

  const onChangeTextField = (e, val) => {
    let { name, value } = e.target
    name = revertOfTransformStringIfAddressInHere(name)
    const newAddressData = { ...addressData }
    const newAddressDataErrors = { ...addressDataErrors }
    switch (name) {
      case 'address': {
        if (val) value = val
        newAddressDataErrors[name] = value ? '' : 'Please input property address'
        break
      }
      case 'postcode': {
        if (val) value = val
        else {
          value = value
            .trim()
            .replace(/\D/g, '')
            .slice(0, 5)
        }
        newAddressDataErrors[name] = value
          ? value.length !== 5
            ? 'Please input 5 digits ZIP'
            : !!newAddressData.city && !!newAddressData.state
            ? ''
            : 'Please choose ZIP from dropdown'
          : 'Please input ZIP'
        break
      }
      case 'city': {
        newAddressDataErrors[name] = value ? '' : 'Please input city'
        break
      }
      case 'state': {
        newAddressDataErrors[name] = value ? '' : 'Please input state'
        break
      }
      case 'tempTagValue': {
        newAddressDataErrors.tags = value && addressData.tags.length >= 20 ? 'Maximum 20 tags' : ''
        break
      }
      default:
        break
    }
    if (name !== 'tempTagValue') newAddressData[name] = value
    setTempTagValue(name === 'tempTagValue' ? value : tempTagValue)
    setAddressData(name !== 'tempTagValue' ? newAddressData : addressData)
    setAddressDataErrors(newAddressDataErrors)
  }

  const onChoosePostcode = addressObj => {
    if (addressObj) {
      const { city, state, zip, postcode, lat, lng, bbox, poligon } = addressObj
      setAddressData(prev => ({
        ...prev,
        postcode: postcode ? postcode : zip ? zip : '',
        city: city ? city : '',
        state: state ? state : '',
        lat: !!lat && !!lng ? /* isCoordsChoosenFromMap ? prev.lat : */ lat : '',
        lng: !!lat && !!lng ? /* isCoordsChoosenFromMap ? prev.lng : */ lng : '',
        bbox: bbox
          ? [
              [+bbox[0], +bbox[2]],
              [+bbox[1], +bbox[3]],
            ]
          : null,
      }))
      setAddressDataErrors(prev => ({
        ...prev,
        postcode: '',
        city: city ? '' : 'Please input city',
        state: state ? '' : 'Please input state',
        lat: !!lat && !!lng ? '' : 'Please click on map to put mark',
        lng: !!lat && !!lng ? '' : 'Please click on map to put mark',
      }))
      setPoligon(poligon)
      setIsCoordsChoosenFromMap(true)
    }
  }

  const onCloseForm = () => {
    setAddressData(initialAddressData)
    setAddressDataErrors(initialAddressDataErrors)
    setIsCoordsChoosenFromMap(false)
    onCancel()
  }

  const validateFieldsAndScroll = () => {
    ///check if there are some error
    let firstError = scrollToFirstError(addressDataErrors)
    if (firstError) return false
    //// if no errors then check required fields
    const newAddressDataErrors = { ...addressDataErrors }
    newAddressDataErrors.address = addressData.address ? '' : 'Please input property address'
    newAddressDataErrors.postcode = addressData.postcode ? '' : 'Please choose ZIP from dropdown'
    newAddressDataErrors.city = addressData.city ? '' : 'Please input city'
    newAddressDataErrors.state = addressData.state ? '' : 'Please input state'
    newAddressDataErrors.type = addressData.type ? '' : 'Please choose type'
    newAddressDataErrors.lat =
      !!addressData.lat && !!addressData.lng && isCoordsChoosenFromMap ? '' : 'Please click on map to put mark'
    newAddressDataErrors.lng = newAddressDataErrors.lat

    firstError = scrollToFirstError(newAddressDataErrors)
    if (firstError) setAddressDataErrors(newAddressDataErrors)
    return !firstError
  }

  const onSubmit = () => {
    const isValid = validateFieldsAndScroll()
    if (isValid) {
      const newAddressData = { ...addressData, zip: addressData.postcode, is_manual: true }
      onSave(newAddressData)
      onCloseForm()
    }
  }

  const setMarker = ({ lat, lng }) => {
    setIsCoordsChoosenFromMap(true)
    setAddressData(prev => ({
      ...prev,
      lat,
      lng,
    }))
    setAddressDataErrors(prev => ({
      ...prev,
      lat: !!lat && !!lng ? '' : 'Please put mark to the correct place on the map',
      lng: !!lat && !!lng ? '' : 'Please put mark to the correct place on the map',
    }))
  }

  const onAddTag = () => {
    addTag(
      tempTagValue,
      addressData.tags,
      tag =>
        setAddressData({
          ...addressData,
          tags: [tag, ...addressData.tags],
        }),
      () => setTempTagValue(''),
    )
  }

  const onDeleteTag = i => {
    setAddressData({
      ...addressData,
      tags: addressData.tags.filter((tag, idx) => idx !== i),
    })
  }
  const changeTempAddressValue = e => {
    setTempAddressValue(e.target.value)
  }

  const onChooseInDropdown = ({ bbox, ...obj }) => {
    setAddressData(prev => ({
      ...prev,
      ...obj,
      bbox: bbox
        ? [
            [+bbox[1], +bbox[2]],
            [+bbox[3], +bbox[0]],
          ]
        : null,
    }))
    setChoosenFromHelperSearch(true)
  }
  return (
    <ManualAddress
      isMapFixingNeeded={isMapFixingNeeded}
      onChangeTextField={onChangeTextField}
      addressData={addressData}
      addressDataErrors={addressDataErrors}
      onChoosePostcode={onChoosePostcode}
      onSubmit={onSubmit}
      onCloseForm={onCloseForm}
      isCoordsChoosenFromMap={isCoordsChoosenFromMap}
      setMarker={setMarker}
      poligon={poligon}
      onAddTag={onAddTag}
      onDeleteTag={onDeleteTag}
      onChangeTemporaryNotes={onChangeTemporaryNotes}
      temporary_notes={temporary_notes}
      tempTagValue={tempTagValue}
      isCoordsWasChoosenFromOutOfManualAddress={!!property.lat && !!property.lng}
      tempAddressValue={tempAddressValue}
      changeTempAddressValue={changeTempAddressValue}
      onChooseInDropdown={onChooseInDropdown}
      choosenFromHelperSearch={choosenFromHelperSearch}
      okButtonText={okButtonText}
    />
  )
}
