import React, { useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import logo from './image/socal-site-logo_400px.png'
import { footerText } from './const'
import { priceToView } from 'helpers/thousandSeparator'
import ModalSignature from '../Signature/Signature'
import { Tooltip, Checkbox } from 'antd'
import { Check } from '@material-ui/icons'
import moment from 'moment-timezone'
import { updateProjectField } from 'features/Project/slices/projectSlice'
import { NotesBlock } from './components/NotesBlock'
import { ProjectStatusGroup } from 'features/Project/types'
import { updatePublicProject } from 'api/Project'
import { useAppDispatch } from 'store/Orcatec/hooks'

const linkToCompany = 'https://socalsandbags.com/'

const daysOfWeeks = [
  { label: 'M', value: 1 },
  { label: 'T', value: 2 },
  { label: 'W', value: 3 },
  { label: 'Th', value: 4 },
  { label: 'F', value: 5 },
  { label: 'S', value: 6 },
  { label: 'S', value: 7 },
]

interface IData {
  date: string
  customer: string
  phone: string
  email: string
  location: string
  project: string
  work_description: string
  ordered_by: string
  foreman: string
  code: string
  materials: []
  instants: []
  scope_of_works: []
  warranties: []
  total_to_pay: string
  customer_service: boolean
  offsite: boolean
  on_site_indirect: boolean
  sales_marketing: boolean
  super_name: string
  materialTotal: number
  tax: number
  dayOfWeek: number
  days_of_week: []
  p_o_number: string
  items: []
}

export interface IProps {
  proposalForm: object
  currentTab: object
  signatureModal: boolean
  setSignatureModal: () => void
  setSignature: () => void
  isProposalSigning: boolean
}

const sortItems = (groups, items) => {
  let sortedItems

  const itemsWithoutGroups = items
    .filter(item => !item?.group_id)
    .sort((a, b) => a.position - b.position)

  if (groups.length > 0) {
    const sortedGroups = groups.slice().sort((a, b) => a.position - b.position)

    const groupedItems = {}
    Object.values(items).forEach(item => {
      const groupID = item.group_id
      if (!groupedItems[groupID]) {
        groupedItems[groupID] = []
      }
      groupedItems[groupID].push(item)
    })

    for (const groupID in groupedItems) {
      groupedItems[groupID].sort((a, b) => a.position - b.position)
    }

    sortedItems = sortedGroups.reduce((result, group) => {
      if (groupedItems[group.id]) {
        result.push(...groupedItems[group.id])
      }
      return result
    }, [])
    sortedItems = [...itemsWithoutGroups, ...sortedItems]
  } else {
    sortedItems = Object.values(items).sort((a, b) => a.position - b.position)
  }

  return sortedItems
}

export const makeDataForForm = (proposalForm, tab) => {
  // const sections = proposalForm.proposal_tabs.find(
  //   item => item.id === proposalForm.priority_tab_id,
  // )?.proposal_sections

  // const tab = proposalForm.proposal_tabs.find(
  //   item => item.id === proposalForm.priority_tab_id,
  // )

  const sections = tab?.proposal_sections
  const fees = tab?.fees?.reduce((acc, item) => (acc += +item?.price), 0)

  // const tax = tab?.tax?.total
  const rate = tab?.tax?.rate || 0

  const materials = sections?.map(item => item?.materials).flat(1)

  const instants = sections?.map(item => item?.instants).flat(1)

  const scope_of_works = sections?.map(item => item?.scope_of_works).flat(1)
  const warranties = sections?.map(item => item?.warranties).flat(1)
  const items = sections?.map(item => item?.items).flat(1)
  const groups = sections?.map(item => item?.groups).flat(1)

  if (!proposalForm.id || !tab.id) return <p>...loading</p>

  return {
    date: proposalForm?.field_work_order?.date
      ? moment(proposalForm?.field_work_order?.date)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY')
      : '',
    customer: proposalForm?.field_work_order?.customer,
    phone: proposalForm?.field_work_order?.phone || '',
    email: proposalForm?.field_work_order?.email || '',
    location: proposalForm?.field_work_order?.location,
    project: proposalForm?.field_work_order?.project,
    work_description: proposalForm?.field_work_order?.work_description || '',
    ordered_by: proposalForm?.field_work_order?.ordered_by || '',
    super_name: proposalForm?.field_work_order?.super_name || '',
    foreman: proposalForm?.field_work_order?.foreman,
    customer_service: proposalForm?.field_work_order?.customer_service,
    offsite: proposalForm?.field_work_order?.offsite,
    on_site_indirect: proposalForm?.field_work_order?.on_site_indirect,
    sales_marketing: proposalForm?.field_work_order?.sales_marketing,
    code: proposalForm.code,
    sections: sections,
    materials: materials,
    instants: instants,
    scope_of_works: scope_of_works,
    total_to_pay: tab.total_to_pay,
    warranties: warranties,
    items: sortItems(groups, items),
    fees: fees,
    p_o_number: proposalForm?.field_work_order?.p_o_number,
    days_of_week: proposalForm?.field_work_order?.days_of_week,
    materialTotal: proposalForm.financing_info?.[0]?.materialTotal || 0,
    rate,
    tax: proposalForm.financing_info?.[0]?.tax || 0,
    // tax,
    dayOfWeek: proposalForm?.issue_date
      ? moment
          .utc(proposalForm.issue_date)
          .tz(moment.tz.guess())
          .isoWeekday()
      : null,
  }
}

export const FieldWorkOrders = ({
  proposalForm,
  currentTab,
  signatureModal,
  setSignatureModal,
  setSignature,
  isProposalSigning,
  isPublicView,
}: IProps) => {
  const dispatch = useAppDispatch()

  const [state, setState] = useState({
    info_from_customer: proposalForm?.info_from_customer,
  })

  const proposal: IData = makeDataForForm(proposalForm, currentTab)

  const useQuery = () => {
    return new URLSearchParams(window.location.search)
  }
  const search = useQuery()

  //need for print and open tab

  const isPrintNedeed = search.get('print')
  const handleAcceptSignature = () => {
    setSignatureModal(prev => !prev)
  }

  const onSave = () => {
    if (isPublicView) {
      updatePublicProject(proposalForm.id, state)
      return
    }

    // ProjectAPI.updateProjectField(proposalForm?.id, state)
    dispatch(updateProjectField(state))
  }

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setState(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <Wrapper isPrintNedeed={isPrintNedeed}>
      <TableHeader proposal={proposal} />
      <TableBodyWrapper id='second'>
        <table>
          <tbody>
            <tr>
              <TdUnit title='Customer:' width='70%'>
                <span className='item-value'>
                  <b>{proposal.customer}</b>
                </span>
              </TdUnit>
              <TdUnit title='Super Name:' width='30%'>
                <span className='item-value border-right'>
                  <b>{proposal.super_name}</b>
                </span>
              </TdUnit>
            </tr>
            <tr>
              <TdUnit title='Project:'>
                <span className='item-value'>
                  <b>{proposal.project}</b>
                </span>
              </TdUnit>
              <TdUnit title='Phone #:'>
                <span className='item-value border-right'>
                  <b>{proposal.phone}</b>
                </span>
              </TdUnit>
            </tr>
            <tr>
              <TdUnit title='Tract #/Location:'>
                <span className='item-value'>
                  <b>{proposal.location}</b>
                </span>
              </TdUnit>
              <TdUnit title='Email:'>
                <span className='border-right'>
                  <b>{proposal.email}</b>
                </span>
              </TdUnit>
            </tr>
            <tr>
              <TdUnit title='Work Description:' colSpan='2'>
                <span className='item-value border-right'>
                  <b>{proposal.work_description}</b>
                </span>
              </TdUnit>
            </tr>
            <tr>
              <TdUnit colSpan='5'>
                <HeadingItemWrapper>
                  <HeadingRowItem
                    title='Onsite/Indirect'
                    value={proposal.on_site_indirect}
                  />
                  <HeadingRowItem title='Offsite' value={proposal.offsite} />
                </HeadingItemWrapper>
              </TdUnit>
            </tr>
            <tr>
              <TdUnit colSpan='5'>
                <HeadingItemWrapper>
                  <HeadingRowItem
                    title='Customer Service'
                    value={proposal.customer_service}
                  />
                  <HeadingRowItem
                    title='Sales/Marketing'
                    value={proposal.sales_marketing}
                  />
                </HeadingItemWrapper>
              </TdUnit>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <ThUnit width='10%'>Qty</ThUnit>
              <ThUnit width='10%'>Unit</ThUnit>
              <ThUnit width='50%' colSpan='2'>
                Description
              </ThUnit>
              <ThUnit width='6%'>Tax</ThUnit>

              <ThUnit width='8%'>Rate</ThUnit>
              <ThUnit width='10%'>Amount</ThUnit>
            </tr>
          </thead>
          <tbody>
            <TableItem items={proposal.items} />

            <tr>
              <TdUnit colSpan='4' rowSpan='4'>
                <NotesBlock
                  onChange={onChange}
                  onSave={onSave}
                  value={state?.info_from_customer}
                  viewOnly={
                    !!isPrintNedeed ||
                    proposalForm?.status !== ProjectStatusGroup.Estimate
                  }
                />
              </TdUnit>
              <TdUnit colSpan='2'>
                <p
                  className='item-value bold'
                  style={{ textAlign: 'end', paddingRight: '5px' }}
                >
                  Materials:
                </p>
              </TdUnit>
              <TdUnit colSpan='1'>
                <p className='item-value bold'>
                  {priceToView(proposal.materialTotal)}
                </p>{' '}
              </TdUnit>
            </tr>
            <tr>
              <TdUnit colSpan='2'>
                <p
                  className='item-value bold'
                  style={{ textAlign: 'end', paddingRight: '5px' }}
                >
                  Tax Rate:
                </p>
              </TdUnit>
              <TdUnit colSpan='1'>
                <p className='item-value bold'>{proposal?.rate || 0}%</p>{' '}
              </TdUnit>
            </tr>
            <tr>
              <TdUnit colSpan='2'>
                <p
                  className='item-value bold'
                  style={{ textAlign: 'end', paddingRight: '5px' }}
                >
                  Tax:
                </p>
              </TdUnit>
              <TdUnit colSpan='1'>
                <p className='item-value bold'>{priceToView(proposal.tax)}</p>{' '}
              </TdUnit>
            </tr>
            <tr>
              <TdUnit colSpan='2'>
                <p
                  className='item-value bold'
                  style={{ textAlign: 'end', paddingRight: '5px' }}
                >
                  Total:
                </p>
              </TdUnit>
              <TdUnit colSpan='1'>
                <p className='item-value bold'>
                  {priceToView(proposal.total_to_pay)}
                </p>{' '}
              </TdUnit>
            </tr>
          </tbody>
        </table>
      </TableBodyWrapper>
      <TableFooter
        id='third'
        proposalForm={proposalForm}
        handleAcceptSignature={handleAcceptSignature}
        isPrintNedeed={isPrintNedeed}
      />
      <ModalSignature
        show={signatureModal}
        close={() => setSignatureModal(prev => !prev)}
        onSubmit={setSignature}
        termFile={
          proposalForm?.term_and_conditions
            ? proposalForm?.term_and_conditions?.file0
            : null
        }
        isProposalSigning={isProposalSigning}
      />
    </Wrapper>
  )
}

function ThUnit({ children, ...props }) {
  return (
    <TH {...props}>
      <p
        style={{
          minHeight: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '16px',
        }}
      >
        {children}
      </p>
    </TH>
  )
}
function TdUnit({
  children,
  title,
  ...props
}: {
  children: React.ReactNode
  title?: string
  props: any
}) {
  return (
    <TD {...props}>
      <p style={{ minHeight: '32px' }}>
        {title} {children}
      </p>
    </TD>
  )
}

function HeadingRowItem({ title, value }: { title: string; value: boolean }) {
  return (
    <HeadingRowItemWrapper>
      <div className='header-item_value'>
        <Checkbox checked={value} />
      </div>
      <div className='header-item_text'>{title}</div>
    </HeadingRowItemWrapper>
  )
}
function TableItem({ items = [] }) {
  const isMacOS = () => {
    return /Mac|iPod|iPhone|iPad/.test(window.navigator.userAgent)
  }
  const desiredLength = 20

  const array = JSON.parse(JSON.stringify(items))

  if (array.length < desiredLength) {
    const diff = desiredLength - array.length
    const emptyItems = Array(diff).fill({})

    array.push(...emptyItems)
  }
  array.splice(isMacOS() ? 20 : 18)
  return array.map((item, idx) => (
    <tr key={idx}>
      <TdUnit>
        <p className='item-value center centered-text'>{item?.qty || ''}</p>
      </TdUnit>
      <TdUnit>
        <p
          style={{ height: '25px' }}
          className='item-value overlaping center centered-text'
        >
          {item?.id ? item?.unit || 'Qty' : ''}
        </p>
      </TdUnit>
      <TdUnit colSpan='2'>
        <p className='item-value overlaping centered-text'>
          <Tooltip title={item?.description || item?.full_name || item?.name}>
            {item?.description || item?.full_name || item?.name}
          </Tooltip>
        </p>
      </TdUnit>
      <TdUnit>
        <p className='item-value center centered-text'>
          {item?.taxable && <Check />}
        </p>{' '}
      </TdUnit>

      <TdUnit>
        <p className='item-value  centered-text'>
          {priceToView(item?.retail_price, false, 3)}
        </p>{' '}
      </TdUnit>
      <TdUnit>
        <p className='item-value centered-text '>
          {priceToView(item?.qty * +item?.retail_price)}
        </p>{' '}
      </TdUnit>
    </tr>
  ))
}

function TableFooter({ proposalForm, handleAcceptSignature, isPrintNedeed }) {
  // const companyTimezone = useAppSelector(selectCompanyTimezone)
  const companyTimezone = proposalForm.timezone || 'America/Los_Angeles'

  const contractInfo = {
    contract_date: proposalForm.contract_date,
    signature: proposalForm?.signature ? proposalForm.signature : '',
    ip_client: proposalForm?.ip_client
      ? ` (IP-${proposalForm?.ip_client})`
      : '',
    signature_date: proposalForm?.signature_date
      ? moment(proposalForm?.signature_date)
          .tz(companyTimezone)
          .format('MM/DD/YYYY [at] hh:mma')
      : '',
    signed_by: proposalForm?.signed_by ? proposalForm.signed_by : '',
    signatory_name: proposalForm?.signatory_name,
  }

  return (
    <TableFooterWrapper id='third'>
      <p
        style={{
          marginBottom: isPrintNedeed
            ? `${!contractInfo?.signature ? '150px' : '20px'}`
            : `${!contractInfo?.signature ? '70px' : '0px'}`,
        }}
        className='footer-description'
      >
        {footerText}
      </p>
      <div
        style={
          contractInfo?.signature
            ? {
                gridTemplateColumns: '2fr 3fr 0.5fr 2fr',
              }
            : {}
        }
        className='signature-wrapper'
      >
        <div
          style={
            contractInfo?.signature
              ? { textAlign: 'left' }
              : { textAlign: 'center' }
          }
          className='accepted-block'
        >
          {' '}
          Accepted by <br></br>
          {contractInfo.signatory_name && proposalForm?.signature
            ? '' + contractInfo.signatory_name + ''
            : ''}
        </div>
        <div className='signature-block__img'>
          {contractInfo?.signature ? (
            <img
              className='customer-signature__img'
              src={contractInfo?.signature}
              alt='customer signature'
            />
          ) : (
            !isPrintNedeed && (
              <p
                onClick={handleAcceptSignature}
                className='accept-signatire-btn'
              >
                Accept Project
                <p className='signature-title'>Must print and sign name</p>
              </p>
            )
          )}
        </div>
        <div className='signature-date__title'>Date:</div>
        <div>
          <p className='signature-date'>
            {contractInfo?.signature && contractInfo.signature_date
              ? contractInfo.signature_date
              : ''}
          </p>
        </div>
      </div>
    </TableFooterWrapper>
  )
}
function TableHeader({ proposal }: { proposal: IData }) {
  return (
    <TableHeaderWrapper id='first'>
      <div className='header-block__left'>
        <div>
          <a
            target='_blank'
            rel='noreferrer'
            href={proposal?.logo?.company_link || linkToCompany}
          >
            {' '}
            <img width={200} src={logo} alt='logo' />
          </a>
        </div>
        <div style={{ width: '100%' }}>
          <p>12620 Bosley Lane</p>
          <p>Corona, CA 92883</p>
          <p>(951) 277-3404, (800) 834-8682</p>
          <p>(949) 589-8600, (951) 277-3420 - Fax</p>
          <div style={{ width: '100%' }} className='ordered-block font_size'>
            Ordered by:
            <span className='item-value'>
              <b>{proposal.ordered_by}</b>
            </span>
          </div>
        </div>
      </div>
      <div className='header-block__right'>
        <p className='order-title'>
          Field Work Order:{' '}
          <span className='order-number'>{proposal?.code}</span>
        </p>
        <div className='calendar-block'>
          <div className='calendar-wrapper calendar-wrapper__left  border-bot'>
            <p className='days-wrapper border-rig'>
              {daysOfWeeks?.map(day => (
                <span
                  key={day.value}
                  className={
                    proposal?.days_of_week?.includes(day.value)
                      ? 'bordered font_size'
                      : 'font_size'
                  }
                >
                  {day.label}
                </span>
              ))}
            </p>
            <p className='font_size'>
              Date:
              <span className='item-value'>
                <b>{proposal.date}</b>
              </span>
            </p>
          </div>
          <div className='calendar-wrapper calendar-wrapper__right'>
            <p className='border-rig font_size'>
              Foreman:{' '}
              <span className='item-value'>
                <b>{proposal.foreman}</b>
              </span>
            </p>
            <p className='font_size'>
              P.O.#:
              <span className='item-value '>
                <b>{proposal.p_o_number}</b>
              </span>
            </p>
          </div>
        </div>
      </div>
    </TableHeaderWrapper>
  )
}
interface StyleProps {
  isPrintNedeed: boolean
}
const Wrapper = styled.div<StyleProps>`
  width: ${props => (props.isPrintNedeed ? '28.35cm' : '21.35cm')};
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .font_size {
    font-size: 16px;
  }
  #first {
    order: -1;
  }

  #second {
    order: -1;
  }

  #third {
    order: 1;
  }
  & table {
    width: 100%;
    /* border: 2px solid black; */
    border-collapse: collapse;
  }
  & table:first-child {
    border-bottom: none;
    /* border-right: 2px solid black !important;  */
  }
  & table:last-child {
    & thead {
      & tr {
        /* border-bottom: 2px solid black; */
      }
    }
  }
  & .overlaping {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 470px;
  }
  & .bold {
    font-weight: 600;
  }
  .centered-text {
    line-height: 2;
    /* display: flex;
    align-items: center; */
  }
  /* .border-right {
    border-right: 2px solid black;
  } */
`

const TH = styled.th`
  text-align: center;
  border: 1px solid grey;
`
const TD = styled.td`
  border: 1px solid grey;
  font-size: 16px;
  /* height: 32px; */
  & .center {
    text-align: center !important;
  }
`
const TableFooterWrapper = styled.div`
  & .footer-description {
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 10px;
  }
  & .signature-wrapper {
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr 4fr 0.5fr 2fr;
    gap: 5px;
    text-align: center;

    align-items: end;
    margin-top: -50px;

    & .signature-block__img {
      border-bottom: 1px solid black;
    }

    & .customer-signature__img {
      width: 160px;
      height: 160px;
      object-fit: contain;
      object-position: bottom;
    }

    & .accepted-block {
      font-weight: 500;
      font-size: 18px;
      text-align: center;
    }
    & .signature-date__title {
      font-weight: 500;
      font-size: 18px;
    }

    & .signature-date {
      border-bottom: 1px solid black;
      font-weight: 500;
      font-size: 18px;
    }
    & .accept-signatire-btn {
      position: relative;

      width: 100%;
      background-color: #4285f4;

      height: 20px;
      transition: all 0.2s;
      cursor: pointer;

      & .signature-title {
        position: absolute;
        top: 17px;
        left: 0px;
        width: 100%;
        color: black;
        opacity: 1 !important;
      }
    }
  }
`
const TableHeaderWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 54.7% 45%;
  margin-bottom: 6px;
  gap: 2px;

  & .header-block__left {
    align-self: end;
    display: flex;
    gap: 1px;
    width: 100%;
    & .ordered-block {
      width: 100%;
      border: 1px solid black;
      /* border-radius: 3px; */
      padding: 2px;
    }
    & p {
      line-height: 1.1;
    }
  }
  & .header-block__right {
    align-self: end;
    width: 100%;
    & .order-number {
      color: green;
      font-size: 26px;
    }
    & .order-title {
      font-weight: 600;
      font-size: 20px;
    }
    & .calendar-block {
      width: 100%;
      border: 1px solid black;
      /* border-radius: 3px; */
      & .bordered {
        border: 0.5px solid black;
        background-color: #ddd9d9;
      }
      & .calendar-wrapper {
        display: grid;
        align-items: center;
      }
      & .calendar-wrapper__left {
        grid-template-columns: 0.8fr 1fr;
      }
      & .calendar-wrapper__right {
        grid-template-columns: 2fr 1fr;
      }
      & p {
        padding: 2px;
      }
      & .border-bot {
        border-bottom: 1px solid black;
      }
      & .border-rig {
        border-right: 1px solid black;
      }
      & .days-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        & span {
          padding: 0px 4px;
          border-radius: 30%;
          height: 24px;
          display: block;
        }
      }
    }
  }
  & .item-value {
    text-align: left;
    margin-left: 2px;
    min-height: 27px;
  }
`
const TableBodyWrapper = styled.div`
  .item-value {
    text-align: left;
    margin-left: 2px;
    font-size: 16px;
    min-height: 32px;
  }
`

const HeadingRowItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 30px 130px;
  & .header-item_value {
    display: flex;
    justify-content: center;
    font-size: 16px;
  }
`
const HeadingItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`
