import React from 'react'
import { Popup } from 'react-leaflet'
import './PopapWrapper.scss'
const PopapWrapper = props => {
  return (
    <Popup className={props.className}>
      <div className='marker-popap'>{props.children}</div>
    </Popup>
  )
}

export default PopapWrapper
