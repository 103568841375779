import { getRealContractStatus } from 'helpers/getRealContractStatus'
import Items from '../ItemsTable'

import './styles.scss'
import { ProjectSection, ProjectStatusGroup } from 'features/Project/types'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompany } from 'store/Orcatec/selectors/company'
import { Companies } from 'types/Company'

interface Props {
  sections: ProjectSection[]
  proposalStatus: ProjectStatusGroup
  showProgress?: boolean
  isJobView?: boolean
  showModules?: boolean
  isWorkOrder?: boolean
  hideDescription?: boolean
}

const ProposalSections = ({
  sections,
  proposalStatus,
  showProgress,
  isJobView,
  showModules,
  isWorkOrder,
  hideDescription,
}: Props) => {
  const company = useAppSelector(selectCompany)

  return (
    <div className='proposal-tab-info'>
      {showModules && (
        <p className='tab-status'>
          {company.id === Companies['Trilogy Surgical Solutions']
            ? 'Work Order'
            : getRealContractStatus(proposalStatus)}
        </p>
      )}

      {sections?.map((section, i, arr) => (
        <div className='section' key={`${section.title} ${i}`}>
          {!(arr?.length === 1 && section.title === 'New Section') && (
            <div className='section-name'>{section.title}</div>
          )}

          {!!section.items.filter(item => !item.group_id).length && (
            <div className='table-items'>
              <Items
                companyTimezone={company.timezone}
                title={''}
                status={proposalStatus}
                section={i}
                type={'items'}
                showProgress={showProgress}
                groupId={null}
                sectionId={section.id}
                isJobView={isJobView}
                isWorkOrder={isWorkOrder}
                hideDescription={hideDescription}
              />
            </div>
          )}

          {section.groups.map(group => (
            <div className='table-items' key={group.id}>
              <Items
                companyTimezone={company.timezone}
                title={group.name}
                status={proposalStatus}
                section={i}
                type={'items'}
                showProgress={showProgress}
                groupId={group.id}
                sectionId={section.id}
                isJobView={isJobView}
                isWorkOrder={isWorkOrder}
                hideDescription={hideDescription}
              />
            </div>
          ))}

          {/* <div className='table-items Warranty'>
            <Items
              companyTimezone={company.timezone}
              title='Warranty'
              items={section.warranties}
              section={i}
              type={'warranties'}
            />
          </div> */}

          {/* {sections?.length > 1 && (
            <Divider>
              <span style={{ fontSize: '14px' }}>End Of Section</span>
            </Divider>
          )} */}
        </div>
      ))}
    </div>
  )
}

export default ProposalSections
