import { Redirect } from 'react-router-dom'
import { rolesRedirect } from 'adminModule/types/index'
import { IAdminRoute } from '../types/Routes'
import lazyWithRetry from 'containers/mainbuilder/lazyWithRetry'

const roles = [
  'DEFAULT_SUPER_ADMIN',
  'ADMIN__SUPER_ADMIN',
  'ADMIN__ACCOUNT_MANAGER',
  'ADMIN__ADMIN',
]
export const useRoutes = me => {
  const {
    users,
    companies,
    support,
    emails,
    email_accounts,
    articles,
  } = me?.permissions
  const isAdmin = me.role.code === 'DEFAULT_SUPER_ADMIN'
  const redirect =
    !isAdmin && !me.password_changed_at ? (
      <Redirect to='/change-password' />
    ) : (
      <Redirect to='/auth/signin' />
    )
  const routes: IAdminRoute[] = [
    {
      path: '/change-password',
      exact: true,
      isAccess: true,
      component: lazyWithRetry(() => import('../pages/ChangePassword/index')),
      redirect:
        me.role.code === 'ADMIN__ACCOUNT_MANAGER' &&
        !me?.account_manager?.doc_signed
          ? '/acc_manager-onboarding'
          : rolesRedirect[me.role.code] || '/norights',
    },
    {
      path: '/',
      exact: true,
      component: lazyWithRetry(() => import('../pages/Companies/index')),
      isAccess: isAdmin || companies,
    },
    {
      path: '/acc_manager-onboarding',
      exact: true,
      isAccess: true,
      redirect: rolesRedirect[me.role.code] || '/norights',
      component: lazyWithRetry(() =>
        import('../pages/OnboardingForAccManager/index'),
      ),
    },

    {
      path: '/norights',
      exact: false,
      component: lazyWithRetry(() => import('../pages/NoRights/index')),
      isAccess: true,
    },
    {
      path: '/support-tickets/:id',
      exact: true,
      component: lazyWithRetry(() => import('../pages/SupportTicket/index')),
      isAccess: isAdmin || support,
    },

    {
      path: '/support-tickets',
      exact: true,
      component: lazyWithRetry(() => import('../pages/SupportTickets/index')),
      isAccess: isAdmin || support,
    },
    {
      path: '/posts/create',
      exact: true,
      component: lazyWithRetry(() => import('../pages/CreateBlog/index')),
      isAccess: isAdmin || articles,
    },
    {
      path: '/blog/:id',
      exact: true,
      component: lazyWithRetry(() => import('../pages/CurrentBlog/index')),
      isAccess: isAdmin || companies || articles,
    },
    {
      path: '/default-prices',
      exact: true,
      component: lazyWithRetry(() =>
        import('../pages/DefaultPrices/DefaultPrices'),
      ),
      isAccess: true,
    },
    {
      path: '/companies/create',
      exact: true,
      component: lazyWithRetry(() => import('../pages/CreateCompany/index')),
      isAccess: roles.includes(me.role.code),
    },

    {
      path: '/company/:id',
      exact: true,
      component: lazyWithRetry(() => import('../pages/CurrentCompany/index')),
      isAccess: isAdmin || companies,
    },

    {
      path: '/companies',
      exact: true,
      component: lazyWithRetry(() => import('../pages/Companies/index')),
      isAccess: isAdmin || companies,
    },

    {
      path: '/users-list',
      exact: true,
      component: lazyWithRetry(() => import('../pages/UsersList/index')),
      isAccess: isAdmin || users,
    },

    {
      path: '/email',
      exact: true,
      component: lazyWithRetry(() => import('../pages/Email/index')),
      isAccess: isAdmin || emails,
    },
    {
      path: '/email-accounts',
      exact: true,
      component: lazyWithRetry(() => import('../pages/EmailAccounts/index')),
      isAccess: isAdmin || email_accounts,
    },

    {
      path: '/posts',
      exact: true,
      component: lazyWithRetry(() => import('../pages/Posts/index')),
      isAccess: isAdmin || articles,
    },
    {
      path: '/statistics',
      exact: true,
      component: lazyWithRetry(() => import('../pages/Statistics/index')),
      isAccess: isAdmin,
    },
  ]

  return [routes, redirect]
}
