export const SET_PROPOSALS_FORM = 'SET_PROPOSALS_FORM'
export const PROPOSAL_CHANGE_FIELD = 'PROPOSAL_CHANGE_FIELD'
export const PROPOSAL_SET_FIELD = 'PROPOSAL_SET_FIELD'
export const PROPOSAL_TAB_FIELD_CHANGE = 'PROPOSAL_TAB_FIELD_CHANGE'
export const PROPOSAL_SECTION_FIELD_CHANGE = 'PROPOSAL_SECTION_FIELD_CHANGE'
export const PROPOSAL_SECTION_STRUCTURE_FIELD_CHANGE =
  'PROPOSAL_SECTION_STRUCTURE_FIELD_CHANGE'
export const PROPOSAL_SECTION_STRUCTURE_REORDER =
  'PROPOSAL_SECTION_STRUCTURE_REORDER'
export const PROPOSAL_CHANGE_TAB_INFO = 'PROPOSAL_CHANGE_TAB_INFO'
export const PROPOSAL_CHANGE_TAB_OPTIONAL_MODAL =
  'PROPOSAL_CHANGE_TAB_OPTIONAL_MODAL'
export const PROPOSAL_CLEAR_TAB_OPTIONAL_MODAL =
  'PROPOSAL_CLEAR_TAB_OPTIONAL_MODAL'
export const PROPOSAL_ADD_TAB = 'PROPOSAL_ADD_TAB'
export const PROPOSAL_CHANGE_TAB_POSITION = 'PROPOSAL_CHANGE_TAB_POSITION'
export const PROPOSAL_ADD_ITEM_SECTION = 'PROPOSAL_ADD_ITEM_SECTION'
export const PROPOSAL_CHANGE_ITEM_SECTION = 'PROPOSAL_CHANGE_ITEM_SECTION'
export const PROPOSAL_SECTION_ITEM_FIELD_CHANGE =
  'PROPOSAL_SECTION_ITEM_FIELD_CHANGE'
export const PROPOSAL_COPY_TAB = 'PROPOSAL_COPY_TAB'
export const PROPOSAL_COPY_SECTION = 'PROPOSAL_COPY_SECTION'
export const PROPOSAL_DELETE_SECTION = 'PROPOSAL_DELETE_SECTION'
export const PROPOSAL_DELETE_TAB = 'PROPOSAL_DELETE_TAB'
export const PROPOSAL_TOGGLE_SECTION = 'PROPOSAL_TOGGLE_SECTION'
export const PROPOSAL_ADD_TAG = 'PROPOSAL_ADD_TAG'
export const PROPOSALS_ADD_SECTION = 'PROPOSALS_ADD_SECTION'
export const PROPOSAL_TAB_SET_REORDERED_SECTIONS =
  'PROPOSAL_TAB_SET_REORDERED_SECTIONS'
export const PROPOSAL_TAB_FILE_UPLOAD = 'PROPOSAL_TAB_FILE_UPLOAD'
export const PROPOSAL_TAB_FILE_DELETE = 'PROPOSAL_TAB_FILE_DELETE'
export const SECTION_ITEM_FILE_UPLOAD = 'SECTION_ITEM_FILE_UPLOAD'
export const SECTION_ITEM_FILE_DELETE = 'SECTION_ITEM_FILE_DELETE'
export const SECTION_ITEM_OPTION_FILE_UPLOAD = 'SECTION_ITEM_OPTION_FILE_UPLOAD'
export const SECTION_ITEM_OPTION_FILE_DELETE = 'SECTION_ITEM_OPTION_FILE_DELETE'
export const PROPOSAL_ON_DELETE_SECTION = 'PROPOSAL_ON_DELETE_SECTION'
export const PROPOSAL_DELETE_ITEM_SECTION = 'PROPOSAL_DELETE_ITEM_SECTION'
export const PROPOSAL_ON_DELETE_TAB = 'PROPOSAL_ON_DELETE_TAB'
export const PROPOSAL_ON_DELETE_TAG = 'PROPOSAL_ON_DELETE_TAG'
export const PROPOSAL_FORM_FILE_DELETE = 'PROPOSAL_FORM_FILE_DELETE'
export const PROPOSAL_FORM_FILE_UPLOAD = 'PROPOSAL_FORM_FILE_UPLOAD'
export const PROPOSAL_CUSTOMER_FILE_UPLOAD = 'PROPOSAL_CUSTOMER_FILE_UPLOAD'
export const PROPOSAL_CUSTOMER_FILE_DELETE = 'PROPOSAL_CUSTOMER_FILE_DELETE'
export const SET_REORDERED_ITEMS = 'SET_REORDERED_ITEMS'
export const SET_TAB_IN_PROPOSAL = 'SET_TAB_IN_PROPOSAL'
export const PROPOSAL_GET_RELATIVES = 'PROPOSAL_GET_RELATIVES'
export const RESET_PROPOSAL_FORM = 'RESET_PROPOSAL_FORM'
export const SET_TEMPLATE_IN_SECTION = 'SET_TEMPLATE_IN_SECTION'
export const CLEAR_SECTION_ITEMS = 'CLEAR_SECTION_ITEMS'
export const SET_ALL_TABS_IN_PROPOSAL = 'SET_ALL_TABS_IN_PROPOSAL'
export const SET_DEFAULT_SETINGS_IN_PROPOSAL = 'SET_DEFAULT_SETINGS_IN_PROPOSAL'
export const PROPOSAL_PROPERTY_FILE_UPLOAD = 'PROPOSAL_PROPERTY_FILE_UPLOAD'
export const CLEAR_SIGNATURE_FIELD = 'CLEAR_SIGNATURE_FIELD'
export const UPDATE_PROJECT_FIELDS = 'UPDATE_PROJECT_FIELDS'
// Accounting
export const ACCOUNTING_UPLOAD_FILE = 'ACCOUNTING_UPLOAD_FILE'
export const ACCOUNTING_SET_ROW_ITEM = 'ACCOUNTING_SET_ROW_ITEM'
export const ACCOUNTING_SET_BLOCK_ITEM = 'ACCOUNTING_SET_BLOCK_ITEM'
export const FETCH_ACCOUNTING_DATA = 'FETCH_ACCOUNTING_DATA'
export const SET_PROPOSAL_FORM_FOR_ACCOUNTING =
  'SET_PROPOSAL_FORM_FOR_ACCOUNTING'
export const ACCOUNTING_SET_ITEMS = 'ACCOUNTING_SET_ITEMS'
export const UPDATE_PRIMARY_PROPERTY = 'UPDATE_PRIMARY_PROPERTY'

//////OPTIONS/////
export const PROPOSAL_CLOSE_OPTIONS_MODE_IN_ITEM =
  'PROPOSAL_CLOSE_OPTIONS_MODE_IN_ITEM'
export const PROPOSAL_ADD_OPTION_TO_ITEM = 'PROPOSAL_ADD_OPTION_TO_ITEM'
export const PROPOSAL_CHANGE_OPTION_IN_ITEM = 'PROPOSAL_CHANGE_OPTION_IN_ITEM'
export const PROPOSAL_OPTION_FIELD_CHANGE = 'PROPOSAL_OPTION_FIELD_CHANGE'
export const PROPOSAL_DELETE_OPTION = 'PROPOSAL_DELETE_OPTION'
export const PROPOSAL_SET_REORDERED_OPTIONS = 'PROPOSAL_SET_REORDERED_OPTIONS'
export const PROPOSAL_INIT_OPTIONS_IN_ITEM = 'PROPOSAL_INIT_OPTIONS_IN_ITEM'
export const PROPOSAL_CHOOSE_OPTION = 'PROPOSAL_CHOOSE_OPTION'
////OPTIONS/////

////CHECK FOR NOT COMPLETED NEW ITEMS///
export const PROPOSAL_ADD_UNCOMPLETED_NEW_ITEM_ID =
  'PROPOSAL_ADD_UNCOMPLETED_NEW_ITEM_ID'
export const PROPOSAL_DELETE_UNCOMPLETED_NEW_ITEM_ID =
  'PROPOSAL_DELETE_UNCOMPLETED_NEW_ITEM_ID'
export const PROPOSAL_RESET_UNCOMPLETED_NEW_ITEM_ID =
  'PROPOSAL_RESET_UNCOMPLETED_NEW_ITEM_ID'
////CHECK FOR NOT COMPLETED NEW ITEMS///

// TAXES
export const PROPOSAL_SET_TAXES = 'PROPOSAL_SET_TAXES'
export const PROPOSAL_SET_FEE = 'PROPOSAL_SET_FEE'
export const PROPOSAL_SET_FEE_TO_DELETE = 'PROPOSAL_SET_FEE_TO_DELETE'
export const PROPOSAL_SET_EDITED = 'PROPOSAL_SET_EDITED'
export const PROPOSAL_SET_TOTAL_AFTER_TAX = 'PROPOSAL_SET_TOTAL_AFTER_TAX'
export const PROPOSAL_CLEAR_TAXES = 'PROPOSAL_CLEAR_TAXES'
export const PROPOSAL_RATE_BY_ZIP = 'PROPOSAL_RATE_BY_ZIP'

//CANCELLATION FEES
export const PROPOSALS_SET_CANCELLATION_FEES = 'PROPOSALS_SET_CANCELLATION_FEES'

//INDUSTRY
export const PROPOSAL_SET_INDUSTRY = 'PROPOSAL_SET_INDUSTRY'

export const PROPOSAL_CLEAR_ID_TO_DELETE = 'PROPOSAL_CLEAR_ID_TO_DELETE'

// INFORMATION FOR/FROM CUSTOMER

export const PROPOSAL_CLEAR_CUSTOMER_INFORMATION =
  'PROPOSAL_CLEAR_CUSTOMER_INFORMATION'

//SIGNATURE
export const RESET_PROJECT_SIGNATURE = 'RESET_PROJECT_SIGNATURE'
