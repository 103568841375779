import { Popconfirm, Tooltip } from 'antd'
import { MenuItem, TextField } from '@material-ui/core'
import MainButton from '../../../../components/buttons/MainButton'
import ProposalStatus from '../../../../components/ProposalStatus/ProposalStatus'

import TableActionsNew from '../../../../components/TableActionsNew'
import { separateDateTimeByStr } from 'helpers/date/separateDateTimeByStr'

import './Header.scss'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'

import {
  selectProjectSlice,
  updateProjectField,
} from 'features/Project/slices/projectSlice'

import { EditProjectCode } from './components/EditProjectCode'

import { Companies } from 'types/Company'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import {
  ProjectPermissions,
  ModuleName,
} from 'features/Settings/UsersAndGroups'
import {
  selectUserPermissionsByModule,
  selectIsPriceVisible,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import { meSelector } from 'store/SuperAdmin/selectors'
import React, { useState } from 'react'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { ProjectStatusGroup } from 'features/Project/types'
import {
  selectProjectActiveTab,
  selectProjectTotal,
} from 'features/Project/projectSelectors'
import { updateProjectTabField } from 'features/Project/slices/projectTabsSlice'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { TextArea } from 'components/UIKit'
import moment from 'moment-timezone'

const proposalNameInputProps = { maxLength: 100 }

const Header = props => {
  const dispatch = useAppDispatch()

  const companyId = useAppSelector(state => state.orcatec.company?.id)
  const me = useAppSelector(meSelector)
  const { isEdited: isProjectEdited, project } = useAppSelector(
    selectProjectSlice,
  )
  const currentTab = useAppSelector(selectProjectActiveTab)
  const projectTotal = useAppSelector(selectProjectTotal)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const isPriceVisible = useAppSelector(selectIsPriceVisible(props.userId))

  const [projectName, setProjectName] = useState(project?.name)
  const [projectStatus, setProjectStatus] = useState(project?.status || 1)
  const [cancelModal, showCancelModal] = useState(false)
  const [cancellationReason, setCancellationReason] = useState('')
  const [updatingStatus, setUpdatingStatus] = useState(false)

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setProjectName(value)
  }

  const handleNameBlur = () => {
    dispatch(
      updateProjectField({
        name: projectName,
      }),
    )
  }

  const tableActionProps = {
    todos: [
      'settings-blue',
      'proposal insights',
      props?.hadBeenSent ? 'email-green' : 'email',
      'view',
      'copyThin',
      props?.hadBeenViewed ? 'activity-green' : 'activity',
      'logs',
      'quickbooks',
    ],
    callbacks: [
      props.openSettingsModal,
      props.openAccountingModal,
      props.openEmailHistoryModal,
      props.openProposalCustomerView,
      // props.copyProposal,
      props.handleClickDuplicate,
      props.openClientActivityModal,

      props.toggleStatusLogModal,
      props.toggleQuickbooksModal,
    ],
    preloaders: [
      false,
      false,
      false,
      props.isPreviewOpening,
      props.isCopying,
      false,
      false,
      false,
    ],
    tooltips: [
      'Project settings',
      'Project Insights',
      'Send Project',
      [Companies.SoCal].includes(companyId)
        ? 'Field Work Order'
        : 'Customer View',
      'Copy Project',
      'Viewing History',
      'View Project History',
      'QuickBooks',
    ],
    visible: [
      checkAccessControl(
        permissions.project_settings_can_read,
        props.userId,
        me.id,
      ),
      checkAccessControl(
        permissions.project_insights_can_read,
        props.userId,
        me.id,
      ) && isPriceVisible,

      !!isPriceVisible,
      true,
      checkAccessControl(
        permissions.project_can_duplicate,
        props.userId,
        me.id,
      ),
      true,
      true,
      props.showQB,
    ],
  }

  const checkAccessToChangeStatus = (
    value: ProjectStatusGroup,
    previousProposalStatus: ProjectStatusGroup,
    cb,
  ) => {
    let noAccess = true
    const {
      project_can_change_status_from_estimate_to_contract,
      project_can_change_status_from_contract_to_completed,
      project_can_change_status_from_completed_to_contract,
      project_can_change_status_from_contract_to_estimate,
      project_can_change_status_from_contract_to_canceled,
      project_access_to_contract,
    } = permissions

    const text = 'You do not have access to the action.'

    if (value === 1) {
      if (
        previousProposalStatus === 2 &&
        !checkAccessControl(
          project_can_change_status_from_contract_to_estimate,
          project?.user_id,
          me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
    } else if (value === 2) {
      if (
        (previousProposalStatus === 1 &&
          !checkAccessControl(
            project_can_change_status_from_estimate_to_contract,
            project?.user_id,
            me.id,
          )) ||
        !checkAccessControl(project_access_to_contract, project?.user_id, me.id)
      ) {
        noAccess = false
        cb(text)
      }
      if (
        previousProposalStatus === 3 &&
        !checkAccessControl(
          project_can_change_status_from_completed_to_contract,
          project?.user_id,
          me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
    } else if (value === 3) {
      if (
        previousProposalStatus === 2 &&
        !checkAccessControl(
          project_can_change_status_from_contract_to_completed,
          project?.user_id,
          me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
    } else if (value === 4) {
      if (
        previousProposalStatus === 2 &&
        !checkAccessControl(
          project_can_change_status_from_contract_to_canceled,
          project?.user_id,
          me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
    }
    return noAccess
  }

  const handleStatusChange = async (value: ProjectStatusGroup) => {
    const isAccess = checkAccessToChangeStatus(
      value,
      project.status,
      (description: string) =>
        openNotificationWithIcon('warning', {
          message: 'Attention',
          description: description,
        }),
    )
    if (!isAccess) return

    if (value === ProjectStatusGroup.Canceled && !cancelModal) {
      return showCancelModal(true)
    }

    setUpdatingStatus(true)

    const res = await dispatch(
      updateProjectField({
        status: value,
        signed_tab_id:
          value === ProjectStatusGroup.Contract
            ? currentTab?.id
            : value === ProjectStatusGroup.Estimate
            ? null
            : project.signed_tab_id,
        canceled_info:
          value === ProjectStatusGroup.Canceled
            ? {
                date: moment().utc(),
                reason: cancellationReason,
                user: me.full_name,
              }
            : null,
      }),
    )

    setUpdatingStatus(false)

    if (cancelModal) showCancelModal(false)
    if (value === ProjectStatusGroup.Canceled) {
      dispatch(
        updateProjectTabField({
          contract_cancelled: projectTotal,
        }),
      )
    }

    if (res.meta.requestStatus === 'rejected')
      return openNotificationWithIcon('warning', {
        message: 'Attention',
        description: res.payload?.errors?.status,
      })

    setProjectStatus(value)
  }

  const showStatusInfo = props.proposalStatus === 4 && !!props.canceledInfo

  return (
    <>
      <div className='proposal-form-header'>
        <div className='proposal-form-header-part1'>
          <div className='proposal-form-header-col'>Edit Project</div>
          <EditProjectCode
            proposalId={props.proposalId}
            projectCode={props.proposalCode}
            proposalStatus={props.proposalStatus}
            userId={props.userId}
          />

          <div className='proposal-form-header-col'>
            <TextField
              id='proposal-name'
              type='text'
              label='Project name'
              name='name'
              variant='outlined'
              fullWidth
              size='small'
              value={projectName}
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              inputProps={proposalNameInputProps}
              style={{ minWidth: '100px' }}
            />
            <TableActionsNew {...tableActionProps} />
          </div>
        </div>
        <div className='proposal-form-header-part2'>
          <div
            className={
              'proposal-form-header-col' +
              (props.proposalStatus === 4 && !!props.canceledInfo
                ? ' caneled-status'
                : '')
            }
          >
            <TextField
              id='proposal-status'
              size='small'
              variant='outlined'
              label='Status'
              name='status'
              disabled={updatingStatus}
              onChange={e => handleStatusChange(e.target.value)}
              value={projectStatus}
              select
              fullWidth
            >
              {[1, 2, 3, 4, 5].map(status => {
                return (
                  <MenuItem key={`status-${status}`} value={status}>
                    <ProposalStatus
                      statusId={status}
                      withStatusName={true}
                      isStatusChanging={updatingStatus}
                    />
                  </MenuItem>
                )
              })}
            </TextField>
            {showStatusInfo &&
              (props.proposalStatus === 4 ? (
                <Tooltip
                  title={
                    props.canceledInfo.reason &&
                    props.canceledInfo.reason?.length > 20
                      ? 'Reason: ' + props.canceledInfo.reason
                      : ''
                  }
                >
                  <div className='proposal-form-header-col-status-canceled_info'>
                    <div>
                      Cancelled by:{' '}
                      <span>
                        {props.canceledInfo.user +
                          ' on ' +
                          separateDateTimeByStr('at', props.canceledInfo.date)}
                      </span>
                    </div>
                    {!!props.canceledInfo.reason && (
                      <div>
                        Reason:{' '}
                        <span>
                          {props.canceledInfo.reason.slice(0, 20).trim() +
                            (props.canceledInfo.reason?.length > 20
                              ? '...'
                              : '')}
                        </span>
                      </div>
                    )}
                  </div>
                </Tooltip>
              ) : (
                <div className='proposal-form-header-col-status-canceled_info'>
                  <div>
                    Contract by:{' '}
                    <span>
                      {props.contractInfo.signed_by +
                        ' on ' +
                        separateDateTimeByStr(
                          'at',
                          props.contractInfo.contract_date,
                        )}
                    </span>
                  </div>
                </div>
              ))}
          </div>
          <div className='proposal-form-header-col buttons'>
            <AccessControl
              module={ModuleName.PROJECT}
              scopes={[ProjectPermissions.PROJECT_CAN_DELETE]}
              author={props.userId}
              additionalAccess={props.proposalStatus === 1}
            >
              <Popconfirm
                title='Are you sure to delete this project?'
                onConfirm={props.deleteProposal}
                okText='Yes'
                cancelText='No'
                className='payment-balance-confirm'
              >
                <MainButton title='Delete' isFetching={props.isDeleting} />
              </Popconfirm>
            </AccessControl>

            {props.isEdited && (
              <MainButton
                title='Cancel'
                type='cancel'
                onClick={props.closeProposalForm}
                isFetching={props.isCanceling}
              />
            )}
            {(props.isEdited || isProjectEdited || props.isEditContract) && (
              <Popconfirm
                title='You have unsaved items. Please check to proceed.'
                onConfirm={props.checkNewItems}
                onCancel={() =>
                  props.saveProposal('reset uncompleted new items')
                }
                okText='Check'
                cancelText='Save anyway'
                className='payment-balance-confirm'
                disabled={!props.uncompletedNewItemsIds?.length}
              >
                <MainButton
                  title='Done'
                  onClick={
                    /* 
                    !props.isProjectHasContacts
                      ? props.toggleConfirmModal
                      : !props.uncompletedNewItemsIds?.length
                      ?  */ props.saveProposal
                    /*  : () => null */
                  }
                  isFetching={props.isSaving}
                />
              </Popconfirm>
            )}

            <AccessControl
              additionalAccess={props.proposalStatus === 2}
              module={ModuleName.PROJECT}
              scopes={[ProjectPermissions.PROJECT_CAN_EDIT_CONTRACT]}
              author={props.userId}
            >
              {!props.isEditContract && (
                <MainButton
                  title='Edit contract'
                  type='custom-secondary'
                  onClick={props.setModalEditContract}
                  style={{ fontSize: 11 }}
                />
              )}
            </AccessControl>
          </div>
        </div>
      </div>

      {cancelModal && (
        <Modal
          title='Cancel Project'
          onCancel={() => showCancelModal(false)}
          onOk={() => handleStatusChange(4)}
          visible
          width={400}
        >
          <div>
            <h6>Are you sure you want to cancel Project?</h6>

            <TextArea
              value={cancellationReason}
              placeholder='Cancellation reason...'
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setCancellationReason(e.target.value)
              }
              style={{ margin: '20px 0 20px' }}
            />
          </div>
        </Modal>
      )}
    </>
  )
}

export default Header
