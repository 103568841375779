import axios from 'axios'
import { refreshToken } from './Auth'
import * as actions from 'store/Orcatec/actions'
import moment from 'moment-timezone'
import { store } from 'index'
import packageJson from '../../package.json'
import { makeBrowserTitle } from 'helpers/makeBrowserTitle'

export const CancelToken = axios.CancelToken

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(promise =>
    error ? promise.reject(error) : promise.resolve(token),
  )
  failedQueue = []
}

const axiosOrcatec = (() => {
  const params = {}
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-timezone': moment.tz.guess(), // Custom header with current user's timezone
    'X-appVersion': packageJson.version, //current App version
    //'X-page': makeBrowserTitle({ customTitle: '', prefixForTitle: '' }),
  }

  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    params,
    headers,
  })
})()

axiosOrcatec.interceptors.request.use(
  config => {
    const auth = localStorage.getItem('auth')
      ? JSON.parse(localStorage.getItem('auth'))
      : null

    if (auth) config.headers.Authorization = `Bearer ${auth.token}`

    return config
  },
  error => Promise.reject(error),
)

axiosOrcatec.interceptors.response.use(
  data => data,
  error => {
    const originalRequest = error.config

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      localStorage.auth
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) =>
          failedQueue.push({ resolve, reject }),
        )
          .then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token
            return axios(originalRequest)
          })
          .catch(err => Promise.reject(err))
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise((resolve, reject) => {
        /* return  */ refreshToken()
          .then(({ data }) => {
            data.rememberMe = localStorage.auth.rememberMe
            data.expTime = Date.now() / 1000 + data.expires_in
            window.localStorage.setItem('auth', JSON.stringify(data))
            originalRequest.headers['Authorization'] = 'Bearer ' + data.token
            processQueue(null, data.token)
            resolve(axios(originalRequest))
          })
          .catch(err => {
            processQueue(err, null)
            if (window.location.pathname === '/login') {
              delete localStorage.auth
            }
            reject(err)
          })
          .finally(() => (isRefreshing = false))
      })
    } else if (error.response?.status === 402) {
      store.dispatch(actions.negativeBalance.toggleNegativeBalanceModal?.())
      return Promise.reject(error)
    }

    return Promise.reject(error)
  },
)

export default axiosOrcatec
