import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Select } from 'antd'
import { assignMembership } from 'api/Membership'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { SettingsRow } from 'containers/MainContent/Orcatec/components/UI/SettingsRow/SettingsRow'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import useMemberships from '../hooks/useMemberships'
import {
  MembershipDiscountType,
  MembershipEntityType,
  MembershipRelation,
} from '../types'

const { Option } = Select

interface Props {
  entityType: MembershipEntityType
  entityId?: string
  onClose: () => void
  onUpdate: () => void
}

export const AssignMembershipForm = ({
  entityId,
  entityType,
  onClose,
  onUpdate,
}: Props) => {
  const { labels } = useMemberships()
  const { id: propertyId } = useParams<{ id: string }>()

  const [state, setState] = useState<MembershipRelation>({
    label_id: null,
    assign_children: false,
    start_date: moment(),
    end_date: moment().add(1, 'year'),
  })
  const [error, setError] = useState(null)

  const handleChange = (value: string) => {
    setState(prev => ({
      ...prev,
      label_id: value,
    }))

    if (error?.label_id) setError(null)
  }

  const handleChangeDate = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined,
    name: string,
  ) => {
    setState(prev => ({
      ...prev,
      [name]: date,
    }))

    if (name === 'start_date' && !!date) {
      setState(prev => ({
        ...prev,
        end_date: date?.clone().add(1, 'year'),
      }))
    }
  }

  const handleAssignLabel = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    try {
      await assignMembership(entityType, entityId || propertyId, {
        ...state,
        start_date: moment(state.start_date).format('YYYY-MM-DD'),
        end_date: moment(state.end_date).format('YYYY-MM-DD'),
      })

      openNotificationWithIcon('success', {
        message: 'Membership has been added',
      })
      onClose?.()
      onUpdate?.()
    } catch (error) {
      setError(error.response.data.errors)
      openNotificationWithIcon('error', {
        message: error?.response?.data?.message || 'Something went wrong',
      })
    }
  }

  return (
    <Wrapper>
      <SettingsRow title='Choose membership name'>
        <div>
          <Select
            style={{ width: 258 }}
            placeholder='Select membership'
            onChange={handleChange}
            optionLabelProp='label'
            className={error?.label_id ? ' error' : ''}
          >
            {labels
              ?.filter(membership => !membership.deleted_at)
              ?.map(label => (
                <Option value={label.id} label={label.name} key={label.id}>
                  <div
                    className='demo-option-label-item'
                    style={{ display: 'flex', gap: 5, alignItems: 'center' }}
                  >
                    <p
                      style={{
                        height: 20,
                        width: 30,
                        backgroundColor: label.color,
                      }}
                    />
                    {label.name}
                    <span style={{ marginLeft: 10 }}>
                      {label.discount_type === MembershipDiscountType.Amount &&
                        '$'}
                      {label.discount}
                      {label.discount_type === MembershipDiscountType.Percent &&
                        '%'}
                    </span>
                  </div>
                </Option>
              ))}
          </Select>
          {error?.label_id && (
            <p style={{ fontSize: 10, color: 'red' }}>{error.label_id}</p>
          )}
        </div>
      </SettingsRow>

      <SettingsRow title='Start date'>
        <KeyboardDatePicker
          disableToolbar
          variant='inline'
          clearable
          autoOk
          placeholder='Select date'
          value={state.start_date}
          inputVariant='outlined'
          format='MM/DD/YYYY'
          size='small'
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(momentObj, value) =>
            handleChangeDate(momentObj, value, 'start_date')
          }
        />
      </SettingsRow>

      <SettingsRow title='End date'>
        <KeyboardDatePicker
          disableToolbar
          variant='inline'
          clearable
          autoOk
          placeholder='Select date'
          value={state.end_date}
          inputVariant='outlined'
          format='MM/DD/YYYY'
          size='small'
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(momentObj, string) =>
            handleChangeDate(momentObj, string, 'end_date')
          }
        />
      </SettingsRow>

      <Buttons>
        <MainButton type='cancel' title='Cancel' onClick={onClose} />
        <MainButton title='Save' onClick={handleAssignLabel} />
      </Buttons>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .error {
    .ant-select-selector {
      border-color: red;
    }
  }
`
const Buttons = styled.div`
  display: flex;
  gap: 10px;
  margin: 10px 0 20px;
`
