import React from 'react'

import Message from './Message'

const MessageBag = ({ messages, me }) => {
  return (
    <div className='ticket__message-bag'>
      {messages.map((item, idx) => (
        <Message
          key={idx}
          isSelf={item.sender.id === me.id}
          title={!idx ? 'Description' : item.sender.id === me.id ? 'You:' : 'Support team answer:'}
          sentTimestamp={item.created_at}
          body={item.body}
          attachments={item.attachments || []}
        />
      ))}
    </div>
  )
}

export default MessageBag
