import React, { useEffect, useState } from 'react'
import Autocomplete from 'containers/MainContent/Orcatec/components/Autocomplete'
import { AppointmentType } from 'types/Appointment'
import styled from 'styled-components'
import { Popconfirm } from 'antd'
import { Error } from 'types/Error'
import { DeleteFilled } from '@ant-design/icons'
import ItemsAPI from 'api/Items'
import { ItemUnit as IItemUnit } from 'features/Items/types'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ProjectPermissions,
  ModuleName,
} from 'features/Settings/UsersAndGroups'
import {
  itemUnitAdded,
  itemUnitDeleted,
} from 'features/Project/slices/projectItemsSlice'

const sourceAndTypeStyle = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '14px',
  letterSpacing: '0.01em',
  color: ' #191919',
}

interface Props {
  label?: string
  name: string
  value: number | null
  error?: { [key: string]: string }
  onChange: (
    e: { target: { name: string; value: any } },
    ignoreEdit?: boolean,
  ) => void
  setEdited?: (val: boolean) => void
  required?: boolean
  onClick?: () => void
}

export const ItemUnit = ({
  onChange,
  name,
  label,
  value,
  error,
  ...rest
}: Props) => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [localError, setLocalError] = useState<Error | null>(null)
  const [units, setUnits] = useState<IItemUnit[]>([])

  const canManageUnit = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.PROJECT,
      ProjectPermissions.PROJECT_CAN_CREATE_DELETE_UNIT_QTY,
    ),
  )

  useEffect(() => {
    const getData = async () => {
      const data = await ItemsAPI.getItemUnits()

      setUnits(data)
    }

    getData()
  }, [])

  const handleChangeAppType = async (
    _: React.ChangeEvent<HTMLInputElement>,
    value: any,
  ) => {
    if (localError) setLocalError(null)
    if (!value) return /* onChange({ target: { name, value: null } }) */

    if (value?.id || value?.id === null)
      return onChange({ target: { name, value: value.id } })

    const newValue = value?.inputValue || value

    const existingType = units
      // ?.filter(type => !type.deleted_at)
      ?.find(type => type.name === newValue)

    if (existingType)
      return onChange({ target: { name, value: existingType.id } })

    try {
      if (!canManageUnit) return
      setLoading(true)
      const createdUnit = await ItemsAPI.createItemUnit({
        name: newValue,
      } as IItemUnit)

      setUnits(prev => [...prev, createdUnit])
      onChange({ target: { name, value: createdUnit.id } })

      dispatch(itemUnitAdded(createdUnit))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleBlurEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const exitstingType = ['Qty', ...units?.map(type => type?.name)]

    // if (!value) {
    //   const defaultValue = appTypes?.find(type => type.name === '-')
    //   onChange({ target: { name, value: defaultValue?.id } })
    //   return
    // }
    if (!value || exitstingType.includes(value)) return

    handleChangeAppType(e, value)
  }

  const handleDeleteType = async (
    e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    id: number,
  ) => {
    e?.stopPropagation()

    await ItemsAPI.deleteItemUnit(id)

    setUnits(prev => prev.filter(unit => unit.id !== id))

    dispatch(itemUnitDeleted(id))

    if (id === value) onChange({ target: { name, value: null } })
  }

  const filterOptions = (options, params) => {
    // const filtered = options?.filter(
    //   option =>
    //     option?.id !== null && !option?.deleted_at && option?.name !== '-',
    // )
    const filter = options?.filter(f =>
      f?.name?.toLowerCase()?.includes(params?.inputValue?.toLowerCase()),
    )
    if (params?.inputValue !== '' && !filter?.length && canManageUnit) {
      filter?.push({
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`,
      })
    }

    return filter
  }

  return (
    <div>
      <Label>
        {label}
        {rest.required && '*'}
      </Label>

      <Autocomplete
        loading={loading}
        name={name}
        value={value}
        onChange={handleChangeAppType}
        onBlur={handleBlurEvent}
        options={[{ id: null, name: 'Qty' }, ...units]}
        error={localError || error}
        filterOptions={filterOptions}
        renderOption={(option: AppointmentType) => (
          <div style={sourceAndTypeStyle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {option?.name}
            </div>
            {!!option?.id && !!canManageUnit && (
              <Popconfirm
                title='Are you sure you want to delete this type?'
                overlayStyle={{
                  zIndex: 1400, //to show tooltip over dropdown
                }}
                onConfirm={e => {
                  if (!option.id) return

                  handleDeleteType(e, option?.id)
                }}
              >
                <DeleteFilled onClick={e => e.stopPropagation()} />
              </Popconfirm>
            )}
          </div>
        )}
        {...rest}
      />
    </div>
  )
}

export const Label = styled.p`
  color: rgba(0, 0, 0, 0.54);
`
