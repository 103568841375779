import { TagFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import {
  MembershipDiscountType,
  MembershipLabel,
  MembershipStatus,
} from 'features/Membership/types'
import styled from 'styled-components'

interface Props {
  memberships: MembershipLabel[]
  tagSize?: number
}

export const MembershipLabels = ({ memberships, tagSize = 20 }: Props) => {
  return (
    <Wrapper>
      {memberships?.map((membership, index) => (
        <Tooltip
          key={index}
          title={`${membership.name} - ${
            membership.discount_type === MembershipDiscountType.Amount
              ? '$'
              : ''
          }${membership.discount}${
            membership.discount_type === MembershipDiscountType.Percent
              ? '%'
              : ''
          } (expire${
            membership.status === MembershipStatus.Expired ? 'd' : 's'
          } ${membership.end_date})`}
        >
          <TagFilled
            style={{
              color:
                membership.status === MembershipStatus.Expired
                  ? '#9c9c9c'
                  : membership.color,
              fontSize: tagSize,
              opacity: membership.status === MembershipStatus.Expired ? 0.7 : 1,
              marginLeft: index === 0 ? 0 : -tagSize / 2.5,
              zIndex: index,
            }}
          />
        </Tooltip>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div``
