import { useCallback, useState } from 'react'
import { upload } from 'api/Media'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { notification } from 'antd'
import { ImageUploader } from 'components/UIKit/ImageUploader/ImageUploader'

import './styles.scss'
import { TextArea, TextField } from 'components/UIKit'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { updateProjectTabField } from 'features/Project/slices/projectTabsSlice'
import {
  selectProject,
  selectProjectTabsSlice,
} from 'features/Project/projectSelectors'
import { projectFieldUpdated } from 'features/Project/slices/projectSlice'

const TabInfo = ({ tabInfo, onCancel, onSave }) => {
  const dispatch = useAppDispatch()

  const project = useAppSelector(selectProject)
  const { status: tabStatus } = useAppSelector(selectProjectTabsSlice)

  const [tabData, setTabData] = useState(tabInfo)
  const [uploading, setUploading] = useState(false)

  const handleDataChange = e => {
    const { name, value } = e.target

    setTabData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const setCroppedLogo = useCallback(file => {
    const data = new FormData()
    data.append('media', file)
    setUploading(true)

    upload(data)
      .then(images => {
        setTabData(prev => ({ ...prev, image: images[0] }))
      })
      .catch(error => notification.error({ message: error?.message }))
      .finally(() => {
        setUploading(false)
        setTabData(prev => ({ ...prev, crop: null }))
      })
  }, [])

  const onDeletePicture = () => {
    setTabData(prev => ({ ...prev, image: '', crop: null }))
  }

  const handleSave = async tabInfo => {
    await dispatch(updateProjectTabField(tabInfo))

    const newTabsInfo = [...project.tabs_info]
    newTabsInfo[project?.activeTab] = tabInfo

    dispatch(
      projectFieldUpdated({
        tabs_info: newTabsInfo,
      }),
    )
    onSave?.()
  }

  return (
    <div className='tab-info'>
      <TextField
        value={tabData?.title || ''}
        label='Title'
        name='title'
        onChange={handleDataChange}
        maxLength={250}
        helperText='Max length 250 characters'
      />

      <div
        style={{
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ImageUploader
          image={tabData?.image?.source}
          onUploadFinish={setCroppedLogo}
          onDeleteImage={onDeletePicture}
        />
      </div>

      <TextArea
        value={tabData?.description || ''}
        label='Description'
        multiline
        maxRows={6}
        name='description'
        onChange={handleDataChange}
        inputProps={{ maxLength: 2000 }}
      />
      <span>•</span>

      <div className='tab-info-buttons'>
        <MainButton type='cancel' title='Cancel' onClick={onCancel} />
        <MainButton
          title='Ok'
          onClick={() => handleSave(tabData)}
          // disabled={uploading}
          isFetching={uploading || tabStatus === 'loading'}
        />
      </div>
    </div>
  )
}

export default TabInfo
