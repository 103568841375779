import React, { Component } from 'react'
import './style.scss'

import MainButton from '../../components/buttons/MainButton'

class RegisterFinishPage extends Component {
  render() {
    return (
      <div className='finish-page'>
        <div className='finish-text'>Congratulations on registering your company and Welcome to Orcatec family!</div>
        <div className='finish-please-text'>Please login to your account and take some time to set up the company.</div>
        <MainButton title='Finish' onClick={this.props.confirmEmailOnFinish} className='w-100 main-button' />
      </div>
    )
  }
}
export default RegisterFinishPage
