import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProjectSection } from '../types'
import { ProjectSectionsAPI } from 'api/Project'
import { AsyncThunkAPI } from 'store/types'
import { Error } from 'types/Error'
import {
  addSection,
  onDeleteSectionFromTab,
} from 'store/Orcatec/actions/proposal/proposalForm'
import { itemsSet, resetSectionItems } from './projectItemsSlice'
import { itemsGroupsSet } from './projectGroupsSlice'
import { discountsSet } from './projectDiscountsSlice'
import { rebatesSet } from './projectRebatesSlice'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

const populateCreatedSection = (section: ProjectSection, dispatch) => {
  dispatch(addSection(section))

  if (section.items.length) dispatch(itemsSet(section.items))
  if (section.discounts.length) dispatch(discountsSet(section.discounts))
  if (section.rebates.length) dispatch(rebatesSet(section.rebates))
  if (section.groups.length) dispatch(itemsGroupsSet(section.groups))
}

const initialState: {
  status: 'idle' | 'loading' | 'error'
  sections: { ids: number[]; data: Record<number, ProjectSection> }
  error: Error | null
} = {
  status: 'idle',
  sections: { ids: [], data: {} },
  error: null,
}

const projectSectionsSlice = createSlice({
  name: 'projectSections',
  initialState,
  reducers: {
    sectionsSet: (state, { payload }: PayloadAction<ProjectSection[]>) => {
      state.sections = {
        ids: payload.map(section => section.id),
        data: transformArrayToObj(payload, 'id', { show: true }),
      }
    },
    sectionUpdated: (state, { payload }) => {
      state.sections.data[payload.sectionId] = {
        ...state.sections.data[payload.sectionId],
        ...payload.fields,
      }
    },
    sectionRemoved: (state, { payload }) => {
      state.sections.ids = state.sections.ids.filter(id => id !== payload)
    },
    sectionReordered: (state, { payload }: PayloadAction<ProjectSection[]>) => {
      state.sections = {
        ids: payload.map(section => section.id),
        data: transformArrayToObj(payload),
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(addProjectSection.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(addProjectSection.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.sections = {
        ids: [...state.sections.ids, payload.id],
        data: {
          ...transformArrayToObj(
            Object.values(state.sections.data).map(section => ({
              ...section,
              show: false,
            })),
          ),
          [payload.id]: { ...payload, show: true },
        },
      }
    })
    builder.addCase(addProjectSection.rejected, (state, action) => {
      state.status = 'error'
      state.error = action.payload?.errors || null
    })

    builder.addCase(duplicateProjectSesction.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(
      duplicateProjectSesction.fulfilled,
      (state, { payload }) => {
        state.status = 'idle'
        state.sections = {
          ids: [...state.sections.ids, payload.id],
          data: {
            ...transformArrayToObj(
              Object.values(state.sections.data).map(section => ({
                ...section,
                show: false,
              })),
            ),
            [payload.id]: { ...payload, show: true },
          },
        }
      },
    )
    builder.addCase(duplicateProjectSesction.rejected, (state, action) => {
      state.status = 'error'
      state.error = action.payload?.errors || null
    })

    builder.addCase(deleteProjectSection.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteProjectSection.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.sections.ids = state.sections.ids.filter(id => id !== payload.id)
      // delete state.sections[payload.id]
    })
    builder.addCase(deleteProjectSection.rejected, (state, action) => {
      state.status = 'error'
      state.error = action.payload?.errors || null
    })
  },
})

export const {
  sectionsSet,
  sectionUpdated,
  sectionRemoved,
  sectionReordered,
} = projectSectionsSlice.actions
export default projectSectionsSlice.reducer

export const addProjectSection = createAsyncThunk<
  ProjectSection,
  ProjectSection & { section_id?: number; sectionIdx?: number },
  AsyncThunkAPI
>('projectSections/addSection', async (section, { dispatch }) => {
  const res = await ProjectSectionsAPI.addSection({
    ...section,
    title: 'New Section',
    // position,
  })

  populateCreatedSection(res, dispatch)

  if (section.section_id) {
    dispatch(sectionRemoved(section.section_id))
    dispatch(onDeleteSectionFromTab(section.sectionIdx, section.section_id))
    dispatch(resetSectionItems(section.section_id))
  }
  return res
})

export const updateProjectSection = createAsyncThunk<
  ProjectSection,
  { sectionId: number; fields: Record<keyof ProjectSection, unknown> },
  AsyncThunkAPI
>(
  'projectSection/updateSection',
  async ({ sectionId, fields }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(sectionUpdated({ sectionId, fields }))

      return await ProjectSectionsAPI.updateSectionTitle(sectionId, fields)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const deleteProjectSection = createAsyncThunk<
  ProjectSection,
  ProjectSection & { sectionIdx: number },
  AsyncThunkAPI
>(
  'projectSection/deleteSection',
  async (section, { dispatch, rejectWithValue }) => {
    try {
      await ProjectSectionsAPI.deleteSection(section.id)

      dispatch(resetSectionItems(section.id))
      dispatch(onDeleteSectionFromTab(section?.sectionIdx, section?.id))

      return section
    } catch (error) {
      openNotificationWithIcon('error', {
        message: error.response?.data?.message,
      })
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const duplicateProjectSesction = createAsyncThunk<
  ProjectSection,
  number
>('projectSection/duplicateSection', async (sectionId, { dispatch }) => {
  const duplicatedSection = await ProjectSectionsAPI.duplicateSection(sectionId)

  populateCreatedSection(duplicatedSection, dispatch)

  return duplicatedSection
})

export const reorderProjectSections = createAsyncThunk<
  void,
  ProjectSection[],
  AsyncThunkAPI
>(
  'projectSections/reorderSections',
  async (sections, { dispatch, rejectWithValue }) => {
    try {
      dispatch(sectionReordered(sections))

      await ProjectSectionsAPI.reorderSections(sections)
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)
