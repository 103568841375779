import { INote } from 'containers/MainContent/Orcatec/components/Notes/types'

export interface ClientEmail {
  id: number
  preference: boolean
  send_promo: boolean
  send_reminder: boolean
  email: string
  entity_type: ClientType
}

export interface ClientPhone {
  id: number
  phone: string
  preference: 'voice' | 'text'
  entity_type: ClientType
  type: 'cell' | 'work' | 'home' | 'other'
}

export enum ClientType {
  ORGANIZATION = 1,
  INDIVIDUAL = 2,
  CONTACT = 3,
}

export interface Client {
  id: number
  client_associations?: []
  client_properties: []
  memberships: []
  appointments: []
  contacts?: []
  emails: ClientEmail[]
  feedbacks: []
  is_individual: boolean
  name: string
  new_client_associations: []
  new_client_properties: []
  note: INote | string
  organizations: []
  owner_user_id: number
  phones: ClientPhone[]
  proposals: []
  proposal_relation?: ''
  tags: string[]
  type: ClientType
  relation_name?: string
  messagings?: []
  relations_with_property?: {
    [key: number]: {
      note?: string
    }
  }[]
}
