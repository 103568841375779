import { Table, Empty, Tooltip } from 'antd'
import styled from 'styled-components'
import moment from 'moment-timezone'
import AppointmentStatus from 'containers/MainContent/Orcatec/components/AppointmentStatus/AppointmentStatus'
import { renderNotes } from '../utils/renderNotes'

const Wrapper = styled.div`
  & .ant-table-thead > tr > th {
    padding: 4px;
  }

  & .ant-table-thead > th,
  .ant-table-thead > td {
    text-align: center;
  }
`

interface IProps {
  tableData: []
}

// const linkStyle = {
//   color: '#007bff',
//   cursor: 'pointer',
// }
export const AppointmentsHistoryTable = ({ tableData = [] }: IProps) => {
  // const goToAppointment = appointment => window.open(`/appointment/${appointment.id}`, '_self')

  const columns = [
    {
      title: 'Status',
      name: 'Status',
      dataIndex: 'status',
      sorter: false,
      defaultWidth: 200,
      render: status => (
        <AppointmentStatus statusId={status} withStatusName={false} />
      ),
    },
    {
      title: 'Date',
      name: 'Date',
      dataIndex: 'date',
      sorter: false,
      defaultWidth: 200,
      render: date => (date ? moment(date).format('MM/DD/YYYY') : '-'),
    },
    {
      title: 'Type',
      name: 'Type',
      dataIndex: 'appointment_type',
      sorter: false,
      defaultWidth: 250,
      render: type => type?.name || '-',
    },
    {
      title: 'Tech',
      name: 'Tech',
      dataIndex: 'matrix_user',
      sorter: false,
      defaultWidth: 200,
      noFilter: true,
      render: (name, record) => name || record?.username || 'QUEUE',
    },
    {
      title: 'Source',
      name: 'Source',
      dataIndex: 'appointment_source',
      sorter: false,
      defaultWidth: 200,
      noFilter: true,
      render: source => source?.name || '-',
    },

    {
      title: 'Appointment Notes',
      name: 'Appointment Notes',
      dataIndex: 'notes',
      sorter: false,
      defaultWidth: 100,
      noFilter: true,
      render: notes => (
        <Tooltip
          overlayStyle={{ whiteSpace: 'pre-line' }}
          title={notes?.map(n => n.text).join?.('\n\n')}
        >
          {renderNotes(notes)}
        </Tooltip>
      ),
    },
  ]

  return (
    <Wrapper>
      {tableData?.length ? (
        <Table pagination={false} columns={columns} dataSource={tableData} />
      ) : (
        <Empty description='No appointments yet' />
      )}
    </Wrapper>
  )
}
