import React from 'react'
import './style.scss'

const Time = ({ settings }) => {
  // const endHour = settings.end_hour > settings.start_hour ? settings.end_hour : settings.end_hour + 24
  return (
    <div className='time-container'>
      {new Array(settings.end_hour - settings.start_hour)?.fill('').map((time, i) => (
        <div key={i} className='time'>
          {i + settings.start_hour > 12 && i + settings.start_hour < 24
            ? `${i + settings.start_hour - 12}p`
            : i + settings.start_hour === 24
            ? `12a`
            : i + settings.start_hour === 12
            ? `12p`
            : i === 0 && settings.start_hour === 0
            ? `${12}a`
            : `${i + settings.start_hour < 12 ? i + settings.start_hour : i + settings.start_hour - 24}a`}
        </div>
      ))}
    </div>
  )
}
export default Time
