import moment from 'moment-timezone'

export const getStatusIcon = status => {
  switch (status) {
    case 'Active':
      return <i className='mdi mdi-chart-donut' />
    case 'En Route':
      return <i className='mdi mdi-truck-fast'></i>

    case 'In Progress':
      return <i className='mdi mdi-progress-clock' />

    case 'Completed':
      return <i className='mdi mdi-check-circle' />

    default:
      return <i className='mdi mdi-cancel' />
  }
}

export const calculateTimeDiff = (currentDate, previousDate, pretty) => {
  const hours = moment(currentDate).diff(moment(previousDate), 'hours')
  const minutesAsNumber = Number(
    moment
      .utc(
        moment(currentDate, 'HH:mm:ss')
          .set('second', 0)
          .diff(moment(previousDate, 'HH:mm:ss').set('second', 0)),
      )
      .format('m'),
  )
  const minutes = moment
    .utc(
      moment(currentDate, 'HH:mm:ss')
        .set('second', 0)
        .diff(moment(previousDate, 'HH:mm:ss').set('second', 0)),
    )
    .format('mm')
  return pretty ? ` ${hours}h : ${minutes}m` : { hours, minutes: minutesAsNumber }
}
