import * as types from '../../types'

export default (state = [], action) => {
  switch (action.type) {
    case types.workers.CHANGE_COLOR:
      const newState = [...state]
      newState[action.i].backgroundColor = action.color
      return newState
    case types.workers.ON_DRAG:
      const dragState = [...state]
      const temp = dragState[action.draggableIndex]
      dragState[action.draggableIndex] = dragState[action.targetIndex]
      dragState[action.targetIndex] = temp
      return dragState
    default:
      return state
  }
}
