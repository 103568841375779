export const transformObjectToStore = inputObject => {
  const result = {}

  for (const moduleName in inputObject) {
    if (moduleName in inputObject) {
      const moduleArray = inputObject[moduleName]

      moduleArray.forEach(entry => {
        const permission_key = entry.permission_key
        const value = entry.value

        if (!result[moduleName]) {
          result[moduleName] = {}
        }

        result[moduleName][permission_key] = value
      })
    }
  }

  return result
}
