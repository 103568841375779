import * as types from '../../types'

export default (state = {}, action) => {
  switch (action.type) {
    case types.serviceContract.SERVICE_CONTRACT_SET_ITEM:
      return action.item
    case types.serviceContract.SERVICE_CONTRACT_GET_ITEM:
      return state
    default:
      return state
  }
}
