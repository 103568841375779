import { Table, Empty } from 'antd'

import { parsePhoneNumber } from 'features/Messaging/utils'
import moment from 'moment-timezone'
import { useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import { getCallsStatus, fullLink } from 'features/Calls/common'

export const CallsHistoryTable = ({ tableData }) => {
  const timezone = useAppSelector(state => state.orcatec.company.timezone)
  const technicians = useAppSelector(state =>
    state.orcatec?.company?.technicians?.map(t => ({
      id: t.id,
      name: t.full_name,
    })),
  )

  const columns = [
    {
      title: 'From',
      name: 'From',
      dataIndex: 'from_number',
      sorter: false,
      width: 150,
      render: client => parsePhoneNumber(client),
    },
    {
      title: 'To',
      name: 'To',
      dataIndex: 'to_number',
      sorter: false,
      width: 150,
      render: text => parsePhoneNumber(text),
    },
    {
      title: 'Date',
      name: 'date',
      dataIndex: 'date',
      sorter: false,
      defaultWidth: 200,
      noFilter: true,
      render: data => moment(data || new Date()).format('MM/DD/YYYY'),
    },
    {
      title: 'Sources',
      key: 'sources',
      dataIndex: 'sources',
      width: 200,
      sorter: false,
      noFilter: true,
      render: sources => (sources ? sources : '--'),
    },
    {
      title: 'Time',
      name: 'Time',
      dataIndex: 'start_time',
      sorter: false,
      defaultWidth: 200,
      noFilter: true,
      render: time =>
        time
          ? moment(time)
              .tz(timezone)
              .format('hh:mm A')
          : '-',
    },

    {
      title: 'Record',
      name: 'Record',
      dataIndex: 'recording_url',
      sorter: false,

      defaultWidth: 400,
      noFilter: true,
      render: link =>
        link ? (
          <audio
            style={{ height: '30px' }}
            controls
            src={fullLink(link)}
          ></audio>
        ) : (
          'Record not found'
        ),
    },

    {
      title: 'Status',
      name: 'Status',
      dataIndex: 'status',
      sorter: false,

      defaultWidth: 150,
      noFilter: true,
      render: status => getCallsStatus(status),
    },
    {
      title: 'Assigned user',
      name: 'Assigned user',
      dataIndex: 'answered_user_id',
      sorter: false,
      defaultWidth: 150,
      noFilter: true,
      render: userId =>
        userId ? technicians?.find(t => t?.id === userId)?.name : '-',
    },
  ]

  return tableData?.length ? (
    <Wrapper>
      <Table
        pagination={false}
        size='small'
        columns={columns}
        dataSource={tableData}
      />
    </Wrapper>
  ) : (
    <Empty />
  )
}

const Wrapper = styled.div`
  overflow: scroll;
  & .ant-table-thead > tr > th {
    padding: 4px;
  }

  & .ant-table-thead > th,
  .ant-table-thead > td {
    text-align: center;
    min-width: 150px;
  }
  & td {
    min-width: 150px;
  }
`
