import React from 'react'
import { separateDateTimeByStr } from 'helpers/date/separateDateTimeByStr'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { Project } from 'features/Project/types'
import './style.scss'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  selectCompanySources,
  selectCompanyTypes,
} from 'store/Orcatec/selectors/company'

interface AppointmentDataInterface {
  data: Project
}

export const AppointmentData: React.FC<AppointmentDataInterface> = ({
  data,
}) => {
  const history = useHistory()
  const companyTypes = useAppSelector(selectCompanyTypes)
  const companySources = useAppSelector(selectCompanySources)

  const redirectToAppointment = () =>
    history.push(`/appointment/${data?.appointment?.id}`)

  return (
    <Wrapper>
      <Container>
        {data?.template_name && (
          <Row>
            <span className='proposal-form-helper-label'>
              Created from Project Template:
            </span>
            <span>{data?.template_name}</span>
          </Row>
        )}
        <Row>
          <span className='proposal-form-helper-label'>Created by:</span>
          <span>
            {data?.created_by?.full_name}{' '}
            {separateDateTimeByStr('at', data.created_at)}
          </span>
        </Row>
        {data?.appointment?.id && (
          <Row>
            <span className='proposal-form-helper-label'>Created from:</span>
            <span>
              <StyledLink onClick={redirectToAppointment}>
                Appointment #{data?.appointment?.id}
              </StyledLink>
            </span>
          </Row>
        )}
        <Row>
          <span className='proposal-form-helper-label'>Industry:</span>
          <span>{data?.industry_name || '--'}</span>
        </Row>
        <Row>
          <span className='proposal-form-helper-label'>Assigned to:</span>
          <span>{data?.user?.full_name || '--'}</span>
        </Row>
        <Row>
          <span className='proposal-form-helper-label'>Type:</span>
          <span>
            {companyTypes.find(type => type.id === data.type_id)?.name || '--'}
          </span>
          {/* <span>{data?.type?.name || '--'}</span> */}
        </Row>
        <Row>
          <span className='proposal-form-helper-label'>Source:</span>
          {companySources.find(source => source.id === data.source_id)?.name ||
            '--'}

          {/* <span>{data?.source?.name || '--'}</span> */}
        </Row>
      </Container>

      <Container>
        <Row>
          <span className='proposal-form-helper-label'>Issue Date:</span>
          <span>
            {data?.issue_date
              ? moment(data.issue_date).format('MM/DD/YYYY')
              : '--'}
          </span>
        </Row>
        {data?.date_of_sale && (
          <Row>
            <span className='proposal-form-helper-label'>Date of Sale:</span>
            <span>
              {data?.date_of_sale
                ? moment
                    .utc(data.date_of_sale)
                    .tz(moment.tz.guess())
                    .format('MM/DD/YYYY')
                : '--'}
            </span>
          </Row>
        )}
      </Container>
    </Wrapper>
  )
}

const StyledLink = styled.span`
  cursor: pointer;
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  transition: color 0.3s;
`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  justify-content: space-between;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  & .proposal-form__appointment-icon {
    cursor: pointer;
  }
`

const Container = styled.div``
