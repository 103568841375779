import { useEffect, useState } from 'react'
import { getContacts } from 'api/NotificationModal'
import { ContactI } from '../modals/NotificationModal/types'

interface Props {
  type: 'appointments' | 'proposals'
  entityId: number
}

export const useContacts = (
  type: Props['type'],
  entityId: Props['entityId'],
) => {
  const [contactList, setContactList] = useState<ContactI[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchContacts = async () => {
      setLoading(true)
      try {
        const res = await getContacts(entityId, type)
        setContactList(res.contacts)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (entityId && type) fetchContacts()
  }, [])

  return { contactList, loading }
}
