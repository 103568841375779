import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import useProjectSettings from './hooks/useProjectSettings'
import SettingsRow from 'components/UI/SettingsRow'
import { Button, Popover, Spin, Switch, Tooltip } from 'antd'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { selectProjectSettings } from 'features/Project/projectSelectors'
import { ProjectSettings } from 'features/Project/types'
import { updateProjectSettings } from 'features/Project/slices/projectSlice'
import { useState } from 'react'
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import {
  Footer,
  GlobalSettings,
  InfoPlate,
  Section,
  Settings,
  Title,
  Wrapper,
} from './ProjectSettingsModal.styles'
import PaymentProcessingFee from './components/PaymentProcessingFee/PaymentProcessingFee'
import DefaultPaymentDue from './components/DefaultPaymentDue/DefaultPaymentDue'
import ErrorBoundary from 'containers/MainContent/Orcatec/components/ErrorBoundary/ErrorBoundary'

interface Props {
  projectId: number
  onClose: () => void
}

export const ProjectSettingsModal = ({ onClose, projectId }: Props) => {
  const dispatch = useAppDispatch()
  const projectSettings = useAppSelector(selectProjectSettings)

  const settings = useProjectSettings()

  const [loading, setLoading] = useState(false)

  const handleSettingsChange = async (
    field: Partial<ProjectSettings>,
    hasPermissions: boolean,
  ) => {
    if (!hasPermissions) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    setLoading(true)

    await dispatch(updateProjectSettings({ projectId, field }))

    setLoading(false)
  }

  return (
    <Modal
      visible
      onCancel={onClose}
      footer={
        <Footer>
          <Button onClick={onClose} type='primary'>
            Done
          </Button>
        </Footer>
      }
      title='Project Settings'
      width={550}
      bodyStyle={{
        maxHeight: '85vh',
        overflow: 'auto',
      }}
    >
      <ErrorBoundary>
        <Spin spinning={loading}>
          <Wrapper>
            <GlobalSettings>
              <InfoPlate>
                <div>
                  {/* <Popover
                  title='Project general settings'
                  content='To set up default settings for all future projects within a specific industry, visit project general settings'
                > */}
                  <InfoCircleOutlined style={{ color: '#4688f1' }} />
                  {/* </Popover> */}
                  <p style={{ fontSize: 12, lineHeight: '1.33' }}>
                    To change default project settings visit &ldquo;General
                    Settings -&gt; Project -&gt; Default Project Settings&ldquo;
                  </p>
                </div>

                <Link
                  to='/settings/project'
                  target='_blank'
                  style={{ flex: '0 0 100px' }}
                >
                  Open settings
                </Link>
              </InfoPlate>
            </GlobalSettings>

            {settings.map(section => (
              <Section key={section.title}>
                <Title>{section.title}</Title>

                <Settings>
                  {section?.settings
                    ?.filter(item => !item?.hide)
                    ?.map(item => (
                      <SettingsRow
                        key={item.field_name}
                        description={item.description}
                        title={item.title}
                        width={250}
                        withInfoIcon
                        align='right'
                      >
                        <Tooltip
                          title={
                            item.field_name === 'require_signature' &&
                            item.disabled
                              ? 'This project has already been signed'
                              : item.field_name === 'hide_zero_price' &&
                                item.disabled
                              ? 'Can be changed if "Show price per item" enabled'
                              : item.disabled
                              ? 'Can be changed if project is in Estimate status or Edit Contract mode'
                              : ''
                          }
                        >
                          {item.field_name === 'payment_processing_fee' ? (
                            <PaymentProcessingFee
                              value={projectSettings.payment_processing_fee}
                              hasPermission={section.hasPermissions}
                              disabled={!!item.disabled}
                              onChange={field =>
                                handleSettingsChange(
                                  field,
                                  section.hasPermissions,
                                )
                              }
                            />
                          ) : item.field_name === 'default_payment_due_date' ? (
                            <DefaultPaymentDue
                              value={projectSettings.default_payment_due_date}
                              onChange={field =>
                                handleSettingsChange(
                                  field,
                                  section.hasPermissions,
                                )
                              }
                              hasPermission={section.hasPermissions}
                              disabled={!!item.disabled}
                            />
                          ) : (
                            <div style={{ textAlign: 'right' }}>
                              {item.helperText && (
                                <Popover
                                  title='You have no permissions'
                                  content={item.helperText}
                                >
                                  <WarningOutlined
                                    style={{
                                      color: '#faad14',
                                      fontSize: 20,
                                      marginRight: 10,
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Popover>
                              )}
                              <Switch
                                checked={!!projectSettings[item.field_name]}
                                disabled={item.disabled}
                                onChange={checked =>
                                  handleSettingsChange(
                                    { [item.field_name]: checked },
                                    section.hasPermissions,
                                  )
                                }
                              />
                            </div>
                          )}
                        </Tooltip>
                      </SettingsRow>
                    ))}
                </Settings>
              </Section>
            ))}
          </Wrapper>
        </Spin>
      </ErrorBoundary>
    </Modal>
  )
}
