import TabSectionTab from './TabSectionTab/TabSectionTab'
import TabSectionContent from './TabSectionContent/TabSectionContent'
import './TabSection.scss'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  selectProjectSections,
  selectProjectSettings,
  selectSectionRebatesTotal,
  selectSectionSubtotal,
  selectSectionTotalAfterDiscounts,
} from 'features/Project/projectSelectors'

const TabSection = ({
  freezeAll,
  section,
  onDeleteSection,
  onCopySection,
  applySectionNewTitle,
  toggleNewSectionMode,
  toggleSectionContent,
  onChangeSectionDescription,
  onChangeSectionInstallation,
  onChangeGrossMargin,
  onBlurSectionInstallation,
  sectionIdx,
  dragHandleProps,
  isDraggable,
  isJustOneSection,
  isMyProposal,
  isEditContract,
  toggleSaveTemplateSectionModal,
  toggleLoadTemplateSectionModal,
  onSubmit,
  // hide_section_items,
  getFileForModalDocViewer,
  showPrice,
}) => {
  const { description, installation, id } = section

  const sectionSubtotal = useAppSelector(selectSectionSubtotal(id)) || 0
  const sectionTotalAfterDiscounts =
    useAppSelector(selectSectionTotalAfterDiscounts(id)) || 0
  const rebatesTotal = useAppSelector(selectSectionRebatesTotal(id, null))
  const projectSettings = useAppSelector(selectProjectSettings)
  const projectSections = useAppSelector(selectProjectSections)

  const hideSectionHeading =
    !projectSettings?.show_sections && projectSections?.length === 1

  const sectionTotalAfter = sectionTotalAfterDiscounts - rebatesTotal

  return (
    <div className='proposal-form-tabs_content-tab_section-wrapper'>
      {!hideSectionHeading && (
        <TabSectionTab
          title={section.title}
          onDeleteSection={onDeleteSection}
          onCopySection={onCopySection}
          onAddSection={toggleNewSectionMode}
          applySectionNewTitle={applySectionNewTitle}
          showSectionContent={section.show}
          toggleSectionContent={toggleSectionContent}
          sectionSubtotal={sectionSubtotal}
          sectionTotalAfter={sectionTotalAfter}
          dragHandleProps={dragHandleProps}
          isDraggable={isDraggable}
          freezeAll={freezeAll}
          isJustOneSection={isJustOneSection}
          isMyProposal={isMyProposal}
          isNewSection={section.isNew}
          toggleSaveTemplateSectionModal={toggleSaveTemplateSectionModal}
          toggleLoadTemplateSectionModal={toggleLoadTemplateSectionModal}
          section_id={id}
          onSubmit={onSubmit}
          sectionIdx={sectionIdx}
          getFileForModalDocViewer={getFileForModalDocViewer}
        />
      )}
      {(section.show || hideSectionHeading) && (
        <TabSectionContent
          description={description}
          installation={installation}
          sectionSubtotal={sectionSubtotal}
          sectionTotalAfter={sectionTotalAfter}
          onChangeSectionDescription={onChangeSectionDescription}
          onChangeSectionInstallation={onChangeSectionInstallation}
          onChangeGrossMargin={onChangeGrossMargin}
          onBlurSectionInstallation={onBlurSectionInstallation}
          sectionIdx={sectionIdx}
          freezeAll={freezeAll}
          isEditContract={isEditContract}
          section_id={id}
          getFileForModalDocViewer={getFileForModalDocViewer}
          showPrice={showPrice}
        />
      )}
    </div>
  )
}

export default TabSection
