import {
  selectProjectFees,
  selectProjectTaxAndFeesSlice,
} from '../projectSelectors'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import { Fee } from './Fee'
import { Button } from 'antd'
import { useState } from 'react'
import { ProjectFee } from '../types'
import {
  createProjectFee,
  deleteProjectFee,
  updateProjectFee,
} from '../slices/projectTaxAndFeesSlice'
import { Error } from 'types/Error'

const INITIAL_FEE = {
  description: 'Fee',
  price: 0,
}

interface Props {
  disabled: boolean
  tabId: number
}

export const Fees = ({ disabled, tabId }: Props) => {
  const dispatch = useAppDispatch()
  const fees = useAppSelector(selectProjectFees)
  const { status } = useAppSelector(selectProjectTaxAndFeesSlice)

  const [editingFee, setEditingFee] = useState<ProjectFee | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const onFeeAdd = () => {
    setEditingFee(INITIAL_FEE as ProjectFee)
  }

  const handleFeeEdit = (fee: ProjectFee) => setEditingFee(fee)

  const handleFeeSave = async (fee: ProjectFee) => {
    if (!fee.description)
      return setError({ description: 'Description is required' })

    const res = fee.id
      ? await dispatch(updateProjectFee({ ...fee, price: fee.price || 0 }))
      : await dispatch(
          createProjectFee({ ...fee, tab_id: tabId, price: fee.price || 0 }),
        )

    if (res.meta.requestStatus === 'rejected') return

    setEditingFee(null)
  }

  const handleFeeDelete = async (fee: ProjectFee) => {
    dispatch(deleteProjectFee(fee))
  }

  const handleFeeChange = (e: {
    target: { name: keyof ProjectFee; value: unknown }
  }) => {
    const { name, value } = e.target

    setEditingFee(prev => ({
      ...prev,
      [name]: value,
    }))

    if (error?.[name]) setError(null)
  }

  const handleCancel = () => {
    setEditingFee(null)
  }

  const isEditing = (fee: ProjectFee) => fee?.id === editingFee?.id

  if (!fees?.length && disabled) return null

  return (
    <Wrapper>
      {fees.length || !!editingFee ? (
        <ProjectFeeTable>
          <h6>Project Fees:</h6>
          <Heading>
            <p>Description</p>
            <p>Amount</p>
            <p></p>
          </Heading>

          <FeeList>
            {fees.map(fee => (
              <Fee
                key={fee.id}
                data={isEditing(fee) && editingFee ? editingFee : fee}
                disabled={disabled || !!editingFee || status === 'loading'}
                editMode={isEditing(fee)}
                onChange={handleFeeChange}
                onSave={handleFeeSave}
                onCancel={handleCancel}
                onDelete={handleFeeDelete}
                onEdit={handleFeeEdit}
                error={error}
                isLoading={status === 'loading'}
              />
            ))}
          </FeeList>

          {editingFee && !editingFee?.id && (
            <Fee
              data={editingFee}
              editMode
              onChange={handleFeeChange}
              onSave={handleFeeSave}
              onCancel={handleCancel}
              onDelete={handleFeeDelete}
              error={error}
              isLoading={status === 'loading'}
            />
          )}

          {!disabled && (
            <Button
              onClick={onFeeAdd}
              style={{ marginTop: 5 }}
              disabled={!!editingFee}
            >
              + Add Fee
            </Button>
          )}
        </ProjectFeeTable>
      ) : (
        <Button
          onClick={onFeeAdd}
          style={{ display: 'flex', marginLeft: 'auto' }}
          disabled={!!editingFee}
        >
          + Add Fee
        </Button>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 400px;
  margin-left: auto;
  margin-top: 10px;
`
const ProjectFeeTable = styled.div`
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
`
const Heading = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px 55px;
  gap: 5px;
  background-color: #fafafa;
  color: gray;

  & > p {
    padding-left: 8px;
  }
`

const FeeList = styled.div`
  margin-bottom: 5px;
`
