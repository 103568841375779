import * as types from '../../types'
import * as proposal from '../../../../api/Proposal'

export const openModal = () => {
  return {
    type: types.modalEquipment.OPEN_MODAL_EQUIPMENT,
  }
}

export const getOrdersHistory = tabId => dispatch => {
  return proposal.fetchOrdersHistory(tabId)
}
