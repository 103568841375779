const LeadIcon = () => (
  <svg
    version='1.0'
    xmlns='http://www.w3.org/2000/svg'
    width='300.000000pt'
    height='300.000000pt'
    viewBox='0 0 300.000000 300.000000'
    preserveAspectRatio='xMidYMid meet'
  >
    <g transform='translate(0.000000,300.000000) scale(0.100000,-0.100000)' fill='#0098d8' stroke='none'>
      <path
        d='M1420 2634 c-14 -2 -59 -9 -100 -15 -41 -6 -114 -24 -161 -39 -400
-131 -695 -462 -785 -880 -25 -117 -25 -343 0 -460 141 -658 789 -1065 1434
-900 200 51 361 141 516 290 238 228 359 511 359 840 0 148 -20 263 -68 398
-62 172 -154 311 -296 447 -164 157 -349 254 -571 299 -77 16 -281 28 -328 20zz
m191 -369 c37 -20 69 -70 69 -110 0 -68 -65 -135 -130 -135 -64 0 -130 67
-130 133 0 93 106 155 191 112z m-355 -135 c45 -29 71 -95 55 -143 -23 -69
-91 -109 -158 -93 -14 4 -41 22 -60 41 -29 29 -33 40 -33 83 0 40 6 57 27 82
45 54 111 65 169 30z m661 -30 c71 -43 84 -144 25 -203 -62 -61 -175 -41 -208
38 -19 46 -18 73 4 114 35 65 118 89 179 51z m-460 -53 c37 -61 142 -61 189 1
6 8 19 12 30 10 16 -3 19 -12 19 -55 0 -29 6 -58 13 -64 19 -20 14 -39 -9 -39
-54 0 -89 -53 -89 -135 l0 -46 -92 3 -93 3 -5 81 c-5 86 -15 105 -61 130 -25
12 -27 17 -19 45 11 37 27 58 55 69 30 13 53 12 62 -3z m-350 -147 c48 -43
108 -42 157 1 33 29 38 30 71 19 53 -18 65 -50 65 -178 0 -152 7 -147 -209
-147 -226 0 -221 -4 -221 149 0 101 2 116 21 140 20 25 50 44 73 45 4 1 24
-12 43 -29z m668 -34 c46 -43 125 -39 164 7 17 21 60 22 87 2 36 -24 45 -64
42 -175 l-3 -105 -40 -20 c-34 -17 -61 -20 -170 -20 -138 0 -198 13 -215 45
-11 21 -14 160 -4 209 9 41 50 81 85 81 16 0 39 -10 54 -24z m385 -127 c69
-26 87 -42 102 -90 22 -76 7 -95 -332 -434 l-310 -310 0 -72 c0 -89 -14 -103
-105 -103 -91 0 -105 14 -105 103 l0 71 -314 316 c-340 341 -345 347 -324 429
12 49 29 63 92 85 68 22 76 21 76 -12 0 -19 -10 -38 -30 -55 -38 -34 -38 -59
0 -91 77 -64 314 -106 605 -106 291 0 528 42 605 106 38 32 38 55 0 87 -23 20
-30 34 -30 61 0 20 3 36 8 36 4 0 32 -9 62 -21z'
      />
    </g>
  </svg>
)

export default LeadIcon
