import moment from 'moment'
import * as types from '../types'

export default (
  state = {
    list: [],
    currentTimeType: {},
    currentUserTimeCard: {},
    timeCardsRange: [],
    loading: false,
    pagination: { page: 1, limit: 25 },
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case types.timecards.TIMETYPE_SET:
      return { ...state, currentTimeType: action.response }
    case types.timecards.TIMECARDS_RANGE_SET:
      return { ...state, timeCardsRange: action.response }
    case types.timecards.CHANGE_LIMIT:
      return {
        ...state,
        pagination: { ...state.pagination, limit: +action.response, page: 1 },
      }
    case types.timecards.CHANGE_PAGE:
      return {
        ...state,
        pagination: { ...state.pagination, page: +action.response },
      }

    /////
    case types.timecards.GET_TIMECARD_LIST:
      return { ...state, loading: true, error: null }
    case types.timecards.SET_TIMECARD_LIST: {
      const pagination = {
        total: action.payload.meta.total,
        limit: action.payload.meta.per_page || 10,
        page: action.payload.meta.current_page,
      }
      return { ...state, list: action.payload.list, loading: false, pagination }
    }
    case types.timecards.GET_TIMECARD_LIST_FAILURE:
      return { ...state, loading: false }

    case types.timecards.GET_TIMECARD:
      return { ...state, loading: true }
    case types.timecards.SET_TIMECARD:
      return {
        ...state,
        currentUserTimeCard: action.payload,
        loading: false,
        error: null,
      }
    case types.timecards.TIMECARD_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case types.timecards.ADD_TIMECARD_DAY:
      return {
        ...state,
        error: null,
        currentUserTimeCard: {
          ...state.currentUserTimeCard,
          data: {
            ...state.currentUserTimeCard.data,
            [moment(action.payload).format('YY-MM-DD')]: {
              data: [],
              total_time_worked: '00:00:00',
              total_brake_time: '00:00:00',
              date: moment(action.payload).format('YYYY-MM-DD'),
            },
          },
        },
      }

    case types.timecards.DELETE_TIMECARD_DAY: {
      const newcurrentUserTimeCard = state.currentUserTimeCard
      delete newcurrentUserTimeCard?.data?.[action.payload]

      return {
        ...state,
        error: null,
        currentUserTimeCard: newcurrentUserTimeCard,
      }
    }

    case types.timecards.ADD_TIMECARD_TRACK:
    case types.timecards.EDIT_TIMECARD_TRACK:
      return { ...state, error: null }

    case types.timecards.RESET_TIMECARDS_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

//Selectors
export const timecardsSelector = state => state.orcatec.timecards
