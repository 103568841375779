import * as types from '../../types'

const initialState = {
  show: false,
}

export default (state = initialState, action) => {
  let newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case types.importItems.IMPORT_ITEMS_TOGGLE:
      return {
        show: !newState.show,
      }

    default:
      return state
  }
}
