import { downloadFile as fetchFileUrl } from 'api/Media'

const _fileLoader = ({
  documentURI,
  signal,
  fileLoaderComplete,
  readerTypeFunction,
  setLoadingHeandle,
}) => {
  setLoadingHeandle(true)
  return fetchFileUrl(documentURI, signal)
    .then(async res => {
      const blob = res
      const fileReader = new FileReader()
      fileReader.addEventListener('loadend', () =>
        fileLoaderComplete(fileReader),
      )
      switch (readerTypeFunction) {
        case 'arrayBuffer':
          fileReader.readAsArrayBuffer(blob)
          break
        case 'binaryString':
          fileReader.readAsBinaryString(blob)
          break
        case 'dataURL':
          fileReader.readAsDataURL(blob)
          break
        case 'text':
          fileReader.readAsText(blob)
          break

        default:
          break
      }
    })
    .catch(e => {
      return console.error(e)
    })
    .finally(() => setLoadingHeandle(false))
}

export const arrayBufferFileLoader = props => {
  return _fileLoader({ ...props, readerTypeFunction: 'arrayBuffer' })
}

export const dataURLFileLoader = props => {
  return _fileLoader({ ...props, readerTypeFunction: 'dataURL' })
}

export const textFileLoader = props => {
  return _fileLoader({ ...props, readerTypeFunction: 'text' })
}

export const binaryStringFileLoader = props => {
  return _fileLoader({ ...props, readerTypeFunction: 'binaryString' })
}

export const defaultFileLoader = dataURLFileLoader
