import {
  Autocomplete as AutocompleteMaterial,
  // createFilterOptions,
} from '@material-ui/lab'

import InputField from '../Input'
import { SmallPreloader } from '../Preloader/SmallPreloader'

// const filter = createFilterOptions()

/* const filterOptions = (options, params) => {
  const filtered = filter(options, params)

  // Suggest the creation of a new value
  if (params.inputValue !== '') {
    filtered.push({
      inputValue: params.inputValue,
      name: `Add "${params.inputValue}"`,
    })
  }

  return filtered.filter(
    option => option.id !== null && !option.deleted_at && option.name !== '-',
  )
} */

const getOptionLabel = option => {
  // Value selected with enter, right from the input
  if (typeof option === 'string') {
    return option
  }
  // Add "xxx" option created dynamically
  if (option.inputValue) {
    return option.inputValue
  }
  // Regular option
  return option.name ? option.name : ''
}

const defaultRenderOption = option => option.name

const Autocomplete = ({
  options,
  value,
  name,
  onChange,
  label,
  error,
  loading,
  renderOption,
  filterOptions = filterOptions,
  required = false,
  ...rest
}) => {
  const mapValue = () => {
    if (/* value &&  */ options.length) {
      return { id: value, name: options?.find(o => o?.id === value)?.name }
    }
    return value
  }

  return (
    <AutocompleteMaterial
      value={mapValue()}
      onChange={onChange}
      filterOptions={filterOptions}
      selectOnFocus
      // clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption ?? defaultRenderOption}
      freeSolo
      {...rest}
      renderInput={params => (
        <InputField
          {...params}
          label={label}
          variant='outlined'
          size='small'
          name={name}
          error={error}
          required={required}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: loading ? <SmallPreloader /> : null,
            style: { fontSize: 14, fontWeight: 400 },
          }}
        />
      )}
    />
  )
}

export default Autocomplete
