import {
  selectProject,
  selectProjectRebatesSlice,
  selectSectionRebates,
} from 'features/Project/projectSelectors'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import OptionGroup from '../OptionGroup'
import { ProjectRebate, ProjectStatusGroup } from 'features/Project/types'
import { Rebate } from './Rabate'
import { updateProjectRebate } from 'features/Project/slices/projectRebatesSlice'

interface Props {
  sectionId: number
  pricePermission: boolean
}

export const SectionRebates = ({ sectionId, pricePermission }: Props) => {
  const dispatch = useAppDispatch()

  const rebates = useAppSelector(selectSectionRebates(sectionId, null))
  const { status } = useAppSelector(selectProjectRebatesSlice)
  const project = useAppSelector(selectProject)

  const handleItemChange = (rebate: ProjectRebate) =>
    dispatch(updateProjectRebate(rebate))

  const filteredRebates = rebates.filter(rebate =>
    rebate.option_group_id ? rebate.checked_option : rebate,
  )

  if (!rebates.length) return null

  return (
    <Wrapper>
      <Title>Rebates</Title>
      <List>
        {filteredRebates.map(rebate =>
          rebate.checked_option &&
          rebate.option_group_id &&
          project.status === ProjectStatusGroup.Estimate ? (
            <OptionGroup key={rebate.id}>
              {rebates
                .filter(
                  option => option.option_group_id === rebate.option_group_id,
                )
                .toSorted((a, b) => a.order_option - b.order_option)
                .map(option => (
                  <Rebate
                    key={rebate.id}
                    data={option}
                    disabled={status === 'loading'}
                    onChange={handleItemChange}
                    checkedItem={rebates.find(
                      option =>
                        option.option_group_id === rebate.option_group_id &&
                        option.checked_option,
                    )}
                    pricePermission={pricePermission}
                  />
                ))}
            </OptionGroup>
          ) : (
            <Rebate
              key={rebate.id}
              data={rebate}
              disabled={status === 'loading'}
              onChange={handleItemChange}
              pricePermission={pricePermission}
            />
          ),
        )}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const List = styled.div``
const Title = styled.h6`
  font-size: 16px;
  font-weight: bold;
`
