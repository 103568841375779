import { useState, useEffect } from 'react'
import moment from 'moment-timezone'

export const useTimeCollision = (timelines = []) => {
  const [timeCollision, setTimeCollision] = useState(false)

  useEffect(() => {
    const isTimeCollision = list => {
      const arr = list.filter(el => !el.deleted)
      const res = []

      for (let i = 0; i < arr.length; i++) {
        if (i !== arr.length - 1 && arr[i].time_id) {
          const from = arr[i].clock_out
          const to = arr[i + 1].clock_in
          const diff = moment(to, 'YYYY-MM-DD h:mm A').diff(moment(from, 'YYYY-MM-DD h:mm A'))
          res.push(diff)
        }
      }

      if (res.some(diff => diff < 0)) return true
      return false
    }

    Array.isArray(timelines) && setTimeCollision(isTimeCollision(timelines))
  }, [timelines])

  return timeCollision
}
