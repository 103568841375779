import React, { useState, useEffect, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import * as actions from '../../../../../../store/Orcatec/actions'
import CustomAddressAutocompleteContainer from '../../../components/CustomAddressAutocomplete/CustomAddressAutocompleteContainer'
import { Row, Col, Button } from 'antd'
// import Tags from './Tags/Tags'
import Tags from '../../../components/Tags/Tags'
import './PropertyForm.scss'

import MainButton from '../../../components/buttons/MainButton'
import FullAddressSuggestionModal from './components/Modals/FullAddressSuggestionModal'
import { getAddressWithUnit } from 'helpers/getAddressWithUnit'
import MergePropertiesModal from '../../../components/MergeModal'
import { addTag } from '../../../../../../helpers/tags/addTag'
import { ManualAddressContainer } from '../../../components/ManualAddressComponent/ManualAddressContainer'
import Preloader from '../../../components/Preloader'
import MergeTempNotesAndTempTagsModal from '../../../CreateAppointment/components/PropertySection/components/MergeTempNotesAndTempTagsModal/MergeTempNotesAndTempTagsModal'
import Map from '../PropertyForm/components/Map/Map'
import { withRouter } from 'react-router-dom'
import isMobile from '../../../Mobile/helpers/isMobile'
import states from '../../../../../../helpers/states'
import SureModal from '../../../components/SureModal'
import { openNotificationWithIcon } from '../../../../../../helpers/notifications/openNotificationWithIcon'

const initialFieldValues = {
  id: null,
  ////////////address to server
  address: '', //required
  postcode: '', //required
  state: '', //required
  city: '', //required
  lat: null, //required
  lng: null, //required
  type: '', // 1-Commercial, 2-Residential //required
  unit: '', //not required
  ////////////////////////////////
  //prevents one more search after address was choosen in dropdawn
  isChooseAddress: false,
  localAddressSuggestions: [],
  filteredLocalAddressSuggestions: [],
  viewMode: false,
  isEnotherAddressNeeded: false,

  notes: '',
  tempNotes: '', //for add to note if address was choosen from DB
  tags: [],
  tempTags: [], //for add to tags if address was choosen from DB
  mergeTempNotesAndTempTagsModal: false,
  apt: '',

  /////////////////////////////just for tables in tabs
  clients: [],
  new_client_properties: [], // new objects
  deleted_client_properties: [], //id's

  media: [],
  appointments: [],
  proposals: [],
  service_contracts: [],
  /////////////////service values ->
  //: '',
  secondAddressPart: '',
  //tempTagValue: '',
  manualAddressForm: false,
}

const initialTabs = {
  clients: { show: false },
  appointments: { show: false },
  media: { show: false },
  proposals: { show: false },
  service_contracts: { show: false },
}

const PropertyForm = ({
  isClear,
  propertyId,
  ownerId,
  closeForm,
  pagination = {},
  isServiceContract,
  disableEvery = false,
  history,
  update,
  ////from proposal or serv.contr
  updatePropertyInItem,
  ///just from client form
  onChangeRelation,
  isAppointment = false,
  isClientForm = false,
  relationValue = '',
  //////////from connect
  fetchProperty,
  setCurrentProperty,
  mergeProperties,
  addProperty,
  updateProperty,
  createRelationProperty,
  dellAttachedProperty,
  media,
  onChangeField,
  onAddAttachedProperty,
  isProposalProperties,
  deleteProperty,
  cancelBtnRef = () => {},
  saveBtnRef = () => {},
  deleteBtnRef = () => {},
  setShowButtonsInParent,
  redirectOnSave = '',
  // temporary_notes,
  attachPropertyToProposal,
  proposalButtons,
  proposalUpdateProperty,
  disableEdit,
}) => {
  const [, setIsEdit] = useState(false)
  const isInitialMount = useRef(true)
  const [fieldValues, setFieldValues] = useState(initialFieldValues)
  // eslint-disable-next-line no-unused-vars
  const [, setAddressError] = useState('')
  const [tempTagValue, setTempTagValue] = useState('')
  const [tempTagValueError, setTempTagValueError] = useState('')
  const [tabs, setTabs] = useState(initialTabs)
  const [errors, setErrors] = useState({
    address: false,
    postcode: false,
    type: false,
  })
  // eslint-disable-next-line no-unused-vars
  const [, setIsAddButtonClicked] = useState({
    clients: false,
    media: false,
    proposals: false,
    service_contracts: false,
  })
  // eslint-disable-next-line no-unused-vars
  const [, setShowSuggestionsTableOnChange] = useState(false)
  const [modalFullSuggestion, setModalFullSuggestion] = useState(false)
  const [showButtons, setShowButtons] = useState(false)
  const [showMergePropertiesModal, setShowMergePropertiesModal] = useState(false)
  const [checkedRows, setCheckedRows] = useState([])
  const [addressValue, setAddressValue] = useState('')
  const [isPropertyFetching, setIsPropertyFetching] = useState(false)
  const [tableLoader, setTableLoader] = useState(false)
  const [prevPropertyForMerge, setPrevPropertyForMerge] = useState({
    id: null,
    display_info: '',
  })
  const [, setDebouncedValue] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    if (setShowButtonsInParent && showButtons) setShowButtonsInParent(true)
  }, [showButtons])

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal)
  }

  const handleDeleteProperty = () => {
    deleteProperty(propertyId)
      .then(() => {
        openNotificationWithIcon('success', {
          message: 'Property has been deleted successfully',
          description: '',
          key: 'property-delete-success',
        })
        history.push('/properties')
      })
      .catch(error => {
        if (!!error.response && error.response.status === 403 && error.response.data.error.code === 10037) {
          toggleDeleteModal()
          openNotificationWithIcon('error', {
            message: error.response.data.error.message,
            description: '',
            key: 'property-delete-error',
          })
        }
      })
  }

  useEffect(() => {
    if (!tableLoader) setTableLoader(true)

    const timeoutRef = setTimeout(() => {
      setDebouncedValue(addressValue)
    }, 250)

    return () => clearTimeout(timeoutRef)
  }, [addressValue])
  /* useEffect(() => {
    if (debouncedValue) {
      searchLocalAddress({
        address: debouncedValue,
        postcode: '',
        unit: '',
      }).then(data => {
        setTableLoader(false)
        setFieldValues(prev => ({
          ...prev,
          localAddressSuggestions: data,
          filteredLocalAddressSuggestions: data,
        }))
      })
    }
  }, [debouncedValue]) */

  let firstRender = useRef(true)
  const resetLocalState = () => {
    setTabs({
      clients: { show: isServiceContract ? tabs.clients.show : false },
      appointments: { show: false },
      media: { show: false },
      proposals: { show: false },
      service_contracts: { show: false },
    })
    setFieldValues(initialFieldValues)
    setShowButtons(false)
    setIsAddButtonClicked({
      clients: false,
      media: false,
      proposals: false,
      service_contracts: false,
    })
    setAddressError('')
    //if (isCloseFormNeeded !== 'noCloseForm') closeForm()
    setCurrentProperty({})
    setShowSuggestionsTableOnChange(false)
    setModalFullSuggestion(false)
    setErrors({
      address: false,
      type: false,
      postcode: false,
    })
    setTempTagValue('')
    //setTempNotesValue('')
    //setModalFullSuggestion2(false)
  }
  useEffect(() => {
    resetLocalState()
  }, [isClear])

  const onHandleCancel = () => {
    if (isClientForm) return closeForm()

    setFieldValues(prev => ({
      ...prev,
      viewMode: true,
    }))
    getProperty(fieldValues.id)
    setShowButtons(false)
  }

  /* useEffect(() => {
    if (propertyId && onChangeRelation)
    resetLocalState()
  }, []) */

  const getProperty = id => {
    setIsPropertyFetching(true)
    fetchProperty(id, updatePropertyInItem)
      .then(resp => {
        //const addressParts = currentProperty.address.split(`\n`)
        setAddressError('')
        const data = {
          ...resp,
          postcode: resp.postcode ? `${resp.postcode}` : '',
          unit: resp.unit ? resp.unit : '',
          notes: resp.notes ? resp.notes : '',
          tempNotes: resp.notes ? resp.notes : '',
          //firstAddressPart: addressParts[0],
          //secondAddressPart: addressParts[1],
          localAddressSuggestions: [],
          filteredLocalAddressSuggestions: [],
          new_client_properties: [], // new objects
          deleted_client_properties: [], //id's
          isChooseAddress: true,
        }
        setFieldValues(prev => ({
          ...prev,
          ...data,
          viewMode: true,
        }))
        setPrevPropertyForMerge({
          id: data.id,
          display_info: data.full_address ? data.full_address : `${getAddressWithUnit(data)}, ${data.postcode}`,
        })
        setIsPropertyFetching(false)
      })
      .catch(err => {
        if (
          typeof err === 'object' &&
          (err.status === 404 || (err.response || {}).status === 404) &&
          history.location.pathname.startsWith('/properties-form')
        ) {
          history.replace('/404/property')
        }
        setIsPropertyFetching(false)
      })
  }

  useEffect(() => {
    if (propertyId) {
      getProperty(propertyId)
    }
    // return () => resetLocalState()
  }, [propertyId])

  useEffect(() => {
    if (!firstRender.current) {
      handleSubmit()
    }
  }, [update])

  useEffect(() => {
    if (fieldValues.viewMode && !firstRender.current) {
      setShowButtons(true)
    }
    if (fieldValues.id && fieldValues.viewMode && firstRender.current) {
      firstRender.current = false
    }
  }, [fieldValues])

  const getFullAddressSuggestion = () => {
    if (fieldValues.filteredLocalAddressSuggestions.length) {
      if (fieldValues.filteredLocalAddressSuggestions.length === 1) {
        if (
          (fieldValues.filteredLocalAddressSuggestions[0].unit
            ? fieldValues.filteredLocalAddressSuggestions[0].unit
            : '') === fieldValues.unit
        ) {
          return fieldValues.filteredLocalAddressSuggestions[0]
        }
      } else {
        const addresses = fieldValues.filteredLocalAddressSuggestions.filter(
          item => fieldValues.unit === (item.unit ? item.unit : ''),
        )
        return addresses.length ? addresses[0] : null
      }
    }

    return null
  }

  const onChange = e => {
    //if (!e.trigger) e.preventDefault()
    let { name, value } = e.target
    if (name === 'postcode') {
      if ((/^\d+$/.test(value) || !value) && value.length <= 5) {
        setErrors(prev => ({ ...prev, postcode: false }))
        setFieldValues(prev => ({
          ...prev,
          [name]: value,
          /* address: '',
          state: '',
          city: '',
          lat: null,
          lng: null, */
          isChooseAddress: false,
          localAddressSuggestions: [],
          filteredLocalAddressSuggestions: [],
          isEnotherAddressNeeded: false,
        }))
      } else {
        return
      }
    } else if (name === 'state') {
      setFieldValues(prev => ({
        ...prev,
        [name]: value,
        address: '',
        city: '',
        lat: null,
        lng: null,
        isChooseAddress: false,
        localAddressSuggestions: [],
        filteredLocalAddressSuggestions: [],
        isEnotherAddressNeeded: false,
      }))
    } else if (name === 'city') {
      setFieldValues(prev => ({
        ...prev,
        [name]: value,
        address: '',
        state: '',
        lat: null,
        lng: null,
        isChooseAddress: false,
        localAddressSuggestions: [],
        filteredLocalAddressSuggestions: [],
        isEnotherAddressNeeded: false,
      }))
    } else if (name === 'unit') {
      value = value.toUpperCase()
      setFieldValues(prev => ({
        ...prev,
        [name]: value,
        isEnotherAddressNeeded: false,
        filteredLocalAddressSuggestions: prev.localAddressSuggestions.filter(item =>
          item.unit ? item.unit.startsWith(value) : value ? !!item.unit : !item.unit,
        ),
      }))
    } else if (name === 'tempTagValue') {
      if (fieldValues.tags.length >= 20) {
        setTempTagValueError(value ? 'Maximum 20 tags' : '')
      }
      setTempTagValue(value)
    } else {
      if (name === 'type') {
        setErrors(prev => ({ ...prev, type: false }))
      }
      setFieldValues({
        ...fieldValues,
        [name]: value,
        tempNotes: name === 'notes' && !fieldValues.id ? value : fieldValues.notes,
      })
    }
  }
  const handleSubmit = () => {
    const data = {
      full_address: `${getAddressWithUnit(fieldValues)}, ${fieldValues.postcode}`,
      address: fieldValues.address,
      postcode: fieldValues.postcode,
      city: fieldValues.city,
      state: fieldValues.state,
      unit: fieldValues.unit,
      lat: fieldValues.lat,
      lng: fieldValues.lng,
      owner_user_id: ownerId,
      notes: fieldValues.notes,
      tags: fieldValues.tags,
      type: fieldValues.type,
      media: media ? media : fieldValues.media,
      is_manual: fieldValues.is_manual,
    }
    setAddressError('')
    let isValid = true
    if (!data.address || !data.type || !data.postcode) {
      isValid = false
      let tempErrors = { ...errors }

      if (!data.address && !data.type && !data.postcode) {
        tempErrors = { type: true, address: 'This field is required', postcode: false }
      } else {
        if (!data.address) tempErrors.address = 'This field is required'
        if (!data.type) tempErrors.type = true
        if (!data.postcode && data.address) tempErrors.address = 'You need to choose address from dropdown'
      }
      setErrors(tempErrors)
    }
    if (isValid) {
      if (fieldValues.id) {
        const fullSuggestionProperty = getFullAddressSuggestion()
        if (fullSuggestionProperty) {
          setModalFullSuggestion(true)
          setCheckedRows([
            prevPropertyForMerge,
            {
              id: fullSuggestionProperty.id,
              display_info: fullSuggestionProperty.full_address
                ? fullSuggestionProperty.full_address
                : `${getAddressWithUnit(fullSuggestionProperty)}, ${fullSuggestionProperty.postcode}`,
            },
          ])
        } else {
          setIsPropertyFetching(true)
          updateProperty(fieldValues.id, data)
            .then(() => {
              const promises = []
              fieldValues.deleted_client_properties?.forEach(item => {
                promises.push(dellAttachedProperty(item))
              })
              fieldValues.new_client_properties?.forEach(item => {
                promises.push(createRelationProperty({ ...item, property_id: fieldValues.id }))
              })
              if (isAppointment) {
                closeForm({
                  property_id: fieldValues.id,
                })
                setFieldValues(prev => ({
                  ...prev,
                  viewMode: true,
                }))
              }
              return Promise.all(promises)
            })
            .then(() => {
              openNotificationWithIcon('success', {
                message: 'Property has been updated successfully',
                description: '',
                key: 'property-update-success',
              })
              if (redirectOnSave) history.push(redirectOnSave)
              setFieldValues(prev => ({
                ...prev,
                viewMode: true,
                isEnotherAddressNeeded: false,
                localAddressSuggestions: [],
                filteredLocalAddressSuggestions: [],
              }))
              setShowButtons(false)
            })
            .then(() => {
              if (isProposalProperties) {
                proposalUpdateProperty()
              }
            })
            .catch(() => updatePropertyInItem && getProperty(fieldValues.id, updatePropertyInItem))
            .finally(() => {
              setIsPropertyFetching(false)
            })
        }
      } else {
        if (getFullAddressSuggestion()) {
          setModalFullSuggestion(true)
        } else {
          setIsPropertyFetching(true)
          addProperty(data)
            .then(response => {
              const promises = []
              fieldValues.new_client_properties?.forEach(item => {
                promises.push(createRelationProperty({ ...item, property_id: response.id }))
              })
              if (isAppointment) {
                closeForm({ property_id: response.id })
              }
              if (isClientForm) {
                onAddAttachedProperty(response)
                closeForm()
              }
              return Promise.all(promises).then(() => response)
            })
            .then(response => {
              openNotificationWithIcon('success', {
                message: 'Property has been created successfully',
                description: '',
                key: 'property-create-success',
              })
              if (history.location.pathname.startsWith('/properties-form')) {
                return history.push(`/properties-form/${response.id}`)
              }
              getProperty(response.id)
              setFieldValues(prev => ({
                ...prev,
                id: response.id,
                viewMode: true,
                isEnotherAddressNeeded: false,
                localAddressSuggestions: [],
                filteredLocalAddressSuggestions: [],
              }))
              setShowButtons(false)
              return response
            })
            .then(res => {
              if (isProposalProperties) {
                attachPropertyToProposal(res.id)
              }
            })
            .finally(() => {
              setIsPropertyFetching(false)
            })
        }
      }
      setErrors(() => ({ type: false, address: false, postcode: false }))
    }
  }

  useEffect(() => {
    if (isServiceContract && !isInitialMount.current && fieldValues.id) {
      handleSubmit()
    }
  }, [fieldValues.clients.length])

  const onAddTag = () => {
    addTag(
      tempTagValue,
      fieldValues.tags,
      tag =>
        setFieldValues({
          ...fieldValues,
          tags: [tag, ...fieldValues.tags],
          tempTags: fieldValues.id ? fieldValues.tempTags : [tag, ...fieldValues.tags],
        }),
      () => setTempTagValue(''),
    )
  }

  const onDeleteTag = (i, tag) => {
    setFieldValues({
      ...fieldValues,
      tags: fieldValues.tags.filter(item => item !== tag),
      tempTags: fieldValues.id ? fieldValues.tempTags : fieldValues.tempTags.filter(item => item !== tag),
    })
  }
  const onChangeAddress = useCallback(
    e => {
      const address = e.target.value
      if (propertyId) setAddressValue(address)
      setErrors(prev => ({ ...prev, address: false }))
      setFieldValues(prev => ({
        ...prev,
        address,
        postcode: propertyId ? '' : prev.postcode,
        state: propertyId ? '' : prev.state,
        city: propertyId ? '' : prev.city,
        lat: propertyId ? '' : prev.lat,
        lng: propertyId ? '' : prev.lng,
        isChooseAddress: propertyId ? false : prev.isChooseAddress,
        localAddressSuggestions: propertyId ? [] : prev.localAddressSuggestions,
        filteredLocalAddressSuggestions: propertyId ? [] : prev.filteredLocalAddressSuggestions,
        isEnotherAddressNeeded: false,
      }))
    },
    [propertyId],
  )

  const onChangeAddressValue = useCallback(e => {
    const address = e.target.value
    setAddressValue(address)
    setErrors(prev => ({ ...prev, address: false }))
  }, [])

  const onChooseInDropdown = obj => {
    if (obj.id) {
      onChooseLocalSuggestionFromTable(obj.id)
    } else {
      setErrors(prev => ({ ...prev, address: false, postcode: false }))
      setFieldValues(prev => ({
        ...prev,
        ...obj,
        notes: prev.tempNotes,
        tags: prev.tempTags,
        isChooseAddress: true,
        //viewMode: false,
        is_manual: !!obj.is_manual,
      }))
    }
  }

  const capitalize = s => {
    if (typeof s !== 'string') return ''
    const trimmed = s.trim()
    return trimmed.charAt(0).toUpperCase() + trimmed.slice(1)
  }

  const onChooseLocalSuggestionFromTable = id => {
    // if (onAddAttachedProperty) {
    //   onAddAttachedProperty(id)
    //   return closeForm()
    // }

    if (history.location.pathname.startsWith('/properties-form') && id !== propertyId) {
      history.push(`/properties-form/${id}`)
    } else {
      setIsEdit(true)
      setIsPropertyFetching(true)
      const fetchPropertyData = fetchProperty
      return fetchPropertyData(id)
        .then(res => {
          if (isClientForm) {
            onAddAttachedProperty(res)
            return closeForm()
          }
          setModalFullSuggestion(false)
          setIsPropertyFetching(false)
          setCheckedRows([])
          setFieldValues(prev => ({
            ...prev,
            ...res,
            viewMode: true,
            isEnotherAddressNeeded: false,
            localAddressSuggestions: [],
            filteredLocalAddressSuggestions: [],
            is_manual: false,
            mergeTempNotesAndTempTagsModal: (!!prev.tempNotes || !!prev.tempTags.length) && prev.id !== res.id,
          }))
          setErrors({
            address: false,
            postcode: false,
            type: false,
          })
          return res
        })
        .catch(() => {
          setIsPropertyFetching(false)
        })
    }
  }

  //const widthObject = fieldValues.secondAddressPart ? { width: '90%' } : { width: '100%' }
  ///////////////MERGE/////////////
  function toggleMergeModal() {
    if (showMergePropertiesModal) {
      setCheckedRows([])
    }
    setShowMergePropertiesModal(!showMergePropertiesModal)
  }
  function onHandleMerge() {
    const data = {
      property_id_from: checkedRows[0].id,
      property_id_to: checkedRows[1].id,
    }
    mergeProperties(data).then(() => {
      onChooseLocalSuggestionFromTable(checkedRows[1].id).then(() => {
        setCheckedRows([])
        toggleMergeModal()
      })
    })
  }
  function onSwapItemsToMerge() {
    setCheckedRows(prev => [...prev.reverse()])
  }

  ///////////////MERGE/////////////

  const onApplyFullSuggestion = () => {
    if (!fieldValues.id) {
      onChooseLocalSuggestionFromTable(fieldValues.filteredLocalAddressSuggestions[0].id)
    } else {
      setModalFullSuggestion(false)
      toggleMergeModal()
    }
  }

  const onOpenManualAddressForm = useCallback(
    () =>
      setFieldValues(prev => ({
        ...prev,
        manualAddressForm: true,
      })),
    [],
  )

  const setManualAddress = data => {
    onChooseInDropdown(data)
    const dataToServer = {
      ...data,
      owner_user_id: ownerId,
      media: media ? media : fieldValues.media,
    }
    setIsPropertyFetching(true)
    addProperty(dataToServer)
      .then(response => {
        const promises = []
        fieldValues.new_client_properties?.forEach(item => {
          promises.push(createRelationProperty({ ...item, property_id: response.id }))
        })
        if (isClientForm) {
          onAddAttachedProperty(response)
          if (closeForm) closeForm()
        }
        if (isAppointment) {
          closeForm({ property_id: response.id })
        }
        return Promise.all(promises).then(() => response)
      })
      .then(response => {
        getProperty(response.id)
        setFieldValues(prev => ({
          ...prev,
          id: response.id,
          viewMode: true,
          isEnotherAddressNeeded: false,
          localAddressSuggestions: [],
          filteredLocalAddressSuggestions: [],
        }))
        setIsPropertyFetching(false)
        setShowButtons(false)
        return response
      })
      .catch(() => {
        setIsPropertyFetching(false)
      })
    setErrors(() => ({ type: false, address: false, postcode: false }))
  }

  /*  const onChangeTempNotesValue = e => {
    setTempNotesValue(e.target.value)
  } */

  const setMarker = ({ lat, lng }) => {
    setFieldValues(prev => ({
      ...prev,
      lat,
      lng,
    }))
  }
  const propertyToManualAddress = {
    address: propertyId ? fieldValues.address : addressValue,
    postcode: fieldValues.postcode,
    city: fieldValues.city,
    state: fieldValues.state,
    unit: fieldValues.unit,
    lat: fieldValues.lat ? +fieldValues.lat : '',
    lng: fieldValues.lng ? +fieldValues.lng : '',
    type: fieldValues.type,
    notes: fieldValues.notes,
    tags: fieldValues.tags,
    media: fieldValues.media,
    owner_user_id: ownerId,
    bbox: fieldValues.bbox ? fieldValues.bbox : null,
  }

  const onMergeNotesAndTags = mergeData => {
    setFieldValues({
      ...fieldValues,
      notes: mergeData.isNotesConfirmed
        ? (fieldValues.notes ? fieldValues.notes + '. ' : '') + capitalize(fieldValues.tempNotes)
        : fieldValues.notes,
      tempNotes: '',
      tags:
        mergeData.isTagsConfirmed && !!fieldValues.tags && !!fieldValues.tags.length
          ? [...new Set([...fieldValues.tags, ...fieldValues.tempTags])]
          : fieldValues.tags,
      tempTags: [],
      mergeTempNotesAndTempTagsModal: !fieldValues.mergeTempNotesAndTempTagsModal,
    })
  }

  const onCloseMergeTempNotesAndTempTagsModal = () => {
    setFieldValues({
      ...fieldValues,
      mergeTempNotesAndTempTagsModal: !fieldValues.mergeTempNotesAndTempTagsModal,
      tempTags: [],
      tempNotes: '',
    })
  }
  return (
    <div className='property-form'>
      {isPropertyFetching && (
        <div className='property-form-preloader'>
          <Preloader />
        </div>
      )}
      {!fieldValues.manualAddressForm ? (
        <Row gutter={[55, { xs: 10, md: 24 }]}>
          {/* {fieldValues.viewMode && !disableEvery && (
            <Col xl={24} className='edit-form'>
              <Row>
                <Col xl={12}>
                  <div className='d-flex justify-content-end'>
                    <button
                      className='btn btn-outline-secondary ml-auto edit-btn'
                      style={{ padding: '0 5px 0 5px' }}
                      type='button'
                      disabled={disableEvery}
                      onClick={() => {
                        setFieldValues(prev => ({
                          ...prev,
                          viewMode: false,
                          isChooseAddress: true,
                        }))
                        setShowButtons(true)
                      }}
                    >
                      <i className='mdi mdi-pencil' style={{ fontSize: '22px' }} />
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          )} */}
          <Col xl={12}>
            {!!onChangeRelation && (
              <Row>
                <Col span={24}>
                  <TextField
                    size='small'
                    id='relation'
                    name='relation'
                    type='text'
                    variant='outlined'
                    label='Property Relation'
                    value={relationValue ? relationValue : ''}
                    onChange={onChangeRelation}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Col>
              </Row>
            )}
            <Row>
              {!!propertyId || fieldValues.is_manual ? (
                <>
                  <Col span={24} className='custom-address'>
                    <CustomAddressAutocompleteContainer
                      value={fieldValues.address}
                      onChangeValue={onChangeAddress}
                      onChoose={onChooseInDropdown}
                      onOpenManualAddressForm={onOpenManualAddressForm}
                      addressError={errors.address}
                      disabled={fieldValues.viewMode || disableEvery}
                    />
                    <button
                      className='btn btn-outline-secondary ml-auto edit-btn'
                      style={{ padding: '0 5px 0 5px' }}
                      type='button'
                      disabled={disableEvery}
                      onClick={() => {
                        setFieldValues(prev => ({
                          ...prev,
                          viewMode: !prev.viewMode,
                          isChooseAddress: true,
                        }))
                        setShowButtons(!showButtons)
                      }}
                    >
                      <i className='mdi mdi-pencil' />
                    </button>
                  </Col>
                  {/*   <Col span={1} className='edit-form'>
                  </Col> */}
                </>
              ) : (
                <>
                  <Col span={24}>
                    <CustomAddressAutocompleteContainer
                      value={addressValue}
                      onChangeValue={onChangeAddressValue}
                      onChoose={onChooseInDropdown}
                      onOpenManualAddressForm={onOpenManualAddressForm}
                      addressError={errors.address}
                      disabled={fieldValues.viewMode || disableEvery}
                      id='address-search'
                      label='Search Address*'
                      isProposalProperties={isProposalProperties}
                      attachPropertyToProposal={attachPropertyToProposal}
                    />
                  </Col>
                  <Col span={24}>
                    <TextField
                      size='small'
                      id='address'
                      name='addressValue'
                      type='text'
                      variant='outlined'
                      label='Property Address*'
                      value={fieldValues.address ? fieldValues.address : ''}
                      onChange={onChangeAddress}
                      disabled={fieldValues.viewMode || !fieldValues.is_manual}
                      error={!!errors.address}
                      helperText={errors.address}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Col>
                </>
              )}
              <Col span={24}>
                <Row gutter={[10, 0]}>
                  <Col xs={4} md={5}>
                    <TextField
                      size='small'
                      id='postcode'
                      name='postcode'
                      variant='outlined'
                      type='text'
                      label='ZIP'
                      autoComplete='off'
                      value={fieldValues.postcode ? fieldValues.postcode : ''}
                      onChange={onChange}
                      error={!!errors.postcode}
                      helperText={errors.postcode ? 'This field is required' : ''}
                      // disabled={fieldValues.viewMode || !fieldValues.is_manual}
                      disabled={fieldValues.viewMode || !fieldValues.id}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Col>
                  <Col xs={16} md={14}>
                    <TextField
                      size='small'
                      id='city'
                      name='city'
                      label='City'
                      type='text'
                      variant='outlined'
                      autoComplete='off'
                      value={fieldValues.city ? fieldValues.city : ''}
                      onChange={onChange}
                      error={!!errors.postcode}
                      disabled={fieldValues.viewMode || !fieldValues.is_manual}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Col>
                  <Col xs={4} md={5}>
                    <TextField
                      size='small'
                      id='state'
                      name='state'
                      label='State'
                      variant='outlined'
                      type='text'
                      autoComplete='off'
                      value={fieldValues.state ? states[fieldValues.state] : ''}
                      onChange={onChange}
                      error={!!errors.postcode}
                      disabled={fieldValues.viewMode || !fieldValues.is_manual}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[10, 0]}>
                  <Col xs={12}>
                    <TextField
                      size='small'
                      id='unit'
                      name='unit'
                      type='text'
                      inputProps={{
                        maxLength: 10,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant='outlined'
                      label='Unit'
                      value={fieldValues.unit ? fieldValues.unit : ''}
                      onChange={onChange}
                      disabled={fieldValues.viewMode || disableEvery}
                      error={!!fieldValues.isEnotherAddressNeeded}
                      helperText={fieldValues.isEnotherAddressNeeded ? 'Please fill another unit' : ''}
                      fullWidth
                    />
                  </Col>
                  <Col xs={12}>
                    <TextField
                      size='small'
                      id='type'
                      name='type'
                      variant='outlined'
                      label='Property type*'
                      value={fieldValues.type ? fieldValues.type : ''}
                      onChange={
                        /* e => */
                        onChange /* ({ target: { name: 'type', value: e.target.value }, trigger: true }) */
                      }
                      select
                      error={!!errors.type}
                      helperText={errors.type ? 'This field is required' : ''}
                      disabled={fieldValues.viewMode || disableEvery}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    >
                      <MenuItem value={1}>Commercial</MenuItem>
                      <MenuItem value={2}>Residential</MenuItem>
                    </TextField>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <TextField
                  size='small'
                  multiline
                  label='Property Notes'
                  // rows={isMobile ? null : 5}
                  maxRows='5'
                  name='notes'
                  value={fieldValues.notes ? fieldValues.notes : ''}
                  onChange={onChange}
                  variant='outlined'
                  // disabled={fieldValues.viewMode || disableEvery}
                  inputProps={{
                    maxLength: 3000,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Col>
              <Col span={24}>
                <Tags
                  values={fieldValues.tags}
                  tempTagValue={tempTagValue}
                  handleFieldChange={onChange}
                  onAddTag={onAddTag}
                  // disabled={fieldValues.viewMode || disableEvery}
                  onDeleteTag={onDeleteTag}
                  tempTagValueError={tempTagValueError}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={12}>
            {!fieldValues.manualAddressForm && (
              <div className={'property-form-map' + (fieldValues.viewMode ? ' property-form-map-disabled' : '')}>
                <Map
                  marker={!!fieldValues.lat && !!fieldValues.lng ? [+fieldValues.lat, +fieldValues.lng] : null}
                  setMarker={setMarker}
                  isMarkerDaggable={!fieldValues.viewMode}
                />
              </div>
            )}
          </Col>
          {(showButtons || !!setShowButtonsInParent) && (
            <Col span={24} className={setShowButtonsInParent ? 'd-none' : ''}>
              <div className='d-flex justify-content-end'>
                {!!propertyId && (
                  <MainButton
                    type='delete'
                    title='Delete'
                    onClick={toggleDeleteModal}
                    style={{ marginRight: '10px' }}
                    ref={deleteBtnRef}
                  />
                )}
                <MainButton
                  type='cancel'
                  title='Cancel'
                  onClick={onHandleCancel}
                  // className='main-button cancel-btn'
                  style={{ /* color: '#191919', border: '1px solid #D6D6D6', */ marginRight: '10px' }}
                  ref={cancelBtnRef}
                />
                <MainButton
                  title={onChangeRelation ? 'Attach Property' : 'Save'}
                  onClick={handleSubmit}
                  // disabled={!fieldValues.isChooseAddress}
                  ref={saveBtnRef}
                  // className='main-button'
                />
              </div>
            </Col>
          )}

          {!fieldValues.id && !propertyId && (
            <Col span={24}>
              <div
                className={
                  isProposalProperties
                    ? 'd-flex justify-content-center align-items-center'
                    : 'd-flex justify-content-start'
                }
              >
                {!!onChangeRelation && !fieldValues.viewMode && !isProposalProperties && (
                  <MainButton
                    type='cancel'
                    title='Cancel'
                    onClick={onHandleCancel}
                    // className='main-button cancel-btn'
                    style={{ marginRight: '10px' }}
                  />
                )}
                {isProposalProperties && !fieldValues.viewMode ? (
                  <div style={{ marginRight: '10px' }}> {proposalButtons} </div>
                ) : null}
                {!fieldValues.viewMode && (
                  <MainButton
                    title={onChangeRelation ? 'Attach property' : 'Create Property'}
                    onClick={handleSubmit}
                    // className='main-button'
                  />
                )}
              </div>
            </Col>
          )}
        </Row>
      ) : (
        <>
          {!!onChangeRelation && (
            <TextField
              size='small'
              id='relation'
              name='relation'
              type='text'
              variant='outlined'
              label='Property Relation'
              value={relationValue ? relationValue : ''}
              onChange={onChangeRelation}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          )}
          <ManualAddressContainer
            property={propertyToManualAddress}
            onSave={setManualAddress}
            onCancel={() =>
              setFieldValues(prev => ({
                ...prev,
                manualAddressForm: false,
              }))
            }
            onChangeTemporaryNotes={onChangeField}
            okButtonText={onChangeRelation ? 'Attach Property' : fieldValues.id ? 'Save' : 'Create Property'}
          />
        </>
      )}

      <SureModal modal={deleteModal} apply={handleDeleteProperty} close={toggleDeleteModal} />

      <MergeTempNotesAndTempTagsModal
        modal={fieldValues.mergeTempNotesAndTempTagsModal}
        onConfirm={onMergeNotesAndTags}
        onClose={onCloseMergeTempNotesAndTempTagsModal}
        newTags={fieldValues.tempTags}
        newNotes={fieldValues.tempNotes}
      />

      <MergePropertiesModal
        show={showMergePropertiesModal}
        onHide={toggleMergeModal}
        onHandleMerge={onHandleMerge}
        onSwapItemsToMerge={onSwapItemsToMerge}
        itemsToMerge={checkedRows}
        name='properties'
      />
      <FullAddressSuggestionModal
        modal={modalFullSuggestion}
        close={() => {
          setFieldValues(prev => ({
            ...prev,
            isEnotherAddressNeeded: true,
          }))
          setModalFullSuggestion(false)
        }}
        apply={onApplyFullSuggestion}
        title={`This address already exists, do you want to ${
          fieldValues.id ? 'enter another address or merge these properties' : 'use this property'
        }?`}
        okTitle={fieldValues.id ? 'Merge properties' : 'Use exist property'}
        cancelTitle='Enter new address'
      />
    </div>
  )
}

const mapStateToProps = state => ({
  currentProperty: state.orcatec.property.currentProperty,
  propertyAppointments: state.orcatec.appointment.propertyAppointments,
  me: state.orcatec.user.me,
  ownerId: state.orcatec.company.owner_id,
})

const mapDispatchToProps = {
  fetchPropertiesList: actions.property.fetchPropertiesList,
  fetchProperty: actions.property.fetchProperty,
  setCurrentProperty: actions.property.setCurrentItem,
  fetchAppointmentsByPropertyId: actions.appointment.fetchAppointmentsByPropertyId,
  addProperty: actions.property.addProperty,
  deleteProperty: actions.property.deleteProperty,
  updateProperty: actions.property.updateProperty,
  createRelationProperty: actions.client.createRelationProperty,
  dellAttachedProperty: actions.client.dellAttachedProperty,
  mergeProperties: actions.property.mergeProperties,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PropertyForm))
