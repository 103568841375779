import React, { useContext, useState } from 'react'
import { Document, Page } from 'react-pdf'
import { DocViewerContext } from '../../../../state'
import { Spin } from 'antd'

const PDFPages = () => {
  const { state } = useContext(DocViewerContext)
  const { currentDATA, rotate, scale } = state

  const [numPage, setNumPages] = useState(null)

  function onDocumentLoadSuccess(num) {
    setNumPages(num)
  }

  if (!currentDATA) return null

  return (
    <div className='pdf-renderer__wrapper'>
      <Document
        file={currentDATA}
        renderMode={'svg'}
        rotate={rotate}
        onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)}
        loading={<Spin className='spin-pdf' size='large' />}
      >
        {Array.from(new Array(numPage), (el, index) => (
          <>
            <p className='page-number'>
              Page {index + 1} of {numPage}
            </p>
            <Page
              // height={(rendererRect?.height || 100) - 100}
              // width={(rendererRect?.width || 100) - 100}
              scale={scale}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              className='pdf-page'
            />
          </>
        ))}
      </Document>
    </div>
  )
}

export default PDFPages
