import { FC } from 'react'
import styled from 'styled-components'
import { Modal, ModalProps, Checkbox, Button, Tooltip } from 'antd'
import { InfoIconLight } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { IWorkOrderState } from 'features/Project/types'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompany } from 'store/Orcatec/selectors/company'
import { Companies } from 'types/Company'
import { InfoCircleOutlined } from '@ant-design/icons'

interface IProps extends ModalProps {
  onChange: (e: CheckboxChangeEvent) => void
  onSave: () => void
  onCancel: () => void
  state: IWorkOrderState
  loading: boolean
  // clients: ChildrenByEntityType<'client'>
  // properties: ChildrenByEntityType<'property'>
}

const basicConfig: {
  title: string
  name: keyof IWorkOrderState
  info?: string
}[] = [
  {
    title: 'Contacts',
    name: 'contacts',
  },
  {
    title: 'Allowable templates',
    name: 'forms',
    info:
      'Choose this field if you want to copy selected Allowable templates from the project',
  },
  {
    title: 'Scope of work',
    name: 'scope_of_work',
  },
  {
    title: 'Notes',
    name: 'notes',
  },
  {
    title: 'Information for workers',
    name: 'info_for_workers',
  },
  {
    title: 'Information for/from customer',
    name: 'customer_info',
  },
]

export const CreateWorkOrder: FC<IProps> = ({
  title = 'Create Work Order',
  onChange,
  onSave,
  onCancel,
  state,
  loading,
  // clients,
  // properties,
}) => {
  const company = useAppSelector(selectCompany)

  return (
    <Modal
      open
      title={title}
      onCancel={onCancel}
      centered
      destroyOnClose
      footer={
        <Controls>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            onClick={onSave}
            // size='large'
            type='primary'
            loading={loading}
          >
            Create Work Order
          </Button>
        </Controls>
      }
    >
      <Wrapper>
        <Title>
          {`Choose which fields to copy from the project to the Work Order.`}
        </Title>

        <ActionsBlock>
          {/*  {!!(clients.length || properties.length) && (
            <Row>
              <p>Contacts</p>
              <Select
                style={{ width: '300px' }}
                onChange={(_, checked) =>
                  onChange({
                    target: { name: 'contacts', checked },
                  })
                }
                mode='multiple'
                value={state.contacts}
                options={[
                  {
                    label: 'Contacts',
                    options: clients.map(item => ({
                      label: item.name,
                      value: item.id,
                      name: 'clients',
                    })),
                  },
                  {
                    label: 'Properties',
                    options: properties.map(item => ({
                      label: item.full_address,
                      value: item.id,
                      name: 'properties',
                    })),
                  },
                ]}
              />
            </Row>
          )} */}
          {[...basicConfig]
            ?.filter(item =>
              [Companies.SafetyNey].includes(company.id)
                ? !!item
                : item.name !== 'forms',
            )
            .map((item, idx) => (
              <Row key={idx}>
                <Checkbox
                  name={item.name}
                  checked={state[item.name]}
                  onChange={onChange}
                />
                <span>{item.title}</span>
                {!!item.info && (
                  <Tooltip title={item.info}>
                    <InfoCircleOutlined
                      style={{ fontSize: 20, color: '#1890ff' }}
                    />
                  </Tooltip>
                )}
              </Row>
            ))}
        </ActionsBlock>
        <AlertBlock>
          <InfoIconLight width={40} height={40} />
          <p>
            {`Remember, any changes made to either the project or the Work Order after copying won't update the other.`}
          </p>
        </AlertBlock>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``

const Title = styled.div`
  margin-bottom: 16px;
`

const AlertBlock = styled.div`
  background: rgba(241, 242, 253, 1);
  padding: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
`

const ActionsBlock = styled.div`
  padding: 0px 0px 0px 16px;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 16px;
  margin-bottom: 24px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  & .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
  & .ant-checkbox {
    margin: 0;
  }
  & .ant-checkbox-inner::after {
    left: 26%;
  }
`

const Controls = styled.div`
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
`
