import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const Label = styled.p`
  color: #0000008a;
`
