import { useDispatch } from 'react-redux'
import InstallersInfoBlock from '../Form/TabsContent/TabInfoBlock/InstallersInfoBlock/InstallersInfoBlock'
import ModalFileUpload from '../Modals/ModalFileUploadContainer'
import { MainSection } from './components/MainSection/MainSection'
import { ProposalContacts } from './components/ProposalContacts/ProposalContacts'
import { proposalModalFiles } from 'store/Orcatec/actions'
import Preloader from 'containers/MainContent/Orcatec/components/Preloader'
import { Job } from 'types/Jobs'
import RelatedJobs from 'containers/MainContent/Orcatec/Jobs/components/RelatedJobs'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { EyeIcon1 } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'

import { ProjectsIcon } from 'layouts/MainLayout/Header/components/Navigation/NavigationIcons'

import { FormsBlock } from 'features/Forms/components/FormsBlock/FormsBlock'
import {
  createRelatedFormToJob,
  getAttachedTemplatesToProject,
  getRelatedFormToJob,
  attachRelatedFormsToEvent,
} from 'api/CustomForms'

import {
  ModuleName,
  ProjectPermissions,
  SchedulePermissions,
} from 'features/Settings/UsersAndGroups'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'
import { useAppSelector } from 'store/Orcatec/hooks'
import { Companies } from 'types/Company'
import { CustomFormEntityType } from 'features/Forms/types'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import ErrorBoundary from 'containers/MainContent/Orcatec/components/ErrorBoundary/ErrorBoundary'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
import { InfoForWorkersOperation } from '../InfoForWorkers/types'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { Project } from 'features/Project/types'

type Props = {
  job: Job
  proposalData: Project
  isSchedule?: boolean
  onChangeData: (data: Project) => void
  linkToProject?: string
  onSendForm: () => void
}

export const JobView = ({
  job,
  proposalData,
  isSchedule,
  onChangeData,
  linkToProject,
  onSendForm,
}: Props) => {
  const { READ, UPDATE } = usePermissionsByModule(InfoForWorkersOperation)

  const dispatch = useDispatch()

  const me = useAppSelector(selectAuthUser)

  const isVisiblePrice = useAppSelector(
    selectIsPriceVisible(proposalData?.user_id),
  )

  const companyId = useAppSelector(state => state.orcatec.company.id)
  const isWorkOrder = !!proposalData.parent_id

  const handleChangeNotesForWorkers = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!UPDATE) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    onChangeData({
      ...proposalData,
      proposal_tabs: [
        {
          ...proposalData.proposal_tabs[0],
          notes_for_installers: e.target.value,
        },
      ],
    })
  }

  const handleChangeDescriptions = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    if (!UPDATE) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    // const newFiles = [...proposalData?.proposal_tabs?.[0]?.files]
    const newFiles = structuredClone(proposalData?.proposal_tabs?.[0]?.files)
    newFiles[idx].description = e.target.value

    onChangeData({
      ...proposalData,
      proposal_tabs: [
        {
          ...proposalData.proposal_tabs[0],
          files: newFiles,
        },
      ],
    })
  }

  const handleSaveFiles = files => {
    onChangeData({
      ...proposalData,
      proposal_tabs: [
        {
          ...proposalData.proposal_tabs[0],
          files /* : [...proposalData.proposal_tabs[0].files, ...files] */,
        },
      ],
    })
  }

  const toggleFileUploadModal = () => {
    if (!UPDATE) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    dispatch(
      proposalModalFiles.toggleModal(
        'tab',
        proposalData?.proposal_tabs[0].files,
      ),
    )
  }

  if (!proposalData) return <Preloader />
  {
    /* Show only for West Coast Heating */
  }
  // 286 - WEST COST
  // const isShowLinkToProject =
  //   (!!linkToProject && company?.id === 286) || me.permissions.proposals

  return (
    <Wrapper className='job_view'>
      {(!!proposalData.clients?.length ||
        !!proposalData.properties?.length ||
        !!proposalData.organizations?.length) &&
        !isSchedule && (
          <ProposalContacts
            proposal={proposalData}
            title={
              proposalData?.parent_id
                ? 'Work Order Contacts'
                : 'Project Contacts'
            }
          />
        )}

      {READ && (
        <InstallersInfoBlock
          fieldValue={proposalData?.proposal_tabs?.[0]?.notes_for_installers}
          onChangeTextField={handleChangeNotesForWorkers}
          files={proposalData?.proposal_tabs?.[0]?.files}
          openFileModal={toggleFileUploadModal}
          placeholder='Information for workers'
          onChangeDescription={handleChangeDescriptions}
        />
      )}

      <div
        style={{
          margin: '20px 0 30px',
        }}
      >
        <ErrorBoundary>
          <FormsBlock
            showAllowableTemplates={Companies.SafetyNey === companyId}
            getAttachedTemplate={getAttachedTemplatesToProject}
            createRelatedForm={createRelatedFormToJob}
            getRelatedForm={getRelatedFormToJob}
            onAttachTemplates={attachRelatedFormsToEvent}
            onSendForm={onSendForm}
            id={job.id}
            entityId={job.proposal_id}
            entityType={CustomFormEntityType.JOB}
            // module={ModuleName.JOBS}
            // getCount={getCountFormsJob}
            // hideTemplates
            // title='Job'
          />
        </ErrorBoundary>
      </div>

      {!isWorkOrder && (
        <>
          <AccessControl
            additionalAccess={!!isVisiblePrice}
            module={ModuleName.PROJECT}
            scopes={[ProjectPermissions.PROJECT_CAN_READ]}
            author={proposalData?.user_id}
          >
            <LinkToProject
              href={linkToProject}
              target='_blank'
              rel='noreferrer'
            >
              Customer View
              <EyeIcon1 />
            </LinkToProject>
          </AccessControl>

          <AccessControl
            module={ModuleName.PROJECT}
            scopes={[ProjectPermissions.PROJECT_CAN_READ]}
            author={proposalData?.user_id}
          >
            <Link
              to={`/projects/${job.proposal_id}`}
              style={{ margin: '10px 0 20px 20px', display: 'inline-flex' }}
            >
              <p style={{ display: 'inline-flex', gap: 10 }}>
                Edit Project
                <ProjectsIcon size={18} />
              </p>
            </Link>
          </AccessControl>
        </>
      )}

      <AccessControl
        visible={!isSchedule}
        module={ModuleName.SCHEDULE}
        scopes={[SchedulePermissions.SCHEDULE_CAN_VIEW_PROJECT_DETAILS]}
      >
        <MainSection
          data={proposalData}
          onChangeData={onChangeData}
          companyId={companyId}
          me={me}
          eventName={job?.event_name}
        />
      </AccessControl>

      {!isSchedule && (
        <RelatedJobs
          proposalId={proposalData?.id}
          currentJobId={job.id}
          isWorkOrder={isWorkOrder}
          isSchedule={isSchedule}
        />
      )}

      <ModalFileUpload
        files={proposalData?.proposal_tabs?.[0]?.files}
        clientView={true}
        handleSave={handleSaveFiles}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & > div {
    margin-bottom: 10px;
  }
`

const LinkToProject = styled.a`
  display: inline-flex;
  gap: 10px;
  margin: 10px 0 20px 10px;
`
