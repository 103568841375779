import { PaperClipOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { ModalFilesUpload } from 'components/UI/ModalFIlesUpload/ModalFilesUpload'
import { ItemAttachment } from 'features/Items/types'
import DocViewer from 'helpers/viewFIlesNew/ViewDoc'
import { ViewFile } from 'helpers/viewFile/viewFile'
import { forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components'

interface Props {
  data: ItemAttachment[]
  disabled?: boolean
  imageSize?: number
  filesLimit?: number
  title?: string
  tooltip?: boolean
  open?: boolean
  onUpload?: (files: ItemAttachment[]) => void
}

const IMAGE_WIDTH = 60

export const Attachments = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      tooltip,
      data,
      disabled,
      filesLimit,
      onUpload,
      imageSize = IMAGE_WIDTH,
      open,
    },
    ref,
  ) => {
    const [viewerModal, setViewerModal] = useState<{
      showDocViewerModal: boolean
      documents: ItemAttachment[]
      currentFileIdx: number | null
    }>({
      showDocViewerModal: false,
      documents: [],
      currentFileIdx: null,
    })
    const [filesUploadModal, setFilesUploadModal] = useState(!!open)

    useEffect(() => {
      if (open !== undefined) {
        setFilesUploadModal(open)
      }
    }, [open])

    const isMobileApp = () =>
      window?.ReactNativeWebView && window?.ReactNativeWebView?.postMessage

    const onClickInApp = event => {
      if (isMobileApp()) {
        window.ReactNativeWebView.postMessage(JSON.stringify(event))
      }
    }

    const toggleOpenDocViewerModal = () => {
      setViewerModal(prev => ({
        ...prev,
        showDocViewerModal: !prev.showDocViewerModal,
      }))
    }

    const getFileForModalDocViewer = (
      files: ItemAttachment[],
      currentFileIdx: number,
    ) => {
      if (isMobileApp()) {
        return onClickInApp({
          key: 'open_file',
          value: files[currentFileIdx],
        })
      }
      setViewerModal({
        documents: files,
        currentFileIdx,
        showDocViewerModal: true,
      })
    }

    const handleCancel = () => {
      setFilesUploadModal(false)
    }

    const handleOpenFilesUpload = () => {
      setFilesUploadModal(true)
    }

    const handeFilesUpload = (files: ItemAttachment[]) => {
      onUpload?.(
        files.map(file =>
          typeof file === 'string' ? file : file.source || file?.linkToFile,
        ),
      )

      setFilesUploadModal(false)
    }

    return (
      <Wrapper>
        {title && <h6>{title}:</h6>}

        <List imageSize={imageSize}>
          {data
            ?.filter(image => !!image)
            ?.map((image: ItemAttachment, idx: number) => (
              <div
                onClick={e => {
                  if (e?.target?.classList?.contains('ant-tooltip-inner'))
                    return
                  getFileForModalDocViewer(data, idx)
                }}
                key={idx}
              >
                <ViewFile
                  file={image?.source || image?.linkToFile || image}
                  fileStyle={{
                    width: imageSize,
                    height: imageSize,
                    border: '1px solid #d9d9d9',
                    borderRadius: 8,
                    padding: 5,
                  }}
                  viewOnly={false}
                  tooltipPlacement={tooltip ? 'top' : undefined}
                  isLogo={undefined}
                />
              </div>
            ))}

          {!!onUpload && !disabled && (
            <Tooltip title='Manage attachments' mouseLeaveDelay={0}>
              <Upload onClick={handleOpenFilesUpload}>
                <PaperClipOutlined style={{ fontSize: 18 }} />
              </Upload>
            </Tooltip>
          )}
        </List>

        {viewerModal?.showDocViewerModal && (
          <DocViewer
            documents={viewerModal.documents}
            currentDocument={viewerModal.currentFileIdx}
            isModalVisible={viewerModal.showDocViewerModal}
            handleCancel={toggleOpenDocViewerModal}
          />
        )}

        {filesUploadModal && (
          <ModalFilesUpload
            ref={ref}
            data={data}
            onSave={handeFilesUpload}
            onCancel={handleCancel}
            filesLimit={filesLimit || 10}
          />
        )}
      </Wrapper>
    )
  },
)

Attachments.displayName = 'Attachments'

const Wrapper = styled.div`
  width: 100%;
`
const List = styled.div<{ imageSize: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, ${props => props.imageSize}px);
  gap: 5px;
  min-height: ${props => props.imageSize}px;
`

const Upload = styled.div`
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: #2e5bff;
  }
`
