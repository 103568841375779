import { DatePicker as DatePickerAnt, DatePickerProps } from 'antd'
import styled from 'styled-components'

type Props = DatePickerProps & {
  error?: string
}

export const DatePicker = ({ error, ...rest }: Props) => {
  return (
    <Wrapper error={!!error}>
      <DatePickerAnt {...rest} format='MM/DD/YYYY' />
      {!!error && <ErrorText>{error}</ErrorText>}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ error: boolean }>`
  .ant-picker {
    border-color: ${props => (props.error ? 'red' : '#d9d9d9')};
  }
`
const ErrorText = styled.p`
  color: red;
  font-size: 0.6rem;
`
