import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  & > * {
    margin-left: -10px;

    &:first-child {
      margin-left: 0;
    }
  }
`

export const Assignee = styled.div<{ position: number; selected: boolean }>`
  position: relative;
  z-index: ${props => props.position + (props.selected ? 1 : 0)};
  border-radius: 50%;
  border: 2px solid;
  border-color: ${props => (props.selected ? '#4c53ef' : 'transparent')};
  /* width: 40px;
  height: 40px; */
  cursor: pointer;

  &:hover {
    z-index: 10;
  }
`
// export const Avatar = styled.div<{
//   pictureURL: string | null
//   size?: 'small' | 'medium' | 'large'
//   backgroundColor?: string | null
// }>`
//   border-radius: 50%;
//   border: 2px solid #fff;
//   background-image: ${props => `url(${props.pictureURL})`} /* , url('assets/images/users/user-1.jpg') */;
//   background-color: ${props => props.backgroundColor};
//   height: 100%;
//   background-size: cover;
//   background-position: center center;
//   background-repeat: no-repeat;
//   width: ${props =>
//     props.size ? (props.size === 'small' ? '24px' : props.size === 'large' ? '42px' : '36px') : '36px'};
//   height: ${props =>
//     props.size ? (props.size === 'small' ? '24px' : props.size === 'large' ? '42px' : '36px') : '36px'};
// `

export const MoreWrapper = styled.div<{ onTop?: boolean }>`
  z-index: ${props => (props.onTop ? 10 : 9)};
`

export const Circle = styled.div<{ active: boolean }>`
  border-radius: 50%;
  border: 2px solid;
  border-color: ${props => (props.active ? 'grey' : '#fff')};
  background-color: #c5c5c5;
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: grey;
  }
`

export const Dropdown = styled.div`
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25),
    0 0 1px rgba(9, 30, 66, 0.31);
  width: max-content;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  top: 40px;
  left: 120px;
`

export const DropdownItem = styled.div<{ selected: boolean }>`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  background-color: ${props => (props.selected ? '#f4f5f7' : 'none')};

  &:hover {
    background-color: #f4f5f7;
  }
`
