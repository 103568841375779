import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 300px;
  margin: 0 auto;
`

export const Content = styled.div``
export const Heading = styled.h5`
  text-align: center;
`

type TipsItemProps = {
  active: boolean
}

export const TipsItem = styled.div<TipsItemProps>`
  padding: 5px;
  cursor: pointer;
  text-align: center;
  border: 1px solid ${({ active }) => (active ? '#4c53ef' : 'gray')};
  background-color: ${({ active }) => (active ? '#f0f8ff' : '#fff')};
  border-radius: 12px;
  height: 65px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const Price = styled.p``
export const Percent = styled.p`
  color: gray;
`
