import React, { useRef, useState } from 'react'
import { Modal } from 'antd'
import { connect } from 'react-redux'
import * as actions from 'store/Orcatec/actions'
import CanvasDraw from 'react-canvas-draw'
const ModalClientActivity = () => {
  let canvas = useRef()
  const [image, changeImage] = useState('')
  const getBase64 = () => {
    let canvas = document.getElementsByTagName('canvas')
    let localImages = []
    for (let c of canvas) {
      localImages.push(c.toDataURL())
    }
    changeImage(localImages[1])
  }
  return (
    <Modal visible={false} title='Modal Drawing' onOk={() => getBase64()}>
      <div ref={canvas}>
        <CanvasDraw
          brushRadius={5}
          ref={canvasDraw => (canvas = canvasDraw)}
          brushColor='#444'
          canvasWidth='480px'
          canvasHeight='300px'
          hideGrid={true}
          hideInterface={true}
          className='canvas-container'
          style={{ cursor: 'pointer', border: '1px solid black', borderRadius: '8px' }}
        />
        <button className='btn btn-primary' onClick={() => canvas.clear()}>
          Clear
        </button>
        <img src={image} />
      </div>
    </Modal>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalClientActivity)
