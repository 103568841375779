import styled from 'styled-components'

export const AppointmentListWrapper = styled.div``
export const AppointmentListHeading = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`
export const AppointmentListHeadingButtons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 1155px) {
    padding-top: 10px;
  }
`
