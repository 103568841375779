import React, { useState } from 'react'

// import { connect } from 'react-redux'
// import { Input, Checkbox } from 'antd'
import ModalWrapper from './ModalWrapper/ModalWrapper'
import { TextField } from '@material-ui/core'
import './ModalReminder.scss'
import ModuleStatus from '../ModuleStatus'

const inputProps = { maxLength: 3000 }

const statusList = [
  {
    id: 1,
    name: 'No Action',
  },
  {
    id: 2,
    name: 'Action Needed',
  },
  {
    id: 3,
    name: 'Action Started',
  },
  {
    id: 4,
    name: 'Partially Completed',
  },
  {
    id: 5,
    name: 'Complete',
  },
]

const ModalReminder = props => {
  const [notes, setNotes] = useState(
    props?.values?.notes ? props.values.notes : '',
  )

  const handleNotesChange = e => setNotes(e.target.value)

  return (
    <ModalWrapper
      modal={props.show}
      close={props.onHide}
      apply={props.onSave}
      isSaving={false}
      dialogClassName='remainder-module-modal'
      disableCancelBtn={false}
      disableOkBtn={props.proposalStatus === 3}
      customCancelBtnAction={props.onCancel}
      hideCancelBtn
    >
      <div className='remainder-module-wrapper'>
        <div className='remainder-module-wrapper-title'>
          Reminder Module {props.values?.name}
        </div>
        <ModuleStatus
          statusList={statusList}
          currentStatus={props.values?.status}
          onChangeStatus={props.onChangeStatus}
        />
        <div className='remainder-module-wrapper-is_completed_checkbox'></div>
        <div className='remainder-module-wrapper-comments'>
          <TextField
            size='small'
            multiline
            label='Comments'
            rows={3}
            name='notes'
            value={notes}
            // onChange={e => props.onChange('notes', e)}
            onChange={handleNotesChange}
            onBlur={e => props.onChange('notes', e)}
            variant='outlined'
            inputProps={inputProps}
            fullWidth
          />
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ModalReminder
