import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AppStateType } from 'store'

interface SliceState {
  sidebarIsOpen: boolean
  placeFree: number
  settings_title: string
  settings_arrow_back: boolean
  loading: boolean
}

const initialState: SliceState = {
  sidebarIsOpen: false,
  placeFree: window.innerWidth,
  settings_title: '',
  settings_arrow_back: false,
  loading: false,
}

const ui = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    setSettingsTitle: (state, { payload }: PayloadAction<string>) => {
      state.settings_title = payload
    },
    setSettingsArrowBack: (state, { payload }: PayloadAction<boolean>) => {
      state.settings_arrow_back = payload
    },
    setSettingsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    drawerIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.sidebarIsOpen = payload
    },
    setPlaceFree: (state, { payload }: PayloadAction<number>) => {
      state.placeFree = payload
    },
  },
})

export default ui.reducer
export const {
  drawerIsOpen,
  setPlaceFree,
  setSettingsTitle,
  setSettingsArrowBack,
  setSettingsLoading,
} = ui.actions

export const selectCustomSettingsTitle = (state: AppStateType) =>
  state.orcatec.uiSlice.settings_title
export const selectSettingsArrowBack = (state: AppStateType) =>
  state.orcatec.uiSlice.settings_arrow_back
export const selectSettingsLoading = (state: AppStateType) =>
  state.orcatec.uiSlice.loading

//Selectors
