import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, TextField } from 'components/UIKit'

import {
  Agreement,
  Error,
  Form,
  ResultWrapper,
  SecondaryText,
  Signin,
  StyledCheckbox,
  StyledLink,
  Title,
  Wrapper,
} from './Signup.style'
import { Link } from 'react-router-dom'
import ReactInputMask from 'react-input-mask'
import { Result } from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import { sendRegisterForm } from 'api/User'

const phoneRegExp = /^[(][0-9]{3}[)][\s][0-9]{3}[-][0-9]{4}$/

const companyFormSchema = yup.object({
  full_name: yup
    .string()
    .required('Full name is required')
    .trim()
    .min(2, 'Full name length should be at least 2 characters')
    .max(200, 'Full name cannot exceed more than 200 characters'),

  phone: yup
    .string()
    .nullable()
    .matches(phoneRegExp, 'Phone must match: (000) 000-0000')
    .label('Phone'),
  email: yup
    .string()
    .email('Invalid email address')
    .nullable()
    .required('Email is required')
    .trim()
    .label('Email'),
  agreement: yup
    .boolean()
    .oneOf([true], 'You must accept the terms and conditions'),
})

export const SignUpForm = () => {
  const [loading, setLoading] = useState(false)
  const [submited, setSubmited] = useState(false)

  const methods = useForm({
    resolver: yupResolver(companyFormSchema),
    defaultValues: {
      full_name: '',
      company_name: '',
      count_employees: '',
      phone: '',
      industry: '',
      email: '',
      agreement: false,
    },
    mode: 'onChange',
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    // getValues,
    // setError,
    // trigger,
    // setValue,
  } = methods

  const onSubmit = async data => {
    try {
      setLoading(true)
      await sendRegisterForm(data)
      setSubmited(true)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {!submited ? (
        <Wrapper>
          <Title>Sign Up</Title>

          <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='full_name'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Full name'
                  required
                  error={errors?.full_name?.message}
                  // autoComplete='off'
                  autoComplete='new-password'
                  // onBlur={() => trigger('email')}
                />
              )}
            />

            <Controller
              name='phone'
              control={control}
              render={({ field: { value, onChange } }) => (
                <ReactInputMask
                  mask='(999) 999-9999'
                  value={value}
                  onChange={onChange}
                >
                  {() => (
                    <TextField
                      label='Phone'
                      required
                      error={errors?.phone?.message}
                      placeholder='(000) 000-0000'
                    />
                  )}
                </ReactInputMask>
              )}
            />

            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Email'
                  required
                  error={errors?.email?.message}
                  // autoComplete='off'
                  autoComplete='new-password'
                  // onBlur={() => trigger('email')}
                />
              )}
            />

            <Controller
              name='company_name'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Company name'
                  required
                  error={errors?.company_name?.message}
                  // autoComplete='off'
                  autoComplete='new-password'
                  // onBlur={() => trigger('email')}
                />
              )}
            />

            <Controller
              name='industry'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Your industry'
                  // required
                  // error={errors?.email?.message}
                  // autoComplete='off'
                  autoComplete='new-password'
                  // onBlur={() => trigger('email')}
                />
              )}
            />
            <Controller
              name='count_employees'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Number of employees'
                  type='number'
                  // error={errors?.employees?.message}
                  // autoComplete='off'
                  autoComplete='new-password'
                  // onBlur={() => trigger('email')}
                />
              )}
            />

            <Agreement>
              <Controller
                name='agreement'
                control={control}
                render={({ field }) => (
                  <StyledCheckbox
                    {...field}
                    error={!!errors?.agreement?.message}
                  >
                    I agree with{' '}
                    <Link
                      to={{
                        pathname: 'https://orcatec.com/privacy_policy.pdf',
                      }}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link
                      to={{
                        pathname:
                          'https://orcatec.com/terms_and_conditions.pdf',
                      }}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Terms of use
                    </Link>
                  </StyledCheckbox>
                )}
              />
              {!!errors?.agreement?.message && (
                <Error>{errors?.agreement?.message}</Error>
              )}
            </Agreement>

            <Button
              block
              type='primary'
              size='large'
              loading={loading}
              // disabled={!captсha}
              htmlType='submit'
            >
              Sign Up
            </Button>
          </Form>

          <Signin>
            <SecondaryText>Already have an account?</SecondaryText>
            <StyledLink to='/auth/signin'>Sign In</StyledLink>
          </Signin>
        </Wrapper>
      ) : (
        <ResultWrapper>
          <Result
            // status='success'
            icon={<SmileOutlined />}
            title='Thank you for your interest!'
            subTitle='We have received your registration information. Our team will get in touch with you soon to finalize your access.'
          />

          <Signin>
            <SecondaryText>Back to</SecondaryText>
            <StyledLink to='/auth/signin'>Sign In</StyledLink>
          </Signin>
        </ResultWrapper>
      )}
    </>
  )
}
