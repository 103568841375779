import React from 'react'
import { Modal, Button } from 'antd'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import Card from '../../../Card'

const ModalPaySubscription = props => {
  const wasEdited = props.isNewCard || props.isOldCardChanged
  return (
    <Modal
      visible={props.visible}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      className='modal_update_card'
      width='690px'
      okText='Save'
      footer={[
        <Button key='back' onClick={props.handleCancel}>
          Cancel
        </Button>,
        //   <Button key='submit' type='primary' loading={props.loading} onClick={props.handleOk} disabled={props.error || !wasEdited}>
        //     Submit
        //   </Button>,
        <MainButton
          key='submit'
          title={'Pay'}
          onClick={props.handleOk}
          disabled={!!props.error || !wasEdited}
          isFetching={props.loading}
        />,
      ]}
    >
      {!!props.visible && (
        <>
          {<p>Total: ${props.amount}</p>}
          <Card
            parentEl={props.parentEl}
            error={props.error}
            cardData={props.cardData}
            setData={props.setData}
            onSubmit={props.handleOk}
            visible={props.visible}
            fieldErrors={props.fieldErrors}
            setFieldErrors={props.setFieldErrors}
            setError={props.setError}
            setIsNewCard={props.setIsNewCard}
            setIsOldCardChanged={props.setIsOldCardChanged}
          />
        </>
      )}
    </Modal>
  )
}

export default ModalPaySubscription
