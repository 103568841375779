import React, { useState } from 'react'
import moment from 'moment-timezone'
import TimeCardTable from '../TimeCardTable.jsx/TimeCardTable'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import './TimeCard.scss'
import { useTimeCollision } from 'containers/MainContent/Orcatec/TimeCards/hooks/useTimeCollision.jsx'

const TimeCard = React.memo(
  ({
    i,
    card,
    user_id,
    startDate,
    endDate,
    fetchCard,
    updateCardList,
    openedCard,
    setOpenedCardCall,
    timezone,
    readOnly,
  }) => {
    const timeCollision = useTimeCollision(card?.timelines)

    return (
      <div
        className='border p-0 rounded col-md-12 position-relative mb-2 timecard'
        style={{ borderColor: 'red' }}
      >
        <div
          className='row p-4 d-flex justify-content-between'
          style={{ cursor: 'pointer' }}
          onClick={() => setOpenedCardCall(i)}
        >
          <div className='row'>
            <div className='mr-5 ml-4 date'>
              <strong>{moment(card.date).format('MM/DD/YY')}</strong>
            </div>
            <div className='mr-5 work-time'>
              Total time worked:{' '}
              <strong>{card.total_time_worked || '0 min'}</strong>
            </div>
            <div className='mr-3 break-time'>
              Total break time:{' '}
              <strong>{card.total_brake_time || '0 min'}</strong>
            </div>
            {timeCollision && (
              <div className='mr-5 ml-4 date'>
                <strong style={{ color: 'red' }}>Time overlapping!</strong>
              </div>
            )}
            <div className='myArrow ml-auto'>
              <LegacyIcon
                type={openedCard === i ? 'up' : 'down'}
                style={{ fontSize: '18px' }}
              />
            </div>
          </div>
        </div>

        {openedCard === i && (
          <div
            className='time-card-table-wrapper'
            style={{ padding: '0 25px 25px 25px' }}
          >
            <div className='time-card-table-top'>
              <div className='time-card-table-alert'>
                {timeCollision && (
                  <div>
                    This working day has time collision, fix it before adding
                    new time!
                  </div>
                )}
              </div>
            </div>

            <TimeCardTable
              date={i}
              timelines={card.data}
              user_id={user_id}
              startDate={startDate}
              endDate={endDate}
              fetchCard={() => fetchCard()}
              updateCardList={updateCardList}
              timeCollision={timeCollision}
              timezone={timezone}
              readOnly={readOnly}
            />
          </div>
        )}
      </div>
    )
  },
)

TimeCard.displayName = 'TimeCard'

export default TimeCard
