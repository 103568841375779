import React, { Fragment } from 'react'

import momemt from 'moment-timezone'

import MessageAttachment from './MessageAttachment'

const urlfy = text => {
  const urlRegex = /(\bhttps?:\/\/([A-Za-z0-9-_\.]+){2,}\/?[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
  return text.replace(urlRegex, url => `<a href="${url}" target="_blank">${url}</a>`)
}

const Message = ({ title, sentTimestamp, body, attachments, isSelf }) => {
  return (
    <div className={`ticket__message ${isSelf ? 'ticket-message__self' : 'ticket-message__sa'}`}>
      <div className='ticket-message__primary-col'>
        <div className='ticket-message__title'>{title}</div>
        <div className='ticket-message__body' dangerouslySetInnerHTML={{ __html: urlfy(body) }}></div>
        <div className='ticket-message__attachments'>
          {attachments && attachments.length ? (
            <Fragment>
              <div className='ticket-message-attachments__title'>Attachments:</div>
              <div className='ticket-message-attachments__files'>
                {attachments.map((item, idx) => (
                  <MessageAttachment key={idx} attachment={item} />
                ))}
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>
      <div className='ticket-message__secondary-col'>
        <div className='ticket-message__date'>
          <div className='two-rows-date__date'>{momemt(sentTimestamp, 'X').format('DD/MM/YYYY')}</div>
          <div className='two-rows-date__time'>{momemt(sentTimestamp, 'X').format('h:mma')}</div>
        </div>
      </div>
    </div>
  )
}

export default Message
