import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
  title: string
  description?: string
  withInfoIcon?: boolean
  styles?: React.CSSProperties | null
}

export const SettingsRow = ({ children, title, description, withInfoIcon, styles = {} }: Props) => {
  return (
    <SettingsRowStyled style={styles}>
      <Title>
        {title}
        {!!description && withInfoIcon && (
          <Tooltip title={description}>
            <span
              style={{
                marginLeft: 5,
                cursor: 'help',
              }}
            >
              <QuestionCircleOutlined />
            </span>
          </Tooltip>
        )}
      </Title>

      <div>
        {children}
        {!!description && !withInfoIcon && <Description>{description}</Description>}
      </div>
    </SettingsRowStyled>
  )
}

const SettingsRowStyled = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  padding: 12px 0;
  border-bottom: 1px solid #0000000f;
`

const Title = styled.p`
  color: #707683;
`
const Description = styled.p`
  margin-top: 10px;
  font-size: 0.8rem;
  font-style: italic;
  color: #707683;
`
