import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 200px;
  margin-left: auto;
  margin-right: 10px;
  margin: 0 20px 5px auto;
`
export const Row = styled.div<{ last?: boolean }>`
  display: flex;
  justify-content: space-between;
  font-size: ${props => (props.last ? '14px' : '12px')};
  font-weight: ${props => (props.last ? '500' : '400')};

  span {
    /* font-weight: 500; */

    &:not(:first-child) {
      font-size: 1.2em;
    }
  }
`

export const Table = styled.div`
  border-bottom: 1px solid;
  margin-bottom: 10px;
`
