import React, { useState, useEffect, useRef } from 'react'

import { TextField } from '@material-ui/core'

import Preloader from '../../../components/Preloader'
import AttachmentsUploader from '../AttachmentsUploader'

import { MAX_BODY_LENGTH } from '../../../../../../helpers/supportTickets'
import { replyMessage } from '../../../../../../api/supportTickets'
import { parseMessages } from '../../../../../../helpers/http-error'
import simpleNotify from '../../../../../../helpers/notifications/simpleNotify'

const validate = (value, callback) => {
  const result = callback(value)

  return {
    error: !!result,
    message: result || '',
  }
}

const validationFields = ['body', 'attachments']

const initialFields = {
  body: '',
  attachments: [],
}

const Reply = ({ ticket, onSubmit, onSent, onError }) => {
  const [fields, setFields] = useState(initialFields)
  const [attachmentUploadingProcess, setAttachmentUploadingProcess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    clear()
  }, [ticket])

  const attachmentsRef = useRef()

  const clear = () => {
    setFields(initialFields)
    setAttachmentUploadingProcess(false)
    setIsLoading(false)
    setErrors({})

    if (attachmentsRef.current) {
      attachmentsRef.current.clear()
    }
  }

  const rules = {
    body: value =>
      validate(value, value =>
        !value && !fields.attachments.length ? 'This field is required if attachments is empty' : '',
      ),
  }

  const handleChangeField = field => {
    const { name, value } = field

    setFields({
      ...fields,
      [name]: value,
    })

    const rule = rules[name]
    if (rule) {
      setErrors({
        ...errors,
        [name]: rule(value),
      })
    }
  }

  const handleChangeBody = e => {
    let { value } = e.target
    if (value.length > MAX_BODY_LENGTH) {
      value = value.slice(0, MAX_BODY_LENGTH)
    }
    handleChangeField({ name: 'body', value })
  }

  const handleStartAttachmentUpload = () => {
    setAttachmentUploadingProcess(true)
  }

  const handleEndAttachmentUpload = () => {
    setAttachmentUploadingProcess(false)
  }

  const handleAttachmentUploaded = attachment => {
    const newAttachments = fields.attachments.slice()
    newAttachments.push(attachment)
    handleChangeField({ name: 'attachments', value: newAttachments })

    handleEndAttachmentUpload()
  }

  const handleRemoveAttachment = attachment => {
    const newAttachments = fields.attachments.filter(item => item.id !== attachment.id)
    handleChangeField({ name: 'attachments', value: newAttachments })
  }

  const handleSubmit = () => {
    if ((fields.body || fields.attachments.length) && !isLoading) {
      if (onSubmit) {
        onSubmit()
      }

      setIsLoading(true)

      replyMessage(ticket.id, {
        ...fields,
        attachments: fields.attachments.map(item => item.id),
      })
        .then(response => {
          if (onSent) {
            onSent(response.data)
          }
          clear()
        })
        .catch(error => {
          if (onError) {
            onError(error.response)
          }

          const parsedError = parseMessages(error)
          if (parsedError.status === 422) {
            const newErrors = { ...errors }
            validationFields.forEach(field => {
              if (parsedError.validationMessages[field]) {
                newErrors[field] = validate(null, () => parsedError.validationMessages[field])
              }
            })

            setErrors(newErrors)
          } else {
            simpleNotify('error', 'Whoops, something went wrong')
          }
        })
        .finally(() => setIsLoading(false))
    }
  }

  const renderPreloader = !!isLoading && (
    <div className='ticket-reply__preloader'>
      <Preloader />
    </div>
  )

  return (
    <div className='ticket__reply'>
      {renderPreloader}
      <div className='ticket-reply__title'>Reply:</div>
      <div className='ticket-reply__body'>
        <TextField
          fullWidth={true}
          variant='outlined'
          multiline
          rows={3}
          placeholder='Type in your message'
          value={fields.body}
          onChange={handleChangeBody}
          error={errors.body && errors.body.error}
          helperText={errors.body && errors.body.error && errors.body.message}
        />
      </div>
      <div className='ticket-reply__footer'>
        <AttachmentsUploader
          ref={attachmentsRef}
          onStartUpload={handleStartAttachmentUpload}
          onUploaded={handleAttachmentUploaded}
          onError={handleEndAttachmentUpload}
          onRemove={handleRemoveAttachment}
        />
        <div className='ticket-reply-submit__wrapper'>
          <button
            className='btn btn-custom-info ml-2'
            disabled={(!fields.body && !fields.attachments.length) || attachmentUploadingProcess}
            onClick={handleSubmit}
          >
            Reply
          </button>
        </div>
      </div>
    </div>
  )
}

export default Reply
