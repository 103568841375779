export const cssStyles = `
  .error{
    color: red;
    border-color:red;
  }

  label{
    font-family: sans-serif;
    font-size:12px;
    display: inline-block;
    margin-bottom: 5px;
  }

  input{
    height: 28px;
    font-size: 14px;
    outline: none;
    // border: 1px solid #000;
    // border-radius: 4px;
    // border-color: #a2a2a2;
    color: #000000b3;
    padding: 0 5px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
  }

  body{
    margin:0px;
    height:100%
  }

  select{
    height:18px;
  }

  #ccexpiryfieldmonth{
    width:27px;
    margin-right: 5px!important;
  }

  #ccexpiryfieldyear{
    width:42px;
    margin-left: 5px!important;
  }

  #ccnumfield{
    width: 298px;
    margin-bottom: 25px;
  }

  #cccvvfield{
    display:block;
    margin-left: 242px;
    margin-top: -19px;
    width:55px
  }

  #cccvvlabel{
    display:block;
    margin-left: 242px;
    margin-top: -49px;
  }
`
