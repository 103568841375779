import SettingsRow from 'components/UI/SettingsRow'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { Select, TextField } from 'components/UIKit'
import { useState } from 'react'
import { Button, Popconfirm } from 'antd'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { formatStringToNumber } from 'helpers/numbers'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  selectProject,
  selectProjectSettings,
  selectProjectTaxAndFeesSlice,
  selectProjectTotalAfterDiscounts,
  selectTaxableTotal,
} from '../projectSelectors'
import { priceToView } from 'helpers/thousandSeparator'
import { round } from 'helpers/Math'
import { ProjectTax, TaxChargeType, TaxRateSource } from '../types'
import { useTaxRateByZip } from './hooks'
import { Error } from 'types/Error'
import { selectPrimaryProjectProperty } from '../slices/projectContactsSlice'

const INITIAL_TAX = {
  name: 'Tax',
  type: TaxChargeType.MATERIALS,
  rate: 0,
  rate_source: TaxRateSource['Custom for this Indusctry'],
  total: 0,
} as ProjectTax

interface Props {
  data: ProjectTax | null
  disabled: boolean
  error: Error | null
  onSave: (tax: ProjectTax) => void
  onClose: () => void
  onDelete: () => void
  onChange: (e: { target: { name: string; value: unknown } }) => void
}

export const TaxForm = ({
  data,
  disabled,
  error,
  onClose,
  onSave,
  onDelete,
  onChange,
}: Props) => {
  const project = useAppSelector(selectProject)
  const projectSettings = useAppSelector(selectProjectSettings)
  const primaryProperty = useAppSelector(selectPrimaryProjectProperty)
  const { status } = useAppSelector(selectProjectTaxAndFeesSlice)
  const taxableItemsTotal = useAppSelector(selectTaxableTotal)
  const totalAfterDiscounts = useAppSelector(selectProjectTotalAfterDiscounts)

  const [tax, setTax] = useState(
    data ?? { ...INITIAL_TAX, rate: projectSettings.default_rate },
  )

  // const primaryProperty =
  //   project.properties.find(property => property?.is_primary) ||
  //   project?.properties?.[0]

  const { rateByZIP, loading } = useTaxRateByZip(primaryProperty)

  // useEffect(() => {
  //   if (
  //     tax.rate_source === TaxRateSource['Based on ZIP'] &&
  //     (!primaryProperty || (rateByZIP && rateByZIP !== tax.rate))
  //   ) {
  //     setTax(prev => ({
  //       ...prev,
  //       rate_source: TaxRateSource['Custom %'],
  //     }))
  //   }
  // }, [rateByZIP])

  const handleTaxChange = (e: { target: { name: string; value: any } }) => {
    const { name, value } = e.target

    setTax(prev => ({
      ...prev,
      [name]: value,
    }))

    onChange?.(e)
  }

  const handleTaxSourceChange = (e: { target: { value: TaxRateSource } }) => {
    const { value } = e.target

    setTax(prev => ({
      ...prev,
      rate_source: value,
      rate:
        value === TaxRateSource['Based on ZIP']
          ? rateByZIP
          : project?.default_rate || 0,
    }))
  }

  const handleZeroFieldFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e?.target
    if (tax?.[name as keyof ProjectTax] === 0)
      setTax(prev => ({
        ...prev,
        [name]: '',
      }))
  }

  const TAX_TOTAL =
    tax.type === TaxChargeType.MATERIALS
      ? taxableItemsTotal
      : totalAfterDiscounts

  const TAX_SOURCE_OPTIONS = [
    {
      value: TaxRateSource['Based on ZIP'],
      label: 'Based on ZIP',
      disabled: !primaryProperty || !rateByZIP,
    },
    {
      value: TaxRateSource['Custom for this Indusctry'],
      label: 'Custom for this Industry',
    },
    {
      value: TaxRateSource['Custom %'],
      label: 'Custom %',
    },
  ]

  return (
    <Modal
      open
      title={tax.id ? 'Edit Tax' : 'Add Tax'}
      onCancel={onClose}
      width={450}
      footer={
        <Buttons>
          {tax.id && (
            <Popconfirm title='Delete tax?' onConfirm={onDelete}>
              <Button style={{ borderColor: 'red', color: '#000' }}>
                Delete Tax
              </Button>
            </Popconfirm>
          )}
          <Button style={{ marginLeft: 'auto' }} onClick={onClose}>
            Cancel
          </Button>
          <Button
            type='primary'
            onClick={() => onSave(tax)}
            loading={status === 'loading'}
          >
            Save
          </Button>
        </Buttons>
      }
    >
      <SettingsRow width={150} title='Label'>
        <TextField
          name='name'
          value={tax.name}
          onChange={handleTaxChange}
          error={error?.name}
        />
      </SettingsRow>
      <SettingsRow width={150} title='Charge Tax'>
        <div>
          <Select
            name='type'
            value={tax.type}
            options={TAX_TYPE_OPTIONS}
            showSearch={false}
            onChange={handleTaxChange}
          />
          {tax.type !== TaxChargeType.CUSTOM && (
            <TaxableAmount>
              Taxable amount: <span>{priceToView(TAX_TOTAL)}</span>
            </TaxableAmount>
          )}
        </div>
      </SettingsRow>
      {tax.type !== TaxChargeType.CUSTOM && (
        <>
          <SettingsRow width={150} title='Tax Type' description=''>
            <Select
              name='source'
              value={tax.rate_source}
              options={TAX_SOURCE_OPTIONS}
              showSearch={false}
              onChange={handleTaxSourceChange}
              loading={
                tax.rate_source === TaxRateSource['Based on ZIP'] && loading
              }
            />
          </SettingsRow>

          <SettingsRow width={150} title='Tax Rate'>
            <NumberFormat
              name='rate'
              thousandSeparator
              decimalScale={3}
              customInput={TextField}
              value={tax?.rate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTaxChange({
                  target: {
                    name: 'rate',
                    value: formatStringToNumber(e, false),
                  },
                })
              }
              onBlur={() => {
                handleTaxChange({
                  target: {
                    name: 'rate',
                    value: tax?.rate || 0,
                  },
                })
              }}
              onFocus={handleZeroFieldFocus}
              suffix='%'
              disabled={
                tax.rate_source === TaxRateSource['Based on ZIP'] ||
                tax.rate_source ===
                  TaxRateSource['Custom for this Indusctry'] ||
                disabled
              }
            />
          </SettingsRow>
        </>
      )}
      <SettingsRow width={150} title='Tax Total'>
        <NumberFormat
          name='total'
          thousandSeparator
          decimalScale={2}
          customInput={TextField}
          value={
            tax.type === TaxChargeType.CUSTOM
              ? tax.total
              : round(TAX_TOTAL * (tax.rate / 100))
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTaxChange({
              target: {
                name: 'total',
                value: formatStringToNumber(e, false),
              },
            })
          }
          onBlur={() => {
            handleTaxChange({
              target: {
                name: 'total',
                value: tax?.total || 0,
              },
            })
          }}
          onFocus={handleZeroFieldFocus}
          prefix='$'
          disabled={tax.type !== TaxChargeType.CUSTOM || disabled}
        />
      </SettingsRow>
    </Modal>
  )
}

const Buttons = styled.div`
  display: flex;
  gap: 10;
`

const TaxableAmount = styled.p`
  font-weight: 400;
  margin-top: 5px;
  font-size: 0.8rem;
  color: grey;

  span {
    font-weight: 500;
  }
`

const TAX_TYPE_OPTIONS = [
  {
    value: TaxChargeType.MATERIALS,
    label: 'On Taxable Items',
  },
  {
    value: TaxChargeType.INVESTMENT,
    label: 'On Total after Discounts',
  },
  {
    value: TaxChargeType.CUSTOM,
    label: 'Custom',
  },
]
