import InputField from 'containers/MainContent/Orcatec/components/Input'
import { priceToView } from 'helpers/thousandSeparator'
import { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { Content, Heading, Percent, Price, TipsItem, Wrapper } from './Tips.style'

const tipsOptions = [
  { name: '20%', value: 20 },
  { name: '18%', value: 18 },
  { name: '15%', value: 15 },
  { name: 'Custom Amount', value: 0 },
  { name: 'No Tips', value: 0 },
]

interface Props {
  amount: number
  onOptionChose: ({ name, value }: { name: string; value: number }) => void
  choosenTips: string
}

export const Tips = ({ amount = 0, onOptionChose, choosenTips }: Props) => {
  const [choosenOption, setChooseOption] = useState('No Tips')

  useEffect(() => {
    if (choosenTips) setChooseOption(choosenTips)
  }, [])

  const handleClickTipItem = (name: string, price: number) => {
    if (name === choosenOption && name !== 'No Tips') return

    setChooseOption(name)
    onOptionChose?.({ name, value: price })
  }

  const renderItem = (item: { name: string; value: number }) => (
    <>
      {item.name === 'Custom Amount' ? (
        choosenOption === 'Custom Amount' && (
          <NumberFormat
            defaultValue={0}
            autoFocus
            thousandSeparator
            onValueChange={values => onOptionChose?.({ name: 'Custom Amount', value: +values.value })}
            customInput={InputField}
            prefix='$'
          />
        )
      ) : (
        <Price>
          <strong>{!!item.value && priceToView((amount * item.value) / 100)}</strong>
        </Price>
      )}

      <Percent>{item.name}</Percent>
    </>
  )

  return (
    <Wrapper>
      <Heading className='tips-header'>Add a tip?</Heading>

      <Content className='tip-buttons-container'>
        {tipsOptions.map(item => (
          <TipsItem
            key={item.name}
            active={item.name === choosenOption}
            onClick={() => handleClickTipItem(item.name, (amount * item.value) / 100)}
          >
            {renderItem(item)}
          </TipsItem>
        ))}
      </Content>
    </Wrapper>
  )
}
