import { AccountingPermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'

export const accounting = {
  accounting_can_read: {
    name: 'Accounting Table',
    control_type: UniteType.THIRD_VIEW,
    description: 'Views accounting-related tables',
    with_access_scope: false,
    with_access_checkbox: false,
    permission_key: IKey.ACCOUNTING_CAN_READ,
    related_key: [
      IKey.ACCOUNTING_CAN_ADD_PAYOUTS,
      IKey.ACCOUNTING_CAN_EXPORT_TABLE,
    ],
    is_module: true,
    children: [
      {
        name: 'View Accounting Table',
        permission_key: IKey.ACCOUNTING_CAN_READ,
        with_access_scope: false,
        with_access_checkbox: true,
        related_key: [
          IKey.ACCOUNTING_CAN_ADD_PAYOUTS,
          IKey.ACCOUNTING_CAN_EXPORT_TABLE,
        ],
      },
      {
        name: 'Add Payouts',
        permission_key: IKey.ACCOUNTING_CAN_ADD_PAYOUTS,
        with_access_scope: false,
        with_access_checkbox: true,
        parent_permission_key: IKey.ACCOUNTING_CAN_READ,
      },
      {
        name: 'Export Table',
        permission_key: IKey.ACCOUNTING_CAN_EXPORT_TABLE,
        with_access_scope: false,
        with_access_checkbox: true,
        parent_permission_key: IKey.ACCOUNTING_CAN_READ,
      },
    ],
  },
  accounting_can_read_balance_table: {
    name: 'Balance Table',
    control_type: UniteType.THIRD_VIEW,
    permission_key: IKey.ACCOUNTING_CAN_READ_BALANCE_TABLE,
    description: 'Views balance-related tables.',
    with_access_scope: false,
    with_access_checkbox: false,
    related_key: [],
    children: [
      {
        name: 'View Balance table',
        permission_key: IKey.ACCOUNTING_CAN_READ_BALANCE_TABLE,
        with_access_scope: false,
        with_access_checkbox: true,
      },
    ],
  },
  accounting_can_read_expenses_table: {
    name: 'Expenses table',
    control_type: UniteType.THIRD_VIEW,
    permission_key: IKey.ACCOUNTING_CAN_READ_EXPENSES_TABLE,
    description: 'Views expenses-related tables.',
    with_access_scope: false,
    with_access_checkbox: false,
    related_key: [],
    children: [
      {
        name: 'View Expenses Table',
        permission_key: IKey.ACCOUNTING_CAN_READ_EXPENSES_TABLE,
        with_access_scope: false,
        with_access_checkbox: true,
      },
    ],
  },
  accounting_can_read_entries_table: {
    name: 'Work progress table',
    control_type: UniteType.THIRD_VIEW,
    permission_key: IKey.ACCOUNTING_CAN_READ_WORK_PROGRESS_TABLE,
    description: 'Views work progress-related tables.',
    with_access_scope: false,
    with_access_checkbox: false,
    related_key: [],
    children: [
      {
        name: 'View Work Progress Table',
        permission_key: IKey.ACCOUNTING_CAN_READ_WORK_PROGRESS_TABLE,
        with_access_scope: false,
        with_access_checkbox: true,
      },
    ],
  },
}
