import styled from 'styled-components'
import { ScrollAreaPosition } from './ScrollAreas'

export const Wrapper = styled.div`
  position: relative;
`
interface ScrollAreaProps {
  position: ScrollAreaPosition
  show: boolean
  areaSize: number
  disablePointerEvents?: boolean
}
export const ScrollArea = styled.div<ScrollAreaProps>(
  ({ position, show, areaSize, disablePointerEvents }) => `
  position: absolute;
  z-index: 1;
  pointer-events: ${disablePointerEvents ? 'none' : 'auto'};
  width: ${position === 'left' || position === 'right' ? areaSize + 'px' : '100%'};
  height: ${position === 'top' || position === 'bottom' ? areaSize + 'px' : '100%'};
  box-shadow: ${
    position === 'left'
      ? 'inset 15px 0 8px -8px rgb(0 0 0 / 15%)'
      : position === 'right'
      ? 'inset -15px 0 8px -8px rgb(0 0 0 / 15%)'
      : position === 'top'
      ? 'inset 0 15px 8px -8px rgb(0 0 0 / 15%)'
      : 'inset 0 -15px 8px -8px rgb(0 0 0 / 15%)'
  };
  display: ${show ? 'block' : 'none'};
  top: ${position === 'bottom' ? 'unset' : '8px'};
  bottom: ${position === 'top' ? 'unset' : '8px'};
  left: ${position === 'right' ? 'unset' : '8px'};
  right: ${position === 'left' ? 'unset' : '8px'};
`,
)
