import * as types from '../../types'
import { modifyFiles } from './modifyFiles'

const initialState = {
  show: false,
  show_property_modal: false,
  files: [],
  file_type: '',
  section: 0,
  item_type: '',
  item_num: 0,
  filesToDelete: [],
  progress: {
    // format will be like below
    // 1: {  --> this interpreted as uploaded file #1
    //   id: 1,
    //   file,
    //   progress: 0,
    // }
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.proposalModalFiles.SET_UPLOAD_FILES:
      return {
        ...state,
        progress: {
          ...state.progress,
          ...modifyFiles(Object.keys(state.progress), action.payload),
        },
      }
    case types.proposalModalFiles.PROPOSAL_CLEAR_FILE_IN_PROGRESS:
      !!Object.values(state.progress)?.length &&
        Object.values(state.progress)?.map(el => {
          if (el.file.lastModified === action.payload) {
            return delete state.progress[el.id]
          }
        })
      return {
        ...state,
        progress: {
          ...state.progress,
        },
      }
    case types.proposalModalFiles.SET_UPLOAD_PROGRESS:
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.payload.id]: {
            ...state.progress[action.payload.id],
            progress: action.payload.progress,
          },
        },
      }

    case types.proposalModalFiles.SUCCESS_UPLOAD_FILE:
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.payload]: {
            ...state.progress[action.payload],
            status: 1,
          },
        },
      }

    case types.proposalModalFiles.FAILURE_UPLOAD_FILE:
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.payload]: {
            ...state.progress[action.payload],
            status: 0,
            progress: 0,
          },
        },
      }
    /* case 'SET_UPLOAD_PROGRESS':

      return {
        ...state,
        progress: action.payload
      } */

    case types.proposalModalFiles.PROPOSAL_PROPERTY_TOGGLE_MODAL:
      return state.show_property_modal
        ? initialState
        : {
            ...state,
            show_property_modal: true,
            show: false,
            ...action.payload,
          }

    case types.proposalModalFiles.PROPOSAL_TOGGLE_MODAL:
      return state.show
        ? initialState
        : {
            ...state,
            show: true,
            show_property_modal: false,
            ...action.payload,
          }

    case types.proposalForm.PROPOSAL_TAB_FILE_UPLOAD:
    case types.proposalForm.SECTION_ITEM_FILE_UPLOAD:
    case types.proposalForm.PROPOSAL_FORM_FILE_UPLOAD:
    case types.proposalForm.SECTION_ITEM_OPTION_FILE_UPLOAD:
    case types.proposalModalFiles.PROPOSAL_ADD_NEW_ITEM_FILE:
    case types.proposalModalFiles.ACCOUNTING_ADD_NEW_ITEM_FILE:
      // return { ...state, files: [...state.files, ...action.payload] }
      return { ...state, files: action.payload }
    case types.proposalModalFiles.PROPOSAL_CUSTOMER_FILE_UPLOAD:
      // return { ...state, files_from_customer: [...state.files_from_customer, ...action.payload] }
      return { ...state, files_from_customer: action.payload }
    case types.proposalForm.PROPOSAL_TAB_FILE_DELETE:
    case types.proposalForm.SECTION_ITEM_FILE_DELETE:
    case types.proposalForm.PROPOSAL_FORM_FILE_DELETE:
    case types.proposalForm.SECTION_ITEM_OPTION_FILE_DELETE:
    case types.proposalModalFiles.PROPOSAL_DELETE_NEW_ITEM_FILE: {
      return state.show ? { ...state, files: state.files.filter((_, idx) => idx !== action.index) } : state
    }
    case types.proposalModalFiles.PROPOSAL_CUSTOMER_FILE_DELETE: {
      return state.show
        ? { ...state, files_from_customer: state.files_from_customer.filter((_, idx) => idx !== action.index) }
        : state
    }
    default:
      return state
  }
}
