import * as types from '../../types'
import * as api from '../../../../api/ProposalContact'
import * as actions from 'store/Orcatec/actions'
import { updatePrimaryProperty, updateProposal } from './proposalForm'
import {
  formatTableDataProperties,
  formatTableDataClients,
  formatTableDataOrganizations,
} from 'containers/MainContent/Orcatec/Proposals/components/Form/ProposalContact/utils'

// PROPERTY
export const fetchProposalPropertyByAddress = address => async () => {
  const res = await api.searchProposalProperties(address)
}

export const getAllProposalProperties = (
  id,
  handleAction,
  isNeedFetchProposal = true,
) => async dispatch => {
  const res = await api.getAllProposalProperties(id)
  dispatch({
    type: types.proposalContact.PROPOSAL_PROPERTY_SET_ATTACHED_ITEM,
    payload: res,
  })
  handleAction && handleAction()
  isNeedFetchProposal && dispatch(actions.proposalForm.initProposalForm(id, ''))
}

export const fetchProposalPropertyById = id => async dispatch => {
  const res = await api.fetchProposalProperties(id)
  dispatch({
    type: types.proposalContact.PROPOSAL_PROPERTY_SET_CURRENT_ITEM,
    payload: res,
  })
}

export const attachProposalPropertyById = (
  proposalId,
  propertyId,
  handleAction,
) => async dispatch => {
  const attachedProperty = await api.putProposalPropertiesAttach(
    proposalId,
    propertyId,
  )

  if (attachedProperty?.clients?.length) {
    dispatch(
      actions.proposalContact.saveProposalProperty(
        proposalId,
        formatTableDataProperties([attachedProperty], true),
      ),
    )
  } else if (attachedProperty?.organizations?.length) {
    dispatch(
      actions.proposalContact.saveProposalProperty(
        proposalId,
        formatTableDataProperties([attachedProperty], true),
      ),
    )
  }

  dispatch(getAllProposalProperties(proposalId, handleAction('attach')))
}

export const detachProposalPropertyById = (
  proposalId,
  data,
  handleAction,
) => async dispatch => {
  const res = await api.putProposalPropertiesDetach(proposalId, data)
  dispatch(getAllProposalProperties(proposalId, handleAction('detach')))
}

export const saveProposalProperty = (
  proposalId,
  data,
  handleAction,
) => async dispatch => {
  const res = await api.postProposalPropertiesSave(proposalId, data)
  dispatch(
    actions.proposalForm.initProposalForm(
      proposalId,
      '',
      handleAction?.('save'),
    ),
  )
  dispatch(getAllProposalProperties(proposalId, null, false))
}

export const clearAttachedProposalProperties = () => ({
  type: types.proposalContact.PROPOSAL_PROPERTY_CLEAR_ATTACHED_ITEM,
})

export const removeProposalPropertyCurrentItem = () => ({
  type: types.proposalContact.PROPOSAL_PROPERTY_REMOVE_CURRENT_ITEM,
})

export const setProposalPropertiesPosition = (
  data,
  handleSave,
) => async dispatch => {
  await api.postProposalPropertiesSetPosition(data)

  handleSave?.()

  const primaryPropertyRelationId = data?.data?.find(d => d.is_primary)
    ?.property_relation_id

  if (!primaryPropertyRelationId) return
  dispatch(updatePrimaryProperty(primaryPropertyRelationId))
}

//CLIENTS

export const getAllProposalClients = (
  id,
  handleAction,
  isNeedFetchProposal = true,
) => async dispatch => {
  const clients = await api.getAllProposalClients(id)
  const organizations = await api.getAllProposalOrganizations(id)

  handleAction && handleAction()

  dispatch(updateProposal())

  dispatch({
    type: types.proposalContact.PROPOSAL_CLIENTS_SET_ATTACHED_ITEM,
    payload: [...clients, ...organizations],
  })
  isNeedFetchProposal && dispatch(actions.proposalForm.initProposalForm(id, ''))
}

export const clearAttachedProposalClients = () => ({
  type: types.proposalContact.PROPOSAL_CLIENTS_CLEAR_ATTACHED_ITEM,
})

export const attachProposalClientById = (
  proposalId,
  clientId,
  handleAction,
) => async dispatch => {
  const attachedClient = await api.putProposalClientAttach(proposalId, clientId)

  if (attachedClient?.properties?.length) {
    dispatch(
      actions.proposalContact.saveProposalClient(
        proposalId,
        formatTableDataClients([attachedClient], true) /* handleAction */,
      ),
    )
  }

  dispatch(getAllProposalClients(proposalId, handleAction('attach')))
}

export const attachProposalOrganizationById = (
  proposalId,
  clientId,
  handleAction,
) => async dispatch => {
  const attachedOrganization = await api.putProposalOrganizationAttach(
    proposalId,
    clientId,
  )

  if (attachedOrganization?.properties?.length) {
    dispatch(
      actions.proposalContact.saveProposalOrganizations(
        proposalId,
        formatTableDataOrganizations([attachedOrganization], true),
        // handleSave,
      ),
    )
  }

  dispatch(getAllProposalClients(proposalId, handleAction('attach')))
}

export const detachProposalClientById = (
  proposalId,
  data,
  handleAction,
) => async dispatch => {
  const res = await api.putProposalClientsDetach(proposalId, data)
  dispatch(getAllProposalClients(proposalId, handleAction('detach')))
}

export const detachProposalOrganizationById = (
  proposalId,
  data,
  handleAction,
) => async dispatch => {
  const res = await api.putProposalOrganizationsDetach(proposalId, data)
  dispatch(getAllProposalClients(proposalId, handleAction('detach')))
}

export const saveProposalClient = (
  proposalId,
  data,
  handleAction,
) => async dispatch => {
  await api.postProposalClientSave(proposalId, data)
  dispatch(getAllProposalClients(proposalId, null, false))
  dispatch(
    actions.proposalForm.initProposalForm(
      proposalId,
      '',
      handleAction?.('save'),
    ),
  )
}

export const saveProposalOrganizations = (
  proposalId,
  data,
  handleAction,
) => async dispatch => {
  await api.postProposalOrganizationsSave(proposalId, data)
  dispatch(getAllProposalClients(proposalId, null, false))
  dispatch(
    actions.proposalForm.initProposalForm(
      proposalId,
      '',
      handleAction?.('save'),
    ),
  )
}

export const setProposalClientsPosition = (
  data,
  handleSave,
) => async dispatch => {
  await api.postProposalClientsSetPosition(data)
  handleSave && handleSave()
}

//ORGANIZATIONS

export const getAllProposalOrganizations = (
  id,
  handleAttach,
  handleDetach,
) => async dispatch => {
  const res = await api.getAllProposalOrganizations(id)
  handleAttach && handleAttach('attach', 'organization')
  handleDetach && handleDetach('detach', 'property')

  dispatch({
    type: types.proposalContact.PROPOSAL_CLIENTS_SET_ATTACHED_ITEM,
    payload: res,
  })
  dispatch(actions.proposalForm.initProposalForm(id, ''))
}

export const clearAttachedProposalOrganizations = () => ({
  type: types.proposalContact.PROPOSAL_ORGANIZATIONS_CLEAR_ATTACHED_ITEM,
})
