import * as types from '../types'

const initialState = {
  currentProperty: {},
  attachedProperty: [],
  attachedClients: [],
  attachedOrganizations: [],
  isOpen: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // PROPERTIES
    case types.proposalContact.PROPOSAL_PROPERTY_SET_CURRENT_ITEM: {
      return { ...state, currentProperty: payload }
    }
    case types.proposalContact.PROPOSAL_PROPERTY_REMOVE_CURRENT_ITEM: {
      return { ...state, currentProperty: {} }
    }
    case types.proposalContact.PROPOSAL_PROPERTY_SET_ATTACHED_ITEM: {
      return { ...state, attachedProperty: payload, isOpen: true }
    }
    case types.proposalContact.PROPOSAL_PROPERTY_REMOVE_ATTACHED_ITEM: {
      return { ...state, attachedProperty: [...state.attachedProperty, payload] }
    }
    case types.proposalContact.PROPOSAL_PROPERTY_CLEAR_ATTACHED_ITEM: {
      return { ...state, attachedProperty: [] }
    }
    //  CLIENTS
    case types.proposalContact.PROPOSAL_CLIENTS_SET_ATTACHED_ITEM: {
      return { ...state, attachedClients: payload }
    }
    case types.proposalContact.PROPOSAL_CLIENTS_CLEAR_ATTACHED_ITEM: {
      return { ...state, attachedClients: [] }
    }
    // ORGANIZATIONS
    case types.proposalContact.PROPOSAL_ORGANIZATIONS_SET_ATTACHED_ITEM: {
      return { ...state, attachedOrganizations: payload }
    }
    case types.proposalContact.PROPOSAL_ORGANIZATIONS_CLEAR_ATTACHED_ITEM: {
      return { ...state, attachedOrganizations: [] }
    }
    default:
      return state
  }
}
