import React from 'react'
import './EmployeeTimedMarkerPopap.scss'
import PopapWrapper from '../PopapWrapper'

const EmployeeTimedMarkerPopap = props => {
  return (
    <PopapWrapper className={props.className}>
      <div className='row d-flex justify-content-between mx-0'>
        <span>
          <strong>{props.employee.full_name}</strong>
        </span>
        <span>{props.employee.title}</span>
      </div>
      <div className='row d-flex justify-content-between mx-0'>
        <span>{`Clocked-${props.type}`}</span>
        <span>
          <strong>{props.time}</strong>
        </span>
      </div>
      {!props.withoutDuration ? (
        <div className='row d-flex justify-content-between mx-0'>
          <div className='col-6 p-0'>{`Clocked-${props.type} duration`}</div>
          <span>
            <strong>{props.duration}</strong> min
          </span>
        </div>
      ) : (
        ''
      )}
    </PopapWrapper>
  )
}

export default EmployeeTimedMarkerPopap
