import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  // getClientsForNotificationModal,
  UpdateColumnBody,
  updateColumn,
} from '../../../api/Matrix-log'
import { openNotificationWithIcon } from '../../../helpers/notifications/openNotificationWithIcon'

import {
  createAppointment,
  dublicateAppointment,
  deleteAppointment,
} from 'api/Appointment'
import {
  Box,
  Title,
  Time,
  Event,
  Timeoff,
  Created,
} from '../../../containers/MainContent/Orcatec/Matrix/components/calendarV2'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import {
  SureModal,
  TimeModal,
  DateModal,
} from '../../../containers/MainContent/Orcatec/Matrix/components/calendarV2/modals'
import * as actions from 'store/Orcatec/actions'
import 'simplebar/dist/simplebar.min.css'
import { Tooltip, message } from 'antd'
// import Preloader from '../../../containers/MainContent/Orcatec/components/Preloader'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  createAppointmentData,
  // getTypeTo,
} from '../../../containers/MainContent/Orcatec/Matrix/helpers'
// import { updateTechOrder } from 'api/Matrix-log'
// import ToDoList from '../../../containers/MainContent/Orcatec/Matrix/components/calendar/components/resources/to-do-list'
import { Appointment } from '../../../containers/MainContent/Orcatec/Matrix/components/Appointment/Appointment'
import {
  createDispatchEvent,
  eventStatusUpdated,
  getMatrixLog,
  resetCreated,
  selectCurrentMatrixLog,
  selectMatrixLog,
  setMatrixEvent,
  updateMatrixEvent,
  updateColumnsOrder,
  selectColumnById,
  selectDispatchSettings,
  eventDeleted,
  selectEventsByDate,
  getQueueEvents,
  // selectAllQueueEvents,
} from 'features/Dispatch/dispatchSlice'
import { useAppSelector } from 'store/Orcatec/hooks'
import { EventKind, AppointmentStatusID } from 'types/Appointment'
import { duplicateJob, deleteJob } from 'api/Job'
import className from 'classnames'
import { setCurrentAppointmentId } from 'store/Orcatec/actions/appointment'
import { changeAppointmentStatus } from '../../../api/Appointment'
// import { Modal as RfRModal } from '../../../containers/MainContent/Orcatec/components/RequestForReviewModal'
// import { getMatrixLog, getMatrixQueues } from 'store/Orcatec/actions/matrix/matrixLog'
import QueueList from '../Queue'
// import { useQueryClient } from '@tanstack/react-query'
import ModalLogSheets from '../../../containers/MainContent/Orcatec/Matrix/components/ModalLogSheets/ModalLogSheets'
// import { useListenChangeQueue } from '../../../containers/MainContent/Orcatec/Matrix/listeners'
// import { QueueType } from '../../../containers/MainContent/Orcatec/Matrix/components/Queue/types'
import {
  Column,
  DispatchEvent,
  EventColorType,
  IEventContact,
} from 'features/Dispatch/types'
// import { TimeOff } from 'api/TimeOff'
// import { checkOverlap } from './helpers'
// import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import ColumnSettings from './components/ColumnSettings'
import AssigneeFilter from 'containers/MainContent/Orcatec/Matrix/components/AssigneeFilter'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import {
  NotificationTemplateType,
  NotificationModalType,
  NotificationTitleType,
} from 'features/Notification/types'
import { getNotificationSettings } from 'api/NotificationModal'

import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import CreateProposal from 'containers/MainContent/Orcatec/Proposals/components/Modals/CreateProposal'
import { useActiveTodo } from 'features/ToDo'
import useEventSettings from 'hooks/useEventSettings'
import { ClientType } from 'types/Client'
import Clients from 'containers/MainContent/Orcatec/Clients/components/Form/index'
import { Companies } from 'types/Company'
import { createProposal } from 'api/Proposal'
import {
  selectModulePermissions,
  selectUserPermissionsByModule,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  DispatchPermission,
  ModuleName,
} from 'features/Settings/UsersAndGroups'

import { PublicEventModal } from 'features/PublicEvent'
import { selectQueueSlice } from '../queueSlice'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'

export const BoxWrapper = React.forwardRef(
  (
    {
      maxTime,
      minTime,
      workerId,
      dragEventOver,
      dragEventLeave,
      startResizingBox,
      eventType,
      isSchedule,
    },
    ref,
  ) => {
    const time = new Array(maxTime - minTime).fill('')
    return time.map((time, i) => (
      <Box
        key={`box-${workerId}-${i}`}
        workerId={workerId}
        minTime={minTime}
        dragEventOver={dragEventOver}
        dragEventLeave={dragEventLeave}
        index={i}
        ref={ref}
        startResizingBox={startResizingBox}
        eventType={eventType}
        isSchedule={isSchedule}
      />
    ))
  },
)

BoxWrapper.displayName = 'BoxWrapper'

interface EventWrapperProps {
  events: DispatchEvent[]
  column: Column
  weekView: boolean
  isWorker: boolean
  loading: boolean
  boxHeight: number
  boxSize: {
    height: number
    width: number
  }
}

const EventWrapper = ({
  events,
  column,
  // getAmericanTime,
  dragEventEnd,
  dragEventStart,
  minTime,
  maxTime,
  // startDragging,
  currentAppointmentId,
  activeAppointment,
  isMobileDevise,
  weekView,
  isWorker,
  boxHeight,
  boxSize,
  onDublicate,
  onCreateProposal,
  isSchedule,
  onScheduleClick,
  onMapClick,
  activeEvent,
  onChangeActiveEvent,
  create,
  heandleCreateAppointment,
  headleDeleteAppointment,
  loading,
  onOpenAppointment,
  onSetEventData,
  eventType,
  handleDeleteAppointment,
  heandleChangeStatusAppointment,
  handleClickRfRModal,
  // getPropertyHeandle,
  onClearCreatedStore,
  tooltipLoading,
  isMap,
  handleOpenContactModal,
  handleOpenPublicEventModal,
  eventSettings,
  showPreloader,
  onDrop,
  dragEventOver,
  workerId,
  resourceid,
  dragEventLeave,
}: // setEvent,
EventWrapperProps) => {
  const [visible, changeVisible] = useState<{
    id: number | null
    visible: boolean
  }>({ id: null, visible: false })

  const { event_color_type } = useAppSelector(selectDispatchSettings)
  const getbBackgroundColor = (event: DispatchEvent) =>
    event_color_type === EventColorType.Column
      ? column.background_color || '#626ed4'
      : event_color_type === EventColorType.User
      ? column.technicians.find(tech => tech.user_id === column.main_tech_id)
          ?.background_color || '#626ed4'
      : event.background_color || '#626ed4'

  const handleVisibleChange = (visible: boolean, id: number) => {
    changeVisible({ id, visible: visible })
  }

  const eventGroups = events.reduce((groups, event, index) => {
    const overlappingGroupIndex = groups.findIndex(group => {
      return group.some(existingEvent => {
        return (
          existingEvent.current_start_time < event.current_end_time &&
          existingEvent.current_end_time > event.current_start_time
        )
      })
    })

    if (overlappingGroupIndex === -1) {
      groups.push([{ ...event, eventIndex: index }])
    } else {
      groups[overlappingGroupIndex].push({ ...event, eventIndex: index })
    }

    return groups
  }, [])

  return eventGroups.map((group, groupIndex) => {
    const eventCount = group.length

    const sortedEvents = group.sort((a, b) => {
      const durationA = a.current_end_time - a.current_start_time
      const durationB = b.current_end_time - b.current_start_time
      return durationB - durationA
    })

    return sortedEvents.map((event, index) => {
      let eventWidth
      let eventLeft
      if (index === 0 && eventCount !== 2) {
        eventWidth = 100
        eventLeft = 0
      } else if (eventCount === 2) {
        eventWidth = 50
        eventLeft = index === 0 ? 0 : 50
      } else {
        const remainingWidth = 100 - index * 10
        eventWidth = remainingWidth
        eventLeft = index * 10
      }

      //  if (index === 0) {
      //    eventWidth = 100
      //    eventLeft = 0
      //  } else {
      //    const remainingWidth = 100 - index * 10
      //    eventWidth = remainingWidth
      //    eventLeft = index * 10
      //  }

      return (
        <Event
          eventWidth={eventWidth}
          eventLeft={eventLeft}
          event={event}
          minTime={minTime}
          maxTime={maxTime}
          worker={column}
          key={event.id}
          index={index + groupIndex}
          visible={visible}
          changeVisible={changeVisible}
          backgroundColor={getbBackgroundColor(event)}
          handleVisibleChange={handleVisibleChange}
          // getAmericanTime={getAmericanTime}
          dragEventStart={dragEventStart}
          dragEventEnd={dragEventEnd}
          // startDragging={startDragging}
          currentAppointmentId={currentAppointmentId}
          activeAppointment={activeAppointment}
          isMobileDevise={isMobileDevise}
          weekView={weekView}
          isWorker={isWorker}
          boxHeight={boxHeight}
          boxSize={boxSize}
          onDublicate={onDublicate}
          onCreateProposal={onCreateProposal}
          isSchedule={isSchedule}
          onScheduleClick={onScheduleClick}
          onMapClick={onMapClick}
          activeEvent={activeEvent}
          onChangeActiveEvent={onChangeActiveEvent}
          create={create}
          heandleCreateAppointment={heandleCreateAppointment}
          headleDeleteAppointment={headleDeleteAppointment}
          loading={loading}
          onOpenAppointment={onOpenAppointment}
          onSetEventData={onSetEventData}
          eventType={eventType}
          handleDeleteAppointment={handleDeleteAppointment}
          heandleChangeStatusAppointment={heandleChangeStatusAppointment}
          handleClickRfRModal={handleClickRfRModal}
          onClearCreatedStore={onClearCreatedStore}
          tooltipLoading={tooltipLoading}
          isMap={isMap}
          handleOpenContactModal={handleOpenContactModal}
          handleOpenPublicEventModal={handleOpenPublicEventModal}
          onDrop={onDrop}
          dragEventOver={dragEventOver}
          workerId={workerId}
          resourceid={resourceid}
          dragEventLeave={dragEventLeave}
          showPreloader={showPreloader}
          eventSettings={eventSettings}
        />
      )
    })
  })

  // return events?.map((event, i) => (
  //   <Event
  //     event={event}
  //     minTime={minTime}
  //     maxTime={maxTime}
  //     worker={column}
  //     key={event.id}
  //     index={i}
  //     visible={visible}
  //     changeVisible={changeVisible}
  //     backgroundColor={getbBackgroundColor(event)}
  //     handleVisibleChange={handleVisibleChange}
  //     // getAmericanTime={getAmericanTime}
  //     dragEventStart={dragEventStart}
  //     dragEventEnd={dragEventEnd}
  //     // startDragging={startDragging}
  //     currentAppointmentId={currentAppointmentId}
  //     activeAppointment={activeAppointment}
  //     isMobileDevise={isMobileDevise}
  //     weekView={weekView}
  //     isWorker={isWorker}
  //     boxHeight={boxHeight}
  //     boxSize={boxSize}
  //     onDublicate={onDublicate}
  //     onCreateProposal={onCreateProposal}
  //     isSchedule={isSchedule}
  //     onScheduleClick={onScheduleClick}
  //     onMapClick={onMapClick}
  //     activeEvent={activeEvent}
  //     onChangeActiveEvent={onChangeActiveEvent}
  //     create={create}
  //     heandleCreateAppointment={heandleCreateAppointment}
  //     headleDeleteAppointment={headleDeleteAppointment}
  //     loading={loading}
  //     onOpenAppointment={onOpenAppointment}
  //     onSetEventData={onSetEventData}
  //     eventType={eventType}
  //     handleDeleteAppointment={handleDeleteAppointment}
  //     heandleChangeStatusAppointment={heandleChangeStatusAppointment}
  //     handleClickRfRModal={handleClickRfRModal}
  //     onClearCreatedStore={onClearCreatedStore}
  //     tooltipLoading={tooltipLoading}
  //     isMap={isMap}
  //     handleOpenContactModal={handleOpenContactModal}
  //     handleOpenPublicEventModal={handleOpenPublicEventModal}
  //     onDrop={onDrop}
  //     dragEventOver={dragEventOver}
  //     workerId={workerId}
  //     resourceid={resourceid}
  //   />
  // ))
}
const CreatedWrapper = ({
  events,
  worker,
  // getAmericanTime,
  dragEventEnd,
  dragEventStart,
  minTime,
  // startDragging,
  currentAppointmentId,
  activeAppointment,
  isMobileDevise,
  weekView,
  isWorker,
  boxHeight,
  boxSize,
  onDublicate,
  onCreateProposal,
  isSchedule,
  onScheduleClick,
  activeEvent,
  onChangeActiveEvent,
  create,
  heandleCreateAppointment,
  headleDeleteAppointment,
  loading,
  onOpenAppointment,
  onSetEventData,
  eventType,
  handleDeleteAppointment,
  heandleChangeStatusAppointment,
  handleClickRfRModal,
  // getPropertyHeandle,
  onDeleteCreatedEvent,
  onCreateCreatedEvent,
  tooltipLoading,
}) => {
  const [visible, changeVisible] = useState({ id: null, visible: false })

  const colorByTech = useAppSelector(
    state => state.orcatec.company.appointment_color_by_tech,
  )
  // const { event_color_type } = useAppSelector(selectDispatchSettings)

  const handleVisibleChange = (visible, id) => {
    changeVisible({ id: id, visible: visible })
  }
  return events?.map(
    (event, i) => (
      <Created
        event={event}
        minTime={minTime}
        worker={worker}
        key={
          worker.user_id === 'misc' ? `misc-${event.id}` : `used-${event.id}`
        }
        index={i}
        visible={visible}
        changeVisible={changeVisible}
        backgroundColor={
          colorByTech ? worker?.background_color : event?.background_color
        }
        handleVisibleChange={handleVisibleChange}
        // getAmericanTime={getAmericanTime}
        dragEventStart={dragEventStart}
        dragEventEnd={dragEventEnd}
        // startDragging={startDragging}
        currentAppointmentId={currentAppointmentId}
        activeAppointment={activeAppointment}
        isMobileDevise={isMobileDevise}
        weekView={weekView}
        isWorker={isWorker}
        boxHeight={boxHeight}
        boxSize={boxSize}
        onDublicate={onDublicate}
        onCreateProposal={onCreateProposal}
        isSchedule={isSchedule}
        onScheduleClick={onScheduleClick}
        activeEvent={activeEvent}
        onChangeActiveEvent={onChangeActiveEvent}
        create={create}
        heandleCreateAppointment={heandleCreateAppointment}
        headleDeleteAppointment={headleDeleteAppointment}
        loading={loading}
        onOpenAppointment={onOpenAppointment}
        onSetEventData={onSetEventData}
        eventType={eventType}
        handleDeleteAppointment={handleDeleteAppointment}
        heandleChangeStatusAppointment={heandleChangeStatusAppointment}
        handleClickRfRModal={handleClickRfRModal}
        // getPropertyHeandle={getPropertyHeandle}
        onDeleteCreatedEvent={onDeleteCreatedEvent}
        onCreateCreatedEvent={onCreateCreatedEvent}
        tooltipLoading={tooltipLoading}
      />
    ),
    // ),
  )
}

const Calendar = ({
  currentDate,
  timeoff,
  // reorderTechs,
  todo,
  me,
  // todo_lists,
  weekView = false,
  currentAppointmentId,
  activeAppointment,
  onDragEvent,
  isMobileDevise,
  isWorker,
  isSchedule,
  onScheduleClick,
  onMapClick,
  zoomLevel = 1,
  techColumn = true,
  filterTechnicians,
  // showMyQueue,
  clearMatrixEvents,
  setShowAppointment,
  showAppointment,
  isMap,
  showPreloader,
  heandlePrintModal,
  printModalVisible,
  setEvent = () => null,
  handleZoom,
}) => {
  const { filters } = useAppSelector(selectQueueSlice)

  const dictionary = useAppSelector(selectJobDictionary)

  // eslint-disable-next-line no-prototype-builtins
  const showUnassignedUsers = filters.hasOwnProperty('unassignedUsers')
    ? filters.unassignedUsers
    : true

  const dispatch = useDispatch()
  const [target, changeTarget] = useState([])
  const [draggingEvent, setDraggingEvent] = useState<DispatchEvent | null>(null)
  // const [eventSource, setEventSource] = useState('')
  const [modalPreferWorker, changeModalPreferWorker] = useState(false)
  const [params, changeParams] = useState<{
    type: string
    currentAppointment: DispatchEvent
    difference: number
  } | null>(null)
  const [selectTimeModal, toggleSelectTimeModal] = useState(false)
  const [confirmDateModal, toggleConfirmDateModal] = useState(false)
  const [appliedWorker, applyWorker] = useState(false)
  const [suggestionTime, changeSuggestionTime] = useState<number | null>(null)
  const [firstRender, changeFirstRender] = useState(true)
  const [today, setToday] = useState(moment())
  const [showTime, setShowTime] = useState(false)
  const [draggingData, setDraggingData] = useState({
    difference: 1,
    draggingPart: 1,
  })
  const [boxHeight, setBoxHeight] = useState(40)
  const [boxSize, setBoxSize] = useState({
    height: 40,
    width: 60,
  })
  const [activeEvent, setActiveEvent] = useState('')
  const [openContactModal, setOpenContactModal] = useState(false)
  const [
    activeEventContact,
    setActiveEventContact,
  ] = useState<IEventContact | null>({})
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const [appointment, setAppointment] = useState({})
  const boxRef = useRef<HTMLDivElement>(null)
  //Appointment from matrix
  // const [loading, setLoading] = useState(false)
  const [eventData, setEventData] = useState({})
  const eventSettings = useEventSettings()
  // const queryClient = useQueryClient()

  // const [
  //   clientsForNotificationModal,
  //   setClientsForNotificationModal,
  // ] = useState([])
  const [isShownRfRModal, setIsShownRfRModal] = useState(false)
  // const [appointmentId, setAppointmentId] = useState(null)
  const [changeVisible] = useState<{
    visible: boolean
    id: number | null
  }>({ id: null, visible: false })
  const [tooltipLoading, setTooltipLoading] = useState({
    project: false,
    dublicate: false,
  })
  const [projectEntity, setProjectEntity] = useState(null)

  // const [freeUsers, setFreeUsers] = useState<ITechnician[]>([])

  const [createdEventLoading, setCreatedEventLoading] = useState(false)
  const [
    showNotificationInRouteModal,
    setShowNotificationInRouteModal,
  ] = useState(false)
  const [
    showNotificationCanceledModal,
    setShowNotificationCanceledModal,
  ] = useState(false)
  const [columnId, setColumnId] = useState<number | null>(null)
  const [openPublicEventModal, setOpenPublicEventModal] = useState(false)
  const [publicEvent, setPublicEvent] = useState({})

  // const isShowUpcomingNotification = useAppSelector(
  //   isShowUpcomingNotificationSelector,
  // )
  // const isShowInRouteNotification = useSelector(
  //   isShowInRouteNotificationSelector,
  // )

  const date = Array.isArray(currentDate) ? currentDate?.[0] : currentDate

  const matrixLog = useAppSelector(state =>
    selectCurrentMatrixLog(state, moment(date).format('MM/DD/YYYY')),
  )
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.DISPATCH),
  )

  const modulePermissions = useAppSelector(selectModulePermissions())

  const company = useAppSelector(state => state.orcatec.company)
  const currentColumn = useAppSelector(selectColumnById(columnId))

  const matrixWidth = useAppSelector(state => state.orcatec.uiSlice.placeFree)
  const sideBarIsOpen = useAppSelector(
    state => state.orcatec.uiSlice.sidebarIsOpen,
  )
  const {
    settings: { start_hour: initialTimeStart, end_hour: initialTineEnd },
    appointments_created,
    freeTechs,
  } = useAppSelector(selectMatrixLog)

  const events = useAppSelector(selectEventsByDate(currentDate))
  // const queueEvents = useAppSelector(selectAllQueueEvents)
  const [todoList] = useActiveTodo(weekView)

  const minTime = matrixLog?.time_start || initialTimeStart
  const maxTime = matrixLog?.time_end || initialTineEnd
  const columns = matrixLog?.columns ? Object.values(matrixLog?.columns) : []

  const onCloseAppointment = () => {
    setShowAppointment?.(false)
    setEventData({})
  }
  const onOpenAppointment = () => {
    setShowAppointment?.(true)
  }

  const onSetEventData = data => {
    dispatch(setCurrentAppointmentId(data?.id))
    setEventData(data)
    setEvent?.(data)
  }
  const handleClickRfRModal = () => {
    setIsShownRfRModal(true)
    // getClientsForRfRModal(id)
    // setAppointmentId(id)
  }
  // const getClientsForRfRModal = id => {
  //   getClientsForNotificationModal(id)
  //     .then(r => setClientsForNotificationModal(r))
  //     .catch(e => console.error(e))
  // }
  const handleChangeRfRStatus = status => {
    setAppointment(p => ({ ...p, review_status: status }))
  }

  const handleOpenPublicEventModal = event => {
    setOpenPublicEventModal(true)
    setPublicEvent(event)
  }
  const handleClosePublicEventModal = () => {
    setOpenPublicEventModal(false)
    setPublicEvent({})
  }

  const industries = useAppSelector(state => state.orcatec.user.me.industries)

  const timeLineSettings = {
    blockHeight: boxHeight,
    headerHeight: boxHeight < 40 ? 27.5 : 77.5,
    marginBetveenBoxes: 5,
    minutesInHour: 60,
  }

  const resourceBottomScroll = useRef()

  // useEffect(() => {
  //   const fetchEventSettings = async () => {
  //     try {
  //       const res = await getEventSettings()
  //       setEventSettings(res)
  //     } catch (error) {
  //       console.error(error)
  //     }
  //   }
  //   fetchEventSettings()
  // }, [])
  useEffect(() => {
    changeFirstRender(false)
    const interval = window.setInterval(() => setToday(moment()), 60000)

    return () => {
      window.clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (boxRef.current) {
      const { offsetWidth, offsetHeight } = boxRef.current

      setBoxHeight(offsetHeight)
      setBoxSize({
        width: offsetWidth,
        height: offsetHeight,
      })
    }
  }, [boxRef])

  useEffect(() => {
    !firstRender && checkEvery()
  }, [params, appliedWorker])

  useEffect(() => {
    if (!params) return

    if (!firstRender && suggestionTime) {
      toggleSelectTimeModal(true)
    }

    return changeSuggestionTime('')
  }, [suggestionTime])

  const dragEventStart = (e, event: DispatchEvent) => {
    // if (isSchedule && !permissions?.schedule_can_change_event_frame) return

    document.body.classList.add('grabbing')
    const rect = e.target.getBoundingClientRect()
    const difference =
      event.current_end_time && event?.current_start_time
        ? event.current_end_time - event?.current_start_time
        : 1

    const draggingPart = Math.ceil(
      Number(e.clientY - rect.top) / (boxHeight * zoomLevel),
    )

    if (e.dataTransfer) {
      const format = 'text/html'
      const textData = e.dataTransfer.getData(format)
      if (textData) {
        if (textData.length > 20) {
          e.dataTransfer.clearData(format)
        } else {
          let reverseText = ''
          for (let i = 0; i < textData.length; i++) {
            reverseText += textData.charAt(textData.length - i - 1)
          }
          e.dataTransfer.setData(format, reverseText)
          e.target.style.cursor = 'grabbing'
          e.dataTransfer.setDragImage(e.target)
          e.dataTransfer.effectAllowed = 'copyMove'
        }
      }
    }
    setDraggingData({
      difference,
      draggingPart,
    })
    changeTarget(e.currentTarget.parentElement)
    setDraggingEvent(event)
    // setEventSource(source)

    onDragEvent?.(true)

    return true
  }

  const applyDrag = async () => {
    applyWorker(true)
    changeModalPreferWorker(false)
  }

  const applyDragSelectTime = async (time_start: string, time_end: string) => {
    if (!params) return

    const { type, currentAppointment, difference } = params
    currentAppointment.time_start = moment(time_start).format('HH:mm')
    currentAppointment.time_end = moment(time_end).format('HH:mm')

    changeParams({ type, currentAppointment, difference })
    checkEvery()
    toggleSelectTimeModal(false)
  }

  const checkEvery = () => {
    if (!params) return

    const { currentAppointment } = params
    if (currentAppointment) {
      if (
        currentAppointment.preferred_technician &&
        Number(currentAppointment.preferred_technician.id) !==
          Number(
            target.parentElement.parentElement.getAttribute('resourceid'),
          ) &&
        !events.some(
          item =>
            item.id === currentAppointment.id &&
            Number(item.column_template_id) ===
              Number(
                target.parentElement.parentElement.getAttribute('resourceid'),
              ),
        ) &&
        appliedWorker === false
      ) {
        changeModalPreferWorker(true)
      } else if (
        (!currentAppointment.time_start || !currentAppointment.time_end) &&
        eventSettings?.show_promise_time
      ) {
        changeSuggestionTime(
          `${
            currentAppointment.current_start_time > 24
              ? currentAppointment.current_start_time - 24
              : currentAppointment.current_start_time
          }:00`,
        )
      } else if (
        !!currentAppointment.date &&
        !moment(currentDate).isSame(moment(currentAppointment.date), 'day')
      ) {
        toggleConfirmDateModal(true)
      } else {
        finishDrop()
      }
    }
  }

  const finishDrop = async () => {
    if (!params) return

    const { currentAppointment, type } = params

    onDragEvent?.(false)

    changeParams(null)
    applyWorker(false)
    if (isMap || isSchedule) {
      showPreloader?.()
    }

    const res = await dispatch(
      updateMatrixEvent({
        event: {
          ...currentAppointment,
          time_start: !eventSettings?.show_promise_time
            ? moment(currentAppointment.current_start_time, 'HH').format(
                'HH:mm',
              )
            : currentAppointment.time_start,
          time_end: !eventSettings?.show_promise_time
            ? moment(currentAppointment.current_end_time, 'HH').format('HH:mm')
            : currentAppointment.time_end,
          updated_at: moment().format('MM/DD/YYYY HH:mm:ss'),
        },
        date: moment(currentDate).format('MM/DD/YYYY'),
      }),
    )

    if (type === 'calendar') return // return if event was already on calendar

    setAppointment({
      ...currentAppointment,
      time_start: !eventSettings?.show_promise_time
        ? moment(currentAppointment.current_start_time, 'HH').format('HH:mm')
        : currentAppointment.time_start,
      time_end: !eventSettings?.show_promise_time
        ? moment(currentAppointment.current_end_time, 'HH').format('HH:mm')
        : currentAppointment.time_end,
    })

    const notificationSettings = await getNotificationSettings(
      currentAppointment?.industry_id,
    )

    notificationSettings?.should_display_upcoming &&
      setShowNotificationModal(true)

    if (res instanceof Error) return setShowNotificationModal(false)
  }

  const applyDragDateModal = async () => {
    if (!params) return

    const { type, currentAppointment, difference } = params
    currentAppointment.date = moment(currentDate).format('MM/DD/YYYY')

    changeParams({ type, currentAppointment, difference })
    toggleConfirmDateModal(false)
  }

  //Drop Event Action
  const dropMiscOrUsed = (
    currentAppointment: DispatchEvent,
    difference: number,
    // type: string,
  ) => {
    const copy = { ...currentAppointment }
    if (
      target.className.includes('box') ||
      target.className.includes('box-overlay')
    ) {
      let startTime =
        Number(target.getAttribute('hour')) - (draggingData.draggingPart - 1)
      const removeTimeDiff =
        startTime < +matrixLog.time_start
          ? +matrixLog.time_start - startTime
          : 0
      startTime =
        startTime < +matrixLog.time_start ? +matrixLog.time_start : startTime
      copy.current_start_time = startTime
      copy.current_end_time = startTime + difference - removeTimeDiff
      if (copy.current_end_time > +matrixLog.time_end)
        copy.current_end_time = +matrixLog.time_end
      // copy.appointed_technician_id = target.parentElement.getAttribute('resourceid')
      copy.column_template_id = +target.parentElement.getAttribute('resourceid')
      copy.queue_id = null
      // if (checkOverlap(events, matrixLog, copy, currentDate, timeoff)) {
      changeParams({
        type: currentAppointment.column_template_id ? 'calendar' : 'queue',
        currentAppointment: {
          ...copy,
          time_start: !eventSettings?.show_promise_time
            ? moment(currentAppointment.current_start_time, 'HH').format(
                'HH:mm',
              )
            : currentAppointment.time_start,
          time_end: !eventSettings?.show_promise_time
            ? moment(currentAppointment.current_end_time, 'HH').format('HH:mm')
            : currentAppointment.time_end,
        },
        difference,
      })
      // }
    }
  }

  // const getActionByQueueType = {
  //   appointments_queue: 'queue',
  //   jobs_queue: 'queue',
  // }

  const handleDropInQueue = async (queue_id: number) => {
    if (!draggingEvent) return

    await dispatch(
      updateMatrixEvent({
        event: {
          ...draggingEvent,
          current_end_time: null,
          current_start_time: null,
          column_template_id: null,
          // status: 1,
          queue_id,
          updated_at: moment().format('MM/DD/YYYY HH:mm:ss'),
          time_start: !eventSettings?.show_promise_time
            ? moment(draggingEvent.current_start_time, 'HH').format('HH:mm')
            : draggingEvent.start_time,
          time_end: !eventSettings?.show_promise_time
            ? moment(draggingEvent.current_end_time, 'HH').format('HH:mm')
            : draggingEvent.end_time,
        },
        date: moment(currentDate).format('MM/DD/YYYY'),
      }),
    )

    // const invalidateQueueByType =
    //   queueType === 'appointments_queue'
    //     ? !!draggingEvent?.parent_id
    //       ? 'appointments_recurring'
    //       : 'appointments_queue'
    //     : !!draggingEvent?.parent_id
    //     ? 'jobs_recurring'
    //     : 'jobs_queue'

    // queryClient.invalidateQueries({ queryKey: [invalidateQueueByType] })
  }

  const dragEventEnd = async e => {
    onDragEvent?.(false)
    if (
      !target.classList.contains('box') ||
      !target.classList.contains('box-overlay')
    )
      return

    if (!!e && !!e.preventDefault) e.preventDefault()
    document.body.classList.remove('grabbing')
  }

  const dragEventOver = useCallback(
    (e, i) => {
      if (!!e && !!e.preventDefault) e.preventDefault()
      const { className } = e.currentTarget

      if (
        className.includes('box') ||
        className.includes('queue') ||
        className.includes('box-overlay')
      )
        changeTarget(e.currentTarget)
      const difference = draggingData.difference
      let startOfShadow = i - (draggingData.draggingPart - 1)

      const partOfShadowToReplace =
        startOfShadow < 0 ? Math.abs(startOfShadow) : 0
      startOfShadow = startOfShadow <= 0 ? 0 : startOfShadow
      const elements = [...e.currentTarget.parentElement.children].splice(
        startOfShadow,
        difference - partOfShadowToReplace,
      )

      if (className.includes('box-overlay')) {
        elements.map(
          elem => (elem.style.backgroundColor = 'rgba(107, 109, 110, 0.6)'),
        )
      } else {
        elements.map(
          elem => (elem.style.backgroundColor = 'rgb(193, 197, 199)'),
        )
      }
    },
    [draggingData],
  )

  const handleDropEvent = e => {
    onDragEvent?.(false)

    e.currentTarget.childNodes.forEach(child => {
      // child.style.backgroundColor = 'white'
      child.style.backgroundColor = 'transparent'
      child.style.borderBottom = 'none'
    })

    document.querySelectorAll('.box-overlay').forEach(item => {
      item.style.backgroundColor = 'transparent'
    })

    let difference =
      Number(draggingEvent?.current_end_time) -
      Number(draggingEvent?.current_start_time)
    if (difference <= 0 || Number.isNaN(difference)) {
      difference = 1
    }

    const event = { ...draggingEvent }

    if (!draggingEvent?.date) {
      event.date = moment(currentDate).format('MM/DD/YYYY')
    }
    dropMiscOrUsed(event, difference)
  }

  const dragEventLeave = useCallback(
    (e, i) => {
      if (!!e && !!e.preventDefault) e.preventDefault()
      const difference = draggingData.difference
      let startOfShadow = i - (draggingData.draggingPart - 1)
      const partOfShadowToReplace =
        startOfShadow < 0 ? Math.abs(startOfShadow) : 0
      startOfShadow = startOfShadow <= 0 ? 0 : startOfShadow
      const elements = [...e.currentTarget.parentElement.children].splice(
        startOfShadow,
        difference - partOfShadowToReplace,
      )

      elements.map(elem => {
        const isOverlayBox = elem.className === 'box-overlay'

        elem.style.backgroundColor = isOverlayBox ? 'transparent' : 'white'
        elem.style.borderBottom = 'none'
      })
    },
    [draggingData],
  )

  const {
    blockHeight,
    headerHeight,
    marginBetveenBoxes,
    minutesInHour,
  } = timeLineSettings

  const hours = today.tz(company?.timezone || moment.tz.guess()).hours()

  const minutes = today.minutes()
  const totalMinutesForNow = hours * minutesInHour + minutes
  const totalMinMinutesMatrix = minTime * minutesInHour
  const totalMaxMinutesMatrix = maxTime * minutesInHour
  const top =
    hours >= minTime
      ? (hours - minTime) * (blockHeight + marginBetveenBoxes) +
        (blockHeight * minutes) / minutesInHour +
        headerHeight
      : 0

  const showTimeLineTime = () => {
    setShowTime(true)
  }
  const closeTimeLineTime = () => {
    setShowTime(false)
  }

  const showTimeLine =
    totalMinutesForNow >= totalMinMinutesMatrix &&
    totalMinutesForNow <= totalMaxMinutesMatrix &&
    moment(currentDate)
      .startOf('day')
      .diff(moment().startOf('day'), 'days') === 0

  const closeConfirmDateModal = () => toggleConfirmDateModal(false)
  const closePreferWorkerModal = () => changeModalPreferWorker(false)
  const closeSelectTimeModal = () => toggleSelectTimeModal(false)

  const handleCreateproposal = async (event: DispatchEvent) => {
    if ([Companies.SoCal].includes(company.id)) {
      const data = {
        user_id: me.id,
        industry_id: eventSettings?.industry_id || me?.industries?.[0]?.id,
        status: 1,
        source_id: event?.appointment_source_id || null,
        type_id: event?.appointment_type_id || null,
      }
      return await createProposal(data)
        .then(res => window.open(`${window.origin}/projects/${res.id}`))
        .catch(e => console.error(e))
    }
    setProjectEntity({ ...event, type: 'appointment_id' })
  }

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    dispatch(
      updateColumnsOrder({
        columns,
        source: source.index,
        target: destination.index,
      }),
    )
  }

  // CREATE APPOINTMENT FROM MATRIX - START

  const onDeleteCreatedEvent = () => {
    clearMatrixEvents()
  }

  const onClearCreatedStore = () => {
    clearMatrixEvents()
  }

  const onCreateCreatedEvent = async event => {
    if (!permissions[DispatchPermission.DISPATCH_CAN_CREATE_EVENT]) return
    const eventForCreate = appointments_created?.find(e => e.id === event.id)
    setCreatedEventLoading(true)

    try {
      const { appointment, status } = await createAppointment({
        ...eventForCreate,
        industry_id: eventSettings?.industry_id || eventForCreate?.industry_id,
        timezone: eventSettings?.display_timezone
          ? eventSettings?.timezone
          : null,

        // time_end: moment()
        //   .set('hour', eventForCreate.current_start_time)
        //   .set('minute', 0)
        //   .add(
        //     +eventSettings?.arrival_window || eventForCreate.current_end_time,
        //     'h',
        //   )
        //   .format('HH:mm'),

        time_end: !eventSettings.arrival_window
          ? moment(eventForCreate.current_end_time, 'HH').format('HH:mm')
          : eventForCreate.current_start_time + +eventSettings.arrival_window >=
            24
          ? '00:00'
          : moment(eventForCreate.current_start_time, 'HH')
              .add(+eventSettings.arrival_window, 'h')
              .format('HH:mm'),
        matrix_time_start: moment()
          .set('hour', eventForCreate.current_start_time)
          .set('minute', 0)
          .format('HH:mm'),
        matrix_time_end: moment()
          .set('hour', eventForCreate.current_end_time)
          .set('minute', 0)
          .format('HH:mm'),
        date:
          company.id === Companies.WestCost
            ? eventForCreate?.date
            : [eventForCreate?.date],
      })
      if (!status) {
        // message.error('Something went wrong')
        openNotificationWithIcon('error', {
          message: 'Something went wrong',
        })
        return await dispatch(
          getMatrixLog({
            date: moment(currentDate || new Date()).format('MM/DD/YYYY'),
          }),
        )
      }
      // message.success('Appointment has been created successfully')
      // openNotificationWithIcon('success', {
      //   message: 'Appointment has been created successfully',
      // })
      setEventData({ ...event, id: appointment?.[0].id })
      setEvent?.({ ...event, id: appointment?.[0].id })
      setShowAppointment?.(true)
      onDeleteCreatedEvent(event)
      if (isMap) showPreloader?.()

      dispatch(setMatrixEvent(appointment))
    } catch (error) {
      // message.error('Something went wrong')
      openNotificationWithIcon('error', {
        message: error?.response?.data?.message || 'Something went wrong',
      })
    } finally {
      setCreatedEventLoading(false)
    }
  }

  const onClearEventBox = () => {
    document?.querySelectorAll('.box__background')?.forEach(el => {
      el.style = ''
    })
  }

  const handleDeleteAppointment = async event => {
    const { id, kind } = event
    const deleteEvent = kind === EventKind.JOB ? deleteJob : deleteAppointment
    try {
      if (id === eventData?.id) onCloseAppointment()

      await deleteEvent(id)

      dispatch(eventDeleted(event.id))
      // message.success('Appointment was deleted successfully')
      isMap && showPreloader?.()
    } catch (error) {
      console.error(error)
      message.error('Something went wrong')
      await dispatch(
        getMatrixLog({
          date: moment(currentDate ?? new Date()).format('MM/DD/YYYY'),
        }),
      )
    }
  }

  const handleDublicateAppointment = async ({ id, kind }) => {
    const duplicateEvent = kind === 2 ? duplicateJob : dublicateAppointment
    setTooltipLoading(prev => ({ ...prev, dublicate: true }))
    try {
      await duplicateEvent(id)

      dispatch(getQueueEvents())
      setTooltipLoading(prev => ({ ...prev, dublicate: false }))
      if (id === eventData?.id) onCloseAppointment()
      // message.success(
      //   `${kind === 2 ? 'Job' : 'Appointment'} was duplicate successfully`,
      // )
      openNotificationWithIcon('success', {
        message: `${
          kind === 2 ? dictionary.singular : 'Appointment'
        } has been duplicated successfully`,
      })
      isMap && showPreloader?.()
    } catch (error) {
      console.error(error)
      // message.error('Something went wrong')
      openNotificationWithIcon('error', {
        message: error?.response?.data?.message || 'Something went wrong',
      })
    }
  }

  const heandleChangeStatusAppointment = async event => {
    try {
      const notificationSettings = await getNotificationSettings(
        event.industry_id,
      )

      // if (event.column_template_id) {
      dispatch(
        eventStatusUpdated({
          eventId: event.id,
          status: event.status /* , type: getTypeTo(event.action) */,
        }),
      )
      // } else {
      //   queryClient.invalidateQueries({ queryKey: [getTypeTo(event.action)] })
      // }

      if (
        event?.status === AppointmentStatusID['En Route'] &&
        notificationSettings?.should_display_in_route
      ) {
        setShowNotificationInRouteModal(true)
        setAppointment(event)
      }
      if (
        event?.status === AppointmentStatusID.Active &&
        notificationSettings?.should_display_upcoming
      ) {
        setShowNotificationModal(true)
        setAppointment(event)
      }
      if (
        event?.status === AppointmentStatusID.Cancelled &&
        notificationSettings?.should_display_canceled
      ) {
        setShowNotificationCanceledModal(true)
        setAppointment(event)
      }
      if (
        event?.status === AppointmentStatusID.Completed &&
        notificationSettings?.should_display_request_for_review_event_completed &&
        modulePermissions?.reviews
      ) {
        setIsShownRfRModal(true)
        setAppointment(event)
      }
      isMap && showPreloader?.()

      // openNotificationWithIcon('success', {
      //   message: 'Status was updated successfully',
      // })
      await changeAppointmentStatus(event.id, { status: event.status })
    } catch (error) {
      console.error(error)
      message.error('Something went wrong')
    }
  }

  const onCloseInRouteNotification = value => {
    setShowNotificationInRouteModal(value)
    setAppointment({})
  }
  const onCloseUpcomingNotification = value => {
    setShowNotificationModal(value)
    setAppointment({})
  }
  const onCloseCanceledNotification = value => {
    setShowNotificationCanceledModal(value)
    setAppointment({})
  }

  let start,
    end,
    tech,
    direction = '',
    oldx = 0,
    oldy = 0
  const timeArr = new Set()

  const resizingBox = e => {
    if (!permissions[DispatchPermission.DISPATCH_CAN_CREATE_EVENT]) return
    const bgColor = '#d2e4ed'
    const bgHeight = `${e.target.offsetHeight}px`
    const techId = e.target?.getAttribute('workerid')
    if (e.pageX === oldx && e.pageY > oldy) {
      direction = 'down'
    } else if (e.pageX === oldx && e.pageY < oldy) {
      direction = 'up'
    }
    oldx = e.pageX
    oldy = e.pageY
    if (techId !== tech) return

    const parent = e.target.querySelector('.box__background')
    const endTime = e.target?.getAttribute('hour')

    if (
      (e.type === 'mouseout' || e.type === 'mousemove') &&
      direction === 'up' &&
      timeArr.has(Number(endTime))
    ) {
      if (e.target?.id) {
        parent.style.height = 0
        parent.style.backgroundColor = bgColor
      }
      if (e.target?.title) {
        e.target.style.height = 0
        e.target.style.backgroundColor = bgColor
      }
      timeArr.delete(Number(endTime))
      end = endTime
      return
    }

    if (direction === 'down' && Number(start) <= Number(endTime)) {
      if (e.target?.id) {
        parent.style.height = bgHeight
        parent.style.backgroundColor = bgColor
      }
      if (e.target?.title) {
        e.target.style.height = bgHeight
        e.target.style.backgroundColor = bgColor
      }
      timeArr.add(Number(endTime))
      end = endTime
    }
  }

  const startResizingBox = e => {
    if (!!e && !!e.preventDefault) e.preventDefault()

    if (!permissions[DispatchPermission.DISPATCH_CAN_CREATE_EVENT]) return

    // if (weekView) return

    const startTime = e.target?.getAttribute('hour')
    const techId = e.target?.getAttribute('workerid')
    start = startTime
    tech = techId
    clearMatrixEvents()
    // clearMatrixEvents('appointments_created')
    const some_function = e => resizingBox(e)
    const stopResizing = async () => {
      document.body.removeEventListener('mousemove', some_function, false)
      document.body.removeEventListener('mouseup', stopResizing)
      document.body.removeEventListener('mouseout', some_function, false)
      document.body.style.cursor = 'auto'
      if (timeArr.size === 0) {
        timeArr.add(start)
      }

      const t = [...timeArr]
      start = t?.[0] || start
      end = t?.[t?.length - 1] || t?.[0] || start
      if (Number(end) < Number(start)) {
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ;[start, end] = [end, start]
      }

      const data = createAppointmentData(
        techId,
        Number(start),
        Number(end ? end : start) + 1,
        industries?.[0]?.id,
        currentDate,
      )
      // setMatrixEvent('appointments_created', { ...data, action: 'created' }, 'appointments_created')
      dispatch(createDispatchEvent({ currentEvent: data }))
      onClearEventBox()
    }
    document.body.addEventListener('mousemove', some_function, false)
    document.body.addEventListener('mouseup', stopResizing)
    document.body.addEventListener('mouseout', some_function, false)
    document.body.style.cursor = 'grabbing'
  }
  const handleVisibleChange = (visible: boolean, id: number) => {
    changeVisible({ id: id, visible: visible })
  }

  const handleOpenColumnSettings = (id: number) => (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault()

    if (weekView) return

    setColumnId(id)
  }

  const handleColumnSettingsUpdated = async (data: UpdateColumnBody) => {
    try {
      await updateColumn(data)
      openNotificationWithIcon('success', {
        message: 'Column updated successfully',
      })

      setColumnId(null)

      dispatch(getMatrixLog({ date: moment(currentDate).format('MM/DD/YYYY') }))
    } catch (error) {
      openNotificationWithIcon('error', {
        message: error?.response?.data?.message || 'Something went wrong',
      })
    }
  }

  const handleOpenContactModal = (contact: IEventContact) => {
    setActiveEventContact(contact)
    setOpenContactModal(true)
  }

  // CREATE APPOINTMENT FROM MATRIX - END
  const workersJSX = columns
    ?.sort((a, b) => a.sort_order - b.sort_order)
    ?.filter(column =>
      filterTechnicians?.length
        ? column.technicians.some(tech =>
            filterTechnicians?.includes(tech.user_id),
          )
        : column,
    )
    ?.map((column, i) => {
      return (
        <Draggable
          key={column.column_template_id}
          draggableId={column.column_template_id.toString()}
          index={i}
        >
          {provided => (
            <div
              className='column'
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <div {...provided.dragHandleProps}>
                <Title
                  column={column}
                  small={weekView || boxSize?.height < 40}
                  onClick={handleOpenColumnSettings(column.column_template_id)}
                  disabled={isSchedule}
                />
              </div>
              <div
                className='playground'
                resourceid={column.column_template_id}
              >
                <div
                  className='boxes'
                  resourceid={column.column_template_id}
                  onDrop={e => handleDropEvent(e, 'used')}
                >
                  <BoxWrapper
                    maxTime={maxTime}
                    minTime={minTime}
                    workerId={+column.column_template_id}
                    dragEventOver={dragEventOver}
                    dragEventLeave={dragEventLeave}
                    startResizingBox={
                      (!isSchedule || isMap) && startResizingBox
                    }
                    ref={boxRef}
                    eventType='used'
                    isSchedule={isSchedule}
                  />
                </div>

                <Timeoff
                  events={timeoff}
                  worker={column}
                  maxTime={maxTime}
                  minTime={minTime}
                  currentDate={currentDate}
                  weekView={weekView}
                  boxHeight={boxHeight}
                  boxSize={boxSize}
                  isWorker={isWorker}
                  dragEventOver={dragEventOver}
                  dragEventLeave={dragEventLeave}
                  onDrop={e => handleDropEvent(e, 'used')}
                  resourceid={column.column_template_id}
                />

                <div className='events' resourceid={column.column_template_id}>
                  <EventWrapper
                    column={column}
                    events={events?.filter(
                      event =>
                        event.column_template_id === column.column_template_id,
                    )}
                    isWorker={isWorker}
                    dragEventStart={dragEventStart}
                    dragEventEnd={dragEventEnd}
                    minTime={minTime}
                    maxTime={maxTime}
                    currentAppointmentId={currentAppointmentId}
                    activeAppointment={activeAppointment}
                    isMobileDevise={isMobileDevise}
                    weekView={weekView}
                    boxHeight={boxHeight}
                    boxSize={boxSize}
                    onDublicate={handleDublicateAppointment}
                    onCreateProposal={handleCreateproposal}
                    isSchedule={isSchedule}
                    onScheduleClick={onScheduleClick}
                    onMapClick={onMapClick}
                    activeEvent={activeEvent}
                    onChangeActiveEvent={setActiveEvent}
                    onOpenAppointment={onOpenAppointment}
                    onSetEventData={onSetEventData}
                    eventType='used'
                    handleDeleteAppointment={handleDeleteAppointment}
                    heandleChangeStatusAppointment={
                      heandleChangeStatusAppointment
                    }
                    handleClickRfRModal={handleClickRfRModal}
                    onClearCreatedStore={onClearCreatedStore}
                    tooltipLoading={tooltipLoading}
                    isMap={isMap}
                    handleOpenContactModal={handleOpenContactModal}
                    handleOpenPublicEventModal={handleOpenPublicEventModal}
                    eventSettings={eventSettings}
                    showPreloader={showPreloader}
                    onDrop={e => handleDropEvent(e, 'used')}
                    dragEventOver={dragEventOver}
                    workerId={+column.column_template_id}
                    resourceid={column.column_template_id}
                    dragEventLeave={dragEventLeave}
                  />
                  <CreatedWrapper
                    worker={column}
                    events={appointments_created?.filter(
                      event =>
                        event?.column_template_id?.includes(
                          column.column_template_id,
                        ) &&
                        moment(event.date).isSame(moment(currentDate), 'day'),
                    )}
                    loading={createdEventLoading}
                    dragEventStart={dragEventStart}
                    dragEventEnd={dragEventEnd}
                    minTime={minTime}
                    currentAppointmentId={currentAppointmentId}
                    activeAppointment={activeAppointment}
                    isMobileDevise={isMobileDevise}
                    weekView={weekView}
                    isWorker={isWorker}
                    boxHeight={boxHeight}
                    boxSize={boxSize}
                    onDublicate={handleDublicateAppointment}
                    onCreateProposal={handleCreateproposal}
                    isSchedule={isSchedule}
                    onScheduleClick={onScheduleClick}
                    activeEvent={activeEvent}
                    onChangeActiveEvent={setActiveEvent}
                    onOpenAppointment={onOpenAppointment}
                    onSetEventData={onSetEventData}
                    eventType='created'
                    handleDeleteAppointment={handleDeleteAppointment}
                    heandleChangeStatusAppointment={
                      heandleChangeStatusAppointment
                    }
                    handleClickRfRModal={handleClickRfRModal}
                    onDeleteCreatedEvent={onDeleteCreatedEvent}
                    onCreateCreatedEvent={onCreateCreatedEvent}
                    tooltipLoading={tooltipLoading}
                    isMap={isMap}
                  />
                </div>
              </div>
            </div>
          )}
        </Draggable>
      )
    })

  if (!matrixLog) return <p>...loading</p>

  return (
    <>
      {!weekView && !isSchedule && !isMap && (
        <>
          <QueueList
            todoList={todoList}
            dragEventStart={dragEventStart}
            handleVisibleChange={handleVisibleChange}
            handleCreateproposal={handleCreateproposal}
            dragEventEnd={dragEventEnd}
            onSetEventData={onSetEventData}
            onOpenAppointment={onOpenAppointment}
            heandleChangeStatusAppointment={heandleChangeStatusAppointment}
            handleDeleteAppointment={handleDeleteAppointment}
            onDublicate={handleDublicateAppointment}
            tooltipLoading={tooltipLoading}
            handleClickRfRModal={handleClickRfRModal}
            draggingEvent={draggingEvent}
            onDrop={handleDropInQueue}
            onCreateProposal={handleCreateproposal}
            handleOpenContactModal={handleOpenContactModal}
            handleOpenPublicEventModal={handleOpenPublicEventModal}
          />

          {!!freeTechs?.length && showUnassignedUsers && (
            <div style={{ marginTop: 10 }}>
              <p>Unassigned Users: </p>
              <AssigneeFilter data={freeTechs} />
            </div>
          )}
        </>
      )}
      <div
        style={sideBarIsOpen ? { width: matrixWidth, overflow: 'scroll' } : {}}
        className='body'
      >
        {/* {!matrixLog?.id && (
          <div className='preloader-matrix'>
            <Preloader />
          </div>
        )} */}
        {showTimeLine && (
          <div
            className='matrix-time-line'
            style={{ top: top + 'px' }}
            onMouseOver={showTimeLineTime}
            onMouseLeave={closeTimeLineTime}
          >
            <Tooltip title={today.format('h:mm A')} visible={showTime}>
              <div
                className='matrix-time-line-dot'
                style={{ top: top + 'px' }}
                onMouseOver={showTimeLineTime}
                onMouseLeave={closeTimeLineTime}
              >
                {today.format('h:mm A')}
              </div>
            </Tooltip>
            <div className='matrix-time-line-line' />
          </div>
        )}
        {(techColumn || todo) && <Time minTime={minTime} maxTime={maxTime} />}
        <div className={className(!techColumn && 'hidden_column')}>
          <div ref={resourceBottomScroll} className='resources'>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='columns' direction='horizontal'>
                {provided => (
                  <div
                    className='column-container'
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {workersJSX}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>

        <SureModal
          modal={modalPreferWorker}
          title='Would you like to proceed?'
          body='You are dropping this appointment not for the tech that has been requested'
          close={closePreferWorkerModal}
          apply={applyDrag}
        />
        <TimeModal
          modal={selectTimeModal}
          close={closeSelectTimeModal}
          apply={applyDragSelectTime}
          suggestionTime={suggestionTime}
        />
        <DateModal
          modal={confirmDateModal}
          close={closeConfirmDateModal}
          apply={applyDragDateModal}
        />
      </div>

      {showNotificationModal && appointment?.id && (
        <NotificationModal
          title={`Upcoming ${
            appointment?.kind === EventKind.JOB
              ? dictionary.singular
              : 'Appointment'
          } Notification`}
          templateType={NotificationTemplateType.APPOINTMENT}
          notificationType={NotificationModalType.UPCOMING}
          showModal={showNotificationModal}
          setShowModal={onCloseUpcomingNotification}
          appointment={appointment}
        />
      )}
      {showNotificationInRouteModal && appointment?.id && (
        <NotificationModal
          title={`En Route ${
            appointment?.kind === EventKind.JOB
              ? dictionary.singular
              : 'Appointment'
          }  Notification`}
          templateType={NotificationTemplateType.APPOINTMENT}
          notificationType={NotificationModalType.EN_ROUTE}
          showModal={showNotificationInRouteModal}
          setShowModal={onCloseInRouteNotification}
          appointment={appointment}
        />
      )}
      {showNotificationCanceledModal && appointment?.id && (
        <NotificationModal
          title={`Canceled ${
            appointment?.kind === EventKind.JOB
              ? dictionary.singular
              : 'Appointment'
          }  Notification`}
          templateType={NotificationTemplateType.APPOINTMENT}
          notificationType={NotificationModalType.CANCELED}
          showModal={showNotificationCanceledModal}
          setShowModal={onCloseCanceledNotification}
          appointment={appointment}
        />
      )}

      {/* {showMyQueue && isSchedule && (
        <ToDoList
          maxTime={maxTime}
          minTime={minTime}
          currentDate={currentDate}
          events={queueEvents?.filter?.(
            event => event.preferred_technician_id === me.id,
          )}
          myQueue
          isSchedule={isSchedule}
        />
      )} */}

      {/*  {todo && isSchedule && (
        <ToDoList
          maxTime={maxTime}
          minTime={minTime}
          currentDate={currentDate}
          events={todoList}
        />
      )} */}

      {/* //    {appointment && (
  //      <RfRModal
  //        isShow={isShownRfRModal}
  //        close={() => {
  //          setIsShownRfRModal(false)
  //          setAppointmentId(null)
  //        }}
  //        appointmentID={appointmentId}
  //        status={eventData?.review_status}
  //        changeStatus={handleChangeRfRStatus}
  //        setUpdatedClientsToParent={getClientsForRfRModal}
  //        clients={clientsForNotificationModal}
  //      />
  //    )} */}

      {isShownRfRModal && appointment?.id && (
        <NotificationModal
          title={NotificationTitleType.REQUEST_FOR_REVIEW}
          templateType={NotificationTemplateType.APPOINTMENT}
          notificationType={NotificationModalType.REQUEST_FOR_REVIEW}
          showModal={isShownRfRModal}
          setShowModal={setIsShownRfRModal}
          appointment={appointment}
          changeStatus={handleChangeRfRStatus}
          customSubmit
        />
      )}

      {showAppointment && !weekView && (
        <Appointment
          currentDate={currentDate}
          onCloseDrawer={onCloseAppointment}
          visibleDrawer={showAppointment}
          event={eventData}
        />
      )}

      {[Companies.SoCal].includes(company.id) && (
        <ModalLogSheets
          matrixLog={matrixLog}
          visible={printModalVisible}
          onClose={() => heandlePrintModal(!printModalVisible)}
          handleZoom={handleZoom}
        />
      )}

      {currentColumn && (
        <ColumnSettings
          data={currentColumn}
          onClose={() => setColumnId(null)}
          onSave={handleColumnSettingsUpdated}
        />
      )}

      <Modal
        style={{ minWidth: '350px' }}
        visible={!!projectEntity}
        onCancel={() => setProjectEntity(null)}
        footer={null}
      >
        <CreateProposal
          entity={projectEntity}
          onCancel={() => setProjectEntity(null)}
          fromAppointment={true}
        />
      </Modal>

      <Clients
        modal={openContactModal}
        close={() => {
          setOpenContactModal(false)
          setActiveEventContact(null)
        }}
        clientId={activeEventContact?.id}
        currentTab={
          activeEventContact?.type === ClientType.ORGANIZATION
            ? 'organization'
            : 'clients'
        }
        isOrganization={activeEventContact?.type === ClientType.ORGANIZATION}
      />

      <PublicEventModal
        visible={openPublicEventModal}
        onCancel={handleClosePublicEventModal}
        event={publicEvent}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    currentAppointmentId: state.orcatec.appointment.currentAppointmentId,
    me: state.orcatec.user.me,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateMatrixLog: (date, obj) =>
      dispatch(actions.matrixLog.updateMatrixLog(date, obj)),
    createTodoTask: data => dispatch(actions.todo.createTodoTask(data)),
    updateTodoTask: (id, data) =>
      dispatch(actions.todo.updateTodoTask(id, data)),
    deleteTodoTask: id => dispatch(actions.todo.deleteTodoTask(id)),
    updateTodoTaskStatus: (id, status) =>
      dispatch(actions.todo.updateTodoTaskStatus(id, status)),
    clearMatrixEvents: () => dispatch(resetCreated()),
  }
}

const connectedCalendar = connect(mapStateToProps, mapDispatchToProps)(Calendar)

export { connectedCalendar as Calendar }
