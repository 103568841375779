import React from 'react'
import { connect } from 'react-redux'

import PageLayout from './../../../Layouts/PageLayout'
import ModalOrderEquipmentNew from './../../components/Modals/ModalOrderEquipments/ModalOrderEquipmentNew.tsx'
import ModalReminderContainer from './../../components/Modals/ModalReminderContainer'
import { ModalProposalSectionTemplateLoad } from '../Modals/ModalProposalSectionTemplateLoad/ModalProposalSectionTemplateLoad'
import ModalReminderList from '../Modals/ModalReminderList'
import ModalScheduleAutomaticFollowUp from '../Modals/ModalScheduleAutomaticFollowUp'
import ModalFileUpload from '../Modals/ModalFileUploadContainer'
// import ModalInstallationDate from '../Modals/ModalInstallationDate'
import ModalDrawing from '../Modals/ModalDrawing'
import ModalProposalEmails from '../Modals/ModalProposalEmails'
import ModalProposalSetting from '../Modals/ModalProposalSetting/ModalProposalSetting.jsx'
import { ModalProposalSectionTemplate } from '../Modals/ModalProposalSectionTemplate/ModalProposalSectionTemplate'
import * as actions from 'store/Orcatec/actions'
import { notification, Popconfirm, Modal } from 'antd'
import './index.scss'
import ProjectTabs from './Tabs/ProjectTabs'
import MainButton from '../../../components/buttons/MainButton'
// import ModalEmailHistory from '../../../components/ModalEmailHistory/modalEmailHistory'
// import { deleteMedia } from '../../../../../../api/Media'
// import Preloader from '../../../components/Preloader'
import SureModal from '../../../components/SureModal'
// import moment from 'moment-timezone'
import { openNotificationWithIcon } from '../../../../../../helpers/notifications/openNotificationWithIcon'
import { Prompt } from 'react-router-dom'
import ModalClientActivity from '../Modals/ModalClientActivity'
import { ModalProposalTemplate } from '../Modals/ModalProposalTemplate/ModalProposalTemplate'
// import { ModalProposalTemplateLoad } from '../Modals/ModalProposalTemplateLoad/ModalProposalTemplateLoad'
import {
  postProposalTemplate,
  // updateProposal,
} from '../../../../../../api/Proposal'
import Header from './Header/Header'
// import CustomerInfoBlock from './TabsContent/TabInfoBlock/InstallersInfoBlock/InstallersInfoBlock'
import TableActionsNew from '../../../components/TableActionsNew'
import { getSelectedClients } from '../../helpers/getSelectedClients'
// import { updateProperty } from '../../../../../../api/Property'
import ModalConfirmIfThereIsNotSavedNewItems from '../Modals/ModalConfirmIfThereIsNotSavedNewItems/ModalConfirmIfThereIsNotSavedNewItems'
import ModalCancelContract from '../Modals/ModalCancelContract/ModalCancelContract'
import { defaultProposalModuleNames } from '../../../../../../common/constants'
import SignatureBlock from './SignatureBlock/SignatureBlock.tsx'
import { hasActiveMerchant } from '../../../../../../store/Orcatec/reducers/settings/company'
import { AppointmentData } from './AppointmentData'
import ModalWrapper from '../Modals/ModalWrapper/ModalWrapper'
import { ProposalContact } from './ProposalContact'
import { BlockRoutingModal } from 'containers/MainContent/Orcatec/components/BlockRoutingModal'
// import ProposalSettings from '../ProposalSettings'
// import { default as Select } from 'containers/MainContent/Orcatec/components/Select'
import ModalAccounting from '../Modals/ModalAccounting'
// import AppointmentForm from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm'
// import SettingsIcon from '@material-ui/icons/Settings'
import DocViewer from 'helpers/viewFIlesNew/ViewDoc'
import PaymentProcess from '../PaymentProcess'
import states from 'helpers/states'
import moment from 'moment-timezone'
import { getPaymentsModal, postPayments, putPayments } from 'api/Payment'
import { JobForm } from 'containers/MainContent/Orcatec/Jobs/components/JobPage/components/JobForm/JobForm'
import { makeBrowserTitle } from 'helpers/makeBrowserTitle'
// import { KeyboardDatePicker } from '@material-ui/pickers'
import PaymentSchedule from '../Modals/PaymentSchedule'
// import { UserRole } from 'types/User'
import { Companies } from 'types/Company'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import {
  NotificationTemplateType,
  NotificationModalType,
} from 'features/Notification/types'
import ConfirmModal from 'containers/MainContent/Orcatec/components/ConfirmModal/ConfirmModal'
import { ModalProjectHistory } from '../Modals/ModalProjectHistory/ModalProjectHistory'
import {
  setEditContract,
  updateProjectField,
} from 'features/Project/slices/projectSlice'
import { ModalDuplicateProject } from '../Modals/ModalDuplicateProject/ModalDuplicateProject'
import { duplicateProject } from '../../../../../../api/Proposal'
// import { FieldWorkOrder } from './FieldWorkOrder/FieldWorkOrder'
import { addProjectSection } from 'features/Project/slices/projectSectionsSlice'
import {
  resetGroupItems,
  resetProjectItemsSlice,
} from 'features/Project/slices/projectItemsSlice'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
// import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'
import { ProjectPermissions } from 'features/Settings/UsersAndGroups'
import ProjectTemplates from 'features/Project/components/EditProject/components/Templates/ProjectTemplates'
import { ProjectQuickbooks } from 'features/Project/Quickbooks/ProjectQuickbooks'
import { ProjectAPI } from 'api/Project'
import { ProjectStatusGroup } from 'features/Project/types'
import { CustomerInformation } from '../CustomerInformation/CustomerInformation'
import { ProjectWrapper } from 'features/Project/ProjectWrapper/ProjectWrapper'
import { updateProjectTabField } from 'features/Project/slices/projectTabsSlice'
import { ContactSummary, ContactsTree } from 'features/Project/Contacts'
import {
  resetProjectContactSlice,
  selectPrimaryProjectClient,
  selectPrimaryProjectProperty,
} from 'features/Project/slices/projectContactsSlice'
import { selectProjectTotal } from 'features/Project/projectSelectors'

const defaultAction = () => {}
const CancelButton = ({ onClick, isEdited, title, onCancel, ...props }) => {
  return isEdited ? (
    <Popconfirm
      placement='topLeft'
      title={title ?? 'If you cancel proposal all unsaved changes will be lost'}
      onConfirm={onClick}
      okText='Yes'
      cancelText='No'
      disabled={!isEdited}
      onCancel={onCancel}
    >
      <MainButton
        type='cancel'
        title='Cancel'
        onClick={!isEdited ? onClick : onCancel}
        {...props}
      />
    </Popconfirm>
  ) : null
}
const SubmitButton = ({ onClick, isEdited, title, onCancel, ...props }) => {
  return isEdited ? (
    <Popconfirm
      placement='topLeft'
      title={title ?? 'If you cancel proposal all unsaved changes will be lost'}
      onConfirm={onClick}
      okText='Yes'
      cancelText='No'
      disabled={!isEdited}
      onCancel={onCancel}
    >
      <MainButton
        type='primary'
        title='OK'
        onClick={!isEdited ? onClick : onCancel}
        {...props}
      />
    </Popconfirm>
  ) : null
}

export const scrollToFirstUncompletedNewItem = uncompletedItems => {
  const element = document.getElementById(uncompletedItems[0])
  if (!element) return
  element.scrollTop += 50
  element?.scrollIntoView?.({
    behavior: 'smooth',
    block: 'center',
    inline: 'nearest',
  })
}

const initialState = {
  saveTemlateModal: false,
  loadTemlateModal: false,
  saveTemplateSectionModal: false,
  loadTemplateSectionModal: false,
  sectionId: null,
  sectionIdx: null,
  isTabLoading: false,
  status: '',
  loading: true,
  modalUploadBtns: [false, false, false, false, false, false],
  btnColors: ['grey', 'grey', 'grey', 'grey', 'grey', 'grey'],
  modalAccounting: false,
  paymentModal: false,
  selectedBalance: null,
  modalAccountingCloseConfirm: false,
  modalTemplate: { load: false, save: false },
  modalEstimate: false,
  modalCanceled: false,
  isAddButtonClicked: {
    clients: false,
  },
  showFollowUp: false,
  showUncompletedNewItemsModal: false,
  checkUncompletedNewItems: false,
  showEmailModal: false,
  canceled_reason: '',
  isCanceling: '',
  isSaving: false,
  isDeleting: false,
  isCopying: false,
  isStatusChanging: false,
  isPropertySaving: false,
  isPreviewOpening: false,
  proposalForm: {
    id: 0,
  },
  isModalPaymentOpen: false,
  industryInputFieldValue: '',
  payments: {},
  settings: {
    module_one: defaultProposalModuleNames.module_one,
    module_two: defaultProposalModuleNames.module_two,
    module_three: defaultProposalModuleNames.module_three,
  },
  wasSigned: false,
  statusChangingModal: false,
  saveJustStatus: false,
  blockRoutingModal: false,
  nextLocation: '',
  modalEditContract: false,
  isEditContract: false,
  showSettingsModal: false,
  showDocViewerModal: false,
  documents: [],
  currentFileIdx: null,
  jobCreated: false,
  confirmModal: false,
  statusLogModal: false,
  duplicateModal: false,
}

class Index extends React.Component {
  state = JSON.parse(JSON.stringify(initialState))

  toggleSettingModal = bool => {
    // if (bool) {
    //   this.props.getAllTabs()
    // }
    this.setState({ showSettingsModal: bool })
  }
  toggleConfirmModal = () => {
    this.setState({ confirmModal: !this.state.confirmModal })
  }
  toggleStatusLogModal = () => {
    this.setState({ statusLogModal: !this.state.statusLogModal })
  }

  toggleQuickbooksModal = () => {
    this.setState({ quickbooksModal: !this.state.quickbooksModal })
  }

  toggleDuplicateModal = () => {
    this.setState({ duplicateModal: !this.state.duplicateModal })
  }

  handleClickDuplicate = () => {
    this.setState({ duplicateModal: true })
  }

  updateProposalErrorsCatcher = error => {
    if (error.response) {
      let errorText = ''
      let errorKey = ''
      const isValidation =
        !!error.response && error.response.status === 422 && error.response.data
      const isServerError = !!error.response && error.response.status === 500
      const isServerErrorWithMessage =
        isServerError &&
        !!error.response.data &&
        !!error.response.data.error &&
        !!error.response.data.error.message
      if (isValidation) {
        errorKey = Object.entries(error.response.data)[0][0]
        errorText = Object.entries(error.response.data)[0][1]
      }
      if (isValidation && errorKey === 'status') {
        notification.warning({
          description: errorText,
          duration: 7,
          message: 'Attention!',
        })
        return this.setState({
          isSaving: false,
          isCanceling: false,
          loading: false,
        })
      }
      if (isServerErrorWithMessage) {
        errorKey = 'ERROR'
        const message = `${error.response.data.error.message}`
        errorText = message
          .replace(/\r|\n/gi, '')
          .replace(/^(.*ERROR:)(.*)(DETAIL:.*)$/, '$2')
          .trim()
      }
      openNotificationWithIcon('error', {
        message: isValidation
          ? 'Invalid data'
          : isServerError
          ? 'Internal Server Error'
          : 'Something went wrong',
        description:
          isValidation || isServerErrorWithMessage
            ? errorKey + ': ' + errorText
            : isServerError
            ? 'Please try again later'
            : error?.response?.data?.message
            ? error?.response?.data?.message
            : 'Check all required fields and try again',
        key: 'proposal-save-error',
        duration: 0,
      })
      this.setState({ isSaving: false, isCanceling: false, loading: false })
    }
    //return error
  }

  toggleOpenDocViewerModal = () => {
    this.setState({ showDocViewerModal: !this.state.showDocViewerModal })
  }

  getFileForModalDocViewer = (documents, currentFileIdx) => {
    this.setState({ documents, currentFileIdx, showDocViewerModal: true })
  }

  toggleIsTabLoading = boolean => {
    this.setState({ isTabLoading: boolean })
  }

  toggleSaveTemplateModal = () => {
    this.setState({ saveTemlateModal: !this.state.saveTemlateModal })
  }

  toggleLoadTemplateModal = () => {
    this.setState({ loadTemlateModal: !this.state.loadTemlateModal })
  }
  toggleSaveTemplateSectionModal = id => {
    this.setState({ sectionId: id })
    this.setState({
      saveTemplateSectionModal: !this.state.saveTemplateSectionModal,
    })
  }

  toggleLoadTemplateSectionModal = (id, idx) => {
    this.setState({ sectionId: id })
    this.setState({ sectionIdx: idx })
    this.setState({
      loadTemplateSectionModal: !this.state.loadTemplateSectionModal,
    })
  }

  closeModalConfirmUncompletedNewItems = () => {
    this.setState({
      showUncompletedNewItemsModal: !this.state.showUncompletedNewItemsModal,
      checkUncompletedNewItems: true,
    })
  }

  initForm = isCancel => {
    if (isCancel === 'cancel') this.props.clearDeleteEntities()
    this.setState({ isCanceling: isCancel === 'cancel' })
    return this.props
      .initProposalForm(this.props.match.params.id)
      .then(res => {
        if (!res.tabs_info) {
          this.props
            .updateProposal()
            .then(data => {
              this.setState({
                status: res.status,
                loading: false,
                isCanceling: false,
              })
            })
            .catch(this.updateProposalErrorsCatcher)
        } else {
          this.setState({
            status: res.status,
            loading: false,
            isCanceling: false,
          })
        }
      })
      .catch(err => {
        if (
          !!err &&
          (err.status === 404 ||
            (!!err.response && err.response.status === 404))
        ) {
          this.props.history.push(`/404/project`)
        }
        if (
          !!err &&
          (err.status === 403 ||
            (!!err.response && err.response.status === 403))
        ) {
          this.props.history.push(`/404/project`)
        }
        this.setState({ isCanceling: false })
      })
  }

  componentDidMount() {
    this.props.fetchSubscription()
    this.initForm()
  }

  onbeforeunload = e => {
    e.preventDefault()
    e.returnValue =
      'Are you sure you want to leave the page without saving changes?'
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.proposalForm.isEdited !== this.props.proposalForm.isEdited) {
      if (this.props.proposalForm.isEdited)
        window.addEventListener('beforeunload', this.onbeforeunload)
      else window.removeEventListener('beforeunload', this.onbeforeunload)
    }
    if (this.props.jobModal) {
      document.body?.classList?.add('no-scroll')
    } else {
      document.body?.classList?.remove('no-scroll')
    }
    makeBrowserTitle({ prefixForTitle: prevProps?.proposalForm?.code })

    if (
      prevProps.proposalForm.status !== this.props.proposalForm.status &&
      this.props.proposalForm.status !== ProjectStatusGroup.Estimate
    ) {
      ProjectAPI.getQuickbooksInfo(this.props.proposalForm.id).then(res => {
        this.setState({ quickbooksInfo: { data: res } })
      })
    }

    window.Echo?.private('proposal-channel.' + this.props.ownerId).listen(
      'SignedProposalEvent',
      ({ data }) => {
        if (data.proposalId === this.props.proposalForm.id) {
          window.removeEventListener('beforeunload', this.onbeforeunload)
          this.setState({ wasSigned: true })
        }
      },
    )
  }

  componentWillUnmount() {
    this.props.resetProposalForm()
    window.removeEventListener('beforeunload', this.onbeforeunload)
    this.props.setPayments({})
    window.Echo.leave('private-proposal-channel.' + this.props.ownerId) //remove timecard channel from ws connector

    this.props.resetProjectItemsSlice()
    this.props.resetProjectContactSlice()
  }

  onChangeCancelationReason = e => {
    this.setState({ canceled_reason: e.target.value })
  }

  onSubmit = resetUncompletedNewItems => {
    // if (resetUncompletedNewItems === 'reset uncompleted new items')
    //   this.props.resetUncompletedNewItemId()

    // this.setState({
    //   isSaving: true,
    //   showUncompletedNewItemsModal: false,
    // })

    this.setState({ isSaving: false, isEditContract: false })

    /*  this.props
      .updateProposal()
      .then(res => {
        notification.close('proposal-save-in-process')
        // openNotificationWithIcon('success', {
        //   message: 'Your changes have been saved successfully',
        //   description: '',
        //   key: 'proposal-save-success',
        // })
        this.setState({ isSaving: false, isEditContract: false })
        this.setState({ status: res.status })
        if (this.state.nextLocation) {
          this.props.history.push(this.state.nextLocation)
        }
      })
      .catch(this.updateProposalErrorsCatcher) */
  }

  copyProposal = async (proposalId, proposalCode) => {
    const copy = () => duplicateProject(proposalId, proposalCode)

    const updateAndCopy = () =>
      this.props
        .updateProposal()
        .then(copy)
        .catch(this.updateProposalErrorsCatcher)

    const copyProposal = this.props.proposalForm.isEdited ? updateAndCopy : copy
    return await copyProposal()
  }
  //
  //   onDeleteFormFile = (id, idx) => {
  //     this.props.deleteFormFiles(idx, id)
  //   }

  // onDeleteCustomerFile = (id, idx) => {
  //   this.props.deleteCustomerFiles(idx, id)
  // }

  toggleModalEstimate = (saveJustStatus = false) => {
    this.setState({
      modalEstimate: !this.state.modalEstimate,
      isStatusChanging: this.state.modalEstimate
        ? false
        : this.state.isStatusChanging,
      statusChangingModal: !this.state.modalEstimate
        ? false
        : this.state.statusChangingModal,
      saveJustStatus,
    })
  }

  toggleModalCanceled = (saveJustStatus = false) => {
    this.setState({
      modalCanceled: !this.state.modalCanceled,
      isStatusChanging: this.state.modalCanceled
        ? false
        : this.state.isStatusChanging,
      canceled_reason: '',
      statusChangingModal: !this.state.modalCanceled
        ? false
        : this.state.statusChangingModal,
      saveJustStatus,
    })
  }

  updateProposalOnStatusChanging = (value, saveJustStatus = false) => () => {
    const { proposalForm } = this.props
    const deleteSignature =
      value === 1 && +proposalForm.status === 2 ? 'delete signature' : false
    const updateProposal = () => {
      this.setState({ isStatusChanging: true })
      return this.props
        .updateProposal(deleteSignature, value, '', undefined, saveJustStatus)
        .then(() =>
          notification.success({ message: 'Status successfully changed!' }),
        )
        .catch(this.updateProposalErrorsCatcher)
        .finally(() =>
          this.setState({
            isStatusChanging: false,
            statusChangingModal: false,
          }),
        )
    }
    const actionToChangeStatusFromContractIfWasSigned =
      value === 1 && !!proposalForm.signature
        ? () => this.toggleModalEstimate(saveJustStatus)
        : value === 4
        ? () => this.toggleModalCanceled(saveJustStatus)
        : updateProposal
    if (+proposalForm.status === 2)
      actionToChangeStatusFromContractIfWasSigned()
    else updateProposal()
  }

  toggleStatusChangingModal = value => () => {
    this.setState({ statusChangingModal: value })
  }

  isThereArePaidBalances = () =>
    Array.isArray(this.props.payments?.payment_balances)
      ? this.props.payments?.payment_balances?.some(
          balance => balance.status === 3 || balance.status === 5,
        )
      : false

  /* checkAccessToChangeStatus = (value, previousProposalStatus, cb) => {
    let noAccess = true
    const {
      project_can_change_status_from_estimate_to_contract,
      project_can_change_status_from_contract_to_completed,
      project_can_change_status_from_completed_to_contract,
      project_can_change_status_from_contract_to_estimate,
      project_can_change_status_from_contract_to_canceled,
      project_access_to_contract,
    } = this.props.permissions

    const text = 'You do not have access to the action.'

    if (value === 1) {
      if (
        previousProposalStatus === 2 &&
        !checkAccessControl(
          project_can_change_status_from_contract_to_estimate,
          this.props.proposalForm.user_id,
          this.props.me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
    } else if (value === 2) {
      if (
        (previousProposalStatus === 1 &&
          !checkAccessControl(
            project_can_change_status_from_estimate_to_contract,
            this.props.proposalForm.user_id,
            this.props.me.id,
          )) ||
        !checkAccessControl(
          project_access_to_contract,
          this.props.proposalForm.user_id,
          this.props.me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
      if (
        previousProposalStatus === 3 &&
        !checkAccessControl(
          project_can_change_status_from_completed_to_contract,
          this.props.proposalForm.user_id,
          this.props.me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
    } else if (value === 3) {
      if (
        previousProposalStatus === 2 &&
        !checkAccessControl(
          project_can_change_status_from_contract_to_completed,
          this.props.proposalForm.user_id,
          this.props.me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
    } else if (value === 4) {
      if (
        previousProposalStatus === 2 &&
        !checkAccessControl(
          project_can_change_status_from_contract_to_canceled,
          this.props.proposalForm.user_id,
          this.props.me.id,
        )
      ) {
        noAccess = false
        cb(text)
      }
    }
    return noAccess
  } */

  /* onChangeProposalStatus = e => {
    const { value } = e.target
    const previousProposalStatus = this.props.proposalForm.status
    let isError = false

    const handleChangeProposalStatusError = (description = String) => {
      notification.warning({
        description: description,
        duration: 7,
        message: 'Attention!',
      })
      return (isError = true)
    }

    const isAccess = this.checkAccessToChangeStatus(
      value,
      previousProposalStatus,
      handleChangeProposalStatusError,
    )
    if (!isAccess) return

    if (value === 1) {
      previousProposalStatus === 3 &&
        handleChangeProposalStatusError(
          'Completed can not be changed to Estimate!',
        )
      previousProposalStatus === 4 &&
        handleChangeProposalStatusError(
          'Cancelled can not be changed to Estimate!',
        )
      this.isThereArePaidBalances() &&
        handleChangeProposalStatusError(
          'Some balances had been paid. Can not be changed to Estimate!',
        )
    } else if (value === 2) {
      previousProposalStatus === 5 &&
        handleChangeProposalStatusError('Cold can only be changed to Estimate!')
    } else if (value === 3) {
      previousProposalStatus === 1 &&
        handleChangeProposalStatusError(
          'Estimate can not be changed to Completed!',
        )
      previousProposalStatus === 4 &&
        handleChangeProposalStatusError(
          'Cancelled can not be changed to Completed!',
        )
      previousProposalStatus === 5 &&
        handleChangeProposalStatusError('Cold can not be changed to Completed!')
      !this.checkForCanChangeToCompletedStatus() &&
        handleChangeProposalStatusError(
          'Not all modules were completed or not all balances had been paid!',
        )
    } else if (value === 4) {
      previousProposalStatus === 1 &&
        handleChangeProposalStatusError(
          'Estimate can not be changed to Cancelled!',
        )
      previousProposalStatus === 5 &&
        handleChangeProposalStatusError('Cold can only be changed to Estimate!')
    } else if (value === 5) {
      previousProposalStatus === 2 &&
        handleChangeProposalStatusError('Contract can not be changed to Cold!')
      previousProposalStatus === 3 &&
        handleChangeProposalStatusError('Completed can not be changed to Cold!')
      previousProposalStatus === 4 &&
        handleChangeProposalStatusError('Cancelled can not be changed to Cold!')
    }

    if (!isError) {
      if (this.props.proposalForm.isEdited) {
        this.toggleStatusChangingModal(value)()
        return (isError = false)
      } else {
        this.updateProposalOnStatusChanging(value)()
        return (isError = false)
      }
    }
  } */

  openCloseModalPayment = () => {
    const value = this.state.isModalPaymentOpen
    this.setState({ isModalPaymentOpen: !value })
  }

  onDeleteProposal = () => {
    const { id } = this.props.proposalForm
    this.setState({ isDeleting: true })
    this.props
      .deleteProprosal(id)
      .then(() => {
        this.props.history.push('/projects')
      })
      .catch(error => {
        openNotificationWithIcon('error', {
          message: error.response.data.message,
        })
      })
      .finally(() => {
        this.setState({ isDeleting: false })
      })
  }

  onChangeField = e => {
    const { value, name } = e.target
    this.props.onChangeField(value, name)
  }

  onCancel = () => {
    const location = this.state.nextLocation

    if (location) {
      this.setState({ nextLocation: '' })
      return this.props.history.push(location)
    }
    if (this.props.proposalForm.isEdited) {
      this.initForm('cancel')
    } else {
      this.props.history.push('/projects')
    }
  }

  // openInstallationDate = () => {
  //   this.props.openInstallationDate()
  // }

  // setSelectedClientsIds = items => {
  //   if (Array.isArray(items)) this.props.onChangeField(items, 'client_ids')
  //   else if (Number.isInteger(+items))
  //     this.props.onChangeField(
  //       [...this.props.proposalForm.client_ids, items],
  //       'client_ids',
  //     )
  // }

  onOpenPreview = () => {
    if (this.props.proposalForm.isEdited) {
      this.setState({ isPreviewOpening: true })
      this.props
        .updateProposal()
        .then(() => {
          const proposalState = {
            proposal: this.props.proposalForm,
            proposalSettings: this.props.proposalSettings,
            company: this.props.company,
          }
          localStorage.setItem('proposalState', JSON.stringify(proposalState))
          this.props.history.push('/proposal/preview')
        })
        .catch(this.updateProposalErrorsCatcher)
        .finally(() => {
          this.setState({ isPreviewOpening: false })
        })
    } else {
      const proposalState = {
        proposal: this.props.proposalForm,
        proposalSettings: this.props.proposalSettings,
        company: this.props.company,
      }
      localStorage.setItem('proposalState', JSON.stringify(proposalState))
      this.props.history.push('/proposal/preview')
    }
  }

  toggleAccountingModal = () => {
    this.setState(prev => ({ modalAccounting: !prev.modalAccounting }))
  }

  togglePaymentModal = () => {
    this.setState(prev => ({ paymentModal: !prev.paymentModal }))
  }

  toggleModalEmailHistory = () => {
    this.setState(prev => ({ showEmailModal: !prev.showEmailModal }))
  }

  saveProposalTemplate = (newTemplateName, sharedIds, isNewTemlateDefault) => {
    const data = {
      user_id: this.props.me.id,
      owner_template_name: this.props.me.full_name,
      is_shared: sharedIds,
      type: 1, //1-proposal
      entity_id: this.props.proposalForm.id,
      title: newTemplateName,
    }
    const postTemplate = () =>
      postProposalTemplate(data)
        .then(data => {
          return isNewTemlateDefault
            ? this.props.updateUserTemplate(this.props.me.id, {
                template_id: data.id,
              })
            : data
        })
        .then(() => notification.success({ message: 'Template saved!' }))
        .catch(e => notification.error({ message: e?.message }))
    const updateProposalAndPostTemplate = () =>
      this.props
        .updateProposal('just update')
        .then(postTemplate)
        .then(() => notification.success({ message: 'Template saved!' }))
        .catch(e => {
          notification.error({ message: e?.message })
          this.updateProposalErrorsCatcher()
        })

    return this.props.proposalForm.isEdited
      ? updateProposalAndPostTemplate()
      : postTemplate()
  }

  saveProposalSectionTemplate = (
    newTemplateName,
    sharedIds,
    isNewTemlateDefault,
  ) => {
    const data = {
      user_id: this.props.me.id,
      owner_template_name: this.props.me.full_name,
      is_shared: sharedIds,
      type: 2, //2-proposal_section
      entity_id: this.state.sectionId,
      title: newTemplateName,
    }
    const postTemplate = () =>
      postProposalTemplate(data)
        .then(data => {
          return isNewTemlateDefault
            ? this.props.updateUserTemplate(this.props.me.id, {
                template_id: data.id,
              })
            : data
        })
        .then(() =>
          notification.success({ message: 'Section template saved!' }),
        )
        .then(() => this.setState({ sectionId: null }))
        .catch(e => notification.error({ message: e?.message }))
    const updateProposalAndPostTemplate = () =>
      this.props
        .updateProposal('just update')
        .then(postTemplate)
        .then(() =>
          notification.success({ message: 'Section template saved!' }),
        )
        .then(() => this.setState({ sectionId: null }))
        .catch(e => {
          notification.error({ message: e?.message })
          this.updateProposalErrorsCatcher()
        })

    return this.props.proposalForm.isEdited
      ? updateProposalAndPostTemplate()
      : postTemplate()
  }

  openFollowUpModal = () => {
    this.setState({ showFollowUp: true })
  }

  // onChangePropertyTags = tags => {
  //   this.props.onChangeField(tags, 'property_tags')
  // }

  onSubmitVisibilityItems = (close = true) => {
    //I compare the default settings with the current ones, if the values ​​are different, I call the appropriate action
    /* const {
      // show_information,
      hide_section_items,
      require_signature,
    } = this.props.proposalForm.setting
    const {
      // isHideMaterials,
      // isHideWarranty,
      // isHideDiscount,
      // isHideRebates,
      // qtyItems,
    } = hide_section_items */
    // const { proposalForm } = this.props
    // if (proposalForm.hide_section_items.isHideMaterials !== isHideMaterials) {
    //   this.props.deleteSectionItems('materials')
    //   this.props.resetItemsGroup(1)
    // }
    // if (proposalForm.hide_section_items.isHideWarranty !== isHideWarranty) {
    //   this.props.deleteSectionItems('warranties')
    // }
    // if (proposalForm.hide_section_items.isHideDiscount !== isHideDiscount) {
    //   this.props.deleteSectionItems('instants')
    // }
    // if (proposalForm.hide_section_items.isHideRebates !== isHideRebates) {
    //   this.props.deleteSectionItems('rebates')
    // }
    // if (proposalForm.hide_section_items.qtyItems !== qtyItems) {
    //   this.props.deleteSectionItems('qtyItems')
    // }
    // if (proposalForm.show_information !== show_information) {
    // this.props.clearCustomerInfo()
    // }
    // if (proposalForm.require_signature !== require_signature) {
    //   this.props.clearSignatureField()
    // }

    // this.onSubmit()
    close && this.toggleSettingModal(false)
  }

  /*   onCancelVisibilityItems = (close = true) => {
    if (this.props.proposalForm.isEdited) {
      this.props.setDefaultUserSetting()
    }
    close && this.setState({ showSettingsModal: !this.state.showSettingsModal })
  } */
  // handleChangeSettings = (checked, name) => {
  //   this.props.onChangeField(checked, name)
  // }

  // onChangeAllWorkComplited = (e, completeRelateJobs) => {
  //   const { checked } = e.target
  //   // const value = checked ? 1 : 0
  //   this.props.onChangeField(checked, 'all_work_completed')
  //   this.props.onChangeField(!!completeRelateJobs, 'complete_related_jobs')
  // }

  // openFormFilesModal = files => () => {
  //   this.props.toggleAttachmentModal('form', files)
  // }

  // openCustomerFilesModal = files => () => {
  //   this.props.toggleAttachmentModal('customer', files)
  // }

  // saveProperty = newNotes => {
  //   const { property } = this.props.proposalForm
  //   this.setState({ isPropertySaving: true })
  //   return updateProperty(property.id, {
  //     ...property,
  //     notes: newNotes,
  //   }).finally(() => {
  //     this.setState({ isPropertySaving: false })
  //   })
  // }

  checkNewItems = () => {
    this.props.proposalForm.uncompletedNewItemsIds.forEach(id => {
      const el = document.getElementById(id)
      el?.classList.add('uncompleted')
    })
    scrollToFirstUncompletedNewItem(
      this.props.proposalForm.uncompletedNewItemsIds,
    )
  }

  onCloseModal = () => {
    this.setState({ showFollowUp: false })
  }

  handleReaload = () => {
    window.location.reload(false)
  }

  handleBlockedRoute = nextLocation => {
    this.setState({ blockRoutingModal: true, nextLocation: nextLocation })
    return false
  }

  handleJobCreate = () => {
    this.props.toggleInstallationDate()
    this.setState({ jobCreated: true })
  }

  handleQuickbooksSync = async () => {
    try {
      this.setState({
        quickbooksInfo: { ...this.state.quickbooksInfo, isSyncing: true },
      })

      const res = await ProjectAPI.exportProjectToQB(this.props.proposalForm.id)

      this.setState({
        quickbooksInfo: {
          ...this.state.quickbooksInfo,
          isSyncing: false,
          data: {
            ...this.state.quickbooksInfo.data,
            exported_at: res.exported_at,
          },
        },
      })
    } catch (error) {}
  }

  render() {
    const { project, projectActiveTab } = this.props
    const { role_id: myRole } = this.props.me

    const lockActions =
      this.state.isTabLoading ||
      this.state.isCopying ||
      this.state.isSaving ||
      this.state.isDeleting ||
      this.state.isStatusChanging

    const tableActionPropsForFooter = {
      todos: [
        project?.had_been_sent ? 'email-green' : 'email',
        'view',
        'copyThin',
        project?.had_been_viewed ? 'activity-green' : 'activity',
        'logs',
      ],
      callbacks: [
        this.toggleModalEmailHistory,
        lockActions ? defaultAction : this.onOpenPreview,
        // lockActions ? defaultAction : this.copyProposal,
        lockActions ? defaultAction : this.handleClickDuplicate,
        this.props.toggleClientActivityModal,
        this.toggleStatusLogModal,
      ],
      preloaders: [
        false,
        this.state.isPreviewOpening,
        this.state.isCopying,
        false,
        false,
      ],
      tooltips: [
        'Send Project',
        [Companies.SoCal].includes(this.props?.companyId)
          ? 'Field Work Order'
          : 'Customer View',

        'Copy Project',
        'View Client Activity',
        'View Project History',
      ],
      visible: [
        checkAccessControl(
          this.props?.permissions?.[ProjectPermissions.PROJECT_CAN_READ_PRICE],
          this.props.proposalForm.user_id,
          this.props.me.id,
        ),
        true,
        checkAccessControl(
          this.props?.permissions?.[ProjectPermissions.PROJECT_CAN_DUPLICATE],
          this.props.proposalForm.user_id,
          this.props.me.id,
        ),
        true,
        true,
        true,
        true,
      ],
    }

    const canceledInfo =
      !!project.canceled_info && project.canceled_info.date
        ? project.canceled_info
        : ''

    const contractInfo = {
      signature: project?.signature,
      ip_client: project?.ip_client,
      signature_date: project?.signature_date,
      contract_date: project?.contract_date,
      proposal_status_changed_by: project?.proposal_status_changed_by,
      signatory_name: project?.signatory_name,
    }

    // const organizations =
    //   !!proposalForm.property &&
    //   Array.isArray(proposalForm.property.organizations)
    //     ? proposalForm.property.organizations
    //     : []

    const selectedClients = getSelectedClients(project)

    //     const handleChangeDescription = (e, idx) => {
    //       const value = { idx, text: e.target.value, source: 'files' }
    //       this.props.onChangeField(value, 'file_description')
    //     }
    //
    //     const handleChangeDescriptionFromCustomer = (e, idx) => {
    //       const value = { idx, text: e.target.value, source: 'files_from_customer' }
    //       this.props.onChangeField(value, 'file_description')
    //     }

    const handlePayBalance = async balance => {
      const activeTab = projectActiveTab

      if (!balance) {
        const newBalance = {
          accept_card: true,
          amount: this.props.totalToPay,
          description: '',
          due_date: moment().format('YYYY-MM-DD'),
          id: 0,
          name_balance: 'Balance',
          payment_method: '',
          position: 0,
          status: 1,
        }

        let payment = {
          entity_id: project.id,
          entity_type: 1,
          id: 0,
          info: '',
          remaining_balance: this.props.totalToPay,
          tab_id: activeTab.id,
          total_balance: this.props.totalToPay,
          payment_balances: [newBalance],
        }

        if (activeTab?.payment?.id) {
          payment = {
            ...activeTab?.payment,
            payment_balances: [newBalance],
          }
          await putPayments(payment?.id, payment)
        } else {
          await postPayments(payment)
        }

        const { payment_balances } = await getPaymentsModal(
          project.id,
          1,
          activeTab.id,
        )
        this.props.fetchPaymentsList(project.id, 1, activeTab.id)

        balance = payment_balances?.[0]
      }

      this.setState(
        {
          selectedBalance: balance,
        },
        () => this.togglePaymentModal(),
      )
    }

    const handleBalancePaid = () => {
      this.initForm()
      // this.props.fetchPaymentsList(project.id, 1, projectActiveTab.id)
    }

    const isProjectHasContacts =
      project.properties?.length ||
      project.clients?.length ||
      project.organizations?.length

    const handleLoadSectionTemplate = async template_id => {
      await this.props.addProjectSection({
        proposal_tab_id: projectActiveTab.id,
        template_id,
        section_id: this.state.sectionId,
        sectionIdx: this.state.sectionIdx,
      })
    }

    const handleProjectTemplateApply = async templateId => {
      this.setState({
        isSaving: true,
      })
      this.props.setProposalTemplate()

      await ProjectAPI.loadProjectTemplate(project.id, templateId).finally(
        () => {
          this.setState({
            isSaving: false,
          })
          this.toggleLoadTemplateModal()
        },
      )
      await this.initForm()
    }

    const handleCustomerInfoUpdate = filesToUpdate => {
      this.props.updateProjectField(filesToUpdate)
    }

    const handleModuleChange = async (
      activeTabIdx,
      moduleIdx,
      filedName,
      value,
    ) => {
      const newModules = [...projectActiveTab.modules]
      const updatingModule = { ...newModules[moduleIdx], [filedName]: value }
      newModules[moduleIdx] = updatingModule

      this.props.updateProjectTabField({
        modules: newModules,
      })
    }

    return (
      <ProjectWrapper>
        {!this.state.loading ? (
          <PageLayout
            className={`proposal-form`}
            // className={`proposal-form ${
            //   project.setting?.compact_view ? 'compact-view' : ''
            // }`}
            preloader={this.state.loading}
          >
            <div
              className={`proposal-form${
                lockActions ? ' proposal-form-transparent' : ''
              }`}
            >
              <Modal
                className={'accounting-modal'}
                width={'90%'}
                closable={false}
                footer={null}
                visible={this.state.modalAccounting}
                centered
                destroyOnClose
                bodyStyle={{
                  marginTop: '10px',
                  overflow: 'scroll',
                  height: '97vh',
                }}
              >
                <ModalAccounting
                  totalToPay={this.props.totalToPay}
                  proposalID={project?.id}
                  closeModal={this.toggleAccountingModal}
                  user={project?.user}
                  projectStatus={project?.status}
                />
              </Modal>
              <Header
                proposalCode={project.code ?? 'No proposal code'}
                proposalName={project.name ?? ''}
                proposalStatus={project.status ?? 1} // 1-estimate
                createdBy={project.user ? project.user.full_name : ''}
                createdDate={project.created_at}
                onChangeProposalStatus={this.onChangeProposalStatus}
                openEmailHistoryModal={this.toggleModalEmailHistory}
                openAccountingModal={this.toggleAccountingModal}
                openProposalCustomerView={this.onOpenPreview}
                isPreviewOpening={this.state.isPreviewOpening}
                copyProposal={this.copyProposal}
                isCopying={this.state.isCopying}
                isStatusChanging={this.state.isStatusChanging}
                openClientActivityModal={this.props.toggleClientActivityModal}
                deleteProposal={this.onDeleteProposal}
                isDeleting={this.state.isDeleting}
                saveProposal={this.onSubmit}
                isSaving={this.state.isSaving}
                closeProposalForm={this.onCancel}
                isCanceling={this.state.isCanceling}
                cancelButton={
                  <CancelButton
                    onClick={this.onCancel}
                    isFetching={this.state.isCanceling}
                    isEdited={project.isEdited}
                  />
                }
                // isCanDeleteProposal={isCanDeleteProposal}
                onChangeField={this.onChangeField}
                disableCompletedStatus={
                  // !this.checkForCanChangeToCompletedStatus()
                  false
                }
                payments={this.props.payments}
                uncompletedNewItemsIds={
                  this.props.proposalForm.uncompletedNewItemsIds
                }
                checkNewItems={this.checkNewItems}
                canceledInfo={canceledInfo}
                contractInfo={contractInfo}
                lockActions={lockActions}
                isEdited={project.isEdited}
                isEditContract={this.state.isEditContract}
                setModalEditContract={() =>
                  this.setState({ modalEditContract: true })
                }
                myRole={myRole}
                toggleConfirmModal={this.toggleConfirmModal}
                isProjectHasContacts={isProjectHasContacts}
                toggleStatusLogModal={this.toggleStatusLogModal}
                toggleQuickbooksModal={this.toggleQuickbooksModal}
                openSettingsModal={() => this.toggleSettingModal(true)}
                handleClickDuplicate={this.handleClickDuplicate}
                proposalId={project.id}
                userId={project?.user_id}
                hadBeenSent={project.had_been_sent}
                hadBeenViewed={project.had_been_viewed}
                showQB={this.state.quickbooksInfo?.data?.show_button}
              />
              <div className='appointmentData'>
                <AppointmentData data={project} />
              </div>

              {/* {proposalForm.industry_id && proposalForm.setting && proposalForm.company?.industries?.length > 1 && (
                <ProposalSettings settings={proposalForm.setting} />
              )} */}

              {/* <ProposalContact isEditContract={this.state.isEditContract} /> */}

              <ContactsTree isEditContract={this.state.isEditContract} />
              <ContactSummary isEditContract={this.state.isEditContract} />

              <div className='proposal-form-follow_up_and_template_btns'>
                <MainButton
                  title='Schedule Follow-up'
                  onClick={this.openFollowUpModal}
                />
                {!!this.props?.permissions?.project_can_read_templates && (
                  <MainButton
                    title='Load Template'
                    className={`${this.state.isEditContract ? 'disabled' : ''}`}
                    onClick={
                      this.state.isEditContract
                        ? () => {}
                        : this.toggleLoadTemplateModal
                    }
                    disabled={project?.status !== 1}
                  />
                )}

                {!!this.props.permissions?.project_can_create_template && (
                  <MainButton
                    title='Save Template'
                    className={`${this.state.isEditContract ? 'disabled' : ''}`}
                    onClick={
                      this.state.isEditContract
                        ? () => {}
                        : this.toggleSaveTemplateModal
                    }
                  />
                )}
              </div>

              <div className='row'>
                <div className='col'>
                  <ProjectTabs
                    modulesNamesFromSettings={{
                      module_one: project?.setting?.module_one,
                      module_two: project?.setting?.module_two,
                      module_three: project?.setting?.module_three,
                    }}
                    openModalPayment={this.openCloseModalPayment}
                    // updateProposal={this.updateProposalCallback}
                    toggleIsTabLoading={this.toggleIsTabLoading}
                    isTabLoading={this.state.isTabLoading}
                    lockActions={lockActions}
                    isEditContract={this.state.isEditContract}
                    toggleSaveTemplateSectionModal={
                      this.toggleSaveTemplateSectionModal
                    }
                    toggleLoadTemplateSectionModal={
                      this.toggleLoadTemplateSectionModal
                    }
                    onSubmit={this.onSubmit}
                    canShowTaxesFromSettings={
                      this.props.canShowTaxesFromSettings
                    }
                    canShowMultipleTabsFromSettings={
                      this.props.canShowMultipleTabsFromSettings
                    }
                    getFileForModalDocViewer={this.getFileForModalDocViewer}
                    onPayBalance={
                      this.props.hasActiveMerchant && handlePayBalance
                    }
                    jobCreated={this.state.jobCreated}
                  />
                </div>
              </div>

              {this.props.canShowInformationFromSettings && (
                <>
                  {' '}
                  <div className='proposal-form-information_for_customer'>
                    <CustomerInformation
                      dataSource={this.props.project}
                      label='Information for customer'
                      notesFieldName='info_for_customer'
                      filesFieldName='files'
                      getFileForModalDocViewer={this.getFileForModalDocViewer}
                      onUpdate={handleCustomerInfoUpdate}
                      filesLimit={100}
                    />
                  </div>
                  <div className='proposal-form-information_for_customer'>
                    <CustomerInformation
                      dataSource={this.props.project}
                      label='Information from customer'
                      notesFieldName='info_from_customer'
                      filesFieldName='files_from_customer'
                      getFileForModalDocViewer={this.getFileForModalDocViewer}
                      restrictUpload
                      onUpdate={handleCustomerInfoUpdate}
                      filesLimit={100}
                    />
                    {/* <CustomerInfoBlock
                      fieldValue={
                        proposalForm.info_from_customer
                          ? proposalForm.info_from_customer
                          : ''
                      }
                      onChangeTextField={this.onChangeField}
                      deleteFile={this.onDeleteCustomerFile}
                      onChangeDescription={handleChangeDescriptionFromCustomer}
                      files={filesFromCustomer}
                      // openFileModal={this.openCustomerFilesModal(filesFromCustomer)}
                      placeholder='Information from customer'
                      fieldName='info_from_customer'
                      buttonTitle='Upload File'
                      getFileForModalDocViewer={this.getFileForModalDocViewer}
                    /> */}
                  </div>
                </>
              )}
              <div className='proposal-form-footer_buttons_block'>
                <TableActionsNew {...tableActionPropsForFooter} />
                <div className='d-flex'>
                  {/* <button onClick={this.onCancel}>Cancel</button> */}
                  <CancelButton
                    onClick={this.onCancel}
                    isFetching={this.state.isCanceling}
                    isEdited={project.isEdited}
                  />
                  {/* <MainButton
                    title='Cancel'
                    type='cancel'
                    onClick={this.onCancel}
                    isFetching={this.state.isCanceling}
                  /> */}
                  {/* {project.isEdited && (
                    <Popconfirm
                      title='You have unsaved items. Please check to proceed.'
                      onConfirm={this.checkNewItems}
                      onCancel={() =>
                        this.onSubmit('reset uncompleted new items')
                      }
                      okText='Check'
                      cancelText='Save anyway'
                      className='payment-balance-confirm'
                      disabled={
                        !this.props.proposalForm.uncompletedNewItemsIds?.length
                      }
                    >
                      <MainButton
                        title='Save'
                        onClick={
                          !isProjectHasContacts
                            ? this.toggleConfirmModal
                            : !this.props.proposalForm.uncompletedNewItemsIds
                                ?.length
                            ? this.onSubmit
                            : () => null
                        }
                        isFetching={this.state.isSaving}
                      />
                    </Popconfirm>
                  )} */}
                </div>
              </div>
            </div>

            {this.props.proposalForm?.setting?.require_signature &&
              [
                ProjectStatusGroup.Contract,
                ProjectStatusGroup.Completed,
                ProjectStatusGroup.Canceled,
              ].includes(project.status) &&
              project?.activeTabId === project?.signed_tab_id && (
                <SignatureBlock
                  contractInfo={contractInfo}
                  editProjectView
                  projectStatus={project?.status}
                />
              )}

            <ConfirmModal
              title='Project contacts'
              onConfirm={() => {
                this.onSubmit('reset uncompleted new items')
                this.toggleConfirmModal()
              }}
              onCancel={this.toggleConfirmModal}
              okText='Save anyway'
              cancelText='Cancel'
              content='There are no contacts associated with this project. Please add contacts and properties to establish relationships and keep your network up to date.'
              type='warning'
              visible={this.state.confirmModal}
            ></ConfirmModal>

            <Modal
              className={'paymenta-modal'}
              width='auto'
              // closable={false}
              title={`Payment for Project - ${project.code}`}
              footer={null}
              visible={this.state.paymentModal}
              centered
              destroyOnClose
              // bodyStyle={{ marginTop: '10px' }}
              onCancel={this.togglePaymentModal}
              maskClosable={false}
            >
              <PaymentProcess
                balance={this.state.selectedBalance}
                cardInfo={{
                  owner_user_id: project.owner_user_id,
                  card_holder: this.props.primaryClient?.name || '',
                  billing_address: {
                    address: this.props.primaryProperty?.address || '',
                    city: this.props.primaryProperty?.city || '',
                    region: states[this.props.primaryProperty?.state] || '',
                    postal: this.props.primaryProperty?.postcode || '',
                  },
                }}
                transactionFee={+project?.setting?.payment_processing_fee}
                settings={{
                  disableTips: project?.setting?.disable_tips,
                }}
                onCancel={this.togglePaymentModal}
                onPaid={handleBalancePaid}
                payer={this?.props?.me?.full_name}
              />
            </Modal>

            <ModalWrapper
              modal={this.state.wasSigned}
              okText='Reload Page'
              close={this.handleReaload}
              apply={this.handleReaload}
              hideCancelBtn
            >
              <p
                style={{
                  textAlign: 'center',
                  marginBottom: 20,
                  fontSize: 22,
                  fontWeight: 500,
                }}
              >
                Proposal {this.props.proposalForm.code} was signed.
                <br /> Page will be reloaded
              </p>
            </ModalWrapper>

            <ModalCancelContract
              type='to estimate'
              show={this.state.modalEstimate}
              onSave={() => {
                this.setState({ isStatusChanging: true })
                this.props
                  .updateProposal(
                    'delete signature',
                    1,
                    '',
                    undefined,
                    this.state.saveJustStatus,
                  )
                  .then(() => {
                    this.toggleModalEstimate()
                  })
                  .catch(error => {
                    this.setState({ isStatusChanging: false })
                    this.updateProposalErrorsCatcher(error)
                  })
              }}
              onHide={this.toggleModalEstimate}
              isStatusChanging={this.state.isStatusChanging}
            />
            <ModalCancelContract
              type='to canceled'
              show={this.state.modalCanceled}
              onSave={() => {
                this.setState({ isStatusChanging: true })
                this.props
                  .updateProposal(
                    'delete signature',
                    4,
                    this.state.canceled_reason,
                    undefined,
                    this.state.saveJustStatus,
                  )
                  .then(() => {
                    this.toggleModalCanceled()
                  })
                  .catch(error => {
                    this.setState({ isStatusChanging: false })
                    this.updateProposalErrorsCatcher(error)
                  })
              }}
              onHide={this.toggleModalCanceled}
              isStatusChanging={this.state.isStatusChanging}
              cancelationReason={this.state.canceled_reason}
              onChangeCancelationReason={this.onChangeCancelationReason}
            />
            <ModalCancelContract
              type='to edit contract'
              show={this.state.modalEditContract}
              onSave={async () => {
                if (project.signature) {
                  this.setState({ isStatusChanging: true })
                  await this.props.resetProjectSignature(project.id)

                  this.setState({
                    isEditContract: true,
                    modalEditContract: false,
                    isStatusChanging: false,
                  })
                  this.props.setEditContract(true)
                } else {
                  this.setState({
                    isEditContract: true,
                    modalEditContract: false,
                  })
                  this.props.setEditContract(true)
                }
              }}
              onHide={() => this.setState({ modalEditContract: false })}
              isStatusChanging={this.state.isStatusChanging}
              isSignature={!!project.signature}
            />
            <ModalReminderList
              showFollowUp={this.state.showFollowUp}
              onCloseModal={this.onCloseModal}
              entityType='1'
            />
            <ModalScheduleAutomaticFollowUp />

            {!this.state.isTabLoading && (
              <>
                {this.props.modalSuppliesIsOpen && (
                  <ModalOrderEquipmentNew status={projectActiveTab?.status} />
                )}
                <PaymentSchedule
                  isOpen={this.state.isModalPaymentOpen}
                  openCloseModalPayment={this.openCloseModalPayment}
                  close={this.handleModal}
                  tabId={
                    this.props.proposalForm.tabs_info
                      ? this.props.proposalForm.tabs_info[
                          this.props.proposalForm.activeTab
                        ]?.id
                      : 0
                  }
                  entityType={1}
                  totalToPay={this.props.totalToPay}
                  entityId={this.props.proposalForm.id}
                  payments={this.props.payments}
                  changePaymentStatus={payment_status =>
                    this.props.updateProjectTabField({
                      payment_status,
                    })
                  }
                  hasActiveMerchant={this.props.hasActiveMerchant}
                  proposalStatus={project.status}
                />
                <ModalReminderContainer
                  module={0}
                  onChange={handleModuleChange}
                  // onChange={this.props.onChangeOptionalModuleValues}
                  activeTab={this.props.proposalForm.activeTab}
                  values={projectActiveTab?.modules?.[0]}
                  onReset={this.props.onClearOptionalModuleValues}
                  // onSave={this.props.updateOptionalModules}
                  proposalStatus={project.status}
                />
                <ModalReminderContainer
                  module={1}
                  onChange={handleModuleChange}
                  activeTab={this.props.proposalForm.activeTab}
                  values={projectActiveTab?.modules?.[1]}
                  onReset={this.props.onClearOptionalModuleValues}
                  // onSave={this.props.updateOptionalModules}
                  proposalStatus={project.status}
                />
                <ModalReminderContainer
                  module={2}
                  onChange={handleModuleChange}
                  activeTab={this.props.proposalForm.activeTab}
                  values={projectActiveTab?.modules?.[2]}
                  onReset={this.props.onClearOptionalModuleValues}
                  // onSave={this.props.updateOptionalModules}
                  proposalStatus={project.status}
                />
              </>
            )}

            {this.state.showSettingsModal && (
              <ModalProposalSetting
                isOpenModal={this.state.showSettingsModal}
                // onCancelModal={this.onCancelVisibilityItems}
                onSubmit={this.onSubmitVisibilityItems}
                toggleSettingModal={this.toggleSettingModal}
                isEditContract={this.state.isEditContract}
              />
            )}

            <ModalFileUpload
              files={this.state.currentFiles}
              handleSave={this.onSubmit}
              handleCancel={this.onCancel}
            />
            <ModalProposalTemplate
              title={'Save template'}
              modal={this.state.saveTemlateModal}
              onCancel={this.toggleSaveTemplateModal}
              onSave={this.saveProposalTemplate}
              me={this.props.me}
            />
            <ModalProposalSectionTemplate
              title={'Save section template'}
              modal={this.state.saveTemplateSectionModal}
              onCancel={this.toggleSaveTemplateSectionModal}
              onSave={this.saveProposalSectionTemplate}
              me={this.props.me}
            />

            {this.state.loadTemlateModal && (
              <ProjectTemplates
                onCancel={this.toggleLoadTemplateModal}
                onProjectTemplateAplly={handleProjectTemplateApply}
                isLoading={this.state.isSaving}
              />
            )}

            <ModalProposalSectionTemplateLoad
              title={'Load section template'}
              modal={this.state.loadTemplateSectionModal}
              onCancel={this.toggleLoadTemplateSectionModal}
              setProposalTemplate={this.props.setProposalTemplate}
              updateUserTemplate={this.props.updateUserTemplate}
              me={this.props.me}
              proposalSettings={this.props.proposalSettings}
              // updateProposal={this.updateProposalCallback}
              proposalStatus={project?.status ?? 1}
              sectionId={this.state.sectionId}
              proposalForm={project}
              initForm={this.initForm}
              activeTab={this.props.activeTab}
              setSectionTemplate={this.props.setSectionTemplate}
              // onSubmit={this.onSubmit}
              onLoadSectionTemplate={handleLoadSectionTemplate}
              onDeleteSection={this.props.onDeleteSection}
              sectionIdx={this.state.sectionIdx}
            />
            {/* <ModalInstallationDate save={this.onSubmit} /> */}
            <ModalDrawing show={false} />
            <ModalProposalEmails show={false} onCancel={() => {}} />

            <Modal
              title={`Create New ${this.props.company.dictionary.job.singular}`}
              width={750}
              footer={null}
              visible={this.props.jobModal}
              centered
              destroyOnClose
              onCancel={this.props.toggleInstallationDate}
              maskClosable={false}
            >
              <JobForm
                proposalId={project.id}
                onJobCreated={this.handleJobCreate}
                onCancel={this.props.toggleInstallationDate}
              />
            </Modal>

            <ModalClientActivity activity={project.activity} />

            <ModalProjectHistory
              handleCancel={this.toggleStatusLogModal}
              handleOk={this.toggleStatusLogModal}
              isModalOpen={this.state.statusLogModal}
              proposalId={project.id}
            />

            {this.state.quickbooksModal && (
              <ProjectQuickbooks
                onCancel={this.toggleQuickbooksModal}
                onSync={this.handleQuickbooksSync}
                data={this.state.quickbooksInfo.data}
                isSyncing={this.state.quickbooksInfo.isSyncing}
              />
            )}

            <ModalConfirmIfThereIsNotSavedNewItems
              onSave={() => this.onSubmit('reset uncompleted new items')}
              show={this.state.showUncompletedNewItemsModal}
              onHide={this.closeModalConfirmUncompletedNewItems}
            />

            {this.state.showEmailModal && (
              <NotificationModal
                title='Send Project'
                showModal={this.state.showEmailModal}
                setShowModal={() => this.toggleModalEmailHistory()}
                templateType={NotificationTemplateType.PROJECT}
                notificationType={NotificationModalType.PROJECT}
                proposalForm={project}
                clients={selectedClients}
              />
            )}

            <SureModal
              modal={!!this.state.statusChangingModal}
              close={this.toggleStatusChangingModal(false)}
              apply={this.updateProposalOnStatusChanging(
                this.state.statusChangingModal,
              )}
              title='Do you want to save the changes?'
              okTitle='Confirm'
              cancelTitle='Cancel'
              //customCancelBtnAction={this.updateProposalOnStatusChanging(this.state.statusChangingModal, true)}
              isLoading={this.state.isStatusChanging}
            />
            <BlockRoutingModal
              status={!!this.state.blockRoutingModal}
              handleCancel={() =>
                this.setState({ blockRoutingModal: false, nextLocation: '' })
              }
              handleDiscard={this.onCancel}
              handleSave={this.onSubmit}
              title='You have some unsaved changes.'
              buttonsTitle={{
                save: 'Save and continue',
                discard: 'Discard and continue',
                cancel: 'Cancel',
              }}
              isLoading={this.state.isSaving}
            />
            <Prompt
              when={
                this.props.proposalForm.isEdited &&
                !this.state.isDeleting &&
                !this.state.blockRoutingModal
              }
              message={this.handleBlockedRoute}
            />

            <DocViewer
              documents={this.state.documents}
              currentDocument={this.state.currentFileIdx}
              isModalVisible={this.state.showDocViewerModal}
              handleCancel={this.toggleOpenDocViewerModal}
            />

            <ModalDuplicateProject
              visible={this.state.duplicateModal}
              onClose={this.toggleDuplicateModal}
              proposalID={project.id}
              handleDuplicate={this.copyProposal}
              proposalCode={project?.code}
              assignedTo={project?.user?.id}
            />
          </PageLayout>
        ) : null}
      </ProjectWrapper>
    )
  }
}

const mapStateToProps = state => ({
  project: state.orcatec.projectSlice.project,
  projectActiveTab: state.orcatec.projectTabsSlice.projectTab,
  modalBillingAddress: state.orcatec.modalBillingAddress.isOpen,
  proposalForm: state.orcatec.proposalForm,
  proposalTemplates: state.orcatec.proposalTable.templates,
  proposalModalFiles: state.orcatec.proposalModalFiles,
  proposalSettings: state.orcatec.proposal,
  activeTab: state.orcatec.proposalForm.activeTab,
  jobModal: state.orcatec.installationDate.show,
  me: state.orcatec.user.me,
  modalSuppliesIsOpen: state.orcatec.modalEquipment.isOpen,
  company: state.orcatec.company,
  relatives: state.orcatec.proposalForm.relatives,
  totalToPay: selectProjectTotal(state),
  payments: state.orcatec.modalPayment.payments,
  hasActiveMerchant: hasActiveMerchant(state),
  ownerId: state.orcatec.company.owner_id,
  isProposalAndAppointmentIndustryNeeded:
    state.orcatec.app.isProposalAndAppointmentIndustryNeeded !== false,
  canShowTaxesFromSettings: state.orcatec.proposalForm?.setting?.show_taxes,
  canShowInformationFromSettings:
    state.orcatec.proposalForm?.setting?.show_information,
  canShowMultipleTabsFromSettings:
    state.orcatec.proposalForm?.setting?.multiple_tabs,
  canShowSectionItemsFromSettings:
    state.orcatec.proposalForm?.setting?.hide_section_items,
  companyId: state.orcatec.company.id,
  permissions: state.orcatec.permissions?.current?.project,
  primaryProperty: selectPrimaryProjectProperty(state),
  primaryClient: selectPrimaryProjectClient(state),
})

const mapDispatchToProps = {
  addEmailToClient: actions.proposalForm.addEmailToClient,
  addProjectSection: addProjectSection,
  changeOrderEquipmentStatus: actions.proposalForm.changeOrderEquipmentStatus,
  changePaymentStatus: actions.proposalForm.changePaymentStatus,
  // changePaymentStatus: actions.proposalForm.changePaymentStatus,
  clearCustomerInfo: actions.proposalForm.clearCustomerInfo,
  clearDeleteEntities: () => ({ type: 'PROPOSAL_CLEAR_ID_TO_DELETE' }),
  clearSignatureField: actions.proposalForm.clearSignatureField,
  copyProposal: actions.proposalForm.copyProposal,
  createRelationProperty: actions.client.createRelationProperty,
  deleteCustomerFiles: actions.proposalForm.deleteCustomerFiles,
  deleteFormFiles: actions.proposalForm.deleteFormFiles,
  deleteProprosal: actions.proposalTable.deleteProprosal,
  deleteRelationProperty: actions.client.dellAttachedProperty,
  deleteSectionItems: actions.proposalForm.deleteSectionItems,
  deleteTab: actions.proposalForm.deleteTab,
  delProposalTemplate: actions.proposalTable.delProposalTemplate,
  fetchPaymentsList: actions.payments.fetchPaymentsList,
  fetchProperty: actions.property.fetchProperty,
  fetchProposalSettings: actions.proposal.fetchProposal,
  fetchSubscription: actions.subscription.fetchSubscription,
  getAllTabs: actions.proposalForm.getAllTabs,
  getIndustry: actions.proposalForm.getIndustry,
  getProposalTemplates: actions.proposalTable.getProposalTemplates,
  initProposalForm: actions.proposalForm.initProposalForm,
  onChangeField: actions.proposalForm.onChangeField,
  onChangeOptionalModuleValues:
    actions.proposalForm.onChangeOptionalModuleValues,
  onClearOptionalModuleValues: actions.proposalForm.onClearOptionalModuleValues,
  onDeleteSection: actions.proposalForm.onDeleteSection,
  orderEquipment: actions.proposalForm.orderEquipment,
  resetItemsGroup: resetGroupItems,
  resetProjectItemsSlice: resetProjectItemsSlice,
  resetProjectContactSlice: resetProjectContactSlice,
  resetProposalForm: actions.proposalForm.resetProposalForm,
  resetProjectSignature: actions.proposalForm.resetSignature,
  resetUncompletedNewItemId: actions.proposalForm.resetUncompletedNewItemId,
  saveProposalTemplate: actions.proposalTable.saveProposalTemplate,
  setDefaultUserSetting: actions.proposalForm.setDefaultUserSetting,
  setEditContract: setEditContract,
  setPayments: actions.payments.setPayments,
  setProposalTemplate: actions.proposalTable.setProposalTemplate,
  setSectionTemplate: actions.proposalForm.setSectionTemplate,
  toggleAttachmentModal: actions.proposalModalFiles.toggleModal,
  toggleClientActivityModal: actions.clientActivity.toggleModal,
  toggleInstallationDate: actions.installationDate.toggleModal,
  toggleModalBillingAddress: actions.modalBillingAddress.openModal,
  updateOptionalModules: actions.proposalForm.updateOptionalModules,
  updateProposal: actions.proposalForm.updateProposal,
  updateProposalTemplate: actions.proposalTable.updateProposalTemplate,
  updateUserTemplate: actions.user.updateUserTemplate,
  updateProjectField: updateProjectField,
  updateProjectTabField: updateProjectTabField,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
