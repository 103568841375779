import React from 'react'
import MainButton from '../buttons/MainButton'

import './ErrorBoundary.scss'
import Fallback from './Fallback'
import * as Sentry from '@sentry/react'

class ErrorBoundary extends React.Component {
  state = {
    error: false,
    errorInfo: false,
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.errorInfo) {
      Sentry.captureException(this.state.error)
      Sentry.captureMessage(this.state.errorInfo)

      return <p>Ooops! Something went wrong</p>
    }
    return this.props.children
  }
}

export default ErrorBoundary
