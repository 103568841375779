import moment, { Moment } from 'moment-timezone'

export const getAmericanTime = (time: Moment | string | null) => {
  if (!time) return ''

  const configuredTime =
    moment(time, 'HH:mm').minutes() === 0 ? moment(time, 'HH:mm').format('h a') : moment(time, 'HH:mm').format('h:mm a')
  const config = (t: string) =>
    t
      .replace(' ', '')
      .slice(0, -1)
      .toLowerCase()
  return config(configuredTime)
}

export const cutString = (str: string, stringLength: number) =>
  str?.length > stringLength ? `${str.substring(0, stringLength)}` : str

export const getEventTimeStatus = (date: string) =>
  moment
    .utc(date)
    .local()
    .isBefore(moment().endOf('day'))
    ? 'past'
    : moment
        .utc(date)
        .local()
        .isBetween(
          moment().endOf('day'),
          moment()
            .add(1, 'day')
            .endOf('day'),
        )
    ? 'actual'
    : 'future'
