import { FC } from 'react'

import { SummaryItems } from './components/SummaryItems/SummaryItems'
import styled from 'styled-components'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  // selectSummaryContact,
  selectContactStatus,
} from '../slices/projectContactsSlice'
// import { useIsFreezed } from './hooks/useIsFreezed'
import { Contact, EntityType, ProjectProperty } from '../types'
import { Skeleton } from 'antd'

interface IProps {
  disabled: boolean
  isWorkOrder?: boolean
  properties: ProjectProperty[]
  clients: Contact[]
}

export const ContactSummary: FC<IProps> = ({
  clients,
  disabled,
  isWorkOrder,
  properties,
}) => {
  // const { id: projectId } = useParams()
  // const freezed = useIsFreezed(isEditContract)
  // const dispatch = useAppDispatch()
  const status = useAppSelector(selectContactStatus)

  /* const { organizations, properties, clients } = useAppSelector(
    state => state.orcatec.proposalForm,
  ) */

  // useEffect(() => {
  //   if (projectId) dispatch(getRelationContactToProject({ projectId }))
  // }, [projectId])

  return (
    <Wrapper>
      <WrapperInner>
        <Skeleton active loading={status === 'loading' && clients?.length}>
          <SummaryItems
            entityType={EntityType.CLIENT}
            freezed={disabled}
            items={clients?.filter(item => item.display)}
            title='Client'
            isWorkOrder={isWorkOrder}
          />
        </Skeleton>
      </WrapperInner>
      <WrapperInner>
        <Skeleton active loading={status === 'loading' && !!properties?.length}>
          <SummaryItems
            entityType={EntityType.PROPERTY}
            freezed={disabled}
            items={properties?.filter(item => item.display)}
            title='Address'
            isWorkOrder={isWorkOrder}
          />
        </Skeleton>
      </WrapperInner>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const WrapperInner = styled.div``
