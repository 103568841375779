import { InputAdornment, TextField } from '@material-ui/core'

import { Badge, Popconfirm, Tooltip, Checkbox } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteProposalInsightsItem } from '../../../../../../../../../api/ProposalAccounting'
import {
  priceToView,
  textFieldPriceFormatter,
  thousandSeparator,
} from '../../../../../../../../../helpers/thousandSeparator'
import { getProposalInsightsData } from '../../../../../../../../../store/Orcatec/actions/proposal/proposalForm'
import { toggleModal } from '../../../../../../../../../store/Orcatec/actions/proposal/proposalModalFiles'
import MainButton from '../../../../../../components/buttons/MainButton'
import { DeleteIcon } from '../../../../../../components/Icons/CommonIcons'
import InputField from '../../../../../../components/Input'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

import { Suggestion } from '../Suggestion/Suggestion'

import { makeStyles } from '@material-ui/styles'
import { Attachments } from '../Attachments/Attachments'

import './RowItem.scss'

const useStyles = makeStyles({
  customTextField: {
    '& .MuiFormLabel-root': {
      fontSize: '0.8rem',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      fontSize: '1rem',
    },
    '& .MuiFormLabel-root.MuiFormLabel-filled': {
      fontSize: '1rem',
    },
  },
})

const RowItem = ({
  rowTitle = '',
  listData = [],
  setListData = () => {},
  total = Number,
  setIsProposalInsightsTouched = () => {},
  proposalID = 0,
  setIsLoading = () => {},
  saveItemsBeforeFileUpload = () => {},
  canEdit = true,
  message,
  type,
  canAdd,
  isProjectCompleted,
  canView,
  canDelete,
  canDeleteSuggestion,
  canAddSuggestion,
}) => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const handleDeleteWithPermissions = handler => {
    return (itemID, itemIDX, rowTitle) => {
      if (isProjectCompleted) {
        setIsProposalInsightsTouched(false)
        return openNotificationWithIcon('warning', {
          message,
          key: 'commission',
          maxCount: 2,
        })
      }
      if (canDelete || (!canDelete && !itemID)) {
        return handler(itemID, itemIDX, rowTitle)
      }
      setIsProposalInsightsTouched(false)
      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
  }
  const handleChangeWithPermissions = handler => {
    return (event, idx, id) => {
      if (isProjectCompleted) {
        setIsProposalInsightsTouched(false)
        return openNotificationWithIcon('warning', {
          message,
          key: 'commission',
          maxCount: 2,
        })
      }

      if (canEdit) {
        return handler(event, idx, id)
      }
      if (!canEdit && canAdd && !id) {
        return handler(event, idx, id)
      }
      setIsProposalInsightsTouched(false)
      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
  }
  const handleChangeFilesWithPermissions = handler => {
    return (itemID, files, id) => {
      if (isProjectCompleted) {
        setIsProposalInsightsTouched(false)
        return openNotificationWithIcon('warning', {
          message,
          key: 'commission',
          maxCount: 2,
        })
      }
      if (canEdit) {
        return handler(itemID, files)
      }
      if (canAdd && !id) {
        return handler(itemID, files)
      }
      setIsProposalInsightsTouched(false)
      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
  }

  const toggleFilesModal = (itemID = Number, files = []) => {
    setIsProposalInsightsTouched?.(true)
    saveItemsBeforeFileUpload?.()
    dispatch(
      toggleModal(
        'accounting',
        files,
        undefined,
        undefined,
        itemID,
        undefined,
        undefined,
        undefined,
        rowTitle,
      ),
    )
  }

  const handleDelete = (itemID, itemIDX, rowTitle) => {
    setIsLoading?.(true)
    if (itemID === undefined) {
      setListData?.(prevState =>
        prevState.filter((el, elIDX) => elIDX !== itemIDX),
      )
      setIsLoading?.(false)
    } else {
      // saveItemsBeforeFileUpload?.()
      let data = {
        expenses: [],
        advertising: [],
        appointment_rates: [],
        work_date_rates: [],
        pre_net_expenses: [],
      }
      if (rowTitle === 'Expenses') data.expenses = [itemID]
      else if (rowTitle === 'Advertising Cost') data.advertising = [itemID]
      else if (rowTitle === 'Pre Net Expenses') data.pre_net_expenses = [itemID]
      setListData?.(prevState =>
        prevState.filter((el, elIDX) => el.id !== itemID),
      )
      deleteProposalInsightsItem(proposalID, data)
        .then(() => {
          openNotificationWithIcon('success', {
            message: 'Deleted successfully',
          })
        })
        .catch(error => {
          openNotificationWithIcon('error', { message: 'Something went wrong' })
          dispatch(getProposalInsightsData(proposalID))
        })
        .finally(() => {
          setIsLoading?.(false)
        })

      // saveItemsBeforeDeleting?.()
      //   .then(() => deleteProposalInsightsItem(proposalID, data))
      //   .then(() => dispatch(getProposalInsightsData(proposalID)))
      //   .then(() => setIsLoading?.(false))
    }

    // setIsProposalInsightsTouched?.(true)
  }

  const handleAddRow = () => {
    if (!canAdd) {
      setIsProposalInsightsTouched(false)
      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
    setIsProposalInsightsTouched(true)
    setListData(prevState => [
      ...prevState,
      { title: '', price: '', isMy: true, description: '' },
    ])
  }

  const handleChange = (event, idx, id) => {
    setIsProposalInsightsTouched(true)
    const { name, value, checked } = event.target
    let newArray = [...listData]
    newArray.map((item, index) => {
      if (index === idx) {
        if (name === 'price') {
          item[name] = textFieldPriceFormatter(value)
        } else item[name] = name === 'is_reimbursed' ? checked : value
        setListData(newArray)
      }
    })
  }

  const toFixed = (number, digitsAfterDot) => {
    const str = `${number}`
    if (str?.includes('.')) {
      return str?.slice(0, str?.indexOf('.') + digitsAfterDot + 1) || number
    }
    return number
  }

  const decoratedHandleDelete = handleDeleteWithPermissions(handleDelete)
  const decoratedHandleChange = handleChangeWithPermissions(handleChange)
  const decoratedHandleChangeFiles = handleChangeFilesWithPermissions(
    toggleFilesModal,
  )

  const checkIsDisabled = id => {
    if (isProjectCompleted) false
    if (canEdit) return false
    if (canAdd && canEdit) false
    if (!canEdit && canAdd && !id) return false
    return true
  }

  return (
    <>
      <p className={'row-title'}>{rowTitle}</p>

      {listData.map((item, itemIDX) => (
        <div className='row-container__outer' key={itemIDX}>
          <div className='row-container'>
            <Suggestion
              name='title'
              item={item}
              handleChange={event =>
                decoratedHandleChange(event, itemIDX, item?.id)
              }
              type={type}
              label={`${rowTitle} item`}
              canDeleteSuggestion={canDeleteSuggestion}
              canAddSuggestion={canAddSuggestion}
              disableCRUD={checkIsDisabled(item.id)}
            />
            {rowTitle === 'Expenses' && (
              <Tooltip
                style={{ display: 'flex' }}
                title='Parts for reimbursement'
              >
                <Checkbox
                  name='is_reimbursed'
                  checked={item?.is_reimbursed}
                  onChange={event =>
                    decoratedHandleChange(event, itemIDX, item?.id)
                  }
                ></Checkbox>
              </Tooltip>
            )}
            <InputField
              name={'price'}
              value={thousandSeparator(item.price, true)}
              onChange={event =>
                decoratedHandleChange(event, itemIDX, item?.id)
              }
              size={'small'}
              variant={'outlined'}
              label='Price'
              className={'row-price-input num'}
              disabled={checkIsDisabled(item?.id)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$ </InputAdornment>
                ),
                maxLength: 12,
                readOnly: checkIsDisabled(item?.id),
              }}
            />
            <div className={'row-actions'}>
              <>
                {/* <Tooltip
                  title={'Add files'}
                  placement={'top'}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  <Badge
                    size='small'
                    color={'#4C53EF'}
                    count={item?.attachedFiles?.length}
                  >
                    <i
                      className={'fas fa-paperclip'}
                      onClick={() =>
                        decoratedHandleChangeFiles(
                          itemIDX,
                          item.attachedFiles,
                          item?.id,
                        )
                      }
                    />
                  </Badge>
                </Tooltip> */}
                <Tooltip
                  title={'Delete item'}
                  placement={'top'}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  <Popconfirm
                    title={
                      'Save all items before deleting! Are you sure to delete this item?'
                    }
                    onConfirm={() =>
                      decoratedHandleDelete(item.id, itemIDX, rowTitle)
                    }
                    okText={'Yes'}
                    cancelText={'No'}
                  >
                    <div className={'delete-action'}>
                      <DeleteIcon />
                    </div>
                  </Popconfirm>
                </Tooltip>
              </>
            </div>
          </div>

          <div
            style={{ paddingTop: '10px', width: '91.5%' }}
            className='row-container'
          >
            <TextField
              name='description'
              label={`${rowTitle} Description`}
              value={item?.description}
              onChange={event =>
                decoratedHandleChange(event, itemIDX, item?.id)
              }
              variant={'outlined'}
              multiline
              minRows={3}
              maxRows={3}
              fullWidth
              disabled={checkIsDisabled(item?.id)}
              classes={{ root: styles.customTextField }}
              InputProps={{
                readOnly: checkIsDisabled(item?.id),
              }}
            />
          </div>
          <div className='row-container'>
            <Attachments
              tooltip
              disabled={isProjectCompleted}
              data={item?.attachedFiles}
              onUpload={() =>
                decoratedHandleChangeFiles(
                  itemIDX,
                  item.attachedFiles,
                  item?.id,
                )
              }
            />
          </div>
        </div>
      ))}
      <div className={'row-container'}>
        <MainButton
          disabled={isProjectCompleted}
          title={`Add ${rowTitle.toLowerCase()}`}
          onClick={handleAddRow}
        />
      </div>
      {listData?.length > 0 && (
        <div className='total-line'>
          <span className='total-line-title'>Total {rowTitle}:</span>
          <span className='total-line-value'>
            {priceToView(toFixed(total, 2))}
          </span>
        </div>
      )}
    </>
  )
}

export default RowItem
