import styled from 'styled-components'

export const Wrapper = styled.div`
  & .create-appointment-form__buttons_fix.new {
    justify-content: end;
  }
  padding: 10px 10px 10px 0;
  max-width: 1400px;
`
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  max-width: 800px;
  padding-right: 10px;
  & .button-custom-secondary:focus {
    background-color: #edeffe;
    color: #4285f4;
  }
  & .rfr-button {
    height: 30px;
    display: flex;
    align-items: center;
  }
`

export const IndividualPrintStyle = `
button {
  display: none;
  height:0;
  visibility: hidden;
}

.proposal-modules{
 gap:20px;
 margin-right:30px;
} 


h4 {
width:0;
visibility: hidden;
}
.component_to_print {
 padding-right:80px;
}
.appointment-section__body{
width: 100%;
display: grid !important;;
grid-template-columns: 2fr 1fr !important;
gap:30px;
justify-content: center;
}
.appointment-section__main-info{
 grid-row:1;
}
.appointment-statuses-wrapper,.proposal-modules{
  display:none !important;
}

 p, th,td{
  font-size: calc(100% + 4px) !important;
}

.component_to_print p>span{
  font-size: calc(100% + 4px) !important;
}
.appointment-section__main-status{
  display:none !important;
}
.ant-picker input  {
  font-size: calc(100% + 7px) !important;
  height: 44px !important;
}
input, .section-name ,.dnd-items-header {
  font-size: calc(100% + 7px) !important;
}
.MuiOutlinedInput-inputMarginDense{
  font-size: calc(100% + 7px) !important;
  padding: 10px !important;
}
.description  span{
  font-size: 20px !important;
}

.appointment-section__main-info{
  display:none !important;
}
.appointment-info{
  display:none !important;
}
.appointment-date{
  display:none !important;
}
.job_view .proposal-contacts__print{
  display:none !important;
}

.appointment-section__body_toPrint {
  display:block !important;
}
.MuiFormControl-root MuiTextField-root orcatec-input  MuiFormControl-fullWidth{
  display:none !important;
}

.proposal-form-tabs_content-info_block-installers_info .field-label{
  top:-20px !important;
  font-weight:bold;
}
.heading_for-print,.block-installers_info__input,.file-description{
  display:none !important;
}

.visible_for-print {
  display:block !important;
}
.placeholder{
  font-weight:bold;
  font-size: 21px !important;
}
.proposal-form-tabs_content-info_block-installers_info-file{
  align-items: center !important;;
  border: 1px solid rgb(214, 214, 214) !important;
}
.tags div {
  display:none !important;
}
.map{
  display:none !important;
}
.tabss-list-item{
  width:160px !important;
}
.tabss-list-item-label{
  font-size: 21px !important;
}
  .note-text{
  font-size:21px !important;
}
.proposal-contacts__print div{
  flex-wrap: wrap;
}

`
export const Heading = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;
  h1 {
    display: none;
  }

  h4 {
    font-size: 21px;

    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
`
export const Buttons = styled.div`
  display: flex;
  gap: 10px;
`
