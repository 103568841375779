import styled from 'styled-components'

export const Wrapper = styled.div`
  .total_amount {
    margin-bottom: 10px;
    align-self: flex-start;

    span {
      font-size: 20px;
      font-weight: 700;
      padding-right: 5px;
    }
  }
`
export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const Switcher = styled.div`
  .ant-switch {
    background-color: #4285f4;
  }
`
