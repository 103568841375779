import { Companies } from 'types/Company'
import { ContentWrapper, Wrapper } from './ProjectPreview.styles'
import Heading from './components/Heading'
import ProjectPreviewContent from './components/ProjectPreviewContent'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectProject } from 'features/Project/projectSelectors'
import SoCalForm from './components/SoCalForm/SoCalForm'

export const ProjectPrevew = () => {
  const project = useAppSelector(selectProject)

  return (
    <Wrapper>
      <Heading />

      <ContentWrapper>
        {[Companies.SoCal].includes(project?.user?.company_id) ? (
          <SoCalForm />
        ) : (
          <ProjectPreviewContent />
        )}
      </ContentWrapper>
    </Wrapper>
  )
}
