import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  // getIndustry,
  onSetField,
} from '../../../../../../../store/Orcatec/actions/proposal/proposalForm'
import MainButton from '../../../../../Orcatec/components/buttons/MainButton'
import { Switch, Modal, Select, Spin, Popconfirm, Tooltip } from 'antd'

import InputField from 'containers/MainContent/Orcatec/components/Input'
import { SettingsRow } from 'containers/MainContent/Orcatec/components/UI/SettingsRow/SettingsRow'
import { EditableBlock } from 'containers/MainContent/Orcatec/components/UI/EditableBlock/EditableBlock'
import { patchProjectSettings } from 'api/Project'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useAppSelector } from 'store/Orcatec/hooks'
import moment from 'moment-timezone'
import { KeyboardDatePicker } from '@material-ui/pickers'

import { meSelector } from 'store/SuperAdmin/selectors'
// import styled from 'styled-components'

import {
  ModuleName,
  ProjectPermissions,
  TypeSourcePermissions,
} from 'features/Settings/UsersAndGroups'

import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import {
  selectUserPermissionsByModule,
  selectUserPermissionsByName,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import { Companies } from 'types/Company'
import { selectCompany } from 'store/Orcatec/selectors/company'
import AppointmentSources from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentSources'
import AppointmentTypes from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentTypes'
import {
  projectSettingsUpdated,
  // selectProjectSlice,
  updateProjectField,
} from 'features/Project/slices/projectSlice'
import { selectProject } from 'features/Project/projectSelectors'
import { ProjectStatusGroup } from 'features/Project/types'

// const typesSelector = state =>
//   state.orcatec.company.types
//     ?.filter(({ deleted_at }) => !deleted_at)
//     .map(({ id, name }) => ({ value: id, label: name }))
// const sourceSelector = state =>
//   state.orcatec.company.source
//     ?.filter(({ deleted_at }) => !deleted_at)
//     .map(({ id, name }) => ({ value: id, label: name }))

const techSelector = state =>
  state.orcatec.company.technicians
    ?.filter(({ deleted_at }) => !deleted_at)
    .map(({ id, full_name }) => ({ value: id, label: full_name }))
const industriesSelector = state =>
  state.orcatec.user?.me?.industries?.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

const defaultAction = () => null

const ModalProposalSetting = ({
  // toggleSettingModal,
  isOpenModal,
  // onCancelModal,
  onSubmit,
  isEditContract,
}) => {
  const dispatch = useDispatch()
  const me = useAppSelector(meSelector)
  const project = useSelector(selectProject)
  const canEditTypeSource = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.TYPE_SOURCE,
      TypeSourcePermissions.TYPE_SOURCE_CAN_CREATE,
    ),
  )

  const companyFinancing = useSelector(
    state => state.orcatec.company?.financing?.enable_financing,
  )

  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  // const isEditContract = useSelector(
  //   state => state.orcatec.projectSlice.editContract,
  // )
  const companyTypes = useAppSelector(state => state.orcatec.company.types)
  const companySource = useAppSelector(state => state.orcatec.company.source)
  const tech = useAppSelector(techSelector)
  const companyTech = useAppSelector(state => state.orcatec.company.technicians)
  const company = useAppSelector(selectCompany)
  const industries = useAppSelector(industriesSelector)
  const [state, setState] = useState({
    user_id: project?.user_id,
    source_id: project?.source?.id,
    type_id: project?.type?.id,
    industry_id: project?.industry_id,
    date_of_sale: project?.date_of_sale,
    issue_date: project?.issue_date,
    payment_processing_fee: project.setting.payment_processing_fee,
  })
  const [loading, setLoading] = useState(false)

  const handleSettingsChange = async (bool, field) => {
    const proposalId = project?.id

    try {
      await patchProjectSettings(proposalId, { [field]: bool })
      dispatch(onSetField(bool, field))
      dispatch(projectSettingsUpdated({ [field]: bool }))
      openNotificationWithIcon('success', {
        message: 'Project was updated successfully!',
      })
    } catch (error) {
      console.error(error)
      if (error?.response && error?.response?.status === 403) {
        openNotificationWithIcon('error', {
          message: 'This action is not authorized for your user',
        })
      }
      const errorMessage = Object.values(
        error?.response?.data?.errors || {},
      )?.[0]

      openNotificationWithIcon('error', {
        message: errorMessage || 'Something went wrong!',
      })
    }
  }

  // const handleVisibilitySettingsChange = async (name, value) => {
  //   const newVisibililySettings = {
  //     ...project.setting.hide_section_items,
  //     [name]: value,
  //   }
  //   await patchProjectSettings(project.id, {
  //     hide_section_items: newVisibililySettings,
  //   })
  //   dispatch(projestSettingsUpdated({ [name]: value }))
  // }

  // const handleSetField = (value, filed) => {
  //   dispatch(onSetField(value, filed))
  // }

  // const handleGetIndustry = industryId => {
  //   return dispatch(getIndustry(industryId))
  // }

  /*   const checkIfSomeItemsWillBeDeleted = () => {

    const settings = proposalForm.hide_section_items

    const materialsWillDeleted =
      activeTab.filter(item => item?.equipments?.length)?.length &&
      !settings.isHideMaterials
    const warrantiesWillDeleted =
      activeTab.filter(item => item?.warranties?.length)?.length &&
      !settings.isHideWarranty
    const instantsWillBeDeleted =
      activeTab.filter(item => item?.instants?.length)?.length &&
      !settings.isHideDiscount //discount
    const rebatesWillBeDeleted =
      activeTab.filter(item => item?.rebates?.length)?.length &&
      !settings.isHideRebates
    return (
      materialsWillDeleted ||
      warrantiesWillDeleted ||
      instantsWillBeDeleted ||
      rebatesWillBeDeleted
    )
  } */

  const isNumberInput = input => {
    const rgx = /^[0-9]*\.?[0-9]*$/
    return input.match(rgx)
  }

  const onChangePaymentFee = e => {
    const { value } = e.target

    if (!isNumberInput(value)) return
    setState(prev => ({ ...prev, payment_processing_fee: value }))
  }
  const handleChangeProjectRelation = (key, value) => {
    setState(prev => ({
      ...prev,
      [key]: value,
    }))
  }

  /* const handleUpdateProjectRelation = async key => {
    // const proposalId = proposalForm?.id

    try {
      // await updateProjectRelation(proposalId, { [key]: state[key] })
      dispatch(updateProjectField({ [key]: state[key] }))
    } catch (error) {
      console.error(error)
      if (error?.response && error?.response?.status === 403) {
        openNotificationWithIcon('error', {
          message: 'This action is not authorized for your user',
        })
        const errorMessage = Object.values(
          error?.response?.data?.errors || {},
        )?.[0]
        openNotificationWithIcon('error', {
          message: errorMessage || 'Something went wrong!',
        })
      }
    }
  } */

  const handleSave = key => async () => {
    setLoading(true)
    try {
      /*   if (key === 'industry_id') {
        // await handleGetIndustry(state?.industry_id)
        // await onSubmit(false)
        handleUpdateProjectRelation(key)
      }
      if (key === 'user_id') {
        const existUser = companyTech?.find(item => state?.user_id === item?.id)
        await handleUpdateProjectRelation(key)
        handleSetField(state.user_id, key)
        handleSetField(existUser.full_name, 'created_by_entity')
        handleSetField(existUser, 'user')
      }
      if (key === 'type_id') {
        const existType = companyTypes?.find(
          item => state?.type_id === item?.id,
        )
        await handleUpdateProjectRelation(key)
        handleSetField(existType, 'type')
        handleSetField(existType?.id, 'type_id')
      }
      if (key === 'source_id') {
        const existSource = companySource?.find(
          item => state?.source_id === item?.id,
        )
        await handleUpdateProjectRelation(key)
        handleSetField(existSource, 'source')
        handleSetField(existSource?.id, 'source_id')
      }
      if (key === 'issue_date') {
        await handleUpdateProjectRelation(key)
        handleSetField(state.issue_date, key)
      }
      if (key === 'date_of_sale') {
        await handleUpdateProjectRelation(key)
        handleSetField(state.date_of_sale, key)
      }
      if (key === 'payment_processing_fee') {
        const proposalId = project?.id
        handleSetField(state[key], key)
        await patchProjectSettings(proposalId, { [key]: state[key] })
      } */

      if (key === 'user_id') {
        const existUser = companyTech?.find(item => state?.user_id === item?.id)

        return dispatch(
          updateProjectField({
            user_id: state.user_id,
            user: existUser,
          }),
        )
      }

      dispatch(updateProjectField({ [key]: state[key] }))

      openNotificationWithIcon('success', {
        message: 'Project was updated successfully!',
      })
    } catch (error) {
      if (error?.response && error?.response?.status === 403) {
        openNotificationWithIcon('error', {
          message: 'This action is not authorized for your user',
        })
        const errorMessage = Object.values(
          error?.response?.data?.errors || {},
        )?.[0]
        openNotificationWithIcon('error', {
          message: errorMessage || 'Something went wrong!',
        })
      }
    } finally {
      setLoading(false)
    }
  }

  // const someItemsWillBeDeleted = checkIfSomeItemsWillBeDeleted()

  const canEditGeneral = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_SETTINGS_CAN_EDIT_GENERAL],
    project.user_id,
    me.id,
  )
  const canEditSections = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_SETTINGS_CAN_EDIT_SECTION],
    project.user_id,
    me.id,
  )
  const canEditPayment = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_SETTINGS_CAN_EDIT_PAYMENT],
    project.user_id,
    me.id,
  )
  const canEditRelation = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_SETTINGS_CAN_EDIT_RELATIONS],
    project.user_id,
    me.id,
  )

  const isSwitchDisabledForGeneral =
    !canEditGeneral || (project?.status !== 1 && !isEditContract)

  const isSwitchDisabledForSections =
    !canEditSections || (project?.status !== 1 && !isEditContract)

  const isSwitchDisabledForRelations =
    !canEditRelation || (project?.status === 3 && !isEditContract)

  return (
    <Modal
      width={750}
      title='Project Settings'
      visible={isOpenModal}
      onCancel={onSubmit}
      centered
      bodyStyle={{ maxHeight: '80vh', overflow: 'scroll' }}
      destroyOnClose
      footer={[
        <MainButton
          key={'settings-bth'}
          style={{ marginLeft: 'auto' }}
          onClick={onSubmit}
          title='Close'
        />,
      ]}
    >
      <Spin spinning={loading}>
        <SettingsRow
          title='Display Price per Item'
          description='Display price per item in customer view'
          width={150}
          withInfoIcon
        >
          <Tooltip
            title={
              project.status !== 1 && !isEditContract
                ? 'Can be changed if project is in Estimate status or Edit Contract mode'
                : ''
            }
          >
            {isEditContract && project.setting?.show_price ? (
              <Popconfirm
                onCancel={defaultAction}
                disabled={!isEditContract}
                onConfirm={() =>
                  handleSettingsChange(
                    !project.setting?.show_price,
                    'show_price',
                  )
                }
                title='Are you sure? You may lose existing data!'
              >
                <Switch
                  checked={project.setting?.show_price}
                  disabled={isSwitchDisabledForGeneral}
                />
              </Popconfirm>
            ) : (
              <Switch
                checked={project.setting?.show_price}
                onChange={checked =>
                  handleSettingsChange(checked, 'show_price')
                }
                disabled={isSwitchDisabledForGeneral}
              />
            )}
          </Tooltip>
        </SettingsRow>

        {companyFinancing && (
          <SettingsRow
            title='Enable Financing'
            description='Allow your clients to apply for consumer financing directly from the project'
            width={150}
            withInfoIcon
          >
            <Switch
              disabled={!canEditGeneral}
              checked={project.setting?.financing}
              onChange={checked => handleSettingsChange(checked, 'financing')}
            />
          </SettingsRow>
        )}

        <SettingsRow
          title='Require Signature'
          description={`In order to accept the project, your clients will need to provide their electronic signature, include their name, and agree to your Terms and Conditions.`}
          width={150}
          withInfoIcon
        >
          <Tooltip
            title={`The client's e-signature will be required in order to accept the project`}
          >
            {!!project?.signature && project.setting.require_signature ? (
              <Popconfirm
                onCancel={defaultAction}
                onConfirm={() =>
                  handleSettingsChange(
                    !project.setting.require_signature,
                    'require_signature',
                  )
                }
                title='Are you sure? You may lose existing data!'
              >
                <Switch
                  disabled={!canEditGeneral}
                  checked={project.setting.require_signature}
                />
              </Popconfirm>
            ) : (
              <Switch
                checked={project.setting.require_signature}
                disabled={!canEditGeneral}
                onChange={checked =>
                  handleSettingsChange(checked, 'require_signature')
                }
              />
            )}
          </Tooltip>
        </SettingsRow>

        <SettingsRow
          description='Add/Remove ability to add Tax to Project'
          title='Ability to add Taxes'
          width={150}
          withInfoIcon
        >
          <Tooltip
            title={
              project.status !== 1 && !isEditContract
                ? 'You can change this option when project status in Estimate or Edit Contract mode'
                : ''
            }
          >
            {isEditContract && project.setting.show_taxes ? (
              <Popconfirm
                onCancel={defaultAction}
                onConfirm={() =>
                  handleSettingsChange(
                    !project.setting.show_taxes,
                    'show_taxes',
                  )
                }
                title='Are you sure? You may lose existing data!'
              >
                <Switch
                  disabled={isSwitchDisabledForGeneral}
                  checked={project.setting.show_taxes}
                />
              </Popconfirm>
            ) : (
              <Switch
                disabled={isSwitchDisabledForGeneral}
                checked={project.setting.show_taxes}
                onChange={checked =>
                  handleSettingsChange(checked, 'show_taxes')
                }
              />
            )}
          </Tooltip>
        </SettingsRow>

        <SettingsRow
          description='In this field, you can include extra information for your client, upload media to your project, and receive comments from the customer.'
          title='Display Information for Customer'
          width={150}
          withInfoIcon
        >
          <Switch
            checked={project.setting.show_information}
            disabled={!canEditGeneral}
            onChange={checked =>
              handleSettingsChange(checked, 'show_information')
            }
          />
        </SettingsRow>

        <SettingsRow
          description='By enabling this option, items with a price of $0 will only be displayed with their name and description in the customer view.'
          title='Display $0 on Customer View'
          width={150}
          withInfoIcon
        >
          <Switch
            checked={!project.setting.hide_zero_price}
            disabled={!canEditGeneral}
            onChange={checked =>
              handleSettingsChange(!checked, 'hide_zero_price')
            }
          />
        </SettingsRow>

        <SettingsRow
          description='Auto-create remaining balance upon acceptance of the Estimate'
          title='Auto-create balance in Contract'
          width={150}
          withInfoIcon
        >
          <Switch
            checked={project.setting.balance_auto_creation}
            disabled={!canEditGeneral}
            onChange={checked =>
              handleSettingsChange(checked, 'balance_auto_creation')
            }
          />
        </SettingsRow>

        <SettingsRow
          description='Technician contact information will be visible in customer view'
          title='Display Tech Contacts'
          width={150}
          withInfoIcon
        >
          <Switch
            checked={project.setting.show_tech}
            disabled={!canEditGeneral}
            onChange={checked => handleSettingsChange(checked, 'show_tech')}
          />
        </SettingsRow>

        <SettingsRow
          description='Enable tips for customer payments'
          title=' Enable Tips'
          width={150}
          withInfoIcon
        >
          <Switch
            disabled={
              !canEditGeneral || (project.status === 3 && !isEditContract)
            }
            checked={!project.setting.disable_tips}
            onChange={checked => handleSettingsChange(!checked, 'disable_tips')}
          />
        </SettingsRow>

        <SettingsRow
          description='Automatically add tips to tech payouts in project insights'
          title='Add Tips to Payouts '
          width={150}
          withInfoIcon
        >
          <Switch
            disabled={
              !canEditGeneral || (project.status === 3 && !isEditContract)
            }
            checked={project.setting.include_tips_to_insight}
            onChange={checked =>
              handleSettingsChange(checked, 'include_tips_to_insight')
            }
          />
        </SettingsRow>

        <SettingsRow
          description='Show Items description only in customer view'
          title=' Show Items Description only'
          width={150}
          withInfoIcon
        >
          <Switch
            checked={project.setting.show_item_description_only}
            disabled={!canEditGeneral}
            onChange={checked =>
              handleSettingsChange(checked, 'show_item_description_only')
            }
          />
        </SettingsRow>

        <SettingsRow
          description='Enable this option to offer your client more choices by creating multiple customizable tabs for your project, including options like "Good-Better-Best."'
          title=' Multiple Tabs for Project'
          width={150}
          withInfoIcon
        >
          {isEditContract && project.setting.multiple_tabs ? (
            <Popconfirm
              onCancel={defaultAction}
              onConfirm={() =>
                handleSettingsChange(
                  !project.setting.multiple_tabs,
                  'multiple_tabs',
                )
              }
              title='Are you sure? You may lose existing data!'
            >
              <Switch
                disabled={isSwitchDisabledForGeneral}
                checked={project.setting.multiple_tabs}
              />
            </Popconfirm>
          ) : (
            <Switch
              disabled={isSwitchDisabledForGeneral}
              checked={project.setting.multiple_tabs}
              onChange={checked =>
                handleSettingsChange(checked, 'multiple_tabs')
              }
            />
          )}
        </SettingsRow>

        <SettingsRow
          description='Display quantity of items in customer view'
          title=' Display QTY in Customer View'
          width={150}
          withInfoIcon
        >
          <Switch
            disabled={!canEditGeneral}
            checked={project.setting.show_items_qty}
            onChange={checked =>
              handleSettingsChange('show_items_qty', checked)
            }
          />
        </SettingsRow>

        {[
          Companies.BuildX,
          Companies.HVAC,
          Companies.AirLoop,
          Companies.DivineEnergySolutions,
        ].includes(company.id) && (
          <SettingsRow
            description='Display Progress per item'
            title=' Display Completion Progress'
            width={150}
            withInfoIcon
          >
            <Switch
              disabled={!canEditGeneral}
              checked={project.setting.completion_progress}
              onChange={checked =>
                handleSettingsChange(checked, 'completion_progress')
              }
            />
          </SettingsRow>
        )}

        <SettingsRow
          description='Enable this option to make any items optional. Simply click the lock icon next to an item to give clients the ability to include or exclude it from the project scope by using the checkbox.'
          title=' Add-on Items'
          width={150}
          withInfoIcon
        >
          {isEditContract && project.setting.multiple_choice_items ? (
            <Popconfirm
              onCancel={defaultAction}
              onConfirm={() =>
                handleSettingsChange(
                  !project.setting.multiple_choice_items,
                  'multiple_choice_items',
                )
              }
              title='Are you sure? You may lose existing data!'
            >
              <Switch
                disabled={isSwitchDisabledForGeneral}
                checked={project.setting.multiple_choice_items}
              />
            </Popconfirm>
          ) : (
            <Switch
              disabled={isSwitchDisabledForGeneral}
              checked={project.setting.multiple_choice_items}
              onChange={checked =>
                handleSettingsChange(checked, 'multiple_choice_items')
              }
            />
          )}
        </SettingsRow>

        <div
          className='ant-modal-header'
          style={{ padding: '16px 0', marginBottom: '20px' }}
        >
          <div className='ant-modal-title' id='rcDialogTitle0'>
            Project Section Settings
          </div>
        </div>

        {/*  <SettingsRow
          description='Show/Hide Items '
          title=' Display Items'
          width={150}
          withInfoIcon
        >
          {!proposalForm.setting.hide_section_items.isHideMaterials &&
          someItemsWillBeDeleted ? (
            <Popconfirm
              title='Data in hidden items will be deleted. Are you sure to save ?'
              onConfirm={() => heandleChangeField(true, 'isHideMaterials')}
              onCancel={defaultAction}
            >
              <Switch
                disabled={isSwitchDisabledForSections}
                checked={
                  !proposalForm.setting.hide_section_items.isHideMaterials
                }
              />
            </Popconfirm>
          ) : (
            <Switch
              disabled={isSwitchDisabledForSections}
              checked={!proposalForm.setting.hide_section_items.isHideMaterials}
              onChange={checked =>
                heandleChangeField(!checked, 'isHideMaterials')
              }
            />
          )}
        </SettingsRow> */}

        {/* <SettingsRow
          description='Show/Hide Warranty '
          title=' Display Warranty '
          width={150}
          withInfoIcon
        >
          {!proposalForm.setting.hide_section_items.isHideWarranty &&
          someItemsWillBeDeleted ? (
            <Popconfirm
              title='Data in hidden items will be deleted. Are you sure to save ?'
              onConfirm={() => heandleChangeField(true, 'isHideWarranty')}
              onCancel={defaultAction}
            >
              <Switch
                disabled={isSwitchDisabledForSections}
                checked={
                  !proposalForm.setting.hide_section_items.isHideWarranty
                }
              />
            </Popconfirm>
          ) : (
            <Switch
              disabled={isSwitchDisabledForSections}
              checked={!proposalForm.setting.hide_section_items.isHideWarranty}
              onChange={checked =>
                heandleChangeField(!checked, 'isHideWarranty')
              }
            />
          )}
        </SettingsRow> */}

        <SettingsRow
          description='Add/Remove ability to add Discount to Project'
          title=' Ability to add Discount '
          width={150}
          withInfoIcon
        >
          {/* {!project.setting.show_discounts ? (
            <Popconfirm
              title='Data in hidden items will be deleted. Are you sure to save ?'
              onConfirm={() =>
                handleVisibilitySettingsChange('show_discounts', true)
              }
              onCancel={defaultAction}
            >
              <Switch
                disabled={isSwitchDisabledForSections}
                checked={!project.setting.show_discounts}
              />
            </Popconfirm>
          ) : ( */}
          <Switch
            disabled={isSwitchDisabledForSections}
            checked={!project.setting.show_discounts}
            onChange={checked =>
              handleSettingsChange('isHideDiscount', checked)
            }
          />
          {/* )} */}
        </SettingsRow>

        <SettingsRow
          description='Add/Remove ability to add Rebate to Project '
          title=' Ability to add Rebate '
          width={150}
          withInfoIcon
        >
          {/* {!project.setting.show_rebates ? (
            <Popconfirm
              title='Data in hidden items will be deleted. Are you sure to save ?'
              onConfirm={() =>
                handleSettingsChange('show_rebates', true)
              }
              onCancel={defaultAction}
            >
              <Switch
                disabled={isSwitchDisabledForSections}
                checked={!project.setting.show_rebates}
              />
            </Popconfirm>
          ) : ( */}
          <Switch
            disabled={isSwitchDisabledForSections}
            checked={!project.setting.show_rebates}
            onChange={checked => handleSettingsChange('show_rebates', checked)}
          />
          {/* )} */}
        </SettingsRow>

        <div
          className='ant-modal-header'
          style={{ padding: '16px 0', marginBottom: '20px' }}
        >
          <div className='ant-modal-title' id='rcDialogTitle0'>
            Payment Settings
          </div>
        </div>

        <SettingsRow title=' Payment Processing Fee:' width={150}>
          <EditableBlock
            value={`${state.payment_processing_fee} %`}
            onSave={() =>
              handleSettingsChange(
                state.payment_processing_fee,
                'payment_processing_fee',
              )
            }
            disabled={!canEditPayment}
            onCancel={() => {
              setState(prev => ({
                ...prev,
                payment_processing_fee: project?.setting.payment_processing_fee,
              }))
            }}
          >
            <InputField
              name='payment_processing_fee'
              value={state?.payment_processing_fee}
              onChange={onChangePaymentFee}
              inputProps={{ maxLength: 4 }}
              onBlur={e => {
                if (!e.target.value)
                  return handleSettingsChange(0, 'payment_processing_fee')
              }}
            />
          </EditableBlock>
        </SettingsRow>
        <div
          className='ant-modal-header'
          style={{ padding: '16px 0', marginBottom: '10px' }}
        >
          <div className='ant-modal-title' id='rcDialogTitle0'>
            Project Relations Settings
          </div>
        </div>

        <div>
          {industries?.length > 1 && (
            <SettingsRow title='Industry' width={150}>
              <EditableBlock
                value={
                  industries?.find(item => state?.industry_id === item?.value)
                    ?.label || project?.industry_name
                }
                onSave={handleSave('industry_id')}
                disabled={
                  !canEditRelation ||
                  ![
                    ProjectStatusGroup.Estimate,
                    ProjectStatusGroup.Cold,
                  ].includes(project.status)
                }
                disabledText='Industry can only be changed when project is in Estimate status'
                onCancel={() => {
                  setState(prev => ({
                    ...prev,
                    industry_id: project?.industry_id,
                  }))
                }}
              >
                <Select
                  defaultOpen
                  style={{ width: 200 }}
                  value={state?.industry_id}
                  onChange={value =>
                    handleChangeProjectRelation('industry_id', value)
                  }
                  options={industries}
                  disabled={project.status === 3 && !isEditContract}
                ></Select>
              </EditableBlock>
            </SettingsRow>
          )}

          <SettingsRow title='Assigned to' width={150}>
            <EditableBlock
              disabled={isSwitchDisabledForRelations}
              value={tech?.find(item => state?.user_id === item?.value)?.label}
              onSave={handleSave('user_id')}
              onCancel={() => {
                setState(prev => ({ ...prev, user_id: project?.user_id }))
              }}
            >
              <Select
                defaultOpen
                style={{ width: 200 }}
                value={state?.user_id}
                onChange={value =>
                  handleChangeProjectRelation('user_id', value)
                }
                disabled={project.status === 3 && !isEditContract}
                options={tech}
              ></Select>
            </EditableBlock>
          </SettingsRow>
          <SettingsRow title='Type' width={150}>
            <EditableBlock
              disabled={isSwitchDisabledForRelations}
              value={
                companyTypes?.find(item => state?.type_id === item?.id)?.name
              }
              onSave={handleSave('type_id')}
              onCancel={() => {
                setState(prev => ({ ...prev, type_id: project?.type?.id }))
              }}
            >
              {/* <Select
                defaultOpen
                style={{ width: 200 }}
                value={state.type_id}
                onChange={value =>
                  handleChangeProjectRelation('type_id', value)
                }
                options={types}
              ></Select> */}
              <AppointmentTypes
                name='type_id'
                title='Type'
                value={state.type_id}
                onChange={e =>
                  handleChangeProjectRelation('type_id', e.target.value)
                }
                isLead={false}
                required={false}
                disableCRUD={!canEditTypeSource}
                withoutLabel
                style={{ width: '200px' }}
              />
            </EditableBlock>
          </SettingsRow>
          <SettingsRow title='Source' width={150}>
            <EditableBlock
              disabled={isSwitchDisabledForRelations}
              value={
                companySource?.find(item => state?.source_id === item?.id)?.name
              }
              onSave={handleSave('source_id')}
              onCancel={() => {
                setState(prev => ({
                  ...prev,
                  source_id: project?.source?.id,
                }))
                setState(prev => ({
                  ...prev,
                  source_id: project?.source?.id,
                }))
              }}
            >
              {/* <Select
                defaultOpen
                disabled={proposalForm.status === 3 && !isEditContract}
                style={{ width: 200 }}
                value={state.source_id}
                onChange={value =>
                  handleChangeProjectRelation('source_id', value)
                }
                options={sourse}
              ></Select> */}
              <AppointmentSources
                name='source_id'
                title='Source'
                value={state.source_id}
                onChange={e =>
                  handleChangeProjectRelation('source_id', e.target.value)
                }
                required={false}
                disableCRUD={!canEditTypeSource}
                withoutLabel
                style={{ width: '200px' }}
              />
            </EditableBlock>
          </SettingsRow>
          <SettingsRow title='Issue Date' width={150}>
            <EditableBlock
              disabled={isSwitchDisabledForRelations}
              value={
                state?.issue_date
                  ? moment(state.issue_date).format('MM/DD/YYYY')
                  : '--'
              }
              onSave={handleSave('issue_date')}
              onCancel={() => {
                setState(prev => ({
                  ...prev,
                  issue_date: project?.issue_date,
                }))
              }}
            >
              <KeyboardDatePicker
                autoOk
                placeholder='Issue date'
                value={state?.issue_date ? moment(state?.issue_date) : null}
                label='Issue Date'
                inputVariant='outlined'
                format='MM/DD/YYYY'
                size='small'
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ maxWidth: '200px' }}
                disableToolbar
                variant='inline'
                disabled={project.status === 3 && !isEditContract}
                onChange={date =>
                  handleChangeProjectRelation(
                    'issue_date',
                    moment(date).format('MM/DD/YYYY'),
                  )
                }
                inputProps={{
                  readOnly: true,
                }}
              />
            </EditableBlock>
          </SettingsRow>
          {project?.date_of_sale && (
            <SettingsRow title='Date of Sale' width={150}>
              <EditableBlock
                disabled={isSwitchDisabledForRelations}
                value={
                  state?.date_of_sale
                    ? moment(state.date_of_sale).format('MM/DD/YYYY')
                    : '--'
                }
                onSave={handleSave('date_of_sale')}
                onCancel={() => {
                  setState(prev => ({
                    ...prev,
                    date_of_sale: project?.date_of_sale,
                  }))
                }}
              >
                <KeyboardDatePicker
                  autoOk
                  placeholder='Date of Sale'
                  value={
                    state?.date_of_sale ? moment(state?.date_of_sale) : null
                  }
                  label='Date of Sale'
                  inputVariant='outlined'
                  format='MM/DD/YYYY'
                  size='small'
                  disableToolbar
                  variant='inline'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ maxWidth: '200px' }}
                  onChange={date =>
                    handleChangeProjectRelation(
                      'date_of_sale',
                      moment(date).format('MM/DD/YYYY'),
                    )
                  }
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </EditableBlock>
            </SettingsRow>
          )}
        </div>
      </Spin>
    </Modal>
  )
}

export default ModalProposalSetting

// const Description = styled.p`
//   margin-top: 10px;
//   font-size: 0.8rem;
//   font-style: italic;
//   color: #707683;
// `
//
// const InputWrapper = styled.div`
//   pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
// `
