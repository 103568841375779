import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { Section, Wrapper } from './ProjectPreviewContent.styles'
import {
  selectProject,
  selectProjectActiveTab,
  selectProjectSettings,
} from 'features/Project/projectSelectors'
import ContactsInformation from './components/ContactsInformation'
import ProposalSettings from 'containers/MainContent/Orcatec/Proposals/components/ProposalSettings'
import ProposalTabs from 'containers/MainContent/Orcatec/Proposals/components/ProposalTabs'
import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'
import { getProject } from 'features/Project/slices/projectSlice'
import TabContent from './components/TabContent'

export const ProjectPreviewContent = () => {
  const dispatch = useAppDispatch()

  const project = useAppSelector(selectProject)
  const currentTab = useAppSelector(selectProjectActiveTab)
  const projectSettings = useAppSelector(selectProjectSettings)

  const isVisiblePrice = useAppSelector(selectIsPriceVisible(project?.user_id))

  const hideTabs =
    project?.tabs_info?.length === 1 &&
    !projectSettings?.financing &&
    !project?.tabs_info?.[0]?.description &&
    !project?.tabs_info?.[0]?.image &&
    project?.tabs_info?.[0]?.title === 'Tab Title'

  const onChangeTab = async (_: number, tabId: number) => {
    dispatch(getProject({ projectId: project.id, tabId }))
  }

  if (!project || !projectSettings) return null

  return (
    <Wrapper>
      {!!projectSettings && <ProposalSettings settings={projectSettings} />}

      <Section>
        <ContactsInformation proposal={project} currentTab={currentTab} />
      </Section>

      <Section>
        {!hideTabs && (
          <ProposalTabs
            proposal={project}
            activeTabIdx={project?.activeTab}
            onChangeTab={onChangeTab}
            hidePrices={!isVisiblePrice}
            currentTabId={currentTab.id}
          />
        )}

        <TabContent />
      </Section>
    </Wrapper>
  )
}
