import axiosOrcatec from '../axiosInstance'

/**
 * Get subscription.
 *
 * @return {*}
 */
export const fetchSubscription = company_id => {
  const params = {
    company_id,
  }
  return axiosOrcatec.get('setting/subscription', { params }).then(response => response.data)
}

/**
 * Create subscription.
 *
 * @param data
 * @return {*}
 */
export const createSubscription = data => {
  return axiosOrcatec.post('subscription/create-entity', data).then(response => response.data)
}

/**
 * Update subscription.
 *
 * @param id
 * @param data
 * @return {*}
 */
export const updateSubscription = (id, data) => {
  return axiosOrcatec.put(`setting/subscription/${id ?? 0}`, data).then(response => response.data)
}

/**
 * Switch state of module.
 *
 * @param data
 * @return {*}
 */
export const switchStateModule = (data, id) => {
  return axiosOrcatec.put(`setting/subscription/switch-state/${id}`, data).then(response => response.data)
}

/**
 * Switch subscription.
 *
 * @param data
 * @return {*}
 */
export const switchSubscription = data => {
  return axiosOrcatec.put(`setting/subscription/switch-subscription`, data).then(response => response.data)
}

export const paySubscription = (id, data) => {
  return axiosOrcatec
    .put(`setting/subscription/setting/subscription/pay-now/${id}`, data)
    .then(response => response.data)
}
