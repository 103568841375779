import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as actions from 'store/Orcatec/actions'
import ModalFileUpload from './ModalFileUpload'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { InfoForWorkersOperation } from '../InfoForWorkers/types'

const ModalFileUploadContainer = props => {
  const { DELETE } = usePermissionsByModule(InfoForWorkersOperation)

  const [isLinkUploading, setIsLinkUploading] = useState(false)
  const [isDeleting, setIsDeleting] = useState([])
  const [uploadProggress, setUploadProggress] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [filesToDelete, setFilesToDelete] = useState([])
  const [progress, setProgress] = useState(null)

  const isInfoForWorkers = props.proposalModalFiles.file_type === 'tab'

  useEffect(() => {
    let files = []
    if (props.clientView) {
      files = props.files ? props.files : []
    } else {
      files = props.proposalModalFiles.files
    }

    setUploadedFiles(files || [])
  }, [props.proposalModalFiles.files, props.files])

  useEffect(() => {
    setProgress(props.proposalModalFiles.progress)
  }, [props.proposalModalFiles.progress, progress])

  const filesLimit =
    props.proposalModalFiles.file_type === 'items' ||
    props.proposalModalFiles.file_type === 'options' ||
    props.isNewItem
      ? 3
      : props.customFilesLimit ?? 100

  const disableUpload = Array.isArray(uploadedFiles)
    ? uploadedFiles?.length >= filesLimit
    : false
  const toggleModal = () => {
    return props.property_files
      ? props.toggleModalProperty()
      : props.toggleModal()
  }
  const onFileTabUpload = files => {
    const currentFilesCount = Array.isArray(uploadedFiles)
      ? uploadedFiles?.length
      : 0
    let restLimit = filesLimit - currentFilesCount
    restLimit = restLimit >= 0 ? restLimit : 0

    let filesToUpload = [...files].slice(0, restLimit)

    if (props.formats) {
      filesToUpload = [...filesToUpload].filter?.(({ type }) =>
        props.formats.includes(type.split?.('/')?.[1]),
      )
    }

    setUploadedFiles(prev => {
      return [...prev, ...filesToUpload]
    })

    props.setUploadFiles(filesToUpload)
  }

  const onUploadFileByLink = value => {
    setUploadedFiles(prev => [
      ...prev,
      {
        id: Date.now(),
        linkToFile: value,
      },
    ])
  }
  const onDeleteFile = (file, fileIdx) => {
    if (isInfoForWorkers && !DELETE) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    if (file.id) {
      props?.property_files && props.onDeleteMedia(file.id)
      if (!file.linkToFile) setFilesToDelete(prev => [...prev, file.id])
      setUploadedFiles(uploadedFiles.filter((f, i) => f.id !== file.id))
    }

    props.clearFileInProgress(file.lastModified)
    setUploadedFiles(uploadedFiles.filter((file, i) => i !== fileIdx))
  }

  const clearLocalFilesAndSaveProposal = files => {
    setFilesToDelete([])
    toggleModal()
    props.handleSave([
      ...files?.flat(),
      ...(props.clientView
        ? uploadedFiles.filter(file => !(file instanceof File))
        : []),
    ])
  }

  const handleSaveFiles = () => {
    props.saveFiles(
      uploadedFiles,
      props.proposalModalFiles.progress,
      filesToDelete,
      clearLocalFilesAndSaveProposal,
    )
  }

  const handleCancel = () => {
    setFilesToDelete([])
    toggleModal()
  }

  const ActionButtons = ({ handleSave, handleCancel, showButtons }) => {
    return handleSave && handleCancel && showButtons ? (
      <div className='modal-file-upload__action-buttons'>
        <MainButton title='Cancel' type='cancel' onClick={handleCancel} />
        <MainButton
          title='Save'
          className='ml-3'
          onClick={e => {
            e.preventDefault()
            handleSave()
          }}
        />
      </div>
    ) : (
      <div className='modal-file-upload__action-buttons'>
        <MainButton title='Close' onClick={toggleModal} />
      </div>
    )
  }

  return (
    <ModalFileUpload
      show={
        props?.property_files
          ? props.proposalModalFiles.show_property_modal
          : props.proposalModalFiles.show
      }
      onFileTabUpload={onFileTabUpload}
      onUploadFileByLink={onUploadFileByLink}
      onDeleteFile={onDeleteFile}
      toggleModal={toggleModal}
      files={uploadedFiles}
      disableUpload={disableUpload}
      isLocalUploading={uploadProggress.some(item => !!item)}
      isLinkUploading={isLinkUploading}
      isDeleting={isDeleting}
      filesLimit={filesLimit}
      uploadProgress={progress}
      disableUploadByLink={props.disableAddByLink}
      onDeleteMedia={props.onDeleteMedia}
      actionButtons={
        <ActionButtons
          handleSave={handleSaveFiles}
          handleCancel={handleCancel}
          showButtons={true}
        />
      }
      formats={props.formats}
    />
  )
}

const mapStateToProps = state => {
  return {
    proposalForm: state.orcatec.proposalForm,
    proposalModalFiles: state.orcatec.proposalModalFiles,
  }
}

const mapDispatchToProps = dispatch => ({
  toggleModal: () => dispatch(actions.proposalModalFiles.toggleModal()),
  toggleModalProperty: () =>
    dispatch(actions.proposalModalFiles.toggleModalProperty()),
  saveFiles: (files, filesToServer, idsToDelete, cb) =>
    dispatch(
      actions.proposalForm.saveFiles(files, filesToServer, idsToDelete, cb),
    ),
  setUploadFiles: files =>
    dispatch(actions.proposalModalFiles.setUploadFiles(files)),
  clearFileInProgress: files =>
    dispatch(actions.proposalModalFiles.clearFileInProgress(files)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalFileUploadContainer)
