import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox } from 'antd'
import { Button, InfoPlate, Password, TextField } from 'components/UIKit'
import { Controller, useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'
// import { useLoginMutation } from '../authApiSlice'
import { authorization } from 'store/Orcatec/actions/settings/user'
import { useAppDispatch } from 'store/Orcatec/hooks'
import { useState } from 'react'
import { AuthAPI } from '../api'
// import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

const companyFormSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email address')
    .nullable()
    .required('Email is required')
    .trim()
    .label('Email'),
  password: yup
    .string()
    .required('Enter you password')
    .label('Password'),
})

export const Signin = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  // const [error, setError] = useState('')
  // const history = useHistory()

  // const [login] = useLoginMutation()

  const methods = useForm({
    resolver: yupResolver(companyFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = methods

  const onSubmit = async data => {
    setLoading(true)
    try {
      await dispatch(authorization(data.email, data.password, true))
    } catch (error) {
      const { error: serverError } = error?.response?.data

      // const message =
      /* status === 401
          ? 'Email or password do not match'
          : status === 403
          ? 'Email is not verified'
          : 'This account is not active' */

      setError('email', { type: 'custom', message: serverError })

      console.error(serverError)
      // openNotificationWithIcon()
    } finally {
      setLoading(false)
    }

    /* try {
      await login({
        email: data.email,
        password: data.password,
      }).unwrap()

      history.replace('/projects')
    } catch (error) {
      console.error(error)
    } */
  }

  const handleEmailResend = async () => {
    if (loading) return

    setLoading(true)
    try {
      await AuthAPI.resendVerificationEmail({ email: getValues('email') })

      history.push('/auth/confirm-email')
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      <Title>Sign In</Title>

      {errors.email?.message === 'Please verify your email address to log in' &&
        !!getValues('email') && (
          <NotVerified>
            <InfoPlate type='warning'>
              Please verify your email address to log in. Click{' '}
              <LinkText onClick={handleEmailResend}>here</LinkText> to resend
              the verification email.
            </InfoPlate>
          </NotVerified>
        )}

      <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label='Email'
              required
              error={errors?.email?.message}
              autoComplete='off'
            />
          )}
        />
        <Controller
          name='password'
          control={control}
          render={({ field }) => (
            <Password
              {...field}
              label='Password'
              required
              error={errors?.password?.message}
              autoComplete='off'
            />
          )}
        />

        <ForgotPassword>
          <StyledCheckox>Remember me</StyledCheckox>
          <StyledLink to='/auth/forgot-password'>Forgot password?</StyledLink>
        </ForgotPassword>

        <StyledButton
          block
          type='primary'
          size='large'
          loading={loading}
          htmlType='submit'
        >
          Sign In
        </StyledButton>
      </Form>

      <Signup>
        <SecondaryText>Don&apos;t have an account?</SecondaryText>
        <StyledLink to='/auth/signup'>Sign Up</StyledLink>
      </Signup>

      <Policy>
        This site is subject to{' '}
        <Link
          to={{
            pathname: 'https://orcatec.com/privacy_policy.pdf',
          }}
          target='_blank'
          rel='noopener noreferrer'
        >
          Privacy Policy
        </Link>{' '}
        and{' '}
        <Link
          to={{
            pathname: 'https://orcatec.com/terms_and_conditions.pdf',
          }}
          target='_blank'
          rel='noopener noreferrer'
        >
          Terms of use
        </Link>
      </Policy>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 450px;
  padding: 48px;
  border: 2px solid #165ed033;
  border-radius: 10px;
  background-color: #fff;

  @media (max-width: 450px) {
    width: 375px;
    padding: 16px;
  }
`
const Title = styled.h2`
  margin-bottom: 24px;
  text-align: center;
`

const ForgotPassword = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledButton = styled(Button)`
  margin-top: 16px;
`
const StyledLink = styled(Link)`
  font-weight: 500;
`

const Form = styled.form`
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`

const Signup = styled.p`
  display: flex;
  gap: 10px;
  justify-content: center;
  font-size: 16px;
  margin-top: 30px;
`

const StyledCheckox = styled(Checkbox)`
  span {
    font-weight: 400;
  }
`
const NotVerified = styled.div`
  margin-bottom: 24px;
`
const SecondaryText = styled.span`
  color: ${props => props.theme.font.secondary};
`

const LinkText = styled.span`
  color: #206fed;
  cursor: pointer;
  text-decoration: underline;
`

const Policy = styled.p`
  margin-top: 30px;
  color: #4d4d4d;

  a {
    font-weight: 500;
  }
`
