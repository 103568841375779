import { messagingAPI } from 'api/MessagingAPI'
import React, { useEffect, useState } from 'react'
import { parsePhoneNumber } from '../../../../utils'
import {
  ChatHeaderProps,
  ClientType,
  RelatedClientInterface,
} from 'features/Messaging/types'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { Divider } from 'antd'
import { InputField } from 'containers/MainContent/Orcatec/components/Input/InputField'
import { Fade, MenuItem } from '@material-ui/core'
import ReactInputMask from 'react-input-mask'
import ClientForm from '../../../../../../containers/MainContent/Orcatec/Clients/components/Form/index'

import './ChatHeader.scss'

import {
  ClientIcon,
  OrganizationIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import styled from 'styled-components'

export const ChatHeader: React.FC<ChatHeaderProps> = props => {
  const {
    chatID,
    chatInfo,
    isNewChatView,
    formValues,
    handleChange,
    importedPhones,
    isConversationStarted = false,
  } = props

  const [chosenRelatedClient, setChosenRelatedClient] = useState<null | string>(
    null,
  )
  const [relatedClients, setRelatedClients] = useState<
    RelatedClientInterface[]
  >([])
  // const [selectedTechnicians, setSelectedTechnicians] = useState<number[]>(
  //   attachedTechnicians || [],
  // )
  // const [isTechniciansTouched, setTechniciansTouched] = useState(false)
  const [showSelectClient, setShowSelectClient] = useState(false)

  // ClientForm
  const [isClientFormOpen, setIsClientFormOpen] = useState(false)

  const [isSettingsOpen] = useState(false)

  // useEffect(() => {
  //   setSelectedTechnicians(attachedTechnicians)
  // }, [attachedTechnicians])

  useEffect(() => {
    if (!chatID) return
    setShowSelectClient(false)
    setRelatedClients([])
    messagingAPI
      .getProbablyClients(chatID)
      .then(({ clients }) => {
        setRelatedClients(clients)

        const primaryClient = clients?.find?.(
          (item: RelatedClientInterface) => item.is_primary,
        )

        if (primaryClient) setChosenRelatedClient(primaryClient.entity_id)
      })
      .catch(e => console.error(e))
  }, [chatID])

  // useEffect(() => {
  // messagingAPI
  //   .getUsers({ limit: 20, role_id: [4, 5] })
  //   .then(({ data }) =>
  //     setUsers(() =>
  //       data
  //         .filter(({ deleted_at }) => deleted_at === null)
  //         .map(({ id, full_name, title }) => ({ id, title, name: full_name })),
  //     ),
  //   )
  //   .catch(e => console.error(e))
  // }, [])

  const handleChangeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: entityID } = e.target

    const data = {
      id: entityID,
      type: relatedClients.find?.(i => i.entity_id === +entityID)?.type,
    }

    chatID &&
      messagingAPI
        .attachChatToClient(chatID, data)
        .then(() => setChosenRelatedClient(entityID))
        .then(() =>
          openNotificationWithIcon('success', {
            message: 'Success!',
            description: 'Client attached to this chat!',
          }),
        )
        .catch(e => console.error(e))
        .finally(() => {
          setShowSelectClient(false)
        })
  }

  /* const handleClickSettingsIcon = () => {
    setIsSettingsOpen(p => !p)
  }

  const handleSelectTechnicians = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number[],
  ) => {
    setTechniciansTouched(true)
    setSelectedTechnicians([...i])
  }

  const handleTechnicianSelectClose = () => {
    if (isTechniciansTouched) {
      const data = { users: selectedTechnicians }

      messagingAPI
        .attachTechToChat(chatID, data)
        .then(() =>
          notificationMessage('success', 'User successfully attached!'),
        )
        .catch(e => {
          notificationMessage('error', '')
        })
    }
  } */

  const openClientForm = () => {
    setIsClientFormOpen(true)
  }

  const ClientOrOrganizationIcon = () => {
    const isTypeExist = !!chatInfo?.client?.type || false
    const isOrganization = chatInfo?.client?.type === ClientType.ORGANIZATION

    const style = {
      margin: '0 5px',
      cursor: 'pointer',
    }

    return isTypeExist ? (
      isOrganization ? (
        <i style={style}>
          <OrganizationIcon />
        </i>
      ) : (
        <i style={style}>
          <ClientIcon />
        </i>
      )
    ) : null
  }

  const linkStyle = {
    color: '#1890ff',
    textDecoration: 'underline',
    cursor: 'pointer',
  }

  return (
    <>
      {isNewChatView ? (
        <div className='new-chat-header'>
          <div className='header-inputs'>
            <InputField
              value={formValues.fromNumber}
              onChange={handleChange}
              name='fromNumber'
              label='From Number'
              placeholder='(XXX) XXX-XXXX'
              disabled={!importedPhones.length}
              select
            >
              {importedPhones.map(({ number, alias }, i) => (
                <MenuItem value={number} key={i}>
                  {`${alias ?? ''} ${parsePhoneNumber(number)}`}
                </MenuItem>
              ))}
            </InputField>

            <ReactInputMask
              mask='(999) 999-9999'
              value={formValues.toNumber}
              onChange={handleChange}
              maskChar='X'
              disabled={isConversationStarted}
            >
              {() => (
                <InputField
                  name='toNumber'
                  label='To Number'
                  placeholder='(XXX) XXX-XXXX'
                  disabled={isConversationStarted}
                />
              )}
            </ReactInputMask>
          </div>
        </div>
      ) : (
        <div className='chat-header'>
          <div className='header-container'>
            <div>
              <p
                style={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                Contact: <ClientOrOrganizationIcon />
                <span
                  onClick={
                    chatInfo.client?.id ? () => openClientForm() : undefined
                  }
                  style={chatInfo.client?.id ? linkStyle : {}}
                >{`${chatInfo.client_name ??
                  parsePhoneNumber(chatInfo.to) ??
                  ''}`}</span>
                {!!relatedClients?.length && relatedClients?.length > 1 && (
                  <RestItems onClick={() => setShowSelectClient(prev => !prev)}>
                    +{relatedClients.length - 1}
                  </RestItems>
                )}
                {showSelectClient && (
                  <InputField
                    select
                    disabled={!relatedClients.length}
                    size='small'
                    onChange={handleChangeSelect}
                    value={chosenRelatedClient}
                    style={{ maxWidth: '200px', marginLeft: '10px' }}
                  >
                    {relatedClients.map((item, i) => (
                      <MenuItem key={i} value={item.entity_id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </InputField>
                )}
              </p>
              <p>{`Company phone: ${chatInfo.from_alias ??
                parsePhoneNumber(chatInfo.from) ??
                ''}`}</p>
            </div>
            {/* <SettingOutlined style={{ cursor: 'pointer', fontSize: 18 }} onClick={handleClickSettingsIcon} /> */}
          </div>
          <div
            className={`settings-block ${isSettingsOpen ? 'open' : 'closed'}`}
          >
            <Fade in={true} timeout={500}>
              <div
                style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              >
                {/*<span>Attached User</span>*/}
                {/* <span>Attached Client:</span> */}
                {/* <CustomMultyAutocomplete
                  label='Clients'
                  onChange={handleSelectTechnicians}
                  value={selectedTechnicians}
                  filterOptions={(x: number) => x}
                  options={relatedClients?.map(u => u.entity_id)}
                  getOptionLabel={(id: number) => {
                    const user = relatedClients?.find(u => u.entity_id === +id)
                    return user ? `${user.name}` : ''
                  }}
                  checkIsChoosen={(id: number) => selectedTechnicians?.includes(+id)}
                  inputValue={selectedTechnicians?.length ? `${selectedTechnicians?.length} attached` : 'All'}
                  customOnClose={handleTechnicianSelectClose}
                  disabled={![1, 2].includes(roleID)}
                /> */}
              </div>
            </Fade>
          </div>
        </div>
      )}

      <Divider className='header-divider' />

      <ClientForm
        modal={isClientFormOpen}
        close={() => {
          setIsClientFormOpen(prev => !prev)
        }}
        clientId={chatInfo?.client?.id}
        currentTab={
          Number(chatInfo?.client?.type) === 1 ? 'organization' : 'clients'
        }
        isOrganization={Number(chatInfo?.client?.type) === 1}
      />
    </>
  )
}

const RestItems = styled.span`
  padding: 3px 3px;
  margin-left: 5px;
  color: #4285f4;
  background: rgba(76, 83, 239, 0.08);
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  height: auto;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
`
