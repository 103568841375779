import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ConfirmEmail = () => {
  return (
    <Wrapper>
      <Img src='/assets/images/Auth/ic.svg' alt='Sent image' />

      <Heading>You&apos;re almost there!</Heading>

      <Content>
        A verification email has been sent to the provided email address. Please
        check your inbox and follow the instructions to complete the
        registration process
      </Content>

      <Signin>
        <Link to='/auth/signin'>Back to Sign In</Link>
      </Signin>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 450px;
  padding: 48px;
  border: 2px solid #165ed033;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;

  @media (max-width: 450px) {
    width: 375px;
    padding: 16px;
  }
`
const Heading = styled.h4`
  text-align: center;
  margin-bottom: 1.2rem;
  margin-top: 1.5rem;
`

const Content = styled.div`
  text-align: center;
`

const Img = styled.img``

const Signin = styled.p`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
  color: #4d4d4d;
`
