import { QuickbooksPermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'

export const quickbooks = {
  quickbooks_can_read: {
    name: 'View Quickbooks',
    control_type: UniteType.FIRST_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.QUICKBOOKS_CAN_READ,
    related_key: [],
    is_module: true,
  },
}
