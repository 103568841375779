import { getAllIndustries } from 'api/settings/Industries'
import { IIndustries } from 'types/Appointment'
import { useEffect, useState } from 'react'

const useIndustries = () => {
  const [industries, setIndustries] = useState<IIndustries[] | null>(null)

  useEffect(() => {
    const fetchIndustries = async () => {
      const data = await getAllIndustries()

      setIndustries(data)
    }

    fetchIndustries()
  }, [])

  return industries as IIndustries[]
}

export default useIndustries
