import React from 'react'
import { Modal } from 'antd'
import ClientForm from 'containers/MainContent/Orcatec/Clients/components/Form/components/Form/index.js'
import PropertyForm from 'containers/MainContent/Orcatec/Properties/components/PropertyForm'

export const Modals = ({
  handleClientModalClose,
  handlePropertyModalClose,
  openModal,
  // clientModal,
  // propertyModal,
  clientId,
  clientType,
  propertyId,
  updateClientList,
  mode = 'edit',
  attachClientAfterSave,
  onSubmitPropertyForm,
}) => {
  const [isSubmitting, setIsSumbitting] = React.useState(false)

  const onModalClose = () => {
    if (isSubmitting) return

    handleClientModalClose()
  }

  return !!openModal && openModal !== 'Properties' ? (
    <Modal
      visible={!!openModal && openModal !== 'Properties'}
      onCancel={onModalClose}
      title={
        <h4>
          {clientId ? 'Edit' : 'Add'}
          {openModal === 'Organizations'
            ? ' organization'
            : openModal === 'Clients'
            ? ' client'
            : ' contact'}
        </h4>
      }
      className='client-modal'
      footer={null}
      width='auto'
      style={{ top: 50, maxWidth: 1100 }}
      maskClosable={false}
    >
      <ClientForm
        closeForm={onModalClose}
        clientId={clientId}
        changeModalTitle={true}
        isContact={openModal === 'Contacts'}
        isOrganization={openModal === 'Organizations'}
        updateClientList={updateClientList}
        attachClientAfterSave={attachClientAfterSave}
        isProposal={true}
        onSubmitting={setIsSumbitting}
      />
    </Modal>
  ) : openModal === 'Properties' ? (
    <Modal
      visible={openModal === 'Properties'}
      onCancel={handlePropertyModalClose}
      title={<h4>Edit Property</h4>}
      className='client-modal'
      footer={null}
      width='auto'
      style={{ top: 50, maxWidth: 1100 }}
      maskClosable={false}
    >
      <PropertyForm
        isProposal
        propertyId={propertyId}
        setEntityId={onSubmitPropertyForm}
        onCloseForm={
          handlePropertyModalClose
        } /*  proposalCloseTab={handlePropertyModalClose} */
      />
    </Modal>
  ) : null
}
