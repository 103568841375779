import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'

const DateModal = ({ modal, apply, close }) => {
  return (
    <Modal show={modal} onHide={close} backdrop='static' className='date-modal'>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p>Are you sure that you want to move appointment to different date?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-custom-cancel' onClick={close}>
          No
        </button>
        <button className='btn btn-custom-info' onClick={apply}>
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export { DateModal }
