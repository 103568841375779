import axiosOrcatec from './axiosInstance'

/**
 * Fetch todo list.
 *
 * @return {*}
 */
export const signUp = data => {
  return axiosOrcatec.post('/registration', data).then(response => response)
}
