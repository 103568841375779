import styled from 'styled-components'

export const Wrapper = styled.div<{ color: string }>`
  display: inline-flex;
  gap: 5px;
  align-items: center;
  background-color: ${({ color }) => color}33;
  padding: 1px 8px 3px 4px;
  border-radius: 20px;
  width: 100%;
`
export const Text = styled.p<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 500;
  position: relative;
  top: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
