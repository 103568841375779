import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from 'store/Orcatec/actions'
import { Table } from '../Table'
import { Buttons } from '../Buttons'
import { Tabs } from '../TabsV2'
import { Section } from '../Section'
import {
  formatTableDataClients,
  formatTableDataOrganizations,
  handleTableDataChange,
} from '../utils'
import { rowKeys } from './constants'
import { Prompt } from 'react-router-dom'
import ClientForm from 'containers/MainContent/Orcatec/Clients/components/Form/components/Form/index.js'
import { Modals } from '../Modal'
import { useHistory } from 'react-router-dom'
import { BlockRoutingModal } from 'containers/MainContent/Orcatec/components/BlockRoutingModal'
import { updateProposal } from 'store/Orcatec/actions/proposal/proposalForm'
import { putProjectFWOSettings } from 'api/Project'

export const Clients = ({
  openNotification,
  proposalForm,
  currentTableData,
  setCurrentTableData,
  qty,
  proposalId,
  freezed,
  openTab,
  onOpenForm,
  renderSelectTab,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const clientTypeRef = useRef()

  const currentClient = useSelector(state => state.orcatec.client.currentClient)
  const currentOrganization = useSelector(
    state => state.orcatec.organizations.currentOrganization,
  )
  const proposalFormId = useSelector(state => state.orcatec.proposalForm.id)
  const companyId = useSelector(state => state.orcatec.company.id)

  const [isTabsOpen, setIsTabsOpen] = useState(false)
  const [isSectionOpen, setIsSectionOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [clientData, setClientData] = useState({ id: null, type: '' })
  const [isHaveChange, setIsHaveChange] = useState(false)
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false)
  const [clientModal, setClientModal] = useState(false)
  const [clientModalId, setClientModalId] = useState(null)
  const [clientType, setClientType] = useState('')
  const [propertyModal, setPropertyModal] = useState(false)
  const [propertyModalId, setPropertyModalId] = useState(null)
  const [blockedRouteParams, setBlockedRouteParams] = useState({
    blockRoutingModal: false,
    nextLocation: '',
  })
  const [isShowEmptyClients, setIsShowEmptyClients] = useState(false)
  const [isBlockRoutingModal, setIsBlockRoutingModal] = useState(false)

  useEffect(() => {
    if (isSectionOpen) {
      setIsLoading(true)
      dispatch(
        actions.proposalContact.getAllProposalClients(proposalId, () =>
          setIsLoading(false),
        ),
      )
    } else {
      dispatch(actions.proposalContact.clearAttachedProposalClients())
    }
  }, [isSectionOpen])

  useEffect(() => {
    setClientData({ id: currentClient.id, type: 'client' })
  }, [currentClient])

  useEffect(() => {
    setClientData({ id: currentOrganization.id, type: 'organization' })
  }, [currentOrganization])

  useEffect(() => {
    setIsShowEmptyClients(!!calculateQty() && isSectionOpen)
  }, [isSectionOpen, proposalForm])

  const handleTableItemDelete = (id, type) => {
    const transformType = type => {
      if (type === 1) return 'organization'
      return 'client'
    }

    const transformedType = transformType(type)

    const selectedItem = currentTableData.find(obj => obj.id === id)

    const data = {
      [`${transformedType}_id`]: id,
      client_ids: selectedItem?.contacts?.reduce(
        (acc, curr) => (curr.checkbox === true ? acc.concat(curr.id) : acc),
        [],
      ),
      property_ids: selectedItem?.properties?.reduce(
        (acc, curr) => (curr.checkbox === true ? acc.concat(curr.id) : acc),
        [],
      ),
      organization_ids: selectedItem?.properties?.reduce(
        (acc, curr) => [
          ...acc,
          ...curr?.organizations?.filter(el => el.checkbox).map(el => el.id),
        ],
        [],
      ),
    }

    if (transformedType === 'client')
      dispatch(
        actions.proposalContact.detachProposalClientById(
          proposalId,
          data,
          handleAction,
        ),
      )
    if (transformedType === 'organization')
      dispatch(
        actions.proposalContact.detachProposalOrganizationById(
          proposalId,
          data,
          handleAction,
        ),
      )

    putProjectFWOSettings(
      proposalFormId,
      { customer: null, project: null, location: null },
      companyId,
    )
  }

  const handleAction = type => () => {
    if (type === 'save') {
      setIsHaveChange(false)
      if (blockedRouteParams.nextLocation) {
        history.push(blockedRouteParams.nextLocation)
      }
    }
    if (type === 'attach') setIsLoading(false)
    const clientType = clientTypeRef.current
      ? clientTypeRef.current
      : clientData.type === 'client'
      ? 'Client'
      : 'Organization'
    openNotification(type, clientType)
    setIsButtonsDisabled(false)
    //if (clientTypeRef.current) clientTypeRef.current = null
  }

  const handleButtonClick = type => {
    switch (type) {
      case 'apply':
        setIsLoading(true)
        if (clientData.type === 'client')
          dispatch(
            actions.proposalContact.attachProposalClientById(
              proposalId,
              clientData.id,
              handleAction,
            ),
          )
        if (clientData.type === 'organization')
          dispatch(
            actions.proposalContact.attachProposalOrganizationById(
              proposalId,
              clientData.id,
              handleAction,
            ),
          )
        putProjectFWOSettings(
          proposalFormId,
          { customer: null, project: null, location: null },
          companyId,
        )
        clearClientStore()
        setOpenTab('')
        break
      case 'cancel':
        clearClientStore()
        setOpenTab('')
        break
      case 'save':
        setIsButtonsDisabled(true)

        dispatch(updateProposal())

        if (currentTableData?.[0]?.type === 1) {
          dispatch(
            actions.proposalContact.saveProposalOrganizations(
              proposalId,
              formatTableDataOrganizations(currentTableData),
              handleAction,
            ),
          )
        } else {
          dispatch(
            actions.proposalContact.saveProposalClient(
              proposalId,
              formatTableDataClients(currentTableData),
              handleAction,
            ),
          )
        }
        putProjectFWOSettings(
          proposalFormId,
          { customer: null, project: null, location: null },
          companyId,
        )

        break
      case 'cancel-save':
        setIsHaveChange(false)
        setIsLoading(true)
        dispatch(
          actions.proposalContact.getAllProposalClients(proposalId, () =>
            setIsLoading(false),
          ),
        )
        break
      default:
        break
    }
  }

  const attachClientAfterSave = proposalId => clientData => {
    // setClientData({ id: clientData.id, type: clientData.type !== 1 ? 'client' : 'organization' })
    clientTypeRef.current = clientData.type !== 1 ? 'client' : 'organization'
    setIsLoading(true)
    setOpenTab('')
    if (clientData.type !== 1)
      dispatch(
        actions.proposalContact.attachProposalClientById(
          proposalId,
          clientData.id,
          handleAction,
        ),
      )
    if (clientData.type === 1)
      dispatch(
        actions.proposalContact.attachProposalOrganizationById(
          proposalId,
          clientData.id,
          handleAction,
        ),
      )
  }

  const calculateQty = () => {
    return proposalForm.countClients + proposalForm.countOrganizations || 0
  }

  const clearClientStore = () => {
    dispatch(actions.organizations.removeCurrentOrganization())
    dispatch(actions.client.removeCurrentClient())
  }

  const openModal = (id, type, key, deleteDate) => {
    if (key === 'client') {
      if (deleteDate) {
        setIsBlockRoutingModal(true)
      } else {
        if (type === 1) setClientType('organization')
        setClientModal(true)
        setClientModalId(id)
      }
    }
    if (key === 'property') {
      setPropertyModal(true)
      setPropertyModalId(id)
    }
  }

  const updateClientList = () => {
    dispatch(actions.proposalContact.clearAttachedProposalProperties())
    dispatch(
      actions.proposalContact.getAllProposalClients(proposalId, () =>
        setIsLoading(false),
      ),
    )
  }

  /*   const handleModalClose = () => {
    setClientModal(false)
    setClientModalId(null)
    setPropertyModal(false)
    setPropertyModalId(null)
    setClientType('')
    clearClientStore()
  } */

  const SaveButtons = style =>
    currentTableData && currentTableData?.length && isHaveChange ? (
      <Buttons
        handleButtonClick={handleButtonClick}
        firstBtn={{ action: 'cancel-save' }}
        secondBtn={{ action: 'save', title: 'Save' }}
        style={style}
        buttonsDisabled={isButtonsDisabled}
      />
    ) : null

  const handleBlockedRoute = nextLocation => {
    setBlockedRouteParams({
      blockRoutingModal: true,
      nextLocation: nextLocation,
    })
    return false
  }

  return (
    <>
      {/*  <Modals
        handleClientModalClose={handleModalClose}
        handlePropertyModalClose={handleModalClose}
        clientModal={clientModal}
        propertyModal={propertyModal}
        clientModalId={clientModalId}
        clientType={clientType}
        propertyModalId={propertyModalId}
        updateClientList={updateClientList}
      /> */}

      <BlockRoutingModal
        status={isBlockRoutingModal}
        handleCancel={() => setIsBlockRoutingModal(false)}
        title='This client has been deleted.'
        buttonsTitle={{ cancel: 'Cancel' }}
      />

      <Section
        title='Clients'
        qty={calculateQty()}
        isTabsOpen={openTab === 'Clients'}
        setIsTabsOpen={setIsTabsOpen}
        isSectionOpen={isSectionOpen}
        setIsSectionOpen={setIsSectionOpen}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        clearClientStore={clearClientStore}
        SaveButtons={SaveButtons}
        isHaveChange={isHaveChange}
        setIsHaveChange={setIsHaveChange}
        isShowEmpty={isShowEmptyClients}
        // renderSelectTab={renderSelectTab}
      >
        <Tabs isOpen={openTab === 'Clients'}>
          <ClientForm
            updateClientList={updateClientList}
            closeForm={() => handleButtonClick('cancel')}
            onChangeClientId={() => {}}
            setClientId={() => {}}
            changeModalTitle={true}
            setCurrentNameToModalTitle={() => {}}
            attachClientAfterSave={attachClientAfterSave(proposalId)}
            isProposal
            proposalButtonAttach={
              <Buttons
                handleButtonClick={handleButtonClick}
                firstBtn={{ action: 'cancel' }}
                secondBtn={{
                  action: 'apply',
                  title: 'Attach Client to Project',
                }}
                showFirstBtn={false}
                showSecondBtn={!!clientData.id}
                disableMargin
              />
            }
          />
        </Tabs>
        {currentTableData && currentTableData?.length > 0 && (
          <Table
            data={currentTableData}
            setTableData={handleTableDataChange(
              setCurrentTableData,
              setIsHaveChange,
            )}
            handleTableItemDelete={handleTableItemDelete}
            handleOpenItem={openModal}
            rowKeys={rowKeys}
            tableType='clients'
            freezed={freezed}
            updateTableData={() =>
              dispatch(
                actions.proposalContact.getAllProposalClients(proposalId, () =>
                  setIsLoading(false),
                ),
              )
            }
            onOpenForm={onOpenForm}
          />
        )}
        <SaveButtons />
      </Section>
      <BlockRoutingModal
        status={!!blockedRouteParams.blockRoutingModal}
        handleCancel={() =>
          setBlockedRouteParams({ blockRoutingModal: false, nextLocation: '' })
        }
        handleDiscard={() => {
          history.push(blockedRouteParams.nextLocation)
        }}
        handleSave={() => handleButtonClick('save')}
        title='You have some unsaved changes.'
        buttonsTitle={{
          save: 'Save and continue',
          discard: 'Discard and continue',
          cancel: 'Cancel',
        }}
        isLoading={isButtonsDisabled}
      />
      <Prompt
        when={isHaveChange && !blockedRouteParams.blockRoutingModal}
        message={handleBlockedRoute}
      />
    </>
  )
}
