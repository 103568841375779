import * as types from '../types'
import * as map from '../../../api/map'

export const fetchEmployees = ({ search = '', track_type = null, title = [], date }) => dispatch => {
  return map
    .fetchEmployees(search, track_type, title, date)
    .then(response =>
      dispatch({
        type: types.map.MAP_FETCH_EMPLOYEES,
        payload: response,
      }),
    )
    .then(response => {
      return response
    })
}

export const fetchEmployeesTitles = () => dispatch => {
  return map
    .fetchEmployeesTitles()
    .then(response =>
      dispatch({
        type: types.map.MAP_FETCH_EMPLOYEES_TITLES,
        payload: response,
      }),
    )
    .then(response => {
      return response
    })
}

export const fetchQueue = () => dispatch => {
  return map
    .fetchQueue()
    .then(response =>
      dispatch({
        type: types.map.MAP_FETCH_QUEUE,
        payload: response,
      }),
    )
    .then(response => {
      return response
    })
}

export const fetchToDO = () => dispatch => {
  return map
    .fetchToDo()
    .then(response =>
      dispatch({
        type: types.map.MAP_FETCH_TODO,
        payload: response,
      }),
    )
    .then(response => {
      return response
    })
}

export const fetchEmployeesRoutes = (date, employees = []) => dispatch => {
  return map
    .fetchEmployeesRoutes(date, employees)
    .then(response =>
      dispatch({
        type: types.map.MAP_FETCH_EMPLOYEES_ROUTES,
        payload: response,
      }),
    )
    .then(response => {
      return response
    })
}
export const updateEmployeColor = (id, color) => {
  return {
    type: types.map.UPDATE_EMPLOYE_COLOR,
    payload: { id, color },
  }
}

export const mapUpdateNeeded = bool => {
  return {
    type: types.map.MAP_UPDATE_NEEDED,
    payload: bool,
  }
}
