import axiosOrcatec from './axiosInstance'

/*
 ** Get quickbooks status.
 ** If logged in returns export history, if not - link to quickbooks login form
 */
export const getQuickbooksStatus = () => {
  return axiosOrcatec.get('/quickbooks/index')
}

/*
 ** Send signal to start export to quickbooks
 */
export const exportToQuickBooks = () => {
  return axiosOrcatec
    .get('/quickbooks/invoice')
    .then(res => res.data)
    .catch(error => console.log(error.message))
}

export const changeExportType = data => axiosOrcatec.post('quickbooks/settings', data)

export const sendQuickbooksParams = data => {
  return axiosOrcatec.post('/quickbooks/callback', data)
}

export const logoutQuickbooks = () => {
  return axiosOrcatec.post('/quickbooks/logout')
}
