const getStatus = (project, currentTab) => {
  const { status, all_work_completed, signed_tab_id, company } = project

  const payment_status = currentTab?.payment_status
  const isSignedTab = !signed_tab_id || signed_tab_id === currentTab?.id

  const invoiceStatusText =
    company?.id === 264 ? 'Field Work Order Completed' : 'Invoice'
  const estimateStatusText =
    company?.id === 264 ? 'Field Work Order' : 'Estimate'
  const contractStatusText =
    company?.id === 264 ? 'Field Work Order' : 'Contract'

  switch (status) {
    case 1:
      return estimateStatusText
    case 2:
    case 3:
      return payment_status === 4 && !!all_work_completed
        ? 'Receipt'
        : !!all_work_completed && payment_status !== 4 && isSignedTab
        ? invoiceStatusText
        : isSignedTab
        ? contractStatusText
        : estimateStatusText
    case 4:
      return 'Cancelled'
    default:
      return estimateStatusText
  }
}

export default getStatus
