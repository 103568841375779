import * as types from '../../types'
import { fromJS, isImmutable } from 'immutable'

const initState = fromJS({
  list: {
    data: [],
    limit: 10,
    page: 1,
    total: 0,
    total_pages: 0,
  },
  item: {},
})

export default (state = initState, action) => {
  const supplierState = isImmutable(state) ? state : fromJS(state)

  switch (action.type) {
    case types.supplier.SUPPLIER_SET_ITEM:
      return action.item
    case types.supplier.SUPPLIER_GET_ITEM:
      return state
    case types.supplier.SUPPLIER_SET_LIST:
      return supplierState.set('list', action.list).toJS()
    default:
      return state
  }
}
