import * as types from '../../types'
import { fromJS, get, set } from 'immutable'

const initState = fromJS({
  newItem: {},
  equipments: {},
  scopeOfWorks: {},
  warranties: {},
  instants: {},
  rebates: {},
  parts: {},
  supplies: {},
})

export default (state = initState, action) => {
  const item = fromJS(state)
  const newState = JSON.parse(JSON.stringify(state))

  let index = 0

  if (typeof action.id !== 'undefined' && typeof state.items !== 'undefined') {
    index = item
      .getIn(['items', 'items'])
      .findIndex(curItem => curItem.get('id') === action.id)
  }

  switch (action.type) {
    case types.item.ITEM_SET_ITEM: {
      let item_type = action.item_type.toLowerCase() + 's'
      if (action.item_type === 'ScopeOfWork') item_type = 'scopeOfWorks'
      if (action.item_type === 'Supply') item_type = 'supplies'
      if (action.item_type === 'Warranty') item_type = 'warranties'
      newState[item_type].items[action.idx] = action.item
      return newState
    }
    case types.item.SET_EQUIPMENTS:
      newState.equipments = action.equipments
      return newState
    case types.item.SET_PARTS:
      newState.parts = action.parts
      return newState
    case types.item.SET_SUPPLIES:
      newState.supplies = action.supplies
      return newState
    //return item.set('equipments', action.equipments).toJS()
    case types.item.SET_SCOPEOFWORKS:
      newState.scopeOfWorks = action.scopeOfWorks
      return newState
    //return item.set('scopeOfWorks', action.scopeOfWorks).toJS()
    case types.item.SET_WARRANTIES:
      newState.warranties = action.warranties
      return newState
    //return item.set('warranties', action.warranties).toJS()
    case types.item.SET_REBATE:
      newState.rebates = action.rebates
      return newState
    //return item.set('rebates', action.rebates).toJS()
    case types.item.SET_INSTANT:
      newState.instants = action.instants
      return newState
    //return item.set('instants', action.instants).toJS()

    case types.item.PROXY_ITEM_UPDATE: {
      let itemToChange
      switch (action.item_type) {
        case 'Equipment':
          itemToChange = item
            .getIn(['equipments', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.equipments.items[itemToChange][action.attribute] =
            action.value
          break
        case 'Part':
          itemToChange = item
            .getIn(['parts', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.equipments.items[itemToChange][action.attribute] =
            action.value
          break
        case 'Supply':
          itemToChange = item
            .getIn(['supplies', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.equipments.items[itemToChange][action.attribute] =
            action.value
          break
        case 'ScopeOfWork':
          itemToChange = item
            .getIn(['scopeOfWorks', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.scopeOfWorks.items[itemToChange][action.attribute] =
            action.value
          break
        case 'Warranty':
          itemToChange = item
            .getIn(['warranties', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.warranties.items[itemToChange][action.attribute] =
            action.value
          break
        case 'Instant':
          itemToChange = item
            .getIn(['instants', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.instants.items[itemToChange][action.attribute] = action.value

          break
        case 'Rebate':
          itemToChange = item
            .getIn(['rebates', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.rebates.items[itemToChange][action.attribute] = action.value
          break
      }
      return newState
    }
    case types.item.ITEM_SET_ITEM_TO_ITEMS: {
      const newList = item.getIn(['items', 'items']).unshift(action.item)
      return item.setIn(['items', 'items'], newList).toJS()
    }
    case types.item.ITEM_GET_ITEM:
      return state
    case types.item.ITEM_DEL_ITEM: {
      let indexForDelete
      switch (action.itemType) {
        case 'Equipment':
          indexForDelete = item
            .getIn(['equipments', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.equipments.items.splice(indexForDelete, 1)
          break
        case 'Part':
          indexForDelete = item
            .getIn(['parts', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.parts.items.splice(indexForDelete, 1)
          break
        case 'Supply':
          indexForDelete = item
            .getIn(['supplies', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.supplies.items.splice(indexForDelete, 1)
          break
        case 'ScopeOfWork':
          indexForDelete = item
            .getIn(['scopeOfWorks', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.scopeOfWorks.items.splice(indexForDelete, 1)
          break
        case 'Warranty':
          indexForDelete = item
            .getIn(['warranties', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.warranties.items.splice(indexForDelete, 1)

          break
        case 'Instant':
          indexForDelete = item
            .getIn(['instants', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.instants.items.splice(indexForDelete, 1)

          break
        case 'Rebate':
          indexForDelete = item
            .getIn(['rebates', 'items'])
            .findIndex(curItem => curItem.get('id') === action.id)
          newState.rebates.items.splice(indexForDelete, 1)
          break
      }
      return newState
    }

    case types.item.ITEM_DELETE_ITEM:
      return Object.assign({}, action.item)

    case types.item.ITEM_CHECK_ITEM:
      if (action.id === 'uncheckAll' || action.id === 'checkAll') {
        const currentItems = item.getIn([action.itemType, 'items'])
        const newItems = currentItems.map(itemObject =>
          set(itemObject, 'checked', action.id === 'checkAll'),
        )
        return item.setIn([action.itemType, 'items'], newItems).toJS()
      } else {
        const itemObject = item
          .getIn([action.itemType, 'items'])
          .find(item => item.get('id') === action.id)
        const resultItem = get(itemObject, 'checked', false)
          ? itemObject.set('checked', false)
          : set(itemObject, 'checked', true)
        index = item
          .getIn([action.itemType, 'items'])
          .findIndex(curItem => curItem.get('id') === action.id)
        return item.setIn([action.itemType, 'items', index], resultItem).toJS()
      }
    case types.item.CLEAR_NEW_ITEM:
      newState.newItem = {}
      return newState
    case types.item.ITEM_SET_ITEMS:
      newState.item.items = action.items
      return newState
    case types.item.ITEM_FETCH_Equipment:
      break
    case types.item.ITEM_FETCH_Instant:
      break
    case types.item.ITEM_FETCH_Rebate:
      break
    case types.item.ITEM_FETCH_ScopeOfWork:
      break
    case types.item.ITEM_FETCH_Warranty:
      break
    case types.item.ITEM_CHANGE_NEW_ITEM:
      if (action.field === 'clear') {
        newState.newItem = {}
      } else {
        let idx
        if (action.field === 'attachment_one') idx = 0
        if (action.field === 'attachment_two') idx = 1
        if (action.field === 'attachment_three') idx = 2
        if (!newState.newItem.files)
          newState.newItem.files = [] /* [null, null, null] */
        if (/^attachment_/.test(action.field)) {
          newState.newItem.files[idx] = action.value
          if (typeof action.value === 'string')
            newState.newItem[action.field] = action.value
          else
            newState.newItem[action.field] =
              process.env.REACT_APP_API_MEDIA + action.value.path
        } else {
          newState.newItem[action.field] = action.value
        }
      }
      return newState

    case types.item.ITEM_EDIT_CELL:
      item.items.items[index].edit = {
        cellIndex: action.cellIndex,
        showInput: action.showInput,
      }
      return Object.assign({}, item)
    case types.item.ITEM_SEARCH_QUERY:
      return item.set('search', action.query).toJS()

    default:
      return state
  }
}
