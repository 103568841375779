import React from "react"
import { textFileLoader } from "../../utils/fileLoaders.tsx"

const TXTRenderer= ({state}) => {

  return <div className="txt-renderer" id="txt-renderer">{state.currentDATA}</div>;
};

export default TXTRenderer

TXTRenderer.fileTypes = "txt"
TXTRenderer.fileLoader = textFileLoader


