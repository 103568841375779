import {
  AmexIcon,
  DiscoverIcon,
  MasterCardIcon,
  VisaIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import Preloader from 'containers/MainContent/Orcatec/components/Preloader'
import React, { useEffect, useRef, useState } from 'react'
import { CardFrame, Form, IFrame, PaymentMehods, Wrapper } from './Card.styles'
import { cssStyles } from './iframeStyles'

type CardInfo = {
  card_holder: string
  billing_address: {
    address: string
    city: string
    region: string
    postal: string
  }
}

interface Props {
  url: string
  params?: {
    maskfirsttwo?: boolean
    useexpiry?: boolean
    usemonthnames?: boolean
    usecvv?: boolean
    cardnumbernumericonly?: boolean
    orientation?: 'horizontal' | 'vertical'
    invalidinputevent?: boolean
    enhancedresponse?: boolean
    formatinput?: boolean
    tokenizewheninactive?: boolean
    inactivityto?: boolean
    autofocus: boolean
    useexpiryfield?: boolean
  }
  cardData: CardInfo
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onGotToken: (token: { message: string; expiry: string }) => void
}

const Card = ({ url, onGotToken, params }: Props) => {
  const [isLoading, setIsloading] = useState(true)

  const tokenRef = useRef() as React.MutableRefObject<HTMLInputElement>

  useEffect(() => {
    if (!isLoading) {
      window.addEventListener('message', handleGetToken, false)
    }

    return () => {
      window.removeEventListener('message', handleGetToken)
    }
  }, [isLoading])

  const settingsParams = {
    ...params,
    cardinputmaxlength: 19,
    // cardnumbernumericonly: true,
    enhancedresponse: true,
    placeholder: '1111 2222 3333 4444',
    placeholdermonth: 'MM',
    placeholderyear: 'YYYY',
    placeholdercvv: '123',
    formatinput: true,
  }

  const encodedParams = new URLSearchParams(settingsParams)
    .toString()
    .replaceAll('+', ' ')
  const iFrameUrl = url + '?' + encodedParams + '&css=' + encode(cssStyles)

  const handleGetToken = event => {
    event.preventDefault()

    if (typeof event?.data === 'string') {
      const token = JSON.parse(event.data)

      onGotToken?.(token)
    }
  }

  const handleLoadIframe = () => setIsloading(false)

  return (
    <Wrapper>
      {isLoading && <Preloader />}

      <Form name='tokenform' id='tokenform' isLoading={isLoading}>
        <CardFrame>
          <PaymentMehods>
            <MasterCardIcon />
            <VisaIcon />
            <AmexIcon />
            <DiscoverIcon />
          </PaymentMehods>

          <IFrame
            id='tokenFrame'
            name='tokenFrame'
            src={iFrameUrl}
            scrolling='no'
            onLoad={handleLoadIframe}
          />
          <input type='hidden' name='mytoken' id='mytoken' ref={tokenRef} />
        </CardFrame>
      </Form>
    </Wrapper>
  )
}

export default Card

const encode = (string: string) =>
  encodeURIComponent(string)
    .replace(/'/g, '%27')
    .replace(/"/g, '%22')
