import { Modal } from 'react-bootstrap'
import './index.scss'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import MainButton from '../buttons/MainButton'

const MergeModal = ({
  show,
  onHide,
  onHandleMerge,
  onSwapItemsToMerge,
  itemsToMerge,
  name,
  isMerging = false,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
      dialogClassName='merge-modal'
    >
      <Modal.Header closeButton>
        <span className='merge-title'>{`Merge ${name} `}</span>
        <span className='merge-icon'>&nbsp;</span>
        <Tooltip title={`Press arrows to change from/to ${name}`}>
          <QuestionCircleOutlined />
        </Tooltip>
      </Modal.Header>
      <Modal.Body>
        {itemsToMerge?.length < 2 ? (
          <div style={{ textAlign: 'center' }}>Select {name} to merge</div>
        ) : (
          <div>
            <div className='from'>
              <span className='choosen-item'>From:</span>
              <span>
                {itemsToMerge.length
                  ? itemsToMerge[0].display_info.split(`\n`)[0]
                  : ''}
              </span>
            </div>
            <div className='reverse-btn'>
              <i
                className='mdi mdi-swap-vertical'
                onClick={onSwapItemsToMerge}
              />
            </div>
            <div className='to'>
              <span className='choosen-item'>To:</span>
              <span>
                {itemsToMerge.length > 1
                  ? itemsToMerge[1].display_info.split(`\n`)[0]
                  : ''}
              </span>
            </div>
            <div className='result'>
              <div className='choosen-item'>Result will be:</div>
              <div>
                {itemsToMerge.length > 1
                  ? itemsToMerge[1].display_info.split(`\n`)[0]
                  : ''}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {/* <button className='btn btn-custom-cancel' onClick={onHide} style={{ height: 40 }}>
          Cancel
        </button> */}
        <MainButton title='Cancel' type='cancel' onClick={onHide} />
        {/* <button className='btn btn-info' onClick={onHandleMerge}>
          {`Merge ${name}`}
        </button> */}

        <MainButton
          title={`Merge ${name}`}
          onClick={onHandleMerge}
          className='ml-3'
          isFetching={isMerging}
          disabled={itemsToMerge?.length < 2}
          // style={{ width: '170px' }}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default MergeModal
