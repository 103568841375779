import getStatus from 'containers/MainContent/Orcatec/Proposals/helpers/getTabStatus'
import { IProjectTab, Project } from 'features/Project/types'
import styled from 'styled-components'
import { Companies } from 'types/Company'

interface Props {
  project: Project
  currentTab: IProjectTab
}

export const ProjectStatus = ({ project, currentTab }: Props) => {
  const companyId = project?.user?.company_id

  return (
    <Wrapper>
      {companyId === Companies['Trilogy Surgical Solutions']
        ? 'Work Order'
        : getStatus(project, currentTab)}
    </Wrapper>
  )
}

const Wrapper = styled.h4`
  margin: 10px 0;
  text-align: center;
`
