import axiosOrcatec, { CancelToken } from '../../../api/axiosInstance'
import axios from 'axios'
let cancelUploadDocumentRequest

const onUploadProgress = loadCallback => progressEvent => {
  const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
  loadCallback({ percentCompleted, loaded: progressEvent.loaded, total: progressEvent.total })
}

const getConfig = loadCallback => ({
  cancelToken: new CancelToken(c => {
    cancelUploadDocumentRequest = c
  }),
  onUploadProgress: !!loadCallback && onUploadProgress(loadCallback),
})

export const getDocuments = id => axiosOrcatec.get(`/admin/documents?account_manager_id=${id}`)

export const saveDocument = (data, id, uploadCallback) => {
  const config = getConfig(uploadCallback)
  return axiosOrcatec.post('/admin/documents', data, config)
}

export const updateDocument = (data, id, uploadCallback) => {
  const config = getConfig(uploadCallback)
  return axiosOrcatec.post(`/admin/documents/update/${id}`, data, config)
}

export const updateAM = (data, id) => axiosOrcatec.put(`/admin/account_managers/${id}`, data)

export const getAddressSearch = query =>
  axiosOrcatec.get(`/admin/properties/search?query=${query}`).then(res => res.data)
