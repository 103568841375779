import { useEffect, useState, useRef } from 'react'
import PageLayout from '../../../Layouts/PageLayout'
import MainButton from '../../../components/buttons/MainButton'
import { useHistory, useParams, withRouter } from 'react-router-dom'
import './style.scss'
import {
  getProposalPreviewData,
  updateProposal,
  getProposalPreviewDataByLink,
  // setSignatureByLink,
  // getDiffPrices,
  getProposalPdf,
  getPdfOnCustomeView,
  getDataOfPrice,
} from '../../../../../../api/Proposal'
import Preloader from '../../../components/Preloader'
import { downloadBlob } from 'helpers/downloadBlob'
import moment from 'moment-timezone'
import ComponentToPrint from './ComponentToPrint/ComponentToPrint'
import { Modal } from 'antd'
import ModalWrapper from '../../../components/ModalWrapper/ModalWrapper'
import { getSelectedClients } from '../../helpers/getSelectedClients'
import ModalFileUpload from '../Modals/ModalFileUploadContainer'
import { deleteMedia } from 'api/Media'
// import { useDebounce } from 'containers/MainContent/Orcatec/components/SearchHook'
import { fetchProposal } from 'api/settings/Proposal'
import { Alert } from '@material-ui/lab'
import { useDispatch } from 'react-redux'
import * as actions from 'store/Orcatec/actions'
import PaymentProcess from '../PaymentProcess'
import states from 'helpers/states'
// import produce from 'immer'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import {
  NotificationTemplateType,
  NotificationModalType,
} from 'features/Notification/types'
import { isMobileDevise } from '../../../../../../helpers/isMobileDevice'
import ConfirmModal from 'containers/MainContent/Orcatec/components/ConfirmModal/ConfirmModal'
import DocViewer from 'helpers/viewFIlesNew/ViewDoc'
import { FieldWorkOrders } from './FieldWorkOrders/FieldWorkOrders'
import { Companies } from 'types/Company'
import { useAppSelector } from 'store/Orcatec/hooks'
import { meSelector } from 'store/SuperAdmin/selectors'
import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'
// import { setCompany } from 'store/Orcatec/actions/settings/company'
import ProjectItemsAPI, { ProjectAPI, updatePublicProject } from 'api/Project'
import { IHeaderType } from 'layouts/MainLayout/Header/components/types'

const gatherItems = projectItems => {
  return projectItems?.reduce((items, item) => {
    if (!item.option_group_id) {
      items = [...items, item]
      return items
    }

    const groupIndex = items.findIndex(
      group => group.option_group_id === item.option_group_id,
    )

    if (groupIndex >= 0) {
      items[groupIndex] = {
        ...(item.checked_option ? item : items[groupIndex]),
        options: {
          checked_option: item.checked_option
            ? item.id
            : items[groupIndex].options.checked_option,
          data: [...items[groupIndex].options.data, item].sort(
            (a, b) => a.order_option - b.order_option,
          ),
        },
      }
    } else {
      items.push({
        ...item,
        options: {
          checked_option: item.checked_option ? item.id : null,
          data: [item],
        },
      })
    }

    return items
  }, [])
}

const dummyData = {
  id: 1,
  all_work_completed: 1,
  billing_address: {
    address: '111 Street',
    postcode: '00000',
    city: 'Sity',
    state: 'State',
    unit: '1',
    full_address: '111 Unit 1 Street, 00000',
  },
  property: {
    address: '111 Street',
    postcode: '00000',
    city: 'Sity',
    state: 'State',
    unit: '2',
    full_address: '111 Unit 2 Street, 00000',
  },
  canceled_info: { reason: null, date: null, user: null },
  files: [],
  financing: true,
  status: 1,
  created_at: moment().format('MM/DD/YYYY'),
  // show_price: null,
  contacts: [
    {
      email: 'test@mail.com',
      name: 'Test Client',
      phones: [{ phone: '(213) 213-1231', type: 'cell' }],
      relation_name: 'Some Relation',
    },
    {
      email: 'testOrg@mail.com',
      name: 'Test Organization',
      phones: [{ phone: '(213) 132-1211', type: 'cell' }],
      relation_name: 'Parent Company',
    },
  ],
  // organizations: [
  //   {
  //     email: 'testOrg@mail.com',
  //     name: 'Test Organization',
  //     phones: [{ phone: '(213) 132-1211', type: 'cell' }],
  //     relation_name: 'Parent Company',
  //   },
  // ],
  code: 'XXX00000000',
  info_for_customer: 'Test info for customer',
  installation_dates: [],
  ip_client: null,
  name: 'Test proposal name',
  //paste company data here
  company: {
    address: {
      address: '456 Saratoga Avenue',
      city: 'San Jose',
      state: 'California',
      zip: '95129',
    },
    company_phone: '(666) 666-7777',
    fax: '(223) 123-8888',
    logo: 'logotype/5lnJhzLdRajrQxF2XlTAoMx5LomJRYRYk9NTNjun.jpeg',
  },
  //set me here
  user: {
    name: 'My name',
    title: 'My title',
    phone: '(000) 000-0000',
    email: 'my@email.com',
  },
  tabs_info: [{ id: 1, title: 'Test tab', position: 0 }],
  financing_info: [
    {
      investmentSubtotal: 12674,
      totalAfterInstants: 12674,
      totalAfterRebatesAndInstants: 12674,
      tax: 106,
      totalToPay: 12824,
      instants: 21,
      rebates: 212,
      fees: 150,
      payPerMonth: 254,
    },
    // link: 'https://api-dev.orcatec.jointoit.com/api/documentation',
  ],
  proposal_tabs: [
    {
      id: 1,
      proposal_id: 1,
      title: 'Test tab',
      notes_for_installers: 'Some notes for installers here',
      proposal_notes: 'Some notes of this proposal here',
      total_to_pay: '12824',
      position: 0,
      proposal_sections: [
        {
          id: 1,
          title: 'Test section',
          installation: 54645,
          total_to_pay: 58185,
          gross_margin: 20,
          groups: [],
          items: [
            {
              qty: 2,
              price: 150,
              name: 'Item',
              body: {
                brand: { name: 'brand' },
                model: { name: 'model' },
                type: { name: 'type' },
                cat5: 'cat1',
                cat6: 'cat2',
                size: '300',
              },
              group_id: null,
              use_calculation: true,
            },
            {
              qty: 6,
              price: 342,
              name: 'Item 2',
              description: 'Item with description',
              body: {
                brand: { name: 'brand' },
                model: { name: 'model' },
                type: { name: 'type' },
                cat5: 'cat1',
                cat6: 'cat2',
                size: '555',
              },
              group_id: null,
              use_calculation: true,
            },
            {
              qty: 1,
              price: 432,
              name: 'Item 3',
              body: {
                brand: { name: 'brand' },
                model: { name: 'model' },
                type: { name: 'type' },
                cat5: 'cat1',
                cat6: 'cat2',
                size: '666',
              },
              group_id: null,
              use_calculation: true,
            },
            {
              name: 'Install AC',
              price: 123,
              qty: 2,
              group_id: null,
              use_calculation: true,
            },
          ],
          warranties: [
            {
              description: 'some description',
              price: 444,
              qty: 4,
              term: '10 years',
              required_to_pay: true,
            },
          ],
          instants: [
            {
              description: 'Some description of discount',
              expiration_date: moment().format('MM/DD/YYYY'),
              price: 21,
            },
          ],
          rebates: [
            {
              description: 'Some description of rebate',
              expiration_date: moment().format('MM/DD/YYYY'),
              price: 212,
            },
          ],
          structure: {
            materials: { title: 'Materials', position: 0 },
            scope_of_works: { title: 'Scope Of Work', position: 1 },
          },
        },
      ],
      payment: [],
    },
  ],
  logos: null,
  setting: {
    header:
      '<h2 style="text-align: center;">Heading</h2><p>Information about company</p>',
  },
  //paste proposal settings here
}

const EventKeys = {
  ACCEPT_PROJECT: 'accept_project',
  OPEN_FILE: 'open_file',
  PAY_BAlANCE: 'pay_balance',
  SELECT_TAB: 'select_tab',
  SELECT_ITEM: 'select_item',
}

const isValidJSON = str => {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

const PageProposalPreview = props => {
  const isLoadDataByLink = window.location.pathname === '/preview'
  const isPreviewProposal = window.location.pathname.includes(
    '/proposal/preview',
  )
  const me = useAppSelector(meSelector)

  const isVerticalNavigation =
    me?.ui_settings?.header_view === IHeaderType.HEADER_VERTICAL

  const isPreviewFromSettings = window.location.pathname === '/project/preview'
  const [proposalForm, setProposalForm] = useState({})
  // const [userIP, setUserIP] = useState('')
  const [isProposalLoading, setIsProposalLoading] = useState(true)
  // const [isDiffPriceLoading, setIsDiffPriceLoading] = useState(true)
  const [isProposalSigning, setIsProposalSigning] = useState(true)
  const [isTabLoading, setIsTabLoading] = useState(false)
  const [isTabChange, setIsTabChange] = useState(false)
  const [activeTabIdx, setActiveTabIdx] = useState(null)
  const [signatureModal, setSignatureModal] = useState(false)
  const [emailModal, setEmailModal] = useState(false)

  const [loading, setLoading] = useState(false)
  const [totalToPay, setTotalToPay] = useState(0)
  // const [allTotalPrices, setAllTotalPrices] = useState({})
  const [successPaymentModal, setSuccessPaymentModal] = useState(false)
  const [isWorkCompletedChanging, setIsWorkCompletedChanging] = useState(false)
  const [selectedBalance, setSelectedBalance] = useState(null)
  // const [renderCount, setRenderCount] = useState(0)
  // const [isOptionLoading, setIsOptionLoading] = useState(false)

  const [paymentModal, setPaymentModal] = useState(false)
  const [generatePdf, setGeneratePdf] = useState(false)
  const [downloadPdfLoader, setDownloadPdfLoader] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)

  const [isEdited, setIsEdited] = useState(false)

  const [viewerModal, setViewerModal] = useState({
    showDocViewerModal: false,
    documents: [],
    currentFileIdx: null,
  })

  const dispatch = useDispatch()
  const history = useHistory()
  const isMobile = isMobileDevise()

  // const debouncedChanges = useDebounce(proposalForm, 1000)
  const useQuery = () => {
    return new URLSearchParams(props.location.search)
  }

  const isMobileApp = () =>
    !!window?.ReactNativeWebView && !!window?.ReactNativeWebView?.postMessage

  const onClickInApp = event => {
    if (isMobileApp()) {
      window.ReactNativeWebView?.postMessage(JSON.stringify(event))
    }
  }

  const isShowPrice = isMobileApp()
    ? window?.orcatec_permission_price
    : useAppSelector(selectIsPriceVisible(proposalForm?.user_id))

  const isShowDescription = isMobileApp()
    ? window?.orcatec_permission_description
    : true

  // console.log('isMobileApp', isMobileApp())
  // console.log('window', window)
  // console.log('orcatec_permission_price', window?.orcatec_permission_price)

  const search = useQuery()

  //need for print and open tab
  const tabIdToPrint = search.get('tabId')
  const isPrintNedeed = search.get('print')

  const onbeforeunload = e => {
    e.preventDefault()
    e.returnValue =
      'Are you sure you want to leave the page without saving changes?'
  }

  useEffect(() => {
    if (isEdited) window.addEventListener('beforeunload', onbeforeunload)
    else window.removeEventListener('beforeunload', onbeforeunload)
    return () => {
      window.removeEventListener('beforeunload', onbeforeunload)
    }
  }, [isEdited])

  useEffect(() => {
    if (!selectedBalance) return

    setPaymentModal(true)
  }, [selectedBalance])

  useEffect(() => {
    const handler = function(event) {
      if (typeof event?.data !== 'string' && !event?.data?.length) return

      if (!isValidJSON(event?.data)) return

      const keys = {
        set_setting: 'set_setting',
        need_refresh: 'need_refresh',
      }

      try {
        const data = JSON.parse(event?.data)

        if (data?.key === keys?.set_setting) {
          setProposalForm(prev => ({
            ...prev,
            setting: {
              ...prev.setting,
              ...data?.value,
            },
          }))
        }

        if (data?.key === keys.need_refresh) {
          loadProposal()
        }
      } catch (error) {
        console.error(error)
      }
    }
    window.addEventListener('message', handler, true)
    return () => window.removeEventListener('message', handler)
  }, [])

  // useEffect(() => {
  //   console.log('changed')
  // }, [proposalForm.files_from_customer])

  const toggleConfirmModal = () => {
    setConfirmModal(prev => !prev)
    document.querySelector('body').style = ''
  }

  /* useEffect(() => {
    setRenderCount(renderCount + 1)
    const saveProposal = async () => {
      setIsOptionLoading(true)
      const data = {
        ...proposalForm,
        proposal_tabs: [
          {
            ...proposalForm.proposal_tabs[0],
            proposal_sections: proposalForm.proposal_tabs[0].proposal_sections.map(
              section => ({
                ...section,
                items: section?.items
                  ?.flatMap(item =>
                    item?.options
                      ? [
                          ...section.items,
                          ...item.options.data.map(option => ({
                            ...option,
                            option_group_id:
                              item.options.data.find(
                                option => option.checked_option,
                              )?.id || option.option_group_id,
                          })),
                        ]
                      : item,
                  )
                  ?.filter(item => !item.options),
                discounts: section?.discounts
                  ?.flatMap(item =>
                    item?.options
                      ? [
                          ...section.discounts,
                          ...item.options.data.map(option => ({
                            ...option,
                            option_group_id:
                              item.options.data.find(
                                option => option.checked_option,
                              )?.id || option.option_group_id,
                          })),
                        ]
                      : item,
                  )
                  ?.filter(item => !item.options),
                rebates: section?.rebates
                  ?.flatMap(item =>
                    item?.options
                      ? [
                          ...section.rebates,
                          ...item.options.data.map(option => ({
                            ...option,
                            option_group_id:
                              item.options.data.find(
                                option => option.checked_option,
                              )?.id || option.option_group_id,
                          })),
                        ]
                      : item,
                  )
                  ?.filter(item => !item.options),
              }),
            ),
          },
        ],
      }
      if (!isLoadDataByLink) {
        await updateProposal(proposalForm.id, data)
      } else {
        await setSignatureByLink({
          ...data,
          token: props.location.search.substr(1),
        })
      }
      setIsOptionLoading(false)
      // setLoading(false)
    }

    if (renderCount > 1 && !isPreviewFromSettings) {
      saveProposal()
    }
  }, [debouncedChanges]) */

  const toggleFileUploadModal = () => {
    dispatch(
      actions.proposalModalFiles.toggleModal(
        'form',
        proposalForm.files_from_customer,
      ),
    )
  }

  // const [diffPrices, setDiffPrices] = useState({})

  const { id: idFromParams } = useParams()

  // const wasEdited = isNewCard || isOldCardChanged

  const componentRef = useRef()

  // const newProposalForm = JSON.parse(JSON.stringify(proposalForm))

  const handleTotalToPayChange = amount => {
    if (amount === totalToPay) return
    setTotalToPay(amount)
  }

  // const handleAllTotalpricesChange = prices => {
  //   setIsOptionLoading(true)
  //   setAllTotalPrices(prices)
  // }

  /* const handleChangeChoosenOption = (
    sectionIdx,
    itemType,
    itemIdx,
    optionIdx,
    prices,
    parentID,
    totals,
  ) => {
    setIsOptionLoading(true)
    setDiffPrices(prev => {
      const newDiffPrices = {
        ...prev,
        [parentID]: prices,
      }
      return newDiffPrices
    })

    const itemIndex = proposalForm.proposal_tabs[0].proposal_sections[
      sectionIdx
    ][itemType].findIndex(item => item.id === parentID)
    let choosenItem =
      proposalForm.proposal_tabs[0].proposal_sections[sectionIdx][itemType][
        itemIndex
      ]
    let choosenOption = choosenItem.options.data[optionIdx]
    newProposalForm.proposal_tabs[0].proposal_sections[sectionIdx][itemType][
      itemIndex
    ].options.checked_option = choosenOption.idForChoosenOption
    newProposalForm.proposal_tabs[0].proposal_sections[sectionIdx][itemType][
      itemIndex
    ] = {
      ...newProposalForm.proposal_tabs[0].proposal_sections[sectionIdx][
        itemType
      ][itemIndex].options.data[optionIdx],
      options: {
        ...newProposalForm.proposal_tabs[0].proposal_sections[sectionIdx][
          itemType
        ][itemIndex].options,
        data: newProposalForm.proposal_tabs[0].proposal_sections[sectionIdx][
          itemType
        ][itemIndex].options.data.map(option => ({
          ...option,
          checked_option: option.id === choosenOption.id,
        })),
      },
      position:
        newProposalForm.proposal_tabs[0].proposal_sections[sectionIdx][
          itemType
        ][itemIndex].position,
      id: parentID,
      material_type: choosenItem.material_type,
      relation_with_template:
        newProposalForm.proposal_tabs[0].proposal_sections[sectionIdx][
          itemType
        ][itemIndex].relation_with_template,
    }
    newProposalForm.proposal_tabs[0].total_to_pay = totals.totalToPay

    setProposalForm(newProposalForm)
  } */

  const handleOptionChange = async (option, type) => {
    setLoading(true)

    type === 'discounts'
      ? await ProjectItemsAPI.updatePublicDiscount(option.id, {
          checked_option: true,
        })
      : await ProjectItemsAPI.updatePublicItem(option.id, {
          checked_option: true,
        })
    loadProposal()

    onClickInApp({
      key: EventKeys.SELECT_ITEM,
      value: proposalForm.proposal_tabs?.[0]?.id,
    })
  }

  const handleChangeClientNotes = e => {
    setProposalForm({
      ...proposalForm,
      info_from_customer: e.target.value,
    })

    setIsEdited(true)
  }

  const handleChangeDescription = (e, idx) => {
    const filesWithDescriptions = [...proposalForm.files_from_customer]
    filesWithDescriptions[idx].description = e.target.value
    setProposalForm({
      ...proposalForm,
      files_from_customer: filesWithDescriptions,
    })

    setIsEdited(true)
  }

  const handleTextFieldBlur = fieldName => {
    updatePublicProject(proposalForm.id, {
      [fieldName]: proposalForm[fieldName],
    })

    setIsEdited(false)

    onClickInApp({
      key: EventKeys.SELECT_ITEM,
      value: proposalForm.proposal_tabs?.[0]?.id,
    })
  }

  const handleSelectItem = async (
    value,
    /*sectionIdx, itemType,  */ optionId,
  ) => {
    if (isPreviewFromSettings) return
    setLoading(true)

    try {
      await ProjectItemsAPI.updatePublicItem(optionId, {
        use_calculation: value,
      })

      loadProposal()

      onClickInApp({
        key: EventKeys.SELECT_ITEM,
        value: proposalForm.proposal_tabs?.[0]?.id,
      })
      /*  const data = await getDataOfPrice({
        proposal_id: proposalForm.id,
        tab_id: proposalForm.proposal_tabs?.[0]?.id,
        item_id: itemId,
        item_type: itemType,
        use_calculation: value,
      })

      setAllTotalPrices(data)

      setProposalForm(
        produce(draft => {
          const item = draft.proposal_tabs?.[0]?.proposal_sections?.[
            sectionIdx
          ]?.[itemType]?.find(item => item.id === itemId)
          item.use_calculation = value

          if (draft?.proposal_tabs?.[0]?.tax)
            draft.proposal_tabs[0].tax.total = data.tax

          draft.proposal_tabs[0].total_to_pay = data.totalToPay
        }),
      ) */
    } catch (error) {
      openNotificationWithIcon('error', { message: 'Something went wrong' })
    }
  }

  const setProposal = data => {
    if (!Array.isArray(data.logos)) {
      let logos = []
      for (let i = 1; i <= 7; i++) {
        if (data.logos) {
          const logo = data.logos[`logo${i}`] ? data.logos[`logo${i}`] : {}
          logos.push(logo)
        } else {
          logos.push({})
        }
      }
      if (data.logos) data.logos = logos
    }

    const structure = {
      ...data,
      proposal_tabs: [
        {
          ...data.proposal_tabs[0],
          proposal_sections: data.proposal_tabs[0].proposal_sections.map(
            section => ({
              ...section,
              items: gatherItems(section.items),
              rebates: gatherItems(section.rebates),
              discounts: gatherItems(section.discounts),
            }),
          ),
        },
      ],
    }

    setProposalForm(structure)

    if (data.status !== 1) {
      const tabIdx = data.tabs_info?.findIndex(
        item => item.id === data.signed_tab_id,
      )

      if (tabIdx === -1) {
        setActiveTabIdx(0)
      } else setActiveTabIdx(tabIdx)
    }
    if (!data?.setting?.multiple_tabs || data.tabs_info.length === 1) {
      setActiveTabIdx(0)
    }

    setIsProposalLoading(false)
    setIsProposalSigning(false)
    setSignatureModal(false)
  }

  /*  const setDefaultPrice = async () => {
    let newAllItemsWithOptions = []
    let sections = proposalForm.proposal_tabs?.[0].proposal_sections.map(
      (section, sectionIndex) => {
        let itemsOptions = section.items?.map(item => {
          if (item.options?.data?.length > 1) {
            newAllItemsWithOptions.push({
              type: 'items',
              id: item.id,
              idForChoosenOption: item.options.checked_option,
            })
          }
        })

        let discountsOptions = section.discounts?.map(item => {
          if (item.options?.data?.length > 1) {
            newAllItemsWithOptions.push({
              type: 'Discount',
              id: item.id,
              idForChoosenOption: item.options.checked_option,
            })
          }
        })

        let rebatesOptions = section.rebates?.map(item => {
          if (item.options?.data?.length > 1) {
            newAllItemsWithOptions.push({
              type: 'Rebate',
              id: item.id,
              idForChoosenOption: item.options.checked_option,
            })
          }
        })
      },
    )

    if (newAllItemsWithOptions?.length) {
      const newDiffPricesB = {}
      let newTotalPrieces = {}

      Promise.all(
        newAllItemsWithOptions.map(async item => {
          try {
            const params = `type=${item.type}&id=${item.id}&idForChoosenOption=${item.idForChoosenOption}`
            const prices = await getDiffPrices(params)
            newDiffPricesB[item.id] = prices[0]
            newTotalPrieces = prices[1]
          } catch (e) {
            console.log(e, 'error')
          }
        }),
      ).then(() => {
        setDiffPrices(newDiffPricesB)
        setAllTotalPrices(newTotalPrieces)
        // setAllTotalPrices(proposalForm.dataOfPrice)
        setIsDiffPriceLoading(false)
      })
    } else {
      setAllTotalPrices(proposalForm.dataOfPrice)
      setIsDiffPriceLoading(false)
    }
  } */

  const loadProposal = async newState => {
    const activeTabId = proposalForm.tabs_info?.[activeTabIdx]?.id

    if (isLoadDataByLink && isPrintNedeed) {
      const token = props.location.search.split('&')[0]

      return getProposalPreviewDataByLink(token.slice(1), tabIdToPrint || '')
        .then(data => {
          setProposal(data)
          const activeTab = data?.tabs_info?.find(
            tab => tab?.id === Number(tabIdToPrint),
          )

          setActiveTabIdx(activeTab?.position)
        })
        .catch(err => {
          if (
            !!err &&
            (err.status === 404 ||
              (!!err.response && err.response.status === 404))
          ) {
            props.history.push(`/404/project`)
          }
        })
        .finally(() => {
          setIsWorkCompletedChanging(false)
        })
    } else if (isLoadDataByLink) {
      getProposalPreviewDataByLink(
        props.location.search.substr(1),
        activeTabId || '',
      )
        .then(data => {
          const isHasOverdueBalance = data?.proposal_tabs?.[0]?.payment?.payment_balances?.some(
            ({ status }) => status === 2,
          )

          // dispatch(setCompany(data??.company))

          if (isHasOverdueBalance && data?.status !== 1) {
            toggleConfirmModal()
          }

          setProposal(data)
        })
        .catch(err => {
          if (
            !!err &&
            (err.status === 404 ||
              (!!err.response && err.response.status === 404))
          ) {
            props.history.push(`/404/project`)
          }
        })
        .finally(() => {
          setIsWorkCompletedChanging(false)
          setLoading(false)
        })
    } else if (isPreviewFromSettings) {
      const sessionState = sessionStorage?.getItem('proposalState')
      const localState = localStorage?.getItem('proposalState')
      const proposalState = localState ? localState : sessionState

      const { user, company /* proposal_settings */ } = JSON.parse(
        proposalState,
      )
      fetchProposal(search.get('industryId')).then(setting => {
        const data = { ...dummyData, user, company, setting }
        setProposal(data)
        setIsWorkCompletedChanging(false)
      })
    } else {
      let proposalId
      if (props.proposalId) {
        proposalId = props.proposalId
      } else if (idFromParams) {
        proposalId = idFromParams
      } else {
        const sessionState = sessionStorage?.getItem('proposalState')
        const localState = localStorage?.getItem('proposalState')
        const proposalState = localState ? localState : sessionState

        const proposal = JSON.parse?.(proposalState)?.proposal
        const currentData = newState ? newState : proposal
        proposalId = currentData?.id
      }
      if (!proposalId) {
        history.replace('/404/project')
      }

      getProposalPreviewData(proposalId, activeTabId || null, isPrintNedeed)
        .then(data => {
          // dispatch(setCompany(data?.company))
          setProposal(data)
          // setUserIP(data.ip_client ?? '')
          setIsWorkCompletedChanging(false)
          // setAllTotalPrices(data.dataOfPrice)
          const isHasOverdueBalance = data?.proposal_tabs?.[0]?.payment?.payment_balances?.some(
            ({ status }) => status === 2,
          )

          if (isHasOverdueBalance && data?.status !== 1) toggleConfirmModal()

          setLoading(false)
        })
        .catch(() => setIsWorkCompletedChanging(false))
    }
  }

  useEffect(() => {
    loadProposal()
  }, [])

  useEffect(() => {
    // if (renderCount === 1) {
    //   setDefaultPrice()
    // }

    if (isTabChange) {
      // setDiffPrices({})
      // setDefaultPrice()
      setIsTabChange(false)
    }
  }, [proposalForm])

  const onChangeTab = i => {
    if (isPreviewFromSettings) return

    if (activeTabIdx !== i) {
      setIsTabChange(true)
      if (isLoadDataByLink) {
        setIsTabLoading(true)

        return getProposalPreviewDataByLink(
          props.location.search.substr(1),
          proposalForm.tabs_info?.[i]?.id,
        ).then(data => {
          setProposal(data)
          setIsTabLoading(false)
          setActiveTabIdx(i)
          onClickInApp({
            key: EventKeys.SELECT_TAB,
            value: proposalForm.tabs_info?.[i]?.id,
          })
        })
      }
      setIsTabLoading(true)
      getProposalPreviewData(
        proposalForm.id,
        proposalForm.tabs_info?.[i]?.id,
        isPrintNedeed,
      ).then(data => {
        setProposal(data)
        setIsTabLoading(false)
        setActiveTabIdx(i)
      })
    } else {
      setActiveTabIdx(null)
    }
  }

  const showTransactionModal = () => {
    loadProposal()
  }

  const setSignature = async (signature, signatoryName) => {
    // setRenderCount(0)
    setIsProposalSigning(true)
    /* const newStructure = {
      ...proposalForm,
      proposal_tabs: [
        {
          ...proposalForm.proposal_tabs[0],
          proposal_sections: proposalForm.proposal_tabs[0].proposal_sections.map(
            section => ({
              ...section,
              items: section?.items
                ?.flatMap(item =>
                  item?.options
                    ? [
                        ...section.items,
                        ...item.options.data.map(option => ({
                          ...option,
                          option_group_id:
                            item.options.data.find(
                              option => option.checked_option,
                            )?.id || option.option_group_id,
                        })),
                      ]
                    : item,
                )
                ?.filter(item => !item.options),
            }),
          ),
        },
      ],
    } */
    /* if (isLoadDataByLink) {
      const data = {
        ...newStructure,
        signature,
        signatory_name: signatoryName,
        token: props.location.search.substr(1),
        signed_tab_id: newStructure.tabs_info?.[activeTabIdx]?.id,
        // proposal_tabs: proposalTabs,
      }
      setSignatureByLink(data)
        .then(() => loadProposal(data))
        .catch(() => setIsProposalLoading(false))
    } else { */
    await ProjectAPI.setSignature(proposalForm.id, {
      signed_tab_id: proposalForm.tabs_info?.[activeTabIdx]?.id,
      signatory_name: signatoryName,
      signature,
    })

    loadProposal()
    // }
  }

  /* const onChangeAllWorkComplited = e => {
    const { checked } = e.target
    const value = checked ? 1 : 0
    const newProposalForm = {
      ...proposalForm,
      installation_dates: Array.isArray(proposalForm.installation_dates)
        ? proposalForm.installation_dates
        : [],
    }
    newProposalForm.all_work_completed = value
    newProposalForm.installation_dates = newProposalForm.installation_dates.map(
      date => {
        if (date.status !== 3) date.status = value ? 4 : date.status
        return date
      },
    )
    setIsWorkCompletedChanging(true)
    if (!isPreviewFromSettings)
      updateProposal(newProposalForm.id, newProposalForm).then(data =>
        loadProposal(data),
      )
    else setProposalForm(newProposalForm)
  } */

  const onEditProposal = e => {
    e.preventDefault()
    if (!isPreviewFromSettings)
      props.history.push(`/projects/${proposalForm.id}`)
  }

  const toggleProposalEmailModal = () => setEmailModal(!emailModal)

  const onEmailProposal = e => {
    e.preventDefault()
    if (!isPreviewFromSettings) toggleProposalEmailModal()
  }

  const thereAreBlanaces =
    proposalForm.id &&
    proposalForm.proposal_tabs?.[0]?.payment?.payment_balances?.length

  const organizations =
    !!proposalForm.property &&
    Array.isArray(proposalForm.property.organizations)
      ? proposalForm.property.organizations
      : []

  const selectedClients = [
    // ...getSelectedClients(proposalForm),
    ...(proposalForm?.contacts || []),
    ...organizations,
  ]

  const handleSaveFiles = async files => {
    setProposalForm({
      ...proposalForm,
      files_from_customer: files,
    })

    await updatePublicProject(proposalForm.id, { files_from_customer: files })

    if (isMobileApp()) {
      onClickInApp({
        key: EventKeys.SELECT_ITEM,
        value: proposalForm.proposal_tabs?.[0]?.id,
      })
    }
  }

  const handleDeleteCustomerFile = async (id, idx) => {
    if (!id)
      return setProposalForm({
        ...proposalForm,
        files_from_customer: proposalForm.files_from_customer.filter(
          (file, i) => i !== idx,
        ),
      })
    await deleteMedia(id)

    setProposalForm({
      ...proposalForm,
      files_from_customer: proposalForm.files_from_customer.filter(
        file => file.id !== id,
      ),
    })

    updatePublicProject(proposalForm.id, {
      files_from_customer: proposalForm.files_from_customer.filter(
        file => file.id !== id,
      ),
    })
  }

  const heandlePrint = async () => {
    const tabId = proposalForm.tabs_info?.[activeTabIdx]?.id || ''
    const pdfName =
      proposalForm?.code +
      '-' +
      (proposalForm?.tabs_info?.[activeTabIdx]?.title || '') +
      '-' +
      moment().toISOString()
    const proposalId = proposalForm.id
    setGeneratePdf(true)
    const iframe = await document.createElement('iframe')
    const onUpload =
      isLoadDataByLink && !isPreviewProposal
        ? getPdfOnCustomeView
        : getProposalPdf

    try {
      const dataURI = await onUpload(
        proposalId,
        tabId,
        props.location.search.substr(1),
      )

      iframe.src = window.URL.createObjectURL(dataURI)
      iframe.name = pdfName
      iframe.id = 'print_frame'

      iframe.onload = () => {
        const pdfFrame = window.frames[pdfName]
        pdfFrame.focus()
        pdfFrame.print()
      }
      document.body.appendChild(iframe)
    } catch (error) {
      openNotificationWithIcon('error', {
        message:
          'Access to the frame is blocked. Please allow access in your browser settings.',
      })
      window.open(iframe.src, '_blank')

      console.log(error)
    } finally {
      setGeneratePdf(false)
    }
  }

  const downloadPdf = async () => {
    const tabId = proposalForm.tabs_info?.[activeTabIdx]?.id || ''
    const pdfName = `${proposalForm?.code}${
      activeTabIdx ? '-' + proposalForm?.tabs_info?.[activeTabIdx]?.title : ''
    }`
    // proposalForm?.code +
    //   '-' +
    //   proposalForm?.tabs_info?.[activeTabIdx]?.title || ''

    const proposalId = proposalForm.id
    setDownloadPdfLoader(true)
    const onUpload =
      isLoadDataByLink && !isPreviewProposal
        ? getPdfOnCustomeView
        : getProposalPdf
    try {
      if (!proposalId) return
      const dataURI = await onUpload(
        proposalId,
        tabId,
        props.location.search.substr(1),
      )

      await downloadBlob(dataURI, pdfName)
    } catch (error) {
      console.log(error)
    } finally {
      setDownloadPdfLoader(false)
    }
  }

  /**
   * Formatting custom tip field value
   * for thousand separator
   * @param e ReactSyntheticEvent
   */

  const togglePaymentModal = () => setPaymentModal(!paymentModal)

  const handlePayBalance = balance => {
    if (isMobileApp())
      return onClickInApp({ key: EventKeys.PAY_BAlANCE, value: balance })

    setSelectedBalance(balance)
  }

  const toggleOpenDocViewerModal = () => {
    setViewerModal(prev => ({ showDocViewerModal: !prev.showDocViewerModal }))
  }

  const getFileForModalDocViewer = (documents, currentFileIdx) => {
    if (isMobileApp())
      return onClickInApp({
        key: EventKeys.OPEN_FILE,
        value: documents?.[currentFileIdx]?.source || documents[currentFileIdx],
      })

    setViewerModal({ documents, currentFileIdx, showDocViewerModal: true })
  }
  return (
    <>
      {!isProposalLoading /*  && !isDiffPriceLoading */ ? (
        <PageLayout
          title={``}
          className={`proposal-preview ${isPrintNedeed &&
            'proposal-preview__print-view'} ${
            [Companies.SoCal].includes(proposalForm?.user?.company_id)
              ? 'field-work-order__preview'
              : ''
          }${isVerticalNavigation ? ' vertical-navigation' : ''}`}
          style={isPrintNedeed && { width: '1100px', maxWidth: '1100px' }}
          preloader={false}
        >
          <ModalWrapper
            modal={successPaymentModal}
            apply={() => setSuccessPaymentModal(false)}
            okText='Ok'
            dialogClassName='pay_transaction_modal'
            withoutCancelBtn
          >
            <Alert color={'success'} severity={'success'}>
              Your payment was successful
            </Alert>
          </ModalWrapper>
          <div className='header'>
            <div className='preview-header'>
              {isLoadDataByLink && !isPrintNedeed && !isMobile ? (
                <MainButton
                  isFetching={generatePdf}
                  title='Print'
                  onClick={heandlePrint}
                />
              ) : (
                !isPrintNedeed &&
                !isMobile &&
                !!isShowPrice && (
                  <MainButton
                    isFetching={generatePdf}
                    title='Print'
                    onClick={heandlePrint}
                  />
                )
              )}

              {!isLoadDataByLink && (
                <>
                  <MainButton title='Edit Project' onClick={onEditProposal} />
                  {!!isShowPrice && (
                    <MainButton
                      title='Send Project'
                      onClick={onEmailProposal}
                    />
                  )}
                </>
              )}
              {!isMobileApp() && (
                <>
                  {isLoadDataByLink && !isPrintNedeed ? (
                    <MainButton
                      title='Download as PDF'
                      isFetching={downloadPdfLoader}
                      onClick={downloadPdf}
                    />
                  ) : (
                    !isPrintNedeed &&
                    !!isShowPrice && (
                      <MainButton
                        title='Download as PDF'
                        isFetching={downloadPdfLoader}
                        onClick={downloadPdf}
                      />
                    )
                  )}
                </>
              )}
            </div>
            <div className='email-modal'>
              {emailModal && (
                <NotificationModal
                  title='Send Project'
                  showModal={emailModal}
                  setShowModal={toggleProposalEmailModal}
                  proposalForm={proposalForm}
                  templateType={NotificationTemplateType.PROJECT}
                  notificationType={NotificationModalType.PROJECT}
                  clients={selectedClients}
                />
              )}
            </div>
          </div>
          {[Companies.SoCal].includes(proposalForm?.user?.company_id) ? (
            <FieldWorkOrders
              signatureModal={signatureModal}
              setSignatureModal={setSignatureModal}
              proposalForm={proposalForm}
              setSignature={setSignature}
              isProposalSigning={isProposalSigning}
              isPrintNedeed={isPrintNedeed}
              currentTab={proposalForm?.proposal_tabs?.[0]}
              isPublicView={isLoadDataByLink}
            />
          ) : (
            <ComponentToPrint
              ref={componentRef}
              proposalForm={proposalForm}
              activeTabIdx={activeTabIdx}
              onChangeTab={onChangeTab}
              isTabLoading={isTabLoading}
              signatureModal={signatureModal}
              setSignatureModal={
                isMobileApp()
                  ? () =>
                      onClickInApp({
                        key: EventKeys.ACCEPT_PROJECT,
                        tab_id: proposalForm.tabs_info?.[activeTabIdx]?.id,
                      })
                  : setSignatureModal
              }
              setSignature={setSignature}
              showTransactionModal={showTransactionModal}
              isProposalSigning={isProposalSigning}
              // onChangeChoosenOption={handleChangeChoosenOption}
              onSelectItem={handleSelectItem}
              // diffPrices={diffPrices}
              isTransactions={thereAreBlanaces}
              // totalToPay={allTotalPrices?.totalToPay || 0}
              onTotalToPayChange={handleTotalToPayChange}
              isPreviewFromSettings={isPreviewFromSettings}
              // onChangeAllWorkComplited={onChangeAllWorkComplited}
              isWorkCompletedChanging={isWorkCompletedChanging}
              onChangeClientNotes={handleChangeClientNotes}
              onChangeDescription={handleChangeDescription}
              // onChangeTotalPrices={handleAllTotalpricesChange}
              allTotalPrices={proposalForm.financing_info?.find(
                info => info.tab_id === proposalForm.proposal_tabs[0].id,
              )}
              onFileUploadButtonClick={toggleFileUploadModal}
              handleDeleteCustomerFile={handleDeleteCustomerFile}
              isLoadDataByLink={isMobileApp() ? false : isLoadDataByLink}
              // isOptionLoading={isOptionLoading}
              onPayBalance={handlePayBalance}
              loading={loading}
              toggleOpenDocViewerModal={toggleOpenDocViewerModal}
              getFileForModalDocViewer={getFileForModalDocViewer}
              isShowPrice={
                isLoadDataByLink && !isMobileApp() ? true : isShowPrice
              }
              isShowDescription={
                isLoadDataByLink && !isMobileApp() ? true : isShowDescription
              }
              onOptionChange={handleOptionChange}
              onTextFieldBlur={handleTextFieldBlur}
            />
          )}
        </PageLayout>
      ) : (
        <Preloader />
      )}

      <ModalFileUpload
        files={proposalForm.files_from_customer}
        clientView={true}
        handleSave={handleSaveFiles}
      />

      <Modal
        className={'paymenta-modal'}
        width='auto'
        // closable={false}
        title={`Payment for Project - ${proposalForm.code}`}
        footer={null}
        visible={paymentModal}
        centered
        destroyOnClose
        // bodyStyle={{ marginTop: '10px' }}
        onCancel={() => {
          togglePaymentModal()
          setSelectedBalance(null)
        }}
        maskClosable={false}
      >
        <PaymentProcess
          balance={selectedBalance}
          cardInfo={{
            owner_user_id: proposalForm.owner_user_id,
            card_holder: proposalForm?.contacts?.[0]?.name || '',
            billing_address: {
              address: proposalForm?.properties?.[0]?.address || '',
              city: proposalForm?.properties?.[0]?.city || '',
              region: states[proposalForm?.properties?.[0]?.state] || '',
              postal: proposalForm?.properties?.[0]?.postcode || '',
            },
          }}
          settings={{
            disableTips: !proposalForm?.setting?.show_tips,
          }}
          transactionFee={+proposalForm?.setting?.payment_processing_fee}
          onCancel={() => {
            togglePaymentModal()
            setSelectedBalance(null)
          }}
          onPaid={data => {
            loadProposal()
          }}
          payer={isPreviewProposal ? me?.full_name : null}
        />
      </Modal>
      {!isPrintNedeed &&
        ![Companies.WestCost, Companies.SoCal].includes(
          proposalForm?.user?.company_id,
        ) && (
          <ConfirmModal
            title='Balance Overdue'
            onConfirm={toggleConfirmModal}
            okText='Ok'
            content={
              'Your project has some overdue balances. Please pay now to avoid additional fees. '
            }
            type='warning'
            visible={confirmModal}
          ></ConfirmModal>
        )}

      {viewerModal?.showDocViewerModal && (
        <DocViewer
          documents={viewerModal.documents}
          currentDocument={viewerModal.currentFileIdx}
          isModalVisible={viewerModal.showDocViewerModal}
          handleCancel={toggleOpenDocViewerModal}
        />
      )}
    </>
  )
}

export default withRouter(PageProposalPreview)
