import moment from 'moment-timezone'
import {
  Drafts,
  Send,
  Report,
  DeleteOutline,
  Inbox,
  Folder,
  Label,
} from '@material-ui/icons'

export const formatedDate = (
  emailDate: string,
  timezone = moment.tz.guess(),
): string => {
  const date = moment.utc(emailDate).tz(timezone)

  return date.isSameOrAfter(moment().set({ hour: 0, minute: 0, second: 0 }))
    ? date.format('H:mm')
    : date.year() === moment().year()
    ? date.format('MMM D')
    : date.format('MM/DD/YY')
}

export const formatUpdateTime = (dateString: string): string => {
  const updateTime = moment(dateString)
  const now = moment()
  const diffMinutes = now.diff(updateTime, 'minutes')

  if (diffMinutes < 1) {
    return 'updated less than a minute ago'
  } else if (diffMinutes === 1) {
    return 'updated 1 minute ago'
  } else if (diffMinutes < 60) {
    return `updated ${diffMinutes} minute ago`
  } else if (diffMinutes < 120) {
    return 'updated 1 hour ago'
  } else if (diffMinutes < 1440) {
    return `updated ${Math.floor(diffMinutes / 60)} hour ago`
  } else {
    return updateTime.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
  }
}

export const foldersIcon = path => {
  const icon = {
    inbox: <Inbox />,
    drafts: <Drafts />,
    sent: <Send />,
    junk: <Report />,
    trash: <DeleteOutline />,
    folder: <Folder />,
  }
  return icon?.[path] || <Label />
}

export const updateDataOnItemRemoval = (data, removedItemCount) => {
  data.total -= removedItemCount

  if (data.total % data.perPage === 0 && data.page > 1) {
    data.page = data.page - 1
  }

  return data
}
