import { Table, Empty, Tooltip } from 'antd'
import styled from 'styled-components'
import ProposalStatus from 'containers/MainContent/Orcatec/Proposals/components/ProposalsTable/components/ProposalStatus'
import { priceToView } from 'helpers/thousandSeparator'
import { renderNotes } from 'containers/MainContent/Orcatec/Clients/components/Form/components/utils'

const Wrapper = styled.div`
  & .ant-table-thead > tr > th {
    padding: 4px;
  }

  & .ant-table-thead > th,
  .ant-table-thead > td {
    text-align: center;
  }
`

interface IProps {
  tableData: []
}

const linkStyle = {
  color: '#007bff',
  cursor: 'pointer',
}
export const ProposalHistoryTable = ({ tableData = [] }: IProps) => {
  const goToProposal = id => {
    if (id) window.open(`/projects/${id}`, '_blank')
  }

  const columns = [
    {
      title: 'Project ID',
      name: 'Project ID',
      dataIndex: 'code',
      sorter: false,
      defaultWidth: 200,
      render: (code, record) => (
        <span style={linkStyle} onClick={() => goToProposal(record.id)}>
          {code}
        </span>
      ),
    },
    {
      title: 'Status',
      name: 'Status',
      dataIndex: 'status',
      sorter: false,

      defaultWidth: 250,
      render: status => <ProposalStatus status={status} />,
    },
    {
      title: 'Name',
      name: 'Name',
      dataIndex: 'name',
      sorter: false,
      defaultWidth: 200,
      noFilter: true,
      render: name => name || '-',
    },
    {
      title: 'Tech',
      name: 'Tech',
      dataIndex: 'user',
      sorter: false,
      defaultWidth: 200,
      noFilter: true,
      render: user => user?.full_name || '-',
    },

    {
      title: 'Total',
      name: 'Total',
      dataIndex: 'total_to_pay',
      sorter: false,
      defaultWidth: 100,
      noFilter: true,
      render: total => priceToView(total),
    },

    {
      title: 'Notes',
      name: 'Notes',
      dataIndex: 'notes',
      sorter: false,
      defaultWidth: 150,
      noFilter: true,
      render: notes => (
        <Tooltip
          overlayStyle={{ whiteSpace: 'pre-line' }}
          title={
            notes?.length &&
            notes
              ?.map?.(el => ({ text: el?.text || el || '' }))
              ?.map((e, idx) => <p key={idx}>{e?.text}</p>)
          }
        >
          <td>
            {' '}
            {!!notes?.length &&
              renderNotes(notes.map(el => ({ text: el?.text || el || '' })))}
          </td>
        </Tooltip>
      ),
    },
  ]

  return (
    <Wrapper>
      {tableData?.length ? (
        <Table pagination={false} columns={columns} dataSource={tableData} />
      ) : (
        <Empty description='No projects yet' />
      )}
    </Wrapper>
  )
}
