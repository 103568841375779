import React, { useState, useEffect } from 'react'
import { isMobileDevise } from 'helpers/isMobileDevice'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { Tooltip, Popover, Popconfirm, Badge, Tag, Spin } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import states from 'helpers/states'
import AppointmentStatus from '../../../../components/AppointmentStatus/AppointmentStatus'
import { MenuItem, TextField } from '@material-ui/core'
import { getAddressWithUnit } from '../../../../../../../helpers/getAddressWithUnit'
import { getAmericanTime12Format } from '../../../helpers'
import {
  CopyIconThin,
  EditIcon,
  DeleteIcon,
  NotesIcon,
} from '../../../../../Orcatec/components/Icons/CommonIcons'
import {
  Close,
  AssignmentTurnedIn,
  PersonOutline,
  Phone,
  ShareOutlined,
} from '@material-ui/icons'
import {
  MapIcon,
  ProjectsIcon,
  ScheduleIcon,
} from 'layouts/MainLayout/Header/components/Navigation/NavigationIcons'
import { Link, useHistory } from 'react-router-dom'
import { useAppSelector } from 'store/Orcatec/hooks'

import {
  PopoverContentWrapper,
  PopoverContentHeader,
  PopoverContentAddress,
  PopoverContentDate,
  PopoverContentNotes,
  PopoverContentType,
  PopoverContentClient,
  PopoverContentProject,
  PopoverContentStatus,
  PopoverContent,
  PopoverPhoneContent,
} from './EventPopover.style'
import { Job } from 'types/Jobs'
import { EventKind, IAppointment, AppointmentStatusID } from 'types/Appointment'
import {
  selectDispatchSettings,
  eventClientNameUpdated,
} from 'features/Dispatch/dispatchSlice'
import { EventColorType, IEventContact } from 'features/Dispatch/types'
import { getContactByEventId } from 'api/Matrix-log'
import { LoadingOutlined } from '@ant-design/icons'

import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import {
  DispatchPermission,
  ModuleName,
  NotesPermissions,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { meSelector } from 'store/SuperAdmin/selectors'
// import { Companies } from 'types/Company'
import { useDispatch } from 'react-redux'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'

const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />
const popoverStyle = { height: '100%' }
const popoveroverlayStyle = { maxWidth: 500 }
const isMobileDevice = isMobileDevise()
const dispatchLS = 'matrixSettings'

interface IProps {
  handleToggleDnd: () => void
  event: IAppointment | Job
  isSchedule: boolean
  eventType: string
  heandleChangeStatusAppointment: (event: IAppointment | Job) => void
  weekView: boolean
  isWorker: boolean
  onCreateProposal: (event: IAppointment | Job) => void
  isJob: boolean
  onDublicate: (event: IAppointment | Job) => void
  handleClickRfRModal: () => void
  onSetEventData: (event: IAppointment | Job, eventType: string) => void
  onOpenAppointment: () => void
  handleDeleteAppointment: (event: IAppointment | Job) => void
  getPropertyHeandle: (event: IAppointment | Job) => void
  visible: { visible: boolean; id: number }
  onVisibleChange: (visible: boolean) => void
  children: HTMLElement
  tooltipLoading: object
  isMap?: boolean
  handleOpenContactModal: (contact: IEventContact) => void
}

const EventPopover = ({
  handleToggleDnd,
  event,
  isSchedule,
  eventType,
  heandleChangeStatusAppointment,
  weekView,
  onCreateProposal,
  isJob,
  onDublicate,
  onSetEventData,
  onOpenAppointment,
  handleDeleteAppointment,
  visible,
  onVisibleChange,
  children,
  tooltipLoading,
  isMap,
  handleOpenContactModal,
  handleOpenPublicEventModal,
}: IProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { tooltip_mod: isNewlook } = useAppSelector(selectDispatchSettings)
  const dictionary = useAppSelector(selectJobDictionary)
  const dispatchPermissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.DISPATCH),
  )
  const me = useAppSelector(meSelector)
  const sources = useAppSelector(state => state.orcatec.company?.source)
  const { event_color_type } = useAppSelector(selectDispatchSettings)
  const colorByEventType = event_color_type === EventColorType['Event type']
  const types = useAppSelector(state => state.orcatec.company.types)
  // const companyId = useAppSelector(state => state.orcatec.company.id)
  const [openNotes, setOpenNotes] = useState(false)
  const [contact, setContact] = useState<IEventContact | null>(null)
  const [loading, setLoading] = useState(false)

  const isZoomUsed = JSON.parse(localStorage.getItem(dispatchLS))?.zoomLevel > 1

  const onOpenNotes = () => {
    setOpenNotes(prev => !prev)
  }

  useEffect(() => {
    const fetchEventContact = async () => {
      setLoading(true)
      try {
        const res = await getContactByEventId(event?.id)

        if (res?.name !== event?.client_name) {
          dispatch(
            eventClientNameUpdated({
              eventId: event?.id,
              client_name: res?.name,
            }),
          )
        }

        setContact(res)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    if (visible?.id === event?.id && visible?.visible && event?.client_name) {
      fetchEventContact()
    } else {
      setContact(null)
    }
  }, [visible])

  const handleClickOpenContact = (contact: IEventContact) => {
    if (!contact?.id) return
    handleOpenContactModal(contact)
    onVisibleChange(false)
  }

  return (
    <Popover
      visible={visible?.id === event?.id && visible?.visible}
      onVisibleChange={visible => {
        if (!visible) {
          setOpenNotes(false)
        }
        onVisibleChange(visible)
      }}
      overlayClassName='event__popover'
      placement={isZoomUsed ? 'right' : 'right'}
      trigger={isNewlook ? ['click'] : ['hover']}
      style={popoverStyle}
      overlayStyle={popoveroverlayStyle}
      className='popover-children__wrapper'
      content={
        <PopoverContentWrapper>
          <div onClick={e => e.stopPropagation()} className='event-wrapper'>
            {isMobileDevice && (
              <div className='mobile-buttons'>
                <MainButton title='Move' onClick={handleToggleDnd} />
                {!isSchedule && (
                  <MainButton
                    title='Update'
                    onClick={() => history?.push(`/appointment/${event?.id}`)}
                  />
                )}
              </div>
            )}
            {(!isSchedule || isMap) && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <PopoverContentStatus>
                  <p>Status:</p>
                  <TextField
                    size='small'
                    variant='outlined'
                    name='status'
                    label=''
                    onChange={e => {
                      if (
                        [
                          AppointmentStatusID.Active,
                          AppointmentStatusID['En Route'],
                          AppointmentStatusID.Cancelled,
                          AppointmentStatusID.Completed,
                        ].includes(e.target.value)
                      ) {
                        onVisibleChange(false)
                      }
                      heandleChangeStatusAppointment({
                        ...event,
                        action: eventType,
                        status: e.target.value,
                      })
                    }}
                    value={event?.status}
                    select
                    disabled={
                      // !event?.column_template_id ||
                      !dispatchPermissions[
                        DispatchPermission.DISPATCH_CAN_CHANGE_STATUS
                      ]
                    }
                  >
                    <MenuItem value={1}>
                      <AppointmentStatus statusId={1} />
                    </MenuItem>
                    <MenuItem value={5}>
                      <AppointmentStatus statusId={5} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <AppointmentStatus statusId={2} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <AppointmentStatus statusId={3} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <AppointmentStatus statusId={4} />
                    </MenuItem>
                  </TextField>
                </PopoverContentStatus>
                <PopoverContentHeader className='popover-actions'>
                  {!weekView && (
                    <>
                      {isJob && (
                        <Tooltip
                          title={`Share ${
                            isJob ? `${dictionary.singular}` : 'Appointment'
                          } by Link`}
                        >
                          <div
                            onClick={() => {
                              handleOpenPublicEventModal?.(event)
                              onVisibleChange(false)
                            }}
                          >
                            <ShareOutlined />
                          </div>
                        </Tooltip>
                      )}

                      <AccessControl
                        additionalAccess={!isJob}
                        scopes={[ProjectPermissions.PROJECT_CAN_CREATE]}
                        author={me.id}
                      >
                        <Tooltip title='Create Project'>
                          <div
                            onClick={() => {
                              onCreateProposal(event)
                              onVisibleChange(false)
                            }}
                          >
                            {tooltipLoading?.project ? (
                              <Spin size='small' />
                            ) : (
                              <ProjectsIcon />
                            )}
                          </div>
                        </Tooltip>
                      </AccessControl>

                      <AccessControl
                        scopes={[
                          DispatchPermission.DISPATCH_CAN_DUPLICATE_EVENT,
                        ]}
                      >
                        {' '}
                        <Tooltip
                          title={`Duplicate ${
                            isJob ? dictionary.singular : 'Appointment'
                          }`}
                        >
                          <Popconfirm
                            title={`Are you sure you want to duplicate this ${
                              event.kind === EventKind.JOB
                                ? dictionary.singular
                                : 'appointment'
                            } ?`}
                            onConfirm={() => {
                              onDublicate?.(event)
                              onVisibleChange(false, event.id)
                            }}
                            okText='Duplicate'
                            cancelText='Cancel'
                            placement='left'
                          >
                            <div>
                              {tooltipLoading?.dublicate ? (
                                <Spin size='small' />
                              ) : (
                                <CopyIconThin />
                              )}
                            </div>
                          </Popconfirm>
                        </Tooltip>
                      </AccessControl>
                    </>
                  )}
                  <AccessControl
                    scopes={[DispatchPermission.DISPATCH_CAN_EDIT_EVENT]}
                  >
                    <Tooltip
                      title={`Edit ${
                        isJob ? dictionary.singular : 'Appointment'
                      }`}
                    >
                      <div
                        onClick={() => {
                          onOpenAppointment?.()
                          onSetEventData?.(event, eventType)
                          onVisibleChange(false, event.id)
                        }}
                      >
                        <EditIcon />
                      </div>
                    </Tooltip>
                  </AccessControl>

                  <AccessControl
                    scopes={[DispatchPermission.DISPATCH_CAN_DELETE_EVENT]}
                  >
                    <Tooltip title='Delete'>
                      <Popconfirm
                        title={`Are you sure you want to delete this ${
                          event.kind === EventKind.JOB
                            ? dictionary.singular
                            : 'appointment'
                        } ?`}
                        onConfirm={() => {
                          handleDeleteAppointment(event)
                        }}
                        okText='Delete'
                        cancelText='Cancel'
                      >
                        <div>
                          <DeleteIcon />
                        </div>
                      </Popconfirm>
                    </Tooltip>
                  </AccessControl>

                  <Tooltip title='Close'>
                    <div onClick={() => onVisibleChange(false, event.id)}>
                      <Close />
                    </div>
                  </Tooltip>
                </PopoverContentHeader>
              </div>
            )}
            <PopoverContent>
              <PopoverContentDate>
                {event?.date && (
                  <>
                    <Tooltip title='Time'>
                      <div>
                        <ScheduleIcon />
                      </div>
                    </Tooltip>
                    <p>
                      {event.date}{' '}
                      {(event?.time_start || event?.time_end) && (
                        <span>
                          {event?.time_start === event?.time_end
                            ? getAmericanTime12Format(event?.time_start)
                            : `${getAmericanTime12Format(
                                event?.time_start,
                              )} - ${getAmericanTime12Format(event?.time_end)}`}
                        </span>
                      )}
                    </p>
                  </>
                )}
              </PopoverContentDate>
              <PopoverContentAddress>
                <Tooltip title='Location'>
                  <div>
                    <MapIcon />
                  </div>
                </Tooltip>
                <div className='event-address'>
                  {event?.full_address ? (
                    event?.full_address
                  ) : (
                    <>
                      {getAddressWithUnit(event) ? (
                        <p>
                          <span>{getAddressWithUnit(event) + ','}</span>
                          <span>
                            {event?.postcode ? event?.postcode + ',' : ''}{' '}
                          </span>
                        </p>
                      ) : (
                        '-'
                      )}

                      {event?.city || event?.state || states[event?.state] ? (
                        <p>
                          <span>{event?.city ? event?.city + ',' : ''}</span>
                          <span>
                            {states[event?.state]
                              ? states[event?.state]
                              : '' || event?.state
                              ? event?.state
                              : ''}
                          </span>
                        </p>
                      ) : null}
                    </>
                  )}
                </div>
              </PopoverContentAddress>
              <PopoverContentClient>
                <Tooltip title='Client'>
                  <div>
                    <PersonOutline />
                  </div>
                </Tooltip>
                <div
                  style={contact?.id && { color: '#1890ff', cursor: 'pointer' }}
                  onClick={() => handleClickOpenContact(contact)}
                >
                  {event?.client_name || '-'}{' '}
                </div>
              </PopoverContentClient>

              <PopoverContentClient>
                <Tooltip title='Phone'>
                  <div>
                    <Phone />
                  </div>
                </Tooltip>
                <PopoverPhoneContent>
                  {!!contact?.phones?.length ? (
                    contact?.phones?.map((phone, idx) => (
                      <Paragraph
                        style={{ marginBottom: '0' }}
                        key={idx}
                        copyable
                      >
                        {phone}
                      </Paragraph>
                    ))
                  ) : loading ? (
                    <Spin size='small' indicator={antIcon} spinning={loading} />
                  ) : (
                    '--'
                  )}
                </PopoverPhoneContent>
              </PopoverContentClient>
              <AccessControl
                scopes={[ProjectPermissions.PROJECT_CAN_READ]}
                additionalAccess={!!event?.proposal_id}
              >
                <PopoverContentProject>
                  <>
                    <Tooltip title='Project ID'>
                      <div>
                        <AssignmentTurnedIn />
                      </div>
                    </Tooltip>
                    <Tooltip title='Project ID'>
                      <div>
                        <Link to={`/projects/${event?.proposal_id}`}>
                          {event?.proposal_number}
                        </Link>
                      </div>
                    </Tooltip>
                  </>
                </PopoverContentProject>
              </AccessControl>
              <AccessControl scopes={[NotesPermissions.NOTES_CAN_READ]}>
                <PopoverContentNotes>
                  <Tooltip title='Notes'>
                    <span style={{ paddingLeft: '3px' }}>
                      <NotesIcon width={21} height={21} />
                    </span>
                  </Tooltip>
                  {event?.notes?.length ? (
                    // event?.notes?.length === 1 ? (
                    //   <p>
                    //     {event.notes?.[0]?.length > 50
                    //       ? event.notes?.[0].slice(0, 50) + '...'
                    //       : event?.notes?.[0]}
                    //   </p>
                    // ) : (
                    <div onClick={onOpenNotes} className='event-notes'>
                      <p>
                        {!openNotes && (
                          <span>
                            <Badge
                              style={{ top: '6px', right: '-10px' }}
                              count={event.notes?.length - 1}
                            >
                              {event.notes?.[0]?.length > 50
                                ? event.notes?.[0].slice(0, 50) + '...'
                                : event?.notes?.[0]}
                            </Badge>
                          </span>
                        )}
                      </p>
                      <div
                        className={
                          openNotes ? 'notes__visible' : 'notes__hidden'
                        }
                      >
                        {event?.notes?.map?.((el, idx) => (
                          <p style={{ marinBottom: '5px' }} key={idx}>
                            {' '}
                            - {el}.
                          </p>
                        ))}
                      </div>
                    </div>
                  ) : (
                    // )
                    '-'
                  )}
                </PopoverContentNotes>
              </AccessControl>
              <PopoverContentType>
                <div>
                  <Tooltip title='Type'>
                    <p className='type'>Type:</p>
                  </Tooltip>
                </div>

                {event?.appointment_type ? (
                  <Tag
                    color={
                      colorByEventType
                        ? types?.find(t => t?.name === event?.appointment_type)
                            ?.color?.hex
                        : ''
                    }
                  >
                    {event?.appointment_type}
                  </Tag>
                ) : (
                  <span>-</span>
                )}
              </PopoverContentType>
              <PopoverContentType>
                <div>
                  <Tooltip title='Source'>
                    <p className='type'>Source:</p>
                  </Tooltip>
                </div>

                {event?.appointment_source_id ? (
                  <p>
                    {sources?.find(s => s?.id === event?.appointment_source_id)
                      ?.name || '-'}
                  </p>
                ) : (
                  <span>-</span>
                )}
              </PopoverContentType>
            </PopoverContent>
          </div>
        </PopoverContentWrapper>
      }
    >
      {children}
    </Popover>
  )
}

export default EventPopover
