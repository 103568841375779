import React from 'react'
import Preloader from '../components/Preloader'

import './PageLayout.scss'

interface PageLayoutProps {
  className?: string
  fullsize?: string
  fullSize?: string
  customHeader?: string
  title?: string
  preloader?: boolean
  actions?: JSX.Element[]
  children?: React.ReactNode
}

const PageLayout: React.FC<PageLayoutProps> = ({
  className = '',
  fullsize: fullSize,
  customHeader,
  title = '',
  actions,
  preloader,
  children,
}): JSX.Element => {
  // const messageWasShoved = localStorage.getItem('notification')

  // useEffect(() => {
  //   if (browser.getBrowser().name !== 'Chrome' && localStorage.getItem('notification') === null) {
  //     message.warning('For better experience please use Google Chrome Browser', 7.5)
  //     localStorage.setItem('notification', moment().toISOString())
  //   }
  // }, [])

  // useEffect(() => {
  //   if (messageWasShoved) {
  //     const today = moment().toISOString()
  //     const a123s = moment(messageWasShoved).toISOString()
  //     if (moment(today).diff(a123s, 'minutes') > 60) {
  //       message.warning('For better experience please use Google Chrome Browser', 7.5)
  //       localStorage.setItem('notification', moment().toISOString())
  //     }
  //   }
  // }, [])

  return (
    <div
      id='printable'
      className={`custom-page-layout ${className ?? ''} ${
        fullSize ? `w100` : ``
      }`}
    >
      {!customHeader ? (
        (!!title || !!actions) && (
          <div className='custom-page-header page-title-box'>
            <h4 className='page-title'>{title}</h4>
            <div className='page-actions'>{actions}</div>
          </div>
        )
      ) : (
        <div className='custom-page-header'>{customHeader}</div>
      )}
      <div className='page-content card'>
        {preloader ? (
          <Preloader />
        ) : (
          <div className='card-body'>{children}</div>
        )}
      </div>
    </div>
  )
}

export default PageLayout
