import React from 'react'
import { Modal } from 'react-bootstrap'
import PropertyForm from '../Form/PropertyForm_old'
import './PropertyModal.scss'

const PropertyModal = ({ propertyId, modal, onHandleClose, pagination }) => {
  return (
    <Modal show={modal} onHide={onHandleClose} dialogClassName='property-modal' backdrop='static'>
      <Modal.Header closeButton>{propertyId ? 'Edit  property' : 'Create new property'}</Modal.Header>
      <Modal.Body>
        <PropertyForm propertyId={propertyId} close={onHandleClose} pagination={pagination} />
      </Modal.Body>
    </Modal>
  )
}

export default PropertyModal
