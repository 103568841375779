import React from 'react'
import { ITabModule } from 'types/Proposal'
import {
  ModuleOneIcon,
  ModuleThreeIcon,
  ModuleTwoIcon,
  PaymentModuleIcon,
  EquipmentModuleIcon,
} from '../../Icons/ProposalModuleIcons'

import './styles.scss'
import { Tooltip } from 'antd'

const getModuleIcon = (idx: number) => {
  switch (idx) {
    case 0:
      return <PaymentModuleIcon />
    case 1:
      return <EquipmentModuleIcon />
    case 2:
      return <ModuleOneIcon />
    case 3:
      return <ModuleTwoIcon />
    case 4:
      return <ModuleThreeIcon />

    default:
      return <ModuleOneIcon />
  }
}

const getStatusNameByStatusId = (id: number) => {
  switch (id) {
    case 1:
      return ' default'
    case 2:
      return ' pending'
    case 3:
      return ' ordered'
    case 4:
      return ' partially'
    case 5:
      return ' completed'

    default:
      return ''
  }
}
const getStatusNameByStatusIdPayment = (id: number) => {
  switch (id) {
    case 1:
      return ' default'
    case 2:
      return ' due'
    case 3:
      return ' overdue'
    case 4:
      return ' completed'
    default:
      return ''
  }
}

type Props = {
  modules: ITabModule[]
  onModuleClick: (module: ITabModule) => void
}

const ProposalModules = ({ modules, onModuleClick }: Props) => {
  return (
    <div className='proposal-modules'>
      {modules.map((module, idx) => (
        <Tooltip
          key={module.name || idx}
          title={
            module.disabled && module.name !== 'Payment'
              ? `You don't have permission to edit this module`
              : ''
          }
          placement='topLeft'
          mouseLeaveDelay={0}
          mouseEnterDelay={0}
        >
          <div
            key={module.name || idx}
            className={`proposal-modules__module${
              module.name === 'Payment'
                ? getStatusNameByStatusIdPayment(module.status)
                : getStatusNameByStatusId(module.status)
            }${module.disabled ? ' disabled' : ''}`}
            onClick={() => {
              if (module.disabled) return
              onModuleClick(module)
            }}
          >
            {getModuleIcon(idx)}
            <p>{module.name}</p>
          </div>
        </Tooltip>
      ))}
    </div>
  )
}

export default ProposalModules
