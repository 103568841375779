import InputFieldV2 from 'containers/MainContent/Orcatec/components/UI/InputField'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { createPaymentBalanceName, deletePaymentBalanceName } from 'api/Payment'

const optionStyles = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '14px',
  letterSpacing: '0.01em',
  color: ' #191919',
}

interface IBalanceName {
  id: number
  name_balance: string
}
interface Props {
  value: string
  balanceNames: IBalanceName[]
  disabled: boolean
  onChange: (value: string) => void
  setBalanceNames: (value: IBalanceName[]) => void
}

const filter = createFilterOptions()

export const BalanceName: React.FC<Props> = ({
  balanceNames,
  disabled,
  value,
  setBalanceNames,
  onChange,
}) => {
  const handleDeleteType = async (
    e: React.MouseEvent<HTMLElement>,
    id: number,
  ) => {
    e.stopPropagation()

    await deletePaymentBalanceName(id)

    setBalanceNames(balanceNames?.filter(name => name.id !== id))
    onChange('')
  }

  const createNewBalanceName = async (value: string) => {
    onChange(value)

    try {
      const newType = await createPaymentBalanceName({ name_balance: value })

      setBalanceNames(prev => [...prev, newType])
    } catch (error) {
      console.error(error)
    }
  }
  const exitstingOptions = balanceNames?.map(
    balanceName => balanceName?.name_balance,
  )

  const handleChangeBalanceName = async (
    event: React.ChangeEvent<HTMLInputElement>,
    value,
  ) => {
    if (typeof value === 'string') {
      if (!!value && !exitstingOptions?.includes(value))
        return createNewBalanceName(value)
      onChange(value)
    } else if (value && value.inputValue) {
      // Create a new value from the user input
      createNewBalanceName(value.inputValue)
    } else {
      onChange(value?.name_balance)
    }
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target

    handleChangeBalanceName(e, value)
  }

  return (
    <Autocomplete
      value={value}
      onBlur={handleBlurEvent}
      size='small'
      className='balance-name'
      onChange={handleChangeBalanceName}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        const { inputValue } = params
        // Suggest the creation of a new value
        const isExisting = options.some(
          option => inputValue === option.name_balance,
        )
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            name_balance: `Add "${inputValue}"`,
          })
        }

        return filtered
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={balanceNames}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue
        }
        // Regular option
        return option.name_balance
      }}
      renderOption={option => (
        <div style={optionStyles}>
          {option?.name_balance}
          {!!option?.id && (
            <i
              className='mdi mdi-delete p-1'
              style={{ cursor: 'pointer', fontSize: '18px' }}
              onClick={e => handleDeleteType(e, option?.id)}
            />
          )}
        </div>
      )}
      freeSolo
      renderInput={params => (
        <InputFieldV2
          label='Balance Name'
          {...params}
          name='balance_name'
          error={!value && { balance_name: 'This field is required' }}
        />
      )}
      disabled={disabled}
    />
  )
}
