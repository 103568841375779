import axiosOrcatec from './axiosInstance'
import moment from 'moment-timezone'
import { IClientsListResponse } from '../containers/MainContent/Orcatec/Clients/types'
import axios from 'axios'

let cancelToken = null

/**
 * Fetch clients list.
 *
 * @return {*}
 */
export const fetchClientsList = (params: any) => {
  return axiosOrcatec
    .get<Client[]>(`clients?isFeedback=true`, {
      params,
    })
    .then(response => response.data)
}

export const getClientsList = (params: any) => {
  const apiV2Config = {
    baseURL:
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_API_URL.replace('v1.0', 'v2'),
  }
  if (cancelToken) {
    cancelToken.cancel('Same request is executing')
  }
  cancelToken = axios.CancelToken.source()
  return axiosOrcatec
    .get<IClientsListResponse>('clients', {
      ...apiV2Config,
      params,
      cancelToken: cancelToken.token,
    })
    .then(({ data }) => data)
    .catch(error => {
      if (axios.isCancel(error)) {
        console.error('Request canceled:', error?.message)
        const cancellationError = new Error('Request canceled')
        cancellationError.isCancellationError = true
        throw cancellationError
      }

      throw error
    })
}

export const searchClients = (params: any) => {
  return axiosOrcatec
    .get(`/clients/search`, { params })
    .then(response => response.data)
}

export const searchClientsV2 = (params: any) => {
  const config = {
    baseURL: process.env.REACT_APP_API_URL?.replace?.('v1.0', 'v2'),
  }
  return axiosOrcatec
    .get('/clients/search', { params, ...config })
    .then(({ data: { clients } }) => clients)
}

/**
 * Fetch client.
 *
 * @param id
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const getClientById = (id: number) => {
  return axiosOrcatec.get(`clients/${id}`).then(response => response.data)
}

/**
 * Merge clients.
 *
 * @return {Promise<AxiosResponse<any> | never>}
 * @param data
 */
export const mergeClients = (data: {
  entity_id_from: number
  entity_id_to: number
}) => axiosOrcatec.post(`clients/merge`, data).then(({ data }) => data)

/**
 * Create Client.
 *
 * @param data
 * @return {*}
 */
export const createClient = data => {
  return axiosOrcatec
    .post<Client>('clients', data)
    .then(response => response.data)
}

/**
 * Create Client.
 *
 * @param id
 * @param data
 * @return {Promise<T | never>}
 */
export const updateClient = (id: number, data) => {
  return axiosOrcatec.put(`clients/${id}`, data).then(response => response.data)
}

/**
 * Delete Client.
 *
 * @param id
 * @return {*}
 */
export const deleteClient = (id: number) => {
  return axiosOrcatec.delete(`clients/${id}`).then(response => response.data)
}

/**
 * Create Relation Property.
 *
 * @param id
 * @return {*}
 */
export const createRelationProperty = data => {
  return axiosOrcatec
    .post(`/proposals/attach-entities`, data)
    .then(response => response.data)
}
export const createClientAssociation = data => {
  return axiosOrcatec
    .post(`/clients-associations`, data)
    .then(response => response.data)
}
export const dellAttachedProperty = (id: number) => {
  return axiosOrcatec
    .delete(`/clients-properties/${id}`)
    .then(response => response.data)
}
export const dellClientAssociation = (id: number) => {
  return axiosOrcatec
    .delete(`/clients-associations/${id}`)
    .then(response => response.data)
}
export const dellClientPhone = (id: number) => {
  return axiosOrcatec
    .delete(`/clients-phones/${id}`)
    .then(response => response.data)
}
export const dellClientEmail = (id: number) => {
  return axiosOrcatec
    .delete(`/clients-emails/${id}`)
    .then(response => response.data)
}
export const editClientEmail = data => {
  const { id, ...rest } = data
  return axiosOrcatec
    .put(`/clients-emails/${id}`, rest)
    .then(response => response.data)
}
export const editClientPhone = data => {
  const { id, ...rest } = data
  return axiosOrcatec
    .put(`/clients-phones/${id}`, rest)
    .then(response => response.data)
}

export const searchClientByName = searchValue => {
  return axiosOrcatec
    .get(`clients?search=${searchValue}`)
    .then(response => response.data)
}

export const postClientPhone = data => {
  return axiosOrcatec
    .post(`/clients-phones`, data)
    .then(response => response.data)
}
export const postClientEmails = data => {
  return axiosOrcatec
    .post(`/clients-emails`, { ...data, entity_type: 2 })
    .then(response => response.data)
}

/**
 * @param data //{"organization_id": 1,"contact_role": "accountant"}
 * @param clientId
 **/
export const attachOrganizationToClient = (clientId, data) => {
  return axiosOrcatec
    .put(`/clients/attach_organization/${clientId}`, data)
    .then(response => response.data)
}

export const detachOrganizations = (clientId, data) => {
  return axiosOrcatec
    .put(`/clients/detach_organization/${clientId}`, data)
    .then(response => response.data)
}

export const updateAssociation = (assId, note) => {
  const data = {
    id: assId,
    note,
  }
  return axiosOrcatec
    .put(`/clients/update_association_note`, data)
    .then(response => response.data)
}

export const exportContactsTable = params => {
  const config = {
    baseURL: process.env.REACT_APP_API_URL?.replace?.('v1.0', 'v2'),
  }
  return axiosOrcatec
    .get(`/clients/export`, {
      responseType: 'blob',
      params,
      ...config,
    })
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Contacts_${moment().format('DD-MM')}.xls`)
      document.body.appendChild(link)
      link.click()
    })
}
