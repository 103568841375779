import {
  EyeOutlined,
  InboxOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { DeleteOutlined } from '@material-ui/icons'
import { Alert, Popconfirm, Popover } from 'antd'
import { InputFieldV2 } from 'containers/MainContent/Orcatec/components/UI/InputField'
import { Modal } from 'containers/MainContent/Orcatec/components/UI/Modal'
import { ItemAttachment } from 'features/Items/types'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { forwardRef, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import FileUploadService from 'services/FileUploadService'
import { Media } from 'types/Media'
import validator from 'validator'
import { ViewFile } from 'helpers/viewFile/viewFile'

import {
  Drop,
  DropzoneText,
  FileActions,
  FileItem,
  FilesList,
  Info,
  UploadByLink,
  UploadSection,
} from './ModalFilesUpload.styles'

interface Props {
  data?: ItemAttachment[]
  filesLimit?: number
  uploadByLink?: boolean
  onCancel: () => void
  onSave: (files: (Media | string)[]) => void
  publicUsage?: boolean
  eventHash?: string
  restrictDelete?: boolean
  onDelete?: (index) => Promise<any>
}

export const ModalFilesUpload = forwardRef<HTMLDivElement, Props>(
  (
    {
      data,
      filesLimit = 10,
      uploadByLink = true,
      restrictDelete,
      onCancel,
      onSave,
      publicUsage,
      eventHash,
      onDelete,
    },
    ref,
  ) => {
    const [files, setFiles] = useState<ItemAttachment[]>([])
    // const [filesToUpload, setFilesToUpload] = useState<File[]>([])
    const [url, setUrl] = useState('')
    const [isValidUrl, setIsValidUrls] = useState(true)

    useEffect(() => {
      document.body.style.overflow = 'hidden'
      document.body.style.width = 'calc(100% - 8px)'

      return () => {
        document.body.style.overflow = 'unset'
        document.body.style.width = 'unset'
      }
    }, [])

    useEffect(() => {
      if (data?.length) setFiles(data)
    }, [])

    const handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      const isValidURL = validator.isURL(value, { require_protocol: true })

      setIsValidUrls(value ? isValidURL : true)

      setUrl(value)
    }

    const handleUploadFiles = (newFiles: File[]) => {
      const availibleSlots =
        filesLimit -
        (files.filter(file => typeof file === 'string')?.length || 0)

      const filesToUpload = newFiles.slice(0, availibleSlots)
      // setFilesToUpload(prev => [...prev, ...filesToUpload])
      setFiles(prev => [...prev, ...filesToUpload])
    }

    const uploadUrl = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!isValidUrl || !url) return

      if (event.key === 'Enter') {
        setFiles(prev => [...prev, { linkToFile: url }])
        setUrl('')
      }
    }

    const handleRemoveFile = (fileIndex: number) => {
      if (restrictDelete) {
        return openNotificationWithIcon('warning', {
          message: 'You have no permissions to perform this action',
        })
      }

      setFiles(files.filter((file, index) => index !== fileIndex))
      onDelete?.(fileIndex)
    }

    const handleSave = async () => {
      const uploadedFiles = []
      const uploadingFiles = files.filter(file => file instanceof File)

      for (let idx = 0; idx < uploadingFiles.length; idx++) {
        const file = uploadingFiles[idx]
        let uploadPromise

        if (publicUsage) {
          uploadPromise = uploadWithPublicUsage(file, eventHash, idx)
        } else {
          uploadPromise = uploadWithoutPublicUsage(file, idx)
        }

        try {
          const [uploadedFile] = await uploadPromise

          uploadedFiles.push(uploadedFile)
        } catch (error) {
          handleUploadError(error)
        }
      }

      const data = generateData(uploadedFiles)
      onSave?.(data)
    }

    const uploadWithPublicUsage = (file, eventHash, idx) => {
      return new Promise((resolve, reject) => {
        FileUploadService.uploadPublic(file, eventHash, event => {
          console.warn(idx, Math.round((100 * event.loaded) / event.total))
        })
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    }

    const uploadWithoutPublicUsage = (file, idx) => {
      return new Promise((resolve, reject) => {
        FileUploadService.upload(file, event => {
          console.warn(idx, Math.round((100 * event.loaded) / event.total))
        })
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    }

    const handleUploadError = error => {
      console.error(error)
      openNotificationWithIcon('error', {
        message: error.response?.data?.message || 'Invalid data',
      })
    }

    const generateData = uploadedFiles => {
      let data = []

      if (publicUsage) {
        data = [
          ...files.filter(file => file?.id || typeof file === 'string'),
          ...uploadedFiles,
        ]
        if (url) data.push(url)
      } else {
        data = [
          ...files.filter(
            file => file?.id || typeof file === 'string' || file?.linkToFile,
          ),
          ...uploadedFiles,
        ]
        if (url) data.push({ id: Date.now(), linkToFile: url })
      }

      return data

      // onSave?.(data)
    }

    const handleOpenFilePreview = (file: File | string) => {
      window.open(
        file instanceof File ? URL.createObjectURL(file) : file,
        '_blank',
      )
    }

    return (
      <Modal
        title='Upload files'
        visible
        onCancel={onCancel}
        onOk={handleSave}
        width={600}
        getContainer={ref?.current}
      >
        <Info>
          {`Maximum ${filesLimit} files can be uploaded`}
          <Popover content={infoContent}>
            <InfoCircleOutlined
              style={{ fontSize: 15, marginLeft: 10, cursor: 'pointer' }}
            />
          </Popover>
        </Info>

        {files?.length < filesLimit && (
          <UploadSection>
            <Dropzone onDrop={handleUploadFiles}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <Drop {...getRootProps()}>
                  <input {...getInputProps()} />
                  <InboxOutlined
                    style={{ fontSize: 48, color: '#858aff', marginBottom: 10 }}
                  />

                  <DropzoneText>
                    {isDragActive
                      ? 'Drop the files here ...'
                      : 'Click or drag file(s) to this area to upload'}
                  </DropzoneText>
                </Drop>
              )}
            </Dropzone>

            {uploadByLink && (
              <UploadByLink>
                <p>or add external link to file</p>

                <InputFieldV2
                  value={url}
                  label='External link'
                  placeholder='Enter URL here...'
                  onChange={handleChangeUrl}
                  error={!isValidUrl}
                  helperText={
                    !isValidUrl
                      ? 'Enter valid URL'
                      : url
                      ? 'Press Enter to upload'
                      : ''
                  }
                  onKeyUp={uploadUrl}
                />
              </UploadByLink>
            )}
          </UploadSection>
        )}

        <hr />

        <FilesList>
          {files?.map((file, idx) => (
            <FileItem key={idx}>
              <ViewFile
                file={file?.source || file?.linkToFile || file}
                fileStyle={{
                  width: 40,
                  height: 40,
                  border: '1px solid #d9d9d9',
                  borderRadius: 8,
                  padding: 5,
                }}
                viewOnly={true}
                tooltipPlacement={'top'}
                isLogo={undefined}
                fileName={file?.display_name}
              />
              <InputFieldV2
                value={
                  file instanceof File
                    ? file?.name
                    : file?.display_name || file?.linkToFile || file
                }
                disabled
              />
              <FileActions>
                <EyeOutlined
                  style={{
                    fontSize: 20,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleOpenFilePreview(file)}
                />
                <Popconfirm
                  title='Are you sure you want to delete this file?'
                  onConfirm={() => handleRemoveFile(idx)}
                  placement='left'
                  getPopupContainer={trigger => trigger.parentElement}
                >
                  <DeleteOutlined
                    style={{ fontSize: 20, cursor: 'pointer' }}
                    className='delete-icon'
                    onClick={e => e.stopPropagation()}
                  />
                </Popconfirm>
              </FileActions>
            </FileItem>
          ))}
        </FilesList>
      </Modal>
    )
  },
)

ModalFilesUpload.displayName = 'ModalFilesUpload'

const content = (
  <div>
    <p>.jpeg,.png,.jpg,.webp,.pdf,.xls,.ppt,.docx,.txt,.mp3,.mp4,.zip,.rar </p>
    <p>
      Maximum file upload size:<b> 100 MB</b>
    </p>
  </div>
)
const infoContent = (
  <Alert
    message='Accepted file types:'
    description={content}
    type='info'
    showIcon
  />
)
