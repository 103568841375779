import { SettingsPermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'

export const settings = {
  settings_can_read: {
    name: 'View Settings',
    control_type: UniteType.THIRD_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.SETTINGS_CAN_READ,
    related_key: [IKey.SETTINGS_CAN_READ_CONSUMER_PAYMENT],
    disabled: true,
    is_module: true,
    children: [
      {
        name: 'View Consumer Payment Setting',

        description: '',
        with_access_scope: false,
        with_access_checkbox: true,
        accordion: false,
        permission_key: IKey.SETTINGS_CAN_READ_CONSUMER_PAYMENT,
        parent_permission_key: IKey.SETTINGS_CAN_READ,
        related_key: [],
      },
    ],
  },
}
