import axios from 'axios'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import SelectField from 'containers/MainContent/Orcatec/components/Select'
import React, { useEffect, useState } from 'react'
import { Form, Row } from './Card.styles'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import HelpIcon from '@material-ui/icons/Help'
import { Popover } from 'antd'
import checkImage from './checkExamples/1.jpeg'
import checkImage2 from './checkExamples/2.gif'
import { IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { schemaACH } from './validation'
import styled from 'styled-components'

type CardInfo = {
  accttype: string
  card_holder: string
  check_number: string
  billing_address: {
    address: string
    city: string
    region: string
    postal: string
  }
  token: string
}

interface Props {
  cardData: CardInfo
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onGotToken: (token: { message: string; expiry: string }) => void
}

const ACHForm = ({ cardData, onChange, onGotToken }: Props) => {
  const {
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schemaACH),
    defaultValues: {
      routingNumber: '',
      repeatRoutingNumber: '',
      accountNumber: '',
      repeatAccountNumber: '',
    },
  })

  const [showValues, setShowValues] = useState({
    routingNumber: false,
    repeatRoutingNumber: false,
    accountNumber: false,
    repeatAccountNumber: false,
  })

  const obj = watch()

  useEffect(() => {
    if (isValid) {
      axios
        .post(`${process.env.REACT_APP_CARDCONNECT_URL}cardsecure/api/v1/ccn/tokenize`, {
          account: obj.routingNumber + '/' + obj.accountNumber,
        })
        .then(({ data }) => {
          onGotToken?.({ message: data.token, expiry: '' })
        })
    }
  }, [isValid])

  const onChangeCheckNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    onChange({
      target: {
        name,
        value: value.replaceAll(/[^0-9]/g, ''),
      },
    })
  }

  const toggleInputType = (name: string, forceState?: boolean) => {
    setShowValues(prev => ({
      ...prev,
      [name]: forceState ?? !prev[name],
    }))
  }

  const disableAction = (e: React.ClipboardEvent<HTMLInputElement>) => e.preventDefault()

  return (
    <Wrapper>
      <Form>
        <SelectField
          label='Account Type'
          name='accttype'
          value={cardData.accttype}
          options={[
            { id: 'ECHK', name: 'Checking account' },
            { id: 'ESAV', name: 'Savings account' },
          ]}
          onChange={onChange}
          style={{ margin: '10px 0' }}
        />

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Popover title='Check examples' trigger='click' content={content} placement='right'>
            <HelpIcon style={{ color: '#4c53ef', fontSize: 20 }} />
          </Popover>
        </div>

        <Row style={{ margin: '10px 0' }}>
          <Controller
            name='routingNumber'
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                label='Routing No. *'
                type={showValues.routingNumber ? 'text' : 'password'}
                inputProps={{ maxLength: 9, style: { fontSize: 14 } }}
                style={{
                  flexBasis: 250,
                }}
                onCut={disableAction}
                onCopy={disableAction}
                onPaste={disableAction}
                error={{ routingNumber: errors.routingNumber?.message }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        style={{ padding: 0 }}
                        aria-label='toggle password visibility'
                        onMouseUp={() => toggleInputType('routingNumber')}
                        onMouseDown={() => toggleInputType('routingNumber')}
                        onMouseLeave={() => toggleInputType('routingNumber', false)}
                      >
                        {showValues.routingNumber ? (
                          <VisibilityOff style={{ fontSize: 18 }} />
                        ) : (
                          <Visibility style={{ fontSize: 18 }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Controller
            name='repeatRoutingNumber'
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                label='Confirm Routing No. *'
                type={showValues.repeatRoutingNumber ? 'text' : 'password'}
                inputProps={{ maxLength: 9, style: { fontSize: 14 } }}
                style={{
                  flexBasis: 250,
                }}
                onCut={disableAction}
                onCopy={disableAction}
                onPaste={disableAction}
                error={{ repeatRoutingNumber: errors.repeatRoutingNumber?.message }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        style={{ padding: 0 }}
                        aria-label='toggle password visibility'
                        onMouseUp={() => toggleInputType('repeatRoutingNumber')}
                        onMouseDown={() => toggleInputType('repeatRoutingNumber')}
                        onMouseLeave={() => toggleInputType('repeatRoutingNumber', false)}
                      >
                        {showValues.repeatRoutingNumber ? (
                          <VisibilityOff style={{ fontSize: 18 }} />
                        ) : (
                          <Visibility style={{ fontSize: 18 }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Row>

        <Row style={{ margin: '10px 0' }}>
          <Controller
            name='accountNumber'
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                label='Account No. *'
                type={showValues.accountNumber ? 'text' : 'password'}
                inputProps={{ maxLength: 17, style: { fontSize: 14 } }}
                onCut={e => e.preventDefault()}
                onCopy={e => e.preventDefault()}
                onPaste={e => e.preventDefault()}
                error={{ accountNumber: errors.accountNumber?.message }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        style={{ padding: 0 }}
                        aria-label='toggle password visibility'
                        onMouseUp={() => toggleInputType('accountNumber')}
                        onMouseDown={() => toggleInputType('accountNumber')}
                        onMouseLeave={() => toggleInputType('accountNumber', false)}
                      >
                        {showValues.accountNumber ? (
                          <VisibilityOff style={{ fontSize: 18 }} />
                        ) : (
                          <Visibility style={{ fontSize: 18 }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name='repeatAccountNumber'
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                label='Confirm Account No. *'
                type={showValues.repeatAccountNumber ? 'text' : 'password'}
                inputProps={{ maxLength: 17, style: { fontSize: 14 } }}
                onCut={e => e.preventDefault()}
                onCopy={e => e.preventDefault()}
                onPaste={e => e.preventDefault()}
                error={{ repeatAccountNumber: errors.repeatAccountNumber?.message }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        style={{ padding: 0 }}
                        aria-label='toggle password visibility'
                        onMouseUp={() => toggleInputType('repeatAccountNumber')}
                        onMouseDown={() => toggleInputType('repeatAccountNumber')}
                        onMouseLeave={() => toggleInputType('repeatAccountNumber', false)}
                      >
                        {showValues.repeatAccountNumber ? (
                          <VisibilityOff style={{ fontSize: 18 }} />
                        ) : (
                          <Visibility style={{ fontSize: 18 }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Row>

        <Row style={{ margin: '10px 0' }}>
          <InputField
            name='check_number'
            label='Check No. (optional)'
            value={cardData.check_number}
            onChange={onChangeCheckNumber}
            inputProps={{ maxLength: 10, style: { fontSize: 14 } }}
            style={{ maxWidth: 192.5 }}
          />
        </Row>
      </Form>
    </Wrapper>
  )
}

export default ACHForm

const content = (
  <div style={{ maxWidth: 400 }}>
    <img style={{ width: '100%' }} src={checkImage2} />
    <img style={{ width: '100%' }} src={checkImage} />
  </div>
)

const Wrapper = styled.div``
