import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  max-width: 100% !important;
  & .ant-collapse-content-box {
    padding-top: 0;
  }
  & .container-preferred_worker,
  .wrapper {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  & .ant-badge-count {
    top: -6px;
    right: -12px;
  }
`
const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0;
  & .active {
    font-weight: bold;
  }
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
`
const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 0;
`
const PermRow = styled.p`
  color: red;
  font-weight: 500;
  text-align: center;
`

export { Container, Wrapper, InputWrapper, Item, PermRow }
