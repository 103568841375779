import { useEffect, useState } from 'react'
import { getActiveUsers } from 'api/User'
import { ITechnician } from 'types/Appointment'

export const useActiveUsers = () => {
  const [users, setUsers] = useState<ITechnician[]>([])

  useEffect(() => {
    const getUsers = async () => {
      const data = await getActiveUsers()
      setUsers(data)
    }

    getUsers()
  }, [])

  return { users }
}
