import React, { useState } from 'react'
import { Modal, Table, Spin } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { TableData } from 'features/Forms/types'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import DefaultColumnFilter from 'containers/MainContent/Orcatec/components/Table/components/TableFilters/DefaultColumnFilter'
import styled from 'styled-components'

interface IProps {
  isModalOpen: boolean
  handleCancel: () => void
  loading: boolean
  data: TableData[]
  rowSelection: object
}

export const AddTemplateModal = ({
  isModalOpen,
  handleCancel,
  loading,
  data,
  rowSelection,
}: IProps) => {
  const [pageSize, setPageSize] = useState(10)
  const columns: ColumnProps<TableData>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      filterDropdown: (props: FilterDropdownProps) => (
        <DefaultColumnFilter title={'Title'} {...props} />
      ),
      onFilter: (value: string, record) =>
        record.title?.toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
    },
    // {
    //   title: 'Created at',
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
    //   render: value =>
    //     moment(value, 'MM/DD/YYYY HH:mm:ss')
    //       .tz(timezone)
    //       .format('MM/DD/YYYY hh:mm a'),
    // },
    {
      title: 'Created by',
      dataIndex: 'created_by',
      key: 'created_by',
      render: value => value || '--',
    },
  ]

  return (
    <Modal
      footer={
        <ButtonRow>
          <MainButton type='cancel' onClick={handleCancel} title='Close' />
          <MainButton onClick={handleCancel} title='Save' />
        </ButtonRow>
      }
      width={800}
      title='Templates'
      visible={isModalOpen}
      onCancel={handleCancel}
    >
      <Spin spinning={loading}>
        <Table
          columns={columns}
          rowSelection={{
            ...rowSelection,
          }}
          dataSource={data}
          size='small'
          pagination={{
            total: data?.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            pageSize: pageSize,
            size: 'small',
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100', '250', '500'],
            onShowSizeChange: (current, size) => setPageSize(size),
          }}
        />
      </Spin>
    </Modal>
  )
}

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
`
