import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { Popconfirm, Radio } from 'antd'
import {
  getFreeTechs,
  getSettingsFreeTechs,
  UpdateColumnBody,
} from 'api/Matrix-log'
import SettingsRow from 'components/UI/SettingsRow'
import Avatar from 'containers/MainContent/Orcatec/components/Avatar'
import InputFieldTransformable from 'containers/MainContent/Orcatec/components/UI/InputFieldTransformable'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { Column } from 'features/Dispatch/types'
import { ColorBlock } from 'features/Membership/MembershipForm/Membership'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import useOnClickOutside from 'hooks/useOnClickOutside'
import moment from 'moment-timezone'
import React, { useEffect, useRef, useState } from 'react'
import { TwitterPicker } from 'react-color'
import { useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import { ITechnician } from 'types/Appointment'
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { TimeOff } from 'api/TimeOff'
import { getMediaURL } from 'helpers/getMediaURL'

interface Props {
  data: Column
  onClose: () => void
  onSave: (data: UpdateColumnBody) => Promise<void>
  onDeleteColumn?: () => void
  globalSettings: boolean
}
const initialColumn: Column = {
  title: '',
  main_tech_id: null,
  main_tech_name: '',
}

interface AssigningTech extends ITechnician {
  unassigned: boolean
  timeOff?: TimeOff[]
}

export const ColumnSettings = ({
  data,
  onClose,
  onSave,
  onDeleteColumn,
  globalSettings,
}: Props) => {
  const [column, setColumn] = useState<Column>(initialColumn)
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [todayOnly, setTodayOnly] = useState(1)
  const [technicians, setTechnicians] = useState<AssigningTech[]>([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [edited, setEdited] = useState(false)
  const [error, setError] = useState({
    todayOnly: false,
  })

  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => setShowColorPicker(false))

  const currentDate = useAppSelector(
    state => state.orcatec.dispatchSlice.currentDate,
  )

  useEffect(() => {
    const getFreeTechnicians = async () => {
      setLoading(true)
      const techs = globalSettings
        ? await getSettingsFreeTechs()
        : await getFreeTechs({ date: currentDate })
      setTechnicians(prev => [
        ...prev,
        ...techs.map(tech => ({
          ...tech,
          user_id: tech.id,
          unassigned: true,
          timeOff: tech.timeOffs,
        })),
      ])
      setLoading(false)
    }

    getFreeTechnicians()
  }, [])

  useEffect(() => {
    if (!technicians.filter(tech => !tech.unassigned)?.length)
      setColumn(prev => ({ ...prev, main_tech_id: null }))
  }, [technicians])

  useEffect(() => {
    if (data.technicians.length) {
      setTechnicians(prev => [
        ...prev,
        ...data.technicians.map(tech => ({
          ...tech,
          id: tech.user_id,
          unassigned: false,
        })),
      ])
    }
  }, [data.technicians.length])

  useEffect(() => {
    if (data) {
      setColumn(data)
    }
  }, [data])

  useEffect(() => {
    if (
      !column.main_tech_id &&
      technicians.filter(tech => !tech.unassigned).length
    ) {
      setColumn(prev => ({
        ...prev,
        main_tech_id: technicians.filter(tech => !tech.unassigned)[0].id,
      }))
    }
  }, [column.main_tech_id, technicians])

  const toogleColorPicker = () => {
    setShowColorPicker(!showColorPicker)
  }

  const handleChangeSettings = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target

    setColumn(prev => ({
      ...prev,
      [name]: value,
    }))

    setEdited(true)
  }

  const handleUpdateColumn = async () => {
    if (error.todayOnly)
      return openNotificationWithIcon('error', {
        message: 'Cannot be applied to past dates',
      })

    setUpdating(true)
    const response = await onSave({
      ...column,
      technicians: technicians.filter(tech => !tech.unassigned),
      today_only: !!todayOnly,
      date: currentDate,
    })

    setUpdating(false)
    setEdited(false)
    return response
  }

  const handleAssignTech = (id: number) => {
    if (id === column.main_tech_id)
      setColumn(prev => ({ ...prev, main_tech_id: null }))

    setTechnicians(
      technicians.map(tech =>
        tech.id === id ? { ...tech, unassigned: !tech.unassigned } : tech,
      ),
    )
    setEdited(true)
  }

  const handleChangeTodayOnly = (
    e: React.ChangeEvent<{
      name?: string | undefined
      value: number
    }>,
  ) => {
    const { value } = e.target
    setError(prev => ({ ...prev, todayOnly: false }))

    if (
      !value &&
      moment(currentDate)
        .endOf('D')
        .isBefore(moment())
    ) {
      setError(prev => ({ ...prev, todayOnly: true }))
    }

    setTodayOnly(value)
  }

  const mainTech = technicians.find(
    tech => tech.id === column.main_tech_id && !tech.unassigned,
  )

  return (
    <Modal
      title='Column Settings'
      visible={true}
      onCancel={onClose}
      onOk={handleUpdateColumn}
      footer={[
        <Footer key='footer'>
          {!!onDeleteColumn && (
            <Popconfirm title={PopconfirmTitle} onConfirm={onDeleteColumn}>
              <MainButton key='delete' title='Delete Column' type='delete' />
            </Popconfirm>
          )}
          <MainButton
            key='cancel'
            title='Cancel'
            type='cancel'
            onClick={onClose}
            style={{ marginLeft: 'auto' }}
          />
          <MainButton
            key='save'
            title='Save'
            onClick={handleUpdateColumn}
            isFetching={updating}
          />
        </Footer>,
      ]}
      width={800}
      showConfirmationOnExit={edited}
    >
      <Wrapper>
        {!globalSettings && (
          <SettingsRow width={150} withInfoIcon title='Update column'>
            <FormControl size='small'>
              <Select
                value={todayOnly}
                onChange={handleChangeTodayOnly}
                variant='outlined'
                error={error.todayOnly}
              >
                <MenuItem value={1}>Only for {currentDate}</MenuItem>
                <MenuItem value={0}>For today and future dates</MenuItem>
              </Select>
              {error.todayOnly && (
                <FormHelperText style={{ color: 'red' }}>
                  Cannot be applied to past dates
                </FormHelperText>
              )}
            </FormControl>
          </SettingsRow>
        )}

        <SettingsRow width={150} withInfoIcon title='Column Name'>
          <InputFieldTransformable
            name='title'
            value={column?.title}
            onChange={handleChangeSettings}
          />
        </SettingsRow>

        <SettingsRow width={150} withInfoIcon title='Column Color'>
          <ColorBlock
            backgroundColor={column?.background_color || '#626ed4'}
            onClick={toogleColorPicker}
          />
          {showColorPicker && (
            <div ref={ref} style={{ position: 'absolute', zIndex: 2 }}>
              <TwitterPicker
                color={column?.background_color || '#626ed4'}
                onChangeComplete={color => {
                  setEdited(true)
                  setColumn(prev => ({ ...prev, background_color: color.hex }))
                }}
              />
            </div>
          )}
        </SettingsRow>

        <SettingsRow width={150} withInfoIcon title='Users'>
          <div>
            <InputField
              label='Search users'
              value={search}
              onChange={e => setSearch(e.target.value)}
            />

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 20,
              }}
            >
              <div>
                <p>Assigned users:</p>
                <UsersList>
                  {technicians.filter(tech => !tech.unassigned).length ? (
                    <Radio.Group
                      value={column.main_tech_id}
                      onChange={e => {
                        setColumn(prev => ({
                          ...prev,
                          main_tech_id: e.target.value,
                        }))
                      }}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      {!!mainTech && (
                        <Row
                          onClick={() => handleAssignTech(mainTech.id)}
                          key={mainTech.id}
                        >
                          <Radio
                            value={mainTech.id}
                            style={{ alignItems: 'center' }}
                            key={mainTech.id}
                            onClick={e => e.stopPropagation()}
                          >
                            <div
                              style={{
                                display: 'flex',
                                gap: 5,
                                alignItems: 'center',
                              }}
                            >
                              <Avatar
                                pictureURL={getMediaURL(
                                  mainTech?.profile_picture,
                                )}
                                color={mainTech?.background_color || '#626ed4'}
                                size={28}
                              />
                              <span>{mainTech.name}</span>
                              <MainTech>(main)</MainTech>
                              {/* {!!mainTech?.timeOff?.length ? <span style={{ color: 'grey' }}>- Time off</span> : ''} */}
                              <CloseOutlined
                                style={{ fontSize: 14, color: 'red' }}
                              />
                            </div>
                          </Radio>
                        </Row>
                      )}

                      {technicians
                        .filter(
                          tech =>
                            !tech.unassigned && tech.id !== column.main_tech_id,
                        )
                        .filter(tech =>
                          tech.name
                            .toLowerCase()
                            .includes(search.toLowerCase()),
                        )
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(tech => (
                          <Row
                            onClick={() => handleAssignTech(tech.id)}
                            key={tech.id}
                          >
                            <Radio
                              value={tech.id}
                              style={{ alignItems: 'center' }}
                              key={tech.id}
                              onClick={e => e.stopPropagation()}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 5,
                                  alignItems: 'center',
                                }}
                              >
                                <Avatar
                                  pictureURL={getMediaURL(
                                    tech?.profile_picture,
                                  )}
                                  color={tech?.background_color || '#626ed4'}
                                  size={28}
                                />
                                <span>{tech.name}</span>
                                {column.main_tech_id === tech.id && (
                                  <MainTech>(main)</MainTech>
                                )}
                                {tech?.timeOff?.length ? (
                                  <span style={{ color: 'grey' }}>
                                    - Time off
                                  </span>
                                ) : (
                                  ''
                                )}
                                <CloseOutlined
                                  style={{ fontSize: 14, color: 'red' }}
                                />
                              </div>
                            </Radio>
                          </Row>
                        ))}
                    </Radio.Group>
                  ) : (
                    <None>None</None>
                  )}
                </UsersList>
              </div>
              <div>
                <p>Unassigned users:</p>
                <UsersList>
                  {loading ? (
                    <>
                      {Array.from(new Array(3)).map((item, idx) => (
                        <div
                          style={{ display: 'flex', gap: 5, marginBottom: 5 }}
                          key={idx}
                        >
                          <Skeleton variant='circle'>
                            <Avatar size={26} />
                          </Skeleton>
                          <Skeleton width={120} />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {technicians.filter(tech => tech.unassigned).length ? (
                        technicians
                          .filter(tech => tech.unassigned)
                          .filter(tech =>
                            tech.name
                              .toLowerCase()
                              .includes(search.toLowerCase()),
                          )
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map(tech => (
                            <Row
                              onClick={() => handleAssignTech(tech.id)}
                              // style={{ display: 'flex', gap: 5, alignItems: 'center' }}
                              key={tech.id}
                            >
                              <Avatar
                                pictureURL={getMediaURL(tech?.profile_picture)}
                                color={tech?.background_color || '#626ed4'}
                                size={28}
                              />
                              <span>
                                {tech.name}{' '}
                                {tech?.timeOff?.length ? (
                                  <span style={{ color: 'grey' }}>
                                    - Time off
                                  </span>
                                ) : (
                                  ''
                                )}
                              </span>
                              <PlusOutlined
                                style={{ fontSize: 14, color: '#1890ff' }}
                              />
                            </Row>
                          ))
                      ) : (
                        <None>None</None>
                      )}
                    </>
                  )}
                </UsersList>
              </div>
            </div>
          </div>
        </SettingsRow>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const UsersList = styled.div`
  height: 50vh;
  border-radius: 4px;
  overflow-y: auto;
  padding: 3px;
`

const Footer = styled.div`
  display: flex;
  gap: 10px;
`

const MainTech = styled.span`
  color: grey;
  font-size: 0.8rem;
  font-style: italic;
`
const None = styled.p`
  color: grey;
  font-style: italic;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;

  .anticon {
    opacity: 0;
  }

  :hover {
    background-color: #1890ff38;

    .anticon {
      opacity: 1;
    }
  }

  .ant-radio {
    top: 0;
  }
`
const PopconfirmTitle = () => (
  <>
    <p style={{ fontWeight: '500' }}>
      Are you sure you want to delete this column?
    </p>
    <span>All future events assigned to this column will be put in queue</span>
  </>
)
