import React, { useState, useEffect, useRef, memo } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { DraggableIconBlack } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { Buttons } from '../Buttons'
import { useDispatch } from 'react-redux'
import * as actions from 'store/Orcatec/actions'
import { useAppSelector } from 'store/Orcatec/hooks'
import { putProjectFWOSettings } from 'api/Project'

export const PropertiesSummary = memo(
  ({ freezed, openNotification, initialSummary: { properties } }) => {
    const dispatch = useDispatch()
    const companyId = useAppSelector(state => state.orcatec.company.id)
    const proposalFormId = useAppSelector(
      state => state.orcatec.proposalForm.id,
    )
    const [summaryData, setSummaryData] = useState([])
    const [initialValue, setInitialValue] = useState(null)
    const [primaryElemHeight, setPrimaryElemHeight] = useState(0)
    const [hasChange, setHasChange] = useState(false)
    const [isButtonsDisabled, setIsButtonsDisabled] = useState(false)
    const originalValue = useRef()

    useEffect(() => {
      if (freezed) {
        setSummaryData(initialValue)
        setHasChange(false)
      }
    }, [freezed])

    useEffect(() => {
      setPrimaryElemHeight(calcPrimaryElemHeight())
    })

    useEffect(() => {
      window.addEventListener('resize', resizeListener, false)
      return () => {
        window.removeEventListener('resize', resizeListener, false)
      }
    }, [document.body.clientHeight])

    useEffect(() => {
      setSummaryData(properties)

      if (!originalValue.current && properties) {
        setInitialValue(properties)
        originalValue.current = JSON.stringify(properties)
        dispatch(
          actions.proposalContact.setProposalPropertiesPosition(
            formatRequestData(properties),
          ),
        )
      }
      if (
        originalValue.current &&
        originalValue.current !== JSON.stringify(properties)
      ) {
        setInitialValue(properties)
        properties &&
          dispatch(
            actions.proposalContact.setProposalPropertiesPosition(
              formatRequestData(properties),
            ),
          )
      }
    }, [properties?.length])

    const calcPrimaryElemHeight = () => {
      const el = document.getElementById(
        'proposal-contact-summary-primary-property',
      )
      return el ? el.offsetHeight + 10 : 0
    }

    const resizeListener = () => {
      setPrimaryElemHeight(calcPrimaryElemHeight())
    }

    const handleMoveEnd = () => {
      setHasChange(true)
      setPrimaryElemHeight(calcPrimaryElemHeight())
    }

    function formatRequestData(properties) {
      return {
        data:
          properties &&
          properties.map((el, idx) => ({
            property_relation_id: el.id,
            position: idx + 1,
            is_primary: !!(idx === 0),
          })),
      }
    }

    const handleSave = () => {
      setInitialValue(summaryData)

      setHasChange(false)
      openNotification('summary-save', 'address')
      setIsButtonsDisabled(false)
    }

    const handleButtonClick = type => {
      switch (type) {
        case 'save':
          setIsButtonsDisabled(true)
          dispatch(
            actions.proposalContact.setProposalPropertiesPosition(
              formatRequestData(summaryData),
              handleSave,
            ),
          )
          // if (summaryData?.[0]?.postcode) {
          //   dispatch(
          //     actions.proposalForm.getRateByZipCode(summaryData?.[0]?.postcode),
          //   )
          // }
          putProjectFWOSettings(
            proposalFormId,

            { customer: null, project: null, location: null },
            companyId,
          )

          break
        case 'cancel-save':
          setSummaryData(initialValue)
          setHasChange(false)
          break
        default:
          break
      }
    }

    const render = () => {
      return summaryData.map((el, idx) => (
        <div
          className='proposal-contact-summary__item'
          key={idx}
          id={idx === 0 ? `proposal-contact-summary-primary-property` : null}
        >
          {freezed ? null : (
            <span className='proposal-contact-summary__item-icon'>
              <DraggableIconBlack />
            </span>
          )}
          <p className='proposal-contact-summary__item-title'>{el.relation}</p>
          <p>{el.address}</p>
          <p>{el.unit ? `Unit: ${el.unit}` : ''}</p>
          <p>
            {el.city}
            {el.postcode ? `, ${el.postcode}` : ''}
          </p>
        </div>
      ))
    }

    return summaryData && summaryData?.length ? (
      <section className='proposal-contact-summary__section'>
        <div
          className='proposal-contact-summary__section-primary'
          style={{ height: primaryElemHeight }}
        ></div>
        <div className='proposal-contact-summary__section-title'>
          Primary Address
        </div>
        {freezed ? (
          <div className='proposal-contact-summary__content'>{render()}</div>
        ) : (
          <ReactSortable
            list={summaryData}
            setList={setSummaryData}
            onEnd={handleMoveEnd}
            className='proposal-contact-summary__content'
            handle='.proposal-contact-summary__item-icon'
          >
            {render()}
          </ReactSortable>
        )}
        {hasChange && (
          <div className='proposal-contact-summary__section'>
            <Buttons
              handleButtonClick={handleButtonClick}
              firstBtn={{ action: 'cancel-save' }}
              secondBtn={{ action: 'save', title: 'Save' }}
              buttonsDisabled={isButtonsDisabled}
            />
          </div>
        )}
      </section>
    ) : null
  },
)
PropertiesSummary.displayName = 'PropertiesSummary'
