import { SearchOutlined } from '@ant-design/icons'
import { TextField } from 'components/UIKit'
import useOnClickOutside from 'hooks/useOnClickOutside'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useItemsSearch } from './hooks'
import { Spin } from 'antd'
import {
  DiscountExpirationType,
  ProjectDiscount,
  ProjectDiscountType,
} from 'features/Project/types'

interface Props {
  error?: string
  hidePrices?: boolean
  initialValue: string
  isRebates?: boolean
  onItemChoose: (item: ProjectDiscount) => void
  onValueChange: (value: string) => void
}

export const SearchItems = ({
  error,
  hidePrices,
  initialValue,
  isRebates,
  onItemChoose,
  onValueChange,
}: Props) => {
  const ref = useRef(null)

  const [search, setSearch] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)

  const { data, loading } = useItemsSearch(
    {
      search: search || undefined,
    },
    isRebates,
  )

  useOnClickOutside(ref, () => setShowDropdown(false))

  useEffect(() => {
    setSearch(initialValue)
  }, [initialValue])

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setSearch(value)
    onValueChange?.(value)
  }

  const handleOpenDropdown = () => {
    setShowDropdown(true)
  }

  const handleChooseItem = (item: any) => {
    onItemChoose(item)
    setShowDropdown(false)
  }

  return (
    <Wrapper ref={ref}>
      <TextField
        value={search}
        onChange={handleChangeSearch}
        prefix={<SearchOutlined />}
        placeholder='Search in Price Pages'
        onFocus={handleOpenDropdown}
        error={error}
      />

      {showDropdown && (
        <Dropdown>
          <Spin spinning={loading}>
            {/* <DropdownSection>Discounts</DropdownSection> */}
            <List>
              {data?.length
                ? data.map(item => (
                    <ListItem
                      key={item.id}
                      onClick={() => handleChooseItem(item)}
                    >
                      <ItemName>{item.name}</ItemName>

                      <ItemExpiration>
                        {item.expiration_type ===
                        DiscountExpirationType['No expiration']
                          ? 'No expiration'
                          : item.expiration_type ===
                            DiscountExpirationType['Fixed date']
                          ? item.expiration
                          : DiscountExpirationType[item.expiration_type]}
                      </ItemExpiration>

                      {!hidePrices && (
                        <ItemAmount>
                          {(!item.discount_type ||
                            item.discount_type ===
                              ProjectDiscountType.Amount) &&
                            '$'}
                          {item.amount}
                          {item.discount_type ===
                            ProjectDiscountType.Percentage && '%'}
                        </ItemAmount>
                      )}
                    </ListItem>
                  ))
                : `No items found`}
            </List>
          </Spin>
        </Dropdown>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const List = styled.div`
  padding: 10px;
  color: grey;
`

const ListItem = styled.p`
  cursor: pointer;
  margin-bottom: 5px;
  opacity: 0.8;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 200px 80px;

  &:hover {
    color: #000;
    opacity: 1;
  }
`

const ItemName = styled.p`
  font-weight: 500;
  color: #76a3ed;
`

const ItemExpiration = styled.p`
  /* justify-self: center; */
`

const ItemAmount = styled.p`
  justify-self: end;
`

const Dropdown = styled.div`
  position: absolute;
  top: 33px;
  background-color: #fff;
  width: 100%;
  width: 100%;
  z-index: 1;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  max-height: 488px;
  overflow: auto;
`
