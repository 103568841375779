import { notification } from 'antd'

export const openNotificationWithIcon = (
  type = '',
  data = {},
  duration = 5,
  heandler,
  destroyOnHover = true,
  onClick,
) => {
  heandler && heandler()
  const destroyNotificationHeandler = () =>
    destroyOnHover ? notification.destroy() : null
  return notification[type]({
    ...data,
    duration,
    onClick,
    style: { cursor: 'pointer' },
    message: (
      <div onMouseEnter={destroyNotificationHeandler}>{data?.message}</div>
    ),
  })
}
