import { Empty, Tooltip, Modal } from 'antd'
import moment from 'moment-timezone'

import { getRealContractStatus } from 'helpers/getRealContractStatus'
import { priceToView } from 'helpers/thousandSeparator'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { RestItemsSpan, renderNotes } from '../utils'
import CreateProposal from 'containers/MainContent/Orcatec/Proposals/components/Modals/CreateProposal'
import { useSelector } from 'react-redux'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import { meSelector } from 'store/SuperAdmin/selectors'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'

import './styles.scss'
import { StatusBadge } from 'features/Settings/Project/Statuses/components/StatusBadge'
import { STATUS_INFO } from 'features/Settings/Project/Statuses/Statuses'

const TableProposals = ({
  proposals,
  newProposalForm,
  toggleNewProposalForm,
  entity,
  // closeClientForm,
}) => {
  // const history = useHistory()

  const me = useSelector(meSelector)
  const permissions = useSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )

  const goToProposal = id => {
    if (!id) return

    window.open(`/projects/${id}`)

    /**
     *
     * TODO: Add information about opened event and active tab to URL. On "go Back" open same event with active tab in Event Drawer
     */

    // if (window.location.pathname.includes('projects')) {
    // window.open(`/projects/${id}`)
    // } else {
    //   history.push(`/projects/${id}`)
    // }
  }

  // const isContractAccessRestrict = useSelector(
  //   state => state.orcatec.user.me.restrict_contract_access,
  // )
  // const isHasProposalPermission = useSelector(
  //   state => state.orcatec.user.me.permissions.proposals,
  // )

  const handleClick = proposal => {
    const canOpenProject = checkAccessControl(
      permissions[ProjectPermissions.PROJECT_CAN_READ],
      proposal.user_id,
      me.id,
    )
    const canOpenProjectContract = !!permissions[
      ProjectPermissions.PROJECT_ACCESS_TO_CONTRACT
    ]

    if ((!canOpenProjectContract && proposal.status !== 1) || !canOpenProject) {
      return () => null
    } else goToProposal(proposal.id)
  }

  return (
    <div className='client-form-proposals'>
      <Modal
        title='Create Project'
        visible={newProposalForm}
        onCancel={toggleNewProposalForm}
        footer={null}
        destroyOnClose
      >
        <CreateProposal
          onCancel={toggleNewProposalForm}
          entity={entity}
          fromAppointment={false}
        />
      </Modal>
      {proposals.length ? (
        <table>
          <thead className='proposals-table-row heading'>
            <tr>
              <th>Project ID</th>
              <th>Status</th>
              <th>Name</th>
              <th>Tech</th>
              <th>Total</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {proposals
              .sort((a, b) => moment(b?.created_at) - moment(a?.created_at))
              .map(proposal => (
                <tr
                  className='proposals-table-row'
                  key={proposal.id}
                  onClick={() => handleClick(proposal)}
                >
                  <td>{proposal.code}</td>
                  {/* <td>{getRealContractStatus(proposal.status)}</td> */}
                  <td>
                    <StatusBadge
                      status={proposal.custom_status}
                      icon={
                        STATUS_INFO[proposal.custom_status?.parent_status].icon
                      }
                    />
                  </td>
                  {/* <td>{proposal.status_name}</td> */}
                  <td>{proposal.name}</td>
                  <td>{proposal.user?.full_name}</td>

                  <td>
                    {!permissions[ProjectPermissions.PROJECT_CAN_READ]
                      ? '-'
                      : !checkAccessControl(
                          permissions[
                            ProjectPermissions.PROJECT_CAN_READ_PRICE
                          ],
                          proposal?.user_id,
                          me.id,
                        )
                      ? '-'
                      : priceToView(proposal.total_to_pay)}
                  </td>

                  <td>
                    <span>
                      {!!proposal?.notes?.length && (
                        <Tooltip
                          title={proposal?.notes?.map((note, index) => (
                            <p key={index}>{note?.text || note}</p>
                          ))}
                        >
                          {proposal?.notes?.[0]?.text || proposal?.notes?.[0]}
                          {proposal?.notes?.length > 1 && (
                            <RestItemsSpan className='rest-items'>
                              +{proposal?.notes.length - 1}
                            </RestItemsSpan>
                          )}
                        </Tooltip>
                      )}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <>
          <Empty description='No projects yet' />
          <AccessControl scopes={[ProjectPermissions.PROJECT_CAN_CREATE]}>
            <MainButton
              title='Add Project'
              className='add-proposal-button'
              onClick={e => {
                e.preventDefault()
                toggleNewProposalForm()
              }}
            />
          </AccessControl>
        </>
      )}
    </div>
  )
}

export default TableProposals
