import { combineReducers } from 'redux'
import { ActionType } from '../constants/supportConstants'

const {
  SUPPORT_TICKETS_LIST_INIT,
  SUPPORT_TICKETS_LIST_SUCCESS,
  SUPPORT_TICKETS_LIST_FAILURE,
  CLEAR_TICKET,
  CLEAR_MESSAGE,
  SHOW_TICKET_INIT,
  SHOW_TICKET_SUCCESS,
  SHOW_TICKET_FAILURE,
  TICKET_LOADING_DATA,
  TICKET_LOADED_DATA,
  ASSIGNED_TICKET_ASSISTANT,
  NEW_TICKET_MESSAGE,
  TICKET_MESSAGE_DELETED,
  TICKET_MESSAGE_UPDATED,
  TICKET_CHANGED_STATUS,
  REPLY_MESSAGE_INIT,
  REPLY_MESSAGE_SUCCESS,
  REPLY_MESSAGE_FAILURE,
  UPDATE_MESSAGE_INIT,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAILURE,
  SET_UNSEEN_TICKETS_COUNT,
  INCREMENT_UNSEEN_TICKETS_COUNT,
  DECREMENT_UNSEEN_TICKETS_COUNT,
} = ActionType

const listInitialState = {
  data: [],
  total: 1,
  per_page: 10,
  current_page: 1,
  last_page: 1,
  loading: false,
  load: false,
  error: false,
}

const listReducer = (state = listInitialState, { type, payload }) => {
  switch (type) {
    case SUPPORT_TICKETS_LIST_INIT:
      return { ...state, loading: true, load: false, error: false }
    case SUPPORT_TICKETS_LIST_SUCCESS:
      return { ...state, ...payload, loading: false, load: true, error: false }
    case SUPPORT_TICKETS_LIST_FAILURE:
      return { ...state, loading: false, load: false, error: true }
    default:
      return state
  }
}

const ticketInitialState = {
  ticket: null,
  loading: false,
  load: false,
  error: false,
}

const ticketReducer = (state = ticketInitialState, { type, payload }) => {
  switch (type) {
    case CLEAR_TICKET:
      return { ...state, ...ticketInitialState }
    case TICKET_LOADING_DATA:
      return { ...state, loading: true }
    case TICKET_LOADED_DATA:
      return { ...state, loading: false }
    case SHOW_TICKET_INIT:
      return { ...state, loading: true, load: false, error: false }
    case SHOW_TICKET_SUCCESS:
      return { ...state, ticket: payload, loading: false, load: true, error: false }
    case SHOW_TICKET_FAILURE:
      return { ...state, loading: false, load: false, error: true }
    case NEW_TICKET_MESSAGE:
      if (state.ticket) {
        const newMessages = (state.ticket.messages || []).slice()
        newMessages.push(payload)
        return { ...state, ticket: { ...state.ticket, messages: newMessages } }
      } else {
        return state
      }
    case TICKET_MESSAGE_DELETED:
      if (state.ticket && state.ticket.messages) {
        const index = state.ticket.messages.findIndex(message => message.id === payload)
        if (index !== -1) {
          const newMessages = state.ticket.messages.slice()
          newMessages.splice(index, 1)
          return { ...state, ticket: { ...state.ticket, messages: newMessages } }
        } else {
          return state
        }
      } else {
        return state
      }
    case TICKET_MESSAGE_UPDATED:
      if (state.ticket && state.ticket.messages) {
        const index = state.ticket.messages.findIndex(message => message.id === payload.id)
        if (index !== -1) {
          const newMessages = state.ticket.messages.slice()
          newMessages[index] = payload
          return { ...state, ticket: { ...state.ticket, messages: newMessages } }
        } else {
          return state
        }
      } else {
        return state
      }
    case ASSIGNED_TICKET_ASSISTANT:
      return { ...state, ticket: { ...state.ticket, assistant: payload } }
    case TICKET_CHANGED_STATUS:
      return { ...state, ticket: { ...state.ticket, status: payload } }
    default:
      return state
  }
}

const createMessageInitialState = {
  loading: false,
  load: false,
  error: false,
  errorData: null,
}

const createMessageReducer = (state = createMessageInitialState, { type, payload }) => {
  switch (type) {
    case CLEAR_TICKET:
      return { ...state, ...createMessageInitialState }
    case REPLY_MESSAGE_INIT:
      return { ...state, loading: true, load: false, error: false, errorData: null }
    case REPLY_MESSAGE_SUCCESS:
      return { ...state, loading: false, load: true }
    case REPLY_MESSAGE_FAILURE:
      return { ...state, loading: false, load: false, error: true, errorData: { ...payload } }
    default:
      return state
  }
}

const updateMessageInitialState = {
  loading: false,
  load: false,
  error: false,
  errorData: null,
}

const updateMessageReducer = (state = updateMessageInitialState, { type, payload }) => {
  switch (type) {
    case CLEAR_TICKET:
    case CLEAR_MESSAGE:
      return { ...state, ...updateMessageInitialState }
    case UPDATE_MESSAGE_INIT:
      return { ...state, loading: true, load: false, error: false, errorData: null }
    case UPDATE_MESSAGE_SUCCESS:
      return { ...state, loading: false, load: true }
    case UPDATE_MESSAGE_FAILURE:
      return { ...state, loading: false, load: false, error: true, errorData: { ...payload } }
    default:
      return state
  }
}

const unseenTicketsInitialState = {
  count: 0,
}

const unseenTicketsReducer = (state = unseenTicketsInitialState, { type, payload }) => {
  switch (type) {
    case SET_UNSEEN_TICKETS_COUNT:
      return { ...state, count: Number(payload) }
    case INCREMENT_UNSEEN_TICKETS_COUNT:
      return { ...state, count: state.count > 0 ? state.count + 1 : 1 }
    case DECREMENT_UNSEEN_TICKETS_COUNT:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 }
    default:
      return state
  }
}

export default combineReducers({
  listData: listReducer,
  ticketData: ticketReducer,
  createMessageData: createMessageReducer,
  updateMessageData: updateMessageReducer,
  unseenTickets: unseenTicketsReducer,
})
