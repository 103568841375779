export const ActionType = {
  CLEAR_STORE: 'CLEAR_STORE',

  CREATE_ACCOUNT_INIT: 'CREATE_ACCOUNT_INIT',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILURE: 'CREATE_ACCOUNT_FAILURE',

  EDIT_ACCOUNT_INIT: 'EDIT_ACCOUNT_INIT',
  EDIT_ACCOUNT_SUCCESS: 'EDIT_ACCOUNT_SUCCESS',
  EDIT_ACCOUNT_FAILURE: 'EDIT_ACCOUNT_FAILURE',

  EDIT_ACCOUNT_STATUS_INIT: 'EDIT_ACCOUNT_STATUS_INIT',
  EDIT_ACCOUNT_STATUS_SUCCESS: 'EDIT_ACCOUNT_STATUS_SUCCESS',
  EDIT_ACCOUNT_STATUS_FAILURE: 'EDIT_ACCOUNT_STATUS_FAILURE',

  GET_ACCOUNTS_LIST_INIT: 'GET_ACCOUNTS_LIST_INIT',
  GET_ACCOUNTS_LIST_SUCCESS: 'GET_ACCOUNTS_LIST_SUCCESS',
  GET_ACCOUNTS_LIST_FAILURE: 'GET_ACCOUNTS_LIST_FAILURE',

  DELETE_ACCOUNT_INIT: 'DELETE_ACCOUNT_INIT',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILURE: 'DELETE_ACCOUNT_FAILURE',

  GET_CURRENT_ACCOUNT_INIT: 'GET_CURRENT_ACCOUNT_INIT',
  GET_CURRENT_ACCOUNT_SUCCESS: 'GET_CURRENT_ACCOUNT_SUCCESS',
  GET_CURRENT_ACCOUNT_FAILURE: 'GET_CURRENT_ACCOUNT_FAILURE',
}
