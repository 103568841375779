import { useAppSelector } from 'store/Orcatec/hooks'
import { selectProjectItemsSlice } from 'features/Project/projectSelectors'

export const useUnits = () => {
  const { units } = useAppSelector(selectProjectItemsSlice)
  //   const [units, setUnits] = useState<Record<number, ItemUnit>>([])
  //
  //   useEffect(() => {
  //     const getData = async () => {
  //       const data = await ItemsAPI.getItemUnits()
  //       setUnits(transformArrayToObj(data))
  //     }
  //
  //     getData()
  //   }, [])

  return { units }
}
