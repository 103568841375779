import { ProjectStatus } from 'features/Project/types'
import styled from 'styled-components'
import { EditPencilIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import React from 'react'

interface Props {
  icon?: React.ReactNode
  status: ProjectStatus
  onClick?: (status: ProjectStatus) => void
  maxWidth?: number
}

export const StatusBadge = ({ status, icon, onClick, maxWidth }: Props) => {
  return (
    <StatusWrapper key={status.id} onClick={() => onClick?.(status)}>
      <Status key={status.id} color={status.color} maxWidth={maxWidth}>
        {icon}
        <StatusName>{status.display_name}</StatusName>
      </Status>

      {!!onClick && (
        <Icon>
          <EditPencilIcon width={16} height={16} />
        </Icon>
      )}
    </StatusWrapper>
  )
}

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`
const Status = styled.div<{ color: string; maxWidth?: number }>`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: ${props => props.color};
  background-color: ${props => props.color}33;
  padding: 6px 8px;
  border-radius: 6px;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : undefined)};

  span {
    color: ${props => props.color} !important;
  }
`

const Icon = styled.span`
  cursor: pointer;
`

const StatusName = styled.p<{ maxWidth?: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
