import React, { FC } from 'react'
import { FEEDBACK_TYPES, IReviewsTemplate } from 'features/Reviews/types'

import { Company } from 'features/Settings/Subscription/types'
import styled from 'styled-components'
import facebookImage from '../../image/facebook-reviews.webp'
import googleImage from '../../image/Google-Reviews.webp'
import linkedinImage from '../../image/LinkedIn.jpeg'
import yelpImage from '../../image/yelp-review-icon.webp'
import { Companies } from 'types/Company'
import ReactHtmlParser from 'react-html-parser'

interface IProps {
  company: Company
  handleSelect: (type: FEEDBACK_TYPES) => void
  template: IReviewsTemplate
}

interface IItem {
  key: FEEDBACK_TYPES
  title: string
  icon: any
  path?: string
}

const SocialItem = ({
  item,
  onClick,
}: {
  item: IItem
  onClick: (type: number) => void
}) => {
  return (
    <ItemWrapper
      target='_blank'
      rel='noopener noreferrer'
      href={item.path}
      onClick={onClick}
    >
      <img src={item.icon} className='social-icon' />
      <div className='social-title'>{`Review us on ${item.title}`}</div>
    </ItemWrapper>
  )
}

export const FeedbackSocialForm: FC<IProps> = ({
  company,
  handleSelect,
  template,
}) => {
  const socialsLinks: IItem[] = [
    {
      key: FEEDBACK_TYPES.FACEBOOK,
      title: 'Facebook',
      icon: facebookImage,
      path: company?.social?.facebook,
    },
    {
      key: FEEDBACK_TYPES.YELP,
      title: 'Yelp',
      icon: yelpImage,
      path: company?.social?.yelp,
    },
    {
      key: FEEDBACK_TYPES.LINKEDIN,
      title: 'Linkedin',
      icon: linkedinImage,
      path: company?.social?.linkedin,
    },
    {
      key: FEEDBACK_TYPES.GOOGLE,
      title: 'Google',
      icon: googleImage,
      path: company?.social?.google,
    },
  ]
  return (
    <Wrapper>
      {[Companies.ApplianceServiСenter].includes(company?.company_id) ? (
        ReactHtmlParser(template.positive_header_text)
      ) : (
        <h5>Please leave us a review by clicking one of the links below:</h5>
      )}
      {[Companies.ApplianceServiСenter].includes(company?.company_id) &&
        ReactHtmlParser(template.positive_below_links_text)}

      {socialsLinks
        .filter(item => !!item.path)
        .map(item => (
          <SocialItem
            key={item.key}
            item={item}
            onClick={() => handleSelect(item.key)}
          />
        ))}
      {[Companies.ApplianceServiСenter].includes(company?.company_id) &&
        ReactHtmlParser(template.positive_after_links_text)}
    </Wrapper>
  )
}

const ItemWrapper = styled.a`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-radius: 5px;
  & .social-title {
    letter-spacing: 1.2;
  }
  & .social-icon {
    background-color: white;
    border-radius: 4px;
    padding: 1px;
    max-width: 100px;
    height: auto;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`
