import { Checkbox } from 'antd'
import { getMediaURL } from 'helpers/getMediaURL'
import Avatar from 'containers/MainContent/Orcatec/components/Avatar'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useEffect, useMemo, useRef, useState } from 'react'
// import { useAppSelector } from 'store/Orcatec/hooks'
import {
  Assignee,
  Circle,
  Dropdown,
  DropdownItem,
  Wrapper,
  MoreWrapper,
} from './AssigneeFilter.styles'
import { ITechnician } from 'types/Appointment'

type Props = {
  main?: boolean
  data: ITechnician[]
  onChange?: (ids: number[]) => void
}

export const AssigneeFilter = ({ data, main, onChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [choosenItems, setChoosenItems] = useState<any[]>([])

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsOpen(false))

  useEffect(() => {
    onChange?.(choosenItems)
  }, [choosenItems])

  const handleChooseItem = (choosenitem: any) => {
    setChoosenItems(prev =>
      prev?.includes(choosenitem)
        ? prev?.filter(item => item !== choosenitem)
        : [...prev, choosenitem],
    )
  }

  const sortedData = useMemo(
    () => [...data]?.sort((a, b) => a.sort_order - b.sort_order),
    [data],
  )

  return (
    <Wrapper>
      {sortedData?.slice(0, 4).map((el, index, array) => (
        <Assignee
          position={array.length - index + 1}
          selected={choosenItems?.includes(el.user_id)}
          onClick={() => {
            if (!onChange) return
            handleChooseItem(el.user_id)
          }}
          title={el.name}
          key={el.user_id}
        >
          <Avatar
            pictureURL={getMediaURL(el.profile_picture)}
            color={el.background_color || '#626ed4'}
          >
            {el.name}
          </Avatar>
        </Assignee>
      ))}
      {data.length > 4 && (
        <MoreWrapper ref={ref} onTop={!!main}>
          <Circle
            onClick={() => setIsOpen(!isOpen)}
            active={data
              ?.slice(onChange ? 5 : 4)
              ?.some(el => choosenItems.includes(el.user_id))}
          >
            +{data.length - 4}
          </Circle>

          {isOpen && (
            <Dropdown>
              {data.slice(onChange ? 5 : 4)?.map(el => (
                <DropdownItem
                  key={el.id}
                  selected={choosenItems?.includes(el.user_id)}
                  onClick={() => {
                    if (!onChange) return
                    handleChooseItem(el.user_id)
                  }}
                >
                  {!!onChange && (
                    <Checkbox checked={choosenItems?.includes(el.user_id)} />
                  )}
                  <Avatar
                    size={24}
                    pictureURL={getMediaURL(el.profile_picture)}
                    color={el.background_color || '#626ed4'}
                  >
                    {el.name}
                  </Avatar>
                  {el.name}
                </DropdownItem>
              ))}
              {!!onChange && (
                <DropdownItem
                  selected={choosenItems?.includes(null)}
                  onClick={() => handleChooseItem(null)}
                >
                  <Checkbox checked={choosenItems?.includes(null)} />
                  <Avatar
                    size={24}
                    pictureURL={`assets/images/users/user-1.jpg`}
                  />
                  Unassigned
                </DropdownItem>
              )}
            </Dropdown>
          )}
        </MoreWrapper>
      )}
    </Wrapper>
  )
}
