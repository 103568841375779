import * as types from '../types'

export default (state = [], action) => {
  switch (action.type) {
    case types.marketing.GET_COMPAINGS:
      return action.response
    case types.marketing.UPDATE_TAGS:
      let newTags = state
      newTags.data[newTags.data.map(item => item.id).indexOf(action.id)].tags = action.data
      return newTags
    default:
      return state
  }
}
