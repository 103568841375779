import React, { useEffect } from 'react'
import ToggleButton from 'containers/MainContent/Orcatec/components/buttons/ToggleButton/ToggleButton.jsx'
import { Empty } from 'antd'
import Preloader from 'containers/MainContent/Orcatec/components/Preloader'
import SureModal from 'containers/MainContent/Orcatec/components/SureModalRedirect/SureModalRedirect.jsx'

export const Section = ({
  children,
  title,
  qty,
  isTabsOpen,
  isLoading,
  clearClientStore,
  SaveButtons = () => null,
  isSectionOpen,
  isShowEmpty = true,
  isHaveChange,
  // renderSelectTab,
}) => {
  useEffect(() => {
    if (!isTabsOpen && clearClientStore) clearClientStore()
  }, [isTabsOpen])

  const render = () => {
    if (isLoading) return <Preloader />
    if (!qty && !isTabsOpen && isSectionOpen && isShowEmpty)
      return <Empty description={`No ${title.toLowerCase()} data yet`} />
    if (isSectionOpen) return children
  }

  return (
    <>
      <div className={`${isHaveChange && 'proposal-contact-info__edit '} proposal-contact-section`}>
        <div className='proposal-contact-section__title'>
          {isShowEmpty && <div className='proposal-contact-section__title-title'>{title}</div>}
          {/* <SaveButtons style={{ margin: 0 }} /> */}
          {/* {renderSelectTab()} */}
        </div>
        {render()}
      </div>
    </>
  )
}
