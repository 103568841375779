import { SearchOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { TextField } from 'components/UIKit'
import { FolderIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { useProjectItemsSearch } from 'features/Items/hooks/useProjectItemsSearch'
import { GlobalItem } from 'features/Items/types'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface Props {
  catId?: number | null
  error?: string
  itinialSearchValue?: string
  onChooseItem: (item: GlobalItem) => void
  onValueChange?: (value: string) => void
}

export const ItemsSearch = ({
  catId,
  error,
  itinialSearchValue,
  onChooseItem,
  onValueChange,
}: Props) => {
  const [search, setSearch] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const [categoryId, setCategoryId] = useState<number | null>(catId)

  useEffect(() => {
    setCategoryId(catId)
  }, [catId])

  useEffect(() => {
    setSearch(itinialSearchValue || '')
  }, [itinialSearchValue])

  const ref = useRef(null)

  useOnClickOutside(ref, () => setShowDropdown(false))

  const { data, loading } = useProjectItemsSearch({
    search: search || undefined,
    category_id: categoryId || undefined,
  })

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setSearch(value)
    onValueChange?.(value)
  }

  const handleOpenDropdown = () => {
    setShowDropdown(true)
  }

  const handleChooseCategory = (catId: number | null) => {
    setCategoryId(catId)
    setSearch('')
  }

  const handleChooseItem = (item: GlobalItem) => {
    onChooseItem(item)
    setShowDropdown(false)
  }

  return (
    <Wrapper ref={ref}>
      <TextField
        value={search}
        onChange={handleChangeSearch}
        prefix={<SearchOutlined />}
        placeholder='Search in Price Pages'
        onFocus={handleOpenDropdown}
        error={error}
      />

      {showDropdown && (
        <Dropdown>
          <Spin spinning={loading}>
            {!!data?.breadcrumbs?.length && (
              <Breadcrumbs>
                <Crumbs>
                  <p onClick={() => handleChooseCategory(null)}>Main</p>
                  {data.breadcrumbs.map(crumb => (
                    <p
                      key={crumb.id}
                      onClick={() => handleChooseCategory(crumb.id)}
                    >
                      {crumb.name}
                    </p>
                  ))}
                </Crumbs>
              </Breadcrumbs>
            )}

            <DropdownSection>Categories</DropdownSection>
            <List>
              {data?.categories?.length
                ? data.categories.map(cat => (
                    <ListItem
                      key={cat.id}
                      onClick={() => {
                        if (!cat.id) return
                        handleChooseCategory(cat.id)
                      }}
                    >
                      <CatItem>
                        <FolderIcon style={{ fontSize: 18, color: '#000' }} />
                        {cat.name}
                      </CatItem>
                    </ListItem>
                  ))
                : `No categories found${
                    data?.breadcrumbs.length
                      ? ` in "${
                          data.breadcrumbs[data?.breadcrumbs?.length - 1]?.name
                        }"`
                      : ''
                  }`}
            </List>
            <DropdownSection>Items</DropdownSection>
            <List>
              {data?.items?.length
                ? data.items.map(item => (
                    <ListItem
                      key={item.id}
                      onClick={() => handleChooseItem(item)}
                    >
                      <ItemName>{item.name}</ItemName>
                      {!!item?.location && (
                        <ItemLocation>{item.location}</ItemLocation>
                      )}
                      {!!item.description && (
                        <ItemDescription>{item.description}</ItemDescription>
                      )}
                    </ListItem>
                  ))
                : `No items found${
                    data?.breadcrumbs.length
                      ? ` in "${
                          data.breadcrumbs[data?.breadcrumbs?.length - 1]?.name
                        }"`
                      : ''
                  }`}
            </List>
          </Spin>
        </Dropdown>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const Crumbs = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    color: grey;
    cursor: pointer;

    &:not(:last-child)::after {
      content: '>';
      margin: 0 5px;
    }

    &:hover {
      color: #007bff;
    }
  }
`

const Breadcrumbs = styled.div`
  min-height: 25px;
  margin: 2px 0 2px 5px;
`
const List = styled.div`
  padding-left: 10px;
  margin: 10px 0;
  color: grey;
`

const CatItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`
const ListItem = styled.p`
  cursor: pointer;
  margin-bottom: 5px;
  /* opacity: 0.8; */

  &:hover {
    color: #000;
    /* opacity: 1; */
  }
`

const ItemLocation = styled.span`
  font-size: 0.8rem;
  color: #c0c0c0;
  margin-left: 10px;
  font-style: italic;
`
const ItemName = styled.span`
  font-weight: 500;
  color: #76a3ed;
`
const ItemDescription = styled.p`
  font-size: 0.8rem;
  margin-left: 10px;
  margin-top: -6px;
`

const Dropdown = styled.div`
  position: absolute;
  top: 35px;
  background-color: #fff;
  width: 100%;
  width: 100%;
  z-index: 1;
  border-radius: 4px;
  border: 1px solid #8f8f8f;
  max-height: 488px;
  overflow: auto;
`

const DropdownSection = styled.p`
  background: #f5f5f5;
  padding: 5px;
`
