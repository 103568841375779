import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { priceToView } from '../../../../../../../../helpers/thousandSeparator'
import './EndOfSystem.scss'
// import { TaxForm } from '../TaxForm'
import CancellationFees from '../CancellationFees'
// import Fees from '../Fees'
import { useDispatch } from 'react-redux'
import { setTotalAfterTax } from 'store/Orcatec/actions/proposal/proposalForm'
import { useAppSelector } from 'store/Orcatec/hooks'
import { useIsFreezed } from '../../ProposalContact/utils'
import { round } from 'helpers/Math'
import {
  selectInvestmentTotal,
  selectMaterialsTotal,
  // selectTaxableTotal,
  selectProjectTotalAfterDiscounts,
  selectProjectRebatesTotal,
  selectProjectTaxTotal,
  selectProjectFeesTotal,
  selectProjectDiscountsWithStatus,
  selectProjectRebatesWithStatus,
  selectProjectTotal,
  selectProjectFees,
  selectProjectTaxAndFeesSlice,
} from 'features/Project/projectSelectors'
import { store } from 'index'
// import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'
import { selectCompanyTimezone } from 'store/Orcatec/selectors/company'
import { ProjectDiscountType } from 'features/Project/types'
import { SectionDiscountAndRebates } from '../SectionDiscountAndRebates/SectionDiscountAndRebates'
import Taxes from 'features/Project/Taxes'
import NewFees from 'features/Project/Fees'

const calcPrice = (items, price, key = 'price') => {
  const totalPrice = price
  const reducer = (acc, curr) => {
    let priceOfItem
    if (curr.options) {
      const options = curr.options?.data.forEach(option => {
        if (curr.options?.checked_option === option.idForChoosenOption) {
          priceOfItem = Number(option[key]) || 0
        }
      })
    } else {
      // priceOfItem = curr.price ? Number(curr.price) : 0
      priceOfItem = curr[key] && curr?.use_calculation ? Number(curr[key]) : 0
    }
    // return Number(acc) + priceOfItem * (+curr.qty ?? 1)
    return Number(acc) + priceOfItem * curr?.qty
  }

  // const filteredItems = items?.filter(item => item.use_calculation) //filter unselected items

  return Array.isArray(items) && !!items?.length
    ? items.reduce(reducer, totalPrice)
    : totalPrice
}

const getSectionSubtotal = section => {
  const projectItems = Object.values(
    store?.getState()?.orcatec?.projectItemsSlice?.items,
  )
  let result = 0
  // result = calcPrice(section.materials, result)
  // result = calcPrice(section?.scope_of_works, result)
  result = projectItems
    .filter(item => (item.option_group_id ? item.checked_option : item))
    .filter(
      item =>
        item.section_id === section.id &&
        !!item.use_calculation &&
        !!item.approved,
    )
    .reduce((sum, item) => (sum += +item.retail_price * +item.qty), result)

  result = calcPrice(section.warranties, result, 'retail_price')
  result = (section.installation ? Number(section.installation) : 0) + result
  // result = +((section?.gross_margin / 100) * result).toFixed(2) + result
  // result = round((section?.gross_margin / 100) * result) + result
  return result
}

const calcDiscount = (items, price, contractDate, section, timezone) => {
  let sectionSubtotal = 0

  if (section) sectionSubtotal = getSectionSubtotal(section)

  // const endOfExpirationDate = contractDate
  //   ? moment(contractDate).endOf('day')
  //   : moment().endOf('day')

  const endOfExpirationDate = contractDate
    ? moment(contractDate).tz(timezone)
    : moment().endOf('day')

  let totalPrice = price
  const reducer = (acc, curr) => {
    const expiration = curr.expiration
    let priceOfItem

    if (curr.options) {
      const options = curr.options?.data.forEach(option => {
        if (curr.options?.checked_option === option.idForChoosenOption) {
          priceOfItem =
            option.discount_type === ProjectDiscountType.Percentage
              ? (sectionSubtotal * option.price) / 100
              : option.price
              ? Number(option.price)
              : 0
        }
      })
    } else {
      priceOfItem = round(
        curr.discount_type === ProjectDiscountType.Percentage
          ? (sectionSubtotal * curr.price) / 100
          : curr.price
          ? Number(curr.price)
          : 0,
      )
    }
    return moment(expiration)
      .endOf('day')
      .tz(timezone)
      .isBefore(endOfExpirationDate, 'days')
      ? Number(acc) + 0
      : Number(acc) + priceOfItem
    // return moment(expiration).isBefore(endOfExpirationDate, 'days')
    //   ? Number(acc) + 0
    //   : Number(acc) + priceOfItem
  }
  totalPrice =
    Array.isArray(items) && !!items?.length
      ? items.reduce(reducer, totalPrice)
      : totalPrice
  return totalPrice
}

const getDiscountPrice = (discountItem, section) => {
  const percentDiscount =
    discountItem.discount_type === ProjectDiscountType.Percentage
  const price = percentDiscount
    ? getSectionSubtotal(section) * (discountItem.amount / 100)
    : discountItem.amount

  return price
}

const EndOfSystem = props => {
  const { proposalStatus, isEditContract, contractDate } = props

  // const [feeSum, setFeeSum] = useState(0)
  // const [cancellationFees, setCancellationFees] = useState(0)

  const dispatch = useDispatch()
  const isFreezed = useIsFreezed(isEditContract)

  const tabDiscounts = useAppSelector(selectProjectDiscountsWithStatus)
  const tabRebates = useAppSelector(selectProjectRebatesWithStatus)
  // const taxableItemsTotal = useAppSelector(selectTaxableTotal)
  const totalMaterialsPrice = useAppSelector(selectMaterialsTotal())
  const totalInvestmentPrice = useAppSelector(selectInvestmentTotal)
  const totalAfterDiscounts = useAppSelector(selectProjectTotalAfterDiscounts)
  const rebatesTotal = useAppSelector(selectProjectRebatesTotal)
  const taxTotal = useAppSelector(selectProjectTaxTotal)
  const { tax } = useAppSelector(selectProjectTaxAndFeesSlice)
  const fees = useAppSelector(selectProjectFees)
  const feeSum = useAppSelector(selectProjectFeesTotal)
  const totalToPay = useAppSelector(selectProjectTotal)
  const companyTimezone = useAppSelector(selectCompanyTimezone)

  const projectTax = {
    name: props.allTotalPrices?.tax ? props.tax?.name : tax?.name,
    amount: props.allTotalPrices?.tax ? props.allTotalPrices?.tax : taxTotal,
  }

  const projectFees = props?.fees ? props?.fees : fees

  const isInvestmentTotal =
    projectFees?.length ||
    projectTax.amount ||
    !!props.sections.flatMap(section => section.discounts).length ||
    !!props.sections.flatMap(section => section.rebates).length ||
    !!tabDiscounts?.length ||
    !!tabRebates?.length

  const totalAfterDiscountsAndRebates = totalAfterDiscounts - rebatesTotal
  // const totalToPay = totalAfterDiscounts + taxTotal + feeSum

  const isThereRebates = props?.publicView
    ? props.sections.reduce((acc, curr) => acc.concat(curr.rebates), [])?.length
    : tabRebates.length
  const isThereDiscounts = props?.publicView
    ? props.sections.reduce((acc, curr) => acc.concat(curr.discounts), [])
        ?.length
    : tabDiscounts.length

  const getPrice = (section, item) => {
    return `-${priceToView(
      !props.clientView
        ? getDiscountPrice(item, section)
        : Math.abs(
            props?.allTotalPrices?.instants_data?.[section.id]?.[
              item.idForChoosenOption || item.id
            ]?.instant_price ??
              item.instant_price ??
              item.amount,
          ),
    )}`
  }

  const items = () =>
    props.sections.reduce((acc, section, sectionIdx, sectionsArray) => {
      const getItemsRows = (
        items,
        itemsName,
        isSectionNameNeeded,
        isItemsNameNeeded,
        isSectionTitleUnderlineNeeded,
        isItemsTitleUnderlineNeeded,
        isTitleOneLine,
      ) => {
        items
          ?.flatMap(item => (item.options ? item.options.data : item))
          ?.filter(item => (item.option_group_id ? item.checked_option : item))
          ?.forEach((item, idx, itemsArray) => {
            const expiration = item.expiration_date || item.expiration
            let expireStr = null
            let isExpired = false

            const endOfExpirationDate = contractDate
              ? moment(contractDate).tz(companyTimezone)
              : moment().endOf('day')

            if (expiration === expireStr || expiration === '12/12/9999') {
              expireStr = 'no expiration'
              isExpired = false
            } else {
              isExpired = moment(expiration)
                .endOf('day')
                .tz(companyTimezone)
                .isBefore(endOfExpirationDate, 'day')

              expireStr =
                (!isExpired ? 'Expires on ' : 'Expired on ') +
                moment(expiration).format('MM/DD/YYYY')
            }
            const underline = ''
            // const underline = '1px solid #dcdfe5'

            // const firstSection = sectionIdx === 0
            const lastSection = sectionIdx === sectionsArray?.length - 1

            const firstItem = idx === 0
            const lastItem = idx === itemsArray?.length - 1

            const isJustOneSectionAndDefaultTitle =
              sectionsArray?.length === 1 && section.title === 'New Section'

            const itemsTitle =
              firstItem && isItemsNameNeeded ? itemsName + ':' : ''
            const sectionTitle =
              idx === 0 &&
              isSectionNameNeeded &&
              !isJustOneSectionAndDefaultTitle
                ? `Section ${sectionIdx === 0 ? '' : sectionIdx} ${
                    section.title
                  }:`
                : ''

            const itemsUnderline =
              !lastSection ||
              (lastSection && !lastItem) ||
              (lastSection && lastItem && isItemsTitleUnderlineNeeded)
                ? underline
                : 'none'
            const itemsTitleUnderline =
              (!lastSection && lastItem) ||
              (lastSection && lastItem && isItemsTitleUnderlineNeeded)
                ? underline
                : 'none'
            const sectionTitleUnderline =
              !lastSection && lastItem && isSectionTitleUnderlineNeeded
                ? underline
                : 'none'
            acc.push(
              <>
                {sectionTitle && sectionIdx !== 0 ? (
                  <>
                    <tr className='proposal-form-tabs_content-end_of_system__discounts-underline--first'>
                      <td
                        style={{
                          borderBottom: '0.5px solid rgba(0, 0, 0, 0.5)',
                        }}
                      />
                      <td
                        style={{
                          borderBottom: '0.5px solid rgba(0, 0, 0, 0.5)',
                        }}
                      />
                      <td
                        style={{
                          borderBottom: '0.5px solid rgba(0, 0, 0, 0.5)',
                        }}
                      />
                      <td
                        style={{
                          borderBottom: '0.5px solid rgba(0, 0, 0, 0.5)',
                        }}
                      />
                      <td
                        style={{
                          borderBottom: '0.5px solid rgba(0, 0, 0, 0.5)',
                        }}
                      />
                    </tr>
                    <tr className='proposal-form-tabs_content-end_of_system__discounts-underline--second' />
                  </>
                ) : null}
                <tr
                  key={`${section.title}-${sectionIdx} ${itemsName}-${idx}`}
                  className='end-of-system-row rebates'
                >
                  {
                    <td
                      style={{
                        borderBottom: sectionTitleUnderline,
                        verticalAlign: 'middle',
                        paddingRight: isJustOneSectionAndDefaultTitle
                          ? 0
                          : '5px',
                        width: isJustOneSectionAndDefaultTitle ? 0 : 'auto',
                      }}
                    >
                      {sectionTitle ? (
                        <div
                          className={`${
                            isTitleOneLine
                              ? 'proposal-form-tabs_content-end_of_system__discounts__one-line'
                              : ''
                          }`}
                        >
                          <div className='proposal-form-tabs_content-end_of_system__discounts-title'>
                            {/* Section {sectionIdx === 0 ? '' : sectionIdx + 1} */}
                            {section.title}
                          </div>
                          <div className='proposal-form-tabs_content-end_of_system__discounts-subtitle'>
                            {/* {section.title} */}
                          </div>
                        </div>
                      ) : null}
                    </td>
                  }
                  <td
                    style={{
                      borderBottom: itemsTitleUnderline,
                      verticalAlign: 'middle',
                      paddingRight: '5px',
                      width: 'auto',
                    }}
                  >
                    {itemsTitle === 'Instants:' ? 'Discounts:' : itemsTitle}
                  </td>
                  <td
                    style={{
                      borderBottom: itemsUnderline,
                      verticalAlign: 'middle',
                      paddingRight: '5px',
                      width: 'auto',
                    }}
                  >
                    {item?.name}
                  </td>
                  <td
                    style={{
                      borderBottom: itemsUnderline,
                      paddingRight: '10px',
                      opacity: isExpired ? 0.5 : 1,
                      width: 'auto',
                    }}
                  >
                    {expireStr}
                  </td>
                  {props.showPrice && (
                    <td
                      style={{
                        borderBottom: itemsUnderline,
                        opacity: isExpired ? 0.5 : 1,
                        width: 'auto',
                      }}
                    >
                      {getPrice(section, item)}
                    </td>
                  )}
                </tr>
              </>,
            )
          })
      }
      if (section.discounts?.length && section.rebates?.length) {
        /* const instantsTitle = section.instants?.length > 1 ? 'Instants' : 'Instant'
      const rebatesTitle = section.instants?.length > 1 ? 'Rebates' : 'Rebate' */
        getItemsRows(
          section.discounts,
          'Instants',
          true,
          true,
          false,
          true,
          false,
        )
        getItemsRows(
          section.rebates,
          'Rebates',
          false,
          true,
          true,
          false,
          false,
        )
      } else if (section.discounts?.length) {
        getItemsRows(
          section.discounts,
          'Instants',
          true,
          true,
          true,
          true,
          true,
        )
      } else if (section.rebates?.length) {
        getItemsRows(section.rebates, 'Rebates', true, true, true, true, true)
      }
      return acc
    }, [])

  const isEqualToTotalToPay = () => {
    let result
    if (props.clientView)
      result =
        props.allTotalPrices?.totalAfterInstants !==
        props.allTotalPrices?.totalToPay
    else result = totalAfterDiscounts !== totalToPay
    // else result = totalAfter + rebatesPrice !== totalToPay
    return result
  }

  useEffect(() => {
    if (proposalStatus === 4) return

    // dispatch(setTotalAfterTax(round(totalAfter + rebatesPrice + feeSum)))
    dispatch(setTotalAfterTax(round(totalToPay)))
  }, [feeSum, totalAfterDiscounts, taxTotal])

  return (
    <>
      <div className='proposal-form-tabs_content-end_of_system print-element-nowrap'>
        {!props.clientView && props?.showPrice ? (
          <table className='proposal-form-tabs_content-end_of_system-subtotal_table'>
            <tbody>
              <tr>
                <td>Materials Total:</td>
                <td>{priceToView(totalMaterialsPrice)}</td>
              </tr>
              <tr>
                <td>Investment Total:</td>
                <td>{priceToView(totalInvestmentPrice)}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          !!isInvestmentTotal &&
          props?.showPrice && (
            <table className='proposal-form-tabs_content-end_of_system-subtotal_table'>
              <tbody>
                <tr>
                  <td>Investment Total:</td>
                  <td>
                    {props.clientView && !!props.allTotalPrices
                      ? priceToView(props.allTotalPrices?.investmentSubtotal)
                      : priceToView(totalInvestmentPrice)}
                  </td>
                </tr>
              </tbody>
            </table>
          )
        )}

        {!props.publicView ? (
          <div style={{ marginTop: 20 }}>
            <SectionDiscountAndRebates sections={props.sections} />
          </div>
        ) : (
          <>
            {!!props.sections?.length &&
              !!props.sections.some(
                section =>
                  !!section?.discounts?.length || !!section?.rebates?.length,
              ) && (
                <div className='proposal-form-tabs_content-end_of_system-discounts_table'>
                  <table>
                    <tbody>{items()}</tbody>
                  </table>
                </div>
              )}
          </>
        )}
      </div>

      {props?.showPrice && (
        <table className='proposal-form-tabs_content-end_of_system-total_table'>
          <tbody>
            {isThereRebates ? (
              <tr>
                <td>Total After Discounts and Rebates:</td>
                <td>
                  {props.clientView && !!props.allTotalPrices
                    ? priceToView(
                        props.allTotalPrices?.totalAfterRebatesAndInstants,
                      )
                    : priceToView(totalAfterDiscountsAndRebates)}
                </td>
              </tr>
            ) : null}
            {isThereDiscounts && !isEqualToTotalToPay() ? (
              <tr>
                <td>Total After Discounts:</td>
                <td>
                  {props.clientView && !!props.allTotalPrices
                    ? priceToView(props.allTotalPrices?.totalAfterInstants)
                    : priceToView(totalAfterDiscounts)}
                  {/* : priceToView(totalAfter + rebatesPrice)} */}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      )}
      {!props.clientView ? (
        props?.showPrice && (
          <>
            <Taxes disabled={isFreezed} tabId={props.currentTab.id} />
            <NewFees disabled={isFreezed} tabId={props.currentTab.id} />
          </>
        )
      ) : (
        <table className='proposal-form-tabs_content-end_of_system-tax-table'>
          {props?.showPrice && (
            <tbody>
              {!!projectTax && Number(projectTax.amount) > 0 && (
                <tr>
                  <td>{projectTax?.name}:</td>
                  <td>{priceToView(projectTax?.amount)}</td>
                </tr>
              )}
              {!!projectFees.length &&
                projectFees?.map(fee => (
                  <tr key={fee.id}>
                    <td>{fee.description}:</td>
                    <td>{priceToView(fee.price)}</td>
                  </tr>
                ))}
            </tbody>
          )}
        </table>
      )}
      {proposalStatus === 4 &&
        (!props.clientView && props?.showPrice ? (
          <CancellationFees />
        ) : (
          <table className='proposal-form-tabs_content-end_of_system-total_table'>
            <tbody>
              <tr>
                <td>Contract has been cancelled:</td>
                <td>{priceToView(-props.contractCancelled)}</td>
              </tr>
              <tr>
                <td>Cancellation fees:</td>
                <td>{props.cancellationfees}</td>
              </tr>
            </tbody>
          </table>
        ))}
      {props?.showPrice && (
        <table className='proposal-form-tabs_content-end_of_system-total_table'>
          <tbody>
            <tr>
              <td style={{ fontSize: 21 }}>Total:</td>
              <td style={{ fontSize: 21 }}>
                {props.clientView && !!props.allTotalPrices
                  ? priceToView(props.allTotalPrices?.totalToPay)
                  : priceToView(totalToPay)}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  )
}

export default EndOfSystem
