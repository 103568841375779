import { Tag } from 'antd'
import {
  useState,
  FC,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
} from 'react'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 10px;
  max-width: 316px;
  min-width: 230px;
  .table-date-picker {
    width: 100%;
  }
  .filter-buttons {
    margin: 10px 0 0;
    display: flex;
    justify-content: space-between;
  }
  .label {
    color: red;
    text-align: center;
  }

  .filter-values {
    margin: 10px 0 20px;

    & > p {
      font-weight: 600;
    }

    .atn-tag {
      display: inline-flex;
      align-items: center;
      margin-bottom: 3px !important;
      & span {
        margin-top: 1px;
      }
    }
  }

  hr {
    margin-bottom: 0;
  }
`

interface IFilterProps extends FilterDropdownProps {
  title: string
}

const errorMsg = {
  dublicate: 'This value is dublicate.',
}

const DefaultColumnFilter: FC<IFilterProps> = ({
  title,
  clearFilters,
  confirm,
  selectedKeys,
  setSelectedKeys,
  visible,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [error, setError] = useState<string>('')
  useEffect(() => {
    if (visible) inputRef?.current?.focus()
    if (!visible) inputRef.current.value = ''
  }, [visible])

  const handleSearch = () => {
    if (inputRef.current.value?.trim()) {
      setSelectedKeys([...selectedKeys, inputRef.current.value])
      inputRef.current.value = ''
    }
    confirm?.()
  }
  const handleReset = () => {
    clearFilters?.()
    confirm?.()
    setError('')
  }

  const onChangeInputHeandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (selectedKeys?.includes(value)) {
      return setError(errorMsg.dublicate)
    }
    setError('')
  }

  const onKeyDownHeandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (
        !inputRef?.current?.value?.trim() ||
        selectedKeys?.includes(inputRef?.current?.value.trim())
      )
        return
      setSelectedKeys([...selectedKeys, inputRef?.current?.value])
      inputRef.current.value = ''
    }
  }

  return (
    <Wrapper>
      <InputField
        ref={inputRef}
        placeholder={title || 'Search '}
        helperText={!error && 'Press Enter to add filter value'}
        onChange={onChangeInputHeandler}
        error={!!error}
        onKeyDown={onKeyDownHeandler}
      />
      {!!error && <div className='label'>{error}</div>}
      {!!selectedKeys?.length && (
        <div className='filter-values'>
          <p>Filtered values: </p>
          {selectedKeys?.map(key => (
            <Tag
              color='#108ee9'
              closable
              className='atn-tag'
              onClose={() => {
                setSelectedKeys(
                  selectedKeys?.filter(filteredKey => filteredKey !== key),
                )
              }}
              key={key}
            >
              {key}
            </Tag>
          ))}
        </div>
      )}
      <hr />
      <div className='filter-buttons'>
        <MainButton disabled={!!error} title='Search' onClick={handleSearch} />
        <MainButton title='Reset' type='cancel' onClick={handleReset} />
      </div>
    </Wrapper>
  )
}

export default DefaultColumnFilter
