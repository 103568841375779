import React from 'react'
import Tab from './Tab'

import './Tabs.scss'

interface Props {
  addTabLabel?: boolean
  children: JSX.Element[]
  currentTabLabel?: string
  disableAddNewTab?: boolean
  openedTab?: string
  onAddTab?: () => void
  onChangeTab?: (tab: string) => void
  noMarginAndPaddingOfTabContent?: {
    rule: boolean
    for: string
  }
  disabled: boolean
  resetTab?: boolean
}

const Tabs: React.FC<Props> = ({
  addTabLabel,
  children,
  currentTabLabel,
  disableAddNewTab,
  noMarginAndPaddingOfTabContent = {},
  onAddTab,
  onChangeTab,
  openedTab,
  resetTab,
}) => {
  const [activeTab, setActiveTab] = React.useState(
    openedTab ? openedTab : children[0] ? children[0].props?.label : '',
  )

  React.useEffect(() => {
    if (onChangeTab) {
      const tabs = children?.map(child => child?.props?.label)

      if (tabs.length) {
        if (tabs.includes('Tab Title')) {
          handleTabClick('Tab Title')
        } else if (!tabs.includes(activeTab)) {
          setActiveTab(currentTabLabel || children[0].props?.label)
        } else {
          return
        }
      }
    }
  }, [children])

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)

    onChangeTab?.(tab)
  }

  React.useEffect(() => {
    if (resetTab) {
      const active = openedTab
        ? openedTab
        : children[0]
        ? children[0].props?.label
        : ''

      handleTabClick(active)
    }
  }, [resetTab])

  return (
    <div className='tabss'>
      <ul className='tabss-list'>
        {children
          .filter(child => !!child && child.props?.label)
          .map(child => {
            const { label } = child.props

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                {...child.props}
                onClick={handleTabClick}
              />
            )
          })}
        {!!onAddTab && (
          <li
            className={`tabss-list-new${disableAddNewTab ? ' disabled' : ''}`}
            onClick={onAddTab}
          >
            {addTabLabel ? addTabLabel : '+'}
          </li>
        )}
      </ul>
      <div
        className={`tabss-content${
          noMarginAndPaddingOfTabContent.rule &&
          noMarginAndPaddingOfTabContent.for === activeTab
            ? ' tabss-content-no-margin_padding'
            : ''
        }`}
      >
        {children
          .filter(child => !!child && child.props?.label)
          .map(child => {
            if (child.props?.label !== activeTab) return undefined
            return child.props.children
          })}
      </div>
    </div>
  )
}

export default Tabs
