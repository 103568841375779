import { fromJS, isImmutable } from 'immutable'
import * as types from '../../types'

const initState = /* fromJS( */ {
  list: {
    data: [],
    limit: 10,
    page: 1,
    total: 0,
    total_pages: 0,
  },
  item: {},
  me: { info: {} },
  creditCard: {},
} /* ) */

export default (state = initState, action) => {
  //const userState = isImmutable(state) ? state : fromJS(state)

  switch (action.type) {
    case types.user.CHANGE_MATRIX_WEEK:
      return {
        ...state,
        me: {
          ...state.me,
          settings_matrix: {
            ...state.me.settings_matrix,
            matrix_week: action.payload,
          },
        },
      }
    case types.user.UPDATE_TOOLTIP_MODE: {
      return {
        ...state,
        me: {
          ...state.me,
          settings_matrix: {
            ...state.me.settings_matrix,
            tooltip_mod: action.payload,
          },
        },
      }
    }
    case types.user.USER_SET_LIST:
      //return userState.set('list', action.list).toJS()
      return { ...state, list: action.list }
    case types.user.USER_SET_ITEM:
      return { ...state, item: action.item }
    case types.user.USER_SET_PROPOSAL_TEMPLATE_ID:
      return {
        ...state,
        me: { ...state.me, proposal_template_id: action.templateId },
      }
    case types.user.USER_SET_ONBOARDED: {
      //just for acc managers
      const account_manager = state.me.account_manager
        ? state.me.account_manager
        : {}
      return {
        ...state,
        me: {
          ...state.me,
          account_manager: { ...account_manager, doc_signed: action.onboarded },
        },
      }
    }
    case types.user.USER_SET_ME:
      return {
        ...state,
        me: { ...state.me, ...action.me, role_id: +action.me.role_id },
      }
    case types.user.UPDATE_UNREAD_TASK_COUNTER:
      return {
        ...state,
        me: { ...state.me, count_unread_tasks: action.payload },
      }

    case types.user.USER_SET_CREDIT_CARD:
      return { ...state, creditCard: action.creditCard }

    case types.user.USER_ADD_INDUSTRY:
      return {
        ...state,
        me: {
          ...state.me,
          industries: [...state.me.industries, action.payload],
        },
      }

    case types.user.USER_REMOVE_INDUSTRY:
      return {
        ...state,
        me: {
          ...state.me,
          industries: state.me.industries.filter(
            industry => industry.id !== action.payload,
          ),
        },
      }

    case types.user.USER_SET_MERCHANT_STATUS:
      return {
        ...state,
        me: {
          ...state.me,
          merchant: {
            boardingProcessStatusCd: action.boardingProcessStatusCd,
            signatureUrl: action.signatureUrl,
          },
        },
      }
    case types.user.USER_DELETE_ITEM: {
      let array = state
      let user = array.find(arItem => arItem.id === action.item)
      let index = array.indexOf(user)
      array.splice(index, 1)
      return array
    }
    case types.user.UPDATE_WORK_TIME:
      return {
        ...state,
        me: {
          ...state.me,
          settings_matrix: {
            ...state.me.settings_matrix,
            ...action.payload,
          },
        },
      }
    default:
      return state
  }
}
