import { useState, useCallback } from 'react'
import {
  Row,
  Col,
  Checkbox,
  Button,
  Tooltip,
  Result,
  Space,
  Switch,
  Popconfirm,
  Select,
} from 'antd'
import './UserForm.scss'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import MainButton from '../../../../components/buttons/MainButton'
import {
  QuestionCircleOutlined,
  CopyOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import ReactInputMask from 'react-input-mask'
import SureModal from '../../calendar/SureModal/SureModal'
import UserPicture from './UserPicture/UserPicture'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Map from 'containers/MainContent/Orcatec/components/Map'
import InputField from '../../../../components/Input'

import { useSelector } from 'react-redux'
import SearchAddress from 'containers/MainContent/Orcatec/components/SearchAddress'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { IHeaderType } from 'layouts/MainLayout/Header/components/types'
import { selectModulePermissions } from 'features/Settings/UsersAndGroups/permissionSlice'

const mb1rem = { marginBottom: '1rem' }
const inputProps = {
  maxLength: 100,
}
const commissionTypeOptions = [
  { value: 1, title: 'Potential Profit' },
  { value: 2, title: 'Gross Sale' },
  { value: 3, title: 'Current Profit' },
]

const disabledAction = e => {
  if (e) {
    if (e.preventDefault) e.preventDefault()
    if (e.stopImmediatePropagation) e.stopImmediatePropagation()
  }
}

const UserForm = ({
  isSubmitting,
  userData,
  userDataErrors,
  onChangeTextField,
  handleChangeManualOfficeAddress,
  isThereCompanyAddress,
  isThereCompanyAddressDataChoosenAddress,
  onSubmit,
  onChangeRole,
  onChangeStatus,
  onChangePermissions,
  onChooseAddress,
  officeAddressData,
  onCancelChangePassword,
  togglePasswordVisibility,
  onUpdatePassword,
  isProfileSettings,
  // isMapFixingNeeded,
  onChangeUseOfficeAddress,
  onChangeUseAsTech,
  onChangeUseClockInWithGps,
  isChangePasswordOpen,
  setIsChangePasswordOpen,
  closeForm,
  // setPicturePreview,
  permissionsList,
  isNumberPrefixExists,
  setCroppedLogo,
  onCancelCrop,
  setLogo,
  isPasswordUpdating,
  profilePictureError,
  setProfilePictureError,
  currentStatus,
  //confirmation data when change status or use as tech
  confirmModal,
  confirmModalData,
  onCloseConfirmModal,
  onResetProposalIdentifier,
  onGenerateLink,
  inviteLink,
  // isNewUser = false,
  // isVerticalSidebar,
  // setIsVerticalSidebar,
  disabledModules,
  onChangeLayoutView,
  headerType,
  // onClearState,
}) => {
  // const [confirmDirty, setConfirmDirty] = useState(false)
  // const [newDirty, setNewDirty] = useState(false)
  const [copied, setCopied] = useState(false)

  const [isManualOfficeAddress] = useState(false)

  const { settings: isSettingsPermission } = useSelector(
    selectModulePermissions(),
  )
  const user = useSelector(state => state.orcatec.user.me)
  // const [headerSize, setHeaderSize] = useState(
  //   JSON.parse(localStorage.getItem(`header-view_${user?.id}`)) || false,
  // )
  // const company = useSelector(state => state.orcatec.company)
  // const userRoleID = useSelector(state => state.orcatec.user.me.role_id)
  const isUserSetings = window.location.pathname === '/settings/users'

  const onDeletePicture = useCallback(() => {
    setLogo('')
    setProfilePictureError('')
  }, [])

  const onCancel = useCallback(
    e => {
      e.preventDefault()
      setProfilePictureError(false)
      // setConfirmDirty(false)
      // setNewDirty(false)
      closeForm()
      // onClearState()
    },
    [closeForm],
  )

  const submit = useCallback(
    confirm => e => {
      e.preventDefault()
      setProfilePictureError(false)
      onSubmit(confirm)
    },
    [onSubmit],
  )

  const beforeUploadLogo = useCallback((_, props) => {
    const file = props[0]
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpeg'
    const isLt20M = file.size / 1024 / 1024 <= 20
    if (!isJpgOrPng) {
      setProfilePictureError('Please use jpg, jpeg or png file')
    } else if (!isLt20M) {
      setProfilePictureError('Image must be smaller than 20Mb')
    }
    return isJpgOrPng && isLt20M
  }, [])

  const customUploadRequest = useCallback(({ file }) => {
    setLogo(file)
  }, [])

  const toggleChangePasswordForm = useCallback(e => {
    e.preventDefault()
    setIsChangePasswordOpen(!isChangePasswordOpen)
  }, [])

  const handleMouseDownPassword = useCallback(event => {
    event.preventDefault()
  }, [])

  const clearSiteDataHeandle = () => {
    const message = 'Site data has been successfully cleared'
    const auth = localStorage.getItem('auth')
    const meId = localStorage.getItem(user.parent_id)
    localStorage.clear()
    localStorage.setItem('auth', auth)
    localStorage.setItem(user.parent_id, meId)
    openNotificationWithIcon('success', { message })
    location.reload()
  }

  const renderPhoneTextField = () => (
    <TextField
      id='user-phone'
      type='phone'
      label='Phone'
      name='phone'
      variant='outlined'
      error={!!userDataErrors.phone}
      helperText={userDataErrors.phone}
      fullWidth={true}
      size='small'
    />
  )

  const getPopupContainer = useCallback(
    () => document.getElementsByClassName('edit-user-settings-modal')[0],
    [],
  )

  return (
    <div className='user-form'>
      <UserPicture
        imageToCrop={userData.crop}
        profilePicture={userData.profile_picture}
        setCroppedLogo={setCroppedLogo}
        onCancelCrop={onCancelCrop}
        profilePictureError={profilePictureError}
        beforeUploadLogo={beforeUploadLogo}
        customUploadRequest={customUploadRequest}
        onDeletePicture={onDeletePicture}
      />
      <form noValidate autoComplete='off'>
        <TextField
          id='user-full_name'
          type='text'
          label='Full Name'
          name='full_name'
          value={userData.full_name}
          onChange={onChangeTextField}
          variant='outlined'
          error={!!userDataErrors.full_name}
          helperText={userDataErrors.full_name}
          fullWidth={true}
          inputProps={inputProps}
          size='small'
        />
        <TextField
          id='user-email'
          type='email'
          label='Email'
          name='email'
          value={userData.email}
          onChange={onChangeTextField}
          variant='outlined'
          error={!!userDataErrors.email}
          helperText={userDataErrors.email}
          fullWidth={true}
          size='small'
        />
        {userData.status === 2 && (
          <>
            {!inviteLink && (
              <MainButton
                style={{ marginBottom: '1rem' }}
                title='Generate invite link'
                onClick={onGenerateLink}
              />
            )}
            {inviteLink && (
              <div className={`invite-link${copied ? ' copied' : ''}`}>
                <TextField
                  style={{ paddingRight: '30px' }}
                  size='small'
                  variant='outlined'
                  value={inviteLink}
                  fullWidth
                />
                <CopyToClipboard
                  text={inviteLink}
                  onCopy={() => {
                    setCopied(true)
                    setTimeout(() => setCopied(false), 2000)
                  }}
                >
                  <CopyOutlined />
                </CopyToClipboard>
              </div>
            )}
          </>
        )}
        <ReactInputMask
          mask='(999) 999-9999'
          value={userData.phone}
          onChange={onChangeTextField}
          disabled={false}
          maskChar='X'
        >
          {renderPhoneTextField}
        </ReactInputMask>
        <Row>
          <Col sm={13} xs={24}>
            <TextField
              id='user-title'
              type='text'
              label='Title'
              name='title'
              placeholder='Title'
              value={userData.title}
              onChange={onChangeTextField}
              variant='outlined'
              error={!!userDataErrors.title}
              helperText={userDataErrors.title}
              fullWidth={true}
              inputProps={inputProps}
              size='small'
            />
          </Col>
          <Col
            sm={{ span: userDataErrors.number_prefix ? 8 : 10, offset: 1 }}
            xs={24}
          >
            <div style={{ position: 'relative' }}>
              <Tooltip
                getPopupContainer={getPopupContainer}
                placement='topRight'
                title={`This 2-3 letters are unique for the
                user and will be used as first
                letters for project number.`}
                overlayClassName='info-icon-tooltip'
              >
                <QuestionCircleOutlined
                  className='info-icon'
                  // style={{ position: 'absolute', right: 5, top: 6, width: 20, height: 20, zIndex: 1 }}
                />
              </Tooltip>
              <TextField
                id='user-number_prefix'
                type='text'
                label='Project Identifier'
                name='number_prefix'
                placeholder='e.g. AAA or AA'
                value={userData.number_prefix}
                // onChange={
                //   isNumberPrefixExists ? disabledAction : onChangeTextField
                // }
                onChange={onChangeTextField}
                variant='outlined'
                error={!!userDataErrors.number_prefix}
                helperText={userDataErrors.number_prefix}
                fullWidth={true}
                maxLength={3}
                size='small'
                // disabled={isNumberPrefixExists}
                className='user-number_prefix'
              />
            </div>
          </Col>
          {!!userDataErrors.number_prefix && (
            <Col sm={2} xs={2}>
              <div
                className='w-100 d-flex justify-content-center align-items-center'
                style={{ height: '40px' }}
              >
                <Tooltip
                  getPopupContainer={getPopupContainer}
                  title='Reset identifier'
                >
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={onResetProposalIdentifier}
                  >
                    <i className='mdi mdi-rotate-right mdi-18px' />
                  </span>
                </Tooltip>
              </div>
            </Col>
          )}
        </Row>
        {!isProfileSettings && (
          <Row>
            {/* <Col sm={13} xs={24}>
              <TextField
                id='user-role_id'
                type='text'
                select
                label='User Role'
                name='role_id'
                value={userData.role_id}
                onChange={onChangeRole}
                variant='outlined'
                error={!!userDataErrors.role_id}
                helperText={userDataErrors.role_id}
                fullWidth={true}
                size='small'
                className='user-role_id'
              >
                <MenuItem value={1} disabled hidden>
                  Owner
                </MenuItem>
                <MenuItem value={2}>Admin</MenuItem>
                <MenuItem value={3}>Dispatch</MenuItem>
                <MenuItem value={4}>Tech</MenuItem>
                <MenuItem value={5}>Worker</MenuItem>
                <MenuItem value={6}>Accountant</MenuItem>
              </TextField>
            </Col> */}

            <Col sm={{ span: 10, offset: 1 }} xs={24}>
              <TextField
                id='user-status'
                type='text'
                select
                label='Status'
                name='satus'
                value={userData.status}
                onChange={onChangeStatus}
                variant='outlined'
                error={!!userDataErrors.status}
                helperText={userDataErrors.status}
                fullWidth={true}
                size='small'
                className='user-status'
                // disabled={!!userData.token_confirmation || isNewUser}
                // disabled={userRoleID !== 1}
              >
                <MenuItem value={0}>Inactive</MenuItem>
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={2} disabled>
                  Pending
                </MenuItem>
              </TextField>
              {/* <div className='custom-select-outlined'> */}
              {/* <Select
                labelId='status-label'
                label='Status'
                id='user-status'
                value={userData.status}
                onChange={onChangeStatus}
                input={<Input variant='outlined' />}
                
                fullWidth
                disableUnderline
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={0}>Inactive</MenuItem>
              </Select> */}
              {/* </div> */}
            </Col>

            <Col sm={2} xs={0} />
          </Row>
        )}

        {!!isSettingsPermission && isUserSetings && (
          <Row gutter={20} Row>
            <Col sm={{ span: 8 }} xs={8}>
              <TextField
                id='user-status'
                type='text'
                label='Labor rate'
                name='labor_rate'
                value={userData?.labor_rate}
                onChange={onChangeTextField}
                variant='outlined'
                error={!!userDataErrors.labor_rate}
                helperText={userDataErrors.labor_rate}
                fullWidth={true}
                size='small'
                className='user-status'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {userData?.labor_rate ? '$' : ''}
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Col>
            <Col sm={{ span: 8 }} xs={8}>
              <InputField
                className='commission-type'
                label='Commission Type'
                name='commission_type'
                placeholder='Select Type'
                value={userData?.commission_type}
                onChange={onChangeTextField}
                error={!!userDataErrors?.commission_type}
                helperText={userDataErrors?.commission_type}
                select
              >
                {commissionTypeOptions.map((opt, i) => (
                  <MenuItem key={i} value={opt.value}>
                    {opt.title}
                  </MenuItem>
                ))}
              </InputField>
              {!userData?.commission_type && (
                <span className='commission-type_placeholder'>Select Type</span>
              )}
            </Col>
            <Col sm={{ span: 8 }} xs={8}>
              <InputField
                className='percentage'
                label='Commission'
                fullWidth={false}
                name='commission_percent'
                value={userData?.commission_percent}
                onChange={onChangeTextField}
                error={!!userDataErrors?.commission_percent}
                helperText={userDataErrors?.commission_percent}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>%</InputAdornment>
                  ),
                }}
              />
            </Col>
          </Row>
        )}

        {!!userData.change_date_status && (
          <div className='w-100 text-right'>{`${
            +currentStatus === 1 ? 'Active' : 'Inactive'
          } since ${userData.change_date_status}`}</div>
        )}

        {(!isProfileSettings || !!isSettingsPermission) && (
          <>
            <div style={mb1rem}>
              <Checkbox
                checked={userData.useAsTech}
                onChange={onChangeUseAsTech}
              >
                Display on Dispatch Board
              </Checkbox>
            </div>

            {!!isSettingsPermission && (
              <>
                <div style={mb1rem}>
                  <Checkbox
                    checked={userData.use_clock_in_with_gps}
                    name={'use_clock_in_with_gps'}
                    onChange={onChangeUseClockInWithGps}
                  >
                    Use Clock-in with GPS
                  </Checkbox>
                </div>
                {/* {!!isSettingsPermission && (
                  <div style={mb1rem}>
                    <Checkbox
                      checked={userData.restrict_contract_access}
                      name={'restrict_contract_access'}
                      onChange={onChangeUseClockInWithGps}
                    >
                      Restrict Contract Access
                    </Checkbox>
                  </div>
                )} */}
              </>
            )}
          </>
        )}
        {userData.id === user.id ? (
          <div className='use-vertical-sidebar'>
            Menu Layout
            <Tooltip title='Change menu orientation'>
              {'  '}
              <InfoCircleOutlined style={{ color: 'blue' }} />
            </Tooltip>
            <Select
              value={headerType}
              onChange={onChangeLayoutView}
              options={[
                {
                  value: IHeaderType.HEADER_HORIZONTAL,
                  label: 'Horizontal comfortable',
                },
                {
                  value: IHeaderType.HEADER_HORIZONTAL_MIN_WITH_TEXT,
                  label: 'Horizontal cozy ',
                },
                {
                  value: IHeaderType.HEADER_HORIZONTAL_MIN,
                  label: 'Horizontal compact',
                },
                { value: IHeaderType.HEADER_VERTICAL, label: 'Vertical ' },
              ]}
            />
            {/* <div style={{ marginTop: '10px' }}>
              <Space>
                Horizontal
                <Switch
                  style={{ backgroundColor: '#4c53ef' }}
                  checked={isVerticalSidebar}
                  onChange={checked => setIsVerticalSidebar(checked)}
                />{' '}
                Vertical
              </Space>
            </div> */}
          </div>
        ) : null}
        {/* {!isProfileSettings && (
          <div style={mb1rem} className='modules'>
            <label className='modules-label'>Modules</label>
            <Checkbox.Group
              value={userData.permissions}
              
              onChange={
                !userData.role_id ? disabledAction : onChangePermissions
              }
            >
              <Row>
                {Object.keys(permissionsList).map((item, idx) => (
                  <Col key={idx} xs={12} sm={7}>
                    <Tooltip
                      title={
                        disabledModules.includes(item)
                          ? 'Module is not enable in your subscription plan'
                          : ''
                      }
                    >
                      <Checkbox
                        value={item}
                        disabled={disabledModules.includes(item)}
                      >
                        {permissionsList[item]}
                      </Checkbox>
                    </Tooltip>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
        )} */}
        {isProfileSettings &&
          userData?.role_id === 1 &&
          !isThereCompanyAddress && (
            <>
              <div>
                {/* {!isManualOfficeAddress && ( */}
                <>
                  <SearchAddress
                    initialValue={officeAddressData.address.address ?? ''}
                    label='Office Location'
                    onChooseAddress={onChooseAddress('officeAddress')}
                    required
                    forPublicUse
                    publicView
                  />
                  {!!userDataErrors.officeAddress && (
                    <p
                      style={{
                        color: 'red',
                        fontSize: 12,
                        position: 'relative',
                        top: -10,
                      }}
                    >
                      {userDataErrors.officeAddress}
                    </p>
                  )}
                </>
                {/* )} */}

                {isManualOfficeAddress && (
                  <>
                    <InputField
                      value={officeAddressData.address.address ?? ''}
                      onChange={handleChangeManualOfficeAddress}
                      id='user-officeAddress'
                      label='Office Location'
                      name='officeAddress'
                    />
                    <div
                      className='manual-office-address'
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {['zip', 'city', 'state'].map((field, idx) => (
                        <InputField
                          style={{ width: '140px' }}
                          className={`input input-${field}`}
                          name={field.toLowerCase()}
                          key={idx}
                          label={
                            field === 'zip'
                              ? field.toUpperCase()
                              : field.charAt(0).toUpperCase() + field.slice(1)
                          }
                          value={officeAddressData.address?.[field] ?? ''}
                          onChange={handleChangeManualOfficeAddress}
                        />
                      ))}
                    </div>
                  </>
                )}

                <TextField
                  id='company-unit'
                  type='text'
                  label='Unit'
                  name='company-unit'
                  value={officeAddressData.address.unit}
                  onChange={onChangeTextField}
                  variant='outlined'
                  fullWidth={true}
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                className='company-user-map'
                id='companyUserMap'
                style={{ marginBottom: '1.5rem' }}
              >
                {/* <Map
                marker={[
                  +officeAddressData.address.lat,
                  +officeAddressData.address.lng,
                ]}
                setMarker={null}
                isMarkerDaggable={isManualOfficeAddress}
                onChangeMarkerPosition={e =>
                  handleChangeManualOfficeAddress({
                    target: { name: 'map', value: e },
                  })
                }
              /> */}
                <Map
                  marker={[
                    +officeAddressData.address.lat,
                    +officeAddressData.address.lng,
                  ]}
                  zoom={12}
                  isMarkerDaggable={false}
                  onChangeMarkerPosition={undefined}
                />
              </div>
            </>
          )}

        {/* {!!officeAddressData?.address?.address && ( */}
        <>
          <div className='use-start-location'>
            <Checkbox
              checked={userData.use_office_address}
              onChange={
                /*  isManualOfficeAddress
                    ? onChangeUseOfficeAddress
                    : !isThereCompanyAddress &&
                      !isThereCompanyAddressDataChoosenAddress
                    ? disabledAction
                    :  */ onChangeUseOfficeAddress
              }
              disabled={
                isManualOfficeAddress
                  ? false
                  : !isThereCompanyAddress &&
                    !isThereCompanyAddressDataChoosenAddress
              }
            >
              Company office is my start location
            </Checkbox>
          </div>
          <div>
            <>
              <SearchAddress
                initialValue={userData.address.address ?? ''}
                label='Day Start Location'
                onChooseAddress={onChooseAddress('userAddress')}
                disabled={userData.use_office_address}
                required
                forPublicUse
                publicView
              />
              {!!userDataErrors.address && (
                <p
                  style={{
                    color: 'red',
                    fontSize: 12,
                    position: 'relative',
                    top: -10,
                  }}
                >
                  {userDataErrors.address}
                </p>
              )}
            </>
            <TextField
              id='user-unit'
              type='text'
              label='Unit'
              name='unit'
              value={userData.address.unit}
              onChange={onChangeTextField}
              variant='outlined'
              fullWidth={true}
              size='small'
              disabled={userData.use_office_address}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div
            className='company-user-map'
            id='companyUserMap'
            style={{ marginBottom: '1.5rem' }}
          >
            <Map
              marker={[+userData.address.lat, +userData.address.lng]}
              zoom={12}
            />
          </div>
        </>
        {/* )} */}
      </form>
      {!isChangePasswordOpen ? (
        isProfileSettings && (
          <div className='d-flex justify-content-between'>
            <div
              className='change-password-link'
              onClick={toggleChangePasswordForm}
            >
              Change Password
            </div>
            <Popconfirm
              title='Are you sure you want to clear data?'
              onConfirm={clearSiteDataHeandle}
            >
              <MainButton
                title='Clear site data'
                type='delete'
                className='main-button'
              />
            </Popconfirm>
          </div>
        )
      ) : (
        <div className='user-change-password-form'>
          <div className='user-change-password-form-header'>
            Change Password
          </div>
          <div>
            <TextField
              id='user-old_password'
              type={userData.old_password_show ? 'text' : 'password'}
              label={'Current Password'}
              name='old_password'
              value={userData.old_password}
              onChange={onChangeTextField}
              variant='outlined'
              error={!!userDataErrors.old_password}
              helperText={userDataErrors.old_password}
              fullWidth={true}
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={togglePasswordVisibility('old_password')}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {userData.old_password_show ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoFocus={true}
            />
          </div>
          <div>
            <TextField
              id='user-password'
              type={userData.password_show ? 'text' : 'password'}
              label={'New Password'}
              name='password'
              value={userData.password}
              onChange={onChangeTextField}
              variant='outlined'
              error={!!userDataErrors.password}
              helperText={userDataErrors.password}
              fullWidth={true}
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={togglePasswordVisibility('password')}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {userData.password_show ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <TextField
              id='user-password_confirmation'
              type={userData.password_confirmation_show ? 'text' : 'password'}
              label={'Confirm Password'}
              name='password_confirmation'
              value={userData.password_confirmation}
              onChange={onChangeTextField}
              variant='outlined'
              error={!!userDataErrors.password_confirmation}
              helperText={userDataErrors.password_confirmation}
              fullWidth={true}
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={togglePasswordVisibility(
                        'password_confirmation',
                      )}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {userData.password_confirmation_show ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      )}
      <div
        className={
          'user-cgange-password-success-message' +
          (typeof isPasswordUpdating === 'string' ? ' show' : '')
        }
      >
        <Result status='success' title={isPasswordUpdating} />
      </div>

      <div className='d-flex justify-content-center'>
        {!isChangePasswordOpen ? (
          <>
            <MainButton
              title='Cancel'
              type='cancel'
              onClick={onCancel}
              className='mr-3'
            />
            <MainButton
              title='Save'
              onClick={submit()}
              className='main-button'
              isFetching={isSubmitting}
            />
          </>
        ) : (
          <>
            <Button
              variant='default'
              onClick={
                isPasswordUpdating ? disabledAction : onCancelChangePassword
              }
              className='main-button user-cancel-btn'
            >
              Cancel
            </Button>
            <MainButton
              title='Update Password'
              onClick={isPasswordUpdating ? disabledAction : onUpdatePassword}
            />
          </>
        )}
      </div>
      <SureModal
        modal={confirmModal}
        apply={submit('confirm')}
        body={`If you ${
          isProfileSettings ? '' : "will change user status to 'Inactive' or "
        }will uncheck 'Display on Dispatch Board' ${!!confirmModalData.scheduledAppointments &&
          confirmModalData.scheduledAppointments} appointment card${
          +confirmModalData.scheduledAppointments === 1 ? '' : 's'
        } will be transferred to queue. Are you sure you want to proceed?`}
        close={onCloseConfirmModal}
        isFetching={isSubmitting}
      />
    </div>
  )
}

export default UserForm
