import { memo, useEffect, useRef, useState } from 'react'
import { Popover, Tooltip, Spin } from 'antd'
import { withRouter } from 'react-router-dom'
import { getEventStatus } from './helpers'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { isMobileDevise } from 'helpers/isMobileDevice'
import { useDispatch } from 'react-redux'
import { setCurrentAppointmentId } from 'store/Orcatec/actions/appointment'
import classNames from 'classnames'

import '../style.scss'
import { getAmericanTime } from '../../../helpers'
import { useAppSelector } from 'store/Orcatec/hooks'
import { updateAppointmentAction } from 'features/Appointment/appointmentSlice'
import { selectContactSlice } from 'features/Contacts/slice/contactSlice'
import { selectModulePermissions } from 'features/Settings/UsersAndGroups/permissionSlice'

const popoverStyle = { height: '100%' }
const popoveroverlayStyle = { maxWidth: 500 }
const isMobileDevice = isMobileDevise()

const Event = memo(
  ({
    event,
    worker,
    minTime,
    currentAppointmentId,
    activeAppointment,
    isWorker,
    boxHeight,
    isSchedule,
    activeEvent,
    loading,
    eventType,
    onDeleteCreatedEvent,
    onCreateCreatedEvent,
  }) => {
    const [tooltipVisible, setTooltipVisible] = useState(
      eventType === 'created' && !event?.event_created_by,
    )
    const { call_tracking: isCallRecordsPerm } = useAppSelector(
      selectModulePermissions(),
    )
    const { showAppointmentForm, currentContact } = useAppSelector(
      selectContactSlice,
    )
    const dispatch = useDispatch()
    const eventRef = useRef()
    const tooltipRef = useRef()
    // const isNewlook = useSelector(state => state?.orcatec?.user?.me?.settings_matrix?.tooltip_mod) || false
    useEffect(() => {
      if (eventRef?.current && event?.event_created_by) {
        eventRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        })
      }
    }, [])

    const isJob = event.kind === 2
    // const appointmentMatrixType = 'appointments_created'

    const getHeight = event => {
      const endTime =
        event.current_end_time > event.current_start_time
          ? event.current_end_time
          : event.current_end_time + 24
      const difference = endTime - event.current_start_time
      if (difference === 1) {
        return boxHeight
      } else {
        return difference * (boxHeight + 5) - 5
      }
    }
    const getPosition = (event, worker, minTime) => {
      const hours = event.current_start_time - minTime
      const offset =
        worker.user_id === 'misc' ? (boxHeight < 40 ? 24.5 : 74.5) : 0
      return hours * (boxHeight + 5) + offset
    }

    const appointmentStyle = {
      top: `${getPosition(event, worker, minTime)}px`,
      height: `${getHeight(event)}px`,
      backgroundColor: `${'#b8d5ea'}`,
    }

    // const onResize = e => {
    //   const { className } = e.currentTarget
    //   const buttonType = className === 'first' ? 'start' : className === 'second' ? 'finish' : ''
    //   if (!buttonType) return
    //   startDragging(e, index, buttonType, appointmentMatrixType)
    // }

    // const onVisibleChange = visible => {
    //   handleVisibleChange(visible, event?.id)
    // }

    // const dragNDropDenied = isWorker || (isMobileDevice && activeEvent !== event.id)

    const handleHoverEvent = () => {
      if (!currentAppointmentId) return
      dispatch(setCurrentAppointmentId(null))
    }

    const setEventForCreateAppt = event => {
      const data = {
        matrix_time_start: event.current_start_time,
        matrix_time_end: event.current_end_time,
        column_template_id: event.column_template_id,
        date: event.date,
        time_start: event.time_start,
        time_end: event.time_end,
      }
      dispatch(updateAppointmentAction(data))
    }

    // useOnClickOutside(tooltipRef, () => {
    //   onDeleteCreatedEvent(event)
    // })
    return (
      <div
        // className={`event del-cross ${isSchedule && activeAppointment === event.id ? ' current' : ''}${
        //   isMobileDevice && activeEvent === event.id /*  && visible.visible */ ? ' show-resize' : ''
        // }${isWorker ? ' nodrag' : ''}${event.parent_id ? ' recurring' : ''}${isJob ? ' job' : ''}`}
        className={classNames(
          'event del-cross',
          isSchedule && activeAppointment === event.id ? ' current' : '',
          isMobileDevice && activeEvent === event.id ? ' show-resize' : '',
          isJob ? ' job' : '',
          isWorker ? ' nodrag' : '',
          event.parent_id ? ' recurring' : '',
          event?.event_created_by ? 'event-animation' : '',
        )}
        key={`event-${event.id}`}
        resourceid={worker.user_id}
        style={appointmentStyle}
        ref={eventRef}
      >
        <Tooltip
          getPopupContainer={triggerNode => triggerNode?.parentNode?.parentNode}
          defaultVisible={true}
          title={`${currentAppointmentId === event.id ? 'Current' : ''}`}
        >
          <div
            style={{ height: '100%', position: 'relative' }}
            onMouseOver={handleHoverEvent}
          >
            {loading ? (
              <Spin
                style={{ position: 'absolute', top: '36%', left: '39%' }}
                size='small'
              />
            ) : (
              <div className={`draggable-event ${getEventStatus(event)}`}>
                <Popover
                  visible={tooltipVisible}
                  content={
                    <div
                      ref={tooltipRef}
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        width: '110px',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <MainButton
                        onClick={() => {
                          onCreateCreatedEvent(event)
                          setTooltipVisible(false)
                        }}
                        title='Create New Appointment'
                      />
                      {!!isCallRecordsPerm && showAppointmentForm && (
                        <MainButton
                          onClick={() => {
                            setEventForCreateAppt(event)
                            setTooltipVisible(false)
                          }}
                          title={`Reserved for ${currentContact?.name}`}
                        />
                      )}

                      <MainButton
                        onClick={() => {
                          onDeleteCreatedEvent(event)
                        }}
                        type='cancel'
                        title='Cancel'
                        style={{ width: '100%' }}
                      />
                    </div>
                  }
                  placement='right'
                  style={popoverStyle}
                  overlayStyle={popoveroverlayStyle}
                >
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    className='event-time'
                  >
                    {event.time_start === event.time_end
                      ? event.time_start && getAmericanTime(event.time_start)
                      : `${event.time_start &&
                          getAmericanTime(
                            event.time_start,
                          )}${event.time_start &&
                          event.time_end &&
                          ' - '} ${event.time_end &&
                          getAmericanTime(event.time_end)}`}
                  </div>
                </Popover>
              </div>
            )}
          </div>
        </Tooltip>
      </div>
    )
  },
)

Event.displayName = 'Event'

const connectedEvent = withRouter(Event)
export { connectedEvent as Created }
