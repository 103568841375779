import React from 'react'

const MSDocRenderer = ({ state }) => {
  const { currentDocument } = state
  return (
    <div className='msdoc-renderer'>
      <iframe
        id='msdoc-iframe'
        title='msdoc-iframe'
        lang='EN'
        // sandbox='allow-same-origin'
        // crossOrigin='anonymous'
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${currentDocument?.source ||
          currentDocument?.linkToFile ||
          currentDocument}&ui=en-EN`}
        frameBorder='0'
      />
    </div>
  )
}

MSDocRenderer.fileTypes = 'msdoc'
MSDocRenderer.headerType = 'msdoc'

export default MSDocRenderer
