import styled from 'styled-components'

export const Wrapper = styled.div<{ edit?: boolean }>`
  pointer-events: ${props => (props.edit ? 'all' : 'none')};
  & fieldset,
  svg {
    visibility: ${props => (props.edit ? 'visible' : 'hidden')};
  }
`
export const Label = styled.p`
  color: rgba(0, 0, 0, 0.54);
`
