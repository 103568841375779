import React, { useState } from 'react'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import { default as Select } from 'containers/MainContent/Orcatec/components/Select'

import ReactInputMask from 'react-input-mask'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import './styles.scss'

const options = [
  {
    id: 'cell',
    name: 'Cell',
  },
  {
    id: 'home',
    name: 'Home',
  },
  {
    id: 'work',
    name: 'Work',
  },
  {
    id: 'other',
    name: 'Other',
  },
]

const StyledRadio = withStyles({
  root: {
    color: '#191919',
    padding: 0,
    '&$checked': {
      color: '#191919',
    },
  },
  checked: {},
})(props => <Radio {...props} />)

const StyledCheckbox = withStyles({
  root: {
    color: '#191919',
    padding: 0,
    '&$checked': {
      color: '#191919',
    },
  },
  checked: {},
})(props => <Checkbox {...props} />)

const ClientForm = ({
  type,
  client,
  onChange,
  publicAppointmentView = false,
  customPhoneTitle,
  errors = {},
  onBlurEmailValidation = () => {},
}) => {
  return (
    <div className='personal-information'>
      <InputField
        className={'full-name-input'}
        label={
          type === 2
            ? 'Full Name'
            : type === 1
            ? 'Company Name'
            : publicAppointmentView
            ? 'Name'
            : 'Contact Name'
        }
        onChange={onChange}
        name='name'
        value={client.name}
        required
        error={errors}
        helperText={errors?.name?.[0]}
        inputProps={{ maxLength: 255 }}
      />
      {type === 1 && (
        <>
          <InputField
            label='Website'
            onChange={onChange}
            name='website'
            value={client.website}
          />
          <InputField
            label='Industry'
            onChange={onChange}
            name='industry'
            value={client.industry}
            required
          />
        </>
      )}

      {type === 3 && (
        <InputField
          label='Title'
          onChange={onChange}
          name='contact_role'
          value={client.contact_role}
          required
        />
      )}

      <div className='phone-row'>
        <ReactInputMask
          mask='(999) 999-9999'
          value={
            publicAppointmentView ? client.phone : client.client_phone.phone
          }
          onChange={e => {
            onChange(e, 'client_phone')
          }}
          maskChar=''
          disabled={false}
        >
          {inputProps => (
            <InputField
              className={'phone-input'}
              {...inputProps}
              label={
                type === 2
                  ? 'Phone'
                  : publicAppointmentView
                  ? 'Phone'
                  : customPhoneTitle ?? 'Company Phone'
              }
              name='phone'
              required
              fullWidth={false}
              error={errors}
              helperText={errors?.phone?.[0]}
            />
          )}
        </ReactInputMask>

        {!publicAppointmentView && (
          <>
            <Select
              options={options}
              name='type'
              label='Type'
              value={client.client_phone.type}
              onChange={e => onChange(e, 'client_phone')}
              fullWidth={false}
              className='phone-type'
            />

            <RadioGroup
              aria-label='preference'
              name='preference'
              value={client.client_phone.preference}
              classes={{ root: 'radio-group' }}
            >
              <FormControlLabel
                // classes={{ root: 'voice', label: 'label' }}
                value='voice'
                control={
                  <StyledRadio onClick={e => onChange(e, 'client_phone')} />
                }
                label='Voice Preference'
                labelPlacement='top'
              />
              <FormControlLabel
                // classes={{ root: 'text' }}
                value='text'
                control={
                  <StyledRadio onClick={e => onChange(e, 'client_phone')} />
                }
                label='Text Preference'
                labelPlacement='top'
              />
            </RadioGroup>
          </>
        )}
      </div>

      <div className='email-row'>
        <InputField
          label='Email'
          onChange={e => onChange(e, 'client_email')}
          name='email'
          type='email'
          value={
            publicAppointmentView
              ? client.client_email
              : client.client_email.email
          }
          required={false}
          fullWidth={false}
          className='email-field'
          error={errors}
          helperText={errors?.email?.[0]}
          onBlur={e => onBlurEmailValidation(e.target.value)}
        />
        {!publicAppointmentView && (
          <div className='checkbox-group'>
            <FormControlLabel
              value={!!client.client_email.send_promo}
              name='send_promo'
              control={
                <StyledCheckbox
                  color='default'
                  // defaultChecked={client.client_email.send_promo}
                  onChange={e => onChange(e, 'client_email')}
                />
              }
              label='Send promo'
              labelPlacement='top'
            />
            <FormControlLabel
              value={!!client.client_email.send_reminder}
              name='send_reminder'
              control={
                <StyledCheckbox
                  color='primary'
                  // defaultChecked={client.client_email.send_reminder}
                  onChange={e => onChange(e, 'client_email')}
                />
              }
              label='Send service reminders'
              labelPlacement='top'
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ClientForm
