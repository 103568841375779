import { getEventSettings } from 'api/Appointment'
import { IEventSettings } from 'features/Settings/Dispatch/components/EventsTab/EventsTab'
import { useEffect, useState } from 'react'

const useEventSettings = (): IEventSettings => {
  const [settings, setSettings] = useState<IEventSettings>(null)

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await getEventSettings()

      setSettings(data)
    }

    fetchSettings()
  }, [])

  return settings
}

export default useEventSettings
