import React, { useState, useRef, useEffect } from 'react'
import { jsPDF } from 'jspdf'
import { useHistory } from 'react-router-dom'
import html2canvas from 'html2canvas'
import './style.scss'
import logo from './image/logo_soCall.png'
import { Modal, Input, Popconfirm, Spin } from 'antd'
import moment from 'moment-timezone'
import { downLoadFile } from 'helpers/downloadFile/downloadFile'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import styled from 'styled-components'
import {
  getForemanLogSheets,
  postForemanLogSheets,
  putForemanLogSheets,
} from 'api/Appointment'
import ReactInputMask from 'react-input-mask'
import { TextField } from '@material-ui/core'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useAppSelector } from 'store/Orcatec/hooks'
import classNames from 'classnames'
import { Column } from 'features/Dispatch/types'
import { IAppointment } from 'types/Appointment'
import { selectCurrentMatrixLog } from 'features/Dispatch/dispatchSlice'
import { IProperty } from '../AppointmentPreferredTech/types'
import { IContacts } from 'features/Contacts/types'
import { Job } from 'types/Jobs'
import { getProjectFWOSettings } from 'api/Project'

const { TextArea } = Input

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;
`
const qtyErrorText = 'Quantity must be a number'
const MATERIALS_CHECKED_OUT = 1
const MATERIALS_CHECKED_IN = 2
const BILLABLE_MATERIALS = 3
const EQUIPMENT_CHECK_OUT = 4
const initData = {
  appointment_id: null,
  customer_name: '',
  customer_relation: '',
  address: '',
  appointment_type: '',
  date: '',
  super_name: '',
  phone: '',
  preferred_tech: '',
  description: '',
  truck_number: '',
  truck_hours_onsite: '',
  pm: '',
  billed_by: '',
  fwo: '',
  foreman_work_break: [
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
  ],
  materials_checked_out: [
    {
      foreman_log_sheet_id: 1,
      type: MATERIALS_CHECKED_OUT,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 2,
      type: MATERIALS_CHECKED_OUT,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 3,
      type: MATERIALS_CHECKED_OUT,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 4,
      type: MATERIALS_CHECKED_OUT,
      note: '',
      qty: '',
    },
  ],
  materials_checked_in: [
    {
      foreman_log_sheet_id: 1,
      type: MATERIALS_CHECKED_IN,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 2,
      type: MATERIALS_CHECKED_IN,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 3,
      type: MATERIALS_CHECKED_IN,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 4,
      type: MATERIALS_CHECKED_IN,
      note: '',
      qty: '',
    },
  ],
  billable_materials: [
    {
      foreman_log_sheet_id: 1,
      type: BILLABLE_MATERIALS,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 2,
      type: BILLABLE_MATERIALS,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 3,
      type: BILLABLE_MATERIALS,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 4,
      type: BILLABLE_MATERIALS,
      note: '',
      qty: '',
    },
  ],
  equipment_check_out: [
    {
      foreman_log_sheet_id: 1,
      type: EQUIPMENT_CHECK_OUT,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 2,
      type: EQUIPMENT_CHECK_OUT,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 3,
      type: EQUIPMENT_CHECK_OUT,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 4,
      type: EQUIPMENT_CHECK_OUT,
      note: '',
      qty: '',
    },
  ],
}
const initError = {
  hasError: false,
  materials_checked_out: [
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
  ],
  materials_checked_in: [
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
  ],
  billable_materials: [
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
  ],
  equipment_check_out: [
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
    {
      qty: '',
    },
  ],
}
const bodyStyle = { width: '30cm', margin: '0 auto', padding: '0' }

interface Props {
  appointment: IAppointment | Job
  column: Column
  openModal: boolean
  onCloseModal: () => void
  entityName: string
  entity: IProperty
  entityData: IProperty | IContacts
}

const makeData = (
  appointment,
  entity,
  entityData,
  entityName,
  appointmentType,
  column,
) => {
  if (!!appointment?.proposal_info) {
    const primaryProperty = appointment?.proposal_info?.properties?.find(
      item => item?.is_primary,
    )

    // const primaryContact = appointment?.proposal_info?.clients?.find(
    //   item => item?.is_primary,
    // )

    const projectData = {
      appointment_id: appointment?.id ? appointment?.id : '',
      customer_name: appointment?.client_name || '',
      date: appointment?.matrix_date
        ? moment(appointment?.matrix_date).format('MM/DD/YYYY')
        : '',
      preferred_tech: appointment?.matrix_user || '',

      customer_relation: '',
      address: `${primaryProperty?.address ? primaryProperty?.address : ''}  ${
        primaryProperty?.city ? primaryProperty?.city : ''
      } ${primaryProperty?.state ? primaryProperty?.state : ''} ${
        primaryProperty?.postcode ? primaryProperty?.postcode : ''
      }`,
      appointment_type:
        appointmentType?.find(
          type => type?.id === appointment?.appointment_type_id,
        )?.name || '',
    }
    return projectData
  }

  if (!appointment?.proposal_info) {
    const ent = entity ? entity : entityData?.properties?.[0]
    const apptData = {
      appointment_id: appointment?.id ? appointment?.id : '',
      customer_name:
        appointment?.entity_type === 2 || appointment?.entity_type === 3
          ? entityName
          : appointment?.primary_entity_id
          ? ent?.clients?.find(c => c?.id === appointment?.primary_entity_id)
              ?.name
          : ent?.clients?.[0]?.name || '',
      customer_relation: appointment?.primary_entity_id
        ? ent?.clients?.find(c => c?.id === appointment?.primary_entity_id)
            ?.property_relation
        : ent?.clients?.[0]?.property_relation || '',
      address: `${ent?.address ? ent?.address : ''}  ${
        ent?.city ? ent?.city : ''
      } ${ent?.state ? ent?.state : ''} ${ent?.postcode ? ent?.postcode : ''}`,
      appointment_type:
        appointmentType?.find(
          type => type?.id === appointment?.appointment_type_id,
        )?.name || '',
      date: appointment?.date
        ? moment(appointment?.date).format('MM/DD/YYYY')
        : '',
      preferred_tech: column?.main_tech_name || '',
    }

    return apptData
  }
}

const AppointmentPrintPDF = ({
  appointment,
  column,
  openModal,
  onCloseModal,
  entity,
  entityData,
  entityName,
}: Props) => {
  const appointmentType = useAppSelector(state => state.orcatec.company.types)

  const matrixLog = useAppSelector(state =>
    selectCurrentMatrixLog(
      state,
      moment(appointment?.date || new Date()).format('MM/DD/YYYY'),
    ),
  )
  const getHelpers = (columnId: number) =>
    matrixLog?.columns?.[columnId]?.technicians?.filter(
      t => t?.user_id !== column?.main_tech_id,
    )
  const technician = getHelpers(appointment.column_template_id)

  const apptData = makeData(
    appointment,
    entity,
    entityData,
    entityName,
    appointmentType,
    column,
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(initError)
  const [data, setData] = useState(initData)
  const [edit, setEdit] = useState(false)
  const [forPrint, setForPrint] = useState(false)
  const ref = useRef()
  const history = useHistory()
  const pdfName = `date-${moment(data?.date || new Date()).format(
    'MM/DD/YYYY',
  ) || '-'}/foreman-${data?.preferred_tech || '-'}/customer_name${
    data?.customer_name
  }`
  const generatePDF = () => {
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    })
    const imgWidth = 210
    const imgHeight = 294
    // let blob = {}
    const pages = [ref?.current]
    return Promise.all(
      pages?.map(page => html2canvas(page, { scale: 2 })),
    ).then(imgPages => {
      imgPages.forEach((imgPage, idx, arr) => {
        const contentDataURL = imgPage.toDataURL('image/jpeg')
        pdf.addImage(contentDataURL, 'JPEG', 0, 0, imgWidth, imgHeight)
        if (idx !== arr?.length - 1) pdf.addPage()
      })

      return pdf.output('blob')
    })
  }

  const downloadPDF = file => {
    downLoadFile(file, `${pdfName}.pdf`)
  }
  const printPdf = () => {
    const pdfName = new Date().toISOString()
    const iframe = document.createElement('iframe')
    generatePDF().then(blob => {
      iframe.src = window.URL.createObjectURL(blob)
      iframe.name = pdfName
      iframe.id = 'print_frame'
      iframe.onload = () => {
        const pdfFrame = window.frames[pdfName]
        pdfFrame.focus()
        pdfFrame.print()
      }
      document.body.appendChild(iframe)
    })
  }

  const heandleDownloadDocument = async () => {
    setLoading(true)
    setForPrint(true)
    setTimeout(async () => {
      await generatePDF()
        .then(downloadPDF)
        .catch(error => console.error(error))
        .finally(() => {
          setLoading(false)
          setForPrint(false)
        })
    }, 100)
  }

  const heandlePrintDocument = () => {
    setLoading(true)
    setForPrint(true)
    generatePDF()
      .then(printPdf)
      .catch(error => console.error(error))
      .finally(() => {
        setLoading(false)
        setForPrint(false)
      })
  }

  const heandleSaveData = async () => {
    setLoading(true)
    await setForPrint(true)
    const dataForSave = {
      ...data,
      foreman_materials: [
        ...data.materials_checked_out,
        ...data.materials_checked_in,
        ...data.billable_materials,
        ...data.equipment_check_out,
      ],
    }
    delete dataForSave.materials_checked_out
    delete dataForSave.materials_checked_in
    delete dataForSave.billable_materials
    delete dataForSave.equipment_check_out
    const onSave = dataForSave?.id ? putForemanLogSheets : postForemanLogSheets

    return await onSave({ ...dataForSave })
      .then(res => {
        setData({ ...res.data })
        openNotificationWithIcon('success', {
          message: 'Data saved successfully.',
        })
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          history.push('/login')
        }
        openNotificationWithIcon('error', {
          message:
            error?.response?.data?.error?.message || 'Something went wrong',
        })
        setError(error)
      })
      .finally(() => {
        setLoading(false)
        setEdit(false)
        setForPrint(false)
      })
  }

  const heandleChangeData = (e, key, index) => {
    setEdit(true)
    const { name, value } = e.target
    const state = JSON.parse(JSON.stringify(data))
    const errors = JSON.parse(JSON.stringify(error))
    if (key) {
      if (name === 'qty') {
        if (!!value && !Number(value)) {
          !errors[key][index][name] &&
            openNotificationWithIcon('error', { message: qtyErrorText })
          errors[key][index][name] = qtyErrorText
          errors.hasError = true
        } else {
          errors[key][index][name] = ''
          errors.hasError = false
        }
      }
      state[key][index][name] = value
      setError(errors)
      return setData(state)
    }
    setData(prev => ({ ...prev, [name]: value }))
  }
  const col = idx => {
    switch (idx) {
      case 0:
        return (
          <td>
            <div className='pdf-field'>
              {forPrint ? (
                <>
                  <span style={{ lineHeight: '3.2' }}>
                    <span className='bold size16'>Truck#</span>
                  </span>
                  <span
                    style={{ paddingTop: '5px', paddingLeft: '5px' }}
                    className='bold semi_bigest__size'
                  >
                    {data.truck_number}
                  </span>
                </>
              ) : (
                <>
                  <span style={{ lineHeight: '2.4' }}>
                    <span className='bold size16'>Truck#</span>
                  </span>
                  <TextArea
                    onChange={heandleChangeData}
                    name='truck_number'
                    value={data.truck_number}
                    className='textarea_pdf widthFull semi_bigest__size'
                    maxLength={8}
                    style={{ height: '40px' }}
                  />
                </>
              )}
            </div>
          </td>
        )

      case 1:
        return (
          <td>
            <div className='pdf-field'>
              {forPrint ? (
                <>
                  <span style={{ lineHeight: 2.5 }}>
                    <span className='bold size16'>Truck Hours Onsite:</span>
                  </span>
                  <span
                    style={{ paddingTop: '5px', paddingLeft: '5px' }}
                    className='bold semi_bigest__size'
                  >
                    {data.truck_hours_onsite}
                  </span>
                </>
              ) : (
                <>
                  <span style={{ lineHeight: 1.2 }}>
                    <span className='bold size16'>Truck Hours Onsite:</span>
                  </span>
                  <TextArea
                    onChange={heandleChangeData}
                    name='truck_hours_onsite'
                    value={data.truck_hours_onsite}
                    className='textarea_pdf widthFull semi_bigest__size'
                    maxLength={6}
                    rows={1}
                    style={{ height: '40px', width: '70%' }}
                  />
                </>
              )}
            </div>
          </td>
        )

      case 2:
        return <td className='dark center bold'></td>

      case 3:
        return <td className='dark center bold'>Office Use Only</td>

      case 4:
        return (
          <tr className='intable'>
            <td style={{ width: '30%' }} className='dark bold size16 '>
              FWO
            </td>
            <td className='dark borderRightNone'>
              <div className='pdf-field'>
                {forPrint ? (
                  <span
                    style={{ width: '100px' }}
                    className='bold semi_bigest__size'
                  >
                    {data.fwo}
                  </span>
                ) : (
                  <TextArea
                    value={data.fwo}
                    name='fwo'
                    onChange={heandleChangeData}
                    maxLength={6}
                    rows={1}
                    className='textarea_pdf semi_bigest__size'
                  />
                )}
              </div>
            </td>
          </tr>
        )

      case 5:
        return (
          <tr className='intable'>
            <td style={{ width: '30%' }} className='dark bold size16 '>
              PM
            </td>
            <td className='dark borderRightNone'>
              <div className='pdf-field'>
                {forPrint ? (
                  <span
                    style={{ width: '100px' }}
                    className='bold semi_bigest__size'
                  >
                    {data.pm}
                  </span>
                ) : (
                  <TextArea
                    value={data.pm}
                    onChange={heandleChangeData}
                    name='pm'
                    maxLength={6}
                    rows={1}
                    className='textarea_pdf semi_bigest__size'
                  />
                )}
              </div>
            </td>
          </tr>
        )

      case 6:
        return (
          <tr className='intable'>
            <td
              style={{ width: '30%' }}
              className='dark bold size16 borderBottomNone '
            >
              Billed by{' '}
            </td>
            <td className='dark bold borderRightNone borderBottomNone'>
              {forPrint ? (
                <span
                  style={{ width: '100px' }}
                  className='bold semi_bigest__size '
                >
                  {data.billed_by}
                </span>
              ) : (
                <div className='pdf-field'>
                  <TextArea
                    value={data.billed_by}
                    onChange={heandleChangeData}
                    name='billed_by'
                    className='textarea_pdf widthFull semi_bigest__size'
                    maxLength={6}
                    rows={1}
                  />
                </div>
              )}
            </td>
          </tr>
        )

      default:
        break
    }
  }

  useEffect(() => {
    if (appointment?.id && openModal) {
      setLoading(true)
      getForemanLogSheets(appointment.id)
        .then(res => {
          if (Object.keys(res.data)?.length) {
            setData({
              ...res.data,
              ...apptData,
              foreman_work_break: JSON.parse(JSON.stringify(res.data))
                .foreman_work_break.sort((a, b) => a.id - b.id)
                .map((f, i) => {
                  const newObj = {
                    ...f,
                    full_name:
                      i === 0
                        ? column?.main_tech_name
                        : technician?.[i - 1]?.name || '',
                  }
                  return newObj
                }),
              preferred_tech: column?.main_tech_name,
              appointment_type: res?.data?.appointment_type
                ? res.data.appointment_type
                : apptData.appointment_type
                ? apptData.appointment_type
                : '',
            })
          } else {
            setData({
              ...initData,
              ...apptData,
              foreman_work_break: JSON.parse(
                JSON.stringify(initData),
              ).foreman_work_break.map((f, i) => {
                const newObj = {
                  ...f,
                  full_name:
                    i === 0
                      ? column?.main_tech_name || ''
                      : technician?.[i - 1]?.name || '',
                }
                return newObj
              }),
            })
          }
        })
        .then(async () => {
          if (!!appointment?.proposal_info) {
            const { work_order } = await getProjectFWOSettings(
              appointment?.proposal_info?.id,
              appointment?.proposal_info?.company.id,
            )
            setData(prev => ({
              ...prev,
              customer_relation: work_order?.project,
            }))
          }
        })
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    }
  }, [openModal, appointment?.id])

  useEffect(() => {
    if (!openModal) {
      setData(initData)
      setError(initError)
    }
  }, [openModal])

  const actions = (
    <ButtonWrapper key={'wrapper'}>
      <ButtonWrapper style={{ border: '1px solid gray', borderRadius: '5px' }}>
        {edit && !error.hasError ? (
          <Popconfirm
            title='You have no saved data. Save before closing?'
            onConfirm={() => {
              heandleSaveData().then(() => {
                onCloseModal()
                setError({ ...initError, hasError: false })
                setData(initData)
              })
            }}
            onCancel={() => {
              onCloseModal()
              setError({ ...initError, hasError: false })
              setData(initData)
              setEdit(false)
            }}
            okText='Yes'
            cancelText='No'
            disabled={!edit}
          >
            <MainButton
              type='cencel'
              isFetching={loading}
              key={'Close'}
              title='Close'
            />
          </Popconfirm>
        ) : (
          <MainButton
            type='cencel'
            onClick={() => {
              onCloseModal()
              setError({ ...initError, hasError: false })
              setData(initData)
            }}
            isFetching={loading}
            key={'Close'}
            title='Close'
          />
        )}
      </ButtonWrapper>
      <MainButton
        disabled={error.hasError}
        isFetching={loading}
        onClick={heandleDownloadDocument}
        key={'Save'}
        title='Save as PDF'
      />

      <MainButton
        disabled={error.hasError}
        isFetching={loading}
        onClick={heandlePrintDocument}
        key={'Print'}
        title='Print'
      />
      <MainButton
        disabled={error.hasError}
        isFetching={loading}
        onClick={heandleSaveData}
        key={'Save sheet'}
        title='Save Sheet'
      />
    </ButtonWrapper>
  )

  return (
    <Modal
      bodyStyle={{ ...bodyStyle }}
      maskStyle={{ backgroundColor: 'gray' }}
      className='modal-pdf'
      visible={openModal}
      onCancel={onCloseModal}
      closable={false}
      maskClosable={false}
      title={actions}
      footer={[actions]}
      destroyOnClose={true}
    >
      <div ref={ref} className='pdf-wrapper'>
        <Spin spinning={loading && !forPrint}>
          <div className='pdf-image__wrapper'>
            <img className='pdf-logo' src={logo} alt='pdf' />
            <p className='company-contact'>
              800-834-8682 951-277-2303 Fax. 12620 Bosley Lane Corona. CA 92883
            </p>
            <p className='company-contact__series'>
              CSLB #632882-A DIR #1000007012
            </p>
          </div>
          <div className='table-head'>
            <div className='table-head__text'>Daily Dispatch Log Sheet</div>
            <div className='table-responsive date-table'>
              <table>
                <tbody>
                  <tr style={{ width: '300px', marginLeft: 'auto' }}>
                    <td>
                      <span style={{ padding: '0 6px' }} className='bold'>
                        M
                      </span>
                      <span style={{ padding: '0 6px' }} className='bold'>
                        T
                      </span>
                      <span style={{ padding: '0 6px' }} className='bold'>
                        W
                      </span>
                      <span style={{ padding: '0 6px' }} className='bold'>
                        TH
                      </span>
                      <span style={{ padding: '0 6px' }} className='bold'>
                        F
                      </span>
                      <span style={{ padding: '0 6px' }} className='bold'>
                        S
                      </span>
                      <span style={{ padding: '0 6px' }} className='bold'>
                        SUN
                      </span>
                    </td>
                    <td style={{ width: '300px', marginLeft: 'auto' }}>
                      <span className='bold'>Date:</span>{' '}
                      <span className='bold bigest__size'>{data.date}</span>{' '}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='table-wrapper'>
            <div className='table-responsive heading-table'>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span className='bold'>Customer:</span>{' '}
                      <span className='bold bigest__size'>
                        {data.customer_name}
                      </span>{' '}
                    </td>
                    <td>
                      <div className='pdf-field'>
                        {forPrint ? (
                          <>
                            <span
                              style={{
                                width: '24%',
                                lineHeight: 1.1,
                                paddingTop: '8px',
                              }}
                            >
                              <span className='bold'>Super Name:</span>
                            </span>
                            <span
                              style={{ paddingTop: '10px' }}
                              className='bold bigest__size'
                            >
                              {data.super_name}
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              style={{
                                width: '24%',
                                lineHeight: 1.1,
                                paddingTop: '16px',
                              }}
                            >
                              <span className='bold'>Super Name:</span>
                            </span>
                            <TextArea
                              onChange={heandleChangeData}
                              name='super_name'
                              value={data.super_name}
                              className='textarea_pdf widthFull bigest__size bigest__height'
                              maxLength={15}
                              rows={1}
                              style={{ lineHeight: 2.1 }}
                            />
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className='bold'>Project: </span>
                      <span className='bold bigest__size'>
                        {data.customer_relation}
                      </span>{' '}
                    </td>
                    <td>
                      <div className='pdf-field'>
                        <span style={{ width: '30%', lineHeight: 3.2 }}>
                          <span className='bold'>Phone# :</span>{' '}
                        </span>
                        {forPrint ? (
                          <span className='bold bigest__size'>
                            {data.phone}{' '}
                          </span>
                        ) : (
                          <ReactInputMask
                            mask={'(999)-999-9999'}
                            value={data.phone ? data.phone : ''}
                            onChange={heandleChangeData}
                            maskChar='X'
                            name='phone'
                            className='textarea_pdf widthFull bigest__height'
                          >
                            {() => (
                              <TextField
                                type='text'
                                variant='outlined'
                                fullWidth={true}
                                size='small'
                                id={'phone'}
                                name={'phone'}
                                className='textarea_pdf widthFull bigest__height'
                              />
                            )}
                          </ReactInputMask>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className='bold'>Tract #/Location:</span>{' '}
                      <span className='bold bigest__size'>{data.address}</span>
                    </td>
                    <td>
                      <span className='bold'>Foreman:</span>{' '}
                      <span className='bold bigest__size'>
                        {data.preferred_tech}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan='2'>
                      {forPrint ? (
                        <>
                          <span className='bold'> Work Description: </span>
                          <span
                            style={{ paddingTop: '10px' }}
                            className='bigest__size bold'
                          >
                            {data.appointment_type}
                          </span>{' '}
                        </>
                      ) : (
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '150px 1fr',
                            height: '40px',
                          }}
                        >
                          <span className='bold'> Work Description: </span>
                          <TextArea
                            value={data.appointment_type}
                            onChange={heandleChangeData}
                            name='appointment_type'
                            className='textarea_pdf widthFull  bigest__size'
                            maxLength={50}
                            // rows={2}
                            style={{ lineHeight: '0.8' }}
                          />
                        </div>
                      )}
                      {/* </td> */}
                    </td>
                  </tr>
                  <tr>
                    <td colspan='2'>
                      {forPrint ? (
                        <span
                          style={{
                            wordBreak: 'break-all',
                          }}
                          className='bold bigest__size'
                        >
                          {data?.description.length > 170
                            ? data?.description.slice(0, 170) + '...'
                            : data?.description}
                        </span>
                      ) : (
                        <TextArea
                          value={data?.description}
                          onBlur={e => {
                            e.target.scrollTop = 0
                          }}
                          name='description'
                          onChange={heandleChangeData}
                          className='textarea_pdf widthFull  bigest__size'
                          maxLength={300}
                          style={{
                            height: '120px !important',
                            resize: 'none',
                            overflow: 'hidden',
                            wordBreak: 'break-all',
                          }}
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className='description'
                      style={{ fontSize: '30px' }}
                      colspan='2'
                    >
                      ALL JOBS REQUIRE PPE
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive  work_break_wraper'>
              <table>
                <thead>
                  <th>Emp. #Full Name</th>
                  <th>Start</th>
                  <th>Lunch Out</th>
                  <th>Lunch In</th>
                  <th>Finish</th>
                  <th>Total</th>
                  <th>Vehicle Information</th>
                </thead>
                <tbody>
                  {data?.foreman_work_break
                    ?.sort((a, b) => a?.id - b?.id)
                    .map((el, idx) => (
                      <tr key={idx}>
                        <td width={'30%'}>
                          {/* {idx === 0 ? (
                              <span style={{ paddingLeft: '10px' }} className='bold'>
                                {data.preferred_tech}
                              </span>
                            ) : ( */}
                          <div className='pdf-field'>
                            <TextArea
                              onChange={e =>
                                heandleChangeData(e, 'foreman_work_break', idx)
                              }
                              name='full_name'
                              value={data.foreman_work_break[idx].full_name}
                              className='textarea_pdf widthFull'
                              maxLength={34}
                              rows={1}
                            />
                          </div>
                          {/* )} */}
                        </td>
                        <td width={'10%'}>
                          <div className='pdf-field'>
                            <TextArea
                              onChange={e =>
                                heandleChangeData(e, 'foreman_work_break', idx)
                              }
                              name='start'
                              value={data.foreman_work_break[idx].start}
                              className='textarea_pdf widthFull'
                              maxLength={7}
                              rows={1}
                            />
                          </div>
                        </td>
                        <td width={'10%'}>
                          <div className='pdf-field'>
                            <TextArea
                              onChange={e =>
                                heandleChangeData(e, 'foreman_work_break', idx)
                              }
                              name='lunch_out'
                              value={data.foreman_work_break[idx].lunch_out}
                              className='textarea_pdf widthFull'
                              maxLength={7}
                              rows={1}
                            />
                          </div>
                        </td>
                        <td width={'10%'}>
                          <div className='pdf-field'>
                            <TextArea
                              onChange={e =>
                                heandleChangeData(e, 'foreman_work_break', idx)
                              }
                              name='lunch_in'
                              value={data.foreman_work_break[idx].lunch_in}
                              className='textarea_pdf widthFull'
                              maxLength={7}
                              rows={1}
                            />
                          </div>
                        </td>
                        <td width={'10%'}>
                          <div className='pdf-field'>
                            <TextArea
                              onChange={e =>
                                heandleChangeData(e, 'foreman_work_break', idx)
                              }
                              name='finish'
                              value={data.foreman_work_break[idx].finish}
                              className='textarea_pdf widthFull'
                              maxLength={7}
                              rows={1}
                            />
                          </div>
                        </td>
                        <td width={'10%'}>
                          <div className='pdf-field'>
                            <TextArea
                              onChange={e =>
                                heandleChangeData(e, 'foreman_work_break', idx)
                              }
                              name='total'
                              value={data.foreman_work_break[idx].total}
                              className='textarea_pdf widthFull'
                              maxLength={7}
                              rows={1}
                            />
                          </div>
                        </td>
                        {col(idx)}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className='bottom-table'>
              <div className='left-table'>
                <div className='table-responsive'>
                  <table>
                    <thead>
                      <th>Materials Checked Out</th>
                      <th>Quantity</th>
                    </thead>
                    <tbody>
                      {data?.materials_checked_out
                        ?.sort((a, b) => a?.id - b?.id)
                        .map((el, idx) => (
                          <tr key={idx}>
                            <td>
                              <div className='pdf-field'>
                                <TextArea
                                  onChange={e =>
                                    heandleChangeData(
                                      e,
                                      'materials_checked_out',
                                      idx,
                                    )
                                  }
                                  name='note'
                                  value={data.materials_checked_out[idx].note}
                                  className='textarea_pdf widthFull'
                                  maxLength={45}
                                  rows={1}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='pdf-field'>
                                <TextArea
                                  onChange={e =>
                                    heandleChangeData(
                                      e,
                                      'materials_checked_out',
                                      idx,
                                    )
                                  }
                                  name='qty'
                                  value={data.materials_checked_out[idx].qty}
                                  className={classNames(
                                    'textarea_pdf',
                                    'widthFull',
                                    !!error?.materials_checked_out?.[idx]
                                      ?.qty && error?.hasError
                                      ? 'error_field'
                                      : '',
                                  )}
                                  maxLength={5}
                                  rows={1}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className='table-responsive'>
                  <table>
                    <thead>
                      <th>Materials Checked In</th>
                      <th>Quantity</th>
                    </thead>
                    <tbody>
                      {data.materials_checked_in
                        ?.sort((a, b) => a?.id - b?.id)
                        .map((el, idx) => (
                          <tr key={idx}>
                            <td>
                              <div className='pdf-field'>
                                <TextArea
                                  onChange={e =>
                                    heandleChangeData(
                                      e,
                                      'materials_checked_in',
                                      idx,
                                    )
                                  }
                                  name='note'
                                  value={data.materials_checked_in[idx].note}
                                  className='textarea_pdf widthFull'
                                  maxLength={45}
                                  rows={1}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='pdf-field'>
                                <TextArea
                                  onChange={e =>
                                    heandleChangeData(
                                      e,
                                      'materials_checked_in',
                                      idx,
                                    )
                                  }
                                  name='qty'
                                  value={data.materials_checked_in[idx].qty}
                                  className={classNames(
                                    'textarea_pdf',
                                    'widthFull',
                                    !!error?.materials_checked_in?.[idx]?.qty &&
                                      error?.hasError
                                      ? 'error_field'
                                      : '',
                                  )}
                                  maxLength={5}
                                  rows={1}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='right-table'>
                <div className='table-responsive'>
                  <table>
                    <thead>
                      <th>Billable Materials</th>
                      <th>Quantity</th>
                    </thead>
                    <tbody>
                      {data.billable_materials
                        ?.sort((a, b) => a?.id - b?.id)
                        .map((el, idx) => (
                          <tr key={idx}>
                            <td>
                              <div className='pdf-field'>
                                <TextArea
                                  onChange={e =>
                                    heandleChangeData(
                                      e,
                                      'billable_materials',
                                      idx,
                                    )
                                  }
                                  name='note'
                                  value={data.billable_materials[idx].note}
                                  className='textarea_pdf widthFull'
                                  maxLength={45}
                                  rows={1}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='pdf-field'>
                                <TextArea
                                  onChange={e =>
                                    heandleChangeData(
                                      e,
                                      'billable_materials',
                                      idx,
                                    )
                                  }
                                  name='qty'
                                  value={data.billable_materials[idx].qty}
                                  className={classNames(
                                    'textarea_pdf',
                                    'widthFull',
                                    !!error?.billable_materials?.[idx]?.qty &&
                                      error?.hasError
                                      ? 'error_field'
                                      : '',
                                  )}
                                  maxLength={5}
                                  rows={1}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className='table-responsive'>
                  <table>
                    <thead>
                      <th>Equipment Check Out</th>
                      <th>Quantity</th>
                    </thead>
                    <tbody>
                      {data.equipment_check_out
                        ?.sort((a, b) => a?.id - b?.id)
                        .map((el, idx) => (
                          <tr key={idx}>
                            <td>
                              <div className='pdf-field'>
                                <TextArea
                                  onChange={e =>
                                    heandleChangeData(
                                      e,
                                      'equipment_check_out',
                                      idx,
                                    )
                                  }
                                  name='note'
                                  value={data.equipment_check_out[idx].note}
                                  className='textarea_pdf widthFull'
                                  maxLength={45}
                                  rows={1}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='pdf-field'>
                                <TextArea
                                  onChange={e =>
                                    heandleChangeData(
                                      e,
                                      'equipment_check_out',
                                      idx,
                                    )
                                  }
                                  name='qty'
                                  value={data?.equipment_check_out[idx]?.qty}
                                  className={classNames(
                                    'textarea_pdf',
                                    'widthFull',
                                    !!error?.equipment_check_out?.[idx]?.qty &&
                                      error?.hasError
                                      ? 'error_field'
                                      : '',
                                  )}
                                  maxLength={5}
                                  rows={1}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Modal>
  )
}

export default AppointmentPrintPDF
