import { ActionType } from '../constants/commonConstants'
import { combineReducers } from 'redux'

const { TOGGLE_DRAWER } = ActionType

const drawerInitialState = true

const drawerReducer = (state = drawerInitialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_DRAWER:
      return payload
    default:
      return state
  }
}

export default combineReducers({
  isOpenDrawer: drawerReducer,
})
