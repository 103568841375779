import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Badge } from 'antd'

interface IProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  isOpen: boolean
  counter: number
}

export const CallsBar: FC<IProps> = ({ onClick, isOpen, counter }) => {
  const [cls, setCls] = useState('pulse')
  const delay = 5000

  useEffect(() => {
    let timeout = null
    if (counter) {
      setCls('pulse active')
    } else {
      timeout = setTimeout(() => setCls('pulse'), delay)
    }
    return () => clearTimeout(timeout)
  }, [counter])
  return (
    <Wrapper isOpen={isOpen}>
      <Badge count={counter}>
        <IconWrapper onClick={onClick} className='content-center'>
          <div className={cls}>
            <i className='fas fa-phone fa-2x'></i>
          </div>
        </IconWrapper>
      </Badge>
    </Wrapper>
  )
}

const Wrapper = styled.nav<{ isOpen: boolean }>`
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  display: ${props => (props.isOpen ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  /* overflow: hidden; */
  border-radius: 50%;
  transition: all 0.3s;
  width: 40px;
  height: 40px;
  cursor: pointer;
`

const IconWrapper = styled.div<{
  onClick: (e: React.MouseEvent<HTMLElement>) => void
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 30px;
  width: 30px;
  margin-left: 6px;
  margin-top: 6px;

  .pulse i {
    color: #fff;
    font-size: 20px;
  }

  .pulse {
    height: 30px;
    width: 30px;
    background-color: var(--main-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .active.pulse::before {
    content: '';
    position: absolute;
    border: 1px solid var(--main-color);
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    border-radius: 50%;
    animation: pulse 1s linear infinite;
  }

  .active.pulse::after {
    content: '';
    position: absolute;
    border: 1px solid var(--main-color);
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    border-radius: 50%;
    animation: pulse 1s linear infinite;
    animation-delay: 0.3s;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }

    50% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(1.3);
      opacity: 0;
    }
  }
`
