import { Checkbox, Radio } from 'antd'
import styled from 'styled-components'

export const ItemWrapper = styled.div<{ inactive?: boolean }>`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  padding: 5px 5px 5px 30px;
  font-size: 12px;
  color: ${props => (props.inactive ? '#aeadad' : 'inherit')};
`
export const Title = styled.p`
  font-weight: 500;
  white-space: pre-wrap;
  font-size: 14px;
`
export const Description = styled.p<{ expired?: boolean }>`
  white-space: pre-wrap;
  color: ${props => (props.expired ? '#ef9494' : 'inherit')};
`
export const AdditionalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`
export const PriceSection = styled.div`
  display: grid;
  grid-template-columns: 85px 90px 70px 90px;
`
export const Diff = styled.p``
export const Price = styled.p`
  opacity: 0.5;
`
export const Qty = styled.p``
export const Total = styled.p`
  font-weight: 500;
`

export const Content = styled.div`
  margin-left: 5px;
`

export const AttachmentsWrapper = styled.div`
  max-width: 250px;
  width: 100%;
`

export const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  left: 5px;
  top: 10px;
`
export const StyledRadio = styled(Radio)`
  position: absolute;
  left: 5px;
  top: 10px;
`
