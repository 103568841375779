import axiosOrcatec from './axiosInstance'
import { getQueryStringFromObject } from '../helpers/getQueryStringFromObject'

/**
 * Fetch organizations list.
 *
 * @return {*}
 */
export const fetchOrganizationsList = (props, searchBy = '', sort = '') => {
  const params = getQueryStringFromObject(props)

  return axiosOrcatec
    .get(`organizations?${params ? `&${params}` : ''}${searchBy ? `&search_by=${searchBy}` : ''}${sort ? sort : ''}`)
    .then(response => response.data)
}

export const searchOrganizations = props => {
  const params = getQueryStringFromObject(props)
  return axiosOrcatec.get(`organizations/search${params ? `?${params}` : ''}`).then(response => response.data)
}

/**
 * Fetch organization.
 *
 * @param id
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const getOrganizationById = id => {
  return axiosOrcatec.get(`organizations/${id}`).then(response => response.data)
}

/**
 * Merge organizations.
 *
 * @param id
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const mergeOrganizations = data => axiosOrcatec.post(`organizations/merge`, data).then(({ data }) => data)

/**
 * Create organization.
 *
 * @param data
 * @return {*}
 */
export const createOrganization = data => {
  return axiosOrcatec.post('organizations', data).then(response => response.data)
}

/**
 * Update organization.
 *
 * @param id
 * @param data
 * @return {Promise<T | never>}
 */
export const updateOrganization = (id, data) => {
  return axiosOrcatec.put(`organizations/${id}`, data).then(response => response.data)
}

/**
 * Attach contact.
 *
 * @param id
 * @param data
 * @return {Promise<T | never>}
 */
export const attachContact = (orgId, clientId, relation) => {
  const data = { client_id: clientId, contact_role: relation }
  return axiosOrcatec.put(`organizations/attach_contact/${orgId}`, data).then(response => response.data)
}

export const detachContacts = (organizationId, data) => {
  return axiosOrcatec.put(`/organizations/detach_contact/${organizationId}`, data).then(response => response.data)
}

/**
 * Delete organization.
 *
 * @param id
 * @return {*}
 */
export const deleteOrganization = id => {
  return axiosOrcatec.delete(`organizations/${id}`).then(response => response.data)
}

export const createRelationProperty = data => {
  return axiosOrcatec.post(`/organizations-properties`, data).then(response => response.data)
}
export const createOrganizationAssociation = data => {
  return axiosOrcatec.post(`/organizations-associations`, data).then(response => response.data)
}
export const dellAttachedProperty = id => {
  return axiosOrcatec.delete(`/organizations-properties/${id}`).then(response => response.data)
}
export const dellOrganizationAssociation = id => {
  return axiosOrcatec.delete(`/organizations-associations/${id}`).then(response => response.data)
}
export const dellOrganizationPhone = id => {
  return axiosOrcatec.delete(`/organizations-phones/${id}`).then(response => response.data)
}
export const dellOrganizationEmail = id => {
  return axiosOrcatec.delete(`/organizations-emails/${id}`).then(response => response.data)
}
export const editOrganizationEmail = data => {
  const { id, ...rest } = data
  return axiosOrcatec.put(`/organizations-emails/${id}`, rest).then(response => response.data)
}
export const editOrganizationPhone = data => {
  const { id, ...rest } = data
  return axiosOrcatec.put(`/organizations-phones/${id}`, rest).then(response => response.data)
}

export const searchOrganizationByName = searchValue => {
  return axiosOrcatec.get(`organizations?search=${searchValue}`).then(response => response.data)
}

export const postOrganizationPhone = data => {
  return axiosOrcatec.post(`/organizations-phones`, data).then(response => response.data)
}
export const postOrganizationEmails = data => {
  return axiosOrcatec.post(`/clients-emails`, { ...data, entity_type: 1 }).then(response => response.data)
}
