import React, { useState, useEffect, useRef, useCallback } from 'react'
// import { Modal } from 'react-bootstrap'
import './ModalFileUpload.scss'
import { viewFile } from '../../../../../../helpers/viewFile/viewFile'
import validator from 'validator'
import { TextField } from '@material-ui/core'
import TableActions from '../../../components/TableActionsNew'
import { Modal, Progress, Popover, Alert } from 'antd'
import { SmallPreloader } from '../../../components/Preloader/SmallPreloader'
import Dropzone from 'react-dropzone'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import { InboxOutlined } from '@ant-design/icons'
import { infoIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
const tooltips1 = ['Upload files']
const tooltips = ['View file', 'Delete file']
const popConfirms = ['', 'file']

const ModalFileUpload = props => {
  let inputRef = React.createRef()
  const [value, setValue] = useState('')
  const [error, setError] = useState('')
  const SaveButton = props.saveButton
  useEffect(() => {
    if (props.show) {
      setError(false)
      setValue('')
    }
  }, [props.show])

  useEffect(() => {
    if (value) {
      const isURL =
        validator.isURL(value, { require_protocol: true }) ||
        /^(data:).+(base64).+/g.test(value)
      setError(isURL ? '' : 'Please enter valid url')
    } else setError('')
  }, [value])

  const uploadFromURL = e => {
    if (e.keyCode === 13) {
      if (!error) {
        props.onUploadFileByLink(value)
        setValue('')
      }
    }
  }

  const handleBlur = () => {
    if (!error && value) {
      props.onUploadFileByLink(value)
      setValue('')
    }
  }

  const onChangeTempValue = e => {
    setValue(e.target.value)
    setError(false)
  }

  const getPopupContainer = () =>
    document.getElementsByClassName('modal-file-upload')[0].parentElement
      .parentElement

  const content = (
    <div>
      <p>
        .jpeg,.png,.jpg,.webp,.pdf,.xls,.ppt,.docx,.txt,.mp3,.mp4,.zip,.rar{' '}
      </p>
      <p>
        maximum file upload size:<b> 20 MB</b>
      </p>
    </div>
  )
  const infoContent = (
    <Alert
      message='Accepted file types:'
      description={content}
      type='info'
      showIcon
    />
  )

  return (
    <Modal
      visible={props.show}
      onCancel={() => props.toggleModal('', [])}
      title={<h4 style={{ marginBottom: 0 }}>Add Attachments</h4>}
      className='modal-file-upload'
      footer={null}
    >
      {!!props.uploadProgress &&
        !!Object.values(props.uploadProgress)?.length &&
        Object.values(props.uploadProgress).reduce((acc, val) => {
          if (val.progress)
            acc.push(
              <div key={val.id}>
                <Progress percent={val.progress} status='active' />
                <p>{val?.file?.name}</p>
              </div>,
            )
          return acc
        }, [])}
      <div className='d-flex justify-content-center mb-3'>
        {`Maximum ${props.filesLimit} files can be uploaded`}
        {!props.formats ? (
          <Popover content={infoContent}>
            <span style={{ marginLeft: '10px' }}>{infoIcon()}</span>
          </Popover>
        ) : null}
      </div>
      {!props.disableUpload && (
        <div className='upload-files'>
          <Dropzone
            onDrop={acceptedFiles => props.onFileTabUpload(acceptedFiles)}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps()} className='upload-files__dropzone'>
                <input {...getInputProps()} />
                <p className='upload-drag-icon'>
                  <InboxOutlined />
                </p>

                <p className='upload-drag-text'>
                  {isDragActive
                    ? 'Drop the files here ...'
                    : 'Click or drag file(s) to this area to upload'}
                </p>
              </div>
            )}
          </Dropzone>

          {!props.disableUploadByLink && (
            <div className='upload-files__upload-by-link'>
              <p>or add external link to file</p>

              <InputField
                value={value}
                label='External link to file'
                className='link-upload'
                onChange={onChangeTempValue}
                onKeyUp={uploadFromURL}
                onBlur={handleBlur}
                // error={!!error}
                helperText={
                  error ? error : value ? 'Press Enter to upload file' : ''
                }
                disabled={props.isLocalUploading}
              />
            </div>
          )}
        </div>
      )}

      {!!props.files?.length && (
        <div className='uploaded-files'>
          {props.files.map((file, i) => (
            <div className='row-upload' key={i}>
              <TextField
                variant='outlined'
                size='small'
                fullWidth
                className='form-control'
                value={
                  typeof file === 'string'
                    ? file
                    : file.linkToFile
                    ? file.linkToFile
                    : file.display_name
                    ? file.display_name
                    : file.name
                }
                placeholder='Type url to file'
                disabled={true}
              />

              <div className='actions'>
                {props.isDeleting?.includes(file.id) ? (
                  <SmallPreloader />
                ) : (
                  <TableActions
                    todos={['view', 'delete']}
                    callbacks={[
                      () => {
                        viewFile({ file, viewOnly: true })
                      },
                      () => {
                        props.onDeleteFile(file, i)
                      },
                    ]}
                    tooltips={tooltips}
                    popConfirms={popConfirms}
                    getPopupContainer={getPopupContainer}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {props.formats && (
        <>
          <p
            style={{ textAlign: 'center' }}
          >{`Attention, attach  only files in ${props.formats.join(
            ', ',
          )} formats.`}</p>

          <p style={{ textAlign: 'center', marginBottom: '10px' }}>
            Invalid files will be filtered automatically!
          </p>
        </>
      )}
      {props.actionButtons}
    </Modal>
  )
}

export default ModalFileUpload
