import { getTemplateLogoByIndusty } from 'api/NotificationModal'
import { useEffect, useState } from 'react'

export const useIndustryLogo = (industryId: number) => {
  const [industryLogo, setIndustryLogo] = useState(null)

  useEffect(() => {
    const fetchIndustryLogo = async () => {
      try {
        const { industry_logo } = await getTemplateLogoByIndusty(industryId)

        setIndustryLogo(industry_logo)
      } catch (error) {
        console.error(error)
      }
    }
    if (industryId) fetchIndustryLogo()
  }, [industryId])
  return { industryLogo }
}
