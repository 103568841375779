import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import styled from 'styled-components'
import ContactsTree, { TreeHandler } from '../ContactsTree'
import { useRef } from 'react'
import { EntityType } from 'features/Project/types'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectSummaryContact } from 'features/Project/slices/projectContactsSlice'

interface Props {
  projectId: number
  isEditContract: boolean
}

export const ManageContacts = ({ projectId, isEditContract }: Props) => {
  const childRef = useRef<TreeHandler>(null)

  const { client, property } = useAppSelector(selectSummaryContact)

  const isHasContacts = client.length || property.length

  const onAttach = (entity: EntityType) => () =>
    childRef.current?.onAttach(entity)

  const onManage = () => childRef.current?.onManage()

  return (
    <Wrapper className='proposal-contact-info'>
      <Heading>
        <Title>Project Contacts</Title>

        <ControlsWrapper>
          <MainButton
            title={'Add Property'}
            onClick={onAttach(EntityType.PROPERTY)}
          />
          <MainButton
            title={'Add Contact'}
            onClick={onAttach(EntityType.CLIENT)}
          />
          {!!isHasContacts && (
            <MainButton
              type='custom-secondary'
              title={'Manage Contacts'}
              onClick={onManage}
            />
          )}
        </ControlsWrapper>

        {/*  {!isHasContacts && status !== 'loading' && (
              <AntdTooltip title='There are no contacts associated with this project. Please add contacts and properties to establish relationships and keep your network up to date.'>
                <Warning style={{ color: 'faad14', fontSize: '2rem' }} />
              </AntdTooltip>
            )} */}
      </Heading>

      <ContactsTree
        isEditContract={isEditContract}
        projectId={projectId}
        ref={childRef}
      />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
  & .ant-modal-header {
    padding: 10px;
  }
`

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 4px;
`

const Heading = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const Title = styled.p`
  position: absolute;
  background-color: #fff;
  color: #19191980;
  letter-spacing: 0.01em;
  padding: 0 5px;
  top: -13px;
`
