import { reorderEntities } from 'features/Project/helpers'
import { DropResult } from 'react-beautiful-dnd'

interface Item {
  position: number
  order_option: number
  option_group_id: number | null
  checked_option: boolean
}

export const getProgressStrokeColor = (progress: number) =>
  progress < 100 ? '#4285f4' : progress > 100 ? '#fee10a' : 'green'

export const reorderItemsWithOptions = <T extends Item>(
  listOfItems: T[],
  result: DropResult,
): T[] => {
  const { destination, source } = result

  if (!destination) return listOfItems

  const itemsWithCheckedOptions = listOfItems.filter(item =>
    item.option_group_id ? item.checked_option : item,
  )

  const reorderedItems = reorderEntities(
    itemsWithCheckedOptions,
    source.index,
    destination?.index,
    'position',
  )

  let otherOptions = listOfItems?.filter(
    item => item.option_group_id && !item.checked_option,
  )

  if (otherOptions.length) {
    otherOptions = otherOptions.map(option => {
      const position = reorderedItems.find(
        item => item.option_group_id === option.option_group_id,
      )?.position

      return {
        ...option,
        position: position ?? option.position,
      }
    })
  }

  return [...reorderedItems, ...otherOptions]
}
