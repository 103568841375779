import * as types from '../../types'

export default (state = {}, action) => {
  switch (action.type) {
    case types.matrix.MATRIX_SET_ITEM:
      return action.item
    default:
      return state
  }
}
