const QueueIcon = ({ size = 21 }) => (
  <svg size={size} height={size} viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0)'>
      <path
        d='M13.503 4.8195V3.42671C13.503 2.75312 12.9017 2.20703 12.16 2.20703H2.10076C1.3591 2.20703 0.757812 2.75312 0.757812 3.42671V8.10793C0.757812 8.78158 1.3591 9.32761 2.10076 9.32761H3.08914V6.95248C3.08914 5.7764 4.14275 4.8195 5.4377 4.8195H13.503Z'
        fill='#4C53EF'
      />
      <path
        d='M16.8388 8.34684V6.95406C16.8388 6.28041 16.2376 5.73438 15.4959 5.73438H5.4367C4.69503 5.73438 4.09375 6.28047 4.09375 6.95406V11.6352C4.09375 12.3088 4.69503 12.8549 5.4367 12.8549H6.86325V10.4798C6.86325 9.30367 7.91685 8.34684 9.2118 8.34684H16.8388Z'
        fill='#4C53EF'
      />
      <path
        d='M19.2733 9.25781H9.21405C8.47238 9.25781 7.87109 9.80391 7.87109 10.4775V15.1587C7.87109 15.8323 8.47238 16.3784 9.21405 16.3784H19.2733C20.015 16.3784 20.6163 15.8323 20.6163 15.1587V10.4775C20.6163 9.80385 20.015 9.25781 19.2733 9.25781Z'
        fill='#4C53EF'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect x='0.757812' y='0.277344' width='19.8573' height='18.0346' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default QueueIcon
