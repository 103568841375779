import styled from 'styled-components'

const CookiesDetector = ({ children }: { children: React.ReactNode }) => {
  const guestRoutes = [
    '/preview',
    '/custom-forms',
    '/reviews/review',
    '/public-appointments',
    '/print-appointment',
  ].includes(window.location.pathname)

  if (!navigator.cookieEnabled && !guestRoutes) {
    return (
      <Wrapper>
        <Content>
          <div className='orcatec-logo' />
          <h5>Cookies are disabled</h5>

          <p>
            Your browser has cookies disabled. Make sure your cookies are
            enabled and try again.
          </p>
        </Content>
      </Wrapper>
    )
  }

  return children
}

export default CookiesDetector

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  width: 350px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid;
  padding: 20px;
  border-radius: 11px;
`
