import axiosOrcatec from './axiosInstance'

/**
 * Appointment sources.
 *
 * @return {*}
 */
export const fetchAppointmentSources = () => {
  return axiosOrcatec.get('appointment-sources').then(response => response.data)
}

export const fetchAppointmentSource = id => {
  return axiosOrcatec
    .get(`appointment-sources/${id}`)
    .then(response => response.data)
}

export const fetchAppointmentSourceForTable = tableName => {
  return axiosOrcatec
    .get(`table/sources?table_name=${tableName}`)
    .then(response => response.data?.data)
}
