import { Radio, withStyles } from '@material-ui/core'

export const BlackRadio = withStyles({
  root: {
    color: '#000000',
    '&$checked': {
      color: '#000000',
    },
  },
  checked: {},
})(props => <Radio color='default' size='small' {...props} />)

// export default BlackRadio
