import moment from 'moment-timezone'
import { IAppointment } from 'types/Appointment'

const errorMsg = {
  column_template_id: 'This field is required',
  date: 'The date is required.',
  timeNoWork: 'Selected time is not working or the employee is busy',
  timeInvalid: 'The time is invalid',
  dateInvalid: 'The date is invalid',
  timeSame: 'Time cannot be the same!',
  fieldReqiure: 'This field is reqiure',
  time_end: 'Promised time is required',
  time_start: 'Promised time is required',
  matrixTimeRequire: 'Dispatch time is required.',
  errorBefore: 'The time end must be a date after to time start.',
  errorAfter: 'The time start must be a date before to time end.',
}
const isSameTime = (timeStart, timeEnd) => timeStart === timeEnd
const isFirstTimeAfterSecond = (timeStart, timeEnd) => timeStart > timeEnd
const isSecondTimeBeforeFirst = (timeStart, timeEnd) => timeEnd < timeStart

const validateMatrixTime = (appointment, key) => {
  const { matrix_time_start, matrix_time_end } = appointment

  const isValidStart = isFirstTimeAfterSecond(matrix_time_start, matrix_time_end)
  const isSame = isSameTime(matrix_time_start, matrix_time_end)

  const isValidEnd = isSecondTimeBeforeFirst(matrix_time_start, matrix_time_end)
  const isError = { start: false, matrix_time_end: '', end: false, matrix_time_start: '' }
  if (isSame) {
    isError.end = true
    isError.start = true
    isError.matrix_time_end = errorMsg.timeSame
    isError.matrix_time_start = errorMsg.timeSame
  }
  if (isValidEnd && !isError.end) {
    isError.end = true
    isError.matrix_time_end = errorMsg.errorBefore
  }

  if (isValidStart && !isError.start) {
    isError.start = true
    isError.matrix_time_start = errorMsg.errorAfter
  }
  return isError[key]
}

const validate = (value: string, callback: (value: any) => any) => {
  const result = callback(value)
  return result || ''
}

const recurringRules = (appt: IAppointment, errorMsg) => ({
  ending_options: value =>
    validate(value, value => {
      if (value?.end_type === 1) return !value?.value ? true : null
      if (value?.end_type === 2) return !Number(value?.value) ? true : null
      return false
    }),
  start_date: value =>
    validate(value, value => {
      return !value ? errorMsg.date : null
    }),
})

const dispatchRules = (appt: IAppointment, errorMsg) => ({
  column_template_id: value =>
    validate(value, value => {
      return !value.length ? errorMsg.column_template_id : null
    }),
  date: value =>
    validate(value, value => {
      return !value || !moment(value).isValid() ? errorMsg.date : null
    }),
  time_start: value =>
    validate(value, value => {
      return !value ? errorMsg.time_start : null
    }),
  time_end: value =>
    validate(value, value => {
      return !value ? errorMsg.time_end : null
    }),
  matrix_time_start: value =>
    validate(value, value => {
      return !value ? errorMsg.matrixTimeRequire : validateMatrixTime(appt, 'matrix_time_start')
    }),
  matrix_time_end: value =>
    validate(value, value => {
      return !value ? errorMsg.matrixTimeRequire : validateMatrixTime(appt, 'matrix_time_end')
    }),
})

const queueRules = (appt: IAppointment, errorMsg) => ({
  queue_id: value =>
    validate(value, value => {
      return !value.length ? errorMsg.column_template_id : null
    }),
  time_start: value =>
    validate(value, value => {
      return !value ? null : validateMatrixTime(appt, 'time_start')
    }),
  time_end: value =>
    validate(value, value => {
      return !value ? null : validateMatrixTime(appt, 'time_end')
    }),
})

const validateAll = (appointment, error) => {
  const newErrors = { ...error }
  let validated = true
  const rules = appointment.is_recurring ? recurringRules : appointment.assign_to_matrix ? dispatchRules : queueRules
  Object.keys(error).forEach(field => {
    if (rules()[field]) {
      const result = rules(appointment, errorMsg)[field](appointment[field])
      newErrors[field] = result
      if (result) {
        validated = false
      }
    }
  })

  return { newErrors, validated }
}
const onCreateYearsPeriods = n => {
  return {
    start: new Date().setFullYear(new Date().getFullYear() - n),
    end: new Date().setFullYear(new Date().getFullYear() + n),
  }
}
export { validate, validateAll, onCreateYearsPeriods }
