import styled from 'styled-components'

export const TableWrapper = styled.div`
  position: relative;
  overflow-y: auto;

  //fixed header start

  & thead {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
  }

  & tbody {
    overflow: auto;
  }
  //fixed header end

  th[title]:hover::before {
    //hide default html tooltip with atrribute 'title' on element
    display: none;
  }

  & div.filter-node {
    background-color: #bae7ff !important;
    font-size: 20px;
  }

  //Balansces table - color for expandet row

  tr.ant-table-row-level-1 {
    background: #e7e4e4 !important;
  }
  .row_active {
    background: #e7e4e4 !important;
  }
  tr.ant-table-row-level-1:hover {
    background: #e7e4e4 !important;
  }
  .row_active:hover {
    background: #e7e4e4 !important;
  }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: unset;
  }
  //Balansces table - color for expandet row  -END

  .row_active {
    & .rest-items {
      display: none !important;
    }
  }
  table {
    border-collapse: separate;
    padding-right: 20px;
    width: fit-content;
  }
  th {
    border-spacing: 2px 0;
  }
  .ant-table-content {
    /* height: 79vh; */
    height: ${props =>
      `${props?.autoHeight ? 'auto' : props.tableHeight + 'px'}`};
  }
  .ant-table-pagination {
    margin: 5px !important;
  }
  & span.rest-items {
    padding: 3px 3px;
    margin-left: 5px;

    color: #4285f4 !important;
    background: rgba(76, 83, 239, 0.08) !important;
    font-size: 14px;
    font-variant: tabular-nums;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    height: auto;
    max-height: 26px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: default;
  }

  th.ant-table-cell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  th.ant-table-cell:has(.resize-element div:hover)::before {
    width: 3px !important;
    border-radius: 1px;
    background-color: rgb(191, 191, 191) !important;
  }

  /* .resize-element {
    position: absolute;
    right: -2px;
    top: 0;
    width: 2px;
    background-color: #f5f5f5;
    height: 30px;
    cursor: col-resize;
    border-radius: 1px;
  }

  .resize-element div {
    position: absolute;
    right: 0 !important;
    top: 5px !important;
    width: 2px !important;
    background-color: rgb(191, 191, 191);
    height: 20px !important;
    cursor: col-resize;
    border-radius: 1px;
    z-index: 1;
  } */

  .resize-element div {
    width: 13px !important;
    right: 10px;
  }

  .resize-element__active div {
    position: absolute;
    width: 1px !important;
    border: 0.5px dashed rgb(191, 191, 191);
    background-color: transparent;
    height: calc(100vh - 200px) !important;
    cursor: col-resize;
    right: 0 !important;
    top: 0px !important;
    z-index: 999999;
  }

  table th {
    cursor: move !important;
  }
  table th .ant-table-column-sorter.ant-table-column-sorter-full {
    cursor: pointer;
    z-index: 1;
  }

  .link {
    color: #1890ff;
    text-decoration: underline;
    cursor: pointer;
  }

  .ant-pagination {
    padding-right: 40px;
    @media screen and (max-width: 500px) {
      padding-right: 0px;
    }
  }

  .ant-table {
    .ant-table-content {
      overflow-x: auto;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 5px;
      /* width: 100px;
      min-width: 50px;
      max-width: 150px; */
    }

    .ant-table-summary {
      font-weight: 700;

      tr {
        td {
          background: #fafafa;
          padding: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.divider {
          td {
            background-color: white;
            padding: 10px 0 0;
            text-align: left;
            border: none;
          }
        }
      }
    }

    .ant-table-tbody > tr > td {
      /* vertical-align: top; */
    }

    .ant-table-thead tr .ant-table-selection-column div {
      /* display: none; */
      display: ${props => `${props?.showInputInTh ? 'block' : 'none'}`};
    }

    .ant-table-thead > tr > th {
      position: relative;
      font-size: 0.75rem;

      .ant-table-column-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .ant-table-filter-trigger {
        margin: -4px -4px -4px 4px;
      }
    }

    @media (max-width: 768px) {
      font-size: 10px;

      .ant-table-thead > tr > th {
        .ant-table-column-sorter
          .ant-table-column-sorter-inner
          .ant-table-column-sorter-up,
        .ant-table-column-sorter
          .ant-table-column-sorter-inner
          .ant-table-column-sorter-down {
          font-size: 8px;
        }
      }
    }
  }
`
export const Heading = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 5px;
  gap: 20px;
`
