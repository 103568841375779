import React, { useState, useEffect } from 'react'
import './style.scss'
import { AutoComplete } from 'antd'
import { searchAddress, searchLocalAddress } from 'api/Property'
import useSearch from '../SearchHook'
import { getAddressWithUnit } from 'helpers/getAddressWithUnit'

const { Option } = AutoComplete

const AddressInput = ({ onChoose, type = 'both', placeholder, className, initialValue = '', getResp, ...props }) => {
  const [value, changeValue] = useState(initialValue)
  let searchFunction
  if (type === 'local') searchFunction = address => searchLocalAddress({ address })
  if (type === 'openstreet') searchFunction = searchAddress

  useEffect(() => {
    changeValue(props.value)
  }, [props.value])

  let resp = useSearch(value, searchFunction, 200)

  useEffect(() => {
    getResp && getResp(resp)
  }, [resp])

  const onSelect = (e, option) => {
    onChoose &&
      onChoose(
        resp.find(item =>
          type === 'local' ? Number(item.id) === Number(option.key) : Number(item.place_id) === Number(option.key),
        ),
        type,
      )
  }

  return (
    <AutoComplete
      value={value}
      onChange={e => {
        changeValue(e)
      }}
      className='autoComplete'
      size={props.styleProps ? 'default' : 'large'}
      onSelect={(e, option) => onSelect(e, option)}
      placeholder={placeholder}
      className={className !== undefined && className}
      {...props}
    >
      {resp &&
        resp.map((item, i) => {
          return type === 'both' ? (
            <Option key={resp.type === 'local' ? item.id : item.place_id}>
              {resp.type === 'local' ? item.address : item.display_name}
            </Option>
          ) : type === 'local' ? (
            <Option key={item.id}>
              {item.full_address ? item.full_address : `${getAddressWithUnit(item)}, ${item.postcode}`}
            </Option>
          ) : (
            type === 'openstreet' &&
            resp.type === 'openstreet' && <Option key={item.place_id}>{item.display_name}</Option>
          )
        })}
    </AutoComplete>
  )
}

export default AddressInput
