import {
  /* PayloadAction ,*/ createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { AsyncThunkAPI } from 'store/types'
import { Error } from 'types/Error'
// import { transformArrayToObj } from 'features/Dispatch/helpers'
import { AppStateType } from 'store'
import { SubscriptionAPI } from 'api/Subscriptoin'
import {
  IBilling,
  PlanUsage,
  SubscriptionOptionalFeatures,
  SubscriptionPlan,
} from '../types'
import { UsageInfo } from '../components/SubscriptionPlan/PlanUsage'

const initialState: {
  data: IBilling
  features: SubscriptionOptionalFeatures
  planUsage: PlanUsage
  status: 'idle' | 'loading' | 'error'
  error: Error | null
} = {
  data: {
    id: 0,
    features: {},
    next_billing: 0,
    payment_due: '',
    plan: SubscriptionPlan.Free,
    summary: '',
    total_price: 0,
  },
  features: {} as SubscriptionOptionalFeatures,
  planUsage: {} as PlanUsage,
  status: 'idle',
  error: null,
}

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getBilling.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getBilling.fulfilled, (state, { payload }) => {
      if (!payload.id) return

      state.data = payload
      state.status = 'idle'
    })
    builder.addCase(getBilling.rejected, (state, action) => {
      state.error = action.payload
      state.status = 'error'
    })

    builder.addCase(getFeatures.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getFeatures.fulfilled, (state, { payload }) => {
      state.features = payload
      state.status = 'idle'
    })
    builder.addCase(getFeatures.rejected, (state, action) => {
      state.error = action.payload
      state.status = 'error'
    })

    // builder.addCase(getPlanUsage.pending, () => {
    // })
    builder.addCase(getPlanUsage.fulfilled, (state, { payload }) => {
      state.planUsage = payload
    })
    builder.addCase(getPlanUsage.rejected, (state, action) => {
      state.error = action.payload
    })

    builder.addCase(updateSubscriptionModule.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateSubscriptionModule.fulfilled, (state, { meta }) => {
      state.features = {
        ...state.features,
        [meta.arg.module]: {
          ...state.features[meta.arg.module],
          status: meta.arg.status,
        },
      }
      state.status = 'idle'
    })
    builder.addCase(updateSubscriptionModule.rejected, (state, action) => {
      state.error = action.payload
      state.status = 'error'
    })

    builder.addCase(cancelSubscription.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(cancelSubscription.fulfilled, state => {
      state.status = 'idle'
    })
    builder.addCase(cancelSubscription.rejected, (state, action) => {
      state.error = action.payload
      state.status = 'error'
    })
  },
})

export default subscriptionSlice.reducer

// export const {} = subscriptionSlice.actions

export const getBilling = createAsyncThunk<IBilling, void, AsyncThunkAPI>(
  'subscription/getBilling',
  async (_, { rejectWithValue }) => {
    try {
      const res = await SubscriptionAPI.getBilling()

      return res
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const getFeatures = createAsyncThunk<
  SubscriptionOptionalFeatures,
  void,
  AsyncThunkAPI
>('subscription/getFeatures', async (_, { rejectWithValue }) => {
  try {
    const res = await SubscriptionAPI.getFeatures()

    return res
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const getPlanUsage = createAsyncThunk<
  Record<
    keyof typeof UsageInfo,
    { used: number; total: number; price: number }
  >,
  void,
  AsyncThunkAPI
>('subscription/getPlanUsage', async (_, { rejectWithValue }) => {
  try {
    const res = await SubscriptionAPI.getPlanUsage()

    return res
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const updateSubscriptionModule = createAsyncThunk<
  void,
  {
    module: keyof SubscriptionOptionalFeatures
    status: boolean
  },
  AsyncThunkAPI
>('subscripition/updateModuleStatus', async (data, { rejectWithValue }) => {
  try {
    return await SubscriptionAPI.updateModuleStatus(data)
  } catch (error) {
    rejectWithValue(error.response.data)
  }
})

export const updatePlan = createAsyncThunk<
  void,
  {
    plan: SubscriptionPlan
    features: string[]
    users: number[]
  },
  AsyncThunkAPI
>(
  'subscription/updatePlan',
  async (selectedPlan, { getState, dispatch, rejectWithValue }) => {
    const subscriptionId = getState().orcatec.subscriptionSlice.data.id

    try {
      const res = await SubscriptionAPI.updatePlan(subscriptionId, {
        plan: selectedPlan.plan,
        modules: selectedPlan.features,
        users: selectedPlan.users,
      })

      dispatch(getBilling())

      return res
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const cancelSubscription = createAsyncThunk<void, void, AsyncThunkAPI>(
  'subscription/cancelSubscription',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await SubscriptionAPI.cancelSubscription()

      dispatch(getBilling())

      return res
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)
//Selectors

export const selectSubscriptionSlice = (state: AppStateType) =>
  state.orcatec.subscriptionSlice

export const selectSubscriptionPlan = (state: AppStateType) =>
  state.orcatec.subscriptionSlice.data.plan
