import { Checkbox } from 'antd'
import ReactPasswordChecklist from 'react-password-checklist'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 450px;
  padding: 48px;
  border: 2px solid #165ed033;
  border-radius: 10px;
  background-color: #fff;

  @media (max-width: 450px) {
    width: 375px;
    padding: 16px;
  }
`
export const ResultWrapper = styled.div`
  width: 450px;
  padding: 10px;
  border: 2px solid #165ed033;
  border-radius: 10px;
  background-color: #fff;

  @media (max-width: 450px) {
    width: 375px;
    padding: 5px;
  }
`
export const Title = styled.h2`
  margin-bottom: 24px;
  text-align: center;
`
export const Agreement = styled.div``

export const Form = styled.form`
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`
export const Signin = styled.p`
  display: flex;
  gap: 10px;
  justify-content: center;
  font-size: 16px;
  margin-top: 30px;
`

export const StyledCheckbox = styled(Checkbox)<{ error: boolean }>`
  .ant-checkbox-inner {
    border-color: ${props => (props.error ? '#f12832' : '#d9d9d9')};
  }
  span {
    font-weight: 400;
  }

  a {
    font-weight: 500;
  }
`

export const Error = styled.p`
  font-size: 12px;
  color: #f12832;
`

export const PasswordChecklistStyled = styled(ReactPasswordChecklist)`
  li {
    gap: 10px;
    align-items: center !important;
    margin-bottom: 8px !important;
  }
`

// export const Captcha = styled.div`
//   margin-top: 24px;
// `

export const SecondaryText = styled.span`
  color: ${props => props.theme.font.secondary};
`

export const StyledLink = styled(Link)`
  font-weight: 500;
`

export const Row = styled.div`
  display: flex;
  gap: 20px;
`
