import {
  deleteSource,
  getAppoinmentSources,
  postAppoinmentSources,
  deleteType,
  postAppoinmentTypes,
  getAppoinmentTypes,
} from 'api/Appointment'
import { fetchUsersForTemplate } from 'api/User'
import { Action } from 'redux'
import { AppointmentSource, AppointmentType } from 'types/Appointment'
import * as action from '../../actions/settings/company'

export interface IAction<T> extends Action<string> {
  type: string
  payload?: T
}
type DispatchAllSource = (arg: IAction<AppointmentSource[]>) => void
type DispatchAllType = (arg: IAction<AppointmentType[]>) => void
type DispatchDeleteType = (id: IAction<number>) => void
type DispatchDeleteSource = (id: IAction<number>) => void
type DispatchCreateType = (id: IAction<{ name: string }>) => void
type DispatchCreateSource = (id: IAction<{ name: string }>) => void
type DispatchGetTech = (tech: IAction<object>) => void

export const getCompanySource = () => (dispatch: DispatchAllSource) => {
  getAppoinmentSources()
    .then(data => {
      // const defaultValue = data?.find(source => source?.name === '-')
      const fiteredData = data?.filter(s => s?.id !== null)
      // if (!defaultValue) {
      //   return postAppoinmentSources({ name: '-' }).then(newSource => {
      //     dispatch(action.setCompanySourceAction([{ ...fiteredData, ...newSource }]))
      //   })
      // }
      // else {
      dispatch(action.setCompanySourceAction(fiteredData))
      // }
    })
    .catch(error => console.error(error?.message))
}

export const deleteCompanySource = (id: number) => (
  dispatch: DispatchDeleteSource,
) => {
  deleteSource(id)
    // .then(() => {
    //   dispatch(action.deleteSourcesAction(id))
    // })
    // .then(() => dispatch(getCompanySource()))
    .finally(() => {
      dispatch(action.deleteSourcesAction(id))
      dispatch(getCompanySource())
    })
}

export const createCompanySource = (newSource: { name: string }) => async (
  dispatch: DispatchCreateSource,
) => {
  try {
    const source = await postAppoinmentSources(newSource)
    await dispatch(action.updateCompanySourceAction(source))
    return source
  } catch (error) {
    throw new Error(error)
  }
}

export const getCompanyTypes = () => (dispatch: DispatchAllType) => {
  getAppoinmentTypes()
    .then(data => {
      // const defaultValue = data.find(source => source?.name === '-')
      const fiteredData = data?.filter(s => s?.id !== null)
      // if (!defaultValue) {
      //   return postAppoinmentTypes({ name: '-' }).then(newTypes => {
      //     dispatch(action.setCompanyTypesAction([{ ...fiteredData, ...newTypes }]))
      //   })
      // } else {
      dispatch(action.setCompanyTypesAction(fiteredData))
      // }
    })
    .catch(error => console.error(error?.message))
}

export const createCompanyTypes = (newTypes: { name: string }) => async (
  dispatch: DispatchCreateType,
) => {
  try {
    const type = await postAppoinmentTypes(newTypes)
    await dispatch(action.updateCompanyTypesAction(type))
    return type
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteCompanyTypes = (id: number) => (
  dispatch: DispatchDeleteType,
) => {
  deleteType(id)
    /*  .then(() => {
    dispatch(getCompanyTypes())
  }) */
    .finally(() => {
      dispatch(action.deleteCompanyTypesAction(id))
      dispatch(getCompanyTypes())
    })
}

export const getCompanyTechnicians = () => (dispatch: DispatchGetTech) => {
  fetchUsersForTemplate().then(data => {
    dispatch(action.getCompanyTechniciansAction(data.users))
  })
}
