import { PaperClipOutlined, EditOutlined } from '@ant-design/icons'
import { Tooltip, Card, Typography } from 'antd'
import { ModalFilesUpload } from 'components/UI/ModalFIlesUpload/ModalFilesUpload'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { ItemAttachment } from 'features/Items/types'
import DocViewer from 'helpers/viewFIlesNew/ViewDoc'
import { ViewFile } from 'helpers/viewFile/viewFile'
import { forwardRef, useState } from 'react'
import styled from 'styled-components'

const { Text } = Typography

const gridStyle: React.CSSProperties = {
  width: '100%',
  textAlign: 'left',
  padding: '0px',
  boxShadow: 'none',
}

interface Props {
  data: ItemAttachment[]
  disabled?: boolean
  imageSize?: number
  title?: string
  tooltip?: boolean
  onUpload?: (files: ItemAttachment[]) => void
  handleSaveOnBlur: () => void
  hash?: string
  publicUsage?: boolean
  handleChangeNotesForWorkers?: (e: string) => void
  handleChangeDescriptions?: (e: string, idx: number) => void
}

const IMAGE_WIDTH = 80

export const Attachments = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      tooltip,
      data,
      disabled,
      onUpload,
      imageSize = IMAGE_WIDTH,
      hash,
      publicUsage,
      handleChangeNotesForWorkers,
      handleChangeDescriptions,
      notesValue,
    },
    ref,
  ) => {
    const [viewerModal, setViewerModal] = useState<{
      showDocViewerModal: boolean
      documents: ItemAttachment[]
      currentFileIdx: number | null
    }>({
      showDocViewerModal: false,
      documents: [],
      currentFileIdx: null,
    })
    const [filesUploadModal, setFilesUploadModal] = useState(false)

    const toggleOpenDocViewerModal = () => {
      setViewerModal(prev => ({
        ...prev,
        showDocViewerModal: !prev.showDocViewerModal,
      }))
    }

    const getFileForModalDocViewer = (
      files: ItemAttachment[],
      currentFileIdx: number,
    ) => {
      setViewerModal({
        documents: files,
        currentFileIdx,
        showDocViewerModal: true,
      })
    }

    const handleCancel = () => {
      setFilesUploadModal(false)
    }

    const handleOpenFilesUpload = () => {
      setFilesUploadModal(true)
    }

    const handeFilesUpload = (files: ItemAttachment[]) => {
      onUpload?.(files)
      setFilesUploadModal(false)
    }

    return (
      <Wrapper>
        <h6>{title} </h6>
        <WrapperInner>
          <Text
            style={{ wordBreak: 'break-all' }}
            editable={{
              onChange: handleChangeNotesForWorkers,
              maxLength: 3000,
              tooltip: true,
              autoSize: true,
              icon: !notesValue ? <MainButton title='Add Note' /> : undefined,
            }}
          >
            {notesValue}
          </Text>
        </WrapperInner>

        <h6>Attachments </h6>
        <WrapperInner>
          <List imageSize={imageSize}>
            {data
              ?.filter(image => !!image)
              ?.map((image: ItemAttachment, idx: number) => (
                <Card.Grid hoverable={false} key={idx} style={gridStyle}>
                  <ListItem key={idx}>
                    <div
                      onClick={e => {
                        if (e?.target?.classList?.contains('ant-tooltip-inner'))
                          return
                        getFileForModalDocViewer(data, idx)
                      }}
                      key={idx}
                    >
                      <ViewFile
                        file={image?.source || image?.linkToFile || image}
                        fileStyle={{
                          width: imageSize,
                          height: imageSize,
                          border: '1px solid #d9d9d9',
                          borderRadius: 8,
                          padding: 5,
                        }}
                        viewOnly={false}
                        tooltipPlacement={tooltip ? 'top' : undefined}
                        isLogo={undefined}
                        fileName={image?.display_name}
                      />
                    </div>
                    <Text
                      style={{
                        paddingLeft: '4px',
                        width: '85%',
                        height: '100%',
                      }}
                      editable={{
                        onChange: e => handleChangeDescriptions(e, idx),
                        maxLength: 3000,
                        icon: !data?.[idx]?.description ? (
                          <span
                            style={{
                              color: 'grey',
                              fontSize: '12px',
                              fontStyle: 'italic',
                            }}
                          >
                            Add description{' '}
                            <EditOutlined style={{ color: '#007bff' }} />
                          </span>
                        ) : (
                          undefined
                        ),
                      }}
                    >
                      {data?.[idx]?.description}
                    </Text>
                  </ListItem>
                </Card.Grid>
              ))}

            {!!onUpload && !disabled && (
              <Card.Grid hoverable={false} style={gridStyle}>
                <Tooltip title='Manage attachments' mouseLeaveDelay={0}>
                  <Upload
                    style={{ width: '80px', height: '80px' }}
                    onClick={handleOpenFilesUpload}
                  >
                    <PaperClipOutlined style={{ fontSize: 18 }} />
                  </Upload>
                </Tooltip>
              </Card.Grid>
            )}
          </List>
        </WrapperInner>

        {viewerModal?.showDocViewerModal && (
          <DocViewer
            documents={viewerModal.documents}
            currentDocument={viewerModal.currentFileIdx}
            isModalVisible={viewerModal.showDocViewerModal}
            handleCancel={toggleOpenDocViewerModal}
          />
        )}

        {filesUploadModal && (
          <ModalFilesUpload
            ref={ref}
            data={data}
            onSave={handeFilesUpload}
            onCancel={handleCancel}
            publicUsage={publicUsage}
            eventHash={hash}
            filesLimit={50}
          />
        )}
      </Wrapper>
    )
  },
)

Attachments.displayName = 'Attachments'

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 20px;
`
const WrapperInner = styled.div`
  padding-left: 10px;
  margin-bottom: 10px;
`
const List = styled.div<{ imageSize: number }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
`

const ListItem = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`

const Upload = styled.div`
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: #2e5bff;
  }
`
