import { jsPDF } from 'jspdf'
import { uuid4 } from '@sentry/utils'
import moment from 'moment-timezone'

const readFile = file => {
  return new Promise(resolve => {
    if (typeof file === 'string') {
      return resolve(file)
    } else {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      return reader.readAsDataURL(file)
    }
  })
}

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.addEventListener('load', () => {
      resolve(image)
    })

    image.addEventListener('error', error => reject(error))
    image.src = url
  })

const ObjectURLToBlob = function(url) {
  return new Promise((resolve, reject) => {
    try {
      const blob = fetch(url)
      resolve(blob)
    } catch (err) {
      reject(err)
    }
  })
}

const BlobToObjectURL = function(blob) {
  return new Promise((resolve, reject) => {
    try {
      resolve(URL.createObjectURL(blob))
    } catch (err) {
      reject(err)
    }
  })
}
const blobToArrayBuffer = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = () => reject()
    reader.readAsArrayBuffer(blob)
  })
}
const blobToBase64 = blob => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

const generatePDF = async file => {
  const pdf = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a4',
    putOnlyUsedFonts: true,
  })
  const imgWidth = 210
  const imgHeight = 290
  const pages = file

  return Promise.all(pages?.map(page => readFile(page))).then(imgPages => {
    imgPages.forEach((imgPage, idx, arr) => {
      // const imgHeight = (imgPage.height * imgWidth) / imgPage.width
      // const contentDataURL = imgPage.toDataURL('image/jpeg')

      pdf.addImage(imgPage, 'JPEG', 0, 0, imgWidth, imgHeight)
      if (idx !== arr?.length - 1) pdf.addPage()
    })

    return pdf.output('blob')
  })
}

const printPdf = (file, name = uuid4()) => {
  const iframe = document.createElement('iframe')
  generatePDF(file).then(blob => {
    iframe.src = window.URL.createObjectURL(blob)
    iframe.name = name
    iframe.id = 'print_frame'

    iframe.onload = () => {
      const pdfFrame = window.frames[name]
      pdfFrame.focus()
      pdfFrame.print()
    }
    document.body.appendChild(iframe)
  })
}

const makeDataForLogSheet = (appointment, technician, helpers) => {
  const MATERIALS_CHECKED_OUT = 1
  const MATERIALS_CHECKED_IN = 2
  const BILLABLE_MATERIALS = 3
  const EQUIPMENT_CHECK_OUT = 4
  const initData = {
    appointment_id: null,
    customer_name: '',
    customer_relation: '',
    address: '',
    appointment_type: '',
    date: '',
    super_name: '',
    phone: '',
    preferred_tech: '',
    description: '',
    truck_number: '',
    truck_hours_onsite: '',
    pm: '',
    billed_by: '',
    fwo: '',
    foreman_work_break: [
      {
        foreman_log_sheet_id: 1,
        full_name: helpers?.[0]?.name || '',
        start: '',
        lunch_out: '',
        lunch_in: '',
        finish: '',
        total: '',
      },
      {
        foreman_log_sheet_id: 1,
        full_name: helpers?.[1]?.name || '',
        start: '',
        lunch_out: '',
        lunch_in: '',
        finish: '',
        total: '',
      },
      {
        foreman_log_sheet_id: 1,
        full_name: helpers?.[2]?.name || '',
        start: '',
        lunch_out: '',
        lunch_in: '',
        finish: '',
        total: '',
      },
      {
        foreman_log_sheet_id: 1,
        full_name: helpers?.[3]?.name || '',
        start: '',
        lunch_out: '',
        lunch_in: '',
        finish: '',
        total: '',
      },
      {
        foreman_log_sheet_id: 1,
        full_name: helpers?.[4]?.name || '',
        start: '',
        lunch_out: '',
        lunch_in: '',
        finish: '',
        total: '',
      },
      {
        foreman_log_sheet_id: 1,
        full_name: helpers?.[5]?.name || '',
        start: '',
        lunch_out: '',
        lunch_in: '',
        finish: '',
        total: '',
      },
      {
        foreman_log_sheet_id: 1,
        full_name: helpers?.[6]?.name || '',
        start: '',
        lunch_out: '',
        lunch_in: '',
        finish: '',
        total: '',
      },
    ],
    foreman_materials: [
      {
        foreman_log_sheet_id: 1,
        type: MATERIALS_CHECKED_OUT,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 2,
        type: MATERIALS_CHECKED_OUT,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 3,
        type: MATERIALS_CHECKED_OUT,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 4,
        type: MATERIALS_CHECKED_OUT,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 1,
        type: MATERIALS_CHECKED_IN,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 2,
        type: MATERIALS_CHECKED_IN,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 3,
        type: MATERIALS_CHECKED_IN,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 4,
        type: MATERIALS_CHECKED_IN,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 1,
        type: BILLABLE_MATERIALS,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 2,
        type: BILLABLE_MATERIALS,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 3,
        type: BILLABLE_MATERIALS,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 4,
        type: BILLABLE_MATERIALS,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 1,
        type: EQUIPMENT_CHECK_OUT,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 2,
        type: EQUIPMENT_CHECK_OUT,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 3,
        type: EQUIPMENT_CHECK_OUT,
        note: '',
        qty: '',
      },
      {
        foreman_log_sheet_id: 4,
        type: EQUIPMENT_CHECK_OUT,
        note: '',
        qty: '',
      },
    ],
  }

  return {
    ...initData,
    appointment_id: appointment?.id,
    customer_name: appointment?.client_name || '',
    customer_relation: appointment?.relation || '',
    address: `${appointment?.address ? appointment?.address : ''}  ${
      appointment?.city ? appointment?.city : ''
    } ${appointment?.state ? appointment?.state : ''}`,
    appointment_type: appointment?.appointment_type || '',
    date: appointment?.date || '',
    preferred_tech: technician?.name || '',
  }
}

export const makeForUpdateForm = (appointment, technician, form, helpers) => {
  const breakLog = JSON.parse(
    JSON.stringify(form.foreman_work_break.sort((a, b) => a.id - b.id)),
  )
  const tech = [
    technician,
    ...helpers?.filter(h => h.name !== technician?.name),
  ]

  return {
    appointment_id: appointment?.id,
    customer_name: appointment?.client_name || '',
    customer_relation: appointment?.relation || '',
    address: `${appointment?.address ? appointment?.address : ''}  ${
      appointment?.city ? appointment?.city : ''
    } ${appointment?.state ? appointment?.state : ''}`,
    appointment_type: form?.appointment_type
      ? form?.appointment_type
      : appointment?.appointment_type || '',
    date: appointment?.date || '',
    preferred_tech: technician?.name || '',
    foreman_work_break: breakLog.map((f, i) => {
      const newObj = { ...f, full_name: tech?.[i]?.name || '' }
      return newObj
    }),
  }
}

const fullLink = path => `${process.env.REACT_APP_API_MEDIA}${path}`

const makeBodySmsTemplate = (
  foreman,
  date,
  company,
  project,
  city,
  customerName,
) => `Good afternoon, we have you going with ${foreman || '-'} on ${moment(
  date,
).format('MM/DD/YYYY')}. You will be working at
${customerName || '-'}, ${project || '-'} in ${city || '-'}.\n
Please remember that you have the option of providing your own transportation to and from jobsites or
riding in a company vehicle. Laborers are not required to use company owned transportation to and
from the jobsites. If you choose to provide your own transportation you must arrive by 7:00 AM to
report for work.\n
If you have any questions or you are not able to work please call the office at (800) 834-8682 or (951)
277-3404.\n
So Cal Sandbags Dispatch Office`

export {
  blobToBase64,
  ObjectURLToBlob,
  BlobToObjectURL,
  blobToArrayBuffer,
  generatePDF,
  printPdf,
  makeDataForLogSheet,
  createImage,
  fullLink,
  readFile,
  makeBodySmsTemplate,
}
