import styled from 'styled-components'
import { Section } from './components/Section'
import { ProjectSection } from 'features/Project/types'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  selectProjectDiscountsWithStatus,
  selectProjectRebatesWithStatus,
} from 'features/Project/projectSelectors'

interface Props {
  sections: ProjectSection[]
}

export const SectionDiscountAndRebates = ({ sections }: Props) => {
  const rebates = useAppSelector(selectProjectRebatesWithStatus)
  const discounts = useAppSelector(selectProjectDiscountsWithStatus)

  if (!rebates.length && !discounts.length) return null

  return (
    <Wrapper>
      {sections.map(section => (
        <Section key={section.id} data={section} />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #ffffff;
  padding: 10px 20px;
  border: 1px solid #dcdfe5;
  border-radius: 4px;

  & > *:not(:last-child) {
    border-bottom: 1px solid grey;
  }
`
