import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AsyncThunkAPI } from 'store/types'
import { Error } from 'types/Error'
// import { transformArrayToObj } from 'features/Dispatch/helpers'
import { AppStateType } from 'store'
import { SubscriptionAPI } from 'api/Subscriptoin'
import { PaymentMethod } from '../types'
// import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { deleteErrorKeys } from 'features/Items/helpers'

const initialState: {
  paymentMethods: { ids: number[]; data: Record<number, PaymentMethod> }
  status: 'idle' | 'loading' | 'error'
  error: Error | null
} = {
  paymentMethods: {
    data: {},
    ids: [],
  },
  status: 'idle',
  error: null,
}

const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    resetErrors: (state, action: PayloadAction<string[] | undefined>) => {
      state.error = deleteErrorKeys(state.error, action.payload)
      state.status = 'idle'
    },
  },
  extraReducers: builder => {
    builder.addCase(getPaymentMethods.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getPaymentMethods.fulfilled, (state, { payload }) => {
      state.paymentMethods = {
        ids: payload.map(card => card.id),
        data: transformArrayToObj(payload),
      }
      state.status = 'idle'
    })
    builder.addCase(getPaymentMethods.rejected, (state, action) => {
      state.error = action.payload
      state.status = 'error'
    })

    builder.addCase(createPaymentMethod.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(createPaymentMethod.fulfilled, (state, { payload }) => {
      state.paymentMethods = {
        ids: [...state.paymentMethods.ids, payload.id],
        data: payload.is_default
          ? transformArrayToObj([
              ...Object.values(state.paymentMethods.data).map(method => ({
                ...method,
                is_default: false,
              })),
              payload,
            ])
          : { ...state.paymentMethods.data, [payload.id]: payload },
      }
      state.status = 'idle'
    })
    builder.addCase(createPaymentMethod.rejected, (state, action) => {
      state.error = action.payload
      state.status = 'error'
    })

    builder.addCase(updatePaymentMethod.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updatePaymentMethod.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.paymentMethods = {
        ids: state.paymentMethods.ids,
        data: payload.is_default
          ? transformArrayToObj([
              ...Object.values(state.paymentMethods.data).map(method => ({
                ...method,
                is_default: false,
              })),
              payload,
            ])
          : { ...state.paymentMethods.data, [payload.id]: payload },
      }
    })
    builder.addCase(updatePaymentMethod.rejected, (state, action) => {
      state.error = action.payload
      state.status = 'error'
    })
  },
})

export default paymentMethodsSlice.reducer

export const { resetErrors } = paymentMethodsSlice.actions

export const getPaymentMethods = createAsyncThunk<
  PaymentMethod[],
  void,
  AsyncThunkAPI
>('paymentMethods/getPaymentMethods', async (_, { rejectWithValue }) => {
  try {
    const res = await SubscriptionAPI.getPaymentMethods()

    return res
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const createPaymentMethod = createAsyncThunk<
  PaymentMethod,
  PaymentMethod,
  AsyncThunkAPI
>('paymentMethods/createPaymentMethod', async (card, { rejectWithValue }) => {
  try {
    const res = await SubscriptionAPI.createPaymentMethod(card)

    return res
  } catch (error) {
    // openNotificationWithIcon('error', {
    //   message: error?.response?.data?.message || 'Invalid data',
    // })

    return rejectWithValue(error.response.data.errors)
  }
})

export const updatePaymentMethod = createAsyncThunk<
  PaymentMethod,
  PaymentMethod,
  AsyncThunkAPI
>('paymentMethods/updatePaymentMethod', async (card, { rejectWithValue }) => {
  try {
    const res = await SubscriptionAPI.updatePaymentMethod(card)

    return res
  } catch (error) {
    // openNotificationWithIcon('error', {
    //   message: error?.response?.data?.message,
    // })

    return rejectWithValue(error.response.data.errors)
  }
})

export const deletePaymentMethod = createAsyncThunk<
  PaymentMethod,
  number,
  AsyncThunkAPI
>('paymentMethods/deletePaymentMethod', async (card, { rejectWithValue }) => {
  try {
    const res = await SubscriptionAPI.deletePaymentMethod(card)

    return res
  } catch (error) {
    return rejectWithValue(error.response.data.errors)
  }
})
//Selectors

export const selectPaymentMethodsSlice = (state: AppStateType) =>
  state.orcatec.paymentMethodsSlice

export const selectPaymentMethods = (state: AppStateType) =>
  state.orcatec.paymentMethodsSlice.paymentMethods.ids.map(
    id => state.orcatec.paymentMethodsSlice.paymentMethods.data[id],
  )
