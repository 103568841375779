import React, { useState, useEffect, FC } from 'react'
import { Modal, Skeleton, Table } from 'antd'
import styled from 'styled-components'
import { getGlobalSubscriptionInfo } from 'store/SuperAdmin/api/subscriptionApi'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { priceToView } from 'helpers/thousandSeparator'
import moment from 'moment-timezone'
import { ColumnsType } from 'antd/es/table'

export const options = {
  responsive: true,

  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
}

interface IProps {
  visible: boolean
  onClose: () => void
}

enum GrowthType {
  INCREASE = 1,
  DECREASE = 2,
  NO_CHANGE = 3,
}

interface IItem {
  id: number
  billing_month: string
  monthly_total_fee: string
  growth: GrowthType
}

const renderGrowth = (value: GrowthType) => {
  let icon = '→'
  let color = 'black'
  if (value === GrowthType.INCREASE) {
    icon = '↑'
    color = 'green'
  }
  if (value === GrowthType.DECREASE) {
    icon = '↓'
    color = 'red'
  }

  return <p style={{ color, fontSize: '20px' }}>{icon}</p>
}

function calculateGrowth(data: IItem[]) {
  data.sort((a, b) =>
    moment(b.billing_month, 'MM/YYYY').diff(moment(a.billing_month, 'MM/YYYY')),
  )

  for (let i = 0; i < data.length - 1; i++) {
    const currentTotal = parseFloat(data[i].monthly_total_fee)
    const previousTotal = parseFloat(data[i + 1].monthly_total_fee)

    data[i].growth =
      currentTotal > previousTotal
        ? GrowthType.INCREASE
        : currentTotal < previousTotal
        ? GrowthType.DECREASE
        : GrowthType.NO_CHANGE
  }

  data[data.length - 1].growth = GrowthType.NO_CHANGE

  return data
}

export const SubscriptionGlobalInfo: FC<IProps> = ({ visible, onClose }) => {
  const [subscriptionInfo, setSubscriptionnInfo] = useState<IItem[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchInfo = async () => {
      setLoading(true)
      const data = await getGlobalSubscriptionInfo()
      setSubscriptionnInfo(calculateGrowth(data))
      setLoading(false)
    }
    if (visible) fetchInfo()
  }, [visible])

  const columns: ColumnsType<IItem> = [
    {
      title: 'Billing month',
      dataIndex: 'billing_month',
      key: 'billing_month',
      render: date => (
        <p>{`${date} - ${moment(date, 'MM/YYYY').format('MMMM')}`}</p>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'monthly_total_fee',
      key: 'monthly_total_fee',
      sorter: (a, b) => +a.monthly_total_fee - +b.monthly_total_fee,
      render: value => priceToView(value),
    },
    {
      title: 'Total Growth',
      dataIndex: 'growth',
      key: 'growth',
      render: renderGrowth,
    },
  ]
  return (
    <Modal
      title='Subscription Info'
      centered
      destroyOnClose
      onCancel={onClose}
      width={800}
      bodyStyle={{ maxHeight: '70vh', overflow: 'scroll' }}
      footer={
        <Controls>
          <MainButton onClick={onClose} title='Close' type='cancel' />
        </Controls>
      }
      open={visible}
    >
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Wrapper>
          <Table
            size='small'
            pagination={false}
            dataSource={subscriptionInfo}
            columns={columns}
          />
        </Wrapper>
      )}
    </Modal>
  )
}

const Wrapper = styled.div``

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`
