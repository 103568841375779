import * as types from '../../types'
import { fromJS } from 'immutable'

const initState = fromJS({
  show: false,
})

export default (state = initState, action) => {
  let index
  if (action.file_type === 'attachment_one') index = 0
  if (action.file_type === 'attachment_two') index = 1
  if (action.file_type === 'attachment_three') index = 2
  const modal = fromJS(state)
  const show = modal.get('show')
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case types.attachment.MODAL_ATTACHMENT_TOGGLE:
      return modal
        .set('file_type', action.file_type)
        .set('idx', action.idx)
        .set('show', !show)
        .toJS()
    case types.attachment.MODAL_SET_ITEM:
      return modal
        .set('item', { item: action.item, type: action.item_type })
        .toJS()

    case types.attachment.MODAL_ADD_LINE:
      //if (!newState.item.item[action.file_type]) newState.item.item[action.file_type] = []
      if (!newState.item.item.files)
        newState.item.item.files = [] /* [null, null, null] */

      newState.item.item[action.file_type] =
        typeof action.value === 'string'
          ? action.value
          : process.env.REACT_APP_API_MEDIA + action.value.path
      newState.item.item.files[index] = action.value
      return newState

    case types.attachment.MODAL_DELETE_LINE:
      newState.item.item[action.file_type] = ''
      if (!newState.item.item.files) newState.item.item.files = []
      /* [null, null, null] */ else newState.item.item.files[index] = null
      return newState
    default:
      return state
  }
}
