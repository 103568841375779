import styled from 'styled-components'

interface Props {
  wide?: boolean
}

export const Wrapper = styled.div<Props>`
  box-shadow: 0px 2px 8px rgb(0 0 0 / 35%);
  border-radius: 4px;
  position: absolute;
  top: 55px;
  background-color: #fff;
  width: 100%;
  max-width: 700px;
  z-index: 1001;
  font-size: 0.8rem;
  min-width: ${({ wide }) => (wide ? '700px' : 'initial')};
`

export const Content = styled.div``
export const Heading = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const Footer = styled.div`
  background: rgba(196, 196, 196, 0.3);
  padding: 8px;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const MessageText = styled.p<{ error?: boolean }>`
  color: ${({ error }) => (error ? 'red' : 'rgba(0, 0, 0, 0.54)')};
  padding: 5px;
`
