import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import validator from 'validator'
import {
  getCurrentUser,
  setEditModalFields,
  setEditModalPermissions,
  editUser,
  resendUserInvite,
  selectUserRoles,
  getUserRoles,
} from '../../../../store/SuperAdmin/actions/userActions'
import {
  editModalDataSelector,
  sendUserDataSelector,
  resendUserInviteDataSelector,
  userRolesDataSelector,
  meSelector,
} from '../../../../store/SuperAdmin/selectors'

import CheckBox from '../../AntdComponents/CheckBox'
import { MenuItem, TextField } from '@material-ui/core'

import Spinner from '../../../static/icons/spinner'

import s from './style.module.css'
import { ViewFile } from '../../../../helpers/viewFile/viewFile'
import UserPicture from '../../../../containers/MainContent/Orcatec/Settings/components/users/UserForm/UserPicture/UserPicture'

import { upload, deleteMedia } from '../../../../api/Media'
import { permissionList, permissionTitles } from '../utils'

const validate = (value, callback) => {
  const result = callback(value)

  return {
    error: !!result,
    message: result || '',
  }
}

const validationFields = ['full_name', 'email', 'title', 'role_id']
const fileStyle = { width: '100px', height: '100px', borderRadius: '4px' }
const EditUserModal = ({
  onClose,
  currentId,
  getCurrentUser,
  editModalData,
  setEditModalFields,
  setEditModalPermissions,
  editUser,
  sendUserData,
  resendUserInviteData,
  resendUserInvite,
  userRolesData,
  selectUserRoles,
  getUserRoles,
  me,
  isProfileSettings,
  docsStyle,
  handleUpdate,
}) => {
  const { currentUser, loading } = editModalData
  const {
    full_name: fullName,
    email,
    role_id: roleId,
    invite_email: inviteEmail,
    title,
    permissions,
    profile_picture,
    crop,
    info,
  } = currentUser
  const { users, companies, support, emails, email_accounts: emailAccounts } = permissions
  const { sending, sent, error, errorData } = sendUserData
  const { reinviteSending } = resendUserInviteData

  const [docs, setDocs] = useState(null)
  const [uploading, setUploading] = useState(false)
  //const [crop, setCrop] = useState(null)
  const [profilePictureError, setProfilePictureError] = useState('')
  const [errors, setErrors] = useState({})
  const [isMe] = useState(currentId === me.id)

  const [perm, setPerm] = useState(permissionList)

  useEffect(() => {
    getUserRoles()
  }, [])

  useEffect(() => {
    setPerm(permissions)
  }, [permissions])

  useEffect(() => {
    if (currentId) {
      getCurrentUser(currentId, isProfileSettings)
    }
  }, [currentId, isProfileSettings])

  useEffect(() => {
    !profile_picture && info && info.profile_picture && setEditModalFields('profile_picture', info.profile_picture)
  }, [info])

  useEffect(() => {
    if (error && errorData) {
      const newErrors = {
        ...errors,
      }

      validationFields.forEach(field => {
        if (errorData[field] && errorData[field].length) {
          newErrors[field] = {
            error: true,
            message: errorData[field][0],
          }
        }
      })

      setErrors(newErrors)
    }
  }, [sent, error])

  const rules = () => ({
    full_name: value =>
      validate(value, value => {
        return !value ? 'This field is required' : ''
      }),
    email: value =>
      validate(value, value => {
        return !value
          ? 'This field is required'
          : !validator.isEmail(value)
          ? 'This field must be a valid email address'
          : ''
      }),
    title: value =>
      validate(value, value => {
        return !value ? 'This field is required' : ''
      }),
  })

  const validateAll = () => {
    const allRules = rules()
    const newErrors = { ...errors }
    let validated = true

    validationFields.forEach(field => {
      if (allRules[field]) {
        const result = allRules[field](currentUser[field])

        newErrors[field] = result

        if (result.error) {
          validated = false
        }
      }
    })

    setErrors(newErrors)

    return validated
  }

  const handleChangeFields = e => {
    const { name, value } = e.currentTarget
    setEditModalFields(name, value)

    const rule = rules()[name]
    if (rule) {
      setErrors({
        ...errors,
        [name]: rule(value),
      })
    }
  }

  const handleSelect = (e, options) => {
    const roleId = e.target.value
    const { permissions } = options.props
    selectUserRoles({
      roleId,
      permissions,
    })
  }

  const handleSubmit = async () => {
    if (validateAll()) {
      const data = {
        full_name: fullName,
        title,
        email,
        invite_email: inviteEmail,
        role_id: roleId,
        permissions: perm,
        info: {},
      }
      try {
        if (profile_picture instanceof File) {
          setUploading(true)
          const fd = new FormData()
          fd.append('media', profile_picture)
          const res = await upload(fd)
          data.info.profile_picture = res[0].preview
          data.info.profile_picture_id = res[0].id
          if (info && info.profile_picture_id) {
            deleteMedia(info.profile_picture_id)
          }
        } else if (profile_picture && typeof profile_picture === 'string') {
          data.info = { ...info }
        } else if (!profile_picture && info && info.profile_picture) {
          data.info = {
            profile_picture: '',
            profile_picture_id: '',
          }
          info.profile_picture_id && deleteMedia(info.profile_picture_id)
        } else {
          data.info = {
            profile_picture: '',
            profile_picture_id: '',
          }
        }
        await editUser(currentId, data)
        typeof handleUpdate === 'function' && handleUpdate(data.full_name, data.info)
        typeof onClose === 'function' && onClose()
      } catch (error) {
        console.error(error)
      } finally {
        setUploading(false)
      }
    }
  }

  const handleResendInvite = () => {
    if (validator.isEmail(inviteEmail === null ? '' : inviteEmail)) {
      const data = {
        invite_email: inviteEmail,
      }
      resendUserInvite(currentId, data)
    }
  }

  const isShowPreloader =
    loading || sending || uploading ? (
      <div className='admin-spinner'>
        <Spinner fill='#4c53ef' />
      </div>
    ) : null

  const isShowButtonPreloader = reinviteSending ? (
    <>
      Resend Invite
      <div className='admin-spinner'>
        <Spinner fill='#fff' />
      </div>
    </>
  ) : (
    'Resend Invite'
  )

  const renderUserRoles = (userRolesData || []).map(el =>
    el.code === 'DEFAULT_SUPER_ADMIN' ? null : (
      <MenuItem key={el.id} name='role_id' value={el.id} permissions={el.permissions}>
        {el.name}
      </MenuItem>
    ),
  )

  const setCroppedLogo = useCallback(profile_picture => {
    setEditModalFields('profile_picture', profile_picture)
    setEditModalFields('crop', null)
  }, [])

  const onCancelCrop = () => {
    setEditModalFields('crop', null)
  }

  const beforeUploadLogo = useCallback((_, props) => {
    const file = props[0]
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpeg'
    const isLt20M = file.size / 1024 / 1024 <= 20
    if (!isJpgOrPng) {
      setProfilePictureError('Please use jpg, jpeg or png file')
    } else if (!isLt20M) {
      setProfilePictureError('Image must be smaller than 20Mb')
    }
    return isJpgOrPng && isLt20M
  }, [])

  const customUploadRequest = useCallback(({ file }) => {
    setEditModalFields('profile_picture', file)
    setEditModalFields('crop', file)
    setProfilePictureError('')
  }, [])

  const onDeletePicture = useCallback(() => {
    setEditModalFields('profile_picture', '')
    setProfilePictureError('')
  }, [])

  const handlePermissionCheckboxChange = e => {
    setPerm(p => ({ ...p, [e.target.name]: e.target.checked }))
  }

  return (
    <div>
      <UserPicture
        imageToCrop={crop}
        profilePicture={profile_picture}
        setCroppedLogo={setCroppedLogo}
        onCancelCrop={onCancelCrop}
        profilePictureError={profilePictureError}
        beforeUploadLogo={beforeUploadLogo}
        customUploadRequest={customUploadRequest}
        onDeletePicture={onDeletePicture}
      />
      {isMe && (
        <>
          <div className={s.firstRow}>
            <div className={s.fieldFull}>
              <TextField
                id='sa_eu_fullName'
                label='Name'
                placeholder='Type name'
                onChange={handleChangeFields}
                name='full_name'
                value={fullName ? fullName : ''}
                variant='outlined'
                size='small'
                fullWidth={true}
                required
                error={errors.full_name && errors.full_name.error}
                helperText={errors.full_name && errors.full_name.message}
              />
            </div>
          </div>
          <div className={s.firstRow}>
            <div className={s.fieldFull}>
              <TextField
                id='sa_eu_email'
                label='Login Email'
                placeholder='Type login email'
                onChange={handleChangeFields}
                name='email'
                value={email ? email : ''}
                variant='outlined'
                size='small'
                fullWidth={true}
                type='email'
                required
                error={errors.email && errors.email.error}
                helperText={errors.email && errors.email.message}
              />
            </div>
          </div>
          <div className={s.firstRow}>
            <div className={s.fieldFull}>
              <TextField
                id='sa_eu_title'
                label='Title'
                placeholder='Type title'
                onChange={handleChangeFields}
                name='title'
                value={title ? title : ''}
                variant='outlined'
                size='small'
                fullWidth={true}
                required
                error={errors.title && errors.title.error}
                helperText={errors.title && errors.title.message}
              />
            </div>
          </div>
        </>
      )}
      {!isMe && (
        <>
          <div className={s.firstRow}>
            <div className={s.field}>
              <TextField
                id='sa_eu_fullName'
                label='Name'
                placeholder='Type name'
                onChange={handleChangeFields}
                name='full_name'
                value={fullName ? fullName : ''}
                variant='outlined'
                size='small'
                fullWidth={true}
                required
                error={errors.full_name && errors.full_name.error}
                helperText={errors.full_name && errors.full_name.message}
              />
            </div>
            <div className={s.field}>
              {!isMe && (
                <TextField
                  type='text'
                  select
                  label='User Role'
                  placeholder='Select user role'
                  name='role_id'
                  value={roleId}
                  onChange={(e, options) => handleSelect(e, options)}
                  variant='outlined'
                  fullWidth={true}
                  size='small'
                  required
                  error={errors.role_id && errors.role_id.error}
                  helperText={errors.role_id && errors.role_id.message}
                >
                  {renderUserRoles}
                </TextField>
              )}
            </div>
            {!isMe && (
              <div className={s.field}>
                <p className={s.placeholder}>Permissions</p>
                <div className={s.CheckBoxblock}>
                  {Object.keys(permissionList).map((permissionKey, i) => (
                    <div key={i} className={s.CheckBoxPermission}>
                      <CheckBox
                        checked={perm[permissionKey]}
                        name={`${permissionKey}`}
                        onChange={handlePermissionCheckboxChange}
                      >
                        <span className={s.CheckBoxText}>{permissionTitles[permissionKey]}</span>
                      </CheckBox>
                    </div>
                  ))}
                  {/*<div className={s.CheckBoxPermission}>*/}
                  {/*  <CheckBox checked={users} name='users'>*/}
                  {/*    <span className={s.CheckBoxText}>Users</span>*/}
                  {/*  </CheckBox>*/}
                  {/*</div>*/}
                  {/*<div className={s.CheckBoxPermission}>*/}
                  {/*  <CheckBox checked={companies} name='companies'>*/}
                  {/*    <span className={s.CheckBoxText}>Companies</span>*/}
                  {/*  </CheckBox>*/}
                  {/*</div>*/}
                  {/*<div className={s.CheckBoxPermission}>*/}
                  {/*  <CheckBox checked={support} name='support'>*/}
                  {/*    <span className={s.CheckBoxText}>Support</span>*/}
                  {/*  </CheckBox>*/}
                  {/*</div>*/}
                  {/*<div className={s.CheckBoxPermission}>*/}
                  {/*  <CheckBox checked={emails} name='emails'>*/}
                  {/*    <span className={s.CheckBoxText}>Emails</span>*/}
                  {/*  </CheckBox>*/}
                  {/*</div>*/}
                  {/*<div className={s.CheckBoxPermission}>*/}
                  {/*  <CheckBox checked={emailAccounts} name='email_accounts'>*/}
                  {/*    <span className={s.CheckBoxText}>Emails Accounts</span>*/}
                  {/*  </CheckBox>*/}
                  {/*</div>*/}
                </div>
              </div>
            )}
          </div>
          <div className={s.secondRow}>
            <div className={s.fieldSecond}>
              <TextField
                id='sa_eu_email'
                label='Login Email'
                placeholder='Type login email'
                onChange={handleChangeFields}
                name='email'
                value={email ? email : ''}
                variant='outlined'
                size='small'
                fullWidth={true}
                type='email'
                required
                error={errors.email && errors.email.error}
                helperText={errors.email && errors.email.message}
              />
            </div>
          </div>
          {!isMe && (
            <div className={s.thirdRow}>
              <div className={s.fieldThird}>
                <TextField
                  id='sa_eu_invite_email'
                  label='Email for invite'
                  placeholder='Type email for invite'
                  onChange={handleChangeFields}
                  name='invite_email'
                  value={inviteEmail ? inviteEmail : ''}
                  variant='outlined'
                  size='small'
                  fullWidth={true}
                  type='email'
                />
              </div>
              {!currentUser.password_changed_at ||
                (!!currentUser.password_changed_at &&
                  currentUser.role_code === 'ADMIN__ACCOUNT_MANAGER' &&
                  !(currentUser.account_manager || {}).doc_signed && (
                    <div className={s.fieldThird}>
                      <button
                        type='button'
                        className={s.resendBtn}
                        disabled={!validator.isEmail(inviteEmail || '')}
                        onClick={handleResendInvite}
                      >
                        {isShowButtonPreloader}
                      </button>
                    </div>
                  ))}
            </div>
          )}
          <div className={s.fourthRow}>
            <div className={s.field}>
              <TextField
                id='sa_eu_title'
                label='Title'
                placeholder='Type title'
                onChange={handleChangeFields}
                name='title'
                value={title ? title : ''}
                variant='outlined'
                size='small'
                fullWidth={true}
                required
                error={errors.title && errors.title.error}
                helperText={errors.title && errors.title.message}
              />
            </div>
          </div>
        </>
      )}
      {!!currentUser.account_manager &&
        Array.isArray(currentUser.account_manager.documents) &&
        !!currentUser.account_manager.documents.length && (
          <>
            <center>
              <h5>Onboarding Documents</h5>
            </center>
            <div className={s.Files}>
              {currentUser.account_manager.documents.map(doc => {
                const fileName =
                  doc.step === 1
                    ? `nondisclosure_agreement.pdf`
                    : doc.step === 2
                    ? `account_manager_agreement.pdf`
                    : doc.step === 3
                    ? `form_w9.pdf`
                    : ''
                return (
                  <ViewFile
                    key={doc.id}
                    fileName={fileName}
                    file={doc.file}
                    showName={true}
                    fileStyle={docsStyle ? docsStyle : fileStyle}
                    isFullViewNeeded={true}
                  />
                )
              })}
            </div>
          </>
        )}
      <div className={s.buttonsRow}>
        <button type='button' className={s.cancelBtn} onClick={onClose}>
          Cancel
        </button>
        <button type='button' className={s.submitBtn} onClick={handleSubmit}>
          Save
        </button>
      </div>
      {isShowPreloader}
    </div>
  )
}

const mapStateToProps = state => ({
  editModalData: editModalDataSelector(state),
  sendUserData: sendUserDataSelector(state),
  resendUserInviteData: resendUserInviteDataSelector(state),
  userRolesData: userRolesDataSelector(state),
  me: meSelector(state),
})

const mapDispatchToProps = dispatch => ({
  getCurrentUser: (id, isProfileSettings) => dispatch(getCurrentUser(id, isProfileSettings)),
  setEditModalFields: (name, value) => dispatch(setEditModalFields(name, value)),
  setEditModalPermissions: (name, value) => dispatch(setEditModalPermissions(name, value)),
  editUser: (id, data) => dispatch(editUser(id, data)),
  resendUserInvite: (id, data) => dispatch(resendUserInvite(id, data)),
  selectUserRoles: data => dispatch(selectUserRoles(data)),
  getUserRoles: () => dispatch(getUserRoles()),
})

EditUserModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentId: PropTypes.number.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  editModalData: PropTypes.object.isRequired,
  setEditModalFields: PropTypes.func.isRequired,
  setEditModalPermissions: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  sendUserData: PropTypes.object.isRequired,
  resendUserInviteData: PropTypes.object.isRequired,
  resendUserInvite: PropTypes.func.isRequired,
  userRolesData: PropTypes.arrayOf(PropTypes.object),
  selectUserRoles: PropTypes.func.isRequired,
  getUserRoles: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal)
