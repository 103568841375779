import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useFormatAttachedProperty = ([properties, clients, organizations]) => {
  const attachedProperty = useSelector(state => state.orcatec.proposalContact.attachedProperty)
  const [currentTableData, setCurrentTableData] = useState(null)
  const primaryPropertyId = useMemo(() => Array.isArray(properties) && properties.find(el => el?.is_primary)?.id, [
    properties,
  ])
  const allClients = [].concat(clients, organizations)
  const primaryClientId = useMemo(() => Array.isArray(allClients) && allClients.find(el => el?.is_primary)?.id, [
    allClients,
  ])

  useEffect(() => {
    const tableData = attachedProperty

    const safeFind = (data, compare) => {
      return data?.find(el => el.id === compare) || {}
    }

    const formatAttachedProperty = tableData => {
      const targets = ['clients', 'organizations']

      const getRelatedDataValue = (type, first_id, firstKey, secondKey, second_id, third_id) => {
        const relatedData = tableData.reduce((acc, curr) => {
          const parse = curr.related_data && JSON.parse(curr.related_data)
          Array.isArray(parse) ? acc.push(...parse) : acc.push({})
          return acc
        }, [])

        const relatedDataCurrentValue = relatedData?.find(obj => obj.parent_id === first_id)

        if (type === 'check' && relatedDataCurrentValue) {
          if (firstKey && !secondKey) return safeFind(relatedDataCurrentValue[firstKey], second_id).checked
          if (firstKey && secondKey)
            return safeFind(relatedDataCurrentValue[firstKey], second_id)[secondKey]?.find(el => el.id === third_id)
              ?.checked
        }
        if (type === 'input' && relatedDataCurrentValue) {
          if (!firstKey & !secondKey) return relatedDataCurrentValue.proposal_relation
          if (firstKey && !secondKey) return safeFind(relatedDataCurrentValue[firstKey], second_id).proposal_relation
          if (firstKey && secondKey)
            return safeFind(relatedDataCurrentValue[firstKey], second_id)[secondKey]?.find(el => el.id === third_id)
              ?.proposal_relation
        }
      }

      tableData.forEach(property => {
        property.proposal_relation = getRelatedDataValue('input', property.id)
        property.freezed = false
        property.freezed = !!(property.id === primaryPropertyId)
        targets.forEach(value => {
          property[value].forEach(second => {
            second.freezed = false
            if (second.id === primaryClientId) {
              second.freezed = true
              property.freezed = true
            }
            if (typeof second.checkbox === 'undefined') {
              second.checkbox = getRelatedDataValue('check', property.id, value, null, second.id)
              second.proposal_relation = getRelatedDataValue('input', property.id, value, null, second.id)
            }
            second.properties &&
              second.properties.forEach(third => {
                if (third.id === property.id) {
                  third.hideElement = true
                  return
                }
                third.freezed = false
                if (third.id === primaryPropertyId) {
                  third.freezed = true
                  property.freezed = true
                }
                if (typeof third.checkbox === 'undefined') {
                  third.checkbox = getRelatedDataValue('check', property.id, value, 'properties', second.id, third.id)
                  third.proposal_relation = getRelatedDataValue(
                    'input',
                    property.id,
                    value,
                    'properties',
                    second.id,
                    third.id,
                  )
                }
              })

            second.properties = second.properties && second.properties.filter(el => !el.hideElement)

            second.contacts &&
              second.contacts.forEach(third => {
                if (typeof third.checkbox === 'undefined') {
                  third.checkbox = getRelatedDataValue('check', property.id, value, 'contacts', second.id, third.id)
                  third.proposal_relation = getRelatedDataValue(
                    'input',
                    property.id,
                    value,
                    'contacts',
                    second.id,
                    third.id,
                  )
                }
              })
          })
        })
      })

      setCurrentTableData(tableData?.length ? tableData.reverse() : null)
    }

    attachedProperty && Array.isArray(attachedProperty) && formatAttachedProperty(tableData)
  }, [attachedProperty, primaryPropertyId, primaryClientId])

  return [currentTableData, setCurrentTableData]
}
