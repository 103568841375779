import { Input, Popconfirm, Popover, Spin, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Modal } from 'containers/MainContent/Orcatec/components/UI/Modal'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { selectCompanyTimezone } from 'store/Orcatec/selectors/company'
import moment from 'moment-timezone'
import { FULL_DATE_FORMAT } from 'constants/dateFormats'
import { useEffect, useMemo, useState } from 'react'
import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  WarningFilled,
} from '@ant-design/icons'
import useDebounce from 'hooks/useDebounce'
import { IProjectTemplate, ProjectTemplateType } from '../types'
import { selectCompanyTechnicians } from 'store/Orcatec/reducers/settings/company'
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import {
  deleteProposalTemplate,
  fetchProposalTemplates,
  putProposalTemplate,
} from 'api/Proposal'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import {
  selectUserPermissionsByModule,
  selectUserPermissionsByScope,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { updateUserTemplate } from 'store/Orcatec/actions/settings/user'
import { Actions, Heading, Title, Wrapper } from './ProjectTemplates.styles'
import { Select, TextField } from 'components/UIKit'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'

interface Props {
  isLoading: boolean
  onCancel: () => void
  onProjectTemplateAplly: (templateId: number) => void
}

interface Params {
  user_id: number
  search?: string
  type: ProjectTemplateType
  page: number
  limit: number
  column?: string
  sort?: string
}

export const ProjectTemplates = ({
  isLoading,
  onCancel,
  onProjectTemplateAplly,
}: Props) => {
  const me = useAppSelector(selectAuthUser)
  const technicians: { full_name: string; id: number }[] =
    useAppSelector(selectCompanyTechnicians) || []
  const companyTimezone = useAppSelector(selectCompanyTimezone)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const fullAccess =
    useAppSelector(
      selectUserPermissionsByScope([
        ProjectPermissions.PROJECT_CAN_READ_TEMPLATES,
      ]),
    )?.project_can_read_templates === 1

  const [appliedTemplate, setAppliedTemplate] = useState<number | null>(null)
  // const [showConfirmModal, setShowConfirmationModal] = useState(false)
  const [titleError, setTitleError] = useState(false)
  const [editingTemplate, setEditingTemplate] = useState<{
    id: number
    title: string
  } | null>(null)
  const [search, setSearch] = useState('')
  const [projectTemplates, setProjectTemplates] = useState<{
    data: Record<string, IProjectTemplate>
    ids: number[]
  } | null>(null)
  const [allProjectTemplates, setAllProjectTemplates] = useState<{
    data: Record<string, IProjectTemplate>
    ids: number[]
  } | null>(null)
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 10,
  })
  const [loading, setLoading] = useState(false)
  const [loadingTemplate, setLoadingTemplate] = useState(false)
  const [sorter, setSorter] = useState<SorterResult<IProjectTemplate> | null>(
    null,
  )

  const debouncedSearch = useDebounce(search)
  const dispatch = useAppDispatch()

  useEffect(() => {
    getProjectTemplates({
      user_id: me.id,
      type: ProjectTemplateType.PROJECT,
      search: debouncedSearch || undefined,
      limit: pagination.pageSize,
      column: sorter?.field || undefined,
      sort: !sorter?.order
        ? undefined
        : sorter.order === 'ascend'
        ? 'asc'
        : 'desc',
    })
  }, [debouncedSearch])

  useEffect(() => {
    const getAllTemplate = async () => {
      const { data } = await fetchProposalTemplates({
        page: 1,
        limit: 100,
        user_id: me.id,
        type: ProjectTemplateType.PROJECT,
      })
      setAllProjectTemplates({
        data: transformArrayToObj(data),
        ids: data.map((temp: IProjectTemplate) => temp.id),
      })
    }
    getAllTemplate()
  }, [])

  const getProjectTemplates = async (params: Params) => {
    setLoading(true)
    try {
      const {
        data,
        current_page,
        per_page,
        total,
      } = await fetchProposalTemplates(params)
      setProjectTemplates({
        data: transformArrayToObj(data),
        ids: data.map((temp: IProjectTemplate) => temp.id),
      })
      setPagination(prev => ({
        ...prev,
        page: current_page,
        pageSize: per_page,
        total,
      }))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _: Partial<Record<keyof IProjectTemplate, string[]>>,
    sorter: SorterResult<IProjectTemplate> | SorterResult<IProjectTemplate>[],
  ) => {
    getProjectTemplates({
      user_id: me.id,
      type: ProjectTemplateType.PROJECT,
      search: debouncedSearch || undefined,
      page: pagination.current || 1,
      limit: pagination.pageSize || 10,
      column: sorter.column?.dataIndex || undefined,
      sort: !sorter?.order
        ? undefined
        : sorter.order === 'ascend'
        ? 'asc'
        : 'desc',
    })

    setPagination(prev => ({
      ...prev,
      page: pagination.current || 1,
      pageSize: pagination.pageSize || 10,
    }))
    setSorter(prev => ({
      ...prev,
      order: sorter.order,
      field: sorter.column?.dataIndex || undefined,
    }))
  }

  const handleTemplateTitleUpdate = () => {
    if (!editingTemplate?.id) return

    if (!editingTemplate.title) {
      return setTitleError(true)
    }

    handleTemplateUpdate(editingTemplate?.id, {
      key: 'title',
      value: editingTemplate?.title,
    })
  }

  const handleTemplateUpdate = async (
    templateId: number,
    data: { key: string; value: unknown },
  ) => {
    try {
      await putProposalTemplate(templateId, { [data.key]: data.value })
      setEditingTemplate(null)

      setProjectTemplates(prev => ({
        ...prev,
        data: {
          ...prev?.data,
          [templateId]: {
            ...prev.data[templateId],
            [data.key]: data.value,
          },
        },
      }))
    } catch (error) {
      openNotificationWithIcon('error', {
        message: error?.response?.data?.message,
      })
    }
  }

  const handleTemplateUnlink = (template: IProjectTemplate) => () => {
    handleTemplateUpdate(template?.id, {
      key: 'is_shared',
      value: template.is_shared.filter(id => id !== me.id),
    })

    if (!fullAccess)
      setProjectTemplates(prev => ({
        ...prev,
        ids: prev?.ids.filter(id => id !== template.id),
      }))
  }

  const handleTemplateDelete = async (templateId: number) => {
    try {
      setLoading(true)
      await deleteProposalTemplate(templateId)

      setProjectTemplates(prev => ({
        ...prev,
        ids: prev?.ids.filter(id => id !== templateId),
      }))
      setLoading(false)
    } catch (error) {
      openNotificationWithIcon('error', {
        message: error?.response?.data?.message,
      })
    }
  }

  const selectDefaultTemplate = async (templateId: number | null) => {
    // if (templateId === me.proposal_template_id) templateId = null

    setLoadingTemplate(true)

    await dispatch(
      updateUserTemplate(me.id, { template_id: templateId || null }),
    )
    setLoadingTemplate(false)

    if (!templateId) return

    openNotificationWithIcon('success', {
      message: 'Template has been chosen as default',
    })
  }

  const optionsToShareWith = technicians?.map(tech => ({
    label: tech.full_name,
    value: tech.id,
  }))

  const filterOption = (inputValue: string, option: { label: string }) =>
    option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())

  const columns: ColumnsType<IProjectTemplate> = useMemo(
    () => [
      {
        title: 'Template Name',
        dataIndex: 'title',
        key: 'title',
        sorter: true,
        render: (title, record) =>
          record.id === editingTemplate?.id ? (
            <>
              <Input
                autoFocus
                value={editingTemplate.title}
                onChange={e => {
                  if (titleError) setTitleError(false)

                  setEditingTemplate(() => ({
                    id: editingTemplate.id,
                    title: e.target.value,
                  }))
                }}
                suffix={
                  title ? (
                    <CheckOutlined
                      style={{ color: 'green', cursor: 'pointer' }}
                      onClick={handleTemplateTitleUpdate}
                    />
                  ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                  )
                }
                onBlur={handleTemplateTitleUpdate}
              />
              {titleError && (
                <p style={{ fontSize: 10, color: 'red' }}>
                  Title can not be empty
                </p>
              )}
            </>
          ) : (
            <Title
              onClick={() => {
                // if (showConfirmModal) {
                //   return onProjectTemplateAplly(record.id)
                // }

                setAppliedTemplate(record.id)
              }}
            >
              {title}
            </Title>
          ),
        width: 250,
      },
      {
        title: 'Creator',
        dataIndex: 'owner_template_name',
        key: 'owner_template_name',
        sorter: true,
      },
      {
        title: `Creation Date ${moment()
          .tz(companyTimezone)
          .format('(z)')}`,
        dataIndex: 'created_at',
        key: 'created_at',
        render: date =>
          moment(date)
            .tz(companyTimezone)
            .format(FULL_DATE_FORMAT),
        sorter: true,
        width: 180,
      },
      {
        title: 'Shared',
        dataIndex: 'is_shared',
        key: 'is_shared',
        width: 300,
        render: (value, record) =>
          record.user_id === me.id || fullAccess ? (
            <Select
              mode='multiple'
              value={value.filter(id =>
                optionsToShareWith.map(option => option.value).includes(id),
              )}
              showSearch
              style={{ width: '100%' }}
              filterOption={filterOption}
              options={optionsToShareWith?.filter(tech =>
                record.user_id === me.id ? tech.value !== me.id : tech,
              )}
              placeholder='Select users to share with'
              onChange={(_, value) => {
                setProjectTemplates(prev => ({
                  ...prev,
                  data: {
                    ...prev.data,
                    [record.id]: {
                      ...prev.data[record.id],
                      is_shared: value,
                    },
                  },
                }))

                handleTemplateUpdate(record?.id, {
                  key: 'is_shared',
                  value,
                })
              }}
            />
          ) : record.is_shared.includes(me.id) ? (
            <span style={{ fontStyle: 'italic' }}>
              Shared by {record.owner_template_name}
            </span>
          ) : (
            '-'
          ),
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (_, template) => (
          <Actions>
            <Tooltip title='Edit Template name' mouseLeaveDelay={0}>
              <EditOutlined
                onClick={() => {
                  if (
                    !checkAccessControl(
                      permissions[ProjectPermissions.PROJECT_CAN_EDIT_TEMPLATE],
                      template.user_id,
                      me.id,
                    )
                  ) {
                    // if (
                    //   !permissions[ProjectPermissions.PROJECT_CAN_EDIT_TEMPLATE]
                    // )
                    return openNotificationWithIcon('error', {
                      message: 'Permission denied',
                    })
                  }
                  setEditingTemplate({ id: template.id, title: template.title })
                }}
              />
            </Tooltip>
            {template.is_shared.includes(me.id) ? (
              <Tooltip title='Remove from my list' mouseLeaveDelay={0}>
                <Popconfirm
                  title='Remove this template from my list?'
                  onConfirm={handleTemplateUnlink(template)}
                >
                  <CloseCircleOutlined />
                </Popconfirm>
              </Tooltip>
            ) : (
              <Tooltip title='Delete Template' mouseLeaveDelay={0}>
                <Popconfirm
                  title='Are you sure you want to delete this template?'
                  disabled={
                    !checkAccessControl(
                      permissions[
                        ProjectPermissions.PROJECT_CAN_DELETE_TEMPLATE
                      ],
                      template.user_id,
                      me.id,
                    )
                  }
                  onConfirm={() => {
                    handleTemplateDelete(template.id)
                  }}
                >
                  <DeleteOutlined
                    onClick={() => {
                      // if (
                      //   !permissions[
                      //     ProjectPermissions.PROJECT_CAN_DELETE_TEMPLATE
                      //   ]
                      // )
                      if (
                        !checkAccessControl(
                          permissions[
                            ProjectPermissions.PROJECT_CAN_DELETE_TEMPLATE
                          ],
                          template.user_id,
                          me.id,
                        )
                      ) {
                        return openNotificationWithIcon('error', {
                          message: 'Permission denied',
                        })
                      }
                    }}
                  />
                </Popconfirm>
              </Tooltip>
            )}
            {/* <Tooltip title='Set as default Template' mouseLeaveDelay={0}>
              <Popconfirm
                title='Set this Template as default?'
                onConfirm={() => selectDefaultTemplate(template.id)}
              >
                <Radio checked={template.id === me.proposal_template_id} />
              </Popconfirm>
            </Tooltip> */}
          </Actions>
        ),
        width: 80,
      },
    ],
    [editingTemplate, me.proposal_template_id, titleError],
  )

  const templateOptions = allProjectTemplates?.ids
    .map(templateId => allProjectTemplates.data[templateId])
    .map(template => ({
      label: template.title,
      value: template.id,
    }))

  return (
    <Modal
      title='Load Project Template'
      footer={null}
      onCancel={onCancel}
      visible
      width={1200}
      style={{ minWidth: 1000, overflow: 'auto' }}
    >
      <Spin spinning={loading}>
        <Wrapper>
          <Heading>
            <div
              style={{
                display: 'flex',
                gap: 10,
                width: 300,
              }}
            >
              <Select
                allowClear
                name='template'
                placeholder='(None)'
                width='300px'
                label='Default Template'
                value={me.proposal_template_id}
                onChange={e => selectDefaultTemplate(e.target.value)}
                options={[
                  { label: '(None)', value: null },
                  ...(templateOptions || []),
                ]}
                loading={loadingTemplate}
              />

              <Popover
                title='Default Project Template'
                content='This template will be set as a default one and will be applied automatically to all new projects.'
                placement='right'
              >
                <InfoCircleOutlined
                  style={{
                    color: '#1890ff',
                    position: 'relative',
                    top: 8,
                    fontSize: 16,
                    // cursor: 'help',
                  }}
                />
              </Popover>
            </div>
            <TextField
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder='Search by template name...'
              style={{ marginLeft: 'auto' }}
              suffix={<SearchOutlined />}
              width='300px'
            />
          </Heading>
          <Table
            size='small'
            columns={columns}
            dataSource={projectTemplates?.ids.map(
              id => projectTemplates.data[id],
            )}
            // loading={loading}
            onChange={handleTableChange}
            pagination={{
              size: 'small',
              pageSize: pagination.pageSize,
              total: pagination.total,
            }}
          />
        </Wrapper>
      </Spin>

      <Modal
        title='Apply template'
        visible={!!appliedTemplate}
        onCancel={() => setAppliedTemplate(null)}
        onOk={() => {
          if (!appliedTemplate) return
          onProjectTemplateAplly(appliedTemplate)
        }}
        okButtonProps={{ loading: isLoading }}
      >
        <p>
          <WarningFilled
            style={{ color: 'orange', fontSize: 16, paddingRight: 5 }}
          />
          This action will rewrite the current project. Are you sure you want to
          proceed?
        </p>

        {/* <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}
        >
          <Checkbox
            checked={showConfirmModal}
            onChange={() => setShowConfirmationModal(show => !show)}
          >
            Don`t show me this window again
          </Checkbox>
        </div> */}
      </Modal>
    </Modal>
  )
}
