import { INote } from 'containers/MainContent/Orcatec/components/Notes/types'
import {
  AppointmentSource,
  AppointmentType,
  IAppointment,
} from 'types/Appointment'
import axiosOrcatec from './axiosInstance'
import moment from 'moment-timezone'
import { configV2API } from './helpers'
import { IEventSettings } from 'containers/MainContent/Orcatec/Settings/Dispatch/components/EventsTab/EventsTab'

const apiV2Config = {
  baseURL: process.env.REACT_APP_API_URL?.replace('v1.0', 'v2'),
}

/**
 * Create appointment.
 *
 * @return {*}
 */

export interface AppointmentListItem {
  appointment_category: string
  appointment_date: string
  appointment_location: string
  appointment_number: string
  appointment_source: string
  appointment_type: string
  client_name: string
  entity_id: number
  entity_type: number
  id: number
  notes: INote[]
  route_time: string | null
  status: number
  tech: string
  time_spent: string | null
}

export const getAppointmentsList = (params: any) => {
  interface Response {
    data: AppointmentListItem[]
    meta: {
      page: number
      per_page: number
      total: number
    }
  }
  return axiosOrcatec
    .get<Response>('appointments', { params })
    .then(response => response.data)
}

export const getAppointment = (appointmentID: number) =>
  axiosOrcatec
    .get(`/appointments/${appointmentID}`)
    .then(response => response.data)

export const createAppointment = (data: IAppointment) => {
  return axiosOrcatec
    .post<{ appointment: IAppointment[]; status: boolean }>(
      'appointments',
      data,
      apiV2Config,
    )
    .then(response => response.data)
}

export const createAppointmentChild = (data: { parent_id: number }) => {
  return axiosOrcatec
    .post(
      'appointments/create-recurring-child',
      data,
      // apiV2Config,
    )
    .then(response => response.data)
}

export const updateAppoinment = (id: number, data: IAppointment) => {
  return axiosOrcatec
    .put(`appointments/${id}`, data, configV2API)
    .then(response => response.data)
}

export const fetchAppointmentById = (id: number) => {
  return axiosOrcatec.get(`appointments/${id}`).then(response => response.data)
}

export const deleteAppointment = (id: number) => {
  return axiosOrcatec
    .delete(`appointments/${id}`)
    .then(response => response.data)
}

export const getAppoinmentTypes = () => {
  return axiosOrcatec
    .get<AppointmentType[]>('appointment-types')
    .then(response => response.data)
}
export const postAppoinmentTypes = (data: AppointmentType) => {
  return axiosOrcatec
    .post<AppointmentType>('appointment-types', data)
    .then(response => response.data)
}

export const getAppoinmentSources = () => {
  return axiosOrcatec
    .get<AppointmentSource[]>('appointment-sources')
    .then(response => response.data)
}
export const postAppoinmentSources = (data: AppointmentSource) => {
  return axiosOrcatec
    .post<AppointmentSource>('appointment-sources', data)
    .then(response => response.data)
}

export const deleteType = (id: number) => {
  return axiosOrcatec
    .delete(`appointment-types/${id}`)
    .then(response => response.data)
}
export const deleteSource = (id: number) => {
  return axiosOrcatec
    .delete(`appointment-sources/${id}`)
    .then(response => response.data)
}

export const dublicateAppointment = (id: number) => {
  return axiosOrcatec.get(`appointments/copy/${id}`)
}
export const clientsEmails = (appointmentID: number) => {
  return axiosOrcatec
    .get('reviews/clients', {
      params: {
        appointment_id: appointmentID,
      },
    })
    .then(response => response.data)
}

export const saveNewClientEmail = (clientId: number, email: string) => {
  return axiosOrcatec.put(`reviews/clients/${clientId}`, {
    email,
  })
}

export const updateAppointmentReviewsStatus = (id: number, status: number) => {
  return axiosOrcatec
    .put(`reviews/appointments/${id}`, {
      status,
    })
    .then(response => response.data)
}

export const appointmentReviewHistory = (id: number) => {
  return axiosOrcatec
    .get(`review-requests`, {
      params: {
        appointment_id: id,
      },
    })
    .then(response => response.data)
}

export const requestForReview = (
  appointmentID: number,
  text: string,
  clients = null,
  other = null,
  status = null,
  subject = '',
  // from_name = '',
  // reply_to = '',
) => {
  return axiosOrcatec.post('review-requests', {
    appointment_id: appointmentID,
    text,
    clients,
    other,
    status,
    subject,
    // from_name,
    // reply_to,
  })
}

export const fastAppointment = (data: IAppointment) => {
  return axiosOrcatec.post('fast-appointments', data)
}

export const changeAppointmentStatus = (
  appointmentID: number,
  data: { status: number },
) => axiosOrcatec.post(`appointments/${appointmentID}/change-status`, data)

export const exportAppointmentsTable = (params: any) =>
  axiosOrcatec
    .get(`/appointments/table/export`, {
      responseType: 'blob',
      params,
    })
    .then(r => {
      const url = window.URL.createObjectURL(new Blob([r.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Appointments_${moment().format('DD-MM')}.xls`,
      )
      document.body.appendChild(link)
      link.click()
    })

export const getUserTimeSlots = (id: number, time: string) => {
  return axiosOrcatec.get(`/matrix/users/${id}/time-slots/booked?date=${time}`)
}

export const getActualDate = (date: string) => {
  return axiosOrcatec
    .get<{ start_hour: number; end_hour: number }>(
      `matrix/actual-time?date=${date}`,
    )
    .then(res => res.data)
}

export const getForemanLogSheets = (apptId: number | number[]) => {
  const params = { appointment_id: apptId }
  return axiosOrcatec.get(`/foreman_log_sheets`, { params })
}
export const postForemanLogSheets = params => {
  return axiosOrcatec.post('/foreman_log_sheets', params)
}
export const putForemanLogSheets = params => {
  return axiosOrcatec.put(`/foreman_log_sheets/${params.id}`, params)
}

export const getForemanLogSheetsHistory = params => {
  return axiosOrcatec
    .get(`/appointments/sms-notifications`, { params })
    .then(response => response.data)
}

export const exportForemanLogSheets = (params: any) =>
  axiosOrcatec
    .get(`/foreman_log_sheets/export/xls`, {
      responseType: 'blob',
      params,
    })
    .then(r => {
      const url = window.URL.createObjectURL(new Blob([r.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Appointments_${moment().format('DD-MM')}.xls`,
      )
      document.body.appendChild(link)
      link.click()
    })

export const getEventSettings = () => {
  return axiosOrcatec
    .get(`/setting/events`)
    .then(response => response?.data.settings as IEventSettings)
}
export const patchEventSettings = (data: IEventSettings) => {
  return axiosOrcatec
    .patch(`/setting/events`, data)
    .then(response => response?.data?.settings as IEventSettings)
}

export const detachEntityFromAppointment = (
  apptId: number,
  data: { property_id?: number; contact_id?: number },
) => {
  return axiosOrcatec
    .put(`/appointments/detach-entity/${apptId}`, data)
    .then(response => response?.data)
}
