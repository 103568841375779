import './styles.scss'
import Tooltip from 'antd/es/tooltip'
import { Companies } from 'types/Company'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectSummaryContact } from 'features/Project/slices/projectContactsSlice'
import getStatus from 'containers/MainContent/Orcatec/Proposals/helpers/getTabStatus'
import states from 'helpers/states'

const ContactsInformation = ({ proposal, currentTab }) => {
  const { client, property } = useAppSelector(selectSummaryContact)

  return (
    <div className='proposal-contacts-information'>
      <div className='columns'>
        <div className='columns__left'>
          {!!proposal?.setting?.show_tech && (
            <div className='tech-info'>
              <div>
                <p>
                  <span>{proposal.user?.full_name}</span>,{' '}
                  {proposal.user?.title}
                </p>
                {!!proposal.user?.phone && (
                  <p>
                    Cell: <span>{proposal.user?.phone}</span>
                  </p>
                )}
                {!!proposal.user?.email && (
                  <p>
                    Email: <span>{proposal.user?.email}</span>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <div className='columns__right'>
          <div className='proposal-info'>
            <p>
              {/* <span>Project ID: </span> */}
              <span>
                {proposal?.user?.company_id ===
                Companies['Trilogy Surgical Solutions']
                  ? 'Work Order'
                  : getStatus(proposal, currentTab)}{' '}
                #:{' '}
              </span>
              {proposal.code}
            </p>
            <p>
              <span>Issue Date: </span> {proposal?.issue_date}
            </p>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='columns__left clients'>
          {!!client?.length && (
            <div
              className={`clients-info ${
                client?.length < 2 ? 'clients-info--single' : ''
              }`}
            >
              {client
                ?.filter(item => item.display)
                ?.map(client => (
                  <div key={client.id}>
                    <p className='clients-info__name'>
                      <span
                        style={{ fontWeight: 700 }}
                      >{`${client.name}`}</span>
                      {client.proposal_relation && (
                        <span>{`, ${client.proposal_relation}`} </span>
                      )}
                    </p>
                    {client.phones?.map(item => (
                      <p key={item.phone} className='clients-info__phone'>
                        <span>{item.type}</span>: {item.phone}
                      </p>
                    ))}
                    {client.emails?.map(item => (
                      <Tooltip title={item} key={item}>
                        <p className='clients-info__email'>Email: {item}</p>
                      </Tooltip>
                    ))}
                  </div>
                ))}
            </div>
          )}

          {!!property?.length && (
            <div
              className={`properties-info ${
                property?.length < 2 ? 'properties-info--single' : ''
              }`}
            >
              {property
                .filter(item => item.display)
                ?.map(property => (
                  <div key={property.id}>
                    {!!property.proposal_relation && (
                      <p className='property-relation'>
                        {property.proposal_relation}
                      </p>
                    )}
                    <p>{property.address}</p>
                    {!!property.unit && <p>Unit: {property.unit}</p>}
                    <p>
                      {property.city}
                      {`${property.postcode ? ',' : ''}`} {property.postcode}
                    </p>
                    <p>
                      {property?.state
                        ? states?.[property?.state] || property?.state
                        : ''}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className='columns__right'>
          {/* {!clientView ? (
            <InstallationDates
              installationDates={proposal.installation_dates}
              onChangeInstallationDates={onChangeInstallationDates}
              allWorkCompleted={proposal.all_work_completed}
            />
          ) : (
            !!proposal.jobs?.length &&
            proposal.status !== 1 && (
              <div className='work-completion-dates'>
                <p>Work Completion Dates</p>
                <div className='dates'>
                  {proposal.jobs?.map(job => (
                    <p key={job.id}>{job.date}</p>
                  ))}
                </div>
              </div>
            )
          )} */}
        </div>
      </div>
    </div>
  )
}

export default ContactsInformation
