import React, { useEffect, useMemo, useRef, useState } from 'react'
import Status from '../Status'
import ModalUpdateCard from '../ModalUpdateCard'
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper'
import moment from 'moment-timezone'
import { priceToView } from 'helpers/thousandSeparator'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import ModalPaySubscription from '../ModalPaySubscription'
import { paySubscription } from 'api/settings/Subscription'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { Space, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import './index.scss'
import { useAppSelector } from 'store/Orcatec/hooks'
import { isMessagingSubmitted } from 'store/Orcatec/selectors/messaging'
import { isCompanyHasMessanging } from 'store/SuperAdmin/selectors'
const modalUpdateCard = 'ModalUpdateCard'
const modalCancelSubscription = 'ModalWrapper'
const modalPaySubscription = 'ModalPaySubscription'

const requiredText = 'This field is required'

const initialCard = {
  billing_address: {},
  card_holder: '',
  expiry: '',
  token: '',
}

export const checkCardError = err => {
  if (
    err.response &&
    err.response.status === 422 &&
    !!err.response.data &&
    err.response.data.code === 1001 &&
    !!err.response.data.error
  ) {
    return err.response.data.error
  } else {
    return ''
  }
}

export const getPrice = price => price ?? 0

const InfoSection = props => {
  const [visible, setVisible] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const parentEl = useRef(null)
  const [sending, setSending] = useState(false)
  const [paySubscriptionCardData, setPaySubscriptionCardData] = useState(
    initialCard,
  )

  const [fieldErrors, setFieldErrors] = useState({})
  const [isNewCard, setIsNewCard] = useState(false)
  const [isOldCardChanged, setIsOldCardChanged] = useState(false)
  const isWestCoastHeating = useAppSelector(
    state => state.orcatec.company.id === 286,
  ) //for west coast company HARDCODE
  const subscription = useAppSelector(state => state.orcatec.subscription)
  const isMessaging = useAppSelector(isMessagingSubmitted) //for users
  const isMessanginForSA = useAppSelector(isCompanyHasMessanging) //if in SA
  // console.log(subscription)

  const showModal = name => () => setVisible(name)

  const handleCancel = () => setVisible(false)

  const token = useMemo(() => props.data?.token, [props.data?.token])
  const expiry = useMemo(() => props.data?.expiry, [props.data?.expiry])

  useEffect(() => {
    if (sending) {
      sendDataOfCard()
    }
  }, [sending])

  useEffect(() => {
    if (!visible) {
      setError(false)
      setLoading(false)
      setSending(false)
      setFieldErrors({})
      setIsNewCard(false)
      setIsOldCardChanged(false)
    }
  }, [visible])

  const isPayModuleInBilling = title => {
    if (!props.moduleList) return
    const moduleDateOfCharge = !props.moduleList[title]?.is_custom
      ? moment.utc(props.data?.schedule_charge?.[0])
      : moment.utc(props.moduleList?.[title]?.date_charge)
    const isExpiredInThisCharge = moment
      .utc(moduleDateOfCharge)
      .subtract(60, 'seconds')
      .isBefore(moment.utc(props.data?.next_billing), 'seconds')
    return !!(
      (props.currentJournal &&
        props.currentJournal?.pay_modules?.[title]?.paid &&
        isExpiredInThisCharge) ||
      (!props.currentJournal && !props.moduleList[title].is_custom)
    )
  }

  // const isTrialModule = title => {
  //   return true
  // }

  const getTotalBilling = () => {
    if (!props.moduleList) return
    return Object.keys(props.moduleList)?.reduce((acc, currentValue) => {
      const price = isPayModuleInBilling(currentValue)
        ? props.moduleList[currentValue].price *
          props.data?.current_journal[0]?.count_users?.length
        : 0
      return acc + price
    }, 0)
  }

  const handleUpdateCard = () => validationCard(props.data)

  const handlePaySubscription = () => validationCard(paySubscriptionCardData)

  const validationCard = cardData => {
    if (
      !cardData?.card_holder ||
      !cardData?.billing_address.region ||
      !cardData?.billing_address.city ||
      !cardData?.billing_address.address ||
      !cardData?.billing_address.postal ||
      !cardData?.token ||
      !cardData?.expiry
    ) {
      // setFieldErrors(prev => ({
      //   ...prev,
      //   card_holder: !cardData?.card_holder ? requiredText : '',
      //   region: !cardData?.billing_address?.region ? requiredText : '',
      //   city: !cardData?.billing_address?.city ? requiredText : '',
      //   address: !cardData?.billing_address?.address ? requiredText : '',
      //   postal: !cardData?.billing_address?.postal ? requiredText : '',
      //   cvv: prev.cvv ? prev.cvv : !cardData?.token || !cardData?.expiry ? requiredText : '',
      //   expiry: prev.expiry ? prev.expiry : !cardData?.token || !cardData?.expiry ? requiredText : '',
      //   cardNumber: prev.cardNumber ? prev.cardNumber : !cardData?.token || !cardData?.expiry ? requiredText : '',
      // }))
      setError(true)
    } else {
      setLoading(true)
      setTimeout(() => setSending(true), 1000)
    }
  }

  const sendDataOfCard = () => {
    let data
    let query
    if (visible === modalPaySubscription) {
      data = { ...paySubscriptionCardData, token }
      query = paySubscription
    } else {
      data = { ...props.data, token, expiry: isNewCard ? expiry : '' }
      query = props.updateSubscription
    }
    if (token) {
      query(props.data.id, data)
        .then(res => {
          setVisible(false)
          setError(false)

          openNotificationWithIcon('success', {
            message: res.message,
            description: '',
            key: 'property-save-in-process',
            duration: 4,
          })
        })
        .catch(err => {
          const cardError = checkCardError(err)
          if (cardError) {
            setError(cardError)
          }
        })
        .finally(() => {
          setSending(false)
          setLoading(false)
        })
    } else {
      setSending(false)
      setLoading(false)
      setError(true)
    }
  }

  const confirmCancelSubscription = () => {
    props.switchSubscription({
      cancel_charge: props.subscription.cancel_charge,
    })
    setVisible(false)
  }

  const checkIsItemTrial = (item, moduleKey) => {
    if (props.currentJournal?.pay_modules[moduleKey]?.paid) {
      const currentDay = moment()
        .tz('PST8PDT')
        .format('HH:mm MM/DD/YYYY')
      const expiryDay = moment(item?.date_of_expiry).format('HH:mm MM/DD/YYYY')
      const difference = moment(expiryDay).diff(currentDay, 'hours')

      return difference > 0
    } else return true
  }

  const checkUserModule = item => {
    if (checkIsItemTrial(item, 'users')) return 'Trial'
    // else return `$${(+props.data?.active_users - 1) * +props.data?.price_for_user}`
    else return `$${props.data.total_price_users}`
  }

  const checkTotalBilling = () => {
    // if (props.isSA) return `Total: $${getTotalBilling()}`
    // else return `Total: $${props.data?.total_amount}`
    return `Total: $${props.data?.total_amount}`
  }

  const currentJournal = props?.currentJournal
  const payModules = (() => {
    delete currentJournal?.pay_modules?.['basic_modules']
    return currentJournal?.pay_modules
  })()

  const bills = props.data?.bills || {
    count_checks: 0,
    total_amount_checks: 0,
    count_transactions: 0,
    total_amount_cards: 0,
  }

  const customPrices = [
    {
      // tooltipText: 'Transactions Fee',
      // title: `Total for ${bills.count_checks} ACH transactions`,
      title: `Total for ACH transactions`,
      total: bills.total_amount_checks || 0,
      count: bills.count_checks || 0,
    },
    {
      // tooltipText: 'Credit Card Processing',
      // title: `Total for ${bills.count_transactions} card transactions`,
      title: `Credit Card Processing`,
      total: bills.total_amount_cards || 0,
      count: bills.count_transactions || 0,
    },
  ]
  const styles = {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '94%',
  }

  // console.log(props.data)

  return (
    <div className='info_section_container'>
      <div className='info_general'>
        <div className='subscription_summary'>
          {/*<div style={styles}>*/}
          {/*  Transactions Fee: <span>$ {props.data.ach_price}</span>*/}
          {/*</div>*/}
          {/*<div style={styles}>*/}
          {/*  Credit Card Processing Fee: <span>% {props.data.credit_card_price}</span>*/}
          {/*</div>*/}
          <p className='title'>
            <strong>
              {`Estimated cost for the period from ${moment(
                props.data?.current_journal?.start_period,
              ).format('MMMM DD')} to ${moment(
                props.data?.current_journal?.end_period,
              ).format('MMMM DD')} :`}
            </strong>
            {/*<span>{moment(props.data?.next_billing).format('MMMM DD YYYY')}</span>*/}
            {/*<span className='trial-value__days'>*/}
            {/*  {props.data?.days_left > 0 && <i> {props.data?.days_left} days left</i>}*/}
            {/*</span>*/}
          </p>
          <div className='summary_info'>
            <div className='summary_items'>
              {props.moduleList &&
                Object.keys(props.moduleList)
                  .filter(el => el !== 'basic_modules')
                  .map(
                    moduleKey =>
                      (props.currentJournal?.pay_modules?.[moduleKey]?.paid ||
                        !props.currentJournal) && (
                        <p key={moduleKey} className='summary_items__title'>
                          <span>{props.moduleList[moduleKey]?.title}</span>
                          <span className='summary_items__price'>
                            {checkIsItemTrial(
                              props.moduleList.storage,
                              'storage',
                            )
                              ? 'Trial'
                              : moduleKey === 'storage'
                              ? priceToView(props.data?.total_price_storage)
                              : moduleKey === 'users'
                              ? checkUserModule(
                                  props.moduleList[moduleKey],
                                  moduleKey,
                                )
                              : checkIsItemTrial(
                                  props.moduleList[moduleKey],
                                  moduleKey,
                                )
                              ? 'Trial'
                              : `$${getPrice(
                                  (() => {
                                    return (
                                      payModules?.[moduleKey]?.amount *
                                      props.data?.current_journal[0]
                                        ?.count_users?.length
                                    )
                                  })(),
                                )}`}
                          </span>
                        </p>
                      ),
                  )}

              {/**/}
              {customPrices.map(({ title, count, total }) =>
                !!total ? (
                  <p className='summary_items__title'>
                    {title}
                    <span className='summary_items__price'>${total}</span>
                  </p>
                ) : null,
              )}
              {(!!isMessaging || !!isMessanginForSA) && (
                <p className='summary_items__title'>
                  Messaging
                  <span className='summary_items__price'>
                    {priceToView(
                      props?.data?.messaging?.sms_mms?.total +
                        props?.data?.messaging?.phones?.total +
                        props?.data?.messaging?.messaging_monthly_fee,
                    )}
                  </span>
                </p>
              )}

              {props?.data?.call_records_info?.show_info && (
                <p className='summary_items__title'>
                  Calls Tracking
                  <span className='summary_items__price'>
                    {priceToView(
                      props?.data?.call_records_info?.call_records?.total +
                        props?.data?.call_records_info?.phones?.total +
                        (props?.data?.call_records_info?.call_monthly_fee ?? 0),
                    )}
                  </span>
                </p>
              )}
              {!!props?.data?.external_link_info?.link_count && (
                <p className='summary_items__title'>
                  Public Links
                  <span className='summary_items__price'>
                    {priceToView(
                      props?.data?.external_link_info?.total_amount_links || 0,
                    )}
                  </span>
                </p>
              )}

              {!!props?.data?.user_salary_info?.length && (
                <p className='summary_items__title'>
                  Employee payroll
                  <span className='summary_items__price'>
                    {priceToView(
                      props?.data?.user_salary_info?.reduce(
                        (sum, item) => (sum += item.salary),
                        0,
                      ),
                    )}
                  </span>
                </p>
              )}

              {/**/}
              <p className='total_amount'>{checkTotalBilling()}</p>
              <p>
                Approximate next month billing:{' '}
                {props.isSA
                  ? props.data?.next_total_amount
                  : props.subscription?.next_total_amount}
              </p>
            </div>
          </div>
        </div>
        {!props.isSA && (
          <>
            {!props.subscription?.cancel_charge &&
              props.subscription?.status !== 1 && (
                <div className='due-info'>
                  <div className='due-info__price'>
                    <p>Previous Bill:</p>

                    <p>
                      {priceToView(getPrice(props.subscription?.previous_bill))}
                    </p>

                    <p>Overdue</p>
                    {props.subscription?.status !== 1 && (
                      <MainButton
                        title='Pay'
                        onClick={showModal(modalUpdateCard)}
                      />
                    )}
                  </div>
                  <div className='due-info__date'>
                    <p>Due Date</p>
                    <p>
                      {moment(props.subscription?.next_charge).format(
                        'MM/DD/YYYY',
                      )}
                    </p>
                  </div>
                </div>
              )}
            {!props.subscription?.cancel_charge &&
              props.subscription?.status === 2 && (
                <div className='charge_date_container overdue'>
                  <p>
                    If overdue balance is not paid, your subscription status
                    will change to Read Only in{' '}
                    <span>
                      {props.subscription?.days_until_change_status} Days
                    </span>
                  </p>
                </div>
              )}
          </>
        )}
        <div className='status_container'>
          <div className='parent'>
            <p>Current Subscription Status:</p>
            {props.data?.status && <Status status={props.data?.status} />}
          </div>
          <p className={'message-cancel-subscription'}>
            {(props.subscription.cancel_charge || props.data.cancel_charge) && (
              <i>
                Your subscription for the next period is cancelled and will not
                be renewed.
              </i>
            )}
          </p>
        </div>
        {!props.isSA && (
          <div className='subscription-buttons'>
            <MainButton
              type='cancel'
              title={
                props.subscription.cancel_charge
                  ? 'Activate Subscription'
                  : 'Cancel Subscription'
              }
              onClick={showModal(modalCancelSubscription)}
            />
          </div>
        )}
      </div>

      {!props.isSA && (
        /* props.subscription?.card_holder  && */ <>
          <div className='info_credit_card'>
            <div className='btn_edit_box'>
              <p>Credit card</p>
              <button
                className='edit_card'
                onClick={showModal(modalUpdateCard)}
              >
                Edit
              </button>
            </div>
            <div className='credit_card_data'>
              {props.subscription?.card_holder ? (
                <>
                  <p>
                    <span>{props.subscription?.card_holder}</span>
                    <span>{props.subscription?.card_number}</span>
                  </p>
                  {/* <p>
                    <span>Expires</span>
                    <span>{props.subscription?.expiryDate}</span>
                  </p> */}

                  {!!Object.keys(props.subscription).length && (
                    <div className='address'>
                      <p>{props.subscription?.billing_address?.address} </p>
                      <span>{props.subscription?.billing_address?.city}, </span>
                      <span>
                        {props.subscription?.billing_address?.region},{' '}
                      </span>
                      <span>{props.subscription?.billing_address?.postal}</span>
                    </div>
                  )}
                </>
              ) : (
                <p style={{ justifyContent: 'center' }}>
                  Please add payment info
                </p>
              )}
            </div>
          </div>
          {/* {props.data.status !== 1 && (
            <MainButton
              title={'Pay Now'}
              className='pay-subscription__btn'
              onClick={showModal(modalPaySubscription)}
            />
          )} */}
        </>
      )}

      <ModalUpdateCard
        parentEl={parentEl}
        loading={loading}
        error={error}
        cardData={props.data}
        visible={visible === modalUpdateCard}
        handleOk={handleUpdateCard}
        setData={props.setData}
        handleCancel={handleCancel}
        fieldErrors={fieldErrors}
        setFieldErrors={setFieldErrors}
        setError={setError}
        isNewCard={isNewCard}
        setIsNewCard={setIsNewCard}
        isOldCardChanged={isOldCardChanged}
        setIsOldCardChanged={setIsOldCardChanged}
        isDebtor={props.subscription?.status === 2}
        amount={props.subscription?.total_amount}
      />

      <ModalWrapper
        modal={visible === modalCancelSubscription}
        close={handleCancel}
        apply={confirmCancelSubscription}
        okText='Confirm'
        dialogClassName='confirm_modal_container'
      >
        {props.subscription.cancel_charge ? (
          <div className='confirm_turn_off'>
            <div className='confirm_title'>
              Are you sure you want to activate subscription?
            </div>
            <div className='confirm_description'>
              When activate - you will be charged on the next billing cycle.
            </div>
          </div>
        ) : (
          <div className='confirm_turn_off'>
            <div className='confirm_title'>
              Are you sure you want to cancel subscription?
            </div>
            <div className='confirm_description'>
              After subscription is expired - the functionality of the system
              will be limited.
            </div>
          </div>
        )}
      </ModalWrapper>
      <ModalPaySubscription
        parentEl={parentEl}
        loading={loading}
        error={error}
        cardData={paySubscriptionCardData}
        visible={visible === modalPaySubscription}
        handleOk={handlePaySubscription}
        setData={setPaySubscriptionCardData}
        handleCancel={handleCancel}
        fieldErrors={fieldErrors}
        setFieldErrors={setFieldErrors}
        setError={setError}
        isNewCard={isNewCard}
        setIsNewCard={setIsNewCard}
        isOldCardChanged={isOldCardChanged}
        setIsOldCardChanged={setIsOldCardChanged}
        // isDebtor={props.subscription.status === 2}
        amount={getTotalBilling()}
      />
    </div>
  )
}

export default InfoSection
