import React from 'react'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
}

export const AuthLayout = ({ children }: Props) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 15px;
  min-height: 100vh;
  background: url('/assets/images/Auth/background.jpg');
  background-position: center;
  background-size: cover;
`
const Content = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8vh;
`
