import React from 'react'
import './AuthLayout.scss'
import { Link } from 'react-router-dom'

const AuthLayout = ({ children }) => {
  return (
    <div className='auth-layout-wrapper'>
      <div className='orcatec-background' />
      <div className='auth-layout-content'>
        {/* <img className='auth-beta' src='/assets/beta.svg' alt='beta' /> */}
        <div className='orcatec-logo' />
        <div className='auth-layout-form'>{children}</div>

        <span className='auth-layout-term-and-conditions'>
          <a
            href='https://orcatec.com/img/pdf/terms_and_conditions.pdf'
            className='text-primary'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms & Conditions
          </a>
          <span>{` | `}</span>
          <a
            href='https://orcatec.com/img/pdf/privacy_policy.pdf'
            className='text-primary'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
        </span>
      </div>
    </div>
  )
}

export default AuthLayout
