import styled from 'styled-components'

export const HeaderWrapper = styled.header`
  /* height: 75px; */

  height: ${({ minimizeView }) => (minimizeView ? '55px' : '75px')};
  padding: 0 10px;
  display: flex;
  align-items: center;
  //box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  //border-radius: 12px;
  border-bottom: 1px solid #8080802b;
  justify-content: space-between;

  @media print {
    display: none;
  }
`
export const ClockInButtonStyle = styled.div`
  width: 175px;
  & > .divWrapper {
    margin-left: 0;
    width: 175px;
  }
`
export const ClockInStyle = styled.div``

export const NavbarBlock = styled.div`
  width: calc(100% - 300px);
`

export const AvatarBlock = styled.div`
  width: 150px;
  height: 69.8px;
  cursor: pointer;
`
export const AvatarWrapper = styled.div`
  height: 69.8px;
  cursor: pointer;
`

export const CompactClockBtnStyle = styled.div`
  width: 175px;
  margin-left: auto;
  margin-right: 15px;
  & > .divWrapper {
    width: 200px;
    margin-left: 0;
  }
`
