import * as todo from '../../../api/Todo'
import * as types from '../types'
import * as user from '../../../api/User'

export const setTodoList = response => {
  return {
    type: types.todo.SET_TODO_LIST,
    response,
  }
}
export const setNewTodoTask = response => {
  return {
    type: types.todo.SAVE_TODO_TASK,
    response,
  }
}
export const getTodoList = (page, limit, search, sortBy, sortOrder, filter) => dispatch => {
  const data = {}
  if (page) data.page = page
  if (limit) data.limit = limit
  if (search) data.search = search
  if (sortBy && sortOrder) {
    data.sortBy = sortBy
    data.sortOrder = sortOrder
  }

  //map filter options to query string
  Object.entries(filter).map(f => {
    if (f[1].length)
      data.filter = {
        ...data.filter,
        [`filter[${f[0]}]`]: JSON.stringify(f[1]),
      }
  })

  return todo.getTodoList(data).then(response => dispatch(setTodoList(response)))
}

export const createTodoTask = data => dispatch => {
  user.getAuthUserId()
  return todo.createTodoTask(data)
}

export const updateTodoTask = (id, data) => dispatch => {
  user.getAuthUserId()
  return todo.updateTodoTask(id, data)
}

export const deleteTodoTask = id => dispatch => {
  user.getAuthUserId()
  return todo.deleteTodoTask(id)
}

export const updateTodoTaskStatus = (id, status) => dispatch => {
  user.getAuthUserId()
  return todo.updateTodoTaskStatus(id, status)
}
