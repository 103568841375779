// COMMON
export const isOpenDrawerSelector = state => state.superAdmin.commonData.isOpenDrawer
// USERS
export const usersListDataSelector = state => state.superAdmin.users.usersListData
export const createModalDataSelector = state => state.superAdmin.users.createModalData
export const editModalDataSelector = state => state.superAdmin.users.editModalData
export const sendUserDataSelector = state => state.superAdmin.users.sendUserData
export const resendUserInviteDataSelector = state => state.superAdmin.users.resendInviteUserData
export const userRolesDataSelector = state => state.superAdmin.users.userRolesData
export const meSelector = state => state.orcatec.user.me

// COMPANIES
export const companiesListDataSelector = state => state.superAdmin.companies.companiesListData
export const currentCompanyDataSelector = state => state.superAdmin.companies.currentCompany
export const currentCompanyLoadingSelector = state => state.superAdmin.companies.currentCompany.loading
export const currentCompanyNameSelector = state =>
  state.superAdmin.companies.currentCompany.company
    ? state.superAdmin.companies.currentCompany.company.company_name
    : null
export const sendCompanyDataSelector = state => state.superAdmin.companies.sendCompanyData
export const getRoleId = state => state.orcatec.user.me.role_id

// MAIL ACCOUNTS
export const mailAccountsListDataSelector = state => state.superAdmin.mailAccounts.listData
export const mailAccountsEditDataSelector = state => state.superAdmin.mailAccounts.editData
export const mailAccountsSendDataSelector = state => state.superAdmin.mailAccounts.sendData

// SUPPORT
export const supportTicketsListDataSelector = state => state.superAdmin.support.listData
export const supportTicketDataSelector = state => state.superAdmin.support.ticketData
export const supportCreateMessageDataSelector = state => state.superAdmin.support.createMessageData
export const supportUpdateMessageDataSelector = state => state.superAdmin.support.updateMessageData
export const supportUnseenTicketsSelector = state => state.superAdmin.support.unseenTickets

//SUBSCRIBTION
export const subscriptionSelector = state => state.orcatec.subscription
export const isCompanyHasMessanging = state =>
  !!state?.superAdmin?.companies?.currentCompany?.company?.subscription?.company?.messaging_account?.brand_id &&
  !!state?.superAdmin?.companies?.currentCompany?.company?.subscription?.company?.messaging_account?.campaign_id
