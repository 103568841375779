import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
// import { TimePicker } from 'antd'
import { KeyboardTimePicker } from '@material-ui/pickers'

import moment from 'moment-timezone'
const TimeModal = ({ modal, apply, close, suggestionTime }) => {
  const [timeStart, changeTimeStart] = useState(moment(suggestionTime, 'HH:mm'))
  const [timeEnd, changeTimeEnd] = useState(
    moment(moment(suggestionTime, 'HH:mm').hours(moment(suggestionTime, 'HH:mm').hours() + 2), 'HH:mm'),
  )
  const [error, toggleError] = useState(false)
  useEffect(() => {
    if (suggestionTime) {
      changeTimeStart(moment(suggestionTime, 'HH:mm'))
      changeTimeEnd(moment(moment(suggestionTime, 'HH:mm').hours(moment(suggestionTime, 'HH:mm').hours() + 2), 'HH:mm'))
    }
  }, [suggestionTime])
  const range = (start, end) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }
  const validate = () => {
    if (moment(timeStart).isAfter(moment(timeEnd))) {
      toggleError(true)
      changeTimeEnd(moment(timeStart))
      setTimeout(() => {
        toggleError(false)
      }, 4000)
    }
  }
  useEffect(() => {
    validate()
  }, [timeEnd, timeStart])
  return (
    <Modal show={modal} onHide={close} backdrop='static' className='select-time-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Promised arrival time:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row mt-2 mb-2'>
          {/* <p>Promissed arival time:</p> */}
          <label className='col-4'>From *</label>
          <KeyboardTimePicker
            className='col-4'
            value={timeStart}
            onChange={e => {
              changeTimeStart(e)
              changeTimeEnd(moment(e, 'HH:mm a').hours(moment(e, 'HH:mm a').hours() + 2))
            }}
            style={{ width: '100%', color: 'rgba(0, 0, 0, 0.6)' }}
            keyboardIcon={<i className='far fa-clock' style={{ color: 'rgba(0, 0, 0, 0.6)' }} />}
            inputProps={{
              style: {
                color: 'rgba(0, 0, 0, 0.6)',
              },
            }}
            placeholder='Start Time'
            format='h:mm a'
            use12Hours={true}
            minuteStep={15}
          />
        </div>
        <div className='row mt-2 mb-2'>
          <label className='col-4'>To *</label>
          <KeyboardTimePicker
            className='col-4'
            style={{ width: '100%', color: 'rgba(0, 0, 0, 0.6)' }}
            keyboardIcon={<i className='far fa-clock' style={{ color: 'rgba(0, 0, 0, 0.6)' }} />}
            inputProps={{
              style: {
                color: 'rgba(0, 0, 0, 0.6)',
              },
            }}
            value={timeEnd}
            onChange={changeTimeEnd}
            disabledHours={() => range(0, moment(timeStart).hours())}
            placeholder='End Time'
            format='h:mm a'
            use12Hours={true}
            minuteStep={15}
          />
          {error && <span className='error col-12 mt-1'>Cannot be earlier than start time</span>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-custom-cancel' onClick={close}>
          Cancel
        </button>
        <button
          className='btn btn-custom-info'
          disabled={!timeStart || !timeEnd}
          onClick={() => apply(timeStart, timeEnd)}
        >
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export { TimeModal }
