export const USER_SET_ITEM = 'USER_SET_ITEM'
export const USER_SET_ME = 'USER_SET_ME'
//export const USER_SET_MEME = 'USER_SET_MEME'
export const USER_GET_ITEM = 'USER_GET_ITEM'
export const USER_DELETE_ITEM = 'USER_DELETE_ITEM'
export const USER_CREATE_ITEM = 'USER_CREATE_ITEM'
export const USER_UPDATE_ITEM = 'USER_UPDATE_ITEM'
export const USER_FETCH_ITEM = 'USER_FETCH_ITEM'
export const USER_SET_LIST = 'USER_SET_LIST'
export const USER_SET_CREDIT_CARD = 'USER_SET_CREDIT_CARD'
export const USER_SET_PROPOSAL_TEMPLATE_ID = 'USER_SET_PROPOSAL_TEMPLATE_ID'
export const USER_SET_MERCHANT_STATUS = 'USER_SET_MERCHANT_STATUS'
export const USER_SET_ONBOARDED = 'USER_SET_ONBOARDED'
export const UPDATE_UNREAD_TASK_COUNTER = 'UPDATE_UNREAD_TASK_COUNTER'
export const CHANGE_MATRIX_WEEK = 'CHANGE_MATRIX_WEEK'
export const UPDATE_WORK_TIME = 'UPDATE_WORK_TIME'
export const UPDATE_TOOLTIP_MODE = 'UPDATE_TOOLTIP_MODE'
export const USER_ADD_INDUSTRY = 'USER_ADD_INDUSTRY'
export const USER_REMOVE_INDUSTRY = 'USER_REMOVE_INDUSTRY'
