import {
  IconCardv2,
  IconDoc,
  IconWallet,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import styled from 'styled-components'

export const AuthMarketing = () => {
  return (
    <Wrapper>
      <img
        src='/assets/images/Auth/starr.png'
        style={{ width: 50, marginBottom: 16 }}
      />
      <Title>Start managing your business with Orcatec</Title>

      <Users>
        <StyledImg src='/assets/images/Auth/photos.png' />
        <Text>Join 1,000 + users</Text>
      </Users>

      <Tiles>
        <Tile>
          <IconWallet />
          No setup fees
        </Tile>
        <Tile>
          <IconCardv2 />
          No credit card needed
        </Tile>
        <Tile>
          <IconDoc />
          No contract needed
        </Tile>
      </Tiles>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: #fff;
  font-weight: 500;
  max-width: 480px;

  @media (max-width: 1000px) {
    display: none;
  }
`
const StyledImg = styled.img`
  width: 152px;
`
const Title = styled.h1`
  font-size: 50px;
  color: #fff;
`

const Users = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 32px;
`
const Text = styled.p`
  font-size: 16px;
`
const Tiles = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`
const Tile = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #ffffff40;

  span {
    font-size: 28px;
  }
`
