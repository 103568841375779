import { useEffect, useState, memo, useCallback } from 'react'
import { Popconfirm, Tooltip } from 'antd'
import validator from 'validator'
import {
  // DeleteIcon,
  PdfIcon,
  BaseIcon,
  McWordIcon,
  McExelIcon,
  McPptIcon,
  VideoIcon,
  AudioIcon,
  TxtIcon,
  // ImgIcon,
  WebIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import './styles.scss'
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'

const defaultAction = function() {
  return
}

const placeholder = 'url(/assets/images/placeholder-image.png)'

const defaultObject = {}
const RenderFile = memo(
  ({
    tooltipPlacement,
    isLogo,
    previewFileUrl,
    fullFileUrl,
    name,
    fileStyle = defaultObject,
    isFullViewNeeded,
    // dellIconStyle = defaultObject,
    onDelete,
    // onPreview,
    containerClassName,
    // isDellConfirmation,
    showName,
    // customDellIconClassName,
    isUsersTable,
    withPlaceholder,
  }) => {
    const [isHasPreview, setisHasPreview] = useState(true)
    useEffect(() => {
      const img = new Image()
      img.src = previewFileUrl
      img.onerror = () => {
        setisHasPreview(false)
      }
    }, [previewFileUrl])
    const getFinalFileStyle = useCallback(
      () => ({
        position: onDelete ? 'relative' : 'initial',
        width: '150px',
        height: '150px',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        borderRadius: '8px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: isHasPreview
          ? `url(${previewFileUrl})${withPlaceholder ? `, ${placeholder}` : ''}`
          : placeholder,
        // cursor: isFullViewNeeded ? 'zoom-in' : 'default',
        ...fileStyle,
      }),
      [fileStyle, onDelete, isFullViewNeeded, isHasPreview, previewFileUrl],
    )

    const getFinalPlaceholderStyle = useCallback(
      () => ({
        fontSize: fileStyle.width
          ? `${parseInt(fileStyle.width) * 0.9}px`
          : `${150 * 0.9}px`,
        position: onDelete ? 'absolute' : 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }),
      [fileStyle.width, onDelete],
    )

    const onFullView = isFullViewNeeded
      ? () => {
          if (/^(data:).+(base64).+/g.test(fullFileUrl)) {
            const newTab = window.open()
            newTab.document.body.style.cssText =
              'margin: 0px; background: #0e0e0e; height: 100%;display: flex;justify-content: center;align-items: center'
            newTab.document.body.innerHTML = `<img style="-webkit-user-select: none;margin: auto;" src="${fullFileUrl}">`
          } else {
            window.open(`${fullFileUrl}`, '_blank')
          }
        }
      : defaultAction

    return (
      <div
        className={
          (!containerClassName
            ? 'd-flex flex-column align-items-center customer-block__image'
            : containerClassName) + ' '
        }
        onClick={onFullView}
      >
        <Tooltip
          title={tooltipPlacement ? name : undefined}
          placement={tooltipPlacement || 'top'}
        >
          <div title='icon' style={getFinalFileStyle()} className='image'>
            {!isHasPreview && isLogo ? (
              <div
                className={isUsersTable ? 'fas fa-user' : 'mdi mdi-account'}
                style={getFinalPlaceholderStyle()}
              ></div>
            ) : (
              <div>
                {typeof previewFileUrl === 'function' &&
                  previewFileUrl(fileStyle)}
              </div>
            )}

            <div className='image-actions'>
              <EyeOutlined
                style={{
                  color: '#fff',
                  zIndex: 2,
                  fontSize: 18,
                  // cursor: 'pointer',
                }}
              />

              {onDelete && (
                <Popconfirm
                  title='Are you sure you want to delete this image?'
                  placement='top'
                  onConfirm={e => {
                    e.stopPropagation()
                    onDelete(e)
                  }}
                  onCancel={e => {
                    e.stopPropagation()
                  }}
                  okText='Yes'
                  cancelText='No'
                >
                  <DeleteOutlined
                    style={{
                      color: '#fff',
                      zIndex: 2,
                      fontSize: 15,
                      top: 5,
                      right: 5,
                      position: 'absolute',
                    }}
                    // className='delete-icon'
                    onClick={e => e.stopPropagation()}
                  />
                </Popconfirm>
              )}
            </div>
          </div>
        </Tooltip>
        {showName && !!name && (
          // <Tooltip title={name}>
          <div
            className='text-center'
            style={{
              width: fileStyle.width || '150px',
              textOverflow: 'ellipsis',
              overflowX: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {name}
          </div>
          // </Tooltip>
        )}
      </div>
    )
  },
)

const getFileUrlsForNotImageByName = (name = '', fullFileUrl = '') => {
  let preview = ''
  let fullView = ''
  if (/\.(?:pdf|PDF)/.test(name)) preview = PdfIcon
  else if (/\.(?:docx|DOCX|dotx|doc|DOC)/.test(name)) preview = McWordIcon
  else if (/\.(?:txt|TXT|html)/.test(name)) preview = TxtIcon
  else if (/\.(?:xlsx|XLSX|xls|XLS)/.test(name)) preview = McExelIcon
  else if (/\.(?:pptx|PPTX|ppt|PPT)/.test(name)) preview = McPptIcon
  else if (/\.(?:mp3|MP3|wav|WAV|aac|AAC)/.test(name)) preview = AudioIcon
  else if (/\.(?:mp4|MP4|3gpp|webm|ogg|ogmm)/.test(name)) preview = VideoIcon
  else if (
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      name,
    ) &&
    !name.includes('amazonaws')
  )
    preview = WebIcon
  else if (
    !/\.(?:jpg|JPG|png|PNG|gif|svg|SVG|ico|ICO|jpeg|JPEG|bmp|BMP|webp|WebP|WEBP|gif|GIF|HEIC|heic|DNG|dng)/.test(
      name,
    )
  )
    preview = BaseIcon

  return [preview, fullView]
}

const getFileData = (file, fileName, customPreviewLink, customFullViewLink) => {
  let previewFileUrl = ''
  let fullFileUrl = ''
  let name = ''
  const setFileUrlsForNotImageByName = nameString => {
    const [preview, fullView] = getFileUrlsForNotImageByName(
      nameString,
      fullFileUrl,
    )
    if (preview) previewFileUrl = preview
    if (fullView) fullFileUrl = fullView
  }

  if (typeof file !== 'string' && !file?.linkToFile) {
    if (file instanceof File) {
      previewFileUrl = URL.createObjectURL(file)
      fullFileUrl = URL.createObjectURL(file)
      name = fileName ? fileName : file.name
      if (file.type) {
        setFileUrlsForNotImageByName(file.type)
      } else if (file.name) {
        setFileUrlsForNotImageByName(file.name)
      }
    } else {
      previewFileUrl = process.env.REACT_APP_API_MEDIA + file?.preview
      fullFileUrl = process.env.REACT_APP_API_MEDIA + file?.path
      name = fileName ? fileName : file?.display_name
      if (file?.display_name) {
        setFileUrlsForNotImageByName(file?.display_name)
      }
    }
  } else {
    let link = file.linkToFile ? file.linkToFile : file
    const isBase64 = /^(data:).+(base64).+/g.test(link)

    file =
      validator.isURL(link, { require_protocol: true }) ||
      /^\/assets\//.test(link) ||
      isBase64
        ? link
        : process.env.REACT_APP_API_MEDIA + link
    previewFileUrl = file
    fullFileUrl = file
    setFileUrlsForNotImageByName(file)
    name = fileName ? fileName : !isBase64 ? file : ''
  }

  if (customPreviewLink) previewFileUrl = customPreviewLink
  if (customFullViewLink) fullFileUrl = customFullViewLink

  return [previewFileUrl, fullFileUrl, name]
}

const openFile = fullFileUrl => {
  if (/^(data:).+(base64).+/g.test(fullFileUrl)) {
    const newTab = window.open()
    newTab.document.body.style.cssText =
      'display: flex;justify-content: center;align-items: center'
    newTab.document.body.innerHTML = `<img src="${fullFileUrl}">`
  } else {
    window.open(`${fullFileUrl}`, '_blank')
  }
}

export const viewFile = ({
  file = '',
  fileName = '',
  customFullViewLink = '',
  viewOnly,
  customPreviewLink = '',
}) => {
  const [previewFileUrl, fullFileUrl, name] = getFileData(
    file,
    fileName,
    customPreviewLink,
    customFullViewLink,
  )
  if (viewOnly) openFile(fullFileUrl)
}

export const ViewFile = ({
  file,
  fileType = '',
  fileName = '',
  customFullViewLink = '',
  viewOnly,
  customPreviewLink = '',
  tooltipPlacement,
  isLogo,
  ...renderFileProps
}) => {
  const [previewFileUrl, fullFileUrl, name] = getFileData(
    file,
    fileName,
    customPreviewLink,
    customFullViewLink,
  )

  return (
    <RenderFile
      fullFileUrl={fullFileUrl}
      name={name}
      previewFileUrl={previewFileUrl}
      isLogo={isLogo}
      tooltipPlacement={tooltipPlacement}
      {...renderFileProps}
    />
  )
}
