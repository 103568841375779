import React, { useEffect, useState } from 'react'

import CustomAddressAutocompleteContainer from 'containers/MainContent/Orcatec/components/CustomAddressAutocomplete/CustomAddressAutocompleteContainer'
import InputField from 'containers/MainContent/Orcatec/components/Input'

import './styles.scss'
import SearchAddress from 'containers/MainContent/Orcatec/components/SearchAddress'
import EditButton from 'containers/MainContent/Orcatec/components/buttons/EditButton'
const PublicAppointmentsAddressForm = ({
  property,
  onChange,
  onChooseAddress,
  errors,
  chosenAddress = { address: '', chosen: false },
  publicAppointmentView = false,
  companyHash = '',
  toggleManualInput,
  setPublicAppointmentAddressValue,
  setChosenAddress,
  showManualInput,
}) => {
  const [searchString, setSearchString] = useState('')

  useEffect(() => {
    if (chosenAddress.chosen) setSearchString(chosenAddress.address)
  }, [chosenAddress])

  const onChangeCustomAutocomplete = value => {
    console.log(value)
    // const { value } = e.target
    setSearchString(value)
    setPublicAppointmentAddressValue?.(value)
    setChosenAddress?.(p => ({ ...p, chosen: false }))
  }

  return (
    <div className='property-address'>
      {!showManualInput ? (
        <SearchAddress
          onChangeSearchValue={onChangeCustomAutocomplete}
          onChooseAddress={onChooseAddress}
          onClickManualInput={toggleManualInput}
          companyHash={companyHash}
          publicView
          required
          wrapperClassName='property-search__table'
          initialValue={property?.address || ''}
        />
      ) : (
        <div className='address-row custom_address'>
          <InputField
            label='Address'
            name='address'
            value={property?.address || ''}
            onChange={onChange}
            className='address'
            type='text'
            inputProps={{
              maxLength: 50,
            }}
            error={errors}
            required
          />
          <EditButton title='Search input' onClick={toggleManualInput} />
        </div>
      )}

      <div className='address-fields'>
        {searchString.length > 0 && (
          <>
            <div className='address-row'>
              <InputField
                label='ZIP'
                name='postcode'
                value={property?.postcode || ''}
                onChange={onChange}
                className='zip'
                inputProps={{
                  maxLength: 5,
                }}
                error={errors}
                required
              />
              <InputField
                label='City'
                name='city'
                value={property?.city || ''}
                onChange={onChange}
                className='city'
                error={errors}
                required
              />
              <InputField
                label='State'
                name='state'
                // value={states[property?.state] || ''}
                value={property?.state || ''}
                onChange={onChange}
                error={errors}
                required
              />
            </div>
            <div className='address-row'>
              <InputField
                label='Unit'
                name='unit'
                value={property?.unit || ''}
                onChange={onChange}
                className='unit'
                inputProps={{ maxLength: 10 }}
                error={errors}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default PublicAppointmentsAddressForm
