import React, { useState } from 'react'
import { Tooltip, Empty, Table } from 'antd'
import Rating from 'containers/MainContent/Orcatec/Clients/components/Rating'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { attachOrganizationToClient } from 'api/Client'
import { Link } from 'react-router-dom'
import { ellipsisStyle } from 'containers/MainContent/Orcatec/Properties'
import {
  ClientIcon,
  OrganizationIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { ClientType } from 'types/Client'
import { useAppSelector } from 'store/Orcatec/hooks'
import { Companies } from 'types/Company'
import styled from 'styled-components'

const Wrapper = styled.div`
  & .rest-items {
    padding: 3px 3px;
    margin-left: 5px;
    color: #4285f4;
    background: rgba(76, 83, 239, 0.08);
    font-size: 14px;
    font-variant: tabular-nums;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    height: fit-content;
    border-radius: 4px;
    white-space: nowrap;
    cursor: default;
  }
`

const style = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

export const OrganizationsTable = ({ tableData }) => {
  const company = useAppSelector(state => state.orcatec.company)
  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null)
  const handleUpdateRelation = async (e, record) => {
    const relationValue = e.target.value
    if (relationValue === record?.property_relation) return
    await attachOrganizationToClient(record.pivot.client_id, {
      organization_id: record.pivot.organization_id,
      contact_role: relationValue,
    })
    return openNotificationWithIcon('success', {
      message: 'Relation has been updated',
    })
  }
  const renderTableName = (value: string, client: IClients) => {
    return (
      <>
        <Link
          to={`/clients/${client.id}?clientType=${client.type}`}
          target='_blank'
        >
          {value}
        </Link>
        <Tooltip title={`Rating: ${client.vote}`}>
          <Rating
            max={5}
            value={client.vote}
            readOnly
            precision={0.5}
            size='small'
          />
        </Tooltip>
      </>
    )
  }

  const renderRelation = (text: string, record: IClients) => {
    // if (organizationId) return <p>{text}</p>

    return (
      <InputField
        defaultValue={text}
        onClick={e => e.stopPropagation()}
        onBlur={e => handleUpdateRelation(e, record)}
      />
    )
  }

  const renderArray = (array: IClients[], record: IClients, key: string) => {
    if (!array || !array.length) return '-'
    array = array?.map(el => (el[key] ? el[key] : el))
    if (record.key === clickedRowIndex) {
      return (
        <div>
          {array?.map(item => (
            <p key={item}>{item}</p>
          ))}
        </div>
      )
    }
    return (
      <div style={{ display: 'flex' }}>
        <Tooltip
          title={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {array?.map(el => (
                <div key={el}>{el}.</div>
              ))}
            </div>
          }
        >
          <span style={style}>{!!array[0] && array[0]}</span>
        </Tooltip>
        {array?.length > 1 && (
          <span className='rest-items'>+{array.length - 1}</span>
        )}
      </div>
    )
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      noFilter: true,
      render: (value: Pick<IClients, 'name'>, client: IClients) =>
        renderTableName(value, client),
    },
    {
      title: company.id === Companies.SoCal ? 'Project Name' : 'Relation',
      dataIndex: 'relations',
      key: 'relations',
      sorter: false,
      noFilter: true,
      render: (text: Pick<IClients, 'relations'>, record: IClients) =>
        renderRelation(text, record),
      onCell: (record: IClients) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: false,
      noFilter: true,
      defaultWidth: 50,
      render: (type: IClients['type']) => (
        <span>
          {+type === ClientType.ORGANIZATION ? (
            <Tooltip title='Organization'>
              <i>
                <OrganizationIcon />
              </i>
            </Tooltip>
          ) : (
            <Tooltip title='Individual'>
              <i>
                <ClientIcon />
              </i>
            </Tooltip>
          )}
        </span>
      ),
      onCell: (record: IClients) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
    },
    {
      title: 'Properties',
      dataIndex: 'properties',
      key: 'properties',
      sorter: false,
      noFilter: true,
      render: (property: Pick<IClients, 'properties'>, record: IClients) =>
        renderArray(property, record, 'full_address'),
      onCell: (record: IClients) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      sorter: false,
      noFilter: true,
      render: (notes: Pick<IClients, 'notes'>, record: IClients) =>
        renderArray(notes, record, 'text'),
      onCell: (record: IClients) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
    },
    {
      title: 'Email',
      dataIndex: 'emails',
      key: 'emails',
      sorter: false,
      noFilter: true,
      render: (emails: Pick<IClients, 'emails'>, record: IClients) =>
        renderArray(emails, record, 'email'),
      onCell: (record: IClients) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
    },
    {
      title: 'Phone',
      dataIndex: 'phones',
      key: 'phones',
      sorter: false,
      noFilter: true,
      render: (phones: Pick<IClients, 'phones'>, record: IClients) =>
        renderArray(phones, record, 'phone'),
      onCell: (record: IClients) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
    },
  ]

  const onRow = (record: IClients, rowIndex: number | null) => ({
    onClick: () => {
      if (rowIndex !== clickedRowIndex) {
        return setClickedRowIndex(rowIndex)
      }
      setClickedRowIndex(null)
    },
  })
  return (
    <Wrapper>
      {tableData?.length ? (
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          size='small'
          settings={false}
          onRow={onRow}
          autoHeight
        />
      ) : (
        <Empty />
      )}
    </Wrapper>
  )
}
