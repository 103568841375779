import styled from 'styled-components'

interface IRenderNotesProps {
  id: number
  text: string
  created_by: number
  updated_by: number | null
  deleted_by: number | null
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  pivot: {
    property_id: number
    note_id: number
  }
}

const RestItemsSpan = styled.span`
  padding: 3px 3px;
  margin-left: 5px;
  color: #4285f4;
  background: rgba(76, 83, 239, 0.08);
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  height: auto;
  border-radius: 4px;
  white-space: nowrap;
  cursor: default;
`

export const renderNotes = (array: IRenderNotesProps[]): void | string | JSX.Element => {
  if (array === null || !array) return

  if (!array.length) return '-'
  return (
    <>
      {array[0].text === '' && array.length >= 2 ? array[1].text : array[0].text}
      {array?.length > 1 && <RestItemsSpan className='rest-items'>+{array.length - 1}</RestItemsSpan>}
    </>
  )
}