import React, { useEffect } from 'react'
import { Modal, Space } from 'antd'
import MainButton from '../buttons/MainButton'
import { Warning } from '@material-ui/icons'
import styled from 'styled-components'

type NotificationType = 'success' | 'info' | 'warning' | 'error'

interface IProps {
  type: NotificationType
  onConfirm: () => void
  onCancel: () => void
  title?: string
  content?: string
  okText?: string
  cancelText?: string
  children: React.ReactNode
  visible: boolean
}

const icons = (type: NotificationType) => {
  if (type === 'warning')
    return <Warning style={{ color: 'faad14', fontSize: '1.5rem' }} />
}

const ConfirmModal: React.FC<IProps> = ({
  type,
  onConfirm,
  onCancel,
  title,
  content,
  okText = 'Save',
  cancelText = 'Cancel',
  children,
  visible,
}) => {
  useEffect(() => {
    if (visible) document.body?.classList?.add('no-scroll')

    return () => document.body?.classList?.remove('no-scroll')
  }, [visible])
  return (
    <Space wrap>
      <Modal
        scrolling={false}
        visible={visible}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={null}
      >
        <Title>
          {icons(type)}
          {title}
        </Title>
        <p>{content}</p>
        <div
          style={{
            display: 'flex',
            gap: 10,
            justifyContent: 'flex-end',
            marginTop: 20,
          }}
        >
          {/* <MainButton title="Don't Save" type='delete' onClick={onConfirm} /> */}
          {!!onCancel && (
            <MainButton
              title={cancelText}
              type='cancel'
              onClick={() => {
                onCancel()
              }}
            />
          )}
          {!!onConfirm && <MainButton title={okText} onClick={onConfirm} />}
        </div>
        {children}
      </Modal>
    </Space>
  )
}

const Title = styled.h6`
  display: flex;
  gap: 10px;
  align-items: center;
`

export default ConfirmModal
