//library and custom components
import { useEffect, useState, ChangeEvent } from 'react'
import { InputAdornment } from '@material-ui/core'
import { Popconfirm, Tooltip, Popover } from 'antd'
import { useAppSelector } from 'store/Orcatec/hooks'
import { meSelector } from 'store/SuperAdmin/selectors'
import MainButton from '../../../../../../components/buttons/MainButton'
import InputField from 'containers/MainContent/Orcatec/components/UI/InputField'
import Select from 'containers/MainContent/Orcatec/components/UI/Select'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import Notes from 'containers/MainContent/Orcatec/components/Notes'
//api
import {
  deleteProposalInsightsPayouts,
  getProposalInsightsPayouts,
  putProposalInsightsPayouts,
  postProposalInsightsPayouts,
} from 'api/ProposalAccounting'
import { apiNotes } from 'api/Notes'
//helpers
import {
  priceToView,
  textFieldPriceFormatter,
  thousandSeparator,
} from '../../../../../../../../../helpers/thousandSeparator'
import { formatTimeByMoment } from 'containers/MainContent/Orcatec/components/Notes/helpers'
import { toFixed, userIdToName } from './helpers'
//styles and icons
import {
  DeleteIcon,
  EditIcon,
} from '../../../../../../components/Icons/CommonIcons'
import InfoIcon from '@material-ui/icons/Info'

import {
  ButtonWrapper,
  Row,
  Container,
  Wrapper,
  Title,
  TooltipRow,
} from './Payouts.styles'

interface INotes {
  created_at: string
  created_by: string
  created_by_user_id: number
  deleted_at: string | null
  deleted_by: string | null
  id: number
  text: string
  updated_at: string | null
  updated_by: string | null
}
interface Logs {
  action: number
  amount: string | number
  created_at: string
  id: number
  prev_amount: string | number | null
  proposal_payout_id: number
  updated_at: string
  user_id: number
}

interface IPayout {
  amount: string | number
  created_at: string | null
  created_by: number
  id: number
  notes: INotes[] | string
  updated_at: string | null
  user_id: number
  logs: Logs[]
}

export interface IProps {
  canEdit: boolean
  proposalID: number
  user: { id: number; full_name: string }
  setSumPayout: (value: number) => void
  sumToAdd: number
  canAdd: boolean
  canDelete: boolean
}
const messageSuccessCreated = 'Created successfully'
const messageSuccessUpdated = 'Updated successfully'
const messageSuccessDelete = 'Deleted successfully'
const messageError = 'Something went wrong'
const message = 'You do not have access to this actions.'
const initEdit = { id: null, edit: false }

export const Payouts = ({
  canEdit = true,
  proposalID = 0,
  user = { id: 0, full_name: '' },
  setSumPayout = () => null,
  sumToAdd = 0,
  canDelete,
  canAdd,
}: IProps) => {
  //selectors
  const technicians = useAppSelector(
    state => state.orcatec.company.technicians || [],
  )
  const { id } = useAppSelector(meSelector)

  //states
  const [payouts, setPayouts] = useState<IPayout[]>([])
  const [edit, setEdit] = useState<{ id: null | number; edit: boolean }>(
    initEdit,
  )
  const [loading, setLoading] = useState(false)

  //hendlers
  const handleChangeWithPermissions = handler => {
    return (event, idx, id) => {
      if ((canEdit && id) || !id) {
        return handler(event, idx, id)
      }
      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
  }
  const handleDeleteWithPermissions = handler => {
    return (itemID, itemIDX, proposalID) => {
      if ((canDelete && itemID) || !itemID) {
        return handler(itemID, itemIDX, proposalID)
      }

      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
  }
  const handleUpdateWithPermissions = handler => {
    return (proposalID, payout) => {
      if (canEdit) {
        return handler(proposalID, payout)
      }

      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>, idx: number) => {
    const { name, value } = event.target

    const newArray = [...payouts]
    newArray.map((item, index) => {
      if (index === idx) {
        if (name === 'amount') {
          item[name] = textFieldPriceFormatter(value || 0)
        } else item[name] = value
        setPayouts(newArray)
      }
    })
  }

  const handleDelete = async (
    itemID: number,
    itemIDX: number,
    proposalID: number,
  ) => {
    setLoading(true)
    try {
      if (itemID === undefined) {
        setPayouts?.(prevState =>
          prevState.filter((el, elIDX) => elIDX !== itemIDX),
        )
        return openNotificationWithIcon('success', {
          message: messageSuccessDelete,
        })
      }
      setPayouts?.(prevState => prevState.filter(el => el.id !== itemID))
      await deleteProposalInsightsPayouts(proposalID, itemID)
      openNotificationWithIcon('success', { message: messageSuccessDelete })
    } catch (error) {
      openNotificationWithIcon('error', {
        message: error?.message || messageError,
      })
    } finally {
      setLoading(false)
      setEdit(initEdit)
    }
  }
  const heandleCreate = async (
    proposalID: number,
    data: IPayout,
    itemIDX: number,
  ) => {
    setLoading(true)
    try {
      let notes = {}
      const payout = await postProposalInsightsPayouts(proposalID, data)
      if (data?.notes?.length) {
        notes = await apiNotes.postEntityNote(
          `/proposals/${proposalID}/proposal-payouts/${payout?.id}/notes`,
          {
            text: data.notes,
          },
        )
      }
      const createdPayout = {
        ...payout,
        notes,
      }
      setPayouts?.(prev =>
        prev.map((el, elIDX) => (elIDX === itemIDX ? createdPayout : el)),
      )
      openNotificationWithIcon('success', { message: messageSuccessCreated })
    } catch (error) {
      console.error(error)
      openNotificationWithIcon('error', {
        message: error?.message || messageError,
      })
    } finally {
      setLoading(false)
      setEdit(initEdit)
    }
  }

  const heandleUpdate = async (proposalID: number, payout: IPayout) => {
    setLoading(true)
    try {
      const res = await putProposalInsightsPayouts(
        proposalID,
        payout.id,
        payout,
      )
      if (res.status)
        openNotificationWithIcon('success', { message: messageSuccessUpdated })
      if (res?.payout?.id)
        setPayouts(prev =>
          prev.map(p => (p.id === res.payout.id ? res.payout : p)),
        )
    } catch (error) {
      console.error(error)
      openNotificationWithIcon('error', {
        message: error?.message || messageError,
      })
    } finally {
      setLoading(false)
      setEdit(initEdit)
    }
  }

  const handleAddRow = () => {
    const initDataRow = {
      user_id: user?.id,
      amount: sumToAdd,
      created_by: id,
      notes: '',
    }

    setPayouts(prevState => [...prevState, initDataRow])
  }

  const hendleEditPayout = (payoutId: number) => {
    setEdit(prev => ({ id: payoutId, edit: !prev.edit }))
  }
  //effects
  useEffect(() => {
    setSumPayout(payouts?.reduce((acc, payout) => +payout.amount + +acc, 0))
  }, [payouts])

  useEffect(() => {
    const fetchProposalInsightsData = async () => {
      setLoading(true)
      try {
        const { payouts } = await getProposalInsightsPayouts(proposalID)
        setPayouts(payouts)
      } catch (error) {
        openNotificationWithIcon('error', {
          message: error?.message || 'Error',
        })
      } finally {
        setLoading(false)
      }
    }
    if (proposalID) fetchProposalInsightsData()
  }, [proposalID])

  const logs = (logs: Logs[]) =>
    logs?.map?.((log, idx) => (
      <TooltipRow key={idx}>
        {log.action === 1
          ? `${idx + 1}. Payout created - ${priceToView(log.amount)}`
          : `${idx + 1}. Payout changed from ${priceToView(
              log.prev_amount || 0,
            )}  ${'to'}   ${priceToView(log.amount)}`}

        {log?.action === 1 ? (
          <p className='created-text'>
            <i>
              Created by {userIdToName(log.user_id, technicians)} at{' '}
              {formatTimeByMoment(log?.created_at)}
            </i>
          </p>
        ) : (
          <p className='created-text'>
            <i>
              Updated {userIdToName(log.user_id, technicians)} at{' '}
              {formatTimeByMoment(log?.updated_at)}
            </i>
          </p>
        )}
      </TooltipRow>
    ))

  const decoratedHandleChange = handleChangeWithPermissions(handleChange)
  const decoratedHandleDelete = handleDeleteWithPermissions(handleDelete)
  const decoratedHandleUpdate = handleUpdateWithPermissions(heandleUpdate)
  return (
    <Wrapper>
      {!!payouts?.length && <Title>Payouts</Title>}
      {payouts?.map((item, itemIDX) => (
        <Container key={itemIDX}>
          <Row>
            <>
              {<p>Worker:</p>}
              <Select
                id='outlined-select-currency'
                select
                name='user_id'
                value={item.user_id}
                variant='outlined'
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  decoratedHandleChange(event, itemIDX, item?.id)
                }
                style={{ width: '250px' }}
                options={technicians?.map(t => ({
                  id: t.id,
                  name: t.full_name,
                }))}
                edit={item?.id ? edit?.edit && edit.id === item.id : true}
              />
              {<p>Payout:</p>}
              <InputField
                name='amount'
                value={toFixed(thousandSeparator(item.amount, true), 2)}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  decoratedHandleChange(event, itemIDX, item?.id)
                }
                size='small'
                variant='outlined'
                edit={item?.id ? edit?.edit && edit.id === item.id : true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$ </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 12,
                }}
              />
            </>

            {item?.id ? (
              <ButtonWrapper>
                {!!item?.logs?.length && (
                  <Popover
                    overlayStyle={{ whiteSpace: 'pre-line' }}
                    title={logs(item.logs)}
                  >
                    <InfoIcon
                      style={{
                        marginLeft: '-5px',
                        fontSize: '20px',
                        color: '#4c53ef',
                        marginTop: '3px',
                      }}
                    />
                  </Popover>
                )}

                {item?.id === edit?.id && edit.edit && canEdit && (
                  <MainButton
                    isFetching={loading}
                    onClick={() => decoratedHandleUpdate(proposalID, item)}
                    title='Save'
                  />
                )}
                {(canEdit || canDelete) && (
                  <Tooltip
                    title={'Edit Payout'}
                    placement='top'
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                  >
                    <div
                      onClick={() => hendleEditPayout(item.id)}
                      className='delete-action'
                    >
                      <EditIcon />
                    </div>
                  </Tooltip>
                )}

                {edit.edit && edit.id === item.id && canDelete && (
                  <Tooltip
                    title={'Delete Payout'}
                    placement={'top'}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                  >
                    <Popconfirm
                      title={'Are you sure to delete this item?'}
                      onConfirm={() =>
                        decoratedHandleDelete(item.id, itemIDX, proposalID)
                      }
                      okText={'Yes'}
                      cancelText={'No'}
                    >
                      <div className={'delete-action'}>
                        <DeleteIcon />
                      </div>
                    </Popconfirm>
                  </Tooltip>
                )}
              </ButtonWrapper>
            ) : (
              <ButtonWrapper>
                <MainButton
                  isFetching={loading}
                  onClick={() => heandleCreate(proposalID, item, itemIDX)}
                  title='Save'
                />

                <Tooltip
                  title={'Delete Payout'}
                  placement={'top'}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  <Popconfirm
                    title={'Are you sure to delete this item?'}
                    onConfirm={() =>
                      decoratedHandleDelete(item.id, itemIDX, proposalID)
                    }
                    okText={'Yes'}
                    cancelText={'No'}
                  >
                    <div className={'delete-action'}>
                      <DeleteIcon />
                    </div>
                  </Popconfirm>
                </Tooltip>
              </ButtonWrapper>
            )}
          </Row>

          {!item?.logs?.length && (
            <Row
              style={{ flexDirection: 'column', alignItems: 'start', gap: '0' }}
            >
              {' '}
              {item?.updated_at && (
                <p className='updated-text'>
                  <i>
                    Updated {userIdToName(item.created_by, technicians)} at{' '}
                    {formatTimeByMoment(item?.updated_at)}
                  </i>
                </p>
              )}
              <p className='created-text'>
                <i>
                  Created by {userIdToName(item.created_by, technicians)} at{' '}
                  {formatTimeByMoment(item?.created_at)}
                </i>
              </p>
            </Row>
          )}

          <Row>
            {item?.id ? (
              <Notes
                label='Payouts Note'
                route={`/proposals/${proposalID}/proposal-payouts/${item?.id}/notes`}
              />
            ) : (
              <InputField
                name='notes'
                label={'Notes'}
                value={item?.notes}
                onChange={event =>
                  decoratedHandleChange(event, itemIDX, item?.id)
                }
                multiline
                maxRows={5}
                inputProps={{ maxLength: 3000 }}
                style={{ width: '100%' }}
                className='appointment-notes select-appt'
                edit={item.id ? edit?.edit && edit.id === item.id : true}
              />
            )}
          </Row>
        </Container>
      ))}

      <MainButton
        style={{ marginRight: 'auto', marginBottom: '10px' }}
        onClick={handleAddRow}
        title='Add payout'
        disabled={!canAdd}
      />
    </Wrapper>
  )
}
