import * as types from '../types'

export default (state = { organizations: [], pagination: {}, currentOrganization: {} }, action) => {
  switch (action.type) {
    case types.organizations.ORGANIZATION_SET_ITEM: {
      let { data, ...rest } = action.item
      rest.total = rest.total ? +rest.total : 0
      rest.page = rest.page ? +rest.page : rest.current_page ? +rest.current_page : 1
      rest.limit = rest.limit ? +rest.limit : rest.per_page ? +rest.per_page : 10
      if (!Array.isArray(data)) {
        data = [...Object.values(data)]
      }
      return { ...state, organizations: data, pagination: { ...rest } }
    }
    case types.organizations.ORGANIZATION_GET_ITEM:
      return state
    case types.organizations.ORGANIZATION_CHANGE_FIELD:
      return { ...state, currentOrganization: { ...state.currentOrganization, [action.field]: action.value } }
    case types.organizations.SET_CURRENT_ORGANIZATION:
      /* if (action.res.emails && action.res.emails.length === 0) {
        action.res.emails.push({
          value: {
            email: '',
            preference: false,
            send_promo: false,
            send_reminders: false,
          },
        })
      } */
      return { ...state, currentOrganization: action.res }
    case types.organizations.REMOVE_CURRENT_ORGANIZATION:
      return { ...state, currentOrganization: {} }
    case types.organizations.ADD_ORGANIZATION_ASSOCIATION:
      return {
        ...state,
        currentOrganization: {
          ...state.currentOrganization,
          client_associations: [...state.currentOrganization.client_associations, action.payload],
        },
      }
    case types.organizations.DELETE_ORGANIZATION_ASSOCIATION:
      return {
        ...state,
        currentOrganization: {
          ...state.currentOrganization,
          client_associations: state.currentOrganization.client_associations.filter(ass => ass.id !== action.payload),
        },
      }
    default:
      return state
  }
}
