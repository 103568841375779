import { Popover, Spin, Switch, Tooltip } from 'antd'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { selectProjectSettings } from 'features/Project/projectSelectors'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  selectProjectSlice,
  updateProjectSettings,
} from 'features/Project/slices/projectSlice'
import {
  Description,
  Section,
  SectionSettings,
  SectionSettingsItem,
  SectionTitle,
  Title,
  Wrapper,
} from './ProjectSettings.styles'
import { useState } from 'react'
import useProjectPreviewSettings from './hooks/useProjectPreviewSettings'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

export const ProjectSettings = () => {
  const dispatch = useAppDispatch()

  const me = useAppSelector(selectAuthUser)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const { project, status } = useAppSelector(selectProjectSlice)
  const settings = useAppSelector(selectProjectSettings)

  const projectPreviewSettings = useProjectPreviewSettings()

  const canEditCustomerView = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_SETTINGS_CAN_EDIT_CUSTOMER_VIEW],
    project.user_id,
    me.id,
  )

  const [loading, setLoading] = useState(false)

  const handleSettingsChange = (name: string) => async (checked: boolean) => {
    if (!canEditCustomerView)
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })

    setLoading(true)
    await dispatch(
      updateProjectSettings({
        projectId: project.id,
        field: { [name]: checked },
      }),
    )
    setLoading(false)
  }

  return (
    <Spin spinning={loading || status === 'loading'}>
      <Wrapper>
        {projectPreviewSettings.map(section => (
          <Section key={section.title}>
            <SectionTitle>{section.title}</SectionTitle>
            <SectionSettings>
              {section.settings.map(setting => (
                <SectionSettingsItem key={setting.field_name}>
                  <Tooltip
                    title={
                      setting.field_name === 'require_signature' &&
                      setting.disabled
                        ? 'This project has already been signed'
                        : setting.field_name === 'hide_zero_price' &&
                          setting.disabled
                        ? 'Can be changed if "Show price per item" enabled'
                        : setting.disabled
                        ? 'Can be changed if project is in Estimate status or Edit Contract mode'
                        : ''
                    }
                  >
                    <Switch
                      checked={(settings as any)?.[setting.field_name]}
                      onChange={handleSettingsChange(setting.field_name)}
                      disabled={!!setting.disabled}
                    />
                  </Tooltip>
                  <Title>{setting.title}</Title>

                  {setting.description && (
                    <Popover
                      title={setting.title}
                      content={<Description>{setting.description}</Description>}
                      placement='right'
                      trigger={'click'}
                    >
                      <span
                        style={{
                          marginLeft: 5,
                          cursor: 'help',
                        }}
                      >
                        <QuestionCircleOutlined />
                      </span>
                    </Popover>
                  )}
                </SectionSettingsItem>
              ))}
            </SectionSettings>
          </Section>
        ))}
      </Wrapper>
    </Spin>
  )
}
