import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { IHistoryItem } from '../../types'
import { useAppSelector } from 'store/Orcatec/hooks'
import { Media } from 'types/Media'
import './HistoryItem.scss'
import {
  PdfIcon,
  infoIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { Tooltip } from 'antd'
import validator from 'validator'
import { parsePhoneNumber } from 'features/Messaging/utils'

interface HistoryItemProps {
  item: IHistoryItem
  onOpenFileHeandler: (attached_files: Media[], idx: number) => void
}

type ItemType = {
  email?: string
}

export const HistoryItem: React.FC<HistoryItemProps> = props => {
  const timezone = useAppSelector(s => s.orcatec?.company?.timezone)

  const { item, onOpenFileHeandler } = props
  const { from_name: from = '', send_at = '', subject = '', body = '' } = item
  const [recipients, setRecipients] = useState('')

  const formattedDate = moment
    .utc(send_at)
    .tz(timezone)
    .format('MM/DD/YYYY hh:mm A ')

  useEffect(() => {
    const parseItem = (item: ItemType | string) => {
      // debugger
      if (typeof item === 'object') return item.email
      else return item
    }
    const emailsArray = [
      ...item.recipients?.clients.map(client => parseItem(client)),
      ...item.recipients.other,
    ]
    const result = emailsArray
      .map(item => (validator.isEmail(item) ? item : parsePhoneNumber(item)))
      .join(', ')

    setRecipients(result)
  }, [item])

  return (
    <>
      <div className='history-block'>
        <p className='history-item'>
          <strong>Sent by:</strong>
          <span>{`${from || item?.created_by} at ${formattedDate}`}</span>
        </p>
        <p className='history-item'>
          <strong>Sent to:</strong>
          {recipients}
        </p>
        {subject && (
          <p className='history-item'>
            <strong>Subject:</strong>
            <span>{subject}</span>
          </p>
        )}
        <p className='history-item'>
          <strong>Message:</strong>
          <span
            style={{ width: 'calc(100% - 70px)' }}
            dangerouslySetInnerHTML={{ __html: body }}
          />
        </p>
        <p className='history-item'>
          <strong>Attached files:</strong>
          {props?.item?.attached_files?.length ? (
            props?.item?.attached_files?.map((file, idx) => (
              <div
                key={idx}
                onClick={() =>
                  file?.id &&
                  onOpenFileHeandler?.(props?.item?.attached_files, idx)
                }
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'zoom-in',
                  padding: '0 4px',
                }}
              >
                <PdfIcon />
                <span>
                  {file?.display_name?.length > 14 ? (
                    <Tooltip title={file.display_name}>
                      <span>{file.display_name.slice(0, 14) + '...'}</span>
                    </Tooltip>
                  ) : (
                    file.display_name
                  )}
                </span>
              </div>
            ))
          ) : item?.has_attachments ? (
            <Tooltip title='Creating files in progress'>{infoIcon()}</Tooltip>
          ) : (
            '-'
          )}
        </p>
        <div className='separator' />
      </div>
    </>
  )
}
