import React, { memo, forwardRef, useRef } from 'react'

export const Box = memo(
  forwardRef(({ dragEventOver, index, minTime, dragEventLeave, workerId, startResizingBox, eventType }, ref) => {
    const boxRef = useRef()
    const onDragLeave = e => !!dragEventLeave && dragEventLeave(e, index)
    const onDragOver = e => !!dragEventOver && dragEventOver(e, index)
    const onStartResizing = e => !!startResizingBox && startResizingBox(e, eventType, boxRef)

    return (
      <div
        className={`box hovered_box ${workerId}`}
        id={workerId}
        workerId={workerId}
        hour={minTime + index}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        ref={ref}
        onMouseDown={onStartResizing}
      >
        <div
          ref={boxRef}
          workerId={workerId}
          hour={minTime + index}
          title='box__background'
          className='box__background'
        ></div>
      </div>
    )
  }),
)
