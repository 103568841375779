import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  selectContactStatus,
  IStatus,
} from 'features/Project/slices/projectContactsSlice'
import SpinnerIcon from 'adminModule/static/icons/spinner'
import { CheckOutlined } from '@ant-design/icons'

// status: 'idle' | 'loading' | 'error' | 'success' | 'processing'

const statusTitle = {
  processing: 'Saving...',
  loading: 'Loading',
  error: 'Error',
}

const TIMER = 2000

const PROCESSING = 'processing'

export const Loader = () => {
  const status: IStatus = useAppSelector(selectContactStatus)
  const [showAltIcon, setShowAltIcon] = useState(false)
  const prevStatus = usePrevious(status)

  useEffect(() => {
    let timer

    if (prevStatus === PROCESSING && status !== PROCESSING) {
      setShowAltIcon(true)
      timer = setTimeout(() => {
        setShowAltIcon(false)
      }, TIMER)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [status])

  return (
    <>
      {showAltIcon && ![PROCESSING].includes(status) && (
        <Wrapper>
          <CheckOutlined style={{ color: 'green', width: 20 }} />
          <p>Saved</p>
        </Wrapper>
      )}
      {[PROCESSING].includes(status) && (
        <Wrapper>
          <SpinnerIcon width={40} height={40} fill='#4c53ef' />
          <p>{statusTitle[status]}</p>
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  color: rgba(35, 38, 47, 1);
`

function usePrevious(value) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}
