import React, { useEffect, useState, useMemo, ChangeEvent } from 'react'
import { Popconfirm, Spin } from 'antd'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/styles'
import {
  getSuggestionsItems,
  postSuggestionsItems,
  deleteSuggestionsItems,
} from 'api/ProposalAccounting'
import useDebounce from 'hooks/useDebounce'

import { LoadingOutlined } from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />
const sourceAndTypeStyle = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '14px',
  letterSpacing: '0.01em',
  color: ' #191919',
}
const useStyles = makeStyles({
  customTextField: {
    '& .MuiFormLabel-root': {
      fontSize: '0.8rem',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      fontSize: '1rem',
    },
    '& .MuiFormLabel-root.MuiFormLabel-filled': {
      fontSize: '1rem',
    },
  },
})
interface IItem {
  name: string
  id: number
  type: 1 | 2
}
interface IMeta {
  current_page: number
  from: number
  last_page: number
  links: string[]
  path: string
  per_page: number
  to: number
  total: number
}
interface IProps {
  name: string
  label: string
  type: 1 | 2
  item: IItem
  disableCRUD: boolean
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  canDeleteSuggestion: boolean
  canAddSuggestion: boolean
}
export const Suggestion = ({
  type,
  name,
  item,
  handleChange,
  label,
  disableCRUD,
  canDeleteSuggestion,
  canAddSuggestion,
  ...rest
}: IProps) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(null)
  const [input, setInputValue] = useState('')
  const [options, setOptions] = useState<IItem[]>([])
  const [meta, setMeta] = useState<IMeta>({})
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const inputValue = useDebounce(input)
  const styles = useStyles()
  const methods = useForm({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: item,
  })
  const { control, watch, setValue: setVal } = methods
  const fetch = useMemo(
    () => request => {
      setLoading(true)
      getSuggestionsItems(request)
        .then(res => {
          const { items, meta } = res
          setMeta(meta)
          setOptions(items)
          return items
        })
        .catch(e => console.error(e))
        .finally(() => setLoading(false))
    },
    [],
  )

  useEffect(() => {
    let active = true

    if (!open) return

    fetch({ search: inputValue, type, page: 1 }, results => {
      if (active) {
        let newOptions = []

        if (value) {
          newOptions = [value]
        }
        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch, open])

  useEffect(() => {
    if (!open) {
      setOptions([])
      setMeta({})
    }
  }, [open])

  const fetchItems = async params => {
    try {
      setLoading(true)
      return await getSuggestionsItems(params)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) {
      setLoading(true)
      fetchItems({ type, search: inputValue, page: page })
        .then(res => {
          const { items, meta } = res
          setMeta(meta)
          setOptions(prev => [...prev, ...items])
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [page])

  const onDelete = async (option: IItem) => {
    try {
      await deleteSuggestionsItems(option.id)
      setOptions(prev => prev.filter(p => p.id !== option.id))
      handleChange({ target: { name, value: '' } })
      setVal('title', '')
      setInputValue('')
    } catch (error) {
      console.error(error)
    }
  }

  const onScroll = async (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget
    const nextPage = meta?.current_page + 1
    const lastPage = meta?.last_page

    if (
      listboxNode.scrollTop + listboxNode.clientHeight >=
      listboxNode.scrollHeight
    ) {
      if (nextPage <= lastPage) {
        setPage(nextPage)
        listboxNode.scrollTo({
          top: listboxNode.scrollHeight,
          behavior: 'smooth',
        })
      }
    }
  }

  const onBlur = async (e: React.SyntheticEvent) => {
    const { value } = e.target
    if (!value) return

    try {
      // const serchValue = await fetchItems({ type, search: value, page: 1 })
      // const { items } = serchValue
      // const existValue = options.find(o => o?.name === value)

      // if (existValue) {
      return handleChange({ target: { name, value } })
      // }

      // const createdItem = await postSuggestionsItems({ type, name: value })
      // handleChange({ target: { name, value: createdItem?.name } })
    } catch (error) {
      console.error(error)
    }
  }

  const onChange = async (newValue: IItem, cb: () => void) => {
    if (!newValue) {
      setValue('')
      cb('')
      handleChange({ target: { name, value: '' } })
      return
    }
    if (newValue?.id) {
      setValue(newValue?.name)
      cb(newValue?.name)
      handleChange({ target: { name, value: newValue?.name } })
      return
    }

    if (newValue?.inputValue) {
      handleChange({ target: { name, value: newValue?.inputValue } })
      setValue(newValue?.inputValue)
      cb(newValue?.inputValue)
      await postSuggestionsItems({ type, name: newValue?.inputValue })
    }
  }

  const filterOptions = (options, params) => {
    const filtered = options?.filter(
      option => option?.id !== null && option?.name !== '',
    )
    const filter = filtered?.filter(f =>
      f?.name?.toLowerCase()?.includes(params?.inputValue?.toLowerCase()),
    )
    const existValue = filtered?.find(
      f => f?.name?.toLowerCase() === params?.inputValue?.toLowerCase(),
    )

    if (
      params?.inputValue !== '' &&
      !existValue &&
      !loading &&
      canAddSuggestion
    ) {
      filter?.push({
        type: type,
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`,
      })
    }

    return filter
  }
  return (
    <FormProvider {...methods}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            id='item'
            size='small'
            noOptionsText='Select item'
            style={{ marginRight: '6px' }}
            onOpen={() => {
              setOpen(true)
              setLoading(true)
            }}
            onClose={() => {
              setOpen(false)
              setLoading(false)
            }}
            fullWidth
            freeSolo
            loading={loading}
            getOptionLabel={option =>
              typeof option === 'string' ? option : option?.name
            }
            getOptionSelected={(optionA, optionB) =>
              optionA?.name === optionB?.name
            }
            // filterOptions={x => x}
            filterOptions={filterOptions}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={watch('title')}
            ListboxProps={{
              onScroll,
            }}
            onBlur={onBlur}
            onChange={(event, value) => onChange(value, field.onChange)}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            disabled={disableCRUD}
            renderInput={params => (
              <TextField
                {...rest}
                {...params}
                classes={{ root: styles.customTextField }}
                label={label}
                variant='outlined'
                fullWidth
                disabled={disableCRUD}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      <Spin
                        style={{ marginRight: '30px' }}
                        indicator={antIcon}
                        spinning={loading}
                      />
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(option: IItem) => (
              <div style={sourceAndTypeStyle}>
                {option?.name}
                {!!option?.id && canDeleteSuggestion && !disableCRUD && (
                  <Popconfirm
                    title='Are you sure you want to delete this item?'
                    overlayStyle={{
                      zIndex: 1400,
                    }}
                    onCancel={e => e.stopPropagation()}
                    onConfirm={e => {
                      e.stopPropagation()
                      if (!option.id) return
                      onDelete(option)
                    }}
                  >
                    <i
                      className='mdi mdi-delete p-1'
                      style={{ cursor: 'pointer', fontSize: '18px' }}
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    />
                  </Popconfirm>
                )}
              </div>
            )}
          />
        )}
      />
    </FormProvider>
  )
}
