import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchAppointmentById, createAppointment } from 'api/Appointment'
import { normalizeObj } from './Form/helpers/helpers'
import {
  AppointmentEntityType,
  IAppointment,
  RecurringInterval,
} from 'types/Appointment'
import { validateAll } from './Form/validation/validation'
import { addNewAppointment } from 'features/Contacts/slice/contactSlice'
import { AsyncThunkAPI } from 'features/Dispatch/types'

export const initialAppointment: IAppointment = {
  appointment_source_id: null,
  appointment_type_id: null,
  column_template_id: [],
  canceled_info: null,
  date: '',
  each: [],
  ending_options: {
    end_type: 1,
    value: null,
  },
  entity_id: 0,
  entity_type: AppointmentEntityType.PROPERTY,
  industry_id: 0,
  is_recurring: false,
  interval: RecurringInterval.DAY,
  matrix_time_end: null,
  matrix_time_start: null,
  make_first_appointment: false,
  notes: [],
  preferred_technician_id: null,
  repeat_every: 1,
  start_date: null,
  status: 1,
  time_end: null,
  time_start: null,
  current_end_time: null,
  current_start_time: null,
  times_needed: 1,
  primary_entity_id: null,
  company_start_time: null,
  company_end_time: null,
  confirm: false,
  new: false,
}
export const initialErrors: Error = {
  column_template_id: null,
  preferred_technician_id: null,
  matrix_time_end: null,
  matrix_time_start: null,
  appointment_source_id: null,
  appointment_type_id: null,
  appointed_technician_id: null,
  time_end: null,
  time_start: null,
  date: null,
  start_date: null,
  ending_options: null,
  queue_id: null,
}
const initialState: {
  currentAppointment: IAppointment
  error: Error
  open: boolean
  loading: boolean
  touched: boolean
  hasError: boolean
  showDropModal: boolean
  showUpcomingNotification: boolean
} = {
  currentAppointment: initialAppointment,
  error: initialErrors,
  open: false,
  loading: false,
  touched: false,
  hasError: false,
  showDropModal: false,
  showUpcomingNotification: false,
}

const GET_APPOINTMENT = 'appointment/get'
const CREATE_APPOINTMENT = 'appointment/create'

export const fetchAppointment = createAsyncThunk(
  GET_APPOINTMENT,
  async (id, { rejectWithValue }) => {
    try {
      const data = await fetchAppointmentById(id)
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchCreateAppointment = createAsyncThunk<
  IAppointment,
  IAppointment,
  AsyncThunkAPI
>(CREATE_APPOINTMENT, async (data, { rejectWithValue, dispatch, getState }) => {
  const currentAppointment = normalizeObj(
    getState().orcatec.appointmentSlice.currentAppointment,
  )
  const currentError = getState().orcatec.appointmentSlice.error

  const { newErrors, validated } = validateAll(currentAppointment, currentError)
  if (!validated) {
    return rejectWithValue(newErrors)
  }

  try {
    const { appointment } = await createAppointment(currentAppointment)

    const getFullAppointment = await fetchAppointmentById(appointment?.[0]?.id)
    dispatch(addNewAppointment(getFullAppointment))
    return getFullAppointment
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    resetAppointmentAction: state => {
      state.currentAppointment = initialAppointment
      state.loading = false
      state.touched = false
      state.hasError = false
    },
    updateAppointmentAction: (state, { payload }) => {
      state.touched = true
      state.currentAppointment = {
        ...state.currentAppointment,
        ...payload,
      }
    },
    onChangeField: (state, { payload }) => {
      if (state.hasError) {
        state.error = initialErrors
        state.hasError = false
      }
      state.currentAppointment[payload.field] = payload.value
      state.touched = true
    },

    onChangeFieldAction: (state, { payload }) => {
      state.currentAppointment[payload.field] = payload.value
    },
    setErrorsAction: (state, { payload }) => {
      state.error = payload
      state.hasError = true
    },
    toggleDropModalAction: (state, { payload }) => {
      state.showDropModal = payload
    },
    toggleUpcomingModalAction: (state, { payload }) => {
      state.showUpcomingNotification = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAppointment.pending, state => {
      state.loading = true
      state.touched = false
    })
    builder.addCase(fetchAppointment.fulfilled, (state, { payload }) => {
      state.currentAppointment = payload
      state.loading = false
    })
    builder.addCase(fetchAppointment.rejected, state => {
      state.loading = false
      state.error = payload?.error
    })

    builder.addCase(fetchCreateAppointment.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchCreateAppointment.fulfilled, (state, { payload }) => {
      state.loading = false
      state.hasError = false
      state.touched = false
      state.currentAppointment = payload
    })
    builder.addCase(fetchCreateAppointment.rejected, (state, { payload }) => {
      state.loading = false
      state.hasError = true
      state.error = { ...state.error, ...payload }
      if (payload?.count_days) {
        state.showDropModal = true
      }
    })
  },
})

export default appointmentSlice.reducer
export const {
  onChangeFieldAction,
  resetAppointmentAction,
  onChangeField,
  updateAppointmentAction,
  toggleDropModalAction,
  setErrorsAction,
  toggleUpcomingModalAction,
} = appointmentSlice.actions

export const selectCurrentAppointment = (state: AppStateType) =>
  state.orcatec.appointmentSlice.currentAppointment
export const selectAppointmentSlice = (state: AppStateType) =>
  state.orcatec.appointmentSlice
