import React, { ChangeEvent, useState, useEffect } from 'react'
import { Checkbox, Input, Spin, DatePicker } from 'antd'
import moment from 'moment-timezone'
import styled from 'styled-components'
import { useAppSelector } from 'store/Orcatec/hooks'
import ReactInputMask from 'react-input-mask'
import { putProjectFWOSettings } from 'api/Project'

import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
// import {
//   parsePhoneNumberBeforeSendInChat,
//   parsePhoneNumberBeforeSend,
//   parsePhoneNumber,
// } from 'features/Messaging/utils'
import validator from 'validator'
import { EditIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
// import { selectSummaryContact } from 'features/Project/slices/projectContactsSlice'
import {
  getFwoSettingsThunk,
  selectFwo,
  updateFwoSettingsThunk,
  // updateFwoFieldThunk,
} from 'features/Project/slices/projectFwoSlice'
import { updateProjectField } from 'features/Project/slices/projectSlice'
import { useDispatch } from 'react-redux'

const renderPhoneField = () => {
  return <Input name='phone'></Input>
}

const daysOfWeeks = [
  { label: 'M', value: 1 },
  { label: 'T', value: 2 },
  { label: 'W', value: 3 },
  { label: 'Th', value: 4 },
  { label: 'F', value: 5 },
  { label: 'S', value: 6 },
  { label: 'S', value: 7 },
]

export interface IFWO {
  super_name: string | null
  phone: string | null
  email: string | null
  work_description: string | null
  ordered_by: string | null
  on_site_indirect: boolean
  offsite: boolean
  customer_service: boolean
  customer: string
  sales_marketing: boolean
  project: string
  p_o_number: string
  days_of_week: []
  location: string | null
  foreman: string | null
  date: string | null
}

const initState: IFWO = {
  super_name: '',
  phone: '',
  email: null,
  work_description: null,
  ordered_by: null,
  on_site_indirect: false,
  offsite: false,
  customer_service: false,
  sales_marketing: false,
  project: '',
  p_o_number: '',
  days_of_week: [],
  location: '',
  foreman: '',
  date: '',
  customer: '',
}

export const FieldWorkOrder = ({ proposalForm }) => {
  const dispatch = useDispatch()
  const companyId = useAppSelector(state => state.orcatec.company.id)
  const fwoSlice = useAppSelector(selectFwo)
  // const proposalContact = useAppSelector(state => state.orcatec.proposalContact)
  // const tiles = useAppSelector(selectSummaryContact)

  const [state, setState] = useState<IFWO>(initState)
  const [prevState, setPrevState] = useState(initState)
  const [editMode, setEditMode] = useState(false)
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSave = async () => {
    setLoading(true)
    try {
      if (!!state?.email && !validator.isEmail(state?.email)) {
        return openNotificationWithIcon('error', {
          message: 'Email value is not valid email address!',
        })
      }

      dispatch(
        updateFwoSettingsThunk({
          proposalId: proposalForm.id,
          state,
          companyId,
        }),
      )

      // const { work_order } = await putProjectFWOSettings(
      //   proposalForm.id,
      //   state,
      //   companyId,
      // )

      // setPrevState(work_order)
      // setState(work_order)
      setEditMode(false)
      setEdit(false)
    } catch (error) {
      const message =
        Object.values(error?.response?.data?.errors || {})?.[0] ||
        'Something went wrong!'
      console.error(error)

      openNotificationWithIcon('error', { message })
    } finally {
      setLoading(false)
    }
  }
  const handleCancel = async () => {
    setLoading(true)
    try {
      if (edit) {
        await putProjectFWOSettings(proposalForm.id, prevState, companyId)
      }
      setState(prevState)
      setEditMode(false)
      setEdit(false)
    } catch (error) {
      const message =
        Object.values(error?.response?.data?.errors || {})?.[0] ||
        'Something went wrong!'
      console.error(error)

      openNotificationWithIcon('error', { message })
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setEdit(true)

    try {
      if (name === 'days_of_week') {
        const newArr = state?.days_of_week.includes(value)
          ? state?.days_of_week?.filter(item => item !== value)
          : [...state?.days_of_week, value]

        setState(prev => ({ ...prev, [name]: newArr }))
      } else {
        setState(prev => ({ ...prev, [name]: value }))
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Something went wrong!')
    }
  }

  const handleEdit = () => {
    setEditMode(prev => !prev)
  }

  const checkAndSaveProjectName = async (fwo: IFWO) => {
    if (!!fwo?.project && !!proposalForm?.name) return
    try {
      if (!!fwo?.project && !proposalForm?.name) {
        dispatch(updateProjectField({ name: fwo.project }))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchSettings = async () => {
    setLoading(true)
    try {
      dispatch(getFwoSettingsThunk({ proposalId: proposalForm.id, companyId }))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setState(fwoSlice.work_order)
    setPrevState(fwoSlice.work_order)
    checkAndSaveProjectName(fwoSlice.work_order)
  }, [fwoSlice.work_order])

  useEffect(() => {
    if (proposalForm?.id) fetchSettings()
  }, [proposalForm?.id])

  // useEffect(() => {
  //   const revalidate = async () => {
  //     const fields = { project: null, location: null }
  //
  //     await dispatch(updateFwoFieldThunk({ fields }))
  //   }
  //
  //   revalidate()
  // }, [tiles])

  // useEffect(() => {
  //   if (loading) return
  //   let timeoutId: number = null

  //   if (proposalForm?.id) timeoutId = setTimeout(fetchSettings, 3000)

  //   return () => clearTimeout(timeoutId)
  // }, [
  //   // proposalForm?.lead_installer_id,
  //   // proposalForm?.rate_by_zip?.zip_code,
  //   // proposalForm?.properties?.length,
  //   // proposalForm?.clients?.length,
  //   //proposalForm?.date_of_completion,
  //   primaryProperty,
  //   primaryClient,
  // ])

  return (
    <Spin spinning={loading}>
      <Wrapper>
        <TableHedingWrapper>
          <h2>Field Work Order</h2>

          {editMode ? (
            <div style={{ display: 'flex', gap: '10px' }}>
              <MainButton
                type='cancel'
                isFetching={loading}
                onClick={handleCancel}
                title='Cancel'
              />
              <MainButton
                isFetching={loading}
                onClick={handleSave}
                title='Save'
              />
            </div>
          ) : (
            <div onClick={handleEdit} className='edit-button'>
              <EditIcon />
            </div>
          )}
        </TableHedingWrapper>

        <TableBodyWrapper editMode={editMode}>
          <table>
            <tbody>
              <tr>
                <TdUnit title='Days of week:' width='70%'>
                  <p className='days-wrapper'>
                    {daysOfWeeks.map(day => (
                      <span
                        key={day.value}
                        onClick={() =>
                          handleChange({
                            target: { value: day.value, name: 'days_of_week' },
                          })
                        }
                        className={
                          state?.days_of_week?.includes(day.value)
                            ? 'bordered'
                            : ''
                        }
                      >
                        {day.label}
                      </span>
                    ))}
                  </p>
                </TdUnit>
                <TdUnit title='Date:' width='30%'>
                  <DatePicker
                    value={state?.date ? moment(state.date) : null}
                    format='MM/DD/YYYY'
                    name='date'
                    style={{ width: '120px' }}
                    onChange={(date, dateStr) =>
                      handleChange({
                        target: {
                          name: 'date',
                          value: dateStr,
                        },
                      })
                    }
                  />
                </TdUnit>
              </tr>
              <tr>
                <TdUnit title='Foreman:' width='70%'>
                  <Input
                    name='foreman'
                    value={state?.foreman}
                    onChange={handleChange}
                    autoComplete='off'
                  />
                </TdUnit>
                <TdUnit title='P.O.#:' width='30%'>
                  <Input
                    name='p_o_number'
                    value={state.p_o_number}
                    onChange={handleChange}
                    autoComplete='off'
                  />
                </TdUnit>
              </tr>
              <tr>
                <TdUnit title='Ordered by:' width='70%'>
                  <Input
                    name='ordered_by'
                    value={state.ordered_by}
                    onChange={handleChange}
                    autoComplete='off'
                  />
                </TdUnit>
                <TdUnit title='Super Name:' width='30%'>
                  <Input
                    name='super_name'
                    value={state.super_name}
                    onChange={handleChange}
                    autoComplete='off'
                  />
                </TdUnit>
              </tr>
              <tr>
                <TdUnit title='Project:'>
                  <span style={{ fontWeight: '500' }} className='item-value'>
                    {state.project}
                  </span>
                </TdUnit>
                <TdUnit title='Phone #:'>
                  <ReactInputMask
                    mask='(999) 999-9999'
                    value={state.phone ?? ''}
                    onChange={handleChange}
                    disabled={false}
                    maskChar='X'
                  >
                    {renderPhoneField}
                  </ReactInputMask>
                </TdUnit>
              </tr>
              <tr>
                <TdUnit title='Tract #/Location:'>
                  <Input
                    name='location'
                    value={state?.location}
                    onChange={handleChange}
                    autoComplete='off'
                  />
                </TdUnit>
                <TdUnit title='Email:'>
                  <Input
                    name='email'
                    value={state.email}
                    onChange={handleChange}
                    autoComplete='off'
                  />
                </TdUnit>
              </tr>
              <tr>
                <TdUnit title='Customer:' colSpan='2'>
                  <Input
                    name='customer'
                    value={state.customer}
                    onChange={handleChange}
                    autoComplete='off'
                  />
                </TdUnit>
              </tr>
              <tr>
                <TdUnit title='Work Description:' colSpan='2'>
                  <Input
                    name='work_description'
                    value={state.work_description}
                    onChange={handleChange}
                    autoComplete='off'
                  />
                </TdUnit>
              </tr>

              <tr>
                <TdUnit colSpan='5'>
                  <HeadingItemWrapper>
                    <HeadingRowItem
                      title='Onsite/Indirect'
                      onChange={handleChange}
                      name='on_site_indirect'
                      value={state.on_site_indirect}
                    />
                    <HeadingRowItem
                      title='Offsite'
                      onChange={handleChange}
                      value={state.offsite}
                      name='offsite'
                    />
                  </HeadingItemWrapper>
                </TdUnit>
              </tr>
              <tr>
                <TdUnit colSpan='5'>
                  <HeadingItemWrapper>
                    <HeadingRowItem
                      title='Customer Service'
                      value={state.customer_service}
                      name='customer_service'
                      onChange={handleChange}
                    />
                    <HeadingRowItem
                      title='Sales/Marketing'
                      onChange={handleChange}
                      name='sales_marketing'
                      value={state.sales_marketing}
                    />
                  </HeadingItemWrapper>
                </TdUnit>
              </tr>
            </tbody>
          </table>
        </TableBodyWrapper>
      </Wrapper>
    </Spin>
  )
}

function HeadingRowItem({
  title,
  value,
  name,
  onChange,
}: {
  title: string
  name: string
  value: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}) {
  return (
    <HeadingRowItemWrapper>
      <div className='header-item_value'>
        <Checkbox
          onChange={() => onChange({ target: { value: !value, name } })}
          checked={value}
        />
      </div>
      <div className='header-item_text'>{title}</div>
    </HeadingRowItemWrapper>
  )
}
function TdUnit({
  children,
  title,
  ...props
}: {
  children: React.ReactNode
  title?: string
  props: any
}) {
  return (
    <TD {...props}>
      <p>
        <span style={{ width: 'auto', whiteSpace: 'nowrap' }}>{title}</span>{' '}
        <span style={{ flexGrow: 1 }}>{children}</span>
      </p>
    </TD>
  )
}

interface IStyleProps {
  editMode: boolean
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    border: 1px solid black;
    border-radius: 10px;
  }
  & td {
    border: 1px solid #d6d6d6;
  }

  & .overlaping {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 470px;
  }
  & .bold {
    font-weight: 600;
  }
`

const TD = styled.td`
  border: 1px solid grey;
  & p {
    display: flex;
    align-items: center;
    gap: 2px;
  }
`

const TableBodyWrapper = styled.div<IStyleProps>`
  border-radius: 4px;
  overflow: scroll;
  & table {
    pointer-events: ${props => (props.editMode ? 'all' : 'none')};
  }
  .item-value {
    text-align: left;
    margin-left: 2px;
  }
  & .ant-input {
    border: none;
    font-weight: 600;
  }
  & .ant-picker {
    border: none !important;
  }
  & .days-wrapper {
    margin-right: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    & span {
      cursor: pointer;
    }
    & .bordered {
      border: 1px solid #007bff;
      border-radius: 50%;
      padding: 0 8px;
    }
  }
`
const TableHedingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 6px;
  padding-left: 22px;

  & h2 {
    background-color: #fff;
    color: #19191980;
    letter-spacing: 0.01em;
    font-size: 14px;
    margin: 0;
    font-weight: 300;
  }
  & .edit-button {
    cursor: pointer;
  }
`

const HeadingRowItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 30px 130px;
  & .header-item_value {
    display: flex;
    justify-content: center;
  }
`
const HeadingItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`
