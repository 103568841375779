import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'antd'
import MainButton from '../buttons/MainButton'
import './ModalWrapper.scss'

//const cancelButtonStyle = { color: '#191919', border: '1px solid #D6D6D6', marginRight: '10px', height: '40px' }

const ModalWrapper = ({
  modal,
  close,
  children,
  apply,
  isSaving,
  cancelText = 'Cancel',
  okText = 'Save',
  customCancelBtnAction,
  disableCancelBtn,
  disableOkBtn,
  helperBtnAction,
  helperBtnText = 'Reset',
  dialogClassName = '',
  withoutCancelBtn = false,
  customOkBtn = false,
  customOkText = '',
  customOkBtnHandler = () => {},
}) => {
  return (
    <Modal
      dialogClassName={dialogClassName}
      show={modal}
      backdrop='static'
      onHide={close}
      className='proposal-form-modal_wrapper'
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className='w-100'>{children}</div>
        <div className='d-flex justify-content-center buttons'>
          {/* <button
            onClick={customCancelBtnAction ? customCancelBtnAction : close}
            className='proposal-form-modal_wrapper-cancel_btn'
            disabled={disableCancelBtn}
          >
            {cancelText}
          </button> */}
          {!withoutCancelBtn && (
            <MainButton
              title={cancelText}
              type='cancel'
              disabled={disableCancelBtn}
              onClick={customCancelBtnAction ? customCancelBtnAction : close}
            />
          )}

          {!!helperBtnAction && (
            <MainButton type='info' title={okText} onClick={apply} isFetching={isSaving} disabled={disableOkBtn} />
          )}
          {customOkBtn ? (
            <MainButton title={customOkText} onClick={customOkBtnHandler} />
          ) : (
            <MainButton title={okText} onClick={apply} isFetching={isSaving} disabled={disableOkBtn} />
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default ModalWrapper
