import React, { useCallback, useState } from 'react'
import CustomAddressAutocomplete from './CustomAddressAutocomplete/CustomAddressAutocomplete'
import { searchLocalAddress } from '../../../../../api/Property'
import { useHistory, useParams } from 'react-router'

const CustomAddressAutocompleteContainer = ({
  onChoose,
  onOpenManualAddressForm,
  onChangeValue,
  value,
  addressError,
  disabled,
  required,
  addressType,
  label,
  name,
  id,
  className,
  placeholder,
  customPrefixToSearchValue,
  customSuffixToSearchValue,
  noTable,
  searchType,
  isProposalProperties,
  attachPropertyToProposal,
  publicAppointmentView = false,
  companyHash = '',
  autoFocus,
}) => {
  const history = useHistory()

  const [searchByClientName, setSearchByClientName] = useState(false)
  const searchFn = useCallback(
    (address, parameter) =>
      searchLocalAddress({ address, parameter, publicAppointmentView, companyHash, searchByClientName }),
    [searchByClientName],
  )

  const handleChooseProperty = property => {
    if (isProposalProperties && property.id) {
      attachPropertyToProposal(property.id)
    }
    return onChoose(property)
  }

  const showSearchTypeSwitcher = history?.location?.pathname === '/appointment'

  return (
    <CustomAddressAutocomplete
      onChangeValue={onChangeValue}
      value={value}
      error={addressError}
      searchFn={searchFn}
      onClickRow={handleChooseProperty}
      onClickManual={onOpenManualAddressForm}
      disabled={disabled}
      addressType={addressType}
      label={label}
      name={name}
      id={id}
      className={className}
      placeholder={placeholder}
      customPrefixToSearchValue={customPrefixToSearchValue}
      customSuffixToSearchValue={customSuffixToSearchValue}
      noTable={noTable}
      searchType={searchType}
      required={required}
      showSearchTypeSwitcher={showSearchTypeSwitcher}
      searchByClientName={searchByClientName}
      setSearchByClientName={setSearchByClientName}
      autoFocus={autoFocus}
    />
  )
}

export default CustomAddressAutocompleteContainer
