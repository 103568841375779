import { SubscriptionStatus } from 'features/Settings/Subscription/types'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  subscriptionOff: (state.orcatec.company || {}).subscription_off,
  subscriptionStatus: state.orcatec.app.subscription_status,
  me: state.orcatec.user.me,
  nextCharge: state.orcatec.company?.subscription?.next_charge,
  subscription: state.orcatec.company?.subscription,
})

const pathToKeyMapping = [
  { path: ['/reviews'], key: 'request_review' },
  { path: ['/time-cards'], key: 'time_cards' },
  { path: ['/to-do'], key: 'todo' },
  { path: ['/quickbooks'], key: 'quickbooks' },
  { path: ['/memberships'], key: 'memberships' },
]

const shouldShowContent = (path, mapping, modules) => {
  if (!Object.values(modules || {}).length) return false
  for (const entry of mapping) {
    const { path: mappingPath, key } = entry

    if (mappingPath.includes(path)) {
      return modules[key]
    }
  }
  return true
}

export const getSubscriptionData = ({
  subscriptionOff = false,
  subscriptionStatus = SubscriptionStatus.Active,
  me,
  subscription,
}) => {
  const daysLeft =
    subscription?.attempts !== 0 && subscription?.days_until_change_status === 0
      ? 14
      : subscription?.days_until_change_status
  const failSubscriptionMessage = `Dear, ${
    me.full_name
  }! Your account has a negative balance on your subscription. ${
    subscriptionStatus === SubscriptionStatus['View only']
      ? 'It had been changed to view only mode. Please pay immediately or your account will be blocked.'
      : subscriptionStatus === SubscriptionStatus.Inactive
      ? 'It had been blocked. Please pay immediately.'
      : me.role_id === 4
      ? 'Please contact your adninistrator to avoid service interruption. '
      : 'Please pay immediately to avoid service interruption. '
  }`
  const payedSubscription =
    subscriptionOff ||
    subscriptionStatus === SubscriptionStatus.Active ||
    me.is_admin

  const subscriptionMessage =
    payedSubscription || subscriptionStatus === SubscriptionStatus.Cancelled
      ? ''
      : failSubscriptionMessage
  const status =
    payedSubscription && subscriptionStatus !== SubscriptionStatus.Cancelled
      ? 'PAID'
      : subscriptionStatus === SubscriptionStatus.Pending
      ? 'PENDING'
      : subscriptionStatus === SubscriptionStatus['View only']
      ? 'VIEW_ONLY'
      : subscriptionStatus === SubscriptionStatus.Cancelled
      ? 'CANCELLED'
      : 'INACTIVE'

  const shouldShowPaidContent = me?.login_from_admin
    ? true
    : shouldShowContent(
        window.location.pathname,
        pathToKeyMapping,
        me.paid_modules,
      )

  const shouldShowPaidContentText =
    'Please contact your administrator to enable the module.'
  return [
    status,
    subscriptionMessage,
    daysLeft,
    shouldShowPaidContent,
    shouldShowPaidContentText,
  ]
}

const withSubscription = Component => {
  const SubscribtionComponent = props => {
    const [
      subscriptionStatus,
      subscriptionMessage,
      daysLeft,
      shouldShowPaidContent,
      shouldShowPaidContentText,
    ] = getSubscriptionData(props)

    return (
      <Component
        {...props}
        subscriptionMessage={subscriptionMessage}
        subscriptionStatus={subscriptionStatus}
        daysLeft={daysLeft}
        shouldShowContent={shouldShowPaidContent}
        shouldShowPaidContentText={shouldShowPaidContentText}
      />
    )
  }
  return connect(mapStateToProps)(SubscribtionComponent)
}

export default withSubscription
