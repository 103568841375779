import { Table as AntdTable, TableProps, TablePaginationConfig } from 'antd'
import { PaginationConfig } from 'antd/lib/pagination'
import {
  FilterDropdownProps,
  SorterResult,
  TableCurrentDataSource,
  FilterValue,
} from 'antd/lib/table/interface'
import useLocalStorage, { setValueToLS } from 'hooks/useLocalStorage'
import { useEffect, useState, FC, useMemo } from 'react'
import DefaultColumnFilter from './components/TableFilters/DefaultColumnFilter'
import TableSettings from './components/TableSettings'
import TableSummary from './components/TableSummary'
import { TableWrapper, Heading } from './Table.style'
import ReactDragListView from 'react-drag-listview'
import { Resizable, Enable, ResizeDirection, NumberSize } from 're-resizable'

interface Props<T> extends TableProps<T> {
  name?: string
  data: any[]
  pagination?: {
    total: number
  }
  settings?: boolean
  summaryData?: { [key: string]: number } | null
  hiddenColumnsByDefault?: string[]
  additionalFilters: {
    pagination: TablePaginationConfig
    filters: Record<string, FilterValue | null>
    sorter: { sort_field: string; order: string } | null
  }
  search?: string | null
  dataIndex: string
  openTableSettings: boolean
  onCloseTableSettings: () => void
  autoHeight?: boolean
  hasPermission?: boolean
}
interface ITableSettings {
  columns: string[]
  columnsIndex?: { [key: string]: number | null }
  columnWidth?: { [key: string]: number | null }
  page_size?: number
  filters?: object | null
  sorter?: { sort_field: string; order: string } | null
  search?: string | null
}
type ResizeCallback = (
  event: MouseEvent | TouchEvent,
  direction: ResizeDirection,
  elementRef: HTMLElement,
  delta: NumberSize,
  title?: string,
) => void
type onDragEnd = (fromIndex: number, toIndex: number) => void
type OnChangeTable = (
  pagination: PaginationConfig,
  filters: Partial<Record<string | number | symbol, string[]>>,
  sorter: SorterResult<any>,
  extra: TableCurrentDataSource<T>,
) => void
const defaultWidth = 150
const defaultHeight = 30
const defaultMinWidth = 40
const defaultMaxWidth = 500
const defaultPagination = 25
const tableHeight = window.innerHeight - 170
const enableResizeDirection = {
  top: false,
  right: true,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
} as Enable

export const Table: FC<Props> = ({
  name = '',
  columns = [],
  data = [],
  pagination,
  summaryData,
  onChange,
  hiddenColumnsByDefault = [],
  additionalFilters = {},
  search = '',
  openTableSettings = false,
  onCloseTableSettings,
  autoHeight = false,
  hasPermission = true,
  hiddenColumns = [],
  ...rest
}) => {
  const dataForTableSettings = useMemo(() => {
    const initialSettingsObj: ITableSettings = {
      columns: [],
      columnsIndex: {},
      columnWidth: {},
      filters: null,
      sorter: null,
      search,
      page_size: pagination?.per_page || defaultPagination,
      ...additionalFilters,
    }
    return columns.reduce((acc, column, index) => {
      if (!hiddenColumnsByDefault?.includes(column?.dataIndex)) {
        acc.columns.push(column?.dataIndex)
      }
      acc.columnsIndex[column?.dataIndex] = index
      acc.columnWidth[column?.dataIndex] = column?.defaultWidth || defaultWidth
      return acc
    }, initialSettingsObj)
  }, [columns, name])

  const [tableSettings, setTableSettings] = useLocalStorage<ITableSettings>(
    `${name}-table_v1`,
    dataForTableSettings,
  )
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    tableSettings?.columns,
    // tableSettings?.columns?.filter(
    //   column => !hiddenColumnsByDefault?.includes(column),
    // ),
  )
  const [columnsCopy, setColumnsCopy] = useState(columns)

  useEffect(() => {
    if (window.location.pathname === '/companies') {
      return setValueToLS(`${name}-table_v1`, { search })
    }
    setValueToLS(`${name}-table_v1`, { search, ...additionalFilters })
  }, [additionalFilters, search, name])

  const handleSelectColumns = (selectedOptions: string[]) => {
    const sortedColumns = selectedOptions
      .reduce((acc, el) => {
        if (
          tableSettings?.columnsIndex[el] ||
          tableSettings?.columnsIndex[el] === 0
        ) {
          acc.push({ name: el, index: tableSettings?.columnsIndex[el] })
          return acc
        }
        acc.push({
          name: el,
          index: tableSettings?.columnsIndex[tableSettings?.columns?.length],
        })
        return acc
      }, [])
      .sort((a, b) => a?.index - b?.index)
      .map(el => el?.name)
    const col = columns?.filter(column =>
      sortedColumns?.includes(column?.dataIndex),
    )

    setSelectedColumns(sortedColumns)
    setTableSettings(prev => ({ ...prev, columns: sortedColumns }))
    setColumnsCopy(col)
  }
  const getColumnProps = (title: string) => ({
    filterDropdown: (props: FilterDropdownProps) => (
      <DefaultColumnFilter title={title} {...props} />
    ),
  })
  const handleTableChange: OnChangeTable = (
    pagination,
    filters,
    sorter,
    extra,
  ) => {
    setTableSettings(prev => ({
      ...prev,
      ...additionalFilters,
      search,
      page_size: pagination?.pageSize || defaultPagination,
      filters: filters,
      sorter: sorter.field
        ? {
            sort_field: sorter.order ? sorter.field : undefined,
            order:
              sorter.order === 'ascend'
                ? 'asc'
                : sorter.order === 'descend'
                ? 'desc'
                : undefined,
          }
        : undefined,
    }))
    onChange(pagination, filters, sorter, extra)
  }
  const onDragEnd: onDragEnd = (fromIndex, toIndex) => {
    const col = mergedColumn?.filter(column =>
      selectedColumns?.includes(column?.dataIndex),
    )
    const item = col?.splice(fromIndex, 1)[0]
    col?.splice(toIndex, 0, item)
    setColumnsCopy(col)
    setTableSettings(prev => ({
      ...prev,
      columns: col?.map(e => e?.dataIndex),
      columnsIndex: col.reduce((acc, column, index) => {
        acc[column?.dataIndex] = index
        return acc
      }, {}),
    }))
  }
  const heandleResizeStart: ResizeCallback = (event, dir, refToElement) => {
    event.preventDefault()
    event.stopPropagation()
    refToElement?.lastElementChild?.classList?.add('resize-element__active')
  }
  const heandleResizeStop: ResizeCallback = (
    event,
    dir,
    refToElement,
    delta,
    title,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    refToElement?.lastElementChild?.classList?.remove('resize-element__active')

    setTableSettings(prev => ({
      ...prev,
      columnWidth: {
        ...prev.columnWidth,
        [title]: (prev?.columnWidth?.[title] || defaultWidth) + delta?.width,
      },
    }))
  }
  const onDragStarHeandle = e => {
    const DRAG_LIND_STYLE = `position:absolute; top:30px; height:${tableHeight -
      100}px; width:100%; background-color:rgba(128, 128, 128, 1); z-index:999; margin-left:-2px;`
    const line = document.createElement('div')
    line.classList.add('dragable-line')
    line.setAttribute('style', DRAG_LIND_STYLE)
    e.target.appendChild(line)
  }
  const onDropHeandle = () => {
    document.querySelector('.dragable-line')?.remove?.()
  }

  const ResizableHeaderCell = props => {
    return (
      <Resizable
        as='th'
        bounds={'table'}
        handleWrapperClass='resize-element'
        size={{
          width: tableSettings?.columnWidth?.[props?.datatitle] || defaultWidth,
          height: defaultHeight,
        }}
        minWidth={defaultMinWidth}
        maxWidth={defaultMaxWidth}
        onResizeStart={heandleResizeStart}
        onResizeStop={(event, dir, refToElement, delta) => {
          heandleResizeStop(event, dir, refToElement, delta, props.datatitle)
        }}
        onDragStart={onDragStarHeandle}
        onDrop={onDropHeandle}
        onDragEnd={onDropHeandle}
        boundsByDirection
        enable={enableResizeDirection}
        enableUserSelectHack={false}
        {...props}
      ></Resizable>
    )
  }
  const mergedColumn = columnsCopy?.map(column => {
    const customRender = (text: string) => text || '-'
    const defaultValue = tableSettings?.filters?.[column.dataIndex]
    const defaultSort =
      tableSettings?.sorter?.sort_field === column?.dataIndex
        ? tableSettings?.sorter?.order
        : null
    const methodFromColumn =
      columns?.filter(col => col.dataIndex === column.dataIndex)?.[0] || {}
    column = {
      ...column,
      ...methodFromColumn,
      defaultFilteredValue:
        !!column?.defaultFilteredValue && column?.defaultFilteredValue?.length
          ? column?.defaultFilteredValue?.map(f => f?.toString() || f)
          : defaultValue
          ? defaultValue?.map(v => v?.toString())
          : null,

      defaultSortOrder:
        defaultSort === 'asc'
          ? 'ascend'
          : defaultSort === 'desc'
          ? 'descend'
          : null,
      render: column?.render ?? customRender,
      index: tableSettings?.columnsIndex?.[column?.dataIndex],
      onHeaderCell: column => ({
        datatitle: column?.dataIndex,
      }),
    }

    if (column?.filters || column?.noFilter) return column

    return {
      ...getColumnProps(column?.name),
      ...column,
    }
  })
  const isCheckboxVisible = window.location.pathname === '/accounting'

  return (
    <TableWrapper
      showInputInTh={isCheckboxVisible}
      autoHeight={autoHeight}
      tableHeight={tableHeight}
    >
      <Heading>
        <TableSettings
          openTableSettings={openTableSettings}
          onCloseTableSettings={onCloseTableSettings}
          columns={columns}
          selectedColumns={selectedColumns}
          onSelectColumns={handleSelectColumns}
          name={name}
        />
      </Heading>

      <ReactDragListView.DragColumn
        ignoreSelector='.ant-table-selection-column'
        onDragEnd={onDragEnd}
        nodeSelector='th'
        lineClassName='drag-line'
      >
        <AntdTable
          columns={mergedColumn
            ?.filter(
              column =>
                selectedColumns?.includes(column?.dataIndex) &&
                !hiddenColumns.includes(column?.dataIndex),
            )
            ?.sort((a, b) => a.index - b.index)}
          dataSource={data}
          onChange={handleTableChange}
          components={{
            header: {
              cell: ResizableHeaderCell,
            },
          }}
          pagination={
            pagination
              ? {
                  current: pagination?.current_page,
                  position: ['bottomRight'],
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '25', '50', '100', '250', '500'],
                  total: pagination?.total ?? 25,
                  pageSize: tableSettings.page_size,
                  size: 'small',
                  showTotal: (total, range) =>
                    !hasPermission
                      ? `1-10 of ${total} items`
                      : `${range[0]}-${range[1]} of ${total} items`,
                }
              : false
          }
          rowKey='id'
          showSorterTooltip={false}
          summary={
            !!summaryData && !!pagination?.total
              ? () => (
                  <TableSummary
                    data={summaryData}
                    columns={mergedColumn?.sort((a, b) => a.index - b.index)}
                    selectedColumns={selectedColumns}
                    total={pagination?.total}
                  />
                )
              : undefined
          }
          tableLayout='fixed'
          {...rest}
        />
      </ReactDragListView.DragColumn>
    </TableWrapper>
  )
}
