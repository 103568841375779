import React from 'react'
import { Form, Input, Button, InputProps, Popconfirm } from 'antd'
import InputMask from 'react-input-mask'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
}

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
}

const phoneReg = /^\(\d{3}\) \d{3}-\d{4}$/

export const ContactForm = ({
  handleFormChange,
  formIsEmpty,
  isButtonDisabled,
  form,
  fieldStatus,
}) => {
  return (
    <Wrapper>
      <Form.Item
        validateTrigger={['onBlur']}
        name='full_name'
        style={{ width: '90%' }}
        hasFeedback
        label='Full Name'
        shouldUpdate
        {...formItemLayout}
      >
        <Input autoComplete='off' placeholder='Full Name' />
      </Form.Item>

      <Form.List name='phones'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name, ...restField }, idx, array) => (
              <>
                <Row key={idx}>
                  <Form.Item
                    {...restField}
                    {...(idx === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    validateTrigger={['onBlur']}
                    name={[name, 'phone']}
                    style={{
                      width: '90%',
                    }}
                    hasFeedback
                    label={idx === 0 ? 'Phones' : ''}
                    shouldUpdate
                    rules={[
                      {
                        pattern: phoneReg,
                        message:
                          'Please input a valid phone number in the format (123) 456-7890',
                      },
                    ]}
                  >
                    <InputMask mask='(999) 999-9999' maskChar='X'>
                      {(inputProps: InputProps) => (
                        <Input placeholder='(999) 999-9999' {...inputProps} />
                      )}
                    </InputMask>
                  </Form.Item>
                  {idx !== 0 ? (
                    !!form.getFieldsValue()?.phones[idx]?.phone ? (
                      <Popconfirm
                        onConfirm={() => {
                          remove(name)
                          handleFormChange()
                        }}
                        title='Are you sure?'
                      >
                        <DeleteIconBlock>
                          <DeleteOutlined />
                        </DeleteIconBlock>
                      </Popconfirm>
                    ) : (
                      <DeleteIconBlock
                        onClick={() => {
                          remove(name)
                          handleFormChange()
                        }}
                      >
                        <DeleteOutlined />
                      </DeleteIconBlock>
                    )
                  ) : null}
                  {idx === array.length - 1 && fieldStatus.phones[idx] && (
                    <DeleteIconBlock>
                      <PlusOutlined onClick={() => add({ phone: '' })} />
                    </DeleteIconBlock>
                  )}
                </Row>{' '}
              </>
            ))}
          </>
        )}
      </Form.List>

      <Form.List name='emails'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name, ...restField }, idx, array) => (
              <>
                <Row key={idx}>
                  <Form.Item
                    {...restField}
                    {...(idx === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    name={[name, 'email']}
                    validateTrigger={['onBlur']}
                    style={{
                      width: '90%',
                    }}
                    hasFeedback
                    label={idx === 0 ? 'Emails' : ''}
                    shouldUpdate
                    rules={[
                      {
                        type: 'email',
                        message: 'Please input a valid email address!',
                      },
                    ]}
                  >
                    <Input
                      placeholder='username@example.com'
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  {idx !== 0 ? (
                    !!form.getFieldsValue()?.emails[idx]?.email ? (
                      <Popconfirm
                        onConfirm={() => {
                          remove(name)
                          handleFormChange()
                        }}
                        title='Are you sure?'
                      >
                        <DeleteIconBlock>
                          <DeleteOutlined />
                        </DeleteIconBlock>
                      </Popconfirm>
                    ) : (
                      <DeleteIconBlock
                        onClick={() => {
                          remove(name)
                          handleFormChange()
                        }}
                      >
                        <DeleteOutlined />
                      </DeleteIconBlock>
                    )
                  ) : null}
                  {idx === array.length - 1 && fieldStatus.emails[idx] && (
                    <DeleteIconBlock>
                      <PlusOutlined onClick={() => add({ email: '' })} />
                    </DeleteIconBlock>
                  )}
                </Row>
              </>
            ))}
          </>
        )}
      </Form.List>
      {!formIsEmpty && (
        <Button
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: '10%',
          }}
          htmlType='submit'
          disabled={isButtonDisabled}
        >
          Add Contact
        </Button>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & .anticon-check-circle {
    display: none;
  }
`

const Row = styled.div`
  min-height: 66px;
  display: flex;
  gap: 10px;
`
const DeleteIconBlock = styled.div`
  padding-top: 3px;
  cursor: pointer;
`
