import React from 'react'

const RingIcon = () => {
  return (
    <svg
      style={{ display: 'block' }}
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.2816 18.0456C19.5984 16.9897 19.4963 12.4578 19.4944 12.3434V9.30305C19.4944 6.37682 17.4422 3.86057 14.526 2.78918C14.5197 1.28473 13.1134 0.0625 11.3855 0.0625C9.65755 0.0625 8.25132 1.2848 8.24501 2.78911C5.32891 3.8605 3.27668 6.37675 3.27668 9.30298V12.3434C3.27479 12.4577 3.17263 16.9897 0.489346 18.0455C0.183676 18.1658 0.0156793 18.4557 0.0863519 18.7415C0.156942 19.0272 0.446934 19.2313 0.782392 19.2313H7.58478C7.72423 19.8997 8.08695 20.5215 8.64173 21.0252C9.37857 21.6941 10.353 22.0625 11.3854 22.0625C12.4178 22.0625 13.3923 21.6941 14.129 21.0252C14.6839 20.5215 15.0466 19.8996 15.186 19.2313H21.9884C22.3238 19.2313 22.6138 19.0272 22.6844 18.7415C22.7552 18.4557 22.5873 18.1659 22.2816 18.0456ZM18.7449 15.875C19.027 16.6201 19.4264 17.3684 19.9928 17.9911H2.77393C3.34021 17.3685 3.73962 16.6202 4.02169 15.875H18.7449ZM13.0329 2.38412C12.8261 1.76035 12.1653 1.30273 11.3837 1.30273C10.6021 1.30273 9.94125 1.76039 9.73443 2.38412C10.2671 2.28781 10.8187 2.23709 11.3837 2.23709C11.9486 2.23709 12.5002 2.28785 13.0329 2.38412ZM4.69979 12.3474V9.30312C4.69979 6.09142 7.69857 3.47852 11.3846 3.47852C15.0707 3.47852 18.0695 6.09142 18.0695 9.30312V12.3524C18.0704 12.4458 18.0871 13.4265 18.3708 14.6339H4.39833C4.68217 13.4254 4.69892 12.4429 4.69979 12.3474ZM9.04438 19.2305C9.34881 20.1383 10.2973 20.821 11.384 20.821C12.4708 20.821 13.4191 20.1383 13.7235 19.2305H9.04438Z'
        fill='#191919'
      />
    </svg>
  )
}

export default RingIcon
