import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'

export const BlockRoutingModal = ({
  status,
  isLoading,
  title,
  body,
  handleCancel,
  handleDiscard,
  handleSave,
  buttonsTitle,
  className = '',
}) => {
  return (
    <Modal
      show={status}
      onHide={isLoading ? () => {} : handleCancel}
      centered
      backdrop='static'
      className={`proposal-form-modal_wrapper ${className}`}
      dialogClassName='sure-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='buttons' style={{ marginBottom: '5px' }}>
          {buttonsTitle.discard && (
            <MainButton type='danger' onClick={handleDiscard} disabled={isLoading} title={buttonsTitle.discard} />
          )}
          {buttonsTitle.save && (
            <MainButton onClick={handleSave} disabled={isLoading} isFetching={isLoading} title={buttonsTitle.save} />
          )}
        </div>
        <div className='buttons' style={{ margin: 0 }}>
          <MainButton type='cancel' onClick={handleCancel} disabled={isLoading} title={buttonsTitle.cancel} />
        </div>
      </Modal.Body>
    </Modal>
  )
}
