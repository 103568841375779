import { uuid4 } from '@sentry/utils'
import { DispatchEvent } from 'features/Dispatch/types'
import moment, { Moment } from 'moment-timezone'

type AppointmentCreate = (
  techId: number,
  timeStart: number,
  timeEnd: number,
  industrieId: number,
  currentDate: Moment,
) => DispatchEvent

export enum AppointmentEventType {
  appointments_queue = 'queue',
  jobs_queue = 'jobs',
  appointments_recurring = 'recurring',
  jobs_recurring = 'recurring_jobs',
  appointments_used = 'used',
  appointments_lead = 'lead',
  todo_lists = 'todo',
  appointments_created = 'create',
}

export const appointmentEventType: { [key: string]: string } = {
  appointments_queue: 'queue',
  jobs_queue: 'jobs',
  appointments_recurring: 'recurring',
  jobs_recurring: 'recurring_jobs',
  appointments_used: 'used',
  appointments_lead: 'lead',
  todo_lists: 'todo',
  appointments_created: 'create',
}

export const createAppointmentData: AppointmentCreate = (
  techId,
  timeStart,
  timeEnd,
  industrieId,
  currentDate,
) => {
  const appointmentData = {
    appointment_type_id: null,
    appointment_source_id: null,
    preferred_technician_id: 0,
    entity_type: 1,
    kind: 1,
    id: uuid4(),
    status: 1,
    background_color: null,
    // user_id: techId === 'misc' ? 'misc' : Number(techId),
    // appointed_technician_id: techId,
    column_template_id: [+techId],
    matrix_time_start: moment()
      .set('hour', timeStart)
      .set('minute', 0)
      .format('HH:mm'),
    matrix_time_end: moment()
      .set('hour', timeEnd)
      .set('minute', 0)
      .format('HH:mm'),
    industry_id: industrieId,
    date: moment(currentDate).format('YYYY-MM-DD'),
    time_start: moment()
      .set('hour', timeStart)
      .set('minute', 0)
      .format('HH:mm'),
    time_end:
      moment()
        .set('hour', timeStart + 2)
        .set('minute', 0)
        .format('HH:mm') === '00:00'
        ? '23:00'
        : moment()
            .set('hour', timeStart + 2)
            .set('minute', 0)
            .format('HH:mm'),
    current_start_time: timeStart,
    current_end_time: timeEnd,
    // current_start_time: moment()
    //   .set('hour', timeStart)
    //   .set('minute', 0)
    //   .hour(),
    // current_end_time:
    //   moment()
    //     .set('hour', timeEnd)
    //     .set('minute', 0)
    //     .hour() === 0
    //     ? 24
    //     : moment()
    //         .set('hour', timeEnd)
    //         .set('minute', 0)
    //         .hour(),
  }
  return appointmentData
}

export const prepareDataForAppointment = appointment => {
  return {
    ...appointment,
    action:
      appointment?.action === 'used' ||
      appointment?.action === 'misc' ||
      appointment?.action === 'queue'
        ? getTypeTo(appointment?.action)
        : appointment?.action,
    created_at: appointment?.created_at
      ? moment(appointment.created_at).format('YYYY-MM-DD HH:mm:ss')
      : null,
    appointment_source_id: appointment?.appointment_source_id
      ? appointment?.appointment_source_id
      : 0,
    appointment_type_id: appointment?.appointment_type_id
      ? appointment?.appointment_type_id
      : 0,
    assign_to_matrix:
      appointment?.action === 'appointments_queue' &&
      !appointment.assign_to_matrix
        ? false
        : true,
    time_start: appointment?.time_start
      ? moment(appointment.time_start, 'HH:mm')
      : null,
    time_end: appointment?.time_end
      ? moment(appointment.time_end, 'HH:mm')
      : null,
    matrix_time_end: appointment?.matrix_time_end
      ? moment(appointment?.matrix_time_end, 'HH:mm')
      : appointment?.current_end_time || appointment?.current_end_time === 0
      ? moment()
          .set('hour', appointment?.current_end_time)
          .set('minute', 0)
      : null,

    matrix_time_start: appointment?.matrix_time_start
      ? moment(appointment?.matrix_time_start, 'HH:mm')
      : appointment?.current_start_time || appointment?.current_start_time === 0
      ? moment()
          .set('hour', appointment?.current_start_time)
          .set('minute', 0)
      : null,

    preferred_technician_id: appointment?.preferred_technician_id
      ? appointment?.preferred_technician_id
      : null,

    appointed_technician_id:
      appointment?.action === 'appointments_misc'
        ? 'misc'
        : appointment?.appointed_technician_id
        ? appointment?.appointed_technician_id
        : 0,
  }
}

export const getAmericanTime = time => {
  if (time && time !== null) {
    const configuredTime =
      moment(time, 'HH:mm').minutes() === 0
        ? moment(time, 'HH:mm').format('h a')
        : moment(time, 'HH:mm').format('h:mm a')
    const config = t =>
      t
        .replace(' ', '')
        .slice(0, -1)
        .toLowerCase()
    return config(configuredTime)
  }
}
export const getAmericanTime12Format = time => {
  if (time && time !== null) {
    const configuredTime =
      moment(time, 'HH:mm').minutes() === 0
        ? moment(time, 'HH:mm').format('h a')
        : moment(time, 'HH:mm').format('h:mm a')
    const config = t => t.replace(' ', '').toLowerCase()
    return config(configuredTime)
  }
}

export const getType = app_type => {
  if (app_type === 'appointments_used') {
    return 'used'
  }
  if (app_type === 'appointments_queue') {
    return 'queue'
  }
  if (app_type === 'appointments_misc') {
    return 'misc'
  }
  if (app_type === 'appointments_created') {
    return 'used'
  }
}
export const getTypeTo = app_type => {
  if (app_type === 'used') {
    return 'appointments_used'
  }
  if (app_type === 'job_queue') {
    return 'jobs_queue'
  }
  if (app_type === 'appointments_queue') {
    return 'appointments_queue'
  }
  if (app_type === 'queue') {
    return 'appointments_queue'
  }
  if (app_type === 'misc') {
    return 'appointments_misc'
  }
  if (app_type === 'created') {
    return 'appointments_created'
  }
  if (app_type === 'recurring_queue') {
    return 'appointments_recurring'
  }
  if (app_type === 'jobs_recurring') {
    return 'jobs_recurring'
  }
}

export const getIntegerPartFromDevision = (a, b) => Math.trunc(a / b)

export const getRoundedValue = number =>
  number >= 0 ? Math.floor(number) : Math.ceil(number)

export const filterByTech = (
  events: DispatchEvent[],
  choosenEmployees: number[] = [],
) => {
  if (!choosenEmployees?.length) return events

  return events?.filter(event =>
    choosenEmployees?.includes(event.preferred_technician_id),
  )
}

export const getEventStatusStyles = (status: number) => {
  switch (status) {
    case 2:
      return `linear-gradient(
      to top right,
      #fff0 calc(50% - 2px),
      rgb(255, 255, 255),
      #fff0 calc(50% + 2px)
    );`
    case 3:
      return `linear-gradient(
        to top right,
        #fff0 calc(50% - 2px),
        #009b0a,
        #fff0 calc(50% + 2px)
      );`
    case 4:
      return `
      linear-gradient(
        to top right,
        #fff0 calc(50% - 2px),
        #ff0000,
        #fff0 calc(50% + 2px)
      ),
      linear-gradient(
        to top left,
        #fff0 calc(50% - 2px),
        #ff0000,
        #fff0 calc(50% + 2px)
      );
      `
    case 5:
      return `linear-gradient(
          to top right,
          #fff0 calc(50% - 2px),
          rgb(255, 106, 0),
          #fff0 calc(50% + 2px)
        );`

    default:
      return 'initial'
  }
}
