import styled from 'styled-components'

interface Props {
  children: React.ReactNode
}

export const OptionGroup = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  position: relative;
  margin-top: 10px;

  &::before {
    position: absolute;
    content: 'Please select one of the options:';
    left: 15px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.26);
    top: -10.5px;
    background-color: #fff;
    padding: 0 5px;
  }
`
