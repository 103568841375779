export const styles = theme => ({
  cropWrapper: {
    margin: '0 auto 1.5rem',
  },
  cropContainer: {
    position: 'relative',
    width: 200,
    height: 200,
    background: 'white',
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: '16px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  logoPlaceholder: {
    height: 228,
    width: 228,
    padding: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    background: '#ffffff',
    border: '1px solid #d6d6d6',
    borderRadius: '4px',
  },
  dragLabelContainer: {
    position: 'absolute',
    backgroundColor: 'white',
    top: 0,
    left: 0,
    transform: 'translate(9px, -50%)',
    padding: '0 5px',
    height: '5px',
    zIndex: 1,
  },
  dragLabel: {
    fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
    fontWeight: 400,
    lineHeight: '2px',
    letterSpacing: '0.00938em',
    color: '#000000',
    opacity: 0.5,
    fontSize: 12,
  },
  root: {
    width: '180px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  controlsBtn: {
    cursor: 'pointer',
  },
  sliderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 228,
    //flex: '1',
    alignItems: 'center',
  },

  sliderLabel: {
    fontSize: '13px',
    lineHeight: '19px',
    letterSpacing: '0.01em',
    color: '#979797',
  },
  buttonsBlock: {
    display: 'flex',
    justifyContent: 'space-around',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    width: 228,
    letterSpacing: '0.01em',
    [`&>button:first-child`]: {
      color: '#979797',
    },
    [`&>button:last-child`]: {
      color: '#4C53EF',
    },
  },
  slider: {
    padding: '16px 0px',
    width: '160px',
  },
})
