import React, { memo } from 'react'
import './TabActionButtonsBlock.scss'
import {
  CopyIconThin,
  EditIcon,
  RightIcon,
  LeftIcon,
  DeleteIcon,
} from '../../../../../components/Icons/CommonIcons'
import {
  PaymentModuleIcon,
  EquipmentModuleIcon,
  ModuleOneIcon,
  ModuleTwoIcon,
  ModuleThreeIcon,
} from '../../../../../components/Icons/ProposalModuleIcons'
import { Tooltip } from 'antd'
import { SmallPreloader } from '../../../../../components/Preloader/SmallPreloader'
import { connect } from 'react-redux'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectProject } from 'features/Project/projectSelectors'

const _left = 'left'
const _right = 'right'
const _edit = 'edit'
const _copy = 'copy'
const _delete = 'delete'

const _payment = 'payment'
const _equipment = 'equipment'
const _module_1 = 'moduleOne'
const _module_2 = 'moduleTwo'
const _module_3 = 'moduleThree'

const ButtonsWithName = {
  [_left]: {
    icon: <LeftIcon />,
    name: 'Move Left',
  },
  [_right]: {
    icon: <RightIcon />,
    name: 'Move Right',
  },
  [_edit]: {
    icon: <EditIcon />,
    name: 'Edit Tab Info',
  },
  [_copy]: {
    icon: <CopyIconThin />,
    name: 'Duplicate',
  },
  [_delete]: {
    icon: <DeleteIcon />,
    name: 'Delete',
  },

  [_payment]: {
    icon: <PaymentModuleIcon />,
    name: 'Payment',
  },
  [_equipment]: {
    icon: <EquipmentModuleIcon />,
    name: 'Supplies',
  },
  [_module_1]: {
    icon: <ModuleOneIcon />,
    name: '',
  },
  [_module_2]: {
    icon: <ModuleTwoIcon />,
    name: '',
  },
  [_module_3]: {
    icon: <ModuleThreeIcon />,
    name: '',
  },
}

const GenerateButton = memo(
  ({ name, action, onClick, disabled, loading, modulesData, hideButton }) =>
    !hideButton ? (
      <Tooltip
        title={`${
          name === 'equipment' && disabled
            ? 'Module is off. Please go to Subscription to enable it'
            : ''
        }`}
      >
        <div
          title={
            !!modulesData && !!modulesData[name].name
              ? modulesData[name].name
              : ButtonsWithName[name].name
          }
          className={`proposal-form-tabs_content-tab_action_block-button ${name}${
            disabled ? ' disabled' : ''
          }${modulesData ? ` ${modulesData[name].status}` : ''}`}
          role='button'
          onClick={onClick}
        >
          {loading || (!!modulesData && modulesData[name].isStatusChanging) ? (
            <SmallPreloader />
          ) : (
            ButtonsWithName[name].icon
          )}
          <span>
            {!!modulesData && !!modulesData[name].name
              ? modulesData[name].name
              : ButtonsWithName[name].name}
          </span>
        </div>
      </Tooltip>
    ) : null,
)

GenerateButton.displayName = 'GenerateButton'

const buttonsNavOrderArray = [_left, _right, _edit, _copy, _delete]
const buttonsNavOrderArrayShort = []
const buttonsModulesOrderArray = [
  _payment,
  _equipment,
  _module_1,
  _module_2,
  _module_3,
]

const TabActionButtonsBlock = memo(props => {
  const { signed_tab_id, activeTabId } = useAppSelector(selectProject)

  const isContractTab = signed_tab_id === activeTabId

  const isShortButtonList = props.showAllTabs
    ? buttonsNavOrderArray
    : buttonsNavOrderArrayShort
  const order =
    props.buttonsType === 'modules'
      ? buttonsModulesOrderArray
      : isShortButtonList
  const isLikeContract =
    props.proposalStatus === 2 ||
    props.proposalStatus === 3 ||
    props.proposalStatus === 4
  const buttons = order.map(name => {
    let disabled = false
    let loading = false
    let hideButton = false
    if (!props.isTabLoading) {
      if (name === _left) {
        disabled = props.isFirstTab
      } else if (name === _right) {
        disabled = props.isLastTab
      } else if (name === _copy) {
        loading = props.isTabCopying
        disabled = hideButton = isLikeContract || props.isEnoughTabs
      } else if (name === _delete) {
        loading = props.isTabDeleting
        disabled = props.isFirstTab && props.isLastTab
        hideButton = isContractTab
      } else if (name === _payment) {
        //disabled = props.proposalStatus === 1
      } else if (name === _equipment) {
        disabled = false /*  !props.equipmentModuleStatus && props.subscriptionOff */
      }
      if (
        props.buttonsType === 'modules' &&
        isLikeContract &&
        ((props.proposalStatus !== 2 && props.proposalStatus !== 3) ||
          (props.proposalStatus === 2 && !props.isTabContract) ||
          (props.proposalStatus === 3 &&
            props.modulesData[name].status === ' default'))
      ) {
        disabled = true
      }
    } else {
      disabled = loading = hideButton = props.isTabLoading
    }

    const handleClick = () => {
      if (disabled) return
      props[name]()
    }

    return (
      <GenerateButton
        key={name}
        name={name}
        onClick={handleClick}
        // action={props[name]}
        disabled={disabled || loading}
        loading={loading}
        modulesData={props.modulesData}
        hideButton={hideButton}
      />
    )
  })
  return (
    <div className='proposal-form-tabs_content-tab_action_block'>{buttons}</div>
  )
})

TabActionButtonsBlock.displayName = 'TabActionButtonsBlock'

const mapStateToProps = state => ({
  subscriptionOff: state.orcatec.company.subscription_off,
  showAllTabs: state.orcatec.projectSlice.project.setting.multiple_tabs,
})

export default connect(mapStateToProps)(TabActionButtonsBlock)
