import { IAppointment } from 'types/Appointment'
import Event from '../../../../containers/MainContent/Orcatec/Matrix/components/Event'
import { Box, QueueEvents } from './Queue.style'
import { useAppSelector } from 'store/Orcatec/hooks'
import QueueRowHeading from './components/QueueRowHeading'
import { useRef } from 'react'
// import { filterByTech } from '../../../../containers/MainContent/Orcatec/Matrix/helpers'
import {
  selectDispatchSettings,
  selectSortedEventsByQueue,
} from 'features/Dispatch/dispatchSlice'
import { DispatchQueue } from '../types'
import { DispatchEvent } from 'features/Dispatch/types'
import { getQueueIcon } from '../helpers'

import './styles.scss'

interface QueueProps {
  data: DispatchQueue
  contentType: string
  choosenEmployees: number[]
  dragEventEnd?: () => void
  dragEventStart?: () => void
  showContent: boolean
  toggleContent: (queueType: string) => void
  onDrop: (queueId: number) => void
  onDublicate: () => void
  onCreateProposal: () => void
  heandleChangeStatusAppointment: (data: IAppointment) => void
}

export const QueueRow: React.FC<QueueProps> = ({
  data,
  toggleContent,
  dragEventStart,
  dragEventEnd,
  onDrop,
  onDublicate,
  onCreateProposal,
  onSetEventData,
  onOpenAppointment,
  eventType,
  handleDeleteAppointment,
  handleClickRfRModal,
  tooltipLoading,
  heandleChangeStatusAppointment,
  // choosenEmployees,
  handleOpenContactModal,
  handleOpenPublicEventModal,
}) => {
  const { id, title, icon } = data

  // const { tooltip_mod } = useAppSelector(selectDispatchSettings)

  const { tooltip_mod: openOnClick } = useAppSelector(selectDispatchSettings)
  const technicians = useAppSelector(
    state => state.orcatec.dispatchSlice.technicians,
  )
  const events = useAppSelector(selectSortedEventsByQueue(id))
  const ref = useRef<HTMLDivElement>(null)

  // const isNewlook = tooltip_mod || false
  const queueLength = technicians?.length > 12 ? technicians?.length : 12
  const queueWidth = queueLength * 60 + queueLength * 5

  const handleClickEvent = (event: DispatchEvent) => {
    if (!openOnClick) {
      onSetEventData?.(event, eventType)
      onOpenAppointment?.()
    }
  }

  const mapedEvents = events /* filterByTech(events, choosenEmployees) */

  const getBoxQuantity = () => Math.floor((ref?.current?.clientWidth || 0) / 65) // get box quantity based on screen width and box width

  return (
    <div className='queue-row'>
      <QueueRowHeading
        title={title}
        icon={getQueueIcon(icon)}
        counter={events.length}
        onClick={() => toggleContent(title)}
      />

      <QueueEvents
        onDragOver={onDrop ? e => e.preventDefault() : undefined}
        onDrop={e => {
          onDrop(data.id)
          const childNodes = e.currentTarget.childNodes
          childNodes.forEach(child => (child.style.backgroundColor = 'white'))
        }}
        ref={ref}
        maxWidth={queueWidth}
      >
        {[
          ...Array(Math.max(mapedEvents?.length || 0, getBoxQuantity(), 12)),
        ].map((el, i) => (
          <Box
            key={i}
            onDragEnter={
              onDrop
                ? e =>
                    (e.currentTarget.style.backgroundColor =
                      'rgb(193, 197, 199)')
                : undefined
            }
            onDragLeave={
              onDrop
                ? e => (e.currentTarget.style.backgroundColor = 'white')
                : undefined
            }
          >
            {!!mapedEvents?.length && mapedEvents[i] && (
              <Event
                index={i}
                data={mapedEvents[i]}
                onClick={handleClickEvent}
                onDragStart={dragEventStart}
                dragEventEnd={dragEventEnd}
                onDublicate={onDublicate}
                onCreateProposal={onCreateProposal}
                handleDeleteAppointment={handleDeleteAppointment}
                handleClickRfRModal={handleClickRfRModal}
                eventType={eventType}
                onSetEventData={onSetEventData}
                onOpenAppointment={onOpenAppointment}
                tooltipLoading={tooltipLoading}
                heandleChangeStatusAppointment={heandleChangeStatusAppointment}
                handleOpenContactModal={handleOpenContactModal}
                handleOpenPublicEventModal={handleOpenPublicEventModal}
              />
            )}
          </Box>
        ))}
      </QueueEvents>
    </div>
  )
}
