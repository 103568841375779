import styled from 'styled-components'

const Section = styled.section`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  min-height: 100px;
`

const Wrapper = styled.div``

const ItemWrapper = styled.div<{
  isPrimary: boolean
  label: string
  isDragging: boolean
  freezed: boolean
}>`
  position:${props => (props.isDragging ? 'absolute' : 'relative')};
  padding: 10px;
  transition: background-color 0.2s ease;
  border: ${props =>
    props.isPrimary || props.isDragging ? '1px solid #ccc' : 'none'};
  display: grid;
  grid-template-columns: 20px 1fr;
  background-color: #fff;
  height:100%;
  pointer-events:${props => (props.freezed ? 'not-allowed' : 'all')};
  min-width:272px;
  min-height:90px;
  &:before {
    content: "${props => (props.isPrimary ? props.label : '')}";
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .01em;
    color: #191919;
    font-weight: 700;
    background-color: #fff;
    display:block;
    position:absolute;
    top:-10px;
    left:14px;
  }
`

export { Section, Wrapper, ItemWrapper }
