import { TextField } from 'components/UIKit'
import { DISPATCH_COLORS } from 'constants/colors'
import { ProjectStatus } from 'features/Project/types'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useRef, useState } from 'react'
import { SketchPicker } from 'react-color'
import styled from 'styled-components'
import { Error } from 'types/Error'

interface Props {
  data: ProjectStatus
  error: Error | null
  onChange: (e) => void
}

export const StatusForm = ({ data, onChange, error }: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => setShowPicker(false))

  const [showPicker, setShowPicker] = useState(false)

  return (
    <Wrapper>
      <TextField
        label='Status name'
        placeholder='Enter status name'
        name='display_name'
        value={data.display_name}
        onChange={onChange}
        error={error?.display_name || undefined}
        helperText='Max 30 characters'
        maxLength={30}
        required
      />

      <Color
        onClick={() => {
          setShowPicker(true)
        }}
        color={data?.color}
      />

      {showPicker && (
        <PickerWrapper ref={ref}>
          <SketchPicker
            color={data?.color}
            onChangeComplete={({ hex }) =>
              onChange({
                target: {
                  name: 'color',
                  value: hex,
                },
              })
            }
            presetColors={DISPATCH_COLORS}
          />
        </PickerWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const Color = styled.div`
  background-color: ${props => props?.color || '#626ed4'};
  flex: 0 0 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 4px;
`
export const PickerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 80px;
  z-index: 1000;
  & .sketch-picker {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`

export const ButtonPickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: white;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
`
