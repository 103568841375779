import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import ModalWrapper from '../../Proposals/components/Modals/ModalWrapper/ModalWrapper'
import Preloader from '../Preloader'

const SureModal = ({
  modal,
  close,
  apply,
  body,
  title = 'Are you sure?',
  isLoading,
  okTitle = 'Confirm',
  cancelTitle = 'Cancel',
  dialogClassName = '',
  customCancelBtnAction,
}) => {
  return (
    <>
      <ModalWrapper
        dialogClassName={dialogClassName ?? 'sure-modal'}
        modal={modal}
        close={close}
        apply={apply}
        isSaving={isLoading}
        cancelText={cancelTitle}
        okText={okTitle}
        customCancelBtnAction={customCancelBtnAction}
      >
        {body ? (
          body
        ) : (
          <div className='w-100 d-flex justify-content-center align-items-center text-center'>
            <span>{title}</span>
          </div>
        )}
      </ModalWrapper>

      {/* <Modal show={modal} onHide={isLoading ? () => {} : close} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant='info' onClick={close} disabled={isLoading}>
          {cancelTitle}
        </Button>
        <Button variant='danger' onClick={apply} disabled={isLoading}>
          <div className='d-flex align-items-center'>{okTitle}</div>
        </Button>
      </Modal.Footer>
    </Modal> */}
    </>
  )
}
export default SureModal
