import axiosOrcatec from './axiosInstance'
import moment from 'moment-timezone'
import axios from 'axios'
import { configV2API } from './helpers'
import { ITechnician } from 'types/Appointment'
import { Column } from 'features/Dispatch/types'
import { DispatchQueue } from 'features/Dispatch/Queue/types'

let cancelToken

export const getMatrixLog = date => {
  const params = date.range
    ? `range[start_date]=${date.range[0]}&range[end_date]=${date.range[1]}`
    : `date=${date === 'Invalid date' ? moment().format('MM/DD/YYYY') : date}`
  // const params = date.range ? `date=${date.range[0]}` : `date=${date}`
  if (cancelToken) {
    cancelToken.cancel('Same request is executing')
  }
  cancelToken = axios.CancelToken.source()
  return axiosOrcatec
    .get(`matrix?${params}`, { cancelToken: cancelToken.token })
    .then(res => res.data)
    .catch(error => {
      if (axios.isCancel(error)) console.error(error?.message)
    })
}

export const updateMatrixLog = (date, obj) => {
  // const config = { baseURL: process.env.REACT_APP_API_URL?.replace?.('v1.0', 'v2') }
  return axiosOrcatec
    .post(`matrix?date=${date}`, obj, configV2API)
    .then(res => res.data)
}

// export const updateTechOrder = data => {
//   return axiosOrcatec.post(`matrix/set-sortable-tech`, data).then(res => res.data)
// }
export const updateColumnOrder = data => {
  return axiosOrcatec
    .put(`matrix/set-sortable-column`, data, configV2API)
    .then(res => res.data)
}

export const getTechniciansByColumn = (params: { date: string }) => {
  return axiosOrcatec
    .get(`matrix/tech-list-by-date`, { params, ...configV2API })
    .then(res => res.data)
}

export const getClientsForNotificationModal = appointmentID =>
  axiosOrcatec
    .get(`appointments/${appointmentID}/clients`)
    .then(res => res.data)

export const getScheduleData = date => {
  const params = date.range
    ? `range[start_date]=${date.range[0]}&range[end_date]=${date.range[1]}`
    : `date=${date === 'Invalid date' ? moment().format('MM/DD/YYYY') : date}`

  if (cancelToken) {
    cancelToken.cancel('Same request is executing')
  }
  cancelToken = axios.CancelToken.source()

  const config = { ...configV2API, cancelToken: cancelToken.token }

  return axiosOrcatec
    .get(`matrix/get-schedule?${params}`, config)
    .then(res => res.data)
}
export const getQueuesData = () =>
  axiosOrcatec
    .get<DispatchQueue[]>('matrix/get-queue-events')
    .then(res => res.data)
export const getQueuesList = () =>
  axiosOrcatec
    .get<DispatchQueue[]>('matrix/get-queue-list')
    .then(res => res.data)
// export const fetchQueue = params => axiosOrcatec.get('matrix/get-queues-by-type/', { params }).then(res => res.data)

export const getColumnsByDate = params =>
  axiosOrcatec
    .get<Column[]>('matrix/get-columns-by-date', { params, ...configV2API })
    .then(res => res.data)

export const getFreeTechs = params =>
  axiosOrcatec
    .get<ITechnician[]>('matrix/tech-list', { params, ...configV2API })
    .then(res => res.data)

export const getSettingsFreeTechs = () =>
  axiosOrcatec
    .get<ITechnician[]>('setting/tech-list', configV2API)
    .then(res => res.data)

export interface UpdateColumnBody extends Column {
  today_only: boolean
  date: string
}
export const updateColumn = (column: UpdateColumnBody) =>
  axiosOrcatec
    .put(`matrix/columns/${column.id}`, column, configV2API)
    .then(res => res.data)

export const getContactByEventId = (eventId: number) => {
  if (cancelToken) {
    cancelToken.cancel('Same request is executing')
  }
  cancelToken = axios.CancelToken.source()
  return axiosOrcatec
    .get(`/events/${eventId}/primary-contact`, {
      cancelToken: cancelToken.token,
    })
    .then(res => res?.data?.data)
}
