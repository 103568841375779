import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Autocomplete from 'containers/MainContent/Orcatec/components/Autocomplete'
import { AppointmentType } from 'types/Appointment'
import styled from 'styled-components'
import { Popconfirm, Tooltip } from 'antd'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  createCompanyTypes,
  deleteCompanyTypes,
} from 'store/Orcatec/operation/settings/company'
import { Error } from 'types/Error'
import { selectDispatchSettings } from 'features/Dispatch/dispatchSlice'
import { EventColorType } from 'features/Dispatch/types'

const sourceAndTypeStyle = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '14px',
  letterSpacing: '0.01em',
  color: ' #191919',
}

interface Props {
  label?: string
  name: string
  value: number | null
  isLead?: boolean
  withoutColor?: boolean
  error?: { [key: string]: string }
  onChange: (
    e: { target: { name: string; value: any } },
    ignoreEdit?: boolean,
  ) => void
  setEdited?: (val: boolean) => void
  setAppointmentType?: (types: AppointmentType[]) => void
  required?: boolean
  disableCRUD?: boolean
  onClick?: () => void
}

const Color = styled.div`
  background-color: ${props => props?.color || '#626ed4'};
  display: inline-block;
  flex: 0 0 25px;
  height: 20px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid;
`

const Wrapper = styled.div`
  & input {
    padding: 6.5px 28px 6.5px 4px !important;
  }
`

export const AppointmentTypes: FC<Props> = ({
  onChange,
  name,
  label,
  value,
  error,
  setAppointmentType,
  disableCRUD,
  withoutLabel,
  withoutColor,
  disableClearable,
  cropped,
  ...rest
}) => {
  const appTypes: AppointmentType[] = useAppSelector(
    state => state.orcatec?.company?.types,
  )
  const [loading, setLoading] = useState(false)
  const [localError, setLocalError] = useState<Error | null>(null)

  const { event_color_type } = useAppSelector(selectDispatchSettings)
  const hideTypeColor = event_color_type !== EventColorType['Event type']
  const dispatch = useDispatch()

  useEffect(() => {
    setAppointmentType?.(appTypes)
    // dispatch(updateCompanyTypesAction({ id: null, name: isLead ? 'Public form' : 'Instant appointment' }))
    // if (value === 0) {
    //   const defaultValue = appTypes?.find(type => type?.name === '-')
    //   onChange({ target: { name, value: defaultValue?.id } })
    //   setEdited?.(false)
    // }
  }, [])

  const handleChangeAppType = async (
    event: React.ChangeEvent<HTMLInputElement>,
    value: any,
  ) => {
    if (localError) setLocalError(null)

    if (value?.id) return onChange({ target: { name, value: value.id } })
    if (!value) return onChange({ target: { name, value: null } })

    const newValue = value?.inputValue || value

    const existingType = appTypes
      ?.filter(type => !type.deleted_at)
      ?.find(type => type.name === newValue)
    if (existingType)
      return onChange({ target: { name, value: existingType.id } })

    if (disableCRUD) {
      return setLocalError({
        [name]: 'Select type from list',
      })
    }

    try {
      setLoading(true)
      await dispatch(createCompanyTypes({ name: newValue })).then(type => {
        onChange({ target: { name, value: type.id } })
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleBlurEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const exitstingType = appTypes?.map(type => type?.name)
    // if (!value) {
    //   const defaultValue = appTypes?.find(type => type.name === '-')
    //   onChange({ target: { name, value: defaultValue?.id } })
    //   return
    // }
    if (!value || exitstingType.includes(value)) return

    handleChangeAppType(e, value)
  }

  const handleDeleteType = async (
    e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    id: number,
  ) => {
    e?.stopPropagation()
    dispatch(deleteCompanyTypes(id))
  }

  const filterOptions = (options, params) => {
    const filtered = options?.filter(
      option =>
        option?.id !== null && !option?.deleted_at && option?.name !== '-',
    )
    const filter = filtered?.filter(f =>
      f?.name?.toLowerCase()?.includes(params?.inputValue?.toLowerCase()),
    )
    if (disableCRUD) return filter
    if (params?.inputValue !== '' && !filter?.length) {
      filter?.push({
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`,
      })
    }

    return filter
  }

  return (
    <Wrapper>
      <Autocomplete
        label={withoutLabel ? '' : label || 'Appointment Type'}
        loading={loading}
        name={name}
        value={value}
        onChange={handleChangeAppType}
        onBlur={handleBlurEvent}
        disableClearable={disableClearable}
        options={appTypes}
        error={localError || error}
        filterOptions={filterOptions}
        readOnly={disableCRUD}
        renderOption={(option: AppointmentType) => (
          <div style={sourceAndTypeStyle}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                maxWidth: cropped ? 125 : 'auto',
              }}
            >
              {!hideTypeColor && !withoutColor && (
                <Color color={option?.color?.hex || '#4B94D8'} />
              )}
              <Tooltip title={option?.name} zIndex={9999} mouseLeaveDelay={0}>
                <Elipsis>{option?.name}</Elipsis>
              </Tooltip>
            </div>
            {!!option?.id && !disableCRUD && (
              <Popconfirm
                title='Are you sure you want to delete this type?'
                overlayStyle={{
                  zIndex: 1400, //to show tooltip over dropdown
                }}
                onConfirm={e => {
                  if (!option.id) return

                  handleDeleteType(e, option?.id)
                }}
              >
                <i
                  className='mdi mdi-delete p-1'
                  style={{ cursor: 'pointer', fontSize: '18px' }}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                />
              </Popconfirm>
            )}
          </div>
        )}
        {...rest}
      />
    </Wrapper>
  )
}

// export default AppointmentTypes

const Elipsis = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
