import React, { useEffect } from 'react'
import { dataURLFileLoader } from '../../utils/fileLoaders.tsx'

const HTMLRenderer = ({ state }) => {
  const { currentDATA } = state
  useEffect(() => {
    const b64String = currentDATA
    const bodyBase64 = b64String?.replace('data:text/html;base64,', '') || ''
    const body = window.atob(bodyBase64)

    const iframeCont = document.getElementById('html-body')
    const iframe = iframeCont?.contentWindow && iframeCont.contentWindow
    if (!iframe) return

    const iframeDoc = iframe.document
    iframeDoc.open()
    iframeDoc.write(`${body}`)
    iframeDoc.close()
  }, [])

  return (
    <div className='html-renderer' id='html-renderer'>
      <iframe
        crossOrigin='anonymous'
        className='html-body'
        id='html-body'
        sandbox='allow-same-origin'
        src=''
        frameborder='0'
      ></iframe>
    </div>
  )
}

export default HTMLRenderer

HTMLRenderer.fileTypes = ['htm', 'html', 'text/htm', 'text/html']
HTMLRenderer.fileLoader = dataURLFileLoader
