import { useState, useEffect } from 'react'
import { useAppSelector } from 'store/Orcatec/hooks'

export const useIsFreezed = (isEditContract: boolean): boolean => {
  const [isFreezed, setIsFreezed] = useState(true)
  const status = useAppSelector(
    state => state.orcatec.projectSlice.project.status,
  )

  useEffect(() => {
    if (status) {
      status === 1 || isEditContract ? setIsFreezed(false) : setIsFreezed(true)
    }
  }, [status, isEditContract])

  return isFreezed
}
