import { MenuItem } from '@material-ui/core'
import InputField, { InputFieldProps } from '../InputField'

export interface SelectOption {
  id: number | string
  name: string
  disabled?: boolean
}

type SelectProps = InputFieldProps & {
  options: SelectOption[]
  noValue?: boolean
  hideDisabled?: boolean
}

export const SelectV2: React.FC<SelectProps> = ({
  label,
  options,
  noValue,
  hideDisabled,
  ...rest
}) => {
  const filteredOptions = hideDisabled
    ? options?.filter(option => !option.disabled)
    : options

  return (
    <InputField label={label} select {...rest}>
      {noValue && <MenuItem value=''>No {label}</MenuItem>}
      {filteredOptions?.map(option => (
        <MenuItem
          key={typeof option !== 'string' ? option.id : option}
          value={typeof option !== 'string' ? option.id : option}
          disabled={typeof option !== 'string' ? option?.disabled : false}
        >
          {typeof option !== 'string' ? option.name : option}
        </MenuItem>
      ))}
    </InputField>
  )
}
