import * as types from '../types'

export default (state = {}, action) => {
  switch (action.type) {
    case types.todo.SET_TODO_LIST:
      return action.response
    default:
      return state
  }
}
