import { ChangeEvent, SetStateAction } from 'react'
import { Table, Empty, Popover, Radio, Badge } from 'antd'

import { parsePhoneNumber } from 'features/Messaging/utils'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import styled from 'styled-components'
import {
  OrganizationIcon,
  ClientIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { ClientType } from 'types/Client'
import FeedbackRating from 'containers/MainContent/Orcatec/Clients/components/Rating'
import MembershipLabels from 'features/Membership/components/MembershipLabels'
import { getCallsStatus } from 'features/Calls/common'
import { setPrimaryClient } from 'api/Calls'
import moment from 'moment-timezone'
import { ColumnProps } from 'antd/lib/table'
import { IIncomingCall } from 'features/Calls/types'
import { useQueryClient } from '@tanstack/react-query'
import { useAppSelector } from 'store/Orcatec/hooks'

interface IProps {
  tableData: IIncomingCall[]
  onTakeCallHandler: (
    call: IIncomingCall['clients'],
    record: IIncomingCall,
  ) => void
  setCallsList: SetStateAction<(prevState: IIncomingCall[]) => IIncomingCall[]>
}
const linkStyle = {
  color: '#007bff',
  cursor: 'pointer',
}

export const IncomingCallsTable = ({
  tableData = [],
  onTakeCallHandler,
  setCallsList,
}: IProps) => {
  const timezone = useAppSelector(
    state => state.orcatec.company.timezone || moment.tz.guess(),
  )
  const queryClient = useQueryClient()
  const onClick = (record: IIncomingCall) => {
    if (record?.clients?.length) {
      const primaryClient =
        record?.clients?.find(c => c?.is_primary) || record?.clients?.[0]
      return onTakeCallHandler({ ...record, ...primaryClient }, record)
    }
    onTakeCallHandler({ from_number: record.from_number }, record)
  }

  const onSetPrimaryClientHandler = async (
    e: ChangeEvent<HTMLInputElement>,
    record: IIncomingCall,
  ) => {
    const { value } = e.target
    try {
      await setPrimaryClient(record.id, { client_id: [+value] })
      const clients = record.clients?.map(c =>
        c.id === +value
          ? { ...c, is_primary: true }
          : { ...c, is_primary: false },
      )
      setCallsList(prev =>
        prev.map(p => (p.id === record.id ? { ...p, clients } : p)),
      )
      queryClient.invalidateQueries({ queryKey: ['calls/table'] })
    } catch (error) {
      console.error(error)
    }
  }

  const columns: ColumnProps<IIncomingCall>[] = [
    {
      title: 'Date',
      key: 'start_time',
      dataIndex: 'start_time',
      width: 100,
      render: time =>
        time ? (
          <>
            <p>
              {moment(time)
                .tz(timezone)
                .format('MM/DD')}
            </p>
            <p>
              {moment(time)
                .tz(timezone)
                .format('hh:mm A')}
            </p>
          </>
        ) : (
          '--'
        ),
    },
    {
      title: 'From',
      name: 'From',
      dataIndex: 'from_number',
      sorter: false,
      width: 240,
      render: (number, record) => {
        if (!record?.clients?.length) {
          return (
            <>
              <span onClick={() => onClick(record)} style={linkStyle}>
                {parsePhoneNumber(number)}
              </span>
              <MainButton
                title='Create contact'
                onClick={() => {
                  onTakeCallHandler({ from_number: number }, record)
                }}
              />
            </>
          )
        } else {
          const primaryClient =
            record?.clients.find(c => c?.is_primary) || record?.clients[0]
          return (
            <Popover
              content={
                <ContactRow>
                  {record?.clients?.length <= 1 ? (
                    <>
                      <span>
                        {ClientType.ORGANIZATION ===
                        record?.clients?.[0]?.type ? (
                          <OrganizationIcon />
                        ) : (
                          <ClientIcon />
                        )}{' '}
                      </span>
                      <span
                        onClick={() =>
                          onTakeCallHandler(record?.clients?.[0], record)
                        }
                        style={linkStyle}
                      >
                        {record?.clients?.[0]?.name}
                      </span>
                    </>
                  ) : (
                    <Radio.Group
                      value={primaryClient?.id}
                      onChange={e => onSetPrimaryClientHandler(e, record)}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      {record?.clients.map(({ type, name, id }, i, arr) => (
                        <div key={i}>
                          <Radio value={id}>
                            <span>
                              {ClientType.ORGANIZATION === type ? (
                                <OrganizationIcon />
                              ) : (
                                <ClientIcon />
                              )}
                            </span>
                            <span
                              onClick={() => onTakeCallHandler(arr[i], record)}
                              style={linkStyle}
                            >
                              {name}
                            </span>
                          </Radio>
                        </div>
                      ))}
                    </Radio.Group>
                  )}
                </ContactRow>
              }
            >
              <ContactRow>
                <p
                  onClick={() => onTakeCallHandler(primaryClient, record)}
                  style={linkStyle}
                >
                  <span style={{ paddingRight: '4px' }}>
                    {ClientType.ORGANIZATION === primaryClient?.type ? (
                      <OrganizationIcon />
                    ) : (
                      <ClientIcon />
                    )}
                  </span>
                  <Badge
                    style={{ right: '-16px' }}
                    count={
                      record?.clients?.length > 1
                        ? `+${record?.clients.length - 1}`
                        : 0
                    }
                  >
                    <span style={linkStyle}>{primaryClient?.name}</span>
                  </Badge>
                </p>
                <span
                  onClick={() => onClick(record)}
                  style={{ fontSize: '10px' }}
                >
                  {parsePhoneNumber(number)}
                </span>
              </ContactRow>
            </Popover>
          )
        }
      },
    },
    {
      title: 'Rating & Membership',
      name: 'Rating',
      dataIndex: 'clients',
      sorter: false,
      width: 150,
      render: clients => {
        const primaryClient = clients?.find(c => c?.is_primary) || clients?.[0]
        return (
          <>
            <FeedbackRating
              size='small'
              readOnly
              value={primaryClient?.rating?.value || 0}
              precision={0.5}
            />
            {primaryClient?.memberships ? (
              <MembershipLabels memberships={primaryClient?.memberships} />
            ) : (
              ''
            )}
          </>
        )
      },
    },
    {
      title: 'Source',
      key: 'sources',
      dataIndex: 'sources',
      width: 130,
      render: (sources, record) =>
        sources ? (
          <>
            <p>{sources}</p>
            <span onClick={() => onClick(record)} style={{ fontSize: '10px' }}>
              {parsePhoneNumber(record.to_number)}
            </span>
          </>
        ) : (
          '--'
        ),
    },

    {
      title: 'Status',
      name: 'Status',
      dataIndex: 'status',
      sorter: false,
      defaultWidth: 100,
      noFilter: true,
      render: status => getCallsStatus(status),
    },
  ]

  return (
    <Wrapper>
      {tableData?.length ? (
        <Table pagination={false} columns={columns} dataSource={tableData} />
      ) : (
        <Empty description='No calls yet' />
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  & .ant-table-thead > tr > th {
    padding: 4px;
  }
  & td {
    padding-left: 0 !important;
  }

  & .ant-table-thead > th,
  .ant-table-thead > td {
    text-align: center;
  }
`

const ContactRow = styled.div`
  display: flex;
  gap: 3px;
  align-items: flex-start;
  flex-direction: column;
`
