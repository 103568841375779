// import React, { useEffect, useState } from 'react'
// import moment from 'moment-timezone'
// import InstallationDates from '../../Form/InstallationDates/InstallationDates'
import './styles.scss'
import Tooltip from 'antd/es/tooltip'
import getStatus from '../../../helpers/getTabStatus'
import states from '../../../../../../../helpers/states'
import { Companies } from 'types/Company'

const ContactsInformation = ({ proposal, currentTab }) => {
  return (
    <div className='proposal-contacts-information'>
      <div className='columns'>
        <div className='columns__left'>
          {!!proposal?.setting?.show_tech && (
            <div className='tech-info'>
              <div>
                <p>
                  <span>{proposal.user?.full_name}</span>,{' '}
                  {proposal.user?.title}
                </p>
                {!!proposal.user?.phone && (
                  <p>
                    Cell: <span>{proposal.user?.phone}</span>
                  </p>
                )}
                {!!proposal.user?.email && (
                  <p>
                    Email: <span>{proposal.user?.email}</span>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <div className='columns__right'>
          <div className='proposal-info'>
            <p>
              {/* <span>Project ID: </span> */}
              <span>
                {proposal?.user?.company_id ===
                Companies['Trilogy Surgical Solutions']
                  ? 'Work Order'
                  : getStatus(proposal, currentTab)}{' '}
                #:{' '}
              </span>
              {proposal.code}
            </p>
            <p>
              <span>Issue Date: </span> {proposal?.issue_date}
            </p>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='columns__left clients'>
          {!!proposal.contacts?.length && (
            <div
              className={`clients-info ${
                proposal.contacts?.length < 2 ? 'clients-info--single' : ''
              }`}
            >
              {proposal.contacts
                ?.slice()
                ?.sort((a, b) => a.position - b.position)
                .map(client => (
                  <div key={client.id}>
                    <p className='clients-info__name'>
                      <span
                        style={{ fontWeight: 700 }}
                      >{`${client.name}`}</span>
                      {client.proposal_relation && (
                        <span>{`, ${client.proposal_relation}`} </span>
                      )}
                    </p>
                    {client.phones?.map(item => (
                      <p key={item.phone} className='clients-info__phone'>
                        <span>{item.type}</span>: {item.phone}
                      </p>
                    ))}
                    {client.emails?.map(item => (
                      <Tooltip title={item.email} key={item.email}>
                        <p className='clients-info__email'>
                          Email: {item.email}
                        </p>
                      </Tooltip>
                    ))}
                  </div>
                ))}
            </div>
          )}

          {!!proposal.properties?.length && (
            <div
              className={`properties-info ${
                proposal.properties?.length < 2 ? 'properties-info--single' : ''
              }`}
            >
              {proposal.properties
                ?.slice()
                ?.sort((a, b) => a.position - b.position)
                .map(property => (
                  <div key={property.id}>
                    {!!property.proposal_relation && (
                      <p className='property-relation'>
                        {property.proposal_relation}
                      </p>
                    )}
                    <p>{property.address}</p>
                    {!!property.unit && <p>Unit: {property.unit}</p>}
                    <p>
                      {property.city}
                      {`${property.postcode ? ',' : ''}`} {property.postcode}
                    </p>
                    <p>{states?.[property?.state] || ''}</p>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className='columns__right'>
          {/* {!clientView ? (
            <InstallationDates
              installationDates={proposal.installation_dates}
              onChangeInstallationDates={onChangeInstallationDates}
              allWorkCompleted={proposal.all_work_completed}
            />
          ) : (
            !!proposal.jobs?.length &&
            proposal.status !== 1 && (
              <div className='work-completion-dates'>
                <p>Work Completion Dates</p>
                <div className='dates'>
                  {proposal.jobs?.map(job => (
                    <p key={job.id}>{job.date}</p>
                  ))}
                </div>
              </div>
            )
          )} */}
        </div>
      </div>
    </div>
  )
}

export default ContactsInformation
