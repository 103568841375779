import axiosOrcatec from './axiosInstance'

/**
 * Fetch todo list.
 *
 * @return {*}
 */

export const getTodoList = data => {
  let params = {
    page: data.page,
    limit: data.limit,
    search: data.search,
    'sort[by]': data.sortBy,
    'sort[order]': data.sortOrder,
    ...data.filter,
  }
  return axiosOrcatec
    .get('/todo', { params: params })
    .then(response => response.data)
}

export const getTodoByUser = () =>
  axiosOrcatec.get(`todo/get-todo-by-user`).then(res => res.data)

export const getTodoById = id => {
  return axiosOrcatec.get(`/todo/${id}`).then(res => res.data)
}

export const createTodoTask = data => {
  return axiosOrcatec.post('/todo', data).then(response => response.data)
}

export const updateTodoTask = (id, data) => {
  return axiosOrcatec.put(`/todo/${id}`, data).then(response => response.data)
}

export const updateTodoTaskStatus = (id, status) => {
  return axiosOrcatec
    .post(`/todo/${id}/status`, status)
    .then(response => response.data)
}

export const deleteTodoTask = id => {
  return axiosOrcatec.delete(`/todo/${id}`).then(response => response.data)
}
