import { getMembershipList } from 'api/Membership'
import { useEffect, useState } from 'react'
import { MembershipLabel } from '../types'

const useMemberships = () => {
  const [labels, setLabels] = useState<MembershipLabel[]>([])

  useEffect(() => {
    const getLabels = async () => {
      const { data } = await getMembershipList({ per_page: 50 })
      setLabels(data)
    }

    getLabels()
  }, [])

  return { labels }
}

export default useMemberships
