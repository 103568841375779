import React, { useRef, RefObject } from 'react'
import './style.scss'
import CreateAppointment from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm'
import { JobForm } from 'containers/MainContent/Orcatec/Jobs/components/JobPage/components/JobForm/JobForm'
import { Moment } from 'moment-timezone'
import { IAppointment } from 'types/Appointment'

interface IProps {
  onCloseDrawer: () => void
  visibleDrawer: boolean
  event: IAppointment
  currentDate: Moment
}

export const Appointment = ({
  onCloseDrawer,
  visibleDrawer,
  event,
  currentDate,
}: IProps) => {
  const ref = useRef() as RefObject<{ current: HTMLElement }>
  return event?.kind === 2 ? (
    <JobForm
      ref={ref}
      jobId={event?.id}
      drawerView
      matrix
      onCloseDrawer={onCloseDrawer}
      visibleDrawer={visibleDrawer}
      currentDate={currentDate}
    />
  ) : (
    <CreateAppointment
      ref={ref}
      appointmentId={event?.id}
      drawerView
      onCloseDrawer={onCloseDrawer}
      visibleDrawer={visibleDrawer}
      currentDate={currentDate}
      matrix
    />
  )
}
