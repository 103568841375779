const formRelatedData = (obj, attachParent) => {
  const res = []
  const resObj = {
    parent_id: obj.id,
    proposal_relation: obj.proposal_relation,
    checked: obj.checkbox,
    properties: obj.properties?.slice(0)?.reduce((acc, curr, i, arr) => {
      //slice(0) to copy original array
      if (attachParent && i === 0) arr.splice(1) // if attach parent Client - exit from loop after 1 iteration
      acc.push({
        id: curr.id,
        proposal_relation: curr.proposal_relation,
        checked: attachParent ? true : curr.checkbox,

        clients: curr?.clients?.reduce((ac, value) => {
          ac.push({
            id: value.id,
            proposal_relation: value.proposal_relation,
            checked: value.checkbox,
          })
          return ac
        }, []),

        organizations: curr?.organizations?.reduce((ac, value) => {
          ac.push({
            id: value.id,
            proposal_relation: value.proposal_relation,
            checked: value.checkbox,
          })
          return ac
        }, []),
      })
      return acc
    }, []),
    contacts: obj?.contacts?.slice(0)?.reduce((acc, curr, i, arr) => {
      if (attachParent && i === 0) arr.splice(1)
      acc.push({
        id: curr.id,
        proposal_relation: curr.proposal_relation,
        checked: attachParent ? true : curr.checkbox,
      })
      return acc
    }, []),
  }

  res.push(resObj)
  return res
}

const formRelatedIds = (obj, attachParent) => {
  const res = []
  if (obj.properties && !attachParent)
    res.push(
      ...obj.properties.reduce(
        (acc, curr) => [...acc, ...curr?.organizations?.filter(el => el.checkbox).map(el => el.id)],
        [],
      ),
    )
  res.push(obj.id)
  return res
}

const formRelatedClientIds = (obj, attachParent) => {
  const res = []
  if (obj.properties && !attachParent)
    res.push(
      ...obj.properties.reduce(
        (acc, curr) => [...acc, ...curr?.clients?.filter(el => el.checkbox).map(el => el.id)],
        [],
      ),
    )
  return res
}

export const formatTableDataOrganizations = (currentTableData, attachParent) => {
  return {
    organizations: currentTableData.reduce((acc, curr) => {
      const respondData = {
        organization_relation_id: curr.organization_relation_id,
        proposal_relation: curr.proposal_relation || '',
        related_data: formRelatedData(curr, attachParent),
        property_ids: attachParent
          ? [curr?.properties?.[0]?.id]
          : curr?.properties?.filter(el => el.checkbox).map(el => el.id),
        // client_ids: [curr.id],
        client_ids: [
          ...curr?.contacts?.filter(el => el.checkbox).map(el => el.id),
          ...formRelatedClientIds(curr, attachParent),
        ],
        organization_ids: formRelatedIds(curr, attachParent),
      }
      return acc.concat(respondData)
    }, []),
  }
}
