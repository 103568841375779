import {
  AccountingIcon,
  ContactsIcon,
  DashboardIcon,
  DispatchIcon,
  EstimatesIcon,
  FastPaymentIcon,
  InstantAppointmentIcon,
  JobsIcon,
  MapIcon,
  MessagingIcon,
  ProjectsIcon,
  PropertiesIcon,
  QuickbooksIcon,
  ReviewsIcon,
  ScheduleIcon,
  SettingsIcon,
  TimecardsIcon,
  TodoIcon,
  CustomFormIcon,
} from '../../Header/components/Navigation/NavigationIcons'
import { Badge } from 'antd'
import { useAppSelector } from '../../../../store/Orcatec/hooks'
import { Phone } from '@material-ui/icons'
import {
  selectModulePermissions,
  selectIsOwner,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import { meSelector } from 'store/SuperAdmin/selectors'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'

export const sidebarLinks = () => {
  const companyID = useAppSelector(state => state.orcatec.company.id)
  const dictionary = useAppSelector(selectJobDictionary)
  const unreadTodos = useAppSelector(
    state => state.orcatec.user.me.count_unread_tasks,
  )

  const {
    project,
    schedule,
    accounting,
    contacts,
    dashboard,
    dispatch,
    forms,
    forms_template,
    forms_detailed,
    map,
    properties,
    time_cards,
    settings,
    fast_payment,
    todo,
    jobs,
    quickbooks,
    reviews,
    instant_appointment,
    estimates,
    messaging,
    call_tracking,
    balances,
    expenses,
  } = useAppSelector(selectModulePermissions())
  const me = useAppSelector(meSelector)

  const isOwner = useAppSelector(selectIsOwner(me.id))

  const allLinks = [
    {
      key: 'dashboard',
      title: 'Dashboard',
      src: '/dashboard',
      icon: <DashboardIcon />,
      isAllowed: dashboard,
    },
    {
      key: 'dispatch',
      title: 'Dispatch',
      src: '/dispatch',
      icon: <DispatchIcon />,
      isAllowed: dispatch,
    },

    {
      key: 'instant_appointment',
      title: 'Instant Appointment',
      src: '/instant-appointment',
      icon: <InstantAppointmentIcon />,
      isAllowed: instant_appointment,
    },
    {
      key: 'map',
      title: 'Map',
      src: '/map',
      icon: <MapIcon />,
      isAllowed: map,
    },
    {
      key: 'schedule',
      title: 'Schedule',
      src: '/schedule',
      icon: <ScheduleIcon />,
      isAllowed: schedule && me.useAsTech,
    },
    {
      key: 'job',
      title: dictionary.plural,
      src: '/jobs-list',
      icon: <JobsIcon />,
      isAllowed: jobs,
    },
    {
      key: 'todo',
      title: 'To Do',
      src: '/to-do',
      icon: (
        <Badge status='warning' count={unreadTodos}>
          <TodoIcon />
        </Badge>
      ),
      isAllowed: todo,
    },
    {
      key: 'project',
      title: 'Estimates',
      src: '/estimates',
      icon: <EstimatesIcon />,
      isAllowed: estimates,
    },
    {
      key: 'project',
      title: 'Projects',
      src: '/projects',
      icon: <ProjectsIcon />,
      isAllowed: project,
    },
    {
      key: 'fast_payment',
      title: 'Fast-payment',
      src: '/fast-payment',
      icon: <FastPaymentIcon />,
      isAllowed: fast_payment,
    },
    {
      key: 'properties',
      title: 'Properties',
      src: '/properties',
      icon: <PropertiesIcon />,
      isAllowed: properties,
    },
    {
      key: 'contacts',
      title: companyID?.id === 264 ? 'Contacts & Customers' : 'Contacts',
      src: '/clients',
      icon: <ContactsIcon />,
      isAllowed: contacts,
    },
    {
      key: 'call_tracking',
      title: 'Calls Tracking',
      src: '/calls',
      icon: <Phone />,
      isAllowed: call_tracking,
    },
    {
      key: 'messaging',
      title: 'Messaging',
      src: '/messaging',
      icon: <MessagingIcon />,
      isAllowed: messaging,
    },
    {
      key: 'time_cards',
      title: 'Time cards',
      src: '/time-cards',
      icon: <TimecardsIcon />,
      isAllowed: time_cards,
    },
    {
      key: 'reviews',
      title: 'Reviews',
      src: '/reviews',
      icon: <ReviewsIcon />,
      isAllowed: reviews,
    },
    {
      key: 'quickbooks',
      title: 'Quickbooks',
      src: '/quickbooks',
      icon: <QuickbooksIcon />,
      isAllowed: quickbooks,
    },
    {
      key: 'accounting',
      title: 'Accounting',
      src: '/accounting',
      icon: <AccountingIcon />,
      isAllowed: accounting || balances || expenses,
    },
    {
      key: 'forms',
      title: 'Forms',
      src: '/custom-form',
      icon: <CustomFormIcon />,
      isAllowed: forms || forms_template || forms_detailed,
    },
    {
      key: 'settings',
      title: 'Settings',
      src: '/settings/info',
      icon: <SettingsIcon />,
      isAllowed: settings || isOwner,
    },
  ]

  return allLinks
}
