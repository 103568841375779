import React, { useEffect, useState } from 'react'
import InstallersInfoBlock from '../Form/TabsContent/TabInfoBlock/InstallersInfoBlock/InstallersInfoBlock'
import { ModalFilesUpload } from 'components/UI/ModalFIlesUpload/ModalFilesUpload'
import { Media } from 'types/Media'
import { IProjectTab, Project } from 'features/Project/types'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectProjectSlice } from 'features/Project/slices/projectSlice'

interface Props {
  dataSource: Project | IProjectTab
  label: string
  notesFieldName: string
  filesFieldName: string
  restrictUpload?: boolean
  filesLimit?: number
  getFileForModalDocViewer: (files: Media[], idx: number) => void
  onUpdate: (data: Record<string, unknown>) => void
}

export const CustomerInformation = ({
  dataSource,
  label,
  notesFieldName,
  filesFieldName,
  getFileForModalDocViewer,
  restrictUpload,
  filesLimit,
  onUpdate,
}: Props) => {
  const project = useAppSelector(selectProjectSlice)
  const [notes, setNotes] = useState<string>(dataSource?.[notesFieldName] || '')
  const [files, setFiles] = useState<Media[]>(
    dataSource?.[filesFieldName] || [],
  )
  const [filesUploadModal, setFilesUploadModal] = useState(false)

  useEffect(() => {
    setNotes(dataSource?.[notesFieldName])
    setFiles(dataSource?.[filesFieldName])
  }, [dataSource?.[filesFieldName], dataSource?.[notesFieldName]])

  const handleNotesChange = e => {
    setNotes(e.target.value)
  }

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fileIdx: number,
  ) => {
    const newFiles = [...files]
    const copyFile = { ...newFiles?.[fileIdx] }
    copyFile.description = e.target.value

    newFiles[fileIdx] = copyFile
    setFiles(newFiles)
  }

  const handleFileNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fileIdx: number,
  ) => {
    const newFiles = [...files]
    const copyFile = { ...newFiles?.[fileIdx] }
    copyFile.display_name = e.target.value
    newFiles[fileIdx] = copyFile

    setFiles(newFiles)
  }

  const handleFileDelete = (id: number, idx: number) => {
    const newFiles = [...files]
    newFiles.splice(idx, 1)
    setFiles(newFiles)

    updateField(newFiles)
  }

  const handleFilesSave = (files: Media[]) => {
    setFiles(files)
    setFilesUploadModal(false)

    updateField(files)
  }

  const handleFieldBlur = () => {
    updateField()
  }

  const handleFileUpload = () => {
    setFilesUploadModal(true)
  }

  const updateField = (filesToUpdate?: Media[]) => {
    onUpdate({
      [notesFieldName]: notes,
      [filesFieldName]: filesToUpdate || files,
    })
  }

  return (
    <>
      <InstallersInfoBlock
        fieldValue={notes}
        onChangeTextField={handleNotesChange}
        deleteFile={handleFileDelete}
        files={files}
        onChangeFileName={handleFileNameChange}
        onChangeDescription={handleDescriptionChange}
        openFileModal={!restrictUpload && handleFileUpload}
        placeholder={label}
        fieldName={notesFieldName}
        buttonTitle='Upload File'
        getFileForModalDocViewer={getFileForModalDocViewer}
        onTextFieldBlur={handleFieldBlur}
        loading={project.status === 'updating'}
      />
      {filesUploadModal && (
        <ModalFilesUpload
          data={files}
          onSave={handleFilesSave}
          onCancel={() => setFilesUploadModal(false)}
          filesLimit={filesLimit || 10}
        />
      )}
    </>
  )
}
