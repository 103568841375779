import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'store/Orcatec/hooks'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  MessagingPermissions,
  ModuleName,
} from 'features/Settings/UsersAndGroups'

import { isMessagingSubmitted } from 'store/Orcatec/selectors/messaging'
import { Result } from 'antd'

export enum MESSAGE_STATUSES {
  Queued = 1,
  Failed = 2,
  Sent = 3,
  Delivered = 4,
  Undelivered = 5,
  Received = 6,
}

export const initialChatState = {
  id: null,
  from: '',
  to: '',
  body: '',
  status: null,
  created_at: '',
  client_name: '',
}

export const initialPaginationState = {
  page: 1,
  per_page: 10,
  total: 10,
}

export const initialFormValuesState = {
  fromNumber: '',
  toNumber: '',
  body: '',
  media: [],
}

export const initialChatInfoState = {
  id: 0,
  from: '',
  to: '',
  client_name: null,
  technicians: [],
}

export const initialMessageState = {}

export const initialMessagesPaginationState = {
  current_page: 1,
  from: 1,
  last_page: 1,
  path: '',
  per_page: 15,
  to: 1,
  total: 1,
}

export const statusFilters = [
  { text: 'Queued', value: 1 },
  { text: 'Failed', value: 2 },
  { text: 'Sent', value: 3 },
  { text: 'Delivered', value: 4 },
  { text: 'Undelivered', value: 5 },
  { text: 'Received', value: 6 },
]

export const parsePhoneNumber = (phone = ''): string => {
  if (!phone) {
    return ''
  }
  const splitNumber = phone.split('')
  splitNumber.splice(1, 0, '(')
  splitNumber.splice(5, 0, ') ')
  splitNumber.splice(9, 0, '-')
  const result = splitNumber.toString().replaceAll(',', '')
  return `+${result}`
}

export const parsePhoneNumberBeforeSend = (phone = ''): string => {
  if (!phone) return ''
  let str = phone

  if (str.startsWith('+1')) {
    str = str.substring(2)
  }

  str = str
    .replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace(' ', '')

  return `1${str}`
}

export const parsePhoneNumberBeforeSendInChat = (phone = ''): string => {
  let str = phone

  if (str.startsWith('+1')) {
    str = str.substring(2)
  }

  str = str
    .replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace(' ', '')

  return `${str}`
}

const capitalize = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1)

export const notificationMessage = (type = 'success', description = '') => {
  openNotificationWithIcon(type, {
    message: capitalize(type),
    description: description || 'Something went wrong!',
  })
}

export const MESSAGING_SERVICE_IS_NOT_SETUP_TEXT = ({ withoutBtn = false }) => {
  const history = useHistory()
  const hasSettingsPermission = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.MESSAGING,
      MessagingPermissions.MESSAGING_CAN_READ,
    ),
  )
  const isSubmited = useAppSelector(isMessagingSubmitted)

  const isHasMessagingText = `${`To send text messages through the system, you must have a designated phone number associated with your account. ${
    hasSettingsPermission
      ? 'Please go to settings to get an assigned phone number.'
      : 'Please contact your administrator to get an assigned phone number.'
  }`}
  `

  const isNoHaveMessagingText = `${`Your messaging service is not set up yet.${
    hasSettingsPermission
      ? 'Please go to settings to setup messaging'
      : 'Please contact your administrator.'
  }`}
  `

  return (
    <>
      {/* <p>
        {isSubmited ? isHasMessagingText : isNoHaveMessagingText}
        {hasSettingsPermission && !withoutBtn ? (
          <MainButton
            style={{ display: 'inline' }}
            title='Setup'
            onClick={() => history.push('/settings/messaging')}
          />
        ) : null}
      </p> */}
      <Result
        status='404'
        // title='Merchant not found'
        subTitle={isSubmited ? isHasMessagingText : isNoHaveMessagingText}
        extra={
          hasSettingsPermission && !withoutBtn ? (
            <MainButton
              style={{ display: 'inline' }}
              title='Setup'
              onClick={() => history.push('/settings/messaging')}
            />
          ) : null
        }
      />
    </>
  )
}
