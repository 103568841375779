import { notification } from 'antd'
import { getDocuments } from '../api/onboardingAM'

import {
  createUserApi,
  getUsersApi,
  deleteUserApi,
  setUserStatusApi,
  getCurrentUserApi,
  editUserApi,
  resendUserInviteApi,
  getUserRolesApi,
} from '../api/usersApi'

import { ActionType } from '../constants/usersConstants'

const {
  SET_CREATE_MODAL_FIELDS,
  SET_EDIT_MODAL_FIELDS,
  CLEAR_STORE,
  SET_CREATE_MODAL_PERMISSIONS,
  SET_EDIT_MODAL_PERMISSIONS,
  CREATE_USER_INIT,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  EDIT_USER_INIT,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  GET_USERS_LIST_INIT,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,
  DELETE_USER_INIT,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SET_USER_STATUS_INIT,
  SET_USER_STATUS_SUCCESS,
  SET_USER_STATUS_FAILURE,
  CHECK_INNACTIVE_USER,
  GET_CURRENT_USER_INIT,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  RESEND_INVITE_INIT,
  RESEND_INVITE_SUCCESS,
  RESEND_INVITE_FAILURE,
  GET_USER_ROLES,
  SELECT_USER_ROLE,
} = ActionType

const openNotificationWithIcon = (type, data) => {
  notification[type]({
    ...data,
  })
}

export const setCreateModalFields = (name, value) => ({
  type: SET_CREATE_MODAL_FIELDS,
  payload: { name, value },
})

export const setEditModalFields = (name, value) => ({
  type: SET_EDIT_MODAL_FIELDS,
  payload: { name, value },
})

export const clearStore = () => ({
  type: CLEAR_STORE,
})

export const setCreateModalPermissions = (name, value) => ({
  type: SET_CREATE_MODAL_PERMISSIONS,
  payload: { name, value },
})

export const setEditModalPermissions = (name, value) => ({
  type: SET_EDIT_MODAL_PERMISSIONS,
  payload: { name, value },
})

export const createUser = data => dispatch => {
  dispatch({
    type: CREATE_USER_INIT,
  })
  createUserApi(data)
    .then(() => {
      openNotificationWithIcon('success', {
        message: 'User created',
        description: '',
        key: 'property-save-in-process',
        duration: 4,
      })
      dispatch({
        type: CREATE_USER_SUCCESS,
      })
    })
    .catch(error => {
      dispatch({
        type: CREATE_USER_FAILURE,
        payload: error,
      })
    })
}

export const editUser = (id, data) => dispatch => {
  dispatch({
    type: EDIT_USER_INIT,
  })
  editUserApi(id, data)
    .then(() => {
      openNotificationWithIcon('success', {
        message: 'User edited',
        description: '',
        key: 'property-save-in-process',
        duration: 4,
      })
      dispatch({
        type: EDIT_USER_SUCCESS,
      })
    })
    .catch(error => {
      dispatch({
        type: EDIT_USER_FAILURE,
        payload: error,
      })
    })
}

export const getUsersData = params => dispatch => {
  dispatch({
    type: GET_USERS_LIST_INIT,
  })
  getUsersApi(params)
    .then(response => {
      dispatch({
        type: GET_USERS_LIST_SUCCESS,
        payload: response,
      })
    })
    .catch(error => {
      dispatch({
        type: GET_USERS_LIST_FAILURE,
        payload: error,
      })
    })
}

export const deleteUser = id => dispatch => {
  dispatch({
    type: DELETE_USER_INIT,
  })
  deleteUserApi(id)
    .then(() => {
      openNotificationWithIcon('success', {
        message: 'User deleted',
        description: '',
        key: 'property-save-in-process',
        duration: 4,
      })
      dispatch({
        type: DELETE_USER_SUCCESS,
      })
    })
    .catch(error => {
      dispatch({
        type: DELETE_USER_FAILURE,
        payload: error,
      })
    })
}

export const setUserStatus = (id, value) => dispatch => {
  dispatch({
    type: SET_USER_STATUS_INIT,
  })
  setUserStatusApi(id, value)
    .then(() => {
      openNotificationWithIcon('success', {
        message: 'Status changed',
        description: '',
        key: 'property-save-in-process',
        duration: 4,
      })
      dispatch({
        type: SET_USER_STATUS_SUCCESS,
      })
    })
    .catch(error => {
      dispatch({
        type: SET_USER_STATUS_FAILURE,
        payload: error,
      })
    })
}

export const checkInnactiveUser = value => ({
  type: CHECK_INNACTIVE_USER,
  payload: { value },
})

export const getCurrentUser = (id, isProfileSettings) => (dispatch, getState) => {
  dispatch({
    type: GET_CURRENT_USER_INIT,
  })
  const getDocsAndSetCurrentUser = user => {
    if (user.data.account_manager) {
      getDocuments(user.data.account_manager.id).then(({ data }) => {
        user.data.account_manager.documents = data
        dispatch({
          type: GET_CURRENT_USER_SUCCESS,
          payload: user,
        })
      })
    } else {
      dispatch({
        type: GET_CURRENT_USER_SUCCESS,
        payload: user,
      })
    }
  }
  if (isProfileSettings) {
    const me = JSON.parse(JSON.stringify(getState().orcatec.user.me))
    getDocsAndSetCurrentUser({ data: me })
  } else {
    getCurrentUserApi(id)
      .then(response => {
        getDocsAndSetCurrentUser(response)
      })
      .catch(error => {
        dispatch({
          type: GET_CURRENT_USER_FAILURE,
          payload: error,
        })
      })
  }
}

export const resendUserInvite = (id, data) => dispatch => {
  dispatch({
    type: RESEND_INVITE_INIT,
  })
  resendUserInviteApi(id, data)
    .then(() => {
      openNotificationWithIcon('success', {
        message: 'Invitation sent',
        description: '',
        key: 'property-save-in-process',
        duration: 4,
      })
      dispatch({
        type: RESEND_INVITE_SUCCESS,
      })
    })
    .catch(error => {
      dispatch({
        type: RESEND_INVITE_FAILURE,
        payload: error,
      })
    })
}

export const getUserRoles = () => dispatch => {
  getUserRolesApi()
    .then(response =>
      dispatch({
        type: GET_USER_ROLES,
        payload: response.data.roles,
      }),
    )
    .catch(error => console.error('error: ', error))
}

export const selectUserRoles = data => ({
  type: SELECT_USER_ROLE,
  payload: data,
})
