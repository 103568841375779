import {
  selectProject,
  selectProjectActiveTab,
} from 'features/Project/projectSelectors'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

export const useIsFreezed = isEditContract => {
  const [isFreezed, setIsFreezed] = useState(true)
  const project = useSelector(selectProject)
  const currentTab = useSelector(selectProjectActiveTab)

  useEffect(() => {
    if (project) {
      project.status === 1 ||
      (project.status === 2 &&
        isEditContract &&
        (!project.signed_tab_id || currentTab?.id === project.signed_tab_id))
        ? setIsFreezed(false)
        : setIsFreezed(true)
    }
  }, [project, isEditContract])

  return isFreezed
}
