import React, { useEffect, useState, useRef } from 'react'
import './index.scss'
import Preloader from '../../../components/Preloader'

const initErrors = {
  cardNumber: '',
  cvv: '',
  expiry: '',
}
const defaultAction = () => {}

const cardConnectFields = ['address', 'city', 'region', 'postal']

const Card = ({
  setData,
  cardData,
  parentEl,
  children,
  error,
  visible = true,
  fieldErrors = {},
  setFieldErrors = defaultAction,
  setError = defaultAction,
  setIsNewCard = defaultAction,
  setIsOldCardChanged = defaultAction,
}) => {
  const [loader, setLoader] = useState(true)

  let number = cardData && cardData.card_number ? cardData.card_number : '################'

  number = number.replace(/[\\#]/g, '%23')

  const placeHolderCard = `${'placeholder=' + number + '&'}`
  const cardNumber =
    'css=input%7Bcolor%3A%236c757d%3Bheight%3A45px%3Bborder%2Dradius%3A4px%3Bborder%3Asolid%201px%20%23D6D6D6%3Bfont%2Dsize%3Ainherit%3Bwidth%3A251px%3Bmargin%2Dbottom%3A20px%3Bpadding%2Dleft%3A10px%7D'
  const label =
    'label%7Bwidth%3A200px%3Bfont%2Dfamily%3APoppins%2C%20sans%2Dserif%3Btext%2Dtransform%3Auppercase%3Bfont%2Dsize%3A12px%3Bfont%2Dweight%3A600%3Bcolor%3A%236c757d%3Bpadding%2Dbottom%3A10px%3Bdisplay%3Ainline%2Dblock%7D'
  const select =
    'select%7Bwidth%3A117px%3Bborder%2Dradius%3A4px%3Bcolor%3A%236c757d%3Bborder%3Asolid%201px%20%23D6D6D6%3Bheight%3A49px%3Bbackground%3Awhite%3Bfont%2Dsize%3Ainherit%3B%7D'
  const settingForm =
    'useexpiry=true&usecvv=true&cardnumbernumericonly=true&cardlabel=Credit%20Card%20Number&expirylabel=Expiry%2DDate&cvvlabel=CVV&invalidinputevent=true&placeholdercvv=000&placeholderyear=2222&placeholdermonth=11&'
  const inputCVV = '%23cccvvfield%7Bmargin%2Dleft%3Aauto%3Bdisplay%3Ablock%3Bwidth%3A259px%3Bmargin%2Dtop%3A-19px%7D'
  const labelCVV =
    '%23cccvvlabel%7Bmargin%2Dleft%3A270px%3Bmargin%2Dtop%3A-72px%3Bdisplay%3Ablock%7D%2Eerror%7Bcolor%3Ared%3B%7D'
  const requiredText = 'This field is required'
  const iframe = useRef()

  useEffect(() => {
    if (Object.values(fieldErrors).some(error => !!error)) {
      setError?.(true)
    } else {
      setError?.(false)
    }
  }, [fieldErrors])

  const listener = e => {
    if (e.data) {
      if (typeof e.data === 'string') {
        const data = JSON.parse(e.data)
        const { message: token, expiry, validationError } = data
        const newErrors = ['card_holder', 'address', 'city', 'region', 'postal'].reduce((acc, curr) => {
          let errorText = ''
          if (curr === 'card_holder') {
            errorText = cardData.card_holder ? '' : requiredText
          } else if (curr === 'postal') {
            errorText = !cardData?.billing_address?.[curr]
              ? requiredText
              : cardData?.billing_address?.[curr].length !== 5
              ? 'Must be 5 digits'
              : ''
          } else {
            errorText = !cardData?.billing_address?.[curr] ? requiredText : ''
          }
          acc[curr] = errorText
          return acc
        }, {})
        if (validationError) {
          let field = ''
          if (validationError.includes('Account Number') || validationError.includes('Credit Card Number'))
            field = 'cardNumber'
          else if (validationError.includes('Expiry')) field = 'expiry'
          else if (validationError.includes('CVV')) field = 'cvv'
          setFieldErrors?.(prev =>
            !field
              ? { ...prev, ...newErrors }
              : {
                  ...prev,
                  ...newErrors,
                  [field]: validationError,
                },
          )
          setData(prev => ({ ...prev, token: '', expiry: '' }))
        } else if (!!token && !!expiry) {
          setIsNewCard?.(true)
          setData(prev => ({ ...prev, token, expiry }))
          setFieldErrors?.(prev => ({
            ...prev,
            cvv: '',
            expiry: '',
            cardNumber: '',
          }))
        }
      }
    }
  }

  useEffect(() => {
    if (!visible) {
      setFieldErrors?.({})
      setError?.(false)
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      window.removeEventListener('message', listener, false)
      window.addEventListener('message', listener, false)
    } else {
      window.removeEventListener('message', listener, false)
    }
    return () => {
      window.removeEventListener('message', listener, false)
    }
  }, [cardData, fieldErrors, visible])

  const getCurrentIframeErrors = () => {
    const iframeErrors = {
      cardNumber: fieldErrors.cardNumber,
      cvv: fieldErrors.cvv,
      expiry: fieldErrors.expiry,
    }
    const isThereAreSomeIframeError = Object.values(iframeErrors).some(err => !!err)
    return !!cardData.token && !!cardData.expiry && isThereAreSomeIframeError ? initErrors : {}
  }
  const hideLoader = () => setLoader(false)

  const onChangeValue = e => {
    const { name, value } = e.currentTarget
    setIsOldCardChanged?.(true)
    setFieldErrors?.(prev => ({ ...prev, ...getCurrentIframeErrors(), [name]: value ? '' : requiredText }))
    setData(prev => ({ ...prev, [name]: value }))
  }

  const onChangeAddress = e => {
    let { name, value } = e.currentTarget
    let newError = ''
    if (name === 'postal') {
      value = value.replace(/\D/g, '').substr(0, 5)
      newError = !value ? requiredText : value.length !== 5 ? '5 digits please' : ''
    } else {
      newError = value ? '' : requiredText
    }
    setIsOldCardChanged?.(true)
    setFieldErrors?.(prev => ({ ...prev, ...getCurrentIframeErrors(), [name]: newError }))
    setData(prev => ({ ...prev, billing_address: { ...prev?.billing_address, [name]: value } }))
  }

  return (
    <>
      {loader && <Preloader />}
      <div ref={parentEl} className={`card_container${loader ? ' d-none' : ''}${error ? ' error_card' : ''}`}>
        <form name='tokenform' id='tokenform'>
          <iframe
            ref={iframe}
            title='card'
            id='tokenframe'
            name='tokenframe'
            onLoad={hideLoader}
            src={`${process.env.REACT_APP_CARDCONNECT_URL}itoke/ajax-tokenizer.html?${settingForm +
              placeHolderCard +
              cardNumber +
              label +
              select +
              inputCVV +
              labelCVV}`}
            frameBorder='0'
            scrolling='no'
            width='560'
            height='190'
          />

          {!!fieldErrors.cardNumber && (
            <span className='card_container-error card_container-cardNumber'>
              <div />
              <span className='card_container-error-text'>{fieldErrors.cardNumber}</span>
            </span>
          )}
          {!!fieldErrors.expiry && (
            <div className='card_container-error card_container-expiry'>
              <div />
              <div />
              <span className='card_container-error-text'>{fieldErrors.expiry}</span>
            </div>
          )}
          {!!fieldErrors.cvv && (
            <div className='card_container-error card_container-cvv'>
              <div />
              <span className='card_container-error-text'>{fieldErrors.cvv}</span>
            </div>
          )}

          <div className='card_holder'>
            <label>Card Holder Name</label>
            <input
              placeholder='Card Holder Name'
              name='card_holder'
              onChange={onChangeValue}
              value={cardData ? cardData.card_holder : ''}
            />
            {!!fieldErrors.card_holder && <span className='card_container-error-text'>{fieldErrors.card_holder}</span>}
          </div>

          <div className='address_box'>
            <div className='address'>
              <label>Address</label>
              <input
                placeholder='Address'
                name='address'
                onChange={onChangeAddress}
                value={!!cardData && !!cardData?.billing_address ? cardData?.billing_address.address : ''}
              />
              {!!fieldErrors.address && <span className='card_container-error-text'>{fieldErrors.address}</span>}
            </div>

            <div className='city'>
              <label>City</label>
              <input
                placeholder='City'
                name='city'
                onChange={onChangeAddress}
                value={!!cardData && !!cardData?.billing_address ? cardData?.billing_address.city : ''}
              />
              {!!fieldErrors.city && <span className='card_container-error-text'>{fieldErrors.city}</span>}
            </div>

            <div className='state'>
              <label>State</label>
              <input
                placeholder='State'
                name='region'
                onChange={onChangeAddress}
                value={!!cardData && !!cardData?.billing_address ? cardData?.billing_address.region : ''}
              />
              {!!fieldErrors.region && <span className='card_container-error-text'>{fieldErrors.region}</span>}
            </div>

            <div className='postcode'>
              <label>Zip</label>
              <input
                placeholder='Zip'
                name='postal'
                onChange={onChangeAddress}
                value={!!cardData && !!cardData?.billing_address ? cardData?.billing_address.postal : ''}
              />
              {!!fieldErrors.postal && <span className='card_container-error-text'>{fieldErrors.postal}</span>}
            </div>
          </div>
        </form>
        <span
          className={`error_message${!!error || Object.values(fieldErrors).some(error => !!error) ? '' : ' d-none'}`}
        >
          {typeof error === 'string' ? error : 'You need to enter all fields'}
        </span>
      </div>

      {children}
    </>
  )
}

export default Card
