import { getColumnsByDate, getQueuesList } from 'api/Matrix-log'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'

export interface AssignToOption {
  id: string | number
  name: string
  source: 'Queues' | 'Columns'
  type?: number
  main_tech_id: number
  main_tech_name: string
  main_tech_email: string
  main_tech_phone: string
}

export const useEventAssignToOptions = (date?: string | null) => {
  const [data, setData] = useState<AssignToOption[]>([])

  const dateString = moment(moment(date).isValid() ? date : new Date()).format(
    'MM/DD/YYYY',
  )

  useEffect(() => {
    const getData = async () => {
      try {
        const [queues, columns] = await Promise.all([
          getQueuesList(),
          getColumnsByDate({ date: dateString }),
        ])

        setData([
          ...queues
            ?.map(queue => ({
              id: queue.id.toString(),
              name: queue.title,
              source: 'Queues' as const,
              // type: queue.type,
            }))
            ?.sort((a, b) => a.name.localeCompare(b.name)),
          ...columns
            // ?.sort((a, b) => a.title.localeCompare(b.title))
            ?.map(column => ({
              id: column.column_template_id,
              name: column.main_tech_name
                ? `${column.title} - ${column.main_tech_name}`
                : column.title,
              source: 'Columns' as const,
              main_tech_id: column.main_tech_id,
              main_tech_name: column.main_tech_name,
              main_tech_email: column.technicians.find(
                tech => tech.id === column.main_tech_id,
              )?.email,
              main_tech_phone: column.technicians.find(
                tech => tech.id === column.main_tech_id,
              )?.phone,
            })),
        ])
      } catch (error) {
        openNotificationWithIcon('error', { message: 'something went wrong' })
      }
    }

    if (date !== 'Invalid date') getData()
  }, [date])

  return {
    data,
  }
}
