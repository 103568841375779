import React, {
  useEffect,
  useRef,
  MouseEvent,
  MutableRefObject,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import JSZip from 'jszip'
import moment from 'moment-timezone'
import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'
import { Modal, Badge, Empty, Progress } from 'antd'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { MatrixLog } from 'features/Dispatch/types'
import { upload, deleteMedia } from 'api/Media'
import {
  getForemanLogSheets,
  postForemanLogSheets,
  putForemanLogSheets,
  getForemanLogSheetsHistory,
  exportForemanLogSheets,
} from 'api/Appointment'
import { sendBulkSms } from 'api/NotificationModal'
import {
  printPdf,
  makeDataForLogSheet,
  fullLink,
  makeBodySmsTemplate,
  generatePDF,
  makeForUpdateForm,
} from './utils'
import SubMenu from 'containers/MainContent/Orcatec/components/SubMenu'
import {
  Wrapper,
  ButtonWrapper,
  ControlsWrapper,
} from './ModalLogSheets.styles'
import { useAppSelector } from 'store/Orcatec/hooks'
import Template from './Template'
import { Forms } from './components/Forms'
import { Notification } from './components/Notification'
import { CloseOutlined } from '@ant-design/icons'
import { getValueFromLS } from 'hooks/useLocalStorage'
import { useMessagingPhones } from 'features/Messaging/hooks/useMessagingPhones'

import {
  parsePhoneNumberBeforeSend,
  MESSAGING_SERVICE_IS_NOT_SETUP_TEXT,
} from 'features/Messaging/utils'

import { openNotificationWithIcon } from '../../../../../../helpers/notifications/openNotificationWithIcon'
import { Media } from 'types/Media'
import {
  isBrandSubmitted,
  isMessagingSubmitted,
} from 'store/Orcatec/selectors/messaging'
import { Error } from 'types/Error'
import { User } from 'types/User'
import { selectEventsByDate } from 'features/Dispatch/dispatchSlice'

interface IProps {
  visible: boolean
  onClose: () => void
  matrixLog: MatrixLog
  handleZoom: (zoomLevel: number) => void
}

interface ISMSData {
  notification_items: {
    appointment_id: number
    sms: {
      from_number: string
      media: string[]
      send_at: string
      send_at_timezone: string
      recipients: []
    }
  }
}

export interface IData {
  appointment_id: number
  form: object
  contacts: User[]
  blob: Blob
  smsData: ISMSData[]
}

const ModalLogSheets = ({
  visible,
  onClose,
  matrixLog,
  handleZoom,
}: IProps) => {
  const { date, columns } = matrixLog

  //HOOKS
  const browserHistory = useHistory()
  const { phones } = useMessagingPhones()
  //SELECTORS
  //const technician: ITechnician = useAppSelector(state => state.orcatec.company.technicians)
  const isBrandChecked: boolean = useAppSelector(isBrandSubmitted)
  const companyName: string = useAppSelector(
    state => state.orcatec.company.company_name,
  )
  const appointments_used = useAppSelector(selectEventsByDate(date))

  // const appointments_used = Object.values(matrixLog.events)

  const apptIds: (number | string)[] = appointments_used?.map(({ id }) => id)
  const isHaveBrandAndCampaign = useAppSelector(isMessagingSubmitted)

  //STATE
  const [formData, setData] = useState<IData[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<0 | 1>(0)
  const [logSheetData, setLogSheetData] = useState({})
  const [chosenItems, setChosenItems] = useState<number[]>([])
  const [history, setHistory] = useState([])
  const [setupSMS, setSetupSMS] = useState(false)
  const [createPdfLoading, setCreatePdfLoading] = useState<{
    visible: boolean
    id: null | number
  }>({
    visible: false,
    id: null,
  })
  const [progressBar, setProgressBar] = useState<number>(0)
  const [progressBarVisible, setProgressBarVisible] = useState<boolean>(false)
  //REF
  const ref: MutableRefObject<HTMLElement | undefined> = useRef()

  const errorHandler = (error: Error) => {
    openNotificationWithIcon('error', {
      message: 'Something went wrong. Please try again.',
    })
    console.error(error)
  }

  const getMainTech = (columnId: number) =>
    columns[columnId].technicians.find(
      tech => tech.user_id === columns[columnId].main_tech_id,
    )

  const getHelpers = (columnId: number) => columns[columnId].technicians
  const fetchFormData = async () => {
    setLoading(true)
    try {
      const { data } = await getForemanLogSheets(apptIds)
      const forms = data?.data?.map(form => {
        const appointment = appointments_used.find(
          ({ id }) => id === +form.appointment_id,
        )

        if (!appointment) return

        const contacts = getHelpers(appointment.column_template_id)
        return {
          appointment_id: +form.appointment_id,
          form,
          appointment,
          contacts,
        }
      })
      setData(forms)
      // setChosenItems([])
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const chooseItemsHandler = (id: number) => {
    if (chosenItems?.includes(id)) {
      setChosenItems(prev => prev.filter(p => p !== id))
    } else {
      setChosenItems(prev => [...prev, id])
    }
  }

  // const getContacts = (id: number) => {
  //   return technician?.find(tech => tech?.id === +id)
  // }
  const makeSmsData = (
    contacts: User[],
    id: number,
    media: Media,
    city: string | undefined,
    relation: string | undefined,
    customerName: string,
    foremanName: string,
  ) => {
    if (!contacts.length) return []

    return contacts.map(contact => {
      const { phone = '' } = contact
      return {
        appointment_id: id,
        sms: {
          body: makeBodySmsTemplate(
            foremanName,
            date,
            companyName,
            relation,
            city,
            customerName,
          ),
          from_number: phones?.[0]?.number,
          media: [fullLink(media)],
          send_at: moment
            .tz(moment.tz.guess())
            .add(1, 'minute')
            .format('YYYY-MM-DD HH:mm'),
          send_at_timezone: moment.tz.guess(),
          recipients: { clients: [parsePhoneNumberBeforeSend(phone)] },
        },
      }
    })
  }

  const uploadPdfHandler = async (blob: Blob) => {
    const fd = new FormData()
    fd.append('media', blob)
    return upload(fd)
  }

  const createBlobFromRef = async (page: HTMLElement) => {
    const imgPages = await html2canvas(page, { scale: 2, allowTaint: true })
    const contentDataURL = imgPages.toDataURL('image/jpeg')
    const byteArray = new Buffer(
      contentDataURL.replace(/^[\w\d;:/]+base64,/g, ''),
      'base64',
    )
    return new Blob([byteArray], { type: 'image/jpeg' })
  }

  const savePdfAsZipHandler = async (blob: Blob[]) => {
    const zip = new JSZip()
    formData.forEach(({ contacts, appointment_id }, i) => {
      const fileName = `${contacts?.name ||
        'appointment #'}-${appointment_id}.png`
      zip.file(fileName, blob[i], { binary: true })
    })

    const data = await generatePDF(blob)
    zip.file(`Log Sheets ${date}.pdf`, data, { binary: true })
    const content = await zip.generateAsync({ type: 'blob' })

    await saveAs(content, `Log Sheets ${date}`)
  }

  const changeTabHandler = (id: number) => {
    setActiveTab(id)
    setSetupSMS(false)
  }

  const onMakePreviewHandler = async (item: IData) => {
    try {
      const appointment = appointments_used.find(
        ({ id }) => id === +item.appointment_id,
      )
      if (!appointment) return
      const contacts = getMainTech(appointment?.column_template_id)
      const helpers = getHelpers(appointment?.column_template_id)
      if (!item?.form?.id) {
        setCreatePdfLoading({ visible: true, id: +item.appointment_id })

        const formData = await makeDataForLogSheet(
          appointment,
          contacts,
          helpers,
        )
        await setLogSheetData(formData)

        const blob: Blob = await createBlobFromRef(ref.current)
        const [media] = await uploadPdfHandler(blob)
        const { data } = await postForemanLogSheets({
          ...formData,
          media_id: media.id,
          file_path: media.path,
        })
        setData(prev =>
          prev.map(p =>
            p?.appointment_id === data?.appointment_id
              ? { ...p, form: data, blob }
              : p,
          ),
        )
      }
      if (item?.form?.id) {
        setCreatePdfLoading({ visible: true, id: +item.appointment_id })
        // const appointment = appointments_used.find(({ id }) => id === +item.appointment_id)
        const formData = await makeForUpdateForm(
          appointment,
          contacts,
          item?.form,
          helpers,
        )
        await setLogSheetData({ ...item?.form, ...formData })
        const blob: Blob = await createBlobFromRef(ref.current)
        const [media] = await uploadPdfHandler(blob)
        if (item?.form?.media_id) {
          await deleteMedia(item?.form?.media_id)
        }

        const dataForSave = {
          ...item?.form,
          ...formData,
          foreman_materials: [
            ...item?.form.materials_checked_out,
            ...item?.form.materials_checked_in,
            ...item?.form.billable_materials,
            ...item?.form.equipment_check_out,
          ],
        }
        delete dataForSave.materials_checked_out
        delete dataForSave.materials_checked_in
        delete dataForSave.billable_materials
        delete dataForSave.equipment_check_out

        const { data } = await putForemanLogSheets({
          ...dataForSave,
          media_id: media.id,
          file_path: media.path,
        })
        setData(prev =>
          prev.map(p =>
            p?.appointment_id === data?.appointment_id
              ? { ...p, form: data, blob }
              : p,
          ),
        )
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setCreatePdfLoading({ visible: false, id: null })
    }
  }

  const createAndSaveLogSheet = async (appointmentId: number) => {
    try {
      const appointment = appointments_used.find(
        ({ id }) => id === appointmentId,
      )
      if (!appointment) return

      const helpers = getHelpers(appointment?.column_template_id)
      const contacts = getMainTech(appointment?.column_template_id)
      const formData = await makeDataForLogSheet(appointment, contacts, helpers)
      setLogSheetData(formData)
      if (ref?.current && appointment) {
        const blob: Blob = await createBlobFromRef(ref.current)
        const [media] = await uploadPdfHandler(blob)
        const { data } = await postForemanLogSheets({
          ...formData,
          media_id: media.id,
          file_path: media.path,
        })

        return {
          appointment_id: appointmentId,
          form: data,
          appointment,
          contacts,
          blob,
          smsData: makeSmsData(
            contacts,
            appointmentId,
            media.path,
            appointment?.city,
            appointment?.relation,
            appointment?.client_name,
            contacts?.name,
          ),
        }
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setProgressBar(prev => prev + 1)
    }
  }
  const createAndUpdateLogSheet = async (appointmentId: number, form) => {
    try {
      const appointment = appointments_used.find(
        ({ id }) => id === appointmentId,
      )
      if (!appointment) return

      const contacts = getMainTech(appointment.column_template_id)
      const helpers = getHelpers(appointment?.column_template_id)

      const formData = await makeForUpdateForm(
        appointment,
        contacts,
        form,
        helpers,
      )

      await setLogSheetData({ ...form, ...formData })
      if (ref?.current && appointment) {
        const blob: Blob = await createBlobFromRef(ref.current)
        const [media] = await uploadPdfHandler(blob)
        if (form?.media_id) {
          await deleteMedia(form?.media_id)
        }
        const dataForSave = {
          ...form,
          ...formData,
          foreman_materials: [
            ...form.materials_checked_out,
            ...form.materials_checked_in,
            ...form.billable_materials,
            ...form.equipment_check_out,
          ],
        }
        delete dataForSave.materials_checked_out
        delete dataForSave.materials_checked_in
        delete dataForSave.billable_materials
        delete dataForSave.equipment_check_out
        const { data } = await putForemanLogSheets({
          ...dataForSave,
          media_id: media.id,
          file_path: media.path,
        })

        return {
          appointment_id: appointmentId,
          form: data,
          appointment,
          contacts,
          blob,
          smsData: makeSmsData(
            helpers,
            appointmentId,
            media.path,
            appointment?.city,
            appointment?.relation,
            appointment?.client_name,
            contacts?.name,
          ),
        }
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setProgressBar(prev => prev + 1)
    }
  }
  const createAndSaveLogSheetPrint = async (appointmentId: number) => {
    try {
      const appointment = appointments_used.find(
        ({ id }) => id === appointmentId,
      )
      if (!appointment) return
      const helpers = getHelpers(appointment?.column_template_id)
      const contacts = getMainTech(appointment?.column_template_id)
      const formData = await makeDataForLogSheet(appointment, contacts, helpers)
      await setLogSheetData(formData)

      if (ref?.current) {
        const blob: Blob = await createBlobFromRef(ref.current)
        const { data } = await postForemanLogSheets(formData)
        // const contacts = getMainTech(appointment.column_template_id)

        return {
          appointment_id: appointmentId,
          form: data,
          appointment,
          contacts,
          blob,
        }
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setProgressBar(prev => prev + 1)
    }
  }
  const createAndUpdateLogSheetPrint = async (appointmentId: number, form) => {
    try {
      const appointment = appointments_used.find(
        ({ id }) => id === appointmentId,
      )
      if (!appointment) return

      const contacts = getMainTech(appointment.column_template_id)
      const helpers = getHelpers(appointment?.column_template_id)
      const formData = await makeForUpdateForm(
        appointment,
        contacts,
        form,
        helpers,
      )

      await setLogSheetData({ ...form, ...formData })
      if (ref?.current) {
        const blob: Blob = await createBlobFromRef(ref.current)
        const dataForSave = {
          ...form,
          ...formData,
          foreman_materials: [
            ...form.materials_checked_out,
            ...form.materials_checked_in,
            ...form.billable_materials,
            ...form.equipment_check_out,
          ],
        }
        delete dataForSave.materials_checked_out
        delete dataForSave.materials_checked_in
        delete dataForSave.billable_materials
        delete dataForSave.equipment_check_out

        const { data } = await putForemanLogSheets(dataForSave)

        return {
          appointment_id: appointmentId,
          form: data,
          appointment,
          contacts,
          blob,
        }
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setProgressBar(prev => prev + 1)
    }
  }

  const onCombainForms = async () => {
    const forms = formData.filter(f => chosenItems.includes(+f.appointment_id))
    const data = []
    for (let index = 0; index < forms.length; index++) {
      const form = forms[index]
      if (!form?.form?.id) {
        const res = await createAndSaveLogSheet(+form.appointment_id)
        data.push(res)
      } else if (form?.form?.id) {
        const res = await createAndUpdateLogSheet(
          +form.appointment_id,
          form?.form,
        )
        data.push(res)
      }
    }
    return data
  }
  const onCombainFormsPrint = async () => {
    const forms = formData.filter(f => chosenItems.includes(+f.appointment_id))
    const data = []
    for (let index = 0; index < forms.length; index++) {
      const form = forms[index]
      if (!form?.form?.id) {
        const res = await createAndSaveLogSheetPrint(+form.appointment_id)
        data.push(res)
      } else if (form?.form?.id) {
        const res = await createAndUpdateLogSheetPrint(
          +form.appointment_id,
          form?.form,
        )
        data.push(res)
      }
    }
    return data
  }

  const sendSmsHandler = async forms => {
    const dataForSms = forms
      .filter(data => !!data?.appointment_id)
      .filter(({ appointment_id }) => chosenItems.includes(appointment_id))
      .map(({ smsData }) => [...smsData])
    const smsArray = dataForSms
      .flat()
      .filter(s => !!s?.sms?.recipients?.clients?.[0]) //filter by valid number

    if (smsArray?.length) {
      const { count } = await sendBulkSms(smsArray)

      if (count) {
        openNotificationWithIcon('success', {
          message: 'Notification was send successfully!',
        })
      }
    } else {
      openNotificationWithIcon('error', {
        message: 'Select a foreman or the selected foreman has been removed!',
      })
    }
  }

  const submitHandler = async (e: MouseEvent<HTMLButtonElement>) => {
    const { name } = e.target
    setLoading(true)

    if (name === 'send' && !isBrandChecked) {
      setSetupSMS(true)
      setLoading(false)
      return
    }
    setProgressBarVisible(true)

    try {
      if (name === 'export') {
        return await exportForemanLogSheets({
          date: moment(date).format('YYYY-MM-DD'),
        })
      }
      if (name === 'zip') {
        const forms = await onCombainFormsPrint()
        const blob = forms.map(f => f.blob)
        await savePdfAsZipHandler(blob)
      }
      if (name === 'print') {
        const forms = await onCombainFormsPrint()
        const blob = forms.map(f => f.blob)
        setChosenItems(forms?.map(f => +f?.appointment_id))
        await printPdf(blob)
      }
      if (name === 'send') {
        const forms = await onCombainForms()
        await sendSmsHandler(forms)
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
      setProgressBarVisible(false)
      setProgressBar(0)
      await fetchFormData()
    }
  }

  const getTabContent = (activeTab: number) => {
    switch (activeTab) {
      case 0:
        return (
          <Forms
            data={formData}
            chosenItems={chosenItems}
            setChosenItems={setChosenItems}
            loading={loading}
            chooseItemsHandler={chooseItemsHandler}
            onMakePreviewHandler={onMakePreviewHandler}
            apptIds={apptIds}
            createPdfLoading={createPdfLoading}
          />
        )

      case 1:
        return (
          <Notification
            history={history}
            data={formData}
            apptIds={apptIds}
            loading={loading}
          />
        )
    }
  }
  const blockedBodyScroll = (isBlocking = false) => {
    const x = document.querySelector('body')
    if (isBlocking) {
      return x.setAttribute('style', 'overflow: hidden !important')
    }
    return x.removeAttribute('style')
  }

  const menuItems = [
    {
      id: 0,
      title: (
        <Badge size='small' count={apptIds?.length}>
          Forms
        </Badge>
      ),
    },
    {
      id: 1,
      title: 'Notifications History',
    },
  ]

  useEffect(() => {
    const getHistory = async () => {
      try {
        const params = {
          appointment_id: apptIds,
          type: 7,
        }
        const { data } = await getForemanLogSheetsHistory(params)
        setHistory(data)
      } catch (error) {
        console.error(error)
      }
    }
    if (activeTab === 1) getHistory()
  }, [activeTab])
  useEffect(() => {
    if (apptIds?.length && visible) {
      handleZoom?.(1)
      fetchFormData()
    }
    if (!visible) {
      const zoomLevel = getValueFromLS('matrixSettings')?.zoomLevel
      setLogSheetData({})
      setData([])
      handleZoom?.(zoomLevel || 2)
    }
  }, [visible])

  useEffect(() => {
    visible ? blockedBodyScroll(true) : blockedBodyScroll()
  }, [visible])
  return (
    <>
      <Modal
        title={`Log Sheets - ${moment(date).format('MM/DD/YYYY')}`}
        destroyOnClose
        centered
        visible={visible}
        width={'60%'}
        closeIcon={
          <span onClick={!loading ? onClose : null}>
            <CloseOutlined />
          </span>
        }
        footer={
          <ButtonWrapper>
            <MainButton disabled={loading} onClick={onClose} title='Close' />
          </ButtonWrapper>
        }
      >
        <ControlsWrapper>
          <MainButton
            disabled={loading || !chosenItems?.length}
            name='zip'
            onClick={submitHandler}
            title='Save Log Sheets as ZIP'
          />
          <MainButton
            disabled={loading || !chosenItems?.length}
            name='print'
            onClick={submitHandler}
            title='Print Log Sheets'
          />
          <MainButton
            disabled={loading || !chosenItems?.length}
            name='send'
            onClick={submitHandler}
            title='Send SMS Notification'
          />
          <MainButton
            disabled={loading}
            name='export'
            onClick={submitHandler}
            title='Export'
          />
        </ControlsWrapper>
        {setupSMS && !isHaveBrandAndCampaign ? (
          <MESSAGING_SERVICE_IS_NOT_SETUP_TEXT />
        ) : (
          !phones?.length && (
            <MainButton
              key='buy'
              title='Buy a New Number'
              onClick={() => browserHistory.push('/settings/messaging')}
            />
          )
        )}
        <SubMenu
          activeItem={activeTab}
          menuItemsList={menuItems}
          onChange={changeTabHandler}
        />

        <Wrapper>
          {apptIds?.length ? (
            getTabContent(activeTab)
          ) : (
            <Empty style={{ margin: '0 auto' }} />
          )}
        </Wrapper>
        {progressBarVisible && (
          <Progress
            style={{ position: 'absolute', top: '40%', left: '45%' }}
            type='circle'
            percent={Number(
              ((100 / chosenItems.length) * progressBar).toFixed(1),
            )}
            format={percent => (
              <div>
                <p>{`${percent}%`}</p>
                <p
                  style={{ fontSize: '12px' }}
                >{`${progressBar} of ${chosenItems.length} generated`}</p>
              </div>
            )}
          />
        )}
      </Modal>
      {visible && <Template ref={ref} logSheetData={logSheetData} />}
    </>
  )
}

export default ModalLogSheets
