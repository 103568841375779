import {
  AccountingIcon,
  ContactsIcon,
  DashboardIcon,
  DispatchIcon,
  EstimatesIcon,
  FastPaymentIcon,
  InstantAppointmentIcon,
  JobsIcon,
  MapIcon,
  MessagingIcon,
  ProjectsIcon,
  PropertiesIcon,
  QuickbooksIcon,
  ReviewsIcon,
  ScheduleIcon,
  SettingsIcon,
  TimecardsIcon,
  TodoIcon,
  CustomFormIcon,
} from './NavigationIcons'
import { useAppSelector } from '../../../../../store/Orcatec/hooks'
import { IBusinessRoute } from '../types'
import { Badge } from 'antd'

import { useHeaderSocket } from '../../useHeaderSocket'
import { Phone } from '@material-ui/icons'

import IconEmails from 'layouts/MainLayout/Sidebar/icons/IconEmails'

import {
  selectModulePermissions,
  selectIsOwner,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import { meSelector } from 'store/SuperAdmin/selectors'
import {
  selectJobDictionary,
  isEmailModuleAvaliable,
} from 'store/Orcatec/selectors/company'

// import { Companies } from 'types/Company'
import { useEmailsCount } from 'features/Email/hooks/useEmailsCount'
import { isSuperAdmin } from 'store/Orcatec/selectors/user'

export const useRoutes = () => {
  const { unreadChatsCount } = useHeaderSocket()

  const { count } = useEmailsCount()

  const company = useAppSelector(s => s.orcatec.company)
  const dictionary = useAppSelector(selectJobDictionary)
  // const permissions = useAppSelector(s => s.orcatec.user.me.permissions)

  const {
    project,
    schedule,
    accounting,
    contacts,
    dashboard,
    dispatch,
    forms,
    forms_template,
    forms_detailed,
    map,
    properties,
    time_cards,
    settings,
    fast_payment,
    todo,
    jobs,
    quickbooks,
    reviews,
    instant_appointment,
    memberships,
    messaging,
    call_tracking,
    balances,
    expenses,
    estimates,
  } = useAppSelector(selectModulePermissions())
  const me = useAppSelector(meSelector)

  const isOwner = useAppSelector(selectIsOwner(me.id))

  const isSA = useAppSelector(isSuperAdmin)

  const unreadTodos = useAppSelector(
    state => state.orcatec.user.me.count_unread_tasks,
  )

  const isEmailAvaliable = useAppSelector(isEmailModuleAvaliable)

  const links: IBusinessRoute[] = [
    {
      key: 'emails',
      title: 'Emails',
      route: '/emails',
      icon: <IconEmails />,
      icon: (
        <Badge count={count} color='#faad14' offset={[0, 15]}>
          <IconEmails />
        </Badge>
      ),
      isAllowed:
        isEmailAvaliable ||
        // [
        //   Companies.HVAC,
        //   Companies.BuildX,
        //   Companies.AirLoop,
        //   Companies.Sony,
        //   Companies.OrcatecCorporate,
        // ].includes(company?.id)
        (isEmailAvaliable &&
          //   [
          //   Companies.HVAC,
          //   Companies.BuildX,
          //   Companies.AirLoop,
          //   Companies.Sony,
          //   Companies.OrcatecCorporate,
          // ].includes(company?.id)
          isSA),
    },
    {
      key: 'appointments',
      title: 'Dispatch',
      route: '/dispatch',
      icon: <DispatchIcon />,
      isAllowed: dispatch,
    },
    {
      key: 'map',
      title: 'Map',
      route: '/map',
      icon: <MapIcon />,
      isAllowed: map,
    },
    {
      key: 'schedules',
      title: 'Schedule',
      route: '/schedule',
      icon: <ScheduleIcon />,
      isAllowed: schedule,
    },
    {
      key: 'proposals',
      title: 'Projects',
      route: '/projects',
      icon: <ProjectsIcon />,
      isAllowed: project,
    },
    {
      key: 'proposals',
      title: 'Estimates',
      route: '/estimates',
      icon: <EstimatesIcon />,
      isAllowed: estimates,
    },
    {
      key: 'work_schedule',
      title: dictionary.plural,
      route: '/jobs-list',
      icon: <JobsIcon />,
      isAllowed: jobs,
    },
    {
      key: 'properties',
      title: 'Properties',
      route: '/properties',
      icon: <PropertiesIcon />,
      isAllowed: properties,
    },
    {
      key: 'clients',
      title: company?.id === 264 ? 'Contacts & Customers' : 'Contacts',
      route: '/clients',
      icon: <ContactsIcon />,
      isAllowed: contacts,
    },

    {
      key: 'dashboard',
      title: 'Dashboard',
      route: '/dashboard',
      icon: <DashboardIcon />,
      isAllowed: dashboard,
    },
    {
      key: 'fast_appointment',
      title: 'Instant Appt.',
      route: '/instant-appointment',
      icon: <InstantAppointmentIcon />,
      isAllowed: instant_appointment,
    },
    {
      key: 'fast_payment',
      title: 'Fast-Payment',
      route: '/fast-payment',
      icon: <FastPaymentIcon />,
      isAllowed: fast_payment,
    },
    {
      key: 'todo',
      title: 'To Do',
      route: '/to-do',
      icon: (
        <Badge status='warning' count={unreadTodos} offset={[0, 15]}>
          <TodoIcon />
        </Badge>
      ),
      isAllowed: todo,
    },
    {
      key: 'messaging',
      title: 'Messaging',
      route: '/messaging',
      icon: (
        <Badge count={unreadChatsCount} color='#faad14' offset={[0, 15]}>
          <MessagingIcon />
        </Badge>
      ),
      isAllowed: messaging,
    },
    {
      key: 'call_tracking',
      title: 'Calls Tracking',
      route: '/calls',
      icon: <Phone />,
      isAllowed: call_tracking,
    },

    {
      key: 'time_cards',
      title: 'Time cards',
      route: '/time-cards',
      icon: <TimecardsIcon />,
      isAllowed: time_cards,
    },
    {
      key: 'request_review',
      title: 'Reviews',
      route: '/reviews',
      icon: <ReviewsIcon />,
      isAllowed: reviews,
    },

    {
      key: 'accounting',
      title: 'Accounting',
      route: '/accounting',
      icon: <AccountingIcon />,
      isAllowed: balances || expenses || accounting,
    },
    {
      key: 'quickbooks',
      title: 'Quickbooks',
      route: '/quickbooks',
      icon: <QuickbooksIcon />,
      isAllowed: quickbooks,
    },
    {
      key: 'memberships',
      title: 'Memberships',
      route: '/memberships',
      icon: <ContactsIcon />,
      isAllowed: memberships,
    },
    {
      key: 'custom_forms',
      title: 'Forms',
      route: '/custom-form',
      icon: <CustomFormIcon />,
      isAllowed: forms || forms_template || forms_detailed,
    },
    {
      key: 'settings',
      title: 'Settings',
      route: '/settings/info',
      icon: <SettingsIcon />,
      isAllowed: settings || isOwner,
    },
  ]

  // const isRouteExclude = (key: string) => {
  //   const excludedRoutes = ['estimates', 'calls', 'map']
  //   // const companyToExclude = [
  //   //   Companies.HVAC,
  //   //   Companies.SafetyNey,
  //   //   Companies.WestCost,
  //   // ]
  //   // if (companyToExclude.includes(company.id)) {
  //   //   excludedRoutes.push('custom-form')
  //   // }

  //   return excludedRoutes.includes(key)
  // }

  // const routes = links.filter(
  //   ({ key }) => isRouteExclude(key) || permissions[key],
  // )

  const routes = links.filter(({ isAllowed }) => !!isAllowed)

  return [routes, unreadChatsCount, unreadTodos]
}
