import React from 'react'
import Preloader from '../../components/Preloader'
import styled from 'styled-components'
import { Drawer, DrawerProps } from 'antd'

import './style.scss'

export const IconWrapper = styled.div`
  /* pointer-events: ${props => (props['aria-disabled'] ? 'none' : 'all')}; */
  & svg {
    cursor: pointer;
    transition: all 0.2s;
    fill: ${props => (props['aria-disabled'] ? 'grey' : '')};
  }
  &:hover svg {
    fill: ${props => (props['aria-disabled'] ? '' : '#4c53ef')};
    transition: all 0.2s;
  }
`
export const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px;
  flex-wrap: nowrap;
  @media screen and (max-width: 500px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }
`
export const IconsBlock = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  & .ant-picker {
    width: 200px;
  }
  & .ant-picker.ant-picker-range {
    width: 280px;
  }
`
export const AccoutingTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  & ul {
    padding-top: 7px;
  }
  & li {
    font-size: 14px;
  }
`

interface PageLayoutProps {
  className?: string
  fullsize?: string
  title?: React.ReactNode
  preloader?: boolean
  actions?: React.ReactNode
  children?: React.ReactNode
  sidebarContent?: React.ReactNode
  visible?: DrawerProps['visible']
  onClose?: DrawerProps['onClose']
  addButton?: React.ReactNode
  drawerTitle?: DrawerProps['title']
  isFullScreen?: boolean
}

const PageLayout: React.FC<PageLayoutProps> = ({
  className = '',
  fullsize: fullSize,
  title = '',
  actions,
  preloader,
  sidebarContent,
  children,
  visible,
  onClose,
  addButton,
  drawerTitle = 'Filters',
  isFullScreen = false,
}): JSX.Element => {
  return (
    <div
      id='printable'
      className={`custom-page-layout ${className ?? ''} ${
        fullSize ? `w100` : ``
      } ${isFullScreen ? 'table-fullscreen' : ''}`}
    >
      <div className='custom-page-headers page-title-boxs'>
        <h4 className='page-title'>
          {title} {addButton}
        </h4>
        <div className='page-action'>{actions}</div>
      </div>

      <div className='page-content card'>
        {preloader ? (
          <Preloader />
        ) : (
          <div className='card-body'>{children}</div>
        )}
      </div>
      <Drawer title={drawerTitle} visible={visible} onClose={onClose}>
        <div>{sidebarContent}</div>
      </Drawer>
    </div>
  )
}

export default PageLayout
