import React, { useState, useEffect } from 'react'

import Preloader from '../../../components/Preloader'

const ResolveConfirmation = ({ show, onHide, onConfirm }) => {
  const [confirmed, setConfirmed] = useState(false)

  useEffect(() => {
    if (!show) {
      setConfirmed(false)
    }
  }, [show])

  const handleConfirm = () => {
    setConfirmed(true)
    onConfirm()
  }

  const renderPreloader = !!confirmed && (
    <div className='ticket-conf__preloader'>
      <Preloader />
    </div>
  )

  return (
    <div className='ticket-conf__backdrop'>
      {renderPreloader}
      <div className='ticket-conf__modal'>
        <div className='ticket-conf-modal__title'>Are you sure you want to mark this request as resolved?</div>
        <div className='d-flex justify-content-center'>
          <button className='btn btn-custom-cancel mr-2' onClick={onHide}>
            Cancel
          </button>
          <button className='btn btn-custom-info ml-2' onClick={handleConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default ResolveConfirmation
