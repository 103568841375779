export const ITEM_FETCH_Equipment = 'ITEM_FETCH_Equipment'
export const ITEM_FETCH_ScopeOfWork = 'ITEM_FETCH_ScopeOfWork'
export const ITEM_FETCH_Warranty = 'ITEM_FETCH_Warranty'
export const ITEM_FETCH_Instant = 'ITEM_FETCH_Instant'
export const ITEM_FETCH_Rebate = 'ITEM_FETCH_Rebate'
export const CLEAR_NEW_ITEM = 'CLEAR_NEW_ITEM'
export const ITEM_SET_ITEM = 'ITEM_SET_ITEM'
export const ITEM_SET_ITEM_TO_ITEMS = 'ITEM_SET_ITEM_TO_ITEMS'
export const ITEM_GET_ITEM = 'ITEM_GET_ITEM'
export const ITEM_DEL_ITEM = 'ITEM_DEL_ITEM'
export const ITEM_DELETE_ITEM = 'ITEM_DELETE_ITEM'
export const ITEM_CHECK_ITEM = 'ITEM_CHECK_ITEM'
export const ITEM_SET_ITEMS = 'ITEM_SET_ITEMS'
export const ITEM_CHANGE_NEW_ITEM = 'ITEM_CHANGE_NEW_ITEM'
export const ITEM_EDIT_CELL = 'ITEM_EDIT_CELL'

export const PROXY_ITEM_UPDATE = 'PROXY_ITEM_UPDATE'

export const SET_EQUIPMENTS = 'SET_EQUIPMENTS'
export const SET_SUPPLIES = 'SET_SUPPLIES'
export const SET_PARTS = 'SET_PARTS'

export const SET_SCOPEOFWORKS = 'SET_SCOPEOFWORKS'
export const SET_WARRANTIES = 'SET_WARRANTIES'
export const SET_INSTANT = 'SET_INSTANT'
export const SET_REBATE = 'SET_REBATE'

// export const SET_Warranty = 'SET_Warranty'
