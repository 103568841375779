import * as types from '../../types'
const initialState = {
  tech: [],
  app_types: [],
  app_sources: [],
  selectedData: {
    technicianId: null,
    appointmentTypeId: null,
    appointmentSourceId: null,
    property: {},
    industry_id: '',
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.proposalModal.SET_MODAL_DATA: {
      const newState = {
        tech: action.tech,
        app_types: action.app_types,
        app_sources: action.app_sources,
        industries: action.industries,
        // selectedData: { industries: Array.isArray(action.industries) && action.industries?.length === 1 ? action.industries : [] },
        selectedData: { industry_id: action.industries?.[0].id },
      }
      if (action.selectMe === 'select me') {
        newState.selectedData.technicianId = action.tech[0].id
        if (action.app_sources?.length) newState.selectedData.appointmentSourceId = action.app_sources[0].id
      }
      return newState
    }
    case types.proposalModal.UPDATE_SELECTED_DATA:
      if (action.newState === 'reset') return { ...state, selectedData: initialState.selectedData }
      else return { ...state, selectedData: action.newState }
    case types.proposalModal.SET_ITEMS_IN_MODAL_DATA: {
      const itemsName =
        action.itemsName === 'source'
          ? 'app_sources'
          : action.itemsName === 'type'
          ? 'app_types'
          : action.itemsName === 'user'
          ? 'tech'
          : ''
      return itemsName ? { ...state, [itemsName]: action.items } : state
    }
    default:
      return state
  }
}
