import React, { useState, useEffect, useRef } from 'react'
import { Input as AntInput, Select as AntSelect, Spin, Checkbox } from 'antd'
import { Modal } from 'react-bootstrap'
import { fetchUsersForTemplate } from '../../../../../../../api/User'
import './ModalProposalTemplate.scss'
import { makeStyles, createStyles, TextField } from '@material-ui/core'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import CustomMultyAutocomplete from 'containers/MainContent/Orcatec/components/CustomMultyAutocomplete'

const templateNameErrorMessage = 'This field is required'

export const ModalProposalTemplate = props => {
  const [saving, setisSaving] = useState(false)
  const [newTemplateName, setNewTemplateName] = useState('')
  const [, setTemplates] = useState([])
  const [usersForShare, setUsersForShare] = useState([])
  const [, setIsFetchingUsers] = useState(false)
  const [newTemplateError, setNewTemplateNameError] = useState('')
  const [sharedIds, setSharedIds] = useState([])
  const [isNewTemlateDefault, setIsNewTemplateDefault] = useState(false)
  const [currentTemplateName, setCurrentTemplateName] = useState(null)
  const resetState = () => {
    setisSaving(false)
    setNewTemplateName('')
    setSharedIds([])
    setIsNewTemplateDefault(false)
    setUsersForShare([])
    setIsFetchingUsers(false)
    setTemplates([])
    setCurrentTemplateName(null)
  }

  const onCancel = () => {
    props.onCancel()
  }

  useEffect(() => {
    if (props.modal) {
      setIsFetchingUsers(true)
      fetchUsersForTemplate()
        .then(data => {
          const { users, template_name } = data
          const usersToState = users.filter(user => user.id !== props.me.id)
          setCurrentTemplateName(template_name)
          setUsersForShare(usersToState)
        })
        .finally(() => setIsFetchingUsers(false))
    } else {
      resetState()
    }
  }, [props.modal])

  const onChangeName = e => {
    const { value } = e.currentTarget
    setNewTemplateNameError(!value ? templateNameErrorMessage : '')
    setNewTemplateName(value)
  }

  const onSave = () => {
    if (!newTemplateName)
      return setNewTemplateNameError(templateNameErrorMessage)
    setisSaving(true)
    const userIds = sharedIds.filter(id => !isNaN(id))
    props
      .onSave(newTemplateName, userIds, isNewTemlateDefault)
      .then(() => onCancel())
      .finally(() => setisSaving(false))
  }

  const onChangeCheckbox = e => {
    setIsNewTemplateDefault(e.target.checked)
  }

  const onSelectUsers = (e, selectedUsersIds) => {
    setSharedIds(
      typeof e.target.value === 'string' ? sharedIds : selectedUsersIds,
    )
  }

  return (
    <Modal
      show={props.modal}
      onHide={onCancel}
      backdrop='static'
      className='modal-proposal-template'
    >
      <Modal.Header closeButton>
        <span className='title'>{props.title}</span>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-sm-12 col-md-6'>
            {/*  <Form.Item
              label='Template Name'
              validateStatus={!newTemplateError ? 'success' : 'error'}
              help={newTemplateError}
            >
              <AntInput
                size='large'
                value={newTemplateName}
                name='name'
                onChange={onChangeName}
                placeholder='Template Name'
              />
            </Form.Item> */}
            <InputField
              value={newTemplateName}
              onChange={onChangeName}
              label='Template Name'
            />
          </div>
          <div className='col-sm-12 col-md-6'>
            {/* <Form.Item label='Share with other users'> */}
            <CustomMultyAutocomplete
              label='Share with other users'
              onChange={onSelectUsers}
              value={sharedIds}
              filterOptions={x => x}
              options={usersForShare.map(user => user.id)}
              getOptionLabel={id => {
                const user = usersForShare.find(u => u.id === +id)
                return user ? `${user.full_name} - ${user.title}` : ''
              }}
              checkIsChoosen={id => sharedIds.includes(+id)}
              inputValue={
                sharedIds?.length
                  ? `Shared with ${sharedIds?.length +
                      (sharedIds?.length > 1 ? ' users' : ' user')}`
                  : 'Not shared'
              }
            />
            {/* </Form.Item> */}
          </div>
        </div>
        <Checkbox
          onChange={onChangeCheckbox}
          checked={isNewTemlateDefault}
        >{`Use as default template`}</Checkbox>
        {!!currentTemplateName && (
          <div className='current-template-name'>{`Current Default Template is ${currentTemplateName}`}</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-custom-cancel' onClick={onCancel}>
          Cancel
        </button>
        <button
          disabled={saving}
          className='btn btn-custom-info'
          onClick={onSave}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  )
}
