import React, { useState, useEffect } from 'react'

export const useCalculateQty = (property = [], clients = [], organizations = []) => {
  const [qtyProperties, setQtyProperties] = useState(null)
  const [qtyClients, setQtyClients] = useState(null)
  const [qtyOrganizations, setQtyOrganizations] = useState(null)

  useEffect(() => {
    if (property && Array.isArray(property)) {
      const allProperty = property
        .reduce(
          (acc, curr) => {
            return acc.concat(
              curr.clients.reduce(
                (acc, curr) => acc.concat(curr.properties.filter(el => el.checkbox === true).map(el => el.id)),
                [],
              ),
              curr.organizations.reduce(
                (acc, curr) => acc.concat(curr.properties.filter(el => el.checkbox === true).map(el => el.id)),
                [],
              ),
            )
          },
          [property.map(el => el.id)],
        )
        .flat()
      const uniqProperty = [...new Set(allProperty)]
      setQtyProperties(uniqProperty?.length)
    }
    if (property === null) setQtyProperties(null)
  }, [property])

  useEffect(() => {
    if (clients && Array.isArray(clients)) {
      const allClients = clients.filter(obj => obj.type !== 1).map(el => el.id)
      const uniqClients = [...new Set(allClients)]
      setQtyClients(clients ? uniqClients?.length : null)
    }
  }, [clients])

  useEffect(() => {
    if (organizations && Array.isArray(organizations)) {
      const allOrganizations = organizations.filter(obj => obj.type === 1).map(el => el.id)
      const uniqOrganizations = [...new Set(allOrganizations)]
      setQtyOrganizations(organizations ? uniqOrganizations?.length : null)
    }
  }, [organizations])

  return { properties: qtyProperties, clients: qtyClients, organizations: qtyOrganizations }
}
