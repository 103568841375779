import React, { useState, useEffect, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { toggleDrawer } from '../../../store/SuperAdmin/actions/commonActions'
import {
  fetchUnseenTickets,
  incrementUnseenTickets,
  decrementUnseenTickets,
} from '../../../store/SuperAdmin/actions/supportActions'

import IconBtn from '../../static/icons/drawerIcons/button'
import IconUser from '../../static/icons/drawerIcons/UserIcon'
import IconCompanies from '../../static/icons/drawerIcons/CompaniesIcon'
import IconEmail from '../../static/icons/drawerIcons/EmailIcon'
import IconEmailAccounts from '../../static/icons/drawerIcons/EmailAccounts'
import IconSupport from '../../static/icons/drawerIcons/SupportIcon'
import { default as IconDefaultPrices } from '../../static/icons/drawerIcons/DefaultPricesIcon'
import Blogs from '../../static/icons/drawerIcons/Blogs'

import { getConnector } from '../../../helpers/ws'

import s from './style.module.css'
import { AppStateType } from '../../../store'
import { IAdminRoute, IDrawerProps } from '../../types/Drawer'

const Drawer: React.FC<IDrawerProps> = ({
  toggleDrawer,
  fetchUnseenTicketsCount /*, incUnseenTickets, decUnseenTickets */,
}) => {
  const me = useSelector((state: AppStateType) => state.orcatec.user.me)
  const unseenCount = useSelector(
    (state: AppStateType) => state.superAdmin.support.unseenTickets,
  )

  const [isOpen, setOpen] = useState(true)

  const {
    users,
    companies,
    support,
    emails,
    email_accounts,
    articles,
  } = me.permissions

  const allRights = me.role.code === 'DEFAULT_SUPER_ADMIN'

  useEffect(() => {
    if (allRights || support) {
      fetchUnseenTicketsCount()

      const ws = getConnector()

      if (ws) {
        ws.private(`sa.${me.id}`)
          .listen('.has.support.tickets.unseen', () => {
            fetchUnseenTicketsCount()
          })
          .listen('.seen.support.ticket', () => {
            fetchUnseenTicketsCount()
          })
      }
    }
  }, [])

  const handleToggle = () => {
    setOpen(!isOpen)
    toggleDrawer(!isOpen)
  }

  const drawerClasses = isOpen ? s.Drawer : s.DrawerHidden
  const btnClasses = isOpen ? s.Button : s.ButtonHidden
  const activeStyle = {
    backgroundColor: '#6870f1',
  }

  const links: IAdminRoute[] = useMemo(
    () => [
      {
        to: '/posts',
        icon: <Blogs />,
        title: 'Posts',
        permission: allRights || articles,
      },
      {
        to: '/users-list',
        icon: <IconUser />,
        title: 'Users',
        permission: allRights || users,
      },
      {
        to: '/companies',
        icon: <IconCompanies />,
        title: 'Companies',
        permission: allRights || companies,
      },
      {
        to: '/email',
        icon: <IconEmail />,
        title: 'Email',
        permission: allRights || emails,
      },
      {
        to: '/email-accounts',
        icon: <IconEmailAccounts />,
        title: 'Email Accounts',
        permission: allRights || email_accounts,
      },
      {
        to: '/default-prices',
        icon: <IconDefaultPrices />,
        title: 'Default Prices',
        permission: allRights,
      },
      {
        to: '/support-tickets',
        icon: <IconSupport />,
        title: 'Support',
        permission: allRights || support,
      },
      // {
      //   to: '/statistics',
      //   icon: <BarChart />,
      //   title: 'Statistics',
      //   permission: allRights,
      // },
    ],
    [],
  )

  return (
    <div className={s.DrawerBlock}>
      <button type='button' className={btnClasses} onClick={handleToggle}>
        <IconBtn />
      </button>

      <div className={drawerClasses}>
        {links.map(
          ({ to, icon, title, permission }, i) =>
            permission && (
              <NavLink
                key={i}
                className={s.Link}
                activeStyle={activeStyle}
                to={to}
              >
                {icon}
                {title}
                {title === 'Support' &&
                unseenCount.count &&
                unseenCount.count > 0 ? (
                  <div className={s.LinkCounter}>{unseenCount.count}</div>
                ) : null}
              </NavLink>
            ),
        )}
        {/*{(allRights || support) && (*/}
        {/*  <NavLink to='/posts' className={s.Link} activeStyle={activeStyle}>*/}
        {/*    <Blogs />*/}
        {/*    Posts*/}
        {/*  </NavLink>*/}
        {/*)}*/}
        {/*{(allRights || users) && (*/}
        {/*  <NavLink to='/users-list' className={s.Link} activeStyle={activeStyle}>*/}
        {/*    <IconUser />*/}
        {/*    Users*/}
        {/*  </NavLink>*/}
        {/*)}*/}
        {/*{(allRights || companies) && (*/}
        {/*  <NavLink to='/companies' className={s.Link} activeStyle={activeStyle}>*/}
        {/*    <IconCompanies />*/}
        {/*    Companies*/}
        {/*  </NavLink>*/}
        {/*)}*/}
        {/*{(allRights || emails) && (*/}
        {/*  <NavLink to='/email' className={s.Link} activeStyle={activeStyle}>*/}
        {/*    <IconEmail />*/}
        {/*    Email*/}
        {/*  </NavLink>*/}
        {/*)}*/}
        {/*{(allRights || email_accounts) && (*/}
        {/*  <NavLink to='/email-accounts' className={s.Link} activeStyle={activeStyle}>*/}
        {/*    <IconEmailAccounts />*/}
        {/*    Email Accounts*/}
        {/*  </NavLink>*/}
        {/*)}*/}
        {/*{(allRights || support) && (*/}
        {/*  <NavLink to='/support-tickets' className={s.Link} activeStyle={activeStyle}>*/}
        {/*    <IconSupport />*/}
        {/*    Support*/}
        {/*    {unseenCount.count && unseenCount.count > 0 ? (*/}
        {/*      <div className={s.LinkCounter}>{unseenCount.count}</div>*/}
        {/*    ) : null}*/}
        {/*  </NavLink>*/}
        {/*)}*/}

        {/*<NavLink to={'/default-prices'} className={s.Link} activeStyle={activeStyle}>*/}
        {/*  <IconDefaultPrices />*/}
        {/*  Default Prices*/}
        {/*</NavLink>*/}
      </div>
    </div>
  )
}

// const mapStateToProps = state => ({
// me: meSelector(state),
// unseenCount: supportUnseenTicketsSelector(state),
// })

const mapDispatchToProps = (
  dispatch: (arg0: { type: string; payload: any }) => any,
) => ({
  toggleDrawer: (value: any) => dispatch(toggleDrawer(value)),
  fetchUnseenTicketsCount: () => fetchUnseenTickets()(dispatch),
  incUnseenTickets: () => incrementUnseenTickets()(dispatch),
  decUnseenTickets: () => decrementUnseenTickets()(dispatch),
})

// Drawer.propTypes = {
//   me: PropTypes.object.isRequired,
//   toggleDrawer: PropTypes.func.isRequired,
// }

export default connect(null, mapDispatchToProps)(Drawer)
