import * as types from '../types'
import * as property from '../../../api/Property'
import { onChangeField } from './proposal/proposalForm'
// action creators
export const getItem = () => {
  return {
    type: types.property.PROPERTY_GET_ITEM,
  }
}

export const setItem = item => {
  /* const item = Array.isArray(properties)
    ? properties
    : Object.values(properties) */
  return {
    type: types.property.PROPERTY_SET_ITEM,
    item,
  }
}

export const addItem = item => {
  return {
    type: types.property.PROPERTY_ADD_ITEM,
    item,
  }
}

export const dellItem = id => {
  return {
    type: types.property.PROPERTY_DELETE_ITEM,
    id,
  }
}

export const updateItem = (id, data) => {
  return {
    type: types.property.PROPERTY_UPDATE_ITEM,
    id,
    data,
  }
}
export const setCurrentItem = item => {
  return {
    type: types.property.PROPERTY_SET_CURRENT_ITEM,
    item,
  }
}

const fetchProperties = () => ({
  type: types.property.FETCH_PROPERTIES,
})

// thunks

export const fetchPropertiesList = params => dispatch => {
  dispatch(fetchProperties())

  return property.fetchPropertiesList(params).then(response => dispatch(setItem(response)))
}

export const fetchProperty = (id, updatePropertyInItem) => dispatch => {
  return property.fetchProperty(id).then(response => {
    dispatch(setCurrentItem(response))
    if (updatePropertyInItem === 'proposal') dispatch(onChangeField(response, 'property'))
    return response
  })
}

export const searchAddress = query => dispatch => {
  return property.searchAddress(query)
}

// MAKE CHANGES IN STORE (AFTER POSITIVE RESPONSE FROM SERVER) WITHOUT LOADING NEW DATA
/* export const addProperty = (data) => dispatch => {
  property.createProperty(data).then(response => {dispatch(addItem(response))})
}

  export const deleteProperty = (id) => dispatch => {
  property.deleteProperty(id).then(response => {dispatch(dellItem(id))})
}

export const updateProperty = (id, data) => dispatch => {
  return property.updateProperty(id,data).then(response => {dispatch(updateItem(id, data))})
} */

// WITH LOADING NEW DATA FROM SERVER INTO STORE
export const addProperty = (data, pagination) => dispatch => {
  return property.createProperty(data).then(response => {
    if (pagination) dispatch(fetchPropertiesList(pagination))
    return response
  })
}

export const deleteProperty = (id, data, pagination) => dispatch => {
  return property.deleteProperty(id, data).then(() => {
    dispatch(fetchPropertiesList(pagination))
  })
}

export const updateProperty = (id, data, pagination) => dispatch => {
  return property.updateProperty(id, data).then(response => {
    if (pagination) dispatch(fetchPropertiesList(pagination))
    return response
  })
}

export const mergeProperties = (data, pagination) => dispatch => {
  return property.mergeProperties(data).then(res => {
    if (pagination) return dispatch(fetchPropertiesList(pagination))
    return res
  })
}

export const updatePropertyById = (id, data) => dispatch => {
  return property.updateProperty(id, data).then(prop => {
    dispatch(fetchProperty(id))
  })
}
