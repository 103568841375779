import * as types from '../types'

export type InitialStateType = {
  initialized: boolean
  subscription_status: number
  isProposalAndAppointmentIndustryNeeded: boolean
}

const initialState: InitialStateType = {
  initialized: false,
  subscription_status: 1,
  isProposalAndAppointmentIndustryNeeded: true,
}

export default (state = initialState, action: any): InitialStateType => {
  switch (action.type) {
    case types.app.INITIALIZED_SUCCESS:
      return { ...state, initialized: true }
    case types.app.SET_SUBSCRIPTION_STATUS:
      return !!action.subscription_status && action.subscription_status !== state.subscription_status
        ? { ...state, subscription_status: action.subscription_status }
        : state
    default:
      return state
  }
}
