import axios from 'axios'
import axiosOrcatec from './axiosInstance'
import { getQueryStringFromObject } from '../helpers/getQueryStringFromObject'

export const fetchPath = markersString => {
  return axios
    .get(
      `https://routing.openstreetmap.de/routed-car/route/v1/driving/${markersString}?overview=false&alternatives=true&steps=true`,
    )
    .then(response => response.data)
}

export const fetchOpenStreetMapAddress = query => {
  return axios
    .get(
      `https://nominatim.openstreetmap.org/search?q=${query}&countrycodes=us&format=json&addressdetails=1&limit=10&accept-language=en-US&polygon_svg=1`,
    )
    .then(response => response.data)
}

export const fetchOpenStreetMapAddressFromCoordinates = ({ lat, lng }) => {
  return axios
    .get(
      `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json&addressdetails=1&limit=10&accept-language=en-US&zoom=16&email=alexkhatuntsev.jointoit@gmail.com`,
    )
    .then(response => response.data)
}
export const fetchOpenStreetMapAddressSeparately = props => {
  const params = getQueryStringFromObject(props)
  return axios
    .get(
      `https://nominatim.openstreetmap.org/search?${
        params ? `${params}&` : ''
      }countrycodes=us&format=json&addressdetails=1&limit=10&accept-language=en-US&polygon_geojson=1`,
    )
    .then(response => response.data)
}
export const fetchOpenStreetMapAddressByOneValue = value => {
  //const params = getQueryStringFromObject(props)
  return axios
    .get(
      `https://nominatim.openstreetmap.org/search/${value}?countrycodes=us&format=json&addressdetails=1&limit=10&accept-language=en-US&dedupe=1`,
    )
    .then(response => response.data)
}

export const fetchEmployeesTitles = () => {
  return axiosOrcatec.get('map/employees/titles').then(response => response.data)
}

export const fetchEmployees = (search = '', track_type = null, title = [], date) => {
  return axiosOrcatec
    .get('map/employees', {
      params: {
        search,
        track_type,
        title,
        date,
      },
    })
    .then(response => response.data)
}

export const fetchEmployeesRoutes = (date, employees = []) => {
  return axiosOrcatec
    .get('map/routes', {
      params: {
        date,
        employees,
      },
    })
    .then(response => response.data)
}

export const fetchQueue = () => {
  return axiosOrcatec.get('map/queue').then(response => response.data)
}

export const fetchToDo = () => {
  return axiosOrcatec.get('map/todo-list').then(response => response.data)
}

export const getRoutesSettings = () => {
  return axiosOrcatec.get('/setting/routes').then(response => response.data.data)
}
export const postRoutesSettings = data => {
  return axiosOrcatec.post('/setting/routes', data).then(response => response.data.data)
}
