import axiosOrcatec from 'api/axiosInstance'
import { CompanyInfo, SignUp } from './types'

export const signUp = (data: SignUp) =>
  axiosOrcatec.post('/registration', data).then(response => response.data)

export const updateCompanyInfo = (data: CompanyInfo) =>
  axiosOrcatec.put('/company-info', data).then(response => response.data) //temp here

export const verifyEmail = params =>
  axiosOrcatec
    .get('auth/verify-email', { params })
    .then(response => response.data)

export const resendVerificationEmail = params =>
  axiosOrcatec
    .get('auth/resend-invite', { params })
    .then(response => response.data)

export const forgotPassword = email =>
  axiosOrcatec
    .post(`/forgot-password?email=${window.encodeURIComponent(email)}`)
    .then(response => response.data)

export const resetPassword = data =>
  axiosOrcatec.post(`/reset-password`, data).then(response => response.data)

export const validateResetPasswordToken = data =>
  axiosOrcatec.post(`/reset-password/validate-token`, data)

export const createPassword = data =>
  axiosOrcatec.put(`/users/create-password`, data)

export const updateCompanyLogo = data =>
  axiosOrcatec.post(`/save-company-logo/`, data)

export const AuthAPI = {
  signUp,
  updateCompanyInfo,
  verifyEmail,
  resendVerificationEmail,
  forgotPassword,
  resetPassword,
  validateResetPasswordToken,
  updateCompanyLogo,
  createPassword,
}
