import React from 'react'
import { Result, Button } from "antd"

const NotFound = ({redirectHeandler=null,errorText='Sorry, the document does not have renderer. Open the file in a new tab?'}) => {
  return (
    <Result
    subTitle={errorText}
    extra={redirectHeandler && <Button onClick={redirectHeandler} type="primary">Open</Button>}
  /> 
  )
}

export default NotFound