import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProjectAPI, ProjectTabsAPI, patchProjectSettings } from 'api/Project'
import { AppStateType } from 'store'
import { AsyncThunkAPI } from 'store/types'
import { IProjectTab, Project, ProjectSettings } from '../types'
// import { updateOldProjectFields } from 'store/Orcatec/actions/proposal/proposalForm'
import { populateProjectTabWithEntities, reorderEntities } from '../helpers'
import { projectTabSet } from './projectTabsSlice'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
// import { fetchPaymentsList, setPayments } from 'store/Orcatec/actions/payments'

const initialState: {
  status: 'idle' | 'loading' | 'updating' | 'error'
  project: Project
  isEdited: boolean
  editContract: boolean
  error: Error | null
} = {
  status: 'idle',
  project: {} as Project,
  isEdited: false,
  editContract: false,
  error: null,
}

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    projectSet: (state, { payload }: PayloadAction<Project>) => {
      const tabId = payload.proposal_tabs[0].id
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { proposal_tabs, ...rest } = payload

      state.project = {
        ...rest,
        activeTab: payload.tabs_info.findIndex(tab => tab.id === tabId),
        activeTabId: tabId,
      }
    },
    projectResetted: state => {
      state.project = {} as Project
    },
    setEditContract: (state, { payload }: PayloadAction<boolean>) => {
      state.editContract = payload
    },
    setProjectEdited: (state, { payload }: PayloadAction<boolean>) => {
      state.isEdited = payload
    },
    resetProjectSlice: state => {
      state.isEdited = false
      state.editContract = false
    },
    projectTabsOrderUpdated: (state, { payload }) => {
      state.project.tabs_info = payload
    },
    projectFieldUpdated: (
      state,
      { payload }: PayloadAction<Partial<Project>>,
    ) => {
      state.project = {
        ...state.project,
        ...payload,
      }
    },
    projectSettingsUpdated: (state, { payload }) => {
      state.project.setting = {
        ...state.project.setting,
        ...payload,
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getProject.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getProject.fulfilled, state => {
      state.status = 'idle'
    })
    builder.addCase(getProject.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })

    builder.addCase(updateProjectField.pending, state => {
      state.status = 'updating'
    })
    builder.addCase(updateProjectField.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.project = {
        ...state.project,
        ...payload,
      }
    })
    builder.addCase(updateProjectField.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })
  },
})

export const selectProjectSlice = (state: AppStateType) =>
  state.orcatec.projectSlice

export default projectSlice.reducer
export const {
  projectSet,
  projectResetted,
  setProjectEdited,
  setEditContract,
  resetProjectSlice,
  projectTabsOrderUpdated,
  projectFieldUpdated,
  projectSettingsUpdated,
} = projectSlice.actions

//Thunks

export const getProject = createAsyncThunk<
  Project,
  { projectId: number; tabId?: number }
>(
  'project/getProject',
  async ({ projectId, tabId }, { dispatch, rejectWithValue }) => {
    try {
      const project = await ProjectAPI.getProject(
        projectId,
        tabId ? { tab_id: tabId } : undefined,
      )

      dispatch(projectSet(project))

      dispatch(projectTabSet(project.proposal_tabs[0]))

      populateProjectTabWithEntities(project.proposal_tabs[0], dispatch)

      return project
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const duplicateProject = createAsyncThunk<
  void,
  { projectId: number; projectCode: string }
>(
  'project/duplicateProject',
  async ({ projectId, projectCode }, { rejectWithValue }) => {
    try {
      return await ProjectAPI.duplicateProject(projectId, projectCode)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const deleteProject = createAsyncThunk<void, number>(
  'project/deleteProject',
  async (projectId, { rejectWithValue }) => {
    try {
      return await ProjectAPI.deleteProject(projectId)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateProjectField = createAsyncThunk<
  Partial<Project>,
  Partial<Project>,
  AsyncThunkAPI
>(
  'project/updateProjectField',
  async (fieldsToUpdate, { getState, dispatch, rejectWithValue }) => {
    const projectId = getState().orcatec.projectSlice.project?.id
    if (!projectId) return

    try {
      if (!('status' in fieldsToUpdate) && !('code' in fieldsToUpdate))
        dispatch(projectFieldUpdated(fieldsToUpdate))

      const res = await ProjectAPI.updateProjectField(projectId, fieldsToUpdate)

      return res
    } catch (error) {
      // dispatch(getProject({ projectId }))

      return rejectWithValue(error.response.data)
    }
  },
)

export const reorderProjectTabs = createAsyncThunk<
  IProjectTab[],
  {
    list: IProjectTab[]
    startIndex: number
    endIndex: number
  },
  AsyncThunkAPI
>(
  'project/reorderTabs',
  async ({ list, startIndex, endIndex }, { dispatch, rejectWithValue }) => {
    const reorderedEntities = reorderEntities(
      list,
      startIndex,
      endIndex,
      'position',
    )

    try {
      dispatch(projectTabsOrderUpdated(reorderedEntities))
      dispatch(projectFieldUpdated({ activeTab: endIndex }))

      const res = await ProjectTabsAPI.reorderTabs(reorderedEntities)

      return res
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const updateProjectSettings = createAsyncThunk<
  void,
  {
    projectId: number
    field: Partial<ProjectSettings>
  }
>('project/updateSettings', async ({ projectId, field }, { dispatch }) => {
  try {
    const res = await patchProjectSettings(projectId, field)

    dispatch(projectSettingsUpdated(field))

    return res
  } catch (error) {
    console.error(error)
    openNotificationWithIcon('error', {
      message: error?.response?.data?.errors
        ? Object.values(error?.response?.data?.errors)
        : error?.response?.data?.message,
    })
  }
})

export const signProject = createAsyncThunk<
  void,
  {
    projectId: number
    data: {
      signed_tab_id: number
      signatory_name: string
      signature: string
    }
  },
  AsyncThunkAPI
>(
  'project/signProject',
  async ({ projectId, data }, { dispatch, rejectWithValue }) => {
    try {
      await ProjectAPI.setSignature(projectId, data)

      /* dispatch(
        projectFieldUpdated({
          ...data,
          signature_date,
          date_of_sale: signature_date,
          status: ProjectStatus.Contract,
        }),
      ) */
      dispatch(getProject({ projectId, tabId: data.signed_tab_id }))
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)
