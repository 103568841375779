import {
  SET_UNSEEN_TICKETS_COUNT,
  INCREMENT_UNSEEN_TICKETS_COUNT,
  DECREMENT_UNSEEN_TICKETS_COUNT,
} from '../types/supportTickets'

const initialState = {
  unseenCount: 0,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_UNSEEN_TICKETS_COUNT:
      return { ...state, unseenCount: Number(payload) }
    case INCREMENT_UNSEEN_TICKETS_COUNT:
      return { ...state, unseenCount: state.unseenCount > 0 ? state.unseenCount + 1 : 1 }
    case DECREMENT_UNSEEN_TICKETS_COUNT:
      return { ...state, unseenCount: state.unseenCount > 0 ? state.unseenCount - 1 : 0 }
    default:
      return state
  }
}
