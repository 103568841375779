import { NotesPermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'
import { ModuleName } from '../../module_permissions/types'

export const notes = {
  notes_can_read: {
    name: 'View Notes',
    with_access_scope: false,
    with_access_checkbox: true,
    control_type: UniteType.THIRD_VIEW,
    permission_key: IKey.NOTES_CAN_READ,
    parent_permission_key: IKey.NOTES_CAN_READ,
    related_modules: ModuleName.NOTES,
    description: '',
    is_module: false,
    related_key: [
      IKey.NOTES_CAN_CREATE,
      IKey.NOTES_CAN_DELETE,
      IKey.NOTES_CAN_EDIT,
    ],
    children: [
      {
        name: 'Create a New Note',
        with_access_scope: false,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: IKey.NOTES_CAN_CREATE,
        parent_permission_key: IKey.NOTES_CAN_READ,
        description: '',
        related_key: [],
      },
      {
        name: 'Edit Note',
        with_access_scope: true,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: IKey.NOTES_CAN_EDIT,
        parent_permission_key: IKey.NOTES_CAN_READ,
        description: '',
        related_key: [],
        custom_access_scope: [
          {
            key: 1,
            label: 'All Notes  ',
            activeColor: '#adf7b6',
            color: 'white',
            disabled: false,
          },
          {
            key: 2,
            label: 'Own Notes ',
            color: 'white',
            activeColor: '#adf7b6',
            disabled: false,
          },
        ],
      },
      {
        name: 'Delete Note',
        with_access_scope: true,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: IKey.NOTES_CAN_DELETE,
        parent_permission_key: IKey.NOTES_CAN_READ,
        description: '',
        related_key: [],
        custom_access_scope: [
          {
            key: 1,
            label: 'All Notes  ',
            activeColor: '#adf7b6',
            color: 'white',
            disabled: false,
          },
          {
            key: 2,
            label: 'Own Notes ',
            color: 'white',
            activeColor: '#adf7b6',
            disabled: false,
          },
        ],
      },
    ],
  },
}
