const userIdToName = (id, technicians) => {
  return technicians?.find(t => t.id === id)?.full_name || ''
}
const toFixed = (number, digitsAfterDot) => {
  const str = `${number}`
  if (str?.includes('.')) {
    return str?.slice(0, str?.indexOf('.') + digitsAfterDot + 1) || number
  }
  return number
}

export { toFixed, userIdToName }
