const TodoIcon = () => (
  <svg fill='#000000' viewBox='0 0 53 53'>
    <g>
      <path d='M44.95996,47.79982V10.65968c0-2.0459-1.66406-3.70996-3.70996-3.70996h-1.41992h-3.05975   c-0.28979-0.60651-0.90442-1.02979-1.62042-1.02979h-2.22656l-0.12646-0.28857C31.70703,3.12159,29.23535,1.50001,26.5,1.50001   c-2.74121,0-5.2168,1.62158-6.30908,4.13672l-0.12109,0.2832H17.8501c-0.716,0-1.33063,0.42328-1.62042,1.02979h-3.05975h-1.42969   c-2.04053,0-3.7002,1.66406-3.7002,3.70996v37.14014c0,2.04053,1.65967,3.7002,3.7002,3.7002H41.25   C43.2959,51.50001,44.95996,49.84034,44.95996,47.79982z M18.0498,7.94972V7.91993h2.68018   c0.40039,0,0.76221-0.23877,0.91943-0.60693l0.37793-0.88477C22.80029,4.64943,24.55566,3.50001,26.5,3.50001   c1.93848,0,3.68994,1.14941,4.46387,2.93115L31.354,7.3213c0.15967,0.36377,0.51904,0.59863,0.91602,0.59863h2.68018v0.02979   v3.12988H18.0498V7.94972z M14.16992,8.94972h1.87988v2.33008c0,0.99219,0.80762,1.7998,1.80029,1.7998h17.2998   c0.99266,0,1.80029-0.80762,1.80029-1.7998V8.94972h1.87988v36.29004H14.16992V8.94972z M10.04004,47.79982V10.65968   c0-0.94287,0.7627-1.70996,1.7002-1.70996h0.42969v37.29004c0,0.55225,0.44775,1,1,1h26.66016c0.55225,0,1-0.44775,1-1V8.94972   H41.25c0.94287,0,1.70996,0.76709,1.70996,1.70996v37.14014c0,0.9375-0.76709,1.7002-1.70996,1.7002H11.74023   C10.80273,49.50001,10.04004,48.73732,10.04004,47.79982z'></path>
      <path d='M20.75439,15.43312h-4.08008c-0.51025,0-0.91016,0.3999-0.91016,0.91016v4.08008c0,0.51025,0.3999,0.91016,0.91016,0.91016   h4.08008c0.51025,0,0.90967-0.3999,0.90967-0.91016v-4.08008C21.66406,15.83302,21.26465,15.43312,20.75439,15.43312z    M20.66406,20.33351h-3.8999v-3.90039h3.8999V20.33351z'></path>
      <path d='M36.37158,16.36866h-12.7207c-0.46582,0-0.84521,0.3833-0.84521,0.85498v2.32959   c0,0.46582,0.37939,0.84473,0.84521,0.84473h12.7207c0.47168,0,0.85498-0.37891,0.85498-0.84473v-2.32959   C37.22656,16.75196,36.84326,16.36866,36.37158,16.36866z M36.22656,19.39796h-12.4209v-2.0293h12.4209V19.39796z'></path>
      <path d='M20.75439,22.57667h-4.08008c-0.51025,0-0.91016,0.3999-0.91016,0.91016v4.08008c0,0.51025,0.3999,0.90967,0.91016,0.90967   h4.08008c0.51025,0,0.90967-0.39941,0.90967-0.90967v-4.08008C21.66406,22.97657,21.26465,22.57667,20.75439,22.57667z    M20.66406,27.47657h-3.8999v-3.8999h3.8999V27.47657z'></path>
      <path d='M36.37158,23.51173h-12.7207c-0.46582,0-0.84521,0.38379-0.84521,0.85547v2.3291   c0,0.46582,0.37939,0.84521,0.84521,0.84521h12.7207c0.47168,0,0.85498-0.37939,0.85498-0.84521v-2.3291   C37.22656,23.89552,36.84326,23.51173,36.37158,23.51173z M36.22656,26.54152h-12.4209v-2.02979h12.4209V26.54152z'></path>
      <path d='M20.75439,36.86378h-4.08008c-0.51025,0-0.91016,0.39941-0.91016,0.90967v4.08008   c0,0.51025,0.3999,0.91016,0.91016,0.91016h4.08008c0.51025,0,0.90967-0.3999,0.90967-0.91016v-4.08008   C21.66406,37.2632,21.26465,36.86378,20.75439,36.86378z M20.66406,41.76368h-3.8999v-3.8999h3.8999V41.76368z'></path>
      <path d='M36.37158,37.79884h-12.7207c-0.46582,0-0.84521,0.3833-0.84521,0.85498v2.32959   c0,0.46582,0.37939,0.84521,0.84521,0.84521h12.7207c0.47168,0,0.85498-0.37939,0.85498-0.84521v-2.32959   C37.22656,38.18214,36.84326,37.79884,36.37158,37.79884z M36.22656,40.82862h-12.4209v-2.02979h12.4209V40.82862z'></path>
      <path d='M20.75439,29.72023h-4.08008c-0.51025,0-0.91016,0.3999-0.91016,0.91016v4.07959c0,0.51025,0.3999,0.91016,0.91016,0.91016   h4.08008c0.51025,0,0.90967-0.3999,0.90967-0.91016v-4.07959C21.66406,30.12013,21.26465,29.72023,20.75439,29.72023z    M20.66406,34.62013h-3.8999v-3.8999h3.8999V34.62013z'></path>
      <path d='M36.37158,30.65528h-12.7207c-0.46582,0-0.84521,0.38379-0.84521,0.85547v2.3291   c0,0.46582,0.37939,0.84521,0.84521,0.84521h12.7207c0.47168,0,0.85498-0.37939,0.85498-0.84521v-2.3291   C37.22656,31.03907,36.84326,30.65528,36.37158,30.65528z M36.22656,33.68507h-12.4209v-2.02979h12.4209V33.68507z'></path>
      <path d='M30.09229,6.60304c-0.50391-1.17041-1.49805-2.0376-2.65869-2.31836c-1.82666-0.4458-3.75049,0.51465-4.49023,2.22363   l-0.13135,0.30615C22.7002,7.07814,22.729,7.38038,22.88867,7.62208c0.15918,0.24072,0.42676,0.38428,0.71484,0.38428h5.78516   c0.2876,0,0.55469-0.14355,0.71387-0.38379c0.16016-0.2417,0.18945-0.54346,0.07861-0.80469L30.09229,6.60304z M23.81787,7.00636   l0.04395-0.10254c0.54883-1.26709,1.96582-1.98096,3.33545-1.64746c0.85742,0.20752,1.59619,0.85889,1.97412,1.73633   l0.00537,0.01367H23.81787z'></path>
    </g>
  </svg>
)

export default TodoIcon
