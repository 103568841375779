import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IProjectItemsGroup } from '../types'
import { ProjectGroupsAPI } from 'api/Project'
import { AsyncThunkAPI } from 'store/types'
import { Error } from 'types/Error'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { resetGroupItems } from './projectItemsSlice'
import { AppStateType } from 'store'

const initialState: {
  status: 'idle' | 'loading' | 'error'
  groups: Record<number, IProjectItemsGroup>
  error: Error | null
} = {
  status: 'idle',
  groups: {},
  error: null,
}

const projectGroupsSlice = createSlice({
  name: 'projectGroups',
  initialState,
  reducers: {
    itemsGroupsSet: (state, { payload }) => {
      state.groups = {
        ...state.groups,
        ...transformArrayToObj(payload),
      }
    },
    itemsGroupsReordered: (
      state,
      { payload }: PayloadAction<IProjectItemsGroup[]>,
    ) => {
      state.groups = {
        ...state.groups,
        ...transformArrayToObj(payload),
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(addItemsGroup.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(addItemsGroup.fulfilled, (state, { payload }) => {
      if (!payload.id) return

      state.status = 'idle'
      state.groups = {
        ...state.groups,
        [payload.id]: {
          ...payload,
          isNew: true,
        },
      }
    })
    builder.addCase(addItemsGroup.rejected, (state, action) => {
      state.error = action.payload
      state.status = 'error'
    })
    ////
    builder.addCase(updateItemsGroup.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateItemsGroup.fulfilled, (state, { payload }) => {
      if (!payload.id) return

      state.status = 'idle'
      state.groups[payload.id] = { ...payload, isNew: false }
    })
    builder.addCase(updateItemsGroup.rejected, (state, action) => {
      state.error = action.payload
      state.status = 'error'
    })
    ////
    builder.addCase(deleteItemsGroup.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteItemsGroup.fulfilled, (state, action) => {
      if (!action.meta.arg) return

      state.status = 'idle'
      delete state.groups[action.meta.arg]
    })
    builder.addCase(deleteItemsGroup.rejected, (state, action) => {
      state.error = action.payload
      state.status = 'error'
    })
  },
})

export default projectGroupsSlice.reducer

export const {
  itemsGroupsSet,
  itemsGroupsReordered,
} = projectGroupsSlice.actions

export const addItemsGroup = createAsyncThunk<
  IProjectItemsGroup,
  IProjectItemsGroup,
  AsyncThunkAPI
>('projectGroups/addGroup', async (group, { rejectWithValue, getState }) => {
  const groups = Object.values(getState().orcatec.projectGroupsSlice.groups)

  const position = groups.length
    ? Math.max(...groups.map(group => group.position)) + 1
    : 0

  try {
    const res = await ProjectGroupsAPI.addGroup({
      ...group,
      position,
    })

    return res
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const updateItemsGroup = createAsyncThunk<
  IProjectItemsGroup,
  IProjectItemsGroup,
  AsyncThunkAPI
>('projectGroups/updateGroup', async (group, { rejectWithValue }) => {
  try {
    const res = await ProjectGroupsAPI.updateGroup(group)

    return res
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const deleteItemsGroup = createAsyncThunk<void, number, AsyncThunkAPI>(
  'projectGroups/deleteGroup',
  async (groupId, { rejectWithValue, dispatch }) => {
    try {
      const res = await ProjectGroupsAPI.deleteGroup(groupId)

      dispatch(resetGroupItems(groupId))

      return res
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

//Selectors

export const selectGroupSlice = (state: AppStateType) =>
  state.orcatec.projectGroupsSlice
