import React, { SetStateAction, useState, Dispatch } from 'react'
import { Skeleton, Image, Spin, Divider, Tooltip, Checkbox } from 'antd'
import { IData } from '../ModalLogSheets'
import { fullLink } from '../utils'
import {
  ItemList,
  NotificationItem,
  NotificationRow,
  SkeletonWrapper,
} from '../ModalLogSheets.styles'
import {
  EyeIcon1,
  ClientIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { LoadingOutlined } from '@ant-design/icons'
import { Warning } from '@material-ui/icons'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

interface IProps {
  data: IData[]
  loading: boolean
  chooseItemsHandler: (id: number) => void
  chosenItems: number[]
  apptIds: number[]
  onMakePreviewHandler: (item: IData) => Promise<any>
  setChosenItems: Dispatch<SetStateAction<number[]>>
  createPdfLoading: { visible: boolean; id: null | number }
}
export const Forms = ({
  data,
  loading,
  chooseItemsHandler,
  chosenItems,
  apptIds,
  onMakePreviewHandler,
  setChosenItems,
  createPdfLoading,
}: IProps) => {
  const [visible, setVisible] = useState<{
    visible: boolean
    id: number | null
  }>({ visible: false, id: null })

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setChosenItems(apptIds)
    } else {
      setChosenItems([])
    }
  }
  const onPreview = (item: IData) => {
    onMakePreviewHandler(item).then(() =>
      setVisible({ visible: true, id: item?.appointment_id }),
    )
  }

  const EyeIconLoading = ({ item }: IData) => {
    return (
      <div onClick={() => onPreview(item)}>
        {createPdfLoading?.visible &&
        createPdfLoading?.id === +item?.appointment_id ? (
          <Spin indicator={antIcon} />
        ) : (
          <EyeIcon1 />
        )}
      </div>
    )
  }

  const renderContacts = () => {
    return data
      ?.flatMap(item => item?.contacts?.map(contact => ({ ...contact, item })))
      ?.sort((a, b) => a?.name.localeCompare(b?.name))
      ?.map(contact => (
        <>
          <NotificationItem>
            <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
              <Checkbox
                checked={chosenItems?.includes(contact?.item?.appointment_id)}
                name='send'
                onChange={() =>
                  chooseItemsHandler(contact?.item?.appointment_id)
                }
              />
              <ClientIcon />
            </div>
            <NotificationRow>
              <div>
                <p>
                  {contact?.name} - {contact.title}{' '}
                  {!contact?.phone && (
                    <Tooltip title='Missing phone number. SMS notification will not be delivered.'>
                      <Warning color='primary' />
                    </Tooltip>
                  )}
                </p>
                {<p>{contact?.phone}</p>}
              </div>
              <Tooltip placement='right' title='Show Form'>
                <EyeIconLoading item={contact.item} />
              </Tooltip>
              {visible.visible && visible.id === contact?.item?.appointment_id && (
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  src={fullLink(contact?.item?.form?.file_path)}
                  preview={{
                    visible: visible.visible,
                    src: fullLink(contact?.item?.form?.file_path),
                    onVisibleChange: value => {
                      setVisible({ visible: value, id: null })
                    },
                  }}
                />
              )}
            </NotificationRow>
          </NotificationItem>
          <Divider style={{ margin: '4px 0' }} />
        </>
      ))
  }

  return (
    <ItemList>
      {loading ? (
        apptIds.map(e => (
          <SkeletonWrapper key={e}>
            <Skeleton.Avatar active={true} size={'default'} shape={'circle'} />
            <Skeleton.Input active={true} size={'default'} />
          </SkeletonWrapper>
        ))
      ) : (
        <>
          <Checkbox
            indeterminate={
              chosenItems?.length < apptIds.length && chosenItems?.length > 0
            }
            onChange={onCheckAllChange}
            checked={!!chosenItems?.length}
          >
            Check all
          </Checkbox>

          {renderContacts()}
        </>
      )}
    </ItemList>
  )
}
