import { Component } from 'react'

import Layout from 'layouts/MainLayout/Layout'
import MainBuilder from './containers/mainbuilder/mainbuilder'
import { withRouter } from 'react-router-dom'
import 'antd/dist/antd.css'
import '../src/styles/common.scss'
import { compose } from 'redux'
import * as actions from './store/Orcatec/actions'
import { connect } from 'react-redux'
import { refreshToken } from './api/Auth'
// import { isUserFullSettings } from './helpers/isUserFullSettings'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment-timezone'
import ReactGA from 'react-ga4'
import pack from '../package.json'
// ADMIN APP
import AdminApp from './adminModule/'
import { makeBrowserTitle } from 'helpers/makeBrowserTitle'
import { createTheme, ThemeProvider } from '@material-ui/core'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import * as Sentry from '@sentry/react'
import GlobalStyles, {
  BUTTON_COLORS,
  FONT_COLORS,
} from '../src/styles/globalStyles'
import createSocketConnection from 'services/SocketService'

import { CallsProvider } from 'features/Calls/components/CallsProvider/CallsProvider'
import { fetchCompany } from 'api/Company'
import { dispatchSettingsUpdated } from 'features/Dispatch/dispatchSlice'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import {
  progressSet,
  statusChanged,
} from 'features/Settings/QuickBooks/quickBooksSlice'
import {
  fetchAllItems,
  fetchItemsByCategory,
  getImportStatus,
  importStatusUpdated,
} from 'features/Items/pricePagesSlice'
import { getValueFromLS } from 'hooks/useLocalStorage'
import axiosOrcatec from 'api/axiosInstance'
import { selectAuth } from 'features/Auth/authSlice'
import { fetchUserPermissions } from 'features/Settings/UsersAndGroups/permissionSlice'

moment.updateLocale('en', {
  week: {
    dow: 0,
  },
})

export let globalHistory = null

String.prototype.replaceAll =
  String.prototype.replaceAll ||
  function(searchValue, replaceValue) {
    let string = this
    while (string.indexOf(searchValue) > 0) {
      string = string.replace(searchValue, replaceValue)
    }
    return string
  }

const theme = createTheme({
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  /* overrides: {
    MuiInputLabel: {
      root: {
        fontSize: '0.8rem',
      },
    },
  }, */
})

const styledTheme = {
  font: FONT_COLORS,
  button: BUTTON_COLORS,
}

const refreshCacheAndReload = async () => {
  if (document.visibilityState !== 'visible') return

  console.log('Clearing cache and local storage and hard reloading...')

  // Service worker cache should be cleared with caches.delete()
  if (caches) {
    caches.keys().then(names => {
      for (let name of names) caches.delete(name)
    })
  }
  // localStorage?.clear?.()

  // delete browser cache and hard reload
  window.location.reload(true)
}

class App extends Component {
  state = {
    showModal: false,
    showModalCount: 0,
    font: 'Roboto',
  }

  componentDidMount() {
    // getConnector()
    // createSocketConnection()

    makeBrowserTitle({ customTitle: '', prefixForTitle: '' })
    const matchUrl = window.location.pathname

    axiosOrcatec.defaults.headers['X-page'] = makeBrowserTitle({
      customTitle: '',
      prefixForTitle: '',
    })

    if (
      matchUrl.includes('/login/create-password') ||
      matchUrl.includes('/login/credentials-changed') ||
      matchUrl.includes('/login/reset-password') ||
      this.props.location.search.includes('signin')
    ) {
      return this.props.userLogout()
    }

    globalHistory = this.props.history
    /* const isInSystem =
      !/\/register/.test(window.location.pathname) &&
      !/\/signin/.test(window.location.pathname) */

    const isInSystem = !window.location.pathname.includes('/auth')

    if (
      window.location.pathname !== '/preview' &&
      !window.location.pathname.includes('/custom-forms') &&
      window.location.pathname !== '/reviews/review' &&
      window.location.pathname !== '/public-appointments' &&
      window.location.pathname !== '/print-appointment' &&
      !window.location.pathname.includes('/public/events')
    ) {
      // const authData = localStorage.getItem('auth')
      //   ? JSON.parse(localStorage.getItem('auth'))
      //   : null
      const authData = this.props.auth

      if (authData) {
        if (authData.rememberMe) {
          const currentDate = Date.now() / 1000
          if (authData.expTime <= currentDate) {
            refreshToken()
              .then(resp => {
                resp.data.rememberMe = authData.rememberMe
                resp.data.expTime = Date.now() / 1000 + resp.expires_in
                localStorage.setItem('auth', JSON.stringify(resp.data))
                sessionStorage.setItem('auth', JSON.stringify(resp.data))
                return resp
              })
              .then(() => this.props.initializeApp())
              .catch(err => console.error(err))
          } else {
            this.props.initializeApp()
          }
        } else {
          const currentDate = Date.now() / 1000
          if (authData.expTime <= currentDate) {
            localStorage.removeItem('auth')
            this.props.history.push('/')
          } else {
            this.props.initializeApp()
          }
        }
      } else {
        if (this.props.location.pathname.includes('subscription')) {
          localStorage.setItem('moveTo', this.props.location.pathname)
        }

        if (isInSystem) {
          this.props.history.push('/')
        }
      }
    }
  }

  /*  initSocketConnection = (authData, initialized) => {
    if (initialized) {
      const token = authData ? authData.token : ''
      if (token)
        window.Echo = new Echo({
          broadcaster: 'socket.io',
          host: process.env.REACT_APP_SOCKET_URL,
          path: process.env.REACT_APP_SOCKET_PATH,
          auth: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        })
    } else {
      window.Echo = null
    }
  } */

  listenForMeWasDeleted = () => {
    window.Echo?.private('channel-user.' + this.props.ownerId).listen(
      'RemoveUserEvent',
      e => {
        if (+this.props.me.id === +e.data.id) {
          window.localStorage.removeItem('auth')
          document.cookie = 'orcatecJWT= ;'
          this.props.history.push('/auth/signin')
          this.props.userLogout()
        }
      },
    )
  }

  listenToPageRefresh = () => {
    window.Echo?.private('cache-refresh.' + this.props.me.id).listen(
      'RunRefreshCacheToClient',
      event => {
        if (process?.env?.NODE_ENV !== 'development') {
          console.log('Cache refresh', event)
          refreshCacheAndReload()
        }
      },
    )
  }

  listentForTimeCardStatusChanged = () => {
    window.Echo?.private('time-card-user.' + this.props.ownerId).listen(
      'TimeCardsStatusEvent',
      res => {
        this.props.getUserStatus()
      },
    )
  }

  quickbooksListener = () => {
    window.Echo?.private('uploading-data.' + this.props.me.id)
      .listen('Quickbooks\\EndOfUploadToQuickbooksEvent', ({ data }) => {
        openNotificationWithIcon(data.uploaded ? 'success' : 'error', {
          message: 'Quickbooks export',
          description: data.message,
          key: 'quickbooks-export',
        })
        this.props.quickbooksStatusChanged('finished')
      })
      .listen('Quickbooks\\ProgressUploadDataEvent', ({ data }) => {
        this.props.quickbooksProgressSet(data.progress)
      })
  }

  importItemsListener = () => {
    window.Echo?.private(
      `company-message.${this.props.company.id}.${this.props.me.id}`,
    ).listen('.price-book.imported', ({ status }) => {
      // this.props.importItemStatusChanged({
      //   status,
      // })
      this.props.getItemsImportStatus()

      const companySettings = getValueFromLS(this.props.company.id)
      const itemsTable = getValueFromLS('items-table_v1')
      const generalTable = getValueFromLS('general-items-table_v1')

      companySettings?.[this.props.me.id]?.pricePagesView === 1
        ? this.props.getItemsListByCategory({
            search: itemsTable?.search || undefined,
            category_id: this.props.pricePagesCategory?.id || undefined,
          })
        : this.props.getItemsGeneralList({
            search: generalTable?.search || undefined,
          })

      openNotificationWithIcon(
        status === 'completed' ? 'success' : 'error',
        {
          message: 'Upload Price Pages',
          description: `Import ${status}`,
        },
        10,
        null,
        false,
        () => this.props.history.push('/settings/items'),
      )
    })

    window.Echo?.private('company-message.' + this.props.company.id).listen(
      '.price-book.import-progress',
      ({ progress }) => {
        this.props.importItemStatusChanged({
          progress,
        })
      },
    )
  }

  todoTaskCounterChangeListener = () => {
    window.Echo?.private('auth-user.' + this.props.me.id).listen(
      'CountUnreadTasksEvent',
      ({ data }) => {
        this.props.updateUnreadTaskCounter(data.countUnreadTasks)
      },
    )
  }

  // incomingCallListener = () => {
  //   const COMPANY_CALL_CHANNEL = `company-message.${this.props.company.id}`
  //   const CALL_INCOME_EVENT = '.call.incoming'
  //   window.Echo?.private(COMPANY_CALL_CHANNEL).listen(CALL_INCOME_EVENT, ({ incoming_call }) => {
  //     const isNotEmpty = Object.keys(incoming_call).length
  //     if (isNotEmpty) {
  //       callNotification(incoming_call)
  //     }
  //   })
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      window.location.pathname !== '/preview' &&
      window.location.pathname !== '/reviews/review'
    ) {
      globalHistory = this.props.history
      // const isFromLogin =
      // prevProps.location.pathname !== this.props.location.pathname &&
      // prevProps.location.pathname === '/auth/signin'
      const isFromLogin = ['/auth/signin', '/auth/signup'].includes(
        prevProps.location.pathname,
      )
      // const isInSystem =
      //   !/\/register/.test(window.location.pathname) &&
      //   !/\/login/.test(window.location.pathname)
      const isInSystem = !window.location.pathname.includes('auth')
      const isFromSystem =
        prevProps.location.pathname !== this.props.location.pathname &&
        prevProps.location.pathname !== '/' &&
        !/\/register/.test(prevProps.location.pathname) &&
        !/\/login/.test(prevProps.location.pathname)
      const authData = localStorage.getItem('auth')
        ? JSON.parse(localStorage.getItem('auth'))
        : null
      ///////////////////SOCKET-CONNECTION/////////////////
      if (
        prevProps.initialized !== this.props.initialized &&
        this.props.initialized
      ) {
        if (!this.props.me.is_admin) {
          // this.initSocketConnection(authData, this.props.initialized)
          createSocketConnection(authData.token)
          this.listenForMeWasDeleted()
          this.listentForTimeCardStatusChanged()
          this.todoTaskCounterChangeListener()
          // this.listenToPageRefresh()
          this.quickbooksListener()
          this.importItemsListener()
          // this.uploadItemsListener()

          fetchCompany().then(data =>
            this.props.dispatchSettingsUpdated({
              start_hour: data.settings_matrix.start_hour,
              end_hour: data.settings_matrix.end_hour,
              event_color_type: data.settings_matrix.event_color_type,
              matrix_address_type: data.settings_matrix.matrix_address_type,
              tooltip_mod: data.settings_matrix.tooltip_mod,
              restrict_crud_source_type:
                data.settings_matrix.restrict_crud_source_type,
            }),
          )

          this.props.getUserStatus()

          const lc = JSON.parse(localStorage.getItem(this.props.me.parent_id))
          if (!lc?.[this.props.me.id]?.timezone) {
            localStorage.setItem(
              this.props.me.parent_id,
              JSON.stringify({
                ...lc,
                [this.props.me.id]: {
                  ...lc?.[this.props.me.id],
                  timezone: moment.tz.guess(),
                },
              }),
            )
          }
        }
      }
      ///////////////////END-SOCKET-CONNECTION/////////////////
      // if (!this.props.me.is_admin) {
      // if (prevState.showModal !== this.state.showModal) {
      //   if (this.state.showModal === false) {
      //     interval = setInterval(() => this.check(this.props.initialized), 50000)
      //   } else {
      //     clearInterval(interval)
      //   }
      // }
      // if (prevProps.timeType !== this.props.timeType) {
      //   if (this.props.timeType === 1) {
      //     interval = setInterval(() => this.check(this.props.initialized), 50000)
      //   } else {
      //     clearInterval(interval)
      //   }
      // }
      // }

      if (prevProps.me.role_id !== this.props.me.role_id) {
        const isUserWasHere = this.props.me?.is_admin
          ? localStorage.getItem(`${this.props.me.id}`)
          : localStorage.getItem(`${this.props.me.parent_id}`)
        // const lastModuleVisited = isUserWasHere
        //   ? JSON.parse(isUserWasHere)?.[this.props.me.id]?.lastModuleVisited
        //   : false

        const moveTo = localStorage.getItem('moveTo')

        let lastModuleVisited =
          moveTo && !this.props.me?.is_admin
            ? moveTo
            : isUserWasHere &&
              !JSON.parse(isUserWasHere)?.[
                this.props.me.id
              ]?.lastModuleVisited?.includes('auth')
            ? JSON.parse(isUserWasHere)?.[this.props.me.id]?.lastModuleVisited
            : false

        if (moveTo) localStorage.removeItem('moveTo')

        if (authData) {
          if (!this.props.me.is_admin) {
            if ((isInSystem && isFromLogin) || !isInSystem) {
              this.props.fetchUserPermissions(this.props.me.id).then(() => {
                if (lastModuleVisited) {
                  this.props.history.push(lastModuleVisited)
                } else {
                  if (
                    this.props?.permissions?.current?.dispatch
                      ?.dispatch_can_read
                  )
                    this.props.history.push('/dispatch')
                  else if (
                    this.props?.permissions?.current?.project?.project_can_read
                  )
                    this.props.history.push('/projects')
                  else if (
                    this.props?.permissions?.current?.schedule
                      ?.schedule_can_read &&
                    this.props.me.useAsTech
                  )
                    this.props.history.push('/schedule')
                  else if (
                    this.props?.permissions?.current?.contacts
                      ?.contacts_list_can_read
                  )
                    this.props.history.push('/clients')
                  else if (
                    this.props?.permissions?.current?.forms?.forms_list_can_read
                  )
                    this.props.history.push('/custom-form')
                  else this.props.history.push('/properties')
                }
              })
            }
          } else {
            if (
              this.props.me.role.code !== 'DEFAULT_SUPER_ADMIN' &&
              !this.props.me.password_changed_at
            ) {
              this.props.history.push('/change-password')
            } else if (
              this.props.me.role.code === 'ADMIN__ACCOUNT_MANAGER' &&
              !!this.props.me.password_changed_at &&
              (!this.props.me.account_manager ||
                (!!this.props.me.account_manager &&
                  !this.props.me.account_manager.doc_signed))
            ) {
              this.props.history.push('/acc_manager-onboarding')
            } else {
              if (lastModuleVisited) {
                this.props.history.push(lastModuleVisited)
              } else {
                this.props.history.location.pathname === '/auth/signin' &&
                  this.props.history.push('/companies')
              }
            }
          }
        } else {
          if (isInSystem) {
            this.props.history.push('/')
          }
        }
      }

      /** SETTER OF LAST VISITED MODULE */
      if (prevProps.location.pathname !== this.props.location.pathname) {
        /** IF NEW PATHNAME */
        if (isInSystem) {
          /** SEND CURRENT LOCATION TO GA */
          ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname,
            title: makeBrowserTitle({ customTitle: '', prefixForTitle: '' }),
          })
          /** IF IN SYSTEM */
          if (this.props.me.parent_id) {
            /** IF USER ROLE */
            if (this.props.location.pathname.includes('/404/')) {
              /** IF LOCATION SOME OF 404 PAGES WE DONT SET IN TO LAST MODULE VISITED */
              return
            }
            const lc = JSON.parse(localStorage.getItem(this.props.me.parent_id))

            if (!this.props.location.pathname.includes('/auth')) {
              localStorage.setItem(
                this.props.me.parent_id,
                JSON.stringify({
                  ...lc,
                  [this.props.me.id]: {
                    ...lc?.[this.props.me.id],
                    lastModuleVisited: this.props.location.pathname,
                  },
                }),
              )
            }
          } else {
            /** IF ADMIN ROLE */
            localStorage.setItem(
              this.props.me.id,
              JSON.stringify({
                [this.props.me.id]: {
                  lastModuleVisited: this.props.location.pathname,
                  timezone: moment.tz.guess(),
                },
              }),
            )
          }
        } else {
          if (isFromSystem) {
            window.localStorage.removeItem('auth')
            document.cookie = 'orcatecJWT= ;'
            this.props.userLogout()
          }
        }
      }

      /* if (!/\/proposal\/preview\//.test(window.location.pathname)) {
        sessionStorage.removeItem('proposalState')
      } */

      /*  if (this.props.timeToSendLastActive == 0 && this.props.timeType === 1) {
         sendLastActiveTime({ time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss') })
       }
 
       if (prevProps.timeType !== this.props.timeType) {
         this.props.timeType === 2 ? this.props.stopIdleTimer() : this.props.resumeIdleTimer()
       } */
    }

    Sentry.setUser({
      username: this.props.me?.full_name,
      id: this.props.me?.id,
      company_name: this.props.company?.company_name,
      company_id: this.props.company?.id,
      app_version: pack?.version || 'unknown',
    })

    makeBrowserTitle({ customTitle: '', prefixForTitle: '' })

    axiosOrcatec.defaults.headers['X-page'] = makeBrowserTitle({
      customTitle: '',
      prefixForTitle: '',
    })
  }

  /*   check(authData) {
    if (authData) {
      if (mouse.screenX === newMouse.screenX && mouse.screenY === newMouse.screenY) {
        showModalCount += 1
        //////////10 sec * index - 1    example: index_of_30_min = 179, index_of_2_hrs = 719  ###### 50 SEC!!!!!!!! index = x/5
        const x = this.props.me.role_id === 3 ? 4 : 5
        if (showModalCount > x) this.setState({ showModal: true })
      } else {
        mouse = { ...newMouse }
        sendLastActiveTime({ time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss') })
        showModalCount = 0
      }
    }
  } */

  componentWillUnmount() {
    // clearInterval(interval)
    // window.Echo.leave('private-time-card-user.' + this.props.ownerId)
    window?.Echo?.leave('uploading-data.' + this.props.me.id)
  }

  render() {
    const { is_admin } = this.props.me
    return !is_admin ? (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CallsProvider
            permissions={this.props.permissions}
            me={this.props.me}
            company={this.props.company}
          >
            <Layout
              permissions={this.props.permissions}
              initialized={this.props.initialized}
              me={this.props.me}
            >
              <StyledThemeProvider theme={styledTheme}>
                <GlobalStyles font={'Roboto'} />
                <MainBuilder
                  initialized={this.props.initialized}
                  me={this.props.me}
                  subscriptionOff={this.props.subscriptionOff}
                  history={this.props.history}
                  company={this.props.company}
                  permissions={this.props.permissions.current}
                />
              </StyledThemeProvider>
            </Layout>
          </CallsProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    ) : (
      <AdminApp me={this.props.me} />
    )
  }
}

const mapStateToProps = state => ({
  auth: selectAuth(state),
  initialized: state.orcatec.app.initialized,
  me: state.orcatec.user.me,
  permissions: state.orcatec.permissions,
  company: state.orcatec.company,
  user: state.orcatec.user,
  timeType: state.orcatec?.timecards?.currentTimeType?.timeType,
  ownerId: state.orcatec.company ? state.orcatec.company.owner_id : null,
  subscriptionOff:
    !!state.orcatec.company && !!state.orcatec.company.subscription_off,
  pricePagesCategory: state.orcatec.pricePagesSlice.choosenCategory,
})

export default compose(
  withRouter,
  // withIdle,
  connect(mapStateToProps, {
    setUserClockInOrOut: actions.timecards.setUserClockInOrOut,
    initializeApp: actions.app.initializeApp,
    userLogout: actions.app.logout,
    setUserClockOut: actions.timecards.setUserClockOut,
    setTimeType: actions.timecards.setTimeType,
    getUserStatus: actions.timecards.fetchUserCurrentTimeStatus,
    updateUnreadTaskCounter: actions.user.updateUnreadTaskCounter,
    dispatchSettingsUpdated: dispatchSettingsUpdated,
    quickbooksStatusChanged: statusChanged,
    quickbooksProgressSet: progressSet,
    importItemStatusChanged: importStatusUpdated,
    getItemsImportStatus: getImportStatus,
    getItemsGeneralList: fetchAllItems,
    getItemsListByCategory: fetchItemsByCategory,
    fetchUserPermissions: fetchUserPermissions,
  }),
)(App)
