import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Button, Select } from 'antd'
import { connect } from 'react-redux'
import * as actions from '../../../../../../store/Orcatec/actions'

const ModalCreateProposal = props => {
  const [loading, changeLoading] = useState(false)

  return (
    <Modal
      title='Select Email'
      visible={props.show}
      onOk={() => props.onSubmit()}
      okText='Confirm'
      confirmLoading={loading}
      onCancel={() => {
        props.onCancel()
      }}
      cancelButtonProps={{ type: 'danger' }}
    >
      <div></div>
    </Modal>
  )
}

const mapStateToProps = state => {
  return {
    proposalForm: state.orcatec.proposalForm,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalCreateProposal),
)
