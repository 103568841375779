import React, { useEffect, useRef, useState } from 'react'
import CanvasDraw from 'react-canvas-draw'

import { Checkbox } from 'antd'

import { Modal } from 'react-bootstrap'

import { Link } from 'react-router-dom'
import TermAndConditionsLink from '../../../../components/TermAndConditionsLink/TermAndConditionsLink'
import MainButton from '../../../../components/buttons/MainButton'

import './styles.scss'
import { TextField } from '@material-ui/core'
import styled from 'styled-components'

const initialErrors = {
  name: false,
  terms: false,
  signature: false,
}

const ModalSignature = ({
  show,
  close,
  onSubmit,
  termFile,
  isProposalSigning,
  title = 'Project acceptance',
  canvasWidth = '400px',
  dialogClassName = '',
  withoutModal = false,
  required = false,
  setIsSignatureFill,
  setSignature,
  setTermsAgreed,
  ingoreErrors,
}) => {
  const [canSave, setCanSave] = useState(false)
  const [isSignature, setIsSignature] = useState(false)
  const [signatoryName, setSignatoryName] = useState('')
  const [isAgreedTerms, setIsAgreedTerms] = useState(!termFile)
  const [errors, setErrors] = useState(initialErrors)

  const takeSignature = () => {
    getBase64()
  }

  const handleCancelAcceptance = () => {
    setSignatoryName('')
    setIsSignature(false)
    setIsAgreedTerms(false)
    close()
  }

  useEffect(() => {
    if (withoutModal) {
      if (isAgreedTerms) {
        setTermsAgreed(true)
      } else {
        setTermsAgreed(false)
      }
    }
  }, [isAgreedTerms])

  useEffect(() => {
    if (setIsSignatureFill) {
      if (isSignature) {
        setIsSignatureFill(true)
      } else {
        setIsSignatureFill(false)
      }
    }
  }, [isSignature])

  useEffect(() => {
    if (!termFile) setIsAgreedTerms(true)
  }, [show, termFile])

  useEffect(() => {
    if (!show && canSave) {
      setCanSave(false)
    }
  }, [show, canSave])
  useEffect(() => {
    if (
      signatoryName.replace(/\s+/g, '')?.length &&
      isAgreedTerms &&
      isSignature
    ) {
      setCanSave(true)
    } else {
      setCanSave(false)
    }
  }, [signatoryName, isSignature, isAgreedTerms])

  useEffect(() => {
    if (!show) {
      setSignatoryName('')
      setIsSignature(false)
      setIsAgreedTerms(false)
      setErrors(initialErrors)
    }
  }, [show])

  const validateSignatureForm = (name, signature, terms) => {
    let validationErrors = { ...errors }

    if (!name?.trim()) validationErrors.name = true
    if (!signature) validationErrors.signature = true
    if (!!termFile && !terms) validationErrors.terms = true

    return validationErrors
  }

  const getBase64 = () => {
    const errors = validateSignatureForm(
      signatoryName,
      isSignature,
      isAgreedTerms,
    )

    if ((errors.name || errors.signature || errors.terms) && !ingoreErrors) {
      return setErrors(errors)
    }

    const signatureContainer = document.querySelector(
      'div.signature-canvas-container',
    )
    const canvas = signatureContainer.getElementsByTagName('canvas')
    let localImages = []
    for (let c of canvas) {
      localImages.push(c.toDataURL())
    }
    if (withoutModal) {
      setSignature(localImages[1])
    } else {
      onSubmit(localImages[1], signatoryName)
    }
  }

  return !withoutModal ? (
    <Modal
      show={show}
      onHide={close}
      backdrop='static'
      className='sinature-modal'
      dialogClassName={dialogClassName}
    >
      <Modal.Body>
        <div className='d-flex flex-column align-items-center'>
          <center>
            <span className='sinature-modal-title'>{title}</span>
          </center>
          <div style={{ position: 'relative' }}>
            <p>Signature</p>
            <CanvasDraw
              onChange={() => {
                setIsSignature(true)

                if (errors.signature) {
                  setErrors(prev => ({
                    ...prev,
                    signature: false,
                  }))
                }
              }}
              brushRadius={1}
              brushColor='#000000'
              canvasWidth={canvasWidth}
              canvasHeight='200px'
              hideGrid={true}
              hideInterface={true}
              className='signature-canvas-container'
              style={{
                cursor: 'pointer',
                border: `1px solid ${errors.signature ? 'red' : 'grey'}`,
                borderRadius: '8px',
              }}
            />
            {errors.signature && <p className='error'>required</p>}

            {!isSignature && <WaterMark>Please sign here</WaterMark>}
          </div>
        </div>
        <div>
          <div className='d-flex justify-content-around flex-wrap customer-btns-wrap'>
            <TextField
              type='text'
              label='Enter your name'
              name='name'
              variant='outlined'
              fullWidth
              size='small'
              value={signatoryName}
              onChange={event => {
                const { value } = event.target
                setSignatoryName(value)
                if (errors.name) {
                  setErrors(prev => ({ ...prev, name: false }))
                }
              }}
              error={errors.name}
              helperText={errors.name && 'required'}
            />

            {!!termFile && (
              <div className='d-flex flex-column align-items-center w-100 mb-5'>
                By accepting this project you are agreeing to the
                <div className='d-flex align-items-baseline'>
                  <StyledCheckbox
                    error={!!errors.terms}
                    checked={isAgreedTerms}
                    onChange={() => {
                      setIsAgreedTerms(!isAgreedTerms)

                      if (errors.terms)
                        setErrors(prev => ({ ...prev, terms: false }))
                    }}
                  />
                  <TermAndConditionsLink file={termFile} />
                </div>
                {errors.terms && (
                  <p className='error'>Accept Terms and Conditions</p>
                )}
              </div>
            )}

            <MainButton
              type='cancel'
              title='Cancel'
              onClick={() => handleCancelAcceptance()}
            />
            <MainButton
              title='Accept'
              onClick={getBase64}
              isFetching={isProposalSigning} /* disabled={!canSave} */
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  ) : (
    <>
      <CanvasDraw
        onChange={() => {
          setIsSignature(true)
          takeSignature()
        }}
        brushRadius={1}
        brushColor='#000000'
        canvasWidth={canvasWidth}
        canvasHeight='200px'
        hideGrid={true}
        hideInterface={true}
        className={`signature-canvas-container${required ? ' required' : ''}`}
        style={{
          cursor: 'pointer',
          border: '1px solid grey',
          borderRadius: '8px',
        }}
      />
      {!!termFile && (
        <div className='d-flex flex-column align-items-center w-100 mt-3'>
          By accepting this project you are agreeing to the
          <div className='d-flex align-items-center'>
            <Checkbox
              checked={isAgreedTerms}
              onChange={() => setIsAgreedTerms(!isAgreedTerms)}
            />
            <TermAndConditionsLink file={termFile} />
          </div>
        </div>
      )}
    </>
  )
}

export default ModalSignature

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-color: ${props => (props.error ? 'red' : 'initial')};
  }
`

const WaterMark = styled.p`
  font-size: 24px;
  position: absolute;
  left: 110px;
  top: 100px;
  color: #c4c4c4;
`
