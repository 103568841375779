// import { useEffect } from 'react'
import { priceToView } from '../../../../../../helpers/thousandSeparator'
// import moment from 'moment-timezone'
// import { Tooltip } from '@material-ui/core'
// import { Radio } from 'antd'

import './styles.scss'
// import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
// import { BalanceTransactions } from './components/BalanceTransactions'
import { Balance } from './components/Balance'
// import { hasActiveMerchant } from 'store/Orcatec/reducers/settings/company'
// import { useSelector } from 'react-redux'

const BalanceTable = ({
  balances,
  remaining = 0,
  radio = false,
  // onChangeRadio,
  // selectedBalance,
  remainigTitle = 'Remaining Balance:',
  onClickPay,
  customerView,
}) => {
  // const hasMerchant = useSelector(hasActiveMerchant)

  /*  useEffect(() => {
    if (radio && balances?.length === 1 && canPay(balances?.[0])) {
      onChangeRadio(balances[0])
    }
  }, [balances]) */

  // const handleRowClick = (e, balance) => {
  //   if (!radio || !e.currentTarget?.classList?.contains?.('canPay')) return
  //   onChangeRadio(balance)
  // }

  /*   const hasPending = balance =>
    balance.payment_transactions &&
    balance.payment_transactions.some(
      tr => tr.transaction_status === 'Queued for Capture',
    ) */

  /* const canPay = balance =>
    (balance.status === 1 || balance.status === 2) &&
    (balance?.accept_card || balance?.can_pay_by_check) &&
    !hasPending(balance) &&
    balance?.amount > 0
 */
  return (
    <div className={`payment-balances${radio ? ' radio' : ''}`}>
      <div className='table'>
        <div className='table-row heading'>
          <p>Name of Balance</p>
          <p>Payment Description</p>
          <p>Due Date</p>
          <p>Amount</p>
          <p>Status</p>
          {!!onClickPay && !!balances?.length && <p></p>}
        </div>

        {balances?.map(balance => (
          <Balance
            key={balance.id}
            data={balance}
            isCustomerView={customerView}
            onPay={onClickPay}
          />
        ))}

        {remaining !== 0 && (
          <div className='table-row remaining-balance'>
            <p>{remainigTitle}</p>
            <p />
            <p />
            <p>{priceToView(remaining)}</p>
            <p>Due</p>
            {!!onClickPay && !!balances?.length && <p></p>}
          </div>
        )}
      </div>
    </div>
  )
}

export default BalanceTable
