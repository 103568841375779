import styled from 'styled-components'

export const SeenBlock = styled.div`
  height: 25px;
`

export const DescriptionText = styled.p`
  color: grey;
  font-size: 12px;
  line-height: 12px;
`

export const FailedImageContainer = styled.div`
 width:50px;
 height:50px;
 background-color:'#eeeee';
 display:flex;
 align-items:center;
 justify-content:center;
 cursor: pointer;
  /* & > div {
    position: relative;
  } */

  /* & > span {
    display:inline-flex;
    gap: 4px;
    align-items: center;
    color: red;
    position: absolute;
    bottom: 0px;
    right:-40px;
    cursor: pointer;
  } */
`
