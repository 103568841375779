import HTMLRenderer from './html'
import MSDocRenderer from './msdoc'
import PDFRenderer from './pdf'
import TXTRenderer from './txt'
import IMAGERenderer from './image'
import AUDIORenderer from './audio'
import VIDEORenderer from './video'
import IFRAMERenderer from './iframe'



export const DocViewerRenderers = [
  HTMLRenderer,
  MSDocRenderer,
  PDFRenderer,
  TXTRenderer,
  IMAGERenderer,
  AUDIORenderer,
  VIDEORenderer,
  IFRAMERenderer,
]
