import { useSelector } from 'react-redux'
import Header from '../components/Header'

import './AdminPageLayout.scss'

const AdminPageLayout = ({ children }) => {
  const isOpenDrawer =
    useSelector(state => state.superAdmin?.commonData?.isOpenDrawer) || false
  const isCompaniesPage = window.location.pathname === '/companies'
  const isSupportsPage = window.location.pathname === '/support-tickets'
  const toggleStyle = isOpenDrawer
    ? `admin-page-wrapper__inner-content ${
        isCompaniesPage
          ? 'admin-page-wrapper__companies'
          : isSupportsPage
          ? 'admin-page-wrapper__tickets'
          : ''
      }`
    : `admin-page-wrapper__inner-content ${
        isCompaniesPage
          ? 'admin-page-wrapper__companies'
          : isSupportsPage
          ? 'admin-page-wrapper__tickets'
          : ''
      } admin-page-wrapper__inner-content--response`

  return (
    <div className='admin-page-wrapper'>
      <Header />
      <div className={toggleStyle}>{children}</div>
    </div>
  )
}

export default AdminPageLayout
