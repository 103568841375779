export const clearPhoneNumber = (str: string) => {
  let result = str
  result = result.replaceAll('(', '')
  result = result.replaceAll(')', '')
  result = result.replaceAll('+', '')
  result = result.replaceAll('-', '')
  result = result.replaceAll(' ', '')
  result = result.trim()

  return result
}

export const shouldAddOne = (str: string) => {
  if (str.length >= 11) {
    return str
  } else {
    return `1${str}`
  }
}
