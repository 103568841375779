import React from 'react'
import './style.scss'

const EmployeeIcon = ({ color }) => {
  return (
    <div className='map-label'>
      <div className='map-label-content map-label-content-home' style={{ color }}>
        <i className='mdi mdi-home-outline' />
      </div>
    </div>
  )
}

export default EmployeeIcon
