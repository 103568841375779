import { FC, memo } from 'react'
import { Tooltip } from 'antd'
import './AppointmentStatus.scss'

export const getStatusName = (status: number) => {
  switch (status) {
    case 1:
      return 'Active'
    case 2:
      return 'In Progress'
    case 3:
      return 'Completed'
    case 4:
      return 'Cancelled'
    case 5:
      return 'En Route'
    default:
      return 'invalid'
  }
}

export const getStatusIcon = (status: number) => {
  switch (status) {
    case 1:
      return <i className='mdi mdi-chart-donut' />
    case 2:
      return <i className='mdi mdi-progress-clock' />
    case 3:
      return <i className='mdi mdi-check-circle' />
    case 4:
      return <i className='mdi mdi-cancel' />
    case 5:
      return <i className='mdi mdi-truck-fast' />
    default:
      return null
  }
}

interface AppointmentStatus {
  statusId: number
  withStatusName?: boolean
  disableToolTip?: boolean
  className?: string
  customTitle?: string
}

const AppointmentStatus: FC<AppointmentStatus> = ({
  statusId,
  withStatusName,
  disableToolTip,
  className = '',
  customTitle,
}) => {
  return (
    <div className={'appointmentStatus' + /* withStatusName ? '' : ' justify-content-start' + */ className}>
      {!disableToolTip && !withStatusName ? (
        <Tooltip zIndex={99999999} title={customTitle ?? getStatusName(+statusId)}>
          {getStatusIcon(+statusId)}
        </Tooltip>
      ) : (
        <>
          {getStatusIcon(+statusId)}
          {withStatusName ? getStatusName(+statusId) : ''}
        </>
      )}
    </div>
  )
}

export default memo(AppointmentStatus)
