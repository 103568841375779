import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import * as types from '../../../../../store/Orcatec/types'

import validator from 'validator'
import * as userApi from '../../../../../api/User'
import { checkAuth } from '../../../../../helpers/auth'
import { forgotPassword, resentMessage } from '../../../../../api/Auth'
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm'
import CreatePasswordForm from '../CreatePasswordForm/CreatePasswordForm'
import './style.scss'
import MainButton from '../../components/buttons/MainButton'
import ReconfirmModal from '../ReconfirmModal'
import AuthLayout from '../../Layouts/AuthLayout/AuthLayout'
import { Checkbox } from 'antd'
import { Error } from '../../components/ErrorMessage/ErrorMessage'
import { BackToLoginBtn } from '../../Layouts/AuthLayout/BackToLoginBtn'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { isMobileDevise } from '../../../../../helpers/isMobileDevice'
import { SmallPreloader } from '../../components/Preloader/SmallPreloader'
import { beautifyEmailInput } from 'helpers/beautifyEmailInput'
import { ConsoleSqlOutlined } from '@ant-design/icons'
class PagesLogin2 extends Component {
  state = {
    successForgotPasswordEmailRequest: '',
    emailResendModal: false,
    isResending: false,
    isLoginInProcess: false,
    isFetchingNewPassword: false,
    forgotPassword: false,
    resetPassword: false, //false
    createPassword: false,
    email: '',
    password: '',
    password_show: false,
    remember: false,
    error: {
      email: {
        message: '',
        show: false,
      },
      password: {
        message: '',
        show: false,
      },
    },
  }

  componentDidMount() {
    if (this.props.location.pathname === '/login/reset-password') {
      const params = this.props.location.search.replace(/\?/, '').split('&')
      const [token, email] = params
      this.setState({
        forgotPassword: true,
        resetPassword: true,
      })
      if (isMobileDevise()) window.open(`orcatec://resetPass/${token}/${email}`, '_self')
    }
    if (this.props.location.pathname === '/login/create-password') {
      // const params = this.props.location.search.replace(/\?/, '')
      // const [token, email] = params
      // window.localStorage.removeItem('auth')

      this.setState({
        createPassword: true,
      })
    }
  }

  toggleEmailResendModal = () => {
    this.setState({ emailResendModal: !this.state.emailResendModal, isResending: false })
  }

  resendEmail = () => {
    this.setState({ isResending: true })
    resentMessage(this.state.email, this.state.password)
      .then(() => {
        this.toggleEmailResendModal()
        this.props.history.push('/register/info', {
          reconfirmEmail: this.state.email,
        })
      })
      .catch(() => {
        this.toggleEmailResendModal()
      })
  }

  auth = e => {
    e.preventDefault()
    const error = { ...this.state.error }

    if (validator.isEmpty(this.state.email)) {
      error.email.show = true
      error.email.message = 'This field is required'
    } else {
      error.password.show = false

      if (!validator.isEmail(this.state.email)) {
        error.email.show = true
        error.email.message = 'Enter valid email'
      } else {
        error.email.show = false
      }
    }
    if (validator.isEmpty(this.state.password)) {
      error.password.show = true
      error.password.message = 'This field is required'
    } else {
      error.password.show = false
    }

    if (!error.email.show && !error.password.show) {
      this.setState({ isLoginInProcess: true, error })
      this.props
        .authorization(this.state.email, this.state.password, this.state.remember)
        .catch(err => {
          const confirmEmailToken = localStorage.getItem('confirmEmailToken')
          const { response } = err
          if (response) {
            if (response.status === 401) {
              error.email.show = true
              error.password.show = true
              error.email.message = 'Email or password do not match'
              error.password.message = ''
              this.setState({ error })
            } else if (response.status === 403 && response.data && response.data.error) {
              if (response.data.error === 'Email is not verified') {
                if (confirmEmailToken) this.props.history.push('/register/info')
                else {
                  this.toggleEmailResendModal()
                }
              } else if (response.data.error === 'User status is inactive') {
                error.email.show = true
                error.password.show = true
                error.email.message = 'This account is not active'
                error.password.message = ''
              }
            }
          }
        })
        .finally(() => this.setState({ isLoginInProcess: false }))
    } else {
      this.setState({ error })
    }
  }

  toggleForgotPassword = () => {
    this.setState(prev => ({
      successForgotPasswordEmailRequest: '',
      forgotPassword: !prev.forgotPassword,
      isFetchingNewPassword: false,
      email: '',
      password: '',
      remember: false,
      error: {
        email: {
          message: '',
          show: false,
        },
        password: {
          message: '',
          show: false,
        },
      },
    }))
  }

  forgotPassword = e => {
    e.preventDefault()
    const error = { ...this.state.error }
    if (validator.isEmpty(this.state.email)) {
      error.email.show = true
      error.email.message = 'Enter your email so we can send you a password reset link'
    } else {
      error.password.show = false

      if (!validator.isEmail(this.state.email)) {
        error.email.show = true
        error.email.message = 'Enter valid email'
      } else {
        error.email.show = false
      }
    }
    if (!error.email.show) {
      this.setState({ isFetchingNewPassword: true, error })
      forgotPassword(this.state.email)
        .then(res => {
          this.setState({
            isFetchingNewPassword: false,
            successForgotPasswordEmailRequest: 'We will send you an email with password reset link',
            password: '',
            remember: false,
            error: {
              email: {
                message: '',
                show: false,
              },
              password: {
                message: '',
                show: false,
              },
            },
          })
        })
        .catch(error => {
          console.log(error?.response?.data?.message)
          this.setState({
            isFetchingNewPassword: false,
            successForgotPasswordEmailRequest: '',
            error: {
              email: {
                message: error?.response?.data?.errors?.email[0] || error?.response?.data?.message,
                show: !!error?.response?.data?.errors?.email[0] || !!error?.response?.data?.message,
              },
              password: {
                message: error?.response?.data?.errors?.password || error?.response?.data?.message,
                show: !!error?.response?.data?.errors?.password || !!error?.response?.data?.message,
              },
            },
          })
        })
    } else {
      this.setState({ error, successForgotPasswordEmailRequest: '' })
    }
  }

  changeInput = e => {
    const { name, value } = e.target
    const state = { ...this.state }
    state[name] = beautifyEmailInput(name, value)
    state.error = {
      email: {
        message: '',
        show: false,
      },
      password: {
        message: '',
        show: false,
      },
    }
    this.setState(state)
  }
  changeRemember = e => {
    this.setState({ remember: e.target.checked })
  }

  backToLogin = e => {
    e.preventDefault()
    this.toggleForgotPassword()
  }

  resetForgotPasswordForm = () => {
    this.setState({
      forgotPassword: false,
      resetPassword: false,
    })
  }
  resetCreatePasswordForm = () => {
    this.setState({
      createPassword: false,
    })
  }
  handleClickShowPassword = key => () => {
    this.setState({ [`${key}_show`]: !this.state[`${key}_show`] })
  }
  handleMouseDownPassword = event => {
    event.preventDefault()
  }
  render() {
    let token = ''
    let email = ''
    if (this.props.location.pathname === '/login/reset-password') {
      const params = this.props.location.search.replace(/\?/, '').split('&')
      token = params[0]
      email = params[1]
    }
    if (this.props.location.pathname === '/login/create-password') {
      const params = this.props.location.search.substr(1)
      token = params
    }

    return (
      <AuthLayout>
        <div className='custom-login-form'>
          {this.state.createPassword ? (
            <>
              <CreatePasswordForm
                history={this.props.history}
                token={token}
                createPassword={this?.state?.createPassword}
                resetForm={this.resetCreatePasswordForm}
              />
            </>
          ) : !this.state.forgotPassword ? (
            <>
              <div className='main-text'>Welcome back!</div>
              <div className='please-text'>Please login to your account.</div>
              <form className='custom-login-form' onSubmit={this.auth} noValidate>
                <div>
                  <TextField
                    id='login-email'
                    type='email'
                    label='E-mail'
                    name='email'
                    value={this.state.email}
                    onChange={this.changeInput}
                    error={this.state.error.email.show || this.state.emailResendModal}
                    helperText={this.state.error.email.show ? this.state.error.email.message : ''}
                    variant='outlined'
                    fullWidth={true}
                    size='small'
                  />
                </div>
                <div>
                  <TextField
                    id='login-password'
                    type={this.state.password_show ? 'text' : 'password'}
                    label='Password'
                    name='password'
                    value={this.state.password}
                    onChange={this.changeInput}
                    variant='outlined'
                    error={this.state.error.password.show || this.state.emailResendModal}
                    helperText={this.state.error.password.show ? this.state.error.password.message : ''}
                    fullWidth={true}
                    size='small'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={this.handleClickShowPassword('password')}
                            onMouseDown={this.handleMouseDownPassword}
                            edge='end'
                          >
                            {this.state.password_show ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {this.state.emailResendModal && (
                  <div className='resend-message'>
                    <span>This email is not verified. Do you want to resend verification email?</span>
                    <div>
                      {this.state.isResending ? <SmallPreloader /> : <span onClick={this.resendEmail}>Resend</span>}
                    </div>
                  </div>
                )}
                {/* <div className='form-group'>
                  <label htmlFor='email'>{'E-mail'}</label>
                  <input
                    type='email'
                    className={`form-control ${
                      this.state.error.email.show ? `av-invalid is-invalid is-pristine is-touched` : ''
                    }`}
                    id='email'
                    name='email'
                    placeholder='Enter email'
                    onChange={this.changeInput}
                  />
                  {this.state.error.email.show && <Error>{this.state.error.email.message}</Error>}
                </div>
                <div className='form-group'>
                  <label htmlFor='userpassword'>Password</label>
                  <input
                    type='password'
                    className={`form-control ${this.state.error.email.show ? `av-invalid is-invalid` : ''}`}
                    id='password'
                    name='password'
                    placeholder='Enter password'
                    onChange={this.changeInput}
                  />
                  {this.state.error.password.show && <Error>{this.state.error.password.message}</Error>}
                </div> */}
                <div className='remember-me-block'>
                  <Checkbox onChange={this.changeRemember}>Remember me</Checkbox>
                  <span className='forgot-password-text' onClick={this.toggleForgotPassword}>
                    Forgot Password?
                  </span>
                </div>

                <MainButton
                  title='Login'
                  htmltype='submit'
                  className='w-md waves-effect waves-light main-button'
                  isFetching={this.state.isLoginInProcess}
                />
              </form>
            </>
          ) : !this.state.resetPassword ? (
            <>
              {/* <div className='main-text impekable'>IMPEKABLE</div> */}
              <div className='please-text'>
                Please enter your email address so we can send you a password reset link
              </div>
              <form
                className='custom-login-form reset-password-form'
                onSubmit={this.forgotPassword}
                autoComplete='off'
                noValidate
              >
                <TextField
                  id='login-forgot-email'
                  type='email'
                  label='E-mail'
                  name='email'
                  value={this.state.email}
                  onChange={this.changeInput}
                  variant='outlined'
                  error={this.state.error.email.show}
                  helperText={this.state.error.email.show ? this.state.error.email.message : ''}
                  fullWidth={true}
                  size='small'
                />
                {/* <div className='form-group'>
                  <label htmlFor='email'>E-mail</label>
                  <input
                    type='email'
                    className={`form-control ${
                      this.state.error.email.show ? `av-invalid is-invalid is-pristine is-touched` : ''
                    }`}
                    id='email'
                    placeholder='Enter email'
                    value={this.state.email}
                    onChange={e => this.changeInput(e, 'email')}
                  />
                  {this.state.error.email.show && <Error>{this.state.error.email.message}</Error>}
                </div> */}
                <div className='form-group'>
                  <MainButton
                    title='Send request'
                    className='w-100 waves-effect waves-light main-button'
                    htmltype='submit'
                    isFetching={this.state.isFetchingNewPassword}
                    disabled={
                      this.state.error.email.show ||
                      this.state.error.password.show ||
                      this.state.successForgotPasswordEmailRequest
                    }
                  />
                  <BackToLoginBtn onClick={this.backToLogin} />
                  {/* <div className='d-flex col-6 align-items-center'>
                    <BackToLoginBtn onClick={this.backToLogin} />
                  </div>
                  <div className='col-6'>
                    <div className='text-right'>
                      <MainButton
                        title='Send request'
                        className='w-md waves-effect waves-light main-button'
                        htmltype='submit'
                        isFetching={this.state.isFetchingNewPassword}
                      />
                    </div>
                  </div> */}
                </div>
              </form>
              <div className='text-center mt-2'>
                <strong>{this.state.successForgotPasswordEmailRequest}</strong>
              </div>
            </>
          ) : (
            <>
              {/* <div className='main-text impekable'>IMPEKABLE</div> */}
              {/* <div className='please-text'>Please enter your new password.</div> */}
              <ResetPasswordForm
                history={this.props.history}
                token={token}
                email={email}
                resetForm={this.resetForgotPasswordForm}
              />
            </>
          )}
          {/*  <div className='sign-up-link'>
            <span>{`Don't have an account yet?`}</span>
            <Link to='/register/info' className='font-500 text-primary'>
              Sign-up
            </Link>
          </div> */}
        </div>
        {/* <ReconfirmModal
          resend={this.resendEmail}
          cancel={this.toggleEmailResendModal}
          modal={this.state.emailResendModal}
          isLoading={this.state.isResending}
          history={this.props.history}
        /> */}
      </AuthLayout>
    )
  }
}

export default withRouter(PagesLogin2)
