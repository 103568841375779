import { useEffect, useState } from 'react'
import { NotificationTemplateType } from '../types'
import { getTemplatesListByType } from 'api/NotificationModal'

export const useNotificationTemplatesList = (
  templateType: NotificationTemplateType,
) => {
  const [templatesList, setTemplatesList] = useState([])

  useEffect(() => {
    const getTemplates = async () => {
      const res = await getTemplatesListByType(templateType)

      setTemplatesList(
        res.map(item => ({ ...item, value: item.id, label: item.name })),
      )
    }

    if (templateType) getTemplates()
  }, [])

  return { templatesList }
}
