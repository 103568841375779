import styled from 'styled-components'

const Wrapper = styled.section`
  display: flex;
  min-height: 500px;
  max-height: 500px;
  overflow: auto;
`
const ControlsWrapper = styled.div`
  margin-bottom: 20px;
  gap: 10px;
  display: flex;
  align-items: center;

  & ul {
    padding-top: 10px;
  }
  .ant-badge-count {
    top: -5px;
  }
`

const ItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-content: flex-start;
  padding-top: 10px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
`
const SkeletonWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  gap: 20px;
  margin-bottom: 10px;
  height: 50px;
  width: 100%;
  & .ant-skeleton {
    display: block;
    width: 100%;
    & .ant-skeleton-input {
      display: block;
      width: 100%;
    }
  }
`
const Item = styled.article`
  cursor: pointer;
  pointer-events: all;
  z-index: 999;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
`

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

const NotificationItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 40px 100%;
  align-items: center;
  gap: 4px;
`
const NotificationRow = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

export {
  Wrapper,
  Item,
  ButtonWrapper,
  ItemList,
  NotificationWrapper,
  NotificationItem,
  ControlsWrapper,
  NotificationRow,
  SkeletonWrapper,
}
