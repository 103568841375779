import { useEffect, useState } from 'react'
import { Drawer, Switch } from 'antd'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import { PreferredTech } from './components/PreferredTech'
import { EventStatus } from './components/EventStatus'
import { CloseOutlined, FilterTwoTone } from '@ant-design/icons'
import { selectQueueSlice, queueFiltersSet } from 'features/Dispatch/queueSlice'
import { EventType } from './components/EventType'
import { getValueFromLS } from 'hooks/useLocalStorage'

export const QueueFilters = () => {
  const queueFiltersLS = getValueFromLS('queueFilters')

  const dispatch = useAppDispatch()
  const { filters } = useAppSelector(selectQueueSlice)

  const [openFilters, setOpenFilters] = useState(false)
  const [queueFilters, setQueueFilters] = useState<Record<string, number[]>>({})

  useEffect(() => {
    dispatch(queueFiltersSet(queueFilters))
  }, [queueFilters])

  useEffect(() => {
    if (queueFiltersLS) {
      setQueueFilters(queueFiltersLS)
      dispatch(queueFiltersSet(queueFiltersLS))
    }
  }, [])

  const handleFiltesChange = (name: string, value: number[] | boolean) => {
    const newFilters = { ...queueFilters, [name]: value }
    if (typeof value === 'object' && !value?.length) delete newFilters[name]
    if (typeof value === 'boolean' && !!value) delete newFilters[name]

    setQueueFilters(newFilters)
  }

  const handleFilterReset = () => {
    dispatch(queueFiltersSet({}))
    setQueueFilters({})
  }

  return (
    <Wrapper>
      <Heading onClick={() => setOpenFilters(true)}>
        <p>Queue filters:</p>

        <FilterTwoTone />

        {!!Object.values(filters).length && (
          <p style={{ fontSize: '0.8rem', color: '#4285f4' }}>
            (Applied filters: {Object.values(filters).length})
          </p>
        )}
      </Heading>

      <Drawer
        // title='Filter by'
        title={
          <DrawerHeading>
            <p>Filter by</p>
            {!!Object.values(filters).length && (
              <Reset onClick={handleFilterReset}>
                <CloseOutlined />
                Reset all filters
              </Reset>
            )}
          </DrawerHeading>
        }
        placement='right'
        onClose={() => setOpenFilters(false)}
        open={openFilters}
      >
        <Filter>
          <p>
            Event Status{' '}
            <Active>{filters.status && ` (${filters.status.length})`}</Active>
          </p>
          <EventStatus
            value={queueFilters?.status || []}
            onChange={value => handleFiltesChange('status', value)}
          />
        </Filter>

        <Filter>
          <p>
            Preferred tech{' '}
            <Active>
              {filters.preferred_technician_id &&
                ` (${filters.preferred_technician_id.length})`}
            </Active>
          </p>
          <PreferredTech
            value={queueFilters?.preferred_technician_id || []}
            onChange={value =>
              handleFiltesChange('preferred_technician_id', value)
            }
          />
        </Filter>

        <Filter>
          <p>
            Event type{' '}
            <Active>
              {filters.appointment_type_id &&
                ` (${filters.appointment_type_id.length})`}
            </Active>
          </p>
          <EventType
            value={queueFilters?.appointment_type_id || []}
            onChange={value => handleFiltesChange('appointment_type_id', value)}
          />
        </Filter>

        <Filter>
          <Switch
            checked={
              // eslint-disable-next-line no-prototype-builtins
              queueFilters.hasOwnProperty('unassignedUsers')
                ? !!queueFilters.unassignedUsers
                : true
            }
            onChange={checked => handleFiltesChange('unassignedUsers', checked)}
          />
          <span style={{ marginLeft: 20 }}>Show Unassigned Users</span>
        </Filter>
      </Drawer>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const DrawerHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Heading = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 5px;
`

const Filter = styled.div`
  & > div {
    margin: 5px 0 20px 10px;
  }
`

const Active = styled.span`
  font-size: 0.8rem;
  color: #1890ff;
`

const Reset = styled.p`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.8rem;
  cursor: pointer;
  color: #f28989;
`
