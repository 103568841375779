import { IScope } from '../types'

export const checkAccessControl = (
  permission: number,
  author?: number,
  me?: number,
): boolean => {
  if (!permission) return false
  if (permission === IScope.ALL) return true
  if (permission === IScope.OWN) return author === me
  if (permission === IScope.OWN_RELATED) return true
}
