import { Button, DatePicker, Input, Popconfirm, Select, Spin } from 'antd'
import { getTechniciansByColumn } from 'api/Matrix-log'
import { ItemEntriesAPI } from 'api/Project'
import CustomTimePicker from 'containers/MainContent/Orcatec/components/CustomTimePicker'
import { Modal } from 'containers/MainContent/Orcatec/components/UI/Modal'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { ItemProgressEntry } from 'features/Project/types'
import { round } from 'helpers/Math'
import { formatStringToNumber } from 'helpers/numbers'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'

import styled from 'styled-components'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { ProjectPermissions } from 'features/Settings/UsersAndGroups'
import Notes from 'containers/MainContent/Orcatec/components/Notes'
import { InfoBlock } from 'containers/MainContent/Orcatec/components/UI/InfoBlock/InfoBlock'

const { TextArea } = Input

interface Props {
  disabled: boolean
  id: number
  estimatedQty: number
  installedQty: number
  itemName: string
  loading: boolean
  unit?: string
  onClose?: () => void
  onSave?: (entry: ItemProgressEntry, notes: string) => void
  onEntryDelete?: (entryId: number) => void
}

const initialEntry = {
  install_date: moment().format('MM/DD/YYYY'),
  tech: null,
  qty: 0,
  hours: 0,
  start_time: null,
  end_time: null,
} as ItemProgressEntry

export const ProgressEntry = ({
  estimatedQty,
  disabled,
  id,
  itemName,
  installedQty,
  loading,
  unit,
  onSave,
  onClose,
  onEntryDelete,
}: Props) => {
  const [entry, setEntry] = useState({
    ...initialEntry,
    qty: round(estimatedQty - installedQty),
  })
  const [notes, setNotes] = useState('')
  const [techs, setTechs] = useState<Record<
    number,
    { id: number; title: string; position: number }
  > | null>(null)
  const [loadingEntry, setLoadingEntry] = useState(false)

  useEffect(() => {
    const getData = async () => {
      setLoadingEntry(true)
      const data = await ItemEntriesAPI.getItemEntry(id)
      setEntry(data)
      setLoadingEntry(false)
    }
    if (id) getData()
  }, [])

  useEffect(() => {
    const getTechs = async () => {
      const data = await getTechniciansByColumn({ date: entry.install_date })
      setTechs(
        transformArrayToObj(
          data.map((tech, index) => ({ ...tech, position: index })),
        ),
      )
    }

    if (entry.install_date) getTechs()
  }, [entry.install_date])

  useEffect(() => {
    if (entry.start_time && entry.end_time) {
      setEntry(prev => ({
        ...prev,
        hours:
          moment(entry.end_time, 'hh:mm a').diff(
            moment(entry.start_time, 'hh:mm a'),
            'minutes',
          ) / 60,
      }))
    }
  }, [entry.start_time, entry.end_time])

  const handleEntryChange = (e: {
    target: { name: string; value: unknown }
  }) => {
    const { name, value } = e.target

    setEntry(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleQtyBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (!value) setEntry(prev => ({ ...prev, qty: 0 }))
  }

  return (
    <Modal
      visible
      title={id ? 'Edit entry' : 'Add entry'}
      onCancel={onClose}
      width={500}
      footer={[
        <Footer key='footer'>
          <AccessControl
            author={entry.created_by}
            scopes={[ProjectPermissions.PROJECT_CAN_DELETE_PROGRESS_ENTRY]}
            additionalAccess={!!id && !disabled}
          >
            <Popconfirm
              title='Delete Entry?'
              onConfirm={() => onEntryDelete?.(id)}
            >
              <Button danger>Delete</Button>
            </Popconfirm>
          </AccessControl>
          <Button onClick={onClose} style={{ marginLeft: 'auto' }}>
            {disabled ? 'Close' : 'Cancel'}
          </Button>
          {!disabled && (
            <Button
              onClick={() => onSave?.(entry, notes)}
              type='primary'
              disabled={loading}
            >
              Save
            </Button>
          )}
        </Footer>,
      ]}
    >
      <Spin spinning={loading || loadingEntry}>
        {disabled && (
          <InfoBlock
            type='info'
            style={{
              marginBottom: 20,
              fontSize: '0.85rem',
            }}
          >
            You can&apos;t apply any changes to the progress unless the project
            is in <span style={{ fontWeight: 500 }}>&quot;Contract&quot;</span>{' '}
            status
          </InfoBlock>
        )}

        <ItemTitle>{itemName}</ItemTitle>

        <Row>
          <div>
            <p>Estimated</p>
            <p>
              {estimatedQty} {unit || 'Qty'}
            </p>
          </div>

          <div>
            <p>Installed</p>
            <p>
              {installedQty} {unit || 'Qty'}
            </p>
          </div>
        </Row>

        <Row>
          <div>
            <p>Install Date</p>
            <DatePicker
              value={moment(entry.install_date)}
              format='MM/DD/YYYY'
              style={{ width: 200 }}
              onChange={(_, value) =>
                handleEntryChange({
                  target: {
                    name: 'install_date',
                    value: value,
                  },
                })
              }
              allowClear={false}
              disabled={disabled}
            />
          </div>

          <div>
            <p>Worker</p>
            <Select
              showSearch
              placeholder='Select a worker'
              optionFilterProp='children'
              onChange={value => {
                handleEntryChange({
                  target: {
                    name: 'tech',
                    value: techs?.[+value],
                  },
                })
              }}
              filterOption={(input, option) =>
                (option?.title ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                { id: null, title: '(None)' },
                ...(techs
                  ? Object.values(techs).sort(
                      (a, b) => +a?.position - +b?.position,
                    )
                  : []),
              ]}
              value={entry?.tech?.title || null}
              style={{ width: 200 }}
              fieldNames={{ label: 'title', value: 'id' }}
              disabled={disabled}
            />
          </div>
        </Row>

        <Row>
          <Quantity>
            <p>Quantity</p>
            <NumberFormat
              thousandSeparator
              decimalScale={3}
              // label='Cost'
              // name='net_price'
              customInput={Input}
              value={entry?.qty}
              addonAfter={unit || 'Qty'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEntryChange({
                  target: {
                    name: 'qty',
                    value: formatStringToNumber(e, true),
                  },
                })
              }
              onBlur={handleQtyBlur}
              style={{ width: 200 }}
              disabled={disabled}
            />
          </Quantity>
        </Row>

        <p>Time spent</p>

        <Row
          style={{ gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'flex-end' }}
        >
          <CustomTimePicker
            onChange={value =>
              handleEntryChange({
                target: {
                  name: 'start_time',
                  value: moment(value, 'HH:mm').format('hh:mm a'),
                },
              })
            }
            value={
              entry.start_time
                ? moment(entry.start_time, 'hh:mm a').format('HH:mm')
                : undefined
            }
            placeholder='Time start'
            withMinute={true}
            step={30}
            endWorkTime={22}
            style={{ width: '130px' }}
            disabled={disabled}
          />

          <CustomTimePicker
            onChange={value =>
              handleEntryChange({
                target: {
                  name: 'end_time',
                  value: moment(value, 'HH:mm').format('hh:mm a'),
                },
              })
            }
            value={
              entry.end_time
                ? moment(entry.end_time, 'hh:mm a').format('HH:mm')
                : undefined
            }
            placeholder='Time end'
            withMinute={true}
            step={30}
            endWorkTime={22}
            style={{ width: '130px' }}
            disabled={disabled}
          />

          <NumberFormat
            customInput={Input}
            value={entry.hours}
            addonAfter='Hours'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleEntryChange({
                target: {
                  name: 'hours',
                  value: formatStringToNumber(e),
                },
              })
              setEntry(prev => ({
                ...prev,
                start_time: null,
                end_time: null,
              }))
            }}
            disabled
          />
        </Row>

        <hr />

        <div style={{ marginTop: 20 }}>
          {entry.id ? (
            <Notes
              route={`/proposal/entries/${entry.id}/notes`}
              disabled={disabled}
            />
          ) : (
            <TextArea
              placeholder='Entry notes'
              autoSize
              value={notes}
              onChange={e => setNotes(e.target.value)}
            />
          )}
        </div>
      </Spin>
    </Modal>
  )
}

export const Row = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
  gap: 10px;
`

export const Footer = styled.div`
  display: flex;
`

export const Quantity = styled.div`
  .ant-input-group-addon {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const ItemTitle = styled.h5`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
