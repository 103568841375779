import styled from 'styled-components'

type Props = {
  isLoading?: boolean
}

export const CardFrame = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  /* svg {
    margin-left: auto;
  } */
`

export const IFrame = styled.iframe`
  border: none;
  height: 130px;
  width: 100%;
`
export const Form = styled.form<Props>`
  /* padding: 5px 15px 15px; */
  display: ${props => (props.isLoading ? 'none' : 'block')};
`

export const Row = styled.div`
  display: flex;
  gap: 15px;
`

export const Wrapper = styled.div`
  max-width: 350px;
  margin: 0 auto;
`
export const Heading = styled.div`
  background-color: #eeeeee;
  padding-top: 15px;
  text-align: center;
`

export const PaymentMehods = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
`
