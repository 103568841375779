import axiosOrcatec from 'api/axiosInstance'
import { IContact } from 'features/PublicEvent/types'
import { Job } from 'types/Jobs'

const CREATE_PUBLIC_CONTACT = '/remote-contacts'

export const createPublicContact = (data: IContact): Promise<IContact> => {
  return axiosOrcatec
    .post(CREATE_PUBLIC_CONTACT, data)
    .then(data => data.data?.data)
}

export const getListOfSharedLinsk = (
  eventId: number,
  params: object,
): Promise<IContact> => {
  const LIST_OF_SHARED_LINKS = `events/${eventId}/public-links`
  return axiosOrcatec
    .get(LIST_OF_SHARED_LINKS, { params })
    .then(data => data.data)
}
export const deletePublicContact = (id: number): Promise<IContact> => {
  const DELETE_PATH = `/remote-contacts/${id}`
  return axiosOrcatec.delete(DELETE_PATH).then(data => data.data?.data)
}

export const createPublicLink = (eventId: number, data): Promise<IContact> => {
  const CREATE_PATH = `/events/${eventId}/public-links/send`
  return axiosOrcatec.post(CREATE_PATH, data).then(data => data.data)
}

export const generatePublicLink = (
  eventId: number,
): Promise<{ link: { id: number; url: string } }> => {
  const CREATE_PATH = `/events/${eventId}/public-links/generate`
  return axiosOrcatec.post(CREATE_PATH).then(data => data.data)
}

export const getEventByPublicLink = (eventLinkHash: string): Promise<Job> => {
  const PATH = `/public/events/${eventLinkHash}`
  return axiosOrcatec.get(PATH).then(data => data?.data)
}

export const changePublicEventStatus = (
  eventLinkHash: string,
  data: { status: number },
): Promise<Job> => {
  const PATH = `/public/events/${eventLinkHash}/change-status`
  return axiosOrcatec.post(PATH, data).then(data => data?.data?.data)
}

export const uploadPublicMedia = (
  eventLinkHash: string,
  data: File,
): Promise<Job> => {
  const PATH = `/public/events/${eventLinkHash}/media/upload`
  return axiosOrcatec.post(PATH, data).then(data => data?.data?.data)
}

export const updatePublicEvent = (
  eventLinkHash: string,
  data: Job,
): Promise<Job> => {
  const PATH = `/public/events/${eventLinkHash}`
  return axiosOrcatec.put(PATH, data).then(data => data?.data?.data)
}

export const updateStatusLink = (
  eventId: string,
  linkId: number,
  data: { status: boolean },
): Promise<Job> => {
  const PATH = `events/${eventId}/public-links/${linkId}`
  return axiosOrcatec.patch(PATH, data).then(data => data?.data?.data)
}

export const getPublicFormByHash = (eventLinkHash: string): Promise<Job> => {
  const PATH = `public/events/${eventLinkHash}/forms`
  return axiosOrcatec.get(PATH).then(data => data?.data?.data)
}

export const updatePublicFormByHash = (
  eventLinkHash: string,
  formHash: number,
  data,
): Promise<Job> => {
  const PATH = `public/events/${eventLinkHash}/forms/${formHash}`
  return axiosOrcatec.put(PATH, data).then(data => data?.data?.data)
}

export const downloadAsPDFByHash = (eventLinkHash: number, formHash: number) =>
  axiosOrcatec
    .get(`/public/events/${eventLinkHash}/forms/${formHash}/pdf`, {
      responseType: 'blob',
    })
    .then(res => res.data)

export const updatePublicFormFieldByHash = (
  eventLinkHash: string,
  formHash: number,
  data,
): Promise<Job> => {
  const PATH = `public/events/${eventLinkHash}/forms/${formHash}/partial-update`
  return axiosOrcatec.patch(PATH, data).then(data => data?.data?.data)
}
