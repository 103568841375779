import React from 'react'
import { Tooltip } from 'antd'
import moment from 'moment-timezone'
import { calculateTimeDiff, getStatusIcon } from '../../../helpers/helpers'
import '../BlockItem.scss'

const cardStyle = { display: 'flex', gap: '10px', alignItems: 'center' }
const statusNotCalcTime = ['Active', 'En Route', 'Completed', 'Canceled']
const dateFilterFormat = 'YYYYMMDDHHMM'
export interface IProps {
  logs: { status: string; date: string }[]
}
const Card = ({ logs }: IProps) => {
  return (
    <>
      <div className={'date-sub-item'}>
        {logs
          ?.sort(
            (a, b) =>
              moment(a.date).format(dateFilterFormat) -
              moment(b.date).format(dateFilterFormat),
          )
          .map((item, index, arr) => (
            <div key={index}>
              <span style={cardStyle}>
                <span className='appointmentStatus'>
                  {
                    <Tooltip title={item.status}>
                      {getStatusIcon(item.status)}
                    </Tooltip>
                  }
                </span>
                <span>
                  {' '}
                  <span>{moment(item?.date).format('MM/DD/YYYY')}</span>{' '}
                  <span>{moment(item.date).format('LT')}</span>
                </span>

                {!statusNotCalcTime?.includes(item?.status) && (
                  <i style={{ fontWeight: '500' }}>
                    {calculateTimeDiff(
                      arr?.[index + 1]?.date || moment(),
                      item?.date,
                      true,
                    )}
                  </i>
                )}
              </span>
            </div>
          ))}
      </div>
    </>
  )
}

export default Card
