import { useAppSelector } from 'store/Orcatec/hooks'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { selectProject } from 'features/Project/projectSelectors'

interface IReturn {
  payment: boolean
  supplies: boolean
  module_1: boolean
  module_2: boolean
  module_3: boolean
}

export const useModulePermissions = (): IReturn => {
  const perm = useAppSelector(selectUserPermissionsByModule(ModuleName.PROJECT))
  const me = useAppSelector(selectAuthUser)
  const project = useAppSelector(selectProject)

  return {
    payment: checkAccessControl(
      perm[ProjectPermissions.PROJECT_PAYMENT_MODULE_CAN_CHANGE_STATUS],
      project.user_id,
      me.id,
    ),
    supplies: checkAccessControl(
      perm[ProjectPermissions.PROJECT_SUPPLY_MODULE_CAN_CHANGE_STATUS],
      project.user_id,
      me.id,
    ),
    module_1: checkAccessControl(
      perm[ProjectPermissions.PROJECT_CUSTOM_MODULE_1_CAN_CHANGE_STATUS],
      project.user_id,
      me.id,
    ),
    module_2: checkAccessControl(
      perm[ProjectPermissions.PROJECT_CUSTOM_MODULE_2_CAN_CHANGE_STATUS],
      project.user_id,
      me.id,
    ),
    module_3: checkAccessControl(
      perm[ProjectPermissions.PROJECT_CUSTOM_MODULE_3_CAN_CHANGE_STATUS],
      project.user_id,
      me.id,
    ),
  }
}
