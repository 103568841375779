import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import { ProjectFee } from '../types'
import {
  // ProjectFeeAPI,
  getProjectFWOSettings,
  putProjectFWOSettings,
} from 'api/Project'
import { AsyncThunkAPI } from 'store/types'
import { Error } from 'types/Error'
import { AppStateType } from 'store'

export interface IFWO {
  super_name: string | null
  phone: string | null
  email: string | null
  work_description: string | null
  ordered_by: string | null
  on_site_indirect: boolean
  offsite: boolean
  customer_service: boolean
  customer: string
  sales_marketing: boolean
  project: string
  p_o_number: string
  days_of_week: []
  location: string | null
  foreman: string | null
  date: string | null
}

const initState: IFWO = {
  super_name: null,
  phone: null,
  email: null,
  work_description: null,
  ordered_by: null,
  on_site_indirect: false,
  offsite: false,
  customer_service: false,
  sales_marketing: false,
  project: null,
  p_o_number: null,
  days_of_week: [],
  location: null,
  foreman: null,
  date: null,
  customer: null,
}

interface Init {
  status: 'idle' | 'loading' | 'error'
  work_order: IFWO
  error: Error | null
}

const initialState: Init = {
  status: 'idle',
  work_order: initState,
  error: null,
}

const projectFwoSlice = createSlice({
  name: 'projectFWO',
  initialState,
  reducers: {
    setFwo: (state, { payload }) => {
      state.work_order = payload
    },

    clearFwo: state => {
      state.work_order = initState
    },
  },
  extraReducers: builder => {
    builder.addCase(getFwoSettingsThunk.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getFwoSettingsThunk.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.work_order = payload
    })
    builder.addCase(getFwoSettingsThunk.rejected, (state, action) => {
      state.status = 'error'
      state.error = action.payload?.errors || null
    })

    builder.addCase(updateFwoSettingsThunk.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateFwoSettingsThunk.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.work_order = payload
    })
    builder.addCase(updateFwoSettingsThunk.rejected, (state, action) => {
      state.status = 'error'
      state.error = action.payload?.errors || null
    })

    builder.addCase(updateFwoFieldThunk.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateFwoFieldThunk.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.work_order = payload
    })
    builder.addCase(updateFwoFieldThunk.rejected, (state, action) => {
      state.status = 'error'
      state.error = action.payload?.errors || null
    })
  },
})

export default projectFwoSlice.reducer
export const { setFwo, clearFwo } = projectFwoSlice.actions

export const selectFwo = (state: AppStateType): Init =>
  state.orcatec.projectFwoSlice

export const getFwoSettingsThunk = createAsyncThunk<IFWO, any, AsyncThunkAPI>(
  'project/getFwo',
  async (_, { getState }) => {
    const proposalId = getState().orcatec.projectSlice.project.id
    const companyId = getState().orcatec.company.id

    const { work_order } = await getProjectFWOSettings(proposalId, companyId)

    return work_order
  },
)

export const updateFwoSettingsThunk = createAsyncThunk<
  IFWO,
  { proposalId: number; state: IFWO; companyId: number },
  AsyncThunkAPI
>('project/updateFwo', async ({ proposalId, state, companyId }) => {
  const { work_order } = await putProjectFWOSettings(
    proposalId,
    state,
    companyId,
  )

  return work_order
})

export const updateFwoFieldThunk = createAsyncThunk<
  IFWO,
  {
    fields: { [K in keyof IFWO]: IFWO[K] }
  },
  AsyncThunkAPI
>('project/updateFwoField', async ({ fields }, { getState }) => {
  const state = getState().orcatec.projectFwoSlice.work_order

  const proposalId = getState().orcatec.projectSlice.project.id
  const companyId = getState().orcatec.company.id

  const newState = {
    ...state,
    ...fields,
  }

  const { work_order } = await putProjectFWOSettings(
    proposalId,
    newState,
    companyId,
  )

  return work_order
})
