import React from 'react'
import {
  PrintContainer,
  Wrapper,
  PrintContainerInner,
} from './PublicEvent.styles'

import { JobPublicView } from './JobPublicView/JobPublicView'

export const PublicEvent = () => {
  return (
    <Wrapper>
      <PrintContainer>
        <PrintContainerInner>
          <JobPublicView />
        </PrintContainerInner>
      </PrintContainer>
    </Wrapper>
  )
}
