import { ActionType } from '../constants/usersConstants'
import { combineReducers } from 'redux'

const {
  SET_CREATE_MODAL_FIELDS,
  SET_EDIT_MODAL_FIELDS,
  CLEAR_STORE,
  SET_CREATE_MODAL_PERMISSIONS,
  SET_EDIT_MODAL_PERMISSIONS,
  CREATE_USER_INIT,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  EDIT_USER_INIT,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  GET_USERS_LIST_INIT,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,
  DELETE_USER_INIT,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SET_USER_STATUS_INIT,
  SET_USER_STATUS_SUCCESS,
  SET_USER_STATUS_FAILURE,
  CHECK_INNACTIVE_USER,
  GET_CURRENT_USER_INIT,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  RESEND_INVITE_INIT,
  RESEND_INVITE_SUCCESS,
  RESEND_INVITE_FAILURE,
  GET_USER_ROLES,
  SELECT_USER_ROLE,
} = ActionType

const createModalInitialState = {
  full_name: '',
  title: '',
  email: '',
  invite_email: '',
  email_account: '',
  create_new_mail_account: false,
  role_id: null,
  profile_picture: '',
  crop: '',
  permissions: {
    users: true,
    companies: true,
    support: true,
    emails: true,
    email_accounts: true,
    ac_managers: false,
  },
}

const createModalReducer = (state = createModalInitialState, { type, payload }) => {
  switch (type) {
    case SET_CREATE_MODAL_FIELDS: {
      const { name, value } = payload
      return { ...state, [name]: value }
    }
    case CLEAR_STORE:
      return { ...state, ...createModalInitialState }
    case SET_CREATE_MODAL_PERMISSIONS: {
      const { name, value } = payload
      const copyPermission = { ...state.permissions }
      copyPermission[name] = value
      return { ...state, permissions: copyPermission }
    }
    case SELECT_USER_ROLE: {
      const { roleId, permissions } = payload
      return { ...state, role_id: roleId, permissions: { ...permissions, ac_managers: roleId === 11 } }
    }
    default:
      return state
  }
}

const userRolesInitialState = []

const userRolesReducer = (state = userRolesInitialState, { type, payload }) => {
  switch (type) {
    case GET_USER_ROLES:
      return [...payload]
    default:
      return state
  }
}

const sendingUserInitialState = {
  sending: false,
  sent: false,
  error: false,
  errorData: {},
}

const sendUserDataReducer = (state = sendingUserInitialState, { type, payload }) => {
  switch (type) {
    case CREATE_USER_INIT:
      return { ...state, sending: true, sent: false, error: false, errorData: {} }
    case CREATE_USER_SUCCESS:
      return { ...state, sending: false, sent: true, error: false, errorData: {} }
    case CREATE_USER_FAILURE: {
      const { data } = payload.response
      return { ...state, sending: false, sent: false, error: true, errorData: { ...data } }
    }
    case EDIT_USER_INIT:
      return { ...state, sending: true, sent: false, error: false, errorData: {} }
    case EDIT_USER_SUCCESS:
      return { ...state, sending: false, sent: true, error: false, errorData: {} }
    case EDIT_USER_FAILURE: {
      const { data } = payload.response
      return { ...state, sending: false, sent: false, error: true, errorData: { ...data } }
    }
    case DELETE_USER_INIT:
      return { ...state, sending: true, sent: false, error: false, errorData: {} }
    case DELETE_USER_SUCCESS:
      return { ...state, sending: false, sent: true, error: false, errorData: {} }
    case DELETE_USER_FAILURE:
      return { ...state, sending: false, sent: false, error: true, errorData: { ...payload } }
    case SET_USER_STATUS_INIT:
      return { ...state, sending: true, sent: false, error: false, errorData: {} }
    case SET_USER_STATUS_SUCCESS:
      return { ...state, sending: false, sent: true, error: false, errorData: {} }
    case SET_USER_STATUS_FAILURE:
      return { ...state, sending: false, sent: false, error: true, errorData: { ...payload } }
    case CLEAR_STORE:
      return { ...state, ...sendingUserInitialState }
    default:
      return state
  }
}

const userListInitialState = {
  data: [],
  active_count: 1,
  total: 1,
  per_page: 10,
  current_page: 1,
  last_page: 1,
  loading: false,
  load: false,
  error: false,
  isInnactive: '0',
}

const usersListReducer = (state = userListInitialState, { type, payload }) => {
  switch (type) {
    case GET_USERS_LIST_INIT:
      return { ...state, loading: true, load: false, error: false }
    case GET_USERS_LIST_SUCCESS:
      return { ...state, ...payload.data, loading: false, load: true, error: false }
    case GET_USERS_LIST_FAILURE:
      return { ...state, loading: false, load: false, error: true }
    case CHECK_INNACTIVE_USER: {
      const { value } = payload
      return { ...state, isInnactive: value }
    }
    default:
      return state
  }
}

const editModalInitialState = {
  currentUser: {
    id: null,
    full_name: '',
    email: '',
    created_at: '',
    created_by: '',
    role_id: 8,
    role: '',
    role_code: '',
    profile_picture: '',
    crop: '',
    permissions: {
      users: false,
      companies: false,
      support: false,
      emails: false,
      email_accounts: false,
      ac_managers: false,
    },
    title: '',
    status: 0,
    invite_email: '',
    password_changed_at: null,
  },
  loading: false,
  load: false,
  error: false,
  errorData: {},
}

const editModalReducer = (state = editModalInitialState, { type, payload }) => {
  switch (type) {
    case GET_CURRENT_USER_INIT:
      return { ...state, loading: true, load: false, error: false, errorData: {} }
    case GET_CURRENT_USER_SUCCESS: {
      const { data } = payload
      return { ...state, currentUser: data, loading: false, load: true, error: false, errorData: {} }
    }
    case GET_CURRENT_USER_FAILURE: {
      const { data } = payload.response
      return { ...state, loading: false, load: false, error: true, errorData: { ...data } }
    }
    case SET_EDIT_MODAL_FIELDS: {
      const { name, value } = payload
      const copyCurrentUser = { ...state.currentUser }
      copyCurrentUser[name] = value
      return { ...state, currentUser: copyCurrentUser }
    }
    case SET_EDIT_MODAL_PERMISSIONS: {
      const { name, value } = payload
      const copyPermission = { ...state.currentUser.permissions }
      copyPermission[name] = value
      const copyCurrentUser = { ...state.currentUser, permissions: copyPermission }
      return { ...state, currentUser: copyCurrentUser }
    }
    case SELECT_USER_ROLE: {
      const { roleId, permissions } = payload
      const copyCurrentUser = {
        ...state.currentUser,
        role_id: roleId,
        permissions: { ...permissions, ac_managers: roleId === 11 },
      }
      return { ...state, currentUser: copyCurrentUser }
    }
    case CLEAR_STORE:
      return { ...state, ...editModalInitialState }
    default:
      return state
  }
}

const resendInviteUserInitialState = {
  reinviteSending: false,
  reinviteSent: false,
  reinviteError: false,
  reinviteErrorData: {},
}

const reinviteUserReducer = (state = resendInviteUserInitialState, { type, payload }) => {
  switch (type) {
    case RESEND_INVITE_INIT:
      return { ...state, reinviteSending: true, reinviteSent: false, reinviteError: false, reinviteErrorData: {} }
    case RESEND_INVITE_SUCCESS:
      return { ...state, reinviteSending: false, reinviteSent: true, reinviteError: false, reinviteErrorData: {} }
    case RESEND_INVITE_FAILURE: {
      const { data } = payload.response
      return {
        ...state,
        reinviteSending: false,
        reinviteSent: false,
        reinviteError: true,
        reinviteErrorData: { ...data },
      }
    }
    case CLEAR_STORE:
      return { ...state, ...resendInviteUserInitialState }
    default:
      return state
  }
}

export default combineReducers({
  usersListData: usersListReducer,
  createModalData: createModalReducer,
  editModalData: editModalReducer,
  sendUserData: sendUserDataReducer,
  resendInviteUserData: reinviteUserReducer,
  userRolesData: userRolesReducer,
})
