import React, { useState } from 'react'
import { Modal, Table, Spin } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { TableData } from 'features/Forms/types'

import { FilterDropdownProps } from 'antd/lib/table/interface'
import DefaultColumnFilter from 'containers/MainContent/Orcatec/components/Table/components/TableFilters/DefaultColumnFilter'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'

interface IProps {
  isModalOpen: boolean
  handleCancel: () => void
  loading: boolean
  data: TableData[]
  handleCreateForm: (template: TableData) => void
}

export const AddFormModal = ({
  isModalOpen,
  handleCancel,
  loading,
  data,
  handleCreateForm,
}: IProps) => {
  const [pageSize, setPageSize] = useState(10)
  // const tableActionProps = (template: TableData) => ({
  //   todos: ['add'],
  //   callbacks: [() => handleCreateForm(template)],
  //   preloaders: [false],
  //   tooltips: ['Add to project'],
  //   popConfirms: [false],
  // })
  const columns: ColumnProps<TableData>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      filterDropdown: (props: FilterDropdownProps) => (
        <DefaultColumnFilter title={'Title'} {...props} />
      ),
      onFilter: (value: string, record) =>
        record.title?.toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value, record) => (
        <MainButton onClick={() => handleCreateForm(record)} title='Add form' />
      ),
    },
  ]

  return (
    <Modal
      footer={
        <MainButton
          type='cancel'
          style={{ marginLeft: 'auto' }}
          onClick={handleCancel}
          title='Close'
        />
      }
      width={800}
      title='Templates'
      visible={isModalOpen}
      onCancel={handleCancel}
    >
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={data}
          size='small'
          pagination={{
            total: data?.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            pageSize: pageSize,
            size: 'small',
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100', '250', '500'],
            onShowSizeChange: (current, size) => setPageSize(size),
          }}
        />
      </Spin>
    </Modal>
  )
}
