import {
  HighlightOffOutlined,
  UpdateOutlined,
  PauseCircleOutlineRounded,
  AddCircleOutlineOutlined,
  Call,
  Block,
  CheckCircleOutline,
  DeleteOutline,
  DriveEta,
  ErrorOutline,
  FullscreenExit,
  Grade,
  HelpOutline,
  Details,
  DataUsage,
  Done,
  Place,
  Sync,
} from '@material-ui/icons'
import LeadIcon from 'containers/MainContent/Orcatec/Matrix/components/calendarV2/icons/LeadIcon'
import QueueIcon from 'containers/MainContent/Orcatec/Matrix/components/calendarV2/icons/QueueIcon'
import RecurringIcon from 'containers/MainContent/Orcatec/Matrix/components/calendarV2/icons/RecurringIcon'
import TodoIcon from 'containers/MainContent/Orcatec/Matrix/components/calendarV2/icons/TodoIcon'
import { DispatchEvent } from 'features/Dispatch/types'

export const getQueueIcon = (iconType: string | null) => {
  switch (iconType) {
    case 'todo':
      return <TodoIcon />
    case 'call':
      return <Call />
    case 'block':
      return <Block />
    case 'help':
      return <HelpOutline />
    case 'details':
      return <Details />
    case 'star':
      return <Grade />
    case 'sync':
      return <Sync />
    case 'data':
      return <DataUsage />
    case 'done':
      return <Done />
    case 'place':
      return <Place />
    case 'fullscreenexit':
      return <FullscreenExit />
    case 'error':
      return <ErrorOutline />
    case 'drive':
      return <DriveEta />
    case 'delete':
      return <DeleteOutline />
    case 'check':
      return <CheckCircleOutline />
    case 'simple':
      return <QueueIcon />
    case 'recurring':
      return <RecurringIcon />
    case 'lead':
      return <LeadIcon />
    case 'add':
      return <AddCircleOutlineOutlined />
    case 'update':
      return <UpdateOutlined />
    case 'pause':
      return <PauseCircleOutlineRounded />
    case 'cancel':
      return <HighlightOffOutlined />

    default:
      return <QueueIcon />
  }
}

export const filterQueueEvents = (
  events: DispatchEvent[],
  filterObject: Record<string, number[]>,
): DispatchEvent[] => {
  return events.filter(event => {
    return Object.entries(filterObject).every(([key, values]) => {
      // If the key is not present in the event, consider it a match
      if (!(key in event)) {
        return true
      }

      // If the key is present, check if the event's value is in the filter values
      return values.includes(event[key])
    })
  })
}
