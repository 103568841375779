import { useRef, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'components/UIKit'
import ReactHtmlParser from 'react-html-parser'
import { Editor } from '@tinymce/tinymce-react'
import { initEditorSettings } from '../../utils'

interface Props {
  logo?: string
  message: string
  onChange: (message: string) => void
}

export const MessagePreview = ({ logo, message, onChange }: Props) => {
  const editorRef = useRef(null)

  const [editMode, setEditMode] = useState(false)

  const handleEdit = () => setEditMode(!editMode)

  return (
    <div>
      <Button onClick={handleEdit} style={{ marginBottom: 20 }} type='primary'>
        {editMode ? 'Preview' : 'Edit message'}
      </Button>

      {!!logo && !editMode && (
        <LogoContainer>
          <img
            style={{
              width: 200,
              height: 200,
              margin: '0 auto',
              objectFit: 'contain',
            }}
            src={logo}
            alt=''
          />
        </LogoContainer>
      )}

      {editMode ? (
        <Editor
          apiKey={process.env.REACT_APP_TINY_EDITOR_API_KEY}
          init={initEditorSettings}
          ref={editorRef}
          value={message}
          onInit={(_evt, editor) => (editorRef.current = editor)}
          onEditorChange={newValue => onChange(newValue)}
          // disabled={loading}
        />
      ) : (
        <div>{ReactHtmlParser(message)}</div>
      )}
    </div>
  )
}

const LogoContainer = styled.div`
  text-align: center;
  width: 100%;
`
