import { Checkbox, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { TextField, Select } from 'components/UIKit'
import React, { useState } from 'react'
import ReactInputMask from 'react-input-mask'
import styled from 'styled-components'
import validator from 'validator'

interface Props {
  disabled: boolean
  error: boolean
  emails: {
    checked: boolean
    value: string[]
  }
  phone: {
    checked: boolean
    value: string
  }
  onChange: (type: 'emails' | 'phone', value: string | string[]) => void
  onCheck: (type: 'emails' | 'phone', value: boolean) => void
}

export const OtherContacts = ({
  disabled,
  emails,
  error: errorEmpty,
  phone,
  onChange,
  onCheck,
}: Props) => {
  const [error, setError] = useState(false)

  const handleChangeEmail = (_: Event, value: string[]) => {
    const emailValues = value
      .map(email => email.trim())
      .filter(email => validator.isEmail(email))

    onChange('emails', emailValues)
  }
  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    onChange('phone', value)

    setError(value.includes('_'))
  }

  const handleCheck = (type: 'emails' | 'phone') => (e: CheckboxChangeEvent) =>
    onCheck(type, e.target.checked)

  return (
    <Wrapper>
      <MainTitle>Others:</MainTitle>

      <Section>
        <Title>Other emails:</Title>
        <Row>
          <Checkbox checked={emails.checked} onChange={handleCheck('emails')} />
          <Select
            mode='tags'
            name='emails'
            value={emails.value}
            onChange={handleChangeEmail}
            error={errorEmpty ? 'Enter valid email' : undefined}
            open={false}
          />
        </Row>
      </Section>
      <Section>
        <Title>Other phone:</Title>
        <Tooltip title={disabled ? 'Messaging service isn`t setup yet' : ''}>
          <Row>
            <Checkbox
              checked={phone.checked}
              onChange={handleCheck('phone')}
              disabled={disabled}
            />
            <ReactInputMask
              name='phone'
              mask='(999) 999-9999'
              value={phone.value || ''}
              onChange={handleChangePhone}
              disabled={disabled}
            >
              {() => (
                <TextField
                  disabled={disabled}
                  placeholder='(XXX) XXX-XXXX'
                  error={!!(error || errorEmpty) && 'Enter valid phone number'}
                />
              )}
            </ReactInputMask>
          </Row>
        </Tooltip>
      </Section>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Section = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 1rem;
  margin-bottom: 16px;
  align-items: center;
  margin-left: 10px;
`
const Row = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
const Title = styled.p`
  font-weight: 500;
`

const MainTitle = styled.h6`
  margin-top: 10px;
  color: ${props => props.theme.font.secondary};
`
