import { selectProjectSections } from 'features/Project/projectSelectors'
import { useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import { ProjectSection } from './ProjectSection'

export const ProjectSections = () => {
  const sections = useAppSelector(selectProjectSections)

  return (
    <Wrapper>
      {sections.map(section => (
        <ProjectSection
          key={section?.id}
          data={section}
          hideTitle={sections.length === 1 && section.title === 'New Section'}
        />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div``
