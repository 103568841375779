const createMarkup = markup => {
  return { __html: markup }
}

const createMarkupWithoutSpace = markup => {
  const patern = /<p>(?:\s|&nbsp;)*?<\/p>/gi //remove all tags with empty symbols
  let removedSpaceString = markup ? markup?.replaceAll(patern, '') : ''
  return { __html: removedSpaceString }
}

export { createMarkup, createMarkupWithoutSpace }
