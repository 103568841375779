import React, { useEffect, useState } from 'react'
import { Marker, useMap } from 'react-leaflet'
import L from 'leaflet'
import ReactDOMServer from 'react-dom/server'
import AppointmentMarkerPopap from './../Popaps/AppointmentMarkerPopap/AppointmentMarkerPopap'
import TestIcon from '../Icons/TestIcon/TestIcon'

const AppointmentMarker = props => {
  const [isOpeningPopup, setIsOpeningPopup] = useState(false)
  const closeButtonClassName = 'leaflet-popup-close-button'
  const map = useMap()
  useEffect(() => {
    setIsOpeningPopup(props.openQueuePopup === props.task.id && props.task.id !== undefined)
    const closeButtons = document?.getElementsByClassName(closeButtonClassName)
    if (!closeButtons) return
    closeButtons?.[0]?.addEventListener('click', event => {
      event?.preventDefault?.()
      event?.stopPropagation?.()
      props?.setClosePopup?.()
      setIsOpeningPopup(false)
      map?.closePopup()
    })
  })

  const markerRef = ref => {
    if (ref && isOpeningPopup) {
      return ref?.openPopup()
    } else {
      ref?.closePopup()
    }
  }

  return (
    <Marker
      ref={markerRef}
      position={[Number(props.lat), Number(props.lng)]}
      icon={L.divIcon({
        iconSize: null,
        html: ReactDOMServer.renderToString(
          <TestIcon
            title={props?.task?.type ? props.task?.type?.[0]?.toUpperCase() : ''}
            color={props?.color}
            status={props?.task?.status}
            isJob={props?.task?.kind === 2}
            sn={props.sn}
          />,
        ),
      })}
      className='redborder'
    >
      <AppointmentMarkerPopap
        onOpenAppointment={props.onOpenAppointment}
        task={props.task}
        isAppointment={props?.isAppointment}
        className='popaps-with-content'
      />
    </Marker>
  )
}

export default AppointmentMarker
