import { InfoCircleOutlined } from '@ant-design/icons'
import { IconWarning } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import React from 'react'
import styled from 'styled-components'

type Props = {
  type: 'info' | 'warning' | 'error'
  children: React.ReactNode
  size?: 'small' | 'default'
  width?: string
}

export const InfoPlate = ({
  type = 'info',
  children,
  size = 'default',
  width = '100%',
}: Props) => {
  return (
    <Wrapper color={getPlateColor[type]} size={size} width={width}>
      {getPlateIcon[type]}
      <Body>{children}</Body>
    </Wrapper>
  )
}

const getPlateColor: Record<Props['type'], string> = {
  info: '#206FED',
  warning: '#FDA428',
  error: '#F1453D',
}

const getPlateIcon: Record<Props['type'], React.ReactNode> = {
  info: <InfoCircleOutlined style={{ fontSize: 20 }} />,
  warning: <IconWarning style={{ fontSize: 20 }} />,
  error: '#F1453D',
}

const Wrapper = styled.div<{
  color: string
  size: Props['size']
  width: string
}>`
  padding: ${props =>
    props.size === 'default' ? '16px 16px 16px 12px' : '8px 10px 8px 8px'};
  background-color: ${props => props.color}33;
  border-left: 4px solid;
  border-color: ${props => props.color};
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  width: ${props => props.width};

  & > span {
    color: ${props => props.color};
  }
`

const Body = styled.div``
