import styled from 'styled-components'
import { useState } from 'react'
import { ProjectRebatesTable } from './components/ProjectDiscountsTable/ProjectRebatesTable'

interface Props {
  sectionId: number
  disabled: boolean
  restrictAdd: boolean
  showPrice: boolean
  groupId?: number
}

export const ProjectRebates = ({
  sectionId,
  groupId,
  restrictAdd,
  disabled,
}: Props) => {
  const [isNewRowtriggered, setIsNewRowtriggered] = useState(false)

  return (
    <Wrapper>
      <ProjectRebatesTable
        sectionId={sectionId}
        disabled={disabled}
        groupId={groupId || null}
        isNewRowtriggered={isNewRowtriggered}
        onItemAdd={setIsNewRowtriggered}
        restrictAdd={restrictAdd}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 10px 0;
`
