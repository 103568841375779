import { Column, EventColorType } from 'features/Dispatch/types'
import { DispatchSettings } from '../../../../CalendarSettings'
import './style.scss'

const startTopPosition = 78
const step = 45

const getAddressByType: Record<number, string> = {
  1: '92128',
  2: 'San Diego',
  3: 'San Diego, CA',
}

interface Props {
  index: number
  column: Column
  settings: DispatchSettings
}

const Events = ({ column, index, settings }: Props) => {
  const eventBoxes = new Array(5).fill('').map((eventBox, idx) => {
    const top = idx * step + startTopPosition
    const color =
      settings.event_color_type === EventColorType.Column
        ? column.background_color || '#626ed4'
        : settings.event_color_type === EventColorType.User
        ? column.technicians.find(tech => tech.user_id === column.main_tech_id)?.background_color || '#626ed4'
        : '#626ed4'
    const address = getAddressByType[settings.matrix_address_type]

    const style = {
      position: 'absolute',
      top: `${top}px`,
      height: `40px`,
      width: '60px',
      backgroundColor: `${color}`,
      border: `1px solid ${color}`,
    }

    return (
      <div key={`eventBox-${index}-${idx}`} className='event' style={style}>
        <div className='event-content'>
          <span>{address}</span>
          <span>Repair/Replace</span>
          <span>8a-10a</span>
        </div>
        {idx === 2 && <div className='in_process' />}
        {idx === 3 && <div className='completed' />}
        {idx === 4 && (
          <>
            <div className='denied_1' />
            <div className='denied_2' />
          </>
        )}
      </div>
    )
  })
  return <div className='events'>{eventBoxes}</div>
}
export default Events
