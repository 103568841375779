import { Switch, SwitchProps } from 'antd'
import { Label, Wrapper } from './Switcher.style'

interface SwitcherProps extends SwitchProps {
  label?: string
}

export const Switcher = ({ label, ...rest }: SwitcherProps) => {
  return (
    <Wrapper>
      <Switch {...rest} />
      {!!label && <Label>{label}</Label>}
    </Wrapper>
  )
}
