export const deleteErrorKeys = (
  errorObj: Record<string, string> | null,
  keysToDelete?: string[],
): Record<string, string> | null => {
  if (!keysToDelete || !errorObj) {
    // If the array is empty, delete all error keys
    return null
  }

  const updatedErrorObj: Record<string, string> = Object.keys(errorObj)
    .filter(key => !keysToDelete?.includes(key))
    .reduce((obj, key) => {
      obj[key] = errorObj[key]
      return obj
    }, {} as Record<string, string>)

  return updatedErrorObj
}
