import React from 'react'
import { Card } from 'antd'
import Items from 'containers/MainContent/Orcatec/Proposals/components/PageProposalPreview/ItemsTable'
import styled from 'styled-components'
import moment from 'moment-timezone'

const gridStyle: React.CSSProperties = {
  width: '100%',
  textAlign: 'left',
  padding: '5px',
  boxShadow: 'none',
}

export const PublicItems = ({
  sections,
  proposalStatus,
  showProgress,
  isJobView,
}) => {
  return (
    <Wrapper>
      {sections?.map((section, i, arr) => (
        <Card
          bordered={false}
          key={`${section.title} ${i}`}
          bodyStyle={{ padding: '0px' }}
          style={{ marginBottom: '20px' }}
          title={
            <>
              {!(arr?.length === 1 && section.title === 'New Section') && (
                <div className='section-name'>{section.title}</div>
              )}
              {section?.description && (
                <p
                  style={{
                    fontWeight: '300',
                    color: 'grey',
                    fontSize: '14px',
                    whiteSpace: 'normal',
                  }}
                >
                  {section?.description}
                </p>
              )}
            </>
          }
        >
          <Card.Grid hoverable={false} style={gridStyle}>
            {!!section.items.filter(item => !item.group_id).length && (
              <Items
                title={''}
                status={proposalStatus}
                section={i}
                type={'items'}
                showProgress={showProgress}
                groupId={null}
                sectionId={section.id}
                isJobView={isJobView}
                companyTimezone={moment.tz.guess()}
              />
            )}

            {section.groups.map(group => (
              <Items
                key={group.id}
                title={group.name}
                status={proposalStatus}
                section={i}
                type={'items'}
                showProgress={showProgress}
                groupId={group.id}
                sectionId={section.id}
                isJobView={isJobView}
                companyTimezone={moment.tz.guess()}
              />
            ))}

            {/* <div className='warranty-wrapper'>
              <Items
                title='Warranty'
                items={section.warranties}
                section={i}
                type={'warranties'}
                companyTimezone={moment().tz()}
              />
            </div> */}
          </Card.Grid>
        </Card>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & .price-section {
    display: none;
  }
  & .ant-card-head {
    border-bottom: none;
    padding: 0;
  }
  & .dnd-items-header {
    font-weight: 500;
    font-size: 18px;
    padding-top: 10px;
    /* font-weight: 500;
    font-size: 15px;
    margin-bottom: 10px;
    padding: 5px 20px 5px 5px;
    background-color: #efefef;
    border-radius: 3px; */
  }
  & span[name='description'] {
    font-size: 12px;
  }

  & .warranty-wrapper {
    & span[name='description'] {
      font-size: 14px;
      font-weight: 500;
    }
  }
  & .dnd-items-container {
    padding-left: 10px;
  }
  & .dataTables_wrapper {
    padding-left: 20px;
  }
  & .actions:not(:has(.customer-block__image)) {
    height: 10px;
  }
  & .actions {
    padding: 4px;
    border-bottom: 1px solid #f1efef;
  }
  & .section-name {
    font-weight: 500;
    font-size: 18px;
    padding: 5px 20px 5px 5px;
    background-color: #efefef;
    border-radius: 3px;
  }
`
