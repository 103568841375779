import * as types from '../../types'
import { defaultProposalModuleNames } from '../../../../common/constants'

const initialState = {
  isOpen: false,
  name: defaultProposalModuleNames.module_three,
}

export default (state = initialState, action) => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case types.modalOptional3.OPEN_MODAL_OPTIONAL_THIRD:
      newState.isOpen = !newState.isOpen
      return newState
    default:
      return state
  }
}
