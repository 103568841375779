import axiosOrcatec from './axiosInstance'
import { IGetNewNumberParams, UserInterface } from 'features/Messaging/types'

export const messagingAPI = {
  getAllChats: (params: any) =>
    axiosOrcatec.get(`sms/chats`, { params }).then(r => r.data),

  getChatInfo: (id: number) =>
    axiosOrcatec.get(`sms/chats/${id}`).then(r => r.data),

  getChatMessages: (id: number, page = 1) =>
    axiosOrcatec.get(`sms/chats/${id}/messages?page=${page}`).then(r => r.data),

  getProbablyClients: (id: number) =>
    axiosOrcatec.get(`sms/chats/${id}/clients`).then(r => r.data),

  attachChatToClient: (chatID: number, data: object) =>
    axiosOrcatec
      .post(`sms/chats/${chatID}/clients/relate-to-client`, data)
      .then(r => r.data),

  sendSMS: (data: object) =>
    axiosOrcatec.post(`messaging/sms/send`, data).then(r => r.data),

  seenMessage: (data: { messageId: number[] }) =>
    axiosOrcatec.post(`sms/seen`, data).then(r => r.data),

  getMediaForMessage: (apiMediaID: string) =>
    axiosOrcatec.get(`messaging/sms/${apiMediaID}/media`).then(r => r.data),

  getCountriesForNewNumber: () =>
    axiosOrcatec
      .get('messaging/pricing/phone-numbers/countries')
      .then(r => r.data),

  getNewPhoneNumbers: (params: IGetNewNumberParams) =>
    axiosOrcatec
      .get('messaging/pricing/phone-numbers', { params })
      .then(r => r.data),

  buyNewPhoneNumber: (data: { phoneNumber: string }) =>
    axiosOrcatec
      .post('messaging/pricing/phone-numbers/purchase', data)
      .then(r => r.data),

  clearSMS: () => axiosOrcatec.post('sms/clear-seen'),

  getRelatedChats: (isOrganization: boolean, id: number) =>
    axiosOrcatec
      .get(`sms/${isOrganization ? 'organizations' : 'clients'}/${id}/chats`)
      .then(r => r.data),

  getRelatedChatWithAllMessages: (
    isOrganization: boolean,
    id: number,
    page = 1,
  ) =>
    axiosOrcatec
      .get(
        `sms/${
          isOrganization ? 'organizations' : 'clients'
        }/${id}/messages?page=${page}`,
      )
      .then(r => r && r.data),

  getAvailablePhoneNumbers: <T>() =>
    axiosOrcatec.get<T>('messaging/phone-numbers').then(({ data }) => data),

  getSettingsAvailablePhoneNumbers: <T>() =>
    axiosOrcatec
      .get<T>('/setting/messaging/phone-numbers')
      .then(({ data }) => data),

  getSettingsListPhoneNumbers: <T>() =>
    axiosOrcatec.get<T>('/groups-users-list').then(({ data }) => data),

  attachTechToChat: (chatID: number, data: object) =>
    axiosOrcatec.post(`sms/chats/${chatID}/users`, data).then(r => r && r.data),

  getUsers: (params: any) =>
    axiosOrcatec
      .get<{ data: UserInterface[] }>('/users', { params })
      .then(r => r && r.data),

  deleteMessage: (chatID: number, messageID: number) =>
    axiosOrcatec
      .delete(`/sms/chats/${chatID}/messages/${messageID}`)
      .then(({ data }) => data),

  getUnreadChatsCount: () =>
    axiosOrcatec.get('/sms/chats/unread/count').then(({ data }) => data),
  readAllMessagesInChat: (chatId: number) =>
    axiosOrcatec
      .post(`sms/chats/${chatId}/mark-as-read`)
      .then(({ data }) => data),
  unreadAllMessagesInChat: (chatId: number) =>
    axiosOrcatec
      .post(`sms/chats/${chatId}/mark-as-unread`)
      .then(({ data }) => data),
}
