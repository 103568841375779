import { useEffect, useState } from 'react'
import { ITemplate, NotificationModalType } from '../types'
import {
  getTemplateById,
  getTemplateByTypyAndIndustry,
} from 'api/NotificationModal'

export const useNotificationTemplate = (
  notificationType: NotificationModalType,
  industryId: number,
  templateId: number | null,
) => {
  const [
    notificationTemplate,
    setNotificationTemplate,
  ] = useState<ITemplate | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const template = await getTemplateByTypyAndIndustry(
        notificationType,
        industryId,
      )

      setNotificationTemplate(template)
      setLoading(false)
    }

    if (notificationType && industryId) getData()
  }, [notificationType, industryId])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const template = await getTemplateById(templateId)

      setNotificationTemplate(template)
      setLoading(false)
    }

    if (templateId) getData()
  }, [templateId])

  return { notificationTemplate, loading }
}
