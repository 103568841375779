import { useEffect, useState } from 'react'
import { getProjectSettings } from 'api/Project'
import { ProjectSettings } from 'features/Project/types'

const useProjectSettings = () => {
  const [settings, setSettings] = useState<ProjectSettings | null>(null)

  useEffect(() => {
    const fetchSettings = async () => {
      const res = await getProjectSettings()

      setSettings(res.data.settings)
    }

    fetchSettings()
  }, [])

  return settings as ProjectSettings
}

export default useProjectSettings
