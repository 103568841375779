export function reorder<T>(
  list: T[],
  startIndex: number,
  endIndex: number,
  key = 'position',
) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  // result.map((item, index) => (item.sort_order = index))
  const lol = result.map((item, index) => ({ ...item, [key]: index }))

  return lol
}
