import * as types from '../types'
import moment from 'moment'
import { defaultProposalModuleNames } from '../../../common/constants'

export default (
  state = {
    currentContract: {
      billing_address: {
        address: '',
        postcode: '',
        city: '',
        state: '',
      },
    },
  },
  action,
) => {
  switch (action.type) {
    case types.service_contract.GET_SERVICE_CONTRACTS:
      return action.response
    case types.service_contract.CREATE_NEW_CONTRACT: {
      const newContract = JSON.parse(JSON.stringify(state))
      newContract.currentContract = {
        id: action.data.id,
        user_id: action.data.tech,
        property_id: action.data.property.id,
        owner_user_id: action.data.owner_user_id,
        type_id: action.data.type_id,
        source_id: action.data.source_id,
        updated_at: action.data.updated_at,
        created_at: action.data.created_at,
        name: '',
        code: action.data.number,
        notes: '',
        contract_notes: action.data.description,
        client_ids: [],
        modules: [
          {
            name: action.data.module_one || defaultProposalModuleNames.module_one,
            notes: '',
            status: 1, // or 1  (true/false)
          },
          {
            name: action.data.module_two || defaultProposalModuleNames.module_two,
            notes: '',
            status: 1, // or 1  (true/false)
          },
          {
            name: action.data.module_three || defaultProposalModuleNames.module_three,
            notes: '',
            status: 1, // or 1  (true/false)
          },
        ],
        contract_media: action.data.files ? Object.values(action.data.files) : [],
        instants: [],
        service_contract_appointments: [
          {
            id: 0,
            proposal_ids: [],
            date: new Date().getTime(),
            notes: '',
            status: 3,
            proposals: [],
          },
        ],
        customer_info: '',
        length_of_contract: 1,
        visits_per_year: 1,
        number_of_systems: 1,
        price: 0,
        total_to_pay: 0,
        first_service_date: moment(new Date()).format('YYYY-MM-DD'),
        last_service_complete_on: moment(new Date()).format('YYYY-MM-DD'),
        of_service_remaining: 1,
        expires_on: moment(new Date()).format('YYYY-MM-DD'),
        status: 1,
        items: [],
        billing_media: [],
        customer_media: [],
      }
      return newContract
    }
    case types.service_contract.SET_CURRENT_CONTRACT: {
      const NewCurrentContract = JSON.parse(JSON.stringify(state))
      NewCurrentContract.currentContract = action.data
      NewCurrentContract.currentContract.items = []
      NewCurrentContract.currentContract.medias = {}
      return NewCurrentContract
    }
    case types.service_contract.SET_FULL_PROPERTY: {
      return { ...state, property: action.response }
    }
    case types.service_contract.SERVICE_CHANGE_FIELD: {
      return { ...state, [action.field]: action.value }
    }
    default:
      return state
  }
}
