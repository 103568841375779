import React, { useEffect, useState, FC } from 'react'
import { useDispatch } from 'react-redux'
import { CallsBar } from '../CallsBar/CallsBar'
import { CallDrawer } from '../CallDrawer/CallDrawer'
import { getActiveCall } from 'api/Calls'
import { AppStateType } from 'store'
import { IIncomingCall, IIncomingStatusChanged } from 'features/Calls/types'
// import { drawerIsOpen, setPlaceFree } from 'store/Orcatec/reducers/matrix/matrixLog'
import { drawerIsOpen, setPlaceFree } from 'store/Orcatec/UI/uiSlice'
import { useQuery } from '@tanstack/react-query'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ModuleName,
  CallTrackingPermissions,
} from 'features/Settings/UsersAndGroups'
import { useAppSelector } from 'store/Orcatec/hooks'

interface IProps {
  me: AppStateType['orcatec']['user']['me']
  company: AppStateType['orcatec']['company']
  children: React.ReactElement
}

export const CallsProvider: FC<IProps> = ({ company, children }) => {
  const dispatch = useDispatch()
  const call_tracking = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.CALL_TRACKING,
      CallTrackingPermissions.CALL_TRACKING_CAN_READ,
    ),
  )
  const COMPANY_CALL_CHANNEL = `company-message.${company.id}`
  const CALL_INCOME_EVENT = '.call.incoming'
  const CALL_CHANGE_EVENT = '.call.status-changed'
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [callsList, setCallsList] = useState<IIncomingCall[]>([])
  const [render, setRender] = useState<number>(0)

  const toggleDrawer = () => {
    const widthWindow = window.innerWidth
    const widthSideBar = !openDrawer ? 780 : 0
    setOpenDrawer(prev => !prev)
    dispatch(drawerIsOpen(!openDrawer))
    dispatch(setPlaceFree(widthWindow - widthSideBar))
  }
  const changeCallStatusEvent = (incoming_call: IIncomingStatusChanged) => {
    const { uuid, status } = incoming_call
    setCallsList(prev => {
      return prev.map(prevCall => {
        if (prevCall.uuid === uuid) {
          return { ...prevCall, status: status }
        }
        return prevCall
      })
    })
  }

  const incomingCallsEvent = (incoming_call: IIncomingCall) => {
    const isNotEmpty = Object.keys(incoming_call).length
    if (isNotEmpty) {
      setCallsList(prev => [{ ...incoming_call, status: 2 }, ...prev])
    }
  }

  const fetchActiveCalls = async () => {
    try {
      const calls = await getActiveCall()
      setCallsList(calls)
      return calls
    } catch (error) {
      console.error(error)
    }
  }

  useQuery({
    queryKey: ['calls/incoming_table'],
    queryFn: fetchActiveCalls,
    enabled: !!call_tracking,
  })

  useEffect(() => {
    if (company?.id && render < 1 && call_tracking) {
      setRender(prev => prev + 1)
      window.Echo?.private(COMPANY_CALL_CHANNEL)
        .listen(
          CALL_INCOME_EVENT,
          ({ incoming_call }: { incoming_call: IIncomingCall }) =>
            incomingCallsEvent(incoming_call),
        )
        .listen(
          CALL_CHANGE_EVENT,
          ({ incoming_call }: { incoming_call: IIncomingStatusChanged }) =>
            changeCallStatusEvent(incoming_call),
        )
    }

    return () => !company?.id && window.Echo?.leave(COMPANY_CALL_CHANNEL)
  }, [company, company?.id])

  return (
    <>
      {children}
      {!!call_tracking && (
        <>
          <CallsBar
            counter={callsList?.filter(call => call?.status === 2)?.length}
            isOpen={openDrawer}
            onClick={toggleDrawer}
          />
          <CallDrawer
            setCallsList={setCallsList}
            callsList={callsList}
            isOpen={openDrawer}
            toggleDrawer={toggleDrawer}
          />
        </>
      )}
    </>
  )
}
