import UserFormContainer from './UserForm/UserFormContainer'
import { isUserFullSettings } from '../../../../../../helpers/isUserFullSettings'
import { Modal } from 'antd'

const ModalUserForm = props => {
  return (
    <Modal
      title={
        props.user
          ? props.isProfileSettings
            ? 'Edit Profile'
            : 'Edit User'
          : 'Create new user'
      }
      className='edit-user-settings-modal'
      visible={true}
      footer={null}
      closable={
        !props.user ? true : isUserFullSettings(props.user) ? true : false
      }
      maskClosable={false}
      width={500}
      onCancel={
        !props.user
          ? props.close
          : isUserFullSettings(props.user)
          ? props.close
          : undefined
      }
    >
      <UserFormContainer
        user={props.user}
        isProfileSettings={props.isProfileSettings}
        closeForm={props.close}
        currentPage={props.currentPage}
        activeUserCheckbox={
          props.activeUserCheckbox ? props.activeUserCheckbox : null
        }
        searchValue={props.searchValue ? props.searchValue : ''}
        isMapFixingNeeded={props.isMapFixingNeeded}
        setSubmitSuccess={props.setSubmitSuccess}
      />
    </Modal>
  )
}
export default ModalUserForm
