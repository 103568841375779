import { Switch } from 'antd'
import React, { Dispatch, SetStateAction, memo } from 'react'

interface CustomSwitcherProps {
  value: boolean
  onChange: Dispatch<SetStateAction<boolean>>
  leftOptionName: string
  rightOptionName: string
  disabled: boolean
}

import './CustomSwitcher.scss'

const CustomSwitcher: React.FC<CustomSwitcherProps> = props => {
  const {
    value = false,
    onChange,
    leftOptionName = '',
    rightOptionName = '',
    disabled = false,
  } = props
  return (
    <div className='custom-switcher__wrapper'>
      <span>{leftOptionName}</span>
      <Switch
        disabled={disabled}
        checked={value}
        onChange={checked => onChange?.(checked)}
      />
      <span>{rightOptionName}</span>
    </div>
  )
}

export default memo(CustomSwitcher)
