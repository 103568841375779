import styled from 'styled-components'

interface QueueEventProps {
  maxWidth: number
}
export const QueueEvents = styled.div<QueueEventProps>`
  display: flex;
  gap: 5px;
  max-width: 100vw;
  overflow: auto;
  position: relative;
`
interface BoxProps {
  small?: boolean
}

export const Box = styled.div<BoxProps>`
  flex: 0 0 60px;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 3px 0 5px 0px rgba(236, 236, 241, 0.84);
  overflow: hidden;

  @media (max-width: 500px) {
    flex-direction: column;
    flex: 0 0 20px;
    height: 20px;
  }
`
