import { QuestionCircleOutlined } from '@ant-design/icons'
import { Popover , Skeleton } from 'antd'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
  title: string
  description?: string | React.ReactNode
  withInfoIcon?: boolean
  required?: boolean
  loading: boolean
  isHide?: boolean
}

export const FormRow = ({ children, title, description, withInfoIcon, required, loading = false, isHide = false }: Props) => {
  const content = <Description>{description}</Description>

  return (!isHide ? <SettingsRowStyled>
    <Title>
      {title}
      {required && ' *'}
      {!!description && withInfoIcon && (
        <Popover title={title} content={content} placement='right' trigger={'click'}>
          <span
            style={{
              marginLeft: 5,
              cursor: 'help',
            }}
          >
            <QuestionCircleOutlined />
          </span>
        </Popover>
      )}
    </Title>

    <div>
      <Skeleton loading={loading}>
        {children}
        {!!description && !withInfoIcon && <Description>{description}</Description>}
      </Skeleton>
    </div>
  </SettingsRowStyled> : null
  )
}

const SettingsRowStyled = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  padding: 12px 0;
  border-bottom: 1px solid #0000000f;
`

const Title = styled.p`
  color: #707683;
`
const Description = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  color: #707683;
  white-space: break-spaces;

  p {
    margin-bottom: 5px;

    span {
      font-weight: 600;
    }
  }
`
