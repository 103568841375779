import { selectProjectGroupsBySection } from 'features/Project/projectSelectors'
import { useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import ItemsList from '../ItemsList'

interface Props {
  sectionId: number
  pricePermission: boolean
}

export const ProjectItems = ({ sectionId, pricePermission }: Props) => {
  const groups = useAppSelector(selectProjectGroupsBySection(sectionId))

  return (
    <Wrapper>
      <ItemsList
        sectionId={sectionId}
        groupId={null}
        pricePermission={pricePermission}
      />

      {groups?.map(group => (
        <ProjectItemsGroup key={group.id}>
          <GroupTitle>{group.name}</GroupTitle>

          <ItemsList
            sectionId={sectionId}
            groupId={group.id}
            pricePermission={pricePermission}
          />
        </ProjectItemsGroup>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`
const ProjectItemsGroup = styled.div``
const GroupTitle = styled.h6`
  font-size: 16px;
  font-weight: bold;
`
