import { Empty } from 'antd'
import { IHistoryItem } from './types'
import React, { useMemo } from 'react'
import HistoryItem from './components/HistoryItem'
import moment from 'moment-timezone'

export interface HistoryListProps {
  historyArray: IHistoryItem[]
}

export const HistoryList: React.FC<HistoryListProps> = props => {
  const { historyArray = [] } = props

  const memoizedHistoryList = useMemo(() => {
    return historyArray?.sort(
      (a, b) => +moment(b.send_at).format('YYYYMMDDhhmmss') - +moment(a.send_at).format('YYYYMMDDhhmmss'),
    )
  }, [historyArray])

  return (
    <div className='history'>
      {memoizedHistoryList.length === 0 ? (
        <Empty description='No notifications yet' />
      ) : (
        memoizedHistoryList.map((item, i) => (
          <HistoryItem item={item} onOpenFileHeandler={props?.onOpenFileHeandler} key={i} />
        ))
      )}
    </div>
  )
}
