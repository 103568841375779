import moment from 'moment-timezone'

const makeEmailTemplate = (
  state,
  proposalSections,
  // proposalForm,
  proposalCode,
  me,
  customSupplies,
  relatedJobs,
) => {
  const getFullItemString = item => {
    return item?.name ?? ''
  }
  const getFullItemsString = (itemsType, itemsName) => {
    return (
      '<br><br>Materials in this order:<br>' +
      state[`checked${itemsName}`].reduce((acc, { section, itemNum }) => {
        const item = proposalSections[section][itemsType][itemNum]
        const fullItemString = getFullItemString(item)
        acc = acc + `\xa0\xa0${item?.qty || 0} of ${fullItemString}` + '<br>'
        return acc
      }, '')
    )
  }

  const getCustomSuppliesString = supplies =>
    `Custom Items:<br>${supplies?.reduce(
      (prev, curr) => prev + `\xa0\xa0${curr.qty} of ${curr.description}<br>`,
      '',
    )}`

  const equipments = getFullItemsString('items', 'Equipment')
  const customItems = !!customSupplies?.length
    ? getCustomSuppliesString(customSupplies)
    : ''

  const firstActiveInstallDate = relatedJobs
    // ?.filter(date => new Date(date.date) >= new Date().setHours(0, 0, 0, 0))
    .find(date => date.status === 1)

  return `<p>Dear Supplier!<br><br>Please process this order as follows:<br>${
    firstActiveInstallDate
      ? `\xa0\xa0Job is scheduled for ${moment(
          firstActiveInstallDate.date,
        ).format(
          'MM/DD/YYYY',
        )}.<br>\xa0\xa0Please deliver materials 2 days prior to installation date`
      : `\xa0\xa0Deliver materials as soon as available.`
  }<br>\xa0\xa0PO#${proposalCode}<br>${equipments}<br>${customItems}<br>Thank you, ${
    me.full_name
  }</p>`
}

export default makeEmailTemplate
