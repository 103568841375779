import { FC } from 'react'
import styled from 'styled-components'
import { EntityType } from 'features/Project/types'

import { Button } from 'antd'
import { transformBtnTitle } from 'features/Project/Contacts/constants'
import { PlusIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'

interface SeparatorProps {
  entity_type: EntityType
  onClick: () => void
  disabled: boolean
}

export const ContactItemButton: FC<SeparatorProps> = ({
  entity_type,
  onClick,
  disabled,
}) => {
  return (
    <Button
      style={{ padding: '0px' }}
      disabled={disabled}
      type='link'
      onClick={onClick}
      size='small'
    >
      <Wrapper>
        <span>
          <PlusIcon />
        </span>{' '}
        <span>{` Attach ${transformBtnTitle[entity_type]}`}</span>
      </Wrapper>
    </Button>
  )
}

const Wrapper = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
`
