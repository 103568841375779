import React from 'react'
import './style.scss'
import { Empty, Pagination, Select, Input, Row, Col } from 'antd'
import { TextField, MenuItem } from '@material-ui/core'
import Preloader from 'containers/MainContent/Orcatec/components/Preloader/index.jsx'
import Spinner from '../../../../../adminModule/components/Spinner'
import { priceToView } from '../../../../../helpers/thousandSeparator'
import MainButton from '../buttons/MainButton'

const getClassNameFromProps = className => (className ? ` ${className}` : '')
class Table extends React.Component {
  state = {
    arrowDirection: -1,
    arrowColorId: -1,
  }

  onChangeLimit = limit => {
    this.props.onChangeLimit(limit)
  }

  onChangePage = (page, pageSize) => {
    this.props.onChangePage(page)
  }

  onChangeSortArrow = index => {
    index !== this.state.arrowDirectionIndex
      ? this.setState({ arrowDirectionIndex: index, arrowColorId: index })
      : this.setState({ arrowDirectionIndex: -1, arrowColorId: index })
  }

  onShowSizeChange = (current, pageSize) => {
    this.props.onChangeLimit(pageSize)
  }

  onShowSizeChange2 = e => {
    this.props.onChangeLimit(e.target.value)
  }

  render() {
    const { arrowDirectionIndex, arrowColorId } = this.state
    const { isFetching = false } = this.props

    let classStyle = ''
    let classWrapperStyle = ''
    if (this.props.isAppointment) {
      classStyle = 'appointment'
      classWrapperStyle = 'appointmentWrapper'
    }
    if (this.props.isSchedule) {
      classStyle = 'schedule'
      classWrapperStyle = 'scheduleWrapper'
    }

    const tableStyle = this.props.width
      ? {
          minWidth: this.props.width,
          width: this.props.width,
          maxWidth: this.props.maxWidth ? this.props.maxWidth : this.props.width,
        }
      : this.props.maxWidth
      ? { maxWidth: this.props.maxWidth }
      : this.props.minWidth
      ? { minWidth: this.props.minWidth }
      : {}

    const tableLayoutClassName = `custom-table-layout${getClassNameFromProps(this.props.parentClass)}`
    const tableWrapperClassName = `custom-table${getClassNameFromProps(classWrapperStyle)}${getClassNameFromProps(
      this.props.className,
    )}`
    const tableClassName = `${classStyle}${getClassNameFromProps(this.props.tableClassName)}`
    const tableHeaderClassName = this.props.disableClientsTableClass ? '' : 'clientsTableHead'
    const tableBodyClassName = this.props.disableClientsTableClass ? '' : 'clientsTableBody'

    const arrowUp = this.props.arrowUp ? this.props.arrowUp : 'mdi mdi-chevron-up mdi-24px'
    const arrowDown = this.props.arrowDown ? this.props.arrowDown : 'mdi mdi-chevron-down mdi-24px'

    const renderTableHeader = () => {
      return (
        this.props.rows.length !== 0 && (
          <thead className={tableHeaderClassName}>
            <tr>
              {this.props.head.map((item, index) => (
                <th
                  key={index}
                  onClick={() => {
                    this.onChangeSortArrow(index)
                    item.sort && this.props.onSort && this.props.onSort(item.sort_name)
                  }}
                >
                  <div>
                    <span>{item.label}</span>{' '}
                    {!!this.props.sortOrder &&
                      item.sort &&
                      (arrowColorId === index ? (
                        arrowDirectionIndex === index ? (
                          this.props.sortOrder === 'asc' ? (
                            <i className={arrowDown} style={{ opacity: 1 }} />
                          ) : (
                            <i className={arrowUp} style={{ opacity: 1 }} />
                          )
                        ) : this.props.sortOrder === 'desc' ? (
                          <i className={this.props.arrowUp ? this.props.arrowUp : { arrowUp }} style={{ opacity: 1 }} />
                        ) : (
                          <i className={arrowDown} style={{ opacity: 1 }} />
                        )
                      ) : arrowDirectionIndex === index ? (
                        <i className={arrowDown} />
                      ) : (
                        <i className={arrowDown} />
                      ))}
                    {!this.props.sortOrder &&
                      item.sort &&
                      (arrowColorId === index ? (
                        arrowDirectionIndex === index ? (
                          <i className={arrowUp} style={{ opacity: 1 }} />
                        ) : (
                          <i className={arrowDown} style={{ opacity: 1 }} />
                        )
                      ) : arrowDirectionIndex === index ? (
                        <i className={arrowDown} />
                      ) : (
                        <i className={arrowDown} />
                      ))}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
        )
      )
    }

    const renderTableBody = () => {
      const emptyUI = (
        <tr>
          <td colSpan={(this.props.head || ['']).length} style={{ border: 'none' }}>
            <Empty description={this.props.emptyText ? this.props.emptyText : 'No Data'} />
            {!!this?.props?.toggleAppointmentForm && (
              <MainButton
                title='Add Appointment'
                className='add-appointment-button'
                onClick={e => {
                  e.preventDefault()
                  this.props.toggleAppointmentForm()
                }}
              />
            )}
          </td>
        </tr>
      )

      if (this.props.rows.length !== 0 || this.props.modalOpened) return this.props.children
      else if (this.props.specificRows) return this.props.specificRows
      else if (this.props.isLoading) return <Preloader />
      else return emptyUI
    }

    const renderTableFooter = () =>
      this?.props?.footer?.length > 0 &&
      this?.props?.footer?.map((item, index) => (
        <>
          <tr style={{ height: '20px', marginTop: '5px', borderTop: '1px solid grey' }}>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td>
              {this.props.paggination !== false && (
                <div className='paggination'>
                  <Pagination
                    defaultCurrent={1}
                    current={Number(this.props.page)}
                    showTotal={(total, range) =>
                      this.props.hideTotal ? '' : `${range[0]}-${range[1]} of ${total || 10} items`
                    }
                    defaultPageSize={10}
                    pageSize={Number(this.props.pageSize) || 10}
                    onChange={this.onChangePage}
                    total={Number(this.props.total) || 10}
                    onShowSizeChange={this.onShowSizeChange}
                    hideOnSinglePage={true}
                    responsive={true}
                    totalBoundaryShowSizeChanger={100000}
                  />
                  {this.props.showSizeChanger && !!this.props.rows.length && (
                    <div className='custom-size-changer'>
                      <TextField
                        id='custom-pagesize-changer'
                        type='text'
                        select
                        name='limit'
                        value={Number(this.props.pageSize)}
                        onChange={this.onShowSizeChange2}
                        variant='outlined'
                        fullWidth={true}
                        size='small'
                      >
                        <MenuItem value={10}>10 / page</MenuItem>
                        <MenuItem value={25}>25 / page</MenuItem>
                        <MenuItem value={50}>50 / page</MenuItem>
                      </TextField>
                    </div>
                  )}
                </div>
              )}
            </td>
          </tr>
          <tr style={{ backgroundColor: '#d9d9d9' }} key={index} className={''}>
            <td style={{ textAlign: 'left' }}>{`Total`}</td>
            <td style={{ textAlign: 'left' }}>{''}</td>
            <td style={{ textAlign: 'left' }}>{priceToView(item?.total_to_pay)}</td>
            <td style={{ textAlign: 'left' }}>{priceToView(item?.total_balance_due)}</td>
            <td style={{ textAlign: 'left' }}>{priceToView(item?.total_balance_overdue)}</td>
            <td style={{ textAlign: 'left' }}>{priceToView(item?.total_balance_paid)}</td>
            <td style={{ textAlign: 'left' }}>{priceToView(item?.total_job_cost)}</td>
            <td style={{ textAlign: 'left' }}>{priceToView(item?.total_job_potential_profit)}</td>
            <td style={{ textAlign: 'left' }}>{priceToView(item?.total_job_current_profit)}</td>
            <td style={{ textAlign: 'left' }}>{priceToView(item?.total_tips ?? 0)}</td>
            <td style={{ textAlign: 'left' }}>{priceToView(item?.total_tax ?? 0)}</td>
            <td style={{ textAlign: 'left' }}>{priceToView(item?.total_commission)}</td>
            <td style={{ textAlign: 'left' }}>{''}</td>
            <td style={{ textAlign: 'left' }}>{''}</td>
          </tr>
        </>
      ))

    return (
      <div
        style={this.props.centered === true ? { margin: 'auto' } : {}}
        className={`${tableLayoutClassName} ${isFetching && 'faded'}`}
      >
        <div className={tableWrapperClassName}>
          <table className={tableClassName} style={tableStyle}>
            {renderTableHeader()}
            <tbody className={tableBodyClassName}>
              {isFetching && <Spinner />}
              {renderTableBody()}
              {this.props.footer && renderTableFooter()}
            </tbody>
          </table>
        </div>
        {this.props.paggination !== false && !this.props.footer && (
          <div className='paggination'>
            <Pagination
              defaultCurrent={1}
              current={Number(this.props.page)}
              showTotal={(total, range) =>
                this.props.hideTotal ? '' : `${range[0]}-${range[1]} of ${total || 10} items`
              }
              defaultPageSize={10}
              pageSize={Number(this.props.pageSize) || 10}
              onChange={this.onChangePage}
              total={Number(this.props.total) || 10}
              onShowSizeChange={this.onShowSizeChange}
              // hideOnSinglePage={true}
              responsive={true}
              totalBoundaryShowSizeChanger={100000}
            />
            {this.props.showSizeChanger && !!this.props.rows.length && (
              <div className='custom-size-changer'>
                <TextField
                  id='custom-pagesize-changer'
                  type='text'
                  select
                  name='limit'
                  value={Number(this.props.pageSize)}
                  onChange={this.onShowSizeChange2}
                  variant='outlined'
                  fullWidth={true}
                  size='small'
                >
                  <MenuItem value={10}>10 / page</MenuItem>
                  <MenuItem value={25}>25 / page</MenuItem>
                  <MenuItem value={50}>50 / page</MenuItem>
                </TextField>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default Table
