import {
  RotateRight,
  RotateLeft,
  ZoomIn,
  ZoomOut,
  Restore,
  OpenInBrowser,
  CloudDownload,
  ChevronLeft,
  ChevronRight,
  Close,
  FormatListBulleted,
} from '@material-ui/icons'
import { Spin, Tooltip } from 'antd'

export const ActionButton = ({
  type = '',
  isVisible = true,
  actionHeandler = () => null,
  btnClassName,
  tooltipTitle = 'Title',
  disabled = false,
  loading = false,
}) => {
  let icon = ''
  switch (type) {
    case 'next': {
      icon = <ChevronRight />
      break
    }

    case 'prev': {
      icon = <ChevronLeft />
      break
    }

    case 'rotateRight': {
      icon = <RotateRight />
      break
    }

    case 'rotateLeft': {
      icon = <RotateLeft />
      break
    }

    case 'restore': {
      icon = <Restore />
      break
    }
    case 'zoomIn': {
      icon = <ZoomIn />
      break
    }
    case 'zoomOut': {
      icon = <ZoomOut />
      break
    }

    case 'download': {
      icon = <CloudDownload />
      break
    }

    case 'openTab': {
      icon = <OpenInBrowser />
      break
    }

    case 'close': {
      icon = <Close />
      break
    }

    case 'menu': {
      icon = <FormatListBulleted />
      break
    }
    default: {
      break
    }
  }

  return (
    <button
      style={!isVisible ? { display: 'none' } : null}
      onClick={actionHeandler}
      disabled={disabled}
      className={btnClassName}
    >
      <Spin spinning={loading}>
        <Tooltip placement='bottom' title={tooltipTitle}>
          {icon}
        </Tooltip>
      </Spin>
    </button>
  )
}
