import React, { useEffect, useState, FC } from 'react'
import { Select } from 'antd'
import styled from 'styled-components'
import {
  getTemplatesListByType,
  getTemplateByTypyAndIndustry,
  getTemplateById,
} from 'api/NotificationModal'
import {
  NotificationTemplateType,
  NotificationModalType,
} from 'features/Notification/types'
import { IAppointment } from 'types/Appointment'
import { Job } from 'types/Jobs'
import { parseMessageVariables } from 'features/Notification/modals/NotificationModal/parseMessageVariables'

interface IProps {
  event: IAppointment | Job
}
const proposalForm = {}
const proposalLink = ''
export const TemplateSelect: FC<IProps> = ({
  event,
  template,
  setTemplate,
}) => {
  const [templateList, setTemplateList] = useState([])

  const { parseEmailMessage } = parseMessageVariables({
    appointment: event,
    proposalForm,
    proposalLink,
  })

  const templateParser = template => {
    return {
      ...template,
      email: {
        ...template.email,
        body: parseEmailMessage(template.email.body),
        from_name: parseEmailMessage(template.email.from_name),
        reply_to: parseEmailMessage(template.email.reply_to),
        subject: parseEmailMessage(template.email.subject),
      },
      sms: {
        ...template.sms,
        body: parseEmailMessage(template.sms.body),
      },
    }
  }

  const handleSelectTemplate = async value => {
    try {
      const template = await getTemplateById(value)

      const parsedTemplate = templateParser(template)

      setTemplate(parsedTemplate)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const res = await getTemplatesListByType(
          NotificationTemplateType.APPOINTMENT,
        )
        setTemplateList(res.map(item => ({ label: item.name, value: item.id })))
      } catch (error) {
        console.error(error)
      }
    }
    fetchTemplate()
  }, [])

  useEffect(() => {
    const getAppointmentTemplate = async () => {
      try {
        const template = await getTemplateByTypyAndIndustry(
          NotificationModalType.UPCOMING,
          event.industry_id,
        )
        const parsedTemplate = templateParser(template)
        setTemplate(parsedTemplate)
      } catch (error) {
        console.error(error)
      }
    }
    if (event.industry_id) getAppointmentTemplate()
  }, [event.industry_id])
  return (
    <Wrapper>
      <InputWrapper>
        <InputTitle>Template to Send:</InputTitle>
        <InputBlock>
          <Select
            onChange={handleSelectTemplate}
            placeholder='Select template'
            style={{ width: '100%' }}
            options={templateList}
            value={template?.id}
          />
        </InputBlock>
      </InputWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 186px auto;
  align-items: center;
`
const InputTitle = styled.div``
const InputBlock = styled.div`
  padding-left: 28px;
  display: flex;
  align-items: center;
  gap: 5px;
`
