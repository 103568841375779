import { Checkbox, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ContactI, ContactWithMessage } from '../../types'

interface Props {
  data: ContactI[]
  disabled: boolean
  error: boolean
  selectedContacts: Record<number, ContactWithMessage>
  /*  selectedEmails: string[]
  selectedPhones: string[] */
  title: string
  onCheckAll: (checked: boolean) => void
  onSelect: (type: 'emails' | 'phones', value: string, techId: ContactI) => void
  // onSelectPhone: (checked: boolean, phone: string, tech: ContactI) => void
}

export const ContactsList = ({
  data,
  disabled,
  error,
  selectedContacts,
  /*  selectedEmails,
  selectedPhones, */
  title,
  onCheckAll,
  onSelect,
}: // onSelectPhone,
Props) => {
  // const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    onCheckAll(e.target.checked)
    // setIndeterminate(false)
    // setCheckAll(e.target.checked)
  }

  useEffect(() => {
    // setIndeterminate(
    //   !!selectedEmails.length && selectedEmails.length < data.length,
    // )
    setCheckAll(areAllSelected)
  }, [selectedContacts])

  const contactsEmails = data.flatMap(contact => contact.emails || [])
  const contactsPhones = data.flatMap(contact =>
    disabled ? [] : contact.phones || [],
  )
  const selectedContactsPhones = Object.values(selectedContacts).flatMap(
    contact => contact.phones || [],
  )
  const selectedContactsEmails = Object.values(selectedContacts).flatMap(
    contact => contact.emails || [],
  )
  const areAllSelected =
    selectedContactsEmails.length + selectedContactsPhones.length ===
    contactsEmails.length + contactsPhones.length

  return (
    <Wrapper>
      <Heading>
        <Title>{title}:</Title>

        <Checkbox
          indeterminate={
            !checkAll &&
            !![...selectedContactsPhones, ...selectedContactsEmails].length
          }
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Check all
        </Checkbox>
      </Heading>

      <List>
        {data?.map(contact => (
          <Item key={contact.id}>
            <Name>{contact.name}</Name>

            <Data>
              {contact.emails?.map((email, idx) => (
                <DataItem key={email + idx}>
                  <StyledCheckbox
                    error={error}
                    checked={selectedContacts?.[contact?.id]?.emails?.includes(
                      email,
                    )}
                    onChange={() => onSelect('emails', email, contact)}
                  />
                  <Text color='#3f57ac'>{email}</Text>
                </DataItem>
              ))}
              {contact.phones?.map((phone, idx) => (
                <DataItem key={phone + idx + contact.id}>
                  <Tooltip
                    title={disabled ? 'Messaging service isn`t setup yet' : ''}
                    mouseLeaveDelay={0}
                  >
                    <StyledCheckbox
                      error={error}
                      checked={selectedContacts?.[
                        contact?.id
                      ]?.phones?.includes(phone)}
                      // checked={selectedPhones.includes(phone)}
                      onChange={() => onSelect('phones', phone, contact)}
                      disabled={disabled}
                    />
                    <Text>{phone}</Text>
                  </Tooltip>
                </DataItem>
              ))}
            </Data>
          </Item>
        ))}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Heading = styled.div`
  display: grid;
  grid-template-columns: 210px 1fr;
  gap: 1rem;
  align-items: center;
`
const Title = styled.h6`
  margin-top: 10px;
  color: ${props => props.theme.font.secondary};
`
const List = styled.div`
  margin-left: 10px;
`
const Item = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 1rem;
  border-bottom: 0.3px solid #19191921;
  padding: 10px 0;
`
const Data = styled.div``
const Name = styled.p`
  /* font-size: 16px; */
  font-weight: 500;
`
const DataItem = styled.p`
  /* color: #3f57ac; */
  /* font-size: 16px; */
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
`

const Text = styled.span<{ color?: string }>`
  color: ${props => props.color || props.theme.font.main};
`

const StyledCheckbox = styled(Checkbox)<{ error: boolean }>`
  .ant-checkbox-inner {
    border-color: ${props => (props.error ? '#f12832' : '#d9d9d9')};
  }
`
