import React from 'react'
import Map from '../Map/Map'
import './ManualAddress.scss'
import { Row, Col, Button } from 'antd'
import { TextField, MenuItem } from '@material-ui/core'
import AddressInputMaterial from '../../../CreateAppointment/components/PropertySection/components/SearchAddressMaterial'
import MainButton from '../../buttons/MainButton'
import Tags from '../../Tags/Tags'
import CustomAddressAutocompleteContainer from '../../CustomAddressAutocomplete/CustomAddressAutocompleteContainer'
import { transformStringIfAddressInHere } from 'helpers/transformStringIfAddressInHere'
const cancelButtonStyle = { color: '#191919', border: '1px solid #D6D6D6', height: '40px' }
const inputProps = {
  autoComplete: 'off',
}
export const ManualAddress = ({
  isMapFixingNeeded = false,
  onChangeTextField,
  addressData,
  addressDataErrors,
  onChoosePostcode,
  onSubmit,
  onCloseForm,
  isCoordsChoosenFromMap,
  setMarker,
  poligon,
  rectangle,
  tempTagValue,
  onAddTag,
  onDeleteTag,
  temporary_notes,
  onChangeTemporaryNotes,
  isCoordsWasChoosenFromOutOfManualAddress,
  tempAddressValue,
  changeTempAddressValue,
  onChooseInDropdown,
  choosenFromHelperSearch,
  okButtonText = '',
}) => {
  const marker = !!addressData.lat && !!addressData.lng ? [+addressData.lat, +addressData.lng] : null
  return (
    <div className='manual-address-container'>
      <Row gutter={[55, 24]}>
        <Col xl={12}>
          <Row>
            {!!onChangeTemporaryNotes && (
              <Col span={24}>
                <TextField
                  size='small'
                  multiline
                  label='Temporary Notes'
                  maxRows={5}
                  name='tempNotesValue'
                  value={temporary_notes}
                  onChange={onChangeTemporaryNotes}
                  variant='outlined'
                  inputProps={{
                    maxLength: 3000,
                    ...inputProps,
                  }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Col>
            )}
            <Col span={24}>
              <TextField
                id={transformStringIfAddressInHere('manual-address')}
                type='text'
                label={transformStringIfAddressInHere('Property Address*')}
                name={transformStringIfAddressInHere('address')}
                value={addressData.address}
                onChange={onChangeTextField}
                variant='outlined'
                error={!!addressDataErrors.address}
                helperText={addressDataErrors.address}
                fullWidth={true}
                size='small'
                inputProps={inputProps}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col sm={5} xs={24}>
                  {/* !!!!!!!! If search by postcodes in Pelias will work wright use 
                  CustomAddressAutocompleteContainer component instead of AddressInputMaterial */}

                  {/* <CustomAddressAutocompleteContainer
                    id='manual-postcode'
                    value={addressData.postcode}
                    customPrefixToSearchValue=''
                    customSuffixToSearchValue={''}
                    onChangeValue={onChangeTextField}
                    onChoose={onChoosePostcode}
                    error={addressDataErrors.postcode}
                    name='postcode'
                    searchType='postcode'
                    placeholder=''
                    label='ZIP*'
                  /> */}
                  <div style={{ marginTop: '-24px' }}>
                    <AddressInputMaterial
                      onChoose={onChoosePostcode}
                      value={addressData.postcode}
                      onChange={onChangeTextField}
                      errorMessage={addressDataErrors.postcode}
                      label={transformStringIfAddressInHere('ZIP*')}
                      name={transformStringIfAddressInHere('postcode')}
                      returnEvent={true}
                      id={transformStringIfAddressInHere('manual-postcode')}
                      labelToDropdawn='postcode'
                      disableClearable={true}
                    />
                  </div>
                </Col>
                <Col md={14} sm={14} xs={24}>
                  <TextField
                    id={transformStringIfAddressInHere('manual-city')}
                    type='text'
                    label={transformStringIfAddressInHere('City*')}
                    name={transformStringIfAddressInHere('city')}
                    value={addressData.city}
                    onChange={onChangeTextField}
                    variant='outlined'
                    error={!!addressDataErrors.city}
                    helperText={addressDataErrors.city}
                    fullWidth={true}
                    size='small'
                    inputProps={inputProps}
                  />
                </Col>
                <Col md={5} sm={5} xs={24}>
                  <TextField
                    id={transformStringIfAddressInHere('manual-state')}
                    type='text'
                    label={transformStringIfAddressInHere('State*')}
                    name={transformStringIfAddressInHere('state')}
                    value={addressData.state}
                    onChange={onChangeTextField}
                    variant='outlined'
                    error={!!addressDataErrors.state}
                    helperText={addressDataErrors.state}
                    fullWidth={true}
                    size='small'
                    inputProps={inputProps}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col md={12} sm={12} xs={24}>
                  <TextField
                    size='small'
                    id={transformStringIfAddressInHere('manual-unit')}
                    name={transformStringIfAddressInHere('unit')}
                    type='text'
                    inputProps={{
                      maxLength: 10,
                      ...inputProps,
                    }}
                    variant='outlined'
                    label={transformStringIfAddressInHere('Unit')}
                    value={addressData.unit ? addressData.unit : ''}
                    onChange={onChangeTextField}
                    error={!!addressDataErrors.unit}
                    helperText={addressDataErrors.unit}
                    fullWidth
                  />
                </Col>
                <Col md={12} sm={12} xs={24}>
                  <TextField
                    size='small'
                    id='type'
                    name='type'
                    variant='outlined'
                    label='Property type'
                    value={addressData.type ? +addressData.type : null}
                    onChange={onChangeTextField}
                    select
                    error={!!addressDataErrors.type}
                    helperText={addressDataErrors.type}
                    fullWidth
                  >
                    <MenuItem value={1}>Commercial</MenuItem>
                    <MenuItem value={2}>Residential</MenuItem>
                  </TextField>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <TextField
                size='small'
                multiline
                label='Property Notes'
                rows='5'
                name='notes'
                value={addressData.notes ? addressData.notes : ''}
                onChange={onChangeTextField}
                variant='outlined'
                inputProps={{
                  maxLength: 3000,
                  ...inputProps,
                }}
                fullWidth
              />
            </Col>
            <Col span={24}>
              <Tags
                values={addressData.tags}
                tempTagValue={tempTagValue}
                handleFieldChange={onChangeTextField}
                onAddTag={onAddTag}
                onDeleteTag={onDeleteTag}
                tempTagValueError={addressDataErrors.tags}
              />
            </Col>
            <Col span={24}>
              <div className='manual-address-container-buttons'>
                <Button
                  variant='default'
                  onClick={onCloseForm}
                  className='main-button user-cancel-btn'
                  style={cancelButtonStyle}
                >
                  Cancel Manual Input
                </Button>

                {/* <MainButton title='Cancel Manual Input' onClick={onCloseForm} className='main-button' /> */}
                <MainButton title={okButtonText} onClick={onSubmit} className='main-button' />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={12}>
          <CustomAddressAutocompleteContainer
            value={tempAddressValue}
            customPrefixToSearchValue=''
            customSuffixToSearchValue={
              !!addressData.postcode && /^\d{5}$/.test(addressData.postcode) ? `, ${addressData.postcode}` : ''
            }
            onChangeValue={changeTempAddressValue}
            onChoose={onChooseInDropdown}
            addressType='pelias'
            placeholder='ex. Rancho Bernardo Road & Bernardo Center Drive, San Diego, CA, USA'
            label='Search nearby or intersection'
          />
          <span
            id='manual-lat'
            className={
              'manual-address-container-costom-text' + (addressDataErrors.lat ? ' manual-address-container-error' : '')
            }
          >
            Please place the pin on the map for GPS routing
          </span>
          <div
            className={
              'manual-address-container-map' + (addressDataErrors.lat ? ' manual-address-container-map-error' : '')
            }
          >
            <Map
              isMapFixingNeeded={isMapFixingNeeded}
              marker={marker}
              isCoordsChoosenFromMap={isCoordsChoosenFromMap}
              setMarker={setMarker}
              poligon={poligon}
              rectangle={addressData.bbox}
              isCoordsWasChoosenFromOutOfManualAddress={isCoordsWasChoosenFromOutOfManualAddress}
              choosenFromHelperSearch={choosenFromHelperSearch}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}
