export const proposalTabs = state => state.orcatec.proposalForm.proposal_tabs
export const currentTabIdx = state => state.orcatec.proposalForm.activeTab
export const modalEquipment = state => state.orcatec.modalEquipment
export const proposalForm = state => state.orcatec.proposalForm
export const suppliers = state => state.orcatec.supplier.list
export const me = state => state.orcatec.user.me
export const proposalCode = state => state.orcatec.projectSlice.project.code

export const proposalSections = state =>
  state.orcatec.proposalForm?.proposal_tabs[
    state.orcatec.proposalForm?.activeTab
  ]?.proposal_sections
export const tabId = state =>
  state.orcatec.proposalForm?.proposal_tabs[
    state.orcatec.proposalForm.activeTab
  ]?.id
export const currentTab = state =>
  state.orcatec.proposalForm?.proposal_tabs[
    state.orcatec.proposalForm.activeTab
  ] || {}

export const noItems = state => {
  let isHaveItem = true
  state.orcatec.proposalForm?.proposal_tabs[
    state.orcatec.proposalForm?.activeTab
  ]?.proposal_sections?.forEach(section => {
    if (section?.items?.length) isHaveItem = false
    // if (section.parts && section.parts?.length) isHaveItem = false
    // if (section.supplies && section.supplies?.length) isHaveItem = false
  })
  return isHaveItem
}
