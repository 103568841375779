import React, { useEffect, useMemo, useRef, useState } from 'react'
import MainButton from '../../components/buttons/MainButton'
import { BackToLoginBtn } from '../../Layouts/AuthLayout/BackToLoginBtn'
import Card from '../../Settings/Subscription/Card'
import './style.scss'
import ModalUpdateCard from '../../Settings/Subscription/Tabs/TabSubscription/ModalUpdateCard'
import { checkCardError } from '../../Settings/Subscription/Tabs/TabSubscription/InfoSection'

const requiredText = 'This field is required'
const PagesRegisterCard = ({
  isCreatingCardProfile,
  onCancel,
  submitCard,
  visible,
  cancelBtnText = '',
  hideCancelBtn,
}) => {
  const [data, setData] = useState({
    billing_address: {},
  })
  const [error, setError] = useState(false)
  const parentEl = useRef(null)
  const [sending, setSending] = useState(false)
  const [loading, setLoading] = useState(false)

  const [fieldErrors, setFieldErrors] = useState({})
  const [isNewCard, setIsNewCard] = useState(false)
  const [isOldCardChanged, setIsOldCardChanged] = useState(false)

  const wasEdited = isNewCard || isOldCardChanged

  const onSubmit = () => validationCard()

  useEffect(() => {
    if (sending) {
      sendDataOfCard()
    }
  }, [sending])

  /* const validationCard = () => {
    if (
      !data.card_holder ||
      !data.billing_address.region ||
      !data.billing_address.city ||
      !data.billing_address.address ||
      !data.billing_address.postal
    ) {
      parentEl.current.className += ' error_card'
      setError(true)
    } else {
      setTimeout(() => setSending(true), 1000)
    }
  } */

  const validationCard = () => {
    if (
      !data.card_holder ||
      !data.billing_address.region ||
      !data.billing_address.city ||
      !data.billing_address.address ||
      !data.billing_address.postal ||
      !data.token ||
      !data.expiry
    ) {
      setFieldErrors(prev => ({
        ...prev,
        card_holder: !data.card_holder ? requiredText : '',
        region: !data.billing_address.region ? requiredText : '',
        city: !data.billing_address.city ? requiredText : '',
        address: !data.billing_address.address ? requiredText : '',
        postal: !data.billing_address.postal ? requiredText : '',
        cvv: prev.cvv ? prev.cvv : !data.token || !data.expiry ? requiredText : '',
        expiry: prev.expiry ? prev.expiry : !data.token || !data.expiry ? requiredText : '',
        cardNumber: prev.cardNumber ? prev.cardNumber : !data.token || !data.expiry ? requiredText : '',
      }))
      setError(true)
    } else {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        setSending(true)
      }, 1000)
    }
  }

  const sendDataOfCard = () => {
    if (data.token && data.expiry) {
      submitCard(data)
        .then(() => {
          setError(false)
        })
        .catch(err => {
          const cardError = checkCardError(err)
          if (cardError) {
            setError(cardError)
          }
        })
        .finally(() => {
          setSending(false)
        })
    } else {
      setSending(false)
      setError(true)
    }
  }

  return (
    <div className='card-page'>
      <div className='main-text card-main-text'>Payment information</div>
      <div className='charged-text'>You card will not be charged at this moment.</div>
      <Card
        cardData={data}
        setData={setData}
        parentEl={parentEl}
        setError={setError}
        error={error}
        visible={typeof visible === 'boolean' ? visible : true}
        fieldErrors={fieldErrors}
        setFieldErrors={setFieldErrors}
        setIsNewCard={setIsNewCard}
        setIsOldCardChanged={setIsOldCardChanged}
      >
        <div className='buttons-block'>
          <MainButton
            title='Proceed'
            onClick={onSubmit}
            isFetching={isCreatingCardProfile || loading}
            className='w-100 waves-effect waves-light main-button'
            disabled={!!error || !wasEdited}
          />
          {!hideCancelBtn && <BackToLoginBtn title={cancelBtnText ? cancelBtnText : 'Back'} onClick={onCancel} />}
        </div>
      </Card>
    </div>
  )
}

export default PagesRegisterCard
