import styled from 'styled-components'

export const Info = styled.div``
export const Title = styled.p`
  font-weight: 500;
`

export const Text = styled.p`
  color: #777e91;
`

export const Row = styled.div`
  display: flex;
  gap: 15px;
`

export const Section = styled.div`
  & > * {
    margin-bottom: 15px;
  }
`
export const SectionHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0 10px;
`

export const SectionTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
`

export const AddressWrapper = styled.div<{ error: boolean }>`
  & > div > div > p {
    font-size: 0.75rem;
    color: #808080;
    line-height: 1.5;
    margin-bottom: 3px;

    span {
      color: #f1453d;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${props => (props.error ? '#f44336' : '#d9d9d9')};
  }

  .MuiOutlinedInput-root {
    font-size: 14px;
    height: 32px;
    border-radius: 2px;

    &:hover:not(.Mui-error):not(.Mui-disabled) fieldset {
      border-color: ${props => (props.error ? '#f44336' : '#40a9ff')};
    }
  }
`
export const Error = styled.p`
  margin-top: 2px;
  font-size: 0.7rem;
  color: #f12832;
`
