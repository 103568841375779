import './style.scss'

type IProps = (
  array: string[],
  record: object,
  title: string,
  clickedRowIndex: number | null,
) => void
export const TablePopover: IProps = array => {
  if (!array || !array.length) return '-'
  return (
    <>
      {array?.[0]}{' '}
      {array?.length > 1 ? (
        <span className='rest-items'>+{array?.length - 1}</span>
      ) : (
        ''
      )}
    </>
  )
}
