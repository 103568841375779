import React, { useState } from 'react'
import { connect } from 'react-redux'

import ListingModal from './components/ListingModal'
import CreateTicketModal from './components/CreateTicketModal'
import TicketModal from './components/TicketModal'

const LISTING_MODE = 1
const CREATE_TICKET_MODE = 2
const TICKET_MODE = 3

const Support = ({ onClose, me }) => {
  const [mode, setMode] = useState(LISTING_MODE)
  const [selectedTicketId, setSelectedTicketId] = useState(null)

  const handleClose = () => {
    onClose()
  }

  const handleBackToListing = () => {
    handleTo(LISTING_MODE)
  }

  const handleTo = type => {
    setMode(type)
  }

  const handleToTicket = id => {
    handleTo(TICKET_MODE)
    setSelectedTicketId(id)
  }

  const handleFromTicket = () => {
    handleBackToListing()
    setSelectedTicketId(null)
  }

  const listingModalRender = (
    <ListingModal
      isShow={mode === LISTING_MODE}
      onHide={handleClose}
      toCreate={() => handleTo(CREATE_TICKET_MODE)}
      toTicket={handleToTicket}
      me={me}
    />
  )

  const createTicketModalRender = (
    <CreateTicketModal isShow={mode === CREATE_TICKET_MODE} onHide={handleBackToListing} me={me} />
  )

  const ticketModalRender = (
    <TicketModal
      isShow={mode === TICKET_MODE && selectedTicketId}
      onHide={handleFromTicket}
      onClose={handleClose}
      me={me}
      ticketId={selectedTicketId}
    />
  )

  return (
    <>
      {listingModalRender}
      {createTicketModalRender}
      {ticketModalRender}
    </>
  )
}

const mapStateToProps = state => ({
  me: state.orcatec.user.me,
})

export default connect(mapStateToProps)(Support)
