import { Media } from 'types/Media'
import { UsageInfo } from './components/SubscriptionPlan/PlanUsage'

export const SubscriptionRoutes = [
  {
    id: 1,
    title: 'Billing',
    path: '/settings/subscription',
  },
  {
    id: 2,
    title: 'My Plan',
    path: '/settings/subscription/my-plan',
  },
  {
    id: 3,
    title: 'All Plans',
    path: '/settings/subscription/all-plans',
  },
]

export enum SubscriptionStatus {
  Active = 1,
  Pending,
  'View only',
  Inactive,
  Cancelled,
}

export interface IBilling {
  cancel_charge: boolean
  days_till_system_blocked: number
  features: Features
  id: number
  next_billing: number
  next_plan: SubscriptionPlan
  overdue_amount: number
  payment_due: string
  plan_price?: number
  plan: SubscriptionPlan
  status: SubscriptionStatus
  summary: string
  total_price: number
}

export enum SubscriptionPlan {
  'Free' = 1,
  'Basic',
  'Basic +',
  'Pro',
  'Enterprise',
}
export interface Features {
  storage: Storage
  users: Storage
  map: Accounting
  recurring_appointments: Accounting
  request_review: Accounting
  time_cards: Accounting
  gps: Accounting
  todo: Accounting
  quickbooks: Accounting
  accounting: Accounting
  memberships: Accounting
  messaging_info: MessagingInfo
  call_record_info: CallRecordInfo
}

export interface Accounting {
  title: string
  amount: number
}

export interface CallRecordInfo {
  show_info: boolean
  call_records: CallRecords
  phones: CallRecords
  call_monthly_fee: number
}

export interface CallRecords {
  count: number
  price_each: number
  total: number
}

export interface MessagingInfo {
  sms_mms: CallRecords
  phones: CallRecords
  messaging_monthly_fee: number
}

export interface Storage {
  title: string
  info: string
  used: string
  amount: number
}

// Generated by https://quicktype.io

export interface PaymentMethod {
  id: number
  token: string
  card_holder: string
  expiry: string
  card_number: string
  payment_system:
    | 'Amex'
    | 'Discover'
    | 'Mastercard'
    | 'Visa'
    | 'Non-co-branded debit card'
    | null
  is_default: boolean
  billing_address: BillingAddress
}

export interface BillingAddress {
  address: string
  city: string
  region: string
  postal: string
}

// Generated by https://quicktype.io

export interface SubscriptionOptionalFeatures {
  accounting: Info
  ach_info: Info
  call_record_info: Info
  custom_forms: Info
  external_link_info: Info
  gps: Info
  map: Info
  messaging_info: Info
  quickbooks: Info
  recurring_appointments: Info
  request_review: Info
  time_cards: Info
  todo: Info
  transaction_info: Info
}

export interface Info {
  title: string
  status: boolean
  price: number
}

export type PlanUsage = Record<
  keyof typeof UsageInfo,
  { used: number; total: number; price: number }
>

/*
**
  Old types
**
*/

export interface ISubscriptionData {
  id: number
  company_id: number
  profile_id: string
  token: string
  used_storage: number
  first_charge: string
  next_charge: string
  billing_address: BillingAddress
  card_holder: string
  status: number
  created_at: string
  updated_at: string
  expiry: string
  cancel_charge: boolean
  attempt: number
  days_until_change_status: number
  date_charge_by_debt: null
  date_next_attempt: null
  modules: Modules
  fail_charge_day: null
  user_price: string
  storage_price: string
  ach_price: string
  credit_card_price: string
  price_for_user: string
  total_price_basic_modules: number
  price_for_optional_module: number
  price_for_storage: string
  day_charge: string
  active_users: number
  total_price_op_modules: number
  total_price_users: number
  total_price_storage: number
  total_amount: string
  card_number: string
  is_trail_period: boolean
  take_card_time: boolean
  next_billing: string
  days_left: number
  subscription_invoices: null
  bills: Bills
  schedule_charge: Date[]
  current_journal: CurrentJournal[]
  company: Company
}

// export interface BillingAddress {
//   address: string
//   city: string
//   region: string
//   postal: string
// }

export interface Bills {
  count_checks: number
  total_amount_checks: number
  count_transactions: number
  total_amount_cards: number
}

export interface TimeCardSettings {
  clock_out_time: string
  clock_out_timeout: number
  clock_out_type: number
}

export interface Company {
  id: number
  owner_id: number
  account_manager_id: number
  company_name: string
  company_email: string
  company_phone: string
  industry: string[]
  fax: string
  status: number
  logo: string | BinaryData | null
  social_urls: SocialUrls
  address: Address
  term_and_conditions: { file0: string | Media[] }
  business_hours: BusinessHours
  monthly_subscription: null
  total_monthly_distributor: null
  total_monthly_company: null
  created_at: string
  updated_at: string
  deleted_at: null
  rate: number
  contract_renewal_date: null
  invoice_identifier: string
  secondary_account_manager_id: null
  secondary_renewal_rate: null
  secondary_rate: null
  renewal_rate: null
  secondary_contract_renewal_date: null
  primary_ac_assigned_at: Date
  secondary_ac_assigned_at: null
  made_admin: boolean
  subscription_off: boolean
  last_activity_at: Date
  is_orcatec_corporate: boolean
  is_public_appointment_enabled: boolean
  timezone: string
  start_day_of_week: number
  exclude_from_statistic: boolean
  merchant: CompanyMerchant
  time_card_settings: TimeCardSettings
}

export interface Address {
  address: string
  city: string
  state: string
  zip: string
  lat: string
  lng: string
  unit: string
}

export interface BusinessHours {
  Mon: Fri
  Tue: Fri
  Wed: Fri
  Thu: Fri
  Fri: Fri
  Sat: Fri
  Sun: Fri
  business_hours_changed_by_data: BusinessHoursChangedByData
}

export interface Fri {
  from: string
  to: string
  checked: string
}

export interface BusinessHoursChangedByData {
  user_id: string
  user_name: string
  date: Date
}

export interface CompanyMerchant {
  id: number
  company_id: number
  merchant_id: string
  customer_id: string
  boardingProcessStatusCd: string
  gatewayBoardingStatusCd: string
  signatureUrl: string
  signatureStatusCd: string
  body: Body
  created_at: Date
  updated_at: Date
  backEndMid: string
  username: string
  password: string
}

export interface Body {
  templateId: string
  merchant: BodyMerchant
}

export interface BodyMerchant {
  dbaName: string
  legalBusinessName: string
  taxFilingMethod: string
  taxFilingName: string
  demographic: Demographic
  bankDetail: BankDetail
  ownership: Ownership
  pricing: Pricing
  processing: Processing
  salesCode: string
}

export interface BankDetail {
  depositBank: Bank
  withdrawalBank: Bank
}

export interface Bank {
  bankAcctNum: string
  bankRoutingNum: string
  bankAcctTypeCd: string
  bankName: string
}

export interface Demographic {
  businessAddress: BusinessAddressClass
  mailingAddress: BusinessAddressClass
}

export interface BusinessAddressClass {
  address1: string
  city: string
  stateCd: string
  zip: string
}

export interface Ownership {
  owner: Owner
  ownershipTypeCd: string
  driversLicenseNumber: string
  driversLicenseStateCd: string
}

export interface Owner {
  ownerAddress: BusinessAddressClass
  ownerEmail: string
  ownerName: string
  ownerPhone: string
  ownerMobilePhone: string
  ownerSSN: string
  ownerTitle: string
}

export interface Pricing {
  icPlusPricing: ICPlusPricing
}

export interface ICPlusPricing {
  interchangeTypeCode: string
  visa: Discover
  mastercard: Discover
  discover: Discover
  amex: Amex
}

export interface Amex {
  optBlueQualDiscountPct: number
}

export interface Discover {
  qualCreditDiscountPct: number
}

export interface Processing {
  platformDetails: PlatformDetails
}

export interface PlatformDetails {
  amexProgramAssetCd: string
  discoverProgramCd: string
  acquiringFlg: boolean
}

export interface SocialUrls {
  yelp: string
  facebook: string
  google: string
  linkedin: string
}

export interface CurrentJournal {
  id: number
  subscription_id: number
  start_period: Date
  end_period: Date
  pay_modules: PayModules
  count_users: number[]
  created_at: Date
  updated_at: Date
}

export interface PayModules {
  users: PayModulesStorage
  storage: PayModulesStorage
  map: PayModulesAccounting
  request_review: PayModulesAccounting
  time_cards: PayModulesAccounting
  gps: PayModulesAccounting
  todo: PayModulesAccounting
  quickbooks: PayModulesAccounting
  accounting: PayModulesAccounting
  recurring_appointments: PayModulesAccounting
}

export interface PayModulesAccounting {
  paid: boolean
  amount: number
}

export interface PayModulesStorage {
  paid: boolean
  amount: string
}

export interface Modules {
  users: ModulesStorage
  storage: ModulesStorage
  basic_modules: BasicModulesClass
  accounting: BasicModulesClass
  map: BasicModulesClass
  request_review: BasicModulesClass
  time_cards: BasicModulesClass
  gps: BasicModulesClass
  todo: BasicModulesClass
  quickbooks: BasicModulesClass
  recurring_appointments: BasicModulesClass
}

export interface BasicModulesClass {
  title: string
  price: number
  confirm: boolean
  is_custom: boolean
  status: boolean
  optional: boolean
  date_of_expiry: Date
  date_charge: Date
  count_days: number
  fix_price: boolean
}

export interface ModulesStorage {
  title: string
  price: string
  confirm: boolean
  is_custom: boolean
  status: boolean
  optional: boolean
  date_of_expiry: Date
  date_charge: Date
  count_days: number
  fix_price: boolean
}
