import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { DragIndicator } from '@material-ui/icons'
import { Button, DatePicker, Popconfirm, Radio, Spin, Tooltip } from 'antd'
import { TextField } from 'components/UIKit'
import { DATE_FORMAT } from 'constants/dateFormats'
import { OptionIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { Attachments } from 'features/Items/components/Item/components/Attachments/Attachments'
import { ItemAttachment } from 'features/Items/types'
import { CustomLabel } from 'features/Project/Items/components/ProjectItemsTable/components/TableRow/TableRow.styles'
import { SearchItems } from 'features/Project/Items/components/SearchItems/SearchItems'
import { selectProject } from 'features/Project/projectSelectors'
import { ProjectRebate } from 'features/Project/types'
import { ProjectPermissions } from 'features/Settings/UsersAndGroups'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'
import { formatStringToNumber } from 'helpers/numbers'
import { priceToView } from 'helpers/thousandSeparator'
import useOnClickOutside from 'hooks/useOnClickOutside'
import moment from 'moment-timezone'
import { useRef, useState } from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'
import NumberFormat from 'react-number-format'
import { useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'

interface Props {
  data: ProjectRebate
  disabled: boolean
  editingItem: ProjectRebate | null
  error: Error | null
  isOption?: boolean
  loading: boolean
  provided?: DraggableProvided
  sectionSubtotal: number
  onAddOption?: () => void
  onEdit: (discount: ProjectRebate | null) => void
  onDelete: (discount: ProjectRebate) => void
  onCancel: () => void
  onChangeItem: (event: { target: { name: string; value: unknown } }) => void
  onSave: (discount: ProjectRebate) => void
  onItemSet: (item: ProjectRebate) => void
}

export const ProjectRebateTableRow = ({
  data,
  disabled,
  editingItem,
  error,
  isOption,
  loading,
  provided,
  onAddOption,
  onEdit,
  onDelete,
  onCancel,
  onChangeItem,
  onSave,
  onItemSet,
}: Props) => {
  const ref = useRef(null)
  const { user_id } = useAppSelector(selectProject)
  const isPriceVisible = useAppSelector(selectIsPriceVisible(user_id))

  const [showCalendar, setShowCalendar] = useState(false)

  const handleRowBlur = () => {
    if (!editingItem || showCalendar) return

    if (isEditingItem && !loading) {
      onSave(editingItem)
    }
  }

  useOnClickOutside(ref, handleRowBlur)

  const handleUploadFiles = (files: ItemAttachment[]) => {
    onChangeItem({
      target: {
        name: 'attachments',
        value: files,
      },
    })
  }

  const handleItemChoose = (item: ProjectRebate) => {
    const choosenItem: Partial<ProjectRebate> = { ...item }
    delete choosenItem?.id

    onItemSet(choosenItem as ProjectRebate)
  }

  const handleBlurNumberField = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (value) return

    onChangeItem({
      target: {
        name,
        value: 0,
      },
    })
  }

  const handleZeroFieldFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e?.target
    if (editingItem?.[name as keyof ProjectRebate] === 0)
      onChangeItem({
        target: {
          name,
          value: '',
        },
      })
  }

  const isEditingItem = editingItem?.id === data?.id

  return (
    <Spin spinning={loading && isEditingItem}>
      <Wrapper ref={ref}>
        <Content
          expired={!data.active}
          ref={provided?.innerRef}
          {...provided?.draggableProps}
          key={data.id}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: '#aaa',
              fontSize: 18,
              gap: 5,
            }}
          >
            {!!isOption && (
              <Radio
                style={{
                  justifySelf: 'flex-end',
                  alignSelf: 'center',
                  margin: 0,
                  cursor: 'pointer',
                }}
                disabled={!data?.id}
                checked={data.checked_option}
                onClick={() => {
                  if (disabled || editingItem) return
                  onSave({
                    ...data,
                    checked_option: true,
                  })
                }}
              />
            )}

            {!disabled && !!data.id && (
              <span {...provided?.dragHandleProps}>
                <Tooltip title={disabled ? null : 'Move'} mouseLeaveDelay={0}>
                  <DragIndicator
                    style={{
                      cursor: disabled ? 'not-allowed' : 'grab',
                    }}
                  />
                </Tooltip>
              </span>
            )}
          </div>

          <div style={{ display: 'grid', gridAutoColumns: 'auto 1fr', gap: 5 }}>
            {isEditingItem ? (
              <SearchItems
                isRebates
                hidePrices={!isPriceVisible}
                error={error?.name}
                initialValue={editingItem.name}
                onItemChoose={handleItemChoose}
                onValueChange={value =>
                  onChangeItem({
                    target: {
                      name: 'name',
                      value,
                    },
                  })
                }
              />
            ) : (
              <p
                style={{
                  fontWeight: 500,
                  cursor: disabled ? 'initial' : 'pointer',
                }}
                onClick={() => {
                  if (disabled) return
                  onEdit(data)
                }}
              >
                {!data.global_item_id && (
                  <CustomLabel>
                    <Tooltip
                      title='Custom item (not from "Price pages")'
                      mouseLeaveDelay={0}
                    >
                      *
                    </Tooltip>
                  </CustomLabel>
                )}
                {data.name}{' '}
                {!data.active && (
                  <span style={{ color: '#ef9494', fontStyle: 'italic' }}>
                    (expired)
                  </span>
                )}
              </p>
            )}
            <Attachments
              ref={ref}
              imageSize={35}
              data={isEditingItem ? editingItem?.attachments : data.attachments}
              disabled={!isEditingItem}
              onUpload={handleUploadFiles}
              tooltip
            />
          </div>
          {isEditingItem ? (
            <DatePicker
              size='small'
              name='expiration'
              format={DATE_FORMAT}
              showToday={false}
              open={showCalendar}
              placeholder={
                editingItem.expiration
                  ? editingItem.expiration
                  : 'No expiration'
              }
              onBlur={() => setShowCalendar(false)}
              renderExtraFooter={() => (
                <Button
                  size='small'
                  onClick={() => {
                    onChangeItem({
                      target: {
                        name: 'expiration',
                        value: null,
                      },
                    })
                    setShowCalendar(false)
                  }}
                >
                  No expiration
                </Button>
              )}
              value={
                editingItem.expiration ? moment(editingItem.expiration) : null
              }
              onChange={(_, value) => {
                onChangeItem({
                  target: {
                    name: 'expiration',
                    value,
                  },
                })
                setShowCalendar(false)
              }}
              onClick={() => setShowCalendar(!showCalendar)}
              style={{ height: 32 }}
            />
          ) : (
            <p>{data.expiration || 'No expiration'}</p>
          )}

          <AccessControl
            author={user_id}
            scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
          >
            <>
              {isEditingItem ? (
                <NumberFormat
                  name='amount'
                  value={editingItem.amount}
                  thousandSeparator
                  prefix={'$'}
                  customInput={TextField}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeItem({
                      target: {
                        name: 'amount',
                        value: formatStringToNumber(e),
                      },
                    })
                  }
                  onBlur={handleBlurNumberField}
                  onFocus={handleZeroFieldFocus}
                />
              ) : (
                <p>{priceToView(data.amount)}</p>
              )}
            </>
          </AccessControl>

          <AccessControl
            author={user_id}
            scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
          >
            <p style={{ fontWeight: 500 }}>
              {isEditingItem
                ? `-${priceToView(editingItem.amount)}`
                : `-${priceToView(data.amount)}`}
            </p>
          </AccessControl>
          <Actions>
            {isEditingItem ? (
              <>
                <Tooltip title='Save item' mouseLeaveDelay={0}>
                  <CheckOutlined
                    style={{ color: 'green' }}
                    onClick={() => {
                      if (!editingItem || loading) return

                      onSave(editingItem)
                    }}
                  />
                </Tooltip>
                <Tooltip title='Cancel' mouseLeaveDelay={0}>
                  <CloseOutlined
                    style={{ color: 'red' }}
                    // onClick={() => onEdit(null)}
                    onClick={onCancel}
                  />
                </Tooltip>

                {!!data.id && isEditingItem && !isOption && (
                  <Tooltip title='Add an option' mouseLeaveDelay={0}>
                    <OptionIcon
                      onClick={() => {
                        onAddOption?.()
                      }}
                      style={{
                        // paddingLeft: 3,
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </>
            ) : (
              <>
                <Tooltip
                  title={disabled ? null : 'Edit item'}
                  mouseLeaveDelay={0}
                >
                  <EditOutlined
                    style={{
                      cursor: disabled ? 'not-allowed' : 'pointer',
                    }}
                    onClick={() => {
                      if (disabled || editingItem) return
                      onEdit(data)
                    }}
                  />
                </Tooltip>

                {!data.checked_option && (
                  <Tooltip
                    title={disabled ? null : 'Delete'}
                    mouseLeaveDelay={0}
                  >
                    <Popconfirm
                      placement='left'
                      getPopupContainer={trigger =>
                        trigger.parentElement || document.body
                      }
                      title='Delete rebate?'
                      onConfirm={() => onDelete(data)}
                      disabled={disabled}
                    >
                      <DeleteOutlined
                        style={{
                          cursor: disabled ? 'not-allowed' : 'pointer',
                        }}
                      />
                    </Popconfirm>
                  </Tooltip>
                )}
              </>
            )}
          </Actions>
        </Content>
      </Wrapper>
    </Spin>
  )
}

export const Wrapper = styled.div`
  /* margin-bottom: 5px; */
`

const Content = styled.div<{ expired: boolean }>`
  display: grid;
  grid-template-columns: 30px 1fr 150px 150px 100px 55px;
  align-items: baseline;
  gap: 5px;
  padding: 5px 0;
  color: ${({ expired }) => (expired ? '#aeadad' : 'initial')};
`

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 25px 25px;
  gap: 5px;
  font-size: 16px;
  color: initial;

  & > * {
    cursor: pointer;
  }
`
