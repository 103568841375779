import styled from 'styled-components'

export const FilesList = styled.div``
export const UploadByLink = styled.div`
  & > p {
    text-align: center;
    margin: 20px 0 10px;
  }
`

export const FileActions = styled.div`
  display: flex;
  gap: 5px;

  .anticon-eye:hover {
    color: #858aff;
  }

  .delete-icon:hover {
    color: red;
  }
`

export const FileItem = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr 50px;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`

export const Info = styled.div`
  text-align: center;
  margin-bottom: 15px;
`

export const UploadSection = styled.div`
  margin: 0 auto 15px;

  &__upload-by-link {
    & > p {
      text-align: center;
    }

    .link-upload {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
`

export const Drop = styled.div`
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: border-color 0.3s;
  padding: 10px;
  max-width: 80%;
  margin: 0 auto;

  &:hover {
    border-color: #747474;
  }
`

export const DropzoneText = styled.p`
  font-size: 16px;
`
