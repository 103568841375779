import { useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import { selectProjectSlice } from '../slices/projectSlice'
import { selectProjectTabSlice } from '../slices/projectTabsSlice'

export const ProjectWrapper = ({ children }) => {
  const { status: projectStatus } = useAppSelector(selectProjectSlice)
  const { status: projectTabStatus } = useAppSelector(selectProjectTabSlice)

  return (
    <Wrapper
      loading={projectStatus === 'loading' || projectTabStatus === 'loading'}
    >
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ loading: boolean }>`
  cursor: ${props => (props.loading ? 'progress' : 'initial')};
`
