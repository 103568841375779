import { fetchOpenStreetMapAddressFromCoordinates } from '../api/map'

export const addressBuilderPostcode = obj => {
  let address = ''
  for (let key in obj.address) {
    if (
      key === 'city' ||
      key === 'town' ||
      key === 'county' ||
      key === 'village' ||
      key === 'hamlet' ||
      key === 'state_district'
    )
      break
    address = address + (!address ? obj.address[key] : ` ${obj.address[key]}`)
    if (key === 'street' || key === 'road' || key === 'residential') break
  }
  const city =
    obj.address.city ||
    obj.address.town ||
    obj.address.hamlet ||
    obj.address.county ||
    obj.address.village ||
    obj.address.state_district ||
    ''
  const postcodeFromDisplayNameArray = obj.display_name.match(/\b\d{5}\b/g)
  const state = obj.address.state || ''
  const postcode = obj.address.postcode
    ? obj.address.postcode.match(/(^\d{5})|(\d{5}$)/)
      ? obj.address.postcode.match(/(^\d{5})|(\d{5}$)/)[0]
      : ''
    : postcodeFromDisplayNameArray
    ? postcodeFromDisplayNameArray[postcodeFromDisplayNameArray.length - 1]
    : ''
  return {
    address,
    city,
    state,
    postcode,
    zip: postcode,
    lat: +obj.lat,
    lng: +obj.lon,
    bbox: obj.boundingbox,
    place_id: obj.place_id,
  }
}

/*obj = {
bbox:[],
geometry: {coordinates: [-111.788599, 34.859676]}
properties:{},
  }*/
export const addressBuilder = obj => {
  const {
    name,
    locality,
    region,
    postalcode,
    id,
    housenumber,
    street,
    county,
    town,
    village,
    hamlet,
    state_district,
  } = obj.properties
  const { coordinates } = obj.geometry
  let address = `${housenumber ? housenumber : ''}${street ? ` ${street}` : ''}`
  address = address ? address : name ? name : ''
  const city = locality
    ? locality
    : town
    ? town
    : county
    ? county
    : // : village
      // ? village
      // : hamlet
      // ? hamlet
      // : state_district
      // ? state_district
      ''
  const state = region ? region : ''
  const postcode =
    !!postalcode && !!postalcode.match(/(^\d{5})|(\d{5}$)/) ? postalcode.match(/(^\d{5})|(\d{5}$)/)[0] : ''
  const zip = postcode
  const lat = +coordinates[1]
  const lng = +coordinates[0]
  //const bbox = obj.bbox
  const place_id = id

  const data = {
    address,
    city,
    state,
    postcode,
    zip,
    lat,
    lng,
    bbox: obj.bbox ? obj.bbox : null,
    place_id,
  }

  /* if (!data.postcode) {
    const res = await fetchOpenStreetMapAddressFromCoordinates({ lat, lng })
    data.postcode = res.address.postcode
    data.zip = res.address.postcode
    return data
  }  */
  return data
}
