import React, { useState } from 'react'
import AutoComplete from '@material-ui/lab/Autocomplete'
import useSearch from '../../../../components/SearchHook'
import { fetchOpenStreetMapAddressSeparately, fetchOpenStreetMapAddress } from 'api/map'
import { addressBuilderPostcode, addressBuilder } from '../../../../../../../helpers/OSMAddressBuilder'
import { TextField } from '@material-ui/core'
import './style.scss'
import { fetchOpenStreetMapAddressByOneValue } from '../../../../../../../api/map'
import { searchLocalAddress } from '../../../../../../../api/Property'
import { SmallPreloader } from '../../../../components/Preloader/SmallPreloader'

const AddressInputMaterial = ({
  onChoose,
  placeholder,
  value = '',
  onChange,
  disabled,
  isChooseAddress,
  label = 'Property Address',
  name = 'address',
  returnEvent = false,
  id = 'address',
  errorMessage = '',
  labelToDropdawn = 'default',
  disableClearable = false,
}) => {
  const [searchValue, setSearchValue] = useState('')

  if (labelToDropdawn === 'zip' || labelToDropdawn === 'postcode') labelToDropdawn = 'postcode'
  let searchParam = labelToDropdawn === 'postcode' ? 'postalcode' : labelToDropdawn

  const [isSearching, setIsSearching] = useState(false)

  let openStreetResp = useSearch(
    searchValue,
    debouncedValue => {
      const request =
        labelToDropdawn === 'default'
          ? fetchOpenStreetMapAddress(debouncedValue)
          : fetchOpenStreetMapAddressSeparately({
              [searchParam]: debouncedValue,
            })
      setIsSearching(true)
      return request.finally(() => {
        setIsSearching(false)
      })
    },
    200,
  )

  const Options = !isSearching
    ? !!openStreetResp && !!openStreetResp.length
      ? openStreetResp.reduce((acc, item) => {
          const normalizedAddress = addressBuilderPostcode(item)
          const isValidData =
            (labelToDropdawn === 'postcode' ? true : !!normalizedAddress.address) &&
            !!normalizedAddress.city &&
            !!normalizedAddress.state &&
            !!normalizedAddress.postcode
          const isAllreadyContainsSuchPostcode =
            acc.findIndex(item => item.postcode === normalizedAddress.postcode) >= 0
          if (
            isValidData &&
            (labelToDropdawn !== 'postcode' || (labelToDropdawn === 'postcode' && !isAllreadyContainsSuchPostcode))
          )
            acc.push(normalizedAddress)

          return acc
        }, [])
      : [{ address: '', no_data: true }]
    : ['searching']

  const onSelect = (address, option) => {
    onChoose && onChoose(address)
  }

  const onChangeAddress = addressObject => {
    let address = addressObject.currentTarget.value
    if (onChange) {
      if (/^\{"address.+/.test(address)) {
        address = JSON.parse(address)
        address.address =
          labelToDropdawn === 'postcode'
            ? address.address.replace(/\D/g, '').substring(0, 5)
            : address.address.replace('#', '№')
        onChange(returnEvent ? { target: { value: address.address, name } } : address.address)
        setSearchValue(
          labelToDropdawn === 'postcode'
            ? address.address.length === 5
              ? address.address
              : searchValue
            : address.address,
        )
      } else {
        address =
          labelToDropdawn === 'postcode' ? address.replace(/\D/g, '').substring(0, 5) : address.replace('#', '№')
        onChange(returnEvent ? { target: { value: address, name } } : address)
        setSearchValue(labelToDropdawn === 'postcode' ? (address.length === 5 ? address : searchValue) : address)
      }
    } else return
  }
  //     !!openStreetResp && openStreetResp.length ? (
  //     openStreetResp.map((item, i) => {
  //         let normalizedAddress = addressBuilder(item)
  //         return (
  //             !!normalizedAddress.address &&
  //             !!normalizedAddress.postcode && (
  //                 <Option key={item.place_id} value={JSON.stringify(normalizedAddress)}>
  //                     <b>
  //                         {normalizedAddress.address + (normalizedAddress.postcode ? ', ' : '') + normalizedAddress.postcode}
  //                     </b>
  //                     {' ' + normalizedAddress.city + ', ' + normalizedAddress.state}
  //                 </Option>
  //             )
  //         )
  //     })
  // ) : value ? (
  //     <Option key={'no_data'} value='No Data' disabled>
  //         Not Found
  //     </Option>
  // ) : null
  const renderOption = option =>
    !isSearching ? (
      !isChooseAddress ? (
        <span className='textDropDown'>
          {option.no_data ? (
            'No data'
          ) : labelToDropdawn === 'default' ? (
            <>
              <b>{option.address + (option.postcode ? `, ${option.postcode}` : '')}</b>
              <br />
              {' ' + option.city + ', ' + option.state}
            </>
          ) : (
            option[labelToDropdawn]
          )}
        </span>
      ) : null
    ) : (
      <div className='d-flex justify-content-center align-items-center'>
        <SmallPreloader />
      </div>
    )

  const renderInput = params => (
    <TextField
      {...params}
      name={name}
      label={label}
      variant='outlined'
      //margin='dense'
      onChange={onChangeAddress}
      // value={value}
      className='addressTextField'
      inputProps={{
        ...params.inputProps,
        value: value ? value : '',
        autoComplete: 'no',
      }}
      error={!!errorMessage}
      helperText={errorMessage}
      fullWidth
    />
  )
  const onChangeWrapper = (e, value) => onSelect(value)
  const getOptionLabel = option => (option.address ? option.address : '')
  const getOptionDisabled = option => {
    return option.no_data
  }
  return (
    <AutoComplete
      id={id}
      style={{ width: '100%', marginTop: '8px' }}
      size='small'
      className='auto-comlete-address'
      onChange={onChangeWrapper}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      value={value}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderOption={renderOption}
      // onSelect={onSelect}
      options={Options}
      placeholder={placeholder}
      disabled={disabled}
      // autoHighlight
      renderInput={renderInput}
      //noOptionsText='No Data'
      forcePopupIcon={false}
      disableClearable={disableClearable}
    />
  )
}

export default AddressInputMaterial
