export const getProposalStatus = id => {
  const statuses = {
    1: 'Estimate',
    2: 'Contract',
    3: 'Completed',
    4: 'Cancelled',
    5: 'Cold',
    default: 'Estimate',
  }

  return statuses[id] || statuses.default
}
