import { FC } from 'react'
import styled from 'styled-components'
import { Drawer, Checkbox, CheckboxValueType, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { InfoIcon } from 'containers/MainContent/Orcatec/components/UI/InfoBlock/Icons'

const Wrapper = styled.div`
  & .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    color: rgba(128, 128, 128, 0.5);
  }
`

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
`

interface TableSettingProps {
  columns: {
    dataIndex: string
    title: string
    columnDisabled?: boolean
    columnDisabledText?: string
  }[]
  selectedColumns: string[]
  onSelectColumns: (checkedValue: CheckboxValueType[]) => void
  openTableSettings: boolean
  onCloseTableSettings: () => void
}

const defaultText = `To display this column, you need an active
subscription for accounting module. `

const iconWithTooltip = item => {
  return !item?.columnDisabled ? (
    item?.name
  ) : (
    <Tooltip title={<p>{item?.columnDisabledText || defaultText}</p>}>
      <span>{item?.name}</span> <InfoIcon color='grey' />
    </Tooltip>
  )
}

const TableSettings: FC<TableSettingProps> = ({
  columns,
  selectedColumns,
  onSelectColumns,
  openTableSettings,
  onCloseTableSettings,
}) => {
  const handleChange = (e: CheckboxChangeEvent) => {
    const { name } = e.target

    if (selectedColumns.includes(name)) {
      onSelectColumns(selectedColumns.filter(item => item !== name))
    } else {
      onSelectColumns([...selectedColumns, name])
    }
  }

  return (
    <Wrapper className='table-settings'>
      <Drawer
        title='Columns setting'
        visible={openTableSettings}
        onClose={onCloseTableSettings}
      >
        <CheckboxGroup>
          {columns?.map(item => (
            <Checkbox
              checked={selectedColumns?.includes(item?.dataIndex)}
              key={item?.dataIndex}
              onChange={handleChange}
              name={item.dataIndex}
              disabled={item?.columnDisabled}
            >
              {iconWithTooltip(item)}
            </Checkbox>
          ))}
        </CheckboxGroup>

        {/* <Checkbox.Group
          style={{ display: 'flex', flexDirection: 'column' }}
          options={columns?.map(
            column =>
              ({
                label: column?.name?.toString?.(),
                value: column?.dataIndex,
                disabled: column?.columnDisabled,
              } as CheckboxOptionType),
          )}
          defaultValue={selectedColumns}
          onChange={onSelectColumns}
        /> */}
      </Drawer>
    </Wrapper>
  )
}

export default TableSettings
