export const TIMECARDS_SET = 'TIMECARDS_SET'
export const TIMETYPE_SET = 'TIMETYPE_SET'
export const CURRENT_USER_TIMECARD_SET = 'CURRENT_USER_TIMECARD_SET'
export const TIMECARDS_RANGE_SET = 'TIMECARDS_RANGE_SET'
export const CHANGE_LIMIT = 'CHANGE_LIMIT'
export const CHANGE_PAGE = 'CHANGE_PAGE'

//
export const GET_TIMECARD_LIST = 'GET_TIMECARD_LIST'
export const SET_TIMECARD_LIST = 'SET_TIMECARD_LIST'
export const GET_TIMECARD_LIST_FAILURE = 'GET_TIMECARD_LIST_FAILURE'

export const GET_TIMECARD = 'GET_TIMECARD'
export const SET_TIMECARD = 'SET_TIMECARD'
export const TIMECARD_FAILURE = 'TIMECARD_FAILURE'

export const ADD_TIMECARD_TRACK = 'ADD_TIMECARD_TRACK'
export const ADD_TIMECARD_TRACK_SUCCESS = 'ADD_TIMECARD_TRACK_SUCCESS'
export const ADD_TIMECARD_TRACK_FAILURE = 'ADD_TIMECARD_TRACK_FAILURE'

export const EDIT_TIMECARD_TRACK = 'EDIT_TIMECARD_TRACK'
export const EDIT_TIMECARD_TRACK_SUCCESS = 'EDIT_TIMECARD_TRACK_SUCCESS'
export const EDIT_TIMECARD_TRACK_FAILURE = 'EDIT_TIMECARD_TRACK_FAILURE'

export const DELETE_TIMECARD_TRACK = 'DELETE_TIMECARD_TRACK'
export const DELETE_TIMECARD_TRACK_SUCCESS = 'DELETE_TIMECARD_TRACK_SUCCESS'
export const DELETE_TIMECARD_TRACK_FAILURE = 'DELETE_TIMECARD_TRACK_FAILURE'

export const ADD_TIMECARD_DAY = 'ADD_TIMECARD_DAY'
export const DELETE_TIMECARD_DAY = 'DELETE_TIMECARD_DAY'

export const RESET_TIMECARDS_ERRORS = 'RESET_TIMECARDS_ERRORS'
