import React, { useState } from 'react'
import { Modal, Button, Select, Tag, Table } from 'antd'
import { Row, Col } from 'antd'
import { DatePicker } from 'antd'
import { connect } from 'react-redux'

const ModalScheduleAutomaticFollowUp = props => {
  const [table, setTable] = useState({
    columns: [
      {
        title: '',
        dataIndex: 'client',
        key: 'client',
      },
      {
        title: '',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: '',
        dataIndex: 'email',
        key: 'email',
      },
    ],
    dataSource: [
      {
        key: 0,
        client: 'John Doe',
        role: 'Owner',
        actions: '',
      },
      {
        key: 2,
        client: 'John Doe',
        role: 'Owner',
        actions: '',
      },
      {
        key: 3,
        client: 'John Doe',
        role: 'Owner',
        actions: '',
      },
    ],
  })

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  }

  return (
    <Modal
      width={800}
      title='&nbsp;&nbsp;&nbsp;&nbsp;'
      visible={false}
      onOk={() => {
        window.location = '/projects'
      }}
      onCancel={() => {}}
    >
      <Row>
        <Col span={12}>
          <label htmlFor=''>Select Client</label>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            showHeader={false}
            rowSelection={rowSelection}
            dataSource={table.dataSource}
            columns={table.columns}
            pagination={false}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DatePicker
            showTime
            style={{ width: '100%' }}
            placeholder='Select Time'
            onChange={() => {}}
            onOk={() => {}}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <textarea style={{ width: '100%' }} cols='30' rows='5'></textarea>
        </Col>
      </Row>
    </Modal>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalScheduleAutomaticFollowUp)
