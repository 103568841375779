import { useState, useEffect } from 'react'

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => clearTimeout(timeoutRef)
  })

  return debouncedValue
}

function useSearch(
  query,
  requestFactory,
  delay = 250,
  type = 'address',
  page = 1,
  limit = 10,
  searchBy = '',
  isChangeResultNeeded,
  isSearchByClientName,
) {
  const [response, setResponse] = useState(null)
  const debouncedQuery = useDebounce(query, delay)
  const [renderCount, setRenderCount] = useState(0)

  useEffect(() => {
    let isActive = true
    setRenderCount(renderCount + 1)

    if (renderCount === 0) return

    if (debouncedQuery) {
      type === 'address'
        ? requestFactory &&
          requestFactory(debouncedQuery, searchBy).then(res => {
            if (isActive) {
              setResponse(res)
            }
          })
        : requestFactory &&
          requestFactory(page, limit, debouncedQuery).then(res => {
            if (isActive) {
              setResponse(res)
            }
          })
    } else {
      if (type !== 'address') {
        requestFactory &&
          requestFactory(page, limit, debouncedQuery).then(res => {
            if (isActive) {
              setResponse(res)
            }
          })
      } else setResponse(null)
    }

    return () => (isActive = false)
  }, [debouncedQuery, isSearchByClientName])

  return isChangeResultNeeded ? [response, setResponse] : response
}

export default useSearch
