import { ActionType } from '../constants/mailAccountsConstants'
import { combineReducers } from 'redux'

const {
  CLEAR_STORE,
  GET_ACCOUNTS_LIST_INIT,
  GET_ACCOUNTS_LIST_SUCCESS,
  GET_ACCOUNTS_LIST_FAILURE,
  CREATE_ACCOUNT_INIT,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
  EDIT_ACCOUNT_INIT,
  EDIT_ACCOUNT_SUCCESS,
  EDIT_ACCOUNT_FAILURE,
  EDIT_ACCOUNT_STATUS_INIT,
  EDIT_ACCOUNT_STATUS_SUCCESS,
  EDIT_ACCOUNT_STATUS_FAILURE,
  DELETE_ACCOUNT_INIT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  GET_CURRENT_ACCOUNT_INIT,
  GET_CURRENT_ACCOUNT_SUCCESS,
  GET_CURRENT_ACCOUNT_FAILURE,
} = ActionType

const sendingInitialState = {
  sending: false,
  sent: false,
  error: false,
  errorData: {},
}

const sendDataReducer = (state = sendingInitialState, { type, payload }) => {
  switch (type) {
    case CREATE_ACCOUNT_INIT:
      return { ...state, sending: true, sent: false, error: false, errorData: {} }
    case CREATE_ACCOUNT_SUCCESS:
      return { ...state, sending: false, sent: true, error: false, errorData: {} }
    case CREATE_ACCOUNT_FAILURE: {
      const { data } = payload
      return { ...state, sending: false, sent: false, error: true, errorData: { ...data } }
    }

    case EDIT_ACCOUNT_INIT:
      return { ...state, sending: true, sent: false, error: false, errorData: {} }
    case EDIT_ACCOUNT_SUCCESS:
      return { ...state, sending: false, sent: true, error: false, errorData: {} }
    case EDIT_ACCOUNT_FAILURE: {
      const { data } = payload
      return { ...state, sending: false, sent: false, error: true, errorData: { ...data } }
    }

    case DELETE_ACCOUNT_INIT:
      return { ...state, sending: true, sent: false, error: false, errorData: {} }
    case DELETE_ACCOUNT_SUCCESS:
      return { ...state, sending: false, sent: true, error: false, errorData: {} }
    case DELETE_ACCOUNT_FAILURE: {
      const { data } = payload
      return { ...state, sending: false, sent: false, error: true, errorData: { ...data } }
    }
    case CLEAR_STORE:
      return { ...state, ...sendingInitialState }
    default:
      return state
  }
}

const listInitialState = {
  data: [],
  total: 1,
  per_page: 20,
  current_page: 1,
  last_page: 1,
  loading: false,
  load: false,
  error: false,
}

const listReducer = (state = listInitialState, { type, payload }) => {
  switch (type) {
    case GET_ACCOUNTS_LIST_INIT:
      return { ...state, loading: true, load: false, error: false }
    case GET_ACCOUNTS_LIST_SUCCESS:
      return { ...state, ...payload, loading: false, load: true, error: false }
    case GET_ACCOUNTS_LIST_FAILURE:
      return { ...state, loading: false, load: false, error: true }
    default:
      return state
  }
}

const editInitialState = {
  account: {},
  loading: false,
  load: false,
  error: false,
  errorData: {},
}

const editReducer = (state = editInitialState, { type, payload }) => {
  switch (type) {
    case GET_CURRENT_ACCOUNT_INIT:
      return { ...state, account: {}, loading: true, load: false, error: false, errorData: {} }
    case GET_CURRENT_ACCOUNT_SUCCESS: {
      return { ...state, account: payload, loading: false, load: true, error: false, errorData: {} }
    }
    case GET_CURRENT_ACCOUNT_FAILURE: {
      const { data } = payload
      return { ...state, account: {}, loading: false, load: false, error: true, errorData: { ...data } }
    }
    case EDIT_ACCOUNT_STATUS_INIT:
      return { ...state, loading: true, load: false }
    case EDIT_ACCOUNT_STATUS_SUCCESS: {
      if (payload.id === state.account.id) {
        const { active: status } = payload
        return {
          ...state,
          account: {
            ...state.account,
            status,
          },
          loading: false,
          load: true,
        }
      }
      break
    }
    case EDIT_ACCOUNT_STATUS_FAILURE: {
      if (payload.id === state.account.id) {
        return { ...state, loading: false, load: false }
      }
      break
    }
    case CLEAR_STORE:
      return { ...state, ...editInitialState }
    default:
      return state
  }
}

export default combineReducers({
  listData: listReducer,
  editData: editReducer,
  sendData: sendDataReducer,
})
