import React, { FC, useState, useMemo } from 'react'
import { Table, Typography, Input, Skeleton, Tooltip, Switch } from 'antd'
import { useQuery } from '@tanstack/react-query'
import {
  getListOfSharedLinsk,
  updateStatusLink,
} from 'api/PublicEvent/PublicEvent'
import { PaginationConfig } from 'antd/lib/pagination'
import { SorterResult } from 'antd/lib/table/interface'
import { ColumnProps } from 'antd/lib/table'
import { ILinksTable } from '../types'
import { IAppointment } from 'types/Appointment'
import { Job } from 'types/Jobs'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompanyTimezone } from 'store/Orcatec/selectors/company'
import { FULL_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment-timezone'
import styled from 'styled-components'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import useDebounce from 'hooks/useDebounce'
import { InfoIcon } from 'containers/MainContent/Orcatec/components/UI/InfoBlock/Icons'
import { Check } from '@material-ui/icons'
import { SwitchChangeEventHandler } from 'antd/lib/switch'
import { PlanLimits } from 'features/Settings/Subscription/components/PlanLimits/PlanLimits'

const { Search } = Input

const { Paragraph } = Typography
interface IProps {
  event: IAppointment | Job
}

const renderLink = (hash: string) => {
  const link = `${window.location.host}/public/events/${hash}`

  // const onClick = () => window.open(link, '_blank')
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <p>{'...' + link.slice(22)}</p>
      <Paragraph
        style={{ marginBottom: '0px' }}
        copyable={{ text: link }}
      ></Paragraph>
    </div>
  )
}

const renderLogs = (value, record) => {
  if (!value) {
    return <span style={{ color: 'grey' }}>Never</span>
  }

  return (
    <>
      <span>
        <Check style={{ color: '#2ebda3', paddingRigth: '4px' }} />
        <Tooltip
          style={{ width: '300px' }}
          overlayStyle={{ width: '300px' }}
          overlayInnerStyle={{ width: '300px' }}
          title={record?.logs?.map(log => (
            <p key={log.id} style={{ whiteSpace: 'nowrap', width: '300px' }}>
              {
                <>
                  <span>
                    {moment(log?.created_at)
                      .tz(moment.tz.guess())
                      .format(FULL_DATE_FORMAT)}
                  </span>{' '}
                  -<span>IP:</span>
                  <span>{log?.ip}</span>
                </>
              }
            </p>
          ))}
        >
          <span>
            <InfoIcon color='grey' />
          </span>
        </Tooltip>
      </span>
    </>
  )
}

export const SharedEventsTable: FC<IProps> = ({ event, onCreateLink }) => {
  const timezone = useAppSelector(selectCompanyTimezone)

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 25,
    total: 10,
  })
  const [sortField, setSortField] = useState<string | null>(null)
  const [sortOrder, setSortOrder] = useState<string | null>(null)
  const [tableFilters, setTableFilters] = useState(
    {} as Record<keyof ILinksTable, string[]>,
  )
  const [data, setData] = useState([] as ILinksTable[])
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)

  const { refetch, isLoading } = useQuery({
    queryKey: ['shared_links/table', debouncedSearch],
    enabled: !!event.id,
    queryFn: () =>
      getListOfSharedLinsk(event.id, {
        page: pagination.page,
        per_page: pagination.per_page,
        sort_field: sortField,
        order: sortOrder,
        search: debouncedSearch,
        ...tableFilters,
      }),
    onSuccess: data => {
      setData(data.event_links)
      setPagination(data.meta)
    },
  })

  const handleTableChange = (
    pagination: PaginationConfig,
    filters: Partial<Record<keyof ILinksTable, string[]>>,
    sorter: SorterResult<ILinksTable>,
  ) => {
    setSortOrder(
      sorter.order === 'ascend'
        ? 'asc'
        : sorter.order === 'descend'
        ? 'desc'
        : null,
    )
    setSortField(sorter.order ? sorter.field : null)
    setTableFilters({ ...filters })

    refetch()
  }

  const onChangeStatus = async (
    checked: SwitchChangeEventHandler,
    row: ILinksTable,
  ) => {
    try {
      setData(prev =>
        prev.map(item =>
          item.id === row.id ? { ...item, status: checked } : item,
        ),
      )

      await updateStatusLink(event.id, row.id, { status: checked })
    } catch (error) {
      console.error(error)
    }
  }

  const columns: ColumnProps<ILinksTable>[] = useMemo(
    () => [
      {
        title: 'Link',
        dataIndex: 'hash',
        key: 'hash',
        width: 150,
        render: renderLink,
      },
      {
        title: 'Shared with',
        dataIndex: 'clients',
        key: 'clients',
        sorter: true,
        width: 150,
      },

      {
        title: 'Created by',
        dataIndex: 'created_by',
        key: 'created_by',
        width: 200,
      },
      {
        title: `Created at ${moment()
          .tz(timezone)
          .format('(z)')}`,
        dataIndex: 'created_at',
        key: 'created_by',
        sorter: true,
        width: 200,
        render: value =>
          moment(value)
            .tz(timezone)
            .format(FULL_DATE_FORMAT),
      },

      {
        title: 'Opened',
        dataIndex: 'count_visits',
        key: 'count_visits',
        sorter: true,
        width: 150,
        render: renderLogs,
      },
      {
        title: 'Active',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        width: 150,
        render: (checked, row) => (
          <Switch checked={!!checked} onChange={e => onChangeStatus(e, row)} />
        ),
      },
    ],
    [],
  )

  return (
    <>
      <PlanLimits entity='links' />

      <HeadingButtons>
        <h4 style={{ display: 'flex', gap: '10px' }}>
          Shared Links{' '}
          <MainButton onClick={onCreateLink} title='Create New Link' />
        </h4>
        <Search
          onChange={e => {
            setSearch(e.target.value)
          }}
          placeholder='Search...'
          style={{ width: 300 }}
        />
      </HeadingButtons>

      {isLoading ? (
        <>
          {' '}
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <Table
          onChange={handleTableChange}
          size='small'
          columns={columns}
          dataSource={data}
          pagination={{
            position: ['bottomRight'],
            showSizeChanger: true,
            pageSizeOptions: ['25', '50'],
            total: pagination?.total ?? 25,
            pageSize: pagination?.page_page,
            size: 'small',
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      )}
    </>
  )
}

const HeadingButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
`
