import moment from 'moment-timezone'

export const getFormatFromDateStr = (dateStr, format) =>
  !format
    ? 'YYYY-MM-DD HH:mm:ss'
    : (typeof dateStr === 'string' && dateStr.includes('/')) || format === 'MM/DD/YYYY hh:mma'
    ? 'MM/DD/YYYY hh:mma'
    : format

export const separateDateTimeByStr = (str, dateTime, format) => {
  if (!format) {
    format = 'YYYY-MM-DD HH:mm:ss'
  } else {
    if ((typeof dateTime === 'string' && dateTime.includes('/')) || format === 'MM/DD/YYYY hh:mma')
      format = 'MM/DD/YYYY hh:mma'
  }
  const localDate = moment.utc(dateTime, format).local()
  const date = localDate.format('MM/DD/YYYY')
  const time = localDate.format('hh:mma')
  return `${date} ${str} ${time}`
}
