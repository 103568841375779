import {
  downloadAsPDF,
  // downloadAsPDF2,
  updateFormById,
  updateFormField,
  verifyForm,
} from 'api/CustomForms'
import { useState, useEffect, useRef } from 'react'
import { Button, Modal, Popconfirm, Spin } from 'antd'
import styled from 'styled-components'
import { getComponentByType } from 'features/Forms/utils/getComponentByType'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import {
  ActionEnum,
  Component,
  CustomForm,
  CustomFormsOperation,
} from 'features/Forms/types'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import {
  persentageFillRequiredField,
  persentageFillAllField,
  isTruthy,
} from 'features/Forms/utils/percentageFill'
import { deleteMedia } from 'api/Media'
import { Media } from 'types/Media'
// import jsPDF from 'jspdf'
// import html2canvas from 'html2canvas'
import { CheckOutlined, DownloadOutlined } from '@ant-design/icons'
// import { canVerifyForm } from 'features/Settings/UsersAndGroups/userSlice'
import { useAppSelector } from 'store/Orcatec/hooks'

import { TextField } from 'components/UIKit'
import { EditOutlined } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
import FileUploadService from 'services/FileUploadService'
import moment from 'moment-timezone'
import {
  updatePublicFormByHash,
  downloadAsPDFByHash,
  updatePublicFormFieldByHash,
} from 'api/PublicEvent/PublicEvent'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import { NotificationTemplateType } from 'features/Notification/types'
import validator from 'validator'
import {
  canVerifyFormSelect,
  canSendFormSelect,
} from 'features/Settings/UsersAndGroups/permissionSlice'

import { selectPrimaryProjectClient } from 'features/Project/slices/projectContactsSlice'

interface IProps {
  // isModalOpen: boolean
  handleCancel: (
    formId: number,
    fieldToUpdate: Record<keyof CustomForm, string>,
  ) => void
  createRelatedForm: (entityID: number, form: CustomForm) => void
  action: 'create' | 'update'
  form: CustomForm
  entityID: number
  onDeleteForm: (form: CustomForm) => void
  onSave: (form: CustomForm) => void
  onVerify?: (form: CustomForm) => void
  isPublicView: boolean
  showRelatedInfo: boolean
}

export const EditFormModal = ({
  entityID,
  form,
  // isModalOpen,
  handleCancel,
  action,
  createRelatedForm,
  onDeleteForm,
  onSave,
  onVerify,
  isPublicView = false,
  otherClientEmails = [],
}: // showRelatedInfo,
IProps) => {
  const [state, setState] = useState(form)
  const [error, setError] = useState(form)
  const [loading, setLoading] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)

  const [generatingPDF, setGeneratingPDF] = useState(false)
  const contacts = useAppSelector(selectPrimaryProjectClient)
  const canFormVerify = useAppSelector(canVerifyFormSelect)
  const canSend = useAppSelector(canSendFormSelect)

  const ref = useRef<HTMLDivElement>(null)

  const { UPDATE } = usePermissionsByModule(CustomFormsOperation, isPublicView)

  const onChange = (e: { target: { value: string } }, component: Component) => {
    const { value } = e.target
    const { id } = component

    setState(prev => ({
      ...prev,
      body: {
        ...prev.body,
        [id]: {
          ...prev.body[id],
          value,
        },
      },
    }))

    setError(prev => ({
      ...prev,
      body: {
        ...prev.body,
        [id]: {
          ...prev.body[id],
          error: false,
        },
      },
    }))
  }

  const handleNameChange = (value: string) => {
    setState(prev => ({
      ...prev,
      name: value,
    }))
  }

  const validateFields = () => {
    let isValid = true
    const result: Record<string, { error: boolean }> = {}

    for (const [key, field] of Object.entries(state?.body)) {
      if (field?.required && !isTruthy(field?.value)) {
        result[key] = { ...field, error: true }
        isValid = false
      } else {
        result[key] = { ...field, error: false }
      }
    }

    return { isValid, result }
  }

  const handleCreate = async () => {
    const fill_percentageAll = persentageFillAllField(state.body)
    setLoading(true)
    try {
      const res = await createRelatedForm(entityID, {
        ...state,
        fill_percentage: fill_percentageAll,
        template_id: state.id,
      })
      onSave(res)
      openNotificationWithIcon('success', {
        message: 'Form was created successfully!',
      })
    } catch (error) {
      console.error(error)
      openNotificationWithIcon('error', { message: 'Something went wrong!' })
    } finally {
      setLoading(false)
    }
  }

  const handleUpdate = async () => {
    if (!UPDATE) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    const fill_percentageAll = persentageFillAllField(state.body)
    setLoading(true)
    try {
      const req = {
        ...state,
        fill_percentage: fill_percentageAll === 100 ? 99 : fill_percentageAll,
        completed: false,
      }
      if (isPublicView) {
        const res = await updatePublicFormByHash(entityID, state.hash, req)
        onSave(res)
      } else {
        const res = await updateFormById(req)

        onSave(res)
      }

      openNotificationWithIcon('success', {
        message: 'Form was updated successfully!',
      })
    } catch (error) {
      if (error?.response?.status === 404) {
        return openNotificationWithIcon('error', {
          message: 'This form has been deleted',
        })
      }
      openNotificationWithIcon('error', { message: 'Something went wrong!' })
    } finally {
      setLoading(false)
    }
  }

  const handleCompleteForm = async () => {
    if (!UPDATE) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    const { isValid, result } = validateFields()

    if (!isValid) return setError(prev => ({ ...prev, body: result }))

    const fill_percentageAll = persentageFillAllField(state.body)
    const fill_percentageReq = persentageFillRequiredField(state.body)

    try {
      if (action === ActionEnum.UPDATE) {
        const req = {
          ...state,
          fill_percentage: fill_percentageAll,
          completed: fill_percentageReq === 100,
        }

        if (isPublicView) {
          const res = await updatePublicFormByHash(entityID, state.hash, req)

          onSave(res)
        } else {
          const res = await updateFormById(req)
          onSave(res)
        }
      }

      if (action === ActionEnum.CREATE) {
        const res = await createRelatedForm(entityID, {
          ...state,
          fill_percentage: fill_percentageAll,
          completed: fill_percentageReq === 100,
        })
        onSave(res)
      }

      openNotificationWithIcon('success', {
        message: 'Form was updated successfully!',
      })
    } catch (error) {
      if (error?.response?.status === 404) {
        return openNotificationWithIcon('error', {
          message: 'This form has been deleted',
        })
      }
    }
  }

  const onChangeSignature = (ref, component: Component) => {
    if (!ref) {
      //on click delete icon
      const signatureContainer = document.querySelector(
        'div.signature-canvas-container',
      )
      const canvas = signatureContainer.getElementsByTagName('canvas')

      if (canvas) {
        for (const c of canvas) {
          const context = c.getContext('2d')
          context.clearRect(0, 0, 400, 200)
        }
      }

      return onChange(
        {
          target: { value: '' },
        },
        component,
      )
    }

    const val = ref?.canvasContainer?.childNodes?.[1]?.toDataURL()
    if (val !== component?.value) {
      onChange(
        {
          target: { value: val },
        },
        component,
      )
    }
  }

  const handleUploadFile = async (options, component: Component) => {
    const { file, onError } = options

    setLoading(true)
    try {
      const res = await FileUploadService.upload(file)

      const { id } = component

      setState(prev => ({
        ...prev,
        body: {
          ...prev.body,
          [id]: {
            ...prev.body[id],
            value: [...prev.body[id]?.value, ...res],
          },
        },
      }))

      setError(prev => ({
        ...prev,
        body: {
          ...prev.body,
          [id]: {
            ...prev.body[id],
            error: false,
          },
        },
      }))
    } catch (error) {
      onError({ error })
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteFile = async (file: Media, component: Component) => {
    try {
      setLoading(true)
      const id = file.id

      await deleteMedia(id)
      const filtered = component?.value?.filter(v => v?.id !== id)
      return onChange({ target: { value: filtered } }, component)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleGeneratePdf = async () => {
    if (!form?.id) return

    setGeneratingPDF(true)
    try {
      let blob = null
      const link = document.createElement('a')
      if (isPublicView) {
        blob = await downloadAsPDFByHash(entityID, form.hash)
      } else {
        blob = await downloadAsPDF(form?.id)
      }

      // const blob = await downloadAsPDF2(form?.link)
      const objectURL = URL.createObjectURL(blob)
      // onLoadAndOpenFileHeandler?.(blobURL, 0)
      // window.open(objectURL, '_blank')

      link.href = objectURL
      link.download = form?.main_field_value || 'form'
      document.body.append(link)
      link.click()
      link.remove()
    } catch (error) {
      if (error?.response?.status === 404) {
        return openNotificationWithIcon('error', {
          message: 'This form has been deleted',
        })
      }
    } finally {
      setGeneratingPDF(false)
    }
  }

  const handleVerifyForm = async () => {
    try {
      await verifyForm(form.id, { is_verified: !state?.is_verified })
      setState(prev => ({ ...prev, is_verified: !state?.is_verified }))
      openNotificationWithIcon('success', {
        message: `The form has been successfully ${
          !state?.is_verified ? 'verified' : 'unverified'
        } `,
      })
      onVerify?.({ ...form, is_verified: !state?.is_verified })
    } catch (error) {
      console.error(error)
    }
  }

  const handleFormFieldUpdate = async (name: string) => {
    if (isPublicView) {
      await updatePublicFormFieldByHash(entityID, form.hash, { name })
    } else {
      await updateFormField(form.id, { name })
    }
  }

  const handleCloseNNotificationModal = () => {
    onSave({ ...state, is_sent: true })

    setShowEmailModal(false)
  }

  const handleOpenNotificationModal = async () => {
    setShowEmailModal(true)
  }

  useEffect(() => {
    if (form?.id) {
      setState(form)
      setError(form)
    }
  }, [form])

  // const componentsIds = Object?.keys(state?.body || {})
  const usePreviewForImage = moment(form?.created_at).isAfter(
    moment('03/27/2024', 'MM/DD/YYYY'),
  )

  return (
    <Modal
      destroyOnClose
      centered
      bodyStyle={{ maxHeight: '80vh', overflow: 'scroll' }}
      footer={
        <ButtonRow>
          {!!onDeleteForm && (
            <Popconfirm
              title='Are you sure you want to delete this form?'
              onConfirm={() => onDeleteForm(form)}
            >
              <MainButton type='delete' title='Delete' />
            </Popconfirm>
          )}

          <div
            style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              marginLeft: 'auto',
            }}
          >
            {(form?.project_id || form?.job_id || form?.public_link) && (
              <Button
                onClick={handleGeneratePdf}
                icon={<DownloadOutlined />}
                loading={generatingPDF}
              >
                Download as PDF
              </Button>
            )}

            {canFormVerify && (
              <Popconfirm
                onCancel={() => null}
                onConfirm={handleVerifyForm}
                title={`Are you sure you want to ${
                  state?.is_verified ? 'unverify' : 'verify'
                } this form ?`}
              >
                <MainButton
                  type={!state?.is_verified ? 'completed' : 'delete'}
                  title={state?.is_verified ? 'Unverify Form' : 'Verify Form'}
                />
              </Popconfirm>
            )}
            {canSend && (
              <Button onClick={handleOpenNotificationModal}>Send</Button>
            )}

            <MainButton
              className='button-custom-secondary'
              onClick={
                action === ActionEnum.CREATE ? handleCreate : handleUpdate
              }
              title={'Save Draft'}
              disabled={!state.title}
            />
            <MainButton
              onClick={handleCompleteForm}
              title='Submit Form'
              disabled={!state.title}
            />
          </div>
        </ButtonRow>
      }
      width={800}
      title={
        <FormTitle
          name={state.name}
          title={state.title}
          onChange={handleNameChange}
          onSave={handleFormFieldUpdate}
        />
      }
      visible /* ={isModalOpen} */
      onCancel={() =>
        handleCancel(form.id, {
          name: state.name,
          is_verified: state.is_verified,
        })
      }
    >
      <Wrapper ref={ref}>
        <Spin spinning={loading}>
          {Object?.values(state?.body)
            ?.sort((a, b) => a?.position - b?.position)
            ?.map(component => (
              <ComponentWrapper key={component?.id}>
                {getComponentByType(
                  component,
                  onChange,
                  error?.body?.[component?.id],
                  onChangeSignature,
                  handleUploadFile,
                  handleDeleteFile,
                  false,
                  form?.main_field_id,
                  usePreviewForImage,
                )}
              </ComponentWrapper>
            ))}
        </Spin>
      </Wrapper>
      {showEmailModal && (
        <NotificationModal
          title='Send Forms'
          showModal
          setShowModal={handleCloseNNotificationModal}
          templateType={NotificationTemplateType.GENERAL}
          entityIds={[form.id]}
          otherClientEmails={[
            ...new Set(
              otherClientEmails,
              contacts?.emails?.filter(item => item && validator.isEmail(item)),
            ),
          ]}
        />
      )}
    </Modal>
  )
}

const FormTitle = ({ name, title, onChange, onSave }) => {
  const [editMode, setEditMode] = useState(false)
  const [formName, setFormName] = useState(name)

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormName(e.target.value)
  }

  const handleNameSave = () => {
    const newTitle = formName || title

    onChange(newTitle)
    setFormName(newTitle)
    setEditMode(false)

    onSave(newTitle)
  }

  return (
    <Content>
      <FormName>
        {editMode ? (
          <>
            <TextField
              value={formName}
              onChange={handleNameChange}
              onBlur={handleNameSave}
              autoFocus
              style={{ paddingRight: 30 }}
            />
            <CheckOutlined
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
              onClick={e => {
                e.stopPropagation()
                handleNameSave()
              }}
            />
          </>
        ) : (
          <Text onClick={() => setEditMode(!editMode)}>
            {formName}
            <EditOutlined
              style={{
                fontSize: 15,
                alignSelf: 'start',
                position: 'relative',
                top: 7,
              }}
            />
          </Text>
        )}
      </FormName>
      <Title>Template: {title}</Title>
    </Content>
  )
}

const Wrapper = styled.div``
const Content = styled.div`
  /* margin-left: -12px; */
`
const FormName = styled.div`
  input {
    font-size: 18px;
    font-weight: 500;
  }
  font-size: 18px;
  max-width: 80%;
  position: relative;
`
const Text = styled.p`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 4px 12px 3.5px;
`
const Title = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #4d4d4d;
  margin-left: 12px;
`
const ButtonRow = styled.div`
  display: inline-flex;
  gap: 10px;
  align-items: center;
  margin-left: auto;
`

const ComponentWrapper = styled.div``
