import { useEffect } from 'react'
import L from 'leaflet'
import { createControlComponent } from '@react-leaflet/core'
import 'leaflet-routing-machine'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
// import 'lrm-valhalla'
import 'lrm-mapzen'

const createRoutineMachineLayer = ({ color, waypoints, setRoutesPlan }) => {
  const mapedWaipoints = waypoints.map(point => {
    return L.latLng(point[0], point[1])
  })

  const instance = L.Routing.control({
    router: L?.Routing?.mapzen('<my api key>', { serviceUrl: 'https://route.orcatec.com/route?', costing: 'auto' }),
    waypoints: mapedWaipoints,
    lineOptions: {
      styles: [{ color: color || '#6FA1EC', weight: 4 }],
    },
    createMarker: function () {
      return null
    },
    show: false,
    addWaypoints: false,
    routeWhileDragging: true,
    draggableWaypoints: true,
    fitSelectedRoutes: false,
    showAlternatives: false,
  })
  // instance.on('routesfound', function (e) {
  //   let distance = e.routes[0].summary.totalDistance;
  //   setRoutesPlan(e.routes)
  //   console.log('routing distance: ' + distance);

  // });
  instance.on('routingerror', function (e) {
    openNotificationWithIcon('error', { message: 'Path distance exceeds the max distance limit' })
  })

  return instance
}

const RoutingMachine = createControlComponent(createRoutineMachineLayer)
export default RoutingMachine
