import Echo from 'laravel-echo'

export const getConnector = () => {
  const authData = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : false
  const token = authData ? authData.token : false
  const host = process.env.REACT_APP_SOCKET_URL
  const path = process.env.REACT_APP_SOCKET_PATH
  if (!token) {
    removeConnector()
  } else if (!window.Echo && host) {
    setConnector({
      host,
      path,
      token,
    })
  }

  return window.Echo
}

export const removeConnector = () => {
  window.Echo = null
}

export const setConnector = ({ host, token }) => {
  const path = process.env.REACT_APP_SOCKET_PATH
  window.Echo = new Echo({
    broadcaster: 'socket.io',
    host,
    path,
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  })
}
