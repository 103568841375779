import axiosOrcatec from 'api/axiosInstance'
import {
  MembershipEntityType,
  MembershipLabel,
  MembershipRelation,
} from 'features/Membership/types'

export const getMembershipLabel = (id: string) =>
  axiosOrcatec.get(`/memberships/${id}`).then(r => r && r.data)
export const getMembershipList = (params?: any) =>
  axiosOrcatec
    .get<{ data: MembershipLabel[] }>(`/memberships`, { params })
    .then(r => r && r.data)
export const getMembershipRelationsList = (params?: any) =>
  axiosOrcatec
    .get(`/memberships/relation-list`, { params })
    .then(r => r && r.data)

export const getMembershipRelationList = (
  membershipId: string | number,
  entityType: MembershipEntityType,
) =>
  axiosOrcatec
    .get(`/memberships/${membershipId}/${entityType}`)
    .then(r => r && r.data)

export const createMembershipLabel = (data: any) =>
  axiosOrcatec.post(`/memberships`, data).then(r => r && r.data)
export const updateMembershipLabel = (data: any) =>
  axiosOrcatec.put(`/memberships/${data.id}`, data).then(r => r && r.data)
export const deleteMembershipLabel = (id: number | string) =>
  axiosOrcatec.delete(`/memberships/${id}`).then(r => r && r.data)
export const assignMembership = (
  entityType: MembershipEntityType,
  entityId: string,
  data: MembershipRelation,
) =>
  axiosOrcatec
    .post(`/${entityType}/${entityId}/assign_membership`, data)
    .then(r => r && r.data)
export const unassignMembership = (
  entityType: MembershipEntityType,
  entityId: string,
  data: { label_id: number },
) =>
  axiosOrcatec
    .delete(`/${entityType}/${entityId}/detach_membership`, { data })
    .then(r => r && r.data)
