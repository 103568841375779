import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  span {
    font-size: 22px;
    line-height: 0;

    &:hover {
      color: #4285f4;
    }
  }
`
