export const filterArrayOfObjectsToUniqueKeyValue = (arrayOfObjects, key = '') => {
  const result = []
  const map = new Map()
  for (const item of arrayOfObjects) {
    if (key.includes('.')) {
      const keys = key.split('.')
      if (item[keys[0]] && item[keys[0]][keys[1]] && !map.has(item[keys[0]][keys[1]])) {
        map.set(item[keys[0]][keys[1]], true)
        result.push(item)
      }
    } else {
      if (item[key] && !map.has(item[key])) {
        map.set(item[key], true)
        result.push(item)
      }
    }
  }
  return result
}
