import axios from 'axios'
import packageJson from '../../package.json'

const currentVersion = packageJson.version

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g)

  const versionsB = versionB.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())

    const b = Number(versionsB.shift())

    if (a === b) continue

    return a > b || isNaN(b)
  }
  return false
}

const refreshCacheAndReload = async () => {
  if (document.visibilityState !== 'visible') return

  console.warn('Clearing cache and local storage and hard reloading...')

  // Service worker cache should be cleared with caches.delete()
  if (caches) {
    caches.keys().then(names => {
      for (const name of names) caches.delete(name)
    })
  }
  // localStorage?.clear?.()

  // delete browser cache and hard reload
  window.location.reload(true)
}

const checkAppVersion = async () => {
  const { version } = await axios('/meta.json').then(res => res.data)

  const shouldForceRefresh = semverGreaterThan(version, currentVersion)
  console.warn(version, currentVersion, shouldForceRefresh)

  if (shouldForceRefresh) refreshCacheAndReload()
}
export default {
  checkAppVersion,
}
