import * as types from '../../types'

let initialState = {
  show: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clientActivity.TOGGLE_CLIENT_ACTIVITY:
      return { show: !state.show }
    default:
      return state
  }
}
