import React, { useEffect, useState } from 'react'
import TimeCard from '../TimeCard/TimeCard'

import './TimeCardList.scss'

import MainButton from '../../../components/buttons/MainButton'
import { Empty } from 'antd'
import { AddNewDay } from './AddNewDay'
import { useDispatch, useSelector } from 'react-redux'
import { addNewDay, getTimeCardByUserId } from 'store/Orcatec/actions/timecards'
import { timecardsSelector } from 'store/Orcatec/reducers/timecards'
import moment from 'moment-timezone'
import Spinner from 'adminModule/components/Spinner'
import { TimeCardsPermissions } from 'features/Settings/UsersAndGroups'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'

const TimeCardList = ({
  modalValues,
  readOnly,
  timeState: { startDate, endDate },
  timezone,
}) => {
  const [cardData, setCardData] = useState(null)
  const [fetchingError, setFetchingError] = useState('')
  const [newDay, setNewDay] = useState(false)
  // const [loading, setLoading] = useState(false)
  const [openedCard, setOpenedCard] = useState('')

  const dispatch = useDispatch()
  const { loading, currentUserTimeCard } = useSelector(timecardsSelector)

  useEffect(() => {
    dispatch(getTimeCardByUserId(modalValues?.id, startDate, endDate, timezone))
  }, [startDate, endDate, timezone])

  useEffect(() => {
    setCardData(currentUserTimeCard?.data)
  }, [currentUserTimeCard])

  const fetchCard = () => {
    dispatch(getTimeCardByUserId(modalValues?.id, startDate, endDate, timezone))
  }

  const updateCardList = res => {
    setCardData(res)
  }

  const handleAddNewDay = date => {
    dispatch(addNewDay(moment(date)))
    setOpenedCard(moment(date).format('YYYY-MM-DD'))
    setNewDay(false)
  }

  const setOpenedCardCall = idx => {
    idx === openedCard ? setOpenedCard(null) : setOpenedCard(idx)
  }

  const triggerNewDay = () => {
    setNewDay(true)
  }

  return (
    <div
      className='row p-3 modal-wrapper'
      style={{ border: '1px solid rgb(208,208,208)' }}
      id='modal'
    >
      {cardData ? (
        <>
          <div className='row'>
            <div className='mr-3'>
              <h4 className='pl-3'>
                Total days worked:{' '}
                <strong>{currentUserTimeCard?.total_worked_days}</strong>
              </h4>
            </div>
            <div className='mr-3'>
              <h4>
                Total time worked:{' '}
                <strong>{currentUserTimeCard?.total_time_worked}</strong>
              </h4>
            </div>
          </div>
          {loading && <Spinner />}
          {newDay && (
            <AddNewDay
              modalValues={modalValues}
              startDate={startDate}
              endDate={endDate}
              setCardData={setCardData}
              setNewDay={setNewDay}
              days={cardData}
              timezone={timezone}
              onAddNewDay={handleAddNewDay}
            />
          )}
          {Object.values(cardData)
            ?.sort((a, b) => moment(a.date) - moment(b.date))
            ?.map((card, idx) => (
              <TimeCard
                key={idx}
                i={card.date}
                card={card}
                openedCard={openedCard}
                setOpenedCardCall={setOpenedCardCall}
                user_id={modalValues?.id}
                startDate={startDate}
                endDate={endDate}
                fetchCard={() => fetchCard()}
                updateCardList={updateCardList}
                timezone={timezone}
                readOnly={readOnly}
              />
            ))}
        </>
      ) : (
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          {newDay ? (
            <AddNewDay
              modalValues={modalValues}
              startDate={startDate}
              endDate={endDate}
              setCardData={setCardData}
              setNewDay={setNewDay}
              timezone={timezone}
            />
          ) : fetchingError ? (
            <span>{fetchingError}</span>
          ) : (
            <Empty description={`Data not found`} />
          )}
        </div>
      )}
      <AccessControl
        scopes={[TimeCardsPermissions.TIME_CARDS_USER_TIME_CARD_CAN_EDIT]}
      >
        {!newDay && !readOnly && (
          <MainButton title='Add New Day' onClick={triggerNewDay} />
        )}
      </AccessControl>
    </div>
  )
}

export default TimeCardList
