import { Card, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

const { Meta } = Card

export const ContactCard = ({ contact, onDelete }) => {
  return (
    <Card
      style={{ width: 300, minHeight: 150 }}
      actions={[
        <Popconfirm
          key='setting'
          onConfirm={onDelete}
          title='Are you sure to delete this contact?'
        >
          <DeleteOutlined />
        </Popconfirm>,
      ]}
    >
      <Meta
        title={contact?.full_name || 'No name'}
        description={
          <>
            <div>
              <b>{`Phones: ${contact?.phones?.length ? '' : '-'}`}</b>
              <div>
                {contact?.phones?.map(phone => (
                  <p key={phone?.id}>{phone?.phone}</p>
                ))}
              </div>
            </div>
            <div>
              <b>{`Emails:${contact?.emails?.length ? '' : '-'}`}</b>
              <div>
                {contact?.emails?.map(email => (
                  <p key={email?.id}>{email?.email}</p>
                ))}
              </div>
            </div>
          </>
        }
      />
    </Card>
  )
}
