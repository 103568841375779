export enum PropertyPermissions {
  PROPERTIES_LIST_CAN_READ = 'properties_list_can_read',
  PROPERTIES_LIST_CAN_EXPORT = 'properties_list_can_export',
  PROPERTIES_CAN_CREATE = 'properties_can_create',
  PROPERTIES_CAN_EDIT = 'properties_can_edit',
  PROPERTIES_CAN_DELETE = 'properties_can_delete',
  PROPERTIES_CAN_MERGE = 'properties_can_merge',

  PROPERTIES_CAN_CREATE_CUSTOM_FORMS = 'properties_can_create_custom_forms',
  PROPERTIES_CAN_READ_CUSTOM_FORMS = 'properties_can_read_custom_forms',
  PROPERTIES_CAN_EDIT_CUSTOM_FORMS = 'properties_can_edit_custom_forms',
  PROPERTIES_CAN_DELETE_CUSTOM_FORMS = 'properties_can_delete_custom_forms',
}
