import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import { AppStateType } from 'store'
import { getUserPermission } from 'api/User'
import { transformObjectToStore } from './Groups/GroupForm/utils/transformObjectToStore'
import {
  Module,
  ModuleName,
  UserPermissions,
  ProjectPermissions,
} from './index'
import { checkAccessControl } from './helpers/checkAccessControl'
// type TransformedPermissions = {
//   [module in ModuleName]: {
//     [permission in IPermissions[module]]: number
//   }
// }

type PermissionsType = {
  [key: string]: Record<string, any>
}

const initialPermission = {
  project: {
    [Module.PROJECT]: 0,
  },
  schedule: {
    [Module.SCHEDULE]: 0,
  },
}

export const fetchUserPermissions = createAsyncThunk<UserPermissions, number>(
  'users/fetchUserPermissions',
  async (id: number) => {
    const { module_permissions } = await getUserPermission(id)

    return module_permissions
  },
)

const initialState: {
  status: 'idle' | 'loading' | 'success' | 'error'
  current: PermissionsType
  error: { [key: string]: string } | null
} = {
  status: 'idle',
  current: initialPermission,
  error: null,
}

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setUserPermission: (state, { payload }) => {
      const { group, value } = payload
      state.current[group] = value
    },
    updateCurrentPermission: (state, { payload }) => {
      state.current = transformObjectToStore(payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserPermissions.fulfilled, (state, { payload }) => {
      state.current = transformObjectToStore(payload)
      state.status = 'success'
    })
    builder.addCase(fetchUserPermissions.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchUserPermissions.pending, state => {
      state.status = 'loading'
    })
  },
})

export default permissionsSlice.reducer
export const {
  setUserPermission,
  updateCurrentPermission,
} = permissionsSlice.actions

export const userPermissions = (state: AppStateType) =>
  state.orcatec?.permissions?.current

export const selectUserPermissionsByModule = (module: ModuleName) =>
  createSelector(userPermissions, permissions => permissions[module])

export const selectUserPermissionsByScope = (permissions: string[]) =>
  createSelector(userPermissions, permissionsObj => {
    const combinedPermissions = {}

    for (const item of Object.values(permissionsObj)) {
      for (const key of permissions) {
        if (item[key] !== undefined) {
          combinedPermissions[key] = item[key]
        }
      }
    }

    return combinedPermissions
  })

export const selectUserPermissionsByName = (module: ModuleName, key: string) =>
  createSelector(userPermissions, permissions => permissions?.[module]?.[key])

export const selectIsOwner = (userId: number) => {
  return createSelector(
    (state: AppStateType) => state.orcatec.company.owner_id === userId,
    isOwner => isOwner,
  )
}

export const canVerifyFormSelect = createSelector(
  userPermissions,
  permissions => !!permissions.forms?.forms_can_verify,
)

export const canSendFormSelect = createSelector(
  userPermissions,
  permissions => !!permissions.forms?.forms_can_send,
)

export const selectModulePermissions = () => {
  return createSelector(
    (state: AppStateType) => state?.orcatec?.permissions?.current,
    (state: AppStateType) => state?.orcatec?.user.me.paid_modules,

    (permissions: PermissionsType, paid_modules) => ({
      accounting: permissions?.accounting?.[Module.ACCOUNTING],
      balances: permissions?.accounting?.[Module.BALANCE],
      expenses: permissions?.accounting?.[Module.EXPENSES],
      work_progress: permissions?.accounting?.[Module.WORK_PROGRESS],
      call_tracking: permissions?.call_tracking?.[Module.CALL_TRACKING],
      contacts: permissions?.contacts?.[Module.CONTACTS],
      dashboard: permissions?.dashboard?.[Module.DASHBOARD],
      dispatch: permissions?.dispatch?.[Module.DISPATCH],
      fast_payment: permissions?.fast_payment?.[Module.FAST_PAYMENT],
      forms: permissions?.forms?.[Module.FORMS],
      forms_template: permissions?.forms?.[Module.FORMS_TEMPLATE],
      forms_detailed: permissions?.forms?.[Module.FORMS_DETAILED],
      instant_appointment:
        permissions?.instant_appointment?.[Module.INSTANT_APPOINTMENT],
      jobs: permissions?.jobs?.[Module.JOBS],
      map: permissions?.map?.[Module.MAP],
      memberships:
        permissions?.memberships?.[Module.MEMBERSHIPS] &&
        paid_modules?.memberships,
      messaging: permissions?.messaging?.[Module.MESSAGING],
      notes: permissions?.notes?.[Module.NOTES],
      project: permissions?.project?.[Module.PROJECT],
      properties: permissions?.properties?.[Module.PROPERTIES],
      quickbooks: permissions?.quickbooks?.[Module.QUICKBOOKS],
      reviews:
        permissions?.reviews?.[Module.REVIEWS] && paid_modules?.request_review,
      schedule: permissions?.schedule?.[Module.SCHEDULE],
      settings: permissions?.settings?.[Module.SETTINGS],
      estimates: permissions?.estimates?.[Module.ESTIMATES],
      time_cards:
        permissions?.time_cards?.[Module.TIME_CARDS] &&
        paid_modules?.time_cards,
      todo: permissions?.todo?.[Module.TODO] && paid_modules?.todo,
    }),
  )
}

export const connectModulePermissions = state => {
  return createSelector(
    () => state?.orcatec?.permissions?.current,
    () => state?.orcatec?.user.me.paid_modules,
    (permissions: PermissionsType, paid_modules) => ({
      accounting: permissions?.accounting?.[Module.ACCOUNTING],
      balances: permissions?.accounting?.[Module.BALANCE],
      expenses: permissions?.accounting?.[Module.EXPENSES],
      work_progress: permissions?.accounting?.[Module.WORK_PROGRESS],
      call_tracking: permissions?.call_tracking?.[Module.CALL_TRACKING],
      contacts: permissions?.contacts?.[Module.CONTACTS],
      dashboard: permissions?.dashboard?.[Module.DASHBOARD],
      dispatch: permissions?.dispatch?.[Module.DISPATCH],
      fast_payment: permissions?.fast_payment?.[Module.FAST_PAYMENT],
      forms: permissions?.forms?.[Module.FORMS],
      forms_template: permissions?.forms?.[Module.FORMS_TEMPLATE],
      forms_detailed: permissions?.forms?.[Module.FORMS_DETAILED],
      instant_appointment:
        permissions?.instant_appointment?.[Module.INSTANT_APPOINTMENT],
      jobs: permissions?.jobs?.[Module.JOBS],
      map: permissions?.map?.[Module.MAP],
      memberships:
        permissions?.memberships?.[Module.MEMBERSHIPS] &&
        paid_modules?.memberships,
      messaging: permissions?.messaging?.[Module.MESSAGING],
      notes: permissions?.notes?.[Module.NOTES],
      project: permissions?.project?.[Module.PROJECT],
      properties: permissions?.properties?.[Module.PROPERTIES],
      quickbooks: permissions?.quickbooks?.[Module.QUICKBOOKS],
      reviews:
        permissions?.reviews?.[Module.REVIEWS] && paid_modules?.request_review,
      schedule: permissions?.schedule?.[Module.SCHEDULE],
      settings: permissions?.settings?.[Module.SETTINGS],
      estimates: permissions?.estimates?.[Module.ESTIMATES],

      time_cards:
        permissions?.time_cards?.[Module.TIME_CARDS] &&
        paid_modules?.time_cards,
      todo: permissions?.todo?.[Module.TODO] && paid_modules?.todo,
    }),
  )
}

export const selectIsPriceVisible = (userId: number) => {
  return createSelector(
    (state: AppStateType) => state.orcatec,
    state =>
      checkAccessControl(
        state.permissions.current[ModuleName.PROJECT][
          ProjectPermissions.PROJECT_CAN_READ_PRICE
        ],
        userId,
        state.user.me.id,
      ),
  )
}
