import React, { useEffect, useMemo, useState } from 'react'

import Table from 'containers/MainContent/Orcatec/components/Table'
import useDebounce from 'hooks/useDebounce'
import { ellipsisStyle } from 'containers/MainContent/Orcatec/Properties'
import { ColumnProps } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import { IProposalsTableItem } from 'types/Proposal'
import { getValueFromLS } from 'hooks/useLocalStorage'
import { PaginationConfig } from 'antd/lib/pagination'
import { FilterDropdownProps, SorterResult } from 'antd/lib/table/interface'
import TableLayout, {
  IconWrapper,
  ActionsWrapper,
} from 'containers/MainContent/Orcatec/Layouts/TableLayout/TableLayout'
import { Settings } from '@material-ui/icons'
import { getMembershipList, getMembershipRelationsList } from 'api/Membership'
import {
  MembershipDiscountType,
  MembershipEntityTypeId,
  MembershipLabel,
} from 'features/Membership/types'
import {
  ClientIcon,
  PropertyIcon,
  OrganizationIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import AutocompliteFilter from 'containers/MainContent/Orcatec/components/Table/components/TableFilters/AutocompliteFilter/AutocompliteFilter'
import styled from 'styled-components'
import { getContrastYIQ } from 'helpers/contrastColor'

interface MembershipsTableItem extends MembershipLabel {
  entity_type: MembershipEntityTypeId
  entity_name: string
  entity_id: number
  key: number
}

export const entityTypeFilter = [
  {
    text: 'Property',
    value: 1,
  },
  {
    text: 'Client',
    value: 2,
  },
  {
    text: 'Organization',
    value: 3,
  },
]

const statusFilter = [
  {
    text: 'Active',
    value: 1,
  },
  {
    text: 'Expires Soon',
    value: 2,
  },
  {
    text: 'Expired',
    value: 3,
  },
]
export const getMembershipStatus = (status: number) => {
  let color, statusName
  if (status === 1) {
    color = '#2ebda3'
    statusName = 'Active'
  }
  if (status === 2) {
    color = '#7f8104'
    statusName = 'Expires soon'
  }
  if (status === 3) {
    color = '#e53655'
    statusName = 'Expired'
  }
  return (
    <p style={{ textAlign: 'center', color: color, borderRadius: 3 }}>
      {statusName}
    </p>
  )
}

export const PageMemberships = () => {
  const [membershipEntries, setMembershipEntries] = useState<
    MembershipsTableItem[] | []
  >([])
  const [loading, setLoading] = useState(false)
  const settingsFromLC = getValueFromLS(`memberships-table_v1`)
  const [sortField, setSortField] = useState<string | null>(
    settingsFromLC?.sorter?.sort_field || null,
  )
  const [sortOrder, setSortOrder] = useState<string | null>(
    settingsFromLC?.sorter?.order || null,
  )
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: settingsFromLC?.page_size || 25,
    total: 25,
  })
  const [memberships, setMemberships] = useState([])
  const [search, setSearch] = useState(settingsFromLC?.search || '')
  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null)

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [tableFilters, setTableFilters] = useState({
    ...settingsFromLC?.filters,
  })

  const debouncedSearchValue = useDebounce(search, 500)
  const [openTableSettings, setOpenTableSettings] = useState(false)

  const fetchProposals = async (params?: object) => {
    setLoading(true)
    try {
      const { data, meta } = await getMembershipRelationsList(params)

      setMembershipEntries(
        data.map((proposal: IProposalsTableItem, index: number) => ({
          ...proposal,
          key: index,
        })),
      )
      setPagination(meta)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProposals({
      per_page: pagination.per_page,
      search: debouncedSearchValue || undefined,
      sort_field: sortField,
      order: sortOrder,
      ...tableFilters,
    })
  }, [debouncedSearchValue])

  useEffect(() => {
    const getMemberships = async () => {
      const { data } = await getMembershipList({ per_page: 50 })
      setMemberships(data)
    }

    getMemberships()
  }, [])

  const getEntityRoute = ({ entity_type, entity_id }: MembershipsTableItem) =>
    entity_type === MembershipEntityTypeId.Property
      ? `/properties-form/${entity_id}`
      : entity_type === MembershipEntityTypeId.Client
      ? `/clients/${entity_id}?clientType=${2}`
      : `/clients/${entity_id}?clientType=${1}`

  const columns: ColumnProps<MembershipsTableItem>[] = useMemo(
    () => [
      {
        title: 'Membership name',
        name: 'Membership name',
        dataIndex: 'name',
        sorter: true,
        defaultWidth: 200,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (text, record) => {
          return (
            <MembershipName backgroundColor={record.color}>
              {text}
            </MembershipName>
          )
        },
        filterDropdown: (props: FilterDropdownProps) => (
          <AutocompliteFilter
            title='Membership name'
            values={memberships}
            {...props}
          />
        ),
      },
      {
        title: 'Entity type',
        name: 'Entity type',
        dataIndex: 'entity_type',
        sorter: true,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 60,
        filters: entityTypeFilter,
        render: text =>
          text === MembershipEntityTypeId.Property ? (
            <PropertyIcon />
          ) : text === MembershipEntityTypeId.Client ? (
            <ClientIcon />
          ) : (
            <OrganizationIcon />
          ),
      },
      {
        title: 'Entity name',
        name: 'Entity name',
        dataIndex: 'entity_name',
        sorter: true,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 250,
        render: (text, record) => (
          <Link to={getEntityRoute(record)}>{text}</Link>
        ),
      },
      {
        title: 'Discount',
        name: 'Discount',
        dataIndex: 'discount',
        sorter: true,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
        render: (text, record) => {
          return `${
            record.discount_type === MembershipDiscountType.Amount ? '$' : ''
          }${text}${
            record.discount_type === MembershipDiscountType.Percent ? '%' : ''
          }`
        },
        noFilter: true,
      },
      {
        title: 'Start date',
        name: 'Start date',
        dataIndex: 'start_date',
        sorter: true,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
        noFilter: true,
      },
      {
        title: 'End date',
        name: 'End date',
        dataIndex: 'end_date',
        sorter: true,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
        noFilter: true,
      },
      {
        title: 'Status',
        name: 'Status',
        dataIndex: 'status',
        sorter: true,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
        noFilter: true,
        filters: statusFilter,
        render: status => getMembershipStatus(status),
      },
    ],
    [clickedRowIndex, memberships],
  )

  const handleTableChange = (
    pagination: PaginationConfig,
    filters: Partial<Record<keyof IProposalsTableItem, string[]>>,
    sorter: SorterResult<IProposalsTableItem>,
  ) => {
    setTableFilters(filters)
    fetchProposals({
      search: debouncedSearchValue || undefined,
      page: pagination.current,
      sort_field: sorter.order ? sorter.field : null,
      order:
        sorter.order === 'ascend'
          ? 'asc'
          : sorter.order === 'descend'
          ? 'desc'
          : null,
      per_page: pagination.pageSize,
      ...filters,
    })
    setSortOrder(
      sorter.order === 'ascend'
        ? 'asc'
        : sorter.order === 'descend'
        ? 'desc'
        : null,
    )
    setSortField(sorter.order ? sorter.field : null)
  }

  const onRow = (record: IProposalsTableItem, rowIndex: number) => ({
    onClick: () => {
      setClickedRowIndex(rowIndex)
    },
  })

  return (
    <TableLayout
      title='Memberships'
      className='proposals-table-page'
      visible={drawerVisible}
      onClose={() => setDrawerVisible(false)}
      actions={
        <ActionsWrapper>
          <InputField
            label='Search'
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
            fullWidth={false}
            style={{ width: '100%', maxWidth: '300px' }}
          />

          <IconWrapper onClick={() => setOpenTableSettings(true)}>
            <Tooltip placement='left' title='Columns settings'>
              <Settings />
            </Tooltip>
          </IconWrapper>
        </ActionsWrapper>
      }
    >
      <Table
        name={'memberships'}
        columns={columns}
        data={membershipEntries}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        onRow={onRow}
        // hiddenColumnsByDefault={['name', 'industry_name', 'installation_dates', 'full_address']}
        // additionalFilters={additionalFilters}
        search={debouncedSearchValue}
        openTableSettings={openTableSettings}
        onCloseTableSettings={() => setOpenTableSettings(false)}
      />
    </TableLayout>
  )
}

const MembershipName = styled.span<{ backgroundColor: string }>(
  ({ backgroundColor }) => ({
    padding: 5,
    backgroundColor,
    borderRadius: 4,
    color: getContrastYIQ(backgroundColor),
  }),
)
