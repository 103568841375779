import React, { useState, useEffect } from 'react'
import ClientForm from '../../../Clients/components/Form/components/Form'
import Table from '../../../components/CustomTable'
import { getClientById } from 'api/Client'
import Tab from '../../../Properties/components/Form/Tabs/Tab/Tab'
import ClientstTab from '../../../Properties/components/Form/Tabs/Clients'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import organizations from 'store/Orcatec/reducers/organizations'

const ClientsTable = ({
  clients,
  propertyId,
  deleteClient,
  onAttachClient,
  onChoseProperty,
  form,
  isOpen,
  toggleClientFalse,
  toggleClientTrue,
  toggleFormClientTrue,
  toggleFormClientFalse,
  organizationId,
  isOrganization,
  isAssociation,
  parentAssociations,
  isClient,
  isContact,
  getPopupContainer,
  stepOfOpenedForm,
  isSchedule,
  fetchClientsList,
  toggleEditClient = () => {},
  onUpdateRelation,
  onDetachClientOrOrganization,
  isPropertyForm,
  toggleClientForm,
  toggleFormClient,
  assotiationsId,
  showModal,
  withModal,
  onSubmit,
}) => {
  const [currentId, changeId] = useState('')
  useEffect(() => {
    if (clients && !clients?.length) {
      toggleClientTrue?.()
    }
    return () => toggleFormClientFalse?.()
  }, [])
  const onCreateNewClient = val => {
    if (val.id !== currentId) {
      if (onAttachClient) onAttachClient(val)
    } else {
      if (onChoseProperty && isAssociation) onChoseProperty(propertyId, true)
      if (toggleFormClientFalse) toggleFormClientFalse()
    }
    if (isOrganization) toggleFormClientFalse()
  }

  return (
    <div className='table-style'>
      <ClientstTab
        show={true}
        clients={clients ? clients : []}
        isAddButtonClicked={form}
        setIsAddButtonClicked={toggleFormClientFalse}
        onClickTab={() => (isOpen ? toggleClientFalse() : toggleClientTrue())}
        toggleEditClient={toggleEditClient}
        propertyId={propertyId}
        onAddAttachedClient={val => onCreateNewClient(val)}
        fetchProperty={id => (onChoseProperty ? onChoseProperty(id, true) : Promise.resolve())}
        isServiceContract={false}
        isAppointment={false}
        onDellAttachedClient={deleteClient}
        organizationId={organizationId}
        isOrganization={isOrganization}
        isAssociation={isAssociation}
        parentAssociations={parentAssociations}
        isClient={isClient}
        isContact={isContact}
        getPopupContainer={getPopupContainer}
        stepOfOpenedForm={stepOfOpenedForm}
        fetchClientsList={fetchClientsList}
        isSchedule={isSchedule}
        onUpdateRelation={onUpdateRelation}
        onDetachClientOrOrganization={onDetachClientOrOrganization}
        isPropertyForm={isPropertyForm}
        toggleClientForm={toggleFormClient}
        assotiationsId={assotiationsId}
        showModal={showModal}
        withModal={withModal}
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default ClientsTable
