import Auth from 'features/Auth'
import { AuthLayout } from 'layouts/AuthLayout/AuthLayout'

export const AuthPage = () => {
  return (
    <AuthLayout>
      <Auth />
    </AuthLayout>
  )
}
