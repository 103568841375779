export const getFileTypeByUrl = (name) => {
  let type = ''

  if (/\.(?:pdf|PDF)/.test(name)) type = '.pdf'
  else if (/\.(?:docx|DOCX|dotx|doc|DOC)/.test(name)) type = '.docx'
  else if (/\.(?:txt|TXT|html)/.test(name)) type = '.txt'
  else if (/\.(?:xlsx|XLSX|xls|XLS)/.test(name)) type = '.xlsx'
  else if (/\.(?:pptx|PPTX|ppt|PPT)/.test(name)) type = '.pptx'
  else if (/\.(?:mp3|MP3)/.test(name)) type = '.mp3'
  else if (/\.(?:wav|WAV)/.test(name)) type = '.wav'
  else if (/\.(?:aac|AAC)/.test(name)) type = '.aac'
  else if (/\.(?:mp4|MP4)/.test(name)) type = '.mp4'
  else if (/\.(?:3gpp|3gp)/.test(name)) type = '.3gp'
  else if (/\.(?:webm)/.test(name)) type = '.webm'
  else if (/\.(?:ogg)/.test(name)) type = '.ogg'
  else if (/\.(?:ogmm)/.test(name)) type = '.ogmm'


  return type
}