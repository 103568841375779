// import { Avatar } from 'antd'
import Avatar from 'containers/MainContent/Orcatec/components/Avatar'
import { getMediaURL } from 'helpers/getMediaURL'
import { AvatarWrapper } from './UserAvatar.styles'
import React from 'react'

interface IUserAvatar {
  background_color: string | null
  profile_picture: string
  full_name: string
  size?: number
  isCompactView?: boolean
  minimizeView?: boolean
}

const style = {
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textAlign: 'center',
}

export const UserAvatar: React.FC<IUserAvatar> = ({
  profile_picture = '',
  full_name = '',
  size = 42,
  background_color,
  isCompactView = false,
  minimizeView = false,
}) => (
  <AvatarWrapper minimizeView={minimizeView} isCompactView={isCompactView}>
    <Avatar
      pictureURL={getMediaURL(profile_picture)}
      color={background_color || '#626ed4'}
      size={size}
    >
      {full_name}
    </Avatar>
    {!!full_name && <span style={style}>{full_name}</span>}

    {/*  <img
      src='/assets/Santa_hat.png'
    /> */}
  </AvatarWrapper>
)
