export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT'
export const CHANGE_TYPE = 'CHANGE_TYPE'
export const PASTE_DATA = 'PASTE_DATA'
export const SET_APPOINTMENTS = 'SET_APPOINTMENTS'
export const SET_TYPES = 'SET_TYPES'
export const SET_SOURCES = 'SET_SOURCES'
export const SET_CURRENT_APPOINTMENT_ID = 'SET_CURRENT_APPOINTMENT_ID'

export const TYPE_ADDED = 'TYPE_ADDED'
export const TYPE_DELETED = 'TYPE_DELETED'

export const SOURCE_ADDED = 'SOURCE_ADDED'
export const SOURCE_DELETED = 'SOURCE_DELETED'

export const SET_OPEN_SIDEBAR = 'SET_OPEN_SIDEBAR'
export const SET_FREE_PLACE = 'SET_FREE_PLACE'
