import axiosOrcatec from './axiosInstance'
import { getQueryStringFromObject } from '../helpers/getQueryStringFromObject'
import { Property } from 'types/Property'
import moment from 'moment-timezone'
import { configV2API } from './helpers'

/**
 * Fetch properties list.
 *
 * @return {*}
 */
export const fetchPropertiesList = (params: any) => {
  // props - object with query params
  // const params = getQueryStringFromObject(props)
  return axiosOrcatec
    .get<Property[]>('properties', { params })
    .then(response => response.data)
}

export const searchPropertyByAddress = (searchValue: string) => {
  return axiosOrcatec
    .get(`properties?search=${searchValue}`)
    .then(response => response.data)
}

/**
 * Fetch property.
 *
 * @param id
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const fetchProperty = (id: number) => {
  return axiosOrcatec
    .get<Property>(`properties/${id}`)
    .then(response => response.data)
}

/**
 * Search property by address.
 *
 * @param query
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const searchAddress = (query: string) => {
  return axiosOrcatec
    .get(`properties/search?query=${query}`)
    .then(response => response.data)
}

export const apiV2Config = {
  baseURL: process.env.REACT_APP_API_URL?.replace('v1.0', 'v2'),
}

export const getAddressByInput = (query: string) =>
  axiosOrcatec
    .get(`properties/search?query=${query}`, apiV2Config)
    .then(res => res.data)

export const getAddressByCompanyHash = (
  companyHash: string,
  params: { address: string },
) =>
  axiosOrcatec
    .get(`search-address/${companyHash}`, { params })
    .then(res => res.data)

export const getPublicAddress = (query: string) =>
  axiosOrcatec
    .get(`properties/external/search?query=${query}`, apiV2Config)
    .then(res => res.data)

export const getAddresByRelation = (query: string) =>
  axiosOrcatec
    .get(`properties/relation/search?query=${query}`, apiV2Config)
    .then(res => res.data)

export const searchLocalAddress = ({
  address = '',
  parameter = '',
  publicAppointmentView = false,
  companyHash = '',
  searchByClientName = false,
}) => {
  const params = getQueryStringFromObject({ address, parameter })

  return axiosOrcatec
    .get(
      `${
        publicAppointmentView
          ? `search-address/${companyHash}${params ? `?${params}` : ''}`
          : `properties/search${params ? `?${params}` : ''}${
              searchByClientName ? '&by_client_name=1' : ''
            }`
      }`,
    )
    .then(response => {
      /* if (!response.data.DB.length && !response.data.Pelias.length)
        return axiosOrcatec
          .get(
            `${
              publicAppointmentView
                ? `search-address/${companyHash}${params ? `?${params}&parameter=search` : ''}`
                : `properties/search${params ? `?${params}&parameter=search` : ''}`
            }`,
          )
          .then(response => response.data) */
      return response.data
    })
}

/**
 * Create property.
 *
 * @param data
 * @return {*}
 */
export const createProperty = (data: Property) => {
  return axiosOrcatec
    .post<Property>('properties', data)
    .then(response => response.data)
}

/**
 * Update property.
 *
 * @param id
 * @param data
 * @return {Promise<T | never>}
 */
export const updateProperty = (id: number, data: Property) => {
  return axiosOrcatec
    .put<Property>(`properties/${id}`, data)
    .then(response => response.data)
}

export const manageProperty = (id: number, data: Property) => {
  return axiosOrcatec
    .put<Property>(`properties/${id}/manager`, data)
    .then(response => response.data)
}

export const updatePropertyRelation = (data: {
  property_relation: string
  relation_id: number
}) => axiosOrcatec.put(`/properties/update-property-relation`, data)

/**
 * Delete property.
 *
 * @param id
 * @return {*}
 */
export const deleteProperty = (id: number) => {
  return axiosOrcatec.delete(`properties/${id}`).then(response => response.data)
}

/**
 *	 Get property
 *
 *	 @param id
 *  @return {Promise<AxiosResponse<any> | never>}
 **/
export const getPropertyById = (id: number) => {
  const propertyConfig = {
    baseURL: process.env.REACT_APP_API_URL?.replace?.('v1.0', 'v2'),
  }
  return axiosOrcatec
    .get(`/properties/${id}`, propertyConfig)
    .then(({ data: { data } }) => data)
}

/**
 * Merge properties.
 *
 * @param data = {property_id_from: number, property_id_to:number}
 *  @return {Promise<AxiosResponse<any> | never>}
 */
export const mergeProperties = (data: {
  property_id_from: number
  property_id_to: number
}) => {
  return axiosOrcatec
    .post(`properties/merge`, data)
    .then(response => response.data)
}

export const exportPropertyTable = (params: any) =>
  axiosOrcatec
    .get(`/export/properties`, {
      responseType: 'blob',
      params,
    })
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Properties_${moment().format('DD-MM')}.xls`,
      )
      document.body.appendChild(link)
      link.click()
    })

export const detachClientOrOrganization = (
  property_id: number,
  entity_id: number,
  entityType: number,
) => {
  const data =
    entityType === 2 ? { client_id: entity_id } : { organization_id: entity_id }
  return axiosOrcatec
    .put(`properties/detach/client-or-organization/${property_id}`, data)
    .then(response => response.data)
}

export const attachClientOrOrganization = (
  property_id: number,
  contact_id: number,
  data: { note: string },
) => {
  return axiosOrcatec
    .patch(
      `/properties/${property_id}/clients/${contact_id}/attach`,
      data,
      configV2API,
    )
    .then(response => response.data)
}

export const getPropertyForms = (propertyId: number) =>
  axiosOrcatec
    .get(`properties/${propertyId}/custom-forms`)
    .then(res => res.data)
