import React, { FC } from 'react'
import { Rate, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { IReviewsTemplate } from 'features/Reviews/types'
import { Companies } from 'types/Company'
import ReactHtmlParser from 'react-html-parser'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

interface IProps {
  spining: boolean
  handleSelect: () => void
  template: IReviewsTemplate
  company: IReviewsTemplate
  vote: number
}

export const VoteForm: FC<IProps> = ({
  company,
  handleSelect,
  vote,
  spining,
  template,
}) => {
  return (
    <>
      {!!company?.company_logo ? (
        [Companies.ApplianceServiСenter].includes(company?.company_id) ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <img
              src={`${process.env.REACT_APP_API_MEDIA}${company?.company_logo}`}
              width={140}
              alt=''
            />
            <p style={{ textTransform: 'uppercase', fontSize: '18px' }}>
              Appliance Service Center
            </p>
          </div>
        ) : (
          <img
            src={`${process.env.REACT_APP_API_MEDIA}${company?.company_logo}`}
            width={200}
            alt=''
          />
        )
      ) : (
        <h2>{company?.company_name}</h2>
      )}
      <>
        {[Companies.ApplianceServiСenter].includes(company?.company_id) &&
          ReactHtmlParser(template?.set_rating_text)}
        <h3>How did we do?</h3>
        <Rate style={{ fontSize: 30 }} onChange={handleSelect} value={vote} />
        <div>
          <Spin spinning={spining} indicator={antIcon} />
        </div>
      </>
    </>
  )
}
