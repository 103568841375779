// export const timezones = [
//   { name: '(GMT-11:00) Pago Pago', timezone: 'Pacific/Pago_Pago', offset: -39600 },
//   { name: '(GMT-10:00) America (Adak)', timezone: 'America/Adak', offset: -36000 },
//   { name: '(GMT-10:00) Hawaii', timezone: 'Pacific/Honolulu', offset: -36000 },
//   { name: '(GMT-09:30) Marquesas', timezone: 'Pacific/Marquesas', offset: -34200 },
//   { name: '(GMT-09:00) Alaska', timezone: 'America/Metlakatla', offset: -32400 },
//   { name: '(GMT-09:00) America (Anchorage)', timezone: 'America/Anchorage', offset: -32400 },
//   { name: '(GMT-09:00) Gambier', timezone: 'Pacific/Gambier', offset: -32400 },
//   { name: '(GMT-08:00) Pacific Time (US & Canada)', timezone: 'US/Pacific', offset: -28800 },
//   { name: '(GMT-08:00) Pitcairn', timezone: 'Pacific/Pitcairn', offset: -28800 },
//   { name: '(GMT-08:00) Tijuana', timezone: 'America/Tijuana', offset: -28800 },
//   { name: '(GMT-08:00) America (Los Angeles)', timezone: 'America/Los_Angeles', offset: -28800 },
//   { name: '(GMT-07:00) Arizona', timezone: 'America/Phoenix', offset: -25200 },
//   { name: '(GMT-07:00) Chihuahua, Mazatlan - New', timezone: 'America/Chihuahua', offset: -25200 },
//   { name: '(GMT-07:00) Mountain Time (US & Canada)', timezone: 'America/Denver', offset: -25200 },
//   { name: '(GMT-06:00) Central America', timezone: 'Etc/GMT+6', offset: -21600 },
//   { name: '(GMT-06:00) Central Time (US & Canada)', timezone: 'America/North_Dakota/Center', offset: -21600 },
//   { name: '(GMT-06:00) Costa Rica', timezone: 'America/Costa_Rica', offset: -21600 },
//   { name: '(GMT-06:00) Eastern Time - Indiana - Starke County', timezone: 'America/Indiana/Knox', offset: -21600 },
//   { name: '(GMT-06:00) Mexico City', timezone: 'America/Mexico_City', offset: -21600 },
//   { name: '(GMT-06:00) Saskatchewan, Tegucigalpa', timezone: 'America/Regina', offset: -21600 },
//   { name: '(GMT-06:00) America (Chicago)', timezone: 'America/Chicago', offset: -21600 },
//   { name: '(GMT-06:00) America (Guatemala)', timezone: 'America/Guatemala', offset: -21600 },
//   { name: '(GMT-06:00) America (Tegucigalpa)', timezone: 'America/Tegucigalpa', offset: -21600 },
//   { name: '(GMT-05:00) Bogota, Lima', timezone: 'America/Bogota', offset: -18000 },
//   { name: '(GMT-05:00) Easter Island & Sala y Gomez', timezone: 'Pacific/Easter', offset: -18000 },
//   { name: '(GMT-05:00) Eastern Time - Indiana - most locations', timezone: 'America/Toronto', offset: -18000 },
//   { name: '(GMT-05:00) Eastern Time (US & Canada)', timezone: 'America/New_York', offset: -18000 },
//   { name: '(GMT-05:00) America (Nassau)', timezone: 'America/Nassau', offset: -18000 },
//   { name: '(GMT-04:00) Atlantic (Bermuda)', timezone: 'Atlantic/Bermuda', offset: -14400 },
//   { name: '(GMT-04:00) Atlantic Time (Canada)', timezone: 'America/Halifax', offset: -14400 },
//   { name: '(GMT-04:00) Caracas', timezone: 'America/Caracas', offset: -14400 },
//   { name: '(GMT-04:00) La Paz', timezone: 'America/La_Paz', offset: -14400 },
//   { name: '(GMT-04:00) America (Goose Bay)', timezone: 'America/Goose_Bay', offset: -14400 },
//   { name: '(GMT-04:00) America (Santo Domingo)', timezone: 'America/Santo_Domingo', offset: -14400 },
//   { name: '(GMT-03:30) Newfoundland', timezone: 'America/St_Johns', offset: -12600 },
//   {
//     name: '(GMT-03:00) S & SE Brazil (GO, DF, MG, ES, RJ, SP, PR, SC, RS)',
//     timezone: 'America/Sao_Paulo',
//     offset: -10800,
//   },
//   { name: '(GMT-03:00) America (Godthab)', timezone: 'America/Godthab', offset: -10800 },
//   { name: '(GMT-03:00) Antarctica (Rothera)', timezone: 'Antarctica/Rothera', offset: -10800 },
//   { name: '(GMT-03:00) E Argentina (BA, DF, SC, TF)', timezone: 'America/Argentina/Buenos_Aires', offset: -10800 },
//   { name: '(GMT-03:00) NE Brazil (MA, PI, CE, RN, PB)', timezone: 'America/Fortaleza', offset: -10800 },
//   { name: '(GMT-03:00) Pernambuco', timezone: 'America/Recife', offset: -10800 },
//   { name: '(GMT-03:00) America (Santiago)', timezone: 'America/Santiago', offset: -10800 },
//   { name: '(GMT-02:00) Mid-Atlantic', timezone: 'Atlantic/South_Georgia', offset: -7200 },
//   { name: '(GMT-01:00) America (Scoresbysund)', timezone: 'America/Scoresbysund', offset: -3600 },
//   { name: '(GMT-01:00) Azores, Cape Verde Island', timezone: 'Atlantic/Azores', offset: -3600 },
//   { name: '(GMT+00:00) Antarctica (Troll)', timezone: 'Antarctica/Troll', offset: 0 },
//   { name: '(GMT+00:00) Atlantic (Canary)', timezone: 'Atlantic/Faroe', offset: 0 },
//   { name: '(GMT+00:00) Dublin, Edinburgh, London', timezone: 'Europe/Jersey', offset: 0 },
//   { name: '(GMT+00:00) Greenwich Mean Time Zone', timezone: 'America/Danmarkshavn', offset: 0 },
//   { name: '(GMT+00:00) Lisbon', timezone: 'Europe/Lisbon', offset: 0 },
//   { name: '(GMT+00:00) Etc (UTC)', timezone: 'Etc/UTC', offset: 0 },
//   { name: '(GMT+00:00) Europe (London)', timezone: 'Europe/London', offset: 0 },
//   { name: '(GMT+00:00) UTC (Coordinated Universal Time)', timezone: 'UTC', offset: 0 },
//   { name: '(GMT+01:00) Berlin, Stockholm, Rome, Bern, Brussels', timezone: 'Europe/Berlin', offset: 3600 },
//   { name: '(GMT+01:00) Paris, Madrid', timezone: 'Europe/Paris', offset: 3600 },
//   { name: '(GMT+01:00) Prague, Warsaw', timezone: 'Europe/Prague', offset: 3600 },
//   { name: '(GMT+01:00) Tunis, Algeria ', timezone: 'Africa/Libreville', offset: 3600 },
//   { name: '(GMT+01:00) Africa (Lagos)', timezone: 'Africa/Lagos', offset: 3600 },
//   { name: '(GMT+01:00) Europe (Belgrade)', timezone: 'Europe/Belgrade', offset: 3600 },
//   { name: '(GMT+01:00) Europe (Bratislava)', timezone: 'Europe/Bratislava', offset: 3600 },
//   { name: '(GMT+01:00) Europe (Tirane)', timezone: 'Europe/Tirane', offset: 3600 },
//   { name: '(GMT+01:00) Europe (Warsaw)', timezone: 'Europe/Warsaw', offset: 3600 },
//   { name: '(GMT+01:00) Europe (Zurich)', timezone: 'Europe/Zurich', offset: 3600 },
//   { name: '(GMT+02:00) Athens, Helsinki', timezone: 'Europe/Athens', offset: 7200 },
//   { name: '(GMT+02:00) Cairo', timezone: 'Africa/Cairo', offset: 7200 },
//   { name: '(GMT+02:00) Harare, Pretoria', timezone: 'Africa/Harare', offset: 7200 },
//   { name: '(GMT+02:00) Israel', timezone: 'Asia/Jerusalem', offset: 7200 },
//   { name: '(GMT+02:00) Kiev, Eastern Europe', timezone: 'Europe/Kiev', offset: 7200 },
//   { name: '(GMT+02:00) Africa (Johannesburg)', timezone: 'Africa/Johannesburg', offset: 7200 },
//   { name: '(GMT+02:00) Asia (Damascus)', timezone: 'Asia/Damascus', offset: 7200 },
//   { name: '(GMT+02:00) Europe (Bucharest)', timezone: 'Europe/Bucharest', offset: 7200 },
//   { name: '(GMT+02:00) Europe (Helsinki)', timezone: 'Europe/Helsinki', offset: 7200 },
//   { name: '(GMT+02:00) Europe (Riga)', timezone: 'Europe/Riga', offset: 7200 },
//   { name: '(GMT+03:00) Africa (Juba)', timezone: 'Africa/Juba', offset: 10800 },
//   { name: '(GMT+03:00) Antarctica (Syowa)', timezone: 'Antarctica/Syowa', offset: 10800 },
//   { name: '(GMT+03:00) Baghdad, Kuwait, Nairobi, Riyadh', timezone: 'Asia/Baghdad', offset: 10800 },
//   { name: '(GMT+03:00) Moscow, MINSK, St. Petersburg, Volgograd', timezone: 'Europe/Moscow', offset: 10800 },
//   { name: '(GMT+03:00) Europe (Minsk)', timezone: 'Europe/Minsk', offset: 10800 },
//   { name: '(GMT+03:30) Tehran', timezone: 'Asia/Tehran', offset: 12600 },
//   { name: '(GMT+04:00) Abu Dhabi, Muscat, Tbilisi, Kazan', timezone: 'Asia/Tbilisi', offset: 14400 },
//   { name: '(GMT+04:00) Asia (Dubai)', timezone: 'Asia/Dubai', offset: 14400 },
//   { name: '(GMT+04:00) Asia (Yerevan)', timezone: 'Asia/Yerevan', offset: 14400 },
//   { name: '(GMT+04:30) Kabul', timezone: 'Asia/Kabul', offset: 16200 },
//   { name: '(GMT+05:00) Antarctica (Mawson)', timezone: 'Antarctica/Mawson', offset: 18000 },
//   { name: '(GMT+05:00) Islamabad, Karachi', timezone: 'Asia/Karachi', offset: 18000 },
//   { name: '(GMT+05:00) Sverdlovsk, Tashkent', timezone: 'Asia/Yekaterinburg', offset: 18000 },
//   { name: '(GMT+05:30) Mumbai, Kolkata, Chennai, New Delhi', timezone: 'Asia/Colombo', offset: 19800 },
//   { name: '(GMT+05:30) Asia (Calcutta)', timezone: 'Asia/Calcutta', offset: 19800 },
//   { name: '(GMT+05:30) Asia (Kolkata)', timezone: 'Asia/Kolkata', offset: 19800 },
//   { name: '(GMT+05:45) Kathmandu, Nepal', timezone: 'Asia/Katmandu', offset: 20700 },
//   { name: '(GMT+05:45) Asia (Kathmandu)', timezone: 'Asia/Kathmandu', offset: 20700 },
//   { name: '(GMT+06:00) Almaty, Dhaka', timezone: 'Asia/Almaty', offset: 21600 },
//   { name: '(GMT+06:00) Antarctica (Vostok)', timezone: 'Antarctica/Vostok', offset: 21600 },
//   { name: '(GMT+06:00) Omsk, Russia', timezone: 'Asia/Omsk', offset: 21600 },
//   { name: '(GMT+06:00) Asia (Dhaka)', timezone: 'Asia/Dhaka', offset: 21600 },
//   { name: '(GMT+06:30) Asia (Rangoon)', timezone: 'Asia/Rangoon', offset: 23400 },
//   { name: '(GMT+06:30) Indian (Cocos)', timezone: 'Indian/Cocos', offset: 23400 },
//   { name: '(GMT+07:00) Antarctica (Davis)', timezone: 'Antarctica/Davis', offset: 25200 },
//   { name: '(GMT+07:00) Bangkok, Jakarta, Hanoi', timezone: 'Asia/Bangkok', offset: 25200 },
//   { name: '(GMT+07:00) Krasnoyarsk', timezone: 'Asia/Krasnoyarsk', offset: 25200 },
//   { name: '(GMT+07:00) Novosibirsk, Russia', timezone: 'Asia/Novosibirsk', offset: 25200 },
//   { name: '(GMT+07:00) Asia (Jakarta)', timezone: 'Asia/Jakarta', offset: 25200 },
//   { name: '(GMT+08:00) Beijing, Chongqing, Urumqi', timezone: 'Asia/Shanghai', offset: 28800 },
//   { name: '(GMT+08:00) Kuala Lumpur, Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 28800 },
//   { name: '(GMT+08:00) Manila, Hong Kong SAR, Perth, Singapore, Taipei', timezone: 'Australia/Perth', offset: 28800 },
//   { name: '(GMT+08:00) Asia (Manila)', timezone: 'Asia/Manila', offset: 28800 },
//   { name: '(GMT+08:00) Asia (Singapore)', timezone: 'Asia/Singapore', offset: 28800 },
//   { name: '(GMT+08:00) Asia (Taipei)', timezone: 'Asia/Taipei', offset: 28800 },
//   { name: '(GMT+08:45) Australia (Eucla)', timezone: 'Australia/Eucla', offset: 31500 },
//   { name: '(GMT+09:00) Tokyo, Osaka, Sapporo, Seoul, Yakutsk', timezone: 'Asia/Tokyo', offset: 32400 },
//   { name: '(GMT+09:00) Asia (Yakutsk)', timezone: 'Asia/Yakutsk', offset: 32400 },
//   { name: '(GMT+09:30) Darwin', timezone: 'Australia/Darwin', offset: 34200 },
//   { name: '(GMT+10:00) Antarctica (DumontDUrville)', timezone: 'Antarctica/DumontDUrville', offset: 36000 },
//   { name: '(GMT+10:00) Brisbane', timezone: 'Australia/Brisbane', offset: 36000 },
//   { name: '(GMT+10:00) Guam, Port Moresby', timezone: 'Pacific/Guam', offset: 36000 },
//   { name: '(GMT+10:00) Vladivostok', timezone: 'Asia/Vladivostok', offset: 36000 },
//   { name: '(GMT+10:30) Adelaide', timezone: 'Australia/South', offset: 37800 },
//   { name: '(GMT+10:30) Australia (Broken_Hill)', timezone: 'Australia/Broken_Hill', offset: 37800 },
//   { name: '(GMT+10:30) Australia (Adelaide)', timezone: 'Australia/Adelaide', offset: 37800 },
//   { name: '(GMT+11:00) Antarctica (Casey)', timezone: 'Antarctica/Casey', offset: 39600 },
//   { name: '(GMT+11:00) Antarctica (Macquarie)', timezone: 'Antarctica/Macquarie', offset: 39600 },
//   { name: '(GMT+11:00) Asia (Srednekolymsk)', timezone: 'Asia/Srednekolymsk', offset: 39600 },
//   { name: '(GMT+11:00) Guadalcanal', timezone: 'Pacific/Guadalcanal', offset: 39600 },
//   { name: '(GMT+11:00) Hobart', timezone: 'Australia/Hobart', offset: 39600 },
//   { name: '(GMT+11:00) Magadan, Solomon Islands, New Caledonia', timezone: 'Asia/Magadan', offset: 39600 },
//   { name: '(GMT+11:00) Sydney, Melbourne', timezone: 'Australia/Canberra', offset: 39600 },
//   { name: '(GMT+11:00) Australia (Sydney)', timezone: 'Australia/Sydney', offset: 39600 },
//   { name: '(GMT+11:00) Pacific (Noumea)', timezone: 'Pacific/Noumea', offset: 39600 },
//   { name: '(GMT+12:00) Norfolk', timezone: 'Pacific/Norfolk', offset: 43200 },
//   { name: '(GMT+12:00) Eniwetok, Kwajalein', timezone: 'Pacific/Kwajalein', offset: 43200 },
//   { name: '(GMT+12:00) Kamchatka', timezone: 'Asia/Kamchatka', offset: 43200 },
//   { name: '(GMT+12:00) Fiji Islands, Marshall Islands', timezone: 'Pacific/Fiji', offset: 43200 },
//   { name: '(GMT+13:00) Antarctica (McMurdo)', timezone: 'Antarctica/McMurdo', offset: 46800 },
//   { name: '(GMT+13:00) Wellington, Auckland', timezone: 'Pacific/Auckland', offset: 46800 },
//   { name: '(GMT+13:45) Chatham', timezone: 'Pacific/Chatham', offset: 49500 },
//   { name: '(GMT+14:00) Kiritimati', timezone: 'Pacific/Kiritimati', offset: 50400 },
// ]

export const timezones = [
  {
    name: 'Hawaii Standard Time',
    abbr: 'HST',
    offset: -10,
    isdst: false,
    text: '(UTC-10:00)',
    label: '(UTC-10:00) HST Hawaii Standard Time',
    utc: [
      'Pacific/Honolulu',
      'Etc/GMT+10',
      'Pacific/Johnston',
      'Pacific/Rarotonga',
      'Pacific/Tahiti',
    ],
  },
  {
    name: 'Alaska Daylight Time',
    abbr: 'AKDT',
    offset: -8,
    isdst: true,
    text: '(UTC-09:00)',
    label: '(UTC-09:00) Alaska Daylight Time',
    utc: [
      'America/Anchorage',
      'America/Metlakatla',
      'America/Juneau',
      'America/Nome',
      'America/Sitka',
      'America/Yakutat',
      'Pacific/Gambier',
    ],
  },
  {
    name: 'Pacific Daylight Time',
    abbr: 'PDT',
    offset: -7,
    isdst: true,
    text: '(UTC-08:00)',
    label: '(UTC-08:00) PDT Pacific Daylight Time',
    utc: [
      'America/Los_Angeles',
      'US/Pacific',
      'Pacific/Pitcairn',
      'America/Santa_Isabel',
      'America/Los_Angeles',
      'America/Tijuana',
      'America/Vancouver',
      'PST8PDT',
    ],
  },

  {
    name: 'Mountain Standard Time',
    abbr: 'MST',
    offset: -7,
    isdst: false,
    text: '(UTC-07:00)',
    label: '(UTC-07:00) MST Mountain Standard Time',
    utc: [
      'America/Phoenix',
      'America/Creston',
      'America/Dawson',
      'America/Dawson_Creek',
      'America/Hermosillo',
      'America/Phoenix',
      'America/Whitehorse',
      'Etc/GMT+7',
      'America/Chihuahua',
      'America/Mazatlan',
      'America/Chihuahua',
      'America/Mazatlan',
      'America/Boise',
      'America/Cambridge_Bay',
      'America/Denver',
      'America/Edmonton',
      'America/Inuvik',
      'America/Ojinaga',
      'America/Yellowknife',
      'MST7MDT',
    ],
  },

  {
    name: 'Central Standard Time',
    abbr: 'CST',
    offset: -6,
    isdst: false,
    text: '(UTC-06:00)',
    label: '(UTC-06:00) CST Central Standard Time',
    utc: [
      'America/Belize',
      'America/Costa_Rica',
      'America/El_Salvador',
      'America/Guatemala',
      'America/Managua',
      'America/Tegucigalpa',
      'Etc/GMT+6',
      'Pacific/Galapagos',
      'America/Chicago',
      'America/Indiana/Knox',
      'America/Indiana/Tell_City',
      'America/Matamoros',
      'America/Menominee',
      'America/North_Dakota/Beulah',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/Rainy_River',
      'America/Rankin_Inlet',
      'America/Resolute',
      'America/Winnipeg',
      'CST6CDT',
      'America/Bahia_Banderas',
      'America/Cancun',
      'America/Merida',
      'America/Mexico_City',
      'America/Monterrey',
      'America/Regina',
      'America/Swift_Current',
      'America/Costa_Rica',
      'America/Indiana/Knox',
      'America/Mexico_City',
      'America/Regina',
      'America/Chicago',
      'America/Guatemala',
      'America/Tegucigalpa',
    ],
  },

  {
    name: 'Eastern Daylight Time',
    abbr: 'EDT',
    offset: -5,
    isdst: false,
    text: '(UTC-05:00)',
    label: '(UTC-05:00) EDT Eastern Daylight Time',
    utc: [
      'America/Detroit',
      'America/Havana',
      'America/Indiana/Petersburg',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Iqaluit',
      'America/Kentucky/Monticello',
      'America/Louisville',
      'America/Montreal',
      'America/Nassau',
      'America/New_York',
      'America/Nipigon',
      'America/Pangnirtung',
      'America/Port-au-Prince',
      'America/Thunder_Bay',
      'America/Toronto',
      'America/Bogota',
      'America/Cayman',
      'America/Coral_Harbour',
      'America/Eirunepe',
      'America/Guayaquil',
      'America/Jamaica',
      'America/Lima',
      'America/Panama',
      'America/Rio_Branco',
      'Etc/GMT+5',
      'America/Indiana/Marengo',
      'America/Indiana/Vevay',
      'America/Indianapolis',
      'America/Detroit',
      'America/Havana',
      'America/Indiana/Petersburg',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Iqaluit',
      'America/Kentucky/Monticello',
      'America/Louisville',
      'America/Montreal',
      'America/Nassau',
      'America/New_York',
      'America/Nipigon',
      'America/Pangnirtung',
      'America/Port-au-Prince',
      'America/Thunder_Bay',
      'America/Toronto',
      'Pacific/Easter',
    ],
  },
]
