import axiosOrcatec from './axiosInstance'
import moment from 'moment-timezone'

/**
 * Fetch todo list.
 *
 * @return {*}
 */

const timecardsConfig = {
  baseURL: process.env.REACT_APP_API_URL.replace('v1.0', 'v2'),
}
export const fetchTimeCardsList = props => {
  // const params = getQueryStringFromObject(props)
  const params = new URLSearchParams(props)
  return axiosOrcatec.get(`/timecards${params ? `?${params}` : ''}`, timecardsConfig).then(response => response.data)
}

export const exportTimeCardsTable = params =>
  axiosOrcatec
    .get('/timecards/export', {
      responseType: 'blob',
      baseURL: process.env.REACT_APP_API_URL.replace('v1.0', 'v2'),
      params,
    })
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Timecards_${moment().format('DD-MM')}.xls`)
      document.body.appendChild(link)
      link.click()
    })

export const fetchUserCurrentTimeStatus = () => {
  return axiosOrcatec.get('/timecards/get-status').then(response => response.data)
}

export const fetchTimeCardByUserId = (id, startData, endData, timezone) => {
  return axiosOrcatec
    .get(`/timecards/users/${id}?startDate=${startData}&endDate=${endData}&timezone=${timezone}`, timecardsConfig)
    .then(response => response.data)
}

export const createTimeCardTrack = ({ userId, timezone, clock_in, clock_out }) => {
  return axiosOrcatec
    .post(`timecards/users/${userId}/time-tracks/`, { timezone, clock_in, clock_out }, timecardsConfig)
    .then(response => response.data)
}

export const updateTimeCardTrack = (userId, timeTrackId, data) => {
  return axiosOrcatec
    .put(`/timecards/users/${userId}/time-tracks/${timeTrackId}`, data, timecardsConfig)
    .then(response => response.data)
}
export const deleteTimeCardTrack = ({ userId, timeTrackId }) => {
  return axiosOrcatec
    .delete(`/timecards/users/${userId}/time-tracks/${timeTrackId}`, timecardsConfig)
    .then(response => response.data)
}

export const setUserClockInOrOut = data => {
  return axiosOrcatec.post('/timecards/store-time', data).then(response => response.data)
}

export const sendLastActiveTime = data => {
  return axiosOrcatec.get(`/timecards/last_active_time`).then(response => response.data)
}

export const getGpsData = data =>
  axiosOrcatec.post('/timecards/gps/get-data', data).then(({ data }) => {
    let mapedData = {}

    Object.entries(data).map(([userId, intervals]) => {
      // console.log(intervals.sort((a, b) => a.id - b.id))
      let devices = {}

      intervals
        // .sort((a, b) => a.id - b.id))
        .map(interval => {
          Object.entries(interval.data).map(([deviceName, coordinates]) => {
            const previous = typeof devices[deviceName] !== 'undefined' ? Object.values(devices[deviceName]) : []

            devices = {
              ...devices,
              [deviceName]: [...previous, { clock_in: interval.clock_in, clock_out: interval.clock_out, coordinates }],
            }
          })
        })

      mapedData = {
        ...mapedData,
        [userId]: devices,
      }
    })

    return mapedData
  })
