import React from 'react'
import './style.scss'
import moment from 'moment-timezone'
import { separateDateTimeByStr } from 'helpers/date/separateDateTimeByStr'
import Notes from 'containers/MainContent/Orcatec/components/Notes'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import { ProposalContacts } from 'containers/MainContent/Orcatec/Proposals/components/JobView/components/ProposalContacts/ProposalContacts'

const AppointmentToPrint = ({
  appointment,
  tech,
  isJob,
  jobTitle,
  showPromisedTime,
}) => {
  return (
    <>
      <div className='print_wrapper'>
        <article style={{ minWidth: '500px' }} className='job_section'>
          {isJob ? (
            <h1>
              {jobTitle} details{' '}
              <span className='job_article__name'>
                ({appointment?.job_code})
              </span>
            </h1>
          ) : (
            <h1>Appointment details</h1>
          )}
          {!!appointment?.date && (
            <div className='job_article'>
              <span className='job_article__name'>
                {' '}
                {isJob ? jobTitle + '  ' + 'date: ' : 'Appointment date:'}
              </span>
              <span className='job_article__value'>
                {appointment?.date
                  ? moment(appointment.date).format('MM/DD/YYYY')
                  : ''}
              </span>
            </div>
          )}

          {!!appointment?.time_start && showPromisedTime && (
            <div className='job_article'>
              <span className='job_article__name'>
                {' '}
                Promised arrival time:{' '}
              </span>
              <div className='job-time_wrapper'>
                <span className='job_article__value '>
                  From:{' '}
                  {appointment?.time_start
                    ? moment(appointment?.time_start).format('hh:mm a')
                    : ''}
                </span>
                <span className='job_article__value'>
                  To:{' '}
                  {appointment?.time_end
                    ? moment(appointment?.time_end).format('hh:mm a')
                    : ''}
                </span>
              </div>
            </div>
          )}

          {!isJob && (
            <>
              {!!appointment?.preferred_technician_id &&
                !!tech &&
                !!tech?.length && (
                  <div className='job_article'>
                    <span className='job_article__name'> Preferred tech: </span>
                    <span className='job_article__value'>
                      {tech?.filter(
                        t => t?.id === appointment?.preferred_technician_id,
                      )?.[0]?.name || 'No preferred worker'}
                    </span>
                  </div>
                )}
            </>
          )}

          {!!appointment?.matrix_time_start && (
            <div className='job_article'>
              <span className='job_article__name'> Schedule time: </span>
              <div className='job-time_wrapper'>
                <span className='job_article__value '>
                  From:{' '}
                  {!!appointment?.matrix_time_start ||
                  appointment.matrix_time_start === 0
                    ? moment(appointment?.matrix_time_start).format('hh:mm a')
                    : ''}
                </span>
                <span className='job_article__value'>
                  To:{' '}
                  {!!appointment?.matrix_time_start ||
                  appointment?.matrix_time_start === 0
                    ? moment(appointment?.matrix_time_end).format('hh:mm a')
                    : ''}
                </span>
              </div>
            </div>
          )}

          {!!appointment?.matrix_user && (
            <div className='job_article'>
              <span className='job_article__name'> Assigned to: </span>
              <span className='job_article__value'>
                {appointment?.matrix_user}
              </span>
            </div>
          )}

          {isJob && (
            <>
              {/* <div className='job_article'>
            <span className='job_article__name'> Approximate Completion Time Per Day: </span>
            <span className='job_article__value'>{appointment?.approximate_time}</span>
          </div> */}
              <div className='job_article'>
                <span className='job_article__name'> Days needed: </span>
                <span className='job_article__value'>
                  {appointment?.count_days}
                </span>
              </div>
              <div className='job_article'>
                <span className='job_article__name'> Workers Needed: </span>
                <span className='job_article__value'>
                  {appointment?.count_workers}
                </span>
              </div>
            </>
          )}
          {!!appointment?.preferred_technician_id &&
            !!tech &&
            !!tech?.length &&
            isJob && (
              <div className='job_article'>
                <span className='job_article__name'>
                  {' '}
                  Lead Preferred Worker{' '}
                </span>
                <span className='job_article__value'>
                  {tech?.filter(
                    t => t?.id === appointment?.preferred_technician_id,
                  )?.[0]?.name || 'No preferred worker'}
                </span>
              </div>
            )}

          {!!appointment?.created_at && (
            <div className='job_article'>
              <span className='job_article__name'>Appointment created by:</span>
              <span className='job_article__value'>
                {appointment.username === null
                  ? `Public Appointment`
                  : `${appointment.username} on ${separateDateTimeByStr(
                      'at',
                      appointment.created_at,
                    )}`}
              </span>
            </div>
          )}
        </article>
        {!!appointment?.proposal_info && (
          <article className='job_section'>
            <ProposalContacts proposal={appointment.proposal_info} />
          </article>
        )}
      </div>

      {appointment?.id ? (
        <Notes
          route={`/${isJob ? `jobs` : 'appointments'}/${appointment.id}/notes`}
          forPrint={true}
        />
      ) : (
        !!appointment?.notes && (
          <InputField
            label={'Appointment notes'}
            value={appointment?.notes}
            multiline
            className='appointment-notes select-appt'
          />
        )
      )}
    </>
  )
}

export default AppointmentToPrint
