import { AppStateType } from '../../index'

export const getMeSelector = (state: AppStateType) => state.orcatec.user.me
export const getWasEditSelector = (state: AppStateType) => state.orcatec.ui.wasEdit
export const isShowUpcomingNotificationSelector = (state: AppStateType) =>
  state.orcatec.user?.me?.notifications?.upcoming_appointment?.reminder_to_send
export const isShowInRouteNotificationSelector = (state: AppStateType) =>
  state.orcatec.user.me?.notifications?.in_route?.reminder_to_send
export const isShowRequestForReviewNotificationSelector = (state: AppStateType) =>
  state.orcatec.user.me?.notifications?.request_review?.reminder_to_send
