import { Component } from 'react'
import Items from '../ItemsTable'
import EndOfSystem from '../../Form/Tabs/EndOfSystem'
import { priceToView } from '../../../../../../../helpers/thousandSeparator'
import MainButton from '../../../../components/buttons/MainButton'
import Preloader from '../../../../components/Preloader'
import ModalSignature from '../Signature/Signature'
import { Divider, Tooltip } from 'antd'
import './style.scss'
import moment from 'moment-timezone'
import SignatureBlock from '../../Form/SignatureBlock/SignatureBlock.tsx'
import ProposalStatus from '../../../../components/ProposalStatus/ProposalStatus'
import BalanceTable from '../../BalanceTable'
import ContactsInformation from '../ContactsInformation'
import InstallersInfoBlock from '../../Form/TabsContent/TabInfoBlock/InstallersInfoBlock/InstallersInfoBlock'
import ProposalSettings from '../../ProposalSettings'
import ProposalTabs from '../../ProposalTabs'
import TermAndConditionsLink from 'containers/MainContent/Orcatec/components/TermAndConditionsLink/TermAndConditionsLink'
import getStatus from '../../../helpers/getTabStatus'
import { Warning } from '@material-ui/icons'
import { Companies } from 'types/Company'
import { ProjectStatusGroup } from 'features/Project/types'
// import { ViewFile } from '../../../../../../../helpers/viewFile/viewFile'
// import { isSameAddressess } from '../../Modals/ModalBillingAddress/ModalBillingAddress'
// import proposal from 'store/Orcatec/reducers/settings/proposal'
// import { connect } from 'react-redux'
// import UserContacts from '../UserContacts/UserContacts'
// import { getAddressWithUnit } from '../../../../../../../helpers/getAddressWithUnit'
const printBlockStyle = {
  backgroundColor: 'white',
  // padding: '10px',
}

const getDate = dateString =>
  dateString ? moment(dateString).format('MM/DD/YY') : ''
class ComponentToPrint extends Component {
  remainingBalanceToSplit = () => {
    // let fullAmount = 0
    let fullAmount =
      this.props.proposalForm.id &&
      this.props.proposalForm.proposal_tabs?.[0].payment?.payment_balances
        .filter(bal => bal.status !== 4)
        .map(balance => balance.amount)
        .reduce((acc, val) => {
          return (acc += Number(val))
        }, 0)

    // return this.props.totalToPay - fullAmount.toFixed(2)
    return this.props.allTotalPrices.totalToPay - fullAmount.toFixed(2)
  }

  render() {
    const {
      proposalForm,
      activeTabIdx,
      onChangeTab,
      isTabLoading,
      signatureModal,
      setSignatureModal,
      setSignature,
      // userIP,
      // propsLogo,
      isProposalSigning,
      onChangeChoosenOption,
      onChangeClientNotes,
      onChangeDescription,
      diffPrices,
      loading,
      onFileUploadButtonClick,
      handleDeleteCustomerFile,
      isPreviewFromSettings,
      allTotalPrices,
      onPayBalance,
      onSelectItem,
      getFileForModalDocViewer,
      isLoadDataByLink,
      onOptionChange,
      onTextFieldBlur,
    } = this.props
    const {
      show_information,
      require_signature,
      show_price,
      show_items_qty,
    } = proposalForm.setting
    const transactionsAllowedCard =
      proposalForm.id &&
      proposalForm.proposal_tabs?.[0].payment?.payment_balances?.filter(
        tr => tr.accept_card && tr.status !== 3,
      )

    const contractInfo = {
      contract_date: proposalForm.contract_date,
      signature: proposalForm.signature ? proposalForm.signature : '',
      ip_client: proposalForm.ip_client ? proposalForm.ip_client : '',
      signature_date: proposalForm.signature_date
        ? proposalForm.signature_date
        : '',
      proposal_status_changed_by: proposalForm.proposal_status_changed_by || '',
      signatory_name: proposalForm.signatory_name,
    }

    const compare = (a, b) => {
      if (a.status === b.status) return 0
      if (a.status === 1) return -1
      if (b.status !== 1) return 1
    }
    const installationDates = proposalForm.installation_dates
    const install_dates = Array.isArray(installationDates)
      ? [...installationDates].sort(compare)
      : []
    const all_work_completed = +proposalForm.all_work_completed
    const proposalStatus = proposalForm.status

    const installDatesList = popap => {
      const getFullInstallDateStringForPopap = (date, key, fontSize) => (
        <tr key={key} style={fontSize ? { fontSize } : {}}>
          <td>{getDate(date.date)}</td>
          <td>{date.time === 'Full Day' ? '' : date.time}</td>
          <td>-</td>
          {/* <td>{date.status.replace(/^[a-z]/, u => u.toUpperCase())}</td> */}
          <td>{date.status}</td>
          <td style={{ textAlign: 'right', paddingLeft: '5px' }}>
            {date.installers}
          </td>
          <td>{`installer${+date.installers === 1 ? '' : 's'}`}</td>
        </tr>
      )
      const getFullInstallDateStringForTable = (date, key) => (
        <tr key={key}>
          {/* <td></td> */}
          <td>{`${getDate(date.date)}${
            !!date.time && date.time !== 'Full Day' ? ' ' + date.time : ''
          }`}</td>
          {/* <td className='installers'>{date.installers}</td>
          <td className='status'>{date.status.replace(/^[a-z]/, u => u.toUpperCase())}</td> */}
        </tr>
      )

      return popap ? (
        <table>
          <tbody>
            {Array.isArray(installationDates) &&
              installationDates.map((date, idx) =>
                getFullInstallDateStringForPopap(date, 'popap' + idx),
              )}
          </tbody>
        </table>
      ) : (
        <div className='d-flex w-100 align-items-center'>
          <table className='proposal-form-installation_dates-table'>
            <tbody>
              {install_dates.reduce((acc, date, idx) => {
                if (acc?.length !== 3) {
                  acc.push(getFullInstallDateStringForTable(date, idx))
                }
                return acc
              }, [])}
            </tbody>
            {!install_dates?.length && (
              <tfoot>
                <tr className='proposal-form-intallation_dates-helper_text'>
                  {/* <td></td> */}
                  <td colSpan='3'>
                    {!all_work_completed && (
                      <ProposalStatus
                        statusId={all_work_completed ? 3 : 2}
                        disableToolTip={true}
                        withStatusName={true}
                        customName={
                          all_work_completed
                            ? 'All work has been completed'
                            : `No dates have been scheduled${
                                proposalStatus === 2 ? ' yet' : ''
                              }`
                        }
                      />
                    )}
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
          {install_dates?.length <= 3 ? (
            ''
          ) : (
            <span className='rest-items ml-2'>{`+${install_dates?.length -
              3}`}</span>
          )}
        </div>
      )
    }

    /* const showPopap = items => {
      let itemsList
      if (items === 'Installation Dates') itemsList = installDatesList
      const filteredDates = install_dates.filter(date => date.status === 'active')
      if (
        (!all_work_completed && install_dates?.length > 3) ||
        (!!all_work_completed && !!install_dates?.length) ||
        (install_dates?.length <= 3 && install_dates?.length !== filteredDates?.length)
      ) {
        return (
          <Popover
            content={itemsList(true)}
            title={items}
            placement='left'
            overlayStyle={{ width: '330px' }}
            overlayClassName='proposal-table-popover'
          >
            {itemsList(false)}
          </Popover>
        )
      }
      return itemsList(false)
    } */

    const totalBalanceDue = () => {
      /*  const res = proposalForm.proposal_tabs[0].payment.payment_balances
        .filter(b => b.status === 1 || b.status === 2)
        .reduce((a, b) => a + +b.amount, 0)
      return res */
      const total = proposalForm.proposal_tabs?.[0]?.total_to_pay
      const res = proposalForm.proposal_tabs?.[0]?.payment?.payment_balances
        .filter(b => b?.status !== 3 && b?.status !== 4)
        .reduce((a, b) => {
          return a + +b.amount
        }, 0)
      return res + this.remainingBalanceToSplit()
    }

    const isSignedTab = () =>
      !proposalForm.signed_tab_id
        ? true
        : proposalForm.signed_tab_id === proposalForm.proposal_tabs?.[0]?.id
        ? true
        : false
    /* const logos = proposalForm?.logos
      ? proposalForm.logos
      : proposalForm.proposal_settings?.logos
        ? proposalForm.proposal_settings.logos
        : Array(7).fill({}) */

    /* const getStatus = proposalForm => {
      const { status, all_work_completed } = proposalForm
      const payment_status = proposalForm.proposal_tabs?.[0].payment_status

      switch (status) {
        case 1:
          return 'Estimate'
        case 2:
        case 3:
          return +payment_status === 4 && +all_work_completed === 1
            ? 'Receipt'
            : +all_work_completed === 1 && payment_status !== 4 && isSignedTab()
            ? 'Invoice'
            : isSignedTab()
            ? 'Contract'
            : 'Estimate'
        case 4:
          return 'Cancelled'
        default:
          return 'Estimate'
      }
    } */

    /* const isPayAllowed =
      proposalForm?.status === 2 && !!transactionsAllowedCard?.length */
    // const isPayAllowed = isLoadDataByLink
    //   ? proposalForm?.status === 2 && proposalForm?.signature && !!transactionsAllowedCard?.length
    //   : proposalForm?.status === 2 && !!transactionsAllowedCard?.length

    /*  const getAdditionalLaborCost = section => {
      return (
        +section.installation +
        +section.installation * (+section?.gross_margin / 100)
      )
    } */

    const hideTabs =
      proposalForm?.tabs_info?.length === 1 &&
      !proposalForm?.setting?.financing &&
      !proposalForm?.tabs_info?.[0]?.description &&
      !proposalForm?.tabs_info?.[0]?.image &&
      proposalForm?.tabs_info?.[0]?.title === 'Tab Title'

    const isHasOverdueBalance = proposalForm?.proposal_tabs?.[0]?.payment?.payment_balances?.some(
      ({ status }) => status === 2,
    )

    const companyTimezone = proposalForm.timezone || 'America/Los_Angeles'

    return (
      <div className='proposal-to-print' style={printBlockStyle}>
        {!!proposalForm.setting && (
          <ProposalSettings settings={proposalForm.setting} />
        )}

        <div className='proposal-body'>
          <ContactsInformation
            proposal={proposalForm}
            currentTab={proposalForm.proposal_tabs?.[0]}
          />

          {!hideTabs && (
            <ProposalTabs
              proposal={proposalForm}
              activeTabIdx={activeTabIdx}
              onChangeTab={onChangeTab}
              isClientView
              allTotalPrices={allTotalPrices}
              hidePrices={!this.props.isShowPrice}
            />
          )}

          {(activeTabIdx !== null || isPreviewFromSettings) && (
            <div className='tab-content'>
              {!isTabLoading || isPreviewFromSettings ? (
                <>
                  <div className='form-custom'>
                    <div className='first-row'>
                      <div className='text-center h4'>
                        {proposalForm?.user?.company_id ===
                        Companies['Trilogy Surgical Solutions']
                          ? 'Work Order'
                          : getStatus(
                              proposalForm,
                              proposalForm.proposal_tabs[0],
                            )}
                      </div>
                    </div>
                  </div>
                  {proposalForm.proposal_tabs?.[0].proposal_sections.map(
                    (section, index, arr) => {
                      let miscellaneousItemsPrice = 0
                      Object.entries(section).map(([name, values]) => {
                        if (name === 'materials' || name === 'scope_of_works') {
                          values?.forEach(value => {
                            if (value.is_hidden && value.use_calculation)
                              miscellaneousItemsPrice +=
                                +(value.retail_price || value.price) * value.qty
                          })
                        }
                      })

                      return (
                        <div
                          className='section'
                          key={`${section.title} ${index}`}
                        >
                          {!(
                            arr?.length === 1 && section.title === 'New Section'
                          ) && (
                            <div className='section-header h5'>
                              {section.title}
                            </div>
                          )}
                          {section.description && (
                            <p className='section-header__descr'>
                              {section.description}
                            </p>
                          )}

                          {!!section?.items?.filter(item => !item.group_id)
                            ?.length && (
                            <div className='table-items'>
                              <Items
                                companyTimezone={companyTimezone}
                                title={''}
                                items={section?.items
                                  ?.filter(item => item.approved)
                                  ?.filter(item => !item.group_id)
                                  ?.filter(item => !item.is_hidden)
                                  ?.filter(item =>
                                    proposalForm.status === 1
                                      ? item
                                      : item?.use_calculation,
                                  )}
                                diffPrices={diffPrices}
                                section={index}
                                onChangeChoosenOption={onChangeChoosenOption}
                                onSelectItem={onSelectItem}
                                type={'items'}
                                status={proposalForm.status}
                                requestType='Material'
                                onChangeTotalPrices={
                                  this.props.onChangeTotalPrices
                                }
                                showDescriptionOnly={
                                  proposalForm?.setting
                                    ?.show_item_description_only
                                }
                                hideZeroPrices={
                                  !proposalForm?.setting?.hide_zero_price
                                }
                                // isOptionLoading={loading}
                                loading={loading}
                                isHideQTY={!show_items_qty}
                                showPrice={
                                  isLoadDataByLink
                                    ? show_price
                                    : this.props.isShowPrice && show_price
                                }
                                hideDescription={!this.props.isShowDescription}
                                uncheckable={
                                  proposalForm?.setting?.multiple_choice_items
                                }
                                onOptionChange={onOptionChange}
                              />
                            </div>
                          )}

                          {section?.groups.map(group => (
                            <div className='table-items'>
                              <Items
                                companyTimezone={companyTimezone}
                                title={group?.name}
                                items={section?.items
                                  ?.filter(item => item.approved)
                                  ?.filter(item => item.group_id === group.id)
                                  ?.filter(item => !item.is_hidden)
                                  ?.filter(item =>
                                    proposalForm.status === 1
                                      ? item
                                      : item?.use_calculation,
                                  )}
                                diffPrices={diffPrices}
                                section={index}
                                onChangeChoosenOption={onChangeChoosenOption}
                                onSelectItem={onSelectItem}
                                type={'items'}
                                status={proposalForm.status}
                                requestType='Material'
                                onChangeTotalPrices={
                                  this.props.onChangeTotalPrices
                                }
                                showDescriptionOnly={
                                  proposalForm?.setting
                                    ?.show_item_description_only
                                }
                                showPrice={
                                  isLoadDataByLink
                                    ? show_price
                                    : this.props.isShowPrice && show_price
                                }
                                hideZeroPrices={
                                  !proposalForm?.setting?.hide_zero_price
                                }
                                hideDescription={!this.props.isShowDescription}
                                // isOptionLoading={loading}
                                loading={loading}
                                isHideQTY={!show_items_qty}
                                uncheckable={
                                  proposalForm?.setting?.multiple_choice_items
                                }
                                onOptionChange={onOptionChange}
                              />
                            </div>
                          ))}

                          <div className='table-items Instants'>
                            {!!section.discounts?.length && (
                              <Items
                                companyTimezone={companyTimezone}
                                title='Instants'
                                items={section.discounts}
                                diffPrices={diffPrices}
                                section={index}
                                onChangeChoosenOption={onChangeChoosenOption}
                                onSelectItem={onSelectItem}
                                type={'discounts'}
                                status={proposalForm.status}
                                requestType='Instant'
                                onChangeTotalPrices={
                                  this.props.onChangeTotalPrices
                                }
                                signatureDate={proposalForm.contract_date}
                                // isOptionLoading={loading}
                                loading={loading}
                                instantsData={
                                  allTotalPrices?.instants_data?.[section.id]
                                }
                                showPrice={
                                  isLoadDataByLink
                                    ? true
                                    : this.props.isShowPrice
                                }
                                onOptionChange={onOptionChange}
                              />
                            )}
                          </div>
                          <div className='table-items Rebates'>
                            {!!section?.rebates?.length && (
                              <Items
                                companyTimezone={companyTimezone}
                                title='Rebates'
                                items={section.rebates}
                                diffPrices={diffPrices}
                                section={index}
                                onChangeChoosenOption={onChangeChoosenOption}
                                onSelectItem={onSelectItem}
                                type={'rebates'}
                                status={proposalForm.status}
                                requestType='Rebate'
                                onChangeTotalPrices={
                                  this.props.onChangeTotalPrices
                                }
                                signatureDate={proposalForm.contract_date}
                                // isOptionLoading={loading}
                                loading={loading}
                                showPrice={
                                  isLoadDataByLink
                                    ? true
                                    : this.props.isShowPrice
                                }
                                onOptionChange={onOptionChange}
                              />
                            )}
                          </div>

                          {!!miscellaneousItemsPrice && this.props.isShowPrice && (
                            <div className='miscellaneous-items'>
                              <p>Miscellaneous items</p>
                              <p>{priceToView(miscellaneousItemsPrice)}</p>
                            </div>
                          )}

                          {!!+section.installation &&
                            this.props.isShowPrice &&
                            show_price && (
                              <table className='proposal-form-tabs_content-end_of_system-total_table proposal-form-tabs__labor'>
                                <tbody>
                                  <tr>
                                    <td>Additional Labor Cost:</td>
                                    <td>{priceToView(section.installation)}</td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          {/*{proposalForm.proposal_tabs?.[0].proposal_sections?.length > 1 && (*/}
                          {/*  <Divider className='proposal-preview-divider'>*/}
                          {/*    /!*<span style={{ fontSize: '1.1rem' }}>End Of Section</span>*!/*/}
                          {/*  </Divider>*/}
                          {/*)}*/}
                        </div>
                      )
                    },
                  )}
                  <div className='end-of-tab'>
                    {!!proposalForm.proposal_tabs[0].proposal_sections
                      ?.length && (
                      <>
                        <EndOfSystem
                          sections={
                            proposalForm.proposal_tabs[0].proposal_sections
                          }
                          // onTotalToPayChange={this.props.onTotalToPayChange}
                          tax={proposalForm.proposal_tabs[0].tax}
                          fees={proposalForm.proposal_tabs[0].fees}
                          cancellationfees={
                            proposalForm.proposal_tabs[0].cancellation_fees
                          }
                          contractCancelled={
                            proposalForm.proposal_tabs[0].contract_cancelled
                          }
                          clientView
                          publicView
                          proposalStatus={proposalForm.status}
                          total_to_pay={
                            proposalForm.proposal_tabs[0].total_to_pay
                          }
                          allTotalPrices={allTotalPrices}
                          // allTotalPrices={proposalForm.financing_info?.find(
                          //   info =>
                          //     info.tab_id === proposalForm.proposal_tabs[0].id,
                          // )}
                          showPrice={this.props.isShowPrice}
                          contractDate={proposalForm.contract_date}
                        />
                        <div className='d-flex flex-column payment-block'>
                          {!!proposalForm.proposal_tabs[0]?.payment
                            ?.payment_balances?.length &&
                          isSignedTab() &&
                          this.props.isShowPrice ? (
                            <>
                              <div className='pay_description_box'>
                                <p className='pay_description'>
                                  Payment Schedule
                                </p>
                                {isHasOverdueBalance &&
                                  proposalForm?.status !== 1 &&
                                  Companies.WestCost !==
                                    proposalForm.user.company_id && (
                                    <Tooltip title='Your project has some overdue balances. Please pay now to avoid additional fees. '>
                                      <Warning
                                        style={{
                                          color: '#faad14',
                                          fontSize: '2rem',
                                          marginLeft: '20px',
                                        }}
                                      />
                                    </Tooltip>
                                  )}

                                {/* {isPayAllowed && <MainButton title='Pay' onClick={this.props.showTransactionModal} />} */}
                              </div>

                              {this.props.isTransactions &&
                              this.props.isShowPrice ? (
                                <>
                                  <BalanceTable
                                    balances={
                                      proposalForm.proposal_tabs?.[0].payment
                                        ?.payment_balances
                                    }
                                    remaining={this.remainingBalanceToSplit()}
                                    onClickPay={
                                      [
                                        ProjectStatusGroup.Contract,
                                        ProjectStatusGroup.Canceled,
                                      ].includes(proposalStatus) && onPayBalance
                                    }
                                    customerView
                                  />
                                  {proposalStatus !== 1 &&
                                    proposalStatus !== 5 &&
                                    this.props.isShowPrice && (
                                      <table className='proposal-form-tabs_content-end_of_system-total_table'>
                                        <tbody>
                                          <tr>
                                            {totalBalanceDue() !== 0 ? (
                                              <>
                                                <td>Total Balance Due:</td>
                                                <td>
                                                  {priceToView(
                                                    totalBalanceDue(),
                                                  )}
                                                </td>
                                              </>
                                            ) : (
                                              <>
                                                <td>Paid in full:</td>
                                                <td>
                                                  {priceToView(
                                                    allTotalPrices.totalToPay,
                                                  )}
                                                </td>
                                              </>
                                            )}
                                          </tr>
                                        </tbody>
                                      </table>
                                    )}
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      </>
                    )}

                    {!isTabLoading && (
                      <div className='d-flex flex-column align-items-center signature-block mt-4'>
                        {require_signature &&
                          !!proposalForm.signature &&
                          proposalForm.tabs_info[activeTabIdx]?.id ===
                            proposalForm.signed_tab_id && (
                            <SignatureBlock
                              contractInfo={contractInfo}
                              projectStatus={proposalForm.status}
                            />
                          )}

                        {!proposalForm.signature &&
                          require_signature &&
                          isSignedTab() &&
                          [
                            ProjectStatusGroup.Estimate,
                            ProjectStatusGroup.Contract,
                          ].includes(proposalForm.status) && (
                            <MainButton
                              title='Accept Project'
                              onClick={() => setSignatureModal(prev => !prev)}
                              className='print-error mt-4'
                              disabled={this.props.isPreviewFromSettings}
                            />
                          )}

                        {!!proposalForm?.term_and_conditions?.file0 && (
                          <div className='d-flex justify-content-center w-100 mt-2'>
                            <TermAndConditionsLink
                              file={proposalForm.term_and_conditions?.file0}
                            />
                          </div>
                        )}

                        <ModalSignature
                          show={signatureModal}
                          close={() => setSignatureModal(prev => !prev)}
                          onSubmit={setSignature}
                          termFile={
                            proposalForm.term_and_conditions?.file0 || null
                          }
                          isProposalSigning={isProposalSigning}
                        />
                      </div>
                    )}

                    {(proposalForm.info_for_customer ||
                      !!proposalForm.files?.length) && (
                      <Divider className='proposal-preview-divider'>
                        <span style={{ fontSize: '0.8rem' }}>
                          Additional Information
                        </span>
                      </Divider>
                    )}
                    {show_information && (
                      <>
                        <div className='proposal-form-information_for_customer'>
                          <InstallersInfoBlock
                            fieldValue={proposalForm.info_for_customer}
                            // onChangeTextField={handleChangeNotes}
                            files={proposalForm.files}
                            fieldLabel='Information for customer'
                            getFileForModalDocViewer={getFileForModalDocViewer}
                          />
                        </div>

                        <Divider
                          className={`proposal-preview-divider ${
                            !!proposalForm?.info_from_customer?.length ||
                            !!proposalForm?.files_from_customer?.length
                              ? ''
                              : 'print-hidden'
                          }`}
                        >
                          <span style={{ fontSize: '0.8rem' }}>
                            Please Feel Free To Add Comments
                          </span>
                        </Divider>

                        <div
                          className={`proposal-form-information_from_customer ${
                            !!proposalForm?.info_from_customer?.length ||
                            !!proposalForm?.files_from_customer?.length
                              ? ''
                              : 'print-hidden'
                          }`}
                        >
                          <InstallersInfoBlock
                            fieldValue={proposalForm.info_from_customer}
                            onChangeTextField={onChangeClientNotes}
                            files={proposalForm.files_from_customer}
                            placeholder='Information From Client'
                            onChangeDescription={onChangeDescription}
                            buttonTitle='Upload File'
                            openFileModal={onFileUploadButtonClick}
                            deleteFile={handleDeleteCustomerFile}
                            getFileForModalDocViewer={getFileForModalDocViewer}
                            onTextFieldBlur={onTextFieldBlur}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <Preloader />
              )}
            </div>
          )}
        </div>

        <p className='powered-by'>
          Powered by{' '}
          <a
            href='https://orcatec.com/?utm_source=project+footer&utm_medium=existing+clients&utm_campaign=main'
            target='_blank'
          >
            <img src='/assets/images/favicon/favicon-32x32.png' />
            Orcatec
          </a>
        </p>
      </div>
    )
  }
}

export default ComponentToPrint
