import { Table, Empty, Tooltip } from 'antd'
import styled from 'styled-components'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { renderNotes } from '../utils/renderNotes'
import InputField from 'containers/MainContent/Orcatec/components/UI/InputField'
import { updatePropertyRelation } from 'api/Property'

const Wrapper = styled.div`
  & .ant-table-thead > tr > th {
    padding: 4px;
  }

  & .ant-table-thead > th,
  .ant-table-thead > td {
    text-align: center;
  }
`

interface IProps {
  tableData: []
}

const linkStyle = {
  color: '#007bff',
  cursor: 'pointer',
}

const getFullAddressString = obj => {
  if (obj?.full_address) return `${obj.full_address}`

  return `${obj?.address}${obj?.zip && `, ${obj?.zip}`}${obj?.city &&
    `, ${obj?.city}`}${obj?.postcode && `, ${obj?.postcode}`}`
}
export const PropertiesHistoryTable = ({ tableData = [] }: IProps) => {
  const goToProperty = id => {
    if (id) window.open(`/properties-form/${id}`, '_blank')
  }

  const handleUpdatePropertyRelation = async (e, item) => {
    if (e.target.value === item?.property_relation) return
    try {
      await updatePropertyRelation({
        relation_id: item.relation_id,
        property_relation: e.target.value,
      })
      openNotificationWithIcon('success', {
        message: 'Property relation has been updated',
      })
    } catch (error) {
      console.error(error)
      openNotificationWithIcon('error', { message: 'Error' })
    }
  }
  const renderRelation = (relation, record) => (
    <InputField
      defaultValue={relation}
      value={relation}
      onClick={e => e.stopPropagation()}
      onBlur={e => handleUpdatePropertyRelation(e, record)}
      fullWidth={false}
    />
  )

  const columns = [
    {
      title: 'Address',
      name: 'Address',
      dataIndex: 'full_address',
      sorter: false,
      defaultWidth: 200,
      render: (full_address, record) =>
        (
          <span onClick={() => goToProperty(record.id)} style={linkStyle}>
            {getFullAddressString(record)}
          </span>
        ) || '-',
    },
    {
      title: 'Type',
      name: 'Type',
      dataIndex: 'type',
      sorter: false,
      defaultWidth: 200,
      render: type => (type === 1 ? 'Commercial' : 'Residential'),
    },
    {
      title: 'Relation',
      name: 'Relation',
      dataIndex: 'property_relation',
      sorter: false,
      defaultWidth: 200,
      render: (relation, record) => renderRelation(relation, record),
    },
    {
      title: 'Clients',
      name: 'Clients',
      dataIndex: 'clients',
      sorter: false,
      defaultWidth: 250,
      render: clients => (clients || []).length,
    },
    {
      title: 'Appointments',
      name: 'Appointments',
      dataIndex: 'appointments',
      sorter: false,
      defaultWidth: 200,
      noFilter: true,
      render: appointments => (appointments || []).length,
    },
    {
      title: 'Projects',
      name: 'Projects',
      dataIndex: 'proposals',
      sorter: false,
      defaultWidth: 200,
      noFilter: true,
      render: projects => (projects || []).length,
    },

    {
      title: 'Notes',
      name: 'Notes',
      dataIndex: 'notes',
      sorter: false,
      defaultWidth: 100,
      noFilter: true,
      render: notes => (
        <Tooltip
          overlayStyle={{ whiteSpace: 'pre-line' }}
          title={
            notes &&
            Array.isArray(notes) &&
            notes?.map((note, idx) => <p key={idx}>{note?.text}</p>)
          }
        >
          <p>{renderNotes(notes)}</p>
        </Tooltip>
      ),
    },
    {
      title: 'Tags',
      name: 'Tags',
      dataIndex: 'tags',
      sorter: false,
      defaultWidth: 100,
      noFilter: true,
      render: tags => (tags || []).length,
    },
  ]

  return (
    <Wrapper>
      {tableData?.length ? (
        <Table pagination={false} columns={columns} dataSource={tableData} />
      ) : (
        <Empty description='No properties yet' />
      )}
    </Wrapper>
  )
}
// const TableProperties = ({
//   properties,
//   onAddProperty,
//   onAddAttachedProperty,
//   onDellAttachedProperty,
//   clientId,
//   newRow,
//   showNewRow,
//   onUpdateRelation,
//   setWasEdited,
//   withModal = false,
//   isAppointment,
// }) => {
//   // const [newRow, showNewRow] = useState(false)
//   const [fieldValues, setFieldValues] = useState(initialFieldValues)
//   const [showPropertiesTable, setShowPropertiesTable] = useState(false)
//   const onChange = e => {
//     const { name, value } = e.target
//     setWasEdited(true)
//     setFieldValues(prev => ({ ...prev, [name]: value }))
//   }

//   const onAdd = async entity => {
//     const data = await fetchProperty(entity?.id)

//     const relation = fieldValues.relation ? fieldValues.relation : data.relationValue ? data.relationValue : ''
//     onAddAttachedProperty({
//       address: data.full_address ? data.full_address : `${getAddressWithUnit(data)}, ${data.postcode}`,
//       note: relation,
//       relation_note: relation,
//       property_relation: relation,
//       client_id: clientId,
//       property_id: data.id, //fieldValues.choosenValue.property_id,
//       fullProperty: data,
//     })
//     setWasEdited(false)
//     showNewRow(false)
//     setFieldValues({ relation: '', choosenValue: null })
//   }

//   const onCancel = () => {
//     showNewRow(false)
//     setFieldValues({ relation: '', choosenValue: null })
//     setWasEdited(false)
//   }
//   /* const onChoose = obj => {
//     setFieldValues({ choosenValue: obj })
//   } */

//   const closePropertyForm = obj => {
//     showNewRow(false)
//     setWasEdited(false)
//     /* if (!obj) {
//       onCancel()
//       return
//     }
//     onAddProperty({
//       address: obj.address,
//       note: fieldValues.relation,
//       client_id: clientId,
//       property_id: obj.property_id,
//     })
//     onCancel() */
//   }

//   const toggleNewRow = () => {
//     setFieldValues(initialFieldValues)
//     showNewRow(prev => !prev)
//     setShowPropertiesTable(prev => (!newRow ? true : prev))
//   }

//   const goToProperty = id => () => {
//     if (id) window.open(`/properties-form/${id}`, '_blank')
//   }
//   const getPopupContainer = () => document.getElementsByClassName('formClientWrapper')[0]

//   /*  const togglePropertiesTable = () => {
//     setFieldValues(initialFieldValues)
//     showNewRow(false)
//     setShowPropertiesTable(!showPropertiesTable)
//   }
//  */

//   const handleUpdatePropertyRelation = async (e, item) => {
//     if (e.target.value === item?.property_relation) return

//     await updatePropertyRelation({ relation_id: item.relation_id, property_relation: e.target.value })
//     onUpdateRelation()

//     openNotificationWithIcon('success', { message: 'Property relation has been updated' })
//   }
//   return (
//     <div className='client-properties client-form-table-container'>
//       {/* <h5>
//         <p>{properties.length}</p>
//         Attached Properties
//         <span className={`mdi ${newRow ? 'mdi-minus' : 'mdi-plus'} iconSpan-first`} onClick={toggleNewRow} />
//         <ToggleButton show={showPropertiesTable} toggle={togglePropertiesTable} />
//       </h5> */}
//       {/* {showPropertiesTable && <> */}
//       {/* <PropertyForm
//       closeForm={closePropertyForm}
//       onChangeRelation={onChange}
//       relationValue={fieldValues.relation}
//       isClientForm
//       onAddAttachedProperty={onAdd}
//       /> */}
//       {newRow && (
//         <WithModal
//           toggleModal={closePropertyForm}
//           withModal={isAppointment}
//           showModal={newRow}
//           title='Attach new property'
//         >
//           <div className='attach-new-property'>
//             <InputField
//               value={fieldValues.relation}
//               onChange={e => {
//                 setFieldValues(prev => ({ ...prev, relation: e.target.value }))
//                 setWasEdited(true)
//               }}
//               className='relation-input'
//               label='Property Relation'
//             />
//             <PropertyForm isClient setEntityId={onAdd} onCloseForm={closePropertyForm} createOnButtonClick />
//             {/* <MainButton type='cancel' title='Cancel' onClick={closePropertyForm} /> */}
//           </div>
//         </WithModal>
//       )}
//       {properties.length ? (
//         <table className='client-form-table'>
//           <thead>
//             <tr>
//               <th>Address</th>
//               <th>Type</th>
//               <th>Relation</th>
//               <th style={{ textAlign: 'center' }}>Clients</th>
//               <th style={{ textAlign: 'center' }}>Appointments</th>
//               <th style={{ textAlign: 'center' }}>Projects</th>
//               <th style={{ textAlign: 'left' }}>Notes</th>
//               <th style={{ textAlign: 'center' }}>Tags</th>
//               <th></th>
//             </tr>
//           </thead>
//           <tbody>
//             {properties.map((item, idx) => {
//               const getValue = name =>
//                 !!item.value && !!item.value[name] ? item.value[name] : item[name] ? item[name] : ''
//               const property = getValue('fullProperty') ? getValue('fullProperty') : item.value ? item.value : item
//               const address = `${getAddressWithUnit(property)}, ${property.postcode}`
//               {
//                 /* const relation = getValue('relation_note')
//                 ? getValue('relation_note')
//                 : getValue('relation')
//                 ? getValue('relation')
//                 : getValue('note')
//                 ? getValue('note')
//                 : getValue('property_relation')
//                 ? getValue('property_relation')
//                 : '' */
//               }
//               return (
//                 <tr key={idx}>
//                   <Tooltip title={address}>
//                     <td>
//                       <Link
//                         to={`/properties-form/${getValue('property_id')}`}
//                         target='_blank'
//                         style={{ textDecoration: 'underline' }}
//                       >
//                         {address}
//                       </Link>
//                     </td>
//                   </Tooltip>
//                   <td>{getValue('type') === 1 ? 'Commercial' : 'Residential'}</td>
//                   <td>
//                     <InputField
//                       defaultValue={getValue('property_relation')}
//                       onClick={e => e.stopPropagation()}
//                       onBlur={e => handleUpdatePropertyRelation(e, item.value)}
//                       fullWidth={false}
//                     />
//                   </td>
//                   <td style={{ textAlign: 'center' }}>{(property.clients || []).length}</td>
//                   <td style={{ textAlign: 'center' }}>{(property.appointments || []).length}</td>
//                   <td style={{ textAlign: 'center' }}>{(property.proposals || []).length}</td>
//                   <Tooltip
//                     overlayStyle={{ whiteSpace: 'pre-line' }}
//                     title={property?.notes?.map?.(n => n.text).join?.('\n\n')}
//                   >
//                     {/*<td className='notes'>{property.notes || ''}</td>*/}
//                     <td className='notes'>{renderNotes(property.notes)}</td>
//                   </Tooltip>
//                   <td style={{ textAlign: 'center' }}>{(property.tags || []).length}</td>
//                   <td onClick={stopPropagation}>
//                     <TableActions
//                       todos={['detach']}
//                       popConfirms={['property from this client']}
//                       tooltips={['Detach property']}
//                       callbacks={[
//                         () => {
//                           onDellAttachedProperty(idx)
//                         },
//                       ]}
//                       getPopupContainer={getPopupContainer}
//                       popConfirmPositions={['left']}
//                     />
//                   </td>
//                 </tr>
//               )
//             })}
//           </tbody>
//         </table>
//       ) : (
//         !newRow && (
//           <>
//             <Empty description='No attached properties yet' />
//             <MainButton
//               title='Add Property'
//               className='add-property-button'
//               onClick={() => showNewRow(prev => !prev)}
//             />
//           </>
//         )
//       )}
//       {/* </>} */}
//     </div>
//   )
// }
