import { getColumnsByDate } from 'api/Matrix-log'
import { Column } from 'features/Dispatch/types'
import { useEffect, useState } from 'react'

export const useColumns = (date: string) => {
  const [columns, setColumns] = useState<Column[]>([])

  useEffect(() => {
    const fetchColumns = async () => {
      const data = await getColumnsByDate({ date })

      setColumns(data)
    }

    if (date !== 'Invalid date') fetchColumns()
  }, [date])

  return {
    columns,
  }
}
