import { DownCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import { ProjectItem, ProjectSection } from 'features/Project/types'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ItemList } from './ItemList'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  createWOItem,
  deleteWOItem,
  selectMaterialsTotal,
  // selectSectionTotalAfterDiscounts,
  selectWordOrderGroupsBySection,
  selectWordOrderItems,
  selectWorkOrderSectionSubtotal,
  updateWOItem,
} from 'features/Project/slices/projectWorkOrdersSlice'
import { priceToView } from 'helpers/thousandSeparator'
import { Item } from './Item'
import { Button } from 'components/UIKit'
import { initialItem } from '../helpers'
import { Error } from 'types/Error'
import ProjectItemsGroup from './WoProjectItemsGroup'
import { round } from 'helpers/Math'

const getGroupTotal = (groupId: number, items: ProjectItem[]) => {
  return items
    .filter(item => item.group_id === groupId)
    .reduce((sum, item) => sum + (item.retail_price * item.qty || 0), 0)
}

interface Props {
  data: ProjectSection & { withItem: boolean }
  disabled?: boolean
  isNewRowtriggered: boolean
  isProjectSection?: boolean
  hidePrices: boolean
  hideCost: boolean
  hideItemDescription: boolean
  onItemAdd: (value: boolean) => void
}

export const WoProjectSection = ({
  data,
  disabled,
  isNewRowtriggered,
  isProjectSection,
  hidePrices,
  hideCost,
  hideItemDescription,
  onItemAdd,
}: Props) => {
  const dispatch = useAppDispatch()

  const items = useAppSelector(selectWordOrderItems(data.id))
  const groups = useAppSelector(selectWordOrderGroupsBySection(data.id))
  const sectionSubtotal = useAppSelector(
    selectWorkOrderSectionSubtotal(data.id),
  )
  // const sectionTotalAfterDiscounts = useAppSelector(
  //   selectSectionTotalAfterDiscounts(data.id),
  // )
  const materialsSubtotal = useAppSelector(selectMaterialsTotal(data.id))

  const [content, showContent] = useState(true)
  const [loading, setLoading] = useState(false)
  const [newRow, setNewRow] = useState(false)
  const [editingItem, setEditingItem] = useState<ProjectItem | null>(null)

  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    onItemAdd?.(newRow)
  }, [newRow])

  useEffect(() => {
    if (data?.withItem) {
      //auto trigger new item row
      handleItemAdd()
    }
  }, [])

  const toggleContent = () => showContent(!content)

  const handleItemSave = async (item: ProjectItem) => {
    setLoading(true)
    const res = item?.id
      ? await dispatch(updateWOItem(item))
      : await dispatch(
          createWOItem({
            ...item,
            name: item.name,
            section_id: data.id,
            qty: item.qty || 1,
            group_id: null,
            checked_option: false,
          }),
        )

    setLoading(false)

    if (res.meta.requestStatus === 'rejected') {
      return setError(res?.payload)
    }

    setEditingItem(null)
    setNewRow(false)
  }

  const handleItemDelete = (item: ProjectItem) => {
    dispatch(deleteWOItem(item))
  }

  const handleItemEdit = (item: ProjectItem) => setEditingItem(item)

  const handleItemAdd = () => {
    setEditingItem(initialItem)

    setNewRow(!newRow)
  }

  const handleItemChange = (e: {
    target: {
      name: keyof ProjectItem
      value: unknown
    }
  }) => {
    if (!editingItem) return

    const { name, value } = e.target

    setEditingItem(
      prev =>
        ({
          ...prev,
          [name]: value,
          global_item_id: [
            'name',
            'description',
            'net_price',
            'retail_price',
            'gross_margin',
            'is_material',
            'taxable',
          ].includes(name)
            ? null
            : prev?.global_item_id,
        } as ProjectItem),
    )

    if (error?.[name]) setError(null)

    if (editingItem.gross_margin !== null) {
      if (name === 'net_price') {
        const calculatedRetailPrice =
          value + round((value * editingItem?.gross_margin || 0) / 100)

        setEditingItem(prevItem => ({
          ...prevItem,
          retail_price: calculatedRetailPrice,
        }))
      }
      if (name === 'gross_margin') {
        const calculatedRetailPrice =
          editingItem?.net_price +
          round((editingItem?.net_price * value || 0) / 100)

        setEditingItem(prevItem => ({
          ...prevItem,
          retail_price: calculatedRetailPrice,
        }))
      }
    }

    if (
      editingItem.gross_margin === null &&
      name === 'gross_margin' &&
      !!editingItem.retail_price
    ) {
      const grossMargin =
        round(
          (editingItem?.retail_price - editingItem?.net_price) /
            editingItem?.net_price,
        ) * 100

      setEditingItem(prev => ({
        ...prev,
        gross_margin: grossMargin,
      }))
    }
  }

  const handleCancel = () => {
    if (newRow) setNewRow(false)

    setEditingItem(null)
    setError(null)
  }

  const itemsWithoutGroup = useMemo(
    () => items.filter(item => !item.group_id),
    [items],
  )

  return (
    <Wrapper>
      <Heading onClick={toggleContent}>
        <Title>{data.title}</Title>

        <Totals>
          {/* <Total>
            <p>Section subtotal after D&R:</p>{' '}
            <p>{priceToView(sectionTotalAfterDiscounts)}</p>
          </Total> */}
          {!hidePrices && (
            <Total>
              <p>Section subtotal:</p> <p>{priceToView(sectionSubtotal)}</p>
            </Total>
          )}
        </Totals>

        {content ? (
          <DownCircleOutlined style={{ fontSize: 20 }} />
        ) : (
          <RightCircleOutlined style={{ fontSize: 20 }} />
        )}
      </Heading>

      {content && (
        <Content>
          {!!itemsWithoutGroup.length && (
            <ItemList hidePrice={hidePrices} hideCost={hideCost}>
              {itemsWithoutGroup?.map(item => (
                <Item
                  data={item}
                  disabled={loading}
                  editingItem={editingItem}
                  error={error}
                  hideCost={hideCost}
                  hidePrice={hidePrices}
                  hideDescription={hideItemDescription}
                  key={item.id}
                  limited={!!isProjectSection}
                  loading={loading}
                  onCancel={handleCancel}
                  onChange={handleItemChange}
                  onSave={handleItemSave}
                  onDelete={handleItemDelete}
                  onEdit={handleItemEdit}
                />
              ))}
            </ItemList>
          )}

          {!!groups.length && (
            <Groups>
              {groups?.map(group => (
                <ProjectItemsGroup
                  key={group.id}
                  group={group}
                  groupTotal={getGroupTotal(group.id, items)}
                  disabled
                >
                  <ItemList hidePrice={hidePrices} hideCost={hideCost}>
                    {items
                      .filter(item => item.group_id === group.id)
                      ?.map(item => (
                        <Item
                          data={item}
                          disabled={loading}
                          editingItem={editingItem}
                          error={error}
                          hideCost={hideCost}
                          hidePrice={hidePrices}
                          hideDescription={hideItemDescription}
                          key={item.id}
                          limited={!!isProjectSection}
                          loading={loading}
                          onCancel={handleCancel}
                          onChange={handleItemChange}
                          onSave={handleItemSave}
                          onDelete={handleItemDelete}
                          onEdit={handleItemEdit}
                        />
                      ))}
                  </ItemList>
                </ProjectItemsGroup>
              ))}
            </Groups>
          )}

          {newRow && !!editingItem && (
            <Item
              disabled={false}
              error={error}
              loading={loading}
              editingItem={editingItem}
              data={editingItem}
              hideCost={hideCost}
              hidePrice={hidePrices}
              hideDescription={hideItemDescription}
              onChange={handleItemChange}
              onCancel={handleCancel}
              onSave={handleItemSave}
              onEdit={handleItemEdit}
            />
          )}

          {!disabled && !isProjectSection && (
            <Button
              style={{ margin: '20px 0 20px 10px ' }}
              onClick={handleItemAdd}
              disabled={isNewRowtriggered || !!editingItem}
            >
              + Add Item
            </Button>
          )}

          {!hidePrices && (
            <SectionTotal>
              <span>Materials subtotal:</span>
              <span>{priceToView(materialsSubtotal)}</span>
            </SectionTotal>
          )}
          {!!data.installation && !hidePrices && (
            <SectionTotal>
              <span>Additional Labor Cost:</span>
              <span>{priceToView(data.installation)}</span>
            </SectionTotal>
          )}
          {!hidePrices && (
            <SectionTotal>
              <span>Section subtotal:</span>
              <span>{priceToView(sectionSubtotal)}</span>
            </SectionTotal>
          )}
        </Content>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid;
  border-color: #d4d4d4;
  border-radius: 6px;
`
const Heading = styled.div`
  display: flex;
  align-items: center;
  background-color: #c4c4c447;
  padding: 10px;
  cursor: pointer;
`
const Content = styled.div`
  padding: 5px 10px 5px 5px;
`
const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
`
const Totals = styled.div`
  display: flex;
  align-items: center;
  gap: 96px;
  margin-left: auto;
  margin-right: 50px;

  p {
    line-height: 1.5;
  }
`
const Total = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 12px;
`

const Groups = styled.div`
  margin-top: 10px;
`

const SectionTotal = styled.p`
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 16px;
  margin: 10px 0;

  span {
    &:nth-of-type(2) {
      flex: 0 0 150px;
      text-align: right;
    }
  }
`
