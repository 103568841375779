import * as types from '../../types'

const initialState = {
  show: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.installationDate.TOGGLE_INSTALLATION_DATE:
      return {
        show: !state.show,
      }

    default:
      return state
  }
}
