import * as types from '../../types'

const initialState = {
  loading: false,
  settings: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.proposal.PROPOSAL_GET_ITEM:
      return {
        ...state,
        loading: true,
      }
    case types.proposal.PROPOSAL_SET_ITEM:
      return {
        ...state,
        loading: false,
        settings: action.item,
      }

    case types.proposal.PROPOSAL_SET_MODAL_NAMES:
      return
    case types.proposal.PROPOSAL_CLEAR_ITEM:
      return initialState
    default:
      return state
  }
}
