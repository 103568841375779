import * as types from '../../types'
import * as paymentSetup from '../../../../api/settings/PaymentSetup'
import * as actions from '../index'

export const setItem = item => {
  return {
    type: types.paymentSetup.PAYMENT_SETUP_GET_ITEM,
    item,
  }
}

export const fetchPaymentSetup = () => dispatch => {
  return paymentSetup
    .fetchPaymentSetup()
    .then(response => {
      dispatch(setItem(response))
      return response
    })
    .then(resp => {
      dispatch(actions.system.hideSpinner())
      return resp
    })
}
