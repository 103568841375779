export const parseMessages = error => {
  const result = {
    status: 500,
    message: 'Whoops, something went wrong.',
    validationMessages: {},
  }

  if (error.response || (error.data && error.status)) {
    const response = error.response || error
    const responseData = response.data

    result.status = response.status

    if (response.status < 500) {
      if (response.status === 401 && !responseData.message) {
        result.message = 'Authentication failed'
      } else if (response.status === 403 && !responseData.message) {
        result.message = 'Unauthorized'
      } else if (response.status === 422) {
        result.message = responseData.message || 'The given data was invalid'

        if (responseData.errors) {
          Object.entries(responseData.errors).forEach(field => {
            result.validationMessages[field[0]] = field[1][0]
          })
        }
      } else if (responseData.message) {
        result.message = responseData.message
      }
    }
  }

  return result
}
