import * as types from '../types'
import * as client from '../../../api/Client'
import * as user from '../../../api/User'

const normalizeClient = client => {
  if (typeof client.phones === 'string') {
    client.phones = JSON.parse(client.phones)
  }
  if (typeof client.emails === 'string') {
    client.emails = JSON.parse(client.emails)
  }
  if (!Array.isArray(client.phones)) client.phones = []
  if (!Array.isArray(client.emails)) client.emails = []
  if (!client.feedBackList) client.feedBackList = []
  if (!client.feedbacks)
    client.feedbacks = client.feedBackList ? client.feedBackList : []
  if (!client.properties) client.properties = []
  if (!client.tags) client.tags = []
  if (!client.client_associations) client.client_associations = []
  if (!client.client_properties) client.client_properties = []
  client.client_properties = client.properties.map(item => ({
    ...item,
    property_id: item.id,
  }))
  if (!client.contacts) client.contacts = []
  if (!client.organizations) client.organizations = []

  // client.relations_with_property = [
  //   client.properties.reduce((acc, property) => {
  //     acc[property.id] = {
  //       note: property.relation_note
  //         ? property.relation_note
  //         : property.relation
  //         ? property.relation
  //         : property.note
  //         ? property.note
  //         : property.property_relation
  //         ? property.property_relation
  //         : '',
  //     }
  //     return acc
  //   }, {}),
  // ]

  if (!client.vote) client.vote = 0
  if (!client.note) client.note = ''
}

export const changeField = (field, value) => {
  return {
    type: types.client.CLIENT_CHANGE_FIELD,
    field,
    value,
  }
}

export const setItem = item => {
  return {
    type: types.client.CLIENT_SET_ITEM,
    item,
  }
}

export const getItem = () => {
  return {
    type: types.client.CLIENT_GET_ITEM,
  }
}

export const setCurrentClient = res => {
  return {
    type: types.client.SET_CURRENT_CLIENT,
    res,
  }
}

export const removeCurrentClient = () => {
  return {
    type: types.client.REMOVE_CURRENT_CLIENT,
  }
}

const clientAssociationAdded = ass => {
  return {
    type: types.client.ADD_CLIENT_ASSOCIATION,
    payload: ass,
  }
}

const clientAssociationDeleted = id => {
  return {
    type: types.client.DELETE_CLIENT_ASSOCIATION,
    payload: id,
  }
}

export const deleteClient = id => dispatch => {
  return client.deleteClient(id)
}

export const createClient = data => dispatch => {
  return client.createClient(data)
}

export const updateClient = (id, data) => dispatch => {
  return client.updateClient(id, data)
}
export const fetchClientsList = props => dispatch => {
  return client.fetchClientsList(props).then(response => {
    if (Array.isArray(response.data)) {
      response.data.forEach(client => {
        normalizeClient(client)
      })
    }
    dispatch(setItem(response))
  })
}

export const getClientById = id => dispatch => {
  return client.getClientById(id).then(client => {
    if (!(client instanceof Object)) client = {}
    normalizeClient(client)
    dispatch(setCurrentClient(client))
    return client
  })
}
export const mergeClients = data => dispatch => {
  return client.mergeClients(data).then(response => {
    dispatch(fetchClientsList())
  })
}
export const createRelationProperty = data => dispatch => {
  return client.createRelationProperty(data)
}
export const createClientAssociation = data => dispatch => {
  return client.createClientAssociation(data)
}
export const addClientAssociation = data => dispatch => {
  return client.createClientAssociation(data).then(res => {
    dispatch(clientAssociationAdded(res))
    return res
  })
}
export const deleteClientAssociation = id => dispatch => {
  return client.dellClientAssociation(id).then(res => {
    dispatch(clientAssociationDeleted(id))
  })
}
export const dellAttachedProperty = id => dispatch => {
  return client.dellAttachedProperty(id)
}
export const dellClientAssociation = id => dispatch => {
  return client.dellClientAssociation(id)
}
