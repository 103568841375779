import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import withSubscription from '../../../../hoc/withSubscription'
import { compose } from 'redux'
import { sidebarLinks } from './utils'
import AppVersionService from 'services/AppVersionService'
import './style.scss'

const Sidebar = ({ isOpen, toggle, location, subscriptionStatus }) => {
  const allLinks = sidebarLinks()
  const [maxHeight, setMaxHeight] = useState(document.body.clientHeight - 80)

  const resizeListener = () => {
    setMaxHeight(document.body.clientHeight - 80)
    toggle(prev => (document.body?.clientWidth <= 600 ? false : prev))
  }

  useEffect(() => {
    window.addEventListener(`resize`, resizeListener, false)
    return () => {
      window.removeEventListener(`resize`, resizeListener, false)
    }
  }, [document.body.clientHeight])

  const currentLocation = (page: string) =>
    location.pathname.startsWith(page) ? 'active' : ''

  const onClickLink = () => {
    AppVersionService.checkAppVersion()
    window.innerWidth <= 768 && toggle(false)
  }

  const linksList = allLinks.reduce((result, link, index) => {
    if (!!link?.isAllowed) {
      result.push(
        <Link to={link.src} onClick={onClickLink} key={link.key + index}>
          <div className={`item ${currentLocation(link.src)}`}>
            {link.icon}
            <div>{link.title}</div>
          </div>
        </Link>,
      )
    }
    return result
  }, [])

  return (
    <div id='sidebar' className={isOpen ? `sidebar open` : `sidebar closed`}>
      <SimpleBar style={{ maxHeight: `${maxHeight}px` }}>
        {subscriptionStatus !== 'INACTIVE' && linksList}
      </SimpleBar>
    </div>
  )
}

export default compose(withSubscription)(Sidebar)
