import { configV2API } from 'api/helpers'
import { DispatchQueue } from 'features/Dispatch/Queue/types'
import { Column } from 'features/Dispatch/types'
import axiosOrcatec from './../axiosInstance'
import { EventType } from 'features/Settings/Dispatch/components/QueueSettings/types'

export const getRequestSettings = () => {
  return axiosOrcatec
    .get('setting/matrix', configV2API)
    .then(response => response.data)
    .catch(err => {
      console.error(err.message)
    })
}

export const getQueueList = () =>
  axiosOrcatec
    .get<DispatchQueue[]>('setting/queues', configV2API)
    .then(res => res.data)
export const getQueueById = (id: number) =>
  axiosOrcatec.get(`setting/queues/${id}`, configV2API).then(res => res.data)
export const createQueue = (data: Omit<DispatchQueue, 'id'>) =>
  axiosOrcatec
    .post<DispatchQueue>(`setting/queues`, data, configV2API)
    .then(res => res.data)
export const updateQueue = (data: DispatchQueue) =>
  axiosOrcatec
    .put<DispatchQueue>(`setting/queues/${data.id}`, data, configV2API)
    .then(res => res.data)
export const deleteQueue = (id: number, data: { move_to: number | null }) =>
  axiosOrcatec
    .delete(`setting/queues/${id}`, { ...configV2API, data })
    .then(res => res.data)
export const sortQueues = (data: DispatchQueue) =>
  axiosOrcatec
    .put(`setting/queues/set-sortable-queues`, { queues: data }, configV2API)
    .then(res => res.data)

export const getQueueSettings = () =>
  axiosOrcatec
    .get<Record<EventType, number | null>>('setting/queue_types', configV2API)
    .then(res => res.data)
export const updateQueueSettings = (data: Record<EventType, number | null>) =>
  axiosOrcatec
    .post<Record<EventType, number | null>>(
      `setting/queue_types`,
      data,
      configV2API,
    )
    .then(res => res.data)

export const updateRequestSettings = (data, confirm) => {
  return axiosOrcatec
    .put(`setting/matrix/${data.id}`, { ...data, confirm }, configV2API)
    .then(response => response.data)
}

export const updateGlobalColumnsOrder = (data: Column[]) =>
  axiosOrcatec
    .put(
      `/setting/columns/set-sortable-column-template`,
      { columns: data },
      configV2API,
    )
    .then(res => res.data)

export const updateGlobalColumn = (data: Column) =>
  axiosOrcatec
    .put(`/setting/columns/${data.id}`, data, configV2API)
    .then(res => res.data)

export const createGlobalColumn = (
  data: Omit<Column, 'id' | 'column_template_id'>,
) =>
  axiosOrcatec.post(`/setting/columns`, data, configV2API).then(res => res.data)

export const deleteGlobalColumn = async (id: number, data) =>
  await axiosOrcatec.delete(`/setting/columns/${id}`, { ...configV2API, data })

export const getAppoinments = () => {
  return axiosOrcatec
    .get('appointments')
    .then(response => response.data)
    .catch(err => {
      console.error(err.message)
    })
}
export const createAppontment = data => {
  return axiosOrcatec
    .post('appointments', data)
    .then(response => response.data)
    .catch(err => {
      console.error(err.message)
    })
}

export const updateAppoinments = data => {
  return axiosOrcatec
    .put(`appointments/${data.id}`, data)
    .then(response => response.data)
    .catch(err => {
      console.error(err.message)
    })
}

//Appointment types
export interface AppointmentTypeWithColor {
  id: number
  name: string
  hex: string
}
type NewAppointmentTypeWithColor = Omit<AppointmentTypeWithColor, 'id'>

export const getAppointmentTypes = async () => {
  const { data } = await axiosOrcatec.get<AppointmentTypeWithColor[]>(
    'setting/appointment-types',
  )

  return data
}

export const deleteAppointmentType = async (id: number) => {
  return await axiosOrcatec.delete(`/setting/appointment-types/${id}`)
}

export const createAppointmentType = async (
  newType: NewAppointmentTypeWithColor,
) => {
  const { data } = await axiosOrcatec.post<AppointmentTypeWithColor>(
    `/setting/appointment-types`,
    newType,
  )
  return data
}

export const updateAppointmentType = async (
  id: number,
  appointmentType: AppointmentTypeWithColor,
) => {
  const { data } = await axiosOrcatec.put(
    `/setting/appointment-types/${id}`,
    appointmentType,
  )
  return data.data
}

const queueApi = {
  createQueue,
  updateQueue,
  deleteQueue,
  getQueueList,
  getQueueById,
  sortQueues,
  getQueueSettings,
  updateQueueSettings,
}

export default queueApi
