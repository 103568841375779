export const rowKeys = {
  titleRow: [
    'Name',
    'Type',
    'Relation',
    'Other Projects',
    'Notes',
    'Project Relation',
    'Tags',
    'Icon',
  ],
  firstRow: [
    'name',
    { key: 'type', type: 'clients' },
    'property_relation',
    'proposals',
    'note',
    'proposal_relation',
    'tags',
  ],
  secondRow: [
    { address: 'full_address', name: 'name' },
    'type',
    'property_relation',
    'proposals',
    'note',
    'proposal_relation',
    'tags',
  ],
  thirdRow: [
    { address: 'full_address', name: 'name' },
    'type',
    'property_relation',
    'proposals',
    'note',
    'proposal_relation',
    'tags',
  ],
}
