import Boxes from './boxes'
import Events from './events'
import { Title } from 'containers/MainContent/Orcatec/Matrix/components/calendarV2'

import './style.scss'
import { DispatchSettings } from '../../../CalendarSettings'
import { Column } from 'features/Dispatch/types'

interface Props {
  settings: DispatchSettings
  column: Column
  index: number
  onColumnSettingsClick: (columnId: number) => void
}

const ResourceColumn = ({ settings, column, index, onColumnSettingsClick }: Props) => {
  return (
    <div className='resource-column' resourceid={column.id}>
      <Title
        column={column}
        onClick={e => {
          e.preventDefault()
          onColumnSettingsClick(column.id)
        }}
      />

      <Boxes settings={settings} />
      <Events settings={settings} column={column} index={index} />
    </div>
  )
}

export default ResourceColumn
