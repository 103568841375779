export const ActionType = {
  SET_CREATE_MODAL_FIELDS: 'SET_CREATE_MODAL_FIELDS',
  SET_EDIT_MODAL_FIELDS: 'SET_EDIT_MODAL_FIELDS',
  CLEAR_STORE: 'CLEAR_STORE',
  SET_CREATE_MODAL_PERMISSIONS: 'SET_CREATE_MODAL_PERMISSIONS',
  SET_EDIT_MODAL_PERMISSIONS: 'SET_EDIT_MODAL_PERMISSIONS',
  CREATE_USER_INIT: 'CREATE_USER_INIT',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  EDIT_USER_INIT: 'EDIT_USER_INIT',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',
  GET_USERS_LIST_INIT: 'GET_USERS_LIST_INIT',
  GET_USERS_LIST_SUCCESS: 'GET_USERS_LIST_SUCCESS',
  GET_USERS_LIST_FAILURE: 'GET_USERS_LIST_FAILURE',
  DELETE_USER_INIT: 'DELETE_USER_INIT',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
  SET_USER_STATUS_INIT: 'SET_USER_STATUS_INIT',
  SET_USER_STATUS_SUCCESS: 'SET_USER_STATUS_SUCCESS',
  SET_USER_STATUS_FAILURE: 'SET_USER_STATUS_FAILURE',
  CHECK_INNACTIVE_USER: 'CHECK_INNACTIVE_USER',
  GET_CURRENT_USER_INIT: 'GET_CURRENT_USER_INIT',
  GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_FAILURE: 'GET_CURRENT_USER_FAILURE',
  RESEND_INVITE_INIT: 'RESEND_INVITE_INIT',
  RESEND_INVITE_SUCCESS: 'RESEND_INVITE_SUCCESS',
  RESEND_INVITE_FAILURE: 'RESEND_INVITE_FAILURE',
  GET_USER_ROLES: 'GET_USER_ROLES',
  SELECT_USER_ROLE: 'SELECT_USER_ROLE',
}
