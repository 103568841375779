import React from 'react'
import { Drawer, DrawerProps } from 'antd'
import styled from 'styled-components'

const Wrapper = styled.div`
  & .subtitle {
    min-width: 115px;
  }
  & .appointment-section__body {
    display: flex;
    flex-direction: column-reverse;
  }
  & .clientWrapperMain {
    display: grid;
  }
`
interface IProps extends DrawerProps {
  children: JSX.Element
  withDrawer: boolean
}
const CustomDrawer = ({ children, withDrawer, ...rest }: IProps) => {
  return withDrawer ? (
    <Drawer {...rest}>
      <Wrapper>{children}</Wrapper>
    </Drawer>
  ) : (
    { ...children }
  )
}

export default CustomDrawer
