// import { useState } from 'react'
import TabsLayout from '../TabsLayout'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectWordOrderSections } from 'features/Project/slices/projectWorkOrdersSlice'
import { WoProjectSectionsList } from './components/WoProjectSectionsList'

export const ScopeOfWork = () => {
  const sections = useAppSelector(selectWordOrderSections)

  // const [newRowTriggered, setNewRorTriggered] = useState(false)

  return (
    <TabsLayout title='Scope of work'>
      <WoProjectSectionsList data={sections} />
    </TabsLayout>
  )
}
