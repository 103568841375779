import * as types from '../../types'
import moment from 'moment-timezone'
import { scrollToFirstUncompletedNewItem } from '../../../../containers/MainContent/Orcatec/Proposals/components/Form'
import { defaultProposalModuleNames } from '../../../../common/constants'
import { sessionStartTimeStamp } from '../../../..'
// import states from 'helpers/states'
// import { SET_PROPOSAL_FORM_FOR_ACCOUNTING } from '../../types/proposal/proposalForm'
// import { resetProjectSlice } from 'features/Project/slices/projectSlice'
const itemTypes = [
  // 'equipments',
  // 'parts',
  // 'supplies',
  'materials',
  'scope_of_works',
  'warranties',
  'instants',
  'rebates',
]

const initialProposalTabModules = [
  {
    name: '',
    notes: '',
    status: 1,
  },
  {
    name: '',
    notes: '',
    status: 1,
  },
  {
    name: '',
    notes: '',
    status: 1,
  },
]

const initialProposalTabSection = {
  show: true,
  id: 0,
  // position: 0,
  // tempId: Date.now(),
  title: 'New Section',
  description: '',
  proposal_notes: '',
  notes: '',
  installation: 0,
  gross_margin: 0,
  // equipments: [],
  // parts: [],
  // supplies: [],
  // materials: [],
  // scope_of_works: [],
  items: [],
  warranties: [],
  instants: [],
  rebates: [],
  structure: {
    materials: {
      title: 'Materials',
      position: 0,
    },
    scope_of_works: {
      title: 'Scope of Work',
      position: 1,
    },
    warranties: {
      title: 'Warranty',
      position: 2,
    },
    instants: {
      title: 'Discounts',
      position: 3,
    },
    rebates: {
      title: 'Rebates',
      position: 4,
    },
  },
}

const initialProposalTab = {
  id: 0,
  position: 0,
  files: [],
  title: 'Tab Title',
  proposal_notes: '',
  notes_for_installers: '',
  total_to_pay: 0,
  tags: [],
  status: 1,
  payment_status: 1,
  isAllEquipmentOrdered: false,
  modules: initialProposalTabModules,
  proposal_sections: [{ ...initialProposalTabSection, tempId: Date.now() }],
  feeIds: [],
  tax: {},
  fees: [],
}

const initialState = {
  accounting: {
    expenses: [],
    advertising: [],
    pre_net_expenses: [],
    appointment: {
      type: '',
      history: [],
      rates: [
        {
          id: 0,
          time: 0,
          cost_per_hour: 0,
          natural_time: {
            hours: 0,
            minutes: 0,
          },
        },
      ],
    },
    work_dates: {
      history: [],
      rates: [
        {
          id: 0,
          time: 0,
          cost_per_hour: 0,
          natural_time: {
            hours: 0,
            minutes: 0,
          },
        },
      ],
    },
    commission: {
      type: 1,
      percent: 0,
    },
    job_cost_materials: false,
    total_materials: '0',
  },
  uncompletedNewItemsIds: [],
  itemsToDeleteOnSave: [],
  tabsToDeleteOnSave: [],
  sectionToDeleteOnSave: [],
  fileIdsForDelete: [],
  industry: [],
  activeTabId: null,
  activeTab: 0,
  isEdited: false,
  status: 1,
  files: [],
  info_for_customer: '',
  files_from_customer: [],
  info_from_customer: '',
  priority_tab_id: null,
  client_ids: [],
  all_work_completed: false,
  complete_related_jobs: false,
  installation_dates: [],
  billing_address: {
    address: '',
    postcode: '',
    city: '',
    state: '',
  },
  tabs_info: [{ id: initialProposalTab.id, title: 'Tab Title' }],
  proposal_tabs: [initialProposalTab],
  require_signature: true,
  show_tech: false,
  show_taxes: true,
  show_information: true,
  multiple_tabs: true,
  default_payment_due_date: 30,
  include_tips_to_insight: false,
  show_discounts: false,
  show_rebates: false,
  show_items_qty: false,
  disable_tips: false,
  show_item_description_only: false,
  property: {
    address: '',
    postcode: '',
    city: '',
    state: '',
    notes: '',
  },
  relatives: {},
  filesToDelete: [],
}

const zeroOutItem = (item, idx) => ({
  ...item,
  id: item.id + `${Date.now()}` + 'item_cleared' + idx,
  proposal_section_id: 0,
})

const zeroOutSection = (section, idx) => {
  const newSection = {
    ...section,
    id: 0,
    proposal_tab_id: 0,
    tempId: section.id + `${Date.now()}` + 'section_cleared' + idx,
    isNew: false,
  }
  itemTypes.forEach?.(itemsType => {
    newSection[itemsType] = newSection[itemsType].map(zeroOutItem)
  })
  return newSection
}

const zeroOutTab = (tab, idx) => {
  let tax = {}
  let fees = []
  if (tab.fees?.length) {
    fees = tab.fees.map(feesItem => {
      feesItem.id = 0
      return feesItem
    })
  }
  if (tab.tax) {
    tax = { ...tab.tax, id: 0, tab_id: 0 }
  }
  const newTab = { ...tab, id: 0, tax, fees }
  newTab.proposal_sections = newTab.proposal_sections.map(zeroOutSection)
  return newTab
}

const getAttachmentFieldNameByIdx = idx =>
  idx === 0
    ? 'attachment_one'
    : idx === 1
    ? 'attachment_two'
    : 'attachment_three'
const setNewPositionsInTabInfo = (tab, idx) => ({ ...tab, position: idx })
const setNewPositionsInProposalTab = (tab, idx) =>
  !tab ? tab : { ...tab, position: idx }
// Reducer
export default (state = initialState, action) => {
  let newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case types.proposalForm.ACCOUNTING_SET_ITEMS:
      return {
        ...state,
        job_cost_materials: action.payload?.job_cost_materials,
        accounting: {
          ...state.accounting,
          ...action.payload,
        },
      }
    // case types.proposalForm.ACCOUNTING_SET_ROW_ITEM:
    //   if (action.payload.rowTitle === 'Expenses') {
    //     return {
    //       ...state,
    //       accounting: {
    //         ...state.accounting,
    //         expenses: action.payload.items,
    //       },
    //     }
    //   } else if (action.payload.rowTitle === 'Advertising Cost') {
    //     return {
    //       ...state,
    //       accounting: {
    //         ...state.accounting,
    //         advertising: action.payload.items,
    //       },
    //     }
    //   }
    case types.proposalForm.SET_TEMPLATE_IN_SECTION: {
      const { data, sectionId, sectionIdx } = action?.payload
      /*  const {
        // isHideMaterials,
        // isHideWarranty,
        // isHideDiscount,
        // isHideRebates,
      } = newState.setting.hide_section_items */

      const templateToInsert = {
        ...data,
        position: sectionIdx,
        isNew: true,
        // materials: isHideMaterials ? [] : data?.equipments,
        // equipments: isHideMaterials ? [] : data?.equipments,
        // warranties: isHideWarranty ? [] : data?.warranties,
        // instants: isHideDiscount ? [] : data?.instants,
        // rebates: isHideRebates ? [] : data?.rebates,
      }

      newState.sectionToDeleteOnSave =
        sectionId !== 0 && newState.sectionToDeleteOnSave?.concat(sectionId)
      newState.proposal_tabs[state.activeTab].proposal_sections.splice(
        sectionIdx,
        1,
        templateToInsert,
      )
      newState.isEdited = true
      return newState
    }

    case types.proposalForm.CLEAR_SIGNATURE_FIELD:
      newState.signature = null
      newState.signatory_name = ''
      return newState

    case types.proposalForm.ACCOUNTING_SET_BLOCK_ITEM:
      if (action.payload.blockTitle === 'Work Dates') {
        return {
          ...state,
          accounting: {
            ...state.accounting,
            work_dates: action?.payload?.items,
          },
        }
      } else {
        return {
          ...state,
          accounting: {
            ...state.accounting,
            appointment: action?.payload?.items,
          },
        }
      }
    case types.proposalForm.ACCOUNTING_UPLOAD_FILE:
      const { files = [], itemIDX, rowTitle = '' } = action?.payload
      if (rowTitle === 'Expenses') {
        return {
          ...state,
          accounting: {
            ...state.accounting,
            expenses: state.accounting.expenses.map((expense, expenseID) => {
              if (expenseID === itemIDX) {
                return {
                  ...expense,
                  attachedFiles: files,
                }
              } else {
                return expense
              }
            }),
          },
        }
      } else if (rowTitle === 'Advertising Cost') {
        return {
          ...state,
          accounting: {
            ...state.accounting,
            advertising: state.accounting.advertising.map(
              (advertise, advertiseID) => {
                if (advertiseID === itemIDX) {
                  return {
                    ...advertise,
                    attachedFiles: files,
                  }
                } else {
                  return advertise
                }
              },
            ),
          },
        }
      } else if (rowTitle === 'Pre Net Expenses') {
        return {
          ...state,
          accounting: {
            ...state.accounting,
            pre_net_expenses: state.accounting.pre_net_expenses.map(
              (advertise, advertiseID) => {
                if (advertiseID === itemIDX) {
                  return {
                    ...advertise,
                    attachedFiles: files,
                  }
                } else {
                  return advertise
                }
              },
            ),
          },
        }
      }
      break
    case types.proposalForm.FETCH_ACCOUNTING_DATA:
      return {
        ...state,
        accounting: action.data,
      }
    case types.proposalForm.SET_PROPOSAL_FORM_FOR_ACCOUNTING:
      return {
        ...state,
        ...action.payload,
      }

    case types.proposalForm.SET_ALL_TABS_IN_PROPOSAL:
      const allTabs = JSON.parse(JSON.stringify(action.payload))

      const tabsToInsert = newState.proposal_tabs.map((tab, idx) => {
        return { ...allTabs[idx], ...tab, total_to_pay: tab.total_to_pay }
      })

      newState.proposal_tabs = tabsToInsert
      return newState

    case types.proposalForm.UPDATE_PRIMARY_PROPERTY:
      const primaryPropertyId = state.properties?.find(
        property => property.property_relation_id === action.payload,
      )?.id

      return {
        ...state,
        properties: state.properties?.map(property => ({
          ...property,
          is_primary: property.id === primaryPropertyId,
        })),
      }

    case types.proposalForm.PROPOSAL_CLEAR_ID_TO_DELETE:
      newState.itemsToDeleteOnSave = []
      newState.tabsToDeleteOnSave = []
      newState.sectionToDeleteOnSave = []
      return newState
    case types.proposalForm.SET_PROPOSALS_FORM:
      newState.isEdited = false
      newState.filesToDelete = []
      newState.renderCount = !newState?.renderCount
        ? 1
        : newState?.renderCount + 1 //kostil for update project tags

      const proposal = JSON.parse(JSON.stringify(action.proposal))
      let activeTab =
        Array.isArray(proposal.tabs_info) &&
        !!proposal.tabs_info?.length &&
        Array.isArray(proposal.proposal_tabs) &&
        !!proposal.proposal_tabs?.length
          ? proposal.tabs_info
              .sort((a, b) => a.position - b.position)
              .findIndex(item => item.id === proposal.proposal_tabs[0].id)
          : 0
      if (activeTab < 0) activeTab = 0
      newState.activeTab = activeTab
      if (!proposal.priority_tab_id)
        proposal.priority_tab_id =
          Array.isArray(proposal.proposal_tabs) &&
          !!proposal.proposal_tabs?.length
            ? proposal.proposal_tabs[0].id
            : null
      if (!Array.isArray(proposal.client_ids)) proposal.client_ids = []
      if (!Array.isArray(proposal.installation_dates))
        proposal.installation_dates = []
      if (!proposal.status) {
        proposal.status = 1
        newState.isEdited = true
      }
      if (!proposal.canceled_info)
        proposal.canceled_info = {
          reason: '',
          date: '',
          user: '',
        }
      if (proposal.all_work_completed === null)
        proposal.all_work_completed = false
      if (!proposal.files) proposal.files = initialState.files
      if (!proposal.files_from_customer)
        proposal.files_from_customer = initialState.files_from_customer
      if (
        !proposal.billing_address ||
        (!!proposal.billing_address && !proposal.billing_address.address)
      )
        proposal.billing_address = proposal.property
          ? {
              address: proposal.property.address,
              city: proposal.property.city,
              state: proposal.property.state,
              postcode: proposal.property.postcode,
              unit: proposal.property.unit,
            }
          : initialState.billing_address

      if (
        proposal.show_price === null &&
        typeof action.show_price === 'boolean'
      ) {
        proposal.show_price = action.show_price
        newState.isEdited = true
      } else if (proposal.show_price === '1' || proposal.show_price === '0') {
        proposal.show_price = !!+proposal.show_price
      }
      if (typeof proposal?.setting?.require_signature === 'boolean') {
        newState.require_signature = proposal.setting.require_signature
      }
      if (typeof proposal?.setting?.show_information === 'boolean') {
        newState.show_information = proposal.setting.show_information
      }
      if (typeof proposal?.setting?.show_item_description_only === 'boolean') {
        newState.show_item_description_only =
          proposal.setting.show_item_description_only
      }
      if (typeof proposal?.setting?.show_taxes == 'boolean') {
        newState.show_taxes = proposal.setting.show_taxes
      }
      if (typeof proposal?.setting?.multiple_tabs == 'boolean') {
        newState.multiple_tabs = proposal.setting.multiple_tabs
      }
      if (typeof proposal?.setting?.disable_tips == 'boolean') {
        newState.disable_tips = proposal.setting.disable_tips
      }
      if (typeof proposal?.setting?.show_discounts == 'boolean') {
        newState.show_discounts = proposal.setting.show_discounts
      }
      /*  if (
        typeof proposal?.setting?.hide_section_items.isHideMaterials ==
        'boolean'
      ) {
        newState.hide_section_items.isHideMaterials =
          proposal.setting.hide_section_items.isHideMaterials
      } */
      if (typeof proposal?.setting?.show_rebates == 'boolean') {
        newState.show_rebates = proposal.setting.show_rebates
      }
      /*  if (
        typeof proposal?.setting?.hide_section_items.isHideWarranty == 'boolean'
      ) {
        newState.hide_section_items.isHideWarranty =
          proposal.setting.hide_section_items.isHideWarranty
      } */
      if (typeof proposal?.setting?.show_items_qty == 'boolean') {
        newState.show_items_qty = proposal.setting.show_items_qty
      }
      /*     if (typeof proposal?.setting?.show_items_qty == 'boolean') {
        newState.show_items_qty =
          proposal.setting.show_items_qty
      } */
      if (typeof proposal?.financing == 'boolean') {
        newState.financing = proposal.financing
      }
      if (typeof proposal?.setting?.default_payment_due_date === 'number') {
        newState.default_payment_due_date =
          proposal?.setting?.default_payment_due_date
      }
      if (typeof proposal?.include_tips_to_insight == 'boolean') {
        newState.include_tips_to_insight =
          proposal.include_tips_to_insight || false
      }
      newState.date_of_sale = proposal?.date_of_sale || null

      // if (!proposal.logos && !!action.logos) {
      //   proposal.logos = action.logos
      //   console.log('3 change isEdited')
      //   newState.isEdited = true
      // }

      // if (!proposal.logos) {
      //   proposal.logos = {}
      //   if (!proposal.logos.logo4) {
      //     proposal.logos.logo4 = {
      //       path: action.companyLogo,
      //       preview: action.companyLogo,
      //       company_link: '',
      //     }
      //     console.log('4 change isEdited')
      //     newState.isEdited = true
      //   }
      // }

      if (
        !Array.isArray(proposal.proposal_tabs) ||
        (Array.isArray(proposal.proposal_tabs) &&
          !proposal.proposal_tabs?.length)
      ) {
        proposal.proposal_tabs = [initialProposalTab]
      }
      proposal.proposal_tabs = proposal.proposal_tabs.map(tab => {
        if (
          !tab.modules ||
          (Array.isArray(tab.modules) && !tab.modules?.length)
        ) {
          tab.modules = [
            {
              name:
                action.modulesNamesFromSettings.module_one ||
                defaultProposalModuleNames.module_one,
              notes: '',
              status: 1,
            },
            {
              name:
                action.modulesNamesFromSettings.module_two ||
                defaultProposalModuleNames.module_two,
              notes: '',
              status: 1,
            },
            {
              name:
                action.modulesNamesFromSettings.module_three ||
                defaultProposalModuleNames.module_three,
              notes: '',
              status: 1,
            },
          ]
          newState.isEdited = true
        } else {
          tab.modules = tab.modules.map((module, idx) => {
            let name = ''
            if (idx === 0)
              name = action.modulesNamesFromSettings.module_one
                ? action.modulesNamesFromSettings.module_one
                : defaultProposalModuleNames.module_one
            else if (idx === 1)
              name = action.modulesNamesFromSettings.module_two
                ? action.modulesNamesFromSettings.module_two
                : defaultProposalModuleNames.module_two
            else if (idx === 2)
              name = action.modulesNamesFromSettings.module_three
                ? action.modulesNamesFromSettings.module_three
                : defaultProposalModuleNames.module_three

            if (!module.name || !+module.status) {
              newState.isEdited = true

              return {
                name: module.name ? module.name : name,
                notes: module.notes ? module.notes : '',
                status: +module.status ? +module.status : 1,
              }
            }
            return module
          })
        }
        tab.feeIds = []
        return tab
      })

      if (
        !Array.isArray(proposal.proposal_tabs[0].proposal_sections) ||
        (Array.isArray(proposal.proposal_tabs[0].proposal_sections) &&
          !proposal.proposal_tabs[0].proposal_sections?.length)
      ) {
        newState.isEdited = true

        proposal.proposal_tabs[0].proposal_sections = [
          { ...initialProposalTabSection, tempId: Date.now() },
        ]
      } else {
        proposal.proposal_tabs[0].proposal_sections = proposal.proposal_tabs[0].proposal_sections.map(
          (sec, idx) => {
            if (!sec.description) sec.description = ''
            if (!sec.position) sec.position = idx

            const neededTabIdx =
              action.idxInTabsList !== undefined
                ? action.idxInTabsList
                : state.activeTab

            sec.show =
              !!state.proposal_tabs[neededTabIdx] &&
              !!state.proposal_tabs[neededTabIdx].proposal_sections &&
              !!state.proposal_tabs[neededTabIdx].proposal_sections[idx] &&
              state.id === proposal.id
                ? state.proposal_tabs[neededTabIdx].proposal_sections[idx].show
                : true

            // sec.materials = [
            //   ...sec.equipments,
            //   ...sec.supplies,
            //   ...sec.parts,
            // ]?.sort((a, b) => a.position - b.position)
            return sec
          },
        )
      }

      if (Array.isArray(proposal.tabs_info) && proposal.tabs_info?.length) {
        proposal.proposal_tabs = proposal.tabs_info.map((item, idx) =>
          idx === activeTab
            ? proposal.proposal_tabs[0]
            : !!newState.proposal_tabs[idx] && !!newState.proposal_tabs[idx].id
            ? newState.proposal_tabs[idx]
            : '',
        )
      }

      /*  if (!Array.isArray(proposal.industry)) {
         proposal.industry = Array.isArray(action.industry) && action.industry?.length === 1 ? action.industry : []
       } */
      proposal.industry = action.proposal.industry

      //const { proposal_tabs, ...rest } = proposal

      newState = {
        ...newState,
        ...proposal,
      }

      newState.technician = proposal.user
      newState.fileIdsForDelete = []
      return newState
    case types.proposalForm.PROPOSAL_CHANGE_FIELD:
      if (action.field === 'installation_dates') {
        const { dates, allWorkCompleted, installDateIds } = action.value
        newState[action.field] = dates
        newState.all_work_completed = allWorkCompleted
        newState.installDateIds = installDateIds
      } else if (/^property/.test(action.field)) {
        //if (action.field !== 'property_tags') newState[action.field] = action.value
        if (action.field === 'property') {
          newState[action.field] = action.value
          if (
            !newState.client_ids ||
            (!!newState.client_ids && !newState.client_ids?.length)
          ) {
            if (action.value.clients && action.value.clients?.length) {
              newState.isEdited = true
              newState.client_ids = action.value.clients.map(client => ({
                client_id: client.id,
                checked: true,
              }))
            } else {
              newState.client_ids = !newState.client_ids
                ? []
                : newState.client_ids
            }
          } else {
            if (newState.client_ids.some(id => !isNaN(id))) {
              newState.client_ids = newState.client_ids.map(id => ({
                client_id: id,
                checked: true,
              }))
            }
            newState.client_ids = newState.client_ids.filter(
              item =>
                !!~action.value.clients.findIndex(
                  client => item.client_id === client.id,
                ),
            )
            if (newState.client_ids?.length < action.value.clients?.length) {
              action.value.clients.forEach?.(client => {
                if (
                  !~newState.client_ids.findIndex(
                    item => item.client_id === client.id,
                  )
                ) {
                  newState.client_ids.push({
                    client_id: client.id,
                    checked: true,
                  })
                }
              })
            }
          }
        } else {
          const field = action.field.replace(/^property_/, '')
          newState.property[field] = action.value
        }
      } else if (action.field === 'file_description') {
        newState[action.value.source][action.value.idx].description =
          action.value.text
      } else if (action.field === 'show_price') {
        newState.setting.show_price = action.value
      } else if (action.field === 'show_tech') {
        newState.setting.show_tech = action.value
      } else if (action.field === 'disable_tips') {
        newState.setting.disable_tips = action.value
      } else if (action.field === 'show_item_description_only') {
        newState.setting.show_item_description_only = action.value
      } else if (action.field === 'show_taxes') {
        newState.setting.show_taxes = action.value
      } else if (action.field === 'show_information') {
        newState.setting.show_information = action.value
      } else if (action.field === 'multiple_choice_items') {
        newState.setting.multiple_choice_items = action.value
      } else if (action.field === 'hide_zero_price') {
        newState.setting.hide_zero_price = action.value
      } else if (action.field === 'balance_auto_creation') {
        newState.setting.balance_auto_creation = action.value
      } else if (action.field === 'multiple_tabs') {
        newState.setting.multiple_tabs = action.value
      } else if (action.field === 'show_discounts') {
        newState.setting.show_discounts = action.value
      } else if (action.field === 'show_rebates') {
        newState.setting.show_rebates = action.value
      } else if (action.field === 'require_signature') {
        newState.setting.require_signature = action.value
      } else if (action.field === 'payment_processing_fee') {
        newState.setting.payment_processing_fee = action.value
      } else if (action.field === 'financing') {
        newState.financing = action.value
      } else if (action.field === 'show_items_qty') {
        newState.setting.show_items_qty = action.value
      } else if (action.field === 'include_tips_to_insight') {
        newState.setting.include_tips_to_insight = action.value
      } else {
        newState[action.field] = action.value
      }
      //       if (action.field === 'all_work_completed') {
      //         const WORK_COMPLETED_STATUS = 4
      //
      //         if (action.value === 1 && !!state.installation_dates?.length) {
      //           newState.installation_dates = newState.installation_dates.map(
      //             date => {
      //               if (date.status !== 'canceled')
      //                 date.status = action.value ? WORK_COMPLETED_STATUS : date.status
      //               return date
      //             },
      //           )
      //         } else {
      //           newState.all_work_completed = action.value
      //         }
      //       }
      /* if (action.field === 'show_price') {
        newState.setting.show_price = action.value
      }
      if (action.field === 'show_tech') {
        newState.setting.show_tech = action.value
      } */
      if (
        !/^property/.test(action.field) &&
        ![
          'name',
          'activeTab',
          'all_work_completed',
          'complete_related_jobs',
          'lead_installer_id',
        ].includes(action.field)
      ) {
        newState.isEdited = true
      }

      return newState
    case types.proposalForm.PROPOSAL_SET_FIELD:
      if (action.field === 'show_price') {
        newState.setting.show_price = action.value
      } else if (action.field === 'show_tech') {
        newState.setting.show_tech = action.value
      } else if (action.field === 'completion_progress') {
        newState.setting.completion_progress = action.value
      } else if (action.field === 'disable_tips') {
        newState.setting.disable_tips = action.value
      } else if (action.field === 'show_item_description_only') {
        newState.setting.show_item_description_only = action.value
      } else if (action.field === 'show_taxes') {
        newState.setting.show_taxes = action.value
      } else if (action.field === 'show_information') {
        newState.setting.show_information = action.value
      } else if (action.field === 'multiple_choice_items') {
        newState.setting.multiple_choice_items = action.value
      } else if (action.field === 'hide_zero_price') {
        newState.setting.hide_zero_price = action.value
      } else if (action.field === 'balance_auto_creation') {
        newState.setting.balance_auto_creation = action.value
      } else if (action.field === 'multiple_tabs') {
        newState.setting.multiple_tabs = action.value
      } else if (action.field === 'show_discounts') {
        newState.setting.show_discounts = action.value
      } else if (action.field === 'show_rebates') {
        newState.setting.show_rebates = action.value
      } else if (action.field === 'require_signature') {
        newState.setting.require_signature = action.value
      } else if (action.field === 'payment_processing_fee') {
        newState.setting.payment_processing_fee = action.value
      } else if (action.field === 'financing') {
        newState.setting.financing = action.value
      } else if (action.field === 'show_items_qty') {
        newState.setting.show_items_qty = action.value
      } else if (action.field === 'include_tips_to_insight') {
        newState.setting.include_tips_to_insight = action.value
      } else {
        newState[action.field] = action.value
      }

      // if (
      //   [
      //     'isHideMaterials',
      //     'isHideWarranty',
      //     'isHideDiscount',
      //     'isHideRebates',
      //   ].includes(action.field)
      // ) {
      //   newState.isEdited = true
      // }
      return newState
    case types.proposalForm.PROPOSAL_CLEAR_CUSTOMER_INFORMATION:
      newState.info_for_customer = ''
      newState.files_from_customer = []
      newState.info_from_customer = ''
      return newState

    case types.proposalForm.CLEAR_SECTION_ITEMS:
      newState.isEdited = true
      if (action.payload === 'materials') {
        newState.proposal_tabs.forEach(proposal_tab => {
          if (!proposal_tab) {
            return
          }
          proposal_tab.proposal_sections.forEach(proposal_section => {
            if (!proposal_section) {
              return
            }
            proposal_section.items.forEach(({ id }) => {
              newState.itemsToDeleteOnSave.push({
                id,
                itemTypeForDelete: 'items',
              })
            })
            // if (proposal_section?.parts.length !== 0) {
            //   proposal_section?.parts.forEach(({ id }) => {
            //     newState.itemsToDeleteOnSave.push({
            //       id,
            //       itemTypeForDelete: 'parts',
            //     })
            //   })
            // }
            // if (proposal_section?.supplies.length !== 0) {
            //   proposal_section?.parts.forEach(({ id }) => {
            //     newState.itemsToDeleteOnSave.push({
            //       id,
            //       itemTypeForDelete: 'supplies',
            //     })
            //   })
            // }
            // proposal_section.supplies = []
            // proposal_section.parts = []
            // proposal_section.equipments = []
            proposal_section.items = []
          })
        })
      }
      if (action.payload === 'warranties') {
        newState.proposal_tabs.forEach(proposal_tab => {
          if (!proposal_tab) {
            return
          }
          proposal_tab.proposal_sections.forEach(proposal_section => {
            if (!proposal_section) {
              return
            }
            proposal_section.warranties.forEach(({ id }) => {
              newState.itemsToDeleteOnSave.push({
                itemTypeForDelete: 'Warranty',
                id,
              })
            })
            proposal_section.warranties = []
          })
        })
      }
      if (action.payload === 'instants') {
        newState.proposal_tabs.forEach(proposal_tab => {
          if (!proposal_tab) {
            return
          }
          proposal_tab.proposal_sections.forEach(proposal_section => {
            if (!proposal_section) {
              return
            }
            proposal_section.discounts.forEach(({ id }) => {
              newState.itemsToDeleteOnSave.push({
                itemTypeForDelete: 'Instant',
                id,
              })
            })
            proposal_section.discounts = []
          })
        })
      }
      if (action.payload === 'rebates') {
        newState.proposal_tabs.forEach(proposal_tab => {
          if (!proposal_tab) {
            return
          }
          proposal_tab.proposal_sections.forEach(proposal_section => {
            if (!proposal_section) {
              return
            }
            proposal_section.rebates.forEach(({ id }) => {
              newState.itemsToDeleteOnSave.push({
                itemTypeForDelete: 'Rebate',
                id,
              })
            })

            proposal_section.rebates = []
          })
        })
      }

      return newState

    case types.proposalForm.PROPOSAL_TAB_FIELD_CHANGE:
      if (action.field === 'file_description') {
        newState.proposal_tabs[state.activeTab].files[
          action.value.idx
        ].description = action.value.text
      } else {
        newState.proposal_tabs[state.activeTab][action.field] = action.value
      }

      if (!['payment_status'].includes(action.field)) newState.isEdited = true
      return newState
    case types.proposalForm.PROPOSAL_SECTION_FIELD_CHANGE:
      newState.proposal_tabs[state.activeTab].proposal_sections[action.section][
        action.field
      ] = action.value
      newState.proposal_tabs[state.activeTab].proposal_sections[
        action.section
      ].isNew = false
      if (
        !['show', 'title', 'description', 'installation'].includes(action.field)
      )
        newState.isEdited = true
      return newState
    case types.proposalForm.PROPOSAL_SECTION_STRUCTURE_FIELD_CHANGE:
      newState.isEdited = true

      newState.proposal_tabs[state.activeTab].proposal_sections[
        action.section
      ].structure[action.structureName][action.field] = action.value

      return newState
    case types.proposalForm.PROPOSAL_SECTION_STRUCTURE_REORDER:
      newState.isEdited = true

      newState.proposal_tabs[state.activeTab].proposal_sections[
        action.section
      ].structure = action.newStructure

      return newState
    case types.proposalForm.PROPOSAL_TOGGLE_SECTION:
      newState.proposal_tabs[state.activeTab].proposal_sections[
        action.section
      ].show = !newState.proposal_tabs[state.activeTab].proposal_sections[
        action.section
      ].show
      return newState
    case types.proposalForm.PROPOSAL_DELETE_TAB:
      const { tabIdx, tabId } = action.payload

      newState.proposal_tabs.splice(tabIdx, 1)
      newState.tabs_info.splice(tabIdx, 1)
      delete newState.financing_info[tabIdx]

      newState.proposal_tabs = newState.proposal_tabs.map(
        setNewPositionsInProposalTab,
      )
      newState.tabs_info = newState.tabs_info.map(setNewPositionsInTabInfo)
      newState.financing_info = { ...Object.values(newState.financing_info) }
      return newState
    case types.proposalForm.PROPOSAL_CHANGE_TAB_POSITION: {
      const [removedTabInfo] = newState.tabs_info.splice(newState.activeTab, 1)
      const [removedFinancingbInfo] = Object.values(
        newState.financing_info,
      ).splice(newState.activeTab, 1)
      delete newState.financing_info[newState.activeTab]
      const [removedTab] = newState.proposal_tabs.splice(newState.activeTab, 1)

      newState.tabs_info.splice(action.endIndex, 0, removedTabInfo)

      let newFinancingInfo = Object.values(newState.financing_info)
      newFinancingInfo.splice(action.endIndex, 0, removedFinancingbInfo)
      newState.financing_info = {
        ...newFinancingInfo,
      }

      newState.proposal_tabs.splice(action.endIndex, 0, removedTab)
      newState.activeTab = action.endIndex
      newState.proposal_tabs = newState.proposal_tabs.map(
        setNewPositionsInProposalTab,
      )
      newState.tabs_info = newState.tabs_info.map(setNewPositionsInTabInfo)
      newState.isEdited = true
      return newState
    }

    case types.proposalForm.SET_DEFAULT_SETINGS_IN_PROPOSAL:
      newState.setting.show_tech = newState.show_tech
      newState.setting.show_price = newState.show_price
      newState.setting.show_taxes = newState.show_taxes
      newState.setting.show_information = newState.show_information
      newState.setting.multiple_tabs = newState.multiple_tabs
      newState.setting.disable_tips = newState.disable_tips
      newState.setting.require_signature = newState.require_signature
      newState.setting.financing = newState.financing
      newState.setting.include_tips_to_insight =
        newState.include_tips_to_insight
      newState.setting.show_item_description_only =
        newState.show_item_description_only
      newState.setting.show_discounts = newState.show_discounts
      newState.setting.show_rebates = newState.show_rebates
      newState.setting.show_items_qty = newState.show_items_qty
      newState.isEdited = false

      return newState
    case types.proposalForm.PROPOSAL_DELETE_SECTION:
      break

    case types.proposalForm.PROPOSALS_ADD_SECTION:
      const newSections = newState.proposal_tabs[
        state.activeTab
      ].proposal_sections.map(section => ({
        ...section,
        show: false,
      }))
      // const newSectionPosition = newSections?.length
      newSections.push({ ...action.payload, show: true })
      // newSections.push({
      //   ...initialProposalTabSection,
      //   title: action.title,
      //   position: newSectionPosition,
      // })
      newState.proposal_tabs[state.activeTab].proposal_sections = newSections
      /* newState.proposal_tabs[state.activeTab].proposal_sections = [
        ...newState.proposal_tabs[state.activeTab].proposal_sections,
        {
          ...initialProposalTabSection,
          tempId: Date.now(),
          title: action.title,
          position: newState.proposal_tabs[state.activeTab].proposal_sections?.length,
        },
      ] */
      // newState.isEdited = true
      return newState

    case types.proposalForm.UPDATE_PROJECT_FIELDS:
      return {
        ...newState,
        ...action.payload,
      }

    case types.proposalForm.PROPOSAL_ADD_TAG:
      newState.isEdited = true
      newState.proposal_tabs[state.activeTab].tags = [
        ...newState.proposal_tabs[state.activeTab].tags,
        action.value,
      ]
      return newState
    case types.proposalForm.PROPOSAL_COPY_SECTION:
      const newSection = zeroOutSection(
        state.proposal_tabs[state.activeTab].proposal_sections[action.section],
        0,
      )
      newSection.position =
        newState.proposal_tabs[state.activeTab].proposal_sections?.length
      newSection.isNew = true
      const newSecs = newState.proposal_tabs[
        state.activeTab
      ].proposal_sections.map((section, idx) => ({
        ...section,
        position: idx,
        isNew: false,
      }))
      newSecs.push(newSection)
      newState.proposal_tabs[state.activeTab].proposal_sections = newSecs
      newState.isEdited = true
      return newState
    case types.proposalForm.PROPOSAL_COPY_TAB:
      const { newTab, originalTabIdx } = action.payload

      newState.tabs_info = [
        ...newState.tabs_info,
        {
          id: newTab.id,
          image: newTab.image,
          title: newTab.title,
          position: newTab.position,
          description: newTab.description,
        },
      ]

      newState.financing_info = {
        ...newState.financing_info,
        [newState.tabs_info.length - 1]: {
          tax: newState.financing_info[originalTabIdx].tax,
          fees: newState.financing_info[originalTabIdx].fees,
          rebates: newState.financing_info[originalTabIdx].rebates,
          instants: newState.financing_info[originalTabIdx].instants,
          totalToPay: newState.financing_info[originalTabIdx].totalToPay,
          materialTotal: newState.financing_info[originalTabIdx].materialTotal,
          materialTaxTotal:
            newState.financing_info[originalTabIdx].materialTaxTotal,
          investmentSubtotal:
            newState.financing_info[originalTabIdx].investmentSubtotal,
          totalAfterInstants:
            newState.financing_info[originalTabIdx].totalAfterInstants,
          totalAfterRebatesAndInstants: [
            newState.financing_info[originalTabIdx],
          ].totalAfterRebatesAndInstants,
        },
      }
      /* const newTab = zeroOutTab(state.proposal_tabs[action.tab], 0)
      newTab.position = newState.tabs_info?.length

      const isThereAreSuchTitle = title =>
        state.tabs_info.some(tab => tab.title === title)

      const getNewTitle = newTitleSuffixNumber => {
        if (!newTitleSuffixNumber) newTitleSuffixNumber = 1
        const newTabTitle = `${newTab.title}_${newTitleSuffixNumber}`
        if (isThereAreSuchTitle(newTabTitle))
          return getNewTitle(newTitleSuffixNumber + 1)
        return [newTabTitle, newTitleSuffixNumber]
      }

      let [newTitle, newTitleSuffixNumber] = getNewTitle()
      const newTitleSuffix = `_${newTitleSuffixNumber}`
      if (newTitle?.length > 50)
        newTitle =
          newTab.title.slice(
            0,
            newTab.title?.length - newTitleSuffix?.length - 3,
          ) +
          '...' +
          newTitleSuffix
      newTab.title = newTitle
      newState.proposal_tabs = [...newState.proposal_tabs, newTab] */
      return newState
    case types.proposalForm.PROPOSAL_CHANGE_TAB_INFO:
      // newState.isEdited = true
      newState.proposal_tabs[action.payload.tab].title = action.payload.title
      newState.proposal_tabs[action.payload.tab].description =
        action.payload.description
      newState.proposal_tabs[action.payload.tab].image = action.payload.image
      newState.tabs_info[action.payload.tab].title = action.payload.title
      newState.tabs_info[action.payload.tab].description =
        action.payload.description
      newState.tabs_info[action.payload.tab].image = action.payload.image
      return newState
    case types.proposalForm.PROPOSAL_CHANGE_TAB_OPTIONAL_MODAL:
      // newState.isEdited = true
      if (typeof action.field === 'object') {
        newState.proposal_tabs[action.tab].modules[action.module] = {
          ...newState.proposal_tabs[action.tab].modules[action.module],
          ...action.field,
        }
      } else {
        newState.proposal_tabs[action.tab].modules[action.module][
          action.field
        ] = action.value
      }
      return newState
    case types.proposalForm.PROPOSAL_CLEAR_TAB_OPTIONAL_MODAL:
      newState.isEdited = true
      newState.proposal_tabs[action.tab].modules[action.module].notes = ''
      newState.proposal_tabs[action.tab].modules[action.module].status = 1
      return newState
    case types.proposalForm.PROPOSAL_ADD_TAB:
      //newState.isEdited = true
      newState.proposal_tabs = [
        ...newState.proposal_tabs,
        {
          ...initialProposalTab,
          position: newState.tabs_info?.length,
          title: action.value,
          modules: [
            {
              name:
                action.modulesNamesFromSettings.module_one ||
                defaultProposalModuleNames.module_one,
              notes: '',
              status: 1,
            },
            {
              name:
                action.modulesNamesFromSettings.module_two ||
                defaultProposalModuleNames.module_two,
              notes: '',
              status: 1,
            },
            {
              name:
                action.modulesNamesFromSettings.module_three ||
                defaultProposalModuleNames.module_three,
              notes: '',
              status: 1,
            },
          ],
        },
      ]
      //newState.tabs_info = [...newState.tabs_info, { id: 0, title: action.value }]
      return newState
    case types.proposalForm.SET_TAB_IN_PROPOSAL:
      newState.proposal_tabs[action.idxInTabsList] = action.tab
      newState.proposal_tabs[
        action.idxInTabsList
      ].proposal_sections = newState.proposal_tabs[
        action.idxInTabsList
      ].proposal_sections.map((sec, idx) => {
        sec.show =
          state.proposal_tabs[action.idxInTabsList] &&
          state.proposal_tabs[action.idxInTabsList].proposal_sections &&
          state.proposal_tabs[action.idxInTabsList].proposal_sections[idx]
            ? state.proposal_tabs[action.idxInTabsList].proposal_sections[idx]
                .show
            : true

        // sec.materials = [
        //   ...sec.equipments,
        //   ...sec.supplies,
        //   ...sec.parts,
        // ]?.sort((a, b) => a.position - b.position)

        return sec
      })
      if (action.priority_tab_id)
        newState.priority_tab_id = action.priority_tab_id
      //newState.activeTab = action.idxInTabsList
      return newState
    case types.proposalForm.PROPOSAL_ADD_ITEM_SECTION:
      newState.isEdited = true
      const newOptionId = Date.now()
      const isNewItemGlobal = !!action?.data?.id && !action.data.is_edited
      const newItemSceleton = {
        ...action.data,
        id: newOptionId,
        idForChoosenOption: newOptionId,
        price: action.data.price ? action.data.price : '0',
        files: action.data.files ? action.data.files : [],
        position:
          newState.proposal_tabs[state.activeTab].proposal_sections[
            action.section
          ][action.item_type]?.length,
        obj: action.data,
        attachment_one: action.data.attachment_one
          ? action.data.attachment_one
          : '',
        attachment_two: action.data.attachment_two
          ? action.data.attachment_two
          : '',
        attachment_three: action.data.attachment_three
          ? action.data.attachment_three
          : '',
        options: null,
        is_edited: action.data.is_edited,
        use_calculation: true,
        required_to_pay: true,
        /**
         * PROPOSAL_ADD_ITEM_SECTION
         */
        relation_with_template: {
          type: isNewItemGlobal ? 'global' : 'proposal',
          id: isNewItemGlobal ? action?.data?.id : null,
        },
      }
      // debugger
      let newItem
      switch (action.item_type) {
        // case 'equipments':
        //   case 'parts':
        //     case 'supplies':
        case 'materials':
          newItem = {
            ...newItemSceleton,
            body: {
              type: action.data.type
                ? { name: action.data.type.name }
                : { name: '' },
              brand: action.data.brand
                ? { name: action.data.brand.name }
                : { name: '' },
              model: action.data.model
                ? { name: action.data.model.name }
                : { name: '' },
              size: action.data.size,
              cat5: action.data.cat5,
              cat6: action.data.cat6,
              price: action.data.price ? action.data.price : '0',
              description: action.data.description,
            },
            new: true,
            // ordered: false,
          }
          break
        case 'scope_of_works':
        case 'warranties':
          newItem = newItemSceleton
          break
        case 'instants':
        case 'rebates':
          let expiration
          if (action.data.expiration === 'End of each month') {
            expiration = moment()
              .endOf('month')
              .format('MM/DD/YYYY')
          } else if (action.data.expiration === '15') {
            expiration = moment()
              .date(15)
              .format('MM/DD/YYYY')
          } else if (moment(action.data.expiration, 'MM/DD/YYYY').isValid()) {
            expiration = moment(action.data.expiration, 'MM/DD/YYYY').format(
              'MM/DD/YYYY',
            )
          } else {
            expiration = action.data.expiration
          }
          newItem = {
            ...newItemSceleton,
            expiration,
          }
          break
        default:
          break
      }

      if (action?.item_type === 'materials') {
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.section
        ]['equipments'].push(newItem)
      }

      newState.proposal_tabs[state.activeTab].proposal_sections[action.section][
        action.item_type
      ].push(newItem)
      return newState
    case types.proposalForm.PROPOSAL_CHANGE_ITEM_SECTION:
      if (
        action.item_type === 'parts' ||
        action.item_type === 'supplies' ||
        action.item_type === 'equipments'
      ) {
        //const newData = JSON.parse(JSON.stringify(action.item))
        //delete newData.obj
        // eslint-disable-next-line no-unused-vars
        const { obj, ...rest } = action.item
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.section
        ][action.item_type][action.itemIdx].body = rest
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.section
        ][action.item_type][action.itemIdx].full_name = action.full_name
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.section
        ][action.item_type][action.itemIdx].is_edited = action.is_edited
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.section
        ][action.item_type][action.itemIdx].position = action.itemIdx
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.section
        ][action.item_type][action.itemIdx].obj = rest
        if (action.item.price)
          newState.proposal_tabs[state.activeTab].proposal_sections[
            action.section
          ][action.item_type][action.itemIdx].price = action.item.price
        if (action.item.files)
          newState.proposal_tabs[state.activeTab].proposal_sections[
            action.section
          ][action.item_type][action.itemIdx].files = action.item.files
      } else {
        // eslint-disable-next-line no-unused-vars
        const { obj, ...rest } = action.item
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.section
        ][action.item_type][action.itemIdx] = {
          ...rest,
          obj: rest,
          full_name: action.full_name,
          position: action.itemIdx,
          is_edited: true,
        }
      }
      /**
       * PROPOSAL_CHANGE_ITEM_SECTION
       */
      const isChangedItemGlobal =
        !!action.item.id &&
        !action.is_edited &&
        action.item_type !== 'instants' &&
        action.item_type !== 'rebates'
      newState.proposal_tabs[state.activeTab].proposal_sections[action.section][
        action.item_type
      ][action.itemIdx].relation_with_template = {
        // type: isChangedItemGlobal ? 'global' : 'proposal',
        // id: isChangedItemGlobal ? action.item.id : null,
        type: 'proposal',
        id: isChangedItemGlobal ? action?.data?.id : null,
      }
      // debugger
      newState.isEdited = true
      return newState

    case types.proposalForm.PROPOSAL_DELETE_ITEM_SECTION:
      newState.isEdited = true
      const itemToDelete =
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.section
        ][action.item_type][action.item_num]
      if (!!itemToDelete.id && itemToDelete.id < sessionStartTimeStamp) {
        newState.itemsToDeleteOnSave = [
          ...newState.itemsToDeleteOnSave,
          { id: itemToDelete.id, itemTypeForDelete: action.itemTypeForDelete },
        ]
      }
      newState.proposal_tabs[state.activeTab].proposal_sections[action.section][
        action.item_type
      ].splice(action.item_num, 1)
      newState.proposal_tabs[state.activeTab].proposal_sections[action.section][
        action.item_type
      ] = newState.proposal_tabs[state.activeTab].proposal_sections[
        action.section
      ][action.item_type].map((item, idx) => ({ ...item, position: idx }))

      return newState
    case types.proposalForm.PROPOSAL_SECTION_ITEM_FIELD_CHANGE:
      newState.isEdited = true
      /**
       * PROPOSAL_SECTION_ITEM_FIELD_CHANGE
       */
      newState.proposal_tabs[state.activeTab].proposal_sections[action.section][
        action.item_type
      ][action.item].relation_with_template = {
        type: 'proposal',
        id: null,
      }

      newState.proposal_tabs[state.activeTab].proposal_sections[action.section][
        action.item_type
      ][action.item][action.field] = action.value

      if (action.field === 'required_to_pay' && !!action.value) {
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.section
        ][action.item_type][action.item].use_calculation = true
      }

      return newState

    case types.proposalForm.PROPOSAL_TAB_SET_REORDERED_SECTIONS: {
      newState.proposal_tabs[state.activeTab].proposal_sections =
        action.payload /*  action.sections.map((section, idx) => ({
        ...section,
        position: idx,
        isNew: false,
      })) */
      // newState.isEdited = true
      return newState
    }

    case types.proposalForm.PROPOSAL_TAB_FILE_UPLOAD:
      newState.isEdited = true
      if (!newState.proposal_tabs[state.activeTab].files)
        newState.proposal_tabs[state.activeTab].files = []
      // newState.proposal_tabs[state.activeTab].files = newState.proposal_tabs[state.activeTab].files.concat(action.payload)
      newState.proposal_tabs[state.activeTab].files = action.payload
      return newState
    case types.proposalForm.PROPOSAL_TAB_FILE_DELETE:
      newState.isEdited = true
      if (action.payload.id)
        newState.filesToDelete = [...newState.filesToDelete, action.payload.id]
      newState.proposal_tabs[state.activeTab].files.splice(
        action.payload.index,
        1,
      )
      return newState
    case types.proposalForm.PROPOSAL_ON_DELETE_SECTION:
      // newState.isEdited = true

      const { sectionIdx, sectionId } = action.payload

      newState.sectionToDeleteOnSave =
        sectionId !== 0 && newState.sectionToDeleteOnSave?.concat(sectionId)
      if (
        newState.proposal_tabs[state.activeTab].proposal_sections?.length === 1
      ) {
        newState.proposal_tabs[state.activeTab].proposal_sections[0] = {
          ...initialProposalTabSection,
          tempId: Date.now(),
          isFirst: true,
        }
      } else {
        newState.proposal_tabs[state.activeTab].proposal_sections.splice(
          sectionIdx,
          1,
        )
      }
      newState.proposal_tabs[
        state.activeTab
      ].proposal_sections = newState.proposal_tabs[
        state.activeTab
      ].proposal_sections.map((section, idx) => ({
        ...section,
        position: idx,
        isNew: false,
      }))
      return newState
    case types.proposalForm.PROPOSAL_ON_DELETE_TAB:
      //newState.isEdited = true
      newState.proposal_tabs.splice(action.index, 1)
      newState.tabs_info.splice(action.index, 1)
      return newState
    case types.proposalForm.PROPOSAL_ON_DELETE_TAG:
      newState.isEdited = true
      newState.proposal_tabs[state.activeTab].tags.splice(action.index, 1)
      return newState
    case types.proposalForm.SECTION_ITEM_FILE_UPLOAD:
    case types.proposalForm.SECTION_ITEM_OPTION_FILE_UPLOAD: {
      newState.isEdited = true
      let newFiles =
        action.optionIdx === undefined
          ? newState.proposal_tabs[state.activeTab].proposal_sections[
              action.section
            ][action.item_type][action.item_num].files
          : newState.proposal_tabs[state.activeTab].proposal_sections[
              action.section
            ][action.item_type][action.item_num].options.data[action.optionIdx]
              .files
      if (!Array.isArray(newFiles)) newFiles = []
      else newFiles = newFiles.filter(file => !!file)
      // newFiles = [...newFiles, ...action.payload]
      newFiles = action.payload
      if (action.optionIdx === undefined) {
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.section
        ][action.item_type][action.item_num].files = newFiles
        const files =
          newState.proposal_tabs[state.activeTab].proposal_sections[
            action.section
          ][action.item_type][action.item_num].files
        ;[0, 1, 2].forEach?.(idx => {
          const field = getAttachmentFieldNameByIdx(idx)
          newState.proposal_tabs[state.activeTab].proposal_sections[
            action.section
          ][action.item_type][action.item_num][field] = files[idx]
            ? files[idx]
            : ''
        })
      } else {
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.section
        ][action.item_type][action.item_num].options.data[
          action.optionIdx
        ].files = newFiles
        const files =
          newState.proposal_tabs[state.activeTab].proposal_sections[
            action.section
          ][action.item_type][action.item_num].options.data[action.optionIdx]
            .files
        ;[0, 1, 2].forEach?.(idx => {
          const field = getAttachmentFieldNameByIdx(idx)
          newState.proposal_tabs[state.activeTab].proposal_sections[
            action.section
          ][action.item_type][action.item_num].options.data[action.optionIdx][
            field
          ] = files[idx] ? files[idx] : ''
        })
      }
      return newState
    }
    case types.proposalForm.SECTION_ITEM_FILE_DELETE:
    case types.proposalForm.SECTION_ITEM_OPTION_FILE_DELETE: {
      newState.isEdited = true
      if (action.optionIdx === undefined) {
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.section
        ][action.item_type][action.item_num].files.splice(action.index, 1)
        const files =
          newState.proposal_tabs[state.activeTab].proposal_sections[
            action.section
          ][action.item_type][action.item_num].files
        ;[0, 1, 2].forEach?.(idx => {
          const field = getAttachmentFieldNameByIdx(idx)
          newState.proposal_tabs[state.activeTab].proposal_sections[
            action.section
          ][action.item_type][action.item_num][field] = files[idx]
            ? files[idx]
            : ''
        })
      } else {
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.section
        ][action.item_type][action.item_num].options.data[
          action.optionIdx
        ].files.splice(action.index, 1)
        const files =
          newState.proposal_tabs[state.activeTab].proposal_sections[
            action.section
          ][action.item_type][action.item_num].options.data[action.optionIdx]
            .files
        ;[0, 1, 2].forEach?.(idx => {
          const field = getAttachmentFieldNameByIdx(idx)
          newState.proposal_tabs[state.activeTab].proposal_sections[
            action.section
          ][action.item_type][action.item_num].options.data[action.optionIdx][
            field
          ] = files[idx] ? files[idx] : ''
        })
      }
      return newState
    }
    case types.proposalForm.PROPOSAL_FORM_FILE_DELETE:
      newState.isEdited = true
      if (action.payload.id)
        newState.filesToDelete = [...newState.filesToDelete, action.payload.id]
      newState.files.splice(action.payload.index, 1)
      return newState
    case types.proposalForm.PROPOSAL_CUSTOMER_FILE_DELETE:
      newState.isEdited = true
      if (action.payload.id)
        newState.filesToDelete = [...newState.filesToDelete, action.payload.id]
      newState.files_from_customer.splice(action.payload.index, 1)
      return newState
    case types.proposalForm.PROPOSAL_FORM_FILE_UPLOAD:
      newState.isEdited = true
      newState.files = action.payload
      return newState
    case types.proposalForm.PROPOSAL_CUSTOMER_FILE_UPLOAD:
      newState.isEdited = true
      newState.files_from_customer = action.payload
      return newState
    case types.proposalForm.SET_REORDERED_ITEMS:
      newState.proposal_tabs[state.activeTab].proposal_sections[action.section][
        action.item_type
      ] = action.items.map((item, idx) => ({ ...item, position: idx }))
      newState.isEdited = true
      return newState
    case types.proposalForm.PROPOSAL_GET_RELATIVES:
      newState.relatives = action.value
      return newState
    case types.proposalTable.PROPOSAL_SET_TEMPLATE:
      return {
        ...state,
        // ...action.data,
        // proposal_tabs: [
        //   {
        //     ...action.data.proposal_tabs?.[0],
        //     proposal_sections: action.data?.proposal_tabs?.[0]?.proposal_sections?.map(
        //       section => ({
        //         ...section,
        //         show: true,
        //       }),
        //     ),
        //   },
        // ],
        // isEdited: true,
        tempalteSet: true,
      }
    //////OPTIONS/////
    case types.proposalForm.PROPOSAL_ADD_OPTION_TO_ITEM: {
      const newOptionId = Date.now()
      const isNewOptionGlobal = !!action?.data?.id && !action.data.is_edited
      let newOption = {
        ...action.data,
        id: newOptionId,
        idForChoosenOption: newOptionId,
        price: action.data.price ? action.data.price : '0',
        files: action.data.files ? action.data.files : [],
        position:
          newState.proposal_tabs[newState.activeTab].proposal_sections[
            action.sectionIdx
          ][action.itemsType][action.itemIdx].options.data?.length,
        obj: action.data,
        attachment_one: action.data.attachment_one
          ? action.data.attachment_one
          : '',
        attachment_two: action.data.attachment_two
          ? action.data.attachment_two
          : '',
        attachment_three: action.data.attachment_three
          ? action.data.attachment_three
          : '',
        options: null,
        is_edited: action.data.is_edited,
        use_calculation: true,
        /**
         * PROPOSAL_ADD_OPTION_TO_ITEM
         */
        relation_with_template: {
          type: isNewOptionGlobal ? 'global' : 'proposal',
          id: isNewOptionGlobal ? action?.data?.id : null,
        },
      }
      // debugger
      switch (action.itemsType) {
        // case 'equipments':
        //   case 'parts':
        //     case 'supplies':
        case 'materials':
          newOption = {
            ...newOption,
            //qty: action.data.qty ? action.data.qty : '1',
            body: {
              type: action.data.type
                ? { name: action.data.type.name }
                : { name: '' },
              brand: action.data.brand
                ? { name: action.data.brand.name }
                : { name: '' },
              model: action.data.model
                ? { name: action.data.model.name }
                : { name: '' },
              size: action.data.size,
              cat5: action.data.cat5,
              cat6: action.data.cat6,
              price: action.data.price ? action.data.price : '0',
              //isString: action.data.isString,
              description: action.data.description,
            },
            //full_name: action.data.full_name,
            //is_edited: action.data.is_edited,
            ordered: false,
          }
          break
        case 'scope_of_works':
        case 'warranties':
          break
        case 'instants':
        case 'rebates':
          let exactTime
          if (action.data.expiration === 'End of each month') {
            exactTime = moment()
              .endOf('month')
              .format('MM/DD/YYYY')
          } else if (action.data.expiration === '15') {
            exactTime = moment()
              .date(15)
              .format('MM/DD/YYYY')
          } else if (moment(action.data.expiration, 'MM/DD/YYYY').isValid()) {
            exactTime = moment(action.data.expiration, 'MM/DD/YYYY').format(
              'MM/DD/YYYY',
            )
          } else {
            exactTime = action.data.expiration
          }
          newOption = {
            ...newOption,
            //description: action.data.description ? action.data.description : '',
            expiration: exactTime,
          }
          break
        default:
          break
      }
      newState.proposal_tabs[newState.activeTab].proposal_sections[
        action.sectionIdx
      ][action.itemsType][action.itemIdx].options.data.push(newOption)
      newState.isEdited = true
      return newState
    }
    case types.proposalForm.PROPOSAL_CLOSE_OPTIONS_MODE_IN_ITEM: {
      newState.proposal_tabs[newState.activeTab].proposal_sections[
        action.sectionIdx
      ][action.itemsType][action.itemIdx].options = null
      // newState.proposal_tabs[newState.activeTab].proposal_sections[action.sectionIdx][action.itemsType][
      //   action.itemIdx
      // ].use_calculation = true
      newState.isEdited = true
      return newState
    }
    case types.proposalForm.PROPOSAL_CHANGE_OPTION_IN_ITEM: {
      if (
        action.itemsType === 'equipments' ||
        action.itemsType === 'parts' ||
        action.itemsType === 'supplies'
      ) {
        // eslint-disable-next-line no-unused-vars
        const { obj, ...rest } = action.data
        //const newData = JSON.parse(JSON.stringify(action.data))
        //delete newData.obj
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.sectionIdx
        ][action.itemsType][action.itemIdx].options.data[
          action.optionIdx
        ].body = rest
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.sectionIdx
        ][action.itemsType][action.itemIdx].options.data[
          action.optionIdx
        ].full_name = action.full_name
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.sectionIdx
        ][action.itemsType][action.itemIdx].options.data[
          action.optionIdx
        ].is_edited = action.is_edited
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.sectionIdx
        ][action.itemsType][action.itemIdx].options.data[
          action.optionIdx
        ].obj = rest
        if (action.data.price)
          newState.proposal_tabs[state.activeTab].proposal_sections[
            action.sectionIdx
          ][action.itemsType][action.itemIdx].options.data[
            action.optionIdx
          ].price = action.data.price
        if (action.data.files)
          newState.proposal_tabs[state.activeTab].proposal_sections[
            action.sectionIdx
          ][action.itemsType][action.itemIdx].options.data[
            action.optionIdx
          ].files = action.data.files
      } else {
        // eslint-disable-next-line no-unused-vars
        const { obj, ...rest } = action.data
        //const newData = JSON.parse(JSON.stringify(action.data))
        //delete newData.obj
        newState.proposal_tabs[state.activeTab].proposal_sections[
          action.sectionIdx
        ][action.itemsType][action.itemIdx].options.data[action.optionIdx] = {
          ...rest,
          obj: rest,
          full_name: action.full_name,
          is_edited: true,
          position: action.optionIdx,
        }
      }
      /**
       * PROPOSAL_CHANGE_OPTION_IN_ITEM
       */
      const isChangedItemGlobal =
        !!action?.data?.id &&
        !action.is_edited &&
        action.itemsType !== 'instants' &&
        action.itemsType !== 'rebates'
      newState.proposal_tabs[state.activeTab].proposal_sections[
        action.sectionIdx
      ][action.itemsType][action.itemIdx].options.data[
        action.optionIdx
      ].relation_with_template = {
        // type: isChangedItemGlobal ? 'global' : 'proposal',
        // id: isChangedItemGlobal ? action.data.id : null,
        type: 'proposal',
        id: null,
      }
      // debugger
      const options =
        newState.proposal_tabs[newState.activeTab].proposal_sections[
          action.sectionIdx
        ][action.itemsType][action.itemIdx].options
      const isChoosenOption =
        options.data[action.optionIdx].idForChoosenOption ===
        options.checked_option
      if (isChoosenOption) {
        newState.proposal_tabs[newState.activeTab].proposal_sections[
          action.sectionIdx
        ][action.itemsType][action.itemIdx] = {
          ...options.data[action.optionIdx],
          id:
            newState.proposal_tabs[newState.activeTab].proposal_sections[
              action.sectionIdx
            ][action.itemsType][action.itemIdx].id,
          position:
            newState.proposal_tabs[newState.activeTab].proposal_sections[
              action.sectionIdx
            ][action.itemsType][action.itemIdx].position,
          options,
        }
      }
      newState.isEdited = true
      return newState
    }
    case types.proposalForm.PROPOSAL_OPTION_FIELD_CHANGE: {
      newState.proposal_tabs[newState.activeTab].proposal_sections[
        action.sectionIdx
      ][action.itemsType][action.itemIdx].options.data[action.optionIdx][
        action.field
      ] = action.value
      const options =
        newState.proposal_tabs[newState.activeTab].proposal_sections[
          action.sectionIdx
        ][action.itemsType][action.itemIdx].options
      const isChoosenOption =
        options.data[action.optionIdx].idForChoosenOption ===
        options.checked_option
      if (isChoosenOption) {
        newState.proposal_tabs[newState.activeTab].proposal_sections[
          action.sectionIdx
        ][action.itemsType][action.itemIdx][action.field] = action.value
      }
      newState.isEdited = true
      return newState
    }
    case types.proposalForm.PROPOSAL_DELETE_OPTION: {
      let options =
        newState.proposal_tabs[newState.activeTab].proposal_sections[
          action.sectionIdx
        ][action.itemsType][action.itemIdx].options
      const optionsQuantity = options.data?.length
      if (optionsQuantity === 1) {
        newState.proposal_tabs[newState.activeTab].proposal_sections[
          action.sectionIdx
        ][action.itemsType][action.itemIdx].options = null
      } else {
        const isChoosenOption =
          options.data[action.optionIdx].idForChoosenOption ===
          options.checked_option
        if (isChoosenOption) {
          const nextIdx =
            action.optionIdx === optionsQuantity - 1
              ? action.optionIdx - 1
              : action.optionIdx + 1 //for set choosenOption if current choosen was deleted
          options.checked_option = options.data[nextIdx].idForChoosenOption
          const newMainItem = {
            ...options.data[nextIdx],
            id:
              newState.proposal_tabs[newState.activeTab].proposal_sections[
                action.sectionIdx
              ][action.itemsType][action.itemIdx].id,
            options,
            idForChoosenOption: 'has been choosen from options',
          }
          newMainItem.options.data.splice(action.optionIdx, 1)
          newState.proposal_tabs[newState.activeTab].proposal_sections[
            action.sectionIdx
          ][action.itemsType][action.itemIdx] = newMainItem
        } else {
          newState.proposal_tabs[newState.activeTab].proposal_sections[
            action.sectionIdx
          ][action.itemsType][action.itemIdx].options.data.splice(
            action.optionIdx,
            1,
          )
        }
        /* if (
          newState.proposal_tabs[newState.activeTab].proposal_sections[action.sectionIdx][action.itemsType][
            action.itemIdx
          ].options.data?.length === 1
        ) {
          newState.proposal_tabs[newState.activeTab].proposal_sections[action.sectionIdx][action.itemsType][
            action.itemIdx
          ].options = null
        } */
      }
      newState.isEdited = true
      return newState
    }
    case types.proposalForm.PROPOSAL_SET_REORDERED_OPTIONS: {
      newState.proposal_tabs[newState.activeTab].proposal_sections[
        action.sectionIdx
      ][action.itemsType][action.itemIdx].options.data = action.options.map(
        (option, idx) => {
          option.position = idx
          return option
        },
      )
      newState.isEdited = true
      return newState
    }
    case types.proposalForm.PROPOSAL_INIT_OPTIONS_IN_ITEM: {
      const currentItem =
        newState.proposal_tabs[newState.activeTab].proposal_sections[
          action.sectionIdx
        ][action.itemsType][action.itemIdx]
      const idForChoosenOption = Date.now()
      currentItem.options = {
        checked_option: idForChoosenOption,
        data: [
          { ...currentItem, position: 0, options: null, idForChoosenOption },
        ],
      }
      currentItem.use_calculation = true
      newState.proposal_tabs[newState.activeTab].proposal_sections[
        action.sectionIdx
      ][action.itemsType][action.itemIdx] = currentItem
      newState.isEdited = true
      return newState
    }
    case types.proposalForm.PROPOSAL_CHOOSE_OPTION: {
      newState.proposal_tabs[newState.activeTab].proposal_sections[
        action.sectionIdx
      ][action.itemsType][action.itemIdx].options.checked_option =
        action.idForChoosenOption
      //set choosen option as main item and save current options in it
      newState.proposal_tabs[newState.activeTab].proposal_sections[
        action.sectionIdx
      ][action.itemsType][action.itemIdx] = {
        ...newState.proposal_tabs[newState.activeTab].proposal_sections[
          action.sectionIdx
        ][action.itemsType][action.itemIdx].options.data[action.optionIdx],
        options:
          newState.proposal_tabs[newState.activeTab].proposal_sections[
            action.sectionIdx
          ][action.itemsType][action.itemIdx].options,
        position:
          newState.proposal_tabs[newState.activeTab].proposal_sections[
            action.sectionIdx
          ][action.itemsType][action.itemIdx].position,
        id:
          newState.proposal_tabs[newState.activeTab].proposal_sections[
            action.sectionIdx
          ][action.itemsType][action.itemIdx].id,
        material_type:
          newState.proposal_tabs[newState.activeTab].proposal_sections[
            action.sectionIdx
          ][action.itemsType][action.itemIdx].material_type,
        idForChoosenOption: 'has been choosen from options',
      }
      newState.isEdited = true
      return newState
    }
    //////OPTIONS/////
    ////CHECK FOR NOT COMPLETED NEW ITEMS///
    case types.proposalForm.PROPOSAL_ADD_UNCOMPLETED_NEW_ITEM_ID: {
      newState.uncompletedNewItemsIds.push(action.uncompletedNewItemId)
      newState.uncompletedNewItemsIds = [
        ...new Set(newState.uncompletedNewItemsIds),
      ]
      return newState
    }
    case types.proposalForm.PROPOSAL_DELETE_UNCOMPLETED_NEW_ITEM_ID: {
      const el = document.getElementById(action.uncompletedNewItemId)
      newState.uncompletedNewItemsIds = newState.uncompletedNewItemsIds.filter(
        id => id !== action.uncompletedNewItemId,
      )
      if (
        newState.uncompletedNewItemsIds?.length &&
        el.classList.contains('uncompleted')
      ) {
        scrollToFirstUncompletedNewItem(newState.uncompletedNewItemsIds)
        el.classList.remove('uncompleted')
      }
      return newState
    }
    case types.proposalForm.PROPOSAL_RESET_UNCOMPLETED_NEW_ITEM_ID: {
      newState.uncompletedNewItemsIds.forEach?.(id => {
        const el = document.getElementById(id)
        el.classList.remove('uncompleted')
      })
      newState.uncompletedNewItemsIds = []
      return newState
    }
    ////CHECK FOR NOT COMPLETED NEW ITEMS///

    /// TAXES SECTION
    case types.proposalForm.PROPOSAL_SET_TAXES:
      newState.proposal_tabs[newState.activeTab].tax = action.payload
      if (newState.tempalteSet) newState.tempalteSet = false
      return newState
    case types.proposalForm.PROPOSAL_SET_FEE:
      newState.isEdited = true
      newState.proposal_tabs[newState.activeTab].fees = action.payload
      return newState
    case types.proposalForm.PROPOSAL_SET_FEE_TO_DELETE:
      newState.proposal_tabs[newState.activeTab].feeIds = action.payload
      return newState
    case types.proposalForm.PROPOSAL_SET_EDITED:
      newState.isEdited = action.payload
      return newState
    case types.proposalForm.PROPOSAL_SET_TOTAL_AFTER_TAX:
      newState.proposal_tabs[newState.activeTab].total_to_pay = action.payload
      return newState
    /// TAXES SECTION

    //CANCELLATION FEES
    case types.proposalForm.PROPOSALS_SET_CANCELLATION_FEES:
      newState.isEdited = true
      newState.proposal_tabs[newState.activeTab].cancellation_fees =
        action.payload
      newState.proposal_tabs[newState.activeTab].total_to_pay = action.payload
      return newState
    //CANCELLATION FEES

    case types.proposalForm.PROPOSAL_SET_INDUSTRY:
      newState.isEdited = true
      newState.setting = action.payload
      if (!newState.proposal_tabs[newState.activeTab].tax) {
        newState.proposal_tabs[newState.activeTab].tax = {
          id: 0,
          total: 0,
          name: 'Tax',
          type: 'no-tax',
        }
      }
      newState.proposal_tabs[newState.activeTab].tax.rate =
        action.payload.default_rate
      newState.tempalteSet = true
      newState.industry_id = action.payload.industry_id
      newState.industry_name = action.payload.name
      return newState

    case types.proposalForm.RESET_PROPOSAL_FORM:
      return initialState
    case types.proposalForm.PROPOSAL_RATE_BY_ZIP:
      newState.rate_by_zip = action.payload
      return newState

    case types.proposalForm.RESET_PROJECT_SIGNATURE:
      newState.signature = null
      newState.signatory_name = null
      newState.signature_date = null

      return newState
    default:
      return state
  }
}

//Selectors

// export const selectProjectSettings = state =>
//   state.orcatec?.proposalForm?.setting
