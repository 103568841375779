import * as types from '../types'
import * as feedback from '../../../api/ClientFeedbacks'

export const setItem = response => {
  return {
    type: types.feedback.GET_FEEDBACK,
    response,
  }
}

export const getClientFeedback = (id, type) => dispatch => {
  return feedback
    .getClientFeedback(id, type)
    .then(response => {
      dispatch(setItem(response))
      return response
    })
    .catch(err => {
      console.dir(err)
    })
}

export const createClientFeedback = (id, data) => dispatch => {
  return feedback.createClientFeedback(id, data).catch(err => {
    console.dir(err)
  })
}

export const updateClientFeedback = (id, data) => dispatch => {
  return feedback.updateClientFeedback(id, data).catch(err => {
    console.dir(err)
  })
}

export const deleteClientFeedback = id => dispatch => {
  return feedback.deleteClientFeedback(id).catch(err => {
    console.dir(err)
  })
}
