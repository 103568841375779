import { DragIndicator } from '@material-ui/icons'
import { Button, Tooltip } from 'antd'
import { ProjectItem } from 'features/Project/types'
import { Children, cloneElement, isValidElement } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import styled from 'styled-components'

interface Props {
  disabled: boolean
  children: React.ReactNode
  provided: DraggableProvided
  optionGroupId: number | null
  options: ProjectItem[] | null
  onAddOption: () => void
  onOptionsReorder: (result: DropResult, options: ProjectItem[]) => void
}

export const OptionGroup = ({
  children,
  provided,
  disabled,
  optionGroupId,
  options,
  onAddOption,
  onOptionsReorder,
}: Props) => {
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !options || !optionGroupId) {
      return
    }

    onOptionsReorder(result, options)
  }

  return (
    <Wrapper ref={provided?.innerRef} {...provided?.draggableProps}>
      <span
        {...provided.dragHandleProps}
        style={{ position: 'relative', left: -16 }}
      >
        <Tooltip title={disabled ? null : 'Move'} mouseLeaveDelay={0}>
          <DragIndicator
            style={{
              cursor: disabled ? 'not-allowed' : 'grab',
              color: '#aaa',
            }}
          />
        </Tooltip>
      </span>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='optionGroup'>
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {Children.map(children, (child, index) => {
                if (!isValidElement(child)) return null

                return (
                  <Draggable
                    key={child.props.id + index.toString()}
                    draggableId={child.props.id + index.toString()}
                    index={index}
                    isDragDisabled={disabled}
                  >
                    {provided => (
                      <>
                        {cloneElement(child, {
                          ...child.props,
                          provided,
                        })}
                      </>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {!disabled && (
        <Button style={{ margin: '10px 0px 10px 30px' }} onClick={onAddOption}>
          + Add Option
        </Button>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  margin: 5px 0;
  padding-left: 20px;
`
