export const highlightMatches = (text, keyword) => {
  if (!text || !keyword) return text

  const regex = new RegExp(keyword, 'gi')
  return text.replace(
    regex,
    match =>
      `<mark style="background-color: #c2d3f2;border-radius:2px;">${match}</mark>`,
  )
}
