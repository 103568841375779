import TabInfo from 'containers/MainContent/Orcatec/Proposals/components/Form/Tabs/TabInfo'
import TabsContent from 'containers/MainContent/Orcatec/Proposals/components/Form/TabsContent/TabsContent'
import ProposalTabs from 'containers/MainContent/Orcatec/Proposals/components/ProposalTabs'
import SureModal from 'containers/MainContent/Orcatec/components/SureModal'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import {
  selectProjectSettings,
  selectProjectTabsSlice,
} from 'features/Project/projectSelectors'
import {
  getProject,
  reorderProjectTabs,
  selectProjectSlice,
  updateProjectField,
} from 'features/Project/slices/projectSlice'
import {
  addProjectTab,
  copyProjectTab,
  deleteProjectTab,
} from 'features/Project/slices/projectTabsSlice'
import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'

export const ProjectContent = () => {
  const dispatch = useAppDispatch()

  const { project, editContract: isEditContract } = useAppSelector(
    selectProjectSlice,
  )
  const { projectTab: currentTab, status: tabStatus } = useAppSelector(
    selectProjectTabsSlice,
  )
  const isVisiblePrice = useAppSelector(selectIsPriceVisible(project?.user_id))
  const projectSettings = useAppSelector(selectProjectSettings)
  const payments = useAppSelector(state => state.orcatec.modalPayment.payments)

  const [loading, setLoading] = useState(false)
  const [showTabInfoModal, setShowTabInfoModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  // useEffect(() => {
  //   if (project.id && currentTab?.id) {
  //     dispatch(fetchPaymentsList(project?.id, 1, currentTab?.id))
  //   }
  // }, [currentTab?.id])

  const handleAddNewTab = async () => {
    await dispatch(addProjectTab(project?.id))
  }

  const onChangeTab = async (_: number, tabId: number) => {
    dispatch(getProject({ projectId: project.id, tabId }))
  }

  const handleChangePriorityTab = (id: number) =>
    dispatch(updateProjectField({ priority_tab_id: id }))

  const handleEditTabInfo = () => setShowTabInfoModal(true)

  const onCopyTab = async () => {
    await dispatch(
      copyProjectTab({
        projectId: project.id,
        tabId: currentTab.id,
      }),
    )
  }

  const onTabDelete = () => {
    setShowDeleteModal(true)
  }

  const handleTabDelete = async () => {
    setLoading(true)
    await dispatch(
      deleteProjectTab({
        projectId: project.id,
        tabId: currentTab.id,
      }),
    )
    setShowDeleteModal(false)
    setLoading(false)
  }

  const handleTabPositionChange = (endIndex: number, startIndex: number) => {
    dispatch(
      reorderProjectTabs({ list: project?.tabs_info, startIndex, endIndex }),
    )
  }

  return (
    <Wrapper>
      <ProposalTabs
        activeTabIdx={project?.activeTab}
        canShowMultipleTabsFromSettings={!!projectSettings?.multiple_tabs}
        currentTabId={currentTab.id}
        hidePrices={!isVisiblePrice}
        isLoading={tabStatus === 'loading'}
        onAddNewTab={handleAddNewTab}
        onChangePriorityTab={handleChangePriorityTab}
        onChangeTab={onChangeTab}
        priorityTabId={project?.priority_tab_id}
        proposal={project}
      />

      <TabsContent
        isTabLoading={tabStatus === 'loading'}
        isTabCopying={tabStatus === 'loading'}
        isTabDeleting={tabStatus === 'loading'}
        currentTab={currentTab}
        payments={payments}
        proposalStatus={project?.status}
        contractDate={project?.contract_date}
        onEditTabInfo={handleEditTabInfo}
        onCopyTab={onCopyTab}
        onDeleteTab={onTabDelete}
        isEditContract={isEditContract}
        onTabPositionChange={handleTabPositionChange}
        // onPayBalance={onPayBalance}
        // jobCreated={jobCreated}
      />

      {showTabInfoModal && (
        <Modal
          title='Edit tab info'
          visible
          footer={null}
          onCancel={() => setShowTabInfoModal(false)}
          width={500}
        >
          <TabInfo
            tabInfo={project?.tabs_info[project?.activeTab]}
            onCancel={() => setShowTabInfoModal(false)}
            onSave={() => setShowTabInfoModal(false)}
          />
        </Modal>
      )}

      {showDeleteModal && (
        <SureModal
          modal
          close={() => setShowDeleteModal(false)}
          apply={handleTabDelete}
          isLoading={loading}
          dialogClassName='tab-delete-modal'
          body={
            <div className='w-100 d-flex justify-content-center align-items-center'>
              <span style={{ fontSize: '21px', marginBottom: '1rem' }}>
                Are you sure you want to delete this tab?
              </span>
            </div>
          }
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
