import React from 'react'

import momemt from 'moment-timezone'
import { MenuItem, TextField } from '@material-ui/core'

import {
  types as ticketTypes,
  statuses as ticketStatuses,
  RESOLVED_STATUS,
  RE_OPEN_STATUS,
} from '../../../../../../helpers/supportTickets'

const Header = ({ ticket, onReopen }) => {
  return (
    <div className='ticket__header'>
      <div className='ticket-header__col'>
        <div className='ticket-header-col__cell'>
          <div className='ticket-header-cell__key'>Subject:</div>
          <div className='ticket-header-cell__value'>{ticket.subject}</div>
        </div>
        <div className='ticket-header-col__cell'>
          <div className='ticket-header-cell__key'>ID:</div>
          <div className='ticket-header-cell__value'>{ticket.number}</div>
        </div>
      </div>
      <div className='ticket-header__col'>
        <div className='ticket-header-col__cell'>
          <div className='ticket-header-cell__key'>Type:</div>
          <div className='ticket-header-cell__value'>{ticketTypes[ticket.type]}</div>
        </div>
        <div className='ticket-header-col__cell'>
          <div className='ticket-header-cell__key'>Status:</div>
          <div className='ticket-header-cell__value'>
            {ticket.status !== RESOLVED_STATUS ? (
              ticketStatuses[ticket.status]
            ) : (
              <TextField
                type='text'
                select
                label='Status'
                name='status'
                defaultValue={ticket.status}
                onChange={onReopen}
                variant='outlined'
                fullWidth={true}
                size='small'
                style={{
                  top: '-12px',
                }}
              >
                <MenuItem value={ticket.status}>{ticketStatuses[ticket.status]}</MenuItem>
                <MenuItem value={RE_OPEN_STATUS}>{ticketStatuses[RE_OPEN_STATUS]}</MenuItem>
              </TextField>
            )}
          </div>
        </div>
      </div>
      <div className='ticket-header__col'>
        <div className='ticket-header-col__cell'>
          <div className='ticket-header-cell__key'>Date sent:</div>
          <div className='ticket-header-cell__value'>
            <div className='two-rows-date__date'>{momemt(ticket.created_at, 'X').format('DD/MM/YYYY')}</div>
            <div className='two-rows-date__time'>{momemt(ticket.created_at, 'X').format('h:mma')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
