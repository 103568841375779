import axiosOrcatec from './axiosInstance'
import { getQueryStringFromObject } from '../helpers/getQueryStringFromObject'

/**
 *
 * @return {*}
 */
export const forgotPassword = email => {
  return axiosOrcatec.post(`/forgot-password?email=${window.encodeURIComponent(email)}`).then(response => response.data)
}
export const createPassword = data => {
  return axiosOrcatec.put(`/users/create-password`, data).then(response => response.data)
}
export const resetPassword = data => {
  // const params = getQueryStringFromObject(props)

  return axiosOrcatec.post(`/reset-password`, data).then(response => response.data)
}

export const refreshToken = () => {
  return axiosOrcatec.post(`/refresh`)
}

export const validateResetPasswordToken = data => {
  return axiosOrcatec.post(`/reset-password/validate-token`, data)
}

export const resentMessage = (email, password) => {
  //return axiosOrcatec.post(`/resentMessage?email=${email}&password=${password}`).then(response => response.data)
  return axiosOrcatec.post(`/resentMessage`, { email, password }).then(response => response.data)
}
