import * as yup from 'yup'

const phoneRegExp = /^[(][0-9]{3}[)][\s][0-9]{3}[-][0-9]{4}$/

export const validationSchema = [
  yup.object({
    company_name: yup
      .string()
      .required('Please enter the company name')
      .min(2, 'Company name length should be at least 2 characters')
      .max(200, 'Company name cannot exceed more than 200 characters'),
    industry: yup.array().min(1, 'Please select main industry'),
    // .nullable(),
    // .required('Please select main industry'),
    /*  manual_industry: yup.string().when('industry', {
      is: (industry: string) => industry === 'Other',
      then: yup
        .string()
        .required('Please enter the main industry')
        .min(2, 'Industry length should be at least 2 characters')
        .max(200, 'Industry cannot exceed more than 200 characters'),
    }), */

    company_phone: yup.string().matches(phoneRegExp, {
      message: 'Phone must match: (000) 000-0000',
      excludeEmptyString: true,
    }),
    company_email: yup.string().email('Invalid email address'),
    address: yup
      .object()
      .shape({
        address: yup.string().required(),
        zip: yup.string().required(),
      })
      .default(undefined)
      .required('Please enter the address'),
  }),

  yup.object({
    full_name: yup
      .string()
      .required('Full name is required')
      .min(2, 'Full name length should be at least 2 characters')
      .max(200, 'Full name cannot exceed more than 200 characters'),
    title: yup.string().required('Title is required'),
    phone: yup
      .string()
      .nullable()
      .matches(phoneRegExp, 'Phone must match: (000) 000-0000')
      .label('Phone'),
  }),
]
