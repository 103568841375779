import moment from 'moment-timezone'

export interface MembershipLabel {
  color: string
  deleted_at?: string
  discount: number
  discount_type: MembershipDiscountType
  end_date?: string
  id?: number | string
  name: string
  status: MembershipStatus
  start_date?: string
}

export interface MembershipRelation {
  label_id: string | null
  assign_children: boolean
  start_date: string | moment.Moment
  end_date: string | moment.Moment
}

export enum MembershipDiscountType {
  Amount = 1,
  Percent,
}

export enum MembershipStatus {
  Active = 1,
  Expires,
  Expired,
}

export type MembershipEntityType = 'properties' | 'clients' | 'projects'

export enum MembershipEntityTypeId {
  Property = 1,
  Client,
  Organization,
}
