import { Job, JobsListItem } from 'types/Jobs'
import axiosOrcatec from './axiosInstance'
import { apiV2Config } from './Property'

export const getJob = (id: number) =>
  axiosOrcatec.get<Job>(`/jobs/${id}`).then(({ data }) => data)

export const getJobs = params =>
  axiosOrcatec
    .get<Job[]>(`/jobs`, { params })
    .then(({ data }) => data)

interface JobsListResponse {
  data: JobsListItem[]
  meta: {
    page: number
    per_page: number
    total: number
  }
}

export const getJobsList = params =>
  axiosOrcatec
    .get<JobsListResponse>(`/jobs/list`, { params })
    .then(({ data }) => data)

export const createJob = (data: Job) =>
  axiosOrcatec.post('/jobs', data, apiV2Config).then(({ data }) => data)

export const createRecurringJobChild = (data: { parent_id: number }) => {
  return axiosOrcatec
    .post(
      'jobs/create-recurring-child',
      data,
      // apiV2Config,
    )
    .then(response => response.data)
}

export const createProposalFromJob = (jobId: number) =>
  axiosOrcatec.post(`/jobs/${jobId}/create-proposal`).then(({ data }) => data)

export const updateJob = (id: number, data: Job) =>
  axiosOrcatec.put(`/jobs/${id}`, data, apiV2Config).then(({ data }) => data)

export const deleteJob = (id: number) =>
  axiosOrcatec.delete(`/jobs/${id}`).then(({ data }) => data)

export const duplicateJob = (id: number) =>
  axiosOrcatec.get(`/jobs/copy/${id}`).then(({ data }) => data)

export default {
  getJob,
  getJobs,
  getJobsList,
  createJob,
  createProposalFromJob,
  updateJob,
  deleteJob,
  duplicateJob,
}
