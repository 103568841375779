import ToggleButton from 'containers/MainContent/Orcatec/components/buttons/ToggleButton/ToggleButton'
import ProposalModules from 'containers/MainContent/Orcatec/components/ProposalNotesAndModules/ProposalModules'
import moment from 'moment-timezone'
import { useState } from 'react'
import { ITabModule, Proposal } from 'types/Proposal'
import SignatureBlock from '../../../Form/SignatureBlock/SignatureBlock'
import InstallersInfoBlock from '../../../Form/TabsContent/TabInfoBlock/InstallersInfoBlock/InstallersInfoBlock'
import ModalOrderEquipmentNew from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalOrderEquipments/ModalOrderEquipmentNew'
import ModalReminder from '../../../Modals/ModalReminder'
import ProposalSections from '../../../PageProposalPreview/ProposalSections'
import { Content, Heading, ProjectStatus, Wrapper } from './MainSection.style'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { ProjectStatusGroup } from 'features/Project/types'
import { User } from 'types/User.js'
import { Job } from 'types/Jobs.js'
import { Tooltip } from 'antd'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'
import { StatusBadge } from 'features/Settings/Project/Statuses/components/StatusBadge'
import { STATUS_INFO } from 'features/Settings/Project/Statuses/Statuses'
import { WOStatusInfo } from 'features/Project/components/WorkOrder/types'
import { useModulePermissions } from './useModulePermission'
import { ProjectTabsAPI } from 'api/Project'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ProjectPermissions,
  ModuleName,
} from 'features/Settings/UsersAndGroups'

interface Props {
  data: Proposal
  onChangeData: (data: Proposal) => void
  showModules: boolean
  companyId: number
  me: User
  eventName: Job['event_name']
}

export const MainSection = ({
  data,
  onChangeData,
  showModules = true,
  // companyId,
  // me,
  eventName,
}: Props) => {
  const dispatch = useAppDispatch()
  const perm = useModulePermissions({ user_id: data.user_id })
  const modalOrderSupplyIsOpen = useAppSelector(
    state => state.orcatec.modalEquipment.isOpen,
  )

  const dictionary = useAppSelector(selectJobDictionary)
  const showItemDescription = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.PROJECT,
      ProjectPermissions.PROJECT_CAN_VIEW_ITEM_DESCRIPTON,
    ),
  )

  const [content, showContent] = useState(true)
  const [optionalModule, setOptionalModule] = useState<ITabModule | null>(null)

  const toggleContent = () => showContent(!content)

  const handleModuleClick = (module: ITabModule) => {
    // if (module.name === 'Payment') return

    if (module.name === 'Payment') {
      if (module.status === 1) {
        handleSavePaymentModule(2)
      } else {
        handleSavePaymentModule(1)
      }
      return
    }

    if (module.name === 'Supplies') {
      return module.status === 1
        ? handleSaveSuppliesModule(2)
        : dispatch({ type: 'OPEN_MODAL_EQUIPMENT' })
    }

    if (module.status === 1) {
      const updatedModules = [...data?.proposal_tabs?.[0]?.modules]
      const index = updatedModules.findIndex(({ name }) => name === module.name)

      // if (
      //   index === 2 &&
      //   companyId === 375 && // HARDCODE for Big Joe
      //   ![1337, 1339, 1340].includes(me?.id) &&
      //   ![1, 7, 8].includes(me.role_id)
      // )
      //   return

      updatedModules[index] = { ...module, status: 2 }
      return onChangeData({
        ...data,
        proposal_tabs: [
          {
            ...data.proposal_tabs[0],
            modules: updatedModules,
          },
        ],
      })
    }
    setOptionalModule(module)
  }

  const handleChangeModuleNotes = (field, e) => {
    setOptionalModule({
      ...optionalModule,
      notes: e.target.value,
    })
  }

  const handleChangeMouduleStatus = status => {
    setOptionalModule({
      ...optionalModule,
      status,
    })
  }

  const handleSaveSuppliesModule = (status = null) => {
    onChangeData({
      ...data,
      proposal_tabs: [
        {
          ...data.proposal_tabs[0],
          status,
        },
      ],
    })
    setOptionalModule(null)
  }

  const handleSavePaymentModule = (status = null) => {
    onChangeData({
      ...data,
      proposal_tabs: [
        {
          ...data.proposal_tabs[0],
          payment_status: status,
        },
      ],
    })
    ProjectTabsAPI.updateTabField(data.proposal_tabs[0].id, {
      payment_status: status,
    })
    setOptionalModule(null)
  }

  const handleSaveModule = () => {
    const updatedModules = [...data?.proposal_tabs?.[0]?.modules]
    const index = updatedModules.findIndex(
      module => module.name === optionalModule?.name,
    )
    updatedModules[index] = optionalModule

    onChangeData({
      ...data,
      proposal_tabs: [
        {
          ...data.proposal_tabs[0],
          modules: updatedModules,
        },
      ],
    })
    setOptionalModule(null)
  }

  const handleCloseModalReminder = () => {
    const updatedModules = [...data?.proposal_tabs?.[0]?.modules]
    const index = updatedModules.findIndex(
      ({ name }) => name === optionalModule?.name,
    )

    if (updatedModules[index]?.notes !== optionalModule?.notes) {
      return openNotificationWithIcon('warning', {
        message: 'You have some unsaved changes.',
      })
    }
    setOptionalModule(null)
  }

  const contractInfo = {
    contract_date: data?.contract_date,
    signature: data?.signature ?? '',
    ip_client: data?.ip_client ?? '',
    signature_date: data?.signature_date ?? '',
    proposal_status_changed_by: data?.proposal_status_changed_by ?? '',
    signatory_name: data?.signatory_name,
  }

  const isDisabled = {
    0: perm.module_1,
    1: perm.module_2,
    2: perm.module_3,
  }

  return (
    <Wrapper className='component_to_print'>
      <Heading>
        <ToggleButton show={content} toggle={toggleContent} />
        <p>
          <span>{data.parent_id ? 'Work Order ID' : 'Project ID'}</span>:{' '}
          {data.code}
        </p>
        {eventName && (
          <p>
            <span> {`${dictionary.singular} Name`}</span>:{' '}
            {eventName.length > 20 ? (
              <Tooltip title={eventName}>
                {eventName.slice(0, 20) + '...'}
              </Tooltip>
            ) : (
              eventName
            )}
          </p>
        )}

        <p>
          <span>Issue Date</span>: {moment(data.created_at).format('MM/DD/YY')}
        </p>
        <p>
          <span>Tech name</span>: {data.user?.full_name}
        </p>
        {showModules && (
          <>
            {data.parent_id ? (
              <p style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                <span>Work order Status</span>:{' '}
                <ProjectStatus color={WOStatusInfo[data?.wo_status].color}>
                  {WOStatusInfo[data?.wo_status].display_name}
                </ProjectStatus>
                {/* <span>Status</span>: {data.status_name} */}
              </p>
            ) : (
              <Tooltip title={data?.custom_status?.display_name}>
                <p style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                  <span>Status</span>:{' '}
                  <StatusBadge
                    status={data.custom_status}
                    icon={STATUS_INFO[data.custom_status?.parent_status].icon}
                    maxWidth={120}
                  />
                  {/* <span>Status</span>: {data.status_name} */}
                </p>
              </Tooltip>
            )}
          </>
        )}

        {showModules && !data.parent_id && (
          <ProposalModules
            modules={[
              {
                name: 'Payment',
                status: data?.proposal_tabs?.[0]?.payment_status,
                disabled: true,
              },
              {
                name: 'Supplies',
                status: data?.proposal_tabs?.[0]?.status,
                disabled: !perm.supplies,
              },
              ...data?.proposal_tabs?.[0]?.modules?.map((item, idx) => ({
                ...item,
                disabled: !isDisabled[idx],
              })),
            ]}
            onModuleClick={handleModuleClick}
          />
        )}
      </Heading>

      {content && (
        <Content className='preview-page-layout'>
          <ProposalSections
            sections={data?.proposal_tabs?.[0]?.proposal_sections}
            proposalStatus={data.status}
            showProgress={
              data?.setting?.completion_progress &&
              [
                ProjectStatusGroup.Contract,
                ProjectStatusGroup.Completed,
                ProjectStatusGroup.Canceled,
              ].includes(data.status)
            }
            isJobView={showModules}
            showModules={showModules}
            isWorkOrder={!!data.parent_id}
            hideDescription={!showItemDescription}
          />

          {(data?.info_for_customer || !!data?.files?.length) && (
            <InstallersInfoBlock
              fieldValue={data.info_for_customer}
              files={data.files}
              fieldLabel='Information for customer'
            />
          )}

          {(data?.info_from_customer ||
            !!data?.files_from_customer?.length) && (
            <InstallersInfoBlock
              fieldValue={data.info_from_customer}
              files={data.files_from_customer}
              fieldLabel='Information from client'
            />
          )}

          {showModules && !data.parent_id && (
            <div style={{ marginTop: 10 }}>
              <SignatureBlock
                contractInfo={contractInfo}
                projectStatus={data.status}
              />
            </div>
          )}
        </Content>
      )}

      {modalOrderSupplyIsOpen && (
        <ModalOrderEquipmentNew
          onStatusChanged={handleSaveSuppliesModule}
          onClickSave={handleSaveSuppliesModule}
          status={data?.proposal_tabs[0]?.status || 1}
          jobTabId={data?.proposal_tabs[0]?.id}
          jobProject={data}
          isJob
        />
      )}

      {optionalModule && optionalModule.name !== 'Supplies' && (
        <ModalReminder
          show={!!optionalModule}
          onHide={handleCloseModalReminder}
          values={optionalModule}
          // onReset={this.onReset}
          onChange={handleChangeModuleNotes}
          onChangeStatus={handleChangeMouduleStatus}
          onSave={handleSaveModule}
          onCancel={() => setOptionalModule(null)}
        />
      )}
    </Wrapper>
  )
}
