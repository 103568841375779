import { memo, useMemo } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { timeOffSet } from 'store/Orcatec/reducers/timeoff'

const Timeoff = memo(
  ({
    events,
    worker,
    minTime,
    maxTime,
    currentDate,
    toggleEditTimeoff,
    weekView,
    boxHeight = 40,
    isWorker,
    dragEventOver,
    dragEventLeave,
    onDrop,
    resourceid,
    dragEventStart,
    dragEventEnd,
  }) => {
    const filteredEvents = useMemo(
      () =>
        events.filter(event =>
          moment(currentDate)
            .hours(minTime)
            .isBefore(event.end_date),
        ),
      [events],
    )

    const getPosition = (start_date, end_date) => {
      let start
      let end
      if (
        moment(currentDate).isAfter(
          moment(start_date, 'YYYY-MM-DD HH:mm'),
          'day',
        )
      ) {
        start = minTime
      } else if (minTime > moment(start_date, 'YYYY-MM-DD HH:mm').hours()) {
        start = minTime
      } else {
        start = moment(start_date, 'YYYY-MM-DD HH:mm').hours()
      }
      if (maxTime > moment(end_date, 'YYYY-MM-DD HH:mm').hours()) {
        end = maxTime
      } else {
        end = moment(end_date, 'YYYY-MM-DD HH:mm').hours()
      }
      return Number(start - minTime) * (boxHeight + 5)
    }

    const getHeight = event => {
      let start
      let end
      let start_date = moment(event.start_date).format('YYYY-MM-DD HH:mm')
      let end_date = moment(event.end_date).format('YYYY-MM-DD HH:mm')
      if (moment(start_date).isBefore(moment(currentDate), 'day')) {
        start = minTime
      } else if (
        moment(currentDate).isSame(moment(start_date), 'day') &&
        moment(start_date).hours() >= minTime
      ) {
        start = moment(start_date).hours()
      } else if (
        moment(currentDate).isSame(moment(start_date), 'day') &&
        moment(start_date).hours() < minTime
      ) {
        start = minTime
      }
      if (moment(end_date).isAfter(moment(currentDate), 'day')) {
        end = maxTime
      } else if (
        moment(currentDate).isSame(moment(end_date), 'day') &&
        moment(end_date).hours() <= maxTime
      ) {
        end =
          moment(end_date).format('HH:mm') === '23:59'
            ? 24
            : moment(end_date).hours()
      } else if (
        moment(currentDate).isSame(moment(end_date), 'day') &&
        moment(end_date).hours() > maxTime
      ) {
        end = maxTime
      }

      const dif = Number(end) - Number(start)
      if (dif > 0) {
        return dif * (boxHeight + 5) - 5
      } else {
        return boxHeight
      }
    }

    const goToTimeOff = event => () => {
      if (isWorker || weekView) return

      toggleEditTimeoff(event)
    }

    const columnTechniciansIds = worker?.technicians?.map(tech => tech.user_id)

    const generateArrayStartingFromN = (n, length) => {
      return Array.from({ length: length }, (_, index) => n + index)
    }

    return (
      <div className='timeoff'>
        {filteredEvents?.reduce((acc, event, i) => {
          if (
            columnTechniciansIds?.includes(event.user_id) &&
            moment(event.start_date).isSameOrBefore(
              moment(currentDate).endOf('day'),
            ) &&
            moment(event.end_date).isSameOrAfter(
              moment(currentDate).startOf('day'),
            )
          ) {
            const eventStyle = {
              top: `${Number(getPosition(event.start_date, event.end_date))}px`,
              height: `${getHeight(event)}px`,
            }
            const draggableEventStyle = {
              height: `${getHeight(event)}px`,
            }

            // const boxCount =
            //   moment(event.end_date).hour() - moment(event.start_date).hour()

            const start = moment(event.start_date)
            const end = moment(event.end_date)

            const current = moment(currentDate)

            let boxCount
            let startTime

            if (start.isSame(end, 'day')) {
              boxCount = end.hour() - start.hour()
              startTime = start.hour()
            } else if (current.isSame(start, 'day')) {
              boxCount = maxTime - start.hour()
              startTime = start.hour()
            } else if (current.isSame(end, 'day')) {
              boxCount = maxTime - end.hour() + 1
              startTime = minTime
            } else {
              boxCount = maxTime - minTime
              startTime = minTime
            }

            const overlayBoxes = generateArrayStartingFromN(startTime, boxCount)

            acc.push(
              <div
                className='timeoff-event'
                key={i}
                resourceid={worker.user_id}
                style={eventStyle}
              >
                <div
                  resourceid={resourceid}
                  onClick={goToTimeOff(event)}
                  style={{
                    position: 'absolute',
                    top: 0,
                    height: overlayBoxes.length * 40 + overlayBoxes.length * 2,
                    width: '100%',
                    cursor: 'pointer',
                    // zIndex: 100,
                  }}
                >
                  {overlayBoxes.map((el, idx, arr) => (
                    <div
                      className='box-overlay'
                      style={{
                        height: 39,
                        zIndex: 1,
                        borderRadius: '5px',
                        margin: arr.length - 1 !== idx ? '4px' : '0 4px',
                      }}
                      hour={el}
                      resourceid={worker.user_id}
                      key={el}
                      onDragOver={e => dragEventOver(e, idx)}
                      onDragLeave={e => dragEventLeave(e, idx)}
                      onDrop={onDrop}
                    ></div>
                  ))}
                </div>
                <span
                  // onClick={goToTimeOff(event)}
                  className='draggable-event'
                  style={draggableEventStyle}
                >
                  <span>
                    <div>{moment(event.start_date).format('MM/DD/YY h a')}</div>
                    <div>{event.reason}</div>
                    <div>{moment(event.end_date).format('MM/DD/YY h a')}</div>
                  </span>
                </span>
              </div>,
            )
          }
          return acc
        }, [])}
      </div>
    )
  },
)

const mapDispatchToProps = dispatch => {
  return {
    toggleEditTimeoff: item => dispatch(timeOffSet(item)),
  }
}

const connectedTimeoff = connect(null, mapDispatchToProps)(Timeoff)
export { connectedTimeoff as Timeoff }

Timeoff.displayName = 'TimeoffEvent'
