export const DISPATCH_COLORS = [
  '#DBA0A5',
  '#F1BA6F',
  '#FFCC66',
  '#CAB5BB',
  '#5EAAB9',
  '#A7D197',
  '#A4A4FD',
  '#CC81FB',
  '#7999F6',
  '#69E9CD',
  '#A7D197',
  '#5D7570',
  '#F58383',
  '#F3F0A2',
  '#F489FA',
  '#F3EDF3',
]
