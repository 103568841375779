export const steps = [
  {
    target: '.proposal-contact-info',
    content:
      'Here you can add or attach existing properties, clients and organizations to the project',
    placement: 'top',
    title: 'Project Contacts',
  },
  {
    target: '.project-contact-name-2',
    content: 'Choose the items you want to attach with a checkbox',
    placement: 'top',
    disableBeacon: true,
    title: 'Project Contacts',
  },
]

export const transformTitle = {
  1: {
    client: 'Properties',
    property: 'Clients',
  },
  2: { client: 'Other Properties', property: 'Other Clients' },
  3: { client: 'Other Properties', property: 'Other Clients' },
}

export const transformBtnTitle = {
  client: 'Property',
  property: 'Contact',
}

export const transformTitleToUpperCase = {
  client: 'Contact',
  property: 'Property',
}

export const transformTitleToLowerCase = {
  client: 'contact',
  property: 'property',
}

export const BREAK_POINT = 900
