export const SETTINGS_FETCHED = 'SETTINGS_FETCHED'
export const FETCHING_SETTINGS = 'FETCHING'
export const SETTINGS_UPDATED = 'SETTINGS_UPDATED'
export const UPDATE_GLOBAL_SETTINGS = 'UPDATE_GLOBAL_SETTINGS'
export const LOCAL_UPDATE_SETTINGS_COLOR = 'LOCAL_UPDATE_SETTINGS_COLOR'

export const GLOBAL_COLUMNS_ORDER_CHANGED = 'GLOBAL_COLUMNS_ORDER_CHANGED'
export const GLOBAL_COLUMN_CREATED = 'GLOBAL_COLUMN_CREATED'
export const GLOBAL_COLUMN_UPDATED = 'GLOBAL_COLUMN_UPDATED'
export const GLOBAL_COLUMN_DELETED = 'GLOBAL_COLUMN_DELETED'
