import moment from 'moment'
import { AppStateType } from '../../../store'

export const MenuIcon = ({ onClick }: { onClick: () => void }) => (
  <div onClick={onClick}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width='35'
      height='35'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 24 24'
    >
      <path fill='currentColor' d='M3 6h18v2H3V6m0 5h18v2H3v-2m0 5h18v2H3v-2Z' />
    </svg>
  </div>
)

export const initialTimeState = {
  startDate: moment()
    .startOf('week')
    .format('YYYY-MM-DD'),
  endDate: moment()
    .endOf('week')
    .format('YYYY-MM-DD'),
}

export const selectMe = (state: AppStateType) => state.orcatec.user.me
export const selectSupportTickets = (state: AppStateType) => state.orcatec.supportTickets
