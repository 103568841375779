import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import Appointment from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/Appointment'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import * as actions from '../../../../../../store/Orcatec/actions'
import './styles.scss'
import { Modal } from 'antd'
import moment from 'moment-timezone'
import {
  validateAll,
  makeDisabledTime,
  isSameTime,
} from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/helpers/helpers'
import {
  initialAppointment,
  initialErrors,
} from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/AppointmentForm'
import { getUserTimeSlots } from 'api/Appointment'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { AppointmentStatusID } from 'types/Appointment'
import { useEventAssignToOptions } from 'hooks/useEventAssignToOptions'
import useEventSettings from 'hooks/useEventSettings'

const AppointmentForm = ({ setIsEditFields, onClose, onCreateAppointment, error, setError, isLoading }) => {
  const industries = useSelector(state => state.orcatec.user.me.industries)
  const [appointment, setAppointment] = useState(initialAppointment)
  const [showDropModal, setShowDropModal] = useState(false)
  const [disabledTime, setDisabledTime] = useState({ matrixTimeStart: [], matrixTimeEnd: [] })
  const eventSettings = useEventSettings()

  // const { technicians } = useTechnicians()
  const { data: assignToOptions } = useEventAssignToOptions(appointment.date)

  const handleChangeAppointment = e => {
    setIsEditFields(true)
    let { name, value, checked } = e.target

    if (error?.[name]) {
      const newErrors = { ...error }
      delete newErrors[name]

      setError(newErrors)
    }

    if (name === 'entity_type') value = checked ? 2 : 1
    if (name === 'time_start' && !!value && !appointment.time_end) {
      setAppointment(prev => ({
        ...prev,
        time_end: moment(value).add(+eventSettings?.arrival_window || 1, 'h'),
      }))
    }
    if (name === 'time_end' && !!value && !appointment.time_start) {
      setAppointment(prev => ({
        ...prev,
        time_start: moment(value).add(-2, 'h'),
      }))
      setError(prev => ({ ...prev, time_start: null }))
    }
    if (name === 'appointment-status') {
      setAppointment(prev => ({
        ...prev,
        status: value,
        status_logs: [
          ...prev.status_logs,
          {
            status: AppointmentStatusID[value],
            created_at: moment().format('YYYY/MM/DD'),
          },
        ],
      }))
    }
    if (name === 'assign_to_matrix' && value) {
      const isSame = isSameTime(appointment?.time_start, appointment?.time_end)
      setAppointment(prev => ({
        ...prev,
        appointed_technician_id: !!appointment.preferred_technician_id ? appointment.preferred_technician_id : null,
        matrix_time_start: !appointment?.time_start
          ? moment().set('minute', 0)
          : moment(appointment?.time_start)
              .clone()
              .set('minute', 0)
              .add(1, 'hour'),
        matrix_time_end: !appointment?.time_end
          ? moment()
              .set('minute', 0)
              .add(1, 'hour')
          : isSame
          ? moment(appointment?.time_start)
              .clone()
              .set('minute', 0)
              .add(1, 'hour')
          : moment(appointment?.time_end)
              .clone()
              .set('minute', 0),
      }))
    }
    if (name === 'assign_to_matrix' && !value) {
      setAppointment(prev => ({
        ...prev,
        matrix_time_start: null,
        matrix_time_end: null,
      }))
      setError(initialErrors)
    }
    if (name === 'matrix_time_start' && !!value) {
      setAppointment(prev => ({
        ...prev,
        matrix_time_end: moment(value).add(2, 'h'),
      }))
    }
    if (name === 'appointed_technician_id') {
      setAppointment(prev => ({
        ...prev,
        assign_to_matrix: !!value,
      }))
    }
    setAppointment(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    if (!!error?.count_days) {
      setShowDropModal(true)
    }
  }, [error?.count_days])

  // useEffect(() => {
  //   if (industries?.length) setAppointment(prev => ({ ...prev, industry_id: industries?.[0]?.id }))
  // }, [])
  useEffect(() => {
    if (eventSettings?.industry_id) {
      setAppointment(prev => ({ ...prev, industry_id: eventSettings?.industry_id || industries?.[0]?.id }))
    }
  }, [eventSettings])

  useEffect(() => {
    if (error?.time) setShowDropModal(true)
  }, [error])

  useEffect(() => {
    if (showDropModal && appointment?.confirm) {
      onCreateAppointment(appointment)
    }
  }, [showDropModal, appointment?.confirm])

  // useEffect(() => {
  //   const { newErrors, validate } = validateAll(appointment, error, disabledTime)
  //   setError(newErrors)
  // }, [appointment, disabledTime.matrixTimeEnd, disabledTime.matrixTimeStart])
  useEffect(() => {
    if (
      appointment.id &&
      appointment?.appointed_technician_id &&
      moment(appointment?.date || appointment?.start_date).isValid() &&
      appointment?.assign_to_matrix
    ) {
      getUserTimeSlots(appointment?.appointed_technician_id, appointment?.date || appointment?.start_date)
        .then(data => makeDisabledTime(data, appointment))
        .then(times => setDisabledTime(times))
        .catch(error => {
          setDisabledTime({ matrixTimeStart: [], matrixTimeEnd: [] })
          openNotificationWithIcon('error', {
            message: 'Error!',
            description: Object.values(error.response?.data?.errors || 'Something went wrong'),
            key: 'appointment-error',
          })
        })
    }
  }, [appointment.appointed_technician_id, appointment.date, appointment?.assign_to_matrix, appointment?.start_date])

  return (
    <>
      <div className='appointment-form'>
        <Appointment
          assignToOptions={assignToOptions}
          setIsEditFields={setIsEditFields}
          setEdited={setIsEditFields}
          appointment={appointment}
          onChange={handleChangeAppointment}
          error={error}
          disabledTime={disabledTime}
          eventSettings={eventSettings}
          industries={industries}
        />

        <div className='action-buttons'>
          <MainButton title='Cancel' type='cancel' onClick={onClose} />

          <MainButton
            title='Create Appointment'
            onClick={e => {
              e.preventDefault()
              onCreateAppointment(appointment)
              setIsEditFields(false)
            }}
            className='ml-3'
            disabled={isLoading}
            isFetching={isLoading}
          />
        </div>
      </div>

      {/* <Modal
        title='Ooops..'
        visible={showDropModal}
        onOk={() => {
          setAppointment(prev => ({ ...prev, assign_to_matrix: false }))
        }}
        okText='Proceed'
        onCancel={() => setShowDropModal(false)}
      >
        <h5>This time is used by another appointment</h5>
      </Modal> */}

      <Modal
        title='Ooops..'
        visible={showDropModal}
        onOk={() => {
          setAppointment(prev => ({ ...prev, confirm: true }))
        }}
        okText='Proceed'
        onCancel={() => setShowDropModal(false)}
      >
        <h6>There are time slot conflicts for these technicians: </h6>

        {error?.count_days && (
          <p
            style={{
              whiteSpace: 'pre',
              marginBottom: 10,
            }}
          >
            {Object.entries(error?.count_days)?.map(([id, array]) => {
              return <p style={{ fontWeight: 'bold' }}>{assignToOptions?.find(w => w.id == id)?.name}</p>
            })}
          </p>
        )}

        <p>Would do you like to put conflict appointments in queue?</p>
      </Modal>
    </>
  )
}
const mapDispatchToProps = dispatch => ({
  setIsEditFields: state => dispatch(actions.modals.wasEditField(state)),
})

export default connect(null, mapDispatchToProps)(AppointmentForm)
