import { ActionType } from '../constants/companiesConstants'
import { combineReducers } from 'redux'

const {
  GET_COMPANIES_LIST_INIT,
  GET_COMPANIES_LIST_SUCCESS,
  GET_COMPANIES_LIST_FAILURE,
  GET_CURRENT_COMPANY_INIT,
  GET_CURRENT_COMPANY_SUCCESS,
  GET_CURRENT_COMPANY_FAILURE,
  CLEAR_STORE,
  DELETE_COMPANY_INIT,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE,
  SET_EDIT_COMPANY_FIELDS,
  UPDATE_COMPANY_INIT,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
} = ActionType

const companiesListInitialState = {
  data: [],
  total: 1,
  per_page: 10,
  current_page: 1,
  last_page: 1,
  loading: false,
  load: false,
  error: false,
}

const companiesListReducer = (state = companiesListInitialState, { type, payload }) => {
  switch (type) {
    case GET_COMPANIES_LIST_INIT:
      return { ...state, loading: true, load: false, error: false }
    case GET_COMPANIES_LIST_SUCCESS:
      return { ...state, ...payload.data, loading: false, load: true, error: false }
    case GET_COMPANIES_LIST_FAILURE:
      return { ...state, loading: false, load: false, error: true }
    default:
      return state
  }
}

const currentCompanyInitialState = {
  company: null,
  loading: false,
  load: false,
  error: false,
  errorData: {},
}

const currentCompanyReducer = (state = currentCompanyInitialState, { type, payload }) => {
  switch (type) {
    case GET_CURRENT_COMPANY_INIT:
      return { ...state, company: null, loading: true, load: false, error: false, errorData: {} }
    case GET_CURRENT_COMPANY_SUCCESS:
      return { ...state, company: payload, loading: false, load: true, error: false, errorData: {} }
    case GET_CURRENT_COMPANY_FAILURE:
      return { ...state, company: null, loading: false, load: false, error: true, errorData: payload }
    case CLEAR_STORE:
      return { ...state, ...currentCompanyInitialState }
    case SET_EDIT_COMPANY_FIELDS: {
      const { name, value } = payload
      return { ...state, company: { ...state.company, [name]: value } }
    }
    case UPDATE_COMPANY_SUCCESS:
      return { ...state, company: payload }
    default:
      return state
  }
}

const sendCompanyDataInitialState = {
  sending: false,
  sent: false,
  error: false,
  errorData: null,
}

const sendCompanyDataReducer = (state = sendCompanyDataInitialState, { type, payload }) => {
  switch (type) {
    case DELETE_COMPANY_INIT:
      return { ...state, sending: true, sent: false, error: false, errorData: null }
    case DELETE_COMPANY_SUCCESS:
      return { ...state, sending: false, sent: true, error: false, errorData: null }
    case DELETE_COMPANY_FAILURE: {
      const { data } = payload.response
      return { ...state, sending: false, sent: false, error: true, errorData: { ...data } }
    }
    case UPDATE_COMPANY_INIT:
      return { ...state, sending: true, sent: false, error: false, errorData: null }
    case UPDATE_COMPANY_SUCCESS:
      return { ...state, sending: false, sent: true, error: false, errorData: null }
    case UPDATE_COMPANY_FAILURE: {
      return { ...state, sending: false, sent: false, error: true, errorData: { ...payload.response } }
    }
    case CLEAR_STORE:
    case GET_CURRENT_COMPANY_INIT: // При отмене редактирования компании, идет перезапрос на компанию {id}, чтобы сетить в поля актуальное значение и очищаем стейт отправки.
      return { ...state, ...sendCompanyDataInitialState }
    default:
      return state
  }
}

export default combineReducers({
  companiesListData: companiesListReducer,
  currentCompany: currentCompanyReducer,
  sendCompanyData: sendCompanyDataReducer,
})
