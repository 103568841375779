import { PaymentBalance } from 'types/Proposal'

export const getPaymentStatus = (
  balances: PaymentBalance[],
  total_to_pay: number | string,
): number => {
  const overdueBalances = balances?.some(b => b.status === 2)
  const sumOfPaidBalances = balances
    ?.filter(b => b.status === 3)
    .reduce((a, b) => a + +b.amount, 0)
  const isAnyDueOverDuePending = balances?.some(
    b => b.status === 1 || b.status === 2 || b.status === 5,
  )

  if (!balances?.length) return 1
  if (
    +(+sumOfPaidBalances).toFixed(2) === +(+total_to_pay).toFixed(2) &&
    !isAnyDueOverDuePending
  ) {
    return 4
  } else if (overdueBalances) {
    return 3
  } else return 2
}
