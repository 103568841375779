import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { useEffect, useState } from 'react'
import ContactsList from './components/ContactsList'
import styled from 'styled-components'
import { Collapse, Spin } from 'antd'
import { useContacts } from 'features/Notification/hooks/useContacts'
import { AssignToOption } from 'hooks/useEventAssignToOptions'
import { ContactI, ContactWithMessage } from './types'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { useNotificationTemplatesList } from 'features/Notification/hooks/useNotificationTemplatesList'
import {
  ITemplate,
  NotificationModalType,
  NotificationTemplateType,
} from 'features/Notification/types'
import { Footer } from './components/ModalFooter/ModalFooter'
import { useNotificationTemplate } from 'features/Notification/hooks/useNotificationTemplate'
import { Button, Select } from 'components/UIKit'
import { OtherContacts } from './components/OtherContacts/OtherContacts'
import { parseEmailMessage } from 'features/Notification/helpers/parseEmailMessasge'
import { Job } from 'types/Jobs'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { selectCompany } from 'store/Orcatec/selectors/company'
import { useIndustryLogo } from 'features/Notification/hooks/useIndustryLogo'
// import moment from 'moment-timezone'
// import { currentTimeIsBetweenTzTime } from './utils'
import { HeadInfo } from './components/HeadInfo/HeadInfo'
import { useTimeSettings } from 'features/Notification/hooks/useTimeSettings'
import {
  currentTimeIsBetweenTzTime,
  parsePhoneNumbersBeforeSend,
} from './utils'
import moment from 'moment-timezone'
import {
  sendEmailNotificationV2,
  sendSMSNotificationV2,
} from 'api/NotificationModal'
import { useMessagingPhones } from 'features/Messaging/hooks/useMessagingPhones'
import { removeSpace, removeTagsSms } from './NotificationModal'
import {
  selectWordOrderItems,
  selectWorkOrder,
} from 'features/Project/slices/projectWorkOrdersSlice'
import { MessageSection } from './components/MessagesList/MessageSection'
import { selectWOSummaryContact } from 'features/Project/slices/projectWorkOrderContactsSlice'

const { Panel } = Collapse

interface Props {
  title: string
  templateType: NotificationTemplateType
  notificationType: NotificationModalType
  industryId: number
  techs: ContactI[]
  entity: 'appointments' | 'proposals'
  entityId: number
  dispatchColumns?: AssignToOption[]
  onClose: () => void
  customSubmitFunc: () => void
  event: Job
}

export const NotificationModalV2 = ({
  industryId,
  templateType,
  notificationType,
  title,
  techs,
  onClose,
  customSubmitFunc: onSubmit,
  entity,
  entityId,
  event,
}: Props) => {
  const me = useAppSelector(selectAuthUser)
  const company = useAppSelector(selectCompany)

  const { contactList, loading: contactsLoading } = useContacts(
    entity,
    entityId,
  )
  const { industryLogo } = useIndustryLogo(industryId)
  const { timeSettings } = useTimeSettings()
  const { phones } = useMessagingPhones()
  const isMessagingEnabled = !!phones.length

  const workOrder = useAppSelector(selectWorkOrder)
  const { property } = useAppSelector(selectWOSummaryContact)
  const scopeOfWork = useAppSelector(selectWordOrderItems())

  const [confirmModal, showConfirmModal] = useState(false)
  const [error, setError] = useState(false)
  const [isSending, setIsSending] = useState(false)

  //Phone
  const [selectedCompanyPhone, setSelectedCompanyPhone] = useState('')
  //Templates
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null)
  const {
    templatesList,
  }: {
    templatesList: (ITemplate & { label: string })[]
  } = useNotificationTemplatesList(templateType)
  const {
    notificationTemplate,
    loading: templateLoading,
  } = useNotificationTemplate(notificationType, industryId, selectedTemplate)
  //HeadInfo
  const [emailData, setEmailData] = useState({
    subject: '',
    from_name: '',
    reply_to: '',
  })
  //Contacts
  const [selectedClients, setSelectedClients] = useState<
    Record<number, ContactWithMessage>
  >({})
  const [selectedTechs, setSelectedTechs] = useState<
    Record<number, ContactWithMessage>
  >({})
  const [otherContacts, setOtherContacts] = useState({
    emails: {
      checked: false,
      value: [],
    },
    phone: {
      checked: false,
      value: '',
    },
    emailMessage: '',
    phoneMessage: '',
  })

  const parseMessageWithSourceData = (contact?: ContactI) => (
    message: string | undefined,
    type: 'sms' | 'email',
  ) => {
    return parseEmailMessage(message || '', type, {
      event: {
        ...event,
        tech: contact,
        proposal_info: { ...workOrder, properties: property },
      },
      authUser: me,
      company: company,
      scopeOfWork: scopeOfWork,
    })
  }

  const { body: emailMessage, from_name, reply_to, subject } =
    notificationTemplate?.email ?? {}
  const phoneMessage = notificationTemplate?.sms?.body || ''

  useEffect(() => {
    if (!selectedTemplate && notificationTemplate?.id) {
      setSelectedTemplate(notificationTemplate?.id)
    }

    setSelectedClients(
      transformArrayToObj(
        Object.values(selectedClients).map(contact => ({
          ...contact,
          emailMessage: parseMessageWithSourceData(contact)(
            emailMessage,
            'email',
          ),
          phoneMessage: parseMessageWithSourceData(contact)(
            phoneMessage,
            'sms',
          ),
        })),
      ),
    )

    setSelectedTechs(
      transformArrayToObj(
        Object.values(selectedTechs).map(contact => ({
          ...contact,
          emailMessage: parseMessageWithSourceData(contact)(
            emailMessage,
            'email',
          ),
          phoneMessage: parseMessageWithSourceData(contact)(
            phoneMessage,
            'sms',
          ),
        })),
      ),
    )

    setOtherContacts(prev => ({
      ...prev,
      phoneMessage: parseMessageWithSourceData()(phoneMessage, 'sms'),
      emailMessage: parseMessageWithSourceData()(emailMessage, 'email'),
    }))

    setEmailData(prev => ({
      ...prev,
      from_name: parseMessageWithSourceData()(from_name, 'email'),
      reply_to: parseMessageWithSourceData()(reply_to, 'email'),
      subject: parseMessageWithSourceData()(subject, 'email'),
    }))
  }, [notificationTemplate?.id])

  useEffect(() => {
    if (isMessagingEnabled) setSelectedCompanyPhone(phones?.[0]?.number)
  }, [phones])

  /*   useEffect(() => {
    if (contactList?.length && notificationTemplate)
      handleContactsSelectAll('client')(true)
  }, [contactList.length]) */

  const handleTemplateSelect = (_: Event, templateId: number) =>
    setSelectedTemplate(templateId)

  const handleHeadInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    setEmailData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleContactSelect = (entity: 'client' | 'tech') => (
    type: 'emails' | 'phones',
    value: string,
    contact: ContactI,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { phones, emails, ...rest } = contact
    const { id } = contact

    const helperFunc = (prev: Record<number, ContactWithMessage>) => ({
      ...prev,
      [id]: {
        ...rest,
        ...prev[id],
        [type]: prev?.[id]?.[type]?.includes(value)
          ? prev[id][type].filter(e => e !== value)
          : [...(prev?.[id]?.[type] || []), value],
        emailMessage: parseMessageWithSourceData(contact)(
          emailMessage,
          'email',
        ),
        phoneMessage: parseMessageWithSourceData(contact)(phoneMessage, 'sms'),
      },
    })

    if (error) setError(false)

    entity === 'client'
      ? setSelectedClients(helperFunc)
      : setSelectedTechs(helperFunc)
  }

  const handleContactsSelectAll = (entity: 'client' | 'tech') => (
    checked: boolean,
  ) => {
    const source = entity === 'client' ? contactList : techs
    const body = checked
      ? transformArrayToObj(
          source.map(contact => ({
            ...contact,
            phones: phones?.length ? contact?.phones || [] : [],
            emailMessage: parseMessageWithSourceData(contact)(
              emailMessage,
              'email',
            ),
            phoneMessage: parseMessageWithSourceData(contact)(
              phoneMessage,
              'sms',
            ),
          })),
        )
      : {}

    entity === 'client' ? setSelectedClients(body) : setSelectedTechs(body)

    if (error) setError(false)
  }

  const handleOhterContactsChange = (
    type: 'emails' | 'phone',
    value: string | string[],
  ) => {
    if (error) setError(false)

    setOtherContacts(prev => ({
      ...prev,
      [type]: {
        checked:
          type === 'emails' ? !!value.length : !!value && !value.includes('_'),
        value,
      },
    }))
  }

  const handleCheckOther = (type: 'emails' | 'phone', value: boolean) => {
    setOtherContacts(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        checked: value,
      },
    }))
  }

  const handleMessageChange = (entity: 'client' | 'tech') => (
    type: 'emailMessage' | 'phoneMessage',
    contactId: number,
    message: string,
  ) => {
    const helperFunc = (prev: Record<number, ContactWithMessage>) => ({
      ...prev,
      [contactId]: {
        ...prev[contactId],
        [type]: message,
      },
    })

    entity === 'client'
      ? setSelectedClients(helperFunc)
      : setSelectedTechs(helperFunc)
  }

  const handleOtherContactsMessageChange = (
    type: 'emailMessage' | 'phoneMessage',
    _contactId: number,
    message: string,
  ) => {
    setOtherContacts(prev => ({
      ...prev,
      [type]: message,
    }))
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompanyPhone(e.target.value)
  }

  const clientEmailsToSendNotification = Object.values(selectedClients)
    .filter(entity => entity.emails?.length)
    .reduce((list, entity) => {
      entity?.emails?.forEach(email =>
        list.push({ email: email, body: entity.emailMessage }),
      )

      return list
    }, [])

  const techEmailsToSendNotification = Object.values(selectedTechs)
    .filter(entity => entity.emails?.length)
    .reduce((list, entity) => {
      entity?.emails?.forEach(email =>
        list.push({ email: email, body: entity.emailMessage }),
      )

      return list
    }, [])

  const clientPhonesToSendNotification = Object.values(selectedClients)
    .filter(entity => entity.phones?.length)
    .reduce((list, entity) => {
      entity?.phones?.forEach(phone =>
        list.push({
          phone: parsePhoneNumbersBeforeSend([phone])[0],
          body: entity.phoneMessage,
        }),
      )

      return list
    }, [])

  const techPhonesToSendNotification = Object.values(selectedTechs)
    .filter(entity => entity.phones?.length)
    .reduce((list, entity) => {
      entity?.phones?.forEach(phone =>
        list.push({
          phone: parsePhoneNumbersBeforeSend([phone])[0],
          body: entity.phoneMessage,
        }),
      )

      return list
    }, [])

  const EMAILS = [
    ...clientEmailsToSendNotification,
    ...techEmailsToSendNotification,
    ...(otherContacts.emails.checked ? otherContacts.emails.value : []),
  ]

  const PHONES = [
    ...clientPhonesToSendNotification,
    ...techPhonesToSendNotification,
    ...(otherContacts.phone.checked ? [otherContacts.phone.value] : []),
  ]

  const noContactsSelected = !EMAILS.length && !PHONES.length

  const handleSubmit = async (isSendLater?: boolean, isSendNow?: boolean) => {
    const format = 'HH:mm:ss'
    const tz = timeSettings?.timezone
    const startTime = moment(timeSettings?.time_from, format).format(format)
    const endTime = moment(timeSettings?.time_to, format).format(format)
    const timeNow = moment.tz(tz).format(format)

    const sendAt = isSendLater
      ? moment(timeSettings?.time_from, 'HH:mm')
          .add(1, 'day')
          .format('YYYY-MM-DD HH:mm')
      : moment()
          .tz(tz)
          .add(1, 'minute')
          .format('YYYY-MM-DD HH:mm')

    const isCanSendNow = currentTimeIsBetweenTzTime(
      timeNow,
      tz,
      startTime,
      endTime,
      format,
    )
    const canSendNow = isSendLater || isSendNow || isCanSendNow

    if (!canSendNow) return showConfirmModal(true)

    try {
      setIsSending(true)

      if (EMAILS.length) {
        const emailNotificationData = {
          ...emailData,
          send_at: sendAt,
          send_at_timezone: tz,
          type: notificationType,
          body: otherContacts.emailMessage,
          recipients: {
            clients: clientEmailsToSendNotification,
            techniques: techEmailsToSendNotification,
            other: otherContacts.emails.checked
              ? otherContacts.emails.value
              : [],
          },
        }

        await sendEmailNotificationV2(event.id, emailNotificationData)
      }

      if (isMessagingEnabled && PHONES.length) {
        const smsNotificationData = {
          send_at: sendAt,
          send_at_timezone: tz,
          type: notificationType,
          body: removeSpace(removeTagsSms(otherContacts.phoneMessage)),
          media: [],
          from_number: selectedCompanyPhone,
          recipients: {
            clients: clientPhonesToSendNotification,
            techniques: techPhonesToSendNotification,
            other: otherContacts.phone.checked
              ? parsePhoneNumbersBeforeSend([otherContacts.phone.value])
              : [],
          },
        }

        await sendSMSNotificationV2(event.id, smsNotificationData)
      }

      onClose?.()

      onSubmit?.()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSending(false)
    }
  }

  /* const sendLetterWithNativeEmail = (): void => {
    let emailsList: string[] = [][
      // otherEmails.emails?.map?.(otherEmail => {
      //   emailsList.push(otherEmail)
      // })
      (Object.values(selectedClients), Object.values)
    ]?.clients?.map(email => emailsList.push(email))

    // eslint-disable-next-line no-useless-escape
    // const linkRegex = /(?<![a-zA-Z0-9`~!@#$%^&*()-_+={}\[\]|\\:;"'<>,.?/])link(?![a-zA-Z0-9`~!@#$%^&*()-_+={}\[\]|\\:;"'<>,.?/])/gi
    const linkRegex = /(^|[^a-zA-Z0-9`~!@#$%^&*()-_+={}[\]|\\:;"'<>,.?/])link([^a-zA-Z0-9`~!@#$%^&*()-_+={}[\]|\\:;"'<>,.?/]|$)/gi

    // eslint-enable no-useless-escape
    const cleanedMessage = emailData.message
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
      .replace(/<\/?[^>]+(>|$)/g, ' ')
      .replace(linkRegex, `${proposalLink}`)

    const toEmails = encodeURIComponent(emailsList.join(','))
    const subject = encodeURIComponent(emailData.subject)

    const body = encodeURIComponent(cleanedMessage)

    const prepareEmail = `mailto:${toEmails}?subject=${subject}&body=${body}`

    const prepareEmailWithLink = prepareEmail

    window.location.href = prepareEmailWithLink
    setShowModal(false)

    emailsList = []
  } */

  return (
    <Modal
      open
      title={title}
      footer={
        <Footer
          onSubmit={() => handleSubmit()}
          onCancel={onClose}
          disableSend={noContactsSelected}
          loading={isSending}
        />
      }
      width={750}
      onCancel={onClose}
      bodyStyle={{
        maxHeight: '80vh',
        overflow: 'auto',
        backgroundColor: '#f9f9f9',
      }}
    >
      <Spin spinning={contactsLoading || templateLoading}>
        <Template>
          <Title>Template</Title>
          <Select
            name='template'
            value={selectedTemplate}
            onChange={handleTemplateSelect}
            style={{ width: '100%' }}
            options={templatesList}
          />
        </Template>

        <Section>
          <Contacts>
            {!!contactList.length && (
              <ContactsList
                title='Contacts'
                data={contactList}
                selectedContacts={selectedClients}
                onSelect={handleContactSelect('client')}
                onCheckAll={handleContactsSelectAll('client')}
                error={error}
                disabled={!phones?.length}
              />
            )}
            {!!techs.length && (
              <ContactsList
                title='Technicians'
                data={techs}
                selectedContacts={selectedTechs}
                onSelect={handleContactSelect('tech')}
                onCheckAll={handleContactsSelectAll('tech')}
                error={error}
                disabled={!phones?.length}
              />
            )}
            <OtherContacts
              emails={otherContacts.emails}
              phone={otherContacts.phone}
              onChange={handleOhterContactsChange}
              onCheck={handleCheckOther}
              error={error}
              disabled={!phones?.length}
            />
          </Contacts>
        </Section>

        <Section>
          <Collapse defaultActiveKey={['1']} ghost>
            <Panel
              header={<SectionTitle>Messages settings</SectionTitle>}
              key='1'
            >
              <HeadInfo
                data={emailData}
                onChange={handleHeadInfoChange}
                companyPhone={selectedCompanyPhone}
                onPhoneChange={handlePhoneChange}
                phones={phones}
              />
            </Panel>
          </Collapse>
        </Section>

        <Section>
          <Collapse ghost>
            <Panel header={<SectionTitle>Messages</SectionTitle>} key='1'>
              <MessageSection
                industryLogo={industryLogo}
                selectedClients={selectedClients}
                selectedTechs={selectedTechs}
                otherContacts={otherContacts}
                onMessageChange={handleMessageChange}
                onOtherContactsMessageChange={handleOtherContactsMessageChange}
              />
            </Panel>
          </Collapse>
        </Section>

        {confirmModal && (
          <Modal
            wrapClassName='confirm-modal-wrapper'
            title={'Confirmation'}
            open
            // onOk={handleConfirmOk}
            onCancel={() => showConfirmModal(false)}
            width={750}
            footer={null}
            destroyOnClose
          >
            <p>
              {`You are trying to send a notification during a period that does not match business hours (${moment(
                timeSettings.time_from,
                'HH:mm:ss',
              ).format('hh:mm a')} - ${moment(
                timeSettings.time_to,
                'HH:mm:ss',
              ).format('hh:mm a')}) in the settings.
          Press 'Send Later' to send on the next business day or press 'Send Now' to send it immediately.`}
            </p>
            <div className='buttons-row'>
              <Button
                // disabled={isSubmitDisable}
                onClick={() => handleSubmit(true, false)}
              >
                Send Later
              </Button>
              <Button
                // disabled={isSubmitDisable}
                onClick={() => handleSubmit(false, true)}
              >
                Send Now
              </Button>
            </div>
          </Modal>
        )}
      </Spin>
    </Modal>
  )
}

const Contacts = styled.div`
  & > * {
    margin-bottom: 10px;
  }
`

const Template = styled.div`
  display: grid;
  grid-template-columns: 210px 1fr;
  gap: 10px;
  align-items: center;
`
const Title = styled.p`
  font-weight: 500;
`

const SectionTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
`

const Section = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  margin-top: 30px;
`
