import React, { useEffect, useMemo, useState } from 'react'
import { TextField } from '@material-ui/core'
import { connect } from 'react-redux'
import { useTitle } from 'hooks/useTitle'
import * as actions from '../../../../store/Orcatec/actions'
import PageLayout from '../Layouts/PageLayout'
import SureModal from '../components/SureModal'
import PropertyModal from './components/PropertyModal/PropertyModal'
import MergePropertiesModal from '../components/MergeModal'
// import MainPropertiesTable from './components/MainPropertiesTable/MainPropertiesTable'
import MainButton from '../components/buttons/MainButton'
import { getAddressWithUnit } from '../../../../helpers/getAddressWithUnit'
// import useSearch from '../components/SearchHook'
import { openNotificationWithIcon } from '../../../../helpers/notifications/openNotificationWithIcon'

import './Index.scss'
import moment from 'moment-timezone'
import { Tag, Tooltip } from 'antd'
import useDebounce from 'hooks/useDebounce'
import { Link } from 'react-router-dom'
import { exportPropertyTable } from '../../../../api/Property'
import Table from '../components/Table'
import { getValueFromLS } from 'hooks/useLocalStorage'
import TableLayout, {
  IconWrapper,
  ActionsWrapper,
  IconsBlock,
} from '../../Orcatec/Layouts/TableLayout/TableLayout'
import { Settings, MergeType } from '@material-ui/icons'
import TablePopover from '../components/Table/components/TablePopover/index'
import { TableExport } from '../components/Table/components/TableExport/TableExport'
import MembershipLabels from 'features/Membership/components/MembershipLabels'
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons'

const propertiesType = {
  1: 'Commercial',
  2: 'Residential',
}

export const ellipsisStyle = {
  style: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
}

const Index = ({
  properties,
  pagination,
  fetchPropertiesList,
  addProperty,
  deleteProperty,
  updateProperty,
  currentProperty,
  fetchProperty,
  mergeProperties,
  history,
  loading,
}) => {
  /*  useEffect(() => {
    fetchPropertiesList()
  }, []) */
  const settingsFromLC = getValueFromLS('properties-list-table_v1')
  const [modalValues, setModalValues] = useState({})
  const [showSureModal, setShowSureModal] = useState(false)
  const [isMerging, setIsMerging] = useState(false)
  const [showPropertyModal, setShowPropertyModal] = useState(false)
  const [showMergePropertiesModal, setShowMergePropertiesModal] = useState(
    false,
  )

  const [checkedRows, setCheckedRows] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isValid, setIsValid] = useState(false)
  const [tempTagValue, setTempTagValue] = useState('')
  const [searchProp, setSearchProp] = useState(settingsFromLC?.search || '')

  const [clickedRowIndex, setClickedRowIndex] = useState(null)
  const [isExportButtonDisable, setIsExportButtonDisable] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const debouncedSearchValue = useDebounce(searchProp, 500)
  useTitle('Properties')
  const [sortField, setSortField] = useState(
    settingsFromLC?.sorter?.sort_field || null,
  )
  const [sortOrder, setSortOrder] = useState(
    settingsFromLC?.sorter?.order || null,
  )
  const [tableFilters, setTableFilters] = useState({
    ...settingsFromLC?.filters,
  })
  const [additionalFilters, setAdditionalFilters] = useState({
    user_id: settingsFromLC?.user_id || [],
    start_date:
      settingsFromLC?.start_date ||
      moment()
        .startOf('month')
        .format('MM/DD/YYYY'),
    end_date:
      settingsFromLC?.end_date ||
      moment()
        .endOf('month')
        .format('MM/DD/YYYY'),
  })
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [openTableSettings, setOpenTableSettings] = useState(false)

  useEffect(() => {
    fetchPropertiesList({
      per_page: settingsFromLC?.page_size || 25,
      search: debouncedSearchValue || undefined,
      sort_field: sortField,
      order: sortOrder,
      ...tableFilters,
    })
  }, [debouncedSearchValue])

  useEffect(() => {
    setModalValues(currentProperty)
    setIsValid(!!currentProperty.postcode)
  }, [currentProperty])

  /* TABLE ACCORDION */
  /*   function togglePropertyDetails(e, i) {
    const opened = e.currentTarget.parentNode.parentNode.parentNode
    opened.classList.toggle('opened-row')
  } */

  /* END TABLE ACCORDION */

  function toggleSureModal(id) {
    if (!showSureModal) {
      setModalValues({ id })
    } else {
      setModalValues({})
    }
    setShowSureModal(!showSureModal)
  }

  function onHandleDeleteProperty() {
    deleteProperty(properties[modalValues.id].id)
      .then(() => {
        setShowSureModal(!showSureModal)
        toggleSureModal()
      })
      .catch(error => {
        if (
          !!error.response &&
          error.response.status === 403 &&
          error.response.data.error.code === 10037
        ) {
          setShowSureModal(!showSureModal)
          toggleSureModal()
          openNotificationWithIcon('error', {
            message: error.response.data.error.message,
            description: '',
            key: 'property-delete-error',
          })
        }
      })
  }

  function togglePropertyModal(id) {
    if (!showPropertyModal) {
      if (typeof id === 'number') {
        fetchProperty(properties[id].id).then(() => {
          setShowPropertyModal(!showPropertyModal)
        })
      } else {
        setShowPropertyModal(!showPropertyModal)
        setModalValues({})
      }
    } else {
      setIsValid(false)
      setTempTagValue('')
      setModalValues({})
      setShowPropertyModal(!showPropertyModal)
    }
  }

  function handleFieldChange(e) {
    const { name, value } = e.currentTarget
    if (name === 'postcode') {
      if (!Number.isInteger(+value)) {
        setIsValid(false)
        return
      } else {
        const test = /(^\d{5}$)/.test(value)
        setIsValid(test)
        setModalValues(prev => ({ ...prev, [name]: value }))
      }
    }
    if (name === 'tags') {
      setTempTagValue(value)
    } else {
      setModalValues(prev => ({ ...prev, [name]: value }))
    }
  }

  function onHandleSubmit(obj, propertyId) {
    if (propertyId) {
      updateProperty(propertyId, obj)
    } else {
      if (obj) {
        addProperty(obj)
      }
    }
    togglePropertyModal()
  }

  function onAddTag(e) {
    if (e.keyCode === 13 && tempTagValue) {
      if (!modalValues.tags) {
        setModalValues({ ...modalValues, tags: [tempTagValue] })
      } else if (!modalValues.tags.includes(tempTagValue)) {
        setModalValues({
          ...modalValues,
          tags: [tempTagValue, ...modalValues.tags],
        })
      }
      setTempTagValue('')
    }
  }

  function onDeleteTag(i) {
    setModalValues({
      ...modalValues,
      tags: modalValues.tags.filter((tag, idx) => idx !== i),
    })
  }

  function onSwapItemsToMerge() {
    setCheckedRows(prev => [...prev.reverse()])
  }

  function toggleMergeModal() {
    /*  if (showMergePropertiesModal) {
      setCheckedRows([])
    } */
    setShowMergePropertiesModal(!showMergePropertiesModal)
  }

  function onHandleMerge() {
    const data = {
      property_id_from: checkedRows[0].id,
      property_id_to: checkedRows[1].id,
    }
    setIsMerging(true)
    mergeProperties(data, {
      page: pagination.current_page,
      per_page: pagination.per_page /* , search: searchProp */,
    })
      .then(() => {
        setIsMerging(false)
        setCheckedRows([])
        setSelectedRowKeys([])
        setSearchProp('')
        toggleMergeModal()
        openNotificationWithIcon('success', {
          message: 'Properties have been merged successfully',
          description: '',
          key: 'property-merge-success',
        })
      })
      .catch(() => {
        setIsMerging(false)
      })
  }

  //////////////////////
  const handleTableChange = (pagination, filters, sorter) => {
    fetchPropertiesList({
      page: pagination.current,
      sort_field: sorter.order ? sorter.field : null,
      order:
        sorter.order === 'ascend'
          ? 'asc'
          : sorter.order === 'descend'
          ? 'desc'
          : null,
      per_page: pagination.pageSize,
      search: debouncedSearchValue || undefined,
      ...filters,
    })
    setSortOrder(
      sorter.order === 'ascend'
        ? 'asc'
        : sorter.order === 'descend'
        ? 'desc'
        : null,
    )
    setSortField(sorter.order ? sorter.field : null)
    setTableFilters({ ...filters })
  }

  const renderArray = (array, record, isDate) => {
    if (!array.length) return '-'

    if (record.key === clickedRowIndex) {
      return (
        <div>
          {array?.map(proposal => (
            <p>{isDate ? moment(proposal).format('MM/DD/YYYY') : proposal}</p>
          ))}
        </div>
      )
    }
    return (
      <>
        {isDate ? moment(array[0]).format('MM/DD/YYYY') : array[0]}
        {array?.length > 1 && (
          <span className='rest-items'>+{array.length - 1}</span>
        )}
      </>
    )
  }

  const handleKeyPress = event => {
    if (event.keyCode === 27) {
      setIsFullScreen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [])

  const columns = useMemo(
    () => [
      {
        title: 'Address',
        name: 'Address',
        dataIndex: 'full_address',
        sorter: true,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (text, record) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link to={`/properties-form/${record.id}`} className='link'>
              {text}
            </Link>
            <MembershipLabels memberships={record.memberships} tagSize={15} />
          </div>
        ),
        defaultWidth: 250,
      },
      {
        title: 'Unit',
        name: 'Unit',
        dataIndex: 'unit',
        noFilter: true,
        defaultWidth: 70,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
      },
      {
        title: 'ZIP',
        name: 'ZIP',
        dataIndex: 'postcode',
        sorter: true,
        defaultWidth: 70,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
      },
      {
        title: 'City',
        name: 'City',
        dataIndex: 'city',
        sorter: true,
        defaultWidth: 120,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
      },
      {
        title: 'State',
        name: 'State',
        dataIndex: 'state',
        sorter: true,
        defaultWidth: 120,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
      },
      {
        title: 'Type',
        name: 'Type',
        dataIndex: 'property_type',
        sorter: true,
        defaultWidth: 120,
        render: (text, record) => propertiesType[text],
      },
      {
        title: 'Clients',
        name: 'Clients',
        dataIndex: 'clients',
        sorter: true,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (clients, record) => renderArray(clients, record),
        defaultWidth: 140,
      },
      {
        title: 'Projects',
        name: 'Projects',
        dataIndex: 'proposals',
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        sorter: true,
        defaultWidth: 150,
        render: (proposals, record) => renderArray(proposals, record),
      },
      {
        title: 'Notes',
        name: 'Notes',
        dataIndex: 'notes',
        defaultWidth: 200,
        onCell: record =>
          record.key === clickedRowIndex
            ? { style: { whiteSpace: 'pre-wrap' } }
            : ellipsisStyle,
        render: (notes, record) =>
          TablePopover(notes, record, 'Notes', clickedRowIndex),
      },
      {
        title: 'Appointments',
        name: 'Appointments',
        dataIndex: 'appointments',
        sorter: true,
        ellipsis: true,
        defaultWidth: 140,
        render: (appointments, record) =>
          renderArray(appointments, record, true),
      },
      {
        title: 'Tags',
        name: 'Tags',
        dataIndex: 'tags',
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (tags, record) => {
          if (!tags?.length) return '-'

          if (record.key === clickedRowIndex) {
            return (
              <span>
                {tags?.map(tag => {
                  return (
                    <Tag key={tag} color='geekblue'>
                      {tag}
                    </Tag>
                  )
                })}
              </span>
            )
          }

          return (
            <>
              <Tag color='geekblue'>{tags[0]}</Tag>
              {tags?.length > 1 && (
                <span className='rest-items'>+{tags.length - 1}</span>
              )}
            </>
          )
        },
        defaultWidth: 200,
      },
    ],
    [clickedRowIndex],
  )

  const rowSelection = {
    selectedRowKeys,
    columnWidth: 30,
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedRows(
        selectedRows.map(row => ({
          ...row,
          display_info: row.full_address
            ? row.full_address
            : `${getAddressWithUnit(row)}, ${row.postcode}`,
        })),
      )
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: record => ({
      disabled:
        !checkedRows.map(row => row.id).includes(record.id) &&
        checkedRows?.length === 2, // Column configuration not to be checked
      name: record.name,
    }),
  }

  const onRow = (record, rowIndex) => ({
    onClick: e => {
      setClickedRowIndex(rowIndex)
    },
  })

  const handleExportTable = () => {
    setIsExportButtonDisable(true)
    exportPropertyTable({
      debouncedSearchValue: debouncedSearchValue || undefined,
      sort_field: sortField,
      order: sortOrder,
      ...tableFilters,
      ...additionalFilters,
      columns: JSON.parse(localStorage.getItem('properties-list-table_v1'))
        ?.columns,
    })
      .catch(e => openNotificationWithIcon('error', { message: e?.message }))
      .finally(() => setIsExportButtonDisable(false))
  }

  return (
    <TableLayout
      title='Properties'
      isFullScreen={isFullScreen}
      visible={drawerVisible}
      onClose={() => setDrawerVisible(false)}
      className='properties-layout'
      addButton={
        <MainButton
          style={{ marginRight: 'auto' }}
          title='Add Property'
          onClick={() => {
            history.push('/properties-form', { pagination })
          }}
        />
      }
      actions={
        <ActionsWrapper>
          <TextField
            className='searchMaterialTextField'
            size='small'
            label='Search'
            variant='outlined'
            value={searchProp}
            onChange={e => setSearchProp(e.target.value)}
            InputLabelProps={{ shrink: true }}
            style={{ width: '100%', maxWidth: '300px' }}
          />
          <IconsBlock>
            <IconWrapper
              onKeyDown={handleKeyPress}
              onClick={() => setIsFullScreen(prev => !prev)}
            >
              {isFullScreen ? (
                <FullscreenExitOutlined style={{ fontSize: '22px' }} />
              ) : (
                <FullscreenOutlined style={{ fontSize: '22px' }} />
              )}
            </IconWrapper>
            <TableExport
              handleExportTable={handleExportTable}
              loading={loading}
              currentCount={pagination?.total}
            />

            <IconWrapper
              aria-disabled={checkedRows.length !== 2}
              onClick={toggleMergeModal}
            >
              <Tooltip placement='left' title='Merge properties'>
                <MergeType />
              </Tooltip>
            </IconWrapper>
            <IconWrapper onClick={() => setOpenTableSettings(true)}>
              <Tooltip placement='left' title='Columns settings'>
                <Settings />
              </Tooltip>
            </IconWrapper>
          </IconsBlock>
        </ActionsWrapper>
      }
    >
      <Table
        name='properties-list'
        columns={columns}
        data={properties}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        rowSelection={rowSelection}
        onRow={onRow}
        className='properties-table'
        search={debouncedSearchValue}
        openTableSettings={openTableSettings}
        onCloseTableSettings={() => setOpenTableSettings(false)}
      />

      <SureModal
        modal={showSureModal}
        close={() => {
          toggleSureModal()
        }}
        apply={() => {
          onHandleDeleteProperty()
        }}
      />
      <PropertyModal
        modal={showPropertyModal}
        onHandleClose={togglePropertyModal}
        values={modalValues}
        handleFieldChange={handleFieldChange}
        onSubmit={onHandleSubmit}
        tempTagValue={tempTagValue}
        onAddTag={onAddTag}
        onDeleteTag={onDeleteTag}
        isValid={isValid}
        setIsValid={setIsValid}
        fetchProperty={fetchProperty}
        currentProperty={currentProperty}
        pagination={pagination}
      />
      <MergePropertiesModal
        show={showMergePropertiesModal}
        onHide={toggleMergeModal}
        onHandleMerge={onHandleMerge}
        onSwapItemsToMerge={onSwapItemsToMerge}
        itemsToMerge={checkedRows}
        name='properties'
        isMerging={isMerging}
      />
    </TableLayout>
  )
}

const mapStateToProps = state => ({
  properties: state.orcatec.property.properties,
  pagination: state.orcatec.property?.pagination?.meta,
  currentProperty: state.orcatec.property.currentProperty,
  loading: state.orcatec.property.loading,
})

export default connect(mapStateToProps, {
  fetchPropertiesList: actions.property.fetchPropertiesList,
  addProperty: actions.property.addProperty,
  deleteProperty: actions.property.deleteProperty,
  updateProperty: actions.property.updateProperty,
  searchAddress: actions.property.searchAddress,
  fetchProperty: actions.property.fetchProperty,
  mergeProperties: actions.property.mergeProperties,
})(Index)
