import React from 'react'

import ModalWrapper from '../ModalWrapper/ModalWrapper'
import './ModalConfirmIfThereIsNotSavedNewItems.scss'

const ModalConfirmIfThereIsNotSavedNewItems = props => {
  return (
    <ModalWrapper
      modal={props.show}
      close={props.onSave}
      apply={props.onHide}
      isSaving={false}
      dialogClassName='confirm-unsaved-new-items-modal'
      disableCancelBtn={false}
      disableOkBtn={false}
      cancelText='Save'
      okText='Check'
    >
      <div className='w-100 d-flex justify-content-center'>
        <div className='confirm-unsaved-new-items-modal-title'>You have unsaved items. Please check to proceed.</div>
      </div>
    </ModalWrapper>
  )
}

export default ModalConfirmIfThereIsNotSavedNewItems
