import styled from 'styled-components'
// import ProjectStatus from '../../../ProjectStatus'
import ProjectDetails from './components/ProjectDetails'
import SidebarBlock from './components/SidebarBlock'
import ProjectContacts from './components/ProjectContacts'

export const ProjectSidebar = () => {
  return (
    <Wrapper>
      <ProjectContacts />

      <SidebarBlock title='Project Details'>
        <ProjectDetails />
      </SidebarBlock>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  /* font-size: 12px; */

  /* & > * {
    padding-bottom: 10px;
    &:not(:first-of-type) {
      padding-top: 10px;
    }
  } */
`
// const Section = styled.div`
//   border-bottom: 1px solid #f1f2fd;
// `
