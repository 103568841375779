import React, { useEffect, FC } from 'react'
// import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { FormRow } from 'features/Contacts/common/FormRow'
import { Checkbox, Tooltip } from 'antd'
// import Select from 'containers/MainContent/Orcatec/components/UI/Select'
import SelectField from 'containers/MainContent/Orcatec/components/Select'
import AppointmentTypes from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentTypes'
import AppointmentSources from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentSources'
import { KeyboardDatePicker } from '@material-ui/pickers'
import CustomTimePicker from 'containers/MainContent/Orcatec/components/CustomTimePicker'
// import CustomMultyAutocomplete from 'containers/MainContent/Orcatec/components/CustomMultyAutocomplete'
import Notes from 'containers/MainContent/Orcatec/components/Notes'
import InputField from 'containers/MainContent/Orcatec/components/UI/InputField'
import styled from 'styled-components'
// import { getModuleStatus } from 'store/Orcatec/reducers/settings/subscription'
import { useAppSelector } from 'store/Orcatec/hooks'
// import { useColumns } from 'hooks/useColumns'
import { IAppointment, AppointmentEntityType } from 'types/Appointment'

// import getEndDateAndCounter from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/Appointment/hooks/getEndDateAndCounter'
import { IProperty } from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentPreferredTech/types'
import { selectDispatchSettings } from 'features/Dispatch/dispatchSlice'
import { IContacts } from 'features/Contacts/types'
import { AddIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { onChangeFieldAction } from 'features/Appointment/appointmentSlice'
import { getCallTrackingSettings } from 'api/Calls'
import { Autocomplete } from '@material-ui/lab'
import { useEventAssignToOptions } from 'hooks/useEventAssignToOptions'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  TypeSourcePermissions,
  ModuleName,
} from 'features/Settings/UsersAndGroups'
import useIndustries from 'hooks/useIndustries'

// const weekDays: { [key: number]: string } = {
//   0: 'Sun',
//   1: 'Mon',
//   2: 'Tue',
//   3: 'Wed',
//   4: 'Thu',
//   5: 'Fri',
//   6: 'Sat',
// }
// const types: { [key: number]: string } = {
//   1: 'day',
//   2: 'week',
//   3: 'month',
// }
const Button = ({ handler }) => {
  return (
    <button className='btn add color_gray' onClick={handler}>
      <AddIcon disabled={false} />
    </button>
  )
}

interface IProps {
  appointment: IAppointment
  onChange: (e: { target: { name: keyof IAppointment; value: any } }) => void
  loading: boolean
  error: { [key: keyof IAppointment]: string }
  properties: IProperty[]
  togglePropertyFormHandle: (bool: boolean) => void
  clients: IContacts[]
  contact: IContacts
}
export const AppointmentSection: FC<IProps> = ({
  appointment,
  onChange,
  loading,
  properties,
  togglePropertyFormHandle,
  error,
  clients,
  contact,
  eventSettings,
}) => {
  const dispatch = useDispatch()

  const canEditTypeSource = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.TYPE_SOURCE,
      TypeSourcePermissions.TYPE_SOURCE_CAN_CREATE,
    ),
  )
  // const isRecurringModuleActive = useAppSelector(state =>
  //   getModuleStatus(state, 'recurring_appointments'),
  // )
  const industries = useIndustries()
  const dispatchSettings = useAppSelector(selectDispatchSettings)

  // const owner = useAppSelector(isOwner)
  // const { restrict_crud_source_type } = useAppSelector(
  //   state => state.orcatec.user.me,
  // )
  // const { columns } = useColumns(
  //   appointment.date && moment(appointment.date).isValid()
  //     ? moment(appointment.date).format('MM/DD/YYYY')
  //     : moment().format('MM/DD/YYYY'),
  // )

  const technicians = useAppSelector(state =>
    state.orcatec?.company?.technicians?.map(t => ({
      id: t.id,
      name: t.full_name,
    })),
  )

  const { data: assignToOptions } = useEventAssignToOptions(appointment.date)

  const isQueueChecked =
    Array.isArray(appointment.column_template_id) &&
    appointment.column_template_id.includes(id => typeof id === 'string')
  // appointment.column_template_id.length === 1 &&
  // appointment.column_template_id.includes('queue')
  // const [
  //   firstAppointmentDate,
  //   totalEntries,
  //   lastAppointmentDate,
  // ] = getEndDateAndCounter(
  //   appointment?.is_recurring,
  //   appointment?.start_date,
  //   appointment?.ending_options?.value,
  //   +appointment?.repeat_every,
  //   appointment?.each?.map(el => +el),
  //   appointment?.interval,
  //   +appointment?.ending_options?.end_type === 2
  //     ? +appointment?.ending_options?.value
  //     : null,
  // )
  // const techColumns = columns
  //   .map(column => ({
  //     id: column.column_template_id,
  //     name: column.main_tech_name || column.title,
  //   }))
  //   .sort((a, b) => a.name.localeCompare(b.name))
  // const handleChangeReccuring = (isReccuring: boolean) => {
  //   if (!isRecurringModuleActive) {
  //     return openNotificationWithIcon('info', {
  //       message: `Module is off`,
  //       description:
  //         'Please turn on Recurring Appointments module in your subscription settings',
  //     })
  //   }

  //   onChange({
  //     target: {
  //       name: 'is_recurring',
  //       value: isReccuring,
  //     },
  //   })
  // }
  // const getInputValue = (array: (string | number)[]) => {
  //   const arrayLength = array?.length
  //   return arrayLength
  //     ? arrayLength === 1
  //       ? getOptionLabel(array?.[0])
  //       : `${getOptionLabel(array?.[0])} + ${arrayLength - 1} selected`
  //     : 'Select technician'
  // }
  // const getOptionLabel = (id: number | string) => {
  //   const user = [...techColumns, { id: 'queue', name: 'Queue' }, { id: 'misc', name: 'Misc' }]?.find(u => u?.id === id)
  //   return user ? `${user?.name}` : ''
  // }
  // const handleChangeMultipleTechs = (e: any, value: (number | string)[]) => {
  //   if (value?.length > 1 && value?.[0] === 'queue') {
  //     const filteredValue = value?.filter(el => el !== 'queue')
  //     value = filteredValue
  //   }
  //   onChange({ target: { name: 'column_template_id', value: value } })
  // }

  const renderTags = (value: (string | number)[]) => {
    return (
      <p style={{ paddingLeft: 10, color: '#000' }}>
        {assignToOptions.find(option => option.id === value[0])?.name}
        <span style={{ fontSize: 12 }}>
          {value?.length > 1 ? ` + ${value.length - 1} selected` : ''}
        </span>
        {'\xa0\xa0'}
      </p>
    )
  }

  const handleChangeAssignTo = (
    e: unknown,
    value: string | number | (string | number)[],
  ) => {
    onChange({
      target: {
        name: 'column_template_id',
        value,
      },
    })
  }

  // const handleChangeInterval = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = e.target
  //   onChange({
  //     target: {
  //       name: 'interval',
  //       value,
  //     },
  //   })

  //   onChange({
  //     target: {
  //       name: 'each',
  //       value: [],
  //     },
  //   })
  // }
  // const handleChangeEvery = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target

  //   const onlyNums = value.replace(/[^0-9]/g, '').replace(/^0+/, '')

  //   onChange({
  //     target: {
  //       name,
  //       value: onlyNums,
  //     },
  //   })
  // }

  // const handleChangeEach = (e: unknown, selectedValues: unknown[]) => {
  //   onChange({
  //     target: {
  //       name: 'each',
  //       value: selectedValues.sort((a, b) => a - b),
  //     },
  //   })
  // }
  // const handleChangeEndingOptionsType = (e: RadioChangeEvent) => {
  //   onChange({
  //     target: {
  //       name: 'ending_options',
  //       value: {
  //         end_type: e.target.value,
  //         value: e.target.value === 2 ? 1 : false,
  //       },
  //     },
  //   })
  // }
  // const handleChangeEndingOptionsDate = (
  //   date: MaterialUiPickersDate,
  //   value?: string | null | undefined,
  // ) => {
  //   onChange({
  //     target: {
  //       name: 'ending_options',
  //       value: {
  //         end_type: appointment.ending_options.end_type,
  //         value: moment(value).format('YYYY-MM-DD'),
  //       },
  //     },
  //   })
  // }

  // const handleChangeEndingOptionsOccurrences = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   onChange({
  //     target: {
  //       name: 'ending_options',
  //       value: {
  //         end_type: appointment.ending_options.end_type,
  //         value: e.target.value,
  //       },
  //     },
  //   })
  // }

  // const handleChangeMakeFirstAppointment = (e: {
  //   target: { checked: boolean }
  // }) => {
  //   onChange({
  //     target: {
  //       name: 'make_first_appointment',
  //       value: e.target.checked,
  //     },
  //   })
  // }

  const handleChangeEntityType = () => {
    onChange({
      target: {
        name: 'entity_type',
        value:
          appointment.entity_type === AppointmentEntityType.CLIENT
            ? AppointmentEntityType.PROPERTY
            : AppointmentEntityType.CLIENT,
      },
    })
    onChange({
      target: {
        name: 'entity_id',
        value:
          appointment.entity_type === AppointmentEntityType.CLIENT
            ? properties?.[0]?.id
            : contact?.id,
      },
    })
  }

  useEffect(() => {
    //set default value for industry field
    dispatch(
      onChangeFieldAction({
        field: 'industry_id',
        value: eventSettings?.industry_id || industries?.[0]?.id,
      }),
    )
  }, [eventSettings])

  useEffect(() => {
    //set default value for appointment location field
    if (!properties?.length) {
      dispatch(onChangeFieldAction({ field: 'entity_id', value: contact?.id }))
      dispatch(
        onChangeFieldAction({
          field: 'entity_type',
          value: AppointmentEntityType.CLIENT,
        }),
      )
    } else {
      dispatch(
        onChangeFieldAction({ field: 'entity_id', value: properties?.[0]?.id }),
      )
      dispatch(
        onChangeFieldAction({
          field: 'entity_type',
          value: AppointmentEntityType.PROPERTY,
        }),
      )
    }
  }, [])

  useEffect(() => {
    if (clients?.length) {
      dispatch(
        onChangeFieldAction({
          field: 'primary_entity_type',
          value: clients?.[0]?.type,
        }),
      )
      dispatch(
        onChangeFieldAction({
          field: 'primary_entity_id',
          value: clients?.[0]?.id,
        }),
      )
    }
  }, [clients])

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const res = await getCallTrackingSettings()
        if (!appointment?.new && res?.[0]?.use_existing_client_source) {
          dispatch(
            onChangeFieldAction({
              field: 'appointment_source_id',
              value: res?.[0]?.existing_client_source_id,
            }),
          )
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchSettings()
  }, [])

  return (
    <Wrapper>
      {/* <FormRow title='Recurring'>
        <Switch
          checked={appointment.is_recurring}
          onChange={handleChangeReccuring}
          disabled={appointment.id}
        />
      </FormRow> */}

      <FormRow loading={loading} title='Appointment location'>
        <LocationRow>
          <SelectField
            name='entity_id'
            style={{ width: '100%' }}
            value={appointment?.entity_id}
            options={
              properties?.map(({ id, full_address }) => ({
                id,
                name: full_address,
              })) || []
            }
            onChange={onChange}
            disabled={
              !properties?.length ||
              appointment.entity_type === AppointmentEntityType.CLIENT
            }
          />

          <Tooltip placement='left' title='Add new property'>
            <Button handler={() => togglePropertyFormHandle(true)} />
          </Tooltip>
        </LocationRow>
        <LocationRowControls>
          <Checkbox
            name='entity_type'
            checked={appointment.entity_type === AppointmentEntityType.CLIENT}
            onChange={handleChangeEntityType}
            disabled={!properties?.length}
          >
            Appointment is going to take in my office
          </Checkbox>
        </LocationRowControls>
      </FormRow>
      <FormRow
        isHide={industries?.length <= 1}
        loading={loading}
        title='Industry'
      >
        <SelectField
          options={industries}
          value={appointment?.industry_id}
          name='industry_id'
          onChange={onChange}
          // noValue
          hideDisabled
          // error={error}
          // helperText={error?.preferred_technician_id}
          //
          style={{ width: '100%' }}
        />
      </FormRow>
      <FormRow loading={loading} title='Appointment Type'>
        <AppointmentTypes
          name='appointment_type_id'
          value={appointment?.appointment_type_id}
          onChange={onChange}
          // error={error}
          isLead={appointment?.is_lead}
          required={false}
          appointmentId={appointment?.id}
          disableCRUD={!canEditTypeSource}
          withoutLabel
        />
      </FormRow>
      <FormRow loading={loading} title='Appointment Source'>
        <AppointmentSources
          name='appointment_source_id'
          value={appointment?.appointment_source_id}
          onChange={onChange}
          // error={error}
          required={false}
          appointmentId={appointment?.id}
          disableCRUD={!canEditTypeSource}
          withoutLabel
        />
      </FormRow>
      <FormRow loading={loading} title='Preferred tech'>
        <SelectField
          options={[
            ...technicians,
            { id: 0, name: 'No Preferred User', disabled: false },
          ]}
          value={appointment?.preferred_technician_id || 0}
          name='preferred_technician_id'
          onChange={onChange}
          // noValue
          hideDisabled
          // error={error}
          // helperText={error?.preferred_technician_id}

          style={{ width: '100%' }}
        />
      </FormRow>

      <FormRow
        loading={loading}
        isHide={!clients?.length}
        title='Primary client'
      >
        <SelectField
          options={clients}
          value={appointment?.primary_entity_id || clients?.[0]?.id}
          name='primary_entity_id'
          onChange={e => {
            onChange(e)
            onChange({
              target: {
                name: 'primary_entity_type',
                value: AppointmentEntityType.CLIENT,
              },
            })
          }}
          style={{ width: '100%' }}
        />
      </FormRow>

      <FormRow
        isHide={appointment?.is_recurring}
        required={!!appointment.assign_to_matrix}
        loading={loading}
        title='Appointment date'
      >
        <KeyboardDatePicker
          clearable
          autoOk
          placeholder='Select date'
          value={appointment.date}
          // label='Appointment Date'
          inputVariant='outlined'
          format='MM/DD/YYYY'
          size='small'
          style={{ width: '155px' }}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!error?.date}
          helperText={error?.date}
          onChange={(date, value) =>
            onChange({ target: { name: 'date', value } })
          }
          // required={!!appointment.assign_to_matrix}
          cancelLabel=''
          okLabel=''
          className='appointment-datepicker'
          // minDate={start}
          // maxDate={end}
        />
      </FormRow>
      <FormRow
        loading={loading}
        required={!!appointment.assign_to_matrix}
        title='Promised arrival time'
      >
        <PickerRow>
          <CustomTimePicker
            // title='Promised arrival time:'
            // label='From'
            name='time_start'
            onChange={(value, valueNum, name) =>
              onChange({ target: { value, name } })
            }
            value={appointment?.time_start}
            // endWorkTime={settings_matrix?.end_hour}
            // startWorkTime={settings_matrix?.start_hour}
            endWorkTime={22}
            startWorkTime={0}
            error={error?.time_start}
            required={!!appointment.assign_to_matrix}
            placeholder='Select time'
            withMinute={true}
            // showTimeFrom={matrixLog.time_start}
            short={!error?.time_start}
            step={eventSettings?.promised_time_increment}
          />
          <CustomTimePicker
            // title='Promised arival time'
            //label='To'
            name='time_end'
            onChange={(value, valueNum, name) =>
              onChange({ target: { value, name } })
            }
            value={appointment?.time_end}
            // endWorkTime={settings_matrix?.end_hour}
            // startWorkTime={settings_matrix?.start_hour}
            endWorkTime={23}
            startWorkTime={0}
            error={error?.time_end}
            required={!!appointment.assign_to_matrix}
            placeholder='Select time'
            withMinute={true}
            // showTimeFrom={matrixLog.time_start}
            short={!error?.time_end}
            step={eventSettings?.promised_time_increment}
          />
        </PickerRow>
      </FormRow>
      <FormRow
        isHide={appointment?.is_recurring}
        required={!!appointment.assign_to_matrix}
        loading={loading}
        title='Assigned to'
      >
        {' '}
        {/*  {appointment?.id ? (
          <SelectField
            options={[...technicians, { id: 0, name: 'Queue' }, { id: 'misc', name: 'Misc' }]}
            // label='Assigned to'
            value={appointment?.appointed_technician_id}
            name='appointed_technician_id'
            onChange={onChange}
            hideDisabled
            error={error}
            // helperText={error?.appointed_technician_id}
            required={!!appointment.assign_to_matrix}
          />
        ) : (
          <CustomMultyAutocomplete
            // label='Assigned to'
            name='column_template_id'
            onChange={handleChangeMultipleTechs}
            // error={
            //   !appointment?.appointed_technician_id?.length
            //     ? { appointed_technician_id: 'This field is required' }
            //     : undefined
            // }
            value={appointment?.column_template_id}
            filterOptions={(x: number) => x}
            options={[{ id: 'queue', name: 'Queue' }, { id: 'misc', name: 'Misc' }, ...techColumns]?.map(
              user => user?.id,
            )}
            getOptionLabel={getOptionLabel}
            checkIsChoosen={(id: number) => appointment?.column_template_id?.includes(id)}
            inputValue={getInputValue(appointment.column_template_id)}
          />
        )} */}
        <Autocomplete
          multiple={!appointment.id && !appointment.is_recurring}
          options={assignToOptions
            ?.filter(option =>
              appointment.is_recurring ? option.source === 'Queues' : option,
            )
            ?.map(option => option.id)}
          getOptionLabel={option =>
            assignToOptions.find(o => o.id === option)?.name || ''
          }
          renderInput={params => (
            <InputField
              {...params}
              error={error?.column_template_id || error?.queue_id}
              helperText={error?.column_template_id || error?.queue_id}
              name='column_template_id'
            />
          )}
          value={appointment.column_template_id || ''}
          getOptionSelected={(option, value) => option === value}
          renderTags={renderTags}
          onChange={handleChangeAssignTo}
          groupBy={option =>
            assignToOptions.find(o => o.id === option)?.source || ''
          }
          disableCloseOnSelect={!appointment.id && !appointment.is_recurring}
          disableClearable
          size='small'
        />
      </FormRow>
      <FormRow
        isHide={!appointment?.is_recurring}
        loading={loading}
        title='Start date'
      >
        <KeyboardDatePicker
          clearable
          autoOk
          placeholder='Select date'
          value={appointment?.start_date}
          inputVariant='outlined'
          format='MM/DD/YYYY'
          size='small'
          style={{ width: '155px' }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(date, value) =>
            onChange({ target: { name: 'start_date', value } })
          }
          required
          error={!!error?.start_date}
          helperText={error?.start_date}
          disablePast
          disabled={!!appointment.id}
          cancelLabel=''
          okLabel=''
          className='appointment-datepicker'
          // minDate={start}
          // maxDate={end}
        />
      </FormRow>

      {/* <FormRow
        loading={loading}
        required={appointment?.is_recurring}
        isHide={!appointment?.is_recurring}
        title='Interval'
      >
        <Select
          // label='Interval'
          name='interval'
          value={appointment?.interval}
          onChange={handleChangeInterval}
          options={[
            { id: 1, name: 'Day' },
            { id: 2, name: 'Week' },
            { id: 3, name: 'Month' },
          ]}
          required={true}
          disabled={!!appointment.id}
        />
      </FormRow>
      <FormRow
        loading={loading}
        required={appointment?.is_recurring}
        isHide={!appointment?.is_recurring}
        title={`Every # of ${types[appointment?.interval || 1]}(s)`}
      >
        <InputField
          name='repeat_every'
          value={appointment.repeat_every}
          onChange={handleChangeEvery}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            if (!appointment?.repeat_every) {
              handleChangeEvery({
                ...e,
                target: { ...e.target, name: 'repeat_every', value: '1' },
              })
            }
          }}
          error={!!error?.repeat_every}
          helperText={error?.repeat_every}
          disabled={!!appointment?.id}
        />
      </FormRow>
      <FormRow
        loading={loading}
        required={appointment?.is_recurring && appointment?.interval !== 1}
        isHide={!(appointment?.interval !== 1 && appointment?.is_recurring)}
        title={`On what day(s) of the ${types[appointment?.interval || 2]} `}
      >
        <CustomMultyAutocomplete
          name='each'
          onChange={handleChangeEach}
          value={appointment?.each}
          error={error}
          options={
            appointment?.interval === 2
              ? Object.keys(weekDays)
              : Array.from({ length: 30 }, (_, i) => i + 1)
          }
          getOptionLabel={id =>
            appointment?.interval === 2 ? weekDays[+id] : id
          }
          checkIsChoosen={id => appointment?.each?.includes(+id)}
          inputValue={
            appointment?.each?.length
              ? appointment?.interval === 2
                ? appointment?.each.map(id => weekDays[id]).join(', ')
                : appointment?.each.join()
              : 'Select options'
          }
          disabled={!!appointment?.id}
        />
      </FormRow>
      <FormRow
        loading={loading}
        required={appointment?.is_recurring}
        isHide={!appointment?.is_recurring || !appointment?.start_date}
        title='Ending options'
      >
        <EndingOptRow>
          <Radio.Group
            onChange={handleChangeEndingOptionsType}
            value={appointment?.ending_options?.end_type}
            className='ending-options'
            disabled={!!appointment?.id}
          >
            <Radio value={1}>
              <KeyboardDatePicker
                autoOk
                value={
                  appointment?.ending_options?.end_type === 1
                    ? appointment?.ending_options.value
                      ? moment(appointment.ending_options.value)
                      : null
                    : null
                }
                label='End Date'
                inputVariant='outlined'
                format='MM/DD/YYYY'
                size='small'
                required={appointment.ending_options?.end_type === 1}
                InputLabelProps={{
                  shrink: true,
                }}
                shouldDisableDate={day =>
                  !!day?.isBefore(appointment?.start_date)
                }
                onChange={handleChangeEndingOptionsDate}
                disabled={
                  !!appointment.id || appointment.ending_options?.end_type !== 1
                }
                error={
                  !!error?.ending_options &&
                  appointment.ending_options?.end_type === 1
                }
              />
            </Radio>

            <Radio value={2}>
              <InputField
                label='End after # of occurrences'
                name='ending_options'
                value={
                  appointment.ending_options?.end_type === 2
                    ? appointment.ending_options.value
                    : 1
                }
                onChange={handleChangeEndingOptionsOccurrences}
                disabled={
                  !!appointment.id || appointment.ending_options?.end_type !== 2
                }
                error={
                  appointment.ending_options?.end_type === 2
                    ? error?.ending_options
                    : null
                }
              />
            </Radio>

            <Radio value={3}>Repeat until canceled</Radio>
          </Radio.Group>
        </EndingOptRow>
      </FormRow>
      <FormRow
        loading={loading}
        isHide={
          !appointment?.is_recurring ||
          (!appointment.interval !== 1 &&
            moment(appointment?.start_date).format('YYYMMDD') !==
              moment(firstAppointmentDate).format('YYYMMDD'))
        }
        title='Make appointment on start date'
      >
        <Checkbox
          checked={appointment.make_first_appointment}
          onChange={handleChangeMakeFirstAppointment}
          disabled={!!appointment.id}
        ></Checkbox>
      </FormRow>

      <FormRow
        loading={loading}
        isHide={
          !(
            appointment.start_date &&
            ((appointment.ending_options?.end_type === 1 &&
              totalEntries &&
              totalEntries >= 0) ||
              (appointment.ending_options?.end_type === 2 &&
                !!lastAppointmentDate) ||
              (appointment.ending_options?.end_type === 3 &&
                !!firstAppointmentDate))
          )
        }
      >
        <p>{`Every ${
          appointment.repeat_every > 1 ? appointment.repeat_every : ''
        } ${types[appointment.interval || 1]} on ${
          appointment.interval === 2
            ? appointment?.each?.map(id => weekDays[id])?.join(', ')
            : appointment?.each
                ?.map(a => a)
                ?.sort((a, b) => a - b)
                ?.join(', ')
        } from ${moment(
          appointment.make_first_appointment
            ? appointment.start_date
            : firstAppointmentDate,
        ).format('MM/DD/YYYY')} until ${
          appointment.ending_options?.end_type === 3
            ? 'canceled'
            : moment(lastAppointmentDate).format('MM/DD/YYYY')
        }${
          appointment.ending_options?.end_type !== 3
            ? `, Total: ${
                appointment.make_first_appointment &&
                moment(appointment?.start_date).format('YYYMMDD') !==
                  moment(firstAppointmentDate).format('YYYMMDD')
                  ? '(1) + '
                  : ''
              }${totalEntries} occurrences.`
            : ''
        }`}</p>
      </FormRow> */}

      <FormRow
        loading={loading}
        isHide={appointment?.is_recurring || isQueueChecked}
        required={appointment.assign_to_matrix}
        title='Dispatch time'
      >
        <PickerRow>
          {' '}
          <CustomTimePicker
            // label='From'
            name='matrix_time_start'
            // title='Dispatch time:'
            onChange={(value, valueNum, name) =>
              onChange({ target: { value: valueNum, name } })
            }
            value={appointment.matrix_time_start}
            endWorkTime={dispatchSettings.end_hour - 1}
            startWorkTime={dispatchSettings.start_hour}
            error={error?.matrix_time_start}
            required={!!appointment.assign_to_matrix}
            // disabledTime={disabledTime?.matrixTimeStart}
            placeholder='Select time'
            // showTimeFrom={matrixLog.time_start}
            short={!error?.matrix_time_start}
            style={{ width: '130px' }}
          />
          <CustomTimePicker
            //label='To'
            name='matrix_time_end'
            onChange={(value, valueNum, name) =>
              onChange({ target: { value: valueNum, name } })
            }
            value={appointment.matrix_time_end}
            endWorkTime={dispatchSettings.end_hour}
            startWorkTime={dispatchSettings.start_hour}
            error={error?.matrix_time_end}
            required={!!appointment.assign_to_matrix}
            // disabledTime={disabledTime?.matrixTimeEnd}
            placeholder='Select time'
            // showTimeFrom={matrixLog.time_start}
            short={!error?.matrix_time_end}
            style={{ width: '130px' }}
          />
        </PickerRow>
      </FormRow>

      <FormRow loading={loading} title='Notes'>
        {' '}
        {appointment?.id ? (
          <Notes
            label='Appointment Notes'
            route={`/appointments/${appointment.id}/notes`}
          />
        ) : (
          <InputField
            name='notes'
            value={appointment?.notes}
            onChange={onChange}
            multiline
            // placeholder='Type notes...'
            maxRows={5}
            inputProps={{ maxLength: 3000 }}
            className='appointment-notes select-appt'
          />
        )}
      </FormRow>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & .location__wrapper {
    display: flex;
    align-items: baseline;
    gap: 10px;
    padding: 0 10px;
    margin: 0;
  }

  & .custom_time-picker {
    width: 140px;
    min-width: 140px;
    max-width: 140px;
  }
  & .MuiFormHelperText-marginDense {
    font-size: 8px;
  }
  & .appointment-datepicker .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .appointment-datepicker {
    & button {
      padding: 0;
    }
  }
`
const PickerRow = styled.div`
  display: flex;
  gap: 10px;
`
// const EndingOptRow = styled.div`
//   & .ending-options {
//     display: flex;
//     flex-direction: column;
//     gap: 10px;
//   }
// `

const LocationRow = styled.div`
  display: flex;
  align-items: center;
  & .locaton-icon {
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      transform: scale(1.1);
      transition: all 0.2s;
      fill: var(--main-color);
    }
  }
`
const LocationRowControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`
