import * as types from '../../types'

export default (state = {}, action) => {
  switch (action.type) {
    case types.websiteBuilder.PAGE_LOCAL_SAVE:
      return action.data
    default:
      return state
  }
}
