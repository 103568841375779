import { useMemo } from 'react'
import { Divider, Skeleton, Empty, Collapse, Tooltip } from 'antd'
import { NotificationWrapper, SkeletonWrapper } from '../ModalLogSheets.styles'

import {
  parsePhoneNumber,
  parsePhoneNumberBeforeSend,
  MESSAGING_SERVICE_IS_NOT_SETUP_TEXT,
} from 'features/Messaging/utils'

import { useAppSelector } from 'store/Orcatec/hooks'
import { isBrandSubmitted } from 'store/Orcatec/selectors/messaging'
import {
  HistoryHeader,
  HistoryBody,
  IPropsHistoryItem,
} from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentNotificationHistory/AppointmentNotificationHistory'
import { IconsWrapper } from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentNotificationHistory/AppointmentNotificationHistory.style'
import moment from 'moment-timezone'
import { Sms, Email } from '@material-ui/icons'
import { ScheduleSendIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'

const { Panel } = Collapse
enum TypeMessage {
  SMS = 'sms',
  EMAIL = 'email',
}

interface IProps {
  apptIds: number[]
  loading: boolean
  history: IPropsHistoryItem[]
}
const parsePhoneAndInserName = (phone, tech) => {
  const d = tech?.find(t => parsePhoneNumberBeforeSend(t?.phone) === phone)
  return d?.full_name || parsePhoneNumber(phone)
}
const expandIconPosition = 'start'
export const Notification = ({ apptIds, loading, history }: IProps) => {
  const isBrandChecked: boolean = useAppSelector(isBrandSubmitted)
  const tech = useAppSelector(state => state.orcatec.company.technicians)
  const timezone = useAppSelector(
    s => s.orcatec?.company?.timezone || moment.tz.guess(),
  )
  const formattedDate = (send_at: string) => {
    return moment
      .utc(send_at)
      .tz(timezone)
      .format('MM/DD/YYYY hh:mm A ')
  }
  const dateFormat = 'YYYYMMDDhhmmss'

  const memoizedHistoryList = useMemo(() => {
    const sms = history?.map(s => ({
      ...s,
      from: parsePhoneNumber(s.from),
      formattedDate: formattedDate(s.send_at),
      formattedDateCreate: formattedDate(s.created_at),
      typeMessage: TypeMessage.SMS,
      recipients: [
        ...s?.recipients?.clients?.map(phone =>
          parsePhoneAndInserName(phone, tech),
        ),
        ...s?.recipients?.other?.map(parsePhoneNumber),
      ].join(', '),
    }))

    return sms?.sort(
      (a, b) =>
        +moment(b.send_at).format(dateFormat) -
        +moment(a.send_at).format(dateFormat),
    )
  }, [history])
  return isBrandChecked ? (
    <NotificationWrapper>
      <Divider style={{ margin: '10px 0' }} />
      {loading ? (
        apptIds.map(e => (
          <SkeletonWrapper key={e}>
            <Skeleton.Avatar active={true} size={'default'} shape={'circle'} />
            <Skeleton.Input active={true} size={'default'} />
          </SkeletonWrapper>
        ))
      ) : memoizedHistoryList?.length ? (
        memoizedHistoryList.map((item, idx) => (
          <Collapse
            style={{ width: '100%' }}
            expandIconPosition={expandIconPosition}
            key={idx}
          >
            <Panel
              header={<HistoryHeader {...item} />}
              key='1'
              extra={
                <IconsWrapper>
                  <span>
                    {item?.typeMessage === TypeMessage.SMS ? (
                      <Sms />
                    ) : (
                      <Email />
                    )}
                  </span>{' '}
                  {!item?.is_sent && (
                    <Tooltip
                      zIndex={999999999}
                      title={`Notification will be sent at: ${item?.formattedDate} `}
                    >
                      <span>
                        <ScheduleSendIcon
                          color={'#4c53ef'}
                          width={22}
                          height={22}
                        />
                      </span>
                    </Tooltip>
                  )}
                </IconsWrapper>
              }
            >
              <HistoryBody key={idx} {...item} />
            </Panel>
          </Collapse>
        ))
      ) : (
        <Empty style={{ width: '100%' }} />
      )}
    </NotificationWrapper>
  ) : (
    <MESSAGING_SERVICE_IS_NOT_SETUP_TEXT />
  )
}
