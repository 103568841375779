import React from 'react'
import SpinnerIcon from '../../static/icons/spinner'

const Spinner = () => {
  return (
    <div className='admin-spinner'>
      <SpinnerIcon fill='#4c53ef' />
    </div>
  )
}

export default Spinner
