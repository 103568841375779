import * as types from '../types'

export default (state = { properties: [], currentProperty: {}, loading: false }, action) => {
  switch (action.type) {
    case types.property.PROPERTY_SET_ITEM: {
      let { data, ...rest } = action.item
      if (!Array.isArray(data)) {
        data = [...Object.values(data)]
      }
      return {
        ...state,
        loading: false,
        properties: data.map((property, index) => ({ ...property, key: index })),
        pagination: { ...rest },
      }
    }
    case types.property.PROPERTY_SET_CURRENT_ITEM:
      return { ...state, currentProperty: action.item }
    case types.property.FETCH_PROPERTIES:
      return { ...state, loading: true }
    case types.property.PROPERTY_ADD_ITEM:
      return [action.item, ...state]
    case types.property.PROPERTY_DELETE_ITEM:
      return state.filter(property => property.id !== action.id)
    case types.property.PROPERTY_UPDATE_ITEM: {
      return state.map(property => (action.id === property.id ? { id: action.id, ...action.data } : property))
    }

    case types.property.PROPERTY_GET_ITEM:
      return state
    default:
      return state
  }
}
