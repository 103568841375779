import React, { useEffect, useState } from 'react'
import { Polyline } from 'react-leaflet'
import { fetchPath } from './../../../../../../api/map'

const Route = ({ waypoints, polyLineColor = 'black', setDuration }) => {
  const [routeWaypoints, setRouteWaypoints] = useState([])

  useEffect(() => {
    const filteredWaypoints = waypoints.filter(point => point[0] && point[1])
    if (filteredWaypoints.length) {
      const waypointsString = filteredWaypoints.reduce(
        (acc, current, idx, array) => {
          return `${acc}${String(current[1]).trim()},${String(
            current[0],
          ).trim()}${idx !== array.length - 1 ? ';' : ''}`
        },
        '',
      )

      // setLoading(true)
      fetchPath(waypointsString).then(response => {
        setRouteWaypoints(
          response.routes
            ? response.routes[0].legs.map(item =>
                item.steps
                  .map(step =>
                    step.intersections.map(item => [
                      item.location[1],
                      item.location[0],
                    ]),
                  )
                  .flat(1),
              )
            : [],
        )

        if (setDuration && response.routes.length) {
          setDuration(response.routes[0].duration)
        }
      })
      // .finally(() => setLoading(false))
    }
  }, [waypoints])

  return (
    <>
      {routeWaypoints.map((point, idx) => (
        <Polyline key={idx} color={polyLineColor} positions={point} />
      ))}
    </>
  )
}

export default Route
