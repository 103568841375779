import {
  PdfIcon,
  BaseIcon,
  McWordIcon,
  McExelIcon,
  McPptIcon,
  VideoIcon,
  AudioIcon,
  TxtIcon,
  ImgIcon,
  UrlIcon,
} from '../../../../containers/MainContent/Orcatec/components/Icons/CommonIcons'

export const checkUrlFormat = path => {
  if (/\.(?:pdf|PDF)/.test(path))
    return {
      type: 'iframe',
      isCheck: true,
      title: 'PDF Document',
      icon: <PdfIcon />,
    }
  else if (path?.includes('blob'))
    return {
      type: 'iframe',
      isCheck: true,
      title: 'PDF Document',
      icon: <PdfIcon />,
    }
  else if (/\.(?:docx|DOCX|dotx|doc|DOC)/.test(path))
    return {
      type: 'msdoc',
      isCheck: true,
      title: 'Microsoft Word',
      icon: <McWordIcon />,
    }
  else if (/\.(?:txt|TXT)/.test(path))
    return {
      type: 'iframe',
      isCheck: true,
      title: 'Text Document',
      icon: <TxtIcon />,
    }
  else if (/\.(?:html|HTML)/.test(path))
    return { type: 'link', isCheck: false, title: 'link', icon: <UrlIcon /> }
  else if (/\.(?:xlsx|XLSX|xls|XLS)/.test(path))
    return {
      type: 'msdoc',
      isCheck: true,
      title: 'Microsoft Exel',
      icon: <McExelIcon />,
    }
  else if (/\.(?:pptx|PPTX|ppt|PPT)/.test(path))
    return {
      type: 'msdoc',
      isCheck: true,
      title: 'Microsoft PowerPoint',
      icon: <McPptIcon />,
    }
  else if (/\.(?:mp3|MP3|wav|WAV|aac|AAC)/.test(path))
    return { type: 'audio', isCheck: true, title: 'Audio', icon: <AudioIcon /> }
  else if (/\.(?:mp4|MP4|3gpp|webm|ogg|ogmm)/.test(path))
    return { type: 'video', isCheck: true, title: 'Video', icon: <VideoIcon /> }
  else if (
    /\.(?:jpg|JPG|png|PNG|gif|svg|SVG|ico|ICO|jpeg|JPEG|bmp|BMP|webp|WebP|WEBP|gif|GIF)/.test(
      path,
    )
  )
    return { type: 'image', isCheck: true, title: 'Image', icon: <ImgIcon /> }
  else if (
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      path,
    )
  )
    return { type: 'link', isCheck: false, title: 'link', icon: <UrlIcon /> }
  else return { type: null, isCheck: false, title: 'Link', icon: <BaseIcon /> }
}
