import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createProposal } from 'api/Proposal'
import AppointmentSources from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentSources'
import { FormRow } from 'features/Contacts/common/FormRow'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { default as Select } from 'containers/MainContent/Orcatec/components/Select'
import {
  selectCurrentContact,
  addNewProject,
} from 'features/Contacts/slice/contactSlice'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  TypeSourcePermissions,
  ModuleName,
} from 'features/Settings/UsersAndGroups'
import styled from 'styled-components'

const ControlsRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: end;
  gap: 10px;
`

export const ProjectForm = ({ onCancel }) => {
  const dispatch = useDispatch()
  const industriesList = useSelector(
    state => state.orcatec.user?.me?.industries,
  )
  const currentContact = useSelector(selectCurrentContact)
  const technicians = useSelector(state =>
    state.orcatec.company.technicians?.map(tech => ({
      id: tech.id,
      name: tech.full_name,
    })),
  )
  const me = useSelector(state => state.orcatec.user.me)

  const canEditTypeSource = useSelector(
    selectUserPermissionsByName(
      ModuleName.TYPE_SOURCE,
      TypeSourcePermissions.TYPE_SOURCE_CAN_CREATE,
    ),
  )

  const [industries] = useState(industriesList)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [proposalData, setProposalData] = useState({
    industry_id: industries?.[0]?.id,
    source_id: '',
    status: 1,
    total: 0,
    user_id: me.id,
    client_id: currentContact.id,
  })

  const handleChangeFields = e => {
    const { name, value } = e.target

    if (error) setError(null)

    setProposalData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleCreateProposal = async () => {
    setLoading(true)
    try {
      const resp = await createProposal(proposalData)
      dispatch(addNewProject({ ...resp, total_to_pay: 0 }))
      onCancel()
      openNotificationWithIcon('success', {
        message: 'Projectt has been added',
      })
    } catch (error) {
      setError(error?.response?.data)
      openNotificationWithIcon('error', {
        message: error?.response?.data?.message || error?.message,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='create-proposal'>
      <FormRow title='User for Project ID'>
        <Select
          options={technicians}
          label='User for Project ID'
          value={proposalData?.user_id}
          name='user_id'
          onChange={handleChangeFields}
        />
      </FormRow>

      <FormRow title='Lead Source'>
        <AppointmentSources
          label='Lead Source'
          name='source_id'
          value={proposalData?.source_id}
          onChange={handleChangeFields}
          getFirst
          required
          error={error}
          disableCRUD={!canEditTypeSource}
        />
      </FormRow>
      <FormRow title='Industry'>
        <Select
          label='Industry'
          name='industry_id'
          value={proposalData?.industry_id}
          onChange={handleChangeFields}
          options={industries}
        />
      </FormRow>

      <ControlsRow>
        <MainButton title='Cancel' type='cancel' onClick={onCancel} />
        <MainButton
          title='Create'
          onClick={handleCreateProposal}
          isFetching={loading}
        />
      </ControlsRow>
    </div>
  )
}
