

export const IconSubscriptionBtn = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='exclamation-circle'
    className='svg-inline--fa fa-exclamation-circle fa-w-16'
    role='img'
    viewBox='0 0 512 512'
  >
    <path
      fill='currentColor'
      d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'
    />
  </svg>
)

export const PlayIcon = ({ width = 24, height = 24, fill = 'none' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" >
    <path d="M20.4086 9.35258C22.5305 10.5065 22.5305 13.4935 20.4086 14.6474L7.59662 21.6145C5.53435 22.736 3 21.2763 3 18.9671L3 5.0329C3 2.72368 5.53435 1.26402 7.59661 2.38548L20.4086 9.35258Z" stroke={fill} stroke-width="1.5" />
  </svg>)


export const StopIcon = ({ width = 24, height = 24, color = '#e53655' }) => (
  <svg width={width} height={height} viewBox="0 0 512 512">
    <path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM352 328c0 13.2-10.8 24-24 24h-144C170.8 352 160 341.2 160 328v-144C160 170.8 170.8 160 184 160h144C341.2 160 352 170.8 352 184V328z" fill={color}>
    </path>
  </svg>)