import React from 'react'
import { default as Select } from 'containers/MainContent/Orcatec/components/Select'
import {
  IAppointment,
  RecurringInterval,
  RecurringMonthlyType,
} from 'types/Appointment'
import { Job } from 'types/Jobs'
import { InputField } from 'containers/MainContent/Orcatec/components/Input/InputField'
import { CustomMultyAutocomplete } from 'containers/MainContent/Orcatec/components/CustomMultyAutocomplete/CustomMultyAutocomplete'
import { Error } from 'types/Error'
import { Checkbox, Radio, RadioChangeEvent } from 'antd'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment-timezone'
import getEndDateAndCounter from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/Appointment/hooks/getEndDateAndCounter'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import MonthDaysSelector from '../MonthDaysSelector'
import styled from 'styled-components'
import {
  transformArrayOfObj,
  weekDays,
  weekPrefix,
} from '../../helpers/helpers'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompany } from 'store/Orcatec/selectors/company'
import { Companies } from 'types/Company'

const types: { [key: number]: string } = {
  1: 'day',
  2: 'week',
  3: 'month',
}

interface Props {
  event: IAppointment | Job
  onChange: (e: {
    target: {
      name: keyof IAppointment
      value: any
    }
  }) => void
  error: Error
}

export const RecurringOptions = ({ event, onChange, error }: Props) => {
  const company = useAppSelector(selectCompany)

  const [
    firstAppointmentDate,
    totalEntries,
    lastAppointmentDate,
  ] = getEndDateAndCounter(
    event?.start_date,
    event?.ending_options?.value,
    +event?.repeat_every,
    event?.each,
    event?.interval,
    event.monthly_type,
    +event?.ending_options?.end_type === 2
      ? +event?.ending_options?.value
      : null,
  )

  // console.log(firstAppointmentDate, totalEntries, lastAppointmentDate)

  const handleChangeInterval = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    onChange({
      target: {
        name: 'interval',
        value,
      },
    })

    onChange({
      target: {
        name: 'each',
        value: [],
      },
    })
  }

  const handleChangeEvery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    const onlyNums = value.replace(/[^0-9]/g, '').replace(/^0+/, '')

    onChange({
      target: {
        name: 'repeat_every',
        value: onlyNums,
      },
    })
  }

  const handleChangeEach = (e: unknown, selectedValues: number[]) => {
    onChange({
      target: {
        name: 'each',
        value: selectedValues.sort((a, b) => a - b),
      },
    })
  }

  const handleChangeEndingOptionsType = (e: RadioChangeEvent) => {
    onChange({
      target: {
        name: 'ending_options',
        value: {
          end_type: e.target.value,
          value: e.target.value === 2 ? 1 : false,
        },
      },
    })
  }

  const handleChangeEndingOptionsDate = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined,
  ) => {
    onChange({
      target: {
        name: 'ending_options',
        value: {
          end_type: event?.ending_options?.end_type,
          value: moment(value).format('YYYY-MM-DD'),
        },
      },
    })
  }

  const handleChangeEndingOptionsOccurrences = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange({
      target: {
        name: 'ending_options',
        value: {
          end_type: event?.ending_options?.end_type,
          value: e.target.value,
        },
      },
    })
  }

  const handleChangeMakeFirstAppointment = (e: {
    target: { checked: boolean }
  }) => {
    onChange({
      target: {
        name: 'make_first_appointment',
        value: e.target.checked,
      },
    })
  }

  const handleChangeMonthType = e => {
    const { value } = e.target

    onChange({
      target: {
        name: 'monthly_type',
        value: +value,
      },
    })
  }

  return (
    <Wrapper className='reccuring-settings'>
      <Interval>
        <Select
          label='Interval'
          name='interval'
          value={event?.interval}
          onChange={handleChangeInterval}
          options={[
            { id: 1, name: 'Day' },
            { id: 2, name: 'Week' },
            { id: 3, name: 'Month' },
          ]}
          required={true}
          disabled={!!event.id}
          className='select-appt'
          hideDisabled
        />

        <InputField
          label={`Every # of ${types[event?.interval || 1]}(s)`}
          name='repeat_every'
          className='select-appt'
          value={event.repeat_every}
          onChange={handleChangeEvery}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            if (!event?.repeat_every) {
              handleChangeEvery({
                ...e,
                target: { ...e.target, name: 'repeat_every', value: '1' },
              })
            }
          }}
          required
          error={error}
          disabled={!!event?.id}
        />

        {event?.interval &&
          [RecurringInterval.MONTH].includes(event?.interval) &&
          [Companies.HVAC, Companies['C3 Property'], Companies.Sony].includes(
            company.id,
          ) && (
            <Select
              label='Type'
              value={event.monthly_type}
              options={[
                {
                  id: 1,
                  name: 'Day of Month',
                },
                {
                  id: 2,
                  name: 'Day of Week',
                },
              ]}
              onChange={handleChangeMonthType}
              disabled={event.id}
            />
          )}

        {(event.interval === RecurringInterval.WEEK ||
          (event.interval === RecurringInterval.MONTH &&
            event.monthly_type === RecurringMonthlyType.DAILY)) && (
          <CustomMultyAutocomplete
            label={`On what day(s) of the ${types[event?.interval || 2]} *`}
            name='each'
            onChange={handleChangeEach}
            value={event?.each}
            error={error}
            options={
              event?.interval === 2
                ? Object.keys(weekDays)
                : Array.from({ length: 30 }, (_, i) => i + 1)
            }
            getOptionLabel={id => (event?.interval === 2 ? weekDays[+id] : id)}
            checkIsChoosen={id => !!event?.each?.includes(+id)}
            inputValue={
              event?.each?.length
                ? event?.interval === 2
                  ? event?.each.map(id => weekDays[id]).join(', ')
                  : event?.each.join()
                : 'Select options'
            }
            disabled={!!event?.id}
          />
        )}

        {event.interval === RecurringInterval.MONTH &&
          event.monthly_type === RecurringMonthlyType.WEEKLY && (
            <MonthDaysSelector
              value={event.each}
              onChange={onChange}
              error={error}
              disabled={!!event.id}
            />
          )}
      </Interval>

      {event?.start_date && (
        <EndingOptions>
          <Radio.Group
            onChange={handleChangeEndingOptionsType}
            value={event?.ending_options?.end_type}
            disabled={!!event?.id}
          >
            <Radio value={1}>
              <KeyboardDatePicker
                autoOk
                value={
                  event?.ending_options?.end_type === 1
                    ? event?.ending_options.value
                      ? moment(event.ending_options.value)
                      : null
                    : null
                }
                label='End Date'
                inputVariant='outlined'
                format='MM/DD/YYYY'
                size='small'
                required={event.ending_options?.end_type === 1}
                InputLabelProps={{
                  shrink: true,
                }}
                shouldDisableDate={day => !!day?.isBefore(event?.start_date)}
                onChange={handleChangeEndingOptionsDate}
                disabled={!!event.id || event.ending_options?.end_type !== 1}
                error={
                  !!error?.['ending_options.value'] &&
                  event?.ending_options?.end_type === 1
                }
                helperText={
                  !!error?.['ending_options.value'] && 'Choose End Date'
                }
              />
            </Radio>

            <Radio value={2}>
              <InputField
                label='End after # of occurrences'
                name='ending_options'
                value={
                  event.ending_options?.end_type === 2
                    ? event.ending_options.value
                    : 1
                }
                onChange={handleChangeEndingOptionsOccurrences}
                disabled={!!event.id || event.ending_options?.end_type !== 2}
                error={event?.ending_options?.end_type === 2 ? error : null}
              />
            </Radio>

            <Radio value={3}>Repeat until canceled</Radio>
          </Radio.Group>
        </EndingOptions>
      )}

      {event.interval !== 1 &&
        moment(event?.start_date).format('YYYMMDD') !==
          moment(firstAppointmentDate).format('YYYMMDD') && (
          <Checkbox
            checked={event.make_first_appointment}
            onChange={handleChangeMakeFirstAppointment}
            disabled={!!event.id}
          >
            Create event on Start Date
          </Checkbox>
        )}

      {event.start_date &&
        ((event?.ending_options?.end_type === 1 &&
          totalEntries &&
          totalEntries >= 0) ||
          (event?.ending_options?.end_type === 2 && !!lastAppointmentDate) ||
          (event?.ending_options?.end_type === 3 &&
            !!firstAppointmentDate)) && (
          <p>{`Every ${event.repeat_every > 1 ? event.repeat_every : ''} ${
            types[event.interval || 1]
          } on ${
            event.interval === RecurringInterval.WEEK
              ? event.each?.map(id => weekDays[id]).join(', ')
              : event.interval === RecurringInterval.MONTH &&
                event.monthly_type === RecurringMonthlyType.WEEKLY
              ? transformArrayOfObj(event.each)
                  .sort((a, b) => a.weekNumber - b.weekNumber)
                  .map(
                    week =>
                      `${weekPrefix[week.weekNumber]} ${week.days
                        .sort()
                        .map(day => weekDays[day])
                        .join(', ')}`,
                  )
                  .join('; ')
              : event.each?.sort((a, b) => a - b).join()
          } from ${moment(
            event.make_first_appointment
              ? event.start_date
              : firstAppointmentDate,
          ).format('MM/DD/YYYY')} until ${
            event?.ending_options.end_type === 3
              ? 'canceled'
              : moment(lastAppointmentDate).format('MM/DD/YYYY')
          }${
            event?.ending_options.end_type !== 3
              ? `, Total: ${
                  event.make_first_appointment &&
                  moment(event?.start_date).format('YYYMMDD') !==
                    moment(firstAppointmentDate).format('YYYMMDD')
                    ? '(1) + '
                    : ''
                }${totalEntries} occurrences.`
              : ''
          }`}</p>
        )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Interval = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 1rem;
`
const EndingOptions = styled.div`
  max-width: 240px;

  & > div > * {
    margin-bottom: 10px;
  }
`
