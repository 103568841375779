import styled from 'styled-components'

export const List = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  align-items: center;
  /* margin: 10px 0 20px; */
`

export const ListItem = styled.li`
  cursor: pointer;
  position: relative;
  padding-bottom: 5px;
  font-weight: 500;
  color: #777e91;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &::after {
    content: '';
    background: #4285f4;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
    transition: all 250ms ease 0s;
    transform: scale(0);
  }

  &:hover,
  &.active {
    color: #4285f4;

    &::after {
      transform: scale(0.75);
    }
  }
`
