const RecurringIcon = () => (
  <svg fill='#000000' viewBox='0 0 100 100'>
    <g>
      <path
        fill='#000000'
        d='M49.351,70.349c-2.808-6.307-1.504-13.504,3.322-18.333c4.828-4.826,12.029-6.131,18.34-3.323   c0.06,0.026,0.122,0.037,0.182,0.06l-3.092,2.426c-1.29,1.012-1.516,2.878-0.503,4.169c0.586,0.746,1.457,1.137,2.338,1.137   c0.642,0,1.287-0.207,1.831-0.634l7.837-6.148c0.888-0.696,1.304-1.836,1.074-2.942l-2.039-9.784   c-0.334-1.604-1.904-2.635-3.513-2.301c-1.605,0.334-2.636,1.907-2.302,3.513l0.942,4.52c-0.026-0.013-0.048-0.031-0.074-0.042   c-8.826-3.927-18.906-2.089-25.686,4.685c-6.773,6.778-8.611,16.858-4.683,25.681c0.546,1.228,1.751,1.957,3.015,1.957   c0.448,0,0.904-0.092,1.339-0.286C49.344,73.963,50.092,72.013,49.351,70.349z'
      ></path>
      <path
        fill='#000000'
        d='M85.354,54.327c-0.739-1.665-2.689-2.414-4.353-1.675c-1.665,0.739-2.415,2.688-1.675,4.353   c2.805,6.313,1.5,13.511-3.325,18.335c-4.729,4.729-11.729,6.069-17.945,3.482l3.086-2.42c1.29-1.012,1.516-2.878,0.503-4.169   c-1.012-1.29-2.879-1.516-4.169-0.503l-7.839,6.149c-0.888,0.696-1.305,1.836-1.074,2.941l2.038,9.784   c0.292,1.401,1.527,2.364,2.904,2.364c0.2,0,0.404-0.021,0.608-0.062c1.605-0.335,2.636-1.907,2.302-3.513l-0.938-4.499   c2.934,1.229,5.994,1.837,9.021,1.837c5.951,0,11.759-2.319,16.168-6.729C87.437,73.234,89.277,63.155,85.354,54.327z'
      ></path>
      <path
        fill='#000000'
        d='M21.082,30.459h48.068v0.007h5.15V19.112c0-1.891-1.546-3.434-3.437-3.434h-7.723v-2.544   c0-1.722-1.413-3.134-3.135-3.134h-2.312c-1.726,0-3.135,1.413-3.135,3.134v2.544H35.673v-2.544c0-1.722-1.409-3.134-3.135-3.134   h-2.312c-1.722,0-3.135,1.413-3.135,3.134v2.544h-7.724c-1.887,0-3.434,1.543-3.434,3.434v51.496c0,1.891,1.546,3.437,3.434,3.437   h16.127v-5.819H21.082V30.459z'
      ></path>
    </g>
  </svg>
)

export default RecurringIcon
