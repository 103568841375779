import { useContext } from 'react'
import { DocViewerContext } from '../state'
import { useRendererSelector } from './useRendererSelector'

export const useDocumentLoader = () => {
  const { state, dispatch } = useContext(DocViewerContext)
  const { CurrentPlagin } = useRendererSelector()
  // const { currentDocument } = state
  // const documentURI = currentDocument?.path

  // const setErrorHeandle = () => {
  //   return dispatch(setError(true, ''))
  // }
  // useEffect(() => {
  //   const setLoadingHeandle = (value) => {
  //     return dispatch(setDocumentLoading(value))
  //   }

  //   const fileLoaderComplete = fileReader => {
  //     dispatch(setFileData(fileReader?.result))
  //   }

  //   const signal = CancelToken.source()

  //   CurrentPlagin?.fileLoader?.({ documentURI, signal , fileLoaderComplete, setLoadingHeandle, setErrorHeandle })

  //   return () => {
  //     signal.cancel()
  //   }

  // }, [CurrentPlagin])

  return { state, dispatch, CurrentPlagin }
}
