import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

export const useFormatAttachedOrganizations = () => {
  const attachedClients = useSelector(state => state.orcatec.proposalContact.attachedClients)
  const [currentTableData, setCurrentTableData] = useState(null)

  useEffect(() => {
    const tableData = [...attachedClients]

    const getRelatedDataValue = (type, first_id, firstKey, secondKey, second_id, third_id) => {
      const relatedData = tableData.reduce((acc, curr) => {
        const parse = curr.related_data && JSON.parse(curr.related_data)
        Array.isArray(parse) ? acc.push(...parse) : acc.push({})
        return acc
      }, [])

      const relatedDataCurrentValue = relatedData?.find(obj => obj.parent_id === first_id)

      if (type === 'check' && relatedDataCurrentValue) {
        if (firstKey && !secondKey) return relatedDataCurrentValue[firstKey]?.find(el => el.id === second_id)?.checked
        if (firstKey && secondKey)
          return relatedDataCurrentValue[firstKey]
            ?.find(el => el.id === second_id)
          [secondKey].find(el => el.id === third_id)?.checked
      }
      if (type === 'input' && relatedDataCurrentValue) {
        if (!firstKey & !secondKey) return relatedDataCurrentValue.proposal_relation
        if (firstKey && !secondKey)
          return relatedDataCurrentValue[firstKey]?.find(el => el.id === second_id)?.proposal_relation
        if (firstKey && secondKey)
          return relatedDataCurrentValue[firstKey]
            ?.find(el => el.id === second_id)
          [secondKey]?.find(el => el.id === third_id)?.proposal_relation
      }
    }

    const formatAttachedClients = tableData => {
      const targets = ['properties', 'contacts']

      tableData.forEach(client => {
        client.proposal_relation = getRelatedDataValue('input', client.id)
        targets.forEach(value => {
          client[value] &&
            client[value].forEach(obj => {
              if (typeof obj.checkbox === 'undefined')
                obj.checkbox = getRelatedDataValue('check', client.id, value, null, obj.id)
              obj.proposal_relation = getRelatedDataValue('input', client.id, value, null, obj.id)
            })
        })
      })
      setCurrentTableData(tableData?.length ? tableData.reverse() : null)
    }

    attachedClients && Array.isArray(attachedClients) && formatAttachedClients(tableData)
  }, [attachedClients])

  return [currentTableData, setCurrentTableData]
}
