import { useState, useEffect, FC, useRef } from 'react'
import EmployeeRouting from 'containers/MainContent/Orcatec/Map/components/EmployeeRouting'
import styled from 'styled-components'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import sateliteIcon from '../satellite.png'
import simpleIcon from '../default.png'
import { Modal } from 'antd'

interface IProps {
  showModal: () => void
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
  users: []
  satelite: boolean
}
const IconWrapper = styled.div`
  z-index: 500;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 76px;
  left: 11px;
  & img {
    width: 100%;
    height: auto;
    display: block;
  }
`
const ContainerWrapper = styled.div`
  & .controls {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  & .show-routes {
    margin-bottom: 0px !important;
  }
`
const MapWrapper = styled.div`
  z-index: 99999999;
  & .leaflet-container {
    height: 500px;
    width: 500px;
  }
  & .leaflet-routing-alt {
    display: none;
  }
  & .marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    border-radius: 15px;
    font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }
  & .marker-cluster-small div {
    background-color: rgba(110, 204, 57, 0.6);
  }
  .marker-cluster span {
    line-height: 30px;
  }
  .marker-cluster-small {
    background-color: rgba(181, 226, 140, 0.6);
  }
  .marker-cluster-small {
    background-color: rgba(181, 226, 140, 0.6);
  }
  .marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
  }
`
const TempEventListener = ({ mapCenter }) => {
  const map = useMap()

  const [renderCounter, setRenderCounter] = useState(0)

  useEffect(() => {
    setRenderCounter(renderCounter + 1)

    if (renderCounter > 0) map.setView(mapCenter, 10)
  }, [mapCenter])

  return null
}

const sateliteLayer =
  'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
const simpleLayer =
  'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=4c3b17fc-bee1-42cc-9b17-b4b51480d8c5'
const ModalTechRoutes: FC<IProps> = ({
  users,
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const layerRef = useRef()
  const [mapCenter, setMapCenter] = useState([0, 0])
  const [mapToggles, setMapToggles] = useState({
    satelite: false,
  })
  const [map, setMap] = useState(null)
  const [bounds, setBounds] = useState([
    [47.853935, -128.591003],
    [16.739146, -76.689173],
  ])
  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (layerRef?.current) {
      layerRef.current.setUrl(mapToggles.satelite ? sateliteLayer : simpleLayer)
    }
  }, [mapToggles.satelite])

  useEffect(() => {
    const newBounds = users
      ?.map(r => r.appointments)
      ?.flat()
      ?.map(app => app.coordinates)

    if (newBounds?.length) setBounds(newBounds)
  }, [users])

  return (
    <ContainerWrapper>
      <Modal
        destroyOnClose
        width={550}
        visible={isModalOpen}
        title={
          <div className='controls'>
            <span>Routes for {users?.[0]?.full_name}</span>{' '}
            {/* <span>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={mapToggles.satelite}
                    onChange={() => setMapToggles(prev => ({ satelite: !prev.satelite }))}
                  />
                }
                label='Satelite'
                name='satelite'
                className='show-routes'
              />
            </span> */}
          </div>
        }
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
        centered
      >
        <MapWrapper>
          <MapContainer
            center={mapCenter}
            bounds={bounds}
            zoomSnap={0.75}
            zoomDelta={0.75}
            wheelPxPerZoomLevel={80}
            doubleClickZoom={true}
            scrollWheelZoom={true}
            whenCreated={map => {
              setMap(map)
            }}
          >
            <TileLayer
              ref={layerRef}
              url={mapToggles.satelite ? sateliteLayer : simpleLayer}
              attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org">OpenMapTiles</a>, &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
            />
            <IconWrapper
              onClick={() =>
                setMapToggles(prev => ({ satelite: !prev.satelite }))
              }
            >
              {mapToggles.satelite ? (
                <img src={simpleIcon} />
              ) : (
                <img src={sateliteIcon} />
              )}
            </IconWrapper>

            {!!users?.length &&
              users?.map(user => (
                <EmployeeRouting
                  key={user?.id}
                  employee={user}
                  setMapCenter={setMapCenter}
                  showRoutes={true}
                  // setLoading={setLoading}
                  map={map}
                  isAppointment={true}
                />
              ))}

            <TempEventListener mapCenter={mapCenter} />
          </MapContainer>
        </MapWrapper>
      </Modal>
    </ContainerWrapper>
  )
}

export default ModalTechRoutes
