import { Upload } from 'antd'
import React, { memo } from 'react'
import { ViewFile } from '../../../../../../../../helpers/viewFile/viewFile'
import ImgCropper from '../../../CompanyInfo/ImgCropper/ImgCropper'
import classNames from 'classnames'
import './UserPicture.scss'

const viewFileSettings = (cropShape) => ({
  width: '200px',
  height: '200px',
  borderRadius: cropShape === 'rect' ? '0px' : '100px',
  cursor: 'pointer',
  margin: '14px',
  backgroundSize: 'cover',
})

const dellIconStyleSettings = {
  allwaysVisible: true,
  fontSize: '25px',
  top: '-20px',
  right: '-15px',
  color: '#EF4C4C',
}

const UserPicture = ({
  imageToCrop,
  profilePicture,
  setCroppedLogo,
  onCancelCrop,
  profilePictureError,
  beforeUploadLogo,
  customUploadRequest,
  onDeletePicture,
  customTitle,
  cropShape = 'round',

}) => (
  <div className={classNames('w-100 d-flex flex-column align-items-center justify-content-center, user-form-profile-picture', cropShape)} >
    {imageToCrop ? (
      <ImgCropper
        file={imageToCrop}
        setCroppedLogo={setCroppedLogo}
        onCancelCrop={onCancelCrop}
        label={customTitle || 'Profile picture'}
        cropShape={cropShape}
      />
    ) : (
      <div
        className={classNames('company-drag-wrapper company-drag-logo', !profilePicture ? ' company-drag-logo-empty' : '', profilePictureError ? ' error' : '')}

      >
        <Upload.Dragger
          name='file'
          listType='picture'
          showUploadList={false}
          beforeUpload={beforeUploadLogo}
          customRequest={customUploadRequest}
          multiple={false}
          accept='.jpg, .jpeg, .png'
        >
          {profilePicture ? (
            <ViewFile
              file={profilePicture}
              fileStyle={viewFileSettings(cropShape)}
              onDelete={onDeletePicture}
              customDellIconClassName='mdi mdi-close'
              dellIconStyle={dellIconStyleSettings}
              isLogo={true}
            />
          ) : (
            <span>{customTitle ?? 'Profile picture'}</span>
          )}
        </Upload.Dragger>
      </div>
    )}
    {!!profilePictureError && (
      <div style={{ fontSize: '12px', color: '#f44336', marginTop: '-1rem', marginBottom: '1.5rem' }}>
        {profilePictureError}
      </div>
    )}
  </div>
)

export default memo(UserPicture)
