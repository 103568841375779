import * as types from '../types'

const initialState = {
  loading: false,
  list: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.schedule.START_FETCHING_SCHEDULE:
      return {
        ...state,
        loading: true,
      }
    case types.schedule.END_FETCHING_SCHEDULE:
      return {
        ...state,
        loading: false,
      }
    case types.schedule.SET_SCEDULE:
      return {
        ...state,
        loading: false,
        list: action.res,
      }
    case types.schedule.SET_CURRENT_SCEDULE:
      return state
    case types.schedule.CHANGE_SCHEDULE_APPOINTMENT_STATUS:
      return {
        ...state,
        list: action.newSchedule,
      }
    default:
      return state
  }
}
