import { project } from './project/project'
import { accounting } from './accounting/accounting'
import { schedule } from './schedule/schedule'
import { dispatch } from './dispatch/dispatch'
import { dashboard } from './dashboard/dashboard'
import { contacts } from './contacts/contacts'
import { forms } from './forms/forms'
import { map } from './map/map'
import { notes } from './notes/notes'
import { properties } from './properties/properties'
import { time_cards } from './time_cards/time_cards'
import { fast_payment } from './fast_payment/fast_payment'
import { instant_appointment } from './instant_appointment/instant_appointment'
import { jobs } from './jobs/jobs'
import { quickbooks } from './quickbooks/quickbooks'
import { todo } from './todo/todo'
import { reviews } from './reviews/reviews'
import { settings } from './settings/settings'
import { memberships } from './memberships/memberships'
import { messaging } from './messaging/messaging'
import { call_tracking } from './call_tracking/call_tracking'
import { type_source } from './type_source/type_source'
import { estimates } from './estimates/estimates'

export const submodule_permissions = {
  project,
  accounting,
  schedule,
  dispatch,
  dashboard,
  contacts,
  forms,
  map,
  notes,
  properties,
  time_cards,
  fast_payment,
  instant_appointment,
  jobs,
  quickbooks,
  todo,
  reviews,
  settings,
  memberships,
  messaging,
  call_tracking,
  type_source,
  estimates,
}
