import { Attachments } from 'features/Items/components/Item/components/Attachments/Attachments'
import { useUnits } from 'features/Items/hooks/useUnits'
import { selectProjectSettings } from 'features/Project/projectSelectors'
import { ProjectItem } from 'features/Project/types'
import { priceToView } from 'helpers/thousandSeparator'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  AdditionalInfo,
  AttachmentsWrapper,
  Content,
  Description,
  Diff,
  Price,
  PriceSection,
  Qty,
  StyledCheckbox,
  StyledRadio,
  Title,
  Total,
  ItemWrapper,
} from './Item.styles'
import { round } from 'helpers/Math'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'

interface Props {
  data: ProjectItem
  disabled: boolean
  checkedItem?: ProjectItem
  pricePermission: boolean
  onChange: (item: ProjectItem) => void
}

export const Item = ({
  data,
  disabled,
  pricePermission,
  checkedItem,
  onChange,
}: Props) => {
  const { units } = useUnits()
  const {
    multiple_choice_items,
    show_items_qty,
    show_item_description_only,
    show_price,
    hide_zero_price,
  } = useAppSelector(selectProjectSettings)

  const showItemDescription = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.PROJECT,
      ProjectPermissions.PROJECT_CAN_VIEW_ITEM_DESCRIPTON,
    ),
  )

  const getDifference = (item: ProjectItem) => {
    if (!checkedItem) return 0

    return round(
      item.retail_price * item.qty -
        checkedItem?.retail_price * checkedItem?.qty,
    )
  }

  const isPriceVisible = show_price && pricePermission

  return (
    <ItemWrapper inactive={!!checkedItem && !data.checked_option}>
      {multiple_choice_items && !data.required_to_pay && !checkedItem && (
        <StyledCheckbox
          checked={data?.use_calculation}
          onChange={e =>
            onChange({ ...data, use_calculation: e.target.checked })
          }
          disabled={disabled}
        />
      )}

      {!!checkedItem && (
        <StyledRadio
          disabled={disabled}
          checked={data.checked_option}
          onClick={() => {
            onChange({
              ...data,
              checked_option: true,
            })
          }}
        />
      )}

      {!show_item_description_only && <Title>{data.name}</Title>}

      <Content>
        <Description>{showItemDescription ? data.description : ''}</Description>

        <AdditionalInfo>
          <AttachmentsWrapper>
            <Attachments imageSize={35} data={data?.attachments} />
          </AttachmentsWrapper>

          <PriceSection>
            <Diff>
              {!!getDifference(data) &&
                `${getDifference(data) > 0 ? '+' : ''}${priceToView(
                  getDifference(data),
                )}`}
            </Diff>
            <Price>
              {show_items_qty && isPriceVisible && (
                <>
                  {data.retail_price && data.qty > 1
                    ? `${priceToView(data.retail_price)} ea.`
                    : ''}
                </>
              )}
            </Price>
            <Qty>
              {show_items_qty && (
                <>
                  {data?.unit_id ? units[data?.unit_id]?.name : 'Qty'}{' '}
                  {data.qty}
                </>
              )}
            </Qty>
            <Total>
              {!isPriceVisible ||
              (data?.retail_price * data?.qty === 0 && !hide_zero_price)
                ? ''
                : priceToView((data?.retail_price || 0) * (data?.qty || 0))}
            </Total>
          </PriceSection>
        </AdditionalInfo>
      </Content>
    </ItemWrapper>
  )
}
