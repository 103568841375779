import { fetchUserByToken } from './settings/user'

import { fetchUserPermissions } from 'features/Settings/UsersAndGroups/permissionSlice'
import * as types from '../types'

export type initializedSuccessActionType = {
  type: typeof types.app.INITIALIZED_SUCCESS
}

export type setSubscriptionStatusActionType = {
  type: typeof types.app.SET_SUBSCRIPTION_STATUS
  subscription_status: number
}

export const initializedSuccess = (): initializedSuccessActionType => ({
  type: types.app.INITIALIZED_SUCCESS,
})

export const setSubscriptionStatus = (
  subscription_status: number,
): setSubscriptionStatusActionType => ({
  type: types.app.SET_SUBSCRIPTION_STATUS,
  subscription_status,
})

export const logout = () => {
  return {
    type: types.app.USER_LOGOUT,
  }
}

// export const initializeApp = (isEnterFromLogin: any) => (dispatch: any) => {
//  const promises = [
//    /* dispatch(fetchUserCurrentTimeStatus()) */
//  ]
//  if (isEnterFromLogin !== 'from login')
//    promises.push(dispatch(fetchUserByToken()))
//  return Promise.all(promises)
//    .then(([authUser]) => {
//      if (authUser?.me?.id)
//        return dispatch(fetchUserPermissions(authUser?.me?.id))
//    })
//    .then(() => {
//      dispatch(initializedSuccess())
//      return 'all'
//    })
// }
export const initializeApp = (isEnterFromLogin: string) => async (
  dispatch: any,
) => {
  try {
    if (isEnterFromLogin !== 'from login') {
      const authUser = await dispatch(fetchUserByToken())

      if (authUser?.me?.id) {
        await dispatch(fetchUserPermissions(authUser.me.id))
      }
    }
    dispatch(initializedSuccess())

    return 'all'
  } catch (error) {
    console.error('An error occurred during app initialization:', error)

    throw error
  }
}
