import styled from 'styled-components'
import { getEventStatusStyles } from '../../helpers'

interface Props {
  backgroundColor: string | null
  isJob: boolean
  isRecurring?: boolean
  status: number
}

export const EventWrapper = styled.div<Props>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor || '#4b94d8'};
  border-radius: ${props => (props.isRecurring ? '18px' : '4px')};
  height: 100%;
  color: #fff;
  font-size: 6px;
  cursor: grab;
  text-align: center;
  font-family: 'Poppins';
  position: relative;
  border: ${({ isJob, isRecurring }) =>
    isJob && isRecurring ? '1px dashed #000' : 'none'};
  overflow: hidden;
  position: relative;

  ///
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-repeat: no-repeat;

  background-image: ${({ isJob, isRecurring }) =>
    isJob && !isRecurring
      ? 'repeating-linear-gradient(0deg, #fff, #fff 5px, #000 5px, #000 10px), repeating-linear-gradient(90deg, #fff, #fff 5px, #000 5px, #000 10px), repeating-linear-gradient(180deg, #fff, #fff 5px, #000 5px, #000 10px), repeating-linear-gradient(270deg, #fff, #fff 5px, #000 5px, #000 10px)'
      : 'none'};
  //
  p {
    line-height: 1;

    &.zip {
      position: relative;

      i {
        color: black;
        padding-left: 2px;
      }
    }

    &.client-name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  /* &.recurring {
    border-radius: 18px;
  } */

  &.lead {
    background-color: #22c906;
  }

  .todo-event {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    cursor: pointer;

    &.past {
      background-color: #d91717;
    }
    &.actual {
      background-color: #fcfc1e;
      color: #000;
    }
    &.future {
      background-color: #00ca00;
    }
  }

  &.past {
    background-color: #d91717;
  }
  &.actual {
    background-color: #fcfc1e;
    color: #000;
  }
  &.future {
    background-color: #00ca00;
  }

  &::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: ${props => getEventStatusStyles(props.status)};
  }

  @media (max-width: 500px) {
    font-size: 3px;
  }
`
