import * as types from '../types'
import * as organizations from '../../../api/Organizations'

const normalizeOrganization = organization => {
  if (typeof organization.phones === 'string') {
    organization.phones = JSON.parse(organization.phones)
  }
  if (typeof organization.emails === 'string') {
    organization.emails = JSON.parse(organization.emails)
  }
  if (!Array.isArray(organization.phones)) organization.phones = []
  if (!Array.isArray(organization.emails)) organization.emails = []
  if (!organization.feedBackList) organization.feedBackList = []
  if (!organization.feedbacks)
    organization.feedbacks = organization.feedBackList
      ? organization.feedBackList
      : []
  if (!organization.properties) organization.properties = []
  if (!organization.tags) organization.tags = []
  if (!organization.client_associations) organization.client_associations = []
  if (!organization.client_properties) organization.client_properties = []
  organization.client_properties = organization.properties.map(item => ({
    ...item,
    property_id: item.id,
  }))
  if (!organization.contacts) organization.contacts = []
  if (!organization.organizations) organization.organizations = []
  // organization.relations_with_property = [
  //   organization.properties.reduce((acc, property) => {
  //     acc[property.id] = {
  //       note: property.relation_note
  //         ? property.relation_note
  //         : property.relation
  //         ? property.relation
  //         : property.note
  //         ? property.note
  //         : property.property_relation
  //         ? property.property_relation
  //         : '',
  //     }
  //     return acc
  //   }, {}),
  // ]
  if (!organization.vote) organization.vote = 0
  if (!organization.note) organization.note = ''
}

export const changeField = (field, value) => {
  return {
    type: types.organizations.ORGANIZATION_CHANGE_FIELD,
    field,
    value,
  }
}

export const setItem = item => {
  return {
    type: types.organizations.ORGANIZATION_SET_ITEM,
    item,
  }
}

export const getItem = () => {
  return {
    type: types.organizations.ORGANIZATION_GET_ITEM,
  }
}

export const setCurrentOrganization = res => {
  return {
    type: types.organizations.SET_CURRENT_ORGANIZATION,
    res,
  }
}

export const removeCurrentOrganization = res => {
  return {
    type: types.organizations.REMOVE_CURRENT_ORGANIZATION,
    res,
  }
}

const organizationAssociationAdded = ass => {
  return {
    type: types.organizations.ADD_ORGANIZATION_ASSOCIATION,
    payload: ass,
  }
}

const organizationAssociationDeleted = id => {
  return {
    type: types.organizations.DELETE_ORGANIZATION_ASSOCIATION,
    payload: id,
  }
}

export const deleteOrganization = id => dispatch => {
  return organizations.deleteOrganization(id)
}

export const createOrganization = data => dispatch => {
  return organizations.createOrganization(data)
}

export const updateOrganization = (id, data) => dispatch => {
  return organizations.updateOrganization(id, data)
}
export const fetchOrganizationsList = props => dispatch => {
  return organizations.fetchOrganizationsList(props).then(response => {
    if (Array.isArray(response.data)) {
      response.data.forEach(organization => {
        normalizeOrganization(organization)
      })
    }
    dispatch(setItem(response))
  })
}

export const getOrganizationById = id => dispatch => {
  return organizations.getOrganizationById(id).then(organization => {
    if (!(organization instanceof Object)) organization = {}
    normalizeOrganization(organization)
    dispatch(setCurrentOrganization(organization))
    return organization
  })
}
export const mergeOrganizations = data => dispatch => {
  return organizations.mergeOrganizations(data).then(response => {
    dispatch(fetchOrganizationsList())
  })
}
export const createRelationProperty = data => dispatch => {
  return organizations.createRelationProperty(data)
}
export const createOrganizationAssociation = data => dispatch => {
  return organizations.createOrganizationAssociation(data)
}
export const addOrganizationAssociation = data => dispatch => {
  return organizations.createOrganizationAssociation(data).then(res => {
    dispatch(organizationAssociationAdded(res))
    return res
  })
}
export const deleteOrganizationAssociation = id => dispatch => {
  return organizations.dellOrganizationAssociation(id).then(res => {
    dispatch(organizationAssociationDeleted(id))
  })
}
export const dellAttachedProperty = id => dispatch => {
  return organizations.dellAttachedProperty(id)
}
export const dellOrganizationAssociation = id => dispatch => {
  return organizations.dellOrganizationAssociation(id)
}
