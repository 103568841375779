import { FC, useEffect, useState, CSSProperties } from 'react'
import styled from 'styled-components'

const isFloat = (x: number) => !!(x % 1)

interface Props {
  value: string
  styles?: CSSProperties
}

const CountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 80px;
`

const MessagesCount: FC<Props> = ({ value = '', styles }) => {
  const [countMsg, setCountMsg] = useState(0)

  useEffect(() => {
    if (value.length === 0) {
      setCountMsg(0)
      return
    }
    const countSplit = value.length / 153 + 1
    const getCount = +countSplit.toString().split('.')[0]
    if (!isFloat(countSplit)) {
      setCountMsg(getCount - 1)
      return
    }
    if (isFloat(countSplit) && countMsg !== getCount) {
      setCountMsg(getCount)
    }
  }, [value])

  return (
    <CountContainer
      style={styles}
    >{`Characters: ${value.length}/918 SMS Parts: ${countMsg}/6`}</CountContainer>
  )
}

export default MessagesCount
