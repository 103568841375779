import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProjectStatusAPI } from 'api/Project'
import { ProjectStatus } from '../types'
import { AsyncThunkAPI } from 'store/types'
import { AppStateType } from 'store'
import { Error } from 'types/Error'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { deleteErrorKeys } from 'features/Items/helpers'

const initialState: {
  status: 'idle' | 'loading' | 'error'
  statusList: {
    ids: number[]
    data: Record<number, ProjectStatus>
  }
  error: Error | null
} = {
  status: 'idle',
  statusList: { ids: [], data: {} },
  error: null,
}

const projectStatusSlice = createSlice({
  name: 'projectStatus',
  initialState,
  reducers: {
    resetErrors: (state, action: PayloadAction<string[] | undefined>) => {
      state.error = deleteErrorKeys(state.error, action.payload)
      state.status = 'idle'
    },
  },
  extraReducers: builder => {
    builder.addCase(getProjectStatuses.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getProjectStatuses.fulfilled, (state, action) => {
      state.status = 'idle'
      // state.statusList = groupStatusesByParent(action.payload)
      state.statusList = {
        ids: action.payload.map(status => status.id),
        data: transformArrayToObj(action.payload),
      }
    })
    builder.addCase(getProjectStatuses.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })

    builder.addCase(createProjectStatus.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(createProjectStatus.fulfilled, (state, action) => {
      state.status = 'idle'
      // state.statusList[action.payload.parent_status] = [
      //   ...state.statusList[action.payload.parent_status],
      //   action.payload,
      // ]
      state.statusList.ids.push(action.payload.id)
      state.statusList.data[action.payload.id] = action.payload
    })
    builder.addCase(createProjectStatus.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })

    builder.addCase(updateProjectStatus.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateProjectStatus.fulfilled, (state, action) => {
      state.status = 'idle'
      state.statusList.data[action.payload.id] = action.payload
    })
    builder.addCase(updateProjectStatus.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })

    builder.addCase(deleteProjectStatus.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteProjectStatus.fulfilled, (state, action) => {
      state.status = 'idle'
      state.statusList.ids = state.statusList.ids.filter(
        id => id !== action.payload,
      )
    })
    builder.addCase(deleteProjectStatus.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })

    builder.addCase(changeProjectStatusAndDelete.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(changeProjectStatusAndDelete.fulfilled, (state, action) => {
      state.status = 'idle'
      state.statusList.ids = state.statusList.ids.filter(
        id => id !== action.payload,
      )
    })
    builder.addCase(changeProjectStatusAndDelete.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })
  },
})

export const { resetErrors } = projectStatusSlice.actions
export default projectStatusSlice.reducer

//Selectors
export const selectProjectStatusSlice = (state: AppStateType) =>
  state.orcatec.projectStatusSlice

//Thunks

export const getProjectStatuses = createAsyncThunk<
  ProjectStatus[],
  void,
  AsyncThunkAPI
>('projectStatus/getStatusList', async (_, { rejectWithValue }) => {
  try {
    return await ProjectStatusAPI.getProjectStatusList()
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const createProjectStatus = createAsyncThunk<
  ProjectStatus,
  ProjectStatus,
  AsyncThunkAPI
>('projectStatus/createStatus', async (status, { rejectWithValue }) => {
  try {
    return await ProjectStatusAPI.createProjectStatus(status)
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const updateProjectStatus = createAsyncThunk<
  ProjectStatus,
  ProjectStatus,
  AsyncThunkAPI
>('projectStatus/updateStatus', async (status, { rejectWithValue }) => {
  try {
    return await ProjectStatusAPI.updateProjectStatus(status)
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const deleteProjectStatus = createAsyncThunk<
  number,
  number,
  AsyncThunkAPI
>('projectStatus/deleteStatus', async (statusId, { rejectWithValue }) => {
  try {
    await ProjectStatusAPI.deleteProjectStatus(statusId)

    return statusId
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const changeProjectStatusAndDelete = createAsyncThunk<
  number,
  {
    deleted_status_id: number
    changed_status_id: number
  },
  AsyncThunkAPI
>('projectStatus/updateStatusAndDelete', async (data, { rejectWithValue }) => {
  try {
    await ProjectStatusAPI.changeProjectStatusAndDelete(data)

    return data.deleted_status_id
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})
