import { FC } from 'react'
import { Checkbox, Typography, Tooltip, Popconfirm, Dropdown } from 'antd'

import {
  EntityLevel,
  EntityType,
  ContactWithAdditionalData,
  PropertyWithAdditionalData,
} from 'features/Project/types'
import { ItemLink } from '../../Table.style'

import { FULL_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment-timezone'
import {
  transformTitleToUpperCase,
  transformTitleToLowerCase,
} from 'features/Project/Contacts/constants'
import { CloseOutlined, EllipsisOutlined } from '@ant-design/icons'
import {
  ContactWrapper,
  ContactWrapperInner,
  InfoBlock,
  ItemInfo,
  ItemInfoButtons,
  RelationBlock,
  RemoveItemBlock,
  SelectItemBlock,
  IconItemBlock,
  InfoItemBlock,
  ItemInfoButtonDetach,
} from './ContactItem.styles'

import { renderIcon } from 'features/Project/Contacts/helpers/renderIcons'
import { EditPencilIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { ContactItemButton } from '../ContactItemButton/ContactItemButton'
import { ClientType } from 'types/Client'
import { highlightMatches } from 'features/Project/Contacts/helpers/highlightMatches'
import { ContactsCounter } from '../ContactsCounter/ContactsCounter'
import { useAppSelector } from 'store/Orcatec/hooks'
// import { Companies } from 'types/Company'
// import { selectCompany } from 'store/Orcatec/selectors/company'
// import { updateFwoFieldThunk } from 'features/Project/slices/projectFwoSlice'
import {
  selectPrimaryProjectContacts,
  selectProcessItem,
} from 'features/Project/slices/projectWorkOrderContactsSlice'

type IItem = ContactWithAdditionalData | PropertyWithAdditionalData

const generatDropdownActions = (
  item: IItem,
  isDisabled: boolean,
  freezed: boolean,
) => {
  const items = []
  if (item.level === EntityLevel.PARENT) {
    items.push({
      label: 'Detach',
      key: '1',
      disabled: freezed,
    })
  }
  if (item.entity_type === EntityType.PROPERTY) {
    items.push({
      label: 'Attach client',
      key: '3',
      disabled: isDisabled,
    })
  }
  if (item.entity_type === EntityType.CLIENT) {
    items.push({
      label: 'Attach property',
      key: '2',
      disabled: isDisabled,
    })
  }
  if (
    item.entity_type === EntityType.CLIENT &&
    item.type === ClientType.ORGANIZATION
  ) {
    items.push({
      label: 'Attach client',
      key: '3',
      disabled: isDisabled,
    })
  }

  return items
}

const iconWithTooltip = (item: IItem) => {
  const text = `${
    transformTitleToUpperCase[item.entity_type]
  } was deleted at ${moment(item.deleted_at)
    .tz(moment.tz.guess())
    .format(FULL_DATE_FORMAT)}`

  const mergedText = `${
    transformTitleToUpperCase[item.entity_type]
  } was merged with ${item.merged_with?.name} at ${moment(
    item.merged_with?.merged_at,
  )
    .tz(moment.tz.guess())
    .format(FULL_DATE_FORMAT)}`
  return (
    <Tooltip title={item.merged_with?.name ? mergedText : text}>
      <p
        style={{
          backgroundColor: 'rgba(253, 223, 225, 1)',
          color: 'rgba(241, 69, 61, 1)',
          padding: '2px 8px',
          fontSize: '12px',
          borderRadius: '6px',
        }}
      >
        Deleted
      </p>
    </Tooltip>
  )
}

interface IProps {
  onOpenForm: (item: IItem) => void
  item: IItem
  freezed: boolean

  handleDetachContact: (item: IItem) => void
  level: EntityLevel
  isItemDisabled: boolean
  parentRelationId: number
  searchValue: string
  relations: {
    proposal_relation: null | string
    proposal_relation_id: number
    entity_id: number
    entity_type: EntityType
  }
  hasCheckedChilds: boolean
  handleSelectItem: (checked: boolean, item: IItem) => void
  handleSelectParentItem: (checked: boolean, item: IItem) => void
  handleUpdateProjectRelation: ({
    item,
    proposal_relation,
  }: {
    item: IItem
    proposal_relation: string
  }) => void
}

export const ContactItem: FC<IProps> = ({
  onOpenForm,
  item,
  handleDetachContact,
  handleUpdateProjectRelation,
  handleSelectItem,
  hasCheckedChilds,
  relations,
  freezed,
  searchValue,
  handleSelectParentItem,
}) => {
  const processingItem = useAppSelector(selectProcessItem)
  const primaryContacts = useAppSelector(selectPrimaryProjectContacts)
  // const company = useAppSelector(selectCompany)
  // const disptach = useAppDispatch()

  const onClickBtn = (item: IItem) => {
    return onOpenForm({
      item,
      is_child_modal: true,
      entity_type:
        item.entity_type === EntityType.CLIENT
          ? EntityType.PROPERTY
          : EntityType.CLIENT,
    })
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const onDropDownClick = (key, item) => {
    if (key === '1') {
      handleDetachContact(item)
    } else {
      onClickBtn(item)
    }
  }

  /*   const handleUpdateFwo = (item: IItem) => {
    const fields = {
      email: item?.emails?.[0] || '',
      phone: item?.phones?.[0].phone || '',
      super_name: item.name || item?.text || '',
    }
    disptach(updateFwoFieldThunk({ fields }))
  } */

  const isItemDisabled = (item: IItem) => {
    const isCurrentItem = item?.id === primaryContacts[item.entity_type]?.id
    const isDeleted = !(item?.checked || item?.display) && item?.deleted_at

    const isCurrentRelationId =
      item?.proposal_relation_id ===
      primaryContacts[item.entity_type]?.proposal_relation_id

    if (isDeleted) return true
    if (isCurrentItem && isCurrentRelationId && freezed) return true
    return false
  }

  const isDisabled = isItemDisabled(item)

  return (
    <ContactWrapper
      className={`contact-item_level-${item.level}`}
      onKeyPress={handleKeyPress}
    >
      <ContactWrapperInner>
        <RemoveItemBlock>
          {item.level === EntityLevel.PARENT &&
            (freezed ? (
              <Tooltip
                title={`You can detach this  ${
                  transformTitleToLowerCase[item.entity_type]
                } only when project is in Estimate status or Edit Contract mode`}
              >
                <CloseOutlined style={{ color: 'gray' }} />
              </Tooltip>
            ) : (
              <Popconfirm
                onConfirm={() => handleDetachContact(item)}
                title={
                  <>
                    <p>
                      <b>Are you sure?</b>
                    </p>
                    <p>{`If you detach this ${
                      transformTitleToLowerCase[item.entity_type]
                    } all the related entities will be detached as well`}</p>
                  </>
                }
              >
                <CloseOutlined style={{ color: 'gray' }} />
              </Popconfirm>
            ))}
        </RemoveItemBlock>
        <SelectItemBlock>
          {item.level === EntityLevel.PARENT ? (
            <Checkbox
              checked={item.display}
              onChange={e => handleSelectParentItem(e.target.checked, item)}
              disabled={freezed || processingItem?.id === item?.id}
              indeterminate={!!hasCheckedChilds}
            />
          ) : (
            <Tooltip
              title={
                isDisabled
                  ? `You can uncheck primary ${
                      transformTitleToLowerCase[item.entity_type]
                    } only when project is in Estimate status or Edit Contract mode`
                  : ''
              }
            >
              <Checkbox
                checked={!!relations?.proposal_relation_id}
                onChange={e => handleSelectItem(e.target.checked, item)}
                disabled={isDisabled || processingItem?.id === item?.id}
                indeterminate={!!hasCheckedChilds}
              />
            </Tooltip>
          )}
        </SelectItemBlock>
        <IconItemBlock>{renderIcon(item.entity_type, item.type)}</IconItemBlock>
        <InfoItemBlock>
          <InfoBlock className={`contact-item_info-${item.level}`}>
            <Tooltip
              mouseEnterDelay={0}
              title={
                !item?.deleted_at &&
                `Click to open this ${
                  transformTitleToLowerCase[item.entity_type]
                }`
              }
            >
              <ItemLink
                onClick={() => {
                  if (!item?.deleted_at) onOpenForm(item)
                }}
                dangerouslySetInnerHTML={{
                  __html: highlightMatches(
                    item.text || item.name || item.full_address,
                    searchValue,
                  ),
                }}
              />
            </Tooltip>

            <ItemInfo>
              {item.level === EntityLevel.PARENT && (
                <>
                  <ContactsCounter parent={item} />
                  {(item?.deleted_at || item?.merged_with) &&
                    iconWithTooltip(item)}
                </>
              )}

              {!item?.deleted_at && (
                <ItemInfoButtons>
                  {item.level !== EntityLevel.NESTED_CHILD &&
                    !item?.deleted_at && (
                      <div>
                        <ContactItemButton
                          entity_type={item.entity_type}
                          onClick={() => onClickBtn(item)}
                          disabled={!!item?.deleted_at}
                        />
                      </div>
                    )}

                  {item.level !== EntityLevel.NESTED_CHILD &&
                    item.entity_type === EntityType.CLIENT &&
                    item.type === ClientType.ORGANIZATION && (
                      <div>
                        <ContactItemButton
                          entity_type={EntityType.PROPERTY}
                          onClick={() =>
                            onClickBtn({
                              ...item,
                              entity_type: EntityType.PROPERTY,
                            })
                          }
                          disabled={!!item?.deleted_at}
                        />
                      </div>
                    )}
                </ItemInfoButtons>
              )}

              {item.level !== EntityLevel.PARENT &&
                (item?.deleted_at || item?.merged_with) &&
                iconWithTooltip(item)}
            </ItemInfo>
          </InfoBlock>
          <RelationBlock>
            {relations?.proposal_relation_id &&
              (item?.display || item?.checked) && (
                <Typography.Paragraph
                  style={{
                    fontSize: '14px',
                    margin: '0px',
                    width: '100%',
                    left: '0px',
                  }}
                  editable={{
                    enterIcon: <></>,
                    maxLength: 255,
                    onChange: proposal_relation => {
                      if (
                        relations.proposal_relation !== proposal_relation ||
                        proposal_relation === null
                      ) {
                        handleUpdateProjectRelation({ item, proposal_relation })
                      }
                    },
                    icon: !relations?.proposal_relation ? (
                      <span
                        style={{
                          color: ' rgba(153, 153, 153, 1)',
                          fontSize: '14px',
                          fontStyle: 'italic',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          marginLeft: '-4px',
                        }}
                      >
                        <span>{`{no relation yet}`}</span>
                        <EditPencilIcon />
                      </span>
                    ) : (
                      <EditPencilIcon />
                    ),
                  }}
                >
                  {relations?.proposal_relation}
                </Typography.Paragraph>
              )}
          </RelationBlock>
          {/*  {item.entity_type === EntityType.CLIENT &&
          company.id === Companies.SoCal ? (
            <Tooltip title='Copy to FWO'>
              <Popconfirm
                title='Are you sure?'
                onConfirm={() => handleUpdateFwo(item)}
              >
                <Button size='small'>Copy</Button>
              </Popconfirm>
            </Tooltip>
          ) : null} */}
        </InfoItemBlock>
      </ContactWrapperInner>
      <ItemInfoButtonDetach>
        {item.level !== EntityLevel.NESTED_CHILD && (
          <Dropdown
            menu={{
              items: generatDropdownActions(item, isDisabled, freezed),
              onClick: ({ key }) => onDropDownClick(key, item),
            }}
            trigger={['click']}
          >
            <a onClick={e => e.preventDefault()}>
              <EllipsisOutlined
                style={{ transform: `rotate(90deg)`, fontSize: '20px' }}
              />
            </a>
          </Dropdown>
        )}
      </ItemInfoButtonDetach>
    </ContactWrapper>
  )
}
