import InputWithLabel from '../InputWithLabel'
import ACHForm from './ACHForm'
import Card from './Card'
import { Row } from './Card.styles'

export type CardInfo = {
  card_holder: string
  expiry: string
  token: string
  accttype?: string
  billing_address: {
    address: string
    city: string
    region: string
    postal: string
  }
}

interface Props {
  cardData: CardInfo
  onChangeCardData: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  onGotToken: (token: { message: string; expiry: string }) => void
  achPayment?: boolean
  error?: Record<string, string> | null
}

export const CardContainer = ({
  cardData,
  onChangeCardData,
  onGotToken,
  achPayment,
  error,
}: Props) => {
  const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    const regex = /[^a-zA-z]/g

    onChangeCardData({
      target: {
        name,
        value: value.toUpperCase().replaceAll(regex, ''),
      },
    } as React.ChangeEvent<HTMLInputElement>)
  }

  const handleChangeZip = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    const regex = /[^0-9]/g

    onChangeCardData({
      target: {
        name,
        value: value.replaceAll(regex, ''),
      },
    } as React.ChangeEvent<HTMLInputElement>)
  }

  return (
    <>
      {achPayment ? (
        <ACHForm
          cardData={cardData}
          onChange={onChangeCardData}
          onGotToken={onGotToken}
        />
      ) : (
        <Card
          cardData={cardData}
          onChange={onChangeCardData}
          onGotToken={onGotToken}
          url={
            process.env.REACT_APP_CARDCONNECT_URL +
              'itoke/ajax-tokenizer.html' || ''
          }
          params={{
            useexpiry: true,
            maskfirsttwo: true,
            autofocus: true,
            usecvv: true,
            useexpiryfield: true,
          }}
        />
      )}

      <InputWithLabel
        label={`${achPayment ? 'Account' : 'Card'} Holder *`}
        name='card_holder'
        value={cardData?.card_holder}
        onChange={onChangeCardData}
        maxLength={255}
        error={error?.card_holder}
      />

      <InputWithLabel
        label='Address *'
        name='address'
        value={cardData?.billing_address?.address}
        onChange={onChangeCardData}
        maxLength={255}
        error={error?.['address'] && 'Required field'}
      />

      <InputWithLabel
        label='City *'
        name='city'
        value={cardData?.billing_address?.city}
        onChange={onChangeCardData}
        maxLength={255}
        error={error?.['city'] && 'Required field'}
      />

      <Row>
        <InputWithLabel
          label='State *'
          name='region'
          value={cardData?.billing_address?.region}
          onChange={handleChangeState}
          maxLength={2}
          error={error?.['region'] && 'Required field'}
        />
        <InputWithLabel
          label='Zip *'
          name='postal'
          value={cardData?.billing_address?.postal}
          onChange={handleChangeZip}
          maxLength={5}
          error={error?.['postal'] && 'Required field'}
        />
      </Row>
    </>
  )
}
