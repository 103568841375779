export enum Titles {
  dispatch = 'Dispatch',
  proposals = 'Projects',
  projects = 'Projects',
  dashboard = 'Dashboard',
  map = 'Map',
  schedule = 'Schedule',
  jobslist = 'Jobs',
  jobs = 'Job',
  instantappointment = 'Instant-appointment',
  todo = 'To-do',
  estimates = 'Estimates',
  fastpayment = 'Fast-payment',
  properties = 'Properties',
  clients = 'Contacts',
  timecards = 'Time-cards',
  reviews = 'Reviews',
  quickbooks = 'Quickbooks',
  accounting = 'Accounting',
  settings = 'Settings',
  default = 'Orcatec',
  propertiesform = 'Property',
  appointment = 'Appointment',
  norigts = 'Norigts',
  workschedule = 'Work-schedule',
  twiliocalls = 'Twilio-calls',
  messaging = 'Messaging',
  leadappointments = 'Lead-appointments',
  login = 'Login',
  register = 'Register',
  publicappointments = 'Public-appointments',
  organizations = 'Organizations',
  DEFAULT = 'Orcatec',
}

interface Props {
  customTitle?: string
  prefixForTitle?: string
}

export const makeBrowserTitle = ({
  customTitle = '',
  prefixForTitle = '',
}: Props) => {
  const matchPath =
    window?.location?.pathname?.split?.('/')?.[1] || Titles.DEFAULT
  const PATH = matchPath?.split('-')?.join('')
  const title = Titles[PATH as keyof typeof Titles]
  const doc = `${customTitle ? customTitle : title || Titles.DEFAULT} ${
    prefixForTitle ? '| ' + prefixForTitle : ''
  }`
  document.title = doc
  return doc
}
