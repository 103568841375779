import { Column } from 'features/Dispatch/types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ResourceColumn from './resource-column'

import './style.scss'

interface Props {
  columns: Column[]
}

export const Resources = ({
  columns,
  onDragEnd,
  settings,
  onColumnSettingsClick,
}: Props) => {
  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    onDragEnd(columns, source.index, destination.index)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='workers' direction='horizontal'>
        {provided => (
          <div
            className='resources'
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {columns?.map?.((column, i) => (
              <Draggable
                key={column.id}
                draggableId={column?.id?.toString()}
                index={i}
              >
                {provided => (
                  <div
                    className='resource'
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ResourceColumn
                      settings={settings}
                      column={column}
                      index={i}
                      onColumnSettingsClick={onColumnSettingsClick}
                    />
                  </div>
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
