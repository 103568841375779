import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PaperClipOutlined,
} from '@ant-design/icons'
import {
  Checkbox,
  Dropdown,
  MenuProps,
  Popconfirm,
  Space,
  Spin,
  Tooltip,
} from 'antd'
import ItemsAPI from 'api/Items'
import { TextArea, TextField } from 'components/UIKit'
import { VerticalDots } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { Attachments } from 'features/Items/components/Item/components/Attachments/Attachments'
import ItemsSearch from 'features/Items/components/Item/components/ItemSearch'
import { useUnits } from 'features/Items/hooks/useUnits'
import { GlobalItem, ItemAttachment } from 'features/Items/types'
import { ProjectItem } from 'features/Project/types'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { formatStringToNumber } from 'helpers/numbers'
import { priceToView } from 'helpers/thousandSeparator'
import DocViewer from 'helpers/viewFIlesNew/ViewDoc'
import { ViewFile } from 'helpers/viewFile/viewFile'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'
import { Error } from 'types/Error'

interface Props {
  data: ProjectItem
  disabled: boolean
  error: Error | null
  editingItem: ProjectItem | null
  hideCost: boolean
  hidePrice: boolean
  hideDescription: boolean
  limited?: boolean
  loading: boolean
  onEdit: (item: ProjectItem) => void
  onCancel: () => void
  onChange: (e: { target: { name: keyof ProjectItem; value: unknown } }) => void
  onSave: (item: ProjectItem) => void
  onDelete?: (item: ProjectItem) => void
}

export const Item = ({
  data,
  error,
  disabled,
  editingItem,
  hideCost,
  hidePrice,
  hideDescription,
  limited,
  loading,
  onCancel,
  onEdit,
  onChange,
  onSave,
  onDelete,
}: Props) => {
  const isEditingItem = data.id === editingItem?.id

  const ref = useRef(null)
  const { units } = useUnits()

  const handleRowBlur = () => {
    if (!isEditingItem || limited || loading) return

    onSave(editingItem)
  }

  useOnClickOutside(ref, handleRowBlur)

  const [viewerModal, setViewerModal] = useState<{
    showDocViewerModal: boolean
    documents: ItemAttachment[]
    currentFileIdx: number | null
  }>({
    showDocViewerModal: false,
    documents: [],
    currentFileIdx: null,
  })

  const [attachmentsModal, showAttachmentsModal] = useState(false)

  const getFileForModalDocViewer = (
    files: ItemAttachment[],
    currentFileIdx: number,
  ) => {
    setViewerModal({
      documents: files,
      currentFileIdx,
      showDocViewerModal: true,
    })
  }

  const toggleOpenDocViewerModal = () => {
    setViewerModal(prev => ({
      ...prev,
      showDocViewerModal: !prev.showDocViewerModal,
    }))
  }

  const handleItemBlur = () => {
    if (!editingItem || !data.id) return

    onSave({ ...editingItem, qty: editingItem.qty ?? 1 })

    onChange({
      target: {
        name: 'qty',
        value: editingItem.qty ?? 1,
      },
    })
  }

  const handleSave = () => {
    if (!editingItem || loading) return

    onSave(editingItem)
  }

  const handleChooseItem = async (item: GlobalItem) => {
    if (!item.id) return

    try {
      const res = await ItemsAPI.getItemById(item.id)

      const choosenItem = {
        ...editingItem,
        ...res,
        global_item_id: res.id,
        id: data?.id || 0,
      }
      // delete data.id
      onEdit(choosenItem)
    } catch (error) {
      openNotificationWithIcon('error', {
        message: error?.respose?.data?.message || 'Something went wrong',
      })
    }
  }

  const handleBlurNumberField = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (value) return

    onChange({
      target: {
        name,
        value: name === 'qty' ? 1 : 0,
      },
    })
  }

  const [firstAttachment, ...otherAttachments] = isEditingItem
    ? editingItem.attachments
    : data.attachments

  const items: MenuProps['items'] = [
    {
      label: (
        <MenuItem
          onClick={() => {
            if (!!editingItem && !isEditingItem) return
            onEdit(data)
          }}
        >
          <EditOutlined
            style={{
              color: '#4688F1',
            }}
          />{' '}
          Edit
        </MenuItem>
      ),
      key: '1',
      disabled: limited,
    },
    {
      label: (
        <Popconfirm
          title='Are you sure you want to delete this item'
          onConfirm={() => {
            if (!!editingItem && !isEditingItem) return
            onDelete?.(data)
          }}
        >
          <MenuItem>
            <DeleteOutlined
              style={{
                color: '#4688F1',
              }}
            />{' '}
            Delete
          </MenuItem>
        </Popconfirm>
      ),
      key: '0',
    },
  ]

  return (
    <Spin spinning={isEditingItem && loading}>
      <Wrapper ref={ref}>
        <MainInfo>
          <Image>
            {!!firstAttachment ? (
              <div
                onClick={e => {
                  if (e?.target?.classList?.contains('ant-tooltip-inner'))
                    return
                  getFileForModalDocViewer(data.attachments, 0)
                }}
              >
                <ViewFile
                  file={
                    firstAttachment?.source ||
                    firstAttachment?.linkToFile ||
                    firstAttachment
                  }
                  fileStyle={{
                    width: 40,
                    height: 40,
                    border: '1px solid #d9d9d9',
                    borderRadius: 8,
                    padding: 5,
                  }}
                  viewOnly={false}
                  tooltipPlacement={undefined}
                  isLogo={undefined}
                />
              </div>
            ) : isEditingItem ? (
              <IconWrapper
                onClick={() => showAttachmentsModal(!attachmentsModal)}
              >
                <PaperClipOutlined style={{ fontSize: 18 }} />
              </IconWrapper>
            ) : (
              <ImagePlaceholder />
            )}
          </Image>

          {isEditingItem && !limited ? (
            <ItemsSearch
              error={error?.name}
              itinialSearchValue={editingItem.name}
              onChooseItem={handleChooseItem}
              onValueChange={value =>
                onChange({
                  target: {
                    name: 'name',
                    value,
                  },
                })
              }
            />
          ) : (
            <Name
              onClick={() => {
                if (disabled || editingItem || limited) return
                onEdit(data)
              }}
              active={!disabled && !editingItem}
            >
              {data.name}
            </Name>
          )}

          <Field centered>
            {isEditingItem && !limited ? (
              <Checkbox
                name='is_material'
                checked={editingItem?.is_material}
                onChange={e =>
                  onChange({
                    target: {
                      name: 'is_material',
                      value: e.target.checked,
                    },
                  })
                }
              />
            ) : (
              data.is_material && <CheckOutlined style={{ color: 'green' }} />
            )}
          </Field>

          <Field centered>
            {isEditingItem && !limited ? (
              <Checkbox
                name='taxable'
                checked={editingItem?.taxable}
                onChange={e =>
                  onChange({
                    target: {
                      name: 'taxable',
                      value: e.target.checked,
                    },
                  })
                }
              />
            ) : (
              data.taxable && <CheckOutlined style={{ color: 'green' }} />
            )}
          </Field>

          <Field>
            {!hideCost && (
              <>
                {isEditingItem && !limited ? (
                  <NumberFormat
                    allowNegative={false}
                    thousandSeparator
                    name='net_price'
                    decimalScale={3}
                    customInput={TextField}
                    value={editingItem?.net_price}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange({
                        target: {
                          name: 'net_price',
                          value: formatStringToNumber(e),
                        },
                      })
                    }}
                    onBlur={handleBlurNumberField}
                    prefix='$'
                    // size='small'
                  />
                ) : (
                  `${priceToView(data.net_price, false, 3)}`
                )}
              </>
            )}
          </Field>
          <Field>
            {!hideCost && (
              <>
                {isEditingItem && !limited ? (
                  <GrossMargin>
                    <Checkbox
                      checked={editingItem?.gross_margin !== null}
                      onChange={() =>
                        onChange({
                          target: {
                            name: 'gross_margin',
                            value:
                              editingItem?.gross_margin !== null ? null : 0,
                          },
                        })
                      }
                    />
                    {editingItem?.gross_margin === null ? (
                      <TextField value='n/a' disabled /* size='small' */ />
                    ) : (
                      <NumberFormat
                        allowNegative={false}
                        thousandSeparator
                        decimalScale={2}
                        name='gross_margin'
                        disabled={editingItem?.gross_margin === null}
                        customInput={TextField}
                        value={editingItem?.gross_margin}
                        onBlur={handleBlurNumberField}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange({
                            target: {
                              name: 'gross_margin',
                              value: formatStringToNumber(e),
                            },
                          })
                        }}
                        suffix='%'
                      />
                    )}
                  </GrossMargin>
                ) : (
                  <p>{`${data.gross_margin || 0}%`}</p>
                )}
              </>
            )}
          </Field>

          <Field>
            {!hidePrice && (
              <>
                {isEditingItem && !limited ? (
                  <NumberFormat
                    allowNegative={false}
                    value={editingItem?.retail_price}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange({
                        target: {
                          name: 'retail_price',
                          value: formatStringToNumber(e),
                        },
                      })
                    }}
                    disabled={editingItem?.gross_margin !== null}
                    onBlur={handleBlurNumberField}
                    name='retail_price'
                    customInput={TextField}
                    prefix='$'
                    // size='small'
                    thousandSeparator
                    decimalScale={3}
                  />
                ) : (
                  <>
                    {priceToView(
                      isEditingItem && !limited
                        ? editingItem?.retail_price
                        : data.retail_price,
                      false,
                      3,
                    )}
                  </>
                )}
              </>
            )}
          </Field>

          <Field>
            {isEditingItem ? (
              <NumberFormat
                allowNegative={false}
                value={editingItem.qty}
                onValueChange={valuees =>
                  onChange({
                    target: { name: 'qty', value: valuees.floatValue },
                  })
                }
                onBlur={handleItemBlur}
                name='qty'
                customInput={TextField}
                // label='Qty'
                thousandSeparator
                decimalScale={2}
              />
            ) : limited ? (
              <NumberFormat
                allowNegative={false}
                value={data.qty}
                onClick={() => {
                  onEdit(data)
                }}
                name='qty'
                customInput={TextField}
                thousandSeparator
                decimalScale={2}
              />
            ) : (
              <p>{data.qty}</p>
            )}
          </Field>
          <Field>
            <p
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {data?.unit_id ? units[data?.unit_id]?.name : 'Qty'}
            </p>
          </Field>

          <Total>
            {!hidePrice && (
              <>
                {isEditingItem
                  ? priceToView(
                      (editingItem?.retail_price || 0) *
                        (editingItem?.qty || 0),
                    )
                  : priceToView(data.retail_price * (data.qty || 0))}
              </>
            )}
          </Total>

          <Field centered>
            {isEditingItem && !limited ? (
              <Buttons>
                <Tooltip title='Save item' mouseLeaveDelay={0}>
                  <CheckOutlined
                    style={{ color: 'green' }}
                    onClick={handleSave}
                  />
                </Tooltip>
                <Tooltip title='Cancel' mouseLeaveDelay={0}>
                  <CloseOutlined style={{ color: 'red' }} onClick={onCancel} />
                </Tooltip>
              </Buttons>
            ) : (
              <Dropdown
                menu={{ items: items.filter(item => !item?.disabled) }}
                disabled={disabled}
              >
                <a onClick={e => e.preventDefault()}>
                  <Space>
                    <VerticalDots style={{ fontSize: 20, cursor: 'pointer' }} />
                  </Space>
                </a>
              </Dropdown>
            )}
          </Field>
        </MainInfo>

        <Description>
          {!hideDescription && (
            <>
              {isEditingItem && !limited ? (
                <TextArea
                  name='description'
                  label='Description'
                  value={editingItem.description}
                  onChange={onChange}
                />
              ) : (
                data.description
              )}
            </>
          )}
        </Description>

        {(!!otherAttachments.length || (isEditingItem && !limited)) && (
          <AttachmentsWrapper hide={isEditingItem && !firstAttachment}>
            <Attachments
              ref={ref}
              open={attachmentsModal}
              imageSize={35}
              data={
                !!limited || !isEditingItem
                  ? otherAttachments
                  : editingItem?.attachments
              }
              disabled={disabled || !isEditingItem || !!limited}
              onUpload={(files: ItemAttachment[]) => {
                onChange({
                  target: {
                    name: 'attachments',
                    value: files,
                  },
                })
              }}
              tooltip
            />
          </AttachmentsWrapper>
        )}
      </Wrapper>

      {viewerModal?.showDocViewerModal && (
        <DocViewer
          documents={viewerModal.documents}
          currentDocument={viewerModal.currentFileIdx}
          isModalVisible={viewerModal.showDocViewerModal}
          handleCancel={toggleOpenDocViewerModal}
        />
      )}
    </Spin>
  )
}

const Wrapper = styled.div`
  padding: 12px;
`
const MainInfo = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 50px 50px 80px 80px 80px 50px 50px 80px 60px;
  align-items: center;
  gap: 8px;
`
const Name = styled.p<{ active: boolean }>`
  cursor: ${props => (props.active ? 'pointer' : 'initial')};
  font-weight: 500;
  white-space: pre-wrap;
`
const Description = styled.p`
  margin-top: 8px;
  padding-top: 8px;
  white-space: pre-wrap;
`
const Image = styled.div``
const Field = styled.div<{ centered?: boolean }>`
  text-align: ${props => (props.centered ? 'center' : 'left')};
`

const Total = styled.p`
  font-weight: 500;
`

const MenuItem = styled.div`
  display: flex;
  gap: 8px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
`

const AttachmentsWrapper = styled.div<{ hide: boolean }>`
  display: ${props => (props.hide ? 'none' : 'block')};
  margin-top: 10px;
`

const GrossMargin = styled.div`
  display: flex;
  align-items: center;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
`

const ImagePlaceholder = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-image: url('/assets/images/placeholder-image.png');
  background-position: center;
  background-size: contain;
`
