export const ActionType = {
  SUPPORT_TICKETS_LIST_INIT: 'SUPPORT_TICKETS_LIST_INIT',
  SUPPORT_TICKETS_LIST_SUCCESS: 'SUPPORT_TICKETS_LIST_SUCCESS',
  SUPPORT_TICKETS_LIST_FAILURE: 'SUPPORT_TICKETS_LIST_FAILURE',

  CLEAR_TICKET: 'CLEAR_TICKET',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',

  SHOW_TICKET_INIT: 'SHOW_TICKET_INIT',
  SHOW_TICKET_SUCCESS: 'SHOW_TICKET_SUCCESS',
  SHOW_TICKET_FAILURE: 'SHOW_TICKET_FAILURE',
  TICKET_LOADING_DATA: 'TICKET_LOADING_DATA',
  TICKET_LOADED_DATA: 'TICKET_LOADED_DATA',
  ASSIGNED_TICKET_ASSISTANT: 'ASSIGNED_TICKET_ASSISTANT',
  NEW_TICKET_MESSAGE: 'NEW_TICKET_MESSAGE',
  TICKET_MESSAGE_DELETED: 'TICKET_MESSAGE_DELETED',
  TICKET_MESSAGE_UPDATED: 'TICKET_MESSAGE_UPDATED',
  TICKET_CHANGED_STATUS: 'TICKET_CHANGED_STATUS',

  REPLY_MESSAGE_INIT: 'REPLY_MESSAGE_INIT',
  REPLY_MESSAGE_SUCCESS: 'REPLY_MESSAGE_SUCCESS',
  REPLY_MESSAGE_FAILURE: 'REPLY_MESSAGE_FAILURE',

  UPDATE_MESSAGE_INIT: 'UPDATE_MESSAGE_INIT',
  UPDATE_MESSAGE_SUCCESS: 'UPDATE_MESSAGE_SUCCESS',
  UPDATE_MESSAGE_FAILURE: 'UPDATE_MESSAGE_FAILURE',

  SET_UNSEEN_TICKETS_COUNT: 'SET_UNSEEN_TICKETS_COUNT',
  INCREMENT_UNSEEN_TICKETS_COUNT: 'INCREMENT_UNSEEN_TICKETS_COUNT',
  DECREMENT_UNSEEN_TICKETS_COUNT: 'DECREMENT_UNSEEN_TICKETS_COUNT',
}
