import { useAppSelector } from 'store/Orcatec/hooks'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ModuleName,
  SchedulePermissions,
} from 'features/Settings/UsersAndGroups'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import { selectAuthUser } from 'store/Orcatec/selectors/user'

interface IReturn {
  payment: boolean
  supplies: boolean
  module_1: boolean
  module_2: boolean
  module_3: boolean
}

interface IProps {
  user_id: number
}

export const useModulePermissions = ({ user_id }: IProps): IReturn => {
  const perm = useAppSelector(
    selectUserPermissionsByModule(ModuleName.SCHEDULE),
  )
  const me = useAppSelector(selectAuthUser)

  return {
    payment: checkAccessControl(
      perm[SchedulePermissions.SCHEDULE_PAYMENT_MODULE_CAN_CHANGE_STATUS],
      user_id,
      me.id,
    ),
    supplies: checkAccessControl(
      perm[SchedulePermissions.SCHEDULE_SUPPLY_MODULE_CAN_CHANGE_STATUS],
      user_id,
      me.id,
    ),
    module_1: checkAccessControl(
      perm[SchedulePermissions.SCHEDULE_CUSTOM_MODULE_1_CAN_CHANGE_STATUS],
      user_id,
      me.id,
    ),
    module_2: checkAccessControl(
      perm[SchedulePermissions.SCHEDULE_CUSTOM_MODULE_2_CAN_CHANGE_STATUS],
      user_id,
      me.id,
    ),
    module_3: checkAccessControl(
      perm[SchedulePermissions.SCHEDULE_CUSTOM_MODULE_3_CAN_CHANGE_STATUS],
      user_id,
      me.id,
    ),
  }
}
