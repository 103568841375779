import axiosOrcatec from '../axiosInstance'

interface Industry {
  id: number
  name: string
}

/**
 * Fetch proposal setting.
 *
 * @return {*}
 */
export const getIndustry = (id: number) => axiosOrcatec.get(`industries/${id}`)

export const deleteIndustryById = (id: number) =>
  axiosOrcatec.delete(`industries/${id}`).then(response => response.data)

export const getAllIndustries = () =>
  axiosOrcatec.get<Industry[]>('/industries').then(r => r && r.data)
