import React from 'react'
import ReactDOM from 'react-dom'
import { Modal } from 'react-bootstrap'
import { Button } from 'antd'
import MainButton from '../../../../components/buttons/MainButton'
import './SureModal.scss'

const cancelButtonStyle = { color: '#191919', border: '1px solid #D6D6D6', marginRight: '10px' }

const SureModal = ({ modal = false, body, close, apply, isFetching = false, dialogClassName = '' }) => {
  return (
    <Modal
      show={modal}
      backdrop='static'
      onHide={close}
      className='settings-matrix-sure-modal'
      centered={true}
      dialogClassName={`w-75 ${dialogClassName}`}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className='w-100 d-flex justify-content-center'>
          <div className='settings-matrix-sure-modal-title'>{body}</div>
        </div>
        <div className='d-flex justify-content-center buttons'>
          <Button variant='default' onClick={close} className='main-button user-cancel-btn' style={cancelButtonStyle}>
            Cancel
          </Button>
          <MainButton title='Confirm' onClick={apply} className='main-button' isFetching={isFetching} />
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default SureModal
