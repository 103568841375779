import { useState } from 'react'
import { WorkOrdersList } from './components/WorkOrdersList/WorkOrdersList'
import { ListWrapper } from './WorkOrder.styles'
import { CreateWorkOrder } from './components/CreateWorkOrder/CreateWorkOrder'
import styled from 'styled-components'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import {
  IWorkOrderState,
  ProjectStatusGroup,
  WorkOrderRowData,
} from 'features/Project/types'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { WorkOrderPage } from './components/WorkOrderPage/WorkOrderPage'
import { createWorkOrder } from 'features/Project/slices/projectWorkOrdersSlice'
import { selectProject } from 'features/Project/projectSelectors'
import { Tooltip } from 'antd'

const initialState: IWorkOrderState = {
  forms: true,
  contacts: true,
  scope_of_work: true,
  notes: true,
  info_for_workers: true,
  customer_info: true,
}

export const WorkOrder = () => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectProject)

  const [open, setOpen] = useState(false)
  const [state, setState] = useState(initialState)
  const [activeItemId, setActiveItemId] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setState(initialState)
  }

  const handleSave = async () => {
    setLoading(true)

    const res = await dispatch(
      createWorkOrder({
        proposal_id: project.id,
        related_entities: state,
      }),
    )

    setLoading(false)

    if (res.meta.requestStatus === 'rejected') return

    setOpen(false)

    setActiveItemId(res.payload?.id)
  }

  const onChange = (e: CheckboxChangeEvent) => {
    setState(prev => ({ ...prev, [e.target.name as string]: e.target.checked }))
  }

  const handleOpenDetails = (item: WorkOrderRowData) => {
    setActiveItemId(item.id)
  }

  const handleCloseDetails = () => {
    setActiveItemId(null)
  }

  const disabled = ![ProjectStatusGroup.Contract].includes(project.status)

  return (
    <ListWrapper>
      {activeItemId ? (
        <WorkOrderPage id={activeItemId} onGoBack={handleCloseDetails} />
      ) : (
        <>
          <Heading>
            <p className='section-title'>Work Orders</p>

            <Tooltip
              title={disabled && 'Allowed to create only in Contract status'}
            >
              <span>
                <MainButton
                  onClick={handleOpenModal}
                  title='+ Add Work Order'
                  disabled={disabled}
                />
              </span>
            </Tooltip>
          </Heading>
          <WorkOrdersList
            projectId={project.id}
            handleOpenDetails={handleOpenDetails}
            disabled={disabled}
          />
        </>
      )}

      {open && (
        <CreateWorkOrder
          onCancel={handleCloseModal}
          state={state}
          onChange={onChange}
          onSave={handleSave}
          loading={loading}
          // clients={contacts.clients}
          // properties={contacts.properties}
        />
      )}
    </ListWrapper>
  )
}

const Heading = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .section-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(38, 38, 38, 1);
  }
`
