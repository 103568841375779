import React from "react"
import '../../styles/styles.scss'

const VideoRenderer= ({state}) => {
  const { currentDocument} = state
  return (
   <div className="video-wrapper">
        <video controls autoPlay muted loop playsInline controlsList="nodownload" src={currentDocument?.source || currentDocument?.linkToFile || currentDocument} ></video> 
   </div>
  )
}



VideoRenderer.fileTypes = 'video'

VideoRenderer.headerType='video'

export default VideoRenderer