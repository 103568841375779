import { useState } from 'react'
import { connect } from 'react-redux'
import * as actions from 'store/Orcatec/actions'
import TabInfoBlock from './TabInfoBlock'
// import { putProjectFWOSettings } from 'api/Project'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { Companies } from 'types/Company'
import moment from 'moment-timezone'
import { updateProjectField } from 'features/Project/slices/projectSlice'
import { selectProject } from 'features/Project/projectSelectors'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { JobForm } from 'containers/MainContent/Orcatec/Jobs/components/JobPage/components/JobForm/JobForm'
import { toggleModal } from 'store/Orcatec/actions/modals/installationDate'
import ModalFileUploadContainer from '../../../Modals/ModalFileUploadContainer'
import { updateFwoFieldThunk } from 'features/Project/slices/projectFwoSlice'

const TabInfoBlockContainer = ({
  //from redux
  currentTab,
  // onChangeField,
  setAllJobsToComplete,
  // onSetField,
  dictionary,
  ...props
}) => {
  const dispatch = useAppDispatch()

  const companyId = useAppSelector(state => state.orcatec.company.id)
  const jobModal = useAppSelector(state => state.orcatec.installationDate.show)
  const { id: projectId, issue_date } = useAppSelector(selectProject)

  const [jobCreated, setJobCreated] = useState(false)

  const handleChangeAllWorkComplited = (e, completeRelateJobs) => {
    const { checked } = e.target

    const dateStr = moment().format('MM/DD/YYYY')
    // const value = checked ? 1 : 0
    // onChangeField(checked, 'all_work_completed')
    dispatch(
      updateProjectField({
        all_work_completed: checked,
        date_of_completion: checked ? dateStr : null,
        complete_related_jobs: !!completeRelateJobs,
        issue_date:
          companyId === Companies.DivineEnergySolutions ? dateStr : issue_date,
      }),
    )
    // onChangeField(!!completeRelateJobs, 'complete_related_jobs')

    /* if ([Companies.DivineEnergySolutions].includes(companyId)) {
      onSetField(dateStr, 'issue_date')
    }
    ) */

    // if (!checked) {
    //   onSetField(null, 'date_of_completion')
    // } else {
    //   onSetField(dateStr, 'date_of_completion')
    // }

    if (completeRelateJobs) setAllJobsToComplete(!!checked)
  }

  const handleChangeLeadInstaller = async (id, valueStr) => {
    // onChangeField(id, 'lead_installer_id')
    dispatch(
      updateProjectField({
        lead_installer_id: id,
      }),
    )
    const fields = { foreman: valueStr }
    dispatch(updateFwoFieldThunk({ fields }))

    // await putProjectFWOSettings(projectId, { foreman: valueStr }, companyId)
  }

  const handleChangeWorkCompletionDate = async (projectId, dateStr) => {
    try {
      // onSetField(dateStr, 'date_of_completion')
      // await updateProjectRelation(projectId, { date_of_completion: dateStr })
      await dispatch(
        updateProjectField({
          date_of_completion: dateStr,
          issue_date: [Companies.DivineEnergySolutions].includes(companyId)
            ? dateStr
            : issue_date,
        }),
      )

      // await putProjectFWOSettings(projectId, { date: dateStr }, companyId)
      const fields = { date: dateStr }
      dispatch(updateFwoFieldThunk({ fields }))
      openNotificationWithIcon('success', {
        message: 'Date of completion was saved successfully!',
      })

      // if ([Companies.DivineEnergySolutions].includes(companyId)) {
      //   onSetField(dateStr, 'issue_date')
      // }
    } catch (error) {
      console.error(error)
    }
  }

  const toggleInstallationDate = () => dispatch(toggleModal())

  const handleJobCreate = () => {
    toggleInstallationDate()
    setJobCreated(true)
  }

  return currentTab ? (
    <>
      <TabInfoBlock
        currentTab={currentTab}
        jobCreated={jobCreated}
        onChangeAllWorkComplited={handleChangeAllWorkComplited}
        onChangeLeadInstaller={handleChangeLeadInstaller}
        handleChangeWorkCompletionDate={handleChangeWorkCompletionDate}
        openInstallationDate={toggleInstallationDate}
        {...props}
      />

      {jobModal && (
        <Modal
          title={`Create New ${dictionary.singular}`}
          width={550}
          footer={null}
          visible
          centered
          destroyOnClose
          onCancel={toggleInstallationDate}
          maskClosable={false}
        >
          <JobForm
            proposalId={projectId}
            onJobCreated={handleJobCreate}
            onCancel={toggleInstallationDate}
          />
        </Modal>
      )}

      <ModalFileUploadContainer files={currentTab.files} />
    </>
  ) : null
}

const mapStateToProps = state => ({
  currentTab: state.orcatec.projectTabsSlice.projectTab,
  tabsQuantity: state.orcatec.projectSlice.project.tabs_info?.length,
  proposalForm: state.orcatec.projectSlice.project,
  dictionary: state.orcatec.company.dictionary.job,
})

const mapDispatchToProps = {
  toggleFileModal: actions.proposalModalFiles.toggleModal,
  setAllJobsToComplete: actions.jobs.setAllJobsToComplete,
  // onChangeTabDetails: actions.proposalForm.onChangeTabDetails,
  // openInstallationDate: actions.installationDate.toggleModal,
  // onChangeField: actions.proposalForm.onChangeField,
  // onSetField: actions.proposalForm.onSetField,
  // deleteTabFile: actions.proposalForm.deleteTabFile,
  // onDeleteTabTag: actions.proposalForm.onDeleteTag,
  // onAddTabTag: actions.proposalForm.onAddTag,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TabInfoBlockContainer)
