import './style.scss'
import PopapWrapper from '../PopapWrapper'

const EmployeeHomePopup = ({ employee, className }) => {
  return (
    <PopapWrapper className={className}>
      <div className='row d-flex px-2'>
        <span>Name:</span>
        <span>
          <strong>{employee.full_name}</strong>
        </span>
      </div>

      <div className='row d-flex px-2'>
        <span>Title:</span>
        <span>
          <strong>{employee.title}</strong>
        </span>
      </div>

      {employee?.description && (
        <div className='row d-flex px-2'>
          <span>Description:</span>
          <span>
            <strong>{employee.description}</strong>
          </span>
        </div>
      )}
    </PopapWrapper>
  )
}

export default EmployeeHomePopup
