import styled from 'styled-components'

export const PopoverContentWrapper = styled.div``
export const PopoverContentHeader = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
  & div {
    padding: 4px;
    cursor: pointer;
    transition: all 0.2s;
    transform: scale(1);
  }
  & div:hover {
    background-color: rgba(32, 33, 36, 0.039);
    border-radius: 50%;
    transition: all 0.2s;
    transform: scale(1.1);
  }
`
export const PopoverContentAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  & .event-address {
    /* color: #1890ff; */
    font-weight: bold;
    & span {
      padding: 0 2px;
    }
  }
`
export const PopoverContentDate = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  margin-bottom: 10px;
`
export const PopoverContentNotes = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  transition: all 0.5s ease-out;
  width: 100%;
  max-width: 300px;
  & .notes__hidden {
    max-height: 0;
    transition: all 0.5s ease-out;
    overflow: hidden;
  }
  & .notes__visible {
    max-height: 100px;
    transition: all 0.5s ease-in;
    overflow: scroll;
  }
  & .event-notes {
    width: 100%;
    padding: 2px;
    transition: all 0.2s ease-in;
    border-radius: 2px;
    cursor: pointer;
    &:hover {
      background-color: #dce7f1;
    }
  }
`
export const PopoverContentType = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  & .type {
    font-weight: bold;
  }
`
export const PopoverContentClient = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`
export const PopoverContentProject = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`
export const PopoverContentStatus = styled.div`
  background-color: transparent;
  transform: scale(1);
  display: flex;
  align-items: center;
  & p {
    font-weight: bold;
  }
  &:hover {
    background-color: transparent !important;
    border-radius: 0;
    transform: scale(1);
  }
  & div {
    padding: 4px;
    cursor: pointer;
    transition: all 0.2s;
    background-color: transparent;
  }
  & div:hover {
    background-color: transparent;
    border-radius: 0;
    transition: all 0.2s;
    transform: scale(1);
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiSelect-iconOutlined {
    display: none;
  }
  & .MuiSelect-outlined {
    padding: 0 !important;
    &:focus {
      background-color: transparent;
    }
  }
`
export const PopoverContent = styled.div``
export const PopoverPhoneContent = styled.div``
