import * as types from '../types'
import { getPaymentsModal } from '../../../api/Payment'

export const setPayments = response => {
  // if (response?.payment_balances) {
  //   const balances = response?.payment_balances?.map(b => ({
  //     ...b,
  //     amount: typeof b?.amount === 'string' ? b.amount?.replace?.(/,/g, '')?.replace?.('.00', '') : b.amount,
  //   }))
  //   response.payment_balances = balances
  // }

  return {
    type: types.payments.SET_PAYMENTS,
    response,
  }
}

export const fetchPaymentsList = (id, type, proposalTabId) => dispatch => {
  return getPaymentsModal(id, type, proposalTabId).then(response =>
    dispatch(setPayments(response)),
  )
}

// export const fetchPaymentsList = (id, data) => {
//     return getPaymentsModal(id, data).then(responce => action.response)
// }
