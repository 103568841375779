import React, { useEffect, useState } from 'react'
import { Marker } from 'react-leaflet'
import L from 'leaflet'
import ReactDOMServer from 'react-dom/server'
import EmployeeInfoMarkerPopap from './../Popaps/EmployeeInfoMarkerPopap/EmployeeInfoMarkerPopap'
import TestIcon from '../Icons/TestIcon/TestIcon'
import { fetchPath } from './../../../../../../../../api/map'

const EmployeeMarker = ({
  lat,
  lng,
  time,
  employee,
  nextAppointmentLocation,
  remainingAppointments,
  nextAppointment,
}) => {
  const [timeToNextAppointment, setTimeToNextAppointment] = useState(null)
  const [distance, setDistance] = useState(null)
  useEffect(() => {
    if (nextAppointmentLocation) {
      fetchPath(`${lng},${lat};${nextAppointmentLocation[1]},${nextAppointmentLocation[0]}`).then(response => {
        if (response.routes.length) {
          setTimeToNextAppointment(response.routes[0].duration ? response.routes[0].duration / 60 : 0)
          setDistance(response.routes[0].distance)
        }
      })
    }
  }, [nextAppointmentLocation])

  return (
    <Marker
      position={[Number(lat), Number(lng)]}
      icon={L.divIcon({
        iconSize: null,
        html: ReactDOMServer.renderToString(
          <TestIcon profilePicture={process.env.REACT_APP_API_MEDIA + employee.info.profile_picture || ''} />,
        ),
      })}
      className='redborder'
    >
      <EmployeeInfoMarkerPopap
        className='popaps-with-content w-180'
        employee={employee}
        timeToNextAppointment={timeToNextAppointment}
        remainingAppointments={remainingAppointments}
        distance={distance}
        time={time}
        nextAppointment={nextAppointment}
      />
    </Marker>
  )
}

export default EmployeeMarker
