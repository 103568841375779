import { List, ListItem } from './SubMenu.styles'
import Close from '@material-ui/icons/Close'
import { Badge, Tooltip } from 'antd'

interface Props {
  activeItem: number
  menuItemsList: {
    id: number
    title: string
    counter?: number
  }[]
  onChange: (id: number) => void
}

export const SubMenu: React.FC<Props> = ({
  menuItemsList,
  activeItem,
  onChange,
  ...rest
}) => {
  return (
    <List>
      {menuItemsList.map((item, idx) => (
        <ListItem
          key={item.id}
          onClick={() => onChange(item.id)}
          className={activeItem === item.id ? ' active' : ''}
          {...rest}
        >
          <Badge
            size='small'
            color='#3B71FE'
            count={item.counter}
            offset={[6, 0]}
          >
            {item.title}
          </Badge>
          {rest?.closeIcon && idx !== 0 ? (
            <span
              style={{ position: 'absolute' }}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                rest.onClose()
              }}
            >
              {
                <Tooltip title='Close contact'>
                  <Close style={{ color: 'black' }} />
                </Tooltip>
              }
            </span>
          ) : (
            ''
          )}
        </ListItem>
      ))}
    </List>
  )
}
