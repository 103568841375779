const getItems = items => {
  return Array.isArray(items)
    ? items?.length
      ? items.filter(
          (item, idx, arr) => arr.findIndex(a => a.id === item.id) === idx,
        )
      : items
    : []
}
export const getSelectedClients = proposalForm => {
  const clients = getItems(proposalForm?.clients)
  const organizations = getItems(proposalForm?.organizations)
  return [...clients, ...organizations]
  /* return !!proposalForm.property && Array.isArray(proposalForm.property.clients)
    ? !proposalForm.client_ids || (!!proposalForm.client_ids && !proposalForm.client_ids.some(client => client.checked))
      ? proposalForm.property.clients
      : proposalForm.property.clients.filter(client => {
          const selectedClient = proposalForm.client_ids.find(item => item.client_id === client.id)
          return !!selectedClient && selectedClient.checked
        })
    : [] */
}
