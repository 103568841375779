import styled from 'styled-components'

export const Wrapper = styled.div``

export const ContentWrapper = styled.div`
  min-height: 1000px;
  margin-top: 37px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e6e8ec;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 20px 1px;
`
