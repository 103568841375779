import {
  selectProject,
  selectProjectSections,
  selectSectionDiscounts,
  selectSectionRebates,
  selectSectionSubtotal,
} from 'features/Project/projectSelectors'
import { useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import { SectionDiscounts } from './SectionDiscounts'
import { SectionRebates } from './SectionRebates'
import { ProjectSection } from 'features/Project/types'
import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'

interface Props {
  data: ProjectSection
}

export const Section = ({ data }: Props) => {
  const project = useAppSelector(selectProject)
  const projectSections = useAppSelector(selectProjectSections)
  const rebates = useAppSelector(selectSectionRebates(data.id, null))
  const discounts = useAppSelector(selectSectionDiscounts(data.id, null))
  const sectionSubtotal = useAppSelector(selectSectionSubtotal(data.id))
  const isVisiblePrice = useAppSelector(selectIsPriceVisible(project?.user_id))

  if (!rebates.length && !discounts.length) return null

  return (
    <Wrapper withTitle={projectSections.length > 1}>
      {projectSections.length > 1 && (
        <Title>
          <span>Section:</span> {data.title}
        </Title>
      )}
      <div>
        {!!discounts.length && (
          <SectionDiscounts
            data={discounts.filter(discount =>
              discount.option_group_id ? discount.checked_option : discount,
            )}
            sectionSubtotal={sectionSubtotal}
            showPrice={isVisiblePrice}
          />
        )}
        {!!rebates.length && (
          <SectionRebates
            data={rebates.filter(rebate =>
              rebate.option_group_id ? rebate.checked_option : rebate,
            )}
            showPrice={isVisiblePrice}
          />
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ withTitle: boolean }>`
  display: grid;
  grid-template-columns: ${props => (props.withTitle ? '1fr 3fr' : '1fr')};
  gap: 10px;
  padding: 5px 0;
`

const Title = styled.p`
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    letter-spacing: 0.01em;
    color: #191919;
    opacity: 0.5;
  }
`
