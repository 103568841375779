import * as types from '../types'

export default (state = [], action) => {
  switch (action.type) {
    case types.articles.GET_ARTICLES:
      return { articles: action.response }
    case types.articles.SET_CURRENT_ARTICLE: {
      const newState = state
      newState.current_article = action.data
      return newState
    }
    default:
      return state
  }
}
