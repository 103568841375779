import { useState, useEffect } from 'react'
import { companyInfo, updateFeedback, sendFeedback } from 'api/Reviews'
import styled from 'styled-components'
import { Result, Spin } from 'antd'
import { FeedbackForm } from './components/FeedbackForm/FeedbackForm'
import { FeedbackSocialForm } from './components/FeedbackSocialForm/FeedbackSocialForm'
import { SmileOutlined } from '@ant-design/icons'
import { VoteForm } from './components/VoteForm/VoteForm'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { FeedbackItem } from './components/FeedbackItem/FeedbackItem'
import { IReviewsTemplate } from './types'
import { Companies } from 'types/Company'
import ReactHtmlParser from 'react-html-parser'
import { Forward } from '@material-ui/icons'

const queryStr = '_rrt'

const NEGATIVE_REVIEW_VOTE = [0, 1, 2, 3]

enum Steps {
  VOTE_FORM,
  COMMENT_FORM,
  SOCIAL_FORM,
  WAS_SENT_FORM,
  BAD_LINK,
}

export const ReviewRequest = () => {
  const [token, setToken] = useState<string>('')
  const [company, setCompany] = useState(null)

  const [template, setTemplate] = useState<IReviewsTemplate>({
    negative_rating_text: '',
    positive_after_links_text: '',
    positive_below_links_text: '',
    positive_header_text: '',
    positive_thank_you_screen_text: '',
    set_rating_text: '',
  })

  const [steps, setSteps] = useState(Steps.VOTE_FORM)

  const [isLoad, setIsLoad] = useState(true)

  const [vote, setVote] = useState(0)

  const [spining, setSpining] = useState(false)

  const itThereIsSomeLink =
    !!company?.data?.social &&
    Object?.values(company?.data?.social || {}).some(link => !!link)

  useEffect(() => {
    const currentQueryString = window.location.search

    const urlSearchParams = new URLSearchParams(currentQueryString)

    const rrtValue = urlSearchParams.get(queryStr)

    if (rrtValue) {
      setToken(rrtValue)
    } else {
      setSteps(Steps.BAD_LINK)
      setIsLoad(false)
    }
  }, [])

  useEffect(() => {
    if (token) {
      setIsLoad(true)
      companyInfo(token)
        .then(response => {
          setCompany(response)
          setTemplate(response?.data)
          if (!!response?.feedbacks?.length) {
            return setSteps(Steps.WAS_SENT_FORM)
          }

          setSteps(Steps.VOTE_FORM)
        })
        .catch(error => {
          if (error?.response?.status === 400) setSteps(Steps.WAS_SENT_FORM)
          if (error?.response?.status === 422) setSteps(Steps.BAD_LINK)
          if (error?.response?.status === 404) setSteps(Steps.BAD_LINK)
        })
        .finally(() => {
          setIsLoad(false)
        })
    }
  }, [token])

  const handleSelectVote = async (vote: number) => {
    setSpining(true)
    try {
      const isNegative = NEGATIVE_REVIEW_VOTE.includes(vote)

      await sendFeedback(token, vote, isNegative ? 1 : null)

      setVote(vote)
    } catch (error) {
      console.error(error)
    } finally {
      setTimeout(() => {
        const nextStep = NEGATIVE_REVIEW_VOTE.includes(vote)
          ? Steps.COMMENT_FORM
          : itThereIsSomeLink
          ? Steps.SOCIAL_FORM
          : Steps.COMMENT_FORM

        setSteps(nextStep)
        setSpining(false)
      }, 400)
    }
  }

  const handleUpdateFeedback = async (name, feedback) => {
    try {
      await updateFeedback({
        _rrt: token,
        name,
        feedback,
      })
      setSteps(Steps.WAS_SENT_FORM)
    } catch (error) {
      console.error(error)
      const message = Object.values(error?.response?.data?.errors || {})?.[0]
      openNotificationWithIcon('error', { message })
    }
  }
  const handleSelectSocial = async type => {
    try {
      const res = await updateFeedback({
        _rrt: token,
        type,
      })
      setCompany(prev => ({
        ...prev,
        feedbacks: [...(prev?.feedbacks || {}), res?.data],
      }))
      setSteps(Steps.WAS_SENT_FORM)
    } catch (error) {
      console.error(error)
    }
  }

  const renderContent = (vote: number) => {
    if (steps === Steps.VOTE_FORM) {
      return (
        <VoteForm
          company={company?.data}
          handleSelect={handleSelectVote}
          vote={vote}
          spining={spining}
          template={template}
        />
      )
    }
    if (steps === Steps.COMMENT_FORM) {
      return (
        <FeedbackForm template={template} onSubmit={handleUpdateFeedback} />
      )
    }
    if (steps === Steps.SOCIAL_FORM) {
      return (
        <FeedbackSocialForm
          company={company?.data}
          handleSelect={handleSelectSocial}
          template={template}
        />
      )
    }

    if (steps === Steps.WAS_SENT_FORM) {
      return (
        <>
          {[Companies.ApplianceServiСenter].includes(
            company?.data?.company_id,
          ) &&
            !NEGATIVE_REVIEW_VOTE.includes(vote) && (
              <BackButton onClick={() => setSteps(Steps.SOCIAL_FORM)}>
                <span>Back to social links</span>
                <div className='arrow-block'>
                  <Forward />
                </div>
              </BackButton>
            )}

          <Result
            icon={<SmileOutlined />}
            title={
              <div>
                <p>Thank you for your feedback.</p>
                {[Companies.ApplianceServiСenter].includes(
                  company?.data?.company_id,
                ) && !NEGATIVE_REVIEW_VOTE.includes(vote)
                  ? ReactHtmlParser(template?.positive_thank_you_screen_text)
                  : ''}
              </div>
            }
          />
          {!!company?.feedbacks?.length &&
            company?.feedbacks?.map((feedback, idx) => (
              <FeedbackItem
                key={idx}
                item={{
                  ...feedback,
                  feedback_rating: feedback?.vote,
                  feedback_created_at: feedback?.created_at,
                  feedback_action: feedback?.type,
                }}
              />
            ))}
        </>
      )
    }

    if (steps === Steps.BAD_LINK) {
      return (
        <Result
          status='404'
          title='404'
          subTitle='Sorry, the page you visited does not exist.'
        />
      )
    }
  }

  return (
    <Wrapper>
      <WrapperInner>
        <BodyWrapper>{isLoad ? <Spin /> : renderContent(vote)}</BodyWrapper>
      </WrapperInner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/assets/images/bg.jpg');
  position: fixed;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  overflow: scroll;
`
const WrapperInner = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  min-height: 50vh;
  width: 100%;
  max-width: 500px;
  text-align: center;
  z-index: 0;
  border: 1px solid #efeaea;
  /* overflow: scroll; */
`
const BodyWrapper = styled.div`
  padding: 20px;
`

const BackButton = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 20px;
  color: white;
  background-color: var(--main-color);
  border-radius: 25px;
  padding: 3px 4px 3px 12px;

  & .arrow-block {
    transform: rotate(180deg);
    background-color: white;
    border-radius: 50%;
    padding: 3px;
    & svg {
      fill: var(--main-color);
    }
  }
`
