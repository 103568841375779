import { Tooltip } from 'antd'
import { Wrapper } from './Avatar.styles'

type Props = {
  pictureURL?: string | null
  size?: number
  color?: string | null
  children?: string
  text?: string
  className?: string
  title?: string
}
export const Avatar = ({
  children,
  color,
  size = 36,
  title,
  pictureURL,
  className,
}: Props) => (
  <Tooltip title={title} placement='bottom'>
    <Wrapper
      backgroundColor={color}
      size={size}
      pictureURL={pictureURL}
      className={className}
    >
      {!pictureURL
        ? children
            ?.split(/\s+/)
            ?.splice(0, 2)
            ?.map(world => world[0]?.toUpperCase())
            ?.join('')
        : null}
    </Wrapper>
  </Tooltip>
)
