import { InstantApointmentPermission as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'

export const instant_appointment = {
  instant_appointment_can_create: {
    name: 'Create Instant Appointment',
    control_type: UniteType.FIRST_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.INSTANT_APPOINTMENT_CAN_CREATE,
    related_key: [],
    is_module: true,
  },
}
