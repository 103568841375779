import React, { forwardRef, memo } from 'react'
import './MainButton.scss'

type ButtonProps = React.HTMLProps<HTMLButtonElement> & {
  isFetching?: boolean
  type?:
    | 'cancel'
    | 'danger'
    | 'info'
    | 'delete'
    | 'custom-secondary'
    | 'completed'
}

const MainButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ title, className, isFetching, type, disabled, ...props }, ref) => (
    <button
      {...props}
      ref={ref}
      disabled={disabled ? disabled : isFetching}
      className={`btn btn-${
        !type ? 'primary' : type
      } my-button-class my-button-class--spinner ${className ?? ''}`}
    >
      {isFetching && (
        <>
          <span
            className='spinner-border spinner-border-sm'
            role='status'
            aria-hidden='true'
          />
          <span className='sr-only'>Loading...</span>
        </>
      )}
      {` `}
      {title}
    </button>
  ),
)

export default memo(MainButton)

MainButton.displayName = 'MainButton'
