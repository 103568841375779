import styled from 'styled-components'

export const Wrapper = styled.div``
export const Table = styled.div``
export const TableContent = styled.div``
export const TableHeading = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 100px 30px;
  gap: 5px;
  background-color: #fafafa;
  font-weight: 500;
  padding: 4px 0;
  color: gray;

  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-right: 1px solid #ededed;
    padding: 0 3px;
  }
`
export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 100px 30px;
  gap: 5px;
  padding: 3px 0;
  cursor: pointer;

  &:hover {
    background-color: #fafafa;

    span.anticon-edit {
      color: #1890ff;
    }
  }

  & > * {
    padding: 0 3px;
  }
`

export const Totals = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px 30px;
  gap: 5px;
  padding: 3px 0;
  background-color: #fafafa;
  font-weight: 500;
  & > * {
    padding: 0 3px;
  }
`
