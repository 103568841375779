import * as types from '../../types'
import * as user from '../../../../api/User'
import * as supplier from '../../../../api/settings/Supplier'
import * as actions from '../index'

export const setItem = item => {
  return {
    type: types.supplier.SUPPLIER_SET_ITEM,
    item,
  }
}

export const setList = list => {
  return {
    type: types.supplier.SUPPLIER_SET_LIST,
    list,
  }
}

export const deleteItem = id => {
  return {
    type: types.supplier.SUPPLIER_DELETE_ITEM,
    id,
  }
}

export const createItem = data => {
  return {
    type: types.supplier.SUPPLIER_CREATE_ITEM,
    data,
  }
}

export const updateItem = data => {
  return {
    type: types.supplier.SUPPLIER_UPDATE_ITEM,
    data,
  }
}

/**
 * Fetch suppliers list
 *
 * @param page
 * @param limit
 * @param search
 * @returns {function(*): *}
 */
export const fetchSuppliersList = (page = 1, limit = 10, search = '', sort = '', sort_name) => dispatch => {
  dispatch(actions.system.showSpinner())
  return supplier.fetchSuppliersList(page, limit, search, sort, sort_name).then(response => {
    dispatch(setList(response))
    dispatch(actions.system.hideSpinner())
  })
}

export const fetchAllSuppliers = () => dispatch => {
  dispatch(actions.system.showSpinner())
  return supplier.getAllSuppliers().then(response => {
    dispatch(setList(response))
    dispatch(actions.system.hideSpinner())
  })
}

export const deleteSupplier = id => dispatch => {
  const userId = user.getAuthUserId()
  dispatch(deleteItem(id))
  return supplier.deleteSupplier(id)
}

export const createSupplier = data => dispatch => {
  const userId = user.getAuthUserId()

  return supplier.createSupplier(data).then(response => {
    dispatch(createItem(response))
  })
}

export const updateSupplier = (id, data) => dispatch => {
  const userId = user.getAuthUserId()
  return supplier.updateSupplier(id, data).then(response => {
    dispatch(updateItem(response))
  })
}
