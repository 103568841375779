import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import {
  IProjectTab,
  Project,
  ProjectDiscountType,
  ProjectItem,
  ProjectSection,
  TaxChargeType,
} from '../types'

import { AsyncThunkAPI } from 'store/types'
import { Error } from 'types/Error'
import { WorkOrderAPI, createWOBody } from '../components/WorkOrder/api'
import { AppStateType } from 'store'
import ProjectItemsAPI, {
  ProjectSectionsAPI,
  ProjectTabsAPI,
} from 'api/Project'
import { round } from 'helpers/Math'
import { selectCompanyTimezone } from 'store/Orcatec/selectors/company'
import moment from 'moment-timezone'

const initialState: {
  status: 'idle' | 'loading' | 'updating' | 'error'
  current: Project
  list: []
  total: number
  error: Error | null
} = {
  status: 'idle',
  current: {} as Project,
  list: [],
  total: 0,
  error: null,
}

const actions = {
  CREATE: 'work_order/create',
  READ: 'work_order/get',
  UPDATE: 'work_order/update',
  DELETE: 'work_order/delete',
}

const projectWorkOrdersSlice = createSlice({
  name: 'projectWorkOrder',
  initialState,
  reducers: {
    setWorkOrderList: (state, { payload }) => {
      state.list = payload
    },
    setWorkOrderListTotal: (state, { payload }) => {
      state.total = payload
    },
    setCurrentWorkOrder: (state, { payload }) => {
      state.current = payload
    },
    workOrderFieldUpdated: (
      state,
      { payload }: PayloadAction<Partial<Project>>,
    ) => {
      state.current = {
        ...state.current,
        ...payload,
      }
    },
    workOrderTabFieldUpdated: (
      state,
      { payload }: PayloadAction<Partial<IProjectTab>>,
    ) => {
      state.current.proposal_tabs[0] = {
        ...state.current.proposal_tabs?.[0],
        ...payload,
      }
    },
    itemCreated: (state, action: PayloadAction<ProjectItem>) => {
      if (!action.payload.id) return

      const sectionIdx = state.current.proposal_tabs[0]?.proposal_sections?.findIndex(
        section => section.id === action.payload.section_id,
      )
      if (sectionIdx === -1) return

      state.current.proposal_tabs[0].proposal_sections[sectionIdx].items.push(
        action.payload,
      )
    },
    itemUpdated: (state, action: PayloadAction<ProjectItem>) => {
      if (!action.payload.id) return

      const sectionIdx = state.current.proposal_tabs[0]?.proposal_sections?.findIndex(
        section => section.id === action.payload.section_id,
      )
      if (sectionIdx === -1) return

      const itemIdx = state.current.proposal_tabs[0]?.proposal_sections[
        sectionIdx
      ].items.findIndex(item => item.id === action.payload.id)
      if (itemIdx === -1) return

      state.current.proposal_tabs[0].proposal_sections[sectionIdx].items[
        itemIdx
      ] = action.payload
    },
    itemDeleted: (state, action) => {
      const sectionIdx = state.current.proposal_tabs[0]?.proposal_sections?.findIndex(
        section => section.id === action.payload.section_id,
      )
      if (sectionIdx === -1) return

      state.current.proposal_tabs[0].proposal_sections[
        sectionIdx
      ].items = state.current.proposal_tabs[0].proposal_sections[
        sectionIdx
      ].items.filter(item => item.id !== action.payload.id)
    },
  },
  extraReducers: builder => {
    builder.addCase(getWorkOrder.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getWorkOrder.fulfilled, (state, action) => {
      state.status = 'idle'
      state.current = action.payload
    })
    builder.addCase(getWorkOrder.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(createWorkOrder.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(createWorkOrder.fulfilled, state => {
      state.status = 'idle'
      state.total += 1
    })
    builder.addCase(createWorkOrder.rejected, state => {
      state.status = 'error'
    })

    builder.addCase(deleteWorkOrder.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteWorkOrder.fulfilled, state => {
      state.status = 'idle'
      state.total -= 1
    })
    builder.addCase(deleteWorkOrder.rejected, state => {
      state.status = 'error'
    })

    builder.addCase(addWorkOrderSection.pending, () => {
      // state.status = 'loading'
    })
    builder.addCase(addWorkOrderSection.fulfilled, (state, action) => {
      state.current.proposal_tabs[0].proposal_sections.push({
        ...action.payload,
        withItem: true,
      })
    })
    builder.addCase(addWorkOrderSection.rejected, () => {
      // state.status = 'error'
    })

    //Items
    builder.addCase(createWOItem.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(createWOItem.fulfilled, (state, action) => {
      if (!action.payload.id) return

      const sectionIdx = state.current.proposal_tabs[0]?.proposal_sections?.findIndex(
        section => section.id === action.payload.section_id,
      )
      if (sectionIdx === -1) return

      state.current.proposal_tabs[0].proposal_sections[sectionIdx].items.push(
        action.payload,
      )

      state.status = 'idle'
    })
    builder.addCase(createWOItem.rejected, state => {
      state.status = 'error'
    })
  },
})

export default projectWorkOrdersSlice.reducer
export const {
  setWorkOrderList,
  setWorkOrderListTotal,
  setCurrentWorkOrder,
  workOrderFieldUpdated,
  workOrderTabFieldUpdated,
  itemCreated,
  itemUpdated,
  itemDeleted,
} = projectWorkOrdersSlice.actions

export const getWorkOrder = createAsyncThunk<Project, number, AsyncThunkAPI>(
  actions.READ,
  async workOrderId => {
    const res = await WorkOrderAPI.getWorkOrderById(workOrderId)

    return res
  },
)

export const createWorkOrder = createAsyncThunk<
  Project,
  createWOBody,
  AsyncThunkAPI
>(actions.CREATE, async workOrder => {
  const res = await WorkOrderAPI.create(workOrder)

  return res
})

export const updateWorkorderField = createAsyncThunk<
  Partial<Project>,
  Partial<Project>,
  AsyncThunkAPI
>(
  actions.UPDATE,
  async (fieldsToUpdate, { dispatch, getState, rejectWithValue }) => {
    const woId = getState().orcatec.projectWorkOrdersSlice.current?.id

    if (!woId) return

    try {
      // if (!('status' in fieldsToUpdate) && !('code' in fieldsToUpdate))
      dispatch(workOrderFieldUpdated(fieldsToUpdate))

      const res = await WorkOrderAPI.updateField(woId, fieldsToUpdate)

      return res
    } catch (error) {
      // dispatch(getProject({ projectId }))

      return rejectWithValue(error.response.data)
    }
  },
)

export const updateWorkOrderTabField = createAsyncThunk<
  Partial<IProjectTab>,
  Partial<IProjectTab>,
  AsyncThunkAPI
>(
  'work_order/update_tab_field',
  async (fieldsToUpdate, { getState, dispatch, rejectWithValue }) => {
    // const { activeTab } = getState().orcatec.projectSlice.project
    const {
      id: projectTabId,
    } = getState().orcatec.projectWorkOrdersSlice.current?.proposal_tabs?.[0]
    if (!projectTabId) return

    try {
      dispatch(workOrderTabFieldUpdated(fieldsToUpdate))
      return await ProjectTabsAPI.updateTabField(projectTabId, fieldsToUpdate)
    } catch (error) {
      // dispatch(getProjectTab({ tabId: projectTabId, tabIdx: activeTab }))
      return rejectWithValue(error.response.data)
    }
  },
)

export const deleteWorkOrder = createAsyncThunk<Project, number, AsyncThunkAPI>(
  actions.DELETE,
  async (workOrderId, { rejectWithValue }) => {
    try {
      const res = await WorkOrderAPI.deleteWorkOrder(workOrderId)

      return res
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

//Section

export const addWorkOrderSection = createAsyncThunk<
  ProjectSection,
  { proposal_tab_id: number },
  AsyncThunkAPI
>('work_order/addSection', async section => {
  const res = await ProjectSectionsAPI.addSection({
    ...section,
    title: 'Additional section',
  } as ProjectSection)
  return res
})

//Items

export const createWOItem = createAsyncThunk<
  ProjectItem,
  ProjectItem,
  AsyncThunkAPI
>('work_order/createItem', async (item, { rejectWithValue }) => {
  try {
    return await ProjectItemsAPI.addItem(item)
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const updateWOItem = createAsyncThunk<
  ProjectItem,
  ProjectItem,
  AsyncThunkAPI
>('work_order/updateItem', async (item, { rejectWithValue, dispatch }) => {
  try {
    dispatch(itemUpdated(item))

    return await ProjectItemsAPI.updateItem(item)
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

export const deleteWOItem = createAsyncThunk<
  ProjectItem,
  ProjectItem,
  AsyncThunkAPI
>('work_order/deleteItem', async (item, { rejectWithValue, dispatch }) => {
  try {
    dispatch(itemDeleted(item))

    return await ProjectItemsAPI.deleteItem(item.id)
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

//Selectors

export const selectWorkOrderSlice = (state: AppStateType) =>
  state.orcatec.projectWorkOrdersSlice

export const selectWorkOrder = (state: AppStateType) =>
  state.orcatec.projectWorkOrdersSlice.current

export const selectWorkOrderTab = (state: AppStateType) =>
  state.orcatec.projectWorkOrdersSlice.current?.proposal_tabs?.[0]

export const selectWordOrderSections = createSelector(selectWorkOrderTab, tab =>
  [...tab?.proposal_sections].sort((a, b) => a.position - b.position),
)

export const selectWoSettings = createSelector(
  selectWorkOrder,
  wo => wo.setting,
)

export const selectWorkOrderTax = createSelector(
  selectWorkOrderTab,
  tab => tab.tax,
)

export const selectWorkOrderFees = createSelector(
  selectWorkOrderTab,
  tab => tab.fees,
)

export const selectWoSectionLabor = (sectionId: number) =>
  createSelector(
    selectWordOrderSections,
    sections =>
      sections.find(section => section.id === sectionId)?.installation || 0,
  )

export const selectWordOrderGroupsBySection = (sectionId: number) =>
  createSelector(
    selectWorkOrderTab,
    tab =>
      tab.proposal_sections?.find(section => section.id === sectionId)
        ?.groups || [],
  )

export const selectWordOrderItems = (sectionId?: number) =>
  createSelector(selectWorkOrderTab, tab =>
    tab?.proposal_sections
      ?.flatMap(section => section.items)
      .filter(item => (sectionId ? item.section_id === sectionId : !!item)),
  )

export const selectProjectDiscountsWithStatus = (sectionId?: number) =>
  createSelector(
    [selectWorkOrder, selectWorkOrderTab, selectCompanyTimezone],
    (workOrder, tab, timezone) => {
      const endOfExpirationDate =
        workOrder.contract_date || moment().startOf('day')

      return tab.proposal_sections
        ?.flatMap(section => section.discounts)
        ?.filter(discount =>
          sectionId ? discount.section_id === sectionId : !!discount,
        )
        ?.map(discount => {
          return {
            ...discount,
            active:
              !discount.expiration ||
              moment(discount.expiration)
                .endOf('day')
                .tz(timezone)
                .isAfter(endOfExpirationDate),
          }
        })
    },
  )

export const selectMaterialsTotal = (sectionId?: number) =>
  createSelector(selectWordOrderItems(sectionId), items => {
    return items
      .filter(item => item.is_material)
      .reduce(
        (acc, currItem) =>
          (acc += round((currItem.retail_price || 0) * (currItem.qty ?? 0))),
        0,
      )
  })

export const selectWorkOrderSectionSubtotal = (sectionId: number) =>
  createSelector(
    [selectWordOrderItems(sectionId), selectWoSectionLabor(sectionId)],
    (items, labor) =>
      items.reduce(
        (acc, currItem) =>
          (acc += (currItem.retail_price || 0) * (currItem.qty ?? 0)),
        labor,
      ),
  )

export const selectSectionTotalAfterDiscounts = (sectionId: number) =>
  createSelector(
    [
      selectWorkOrderSectionSubtotal(sectionId),
      selectProjectDiscountsWithStatus(sectionId),
    ],
    (sectionSubtotal, discounts) => {
      const sectionTotal = discounts
        .filter(discount => discount.active)
        .filter(discount =>
          discount.option_group_id ? discount.checked_option : discount,
        )
        .reduce(
          (total, discount) =>
            total -
            round(
              discount.discount_type === ProjectDiscountType.Amount
                ? discount.amount
                : (sectionSubtotal * discount.amount) / 100,
            ),
          sectionSubtotal,
        )
      return sectionTotal
    },
  )

export const selectWoInvestmentTotal = createSelector(
  (state: AppStateType) => state,
  selectWordOrderSections,
  (state, sections) =>
    sections.reduce(
      (total, section) =>
        total + selectWorkOrderSectionSubtotal(section.id)(state),
      0,
    ),
)

export const selectWorkOrderTotalAfterDiscounts = createSelector(
  (state: AppStateType) => state,
  selectWordOrderSections,
  (state, sections) => {
    const investmentTotal = sections?.reduce(
      (total, section) =>
        (total += selectSectionTotalAfterDiscounts(section.id)(state)),
      0,
    )

    return investmentTotal
  },
)

//Taxes and Fees

export const selectTaxableTotal = createSelector(
  selectWordOrderItems(),
  items =>
    items
      .filter(item => item.taxable)
      .reduce(
        (acc, currItem) =>
          (acc += (currItem.retail_price || 0) * (currItem.qty ?? 0)),
        0,
      ),
)

export const selectWorkOrderTaxTotal = createSelector(
  [selectWorkOrderTax, selectTaxableTotal, selectWorkOrderTotalAfterDiscounts],
  (tax, taxableItemsTotal, totalAfterDiscounts) => {
    if (!tax) return 0

    if (tax.type === TaxChargeType.CUSTOM) return tax.total

    const TAXABLE_TOTAL =
      tax?.type === TaxChargeType.MATERIALS
        ? taxableItemsTotal
        : totalAfterDiscounts

    return round(TAXABLE_TOTAL * (tax?.rate / 100), 3)
  },
)

export const selectWorkOrderTotal = createSelector(
  [selectWorkOrderTotalAfterDiscounts, selectWorkOrderTaxTotal],
  (totalAfterDiscounts, taxTotal) => round(totalAfterDiscounts + taxTotal),
)

/* export const selectProjectFeesTotal = createSelector(selectProjectFees, fees =>
  fees?.reduce((sum, fee) => (sum += +fee.price), 0),
) */
