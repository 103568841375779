import { useState } from 'react'
import TabsLayout from '../TabsLayout'
import ErrorBoundary from 'containers/MainContent/Orcatec/components/ErrorBoundary/ErrorBoundary'
import { InfoForWorkers } from 'containers/MainContent/Orcatec/Proposals/components/InfoForWorkers/InfoForWorkers'
import {
  selectWorkOrder,
  updateWorkOrderTabField,
} from 'features/Project/slices/projectWorkOrdersSlice'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import DocViewer from 'helpers/viewFIlesNew/ViewDoc'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
import { InfoForWorkersOperation } from 'containers/MainContent/Orcatec/Proposals/components/InfoForWorkers/types'
import { Result } from 'antd'

export const AdditionalInformation = () => {
  const dispatch = useAppDispatch()

  const workOrder = useAppSelector(selectWorkOrder)
  const { READ } = usePermissionsByModule(InfoForWorkersOperation)

  const [state, setState] = useState({
    showDocViewerModal: false,
    documents: [],
    currentFileIdx: null,
  })

  const handleInformationForWorkersUpdate = filesToUpdate => {
    dispatch(updateWorkOrderTabField(filesToUpdate))
  }

  const getFileForModalDocViewer = (documents, currentFileIdx) => {
    setState(prev => ({
      ...prev,
      documents,
      currentFileIdx,
      showDocViewerModal: true,
    }))
  }

  const toggleOpenDocViewerModal = () => {
    setState(prev => ({
      ...prev,
      showDocViewerModal: !prev.showDocViewerModal,
    }))
  }

  if (!READ) {
    return (
      <Result
        status='403'
        title='403'
        subTitle='Sorry, you have no permissions to perform this action.'
      />
    )
  }

  return (
    <TabsLayout title='For workers'>
      <ErrorBoundary>
        <InfoForWorkers
          dataSource={workOrder?.proposal_tabs[0]}
          label='Notes for workers'
          notesFieldName='notes_for_installers'
          filesFieldName='files'
          getFileForModalDocViewer={getFileForModalDocViewer}
          onUpdate={handleInformationForWorkersUpdate}
          filesLimit={100}
        />

        <DocViewer
          documents={state.documents}
          currentDocument={state.currentFileIdx}
          isModalVisible={state.showDocViewerModal}
          handleCancel={toggleOpenDocViewerModal}
        />
      </ErrorBoundary>
    </TabsLayout>
  )
}
