import Notes from 'containers/MainContent/Orcatec/components/Notes'
import './TabNotesAndModules.scss'
import { IProposalTab } from 'types/Proposal'
import ProjectModules from 'features/Project/components/EditProject/components/ProjectModules'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectProjectSettings } from 'features/Project/projectSelectors'

// const inputProps = { maxLength: 500 }

interface TabNotesAndModulesProps {
  currentTab: IProposalTab
  openModalPayment: () => void
}

const TabNotesAndModules: React.FC<TabNotesAndModulesProps> = ({
  currentTab,
  openModalPayment,
}) => {
  const projectSettings = useAppSelector(selectProjectSettings)

  return (
    <div className='proposal-form-tabs_content-info_block-notes_and_modules'>
      <div>
        {projectSettings.show_notes && (
          <Notes
            label='Project Notes'
            route={`proposal-tabs/${currentTab?.id}/notes`}
          />
        )}
      </div>

      {projectSettings.show_modules && (
        <div style={{ marginRight: 50 }}>
          <ProjectModules onOpenPaymentModule={openModalPayment} />
        </div>
      )}
    </div>
  )
}

export default TabNotesAndModules
