import React, { useState, useEffect, useRef } from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { useRoutes } from './routes'
import { ItemWrapper, MenuWrapper, MinItemWrapper } from './Navigation.styles'
import { OverflowIcon } from './utils'
import { meSelector } from 'store/SuperAdmin/selectors'
import { IHeaderType } from '../../components/types'
import { useAppSelector } from 'store/Orcatec/hooks'
import classNames from 'classnames'
import './Navigation.scss'
import AppVersionService from 'services/AppVersionService'

export const Navigation = () => {
  const [selectedKey, setSelectedKey] = useState('')

  const menuRef = useRef(null)
  const wrapperRef = useRef()
  const me = useAppSelector(meSelector)

  const [routes, unreadChatsCount] = useRoutes()
  const [menuItems, setMenuItems] = useState([])
  const [overflowedItems, setOverflowedItems] = useState([])

  const minimalHeaderView = [
    IHeaderType.HEADER_HORIZONTAL_MIN,
    IHeaderType.HEADER_HORIZONTAL_MIN_WITH_TEXT,
  ].includes(me?.ui_settings?.header_view)

  const checkPath = () => {
    const path = window.location.pathname || ''
    const splitPath = path.split('/')?.[1]

    if (['projects', 'proposal'].includes(splitPath)) {
      setSelectedKey('/projects')
    } else if (['matrix', 'appointment'].includes(splitPath)) {
      setSelectedKey('/dispatch')
    } else if (['jobs'].includes(splitPath)) {
      setSelectedKey('/jobs-list')
    } else if (['properties-form'].includes(splitPath)) {
      setSelectedKey('/properties')
    } else if (['accounting', 'balances', 'expenses'].includes(splitPath)) {
      setSelectedKey('/accounting')
    } else if (['settings'].includes(splitPath)) {
      setSelectedKey('/settings/info')
    } else {
      setSelectedKey(path)
    }
  }

  useEffect(() => {
    checkPath()
  }, [window.location.pathname])

  useEffect(() => {
    const handleResize = () => {
      let countVisible = 10
      const menuWidth = window.innerWidth
      if (menuWidth < 900) countVisible = 8
      if (menuWidth > 900 && menuWidth < 1100) countVisible = 10
      if (menuWidth > 980 && menuWidth < 1100) countVisible = 12
      if (menuWidth > 1100 && menuWidth < 1400) countVisible = 15
      if (menuWidth > 1400) countVisible = 20
      if (menuWidth > 1600) countVisible = 25

      const visibleItem = routes.slice(0, countVisible)
      const unvisibleItem = routes.slice(countVisible)

      setMenuItems(visibleItem)
      setOverflowedItems(unvisibleItem)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [unreadChatsCount, routes?.length])

  return minimalHeaderView ? (
    <MenuWrapper ref={wrapperRef} minimalHeaderView={minimalHeaderView}>
      <Menu
        ref={menuRef}
        className='navigation-menu'
        style={{ alignItems: 'center' }}
        mode='horizontal'
        forceSubMenuRender={true}
        overflowedIndicator={null}
        selectedKeys={[selectedKey]}
        triggerSubMenuAction='click'
        motion={null}
        onClick={AppVersionService.checkAppVersion}
      >
        {menuItems.map(({ key, icon, title, route }) => (
          <Menu.Item key={key} className='ant-menu-item'>
            <MinItemWrapper
              // className='header-menu__wrapper'
              className='header-menu__wrapper'
              active={selectedKey === route}
            >
              <Link className='header-menu__link' to={route}>
                <i className='header-menu__icon'>{icon}</i>
                {me?.ui_settings?.header_view ===
                  IHeaderType.HEADER_HORIZONTAL_MIN_WITH_TEXT && (
                  <p className='header-menu__title'>{title}</p>
                )}
              </Link>
            </MinItemWrapper>
          </Menu.Item>
        ))}
        {overflowedItems?.length > 0 && (
          <Menu.SubMenu
            key='more'
            popupClassName='minimal-menu-popup'
            title={
              <OverflowIcon
                withTitle={false}
                color={
                  overflowedItems
                    ?.map(item => item?.route)
                    ?.includes(selectedKey)
                    ? '#4285f4'
                    : ''
                }
              />
            }
            className='navigation-menu'
          >
            {overflowedItems.map(({ key, icon, title, route }) => (
              <Menu.Item key={key} className='ant-menu-item minimal-menu'>
                <MinItemWrapper
                  className={classNames(
                    'header-menu__wrapper',
                    me?.ui_settings?.header_view ===
                      IHeaderType.HEADER_HORIZONTAL_MIN && 'without_text',
                  )}
                  active={selectedKey === route}
                >
                  <Link className='header-menu__link' to={route}>
                    <i className='header-menu__icon'>{icon}</i>
                    {me?.ui_settings?.header_view ===
                      IHeaderType.HEADER_HORIZONTAL_MIN_WITH_TEXT && (
                      <p className='header-menu__title'>{title}</p>
                    )}
                  </Link>
                </MinItemWrapper>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        )}
      </Menu>
    </MenuWrapper>
  ) : (
    <MenuWrapper>
      <Menu
        className='navigation-menu'
        mode='horizontal'
        overflowedIndicator={
          <OverflowIcon
            color={
              window.location.pathname.includes('/settings') ? '#4285f4' : null
            }
          />
        }
        selectedKeys={[selectedKey]}
        triggerSubMenuAction={'click'}
        onClick={AppVersionService.checkAppVersion}
      >
        {routes.map(({ icon, title, route }) => (
          <Menu.Item key={route}>
            <ItemWrapper>
              <i>{icon}</i>
              <p>{title}</p>
              <Link to={route} />
            </ItemWrapper>
          </Menu.Item>
        ))}
      </Menu>
    </MenuWrapper>
  )
}
