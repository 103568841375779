import ProjectItemsAPI from 'api/Project'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useEffect, useState } from 'react'
import { Breadcrumb, GlobalItem, ItemCategory } from '../types'
import useDebounce from 'hooks/useDebounce'

interface Data {
  breadcrumbs: Breadcrumb[]
  items: GlobalItem[]
  categories: ItemCategory[]
}

export const useProjectItemsSearch = (params: {
  search?: string
  category_id?: number
}) => {
  const [data, setData] = useState<Data | null>(null)
  const [loading, setLoading] = useState(false)

  const debouncedSearchValue = useDebounce(params.search, 300)

  useEffect(() => {
    const getData = async () => {
      setLoading(true)

      try {
        const data = await ProjectItemsAPI.searchItems(params)
        setData(data)
      } catch (error) {
        openNotificationWithIcon('error', {
          message: error.response?.data?.message,
        })
      } finally {
        setLoading(false)
      }
    }

    getData()
  }, [debouncedSearchValue, params.category_id])

  return {
    data,
    loading,
  }
}
