import React, { Component } from 'react'
import './Legal.scss'
import { Checkbox } from 'antd'
import MainButton from '../../components/buttons/MainButton'
import { Error } from '../../components/ErrorMessage/ErrorMessage'
import { BackToLoginBtn } from '../../Layouts/AuthLayout/BackToLoginBtn'

class PagesLegal extends Component {
  state = {
    isRegistering: false,
    terms: false,
    privacy: false,
    terms_error: false,
    privacy_error: false,
  }

  onChange = (e, name) => {
    this.setState({
      [name]: e.target.checked,
      [`${name}_error`]: !e.target.checked,
    })
  }

  onSubmit = () => {
    const newState = { ...this.state }
    if (!newState.terms || !newState.privacy) {
      if (!newState.terms) newState.terms_error = true
      if (!newState.privacy) newState.privacy_error = true
      return this.setState(newState)
    } else {
      this.setState({ isRegistering: true })
      this.props
        .goToFinish()
        .finally(() => this.setState({ isRegistering: false }))
    }
  }

  render() {
    return (
      <div className='legal-page'>
        <div className='take-your-time-text'>
          Please take your time to go over Terms & Сonditions and Privacy Policy
          of Orcatec LLC
        </div>
        <div className='d-flex justify-content-center terms-and-cond-block'>
          <div className='accepts'>
            <div className='form-group'>
              <Checkbox
                name='terms'
                onChange={e => this.onChange(e, 'terms')}
                checked={this.state.terms}
              >
                {'I accept '}
                <a
                  href='https://orcatec.com/terms_and_conditions.pdf'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms & Conditions
                </a>
                {' of Orcatec LLC'}
              </Checkbox>
              <div>
                {this.state.terms_error && (
                  <Error>Please accept Terms & Conditions</Error>
                )}
              </div>
            </div>

            <div className='form-group'>
              <Checkbox
                name='privacy'
                onChange={e => this.onChange(e, 'privacy')}
                checked={this.state.privacy}
              >
                {'I accept '}
                <a
                  href='https://orcatec.com/privacy_policy.pdf'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy Policy
                </a>
                {' of Orcatec LLC'}
              </Checkbox>
              <div>
                {this.state.privacy_error && (
                  <Error>Please accept Privacy Policy</Error>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='buttons-block'>
          <MainButton
            title='Register'
            onClick={this.onSubmit}
            isFetching={this.state.isRegistering}
            className='w-100 waves-effect waves-light main-button'
          />
          <BackToLoginBtn title='Back' onClick={this.props.goToCredit} />
        </div>
      </div>
    )
  }
}
export default PagesLegal
