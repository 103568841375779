/**
 * This types is deprecated and should be remove, not use it
 * @type {string}
 */
export const TABLE_SET_TABLE = 'TABLE_SET_TABLE'
export const TABLE_SET_ROW = 'TABLE_SET_ROW'
export const TABLE_SET_ROWS = 'TABLE_SET_ROWS'
export const TABLE_REMOVE_FIRST_ROW = 'TABLE_REMOVE_FIRST_ROW'
export const TABLE_CHANGE_NEW_ROW = 'TABLE_CHANGE_NEW_ROW'
export const TABLE_DEL_ROW = 'TABLE_DEL_ROW'
