const TermAndConditionsLink = ({ file }) => {
  const isMobileApp = () =>
    window?.ReactNativeWebView && window?.ReactNativeWebView?.postMessage

  const onClickInApp = event => {
    if (isMobileApp()) {
      window.ReactNativeWebView.postMessage(JSON.stringify(event))
    }
  }

  const onClick = e => {
    if (!file) e.preventDefault()
    if (isMobileApp()) {
      return onClickInApp({
        key: 'open_file',
        value: file,
      })
    }
  }
  const fileName = file.name
    ? file.name
    : file.display_name
    ? file.display_name
    : ''
  //const fileNameNoType = fileName ? fileName.split('.')[0] : ''
  return (
    <div className='text-center mt-2'>
      <p>
        <a
          href={
            file && !isMobileApp()
              ? process.env.REACT_APP_API_MEDIA + file.path
              : ''
          }
          download={fileName}
          className='font-500'
          style={{
            textDecoration: 'underline',
            color: '#4285f4',
            fontSize: 16,
          }}
          target='_blank'
          rel='noopener noreferrer'
          onClick={onClick}
        >
          Terms & Conditions
        </a>
      </p>
    </div>
  )
}
export default TermAndConditionsLink
