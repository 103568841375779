import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { CustomForm } from 'features/Forms/types'
import { FormCard } from './FormCard'
import styled from 'styled-components'
import { Button, Tooltip } from 'antd'
import { Search } from 'components/UIKit'
import { useState, useMemo, useEffect } from 'react'
import {
  IconEnvelope,
  VerifiedIcon,
  NotVerifiedIcon,
  GridViewIcon,
  TableViewIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { FULL_DATE_FORMAT } from 'constants/dateFormats'
import {
  selectCompanyTimezone,
  selectJobDictionary,
} from 'store/Orcatec/selectors/company'
import { useAppSelector } from 'store/Orcatec/hooks'
import moment from 'moment-timezone'
import Progress from 'components/UIKit/Progress'
import { Link } from 'react-router-dom'
import {
  ProjectsIcon,
  JobsIcon,
  DispatchIcon,
} from 'layouts/MainLayout/Header/components/Navigation/NavigationIcons'
import Table from 'containers/MainContent/Orcatec/components/Table'
import { PaginationConfig } from 'antd/lib/pagination'
import { SorterResult } from 'antd/lib/table/interface'
import { Settings } from '@material-ui/icons'
// import { canVerifyForm } from 'features/Settings/UsersAndGroups/userSlice'
import { canVerifyFormSelect } from 'features/Settings/UsersAndGroups/permissionSlice'

export const entityTypeIcons = title => {
  return {
    Project: {
      icon: <ProjectsIcon />,
      label: 'Project',
    },
    Job: {
      icon: <JobsIcon />,
      label: title,
    },
    Dispatch: {
      icon: <DispatchIcon />,
      label: 'Appointment',
    },
  }
}

const renderTitle = (title: string) => (
  <Tooltip placement='topLeft' title={title}>
    {title}
  </Tooltip>
)

interface Props {
  data: CustomForm[]
  showRelatedInfo: boolean
  onClose: () => void
  onFormClick: (form: CustomForm) => void
}

enum ItemView {
  BAR = 1,
  ROW,
}

export const RelatedFormsModal = ({
  data,
  onClose,
  onFormClick,
  showRelatedInfo,
}: Props) => {
  const companyTimezone = useAppSelector(selectCompanyTimezone)
  const dictionary = useAppSelector(selectJobDictionary)

  const canFormVerify = useAppSelector(canVerifyFormSelect)

  const [search, setSearch] = useState('')
  const [itemsView, setItemsView] = useState(ItemView.BAR)
  const [sortField, setSortField] = useState<string | null>(null)

  const [sortOrder, setSortOrder] = useState<string | null>(null)
  const [openTableSettings, setOpenTableSettings] = useState(false)
  const [filteredInfo, setFilteredInfo] = useState({})
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    total: data.length,
  })
  const changeItemsView = (type: ItemView) => {
    setItemsView(type)
  }

  const searchResults = data?.filter(
    form =>
      form.name.toLowerCase().includes(search.toLowerCase()) ||
      form?.main_field_label?.toLowerCase().includes(search.toLowerCase()) ||
      form?.main_field_value?.toLowerCase().includes(search.toLowerCase()) ||
      form?.code?.toLowerCase().includes(search.toLowerCase()) ||
      form?.submitted_by?.full_name
        ?.toLowerCase()
        .includes(search.toLowerCase()),
  )

  const handleSearch = (data: CustomForm[]) => {
    return data.filter(
      form =>
        form.name.toLowerCase().includes(search.toLowerCase()) ||
        form?.main_field_label?.toLowerCase().includes(search.toLowerCase()) ||
        form?.main_field_value?.toLowerCase().includes(search.toLowerCase()) ||
        form?.code?.toLowerCase().includes(search.toLowerCase()) ||
        form?.submitted_by?.full_name
          ?.toLowerCase()
          .includes(search.toLowerCase()),
    )
  }

  const handleSort = (data: CustomForm[]) => {
    if (!sortField || !sortOrder) return data

    return [...data].sort((a, b) => {
      const aValue = a[sortField]
      const bValue = b[sortField]

      const aIsNull = aValue === null || aValue === undefined
      const bIsNull = bValue === null || bValue === undefined

      if (aIsNull && bIsNull) return 0
      if (aIsNull) return sortOrder === 'asc' ? 1 : -1
      if (bIsNull) return sortOrder === 'asc' ? -1 : 1

      const aDate = moment(aValue)
      const bDate = moment(bValue)

      if (aDate.isValid() && bDate.isValid()) {
        return sortOrder === 'asc'
          ? +moment(aDate).format('YYYYMMDD') -
              +moment(bDate).format('YYYYMMDD')
          : +moment(bDate).format('YYYYMMDD') -
              +moment(aDate).format('YYYYMMDD')
      }

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1
      return 0
    })
  }

  const handleFilter = (data: CustomForm[]) => {
    return data.filter(item => {
      return Object.keys(filteredInfo).every(key => {
        if (!filteredInfo[key]) return true
        if (!Array.isArray(filteredInfo[key])) return true
        if (typeof item[key] === 'object')
          return filteredInfo[key].some(
            filterValue => item[key]?.full_name === filterValue,
          )

        return filteredInfo[key].some(filterValue => item[key] === filterValue)
      })
    })
  }

  const handleRowClick = record => {
    onFormClick(record)
  }

  const handleTableChange = async (
    pagination: PaginationConfig,
    filters: Partial<Record<keyof CustomForm, string[]>>,
    sorter: SorterResult<CustomForm>,
  ) => {
    setSortOrder(
      sorter.order === 'ascend'
        ? 'asc'
        : sorter.order === 'descend'
        ? 'desc'
        : null,
    )
    setSortField(sorter.order ? (sorter.field as string) : null)
    setFilteredInfo(filters)
  }

  const columns = [
    {
      title: renderTitle('Form Name'),
      name: 'Form Name',
      dataIndex: 'name',
      key: 'name',
      filters: Array.from(new Set(data.map(item => item.name))).map(name => ({
        text: name,
        value: name,
      })),
    },
    {
      title: renderTitle('Template Name'),
      name: 'Template Name',
      dataIndex: 'title',
      key: 'title',
      filters: Array.from(new Set(data.map(item => item.title))).map(name => ({
        text: name,
        value: name,
      })),
    },

    {
      title: renderTitle('Primary Field Label'),
      name: 'Primary Field Label',
      dataIndex: 'main_field_label',
      key: 'main_field_label',
      filters: data
        .filter(
          (item, index, self) =>
            !!item?.main_field_label &&
            index ===
              self.findIndex(t => t.main_field_label === item.main_field_label),
        )
        .map(item => ({
          text: item.main_field_label,
          value: item.main_field_label,
        })),
    },
    {
      title: renderTitle('Primary Field Value'),
      name: 'Primary Field Value',
      dataIndex: 'main_field_value',
      key: 'main_field_value',
      filters: data
        .filter(
          (item, index, self) =>
            !!item?.main_field_value &&
            index ===
              self.findIndex(t => t.main_field_value === item.main_field_value),
        )
        .map(item => ({
          text: item.main_field_value,
          value: item.main_field_value,
        })),
    },
    {
      title: renderTitle(`Related ${dictionary.plural}`),
      name: `Related ${dictionary.plural}`,
      dataIndex: 'job_id',
      key: 'job_id',
      filters: data
        .filter(
          (item, index, self) =>
            !!item?.job_id &&
            index === self.findIndex(t => t.job_id === item.job_id),
        )
        .map(item => ({
          text: item.code,
          value: item.job_id,
        })),
      render: (value, data) =>
        value ? (
          <p>
            {/* <span>{`Related ${dictionary.singular}:`} </span> */}
            <Link
              to={`/jobs/${data.job_id}`}
              target='_blank'
              onClick={e => e.stopPropagation()}
            >
              {data.code}
            </Link>
          </p>
        ) : (
          '--'
        ),
    },

    {
      title: renderTitle('Related Work Order'),
      name: 'Related Work Order',
      dataIndex: 'wo_proposal_code',
      key: 'wo_proposal_code',
      filters: [
        ...new Set(
          data
            .filter(item => item?.wo_proposal_id && item.job_id)
            .map(item => item?.wo_proposal_code),
        ),
      ].map(item => ({
        text: item,
        value: item,
      })),

      render: (value, data) => (
        <>{!!data.wo_proposal_id ? data.wo_proposal_code : '--'}</>
      ),
    },

    {
      title: renderTitle('Submitted by'),
      name: 'Submitted by',
      dataIndex: 'submitted_by',
      key: 'submitted_by',
      sorter: true,
      filters: data
        .filter(
          (item, index, self) =>
            !!item?.submitted_by?.full_name &&
            index ===
              self.findIndex(
                t => t.submitted_by?.full_name === item.submitted_by?.full_name,
              ),
        )
        .map(item => ({
          text: item?.submitted_by?.full_name,
          value: item?.submitted_by?.full_name,
        })),

      render: (value, data) =>
        value?.full_name ? (
          <Container>
            <Tooltip
              title={`Submitted by ${value?.full_name} at
                ${moment(data?.submitted_at)
                  .tz(companyTimezone)
                  .format(FULL_DATE_FORMAT)}`}
            >
              <Text>{`${value?.full_name} ${moment(data?.submitted_at)
                .tz(companyTimezone)
                .format(FULL_DATE_FORMAT)}`}</Text>
            </Tooltip>
          </Container>
        ) : (
          '--'
        ),
    },

    {
      title: renderTitle('Progress'),
      name: 'Progress',
      dataIndex: 'completed',
      key: 'completed',
      defaultWidth: 140,
      sorter: true,
      filters: [
        { text: 'Completed', value: true },
        { text: 'Not Completed', value: false },
      ],
      render: (value, data) => (
        <ProgressWrapper>
          <Progress
            percent={data.fill_percentage === 100 ? 99 : data.fill_percentage}
            width={20}
            isCompleted={data.completed}
          />

          <Tooltip
            title={
              showRelatedInfo
                ? data?.last_notification
                  ? `Sent to  ${data.last_notification?.recipients
                      ?.map(email => email.email)
                      .join(' , ')} at ${moment(data.last_notification?.send_at)
                      .tz(companyTimezone)
                      .format(FULL_DATE_FORMAT)}`
                  : 'This form has not been sent yet'
                : ''
            }
          ></Tooltip>
        </ProgressWrapper>
      ),
    },
    {
      title: renderTitle('Verified'),
      name: 'Verified',
      dataIndex: 'is_verified',
      key: 'is_verified',
      defaultWidth: 40,
      sorter: true,
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      render: (value, data) => (
        <Tooltip
          title={
            data?.is_verified
              ? `Verified by ${data.verified_name}`
              : 'This form has not been verified yet'
          }
        >
          <p> {value ? <VerifiedIcon /> : <NotVerifiedIcon />}</p>
        </Tooltip>
      ),
    },

    {
      title: renderTitle('Sent'),
      name: 'Sent',
      dataIndex: 'is_sent',
      key: 'is_sent',
      defaultWidth: 40,
      sorter: true,
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      render: (value, data) => (
        <Tooltip
          title={
            showRelatedInfo
              ? data?.last_notification
                ? `Sent to  ${data.last_notification?.recipients
                    ?.map(email => email.email)
                    .join(' , ')} at ${moment(data.last_notification?.send_at)
                    .tz(companyTimezone)
                    .format(FULL_DATE_FORMAT)}`
                : 'This form has not been sent yet'
              : ''
          }
        >
          <IconWrapper>
            <IconEnvelope
              style={{
                color: data?.last_notification ? '#28a745' : '#4D4D4D',
                width: '22px',
                height: '22px',
              }}
            />
          </IconWrapper>
        </Tooltip>
      ),
    },

    {
      title: renderTitle('Created at'),
      name: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      noFilter: true,
      render: value =>
        data
          ? moment(value)
              .tz(companyTimezone)
              .format(FULL_DATE_FORMAT)
          : '--',
    },

    {
      title: renderTitle('Updated at'),
      name: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sorter: true,
      noFilter: true,
      render: value =>
        value
          ? moment(value)
              .tz(companyTimezone)
              .format(FULL_DATE_FORMAT)
          : '--',
    },
  ]

  const access = {
    job_id: showRelatedInfo,
    is_verified: canFormVerify,
  }

  const filterColumns = (columns, access) => {
    return columns.filter(column => {
      if (!access.job_id && column.dataIndex === 'job_id') {
        return false
      }
      if (!access.is_verified && column.dataIndex === 'is_verified') {
        return false
      }
      return true
    })
  }

  const processedData = useMemo(() => {
    let result = handleSearch(data)
    result = handleFilter(result)
    result = handleSort(result, sortField, sortOrder)
    setPagination(prev => ({ ...prev, total: result.length }))
    return result
  }, [data, search, filteredInfo, sortField, sortOrder])

  useEffect(() => {
    return () => {
      const tableData = JSON.parse(
        localStorage.getItem(`entity-forms-table-table_v1`) || {},
      )

      // const defaultStatus = location.pathname === '/estimates' ? [] : []
      // localStorage.setItem(`entity-forms-table-table_v1`, '')
      localStorage.setItem(
        `entity-forms-table-table_v1`,
        JSON.stringify({
          ...tableData,
          filters: null,
          sorter: null,
          search: '',
        }),
      )
    }
  }, [])

  const renderContent = () => {
    if (!data.length) return <NoResults>No forms yet</NoResults>

    if (itemsView === ItemView.ROW) {
      return (
        <TableWrapper>
          <Table
            autoHeight={true}
            name='entity-forms-table'
            columns={filterColumns(columns, access)}
            data={processedData}
            pagination={pagination}
            size='small'
            openTableSettings={openTableSettings}
            onCloseTableSettings={() => setOpenTableSettings(false)}
            onRow={record => {
              return {
                onClick: () => {
                  handleRowClick(record)
                },
              }
            }}
            onChange={handleTableChange}
          />
        </TableWrapper>
      )
    }

    if (itemsView === ItemView.BAR) {
      if (!searchResults.length) {
        return <NoResults>No search results found</NoResults>
      } else {
        return (
          <Content>
            {searchResults?.map(form => (
              <FormCard
                key={form.id}
                data={form}
                onClick={onFormClick}
                showRelatedInfo={showRelatedInfo}
              />
            ))}
          </Content>
        )
      }
    }
  }

  return (
    <Modal
      visible
      onCancel={onClose}
      title='Forms'
      width={1000}
      bodyStyle={{ height: '73vh', paddingBottom: '0px', overflow: 'scroll' }}
      footer={<Button onClick={onClose}>Close</Button>}
      style={{ minHeight: '600px' }}
    >
      <SearchWrapper>
        <ActionsWrapper>
          <div>
            {itemsView === ItemView.ROW && (
              <IconWrapper
                style={{ marginBottom: '4px' }}
                onClick={() => setOpenTableSettings(true)}
              >
                <Tooltip placement='left' title='Columns settings'>
                  <Settings />
                </Tooltip>
              </IconWrapper>
            )}
          </div>
          {itemsView === ItemView.ROW ? (
            <Tooltip mouseLeaveDelay={0} title='Grid View'>
              <IconWrapper onClick={() => changeItemsView(ItemView.BAR)}>
                <GridViewIcon />
              </IconWrapper>
            </Tooltip>
          ) : (
            <Tooltip mouseLeaveDelay={0} title='Table View'>
              <IconWrapper onClick={() => changeItemsView(ItemView.ROW)}>
                <TableViewIcon />
              </IconWrapper>
            </Tooltip>
          )}
        </ActionsWrapper>
        <Search
          className='search'
          placeholder='Search...'
          value={search}
          onChange={e => setSearch(e.target.value)}
          style={{ width: '300px' }}
        />
      </SearchWrapper>

      {renderContent()}
    </Modal>
  )
}

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin-top: 10px;
  gap: 10px;
`

const TableWrapper = styled.div`
  & tr {
    cursor: pointer;
  }
`
const NoResults = styled.p`
  color: #b7b7b7;
`

const SearchWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 300px;
  align-items: center;
  gap: 20px;
  justify-content: end;
`
const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 25px 25px;
  gap: 10px;
`

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  overflow: hidden;
`

const IconWrapper = styled.div`
  cursor: pointer;
  & svg {
    width: 22px;
    height: 22px;
  }
`

const Container = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`

const Text = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
`
