import React from 'react'


const Preloader = () => (
  <div className='loading'>
    <div
      className={'spinner-border text-primary'}
      style={{ margin: 'auto', display: 'flex', position: 'relative', zIndex: 999 }}
      role='status'
    >
      <span className='sr-only'>Loading...</span>
    </div>
  </div>
)

export default Preloader
