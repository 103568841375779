import { parsePhoneNumber } from 'features/Messaging/utils'
import Table, { ColumnsType } from 'antd/lib/table/Table'
import { Tooltip } from 'antd'

import { IExistingPhone } from 'features/Messaging/types'
import styled from 'styled-components'

interface Props {
  tableData: IExistingPhone[]
  updatePhones: (arg?: boolean) => void
}

export const CallsSettingsTable: React.FC<Props> = ({ tableData }) => {
  // const [isShowModal, setIsShowModal] = useState(false)
  // const [selectedItem, setSelectedItem] = useState<IExistingPhone>(
  //   {} as IExistingPhone,
  // )

  // const toggleInfoModal = () => setIsShowModal(prev => !prev)

  const renderArray = (array: string | any[]) => {
    if (!array.length) return '-'

    return (
      <>
        {array.length >= 2 ? [array[0], array[1]].join(', ') : array[0]}
        {array?.length >= 3 && (
          <Tooltip
            title={array.map((title, i) =>
              ![0, 1].includes(i) ? (
                <>
                  {title}
                  <br />
                </>
              ) : (
                ''
              ),
            )}
          >
            <RestItems className='rest-items'>+{array.length - 2}</RestItems>
          </Tooltip>
        )}
      </>
    )
  }

  const columns: ColumnsType<IExistingPhone> = [
    {
      title: 'Phone Number',
      key: 'number',
      dataIndex: 'number',
      render: (number: IExistingPhone['number']) => (
        <StyledLink>{parsePhoneNumber(number)}</StyledLink>
      ),
    },
    {
      title: 'Source',
      key: 'alias',
      dataIndex: 'alias',
    },
    {
      title: 'Assigned Users',
      key: 'users',
      dataIndex: 'users',
      render: (users: IExistingPhone['users']) =>
        renderArray(users.map(({ full_name }) => full_name)),
    },
  ]
  //
  //   const handleRowClick = (record: IExistingPhone) => {
  //     setSelectedItem(record)
  //   }

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      // onRow={record => ({
      //   onClick: () => {
      //     handleRowClick(record)
      //     toggleInfoModal()
      //   },
      // })}
      pagination={false}
    />
  )
}

const StyledLink = styled.span`
  cursor: pointer;
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  transition: color 0.3s;
`

const RestItems = styled.span`
  padding: 3px 3px;
  margin-left: 5px;
  color: #4285f4;
  background: rgba(76, 83, 239, 0.08);
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  height: auto;
  border-radius: 4px;
  white-space: nowrap;
  cursor: default;
`
