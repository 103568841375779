import React, { Suspense } from 'react'
import { Switch } from 'react-router-dom'
import PrivateRoute from './routes/PrivateRoute'
import { useRoutes } from './routes/routes'
import Spinner from './components/Spinner'
import './style.scss'
import './variables.scss'

const AdminApp = ({ me }) => {
  const [routes, redirect] = useRoutes(me)

  return (
    <>
      <div className='admin-module-wrapper'>
        <Suspense fallback={<Spinner />}>
          <Switch>
            {routes.map(route => (
              <PrivateRoute key={route.path} {...route} />
            ))}
          </Switch>
        </Suspense>
      </div>
    </>
  )
}

export default AdminApp
