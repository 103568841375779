import { Select } from 'antd'
import Avatar from 'containers/MainContent/Orcatec/components/Avatar'
import { selectMatrixLog } from 'features/Dispatch/dispatchSlice'
import { getMediaURL } from 'helpers/getMediaURL'
import { useAppSelector } from 'store/Orcatec/hooks'

const { Option } = Select

interface Props {
  value: number[]
  onChange: (choosenEmployees: number[]) => void
}

export const PreferredTech = ({ value, onChange }: Props) => {
  const { technicians } = useAppSelector(selectMatrixLog)

  return (
    <Select
      mode='multiple'
      value={value}
      onChange={onChange}
      placeholder='Show all'
      style={{ width: '100%' }}
      allowClear
    >
      {technicians.map(worker => (
        <Option key={worker.user_id} value={worker.user_id} label={worker.name}>
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Avatar
              pictureURL={getMediaURL(worker.profile_picture)}
              color={worker.background_color || '#626ed4'}
              size={22}
            >
              {worker.name}
            </Avatar>
            {worker.name}
          </div>
        </Option>
      ))}
    </Select>
  )
}
