import { FormsPermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'

export const forms = {
  forms_list_can_read: {
    name: 'View Forms List',
    control_type: UniteType.FIRST_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.FORMS_LIST_CAN_READ,
    is_module: true,
    related_key: [],
  },
  forms_detailed_list_can_read: {
    name: 'View Form Detailed List',
    control_type: UniteType.FIRST_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.FORMS_DETAILED_LIST_CAN_READ,
    related_key: [],
  },
  forms_template_list_can_read: {
    name: 'View Form Template List',
    control_type: UniteType.THIRD_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.FORMS_TEMPLATE_LIST_CAN_READ,
    related_key: [IKey.FORMS_TEMPLATE_CAN_DELETE],
    children: [
      {
        name: 'Delete Form Template ',
        permission_key: IKey.FORMS_TEMPLATE_CAN_DELETE,
        with_access_scope: false,
        with_access_checkbox: true,
        parent_permission_key: IKey.FORMS_TEMPLATE_LIST_CAN_READ,
      },
    ],
  },
  forms_can_verify: {
    name: 'User Can Verify Form',
    control_type: UniteType.FIRST_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.FORMS_CAN_VERIFY,
    related_key: [],
  },

  forms_can_send: {
    name: 'User Can Send Form',
    control_type: UniteType.FIRST_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.FORMS_CAN_SEND,
    related_key: [],
  },
}
