import React from "react"
import '../../styles/styles.scss'

const AudioRenderer= ({state}) => {
  const { currentDocument} = state
  
  return (<div className="audio-wrapper">
        <audio controls autoPlay muted loop playsInline controlsList="nodownload" src={currentDocument?.source || currentDocument?.linkToFile || currentDocument} ></audio> 
   </div>
  )
}

AudioRenderer.fileTypes = 'audio'
AudioRenderer.headerType='audio'

export default AudioRenderer