import { Select } from 'components/UIKit'
import EditableBlock from 'containers/MainContent/Orcatec/components/UI/EditableBlock'
import { ProjectSettings } from 'features/Project/types'
import { useState } from 'react'

const OPTIONS = [
  {
    label: 0,
    value: 0,
  },
  {
    label: 15,
    value: 15,
  },
  {
    label: 30,
    value: 30,
  },
  {
    label: 45,
    value: 45,
  },
  {
    label: 60,
    value: 60,
  },
  {
    label: 75,
    value: 75,
  },
  {
    label: 90,
    value: 90,
  },
]

interface Props {
  hasPermission: boolean
  value: number
  disabled: boolean
  onChange: (field: Partial<ProjectSettings>) => void
}

const DefaultPaymentDue = ({
  hasPermission,
  value,
  disabled,
  onChange,
}: Props) => {
  const [dueDate, setDueDate] = useState(value)

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDueDate(e.target.value)
  }

  const handleFeeSave = () => {
    onChange({
      default_payment_due_date: dueDate || 0,
    })
  }

  return (
    <EditableBlock
      value={`${dueDate} days`}
      onSave={handleFeeSave}
      disabled={disabled || !hasPermission}
      disabledText={
        !hasPermission
          ? 'You do not have permissions'
          : 'Can be changed if project is in Estimate status or Edit Contract mode'
      }
      onCancel={() => setDueDate(value)}
    >
      <Select
        showSearch={false}
        value={dueDate}
        onChange={handleValueChange}
        options={OPTIONS}
        width='100px'
      />
    </EditableBlock>
  )
}

export default DefaultPaymentDue
