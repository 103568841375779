import { Popconfirm } from 'antd'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { DeleteIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import { CustomSupply } from '../../ModalOrderEquipmentNew'
import { Row, Wrapper } from './CustomSupplies.styles'

interface Props {
  data: CustomSupply[]
  onChange: (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onAddNew: () => void
  onDelete: (index: number) => void
}

export const CustomSupplies = ({ data, onChange, onAddNew, onDelete }: Props) => {
  return (
    <Wrapper>
      <label>Custom items: </label>
      {data?.map((el, index) => (
        <Row key={index}>
          <InputField
            label='Description'
            name='description'
            value={el.description}
            onChange={e => onChange(index, e)}
            autoFocus
          />
          <InputField label='QTY' name='qty' value={el.qty} onChange={e => onChange(index, e)} />

          <Popconfirm title='Are you sure you want to delete item?' onConfirm={() => onDelete(index)}>
            <span style={{ cursor: 'pointer' }}>
              <DeleteIcon />
            </span>
          </Popconfirm>
        </Row>
      ))}
      <MainButton title='Add custom item' onClick={onAddNew} disabled={data?.some(el => !el.description || !el.qty)} />
    </Wrapper>
  )
}
