import * as types from '../types'
import { fromJS } from 'immutable'

const initState = fromJS({
  spinner: false,
})

export default (state = initState, action) => {
  const systemState = fromJS(state)

  switch (action.type) {
    case types.system.SYSTEM_SHOW_SPINNER:
      return systemState.set('spinner', true)
    case types.system.SYSTEM_HIDE_SPINNER:
      return systemState.set('spinner', false)
    default:
      return state
  }
}
