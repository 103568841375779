import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import queueApi from 'api/settings/Matrix'
import { AppStateType } from 'store'
import { Error } from 'types/Error'
import { transformArrayToObj } from './helpers'
import { DispatchQueue, NewDispatchQueue } from './Queue/types'
import { AsyncThunkAPI } from './types'
import { EventType } from 'features/Settings/Dispatch/components/QueueSettings/types'

interface QueueError {
  errors: Error
}

const initialState: {
  ids: number[]
  entities: Record<string, DispatchQueue>
  errors: Record<string, string> | null
  filters: Record<string, number[]>
  status: 'idle' | 'loading' | 'success' | 'error'
  settings: {
    defaultQueues: Record<EventType, number | null>
  }
} = {
  ids: [],
  entities: {},
  errors: null,
  filters: {},
  status: 'idle',
  settings: {
    defaultQueues: {
      [EventType.Appointments]: null,
      [EventType['Recurring Appointments']]: null,
      [EventType.Jobs]: null,
      [EventType['Recurring Jobs']]: null,
      [EventType.Leads]: null,
    },
  },
}

export const fetchQueues = createAsyncThunk(
  'dispatchQueue/fetchAll',
  async (_, { dispatch }) => {
    const res = await queueApi.getQueueList()

    dispatch(queuesUpdated(res))
    return res
  },
)

export const createQueue = createAsyncThunk<
  DispatchQueue,
  NewDispatchQueue,
  AsyncThunkAPI<QueueError>
>('dispatchQueue/createQueue', async (data, { rejectWithValue }) => {
  try {
    const res = await queueApi.createQueue(data)

    return res
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const updateQueue = createAsyncThunk<
  DispatchQueue,
  DispatchQueue,
  AsyncThunkAPI<QueueError>
>('dispatchQueue/updateQueue', async (data, { rejectWithValue }) => {
  // const queueListLength = getState().orcatec.queueSlice.ids.length

  try {
    const res = await queueApi.updateQueue(data)

    return res
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const deleteQueue = createAsyncThunk<
  number,
  { id: number; deleteOptions: { move_to: number | null } },
  AsyncThunkAPI<Error>
>(
  'dispatchQueue/deleteQueue',
  async ({ id, deleteOptions }, { dispatch, rejectWithValue }) => {
    try {
      await queueApi.deleteQueue(id, deleteOptions)

      dispatch(fetchQueueSettings())
      return id
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateQueueSortOrder = createAsyncThunk<
  void,
  DispatchQueue[],
  AsyncThunkAPI
>('dispatchQueue/updateSortOrder', async (sortedQueues, { dispatch }) => {
  dispatch(queuesUpdated(sortedQueues))

  const res = await queueApi.sortQueues(sortedQueues)
  return res
})

export const fetchQueueSettings = createAsyncThunk(
  'dispatchQueue/fetchAll',
  async (_, { dispatch }) => {
    const res = await queueApi.getQueueSettings()

    dispatch(queueSettingsUpdated(res))
    return res
  },
)

export const updateQueueSettings = createAsyncThunk<
  Record<EventType, number | null>,
  Record<EventType, number | null>,
  AsyncThunkAPI
>('dispatchQueue/fetchAll', async (data, { dispatch }) => {
  const res = await queueApi.updateQueueSettings(data)

  dispatch(queueSettingsUpdated(data))
  return res
})

const queueSlice = createSlice({
  name: 'dispatchQueue',
  initialState,
  reducers: {
    queuesUpdated: (state, action: PayloadAction<DispatchQueue[]>) => {
      const byId = transformArrayToObj(action.payload)

      state.entities = byId
      state.ids = action.payload
        .sort((a, b) => a.sort_order - b.sort_order)
        .map(queue => queue.id)
    },
    queueErrorReseted: (state, action: PayloadAction<keyof DispatchQueue>) => {
      if (state.errors) delete state.errors[action.payload]
    },
    resetStatus: state => {
      state.errors = null
      state.status = 'idle'
    },
    queueSettingsUpdated: (state, action) => {
      state.settings.defaultQueues = action.payload
    },
    queueFiltersSet: (state, action) => {
      state.filters = action.payload

      localStorage.setItem('queueFilters', JSON.stringify(action.payload))
    },
  },
  extraReducers: builder => {
    builder.addCase(createQueue.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(createQueue.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.entities = { ...state.entities, [payload.id]: payload }
      state.ids = [...state.ids, payload.id]
    })
    builder.addCase(createQueue.rejected, (state, { payload }) => {
      state.status = 'error'
      state.errors = payload?.errors || null
    })
    builder.addCase(updateQueue.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateQueue.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.entities[payload.id] = payload
    })
    builder.addCase(updateQueue.rejected, (state, { payload }) => {
      state.status = 'error'
      state.errors = payload?.errors || null
    })
    builder.addCase(deleteQueue.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteQueue.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      delete state.entities[payload]
      state.ids = state.ids.filter(id => id !== payload)
    })
    builder.addCase(deleteQueue.rejected, (state, { payload }) => {
      state.status = 'error'
      state.errors = payload || null
    })
  },
})

export default queueSlice.reducer
export const {
  queuesUpdated,
  queueErrorReseted,
  resetStatus,
  queueSettingsUpdated,
  queueFiltersSet,
} = queueSlice.actions

//Selectors
export const selectQueueSlice = (state: AppStateType) =>
  state.orcatec.queueSlice

export const selectSortedQueueList = createSelector(
  selectQueueSlice,
  ({ ids, entities }) => ids.map(id => entities[id]),
)

export const selectQueueSettings = createSelector(
  selectQueueSlice,
  ({ settings }) => settings,
)

export const selectQueueById = (id: number | string) =>
  createSelector(selectQueueSlice, ({ entities }) => {
    if (typeof id !== 'number') return null

    return entities[id]
  })
