import React from 'react'
import { Popconfirm } from 'antd'

import { DeleteIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { Media } from 'types/Media'

import './MediaPreviewList.scss'

interface Props {
  mediaList: Media[]
  deleteMediaUnit: (id: number) => void
}

export const MediaPreviewList: React.FC<Props> = ({
  mediaList,
  deleteMediaUnit,
}) =>
  mediaList?.length ? (
    <div className='chat-media-preview__container'>
      {mediaList.map(({ id, source }: Media) => (
        <div className='chat-media-preview__item' key={`${id}`}>
          <Popconfirm
            placement='topLeft'
            title='Are you sure you want to delete this image?'
            onConfirm={() => id && deleteMediaUnit(id)}
            okText='Yes'
            cancelText='No'
          >
            <div className='remove-icon'>
              <DeleteIcon />
            </div>
          </Popconfirm>
          <div
            className='chat-media-preview__picture'
            onClick={() => window.open(source)}
            style={{ background: `url(${source}) center / cover` }}
          />
        </div>
      ))}
    </div>
  ) : null
