import styled from 'styled-components'

type Props = {
  pictureURL?: string | null
  size: number
  backgroundColor?: string | null
}

export const Wrapper = styled.div<Props>`
  background-image: ${props => `url(${props.pictureURL})`};
  background-color: ${props => props.backgroundColor};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /* border: 2px solid #fff; */

  border-radius: 50%;
  color: white;
  font-size: ${props => props.size * 0.45}px;

  height: ${props => props.size}px;
  width: ${props => props.size}px;
  min-width: ${props => props.size}px;

  display: flex;
  align-items: center;
  justify-content: center;
`
