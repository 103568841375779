import React from 'react'
import { Modal } from 'antd'

export const DropModal = ({
  showDropModal,
  onConfirmDropModal,
  onCancelDropModal,
  count_days,
  dispatchColumns,
}) => {
  return (
    <Modal
      title='Ooops..'
      visible={showDropModal}
      onOk={onConfirmDropModal}
      okText='Proceed'
      onCancel={onCancelDropModal}
    >
      <h6>There are time slot conflicts for these technicians: </h6>

      {count_days && (
        <div
          style={{
            whiteSpace: 'pre',
            marginBottom: 10,
          }}
        >
          {Object.entries(count_days)?.map(([id]) => {
            return (
              <>
                <p style={{ fontWeight: 'bold' }}>
                  {
                    [...dispatchColumns, { id: 'misc', name: 'Misc' }]?.find(
                      w => w.id === +id,
                    )?.name
                  }
                </p>
              </>
            )
          })}
        </div>
      )}

      <p>Would do you like to put conflict appointments in queue?</p>
    </Modal>
  )
}
