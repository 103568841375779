import { Button, Tooltip } from 'antd'
import { ItemProgressEntry } from 'features/Project/types'
import moment from 'moment-timezone'
import {
  Wrapper,
  Table,
  TableHeading,
  TableContent,
  TableRow,
  Totals,
} from './ProgressTable.styles'
import { WarningFilled, EditOutlined } from '@ant-design/icons'

interface Props {
  data: ItemProgressEntry[]
  disabled: boolean
  estimatedQty: number
  totalInstalled: number
  onClickEntry: (id: number) => void
}

export const ProgressTable = ({
  data,
  disabled,
  estimatedQty,
  totalInstalled,
  onClickEntry,
}: Props) => {
  return (
    <Wrapper>
      <Table>
        <TableHeading>
          <p>Install Date</p>
          <p>Worker</p>
          <p>Qty</p>
          <p></p>
        </TableHeading>

        <TableContent>
          {data.length ? (
            data.map(entry => (
              <TableRow key={entry.id} onClick={() => onClickEntry(entry.id)}>
                <p>{moment(entry.install_date).format('MM/DD/YYYY')}</p>
                <p>{entry?.tech?.title}</p>
                <p>{entry.qty}</p>
                <p>
                  <EditOutlined style={{ marginRight: 10 }} />
                </p>
              </TableRow>
            ))
          ) : (
            <p style={{ textAlign: 'center' }}>No Entries yet</p>
          )}

          <Totals>
            <p>Total installed:</p>
            <p>
              {totalInstalled}/{estimatedQty}
              {totalInstalled > estimatedQty && (
                <Tooltip title='Installed more than estimated'>
                  <WarningFilled style={{ marginLeft: 5, color: '#faad14' }} />
                </Tooltip>
              )}
            </p>
          </Totals>
        </TableContent>
      </Table>

      {!disabled && (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Button type='primary' onClick={() => onClickEntry(0)}>
            + Add Entry
          </Button>
        </div>
      )}
    </Wrapper>
  )
}
