import * as types from '../../types'

const initialState = {
  isOpen: false,
}

export default (state = initialState, action) => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case types.modalEquipment.OPEN_MODAL_EQUIPMENT:
      newState.isOpen = !newState.isOpen
      return newState
    default:
      return state
  }
}
