import { Select } from 'antd'
import { ProjectStatus } from 'features/Project/types'
import styled from 'styled-components'

interface Props {
  error?: string
  statusId?: ProjectStatus['id'] | null
  statusList: ProjectStatus[]
  loading?: boolean
  onChange: (status: ProjectStatus) => void
  statusInfo: Record<
    number,
    {
      title: string
      description?: string
      icon?: React.ReactNode
      color?: string
    }
  >
}

export const ProjectStatusDropdown = ({
  error,
  statusId,
  statusList,
  loading,
  statusInfo,
  onChange,
}: Props) => {
  const status = statusList.find(status => status.id === statusId)

  const handleChangeStatus = (statusId: ProjectStatus['id']) => {
    const status = statusList.find(status => status.id === statusId)

    if (!status) return

    onChange(status)
  }

  return (
    <Status color={status?.color}>
      <Select
        showSearch
        name='status'
        placeholder='Select status'
        value={statusId}
        onChange={handleChangeStatus}
        status={error ? 'error' : undefined}
        loading={loading}
        listItemHeight={32}
        options={statusList.map(status => ({
          key: status.id,
          title: status.display_name,
          value: status.id,
          label: (
            <Label color={status?.color}>
              {statusInfo?.[status.parent_status]?.icon}
              {/* <Tooltip title={statusList.data[statusId].display_name}> */}
              <span>{status.display_name}</span>
              {/* </Tooltip> */}
            </Label>
          ),
        }))}
        filterOption={(inputValue: string, option: { label: string }) =>
          option?.title?.toLowerCase()?.includes(inputValue?.toLowerCase())
        }
      />

      {error && <Error>{error}</Error>}
    </Status>
  )
}

const Status = styled.div<{ color?: string }>`
  /* flex: 0 0 200px;
  max-width: 200px; */

  span {
    color: ${props => props.color} !important;
  }

  .ant-select {
    width: 100%;
    font-size: 14px;
    font-weight: 500;

    .ant-select-selector {
      border-radius: 6px;
      background-color: ${props => `${props.color}33` || '#206FED33'};
      color: ${props => props.color};

      i.mdi {
        color: ${props => props.color} !important;
      }
    }
    .ant-select-arrow {
      color: ${props => props.color};
    }
  }
`

const Label = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: hidden;

  span {
    color: ${props => props.color} !important;
  }
`

const Error = styled.p`
  font-size: 12px;
  color: #f12832;
`
