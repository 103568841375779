export const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.addEventListener('load', () => {
      resolve(image)
    })
    image.addEventListener('error', error => reject(error))
    image.src = url
  })

export async function getRotatedImage(imageSrc, rotation = 0) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270
  if (orientationChanged) {
    canvas.width = image.height
    canvas.height = image.width
    ctx.globalAlpha = 1
    ctx.fillStyle = '#fff'
    ctx.fillRect(image.height / 2, image.width / 2, image.height * 10, image.width * 10)
  } else {
    canvas.width = image.width
    canvas.height = image.height
    ctx.globalAlpha = 1
    ctx.fillStyle = '#fff'
    ctx.fillRect(image.width / 2, image.height / 2, image.width * 10, image.height * 10)
  }
  ctx.translate(canvas.width / 2, canvas.height / 2)
  ctx.rotate((rotation * Math.PI) / 180)

  //ctx.fillRect(-image.width / 2, -image.height / 2, image.width*10, canvas.width)

  ctx.globalCompositeOperation = 'source-over'
  ctx.drawImage(image, -image.width / 2, -image.height / 2)
  return canvas.toDataURL('image/png')
  /* return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file))
    }, 'image/jpeg')
  }) */
}
