import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: 5px;
`
export const Content = styled.div<{ unchecked: boolean; notApproved: boolean }>`
  display: grid;
  grid-template-columns: 30px 1fr 45px 40px 95px 95px 95px 50px 50px 100px 55px;
  align-items: baseline;
  gap: 5px;
  border-bottom: 1px solid #fafafa;
  padding: 5px 0;
  position: relative;
  background-color: ${props => (!props.notApproved ? 'initial' : '#4285f41c')};
  border: ${props => (props.notApproved ? '1px solid #4285f4' : 'none')};
  border-radius: 4px;

  & > *:not(:first-child):not(:last-child) {
    opacity: ${({ unchecked }) => (unchecked ? 0.5 : 1)};
  }
`

export const ApproveButtons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  right: 5px;
  bottom: 5px;
`

export const GrossMargin = styled.div`
  display: flex;
  align-items: center;
`

export const SemiBold = styled.p`
  font-weight: 500;
  white-space: pre-wrap;
`
export const CustomLabel = styled.span`
  cursor: help;
  margin-right: 5px;
  color: #4285f4;
`

export const RowTitle = styled.p`
  color: grey;
  font-size: 0.8rem;
`

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 25px 25px 25px;
  gap: 5px;
  font-size: 16px;
  /* margin-top: 5px; */

  & > * {
    cursor: pointer;
  }
`
