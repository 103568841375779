import { useContext, useEffect, useState } from 'react'
import { DocViewerContext } from '../state'
import { setError } from '../state/actions'
import { checkUrlFormat } from './checkTypeFile'
import { openFileInNewTab } from './openFileInTab'

export const useRendererSelector = () => {
  const { state, dispatch } = useContext(DocViewerContext)
  const {
    currentDocument,
    pluginRenderers,
    handleCancel,
    isOpenSideBar,
  } = state
  const [CurrentPlagin, setCurrentPlagin] = useState()

  useEffect(() => {
    const renderURL =
      currentDocument?.source ||
      currentDocument?.linkToFile ||
      currentDocument ||
      null
    const path = checkUrlFormat(renderURL)
    if (!renderURL) {
      return dispatch(setError(true, ''))
    }

    if (!path.isCheck) {
      openFileInNewTab(renderURL)
      !isOpenSideBar && handleCancel()
      dispatch(setError(true, 'The document has been opened in a new tab.'))
      return
    }
    if (path.isCheck) {
      const [neededPlagin] = pluginRenderers.filter(
        ({ fileTypes }) => fileTypes === path?.type,
      )
      if (!neededPlagin) {
        openFileInNewTab(renderURL)
        !isOpenSideBar && handleCancel()
        return
      }
      setCurrentPlagin(() => neededPlagin)
      dispatch(setError(false, ''))
    }
  }, [currentDocument])

  return { CurrentPlagin }
}
