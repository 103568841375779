import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit'
import { startAppListening } from 'store/listenerMiddleware'
import {
  EntityType,
  Contact,
  ProjectProperty,
  ApiResponse,
  PropertyWithAdditionalData,
  ContactWithAdditionalData,
} from '../types'
import { ProjectContactsAPI } from 'api/Project'
import { AsyncThunkAPI, DefaultError } from 'store/types'

import { AppStateType } from 'store'
import { normalizeData } from '../Contacts/helpers/normalizeData'
import { formatRequestData } from '../Contacts/helpers/formatRequestData'
import { reorderItems } from '../Contacts/helpers/reorderItems'
import {
  attachPropertyToContact,
  attachContactToOrganization,
} from 'api/Contacts/Contacts'
import { attachClientOrOrganization } from 'api/Property'

import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { ClientType } from 'types/Client'

type BaseItem = ContactWithAdditionalData | PropertyWithAdditionalData

interface ContactsState {
  client: {
    [id: BaseItem['proposal_relation_id']]: {
      [index: number]: ContactWithAdditionalData
    }
  }
  property: {
    [id: BaseItem['proposal_relation_id']]: {
      [index: number]: PropertyWithAdditionalData
    }
  }
  allIds: { id: number; entity_type: EntityType }[]
  counts: {
    [id: number]: {
      countClient: number
      countOrganization: number
      countProperties: number
    }
  }
}

interface TilesState {
  client: Contact[]
  property: ProjectProperty[]
}

interface EntitiesState {
  relations: { [id: number]: RelationEntity }
}

interface RelationEntity {
  proposal_relation: null | string
  proposal_relation_id: number | null
  entity_id: number
  entity_type: EntityType
}

interface DataState {
  entities: EntitiesState
  contacts: ContactsState
  tiles: TilesState
}

interface ProjectContactsState {
  status: 'idle' | 'loading' | 'error' | 'success' | 'processing'
  error: DefaultError | undefined
  open: boolean
  in_process: BaseItem | null
  data: DataState
}

interface AddContactToProjectArgs {
  projectId: number
  data: {
    entity_type: EntityType
    entity_id: number
  }
}

type AttachChildToProjectArgs = {
  projectId: number
  item: BaseItem
}

type DetachContactFromProjectArgs = {
  projectId: number
  item: BaseItem
}

type UpdateProjectContactRelationArgs = {
  data: {
    proposal_relation_id: number
    proposal_relation_type: EntityType
    proposal_relation: string
  }
  projectId: number
}

type GetRelationContactToProjectArgs = {
  projectId: number
}

type SetPositionArgs = {
  sourceIndex: number
  destinationIndex: number
  entity_type?: EntityType
}

type AddChildContactToProjectArgs = {
  idToAttach: number
  item: BaseItem
  typeToAttach: EntityType
}

type InvalidateParentTreeArgs = {
  parent_entity_id: number
  parent_entity_type: EntityType
}

const initialContactsState: ContactsState = {
  client: {},
  property: {},
  allIds: [],
  counts: {},
}

const initialState: ProjectContactsState = {
  status: 'idle',
  error: null,
  open: false,
  in_process: null,
  data: {
    entities: {
      relations: {},
    },
    contacts: initialContactsState,
    tiles: {
      client: [],
      property: [],
    },
  },
}

type IndexedItem<T> = {
  item: T
}

const setRelationReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<RelationEntity>,
) => {
  state.data.entities.relations[payload.id] = payload.data
}

const setItemToTilesReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<IndexedItem<BaseItem>>,
) => {
  const position = state.data.tiles[payload.item.entity_type]

  state.data.tiles[payload.item.entity_type] = [
    ...state.data.tiles[payload.item.entity_type],
    {
      ...payload.item,
      position: position.length + 1,
      is_primary: !position,
    },
  ]
}

const toggleContactSectionReducer = (state: ProjectContactsState) => {
  state.open = !state.open
}
const onChangeSelectedItemReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ item: BaseItem; checked: boolean }>,
) => {
  const { item, checked } = payload

  const { index, entity_parent_type, parent_proposal_relation_id } = item

  state.data.contacts[entity_parent_type][parent_proposal_relation_id][
    index
  ].checked = checked
}

const onChangeSelectedParentItemReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ item: BaseItem; checked: boolean }>,
) => {
  const { item, checked } = payload

  const { index, entity_type, parent_proposal_relation_id } = item

  state.data.contacts[entity_type][parent_proposal_relation_id][
    index
  ].display = checked
}

const setItemToIdsReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ id: number; entity_type: EntityType }>,
) => {
  state.data.contacts.allIds = [payload, ...state.data.contacts.allIds]
}

const setItemToCountsReducer = (
  state: ProjectContactsState,
  {
    payload,
  }: PayloadAction<{
    [id: number]: {
      countClient: number
      countOrganization: number
      countProperties: number
    }
  }>,
) => {
  state.data.contacts.counts = { ...state.data.contacts.counts, ...payload }
}

const removeItemFromIdsReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ id: number }>,
) => {
  state.data.contacts.allIds = state.data.contacts.allIds.filter(
    item => item.id !== payload.id,
  )
}

const setContactReducer = (
  state: ProjectContactsState,
  {
    payload,
  }: PayloadAction<{
    id: number
    entity_type: EntityType
    item: IndexedItem<BaseItem>
  }>,
) => {
  state.data.contacts[payload.entity_type][payload.id] = payload.item
}

const setContactsReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<ContactsState>,
) => {
  state.data.contacts.property = {
    ...state.data.contacts.property,
    ...payload.property,
  }
  state.data.contacts.client = {
    ...state.data.contacts.client,
    ...payload.client,
  }

  state.data.contacts.counts = {
    ...state.data.contacts.counts,
    ...payload.counts,
  }

  state.data.contacts.allIds = [
    ...payload.allIds,
    ...state.data.contacts.allIds,
  ]
}

const removeContactReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ id: number; entity_type: EntityType }>,
) => {
  delete state.data.contacts[payload.entity_type][payload.id]
}

const setRelationsReducer = (
  state: ProjectContactsState,
  {
    payload,
  }: PayloadAction<{
    [x: number]: RelationEntity
  }>,
) => {
  state.data.entities.relations = {
    ...state.data.entities.relations,
    ...payload,
  }
}

const removeRelationReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ id: number }>,
) => {
  delete state.data.entities.relations[payload.id]
}

const setEntitiesToTilesReducer = (
  state: ProjectContactsState,
  {
    payload,
  }: PayloadAction<{
    client: Contact[]
    property: ProjectProperty[]
  }>,
) => {
  state.data.tiles = payload
}

const removeItemFromTilesReducer = (
  state: ProjectContactsState,
  { payload }: PayloadAction<{ item: BaseItem }>,
) => {
  const newData = state.data.tiles[payload.item.entity_type].filter(
    item => item.proposal_relation_id !== payload.item.proposal_relation_id,
  )
  state.data.tiles[payload.item.entity_type] = newData
}

const updateOrderReducer = (state: ProjectContactsState, { payload }) => {
  state.data.tiles = { ...state.data.tiles, ...payload }
}

const resetContactSliceReducer = (state: ProjectContactsState) => {
  state.data = initialState.data
  state.open = false
}

const setItemInProcessReducer = (state: ProjectContactsState, { payload }) => {
  state.in_process = payload
}

const projectWorkOrderContacts = createSlice({
  name: 'projectWorkOrderContacts',
  initialState,
  reducers: {
    onChangeSelectedParentItem: onChangeSelectedParentItemReducer,
    toggleContactSection: toggleContactSectionReducer,
    onChangeSelectedItem: onChangeSelectedItemReducer,
    setItemToIds: setItemToIdsReducer,
    setItemToCounts: setItemToCountsReducer,
    removeItemFromIds: removeItemFromIdsReducer,
    setContact: setContactReducer,
    removeContact: removeContactReducer,
    setRelation: setRelationReducer,
    setRelations: setRelationsReducer,
    removeRelation: removeRelationReducer,
    setItemToTiles: setItemToTilesReducer,
    setEntitiesToTiles: setEntitiesToTilesReducer,
    removeItemFromTiles: removeItemFromTilesReducer,
    updateOrder: updateOrderReducer,
    resetWoContactSliceReducer: resetContactSliceReducer,
    setItemInProcess: setItemInProcessReducer,
    setContacts: setContactsReducer,
  },
  extraReducers: builder => {
    builder.addCase(getProjectWOContacts.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getProjectWOContacts.fulfilled, (state, { payload }) => {
      state.status = 'success'
      state.data = {
        ...state.data,
        ...normalizeData(payload),
      }
    })
    builder.addCase(
      getProjectWOContacts.rejected,
      (state, { payload }: PayloadAction<any>) => {
        state.status = 'error'
        state.error = payload
      },
    )

    builder.addCase(addChildContactToProjectWO.pending, state => {
      state.status = 'processing'
    })

    builder.addCase(addChildContactToProjectWO.fulfilled, state => {
      state.status = 'success'
      state.open = true
    })
    builder.addCase(
      addChildContactToProjectWO.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.error = payload
      },
    )

    builder.addCase(addContactToProjectWO.pending, state => {
      state.status = 'processing'
    })

    builder.addCase(addContactToProjectWO.fulfilled, state => {
      state.status = 'success'
      state.open = true
    })
    builder.addCase(addContactToProjectWO.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload
    })

    builder.addCase(attachChildToProjectWO.pending, state => {
      state.status = 'processing'
    })

    builder.addCase(attachChildToProjectWO.fulfilled, (state, { payload }) => {
      const {
        entity_parent_type,
        index,
        parent_proposal_relation_id,
      } = payload.item

      state.data.contacts[entity_parent_type][parent_proposal_relation_id][
        index
      ].proposal_relation_id = payload.proposal_relation_id

      state.status = 'idle'

      state.in_process = null
    })
    builder.addCase(
      attachChildToProjectWO.rejected,
      (state, { payload, meta }) => {
        const {
          index,
          entity_parent_type,
          parent_proposal_relation_id,
        } = meta.arg.item
        state.data.contacts[entity_parent_type][parent_proposal_relation_id][
          index
        ].checked = false
        state.status = 'error'
        state.in_process = null
        state.error = payload
        openNotificationWithIcon('error', { message: 'Something went wrong!' })
      },
    )

    builder.addCase(detachContactFromProjectWO.pending, state => {
      state.status = 'processing'
    })

    builder.addCase(
      detachContactFromProjectWO.fulfilled,
      (state, { payload }) => {
        const { clients_relations, properties_relations } = payload
        state.data.tiles = {
          client: state.data.tiles.client.filter(
            item => !clients_relations.includes(item.proposal_relation_id),
          ),
          property: state.data.tiles.property.filter(
            item => !properties_relations.includes(item.proposal_relation_id),
          ),
        }
        state.status = 'success'
        state.in_process = null
      },
    )
    builder.addCase(
      detachContactFromProjectWO.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.in_process = null
        state.error = payload
        openNotificationWithIcon('error', { message: 'Something went wrong!' })
      },
    )

    builder.addCase(updateProjectWOContactRelation.pending, state => {
      state.status = 'processing'
    })

    builder.addCase(
      updateProjectWOContactRelation.fulfilled,
      (state, { payload }) => {
        const {
          proposal_relation_id,
          proposal_relation,
          proposal_relation_type,
        } = payload

        const oldData = state.data.tiles[proposal_relation_type]
        const newData = oldData.map(item =>
          item.proposal_relation_id === proposal_relation_id
            ? { ...item, proposal_relation }
            : item,
        )

        state.data.tiles[proposal_relation_type] = newData
        state.status = 'idle'
        state.in_process = null
      },
    )
    builder.addCase(
      updateProjectWOContactRelation.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.error = payload
        state.in_process = null
      },
    )

    builder.addCase(
      getRelationContactToProjectWorkOrder.fulfilled,
      (state, { payload }) => {
        const { properties, contacts } = payload
        state.data.tiles = {
          [EntityType.CLIENT]: contacts,
          [EntityType.PROPERTY]: properties,
        }
      },
    )
    builder.addCase(
      getRelationContactToProjectWorkOrder.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.error = payload
      },
    )

    builder.addCase(selectParentWOContact.pending, state => {
      state.status = 'processing'
    })

    builder.addCase(selectParentWOContact.fulfilled, (state, { payload }) => {
      const { entity_type, proposal_relation_id } = payload.item

      state.data.tiles[entity_type] = state.data.tiles[entity_type].map(item =>
        item.proposal_relation_id === proposal_relation_id
          ? { ...item, display: payload.checked }
          : item,
      )

      state.status = 'idle'

      state.in_process = null
    })
    builder.addCase(
      selectParentWOContact.rejected,
      (state, { payload, meta }) => {
        const {
          index,
          entity_type,
          parent_proposal_relation_id,
        } = meta.arg.item
        state.data.contacts[entity_type][parent_proposal_relation_id][
          index
        ].display = false
        state.status = 'error'
        state.in_process = null
        state.error = payload
      },
    )
  },
})

export const getProjectWOContacts = createAsyncThunk<
  ApiResponse,
  number,
  AsyncThunkAPI
>('project/getProjectWOContacts', async (projectId, { rejectWithValue }) => {
  try {
    const res = await ProjectContactsAPI.getProjectContacts(projectId)
    return res
  } catch (error) {
    rejectWithValue(error.response.data)
  }
})

export const addContactToProjectWO = createAsyncThunk<
  Contact,
  AddContactToProjectArgs,
  AsyncThunkAPI
>(
  'project/addContactToProjectWO',
  async ({ projectId, data }, { rejectWithValue, getState, dispatch }) => {
    const tiles = getState().orcatec.projectWorkOrderContactsSlice.data.tiles
    try {
      const res = await ProjectContactsAPI.attachContactToProject(
        projectId,
        data,
      )

      const { contacts, entities } = normalizeData([res.data])

      const { entity_type, proposal_relation_id } = res.data

      dispatch(
        setContact({
          id: proposal_relation_id,
          entity_type,
          item: Object.values(contacts[entity_type])[0],
        }),
      )

      dispatch(setItemToIds({ id: proposal_relation_id, entity_type }))

      dispatch(setRelations(entities.relations))

      dispatch(setItemToCounts(contacts.counts))

      dispatch(
        setEntitiesToTiles({
          client: [...tiles.client, ...res.related_contacts.contacts],
          property: [...tiles.property, ...res.related_contacts.properties],
        }),
      )

      return res.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const attachChildToProjectWO = createAsyncThunk<
  { proposal_relation_id: number; item: BaseItem },
  AttachChildToProjectArgs,
  AsyncThunkAPI
>(
  'project/attachChildToProjectWO',
  async ({ projectId, item }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState().orcatec.projectWorkOrderContactsSlice.data
        .contacts

      const parent =
        state[item.entity_parent_type][item.parent_proposal_relation_id]['1']

      const data = {
        proposal_parent_relation_id: parent?.proposal_relation_id,
        proposal_parent_id: parent?.id,
        proposal_parent_type: parent?.entity_type,
        parent_entity_id: item?.parent_id,
        parent_entity_type: item?.parent_type,
        entity_id: item?.id,
        entity_type: item?.entity_type,
      }

      const {
        data: tiles,
        proposal_relation_id,
      } = await ProjectContactsAPI.attachChildContactToProject(projectId, data)

      dispatch(
        setItemToTiles({
          item: { ...tiles, entity_type: item?.entity_type },
        }),
      )

      dispatch(
        setRelation({
          id: proposal_relation_id,
          data: {
            proposal_relation_id,
            proposal_relation: item.proposal_relation,
            entity_type: item.entity_type,
            entity_id: item.id,
          },
        }),
      )

      return { proposal_relation_id, item }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const detachContactFromProjectWO = createAsyncThunk<
  {
    item: BaseItem
    clients_relations: number[]
    properties_relations: number[]
  },
  DetachContactFromProjectArgs,
  AsyncThunkAPI
>(
  'project/detachContactFromProjectWO',
  async ({ projectId, item }, { rejectWithValue, dispatch }) => {
    const data = {
      entity_id: item.id,
      entity_type: item.entity_type,
      proposal_relation_id: item.proposal_relation_id,
    }
    try {
      dispatch(
        removeItemFromIds({
          id: item.proposal_relation_id,
        }),
      )
      dispatch(removeItemFromTiles({ item }))
      dispatch(
        removeContact({
          id: item.proposal_relation_id,
          entity_type: item.entity_parent_type,
        }),
      )

      dispatch(removeRelation({ id: item.proposal_relation_id }))

      const {
        clients_relations,
        properties_relations,
      } = await ProjectContactsAPI.detachContactFromProject(projectId, data)

      return { item, clients_relations, properties_relations }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateProjectWOContactRelation = createAsyncThunk<
  UpdateProjectContactRelationArgs['data'],
  UpdateProjectContactRelationArgs,
  AsyncThunkAPI
>(
  'project/updateProjectWOContactRelation',
  async ({ data, projectId }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setRelation({ id: data.proposal_relation_id, data }))
      await ProjectContactsAPI.updateContactProjectRelation(projectId, data)

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const getRelationContactToProjectWorkOrder = createAsyncThunk<
  { properties: []; contacts: [] },
  GetRelationContactToProjectArgs,
  AsyncThunkAPI
>(
  'project/getRelationContactToProjectWorkOrder',
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const { data } = await ProjectContactsAPI.getRelationContactToProject(
        projectId,
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const setProjectWOClientsPosition = createAsyncThunk<
  { data: [] },
  SetPositionArgs,
  AsyncThunkAPI
>(
  'workOrder/setClientsPosition',
  async (
    { sourceIndex, destinationIndex },
    { rejectWithValue, dispatch, getState },
  ) => {
    const state = getState().orcatec.projectWorkOrderContactsSlice.data.tiles
      .client

    const reorderedItems = reorderItems(state, sourceIndex, destinationIndex)

    try {
      dispatch(updateOrder({ client: reorderedItems }))
      const data = formatRequestData(reorderedItems, 'client_relation_id')
      await ProjectContactsAPI.projectClientsSetPosition(data)

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const projectWOPropertiesSetPosition = createAsyncThunk<
  { data: [] },
  SetPositionArgs,
  AsyncThunkAPI
>(
  'project/projectWOPropertiesSetPosition',
  async (
    { sourceIndex, destinationIndex },
    { rejectWithValue, dispatch, getState },
  ) => {
    const state = getState().orcatec.projectWorkOrderContactsSlice.data.tiles
      .property

    const reorderedItems = reorderItems(state, sourceIndex, destinationIndex)
    try {
      dispatch(updateOrder({ property: reorderedItems }))
      const data = formatRequestData(reorderedItems, 'property_relation_id')
      await ProjectContactsAPI.projectPropertiesSetPosition(data)

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const projectWOSetPosition = createAsyncThunk<
  { data: [] },
  {
    entity_type: EntityType
  },
  AsyncThunkAPI
>(
  'project/projectWOPropertiesSetPosition',
  async ({ entity_type }, { rejectWithValue, getState }) => {
    const state = getState().orcatec.projectWorkOrderContactsSlice.data.tiles[
      entity_type
    ]

    try {
      const type =
        entity_type === EntityType.CLIENT
          ? 'client_relation_id'
          : 'property_relation_id'
      const data = formatRequestData(state, type)
      if (entity_type === EntityType.CLIENT) {
        await ProjectContactsAPI.projectClientsSetPosition(data)
      } else {
        await ProjectContactsAPI.projectPropertiesSetPosition(data)
      }

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const addChildContactToProjectWO = createAsyncThunk<
  null,
  AddChildContactToProjectArgs,
  AsyncThunkAPI
>(
  'project/addChildContactToProjectWO',
  async (data, { rejectWithValue, dispatch }) => {
    const { idToAttach, item, typeToAttach } = data

    try {
      if (typeToAttach && item.type === ClientType.ORGANIZATION) {
        await attachContactToOrganization(item.id, idToAttach, '')
      } else {
        if (item.entity_type === EntityType.PROPERTY) {
          await attachClientOrOrganization(item.id, idToAttach, { note: '' })
        }

        if (item.entity_type === EntityType.CLIENT) {
          if (!typeToAttach) {
            await attachPropertyToContact(item.id, idToAttach, { note: '' })
          }
        }
      }

      dispatch(
        invalidateWOParentTree({
          parent_entity_id: item.entity_id,
          parent_entity_type: item.entity_parent_type,
        }),
      )
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const invalidateWOParentTree = createAsyncThunk<
  object,
  InvalidateParentTreeArgs,
  AsyncThunkAPI
>(
  'project/invalidateWOParentTree',
  async (data, { rejectWithValue, getState, dispatch }) => {
    const { parent_entity_id, parent_entity_type } = data

    const projectId = getState().orcatec.projectWorkOrdersSlice.current.id

    try {
      const res = await ProjectContactsAPI.getParentContactById(projectId, {
        entity_type: parent_entity_type,
        entity_id: parent_entity_id,
      })

      const { contacts, entities } = normalizeData([res.data])

      const { proposal_relation_id, entity_type } = res.data

      dispatch(
        setContact({
          id: proposal_relation_id,
          entity_type,
          item: Object.values(contacts[entity_type])[0],
        }),
      )

      dispatch(setRelations(entities.relations))

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

/* export const resetFWO = createAsyncThunk<null, null, AsyncThunkAPI>(
  'project/resetFWO',
  async (_, { rejectWithValue, dispatch }) => {
    // const projectId = getState().orcatec.projectSlice.project.id

    // const companyId = getState().orcatec.company.id

    const fields = { customer: null, project: null, location: null }

    try {
      await dispatch(updateFwoFieldThunk({ fields }))

      // await dispatch(getFwoSettingsThunk(null))

      // await putProjectFWOSettings(projectId, data, companyId)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
) */

export const selectParentWOContact = createAsyncThunk<
  { checked: boolean; item: BaseItem },
  { checked: boolean; item: BaseItem; projectId: number },
  AsyncThunkAPI
>('project/selectParentWOContact', async (data, { rejectWithValue }) => {
  const req = {
    main_relation_id: data.item.proposal_relation_id,
    entity_type: data.item.entity_type,
    display: data.checked,
  }

  try {
    await ProjectContactsAPI.selectParentContact(data.projectId, req)

    return data
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

startAppListening({
  matcher: isAnyOf(
    detachContactFromProjectWO.fulfilled,
    attachChildToProjectWO.fulfilled,
    selectParentWOContact.fulfilled,
    setProjectWOClientsPosition.fulfilled,
    projectWOPropertiesSetPosition.fulfilled,
    addContactToProjectWO.fulfilled,
    invalidateWOParentTree.fulfilled,
  ),
  effect: (action, { dispatch }) => {
    if (
      isDetachContactAction(action) ||
      isAttachChildContactAction(action) ||
      isParentSelectedAction(action)
    ) {
      const { item } = action.payload

      dispatch(projectWOSetPosition({ entity_type: item.entity_type }))
    }

    if (
      isAddContactAction(action) ||
      isDetachContactAction(action) ||
      isAttachChildContactAction(action) ||
      isUpdatePropertyOrderAction(action) ||
      isUpdateClientOrderAction(action) ||
      isInvalidateParentAction(action)
    ) {
      dispatch(resetFWO(null))
    }
  },
})

const isDetachContactAction = (
  action: any,
): action is typeof detachContactFromProjectWO.fulfilled => {
  return action.type === detachContactFromProjectWO.fulfilled.type
}

const isAttachChildContactAction = (
  action: any,
): action is typeof attachChildToProjectWO.fulfilled => {
  return action.type === attachChildToProjectWO.fulfilled.type
}

const isAddContactAction = (
  action: any,
): action is typeof addContactToProjectWO.fulfilled.type => {
  return action.type === addContactToProjectWO.fulfilled.type
}

const isParentSelectedAction = (
  action: any,
): action is typeof selectParentWOContact.fulfilled => {
  return action.type === selectParentWOContact.fulfilled.type
}

const isUpdatePropertyOrderAction = (
  action: any,
): action is typeof projectWOPropertiesSetPosition.fulfilled => {
  return action.type === projectWOPropertiesSetPosition.fulfilled.type
}

const isUpdateClientOrderAction = (
  action: any,
): action is typeof setProjectWOClientsPosition.fulfilled.type => {
  return action.type === setProjectWOClientsPosition.fulfilled.type
}

const isInvalidateParentAction = (
  action: any,
): action is typeof invalidateWOParentTree.fulfilled.type => {
  return action.type === invalidateWOParentTree.fulfilled.type
}

const selectContactsIds = (state: AppStateType) =>
  state.orcatec.projectWorkOrderContactsSlice.data.contacts.allIds

const selectContacts = (state: AppStateType) =>
  state.orcatec.projectWorkOrderContactsSlice.data.contacts

export const selectWOSummaryContact = (state: AppStateType) =>
  state.orcatec.projectWorkOrderContactsSlice.data.tiles

const selectRelations = (state: AppStateType) =>
  state.orcatec.projectWorkOrderContactsSlice.data.entities.relations

const selectSlice = (state: AppStateType) =>
  state.orcatec.projectWorkOrderContactsSlice

const selectPropertyTiles = (state: AppStateType) =>
  state.orcatec.projectWorkOrderContactsSlice.data.tiles.property

const selectContactTiles = (state: AppStateType) =>
  state.orcatec.projectWorkOrderContactsSlice.data.tiles.client

const selectIsModalOpen = (state: AppStateType) =>
  state.orcatec.projectWorkOrderContactsSlice.open

const selectIsHasContacts = (state: AppStateType) =>
  state.orcatec.projectWorkOrderContactsSlice.data.contacts.allIds.length

const selectStatus = (state: AppStateType) =>
  state.orcatec.projectWorkOrderContactsSlice.status

const selectCounts = (state: AppStateType) =>
  state.orcatec.projectWorkOrderContactsSlice.data.contacts.counts

export const selectCountsByParentId = id =>
  createSelector(selectCounts, counts => counts[id])

const selectProcessing = (state: AppStateType) =>
  state.orcatec.projectWorkOrderContactsSlice.in_process

export const selectIsProjectHasContacts = createSelector(
  selectIsHasContacts,
  bool => bool,
)

export const selectProcessItem = createSelector(selectProcessing, item => item)

export const selectContactStatus = createSelector(
  selectStatus,
  status => status,
)

export const selectProjectWOModalOpen = createSelector(
  selectIsModalOpen,
  isOpen => isOpen,
)

export const selectProjectContactIds = createSelector(
  [selectContactsIds],
  ids => ids,
)

export const selectProjectContacts = createSelector(
  [selectContactsIds, selectContacts],
  (ids, contacts) =>
    ids.map(({ id, entity_type }) => contacts[entity_type][id]),
)

export const selectContactbyId = (id: number, entity_type: EntityType) =>
  createSelector([selectContacts], contacts =>
    Object.values(contacts[entity_type][id]),
  )

export const selectRelationById = (id: number) =>
  createSelector([selectRelations], relations => relations[id])

export const selectProjectContactsRelations = createSelector(
  selectRelations,
  relations => relations,
)

export const selectProjectContactSlice = () =>
  createSelector(selectSlice, slice => slice)

export const selectPrimaryProjectProperty = createSelector(
  selectPropertyTiles,
  properties =>
    properties.find(property => property?.is_primary) ||
    properties?.filter(item => item.display)?.[0],
)

export const selectPrimaryProjectClient = createSelector(
  selectContactTiles,
  clients =>
    clients.find(client => client?.is_primary) ||
    clients?.filter(item => item.display)?.[0],
)

export const selectPrimaryProjectContacts = createSelector(
  [selectContactTiles, selectPropertyTiles],
  (clients, properties) => ({
    client:
      clients.find(client => client?.is_primary) ||
      clients?.filter(item => item.display)?.[0],
    property:
      properties.find(property => property?.is_primary) ||
      properties?.filter(item => item.display)?.[0],
  }),
)

export const selectProjectContactsTiles = createSelector(
  [selectContactTiles, selectPropertyTiles],
  (clients, properties) => ({
    clients,
    properties,
  }),
)

export default projectWorkOrderContacts.reducer

export const {
  toggleContactSection,
  onChangeSelectedItem,
  removeItemFromTiles,
  setItemToCounts,
  updateOrder,
  setItemToIds,
  setContact,
  setRelation,
  setItemToTiles,
  removeItemFromIds,
  removeContact,
  removeRelation,
  setEntitiesToTiles,
  setRelations,
  resetWoContactSliceReducer,
  setItemInProcess,
  onChangeSelectedParentItem,
  setContacts,
} = projectWorkOrderContacts.actions
