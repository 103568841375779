// import React, { useState } from 'react'
import './TabInfoBlock.scss'
import TabNotesAndModules from './TabNotesAndModules/TabNotesAndModules'

import InformationForWokers from './InformationForWorkers'
// import TagsBlock from './TagsBlock/TagsBlock'
import { FieldWorkOrder } from '../../FieldWorkOrder/FieldWorkOrder'

// import { FormsBlock } from 'features/Forms/components/FormsBlock/FormsBlock'
// import {
//   getAttachedTemplatesToProject,
//   getRelatedFormToProject,
//   createRelatedFormToProject,
//   // getCountFormsProject,
//   // attachRelatedFormsToEvent,
//   getCountFormsProject,
//   attachRelatedFormsToProject,
// } from 'api/CustomForms'
import { Companies } from 'types/Company'
import { useAppSelector } from 'store/Orcatec/hooks'
// import { CustomFormEntityType } from 'features/Forms/types'
// import ErrorBoundary from 'containers/MainContent/Orcatec/components/ErrorBoundary/ErrorBoundary'
// import { selectProjectSettings } from 'features/Project/projectSelectors'
// import { FormsPermissions } from 'features/Settings/UsersAndGroups'
// import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'

const TabInfoBlock = ({
  currentTab = {},
  proposalForm,
  isTabContract,
  onChangeTabDetails,
  openModalOptional,
  openModalEquipment,
  openModalPayment,
  deleteTabFile,
  toggleFileModal,
  onDeleteTabTag,
  onAddTabTag,
  isEquipmentModuleStatusChanging,
  isPaymentModuleStatusChanging,
  isModuleOneStatusChanging,
  isModuleTwoStatusChanging,
  isModuleThreeStatusChanging,
  onChangeAllWorkComplited,
  onChangeLeadInstaller,
  openInstallationDate,
  getFileForModalDocViewer,
  jobCreated,
  handleChangeWorkCompletionDate,
}) => {
  const companyId = useAppSelector(state => state.orcatec.company.id)
  // const projectSettings = useAppSelector(selectProjectSettings)

  // const toggleShowContent = () => setShowContent(!showContent)

  const onChangeTextField = e => {
    const { name, value } = e.currentTarget
    onChangeTabDetails(value, name)
  }

  /*   const onChangeDescription = (e, idx) => {
    const value = { idx, text: e.target.value }
    onChangeTabDetails(value, 'file_description')
  } */

  // const [tempTagValue, setTempTagValue] = useState('')
  // const [tempTagValueError, setTempTagValueError] = useState('')
  /* 
  const changeTempTagValue = e => {
    const { value } = e.target
    setTempTagValueError(!!value && currentTab.tags?.length >= 20 ? 'Maximum 20 tags' : '')
    setTempTagValue(value)
  }

  const onAddTag = () => {
    addTag(
      tempTagValue,
      currentTab.tags,
      tag => onAddTabTag(tag),
      () => setTempTagValue(''),
    )
  }

  const deleteTag = (i, tag) => {
    onDeleteTabTag(i)
    setTempTagValueError('')
  } */

  // const installerFiles = Array.isArray(currentTab.files) ? currentTab.files : []
  /* 
  const openFileModal = () => {
    toggleFileModal('tab', installerFiles)
  } */

  /*   const deleteFile = (id, idx) => {
    return deleteTabFile(idx, id)
  } */

  return (
    <div className='proposal-form-tabs_content-info_block'>
      <TabNotesAndModules
        currentTab={currentTab}
        // proposalNotes={currentTab.proposal_notes ?? ''}
        onChangeNotes={onChangeTextField}
        openModalOptional={openModalOptional}
        openModalEquipment={openModalEquipment}
        isEquipmentModuleStatusChanging={isEquipmentModuleStatusChanging}
        isPaymentModuleStatusChanging={isPaymentModuleStatusChanging}
        isModuleOneStatusChanging={isModuleOneStatusChanging}
        isModuleTwoStatusChanging={isModuleTwoStatusChanging}
        isModuleThreeStatusChanging={isModuleThreeStatusChanging}
        openModalPayment={openModalPayment}
        // equipmentStatus={currentTab.status}
        // paymentStatus={currentTab.payment_status}
        // optionalModules={currentTab.modules}
        isTabContract={isTabContract}
      />

      {/* {projectSettings.show_tags && <TagsBlock id={proposalForm?.id} />} */}

      {/*  <div
        style={{
          margin: '20px 0 30px',
        }}
      >
        <ErrorBoundary>
          <FormsBlock
            entityId={proposalForm?.id}
            entityType={CustomFormEntityType.PROJECT}
            createRelatedForm={createRelatedFormToProject}
            getRelatedForm={getRelatedFormToProject}
            getAttachedTemplate={getAttachedTemplatesToProject}
            onAttachTemplates={attachRelatedFormsToProject}
            getCount={getCountFormsProject}
            id={proposalForm?.id}
            showAllowableTemplates={Companies.SafetyNey === companyId}
            chooseAllowableTemplates={Companies.SafetyNey === companyId}
            showTemplates
            // module={ModuleName.PROJECT}
          />
        </ErrorBoundary>
      </div> */}

      <InformationForWokers
        currentTab={currentTab}
        onAddTabTag={onAddTabTag}
        proposalForm={proposalForm}
        deleteTabFile={deleteTabFile}
        onChangeTextField={onChangeTextField}
        openInstallationDate={openInstallationDate}
        onChangeTabDetails={onChangeTabDetails}
        onChangeAllWorkComplited={onChangeAllWorkComplited}
        onChangeLeadInstaller={onChangeLeadInstaller}
        toggleFileModal={toggleFileModal}
        onDeleteTabTag={onDeleteTabTag}
        getFileForModalDocViewer={getFileForModalDocViewer}
        jobCreated={jobCreated}
        handleChangeWorkCompletionDate={handleChangeWorkCompletionDate}
      />
      {[Companies.SoCal].includes(companyId) && (
        <div style={{ marginTop: '20px' }}>
          <FieldWorkOrder proposalForm={proposalForm} />
        </div>
      )}
    </div>
  )
}

export default TabInfoBlock
