import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from 'store/Orcatec/actions'
import { Table } from '../Table'
import { Buttons } from '../Buttons'
import { Tabs } from '../TabsV2'
import { Section } from '../Section'
import { formatTableDataProperties, handleTableDataChange } from '../utils'
import { rowKeys } from './constants'
import { isNotEmpty } from 'helpers/isNotEmpty'
// import PagePropertyForm from 'containers/MainContent/Orcatec/Properties/components/PagePropertyForm/index.jsx'
import PropertyForm from 'containers/MainContent/Orcatec/Properties/components/PropertyForm'
import { Prompt } from 'react-router-dom'
import { Modals } from '../Modal'
import { useHistory } from 'react-router-dom'
import { BlockRoutingModal } from 'containers/MainContent/Orcatec/components/BlockRoutingModal'
import { Modal } from 'antd'
import { updateProposal } from 'store/Orcatec/actions/proposal/proposalForm'
import { putProjectFWOSettings } from 'api/Project'

export const Properties = ({
  openNotification,
  proposalForm,
  currentTableData,
  setCurrentTableData,
  qty,
  proposalId,
  freezed,
  openTab,
  onOpenForm,
  // renderSelectTab,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  // const currentProperty= useSelector(state => state.orcatec.property.currentProperty
  const companyId = useSelector(state => state.orcatec.company.id)
  const proposalFormId = useSelector(state => state.orcatec.proposalForm.id)
  const [currentPropertyId, setCurrentPropertyId] = useState('')
  const [isTabsOpen, setIsTabsOpen] = useState(false)
  const [isSectionOpen, setIsSectionOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isHaveChange, setIsHaveChange] = useState(false)
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false)
  const [propertyModal, setPropertyModal] = useState(false)
  const [propertyModalId, setPropertyModalId] = useState(null)
  const [clientModal, setClientModal] = useState(false)
  const [clientModalId, setClientModalId] = useState(null)
  const [clientType, setClientType] = useState('')
  const [blockedRouteParams, setBlockedRouteParams] = useState({
    blockRoutingModal: false,
    nextLocation: '',
  })
  const [isShowEmptyProperty, setIsShowEmptyProperty] = useState(false)

  useEffect(() => {
    if (isSectionOpen) {
      setIsLoading(true)
      dispatch(
        actions.proposalContact.getAllProposalProperties(proposalId, () =>
          setIsLoading(false),
        ),
      )
    } else {
      dispatch(actions.proposalContact.clearAttachedProposalProperties())
    }
  }, [isSectionOpen])

  useEffect(() => {
    setIsShowEmptyProperty(!!calculateQty() && isSectionOpen)
  }, [isSectionOpen, proposalForm])

  const handleTableItemDelete = propertyId => {
    const property = currentTableData.find(obj => obj.id === propertyId)

    const data = {
      property_id: propertyId,
      client_ids: property.clients.reduce(
        (acc, curr) => (curr.checkbox === true ? acc.concat(curr.id) : acc),
        [],
      ),
      organization_ids: property.organizations.reduce(
        (acc, curr) => (curr.checkbox === true ? acc.concat(curr.id) : acc),
        [],
      ),
    }

    dispatch(
      actions.proposalContact.detachProposalPropertyById(
        proposalId,
        data,
        handleAction,
      ),
    )
  }

  const handleAction = type => () => {
    if (type === 'save') {
      setIsHaveChange(false)
      if (blockedRouteParams.nextLocation) {
        history.push(blockedRouteParams.nextLocation)
      }
    }
    if (type === 'attach') setIsLoading(false)
    openNotification(type, 'Property')
    setIsButtonsDisabled(false)
  }

  const handleButtonClick = type => {
    switch (type) {
      case 'apply':
        setIsLoading(true)
        dispatch(
          actions.proposalContact.attachProposalPropertyById(
            proposalId,
            currentPropertyId,
            handleAction,
          ),
        )
        putProjectFWOSettings(
          proposalFormId,
          { customer: null, project: null, location: null },
          companyId,
        )
        // setOpenTab('')
        break
      case 'cancel':
        // setOpenTab('')
        break
      case 'save':
        setIsButtonsDisabled(true)
        dispatch(updateProposal())

        dispatch(
          actions.proposalContact.saveProposalProperty(
            proposalId,
            formatTableDataProperties(currentTableData),
            handleAction,
          ),
        )
        putProjectFWOSettings(
          proposalFormId,
          { customer: null, project: null, location: null },
          companyId,
        )
        break
      case 'cancel-save':
        setIsHaveChange(false)
        setIsLoading(true)
        dispatch(
          actions.proposalContact.getAllProposalProperties(proposalId, () =>
            setIsLoading(false),
          ),
        )
        break
      default:
        break
    }
  }

  const calculateQty = () => {
    return proposalForm && proposalForm.countProperties
      ? proposalForm.countProperties
      : 0
  }

  const openModal = (id, type, key) => {
    if (key === 'client') {
      if (type === 1) setClientType('organization')
      setClientModal(true)
      setClientModalId(id)
    }
    if (key === 'property') {
      setPropertyModal(true)
      setPropertyModalId(id)
    }
  }

  const clearClientStore = () => {
    dispatch(actions.organizations.removeCurrentOrganization())
    dispatch(actions.client.removeCurrentClient())
  }

  const clearPropertyStore = () => {
    dispatch(actions.property.setCurrentItem({}))
  }

  const handleModalClose = () => {
    setClientModal(false)
    setClientModalId(null)
    setPropertyModal(false)
    setPropertyModalId(null)
    setClientType('')
    clearClientStore()
    clearPropertyStore()
    dispatch(actions.proposalContact.clearAttachedProposalProperties())
    dispatch(
      actions.proposalContact.getAllProposalProperties(proposalId, () =>
        setIsLoading(false),
      ),
    )
  }

  const handleBlockedRoute = nextLocation => {
    setBlockedRouteParams({
      blockRoutingModal: true,
      nextLocation: nextLocation,
    })
    return false
  }

  const SaveButtons = style =>
    currentTableData && currentTableData?.length && isHaveChange ? (
      <Buttons
        handleButtonClick={handleButtonClick}
        firstBtn={{ action: 'cancel-save', title: 'Cancel' }}
        secondBtn={{ action: 'save', title: 'Save' }}
        style={style}
        buttonsDisabled={isButtonsDisabled}
      />
    ) : null

  /*  const handleSetEntityId = ({ id, entity_type }) => {
    setCurrentPropertyId(id)

    dispatch(actions.proposalContact.attachProposalPropertyById(proposalId, id, handleAction))
  } */

  const handleCloseModal = () => {
    // setOpenTab('')
    setCurrentPropertyId('')
  }

  return (
    <>
      <Modals
        handleClientModalClose={handleModalClose}
        handlePropertyModalClose={handleModalClose}
        clientModal={clientModal}
        propertyModal={propertyModal}
        clientModalId={clientModalId}
        clientType={clientType}
        propertyModalId={propertyModalId}
      />
      <Section
        title='Properties'
        qty={calculateQty()}
        isTabsOpen={openTab === 'Properties'}
        setIsTabsOpen={setIsTabsOpen}
        isSectionOpen={isSectionOpen}
        setIsSectionOpen={setIsSectionOpen}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        SaveButtons={SaveButtons}
        isHaveChange={isHaveChange}
        setIsHaveChange={setIsHaveChange}
        isShowEmpty={isShowEmptyProperty}
        // renderSelectTab={renderSelectTab}
      >
        {/* <Tabs isOpen={openTab === 'Properties'}> */}
        {/* {openTab === 'Properties' && (
          <Modal
            visible
            onCancel={handleCloseModal}
            onOk={() => handleButtonClick('apply')}
            title={<h4>Add new Property</h4>}
            className='client-modal'
            width='auto'
            maskClosable={false}
            style={{ top: 50 }}
            footer={null}
          >
            <PropertyForm
              isProposal
              propertyId={currentPropertyId}
              setEntityId={handleSetEntityId}
              onCloseForm={handleCloseModal}
            />
          </Modal>
        )} */}

        {currentTableData && currentTableData?.length > 0 && (
          <Table
            data={currentTableData}
            setTableData={handleTableDataChange(
              setCurrentTableData,
              setIsHaveChange,
            )}
            handleTableItemDelete={handleTableItemDelete}
            handleOpenItem={openModal}
            rowKeys={rowKeys}
            tableType='properties'
            freezed={freezed}
            updateTableData={() =>
              dispatch(
                actions.proposalContact.getAllProposalProperties(
                  proposalId,
                  () => setIsLoading(false),
                ),
              )
            }
            onOpenForm={onOpenForm}
          />
        )}
        <SaveButtons />
      </Section>
      <BlockRoutingModal
        status={!!blockedRouteParams.blockRoutingModal}
        handleCancel={() =>
          setBlockedRouteParams({ blockRoutingModal: false, nextLocation: '' })
        }
        handleDiscard={() => {
          history.push(blockedRouteParams.nextLocation)
        }}
        handleSave={() => handleButtonClick('save')}
        title='You have some unsaved changes.'
        buttonsTitle={{
          save: 'Save and continue',
          discard: 'Discard and continue',
          cancel: 'Cancel',
        }}
        isLoading={isButtonsDisabled}
      />
      <Prompt
        when={isHaveChange && !blockedRouteParams.blockRoutingModal}
        message={handleBlockedRoute}
      />{' '}
    </>
  )
}
