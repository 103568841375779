import React from 'react'

const Arrow = () => {
  return (
    <svg
      style={{ marginRight: '14px' }}
      width='8'
      height='6'
      viewBox='0 0 8 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.714844 1.5L4.00652 4.5L7.29819 1.5'
        stroke='#191919'
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default Arrow
