import styled from 'styled-components'
import { StatusGroup } from './components/StatusGroup'
import {
  IconCanceled,
  IconCold,
  IconCompleted,
  IconContract,
  IconEstimate,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { StatusForm } from './components/StatusForm'
import { useEffect, useState } from 'react'
import { ProjectStatus } from 'features/Project/types'
import { Popconfirm, Spin, Tooltip } from 'antd'
import { Button } from 'components/UIKit'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  changeProjectStatusAndDelete,
  createProjectStatus,
  deleteProjectStatus,
  getProjectStatuses,
  resetErrors,
  selectProjectStatusSlice,
  updateProjectStatus,
} from 'features/Project/slices/projectStatusSlice'
import { ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons'
import { StatusBadge } from './components/StatusBadge'
import { ProjectStatusDropdown } from 'features/Project/components/ProjectStatus/ProjectStatusDropdown'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

export const STATUS_INFO = {
  1: {
    title: 'Estimate',
    description:
      'This status is assigned to estimates or quotes awaiting client approval and allows for editing of all included information.',
    icon: <IconEstimate style={{ fontSize: 16 }} />,
  },
  2: {
    title: 'Contract',
    description:
      "This status is for projects approved by the client, where essential information can't be altered unless the client's approval is withdrawn.",
    icon: <IconContract style={{ fontSize: 16 }} />,
  },
  3: {
    title: 'Completed',
    description:
      'This status is assigned to projects that have been completed with all work done and payments received.',
    icon: <IconCompleted style={{ fontSize: 18 }} />,
  },
  4: {
    title: 'Cancelled',
    description:
      'This status is assigned to projects that have been cancelled, with essential information locked from editing.',
    icon: <IconCanceled style={{ fontSize: 16 }} />,
  },
  5: {
    title: 'Cold',
    description:
      'This status is assigned to projects put on hold or suspended indefinitely, where most of the information can be edited.',
    icon: <IconCold style={{ fontSize: 16 }} />,
  },
}

const initialStatus = {
  title: '',
  color: '#626ED4',
}

export const Statuses = () => {
  const dispatch = useAppDispatch()

  const { error, status, statusList } = useAppSelector(selectProjectStatusSlice)

  const [currentStatus, setCurrentStatus] = useState<ProjectStatus | null>(null)
  const [changeStatusModal, showChangeStatusModal] = useState(false)
  const [statusToChange, setStatusToChange] = useState<ProjectStatus | null>(
    null,
  )
  const [deletingError, setDeletingError] = useState(false)

  useEffect(() => {
    dispatch(getProjectStatuses())
  }, [])

  const handleOpenStatus = (status?: ProjectStatus) =>
    setCurrentStatus(
      status?.id ? status : ({ ...initialStatus, ...status } as ProjectStatus),
    )

  const handleStatusChange = e => {
    const { name, value } = e.target

    setCurrentStatus(
      prev =>
        ({
          ...prev,
          [name]: value,
        } as ProjectStatus),
    )

    if (error?.[name]) {
      dispatch(resetErrors([name]))
    }
  }

  const handleStatusDelete = async (status: ProjectStatus) => {
    const res = await dispatch(deleteProjectStatus(status.id))

    if (res.meta.requestStatus === 'rejected')
      return showChangeStatusModal(true)

    openNotificationWithIcon('success', {
      message: 'The status has been successfully removed',
    })

    setCurrentStatus(null)
  }

  const handleStatusSave = async () => {
    if (!currentStatus) return

    const res = await dispatch(
      currentStatus?.id
        ? updateProjectStatus(currentStatus)
        : createProjectStatus(currentStatus),
    )

    if (res.meta.requestStatus === 'rejected') return

    setCurrentStatus(null)
  }

  const handleStatusUpdateAndDelete = async () => {
    if (!currentStatus?.id) return

    if (!statusToChange?.id) return setDeletingError(true)

    const res = await dispatch(
      changeProjectStatusAndDelete({
        changed_status_id: statusToChange?.id,
        deleted_status_id: currentStatus?.id,
      }),
    )

    if (res.meta.requestStatus === 'rejected') {
      return openNotificationWithIcon('error', {
        message: error?.response?.data?.message || 'Something went wrong',
      })
    }

    openNotificationWithIcon('success', {
      message: 'The status has been successfully removed',
    })

    setStatusToChange(null)
    setCurrentStatus(null)
    showChangeStatusModal(false)
  }

  const onCancel = () => {
    setCurrentStatus(null)

    dispatch(resetErrors())
  }

  return (
    <Spin spinning={status === 'loading'}>
      <Wrapper>
        {Object.keys(STATUS_INFO).map(statusGroupId => (
          <StatusGroup
            key={statusGroupId}
            parentId={+statusGroupId}
            statusInfo={STATUS_INFO[statusGroupId as keyof typeof STATUS_INFO]}
            list={
              Object.keys(statusList).length
                ? statusList.ids
                    .map(id => statusList.data[id])
                    .filter(status => status.parent_status === +statusGroupId)
                : []
            }
            onClick={handleOpenStatus}
          />
        ))}

        {!!currentStatus && (
          <Modal
            title={currentStatus.id ? 'Update status' : 'Create status'}
            open
            onCancel={onCancel}
            footer={[
              <Footer key='buttons'>
                {!!currentStatus.id && (
                  <Popconfirm
                    title='Are you sure you want to delete this status?'
                    onConfirm={() => handleStatusDelete(currentStatus)}
                  >
                    <Tooltip
                      title={
                        currentStatus.is_default &&
                        'This status is default and cannot be deleted'
                      }
                    >
                      <span>
                        <Button
                          danger
                          loading={status === 'loading'}
                          disabled={currentStatus.is_default}
                          icon={<DeleteOutlined />}
                        >
                          Delete
                        </Button>
                      </span>
                    </Tooltip>
                  </Popconfirm>
                )}
                <Button style={{ marginLeft: 'auto' }} onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  onClick={handleStatusSave}
                  type='primary'
                  loading={status === 'loading'}
                >
                  Save
                </Button>
              </Footer>,
            ]}
            width={500}
          >
            <StatusForm
              data={currentStatus}
              onChange={handleStatusChange}
              error={error}
            />
          </Modal>
        )}

        {changeStatusModal && currentStatus && (
          <Modal
            open
            title='Delete status'
            onOk={handleStatusUpdateAndDelete}
            onCancel={() => {
              showChangeStatusModal(false)
              setStatusToChange(null)
            }}
            width={600}
            okText='Update and delete'
          >
            <p style={{ fontWeight: 500, marginBottom: 12 }}>{error?.id}</p>
            <p>Choose a different status for these projects.</p>

            <Row>
              <StatusBadge
                icon={STATUS_INFO[currentStatus?.parent_status].icon}
                status={currentStatus}
              />

              <ArrowRightOutlined style={{ position: 'relative', top: 9 }} />

              <ProjectStatusDropdown
                statusId={statusToChange?.id}
                statusList={statusList.ids
                  .map(id => statusList.data[id])
                  .filter(
                    status =>
                      status.parent_status === currentStatus.parent_status &&
                      status.id !== currentStatus.id,
                  )}
                onChange={status => {
                  setStatusToChange(status)

                  if (deletingError) setDeletingError(false)
                }}
                statusInfo={STATUS_INFO}
                error={!!deletingError && 'This field is required'}
              />
            </Row>
          </Modal>
        )}
      </Wrapper>
    </Spin>
  )
}

const Wrapper = styled.div``
const Row = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;
  margin-top: 16px;
`
const Footer = styled.div`
  display: flex;
`
