import { Popover, Tooltip } from 'antd'
import { Button } from 'components/UIKit'
import styled from 'styled-components'

interface ButtonsProps {
  disableSend: boolean
  cancelButtonText: string
  loading: boolean
  onSubmit: () => void
  onCancel: () => void
  onCopyLink: () => void
  onShowHistory: () => void
  onOpenEmailClient: () => void
}
export const Footer = ({
  cancelButtonText = 'Cancel',
  disableSend,
  loading,
  onSubmit,
  onCancel,
  onCopyLink,
  onShowHistory,
  onOpenEmailClient,
}: ButtonsProps) => {
  return (
    <Buttons>
      <Button
        onClick={() => {
          onCancel?.()
        }}
      >
        {cancelButtonText}
      </Button>

      {!!onShowHistory && (
        <Button
          style={{
            backgroundColor: 'rgba(52,159,166,.75)',
            borderColor: 'rgba(52, 159, 166, 0.75)',
          }}
          onClick={onShowHistory}
        >
          Show History
        </Button>
      )}

      {!!onCopyLink && (
        <Popover content={'Copied to clipboard!'} trigger={'click'}>
          <Button title={''} onClick={onCopyLink}>
            Copy Link
          </Button>
        </Popover>
      )}

      {!!onOpenEmailClient && (
        <Button onClick={onOpenEmailClient}>Open Email Client</Button>
      )}
      <Tooltip
        title={disableSend ? `Select at least one email or phone number` : ''}
        mouseLeaveDelay={0}
      >
        <div>
          <Button
            disabled={disableSend}
            loading={loading}
            onClick={onSubmit}
            type='primary'
          >
            Send
          </Button>
        </div>
      </Tooltip>
    </Buttons>
  )
}

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
