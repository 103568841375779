import React, { createContext, useEffect, useReducer } from 'react'
import { setDocument} from './actions'
import { initialState, mainStateReducer } from './reducer'
import {DocViewerRenderers} from '../plugins/index'


const DocViewerContext = createContext({ state: initialState, dispatch: () => null })

const DocProvider = ({documents,currentDocument,children,isModalVisible,...props}) => {


  const [state, dispatch] = useReducer(mainStateReducer, {
    ...initialState,
    pluginRenderers:DocViewerRenderers,
    ...props,
  })

  useEffect(() => {
    dispatch(setDocument(documents,currentDocument))
  }, [currentDocument,documents,isModalVisible])

  return <DocViewerContext.Provider value={{ state, dispatch }}>{children}</DocViewerContext.Provider>
}

export { DocViewerContext, DocProvider }
