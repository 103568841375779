import { useState } from 'react'
import { Modal } from 'react-bootstrap'
// import { connect } from 'react-redux'
// import * as actions from '../../../../../../store/Orcatec/actions'
import { Empty } from 'antd'
import Preloader from '../../../components/Preloader'
// import {
// getProposalClientActivity,
// cancelProposalActivityRequest,
// } from '../../../../../../api/Proposal'
import moment from 'moment-timezone'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { FULL_DATE_FORMAT } from 'constants/dateFormats'

const preloaderContainerStyle = { width: '100%', height: '131px' }
const ModalClientActivity = ({ activity, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false)

  // const onCancel = () => {
  //   toggleModal()
  //   cancelProposalActivityRequest()
  // }

  const renderActivity = () => (
    <div className='activity-list'>
      {activity?.length ? (
        [...activity]
          .sort((a, b) => (a.id > b.id ? -1 : 1))
          .map((item, idx) => (
            <p key={idx} className='activity-item'>
              <span>{item.ip}</span>
              <span>
                {moment
                  .utc(item.updated_at)
                  .local()
                  .format(FULL_DATE_FORMAT)}
              </span>
              <span>{item.full_name}</span>
            </p>
          ))
      ) : (
        <Empty description='No clients activity yet' />
      )}
    </div>
  )

  return (
    <Modal
      show
      onHide={onCancel}
      dialogClassName='clients-activity'
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title>History of project views</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading ? (
          renderActivity()
        ) : (
          <div
            className='d-flex justify-content-center align-items-center'
            style={preloaderContainerStyle}
          >
            <Preloader />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <MainButton title='Close' onClick={onCancel}>
          Close
        </MainButton>
      </Modal.Footer>
    </Modal>
  )
}

/* const mapStateToProps = state => ({
  show: state.orcatec.clientActivity.show,
  proposalId: state.orcatec.proposalForm.id,
})

const mapDispatchToProps = {
  toggleModal: actions.clientActivity.toggleModal,
} */

// export default connect(mapStateToProps, mapDispatchToProps)(ModalClientActivity)
export default ModalClientActivity
