export const getAddressWithUnit1 = objAddress => {
  let address = objAddress.address
  if (objAddress.unit) {
    address = objAddress.address.split(' ')
    if (address.length > 1) {
      /*  if (address.length === 2) { */
      address[0] = `${address[0]}-${objAddress.unit}`
      address[0] = `${address[0]} ${address[1]}`
      address.splice(1, 1)
      /* } */
      /*  if (address.length > 2) {
        address[1] = `${address[1]}-${objAddress.unit}`
        address[0] = `${address[0]} ${address[1]} ${address[2]}`
        address.splice(1, 2)
      } */
      address = address.join(' ')
    } else {
      address = `${objAddress.unit} ${address[0]}`
    }
    /* if (address.length > 1) {
      if (address.length === 2) {
        address[0] = `${address[0]}-${objAddress.unit}`
        address[0] = `${address[0]} ${address[1]}`
        address.splice(1, 1)
      }
      if (address.length > 2) {
        address[1] = `${address[1]}-${objAddress.unit}`
        address[0] = `${address[0]} ${address[1]} ${address[2]}`
        address.splice(1, 2)
      }
      address = address.join(', ')
    } else {
      address = `${objAddress.unit} ${address[0]}`
    } */
  }
  return address
}

export const getAddressWithUnit = objAddress => {
  let address = objAddress?.address || ''

  if (objAddress?.unit) {
    let unit = objAddress.unit.trim().split(' ')
    unit.length === 1
      ? (address = `${address}, Unit ${objAddress.unit.trim()}`)
      : (address = `${address}, ${objAddress.unit.trim()}`)
  }
  return address
}
