// export const persentageFillRequiredField = body => {
//   const allFormFileds = filteredComponents?.filter(({ required }) => required)?.length
//   const formFilled = Object.values(body)?.filter(({ required, value }) => value && required)?.length

//   return formFilled && allFormFileds ? +((formFilled * 100) / allFormFileds).toFixed(1) : 0
// }

// export const persentageFillAllField = body => {
//   const allFormFileds = Object.values(body)?.length
//   const formFilled = Object.values(body)?.filter(({ required, value }) => !!value)?.length

//   return formFilled && allFormFileds ? +((formFilled * 100) / allFormFileds).toFixed(1) : 0
// }

export const isTruthy = value => {
  if (Array.isArray(value)) {
    return value.length > 0
  }
  return !!value
}

export const persentageFillRequiredField = body => {
  const allFormFileds = Object.values(body)?.filter(({ required }) => required)
    ?.length
  const formFilled = Object.values(body)?.filter(
    ({ required, value }) => isTruthy(value) && required,
  )?.length

  return formFilled && allFormFileds
    ? +((formFilled * 100) / allFormFileds).toFixed(1)
    : 100
}

export const persentageFillAllField = body => {
  const componentTypesToIgnore = ['note', 'picture']
  const filteredComponents = Object.values(body)?.filter(
    component => !componentTypesToIgnore?.includes(component?.type),
  )

  const allFormFields = filteredComponents?.length
  const formFilled = filteredComponents?.filter(({ value }) => isTruthy(value))
    ?.length

  return formFilled && allFormFields
    ? +((formFilled * 100) / allFormFields)?.toFixed(1)
    : 0
}
