import React, { memo } from 'react'
import { Tooltip } from 'antd'
import { proposalStatuses } from '../../Proposals/helpers/proposalStatuses'
import { SmallPreloader } from '../Preloader/SmallPreloader'

import './ProposalStatus.scss'

const ProposalStatus = ({ statusId, withStatusName, disableToolTip, customName, isStatusChanging }) => {
  const statusName = customName ?? proposalStatuses.statuses[statusId]?.name
  return (
    <div className={'proposalStatus' + (withStatusName ? '' : ' justify-content-start')}>
      {!disableToolTip && !withStatusName ? (
        <Tooltip title={statusName}>
          <i className={proposalStatuses.statuses[statusId].icon} />
        </Tooltip>
      ) : (
        <>
          {isStatusChanging ? <SmallPreloader /> : <i className={proposalStatuses.statuses[statusId]?.icon} />}
          {withStatusName ? statusName : ''}
        </>
      )}
    </div>
  )
}

export default memo(ProposalStatus)
