import React, { useState, useEffect, useRef } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { DraggableIconBlack } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { Buttons } from '../Buttons'
import { useDispatch } from 'react-redux'
import * as actions from 'store/Orcatec/actions'
import Tooltip from 'antd/es/tooltip'
import client from 'store/Orcatec/reducers/client'
import { useAppSelector } from 'store/Orcatec/hooks'
import { putProjectFWOSettings } from 'api/Project'

export const ClientsSummary = ({
  freezed,
  openNotification,
  initialSummary: { clients },
}) => {
  const dispatch = useDispatch()
  const companyId = useAppSelector(state => state.orcatec.company.id)
  const proposalFormId = useAppSelector(state => state.orcatec.proposalForm.id)

  const [summaryData, setSummaryData] = useState([])
  const [initialValue, setInitialValue] = useState(null)
  const [primaryElemHeight, setPrimaryElemHeight] = useState(0)
  const [hasChange, setHasChange] = useState(false)
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false)
  const originalValue = useRef()

  useEffect(() => {
    if (freezed) {
      setSummaryData(initialValue)
      setHasChange(false)
    }
  }, [freezed])

  useEffect(() => {
    setPrimaryElemHeight(calcPrimaryElemHeight())
  })

  useEffect(() => {
    window.addEventListener('resize', resizeListener, false)
    return () => {
      window.removeEventListener('resize', resizeListener, false)
    }
  }, [document.body.clientHeight])

  useEffect(() => {
    setSummaryData(clients)

    if (!originalValue.current && clients) {
      setInitialValue(clients)
      originalValue.current = JSON.stringify(clients)
      dispatch(
        actions.proposalContact.setProposalClientsPosition(
          formatRequestData(clients),
        ),
      )
    }

    if (
      originalValue.current &&
      originalValue.current !== JSON.stringify(clients)
    ) {
      setInitialValue(clients)
      clients &&
        dispatch(
          actions.proposalContact.setProposalClientsPosition(
            formatRequestData(clients),
          ),
        )
    }
  }, [clients])

  const calcPrimaryElemHeight = () => {
    const el = document.getElementById(
      'proposal-contact-summary-primary-client',
    )
    return el ? el.offsetHeight + 10 : 0
  }

  const resizeListener = () => {
    setPrimaryElemHeight(calcPrimaryElemHeight())
  }

  const handleMoveEnd = () => {
    setHasChange(true)
    setPrimaryElemHeight(calcPrimaryElemHeight())
  }

  function formatRequestData(clients) {
    return {
      data:
        clients &&
        clients.map((el, idx) => ({
          client_relation_id: el.id,
          position: idx + 1,
          is_primary: !!(idx === 0),
        })),
    }
  }

  const handleSave = () => {
    setInitialValue(summaryData)

    setHasChange(false)
    openNotification('summary-save', 'address')
    setIsButtonsDisabled(false)
  }

  const handleButtonClick = type => {
    switch (type) {
      case 'save':
        setIsButtonsDisabled(true)
        dispatch(
          actions.proposalContact.setProposalClientsPosition(
            formatRequestData(summaryData),
            handleSave,
          ),
        )
        putProjectFWOSettings(
          proposalFormId,

          { customer: null, project: null, location: null },
          companyId,
        )
        break
      case 'cancel-save':
        setSummaryData(initialValue)
        setHasChange(false)
        break
      default:
        break
    }
  }

  const render = () =>
    summaryData.map((el, idx) => (
      <div
        className='proposal-contact-summary__item'
        key={idx}
        id={idx === 0 ? `proposal-contact-summary-primary-client` : null}
      >
        {freezed ? null : (
          <span className='proposal-contact-summary__item-icon'>
            <DraggableIconBlack />
          </span>
        )}
        <p className='proposal-contact-summary'>
          <b>{el.name}</b>
          {el.relation ? `, ${el.relation}` : ''}
        </p>
        {el?.emails?.length ? (
          <div className='proposal-contact-summary__contacts'>
            <div className='proposal-contact-summary__contacts-type'>
              {el?.emails?.length > 1 ? 'Emails' : 'Email'}:
            </div>
            <div className='proposal-contact-summary__contacts-value'>
              {el?.emails.map((el, idx) => (
                <Tooltip title={el.email} key={idx}>
                  <p>{el.email}</p>
                </Tooltip>
              ))}
            </div>
          </div>
        ) : null}
        {el?.phones?.length
          ? el.phones.map((el, idx) => (
              <div className='proposal-contact-summary__contacts' key={idx}>
                <div className='proposal-contact-summary__contacts-type'>
                  {el.type}:
                </div>
                <div className='proposal-contact-summary__contacts-value'>
                  <p>{el.phone}</p>
                </div>
              </div>
            ))
          : null}
      </div>
    ))

  return summaryData && summaryData?.length ? (
    <section className='proposal-contact-summary__section'>
      <div
        className='proposal-contact-summary__section-primary'
        style={{ height: primaryElemHeight }}
      />
      <div className='proposal-contact-summary__section-title'>
        Primary Client
      </div>
      {freezed ? (
        <div className='proposal-contact-summary__content'>{render()}</div>
      ) : (
        <ReactSortable
          list={summaryData}
          setList={setSummaryData}
          onEnd={handleMoveEnd}
          className='proposal-contact-summary__content'
          handle='.proposal-contact-summary__item-icon'
        >
          {render()}
        </ReactSortable>
      )}
      {hasChange && (
        <div className='proposal-contact-summary__section'>
          <Buttons
            handleButtonClick={handleButtonClick}
            firstBtn={{ action: 'cancel-save' }}
            secondBtn={{ action: 'save', title: 'Save' }}
            buttonsDisabled={isButtonsDisabled}
          />
        </div>
      )}
    </section>
  ) : null
}
