import React, { useState, useEffect } from 'react'
import {
  fetchProperty,
  searchPropertyByAddress,
  updatePropertyRelation,
} from '../../../../../../../../api/Property'
import TableActions from '../../../../../components/TableActionsNew'
import AddressInput from '../../../../../components/AddressInput'
import './index.scss'
// import PropertyForm from '../../../../../Properties/components/Form/PropertyForm'
import { Empty, Tooltip } from 'antd'
import { getAddressWithUnit } from '../../../../../../../../helpers/getAddressWithUnit'
import ToggleButton from '../../../../../components/buttons/ToggleButton/ToggleButton'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import PropertyForm from 'containers/MainContent/Orcatec/Properties/components/PropertyForm'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { Link } from 'react-router-dom'
import { renderNotes } from '../utils'
import { WithModal } from 'hoc/WithModal'
const initialFieldValues = {
  relation: '',
  choosenValue: null,
}

const stopPropagation = e => {
  if (!!e && !!e.stopPropagation) e.stopPropagation()
}

const TableProperties = ({
  properties,
  onAddProperty,
  onAddAttachedProperty,
  onDellAttachedProperty,
  clientId,
  newRow,
  showNewRow,
  onUpdateRelation,
  setWasEdited,
  withModal = false,
  isAppointment,
}) => {
  // const [newRow, showNewRow] = useState(false)
  const [fieldValues, setFieldValues] = useState(initialFieldValues)
  const [showPropertiesTable, setShowPropertiesTable] = useState(false)
  const onChange = e => {
    const { name, value } = e.target
    setWasEdited(true)
    setFieldValues(prev => ({ ...prev, [name]: value }))
  }

  const onAdd = async entity => {
    try {
      setWasEdited(false)
      showNewRow(false)
      const data = await fetchProperty(entity?.id)

      const relation = fieldValues.relation
        ? fieldValues.relation
        : data.relationValue
        ? data.relationValue
        : ''
      onAddAttachedProperty({
        address: data.full_address
          ? data.full_address
          : `${getAddressWithUnit(data)}, ${data.postcode}`,
        note: relation,
        relation_note: relation,
        property_relation: relation,
        client_id: clientId,
        property_id: data.id, //fieldValues.choosenValue.property_id,
        fullProperty: data,
      })

      setFieldValues({ relation: '', choosenValue: null })
    } catch (error) {
      showNewRow(true)
    }
  }

  const onCancel = () => {
    showNewRow(false)
    setFieldValues({ relation: '', choosenValue: null })
    setWasEdited(false)
  }
  /* const onChoose = obj => {
    setFieldValues({ choosenValue: obj })
  } */

  const closePropertyForm = obj => {
    showNewRow(false)
    setWasEdited(false)
    /* if (!obj) {
      onCancel()
      return
    }
    onAddProperty({
      address: obj.address,
      note: fieldValues.relation,
      client_id: clientId,
      property_id: obj.property_id,
    })
    onCancel() */
  }

  const toggleNewRow = () => {
    setFieldValues(initialFieldValues)
    showNewRow(prev => !prev)
    setShowPropertiesTable(prev => (!newRow ? true : prev))
  }

  const goToProperty = id => () => {
    if (id) window.open(`/properties-form/${id}`, '_blank')
  }
  const getPopupContainer = () =>
    document.getElementsByClassName('formClientWrapper')[0]

  /*  const togglePropertiesTable = () => {
    setFieldValues(initialFieldValues)
    showNewRow(false)
    setShowPropertiesTable(!showPropertiesTable)
  }
 */

  const handleUpdatePropertyRelation = async (e, item) => {
    if (e.target.value === item?.property_relation) return

    console.log(item)

    await updatePropertyRelation({
      relation_id: item.relation_id,
      property_relation: e.target.value,
    })
    onUpdateRelation()

    openNotificationWithIcon('success', {
      message: 'Property relation has been updated',
    })
  }
  return (
    <div className='client-properties client-form-table-container'>
      {/* <h5>
        <p>{properties.length}</p>
        Attached Properties
        <span className={`mdi ${newRow ? 'mdi-minus' : 'mdi-plus'} iconSpan-first`} onClick={toggleNewRow} />
        <ToggleButton show={showPropertiesTable} toggle={togglePropertiesTable} />
      </h5> */}
      {/* {showPropertiesTable && <> */}
      {/* <PropertyForm
      closeForm={closePropertyForm}
      onChangeRelation={onChange}
      relationValue={fieldValues.relation}
      isClientForm
      onAddAttachedProperty={onAdd}
      /> */}
      {newRow && (
        <WithModal
          toggleModal={closePropertyForm}
          withModal={isAppointment}
          showModal={newRow}
          title='Attach new property'
        >
          <div className='attach-new-property'>
            <InputField
              value={fieldValues.relation}
              onChange={e => {
                setFieldValues(prev => ({ ...prev, relation: e.target.value }))
                setWasEdited(true)
              }}
              className='relation-input'
              label='Property Relation'
            />
            <PropertyForm
              isClient
              setEntityId={onAdd}
              onCloseForm={closePropertyForm}
              createOnButtonClick
            />
            {/* <MainButton type='cancel' title='Cancel' onClick={closePropertyForm} /> */}
          </div>
        </WithModal>
      )}
      {properties.length ? (
        <table className='client-form-table'>
          <thead>
            <tr>
              <th>Address</th>
              <th>Type</th>
              <th>Relation</th>
              <th style={{ textAlign: 'center' }}>Clients</th>
              <th style={{ textAlign: 'center' }}>Appointments</th>
              <th style={{ textAlign: 'center' }}>Projects</th>
              <th style={{ textAlign: 'left' }}>Notes</th>
              <th style={{ textAlign: 'center' }}>Tags</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {properties.map((item, idx) => {
              const getValue = name =>
                !!item.value && !!item.value[name]
                  ? item.value[name]
                  : item[name]
                  ? item[name]
                  : ''
              const property = getValue('fullProperty')
                ? getValue('fullProperty')
                : item.value
                ? item.value
                : item
              const address = `${getAddressWithUnit(property)}, ${
                property.postcode
              }`
              {
                /* const relation = getValue('relation_note')
                ? getValue('relation_note')
                : getValue('relation')
                ? getValue('relation')
                : getValue('note')
                ? getValue('note')
                : getValue('property_relation')
                ? getValue('property_relation')
                : '' */
              }
              return (
                <tr key={idx}>
                  <Tooltip title={address}>
                    <td>
                      <Link
                        to={`/properties-form/${getValue('property_id')}`}
                        target='_blank'
                        style={{ textDecoration: 'underline' }}
                      >
                        {address}
                      </Link>
                    </td>
                  </Tooltip>
                  <td>
                    {getValue('type') === 1 ? 'Commercial' : 'Residential'}
                  </td>
                  <td>
                    <InputField
                      defaultValue={getValue('property_relation')}
                      onClick={e => e.stopPropagation()}
                      onBlur={e => handleUpdatePropertyRelation(e, item.value)}
                      fullWidth={false}
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {(property.clients || []).length}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {(property.appointments || []).length}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {(property.proposals || []).length}
                  </td>
                  <Tooltip
                    overlayStyle={{ whiteSpace: 'pre-line' }}
                    title={property?.notes?.map?.(n => n.text).join?.('\n\n')}
                  >
                    {/*<td className='notes'>{property.notes || ''}</td>*/}
                    <td className='notes'>{renderNotes(property.notes)}</td>
                  </Tooltip>
                  <td style={{ textAlign: 'center' }}>
                    {(property.tags || []).length}
                  </td>
                  <td onClick={stopPropagation}>
                    <TableActions
                      todos={['detach']}
                      popConfirms={['property from this client']}
                      tooltips={['Detach property']}
                      callbacks={[
                        () => {
                          onDellAttachedProperty(idx)
                        },
                      ]}
                      getPopupContainer={getPopupContainer}
                      popConfirmPositions={['left']}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        !newRow && (
          <>
            <Empty description='No attached properties yet' />
            <MainButton
              title='Add Property'
              className='add-property-button'
              onClick={() => showNewRow(prev => !prev)}
            />
          </>
        )
      )}
      {/* </>} */}
    </div>
  )
}

export default TableProperties

/* const head = [
  {
    label: 'Proposal ID',
  },
  {
    label: 'Status',
  },
  {
    label: 'Name',
  },
  {
    label: 'Tech',
  },
  {
    label: 'Total',
  },
  {
    label: 'Notes',
  },
]

return (properties && properties.length) ? (
  <Table
    rows={props.properties}
    search={false}
    paggination={false}
    showEntries={false}
    head={head}
    className='proposals-table'
  >
    {props.proposals.map((item, idx) => (
      <tr key={item.id} onClick={e => {
        e.stopPropagation()
        if (props.me.permissions.proposals) window.open(`/projects/${item.id}`, '_blank')
      }}
      style={props.me.permissions.proposals ? { cursor: 'pointer' } : {}}>
        <td>{item.code}</td>
        <td><div
        type='button'
        className={`btn waves-effect waves-light btn-sm`}
        style={proposalStatuses.statuses[item.status].style}
      >
        {proposalStatuses.statuses[item.status].name}
      </div></td>
        <td>{!!item.user_with_deleted && !!item.user_with_deleted.full_name ? item.user_with_deleted.full_name : '' }</td>
        <td>No info</td>
        <td>{item.total}</td>
        <td>{item.notes}</td>
      </tr>
    ))}
  </Table>
) : (
  <Empty description='No proposals yet' />
)
} */
