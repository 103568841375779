import axiosOrcatec from './axiosInstance'

export const getReviewsList = params => {
  return axiosOrcatec
    .get('reviews/events', { params })
    .then(response => response.data)
}

export const getFeedbacksList = params => {
  return axiosOrcatec
    .get('reviews/requests', { params })
    .then(response => response.data)
}

export const list = (
  page = 1,
  order_by = 'feedback_date',
  order = 'asc',
  search = '',
  cancelToken = null,
  per_page = 20,
) => {
  const config = {
    params: {
      page,
      order_by,
      order,
      search,
      per_page,
    },
  }

  if (cancelToken) {
    config.cancelToken = cancelToken
  }

  return axiosOrcatec.get('reviews', config).then(response => response.data)
}

export const companyInfo = token => {
  return axiosOrcatec
    .get('reviews/feedback/company-info', {
      params: {
        _rrt: token,
      },
    })
    .then(response => response.data)
}

export const sendFeedback = (token, vote, type) => {
  return axiosOrcatec.post('reviews/feedback', {
    _rrt: token,
    vote,
    type,
  })
}

export const updateFeedback = data => {
  return axiosOrcatec.patch('reviews/feedback', data)
}

export const getFeedbacksListByEventId = eventId => {
  return axiosOrcatec.get(`reviews/events/${eventId}`).then(data => data.data)
}
