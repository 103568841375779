import styled from 'styled-components'
import { Tooltip } from 'antd'

interface Props {
  children: React.ReactNode
  hideCost: boolean
  hidePrice: boolean
}

export const ItemList = ({ children, hidePrice, hideCost }: Props) => {
  return (
    <Wrapper>
      <TableHeading>
        <p></p>
        <p>Name</p>

        <Tooltip mouseLeaveDelay={0} title='Material'>
          <p>MATL</p>
        </Tooltip>
        <Tooltip mouseLeaveDelay={0} title='Taxable'>
          <p>TAX</p>
        </Tooltip>
        <Tooltip mouseLeaveDelay={0} title='Cost'>
          {!hideCost && <p>Cost</p>}
        </Tooltip>
        <Tooltip mouseLeaveDelay={0} title='Gross margin'>
          {!hideCost && <p>Margin</p>}
        </Tooltip>
        <Tooltip mouseLeaveDelay={0} title='Retail price'>
          {!hidePrice && <p>Price </p>}
        </Tooltip>

        <Tooltip mouseLeaveDelay={0} title='Qty'>
          <p>Qty</p>
        </Tooltip>

        <Tooltip mouseLeaveDelay={0} title='Unit'>
          <p>Unit</p>
        </Tooltip>
        <Tooltip mouseLeaveDelay={0} title='Total'>
          {!hidePrice && <p>Total </p>}
        </Tooltip>

        <Tooltip mouseLeaveDelay={0} title='Actions'>
          <p>Actions</p>
        </Tooltip>
      </TableHeading>

      <List>{children}</List>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const List = styled.div`
  & > *:not(:last-child) {
    border-bottom: 1px solid;
    border-color: #d4d4d4;
  }
`

const TableHeading = styled.p`
  display: grid;
  grid-template-columns: 40px 1fr 50px 50px 80px 80px 80px 50px 50px 80px 60px;
  gap: 8px;
  background-color: #fafafa;
  font-weight: 500;
  padding: 8px 12px;
  color: gray;

  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-right: 1px solid #ededed;
    padding: 0 3px;
  }
`
