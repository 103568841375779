export enum ProjectPermissions {
  //main
  PROJECT_CAN_READ = 'project_can_read',
  PROJECT_CAN_DUPLICATE = 'project_can_duplicate',
  PROJECT_CAN_DELETE = 'project_can_delete',
  PROJECT_ACCESS_TO_CONTRACT = 'project_access_to_contract',

  //retail price
  PROJECT_CAN_READ_RETAIL_PRICE = 'project_can_read_retail_price',
  PROJECT_CAN_EDIT_RETAIL_PRICE = 'project_can_edit_retail_price',
  //cost price
  PROJECT_CAN_READ_COST_PRICE = 'project_can_read_cost_price',
  PROJECT_CAN_EDIT_COST_PRICE = 'project_can_edit_cost_price',

  PROJECT_CAN_READ_PRICE = 'project_can_read_price',
  PROJECT_CAN_READ_COST = 'project_can_read_cost',
  //related contact info
  PROJECT_CAN_READ_RELATED_CONTACTS_INFO = 'project_can_read_related_contacts_info',
  PROJECT_CAN_ATTACH_RELATED_CONTACTS = 'project_can_attach_related_contacts',
  //contact info
  PROJECT_CAN_READ_ATTACHED_CONTACTS = 'project_can_read_attached_contacts',
  PROJECT_CAN_READ_ATTACHED_CLIENT_NAME = 'project_can_read_attached_client_name',
  PROJECT_CAN_READ_ATTACHED_CLIENT_INFO = 'project_can_read_attached_client_info',
  //Information from Customer
  PROJECT_CAN_READ_INFO_FROM_CLIENT = 'project_can_read_info_from_client',
  PROJECT_CAN_ADD_INFO_FROM_CLIENT = 'project_can_add_info_from_client',
  PROJECT_CAN_EDIT_INFO_FROM_CLIENT = 'project_can_edit_info_from_client',
  PROJECT_CAN_DELETE_INFO_FROM_CLIENT = 'project_can_delete_info_from_client',
  //Information for Customer
  PROJECT_CAN_READ_INFO_FOR_CLIENT = 'project_can_read_info_for_client',
  PROJECT_CAN_ADD_INFO_FOR_CLIENT = 'project_can_add_info_for_client',
  PROJECT_CAN_EDIT_INFO_FOR_CLIENT = 'project_can_edit_info_for_client',
  PROJECT_CAN_DELETE_INFO_FOR_CLIENT = 'project_can_delete_info_for_client',

  //Information for Workers
  PROJECT_CAN_READ_INFO_FOR_WORKERS = 'project_can_read_info_for_workers',
  PROJECT_CAN_EDIT_INFO_FOR_WORKERS = 'project_can_edit_info_for_workers',
  PROJECT_CAN_DELETE_INFO_FOR_WORKERS = 'project_can_delete_info_for_workers',

  //status
  PROJECT_CAN_CHANGE_STATUS = 'project_can_change_status',
  PROJECT_CAN_CHANGE_STATUS_FROM_ESTIMATE_TO_CONTRACT = 'project_can_change_status_from_estimate_to_contract',
  PROJECT_CAN_CHANGE_STATUS_FROM_CONTRACT_TO_COMPLETED = 'project_can_change_status_from_contract_to_completed',
  PROJECT_CAN_CHANGE_STATUS_FROM_COMPLETED_TO_CONTRACT = 'project_can_change_status_from_completed_to_contract',
  PROJECT_CAN_CHANGE_STATUS_FROM_CONTRACT_TO_ESTIMATE = 'project_can_change_status_from_contract_to_estimate',
  PROJECT_CAN_CHANGE_STATUS_FROM_CONTRACT_TO_CANCELED = 'project_can_change_status_from_contract_to_canceled',

  //Payment
  PROJECT_CAN_READ_PAYMENT = 'project_can_read_payment',
  PROJECT_CAN_ADD_PAYMENT = 'project_can_add_payment',
  PROJECT_CAN_EDIT_PAYMENT = 'project_can_edit_payment',
  PROJECT_CAN_DELETE_PAYMENT = 'project_can_delete_payment',
  PROJECT_CAN_EDIT_PAYMENT_BALANCE_STATUS = 'project_can_edit_payment_balance_status',

  //Items
  PROJECT_CAN_ADD_ITEM_FROM_PRICE_PAGES = 'project_can_add_item_from_price_pages',
  PROJECT_CAN_CREATE_CUSTOM_ITEM = 'project_can_create_custom_item',
  PROJECT_CAN_SAVE_CUSTOM_ITEM_TO_PRICE_PAGES = 'project_can_save_custom_item_to_price_pages',
  PROJECT_CAN_EDIT_ITEM_NAME = 'project_can_edit_item_name',
  PROJECT_CAN_CREATE_DELETE_UNIT_QTY = 'project_can_create_delete_unit_qty',
  PROJECT_CAN_VIEW_ITEM_DESCRIPTON = 'project_can_view_item_description',

  //Insight

  PROJECT_INSIGHTS_CAN_READ = 'project_insights_can_read',
  PROJECT_INSIGHTS_CAN_VIEW_EXPENSES = 'project_insights_can_view_expenses',
  PROJECT_INSIGHTS_CAN_ADD_EXPENSES = 'project_insights_can_add_expenses',
  PROJECT_INSIGHTS_CAN_EDIT_EXPENSES = 'project_insights_can_edit_expenses',
  PROJECT_INSIGHTS_CAN_DELETE_EXPENSES = 'project_insights_can_delete_expenses',
  PROJECT_INSIGHTS_CAN_READ_PAYOUTS = 'project_insights_can_read_payouts',
  PROJECT_INSIGHTS_CAN_ADD_PAYOUTS = 'project_insights_can_add_payouts',
  PROJECT_INSIGHTS_CAN_EDIT_PAYOUTS = 'project_insights_can_edit_payouts',
  PROJECT_INSIGHTS_CAN_DELETE_PAYOUTS = 'project_insights_can_delete_payouts',
  PROJECT_INSIGHTS_CAN_EDIT_TIPS = 'project_insights_can_edit_tips',
  PROJECT_INSIGHTS_CAN_CHANGE_LABOR_RATES = 'project_insights_can_change_labor_rates',
  PROJECT_INSIGHTS_ITEMS_SUGGESTIONS_CAN_ADD = 'project_insights_items_suggestion_can_add',
  PROJECT_INSIGHTS_ITEMS_SUGGESTIONS_CAN_DELETE = 'project_insights_items_suggestion_can_delete',

  //settings
  PROJECT_SETTINGS_CAN_READ = 'project_settings_can_read',
  PROJECT_SETTINGS_CAN_EDIT_GENERAL = 'project_settings_can_edit_general',
  PROJECT_SETTINGS_CAN_EDIT_SECTION = 'project_settings_can_edit_section',
  PROJECT_SETTINGS_CAN_EDIT_PAYMENT = 'project_settings_can_edit_payment',
  PROJECT_SETTINGS_CAN_EDIT_RELATIONS = 'project_settings_can_edit_relation',
  PROJECT_SETTINGS_CAN_EDIT_CUSTOMER_VIEW = 'project_settings_can_edit_customer_view',
  //notification
  PROJECT_NOTIFICATION_CAN_SEND = 'project_notification_can_send',
  PROJECT_NOTIFICATION_CAN_CHANGE_TEXT = 'project_notification_can_change_text',
  PROJECT_NOTIFICATION_CAN_CHANGE_TEMPLATE = 'project_notification_can_change_template',
  PROJECT_NOTIFICATION_CAN_INCLUDE_ATTACHMENTS = 'project_notification_can_include_attachments',

  PROJECT_CAN_CREATE = 'project_can_create',
  PROJECT_CAN_CHANGE_CODE = 'project_can_change_code',
  PROJECT_CAN_EDIT_CONTRACT = 'project_can_edit_contract',
  PROJECT_CAN_READ_TEMPLATES = 'project_can_read_templates',
  PROJECT_CAN_EDIT_TEMPLATE = 'project_can_edit_template',
  PROJECT_CAN_DELETE_TEMPLATE = 'project_can_delete_template',
  PROJECT_CAN_CREATE_TEMPLATE = 'project_can_create_template',
  PROJECT_INSIGHTS_ITEMS_CAN_ADD = 'project_insights_items_can_add',
  PROJECT_INSIGHTS_ITEMS_CAN_EDIT = 'project_insights_items_can_edit',
  PROJECT_INSIGHTS_ITEMS_CAN_DELETE = 'project_insights_items_can_delete',

  PROJECT_CAN_CREATE_CUSTOM_FORMS = 'project_can_create_custom_forms',
  PROJECT_CAN_READ_CUSTOM_FORMS = 'project_can_read_custom_forms',
  PROJECT_CAN_EDIT_CUSTOM_FORMS = 'project_can_edit_custom_forms',
  PROJECT_CAN_DELETE_CUSTOM_FORMS = 'project_can_delete_custom_forms',

  PROJECT_CAN_DELETE_PROGRESS_ENTRY = 'project_can_delete_progress_entry',

  PROJECT_CAN_READ_TECH_LIST_FOR_PROGRESS = 'project_can_read_tech_list_for_progress',

  //custom module

  PROJECT_PAYMENT_MODULE_CAN_CHANGE_STATUS = 'project_payment_module_can_change_status',
  PROJECT_SUPPLY_MODULE_CAN_CHANGE_STATUS = 'project_supply_module_can_change_status',
  PROJECT_CUSTOM_MODULE_1_CAN_CHANGE_STATUS = 'project_custom_module_1_can_change_status',
  PROJECT_CUSTOM_MODULE_2_CAN_CHANGE_STATUS = 'project_custom_module_2_can_change_status',
  PROJECT_CUSTOM_MODULE_3_CAN_CHANGE_STATUS = 'project_custom_module_3_can_change_status',
}
