import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { apiNotes } from '../../../../../api/Notes'
import moment from 'moment-timezone'
import { useSelector, useDispatch } from 'react-redux'
import { AppStateType } from '../../../../../store'
import { Button, Input, Popconfirm, Tooltip, Card } from 'antd'
import { formatTimeByMoment } from './helpers'
import InfoIcon from '@material-ui/icons/Info'
import { wasEditField } from '../../../../../store/Orcatec/actions/ui/modals/modals'
import { INote, INotesProps } from './types'
import { ModuleName, NotesPermissions } from 'features/Settings/UsersAndGroups'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import { ModalFilesUpload } from 'components/UI/ModalFIlesUpload/ModalFilesUpload'
import './Notes.scss'
import { useAppSelector } from 'store/Orcatec/hooks'
import { Skeleton } from '@material-ui/lab'
import {
  DeleteOutlined,
  EditOutlined,
  PaperClipOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'
import { Media } from 'types/Media'
import DocViewer from 'helpers/viewFIlesNew/ViewDoc'
import { ViewFile } from 'helpers/viewFile/viewFile'
import useOnClickOutside from 'hooks/useOnClickOutside'
import ReactHtmlParser from 'react-html-parser'

const { TextArea } = Input

const imageSize = 40

const gridStyle: React.CSSProperties = {
  textAlign: 'left',
  padding: '0px',
  boxShadow: 'none',
  width: '40px',
}

const ERROR_MESSAGE = 'Please add text or attachments.'

const addLinksToText = text => {
  const pattern = /(?:https?:\/\/|www\.)\S+/g

  const replaceFunc = function(match) {
    // const linkText = 'Link'

    return '<a href="' + match + ' "target="_blank">' + match + '</a>'
  }

  const newText = text.replace(pattern, replaceFunc)

  return newText
}

const Notes: React.FC<INotesProps> = props => {
  const {
    route = '',
    forPrint = false,
    setEdited,
    label = '',
    entity,
    disabled,
  } = props

  const ref = useRef()

  const dispatch = useDispatch()
  const user = useSelector((state: AppStateType) => state?.orcatec?.user?.me)

  const [showAll, setShowAll] = useState(false)
  const [notes, setNotes] = useState<INote[]>([])
  const [currentNote, setCurrentNote] = useState('')
  const [editedNote, setEditedNote] = useState({
    id: 0,
    value: '',
    isEdit: false,
    attachments: [],
  })
  const [editAttachment, setEditAttachment] = useState<INote>(null)
  const [attachments, setAttachments] = useState<Media[]>([])
  const [isFetching, setIsFetching] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditedItemFetching, setIsEditedItemFetching] = useState(false)
  const [showNoteInput, setShowNoteInput] = useState(false)
  const [expandedNotes, setExpandedNotes] = useState<number[]>([])
  const [filesUploadModal, setFilesUploadModal] = useState(false)
  const [viewerModal, setViewerModal] = useState<{
    showDocViewerModal: boolean
    documents: Media[]
    currentFileIdx: number | null
  }>({
    showDocViewerModal: false,
    documents: [],
    currentFileIdx: null,
  })
  const [error, setError] = useState('')

  const toggleOpenDocViewerModal = () => {
    setViewerModal(prev => ({
      ...prev,
      showDocViewerModal: !prev.showDocViewerModal,
    }))
  }

  const getFileForModalDocViewer = (files: Media[], currentFileIdx: number) => {
    setViewerModal({
      documents: files,
      currentFileIdx,
      showDocViewerModal: true,
    })
  }

  const handleCancel = () => {
    setFilesUploadModal(false)
    setEditAttachment(null)
  }

  const handleOpenFilesUpload = (item: INote) => {
    setFilesUploadModal(true)
    setEditAttachment(item)
  }

  const handeFilesUpload = async (files: Media[]) => {
    if (!editAttachment?.id && !!files?.length) {
      setFilesUploadModal(false)
      setEditAttachment(null)
      setError('')
      return setAttachments(files)
    }
    if (!files?.length) {
      setFilesUploadModal(false)
      setEditAttachment(null)
      return
    }

    setNotes(prev =>
      prev.map(note =>
        note?.id === editAttachment?.id
          ? { ...note, attachments: files }
          : note,
      ),
    )

    await apiNotes.uploadNoteMedia(route, editAttachment?.id, files)
    setFilesUploadModal(false)
    setEditAttachment(null)
  }

  const handleDeleteFile = async index => {
    const attachmentToDelete = editAttachment?.attachments[index]

    if (!editAttachment?.id) {
      setAttachments(prev => prev?.filter((_, i) => i !== index))
      return
    }

    if (!attachmentToDelete) {
      console.error('Attachment not found')
      return
    }

    try {
      await apiNotes.deleteNoteMedia(
        route,
        editAttachment.id,
        attachmentToDelete.id,
      )

      setNotes(prevNotes => {
        return prevNotes.map(note => {
          if (note?.id === editAttachment?.id) {
            const updatedAttachments = note.attachments?.filter(
              (attachment, i) => i !== index,
            )
            return { ...note, attachments: updatedAttachments }
          }
          return note
        })
      })
      if (editedNote?.id) {
        setEditedNote(prevNotes => {
          if (prevNotes?.id === editAttachment?.id) {
            const updatedAttachments = prevNotes?.attachments?.filter(
              (attachment, i) => i !== index,
            )
            return { ...prevNotes, attachments: updatedAttachments }
          }
          return prevNotes
        })
      }
    } catch (error) {
      console.error('Error deleting file:', error)
    }
  }

  const canRead = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.NOTES,
      NotesPermissions.NOTES_CAN_READ,
    ),
  )

  useEffect(() => {
    if (!!canRead) {
      setIsFetching(true)
      apiNotes
        .getEntityNotes(route)
        .then(r => setNotes(r))
        .catch(e => console.error(e))
        .finally(() => setIsFetching(false))
    }
  }, [route])

  const checkEditField = () => dispatch(wasEditField(false))

  const createNote = useCallback(
    (text: string, attachments: Media[]) => {
      setLoading(true)

      apiNotes
        .postEntityNote(route, { text, attachments })
        .then(r => setNotes(p => [r, ...p]))
        .then(() => {
          setCurrentNote('')
          setAttachments([])
          setShowNoteInput(false)
          checkEditField()
          setEdited?.(true)
        })
        .catch(e => console.error(e))
        .finally(() => setLoading(false))
    },
    [route],
  )

  const deleteNote = useCallback(
    (item, index) => {
      apiNotes
        .deleteEntityNote(`${route}/${item?.id}`)
        .then(() => {
          setNotes(old => {
            old[index] = {
              ...item,
              deleted_by: user.full_name,
              deleted_at: moment.utc(),
              note: '',
            }
            return [...old]
          })
          setEdited?.(true)
          setEditedNote({ id: 0, value: '', isEdit: false })
        })
        .catch(e => console.error(e))
        .finally(checkEditField)
    },
    [route],
  )

  const editNote = useCallback((text, item, index) => {
    setIsEditedItemFetching(true)

    apiNotes
      .editEntityNote(`${route}/${item?.id}`, { text })
      .then(r => {
        setNotes(p => {
          p[index] = r
          return [...p]
        })
      })
      .then(() => {
        setEdited?.(true)
        setEditedNote({ isEdit: false, id: 0, value: '' })
      })
      .then(checkEditField)
      .catch(e => console.error(e))
      .finally(() => setIsEditedItemFetching(false))
  }, [])
  const handleChangeNotesInput = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setCurrentNote(e.target.value)
    setError('')
  }

  const handleSubmitCreateNote = () => {
    createNote(currentNote, attachments)
  }

  const handleCreateOrSaveNote = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()

    if (!showNoteInput) return setShowNoteInput(true)

    if (!!currentNote?.length || !!attachments?.length) {
      handleSubmitCreateNote()
      setError('')
      setShowNoteInput(false)
      return
    }

    if (!currentNote?.length || !attachments?.length) {
      return setError(ERROR_MESSAGE)
    }
  }

  const handleBlurInput = () => {
    if (filesUploadModal) return

    if (!currentNote?.length && !attachments?.length) {
      setError('')
      setShowNoteInput(false)
      return
    }

    handleSubmitCreateNote()
  }

  // useEffect(() => {
  //   if (!props.setOnSaveNotesCallback) return
  //   props.setOnSaveNotesCallback(() => () => handleSubmitCreateNote())
  // }, [currentNote])

  const handleClickNote = (id: number) => {
    if (expandedNotes.includes(id)) {
      setExpandedNotes(prev => prev.filter(noteID => noteID !== id))
    } else {
      setExpandedNotes(prev => [...prev, id])
    }
  }

  useOnClickOutside(ref, handleBlurInput)

  return (
    <AccessControl scopes={[NotesPermissions.NOTES_CAN_READ]}>
      <div ref={ref} className='notes-wrapper'>
        {label && !!notes?.length && (
          <p className='notes-label'>
            <strong>{label}</strong>
          </p>
        )}
        <div className='notes-input-row'>
          {showNoteInput && (
            <div>
              <TextArea
                // className='notes-input'
                placeholder='Add Notes'
                // fullWidth={true}
                value={currentNote}
                onChange={handleChangeNotesInput}
                // onBlur={handleBlurInput}
                disabled={isFetching}
                autoFocus
                autoSize
                maxLength={10000}
                name='notes'
                status={error ? 'error' : ''}
              />
              <p style={{ color: 'red', fontSize: '12px' }}>{error}</p>
            </div>
          )}
          <AccessControl
            module={ModuleName.NOTES}
            scopes={[NotesPermissions.NOTES_CAN_CREATE]}
            additionalAccess={!disabled}
          >
            <div
              style={{ display: 'flex', gap: '10px', alignItems: 'baseline' }}
            >
              {showNoteInput && (
                <PaperClipOutlined
                  onClick={e => {
                    e.stopPropagation()
                    handleOpenFilesUpload({ attachments })
                  }}
                  style={{
                    fontSize: 18,
                    cursor: 'pointer',
                    marginBottom: '4px',
                  }}
                />
              )}

              <StyledButton
                onClick={handleCreateOrSaveNote}
                loading={isFetching || loading}
                type='primary'
                // style={{
                //   minWidth: 125,
                //   maxWidth: 220,
                //   background: '#4285f4',
                // }}
              >
                {`${
                  !!currentNote?.length || !!attachments?.length
                    ? 'Save'
                    : 'Add'
                } ${entity ?? ''} Note`}
              </StyledButton>
            </div>
          </AccessControl>
        </div>

        <List imageSize={imageSize}>
          {attachments
            ?.filter(image => !!image)
            ?.map((image: Media, idx: number) => (
              <Card.Grid hoverable={false} key={idx} style={gridStyle}>
                <ListItem key={idx}>
                  <div
                    onClick={e => {
                      if (e?.target?.classList?.contains('ant-tooltip-inner'))
                        return
                      getFileForModalDocViewer(attachments, idx)
                    }}
                    key={idx}
                  >
                    <ViewFile
                      file={image?.source || image?.linkToFile || image}
                      fileStyle={{
                        width: imageSize,
                        height: imageSize,
                        border: '1px solid #d9d9d9',
                        borderRadius: 8,
                        padding: 5,
                      }}
                      viewOnly={false}
                      tooltipPlacement={'top'}
                      isLogo={undefined}
                      fileName={image?.display_name}
                    />
                  </div>
                </ListItem>
              </Card.Grid>
            ))}
        </List>

        <div
          className={`notes-list-wrapper ${
            notes?.length === 0 ? 'empty' : ''
          } ${(showAll || expandedNotes?.length || editedNote.isEdit) &&
            'fitContent'}`}
        >
          {isFetching ? (
            <>
              <Skeleton width={50} />
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <>
              {notes?.map?.(
                (item, idx) =>
                  (showAll || idx < 3) && (
                    <Fragment key={idx}>
                      <div
                        className={`note-container ${
                          editedNote.isEdit && editedNote.id === item?.id
                            ? 'show'
                            : 'hide'
                        }`}
                      >
                        <div className='note-top__block'>
                          {editedNote?.id === item?.id && editedNote?.isEdit ? (
                            <>
                              <TextArea
                                className='edit-note-input'
                                value={editedNote.value}
                                onChange={e =>
                                  setEditedNote(p => ({
                                    ...p,
                                    value: e.target.value,
                                  }))
                                }
                                // onBlur={e => handleBlurOnEdit(e, editedNote.value, item, idx)}
                                disabled={isEditedItemFetching}
                                autoSize
                                maxLength={10000}
                                name='editNote'
                              />

                              <div className='note-buttons'>
                                <StyledButton
                                  onClick={() =>
                                    editNote(editedNote.value, item, idx)
                                  }
                                  loading={isEditedItemFetching}
                                  // disabled={
                                  //   !editedNote?.value &&
                                  //   !editedNote?.attachments?.length
                                  // }
                                  type='primary'
                                  size='small'
                                >
                                  Save
                                </StyledButton>
                                <Button
                                  onClick={() => {
                                    setEditedNote({
                                      isEdit: false,
                                      id: 0,
                                      value: '',
                                      attachments: [],
                                    })
                                    checkEditField()
                                  }}
                                  loading={isEditedItemFetching}
                                  size='small'
                                  style={{ marginRight: 5 }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </>
                          ) : (
                            <Tooltip
                              title={
                                item?.text?.length > 100
                                  ? `Click to ${
                                      expandedNotes.includes(item?.id)
                                        ? 'close'
                                        : 'expand'
                                    }`
                                  : null
                              }
                            >
                              <span
                                className={`note-text ${
                                  expandedNotes.includes(item?.id) || forPrint
                                    ? ''
                                    : !showAll
                                    ? 'cut'
                                    : ''
                                }`}
                                onClick={() => handleClickNote(item?.id)}
                              >
                                {item?.deleted_at ? (
                                  <i>Deleted</i>
                                ) : item?.text ? (
                                  ReactHtmlParser(
                                    addLinksToText(item?.text),
                                    'text/html',
                                  )
                                ) : (
                                  <span
                                    style={{
                                      color: 'grey',
                                      fontStyle: 'italic',
                                    }}
                                  >{`{no text yet}`}</span>
                                )}
                              </span>
                            </Tooltip>
                          )}
                          {editedNote?.id !== item?.id && (
                            <div className='note-icons'>
                              <AccessControl
                                module={ModuleName.NOTES}
                                scopes={[NotesPermissions.NOTES_CAN_EDIT]}
                                author={item?.created_by_user_id}
                                additionalAccess={!item?.deleted_at}
                              >
                                <div
                                  onClick={() => {
                                    if (disabled) return
                                    handleOpenFilesUpload(item)
                                  }}
                                >
                                  <PaperClipOutlined
                                    style={{
                                      fontSize: 18,
                                      cursor: disabled
                                        ? 'not-allowed'
                                        : 'pointer',
                                    }}
                                  />
                                </div>
                              </AccessControl>
                              <AccessControl
                                scopes={[NotesPermissions.NOTES_CAN_EDIT]}
                                author={item?.created_by_user_id}
                                additionalAccess={!item?.deleted_at}
                              >
                                <div
                                  onClick={() => {
                                    if (disabled) return

                                    setEditedNote({
                                      id: item?.id,
                                      value: item?.text,
                                      isEdit: true,
                                      attachments: item?.attachments,
                                    })
                                  }}
                                >
                                  <EditOutlined
                                    style={{
                                      fontSize: 18,
                                      cursor: disabled
                                        ? 'not-allowed'
                                        : 'pointer',
                                    }}
                                  />
                                </div>
                              </AccessControl>
                              <AccessControl
                                scopes={[NotesPermissions.NOTES_CAN_DELETE]}
                                author={item?.created_by_user_id}
                                additionalAccess={!item?.deleted_at}
                              >
                                <Popconfirm
                                  title='Are you sure you want to delete this note?'
                                  onConfirm={() => deleteNote(item, idx)}
                                  disabled={disabled}
                                >
                                  <div>
                                    <DeleteOutlined
                                      style={{
                                        fontSize: 18,
                                        cursor: disabled
                                          ? 'not-allowed'
                                          : 'pointer',
                                      }}
                                    />
                                  </div>
                                </Popconfirm>
                              </AccessControl>
                            </div>
                          )}
                        </div>
                        {!item?.deleted_at && (
                          <List imageSize={imageSize}>
                            {item?.attachments
                              ?.filter(image => !!image)
                              ?.map((image: Media, idx: number) => (
                                <Card.Grid
                                  hoverable={false}
                                  key={idx}
                                  style={gridStyle}
                                >
                                  <ListItem key={idx}>
                                    <div
                                      onClick={e => {
                                        if (
                                          e?.target?.classList?.contains(
                                            'ant-tooltip-inner',
                                          )
                                        )
                                          return
                                        getFileForModalDocViewer(
                                          item.attachments,
                                          idx,
                                        )
                                      }}
                                      key={idx}
                                    >
                                      <ViewFile
                                        file={
                                          image?.source ||
                                          image?.linkToFile ||
                                          image
                                        }
                                        fileStyle={{
                                          width: imageSize,
                                          height: imageSize,
                                          border: '1px solid #d9d9d9',
                                          borderRadius: 8,
                                          padding: 5,
                                        }}
                                        viewOnly={false}
                                        tooltipPlacement={'top'}
                                        isLogo={undefined}
                                        fileName={image?.display_name}
                                      />
                                    </div>
                                  </ListItem>
                                </Card.Grid>
                              ))}

                            {!!item?.attachments?.length && !item?.deleted_at && (
                              <Card.Grid hoverable={false} style={gridStyle}>
                                <Tooltip
                                  title='Manage attachments'
                                  mouseLeaveDelay={0}
                                >
                                  <Upload
                                    style={{
                                      width: imageSize,
                                      height: imageSize,
                                    }}
                                    onClick={() => handleOpenFilesUpload(item)}
                                  >
                                    <PaperClipOutlined
                                      style={{ fontSize: 18 }}
                                    />
                                  </Upload>
                                </Tooltip>
                              </Card.Grid>
                            )}
                          </List>
                        )}

                        <div className='note-bottom__block'>
                          {item?.deleted_at && (
                            <p className='deleted-text'>
                              <i>
                                Deleted by {item?.deleted_by} at{' '}
                                {formatTimeByMoment(item?.deleted_at)}
                              </i>
                              <Tooltip title={item?.text || '{not text yet}'}>
                                <InfoIcon
                                  className='deleted-text__tooltip'
                                  style={{
                                    marginLeft: 'auto',
                                    marginRight: '5px',
                                    fontSize: '20px',
                                    color: '#4c53ef',
                                    opacity: '1',
                                  }}
                                />
                              </Tooltip>
                            </p>
                          )}
                          {!item?.deleted_at && item?.updated_by && (
                            <p className='updated-text'>
                              <i>
                                Updated by {item?.updated_by} at{' '}
                                {formatTimeByMoment(item?.updated_at)}
                              </i>
                            </p>
                          )}
                          <p className='created-text'>
                            <i>
                              Created by {item?.created_by} at{' '}
                              {formatTimeByMoment(item?.created_at)}
                            </i>
                          </p>
                        </div>
                      </div>
                      {notes?.length !== 0 && notes?.length - 1 !== idx && (
                        <div className='note-separator' />
                      )}
                    </Fragment>
                  ),
              )}
            </>
          )}
          {notes?.length > 3 && (
            <div className={`arrow__wrapper ${showAll ? 'up' : 'down'}`}>
              <div className='arrow__container'>
                <i
                  className={`mdi mdi-chevron-${showAll ? 'up' : 'down'}`}
                  onClick={() => setShowAll(!showAll)}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {viewerModal?.showDocViewerModal && (
        <DocViewer
          documents={viewerModal.documents}
          currentDocument={viewerModal.currentFileIdx}
          isModalVisible={viewerModal.showDocViewerModal}
          handleCancel={toggleOpenDocViewerModal}
        />
      )}

      {filesUploadModal && (
        <ModalFilesUpload
          data={editAttachment?.attachments}
          onSave={handeFilesUpload}
          onDelete={handleDeleteFile}
          onCancel={handleCancel}
          publicUsage={false}
          filesLimit={50}
          uploadByLink={false}
        />
      )}
    </AccessControl>
  )
}

export default Notes

const StyledButton = styled(Button)`
  background: #4285f4;
  border-color: #4285f4;
  /* min-width: 125px;
  max-width: 220px; */

  &:active,
  &:focus {
    background: #4285f4;
    border-color: #4285f4;
  }

  &:hover {
    background: #0069d9;
    border-color: #0069d9;
  }
`

const List = styled.div<{ imageSize: number }>`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`
const ListItem = styled.div``

const Upload = styled.div`
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: #2e5bff;
  }
`
