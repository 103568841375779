import React, { useState, useEffect } from 'react'
import './TimeCardTable.scss'
import TimeCardRow from './TimeCardRow/TimeCardRow'
import moment from 'moment-timezone'
import TableActions from 'containers/MainContent/Orcatec/components/TableActionsNew/index.js'
import { TimePicker } from 'antd'
// import { createTimeCardTrack } from 'api/TimeCards'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import Preloader from 'containers/MainContent/Orcatec/components/Preloader/index.jsx'
import { useDispatch, useSelector } from 'react-redux'
import {
  addTimeCardTrack,
  removeDay,
  resetErrors,
} from 'store/Orcatec/actions/timecards'
import { timecardsSelector } from 'store/Orcatec/reducers/timecards'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  selectUserPermissionsByModule,
  selectUserPermissionsByName,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  TimeCardsPermissions,
  ModuleName,
} from 'features/Settings/UsersAndGroups'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import { meSelector } from 'store/SuperAdmin/selectors'

const TimeCardTable = ({
  timelines,
  date,
  user_id,
  startDate,
  endDate,
  fetchCard,
  updateCardList,
  timeCollision,
  timezone,
  readOnly,
}) => {
  // const canEdit = useAppSelector(
  //   selectUserPermissionsByName(
  //     ModuleName.TIME_CARDS,
  //     TimeCardsPermissions.TIME_CARDS_USER_TIME_CARD_CAN_EDIT,
  //   ),
  // )
  // const canDelete = useAppSelector(
  //   selectUserPermissionsByName(
  //     ModuleName.TIME_CARDS,
  //     TimeCardsPermissions.TIME_CARDS_USER_TIME_CARD_CAN_DELETE,
  //   ),
  // )

  const me = useAppSelector(meSelector)

  const timeCardsPermissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.TIME_CARDS),
  )

  const canEdit = checkAccessControl(
    timeCardsPermissions[
      TimeCardsPermissions.TIME_CARDS_USER_TIME_CARD_CAN_EDIT
    ],
    user_id,
    me.id,
  )

  const canDelete = checkAccessControl(
    timeCardsPermissions[
      TimeCardsPermissions.TIME_CARDS_USER_TIME_CARD_CAN_DELETE
    ],
    user_id,
    me.id,
  )

  const [isTimeRow, setIsTimeRow] = useState(false)
  const [addTimeRow, setAddTimeRow] = useState('')

  const [from, setFrom] = useState(moment(date).startOf('day'))
  const [to, setTo] = useState(moment(date).endOf('day'))
  // const [startTime, setStartTime] = useState(moment(date).startOf('day'))
  // const [endTime, setEndTime] = useState(moment(date).endOf('day'))
  const [startTime, setStartTime] = useState(
    moment(date).set({
      hour: 8,
      minute: 0,
      second: 0,
    }),
  )
  const [endTime, setEndTime] = useState(
    moment(date).set({
      hour: 10,
      minute: 0,
      second: 0,
    }),
  )

  const { error } = useSelector(timecardsSelector)
  const dispatch = useDispatch()

  const resetTimecardsErrors = () => {
    if (error) dispatch(resetErrors())
  }

  useEffect(() => {
    if (error) {
      // setError(true)
      openNotificationWithIcon('error', {
        message: error,
        description: '',
        key: 'timecards-add-failure',
      })
    }

    return () => {
      resetTimecardsErrors()
    }
  }, [error])

  useEffect(() => {
    if (moment(date, 'MM/DD/YY').isSame(moment(), 'day')) {
      if (from.isAfter(moment())) setFrom(moment(date))
      if (to.isAfter(moment())) setTo(moment(date))
      // //if (startTime.isAfter(moment())) setStartTime(moment(date))
      // if (endTime.isAfter(moment())) setEndTime(moment(date))
    }
  }, [from, to, startTime, endTime])

  const handleAddTime = (timeFrom, timeTo) => {
    setIsTimeRow(true)

    if (timeFrom) {
      setFrom(moment(timeFrom, 'YYYY-MM-DD h:mm:ss A') /* .add(1, 's') */)
      setStartTime(moment(timeFrom, 'YYYY-MM-DD h:mm:ss A') /* .add(1, 's') */)
    } else {
      setFrom(moment(date).startOf('day'))
      setStartTime(moment(date).startOf('day'))
    }
    if (timeTo) {
      setTo(moment(timeTo, 'YYYY-MM-DD h:mm:ss A') /* .subtract(1, 's') */)
      setEndTime(moment(timeTo, 'YYYY-MM-DD h:mm:ss A') /* .subtract(1, 's') */)
    } else {
      setTo(moment(date).endOf('day'))
      setEndTime(moment(date).endOf('day'))
    }
  }

  const handleTimeChange = type => value => {
    resetTimecardsErrors()

    if (!value) return
    // if (!value.isBetween(from, to)) return
    if (type === 'start') {
      // if (moment(value).diff(endTime) > 0) return
      setStartTime(value)
    }
    if (type === 'end') {
      // if (moment(value).diff(startTime) < 0) return
      setEndTime(value)
    }
  }

  const isValidEndDate = () => {
    const difference = moment(endTime).diff(moment(startTime))
    return difference > 999 ? true : false
  }

  const isFutureDates = () => {
    const differenceStartTime = moment.utc().diff(moment.utc(startTime))
    const differenceEndTime = moment.utc().diff(moment.utc(endTime))
    return differenceStartTime < 0 || differenceEndTime < 0 ? true : false
  }

  const onSaveChanges = () => {
    if (!isValidEndDate()) {
      openNotificationWithIcon('error', {
        message:
          'Time of your clock-out must be later than the time of the respective clock-in',
        description: '',
        key: 'timecards-add-failure',
      })
      return
    }
    if (isFutureDates()) {
      openNotificationWithIcon('error', {
        message:
          'Adding or updating a time-record for a future time is impossible',
        description: '',
        key: 'timecards-add-failure',
      })
      return
    }
    // setIsLoading(true)
    dispatch(
      addTimeCardTrack(
        {
          date: moment(date).format('YY-MM-DD'),
          userId: user_id,
          clock_in: moment(startTime).format('Y-MM-DD HH:mm:ss'),
          clock_out: moment(endTime).format('Y-MM-DD HH:mm:ss'),
          timezone,
          startDate,
          endDate,
        },
        () => setIsTimeRow(false),
      ),
    )
    /* createTimeCardTrack({
      clock_in: moment(startTime).format('Y-MM-DD HH:mm:ss'),
      clock_out: moment(endTime).format('Y-MM-DD HH:mm:ss'),
      userId: user_id,
      timezone: timezone,
    })
      .then(() => {
        fetchCard()
        setIsTimeRow(false)
      })
      .catch(error => {
        openNotificationWithIcon('error', {
          message: error.response?.data?.error?.message,
          description: '',
          key: 'timecards-add-failure',
        })
      })
      .finally(() => setIsLoading(false)) */
  }

  /* const interpolateList = data => {
    let arr = []
    if (Array.isArray(data)) arr = data
    if (!Array.isArray(data) && typeof arr === 'object') arr = [data]

    const res = []

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].deleted) {
        res.push(arr[i])
        continue
      }

      if (i !== arr.length - 1 && arr[i].time_id) {
        const from = arr[i].clock_out
        let to
        for (let j = i + 1; j < arr.length; j++) {
          if (!arr[j].deleted) {
            to = arr[j].clock_in
            break
          }
        }

        if (from && to) {
          const ms = moment(to, 'YYYY-MM-DD HH:mm:ss A').diff(moment(from, 'YYYY-MM-DD HH:mm:ss A'))
          const diff = moment.utc(ms).format('HH:mm:ss')
          res.push(arr[i])
          res.push({ from, to, break_time: diff, isBreak: true })
        } else {
          res.push(arr[i])
        }
      }
      if (i === arr.length - 1) res.push(arr[i])
    }

    return res
  } */

  const findBoundary = (key, data) => {
    let arr = []
    if (Array.isArray(data)) arr = data
    if (!Array.isArray(data) && typeof arr === 'object') arr = [data]

    arr = arr.filter(el => !el.deleted)
    if (key === 'start' && arr?.[0]) return arr?.[0].clock_in
    if (key === 'end' && arr[arr.length - 1])
      return arr[arr.length - 1].clock_out
    return null
  }

  const isAddTimeline = (data, type) => {
    let arr = []
    if (Array.isArray(data)) arr = data
    if (!Array.isArray(data) && typeof arr === 'object') arr = [data]
    // arr = arr.filter(el => !el.deleted)
    if (arr.length) {
      if (type === 'start')
        return moment(arr?.[0]?.clock_in).diff(moment(date).startOf('day')) > 0
          ? true
          : false
      if (type === 'end')
        return moment(arr?.[arr.length - 1]?.clock_out).diff(
          moment(date).endOf('day'),
        ) < -999
          ? true
          : false
    }
  }

  const isClockedIn = data => {
    let arr = []
    if (Array.isArray(data)) arr = data
    if (!Array.isArray(data) && typeof arr === 'object') arr = [data]

    arr = arr.filter(el => !el?.deleted)
    return arr.some(el => !el?.clock_out)
  }

  const renderEmptyRow = (cb, type) => {
    if (isTimeRow && type === addTimeRow) {
      return (
        <tr className={`${error ? 'error' : ''}`}>
          <td>
            <TimePicker
              getPopupContainer={trigger => trigger.parentElement}
              use12Hours
              format='h:mm:ss a'
              value={startTime}
              onChange={handleTimeChange('start')}
            />
            {!!error && (
              <p style={{ fontSize: 8 }}>
                Choose time between: {moment(from).format('hh:mm:ss A')}{' '}
              </p>
            )}
          </td>
          <td>
            <TimePicker
              getPopupContainer={trigger => trigger.parentElement}
              use12Hours
              format='h:mm:ss a'
              value={endTime}
              onChange={handleTimeChange('end')}
            />
            {!!error && (
              <p style={{ fontSize: 8 }}>
                and {moment(to).format('hh:mm:ss A')}
              </p>
            )}
          </td>
          <td></td>
          <td></td>
          <td>
            {!readOnly && (
              <TableActions
                todos={['close', 'confirm']}
                callbacks={[
                  () => {
                    setAddTimeRow('')
                    resetTimecardsErrors()
                    setIsTimeRow(false)
                  },
                  onSaveChanges,
                ]}
                tooltips={['Cancel', 'Save']}
              />
            )}
          </td>
        </tr>
      )
    }
    return (
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          {!readOnly && (
            <TableActions
              todos={['add']}
              tooltips={['Add time']}
              callbacks={[cb]}
              visible={[canEdit]}
            />
          )}
        </td>
      </tr>
    )
  }

  return (
    <>
      <table className='table-time-list'>
        <thead>
          <tr>
            <th scope='col'>Clock-in</th>
            <th scope='col'>Clock-out</th>
            <th scope='col'>Time worked</th>
            <th scope='col'>Break time</th>
            <th scope='col'>Action</th>
          </tr>
        </thead>
        <tbody>
          {!timeCollision &&
            isAddTimeline(timelines, 'start') &&
            renderEmptyRow(() => {
              handleAddTime(null, findBoundary('start', timelines))
              setAddTimeRow('first')
            }, 'first')}
          {timelines.length ? (
            timelines?.map((item, idx, arr) => (
              <TimeCardRow
                key={idx}
                date={date}
                fetchCard={() => fetchCard()}
                timeline={item}
                user_id={user_id}
                startDate={startDate}
                endDate={endDate}
                updateCardList={updateCardList}
                addNewTime={handleAddTime}
                timeCollision={timeCollision}
                currentRowIndex={idx}
                allRows={arr}
                timezone={timezone}
                error={error}
                resetErrors={resetTimecardsErrors}
                readOnly={readOnly}
                canEdit={canEdit}
                canDelete={canDelete}
              />
            ))
          ) : (
            <tr>
              <td>
                <TimePicker
                  getPopupContainer={trigger => trigger.parentElement}
                  use12Hours
                  format='h:mm:ss a'
                  value={startTime}
                  onChange={handleTimeChange('start')}
                />
              </td>
              <td>
                <TimePicker
                  getPopupContainer={trigger => trigger.parentElement}
                  use12Hours
                  format='h:mm:ss a'
                  value={endTime}
                  onChange={handleTimeChange('end')}
                />
              </td>
              <td></td>
              <td></td>
              <td>
                {!readOnly && (
                  <TableActions
                    todos={['close', 'confirm']}
                    callbacks={[
                      () => {
                        resetTimecardsErrors()
                        dispatch(removeDay(moment(date).format('YY-MM-DD')))
                      },
                      onSaveChanges,
                    ]}
                    tooltips={['Cancel', 'Save']}
                  />
                )}
              </td>
            </tr>
          )}
          {!timeCollision &&
            !isClockedIn(timelines) &&
            isAddTimeline(timelines, 'end') &&
            renderEmptyRow(() => {
              handleAddTime(findBoundary('end', timelines), null)
              setAddTimeRow('second')
            }, 'second')}
        </tbody>
      </table>
    </>
  )
}

export default TimeCardTable
