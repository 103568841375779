import { Popconfirm, Switch } from 'antd'
import {
  createMembershipLabel,
  getMembershipLabel,
  updateMembershipLabel,
  deleteMembershipLabel,
} from 'api/Membership'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import EditableBlock from 'containers/MainContent/Orcatec/components/UI/EditableBlock'
import InputField from 'containers/MainContent/Orcatec/components/UI/InputFieldTransformable'
import { SettingsRow } from 'containers/MainContent/Orcatec/components/UI/SettingsRow/SettingsRow'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useEffect, useRef, useState } from 'react'
import { TwitterPicker } from 'react-color'
import NumberFormat from 'react-number-format'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { MembershipDiscountType, MembershipLabel } from '../types'

export const Membership = ({ id }: { id?: string }) => {
  const [membership, setMembership] = useState<MembershipLabel>({
    name: '',
    color: 'green',
    discount_type: 1,
    discount: 0,
  })
  const [errors, setErrors] = useState(null)
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [loading, setLoading] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  const history = useHistory()

  useOnClickOutside(ref, () => setShowColorPicker(false))

  const getData = async (id: string) => {
    setLoading(true)
    const data = await getMembershipLabel(id)

    setMembership(data)
    setLoading(false)
  }

  useEffect(() => {
    if (id) getData(id)
  }, [id])

  ///
  // const [membershipRelations, setMembershipRelations] = useState([])

  // useEffect(() => {
  //   const getMembershipRelations = async (id: string | number) => {
  //     const { data } = await getMembershipRelationList(id, 'properties')

  //     setMembershipRelations(data)
  //   }

  //   if (id) getMembershipRelations(id)
  // }, [id])

  // const columns = [
  //   {
  //     title: 'Address',
  //     dataIndex: 'full_address',
  //     key: 'full_address',
  //     render: (text: string, record) => <Link to={`/properties-form/${record.id}`}>{text}</Link>,
  //   },
  //   {
  //     title: 'Start date',
  //     dataIndex: 'start_date',
  //     key: 'start_date',
  //   },
  //   {
  //     title: 'End date',
  //     dataIndex: 'end_date',
  //     key: 'end_date',
  //   },
  // ]
  ///
  const handleChangeLabel = (e: {
    target: {
      name: string
      value: any
    }
  }) => {
    let { name, value } = e.target

    if (name === 'discount' && !value) value = 0

    setMembership(prev => ({
      ...prev,
      [name]: value,
    }))

    if (errors?.[name]) setErrors(null)
  }

  const handleSaveLabel = async () => {
    const action = membership?.id
      ? updateMembershipLabel
      : createMembershipLabel

    try {
      await action(membership)

      openNotificationWithIcon('success', {
        message: `Label successfully ${membership?.id ? 'updated' : 'created'}`,
      })

      if (!membership?.id) history.push('/settings/memberships')
    } catch (error) {
      setErrors(error?.response?.data?.errors)
      openNotificationWithIcon('error', {
        message: error?.response?.data?.message,
      })

      return Promise.reject(new Error(error))
    }
  }

  const handleDeleteLabel = async () => {
    if (!membership.id) return

    await deleteMembershipLabel(membership?.id)

    openNotificationWithIcon('success', {
      message: `Label successfully deleted`,
    })

    history.push('/settings/memberships')
  }

  const handleChangeComplete = (color: { hex: string }) => {
    setMembership(prev => ({ ...prev, color: color.hex }))
  }

  const toogleColorPicker = () => {
    setShowColorPicker(!showColorPicker)
  }

  if (loading) return <p>..loading</p>

  return (
    <>
      <Link to={'/settings/memberships'} style={{ width: 70 }}>
        {'<'}Go back
      </Link>
      <form>
        <SettingsRow title='Name'>
          <EditableBlock
            editMode={!membership.id}
            value={membership.name}
            onSave={handleSaveLabel}
          >
            <InputField
              name='name'
              value={membership.name}
              onChange={handleChangeLabel}
              error={errors?.name}
            />
          </EditableBlock>
        </SettingsRow>

        <SettingsRow title='Color'>
          <EditableBlock
            editMode={!membership.id}
            value={
              <ColorBlock
                backgroundColor={membership.color}
                onClick={toogleColorPicker}
              />
            }
            onSave={handleSaveLabel}
          >
            <div>
              <ColorBlock
                backgroundColor={membership.color}
                onClick={toogleColorPicker}
              />
              {showColorPicker && (
                <div ref={ref} style={{ position: 'absolute', zIndex: 1 }}>
                  <TwitterPicker
                    color={membership.color}
                    onChangeComplete={handleChangeComplete}
                  />
                </div>
              )}
            </div>
          </EditableBlock>
        </SettingsRow>

        <SettingsRow title='Discount type'>
          <EditableBlock
            editMode={!membership.id}
            value={
              membership.discount_type === MembershipDiscountType.Amount
                ? '$'
                : '%'
            }
            onSave={handleSaveLabel}
          >
            <Switcher>
              <p>%</p>
              <Switch
                checked={
                  membership.discount_type === MembershipDiscountType.Amount
                }
                onChange={checked =>
                  handleChangeLabel({
                    target: {
                      name: 'discount_type',
                      value: checked
                        ? MembershipDiscountType.Amount
                        : MembershipDiscountType.Percent,
                    },
                  })
                }
              />
              <p>$</p>
            </Switcher>
          </EditableBlock>
        </SettingsRow>

        <SettingsRow title='Discount'>
          <EditableBlock
            editMode={!membership.id}
            value={`${
              membership.discount_type === MembershipDiscountType.Amount
                ? '$'
                : ''
            }${membership.discount}${
              membership.discount_type === MembershipDiscountType.Percent
                ? '%'
                : ''
            }`}
            onSave={handleSaveLabel}
          >
            <NumberFormat
              value={membership.discount}
              thousandSeparator
              // onBlur={() => alert('fdsf')}
              onValueChange={valuees =>
                handleChangeLabel({
                  target: { name: 'discount', value: valuees.value },
                })
              }
              customInput={InputField}
              prefix={
                membership.discount_type === MembershipDiscountType.Amount
                  ? '$'
                  : undefined
              }
              suffix={
                membership.discount_type === MembershipDiscountType.Percent
                  ? '%'
                  : undefined
              }
              name='discount'
            />
          </EditableBlock>
        </SettingsRow>
      </form>

      <Buttons>
        {membership?.id && (
          <Popconfirm
            title='Are you sure you want to delete this membership?'
            onConfirm={handleDeleteLabel}
            okText='Yes'
            cancelText='No'
          >
            <MainButton
              type='delete'
              title='Delete'
              // onClick={handleDeleteLabel}
              style={{
                marginRight: 110,
              }}
            />
          </Popconfirm>
        )}
        {!membership?.id && (
          <>
            <MainButton
              type='cancel'
              title='Cancel'
              onClick={() => history.push('/settings/memberships')}
            />
            <MainButton title='Create' onClick={handleSaveLabel} />
          </>
        )}
      </Buttons>
      {/* temp */}
      {/* <div style={{ marginTop: 30 }}>
        <h6>Properties</h6>
        <Table columns={columns} dataSource={membershipRelations} size='small' pagination={false} />
      </div> */}
      {/* temp */}
    </>
  )
}

export const Wrapper = styled.div``
export const Switcher = styled.div`
  display: flex;
  gap: 5px;

  button.ant-switch {
    background-color: #4285f4;
  }
`
export const Buttons = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 10px;
`

export const ColorBlock = styled.div<{ backgroundColor: string }>`
  width: 35px;
  height: 22px;
  background-color: ${props => props.backgroundColor};
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  /* margin-bottom: 10px; */
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 150px 170px;
  margin-bottom: 15px;
`
