import { Tooltip } from 'antd'
import { priceToView } from 'helpers/thousandSeparator'
import InfoIcon from '@material-ui/icons/Info'
import { Row, Table, Wrapper } from './Totals.styles'

type Props = {
  amount: number
  tips?: number
  transactionFee?: number
}

export const Totals = ({ amount, tips = 0, transactionFee = 0 }: Props) => {
  return (
    <Wrapper>
      {!!(tips || transactionFee) && (
        <Table>
          <Row>
            <span>Total</span>
            <span>{priceToView(amount)}</span>
          </Row>
          {!!transactionFee && (
            <Row>
              <span>
                Transaction fee
                <Tooltip
                  overlayStyle={{ whiteSpace: 'pre-line', fontSize: 10 }}
                  title={`A service fee of ${transactionFee}% will be added for credit card payment`}
                >
                  <InfoIcon
                    style={{
                      position: 'relative',
                      top: '-5px',
                      fontSize: '12px',
                      color: '#4c53ef',
                    }}
                  />
                  {/* <InfoCircleOutlined /> */}
                </Tooltip>
              </span>
              <span>{priceToView(amount * (transactionFee / 100))}</span>
            </Row>
          )}
          {!!tips && (
            <Row>
              <span>Tips</span>
              <span>{priceToView(tips)}</span>
            </Row>
          )}
        </Table>
      )}
      <Row last>
        <span>Amount to pay</span>
        <span>
          {priceToView((amount || 0) + tips + amount * (transactionFee / 100))}
        </span>
      </Row>
    </Wrapper>
  )
}
