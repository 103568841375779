import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Button, Checkbox } from 'antd'
import MainButton from '../../../../../components/buttons/MainButton'
import './MergeTempNotesAndTempTagsModal.scss'

const cancelButtonStyle = { color: '#191919', border: '1px solid #D6D6D6', marginRight: '10px' }
const initialConfirmData = {
  isTagsConfirmed: false,
  isNotesConfirmed: false,
}

const MergeTempNotesAndTempTagsModal = ({ modal, onConfirm, onClose, newTags = [], newNotes = '' }) => {
  const [confirmData, setConfirmData] = useState(initialConfirmData)
  useEffect(() => {
    if (!modal) {
      setConfirmData(initialConfirmData)
    }
  }, [modal])
  const toggleTagsCheckbox = e => {
    setConfirmData({ ...confirmData, isTagsConfirmed: e.target.checked })
  }
  const toggleNotesCheckbox = e => {
    setConfirmData({ ...confirmData, isNotesConfirmed: e.target.checked })
  }

  const onSubmit = () => {
    onConfirm(confirmData)
  }
  return (
    <Modal show={modal} backdrop='static' onHide={onClose} className='sure-modal' centered={true}>
      <Modal.Header closeButton />
      <Modal.Body>
        <div className='w-100 d-flex justify-content-center'>
          <div className='sure-modal-title'>{'Do you want add new info to choosen property?'}</div>
        </div>
        <div className='w-100 d-flex justify-content-center'>
          <table>
            <tbody>
              {Array.isArray(newTags) && !!newTags.length && (
                <tr>
                  <td>
                    <Checkbox checked={confirmData.isTagsConfirmed} onChange={toggleTagsCheckbox}>
                      New Tags
                    </Checkbox>
                  </td>
                  <td>
                    <div className='sure-modal-tags'>
                      {newTags.map(tag => (
                        <span key={tag} className='sure-modal-tags-tag'>
                          {tag}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              )}
              {!!newNotes && (
                <tr>
                  <td>
                    <Checkbox checked={confirmData.isNotesConfirmed} onChange={toggleNotesCheckbox}>
                      New Notes
                    </Checkbox>
                  </td>
                  <td>{newNotes}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='d-flex justify-content-center buttons'>
          <Button variant='default' onClick={onClose} className='main-button user-cancel-btn' style={cancelButtonStyle}>
            Cancel
          </Button>
          <MainButton
            title='Confirm'
            onClick={onSubmit}
            className='main-button'
            disabled={!confirmData.isNotesConfirmed && !confirmData.isTagsConfirmed}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MergeTempNotesAndTempTagsModal
