// PROPERTY
export const PROPOSAL_PROPERTY_SET_CURRENT_ITEM = 'PROPOSAL_PROPERTY_SET_CURRENT_ITEM'
export const PROPOSAL_PROPERTY_REMOVE_CURRENT_ITEM = 'PROPOSAL_PROPERTY_REMOVE_CURRENT_ITEM'
export const PROPOSAL_PROPERTY_SET_ATTACHED_ITEM = 'PROPOSAL_PROPERTY_SET_ATTACHED_ITEM'
export const PROPOSAL_PROPERTY_REMOVE_ATTACHED_ITEM = 'PROPOSAL_PROPERTY_REMOVE_ATTACHED_ITEM'
export const PROPOSAL_PROPERTY_CLEAR_ATTACHED_ITEM = 'PROPOSAL_PROPERTY_CLEAR_ATTACHED_ITEM'
// CLIENTS
export const PROPOSAL_CLIENTS_SET_ATTACHED_ITEM = 'PROPOSAL_CLIENTS_SET_ATTACHED_ITEM'
export const PROPOSAL_CLIENTS_CLEAR_ATTACHED_ITEM = 'PROPOSAL_CLIENTS_CLEAR_ATTACHED_ITEM'
export const PROPOSAL_CLIENTS_REMOVE_ATTACHED_ITEM = 'PROPOSAL_CLIENTS_REMOVE_ATTACHED_ITEM'
// ORGANIZATIONS
export const PROPOSAL_ORGANIZATIONS_SET_ATTACHED_ITEM = 'PROPOSAL_ORGANIZATIONS_SET_ATTACHED_ITEM'
export const PROPOSAL_ORGANIZATIONS_CLEAR_ATTACHED_ITEM = 'PROPOSAL_ORGANIZATIONS_CLEAR_ATTACHED_ITEM'
export const PROPOSAL_ORGANIZATIONS_REMOVE_ATTACHED_ITEM = 'PROPOSAL_ORGANIZATIONS_REMOVE_ATTACHED_ITEM'
