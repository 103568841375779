import { Marker, useMap } from 'react-leaflet'
import L from 'leaflet'
import ReactDOMServer from 'react-dom/server'
import EmployeeHomeIcon from '../Icons/EmployeeHomeIcon'
import EmployeeHomePopup from './../Popaps/EmployeeHomePopup'
import { useEffect, useRef, useState } from 'react'

const EmployeeHomeMarker = props => {
  const map = useMap()
  let popupRef = useRef()

  const [refReady, setRefReady] = useState(false)

  useEffect(() => {
    if (refReady && props?.isActive) {
      popupRef?.current?.openPopup()
    }
  }, [refReady, map])

  return (
    <Marker
      ref={r => {
        popupRef.current = r
        setRefReady(true)
      }}
      position={[Number(props.lat), Number(props.lng)]}
      icon={L.divIcon({
        iconSize: null,
        html: ReactDOMServer.renderToString(
          <EmployeeHomeIcon color={props.employee.color} />,
        ),
        className: 'employer-home-marker',
      })}
    >
      <EmployeeHomePopup
        className='popaps-with-content'
        employee={props.employee}
      />
    </Marker>
  )
}

export default EmployeeHomeMarker
