import React, { useState, useEffect, useRef } from 'react'

import { Modal as BootstrapModal } from 'react-bootstrap'
import { MenuItem, TextField } from '@material-ui/core'

import Preloader from '../../../components/Preloader'
import Modal from '../Modal'
import AttachmentsUploader from '../AttachmentsUploader'

import {
  types as ticketTypes,
  NEED_HELP_TYPE,
  TECH_TYPE,
  OTHER_TYPE,
  MAX_BODY_LENGTH,
  MAX_SUBJECT_LENGTH,
} from '../../../../../../helpers/supportTickets'
import { createTicket } from '../../../../../../api/supportTickets'
import { parseMessages } from '../../../../../../helpers/http-error'
import simpleNotify from '../../../../../../helpers/notifications/simpleNotify'

import './style.scss'

const validate = (value, callback) => {
  const result = callback(value)

  return {
    error: !!result,
    message: result || '',
  }
}

const validationFields = ['type', 'subject', 'body', 'attachments']

const initialFields = {
  type: NEED_HELP_TYPE,
  subject: 'I have a question',
  body: '',
  attachments: [],
}

const CreateTicketModal = ({ isShow, onHide, me }) => {
  const [fields, setFields] = useState(initialFields)
  const [attachmentUploadingProcess, setAttachmentUploadingProcess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const attachmentsRef = useRef()

  useEffect(() => {
    if (!isShow) {
      clear()
    }
  }, [isShow])

  const rules = {
    type: value => validate(value, value => (!value ? 'This field is required' : '')),
    subject: value => validate(value, value => (!value ? 'This field is required' : '')),
    body: value =>
      validate(value, value =>
        !value && !fields.attachments.length ? 'This field is required if attachments is empty' : '',
      ),
  }

  const validateAll = () => {
    const newErrors = { ...errors }
    let validated = true

    validationFields.forEach(field => {
      if (rules[field]) {
        const result = rules[field](fields[field])

        newErrors[field] = result

        if (result.error) {
          validated = false
        }
      }
    })

    setErrors(newErrors)

    return validated
  }

  const handleChangeField = field => {
    const { name, value } = field

    setFields({
      ...fields,
      [name]: value,
    })

    const rule = rules[name]
    if (rule) {
      setErrors({
        ...errors,
        [name]: rule(value),
      })
    }
  }

  const clear = () => {
    setFields(initialFields)
    setErrors({})

    if (attachmentsRef.current) {
      attachmentsRef.current.clear()
    }
  }

  const handleHide = () => {
    onHide()
  }

  const handleChangeType = e => {
    const { value } = e.target
    handleChangeField({ name: 'type', value })
  }

  const handleChangeSubject = e => {
    let { value } = e.target
    if (value.length > MAX_SUBJECT_LENGTH) {
      value = value.slice(0, MAX_SUBJECT_LENGTH)
    }
    handleChangeField({ name: 'subject', value })
  }

  const handleChangeBody = e => {
    let { value } = e.target
    if (value.length > MAX_BODY_LENGTH) {
      value = value.slice(0, MAX_BODY_LENGTH)
    }
    handleChangeField({ name: 'body', value })
  }

  const handleStartAttachmentUpload = () => {
    setAttachmentUploadingProcess(true)
  }

  const handleEndAttachmentUpload = () => {
    setAttachmentUploadingProcess(false)
  }

  const handleAttachmentUploaded = attachment => {
    const newAttachments = fields.attachments.slice()
    newAttachments.push(attachment)
    handleChangeField({ name: 'attachments', value: newAttachments })

    handleEndAttachmentUpload()
  }

  const handleRemoveAttachment = attachment => {
    const newAttachments = fields.attachments.filter(item => item.id !== attachment.id)
    handleChangeField({ name: 'attachments', value: newAttachments })
  }

  const handleSubmit = () => {
    if (!isLoading && validateAll()) {
      setIsLoading(true)
      const data = { ...fields, attachments: fields.attachments.map(item => item.id) }

      createTicket(data)
        .then(() => {
          handleHide()
          simpleNotify('success', 'Your request has been submitted')
        })
        .catch(error => {
          const parsedError = parseMessages(error)
          if (parsedError.status === 422) {
            const newErrors = { ...errors }
            validationFields.forEach(field => {
              if (parsedError.validationMessages[field]) {
                newErrors[field] = validate(null, () => parsedError.validationMessages[field])
              }
            })

            setErrors(newErrors)
          } else {
            simpleNotify('error', 'Whoops, something went wrong')
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const renderPreloader = isLoading && (
    <div className='create-ticket__preloader'>
      <Preloader />
    </div>
  )

  return (
    <Modal
      isShow={isShow}
      titleComponent={() => (
        <div>
          <BootstrapModal.Title>{`Hey ${me.full_name}!`}</BootstrapModal.Title>
          <div>Tell us how we can help you</div>
        </div>
      )}
      onHide={handleHide}
    >
      {renderPreloader}
      <div>
        <TextField
          type='text'
          select
          label='Type'
          placeholder='Select type of your request'
          name='type'
          value={fields.type}
          onChange={handleChangeType}
          variant='outlined'
          fullWidth={true}
          size='small'
          className='mt-3'
          error={errors.type && errors.type.error}
          helperText={errors.type && errors.type.error && errors.type.message}
        >
          <MenuItem value={NEED_HELP_TYPE}>{ticketTypes[NEED_HELP_TYPE]}</MenuItem>
          <MenuItem value={TECH_TYPE}>{ticketTypes[TECH_TYPE]}</MenuItem>
          <MenuItem value={OTHER_TYPE}>{ticketTypes[OTHER_TYPE]}</MenuItem>
        </TextField>
        <TextField
          type='text'
          label='Subject'
          placeholder='Type subject of your request'
          name='subject'
          value={fields.subject}
          onChange={handleChangeSubject}
          variant='outlined'
          fullWidth={true}
          size='small'
          className='mt-3'
          error={errors.subject && errors.subject.error}
          helperText={errors.subject && errors.subject.error && errors.subject.message}
        />
        <TextField
          type='text'
          label='Request Description'
          placeholder='Tell us how we can help you'
          name='body'
          value={fields.body}
          onChange={handleChangeBody}
          variant='outlined'
          fullWidth={true}
          size='small'
          multiline
          rows={3}
          className='mt-5'
          error={errors.body && errors.body.error}
          helperText={errors.body && errors.body.error && errors.body.message}
        />
        <AttachmentsUploader
          fromRight
          ref={attachmentsRef}
          onStartUpload={handleStartAttachmentUpload}
          onUploaded={handleAttachmentUploaded}
          onError={handleEndAttachmentUpload}
          onRemove={handleRemoveAttachment}
        />
      </div>
      <div className='d-flex justify-content-end mt-5'>
        <button className='btn btn-custom-cancel' onClick={handleHide}>
          Cancel
        </button>
        <button className='btn btn-custom-info ml-2' disabled={attachmentUploadingProcess} onClick={handleSubmit}>
          Submit Request
        </button>
      </div>
    </Modal>
  )
}

export default CreateTicketModal
