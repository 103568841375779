import { FC, useMemo } from 'react'
import { ColumnProps } from 'antd/lib/table'
import AppointmentStatus from 'containers/MainContent/Orcatec/components/AppointmentStatus/AppointmentStatus'
import { IAppointment } from 'types/Appointment'
import { Table } from 'antd'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 20px 0;

  .ant-table-wrapper {
    max-height: 250px;
    overflow-y: auto;
  }
`

interface Props {
  data: IAppointment[]
}

const ChildAppointmentList: FC<Props> = ({ data }) => {
  const columns: ColumnProps<IAppointment>[] = useMemo(
    () => [
      {
        title: 'Status',
        dataIndex: 'status',
        render: (value: number) => <AppointmentStatus statusId={value} />,
        // align: 'center',
        width: '6em',
        noFilter: true,
      },
      {
        title: 'ID',
        dataIndex: 'appointment_number',
        width: '6em',
        // align: 'center',
        render: (value: string, record: IAppointment) => (
          <span
            className='link'
            onClick={() => window.open(`/appointment/${record.id}`)}
          >
            {value}
          </span>
        ),
        noFilter: true,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        // align: 'center',
        ellipsis: true,
        width: '10em',
        noFilter: true,
      },
      {
        title: 'Type',
        dataIndex: 'appointment_type',
        // align: 'center',
        ellipsis: true,
        noFilter: true,
      },
      {
        title: 'Tech',
        dataIndex: 'tech',
        // align: 'center',
        ellipsis: true,
        render: (name: string) => name ?? 'Queue',
        noFilter: true,
      },
      {
        title: 'Source',
        dataIndex: 'appointment_source',
        // align: 'center',
        ellipsis: true,
        noFilter: true,
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        ellipsis: true,
        // align: 'center',
        noFilter: true,
      },
    ],
    [],
  )

  return (
    <Wrapper>
      <h5>Child appointments</h5>
      <Table
        columns={columns}
        dataSource={data}
        size='small'
        onRow={record => ({
          onClick: () => window.open(`/appointment/${record.id}`),
        })}
        pagination={false}
      />
    </Wrapper>
  )
}

export default ChildAppointmentList
