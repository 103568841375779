import moment from 'moment-timezone'

export const displayTime = (created_at, timezone) => {
  const isBeforeToday = moment
    .utc(created_at)
    .tz(timezone)
    .isBefore(moment().startOf('day'))
  const isBeforeThisWeek = moment
    .utc(created_at)
    .tz(timezone)
    .isBefore(moment().startOf('week'))
  const isBeforeThisYear = moment
    .utc(created_at)
    .tz(timezone)
    .isBefore(moment().startOf('year'))

  return isBeforeThisYear
    ? moment
        .utc(created_at)
        .tz(timezone)
        .format('MM/DD/YYYY')
    : isBeforeThisWeek
    ? moment
        .utc(created_at)
        .tz(timezone)
        .format('MM/DD')
    : isBeforeToday
    ? moment
        .utc(created_at)
        .tz(timezone)
        .format('dddd')
    : moment
        .utc(created_at)
        .tz(timezone)
        .format('hh:mm A')
}

export const parseItem = (item: string) => {
  if (typeof item === 'object') return item.email
  else return item
}
