import * as types from '../../types'

const company = (state = { source: [], types: [] }, action) => {
  switch (action.type) {
    case types.company.COMPANY_SET_ITEM:
      return { ...state, ...action.item }
    case types.company.COMPANY_UPDATE_ITEM:
      return {
        ...state,
        [action.payload.key]: action.payload.data,
      }

    case types.company.GET_COMPANY_TECHNICIANS:
      return {
        ...state,
        technicians: action.payload,
      }
    case types.company.COMPANY_UPDATE_APPOINTMENT_SETTINGS:
      return {
        ...state,
        appointment_setting: {
          ...state.appointment_setting,
          [action.payload.key]: action.payload.data,
        },
      }

    case types.company.COMPANY_SET_DICTIONARY_ITEM:
      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [action.payload.key]: {
            ...state.dictionary[action.payload.key],
            ...action.payload.data,
          },
        },
      }
    case types.company.COMPANY_GET_ITEM:
      return state
    case types.company.COMPANY_FETCH_ITEM:
      break
    case types.company.ENABLE_OR_DISABLE_PUBLIC_APPOINTMENTS:
      return {
        ...state,
        public_appointment: {
          ...state.public_appointment,
          is_enabled: action.payload,
        },
      }

    case types.company.SET_COMPANY_SOURCE: {
      return { ...state, source: action.payload }
    }
    case types.company.DELETE_COMPANY_SOURCE: {
      return {
        ...state,
        source: state?.source?.filter(s => s?.id !== action.payload),
      }
    }
    case types.company.UPDATE_COMPANY_SOURCE: {
      return { ...state, source: [...state?.source, action.payload] }
    }

    case types.company.SET_COMPANY_TYPES: {
      return { ...state, types: action.payload }
    }
    case types.company.DELETE_COMPANY_TYPES: {
      return {
        ...state,
        types: state.types?.filter(s => s?.id !== action.payload),
      }
    }
    case types.company.UPDATE_COMPANY_TYPES: {
      return { ...state, types: [...state.types, action.payload] }
    }
    case types.company.DELETE_COMPANY_BRAND: {
      return { ...state, brand_id: null }
    }

    default:
      return state
  }
}

export default company

//Selectors
export const hasActiveMerchant = state =>
  state?.orcatec?.company?.merchant?.gatewayBoardingStatusCd === 'BOARDED'
export const selectCompanyTechnicians = state =>
  state.orcatec.company.technicians
