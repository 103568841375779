import styled from 'styled-components'

export const Wrapper = styled.div``

export const Section = styled.div`
  margin-top: 30px;
`

export const ContentContainer = styled.div`
  background-color: #fff;
  /* padding: 20px; */
  border: 1px solid #e6e8ec;
  margin-top: 32px;
`

export const ContentContainerInner = styled.div`
  padding: 20px;
`

export const Content = styled.div`
  // margin-top: 31px;
  /* padding: 20px; */
  background-color: #fff;
  border-top: 1px solid #e6e8ec;
  /* border-radius: 4px; */
  overflow: auto;
`

export const MobileContacts = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`
