import {
  createAsyncThunk,
  createSlice,
  createSelector,
  Selector,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  deleteTimeoff,
  getTimeOff,
  postTimeOff,
  putTimeOff,
  TimeOff,
} from 'api/TimeOff'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import moment from 'moment-timezone'
import { AppStateType } from 'store'

type SliceState = {
  error: null | { [key: string]: string }
  item: TimeOff
  list: Record<number, TimeOff>
  loading: boolean
  showModal: boolean
  overlaps: number
}

const initialItem = {
  id: 0,
  start_date: moment().format('MM/DD/YYYY'),
  end_date: moment().format('MM/DD/YYYY'),
  reason: '',
  note: '',
  is_vacation: false,
}

const initialState: SliceState = {
  error: null,
  item: initialItem,
  list: {},
  loading: false,
  showModal: false,
  overlaps: 0,
}

//Thunks
export const fetchTimeOffList = createAsyncThunk(
  'timeoff/fetchTimeoffList',
  async () => {
    const { data } = await getTimeOff()
    return transformArrayToObj(data)
  },
)

export const updateTimeOff = createAsyncThunk<
  TimeOff,
  TimeOff,
  {
    rejectValue: { [key: string]: string }
  }
>('timeoff/updateTimeOff', async (timeoff, { rejectWithValue }) => {
  try {
    const updatedTimeOff = await putTimeOff(timeoff.id, timeoff)
    return updatedTimeOff
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const createTimeOff = createAsyncThunk<
  TimeOff,
  TimeOff,
  {
    rejectValue: { [key: string]: string }
  }
>('timeoff/createTimeOff', async (timeoff, { rejectWithValue }) => {
  try {
    const createdTimeOff = await postTimeOff(timeoff)
    return createdTimeOff
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const deleteTimeOff = createAsyncThunk<
  number,
  number,
  {
    rejectValue: { [key: string]: string }
  }
>('timeoff/deleteTimeOff', async (id, { rejectWithValue }) => {
  try {
    await deleteTimeoff(id)
    return id
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})
//

const timeoffSlice = createSlice({
  name: 'timeoff',
  initialState,
  reducers: {
    setTimeOffList: (state, action: PayloadAction<Record<number, TimeOff>>) => {
      state.list = { ...state.list, ...action.payload }
    },
    timeOffSet: (state, action) => {
      state.item = action.payload
      state.showModal = true
    },
    // timeOffUpdated: (state, action) => {
    //   const index = state.list.findIndex(timeoff => timeoff.id === action.payload.id)
    //   state.list[index] = action.payload
    //   state.showModal = false
    // },
    toggleTimeOffModal: (state, action) => {
      if (action.payload) {
        state.item = initialItem
        state.error = null
      }
      state.showModal = !state.showModal
    },
    overlapsReseted: state => {
      state.overlaps = 0
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchTimeOffList.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchTimeOffList.fulfilled, (state, action) => {
      state.loading = false
      state.list = { ...state.list, ...action.payload }
    })
    builder.addCase(fetchTimeOffList.rejected, state => {
      state.loading = false
    })
    //
    //Create case
    builder.addCase(createTimeOff.fulfilled, (state, action) => {
      if (action.payload.count) {
        state.overlaps = action.payload.count
        return
      }

      // state.list.push(action.payload)
      state.list[action.payload.id] = action.payload
      state.showModal = false
      openNotificationWithIcon('success', {
        message: 'Time Off created successfully',
      })
    })
    builder.addCase(createTimeOff.rejected, (state, action) => {
      if (!action.payload) return
      state.error = action.payload
      openNotificationWithIcon('error', {
        message: Object.values(action.payload),
      })
    })
    //
    //Update case
    builder.addCase(updateTimeOff.fulfilled, (state, action) => {
      if (action.payload.count) {
        state.overlaps = action.payload.count
        return
      }

      state.list[action.payload.id] = action.payload
      state.showModal = false
      openNotificationWithIcon('success', {
        message: 'Time Off updated successfully',
      })
    })
    builder.addCase(updateTimeOff.rejected, (state, action) => {
      if (!action.payload) return
      state.error = action.payload
      openNotificationWithIcon('error', {
        message: Object.values(action.payload)?.[0],
      })
    })
    //
    //Delete case
    builder.addCase(deleteTimeOff.fulfilled, (state, action) => {
      // state.list = state.list.filter(({ id }) => id !== action.payload)
      delete state.list[action.payload]
      state.showModal = false
      openNotificationWithIcon('success', {
        message: 'Time Off deleted successfully',
      })
    })
    builder.addCase(deleteTimeOff.rejected, (state, action) => {
      if (!action.payload) return
      state.error = action.payload
      openNotificationWithIcon('error', {
        message: Object.values(action.payload)?.[0],
      })
    })
  },
})

export default timeoffSlice.reducer
export const {
  timeOffSet,
  toggleTimeOffModal,
  /* timeOffUpdated,  */ overlapsReseted,
  setTimeOffList,
} = timeoffSlice.actions

//Selectors

export const selectTimeoffList: Selector<AppStateType, TimeOff[]> = state =>
  state.orcatec.timeoff.list ? Object.values(state.orcatec.timeoff.list) : []

export const selectSortedTimeOffListWithUser = createSelector(
  selectTimeoffList,
  list => list.filter(item => !!item?.user)?.sort((a, b) => b.id - a.id),
)
