import React from 'react'
import './TestIcon.scss'

export const statuses = {
  1: 'active',
  2: 'in_process',
  3: 'completed',
  4: 'canceled',
}

function TestIcon({ profilePicture, title, color, status = '', sn, isJob }) {
  return (
    <div className='map-label employee'>
      <div
        className={`map-label-content ${profilePicture ? 'map-label-content-icon' : ''} ${!!status &&
          statuses[status]}`}
        style={isJob ? { border: '1px dashed black' } : { borderColor: color }}
      >
        {profilePicture ? <img src={profilePicture} alt='ava' /> : title ? title + (sn ? ` - ${sn}` : '') : 'I'}
      </div>
      <div className='map-label-arrow' style={{ borderColor: `${color} transparent transparent transparent` }} />
    </div>
  )
}

export default TestIcon
