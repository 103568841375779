import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../../../../store/Orcatec/actions'
// import { Modal } from 'react-bootstrap'
import { Modal } from 'antd'
import ClientForm from './components/Form'
import './style.scss'
import { openNotificationWithIcon } from '../../../../../../helpers/notifications/openNotificationWithIcon'
import { duration } from 'moment-timezone'
import { makeBrowserTitle, Titles } from 'helpers/makeBrowserTitle'

const ClientModal = ({
  modal,
  close,
  clientId,
  setCurrentClient,
  fetchClientsList,
  onChangeClientId,
  updateClientList,
  setClientId,
  currentTab,
  isOrganization,
  clientModal,
  wasEditorField,
  setIsModalClose,
}) => {
  const [currentName, setCurrentName] = useState('')
  const [isCloseClientForm, setIsCloseClientForm] = useState(false)

  useEffect(() => {
    if (window.location.pathname.includes('clients')) {
      makeBrowserTitle({
        prefixForTitle: currentName,
        customTitle: isOrganization ? Titles.organizations : Titles.clients,
      })
    }
  }, [modal])

  useEffect(() => {
    if (!modal && !!currentName) setCurrentName('')
  }, [modal])
  const heandler = () => {
    setIsModalClose(false)
  }
  const onCloseHeandler = () => {
    if (!wasEditorField.wasEdit) {
      setIsModalClose(false)
      setCurrentClient({})
      setIsCloseClientForm(true)
      close()

      return
    }
    setIsModalClose(true)
  }

  const modalTitle = () =>
    clientId
      ? `Edit ${currentTab === 'clients' ? 'client' : 'organization'} ${
          currentName ? ` - ${currentName}` : ''
        }`
      : `Add new ${currentTab === 'clients' ? 'client' : 'organization'}`

  return (
    <Modal
      visible={modal}
      onCancel={onCloseHeandler}
      title={<h4>{modalTitle()}</h4>}
      className='client-modal'
      footer={null}
      width={1100}
      maskClosable={false}
      style={{ top: 50 }}
      destroyOnClose
      bodyStyle={{ minHeight: '450px' }}
    >
      <ClientForm
        closeForm={close}
        clientId={clientId}
        fetchClientsList={fetchClientsList}
        onChangeClientId={onChangeClientId}
        updateClientList={updateClientList}
        setClientId={id => setClientId(id)}
        isOrganization={isOrganization}
        changeModalTitle={true}
        setCurrentNameToModalTitle={setCurrentName}
        setIsCloseClientForm={setIsCloseClientForm}
        isCloseClientForm={isCloseClientForm}
      />
      {wasEditorField.wasEdit &&
        wasEditorField.isCloseModal &&
        openNotificationWithIcon(
          'error',
          { message: 'You have unsaved data' },
          10,
          heandler,
        )}
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  setCurrentClient: data => dispatch(actions.client.setCurrentClient(data)),
  setIsModalClose: state => dispatch(actions.modals.isCloseModal(state)),
})

const mapStateToProps = state => ({
  wasEditorField: state.orcatec.ui,
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientModal)
