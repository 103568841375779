import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ScrollArea, Wrapper } from './ScrollAreas.styles'
// import * as actions from '../../../../../store/Orcatec/actions'
import { scrollDirectionChanged } from 'features/Dispatch/dispatchSlice'

export type ScrollAreaPosition = 'left' | 'right' | 'top' | 'bottom'

const positions: ScrollAreaPosition[] = ['left', 'right', 'top', 'bottom']

interface Props {
  children: React.ReactNode
  areaSize: number
  scrollContainerSelector: string
  disablePointerEvents?: boolean
}
export enum ScrollDirection {
  up = 'up',
  down = 'down',
}
const threshold = 1

export const ScrollAreas = ({ children, areaSize, scrollContainerSelector, disablePointerEvents }: Props) => {
  const [scrollPosition, setScrollPostion] = useState({
    scrollTop: 0,
    scrollLeft: 0,
    scrollHeight: 0,
    scrollWidth: 0,
    clientHeight: 0,
    clientWidth: 0,
  })
  const dispatch = useDispatch()
  useEffect(() => {
    const container = document.querySelector(scrollContainerSelector)

    if (container) {
      container.addEventListener('scroll', handleScrollEvent)
    }

    return () => {
      container?.removeEventListener('scroll', handleScrollEvent)
      dispatch(scrollDirectionChanged(ScrollDirection.up))
    }
  }, [scrollContainerSelector])
  let scrollDir = ScrollDirection.up
  const handleScrollEvent = e => {
    const { scrollTop, scrollLeft, scrollHeight, scrollWidth, clientWidth, clientHeight } = e.target

    if (scrollTop > threshold && scrollDir === ScrollDirection.up) {
      scrollDir = ScrollDirection.down
      dispatch(scrollDirectionChanged(ScrollDirection.down))
    }
    if (scrollTop < threshold && scrollDir === ScrollDirection.down) {
      scrollDir = ScrollDirection.up
      dispatch(scrollDirectionChanged(ScrollDirection.up))
    }

    setScrollPostion({
      scrollTop,
      scrollLeft,
      scrollHeight,
      scrollWidth,
      clientHeight,
      clientWidth,
    })
  }

  const handleDragOver = (position: ScrollAreaPosition) => {
    const container = document.querySelector(scrollContainerSelector)

    if (position === 'left' || position === 'right') {
      container?.scrollBy(position === 'left' ? -8 : 8, 0)
    } else {
      container?.scrollBy(0, position === 'top' ? -8 : 8)
    }
  }

  const getShow = (position: ScrollAreaPosition) =>
    (position === 'left' && !!scrollPosition.scrollLeft) ||
    (position === 'right' && scrollPosition.scrollWidth - scrollPosition.scrollLeft !== scrollPosition.clientWidth) ||
    (position === 'top' && !!scrollPosition.scrollTop) ||
    (position === 'bottom' && scrollPosition.scrollHeight - scrollPosition.scrollTop - scrollPosition.clientHeight >= 0)

  return (
    <Wrapper>
      {positions.map(el => (
        <ScrollArea
          key={el}
          position={el}
          areaSize={areaSize}
          show={getShow(el)}
          onDragOver={() => handleDragOver(el)}
          disablePointerEvents={disablePointerEvents}
        />
      ))}
      {children}
    </Wrapper>
  )
}
