import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { exportToQuickBooks } from 'api/quickbooks'
import { AsyncThunkAPI } from 'features/Dispatch/types'
import { setValueToLS } from 'hooks/useLocalStorage'
import { AppStateType } from 'store'

interface QuickbooksSlice {
  status: 'idle' | 'exporting' | 'finished'
  progress: number
}

const initialState: QuickbooksSlice = {
  status: 'idle',
  progress: 0,
}

export const exportToQuickbooks = createAsyncThunk<void, void, AsyncThunkAPI>(
  'quickbooks/exportData',
  async (_, { dispatch }) => {
    dispatch(statusChanged('exporting'))
    await exportToQuickBooks()
  },
)

export const quickBooksSlice = createSlice({
  name: 'quickbooks',
  initialState,
  reducers: {
    progressSet: (state, action) => {
      state.progress = action.payload

      setValueToLS('quickbooks', {
        uploadProgress: action.payload,
      })
    },
    statusChanged: (state, action: PayloadAction<QuickbooksSlice['status']>) => {
      state.status = action.payload

      if (action.payload === 'idle') {
        state.progress = 0

        setValueToLS('quickbooks', {
          uploadProgress: 0,
        })
      }

      if (action.payload === 'finished') {
        state.progress = 100

        setValueToLS('quickbooks', {
          uploadProgress: 100,
        })
      }
    },
  },
})

export const { progressSet, statusChanged } = quickBooksSlice.actions
export default quickBooksSlice.reducer

//Selectors
export const selectQuickbooks = (state: AppStateType) => state.orcatec.quickBooksSlice
