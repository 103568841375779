import { AddIcon } from '../Icons/CommonIcons'
import {connect} from 'react-redux'

interface Props {
  activeTab: string
  label: string
  onClick: (label: string) => void
  onAdd?: () => void
  count: number
  disabled?: boolean
  title: string
  wasEditorField:boolean,
}

const Tab: React.FC<Props> = ({ wasEditorField,activeTab, label, onClick, count, onAdd, disabled = false, title }) => {
  const handleClick = () => {
    if (disabled) return

    if (activeTab === label) {
      if (!onAdd) return
      onAdd()
    } else {
      onClick(label)
    }
  }
  const handleAdd = () => {
    if (disabled) return

    onClick(label)
    if (onAdd) onAdd()
  }
  return (
    <li className={`tabss-list-item${activeTab === label ? ' active' : ''}${wasEditorField && activeTab !== label ? ' disabled' : ''}`}>
      <span className='tabss-list-item-label' onClick={handleClick}>
        {title ? title : label}
      </span>
      {onAdd && (
        <span onClick={handleAdd}>
          <AddIcon disabled={disabled} />
        </span>
      )}
      {count >= 0 && <span className='counter'>{count}</span>}
    </li>
  )
}
const mapStateToProps = state => ({
  wasEditorField: state.orcatec.ui.wasEdit,
})
export default connect(mapStateToProps,null)(Tab)
