import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { DatePicker } from 'antd'
import './TimeCardList.scss'
import MainButton from '../../../components/buttons/MainButton'

export const AddNewDay = ({ onAddNewDay, modalValues, startDate, endDate, setCardData, setNewDay, days, timezone }) => {
  const [newDayDate, setNewDayDate] = useState(null)
  const [addDayLoader, setAddDayLoader] = useState(false)
  const [error, setError] = useState(false)

  const onPickDay = date => {
    setNewDayDate(date)
  }

  const disabledDate = current => {
    /* debugger
    console.log(moment(current).format('YYYY-MM-DD hh:mm a'))
    console.log(moment(current).isAfter(moment(endDate).endOf('day')))
    console.log(moment(current).isBefore(moment(startDate)))
    console.log(moment(current).isAfter(new Date()))
    console.log('--------------------------------------------') */
    if (
      moment(current).isAfter(moment(endDate).endOf('day')) ||
      moment(current).isBefore(moment(startDate)) ||
      moment(current).isAfter(new Date())
    ) {
      return current
    }
    if (days) {
      const daysList = Object.keys(days)
      for (let i = 0; i < daysList.length; i++) {
        if (moment(daysList[i], 'YY-MM-DD').isSame(current, 'day')) return current
      }
    }
  }

  return (
    <div className='border rounded col-md-12 position-relative mb-2' style={{ padding: '12px' }}>
      <div className='row mt-2 mb-2 d-flex justify-content-between'>
        <div className='row' style={{ margin: 0 }}>
          <div className='mr-3 ml-3'>
            <DatePicker
              style={{ border: 0, width: 180 }}
              format={'MM/DD/YYYY'}
              selected={newDayDate ? newDayDate : null}
              disabledDate={disabledDate}
              onChange={date => onPickDay(date)}
              className='form-control'
              placeholderText='Select day'
            />
          </div>

          <MainButton title='Cancel' type='cancel' onClick={() => setNewDay(false)} className='btn-cancel' />
          <MainButton
            disabled={error || !newDayDate}
            isFetching={addDayLoader}
            title='Add Day'
            onClick={() => onAddNewDay(newDayDate)}
            className='ml-3'
          />
        </div>
      </div>
    </div>
  )
}
