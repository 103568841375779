import { MenuItem } from '@material-ui/core'
import InputField, { InputProps } from '../Input'

export interface SelectOption {
  id: number | string
  name: string
  disabled?: boolean
}

type SelectProps = InputProps & {
  options: SelectOption[]
  noValue?: boolean
  hideDisabled?: boolean
  customValueDisabled?: boolean
}

const SelectField: React.FC<SelectProps> = ({
  label,
  options,
  noValue,
  hideDisabled,
  customValue,
  customTitle = '(default)',
  customValueDisabled = false,
  ...rest
}) => {
  const filteredOptions = hideDisabled
    ? options?.filter(option => !option?.disabled)
    : options

  return (
    <InputField label={label} select {...rest}>
      {noValue && <MenuItem value=''>No {label}</MenuItem>}
      {customValue?.id && (
        <MenuItem disabled={customValueDisabled} value={customValue.id}>
          {customValue.name}{' '}
          <span style={{ color: 'grey' }}>{customTitle}</span>
        </MenuItem>
      )}
      {filteredOptions
        ?.filter(e => e.id !== customValue?.id)
        ?.map(option => (
          <MenuItem
            key={typeof option !== 'string' ? option?.id : option}
            value={typeof option !== 'string' ? option?.id : option}
            disabled={typeof option !== 'string' ? option?.disabled : false}
          >
            {typeof option !== 'string' ? option?.name : option}
          </MenuItem>
        ))}
    </InputField>
  )
}

export default SelectField

SelectField.displayName = 'Select'
