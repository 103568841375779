import styled from 'styled-components'
import { generateMargin } from '../../helpers'

interface ItemStyle {
  level?: number
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 35% 10% 20% 25% 10%;
  gap: 10px;
`

const GridItem = styled.div`
  min-height: 34px;
`

const ItemWrapper = styled.div<ItemStyle>`
  padding-left: ${props => `${generateMargin(props.level || 0)}px`};
  font-size: 14px;
  border-top: 1px solid rgba(237, 239, 247, 1);
`

const FirstColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemLink = styled.span`
  cursor: pointer;
  transition: 0.2s all;
  width: fit-content;
  &:hover {
    color: var(--main-color);
    transition: 0.2s all;
  }
`

const TableWrapper = styled.div`
  padding: 20px;
  /* max-height: 600px; */
  overflow-x: hidden;
  background: rgba(252, 252, 253, 1);
`

export {
  GridContainer,
  GridItem,
  TableWrapper,
  ItemWrapper,
  FirstColWrapper,
  ItemLink,
}
