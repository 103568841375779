import * as types from '../types'
import { fromJS } from 'immutable'

/**
 * This actions is deprecated and should be remove, not use it.
 * @deprecated
 */

/**
 * Set items to redux storage.
 *
 * @param rows
 * @param columns
 * @param newRow
 * @return {{type: string, items: {} & {rows: *, checkedRows: *, columns: *}}}
 * @deprecated
 *
 */
export const setTable = (rows, columns, newRow = {}) => {
  return {
    type: types.table.TABLE_SET_TABLE,
    table: fromJS({
      rows: rows,
      columns: columns,
      newRow: newRow,
    }),
  }
}

/**
 * Set rows.
 *
 * @param rows
 * @return {{type: string, rows: *}}
 */
export const setRows = rows => {
  return {
    type: types.table.TABLE_SET_ROWS,
    rows,
  }
}

/**
 * Set row to table.
 *
 * @param row
 * @return {{type: string, row: *}}
 * @deprecated
 */
export const setRow = row => {
  return {
    type: types.table.TABLE_SET_ROW,
    row,
  }
}

/**
 * Det row to table.
 *
 * @param id
 * @return {{type: string, row: *}}
 */
export const delRow = id => {
  return {
    type: types.table.TABLE_DEL_ROW,
    id,
  }
}

/**
 * Remove first row.
 *
 * @return {{type: string}}
 */
export const removeFirstRow = () => {
  return {
    type: types.table.TABLE_REMOVE_FIRST_ROW,
  }
}

/**
 * Change new row.
 *
 * @param field
 * @param value
 * @return {{type: string, row: *}}
 */
export const changeNewRow = (field, value) => {
  return {
    type: types.table.TABLE_CHANGE_NEW_ROW,
    field,
    value,
  }
}

export const refreshRows = rowFunc => (dispatch, getState) => {
  const state = getState()
  const items = [...state.orcatec.item.items.items]

  const rows = []
  items.forEach((item, index) => {
    const row = rowFunc(index)
    rows.push(row)
  })

  dispatch(setRows(rows))
}
