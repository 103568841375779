import styled from 'styled-components'

interface Props {
  minimalHeaderView: boolean
}
interface IProps {
  active: boolean
}

export const MenuWrapper = styled.div<Props>`
  & > ul {
    border-bottom: none;

    li {
      padding: 0 10px !important;
      &::after {
        display: ${({ minimalHeaderView }) =>
          minimalHeaderView ? 'none' : 'inherit'};
      }
    }
  }
`

export const ItemWrapper = styled.div`
  text-align: center;

  &:hover {
    & > i > svg {
      fill: #4285f4;
      path {
        fill: #4285f4;
        //fill: #448aff;
        //stroke: #448aff; // ?
      }
    }
  }
`

export const MinItemWrapper = styled.div<IProps>`
  ${props =>
    props.active &&
    `
    .header-menu__link {
      border: 1px solid #5087ec;
      border-radius: 8px;
      padding: 5px 8px;
      & .header-menu__title{
        font-weight:500;
      }
      & svg {
        fill: #5087ec;
      }
      & i > svg {
      fill: #4285f4;
      }

      & path {
        fill: #4285f4;
      }
    }
    }
  `}

  .header-menu__link {
    text-align: center;
    display: flex;
    align-items: center;
    height: 32px;

    .header-menu__icon {
      display: flex;
      align-items: center;
    }

    .header-menu__title {
      width: 0;
      overflow: hidden;
    }

    ${props =>
      props.active &&
      `
      .header-menu__title {
        width: 100%;
        overflow: visible;
       // transition: all 0.3s ease;
        margin-left: 10px;
        color: #5087ec;
      }
    `}

    &:hover {
      .header-menu__title,
      .header-menu__link {
        width: 100%;
        overflow: visible;
        //transition: all 0.3s ease;
        margin-left: 10px;
      }

      .header-menu__title {
        color: #5087ec;
      }

      border: 1px solid #5087ec;
      border-radius: 8px;
      padding: 5px 8px;
    }
  }

  &:hover {
    > i > svg {
      fill: #4285f4;

      path {
        fill: #4285f4;
      }
    }
  }
`
