import React from 'react'

export interface IBusinessRoute {
  key: string
  title: string
  route: string
  icon: React.ReactNode
  isAllowed: boolean
}

export enum IHeaderType {
  HEADER_HORIZONTAL = 1,
  HEADER_HORIZONTAL_MIN_WITH_TEXT = 2,
  HEADER_HORIZONTAL_MIN = 3,
  HEADER_VERTICAL = 4,
}
