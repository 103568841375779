import React, {
  FC,
  useState,
  useEffect,
  ChangeEventHandler,
  useRef,
} from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Modal, Steps, message, Form, Alert } from 'antd'
import { SharedEventsTable } from '../Table/SharedEventsTable'
import { ContactForm } from '../Forms/ContactForm'
import { NotificationForm } from '../Forms/NotificationForm'
import { IAppointment } from 'types/Appointment'
import { Job } from 'types/Jobs'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { IContact } from '../types'
import {
  createPublicContact,
  deletePublicContact,
  createPublicLink,
  generatePublicLink,
} from 'api/PublicEvent/PublicEvent'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import {
  parsePhoneNumberBeforeSend,
  parsePhoneNumber,
} from 'features/Messaging/utils'
import { ContactCard } from '../components/ContactCard/ContactCard'
import useWindowSize from 'hooks/useWindowSize'
import { useMessagingPhones } from 'features/Messaging/hooks/useMessagingPhones'
import { useAppSelector } from 'store/Orcatec/hooks'
import { isMessagingSubmitted } from 'store/Orcatec/selectors/messaging'
import {
  selectCompanyTimezone,
  selectJobDictionary,
} from 'store/Orcatec/selectors/company'
import moment from 'moment-timezone'
import {
  removeSpace,
  removeTagsSms,
} from 'features/Notification/modals/NotificationModal/NotificationModal'
import { useQueryClient } from '@tanstack/react-query'
import validator from 'validator'
import { LinkOutlined } from '@ant-design/icons'
import { setCountOfLinks } from 'features/Dispatch/dispatchSlice'
import { CheckCircleOutline } from '@material-ui/icons'
import { SubscriptionPlan } from 'features/Settings/Subscription/types'
import { selectSubscriptionPlan } from 'features/Settings/Subscription/slices/subscriptionSlice'

// const { Search } = Input
// const { Paragraph, Link } = Typography

const errorMessage = `Oops! Something went wrong with your request. It appears that there's an issue with the data you provided. Please review your input and make sure it meets the required criteria. If you continue to experience problems, please contact our support team for assistance.`
interface IProps {
  visible: boolean
  onCancel: () => void
  event: IAppointment | Job
}

enum StepsEnum {
  CONTACT = 0,
  NOTIFICATION = 1,
}

enum TabsEnum {
  TABLE = '0',
  FORM = '1',
}

export const initState: IContact = {
  id: 0,
  full_name: '',
  phones: [
    {
      phone: '',
      id: 0,
    },
  ],
  emails: [{ email: '', id: 0 }],
}

const mobileView = 1000

const phoneReg = /^\(\d{3}\) \d{3}-\d{4}$/

const emailReg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/

export const PublicEventModal: FC<IProps> = ({ visible, onCancel, event }) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const size = useWindowSize()
  const { phones } = useMessagingPhones()
  const btnRef = useRef()
  const [form] = Form.useForm()

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [formIsEmpty, setFormIsEmpty] = useState(true)
  const [fieldStatus, setFieldStatus] = useState({
    full_name: false,
    phones: [false],
    emails: [false],
  })
  const subscriptionPlan = useAppSelector(selectSubscriptionPlan)
  const canUseMessaging = useAppSelector(isMessagingSubmitted)
  const timezone = useAppSelector(selectCompanyTimezone)
  const dictionary = useAppSelector(selectJobDictionary)
  const [template, setTemplate] = useState({})
  const [current, setCurrent] = useState<number>(StepsEnum.CONTACT)
  const [currentTab, setCurrentTab] = useState<string>(TabsEnum.TABLE)

  const [contacts, setContacts] = useState<IContact[]>([])
  const [emailContacts, setEmailContacts] = useState<IContact[]>([])
  const [selectedCompanyPhone, setSelectedCompanyPhone] = useState('')
  const [emailValidation, setEmailValidation] = useState({
    body: false,
    from_name: false,
    reply_to: false,
    subject: false,
  })

  const [smsValidation, setSmsValidation] = useState({
    body: false,
  })

  const [isValidEmailTemplate, setIsValidEmailTemplate] = useState(false)
  const [isValidPhones, setIsValidPhones] = useState(false)

  const [generateLink, setGenerateLink] = useState<{
    id: number
    url: string
  } | null>(null)

  const [otherEmails, setOtherEmails] = useState({
    isChecked: false,
    emails: [],
    inputValue: '',
    isValid: true,
    error: {},
  })
  const [otherPhone, setOtherPhone] = useState({
    phone: '',
    error: false,
    isChecked: false,
  })
  const [linkCopiing, setLinkCopiing] = useState(false)
  const [limitOver, setLimitOver] = useState('')

  const onChange = (value: number) => {
    setCurrent(value)
  }

  const onChangeTab = (value: string) => {
    setCurrentTab(value)
  }

  const validateTemplate = (
    e: ChangeEventHandler<HTMLImageElement>,
    type: 'sms' | 'email',
  ) => {
    const { name, value } = e.target
    if (type === 'email') {
      if (name === 'reply_to') {
        setEmailValidation(prev => ({
          ...prev,
          [name]: !validator.isEmail(value),
        }))
      } else {
        setEmailValidation(prev => ({
          ...prev,
          [name]: !value,
        }))
      }
    }

    if (type === 'sms') {
      setSmsValidation(prev => ({
        ...prev,
        [name]: !value,
      }))
    }
  }

  const handleChangeTemplate = (
    e: ChangeEventHandler<HTMLImageElement>,
    type: 'sms' | 'email',
  ) => {
    const { name, value } = e.target

    setTemplate(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        [name]: value,
      },
    }))
    validateTemplate(e, type)
  }

  const handleChangePhone = (value: string) => {
    setSelectedCompanyPhone(value)
  }

  const handleAddContact = async (contact: IContact) => {
    try {
      const data = {
        ...contact,
        event_link_id: generateLink?.id,
        emails: contact.emails.filter(({ email }) => email && { email }),
        phones: contact.phones
          .filter(({ phone }) => phone)
          .map(phone => ({ phone: parsePhoneNumberBeforeSend(phone.phone) })),
      }

      const createdContact = await createPublicContact(data)

      const prepareData = {
        ...createdContact,
        phones: createdContact.phones.map(data => ({
          ...data,
          phone: parsePhoneNumber(data.phone),
        })),
      }

      const prepareDataForTemplate = {
        ...prepareData,
        phones: prepareData.phones.map(item => ({ ...item, checked: true })),
        emails: prepareData.emails.map(item => ({ ...item, checked: true })),
      }

      setContacts(prev => [...prev, prepareData])
      setEmailContacts(prev => [...prev, prepareDataForTemplate])
    } catch (error) {
      openNotificationWithIcon('error', { message: error.message })
    }
  }

  const handleDeleteContact = async (id: number) => {
    try {
      await deletePublicContact(id)
      setContacts(prev => prev.filter(p => p.id !== id))
      setEmailContacts(prev => prev.filter(p => p.id !== id))
    } catch (error) {
      openNotificationWithIcon('error', { message: error.message })
    }
  }

  const onChangeSelected = (
    checked: boolean,
    type: 'emails' | 'phones',
    id: number,
  ) => {
    setEmailContacts(prev =>
      prev.map(contact => ({
        ...contact,
        [type]: contact[type].map(item =>
          item.id === id ? { ...item, checked } : item,
        ),
      })),
    )
  }

  const clearState = () => {
    setTemplate({})
    setCurrent(StepsEnum.CONTACT)
    setCurrentTab(TabsEnum.TABLE)
    setContacts([])
    setEmailContacts([])
    setGenerateLink(null)
    setOtherEmails({
      isChecked: false,
      emails: [],
      inputValue: '',
      isValid: true,
      error: {},
    })
    setOtherPhone({
      phone: '',
      error: false,
      isChecked: false,
    })
    form.resetFields()
    setIsButtonDisabled(false)
    setFormIsEmpty(true)
    setFieldStatus({
      full_name: false,
      phones: [false],
      emails: [false],
    })
    setLimitOver('')
  }

  const handleCreatePublikLink = async () => {
    const settings = {
      send_at: moment()
        .tz(timezone)
        .add(1, 'minute')
        .format('YYYY-MM-DD HH:mm'),
      send_at_timezone: timezone,
    }

    const prepareData = {
      event_link_id: generateLink?.id,
      email: {
        ...template.email,
        ...settings,
        recipients: {
          clients: emailContacts
            .map(item => item.emails.filter(item => item.checked))
            .flat(1)
            .map(item => item.email),
          other: otherEmails.emails,
        },
      },

      sms: {
        ...template.sms,
        ...settings,
        body: removeSpace(removeTagsSms(template.sms.body)),
        from_number: phones.find(phone => phone.id === selectedCompanyPhone)
          ?.number,
        recipients: {
          clients: emailContacts
            .map(item => item.phones.filter(item => item.checked))
            .flat(2)
            .map(item => parsePhoneNumberBeforeSend(item.phone)),
          other: otherPhone?.phone
            ? [parsePhoneNumberBeforeSend(otherPhone.phone)]
            : [],
        },
      },
    }

    if (!canUseMessaging || !phones?.length || !isValidPhones) {
      delete prepareData.sms
    }

    try {
      await createPublicLink(event.id, prepareData)
      openNotificationWithIcon('success', {
        message: 'Notification was sent successfully.',
      })
      clearState()

      queryClient.invalidateQueries({ queryKey: ['shared_links/table'] })
    } catch (error) {
      console.error(error)
      openNotificationWithIcon('error', {
        message: errorMessage,
      })
    }
  }

  const handleOtherEmailsInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { type } = e

    const { value, checked } = e.target

    if (type === 'change') {
      setOtherEmails(prev => ({
        ...prev,
        isChecked: !!value?.length,
        inputValue: value,
      }))
      const isValidEmailArray: boolean[] = []
      const emailsArray = value.trim().split(', ')
      emailsArray.forEach((email: string): void => {
        if (email.length)
          isValidEmailArray.push(Boolean(validator.isEmail(email)))
      })

      if (isValidEmailArray.some(el => !el)) {
        setOtherEmails(prev => ({
          ...prev,
          isValid: prev.isChecked && false,
          error: true,
        }))
      } else {
        setOtherEmails(prev => ({ ...prev, isValid: true, error: false }))
      }

      if (isValidEmailArray.every(el => el)) {
        setOtherEmails(prev => ({
          ...prev,
          isValid: true,
          error: false,
          emails: emailsArray,
        }))
      }
    }

    if (type === 'checkbox') {
      setOtherEmails(prev => ({
        ...prev,
        isChecked: checked,
        isValid: true,
      }))
    }

    if (value === '') setOtherEmails(prev => ({ ...prev, emails: [] }))
  }

  const handleChangeOtherPhoneInput = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { type } = e
    const { value, checked } = e.target

    if (type === 'checkbox') {
      setOtherPhone(prev => ({
        ...prev,
        isChecked: checked,
        error: checked && !phoneReg.test(prev.phone),
      }))
    }

    if (type === 'change') {
      const isValidPhone = phoneReg.test(value)

      setOtherPhone(prev => ({
        ...prev,
        phone: value,
        error: !isValidPhone && prev.isChecked,
        isChecked: !!value?.length && value !== '(XXX) XXX-XXXX',
      }))
    }
  }

  const handleClickGenerateLink = async () => {
    try {
      onChangeTab(TabsEnum.FORM)
      setCurrent(StepsEnum.CONTACT)
      const { link, warning = '' } = await generatePublicLink(event.id)

      setLimitOver(warning)
      setGenerateLink(link)

      if (!warning) message.success('Link was created.')
    } catch (error) {
      openNotificationWithIcon('error', {
        message: error.response?.data?.message || 'Something went wrong',
      })

      onChangeTab(TabsEnum.TABLE)
    }
  }

  const handleCopyLinkToClipboard = (): void => {
    setLinkCopiing(true)
    navigator.clipboard
      .writeText(generateLink?.url)
      .catch(err => console.error('error', err))
      .finally(() => {
        setTimeout(() => setLinkCopiing(false), 2000)
      })
  }

  const validateIsAbilityToSend = () => {
    const isValidEmailTemplateInfo = Object.values(emailValidation).every(
      key => !key,
    )
    const isValidSmsTemplateInfo = Object.values(smsValidation).every(
      key => !key,
    )
    const isValidOtherEmail = otherEmails.isChecked
      ? otherEmails.isValid
      : false
    const isValidOtherPhone = otherPhone.isChecked ? !otherPhone.error : false
    const isSelectedEmails =
      !!emailContacts
        ?.map(item => item.emails.filter(item => item.checked))
        .flat(1).length &&
      !!emailContacts.map(item => item.emails).flat(1).length
    const isSelectedSms =
      !!emailContacts
        ?.map(item => item.phones.filter(item => item.checked))
        .flat(1).length &&
      !!emailContacts.map(item => item.phones).flat(1).length
    if (!isMessagingSubmitted || !phones.length) {
      setIsValidEmailTemplate(
        isValidEmailTemplateInfo && (isSelectedEmails || isValidOtherEmail),
      )
    } else {
      const validInfo = isValidEmailTemplateInfo && isValidSmsTemplateInfo
      const validEmail = isValidOtherEmail || isSelectedEmails
      const validPhone = isValidOtherPhone || isSelectedSms

      setIsValidEmailTemplate(validInfo && (validEmail || validPhone))
      setIsValidPhones(validPhone)
    }
  }

  const onFinish = async (values: any) => {
    try {
      await form.validateFields().then(() => {
        handleAddContact(values)
        form.resetFields()
        setIsButtonDisabled(false)
        setFieldStatus({
          full_name: false,
          phones: [false],
          emails: [false],
        })
      })
    } catch (errorInfo) {
      return false
    }
  }

  const handleFormChange = () => {
    const values = form.getFieldsValue()

    const validateObj = {
      full_name: !!values.full_name,
      phones: values.phones.map(
        phone => !!phone?.phone && phoneReg.test(phone?.phone),
      ),
      emails: values.emails.map(
        email => !!email?.email && emailReg.test(email?.email),
      ),
    }

    const isAllValid = [
      validateObj.full_name,
      ...validateObj.phones,
      ...validateObj.emails,
    ].every(item => !item)

    setIsButtonDisabled(isAllValid)

    setFieldStatus(validateObj)
  }

  const isAllValid = () => {
    const values = form.getFieldsValue()
    const name = !!values?.full_name
    const phones = values?.phones?.filter(phone => !!phone?.phone)?.length
    const emails = values?.emails?.filter(email => !!email?.email)?.length
    const isFormFilled = name || !!phones || !!emails

    setFormIsEmpty(!isFormFilled)
  }

  const handleSkipOrNext = async () => {
    if (formIsEmpty) {
      return onChange(StepsEnum.NOTIFICATION)
    }

    try {
      const values = form.getFieldsValue()

      await form.validateFields().then(() => {
        handleAddContact(values)
        form.resetFields()
        setIsButtonDisabled(false)
        setFieldStatus({
          full_name: false,
          phones: [false],
          emails: [false],
        })
        onChange(StepsEnum.NOTIFICATION)
      })
    } catch (errorInfo) {
      return false
    }
  }

  useEffect(() => {
    isAllValid()
  }, [fieldStatus, current, currentTab])

  useEffect(() => {
    if (phones.length) setSelectedCompanyPhone(phones?.[0]?.id)
  }, [phones])

  useEffect(() => {
    if (!event?.count_public_links && visible) {
      handleClickGenerateLink()
      dispatch(
        setCountOfLinks({
          eventId: event.id,
          count: event?.count_public_links + 1,
        }),
      )
    }
  }, [event, visible])

  useEffect(() => {
    validateIsAbilityToSend()
  }, [emailValidation, smsValidation, emailContacts, otherEmails, otherPhone])

  const steps = [
    {
      content: (
        <Form
          initialValues={initState}
          onFieldsChange={handleFormChange}
          form={form}
          name='contact-form'
          onFinish={onFinish}
          style={{ width: '100%', maxWidth: '600px' }}
          validateTrigger={['onBlur']}
        >
          <ContactForm
            formIsEmpty={formIsEmpty}
            handleAddContact={handleAddContact}
            handleFormChange={handleFormChange}
            isButtonDisabled={isButtonDisabled}
            form={form}
            fieldStatus={fieldStatus}
          />
        </Form>
      ),

      title: (
        <p style={{ color: current === StepsEnum.CONTACT ? '#1890ff' : '' }}>
          Contacts
        </p>
      ),
      disabled: false,
      status: current === StepsEnum.CONTACT ? 'process' : '',
    },
    {
      content: (
        <NotificationForm
          template={template}
          setTemplate={setTemplate}
          contacts={emailContacts}
          event={event}
          isMessagingUse={canUseMessaging && !!phones.length}
          onChangeSelected={onChangeSelected}
          handleChangePhone={handleChangePhone}
          phone={selectedCompanyPhone}
          phonesList={phones}
          handleOtherEmailsInputChange={handleOtherEmailsInputChange}
          otherEmails={otherEmails}
          handleChangeOtherPhoneInput={handleChangeOtherPhoneInput}
          otherPhone={otherPhone}
          handleChangeTemplate={handleChangeTemplate}
          emailValidation={emailValidation}
          smsValidation={smsValidation}
        />
      ),
      title: (
        <p
          style={{ color: current === StepsEnum.NOTIFICATION ? '#1890ff' : '' }}
        >
          Preview Message
        </p>
      ),
      disabled: !contacts.length,
      status: current === StepsEnum.NOTIFICATION ? 'finish' : '',
    },
  ]

  const renderContent = () => {
    if (currentTab === TabsEnum.TABLE) {
      return (
        <SharedEventsTable
          onCreateLink={() => {
            if (generateLink?.id) {
              onChangeTab(TabsEnum.FORM)
            } else handleClickGenerateLink()
          }}
          event={event}
        />
      )
    }
    if (currentTab === TabsEnum.FORM) {
      return (
        <ContentContainer current={current}>
          <StepsWrappper>
            <Steps
              size={size.width < mobileView ? 'small' : 'default'}
              direction='horizontal'
              current={current}
              onChange={onChange}
              progressDot
            >
              {steps.map((step, index) => (
                <Steps.Step
                  key={index}
                  title={step.title}
                  description={step.description}
                  status={step.status}
                />
              ))}
            </Steps>
          </StepsWrappper>
          <FormWrapper isContact={current === StepsEnum.CONTACT}>
            {steps.map((step, index) => (
              <div
                key={index}
                style={{ display: current === index ? 'block' : 'none' }}
              >
                {step.content}
              </div>
            ))}
            {current === StepsEnum.CONTACT && (
              <CardsWrapper>
                {contacts?.map(contact => (
                  <ContactCard
                    onDelete={() => {
                      handleDeleteContact(contact.id)
                    }}
                    key={contact.id}
                    contact={contact}
                  />
                ))}
              </CardsWrapper>
            )}
          </FormWrapper>
        </ContentContainer>
      )
    }
  }

  return (
    <Modal
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            width: '95%',
          }}
        >
          <p>
            Share {`${dictionary.singular}`}: {`${event?.job_code || ''}`}
          </p>
          {!!limitOver && subscriptionPlan !== SubscriptionPlan.Free && (
            <p>
              <Alert message={limitOver} type='info' showIcon closable />
            </p>
          )}
        </div>
      }
      centered
      width={'70%'}
      onCancel={() => {
        onCancel()
        clearState()
      }}
      visible={visible}
      afterClose={clearState}
      destroyOnClose
      bodyStyle={{ height: '80vh', overflow: 'scroll' }}
      footer={
        <FooterControls>
          <div>
            {currentTab === TabsEnum.FORM && (
              <MainButton
                onClick={() => {
                  if (current === StepsEnum.CONTACT) {
                    onChangeTab(TabsEnum.TABLE)
                    setGenerateLink(null)
                    clearState()
                  } else {
                    onChange(StepsEnum.CONTACT)
                  }
                }}
                type='cancel'
                title='Back '
              />
            )}

            {generateLink?.id && (
              <MainButton
                onClick={handleCopyLinkToClipboard}
                type='completed'
                className='create-linn-btn'
                ref={btnRef}
                title={
                  <p
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '3px',
                    }}
                  >
                    <LinkOutlined />
                    Copy Link
                  </p>
                }
              />
            )}
            {linkCopiing && (
              <>
                <CheckCircleOutline style={{ color: '#2ebda3' }} />{' '}
                <span style={{ color: '#2ebda3' }}>Link was copied</span>
              </>
            )}
          </div>
          <div>
            {currentTab === TabsEnum.FORM && (
              <>
                {current === StepsEnum.NOTIFICATION ? (
                  <>
                    <MainButton
                      onClick={handleCreatePublikLink}
                      disabled={!isValidEmailTemplate}
                      title='Send'
                    />
                  </>
                ) : (
                  <MainButton
                    disabled={isButtonDisabled && !formIsEmpty}
                    onClick={handleSkipOrNext}
                    title={formIsEmpty && !contacts?.length ? 'Skip' : 'Next'}
                  />
                )}
              </>
            )}
          </div>
        </FooterControls>
      }
    >
      {/* {<Alert message='Informational Notes' type='info' showIcon closable />} */}
      <Wrapper>{renderContent()}</Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  min-height: 500px;
  & .ant-collapse-content-box {
    padding: 0;
  }
  & .ant-collapse-header {
    padding-left: 0 !important;
  }
`

const StepsWrappper = styled.div`
  margin-bottom: 20px;
  width: 100%;
  /* max-width: 500px; */
  /* display: grid;
  grid-template-columns: 20% 80%; */
`

const FooterControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  & div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`
const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const FormWrapper = styled.div<{ isContact: boolean }>`
  /* padding: 0 60px; */
  ${props =>
    props.isContact &&
    `
    @media (max-width: 1000px) {
    display: block;
  }
    display: grid;
    grid-template-columns:2fr 1fr;
    gap: 10px;
  `}
`

interface IPropsStyle {
  current: StepsEnum
}
const ContentContainer = styled.div<IPropsStyle>`
  display: block;
  
  /* @media (min-width: ${`${mobileView}px`}) {
    display: grid;
    gap: 10px;
    grid-template-columns: ${props =>
      props.current === StepsEnum.CONTACT ? '0.5fr 2fr 1fr' : '0.3fr 1fr'};
  } */
`
