import * as types from '../../types'
import * as proposal from 'api/Proposal'
import * as proposalAccounting from 'api/ProposalAccounting'
// import * as property from 'api/Property'
// import * as appSource from 'api/AppointmentSource'
// import * as appTypes from 'api/AppointmentType'
// import * as appUsers from 'api/User'
// import * as payments from 'api/Payment'
import { fetchProposal as getProposalSettings } from 'api/settings/Proposal'
import { getTaxRateByZip } from 'api/settings/Proposal'

import moment from 'moment-timezone'
import { postClientEmails } from '../../../../api/Client'
import { postOrganizationEmails } from '../../../../api/Organizations'
// import { sessionStartTimeStamp } from '../../../..'
// import {
//   getAllProposalClients,
//   getAllProposalOrganizations,
// } from 'api/ProposalContact'
import { deleteMedia, upload } from 'api/Media'
// import { ACCOUNTING_SET_BLOCK_ITEM } from '../../types/proposal/proposalForm'
import { setUploadProgress } from './proposalModalFiles'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import {
  itemsSet,
  resetSectionItems,
} from 'features/Project/slices/projectItemsSlice'
import {
  projectFieldUpdated,
  projectSet,
  resetProjectSlice,
} from 'features/Project/slices/projectSlice'
import { itemsGroupsSet } from 'features/Project/slices/projectGroupsSlice'
import { ProjectAPI, ProjectTabsAPI } from 'api/Project'
import { discountsSet } from 'features/Project/slices/projectDiscountsSlice'
import { rebatesSet } from 'features/Project/slices/projectRebatesSlice'
import { feesSet, taxSet } from 'features/Project/slices/projectTaxAndFeesSlice'
import {
  projectTabSet,
  updateInformationForWorkers,
} from 'features/Project/slices/projectTabsSlice'
import {
  gatherItemsFromSections,
  populateProjectTabWithEntities,
} from 'features/Project/helpers'

/* export function gatherDisocuntsFromSections(proposalTab) {
  return proposalTab.proposal_sections.reduce((allItems, section) => {
    allItems.push(...section.discounts)
    return allItems
  }, [])
}

export function gatherRebatesFromSections(proposalTab) {
  return proposalTab.proposal_sections.reduce((allItems, section) => {
    allItems.push(...section.rebates)
    return allItems
  }, [])
}

export function gatherItemsFromSections(proposalTab) {
  return proposalTab.proposal_sections.reduce((allItems, section) => {
    allItems.push(...section.items)
    return allItems
  }, [])
}

function gatherGroupsFromSections(proposalTab) {
  return proposalTab.proposal_sections.reduce((allItems, section) => {
    allItems.push(...section.groups)
    return allItems
  }, [])
} */

export const createProposal = data => () => {
  // fd.append('status', 1)
  // fd.append('total', 0)
  data.status = 1
  data.total = 0
  return proposal.createProposal(data)
}

const getProposal = (id, tabId) => {
  return proposal.fetchProposal(id, tabId)
}

export const setAllTabsInProposal = tabs => {
  return {
    type: types.proposalForm.SET_ALL_TABS_IN_PROPOSAL,
    payload: tabs,
  }
}

export const getAllTabs = () => (dispatch, getState) => {
  const proposalId = getState().orcatec.proposalForm.id
  const allTabsInfo = getState().orcatec.proposalForm.tabs_info
  const fetchAllTabs = allTabsInfo.map(({ id }) =>
    proposal.fetchProposal(proposalId, id),
  )
  return Promise.all(fetchAllTabs)
    .then(data => data.map(res => res.proposal_tabs[0]))
    .then(res => {
      dispatch(setAllTabsInProposal(res))
    })
}

export const copyProposal = () => (dispatch, getState) => {
  const proposalId = getState().orcatec.proposalForm.id
  return proposal.copyProposal(proposalId)
}

export const getProposalDataForAccounting = id => dispatch => {
  const obj = data => ({
    type: types.proposalForm.SET_PROPOSAL_FORM_FOR_ACCOUNTING,
    payload: data,
  })
  return getProposal(id).then(proposal => {
    dispatch(obj(proposal))
  })
}
/* const getPropertyDetails = id => {
  return property.fetchProperty(id)
}

const getSource = id => {
  return appSource.fetchAppointmentSource(id)
}

const getType = id => {
  return appTypes.fetchAppointmentType(id)
}

const getTech = id => {
  return appUsers.fetchUser(id)
} */

export const setForm = (
  proposal,
  modulesNamesFromSettings,
  show_price,
  industry,
  logos,
  companyLogo,
) => {
  return {
    type: types.proposalForm.SET_PROPOSALS_FORM,
    proposal,
    modulesNamesFromSettings,
    show_price,
    industry,
    logos,
    companyLogo,
  }
}

export const updatePrimaryProperty = property_relation_id => {
  return {
    type: types.proposalForm.UPDATE_PRIMARY_PROPERTY,
    payload: property_relation_id,
  }
}

export const initProposalForm = (proposalId, tabId, handleSave) => (
  dispatch,
  getState,
) => {
  const {
    module_one,
    module_two,
    module_three,
    show_price,
    logos = {},
  } = getState().orcatec.proposal
  const { activeTab } = getState().orcatec.proposalForm
  const defaultTemplateId = getState().orcatec.user.me.proposal_template_id
  const company = getState().orcatec.company
  const industry = company.industry
  const companyLogo = company.logo

  const setProposalForm = data => {
    dispatch(
      setForm(
        data,
        {
          module_one,
          module_two,
          module_three,
        },
        show_price,
        industry,
        logos,
        companyLogo,
      ),
    )

    populateProjectTabWithEntities(data?.proposal_tabs?.[0], dispatch)
    //     dispatch(resetProjectSlice())
    //
    //     dispatch(itemsGroupsSet(gatherGroupsFromSections(data?.proposal_tabs?.[0])))
    //     dispatch(itemsSet(gatherItemsFromSections(data?.proposal_tabs?.[0])))
    //     dispatch(taxSet(data?.proposal_tabs?.[0].tax))
    //     dispatch(feesSet(data?.proposal_tabs?.[0].fees))
    //     dispatch(
    //       discountsSet(gatherDisocuntsFromSections(data?.proposal_tabs?.[0])),
    //     )
    //     dispatch(rebatesSet(gatherRebatesFromSections(data?.proposal_tabs?.[0])))
  }

  return getProposal(proposalId, tabId).then(res => {
    dispatch(projectSet(res))
    dispatch(projectTabSet(res.proposal_tabs[0]))
    // console.log(rest)

    if (!res.tabs_info && !!defaultTemplateId) {
      return proposal
        .fetchProposalTemplate(defaultTemplateId)
        .then(data => {
          res = {
            ...res,
            ...data.body,
          }
          setProposalForm(res)
          return res
        })
        .catch(() => {
          setProposalForm(res)
          return res
        })
    } else {
      setProposalForm(res)
      handleSave && handleSave('success')
      return res
    }
  })
}

export const setTabInProposal = (tab, idxInTabsList, priority_tab_id) => ({
  type: types.proposalForm.SET_TAB_IN_PROPOSAL,
  tab,
  idxInTabsList,
  priority_tab_id,
})

export const getProposalTab = (tabId, idxInTabsList) => (
  dispatch,
  getState,
) => {
  const proposalId = getState().orcatec.proposalForm.id
  return getProposal(proposalId, tabId).then(data => {
    dispatch(
      setTabInProposal(
        data.proposal_tabs[0],
        idxInTabsList,
        data.priority_tab_id,
      ),
    )

    dispatch(itemsGroupsSet(gatherGroupsFromSections(data?.proposal_tabs?.[0])))
    dispatch(itemsSet(gatherItemsFromSections(data?.proposal_tabs?.[0])))
    dispatch(taxSet(data?.proposal_tabs?.[0].tax))
    dispatch(feesSet(data?.proposal_tabs?.[0].fees))
    dispatch(
      discountsSet(gatherDisocuntsFromSections(data?.proposal_tabs?.[0])),
    )
    dispatch(rebatesSet(gatherRebatesFromSections(data?.proposal_tabs?.[0])))

    return data
  })
}

export const onChangeField = (value, field) => {
  return {
    type: types.proposalForm.PROPOSAL_CHANGE_FIELD,
    value,
    field,
  }
}
export const onSetField = (value, field) => {
  return {
    type: types.proposalForm.PROPOSAL_SET_FIELD,
    value,
    field,
  }
}

export const onChangeTabDetails = (value, field) => {
  return {
    type: types.proposalForm.PROPOSAL_TAB_FIELD_CHANGE,
    value,
    field,
  }
}

export const onGetRelatives = value => {
  return {
    type: types.proposalForm.PROPOSAL_GET_RELATIVES,
    value,
  }
}

export const onChangeSection = (value, section, item, item_type, field) => {
  return {
    type: types.proposalForm.PROPOSAL_SECTION_ITEM_FIELD_CHANGE,
    value,
    section,
    item,
    item_type,
    field,
  }
}

export const onAddTag = value => {
  return {
    type: types.proposalForm.PROPOSAL_ADD_TAG,
    value,
  }
}

export const onFileTabUpload = files => {
  // export const onFileTabUpload = files => {
  return {
    type: types.proposalForm.PROPOSAL_TAB_FILE_UPLOAD,
    payload: files,
  }
}

export const onFileUpload = obj => {
  return {
    type: types.proposalForm.PROPOSAL_FILE_UPLOAD,
    obj,
  }
}

export const onItemUpload = (item, index, obj) => {
  return {
    type: types.proposalForm.PROPOSAL_ITEM_FILE_UPLOAD,
    item,
    index,
    obj,
  }
}

export const setClientsRelatives = res => {}

// export const getClientsRelativess = (id) => dispatch => {
//   return getClientsRelativesService(id).then(res=>{
//     dispatch(setClientsRelatives(res))
//   })
// }

export const getClientsRelatives = id => dispatch => {
  return proposal.fetchClientsRelatives(id).then(res => {
    dispatch(onGetRelatives(res))
    return res
  })
}

export const addItemToSection = (item, section, item_type) => {
  return {
    type: types.proposalForm.PROPOSAL_ADD_ITEM_SECTION,
    data: item,
    section,
    item_type,
  }
}
export const changeItemSection = (
  item,
  itemIdx,
  section,
  item_type,
  is_edited,
  full_name,
) => {
  return {
    type: types.proposalForm.PROPOSAL_CHANGE_ITEM_SECTION,
    item,
    itemIdx,
    section,
    item_type,
    is_edited,
    full_name,
  }
}

export const onChangeSectionField = (value, field, section) => {
  return {
    type: types.proposalForm.PROPOSAL_SECTION_FIELD_CHANGE,
    value,
    field,
    section,
  }
}

export const onChangeSectionStructureField = ({
  section,
  structureName,
  field,
  value,
}) => {
  return {
    type: types.proposalForm.PROPOSAL_SECTION_STRUCTURE_FIELD_CHANGE,
    section,
    structureName,
    field,
    value,
  }
}

export const onChangeSectionStructureOrder = ({ section, newStructure }) => {
  return {
    type: types.proposalForm.PROPOSAL_SECTION_STRUCTURE_REORDER,
    section,
    newStructure,
    /* structureName,
    field,
    value, */
  }
}

export const onChangeTabInfo = (tab, tabInfo) => {
  return {
    type: types.proposalForm.PROPOSAL_CHANGE_TAB_INFO,
    payload: {
      tab,
      title: tabInfo.title,
      description: tabInfo.description,
      image: tabInfo.image,
    },
  }
}

export const onAddTab = (value, modulesNamesFromSettings) => {
  return {
    type: types.proposalForm.PROPOSAL_ADD_TAB,
    value,
    modulesNamesFromSettings,
  }
}

export const copySection = section => {
  return {
    type: types.proposalForm.PROPOSAL_COPY_SECTION,
    section,
  }
}

export const updateOldProjectFields = fields => {
  return {
    type: types.proposalForm.UPDATE_PROJECT_FIELDS,
    payload: fields,
  }
}

export const copyTab = ({ newTab, originalTabIdx }) => {
  return {
    type: types.proposalForm.PROPOSAL_COPY_TAB,
    payload: {
      newTab,
      originalTabIdx,
    },
  }
}

export const copyProjectTab = ({ tabId, tabIdx }) => async dispatch => {
  const copiedTab = await ProjectTabsAPI.copyTab(tabId)

  dispatch(copyTab({ newTab: copiedTab, originalTabIdx: tabIdx }))

  return copiedTab
}

export const deleteTab = (tabIdx, tabId) => {
  return {
    type: types.proposalForm.PROPOSAL_DELETE_TAB,
    payload: { tabIdx, tabId },
  }
}

export const setDefaultUserSetting = () => {
  return {
    type: types.proposalForm.SET_DEFAULT_SETINGS_IN_PROPOSAL,
  }
}

export const deleteSection = section => {
  return {
    type: types.proposalForm.PROPOSAL_DELETE_SECTION,
    section,
  }
}

export const toggleSection = section => {
  return {
    type: types.proposalForm.PROPOSAL_TOGGLE_SECTION,
    section,
  }
}

export const addSection = section => {
  return {
    type: types.proposalForm.PROPOSALS_ADD_SECTION,
    payload: section,
  }
}

export const deleteTabFile = (index, id) => {
  return {
    type: types.proposalForm.PROPOSAL_TAB_FILE_DELETE,
    payload: {
      index,
      id,
    },
  }
}

export const sectionItemFileUpload = (section, item_type, item_num, files) => {
  return {
    type: types.proposalForm.SECTION_ITEM_FILE_UPLOAD,
    section,
    item_type,
    item_num,
    payload: files,
  }
}
export const sectionItemOptionFileUpload = (
  section,
  item_type,
  item_num,
  optionIdx,
  files,
) => {
  return {
    type: types.proposalForm.SECTION_ITEM_OPTION_FILE_UPLOAD,
    section,
    item_type,
    item_num,
    optionIdx,
    payload: files,
  }
}

export const onDeleteSectionFromTab = (sectionIdx, sectionId) => {
  return {
    type: types.proposalForm.PROPOSAL_ON_DELETE_SECTION,
    payload: { sectionIdx, sectionId },
  }
}

export const onDeleteSection = idx => (dispatch, getState) => {
  const { proposalForm } = getState().orcatec

  const sectionToDelete =
    proposalForm.proposal_tabs[proposalForm.activeTab].proposal_sections[idx]

  dispatch(resetSectionItems(sectionToDelete.id))
  return dispatch(onDeleteSectionFromTab(idx, sectionToDelete.id))
}

//////////////////////////////////////////////////

export const onDeleteTab = index => {
  return {
    type: types.proposalForm.PROPOSAL_ON_DELETE_TAB,
    index,
  }
}
export const onDeleteTag = index => {
  return {
    type: types.proposalForm.PROPOSAL_ON_DELETE_TAG,
    index,
  }
}
export const sectionFileDelete = (section, item_type, item_num, index) => {
  return {
    type: types.proposalForm.SECTION_ITEM_FILE_DELETE,
    section,
    item_type,
    item_num,
    index,
  }
}

export const sectionItemOptionFileDelete = (
  section,
  item_type,
  item_num,
  optionIdx,
  index,
) => {
  return {
    type: types.proposalForm.SECTION_ITEM_OPTION_FILE_DELETE,
    section,
    item_type,
    item_num,
    optionIdx,
    index,
  }
}

export const setReorderedItems = (item_type, items, section) => {
  return {
    type: types.proposalForm.SET_REORDERED_ITEMS,
    items,
    section,
    item_type,
  }
}

export const resetProposalForm = () => ({
  type: types.proposalForm.RESET_PROPOSAL_FORM,
})

export const filesFormUpload = files => {
  return {
    type: types.proposalForm.PROPOSAL_FORM_FILE_UPLOAD,
    payload: files,
  }
}
export const filesProperyUpload = files => {
  return {
    type: types.proposalForm.PROPOSAL_PROPERTY_FILE_UPLOAD,
    payload: files,
  }
}

export const filesCustomerUpload = files => {
  return {
    type: types.proposalForm.PROPOSAL_CUSTOMER_FILE_UPLOAD,
    payload: files,
  }
}
export const deleteFormFiles = (index, id) => {
  return {
    type: types.proposalForm.PROPOSAL_FORM_FILE_DELETE,
    payload: {
      index,
      id,
    },
  }
}
export const deleteCustomerFiles = (index, id) => {
  return {
    type: types.proposalForm.PROPOSAL_CUSTOMER_FILE_DELETE,
    payload: {
      index,
      id,
    },
  }
}

export const setSectionTemplate = (data, sectionId, sectionIdx) => {
  return {
    type: types.proposalForm.SET_TEMPLATE_IN_SECTION,
    payload: {
      data,
      sectionId,
      sectionIdx,
    },
  }
}

////////////////////////////

const calcPrice = (items, price) => {
  const totalPrice = price
  const reducer = (acc, curr) => {
    let priceOfItem
    if (curr.options) {
      const options = curr.options?.data.forEach(option => {
        if (curr.options?.checked_option === option.idForChoosenOption) {
          priceOfItem = Number(option.price) || 0
        }
      })
    } else {
      priceOfItem = curr.price ? Number(curr.price) : 0
    }
    return Number(acc) + priceOfItem * curr?.qty
  }
  return Array.isArray(items) && !!items.length
    ? items.reduce(reducer, totalPrice)
    : totalPrice
}

const calcDiscount = (items, price, signatureDate) => {
  const totalPrice = price
  const endOfExpirationDate = signatureDate
    ? moment(signatureDate)
    : moment().endOf('day')
  const reducer = (acc, curr) => {
    const expiration = curr.expiration_date || curr.expiration
    let priceOfItem
    if (curr.options) {
      const options = curr.options?.data.forEach(option => {
        if (curr.options?.checked_option === option.idForChoosenOption) {
          priceOfItem = Number(option.price) || 0
        }
      })
    } else {
      priceOfItem = curr.price ? Number(curr.price) : 0
    }
    return moment(expiration).isBefore(endOfExpirationDate, 'days')
      ? Number(acc) + 0
      : Number(acc) + priceOfItem
    // return new Date(curr.expiration_date ? curr.expiration_date : curr.expiration) <
    //   new Date(new Intl.DateTimeFormat('en-US').format(new Date()))
    //   ? Number(acc) + 0
    //   : Number(acc) + priceOfItem
  }
  return Array.isArray(items) && !!items.length
    ? items.reduce(reducer, totalPrice)
    : totalPrice
}

/////////////////////////////

export const updateProposal = (
  isDeleteSignature,
  status,
  canceledReason = '',
  oldActiveTabIdx,
  saveJustStatus,
) => (dispatch, getState) => {
  const setSignatureData = (data, tabId) => {
    if (status) data.status = status
    // if (isDeleteSignature === 'with template') data.is_template = 1
    if (isDeleteSignature && isDeleteSignature.indexOf('with template') + 1) {
      const templateId = isDeleteSignature.substr(
        isDeleteSignature.lastIndexOf(':') + 1,
      )
      data.template_id = Number(templateId)
    }
    if (isDeleteSignature === 'delete signature') {
      if (+status === 1) {
        data.signature = ''
        data.signed_tab_id = null
        data.ip_client = ''
        data.canceled_reason = null
      } else if (+status === 4) {
        data.canceled_info = {
          date: moment()
            .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
          reason: canceledReason,
          user: getState().orcatec.user.me.full_name,
        }
      } else if (+status === 2) {
        data.signature = ''
        data.ip_client = ''
      }
      data.all_work_completed = false
    } else if (!isDeleteSignature && +status === 2) {
      data.signed_tab_id = tabId
    }
  }

  const getIpAndUpdate = (data, tabId) => {
    return proposal.updateProposal(data.id, data).then(resp => {
      return dispatch(initProposalForm(data.id, tabId))
    })
  }

  let data = JSON.parse(JSON.stringify(getState().orcatec.proposalForm))

  if (!saveJustStatus) {
    data.proposal_tabs = data.proposal_tabs.reduce((acc, tab, tabIdx) => {
      if (
        (!!tab &&
          (oldActiveTabIdx !== undefined
            ? tabIdx === oldActiveTabIdx
            : tabIdx === data.activeTab)) ||
        (!!tab && !tab.id)
      ) {
        const items = Object.values(getState().orcatec.projectItemsSlice.items)
        const { tax, fees } = getState().orcatec.projectTaxAndFeesSlice

        const discounts = Object.values(
          getState().orcatec.projectDiscountsSlice.discounts,
        )
        const rebates = Object.values(
          getState().orcatec.projectRebatesSlice.rebates,
        )

        const sections = Array.isArray(tab.proposal_sections)
          ? tab.proposal_sections
          : []
        const totalWorkAndDetailsPrice = (() => {
          let totalPrice = 0
          sections.forEach(section => {
            section.items = items.filter(
              item => item.section_id === section?.id,
            )
            section.rebates = rebates.filter(
              item => item.section_id === section?.id,
            )
            section.discounts = discounts.filter(
              item => item.section_id === section?.id,
            )
          })
          return totalPrice
        })()

        tab.tax = tax?.tab_id === tab?.id ? tax : null
        tab.fees = !fees?.ids?.length
          ? []
          : fees?.ids
              .map(feeId => fees.data[feeId])
              .filter(fee => fee.tab_id === tab.id)

        //         const instancePrice = (() => {
        //           let totalPrice = 0
        //           sections.forEach(section => {
        //             totalPrice = calcDiscount(
        //               section.instants,
        //               totalPrice,
        //               data.contract_date,
        //             )
        //           })
        //           return totalPrice
        //         })()
        //
        //         const rebatesPrice = (() => {
        //           let totalPrice = 0
        //           sections.forEach(section => {
        //             totalPrice = calcDiscount(
        //               section.rebates,
        //               totalPrice,
        //               data.contract_date,
        //             )
        //           })
        //           return totalPrice
        //         })()

        // tab.total_to_pay = (() => {
        //   const result = totalWorkAndDetailsPrice - instancePrice - rebatesPrice
        //   return result /*  > 0 ? result : 0 */
        // })()

        tab.proposal_sections = sections.length
          ? sections.map((section, sectionIdx) => {
              ;[
                'equipments',
                'parts',
                'supplies',
                'warranties',
                'scope_of_works',
                'instants',
                'rebates',
              ].forEach(itemKey => {
                section[itemKey] =
                  !!section[itemKey] && !!section[itemKey].length
                    ? section[itemKey].map(item => {
                        if (
                          (itemKey === 'instants' || itemKey === 'rebates') &&
                          item.expiration === 'no expiration'
                        ) {
                          item.expiration = '12/12/9999'
                        }
                        return item
                      })
                    : []
              })
              return section
            })
          : sections

        /* relation_with_template: {
          type: 'global',
          id: dataSource[0].id,
        }, */

        acc.push(tab)
      }
      return acc
    }, [])
    setSignatureData(data, data.tabs_info[data.activeTab].id)
    if (!isDeleteSignature && +status === 2 && !data.ip_client) {
      return getIpAndUpdate(data, data.tabs_info[data.activeTab].id)
    }

    const formatDataToDelete = () => {
      const collectIds = (arr, type) => {
        return (
          Array.isArray(arr) &&
          arr
            .filter(el => el.itemTypeForDelete === type)
            .reduce((a, c) => a.concat(c.id), [])
        )
      }

      return {
        tabIds: data.tabsToDeleteOnSave || [],
        sectionIds: data.sectionToDeleteOnSave || [],
        // equipmentIds: collectIds(data.itemsToDeleteOnSave, 'equipments'),
        // partIds: collectIds(data.itemsToDeleteOnSave, 'parts'),
        // supplyIds: collectIds(data.itemsToDeleteOnSave, 'supplies'),
        materialIds: collectIds(data.itemsToDeleteOnSave, 'materials'),
        // scopeOfWorkIds: collectIds(data.itemsToDeleteOnSave, 'ScopeOfWork'),
        instantIds: collectIds(data.itemsToDeleteOnSave, 'Instant'),
        // warrantyIds: collectIds(data.itemsToDeleteOnSave, 'Warranty'),
        rebateIds: collectIds(data.itemsToDeleteOnSave, 'Rebate'),
      }
    }

    if (status === 4 || data?.status === 4)
      data.proposal_tabs[0].total_to_pay =
        data.proposal_tabs[0].cancellation_fees

    return proposal
      .deleteProposalEntities(formatDataToDelete())
      .then(
        () =>
          Object.values(data).flat().length &&
          dispatch({ type: types.proposalForm.PROPOSAL_CLEAR_ID_TO_DELETE }),
      )
      .then(() => proposal.updateProposal(data.id, data))
      .then(resp => {
        if (data.filesToDelete.length) {
          data.filesToDelete.forEach(id => {
            deleteMedia(id)
          })
        }
        //TODO: change data.tabs_info to resp.tabs.info when set reorder of tabs on back
        let tabId = data.tabs_info[data.activeTab].id
        return /* isDeleteSignature === 'just update' ? resp : */ dispatch(
          initProposalForm(data.id, tabId),
        )
      })
  } else {
    return getProposal(data.id, data.tabs_info[data.activeTab].id).then(
      resp => {
        setSignatureData(resp, data.tabs_info[data.activeTab].id)
        if (!isDeleteSignature && +status === 2 && !resp.ip_client) {
          return getIpAndUpdate(resp, data.tabs_info[data.activeTab].id)
        } else
          return proposal.updateProposal(resp.id, resp).then(resp => {
            //TODO: change data.tabs_info to resp.tabs.info when set reorder of tabs on back
            let tabId = data.tabs_info[data.activeTab].id
            return /* isDeleteSignature === 'just update' ? resp : */ dispatch(
              initProposalForm(data.id, tabId),
            )
          })
      },
    )
  }
}

export const changeOrderEquipmentStatus = status => (dispatch, getState) => {
  const proposalForm = getState().orcatec.proposalForm
  const tabId = proposalForm.proposal_tabs[proposalForm.activeTab].id
  return proposal.changeOrderEquipmentStatus(tabId, status).then(data => {
    return dispatch(onChangeTabDetails(status, 'status'))
  })
}
export const changePaymentStatus = status => (dispatch, getState) => {
  const proposalForm = getState().orcatec.proposalForm
  const tabId = proposalForm.proposal_tabs[proposalForm.activeTab].id
  return proposal.changePaymentStatus(tabId, status).then(data => {
    return dispatch(onChangeTabDetails(status, 'payment_status'))
  })
}

export const onDeleteItem = (
  section,
  item_type,
  item_num,
  itemTypeForDelete,
) => ({
  type: types.proposalForm.PROPOSAL_DELETE_ITEM_SECTION,
  section,
  item_type,
  item_num,
  itemTypeForDelete,
})

export const onDeleteItemFromSection = (
  section,
  item_type,
  item_num,
  itemTypeForDelete,
) => (dispatch, getState) => {
  //deleteTypeOfItem - just for delete from server
  const { proposalForm } = getState().orcatec
  const itemToDelete =
    proposalForm.proposal_tabs[proposalForm.activeTab].proposal_sections[
      section
    ][item_type][item_num]
  /* if (itemToDelete.id !== 0 && itemToDelete.id < sessionStartTimeStamp) {
    return proposal
      .deleteProposalItem(itemToDelete.id, itemTypeForDelete)
      .then(() => dispatch(onDeleteItem(section, item_type, item_num)))
      .catch(e => dispatch(onDeleteItem(section, item_type, item_num)))
  } else { */
  return dispatch(onDeleteItem(section, item_type, item_num, itemTypeForDelete))
  /*  } */
}

export const deleteSectionItems = itemsType => {
  return {
    type: types.proposalForm.CLEAR_SECTION_ITEMS,
    payload: itemsType,
  }
}

export const orderEquipment = data => dispatch => {
  return proposal.orderEquipment(data)
}

export const deleteProposalTab = (tabId, idx) => (dispatch, getState) => {
  //const currentTab = getState().orcatec.proposalForm.activeTab
  return proposal.deleteProposalTab(
    tabId,
  ) /* .then(() => {
    if (currentTab === idx) dispatch(onChangeField(0, 'activeTab'))
    dispatch(deleteTab(idx))
  }) */
}

export const onChangeOptionalModuleValues = (tab, module, field, value) => ({
  type: types.proposalForm.PROPOSAL_CHANGE_TAB_OPTIONAL_MODAL,
  tab,
  module,
  field,
  value,
})
export const onClearOptionalModuleValues = (tab, module) => ({
  type: types.proposalForm.PROPOSAL_CLEAR_TAB_OPTIONAL_MODAL,
  tab,
  module,
})

export const updateOptionalModules = (status, idx) => (dispatch, getState) => {
  const { proposalForm } = getState().orcatec
  const currentTab = proposalForm.proposal_tabs[proposalForm.activeTab]
  const modules = [...currentTab.modules]
  if (status && !isNaN(idx)) {
    modules[idx].status = status
  }

  const proposalFormCopy = { ...proposalForm }
  const tabsCopy = proposalFormCopy.proposal_tabs.filter(tab => !!tab.id)
  proposalFormCopy.proposal_tabs = tabsCopy
  return proposal
    .updateProposal(proposalFormCopy.id, proposalFormCopy)
    .then(() => {
      return proposal
        .updateProposalOptionalModules(currentTab.id, modules)
        .then(() => {
          return dispatch(
            getProposalTab(currentTab.id, proposalFormCopy.activeTab),
          )
        })
    })
}

//////////////////OPTIONS/////////////////

export const onCloseOptionsMode = (sectionIdx, itemsType, itemIdx) => ({
  type: types.proposalForm.PROPOSAL_CLOSE_OPTIONS_MODE_IN_ITEM,
  sectionIdx,
  itemsType,
  itemIdx,
})

export const onAddOptionInItem = (data, sectionIdx, itemsType, itemIdx) => ({
  type: types.proposalForm.PROPOSAL_ADD_OPTION_TO_ITEM,
  data,
  sectionIdx,
  itemsType,
  itemIdx,
})

export const onChangeOptionInItem = (
  data,
  optionIdx,
  sectionIdx,
  itemsType,
  itemIdx,
  is_edited,
  full_name,
) => ({
  type: types.proposalForm.PROPOSAL_CHANGE_OPTION_IN_ITEM,
  data,
  optionIdx,
  sectionIdx,
  itemsType,
  itemIdx,
  is_edited,
  full_name,
})

export const onChangeOption = (
  value,
  sectionIdx,
  itemsType,
  itemIdx,
  optionIdx,
  field,
) => ({
  type: types.proposalForm.PROPOSAL_OPTION_FIELD_CHANGE,
  value,
  sectionIdx,
  itemsType,
  itemIdx,
  optionIdx,
  field,
})

export const onDeleteOption = (sectionIdx, itemsType, itemIdx, optionIdx) => ({
  type: types.proposalForm.PROPOSAL_DELETE_OPTION,
  sectionIdx,
  itemsType,
  itemIdx,
  optionIdx,
})

export const setReorderedOptions = (
  sectionIdx,
  itemsType,
  itemIdx,
  options,
) => ({
  type: types.proposalForm.PROPOSAL_SET_REORDERED_OPTIONS,
  sectionIdx,
  itemsType,
  itemIdx,
  options,
})

export const initOptionsInItem = (sectionIdx, itemsType, itemIdx) => {
  return {
    type: types.proposalForm.PROPOSAL_INIT_OPTIONS_IN_ITEM,
    sectionIdx,
    itemsType,
    itemIdx,
  }
}

export const onChooseOption = (
  sectionIdx,
  itemsType,
  itemIdx,
  optionIdx,
  idForChoosenOption,
) => {
  return {
    type: types.proposalForm.PROPOSAL_CHOOSE_OPTION,
    sectionIdx,
    itemsType,
    itemIdx,
    optionIdx,
    idForChoosenOption,
  }
}

export const setReorderedSections = sections => ({
  type: types.proposalForm.PROPOSAL_TAB_SET_REORDERED_SECTIONS,
  payload: sections,
})

//////////////////OPTIONS/////////////////

////CHECK FOR NOT COMPLETED NEW ITEMS///

export const addUncompletedNewItemId = uncompletedNewItemId => ({
  type: types.proposalForm.PROPOSAL_ADD_UNCOMPLETED_NEW_ITEM_ID,
  uncompletedNewItemId,
})

export const deleteUncompletedNewItemId = uncompletedNewItemId => ({
  type: types.proposalForm.PROPOSAL_DELETE_UNCOMPLETED_NEW_ITEM_ID,
  uncompletedNewItemId,
})

export const resetUncompletedNewItemId = () => ({
  type: types.proposalForm.PROPOSAL_RESET_UNCOMPLETED_NEW_ITEM_ID,
})

////CHECK FOR NOT COMPLETED NEW ITEMS///

export const addEmailToClient = emailData => (dispatch, getState) => {
  //const currentProperty = { ...getState().orcatec.proposalForm.property }
  const proposalId = getState().orcatec.proposalForm.id

  const postRequest =
    emailData.entity_type === 1 ? postOrganizationEmails : postClientEmails
  const getRequest = proposal.fetchProposal //emailData.entity_type === 1 ? getAllProposalOrganizations : getAllProposalClients
  const key = emailData.entity_type === 1 ? 'organizations' : 'clients'
  //const type = emailData.entity_type === 1 ? types.proposalContact.PROPOSAL_ORGANIZATIONS_SET_ATTACHED_ITEM : types.proposalContact.PROPOSAL_CLIENTS_SET_ATTACHED_ITEM

  return (
    postRequest({ ...emailData, id: 0 })
      .then(data => getRequest(proposalId))
      //.then(data => ({ ...currentProperty, clients: data.clients || [], organizations: data.organizations || [] }))
      .then(data => {
        /* dispatch({
        type,
        payload: data,
      }) */
        return dispatch(onChangeField(data[key], key))
      })
  )
}

export const changeTabPosition = endIndex => ({
  type: types.proposalForm.PROPOSAL_CHANGE_TAB_POSITION,
  endIndex,
})

// TAX SECTION

export const setEdit = data => {
  return {
    type: types.proposalForm.PROPOSAL_SET_EDITED,
    payload: data,
  }
}

export const setTaxes = data => {
  return {
    type: types.proposalForm.PROPOSAL_SET_TAXES,
    payload: data,
  }
}

export const setFee = data => {
  return {
    type: types.proposalForm.PROPOSAL_SET_FEE,
    payload: data,
  }
}

export const setDeletedFee = data => {
  return {
    type: types.proposalForm.PROPOSAL_SET_FEE_TO_DELETE,
    payload: data,
  }
}

export const setTotalAfterTax = data => {
  return {
    type: types.proposalForm.PROPOSAL_SET_TOTAL_AFTER_TAX,
    payload: data,
  }
}

//CANCELATTION FEE

export const setCancellationFees = data => {
  return {
    type: types.proposalForm.PROPOSALS_SET_CANCELLATION_FEES,
    payload: data,
  }
}

const setIndustry = data => {
  return {
    type: types.proposalForm.PROPOSAL_SET_INDUSTRY,
    payload: data,
  }
}

// Thunk

export const getIndustry = industryId => dispatch => {
  return getProposalSettings(industryId).then(data =>
    dispatch(setIndustry(data)),
  )
}

// Accounting actions
export const accountingUploadFile = ({ files, itemIDX, rowTitle }) => {
  return {
    type: types.proposalForm.ACCOUNTING_UPLOAD_FILE,
    payload: { files, itemIDX, rowTitle },
  }
}

export const accountingSetItems = ({
  advertising,
  expenses,
  appointment,
  work_dates,
  pre_net_expenses,
  include_tips_to_insight,
  commission,
  job_cost_materials,
  total_materials,
}) => {
  return {
    type: types.proposalForm.ACCOUNTING_SET_ITEMS,
    payload: {
      advertising,
      expenses,
      appointment,
      work_dates,
      pre_net_expenses,
      include_tips_to_insight,
      commission,
      job_cost_materials,
      total_materials,
    },
  }
}

// export const accountingSetRowItems = (items, rowTitle) => {
//   return {
//     type: types.proposalForm.ACCOUNTING_SET_ROW_ITEM,
//     payload: { items, rowTitle },
//   }
// }

export const accountingSetBlockItems = (items, blockTitle) => {
  return {
    type: types.proposalForm.ACCOUNTING_SET_BLOCK_ITEM,
    payload: { items, blockTitle },
  }
}

export const getProposalInsightsData = id => dispatch => {
  return proposalAccounting.getProposalInsightsData(id).then(data =>
    dispatch({
      type: types.proposalForm.FETCH_ACCOUNTING_DATA,
      ...data,
    }),
  )
}

export const saveFiles = (newFiles, serv, filesToDelete, cb) => (
  dispatch,
  getState,
) => {
  const proposalModalFiles = getState().orcatec.proposalModalFiles
  let setFile = () => ({
    type: 'FILE_UPLOAD_DEFAULT_ACTION',
  })

  if (proposalModalFiles.file_type === 'form') {
    setFile = filesFormUpload
  } else if (proposalModalFiles.file_type === 'customer') {
    setFile = filesCustomerUpload
  } else if (proposalModalFiles.file_type === 'tab') {
    setFile = updateInformationForWorkers
    // setFile = onFileTabUpload
  } else if (proposalModalFiles.file_type === 'property') {
    setFile = filesProperyUpload
  } else if (proposalModalFiles.file_type === 'items') {
    setFile = file =>
      sectionItemFileUpload(
        proposalModalFiles.section,
        proposalModalFiles.item_type,
        proposalModalFiles.item_num,
        file,
      )
  } else if (proposalModalFiles.file_type === 'options') {
    setFile = file =>
      sectionItemOptionFileUpload(
        proposalModalFiles.section,
        proposalModalFiles.item_type,
        proposalModalFiles.item_num,
        proposalModalFiles.optionIdx,
        file,
      )
  } else if (proposalModalFiles.file_type === 'accounting') {
    setFile = files =>
      accountingUploadFile({
        files: files,
        itemIDX: proposalModalFiles.item_num,
        rowTitle: proposalModalFiles.rowTitle,
      })
  }

  const filesToUploadOnServer = []
  const linkFiles = []

  newFiles.forEach((file, idx) => {
    if (file instanceof File && !file.id) {
      return
    } else {
      linkFiles.push(file)
    }
  })

  if (Object.values(serv).length) {
    Object.values(serv).forEach(file => {
      const formPayload = new FormData()
      formPayload.append('media', file.file)

      return filesToUploadOnServer.push(
        upload(formPayload, progress => {
          const { loaded, total } = progress
          const percentageProgress = Math.floor((loaded / total) * 100)
          dispatch(setUploadProgress(file.id, percentageProgress))
        }).catch(error => {
          openNotificationWithIcon('error', { message: 'Image is too large' })
        }),
      )
    })
  }

  filesToDelete.forEach(id => deleteMedia(id))

  Promise.all(filesToUploadOnServer)
    .then(data => {
      dispatch(setFile([...data.map(arr => arr[0]), ...linkFiles]))
      cb(data)
    })
    .catch(error => console.log(error))
  /* .then(data => {
      console.log(data)
      cb(data.payload)
    }) */
}

export const clearCustomerInfo = () => {
  return { type: types.proposalForm.PROPOSAL_CLEAR_CUSTOMER_INFORMATION }
}
export const clearSignatureField = () => {
  return { type: types.proposalForm.CLEAR_SIGNATURE_FIELD }
}

// export const getRateByZipCode = zip => dispatch => {
//   return getTaxRateByZip(zip)
//     .then(({ tax }) =>
//       dispatch({
//         type: types.proposalForm.PROPOSAL_RATE_BY_ZIP,
//         payload: tax,
//       }),
//     )
//     .catch(() => {
//       dispatch({
//         type: types.proposalForm.PROPOSAL_RATE_BY_ZIP,
//         payload: null,
//       })
//     })
// }

export const resetSignature = projectId => async dispatch => {
  try {
    const res = await ProjectAPI.resetSignature(projectId)

    // dispatch({
    //   type: types.proposalForm.RESET_PROJECT_SIGNATURE,
    // })

    dispatch(
      projectFieldUpdated({
        signature: null,
        signatory_name: null,
        signature_date: null,
      }),
    )

    return res
  } catch (error) {
    console.error(error?.response?.data)
  }
}
