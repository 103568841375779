import * as types from '../../types'
import * as matrix from '../../../../api/Matrix-log'
import { ThunkAction } from 'redux-thunk'
import { AppStateType } from 'store'
import { AnyAction } from 'redux'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

export const setMatrixLog = res => {
  return {
    type: types.matrixLog.SET_MATRIX_LOG,
    payload: res,
  }
}

export const getMatrixLog = (
  date: string,
  type?: 'both' | 'schedule' | 'queue',
): ThunkAction<void, AppStateType, unknown, AnyAction> => async dispatch => {
  if (type === 'schedule') return await dispatch(getMatrixSchedule(date))
  if (type === 'queue') return await dispatch(getMatrixQueues())

  await dispatch(getMatrixSchedule(date))
  dispatch(getMatrixQueues())
}

export const getMatrixSchedule = (
  date: string,
): ThunkAction<void, AppStateType, unknown, AnyAction> => async dispatch => {
  const res = await matrix.getScheduleData(date)

  let data = res

  if (Array.isArray(res)) {
    data = {
      ...res[0],
      days: res,
    }
  }

  dispatch(setMatrixLog(data))

  return res
}

export const getMatrixQueues = (): ThunkAction<
  void,
  AppStateType,
  unknown,
  AnyAction
> => (dispatch, getState) => {
  const state = getState()

  return matrix.getQueuesData().then(res => {
    dispatch(setMatrixLog({ ...state.orcatec.dispatchSlice, ...res }))
  })
}

export const updateMatrixLog = (
  date: string,
  obj,
): ThunkAction<void, AppStateType, unknown, AnyAction> => () => {
  if (
    obj.appointment.current_end_time === null ||
    obj.appointment.current_start_time === null
  ) {
    if (obj.appointment.current_end_time === null) {
      obj.appointment.current_end_time =
        Number(obj.appointment.current_start_time) + 1
    } else {
      obj.appointment.current_start_time =
        Number(obj.appointment.current_end_time) - 1
    }
  }
  if (obj.appointment.current_start_time < obj.appointment.current_end_time) {
    return matrix.updateMatrixLog(date, obj).then(res => {
      return res
    })
  }
}

export const updateTechsOrder = (workers, source, target) => {
  return {
    type: types.matrixLog.UPDATE_TECHS_ORDER,
    payload: {
      workers,
      source,
      target,
    },
  }
}

export const setEvent = (
  app_type: string,
  currentEvent: object,
  to: string,
) => {
  return {
    type: types.matrixLog.SET_MATRIX_EVENT,
    app_type,
    currentEvent,
    to,
  }
}

export const updateEvent = (
  app_type: string,
  currentEvent: object,
  id: number,
  to: string,
) => {
  return {
    type: types.matrixLog.UPDATE_MATRIX_EVENT,
    app_type,
    currentEvent,
    id,
    to,
  }
}

export const deleteEvent = (app_type: string, id: string) => {
  return {
    type: types.matrixLog.DELETE_MATRIX_EVENT,
    app_type,
    id,
  }
}
export const getScrollDirection = direction => {
  return {
    type: types.matrixLog.MATRIX_SCROLL_DIRECTION,
    direction,
  }
}

export const clearMatrixEvents = (app_type: string) => {
  return {
    type: types.matrixLog.CLEAR_MATRIX_EVENTS,
    app_type,
  }
}

export const updateMatrixEvent = (
  app_type: string,
  currentEvent: object,
  to: string,
  date: string,
): ThunkAction<void, AppStateType, unknown, AnyAction> => async dispatch => {
  dispatch(setEvent(app_type, currentEvent, to))

  try {
    return await matrix.updateMatrixLog(date, { appointment: currentEvent })
  } catch (error) {
    let message = Object.values(error?.response?.data)

    if (error.response.data.errors.time) {
      message = error.response.data.errors.time
    }

    openNotificationWithIcon('error', { message })
    dispatch(getMatrixLog(date))
    // dispatch(getMatrixQueues())

    return error
  }
}
