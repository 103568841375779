import React from 'react'
import Preloader from '../Preloader'

import './styles.scss'

const LoadingScreen = () => {
  return (
    <div className='loading-wrapper'>
      <Preloader />
    </div>
  )
}

export default LoadingScreen
