import React from 'react'
import './style.scss'

const ClockedIcon = props => {
  return (
    <div className='map-label'>
      <div className='map-label-content map-label-content-clocked'>
        <div className={`clocked clocked-${props.type}`}>!</div>
      </div>
    </div>
  )
}

export default ClockedIcon
