import React from 'react'
import { Button } from './Button'
import { connect } from 'react-redux'
import { Tooltip } from 'antd'
import './TableActions.scss'

const initialTodos = ['delete', 'edit', 'showDetails']
const initialCallBacks = [() => {}, () => {}, () => {}]
const initialPreloaders = [false, false, false, false]
const initialTooltips = ['', '', '']
const initialPopConfirms = ['', '', '']
const initialPopConfirmsPositions = ['', '', '']
const initialVisible = [true, true, true, true, true, true]

const TableActions = ({
  todos = initialTodos,
  callbacks = initialCallBacks,
  preloaders = initialPreloaders,
  tooltips = initialTooltips,
  popConfirms = initialPopConfirms,
  popConfirmPositions = initialPopConfirmsPositions,
  isConfirmationNeeded,
  showDelete,
  customDisableDelete,
  myRole,
  className = '',
  disabled = initialPreloaders,
  getPopupContainer,
  section_id,
  isNewSection,
  tooltipPlacement,
  visible = initialVisible,
  ...props
}) => {
  // if (todos.includes('delete')) {
  //   if (
  //     ((myRole === 4 || myRole === 5 || myRole === 6) && !showDelete) ||
  //     customDisableDelete
  //   ) {
  //     //tech or installer or accountant
  //     const deleteIndex = todos.indexOf('delete')
  //     todos.splice(deleteIndex, 1)
  //     callbacks.splice(deleteIndex, 1)
  //   }
  // }
  const disableDefaultActionsAndBubbling = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
  return (
    <div
      className={`common-table-actions${className ? ` ${className}` : ''}`}
      {...props}
      onClick={disableDefaultActionsAndBubbling}
    >
      {todos.map(
        (todo, idx) =>
          !!visible[idx] && (
            <Button
              key={todo + idx}
              type={todo}
              action={callbacks[idx]}
              isConfirmationNeeded={isConfirmationNeeded}
              preloader={preloaders[idx]}
              tooltip={tooltips[idx] ? tooltips[idx] : ''}
              popConfirm={popConfirms[idx] ? popConfirms[idx] : ''}
              popConfirmPosition={popConfirmPositions[idx]}
              isLast={idx === todos.length - 1}
              disabled={isNewSection}
              getPopupContainer={getPopupContainer}
              tooltipPlacement={tooltipPlacement}
            />
          ),
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  myRole: state.orcatec.user.me.role_id,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TableActions)
