export const isValidCoordinates = (coordinates, isAssoc = false) => {
  if (!coordinates || (!isAssoc && coordinates.length !== 2)) {
    return false
  }

  const lat = isAssoc ? coordinates.lat : coordinates[0]
  const lng = isAssoc ? coordinates.lng : coordinates[1]

  return lat && lng && !isNaN(Number(lat)) && !isNaN(Number(lng))
}
