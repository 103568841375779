import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as userAPI from 'api/User'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import moment from 'moment-timezone'
import { AppStateType } from 'store'
import { setMe } from 'store/Orcatec/actions/settings/user'
import { AsyncThunkAPI } from 'features/Dispatch/types'
import { User, UserStatus } from 'types/User'

const initialUser: User = {
  background_color: '#626ed4',
  commission_type: 1,
  commission_percent: 0,
  full_name: '',
  email: '',
  info: {
    address: {
      address: '',
      city: '',
      state: '',
      lat: '',
      lng: '',
      unit: '',
      zip: '',
    },
    coordination: [],
    number_prefix: '',
    profile_picture: '',
    use_office_address: true,
    change_date_status: moment()
      .utc()
      .format(),
  },
  labor_rate: '0',
  // permissions: getModulesByRole(1),
  phone: '',
  project_status_change: true,
  restrict_contract_access: false,
  // role_id: UserRole.ADMIN,
  status: UserStatus.Pending,
  title: '',
  useAsTech: true,
  use_clock_in_with_gps: false,
  // has_access_to_proposal_insights: false,
  // proposal_insights_action_level: 1,
  // proposal_insights_open_level: null,
}

export const fetchUser = createAsyncThunk<User, string>(
  'users/fetchUser',
  async id => {
    const data = await userAPI.getUser(id)

    return data
  },
)

interface PostUser extends User {
  make_column: boolean
}

export const createUser = createAsyncThunk<User, PostUser, AsyncThunkAPI>(
  'users/createUser',
  async (user, { rejectWithValue }) => {
    try {
      const { user: createdUser } = await userAPI.createUser(user)
      return createdUser
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateUser = createAsyncThunk<
  User,
  { user: User; confirm: boolean },
  AsyncThunkAPI
>(
  'users/updateUser',
  async ({ user, confirm }, { rejectWithValue, getState, dispatch }) => {
    try {
      const updatedUser = await userAPI.updateUser(user.id, user, confirm)

      const me = getState().orcatec.user.me
      if (user.id === me.id) dispatch(setMe(updatedUser))

      return updatedUser
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

const initialState: {
  status: 'idle' | 'loading' | 'success' | 'error'
  currentUser: User
  error: { [key: string]: string } | null
  redirect: boolean
} = {
  status: 'idle',
  currentUser: initialUser,
  error: null,
  redirect: false,
}

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserAddress: (state, { payload }) => {
      state.currentUser.info.address = payload
    },
    setUserGroup: (state, { payload }) => {
      state.currentUser.group_id = payload
    },
    resetUser: state => {
      state.redirect = false
      state.error = null
      state.status = 'idle'
      state.currentUser = initialUser
    },
  },
  extraReducers: builder => {
    // builder.addCase(fetchUser.pending, state => {
    //   state.loading = true
    // })
    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      state.currentUser = payload
      state.status = 'success'
    })
    builder.addCase(fetchUser.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.redirect = true
      state.currentUser = payload
      openNotificationWithIcon('success', { message: `User has been created` })
    })
    builder.addCase(createUser.rejected, (state, { payload }) => {
      state.error = payload?.errors || null
      openNotificationWithIcon('error', {
        message: payload?.message || `Something went wrong`,
      })
    })
    builder.addCase(updateUser.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.status = 'success'
      state.currentUser = payload
      state.error = null
      openNotificationWithIcon('success', {
        message: `Your settings have been updated`,
        key: 'user-settings',
        maxCount: 1,
      })
    })
    builder.addCase(updateUser.rejected, (state, { payload }) => {
      state.status = 'error'
      state.error = payload?.errors || payload || null

      openNotificationWithIcon('error', {
        message:
          Object.values(payload?.errors || {})?.[0] ||
          payload?.message ||
          `Something went wrong`,
      })
    })
  },
})

export default userSlice.reducer
export const { setUserAddress, resetUser, setUserGroup } = userSlice.actions

export const selectCurrentUser = (state: AppStateType) =>
  state.orcatec.usersSlice.currentUser

export const isUserJuanMilanFromSafetyNet = (state: AppStateType) =>
  state.orcatec.user.me.id === 1450

export const canVerifyForm = (state: AppStateType) =>
  [1450, 1315, 1316, 2138, 1459, 1873, 2122].includes(state.orcatec.user.me.id)
