import { IMessage } from '../../../../types'
import React, { useEffect, useRef, useState } from 'react'

import { messagingAPI } from 'api/MessagingAPI'
import { parsePhoneNumber } from '../../../../utils'
import moment from 'moment-timezone'
import { Avatar, Image, Skeleton, Tooltip, Popover, Button, Spin } from 'antd'
// import useIntersectionObserver from '../../../../hooks/useIntersectionObserver'
import {
  DescriptionText,
  FailedImageContainer,
  SeenBlock,
} from './ChatMessage.styles'
import {
  UserOutlined,
  QuestionCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { useAppSelector } from 'store/Orcatec/hooks'
// import { UserRole } from 'types/User'
import { downloadByPablicLink } from 'api/Media'
import { downLoadFile as upload } from 'helpers/downloadFile/downloadFile'
import './ChatMessage.scss'
import { ErrorIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import styled from 'styled-components'
import { PermMedia } from '@material-ui/icons'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { getFileTypeByUrl } from 'helpers/getFileTypeByUrl'

type Props = IMessage & {
  unseenMessages: (id: number) => void
  deleteMessage: (id: number) => void
  resendMessage: (id: number) => void
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

// const selectMyRole = state => state.orcatec.user.me.role_id
export const ChatMessage: React.FC<Props> = React.memo(props => {
  const {
    num_media,
    api_id,
    from,
    message,
    status,
    created_at,
    created_by,
    id,
    // isAuthSeen,
    seen,
    deleteMessage,
    resendMessage,
    chatInfo,
  } = props

  // const [media, setMedia] = useState([])
  // const [loading, setLoading] = useState(true)
  const [uploadLoader, setUploadLoader] = useState(false)
  // const [isMessageSent, setIsMessageSent] = useState(isAuthSeen || false)
  const [errorImg, setErrorImg] = useState(false)

  // const myRoleId = useAppSelector(selectMyRole)
  // const isCantSendSeen = [
  //   UserRole?.DEFAULT_SUPER_ADMIN,
  //   UserRole?.SUPER_ADMIN,
  // ]?.includes(myRoleId)
  const ref = useRef<HTMLDivElement | null>(null)
  // const entry = useIntersectionObserver(ref, {})
  const companyID = useAppSelector(state => state.orcatec.company.id)
  const userID = useAppSelector(state => state.orcatec.user.me.id)
  const [open, setOpen] = useState(false)

  const handleUploadFile = async path => {
    const fileType = getFileTypeByUrl(path)
    if (!fileType || !path) {
      return openNotificationWithIcon('error', {
        message: 'An error occurred while uploading the file',
      })
    }
    setUploadLoader(true)
    try {
      const { file } = await downloadByPablicLink(path)

      await upload(file, `${moment().format('MM/DD/YYYY hh:mm A')}${fileType}`)
    } catch (error) {
      console.error(error)
      openNotificationWithIcon('error', {
        message: 'An error occurred while uploading the file',
      })
    } finally {
      setUploadLoader(false)
    }
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  useEffect(() => {
    if (num_media >= 1) {
      // setLoading(true)
      messagingAPI
        .getMediaForMessage(api_id)
        .then(({ media }) => {
          if (!media.length) {
            setErrorImg(true)
          } else {
            // setMedia(media)
          }
        })
        .catch(e => {
          setErrorImg(true)
          console.error(e)
        })
      // .finally(() => setLoading(false))
    }
  }, [])

  // const isVisible = !!entry?.isIntersecting

  // useEffect(() => {
  //   if (isAuthSeen || !isVisible || isCantSendSeen) {
  //     return
  //   }

  //   const data = { messageId: [id] }

  //   messagingAPI.seenMessage(data).catch(e => {
  //     setIsMessageSent(false)
  //     console.error(e)
  //   })
  // }, [isVisible, isAuthSeen, isMessageSent])

  const content = id => (
    <div>
      <p>Your message was not sent.</p>
      <div style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
        {/* <Button onClick={() => hide()} size='small'>
          Cancel
        </Button> */}
        <Button onClick={() => deleteMessage(id)} danger={true} size='small'>
          Delete
        </Button>
        <Button onClick={() => resendMessage(id)} type='primary' size='small'>
          Resend
        </Button>
      </div>
    </div>
  )
  const title = () => (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <QuestionCircleOutlined style={{ color: 'red' }} />
      Not Delivered
    </div>
  )

  return (
    <div
      id={`${id}`}
      ref={ref}
      className={`messages message-block ${
        created_by?.company_id === companyID ? 'mine' : 'yours'
      }`}
    >
      <SenderRow>
        {!created_by?.company_id ? (
          <DescriptionText>
            {`${
              chatInfo?.client_name
                ? chatInfo?.client_name
                : parsePhoneNumber(from)
            } ${moment(created_at).format('hh:mm A')}`}
          </DescriptionText>
        ) : (
          <DescriptionText>
            {`${created_by?.full_name ?? parsePhoneNumber(from)} ${moment(
              created_at,
            ).format('hh:mm A')}`}
          </DescriptionText>
        )}
      </SenderRow>
      <div className='message last'>
        {message && (
          <p
            style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
            className='message-text'
          >
            {message}
          </p>
        )}
        <div className='message-attachments'>
          {errorImg ? (
            <FailedImageContainer
              onClick={() => handleUploadFile(props?.media?.[0])}
            >
              <Tooltip title='Click to download'>
                {' '}
                {uploadLoader ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <PermMedia />
                )}{' '}
              </Tooltip>
            </FailedImageContainer>
          ) : null}
          {props.media?.length && !errorImg ? (
            <Image
              key={props.media[0]}
              src={props.media[0]}
              preview={{ src: props.media[0] }}
              width={'100%'}
              className='message-image'
              alt='Message Image'
              onError={() => setErrorImg(true)}
              placeholder={
                <Skeleton.Image style={{ width: '100%', height: '200px' }} />
              }
            />
          ) : null}
        </div>
      </div>

      <div>
        <SenderRow>
          {[2, 5].includes(status) ? (
            <>
              {' '}
              {/* <Popconfirm title='Are you sure to resend this message?' onConfirm={() => resendMessage(id)}>
                <div style={{ cursor: 'pointer' }}>
                  <RetryIcon />
                </div>
              </Popconfirm> */}
              {/* <Popconfirm
                title='Your message was not sent. Try again to send this message or can delete. '
                onCancel={() => null}
                okText='Delete'
                onConfirm={() => deleteMessage(id)}
              >
                <div style={{ cursor: 'pointer' }}>
                  <ErrorIcon />
                </div>
              </Popconfirm> */}
              <Popover
                content={content(id)}
                title={title}
                trigger={['click', 'hover']}
                open={open}
                onOpenChange={handleOpenChange}
                placement='left'
              >
                <div style={{ cursor: 'pointer' }}>
                  <ErrorIcon />
                </div>
              </Popover>
            </>
          ) : null}
        </SenderRow>
      </div>

      <SeenBlock>
        <Avatar.Group maxCount={3}>
          {seen.map(({ id, profile_picture, full_name }) =>
            id !== userID ? (
              <Tooltip key={id} title={full_name}>
                <Avatar
                  style={{ cursor: 'pointer' }}
                  src={`${process.env.REACT_APP_API_MEDIA}${profile_picture}`}
                  size={25}
                  gap={10}
                  alt='User picture'
                  icon={<UserOutlined />}
                />
              </Tooltip>
            ) : null,
          )}
        </Avatar.Group>
      </SeenBlock>
    </div>
  )
})

export const SenderRow = styled.div`
  display: flex;
  /* gap: 10px; */
  align-items: center;
  margin-bottom: 2px;
`
ChatMessage.displayName = 'ChatMessage'
