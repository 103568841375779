import { Button } from '@material-ui/core'
import { Tooltip } from 'antd'
import { EditIcon } from '../../Icons/CommonIcons'

import './styles.scss'

const EditButton = ({ onClick, title = 'Edit address' }) => {
  return (
    <Tooltip title={title} mouseLeaveDelay={0}>
      <Button
        variant='outlined'
        onClick={onClick}
        className='edit-button'
        startIcon={<EditIcon />}
      />
    </Tooltip>
  )
}

export default EditButton
