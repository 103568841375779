import { ContactsPermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'
import { NotesPermissions } from '../notes/types'
import { ModuleName } from '../../module_permissions/types'

export const contacts = {
  contacts_list_can_read: {
    name: 'View Contacts List',
    control_type: UniteType.FIRST_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.CONTACTS_LIST_CAN_READ,
    related_key: ['all'],
    is_module: true,
  },

  contacts_can_delete: {
    name: 'Delete Contact',
    control_type: UniteType.FIRST_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.CONTACTS_CAN_DELETE,
    related_key: [],
  },
  notes_can_read: {
    name: 'View Notes',
    with_access_scope: false,
    with_access_checkbox: true,
    control_type: UniteType.THIRD_VIEW,
    permission_key: NotesPermissions.NOTES_CAN_READ,
    parent_permission_key: NotesPermissions.NOTES_CAN_READ,
    description: '',
    is_module: false,
    related_key: [
      NotesPermissions.NOTES_CAN_CREATE,
      NotesPermissions.NOTES_CAN_DELETE,
      NotesPermissions.NOTES_CAN_EDIT,
    ],
    related_modules: ModuleName.NOTES,
    children: [
      {
        name: 'Create a New Note',
        with_access_scope: false,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: NotesPermissions.NOTES_CAN_CREATE,
        parent_permission_key: NotesPermissions.NOTES_CAN_READ,
        description: '',
        related_key: [],
      },
      {
        name: 'Edit Note',
        with_access_scope: true,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: NotesPermissions.NOTES_CAN_EDIT,
        parent_permission_key: NotesPermissions.NOTES_CAN_READ,
        description: '',
        related_key: [],
        custom_access_scope: [
          {
            key: 1,
            label: 'All Notes  ',
            activeColor: '#adf7b6',
            color: 'white',
            disabled: false,
          },
          {
            key: 2,
            label: 'Own Notes ',
            color: 'white',
            activeColor: '#adf7b6',
            disabled: false,
          },
        ],
      },
      {
        name: 'Delete Note',
        with_access_scope: true,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: NotesPermissions.NOTES_CAN_DELETE,
        parent_permission_key: NotesPermissions.NOTES_CAN_READ,
        description: '',
        related_key: [],
        custom_access_scope: [
          {
            key: 1,
            label: 'All Notes ',
            activeColor: '#adf7b6',
            color: 'white',
            disabled: false,
          },
          {
            key: 2,
            label: 'Own Notes ',
            color: 'white',
            activeColor: '#adf7b6',
            disabled: false,
          },
        ],
      },
    ],
  },
}
