import { createGlobalStyle } from 'styled-components'

export const FONT_COLORS = {
  main: '#262626',
  secondary: '#4D4D4D',
  tertiary: '#999999',
}

export const BUTTON_COLORS = {
  primary: '#4285f4',
  primaryHover: '#40a9ff',
}

export const GlobalStyles = createGlobalStyle`
  body {
    color: #262626 !important;
    font-family: ${props => props.font}, 'sans-serif';

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: ${props => props.font}, 'sans-serif';
    }
  }
`
export default GlobalStyles
