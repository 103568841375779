import { unseenTicketsCount } from '../../../api/supportTickets'
import {
  SET_UNSEEN_TICKETS_COUNT,
  INCREMENT_UNSEEN_TICKETS_COUNT,
  DECREMENT_UNSEEN_TICKETS_COUNT,
} from '../types/supportTickets'

export const fetchUnseenTickets = () => dispatch => {
  return unseenTicketsCount().then(response => {
    dispatch({
      type: SET_UNSEEN_TICKETS_COUNT,
      payload: response.data.unseen_count,
    })
  })
}

export const setUnseenMessagesCount = count => dispatch => {
  dispatch({
    type: SET_UNSEEN_TICKETS_COUNT,
    payload: count,
  })
}

export const incrementUnseenTickets = () => dispatch => {
  dispatch({
    type: INCREMENT_UNSEEN_TICKETS_COUNT,
  })
}

export const decrementUnseenTickets = () => dispatch => {
  dispatch({
    type: DECREMENT_UNSEEN_TICKETS_COUNT,
  })
}
