import axiosOrcatec from './../axiosInstance'

/**
 * Fetch proposal setting.
 *
 * @return {*}
 */
export const fetchProposal = industryId => {
  const industry = industryId ? `?industryId=${industryId}` : ''

  return axiosOrcatec.get(`setting/proposal${industry}`).then(response => {
    return response.data
  })
}

/**
 * Save proposal setting.
 *
 * @param data
 * @return {*}
 */
export const saveProposal = data => {
  return axiosOrcatec
    .post('setting/proposal', data)
    .then(response => response.data)
}

export const getDefaultTaxesList = params => {
  return axiosOrcatec
    .get('/tax-rates', { params })
    .then(response => response.data)
}
export const getCustomTaxesList = params => {
  return axiosOrcatec
    .get('/tax-rates/individual', { params })
    .then(response => response.data)
}

export const createTaxRate = data => {
  return axiosOrcatec
    .post('/tax-rates/individual', data)
    .then(response => response.data)
}
export const deleteTaxRate = taxRateId => {
  return axiosOrcatec
    .delete(`/tax-rates/individual/${taxRateId}`)
    .then(response => response.data)
}
export const updateTaxRate = (taxRateId, data) => {
  return axiosOrcatec
    .patch(`/tax-rates/individual/${taxRateId}`, data)
    .then(response => response.data)
}

export const getTaxRateByZip = (
  zipCode: string,
  params: { city?: string, address?: string },
) => {
  return axiosOrcatec
    .get(`/tax-rates/by-zip/${zipCode}`, { params })
    .then(response => response.data)
}
