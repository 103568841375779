export const transformStringIfAddressInHere = value => {
  if (value !== undefined) {
    value = value + ''
    value = value.replace(/address/g, 'a\u0000ddress')
    value = value.replace(/Address/g, 'A\u0000ddress')
    value = value.replace(/postcode/g, 'p\u0000ostcode')
    value = value.replace(/Postcode/g, 'P\u0000ostcode')
    value = value.replace(/ZIP/g, 'Z\u0000IP')
    value = value.replace(/zip/g, 'z\u0000ip')
    value = value.replace(/Zip/g, 'Z\u0000ip')
    value = value.replace(/unit/g, 'u\u0000nit')
    value = value.replace(/Unit/g, 'U\u0000nit')
    value = value.replace(/state/g, 's\u0000tate')
    value = value.replace(/State/g, 'S\u0000tate')
    value = value.replace(/city/g, 'c\u0000ity')
    value = value.replace(/City/g, 'C\u0000ity')
  }
  return value
}

export const revertOfTransformStringIfAddressInHere = value => {
  return value.replace('\u0000', '')
}
