import axiosOrcatec from './axiosInstance'
import { getQueryStringFromObject } from '../helpers/getQueryStringFromObject'

// PROPERTIES

export const searchProposalProperties = async props => {
  const params = getQueryStringFromObject(props)
  const { data } = await axiosOrcatec.get(`/proposals_properties/address${params ? `?${params}` : ''}`)
  return { DB: data }
}

export const getAllProposalProperties = async id => {
  const { data } = await axiosOrcatec.get(`/proposals_properties?proposal_id=${id}`)
  return data
}

export const fetchProposalProperties = async id => {
  const { data } = await axiosOrcatec.get(`/proposals_properties/${id ? `${id}` : ''}`)
  return data
}

export const putProposalPropertiesAttach = async (proposal_id, propertyId) => {
  const { data } = await axiosOrcatec.put(`/proposals_properties/attach_parent/${propertyId}`, { proposal_id })
  return data
}

export const putProposalPropertiesDetach = async (proposal_id, reqData) => {
  const { data } = await axiosOrcatec.put(`/proposals_properties/detach/${proposal_id}`, reqData)
  return data
}

export const postProposalPropertiesSave = async (proposal_id, req) => {
  const { data } = await axiosOrcatec.post(`/proposals_properties/save?proposal_id=${proposal_id}`, req)
  return data
}

export const postProposalPropertiesSetPosition = async req => {
  const { data } = await axiosOrcatec.post(`/proposals_properties/set_position`, req)
  return data
}

// CLIENTS

export const getAllProposalClients = async id => {
  const { data } = await axiosOrcatec.get(`/proposals_clients?proposal_id=${id}`)
  return data
}

export const putProposalClientAttach = async (proposal_id, clientId) => {
  const { data } = await axiosOrcatec.put(`/proposals_clients/attach_parent/${clientId}`, { proposal_id })
  return data
}

export const putProposalClientsDetach = async (proposal_id, reqData) => {
  const { data } = await axiosOrcatec.put(`/proposals_clients/detach/${proposal_id}`, reqData)
  return data
}

export const postProposalClientSave = async (proposal_id, req) => {
  const { data } = await axiosOrcatec.post(`/proposals_clients/save?proposal_id=${proposal_id}`, req)
  return data
}

export const postProposalClientsSetPosition = async req => {
  const { data } = await axiosOrcatec.post(`/proposals_clients/set_position`, req)
  return data
}

// ORGANIZATIONS

export const getAllProposalOrganizations = async id => {
  const { data } = await axiosOrcatec.get(`/proposals_organizations?proposal_id=${id}`)
  return data
}

export const putProposalOrganizationAttach = async (proposal_id, clientId) => {
  const { data } = await axiosOrcatec.put(`/proposals_organizations/attach_parent/${clientId}`, { proposal_id })
  return data
}

export const putProposalOrganizationsDetach = async (proposal_id, reqData) => {
  const { data } = await axiosOrcatec.put(`/proposals_organizations/detach/${proposal_id}`, reqData)
  return data
}

export const postProposalOrganizationsSave = async (proposal_id, req) => {
  const { data } = await axiosOrcatec.post(`/proposals_organizations/save?proposal_id=${proposal_id}`, req)
  return data
}
