import styled from 'styled-components'
import { MessagesList } from './MessagesList'
import { ContactWithMessage } from '../../types'

interface Props {
  industryLogo: string | null
  selectedClients: Record<number, ContactWithMessage>
  selectedTechs: Record<number, ContactWithMessage>
  otherContacts: object
  onMessageChange: (
    entity: 'client' | 'tech',
  ) => (
    type: 'emailMessage' | 'phoneMessage',
    contactId: number,
    message: string,
  ) => void
  onOtherContactsMessageChange: (
    type: 'emailMessage' | 'phoneMessage',
    _contactId: number,
    message: string,
  ) => void
}

export const MessageSection = ({
  selectedClients,
  selectedTechs,
  onMessageChange,
  industryLogo,
  otherContacts,
  onOtherContactsMessageChange,
}: Props) => {
  const selectedContactsPhones = [
    ...Object.values(selectedClients),
    ...Object.values(selectedTechs),
  ].flatMap(contact => contact.phones || [])

  const selectedContactsEmails = [
    ...Object.values(selectedClients),
    ...Object.values(selectedTechs),
  ].flatMap(contact => contact.emails || [])

  const otherContactEmails =
    !!otherContacts.emails.value.length && otherContacts.emails.checked
      ? otherContacts.emails.value
      : []

  const otherContactsPhones =
    !!otherContacts.phone.value.length && otherContacts.phone.checked
      ? [otherContacts.phone.value]
      : []

  if (
    ![
      ...selectedContactsPhones,
      ...selectedContactsEmails,
      ...otherContactEmails,
      ...otherContactsPhones,
    ].length
  )
    return <p>Select at least one email or phone number </p>

  return (
    <Wrapper>
      <MessagesList
        title='Clients'
        data={Object.values(selectedClients)}
        logo={industryLogo}
        onMessageChange={onMessageChange('client')}
      />
      <ContactListWrapper>
        <MessagesList
          title='Technicians'
          data={Object.values(selectedTechs)}
          logo={industryLogo}
          onMessageChange={onMessageChange('tech')}
        />
      </ContactListWrapper>

      <ContactListWrapper>
        <MessagesList
          title='Others'
          data={[
            {
              id: 2,
              name: 'Others',
              emails: otherContactEmails,
              phones: otherContactsPhones,
              emailMessage: otherContacts.emailMessage,
              phoneMessage: otherContacts.phoneMessage,
              type: 2,
            },
          ]}
          logo={industryLogo}
          onMessageChange={onOtherContactsMessageChange}
        />
      </ContactListWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const ContactListWrapper = styled.div``
