import React, { useState, useEffect } from 'react'
import { Wrapper, Row } from '../PublicEvent.styles'
import { Job } from 'types/Jobs'
import {
  getEventByPublicLink,
  changePublicEventStatus,
  updatePublicEvent,
  getPublicFormByHash,
} from 'api/PublicEvent/PublicEvent'
import { useParams } from 'react-router-dom'
import { Skeleton, Result } from 'antd'

import AppointmentStatusSwitcher from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentStatusSwitcher'
import { AppointmentStatusID } from 'types/Appointment'
import { useDispatch } from 'react-redux'
import { setForm } from 'store/Orcatec/actions/proposal/proposalForm'
import { itemsSet } from 'features/Project/slices/projectItemsSlice'

import ProposalSettings from 'containers/MainContent/Orcatec/Proposals/components/ProposalSettings'
import { Attachments } from 'features/PublicEvent/PublicEventPage/JobPublicView/components/Attachments/Attachments'
import { ProjectStatusGroup } from 'features/Project/types'

import { PublicItems } from './components/PublicItems/PublicItems'
import { JobPublicInfo } from './components/JobPublicInfo/JobPublicInfo'
import { gatherItemsFromSections } from 'features/Project/helpers'
import { RelatedForms } from 'features/Forms/components/FormsBlock/components/RelatedForms'
import { CustomForm, ActionEnum } from 'features/Forms/types'
import { transformArrayToObj } from 'features/Dispatch/helpers'
// import { CustomFormEntityType } from 'features/Forms/types'
import { setCompany } from 'store/Orcatec/actions/settings/company'
import { EditFormModal } from 'features/Forms/components/FormsBlock/components/EditFormModal'

export const JobPublicView = () => {
  const { hash } = useParams()
  const dispatch = useDispatch()
  const [event, setEvent] = useState<Job | null>(null)
  const [remoteContacts, setRemoteContacts] = useState([])
  const [columnsInfo, setColumnInfo] = useState(null)
  const [showCancellationModal, setShowCancellationModal] = useState(false)
  const [fetching, setFetching] = useState(true)
  const [error, setError] = useState(false)
  const [dictionary, setDictionary] = useState({
    plural: 'Jobs',
    singular: 'Job',
  })
  // const [sliderItemsCount, setSliderItemsCount] = useState(3)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [template, setTemplate] = useState<CustomForm | null>(null)
  const [formAction, setFormAction] = useState(ActionEnum.CREATE)
  // const [isModalFormOpen, setIsModalFormOpen] = useState(false)

  const [relatedForm, setRelatedForms] = useState<{
    data: Record<number, CustomForm>
    ids: number[]
  }>({
    data: {},
    ids: [],
  })
  const handleChangeStatus = async (
    appointmentID: number | undefined,
    appointmentStatusID: AppointmentStatusID,
  ) => {
    let data = { status: appointmentStatusID }

    if (appointmentStatusID === AppointmentStatusID.Cancelled) {
      data = {
        status: appointmentStatusID,
        reason: event?.canceled_info?.reason,
      }
      setShowCancellationModal(false)
    }
    await changePublicEventStatus(hash, data)

    setEvent(prev => ({
      ...prev,
      status: appointmentStatusID,
    }))
  }

  const handleChangeCancellationReason = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target

    setEvent(prev => ({
      ...prev,
      canceled_info: {
        ...(prev?.canceled_info || {}),
        reason: value,
      },
    }))
  }

  const handleSaveFiles = async files => {
    const data = {
      ...event,
      proposal_info: {
        ...event.proposal_info,
        proposal_tabs: [
          {
            ...event.proposal_info.proposal_tabs[0],
            files: files,
          },
        ],
      },
    }
    await updatePublicEvent(hash, data)
    setEvent(data)
  }

  const handleChangeNotesForWorkers = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const data = {
      ...event,
      proposal_info: {
        ...event.proposal_info,
        proposal_tabs: [
          {
            ...event.proposal_info.proposal_tabs[0],
            notes_for_installers: e,
          },
        ],
      },
    }
    setEvent(data)
    updatePublicEvent(hash, data)
  }

  const handleChangeDescriptions = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    const newFiles = [...event.proposal_info?.proposal_tabs?.[0]?.files]
    newFiles[idx].description = e
    const data = {
      ...event,
      proposal_tabs: [
        {
          ...event.proposal_info.proposal_tabs[0],
          files: newFiles,
        },
      ],
    }

    setEvent(data)
    updatePublicEvent(hash, data)
  }

  const handleSaveOnBlur = async () => {
    await updatePublicEvent(hash, event)
  }

  const hadleFormUpdate = (editedForm: CustomForm) => {
    if (!editedForm) return

    setIsModalEditOpen(false)
    setTemplate(null)

    setRelatedForms(prev => ({
      ...prev,
      data: {
        ...prev.data,
        [editedForm.id]: editedForm,
      },
    }))
  }

  const handleFormClose = (
    formId: number,
    fieldToUpdate: Record<keyof CustomForm, string>,
  ) => {
    setRelatedForms(prev => ({
      ...prev,
      data: {
        ...prev.data,
        [formId]: {
          ...prev.data[formId],
          ...fieldToUpdate,
        },
      },
    }))

    setIsModalEditOpen(false)
  }

  const handleUpdateForm = (form: CustomForm) => {
    setFormAction(ActionEnum.UPDATE)
    // setIsModalFormOpen(false)
    setIsModalEditOpen(true)
    setTemplate(form)
  }

  useEffect(() => {
    const fetchEventDetails = async hash => {
      setFetching(true)
      try {
        const {
          data: event,
          remote_contacts,
          dictionary,
          ...rest
        } = await getEventByPublicLink(hash)
        setDictionary(dictionary.job)
        setRemoteContacts(remote_contacts)
        setColumnInfo(rest)

        dispatch(setCompany({ ...event.company, dictionary }))

        dispatch(
          setForm(
            { ...event?.proposal_info, status: 1 },
            {},
            false,
            ['HVAC'],
            [],
            '',
          ),
        )

        dispatch(
          itemsSet(
            gatherItemsFromSections(event?.proposal_info?.proposal_tabs?.[0]),
          ),
        )
        setEvent(event)
      } catch (error) {
        console.error(error)
        setError(true)
      } finally {
        setFetching(false)
      }
    }

    if (hash) fetchEventDetails(hash)
  }, [hash])

  useEffect(() => {
    const fetchEventForms = async hash => {
      try {
        const res = await getPublicFormByHash(hash)
        setRelatedForms({
          data: transformArrayToObj(res),
          ids: res?.map(form => form.id),
        })
      } catch (error) {
        console.error(error)
        setError(true)
      }
    }

    if (hash) fetchEventForms(hash)
  }, [hash])

  return (
    <Wrapper>
      {fetching ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : error ? (
        <Result
          status='404'
          title='404'
          subTitle='Sorry, the page you visited does not exist.'
        />
      ) : (
        <>
          <Row>
            {!!event?.proposal_info?.setting && (
              <ProposalSettings settings={event?.proposal_info?.setting} />
            )}
          </Row>
          <Row>
            <h6>{`${dictionary?.plural} Status`}</h6>
            <AppointmentStatusSwitcher
              appointment={event}
              cancellationOnChange={handleChangeCancellationReason}
              handleChangeStatus={handleChangeStatus}
              showCancellationModal={showCancellationModal}
              setShowCancellationModal={setShowCancellationModal}
              isSchedule
              canEdit={true}
              statusHistoryVisible={false}
              withText
            />
          </Row>
          <Row>
            <JobPublicInfo
              proposalCode={event?.proposal_info?.code}
              columnsInfo={columnsInfo}
              contacts={remoteContacts}
            />
          </Row>
          {!!relatedForm?.ids?.length && (
            <Row>
              <h6>{`Forms`}</h6>

              <RelatedForms
                showRelatedInfo={false}
                relatedForm={relatedForm.ids.map(id => relatedForm.data[id])}
                handleUpdateForm={handleUpdateForm}
                isPublicView={true}
                // handleChangeSliderItemsCount={(count: number) =>
                //   setSliderItemsCount(count)
                // }
              />
            </Row>
          )}

          <Row>
            <Attachments
              data={event?.proposal_info?.proposal_tabs?.[0]?.files}
              tooltip
              onUpload={handleSaveFiles}
              title='Notes'
              hash={hash}
              publicUsage={true}
              handleChangeNotesForWorkers={handleChangeNotesForWorkers}
              handleChangeDescriptions={handleChangeDescriptions}
              handleSaveOnBlur={handleSaveOnBlur}
              notesValue={
                event?.proposal_info?.proposal_tabs?.[0].notes_for_installers
              }
            />
          </Row>

          <Row>
            <PublicItems
              sections={
                event?.proposal_info?.proposal_tabs?.[0]?.proposal_sections
              }
              proposalStatus={event?.status}
              showProgress={
                event?.proposal_info?.setting?.completion_progress &&
                [
                  ProjectStatusGroup.Contract,
                  ProjectStatusGroup.Completed,
                  ProjectStatusGroup.Canceled,
                ].includes(event?.status)
              }
              isJobView={false}
              showModules={false}
            />
          </Row>
        </>
      )}

      {isModalEditOpen && (
        <EditFormModal
          action={formAction}
          form={template}
          onSave={hadleFormUpdate}
          handleCancel={handleFormClose}
          createRelatedForm={() => null}
          entityID={hash}
          isPublicView={true}
        />
      )}
    </Wrapper>
  )
}
