import React, { useState } from 'react'
import './index.scss'
import { Modal, Button } from 'antd'
import Card from '../../../Card'
import MainButton from '../../../../../components/buttons/MainButton'
import CardContainer from 'containers/MainContent/Orcatec/Proposals/components/PaymentProcess/components/Card'

const ModalUpdateCard = props => {
  // const wasEdited = props.isNewCard || props.isOldCardChanged

  const handleChangeCardData = e => {
    const { name, value } = e.target

    if (name === 'card_holder') {
      return props.setData(prev => ({ ...prev, [name]: value }))
    }

    props.setData(prev => ({ ...prev, billing_address: { ...prev?.billing_address, [name]: value } }))
  }

  const handleSetToken = ({ expiry, token }) => {
    props.setData(prev => ({ ...prev, token, expiry }))
  }
  return (
    <>
      <Modal
        title={`Card info`}
        visible={props.visible}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        centered
        destroyOnClose
        maskClosable={false}
        className='modal_update_card'
        width='auto'
        okText='Save'
        footer={[
          <Button key='back' onClick={props.handleCancel}>
            Cancel
          </Button>,
          <MainButton
            key='submit'
            title={props.isDebtor ? 'Update & Pay' : 'Save'}
            onClick={props.handleOk}
            // disabled={!!props.error || !wasEdited}
            isFetching={props.loading}
          />,
        ]}
      >
        {!!props.visible && (
          <div style={{ width: 350 }}>
            {props.isDebtor && <p>Total: ${props.amount}</p>}
            {/* <Card
              parentEl={props.parentEl}
              error={props.error}
              cardData={props.cardData}
              setData={(e, a, b) => console.log(e, a, b)}
              onSubmit={props.handleOk}
              visible={props.visible}
              fieldErrors={props.fieldErrors}
              setFieldErrors={props.setFieldErrors}
              setError={props.setError}
              setIsNewCard={props.setIsNewCard}
              setIsOldCardChanged={props.setIsOldCardChanged}
            /> */}
            <CardContainer
              cardData={props.cardData}
              onChangeCardData={handleChangeCardData}
              onGotToken={handleSetToken}
            />
          </div>
        )}
      </Modal>
    </>
  )
}

export default ModalUpdateCard
