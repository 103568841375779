import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Rate, Empty, Tooltip } from 'antd'
import moment from 'moment-timezone'
import Rating from '../Rating'

import './style.scss'
import MainButton from '../../../components/buttons/MainButton'
import TableActions from '../../../components/TableActionsNew'
import { connect } from 'react-redux'
import Icon from 'antd/es/icon'
import { TextField } from '@material-ui/core'
import { openNotificationWithIcon } from '../../../../../../helpers/notifications/openNotificationWithIcon'

const Comment = ({
  item,
  // newComment,
  index,
  onSaveFeedback,
  // onCreateFeedback,
  onDeleteFeedback,
  clientId,
  isCanEditDelete,
  toggleEditComment,
  // readOnly,
}) => {
  const [edit, toggleEdit] = useState(false)
  const [value, changeValue] = useState('')
  const [valueError, changeValueError] = useState('')
  const [rating, changeRating] = useState(item.vote)
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const onEdit = value => {
    changeValue(value)
    toggleEdit(true)
    toggleEditComment(true)
  }
  const onSave = id => {
    if (value) {
      changeValueError('')
      setSaving(true)
      onSaveFeedback(
        id,
        {
          comment: value,
          vote: rating,
        },
        clientId,
      )
        .then(() => {
          toggleEdit(false)
          toggleEditComment(false)
          setSaving(false)
        })
        .catch(() => {
          setSaving(false)
        })
    } else {
      changeValueError(prev => (!prev ? 'This field is required' : prev))
    }
  }
  const onDelete = id => {
    setDeleting(true)
    onDeleteFeedback(id, clientId)
      .then(() =>
        openNotificationWithIcon('success', {
          message: 'Success!',
          description: 'Feedback was deleted!',
        }),
      )
      .finally(() => setDeleting(false))
  }
  const handleChange = (e, newValue) => {
    newValue = newValue < 1 ? 1 : newValue
    changeRating(newValue)
  }

  let tooltipTitle = ''
  if (item.industry) {
    if (item.industry.length > 1) {
      item.industry.map((item, id) => {
        if (id === 0) {
          tooltipTitle = `${item}`
        } else {
          tooltipTitle = `${tooltipTitle}, ${item}`
        }
      })
    }
  }

  const onChangeReviewMessage = e => {
    const { value } = e.target
    changeValueError(value ? '' : 'This field is required')
    changeValue(value)
  }
  return (
    <div className={`card  ${edit ? 'editing' : ''}`} key={index}>
      <div className='card-title'>
        <div>
          <span className='date'>
            {`${new Date(item.created_at).getMonth() + 1}/${new Date(
              item.created_at,
            ).getDate()}/${new Date(item.created_at).getFullYear()}`}
          </span>
        </div>
        <span className='time-stamp'>
          <span className='time'>
            {/* {`${
              new Date(item.created_at).getHours() >= 12
                ? new Date(item.created_at).getHours() - 11 < 10
                  ? '0' + String(new Date(item.created_at).getHours() - 11)
                  : String(new Date(item.created_at).getHours() - 11)
                : new Date(item.created_at).getHours()
            }:${
              new Date(item.created_at).getMinutes() < 10
                ? '0' + new Date(item.created_at).getMinutes()
                : new Date(item.created_at).getMinutes()
            }
              ${new Date(item.created_at).getHours() >= 11 ? 'pm' : 'am'}`} */}
            {moment
              .utc(item.created_at)
              .local()
              .format('h:mm a')}
          </span>
        </span>
        <div className='created-by'>
          {item.industry && item.industry.length > 1 ? (
            <Tooltip title={tooltipTitle}>
              <strong>Added By:</strong> {item.industry.length} industries
            </Tooltip>
          ) : (
            <p>
              <strong>Added by:</strong>
              {` ${
                !item.industry
                  ? `${item.full_name}${
                      item.role ? `, ${item?.role ? item.role : ''}` : ''
                    }`
                  : `${item.industry[0]}`
              }`}
            </p>
          )}
        </div>
        {isCanEditDelete && (
          /*  !readOnly && */ <>
            {/* {edit ? (
              <div className='buttons'>
                <MainButton title='Save' className='mr-2' onClick={() => onSave(item.id)} isFetching={saving} />
              </div>
            ) : ( */}
            <TableActions
              todos={['edit', 'delete']}
              callbacks={[
                () => {
                  onEdit(item.comment)
                },
                () => {
                  onDelete(item.id)
                },
              ]}
              preloaders={[false, deleting]}
              className={`feedback-actions ${edit ? 'disabled' : ''}`}
              popConfirms={[false, 'Are you sure to delete this feedback?']}
            />
            {/* )} */}
          </>
        )}
      </div>
      <div className='card-rate'>
        <Rating
          name='rating'
          value={rating}
          readOnly={!edit}
          precision={0.5}
          size='small'
          onChange={handleChange}
        />
      </div>
      <div className='card-body'>
        {edit ? (
          <>
            <TextField
              fullWidth
              value={value}
              label='Review'
              rows={4}
              variant='outlined'
              multiline
              onChange={onChangeReviewMessage}
              error={!!valueError}
              helperText={valueError}
            />
            <div className='buttons card-actions'>
              {/* <MainButton title='Cancel' onClick={() => toggleEdit(!edit)} type='danger' />

              <MainButton title='Save' className='mr-2' onClick={() => onSave(item.id)} isFetching={saving} /> */}

              {/* <button
                onClick={() => {
                  toggleEdit(!edit)
                  toggleEditComment(false)
                }}
                className='btn btn-custom-cancel'
              >
                Cancel
              </button> */}

              <MainButton
                type='cancel'
                title='Cancel'
                onClick={() => {
                  toggleEdit(!edit)
                  toggleEditComment(false)
                }}
              />
              <MainButton
                title='Save'
                onClick={() => onSave(item.id)}
                isFetching={saving}
              />
            </div>
          </>
        ) : (
          <>
            <span>{item.comment}</span>
            {!!item.updated_at && item.created_at !== item.updated_at && (
              <div className='edited-by'>
                {item.full_name && (
                  <small>{`Edited By ${item.full_name}`}</small>
                )}
                <small className='time-stamp'>
                  <span className='date'>{`  ${new Date(
                    item.updated_at,
                  ).getMonth() + 1}/${new Date(
                    item.updated_at,
                  ).getDate()}/${new Date(
                    item.updated_at,
                  ).getFullYear()} `}</span>
                  <span className='time'>
                    {/* {`  ${
                      new Date(item.updated_at).getHours() >= 12
                        ? new Date(item.updated_at).getHours() - 11 < 10
                          ? '0' + String(new Date(item.updated_at).getHours() - 11)
                          : String(new Date(item.updated_at).getHours() - 11)
                        : new Date(item.updated_at).getHours()
                    }:${
                      new Date(item.updated_at).getMinutes() < 10
                        ? '0' + new Date(item.updated_at).getMinutes()
                        : new Date(item.updated_at).getMinutes()
                    }
                  ${new Date(item.updated_at).getHours() >= 11 ? 'pm' : 'am'}`} */}
                    {moment
                      .utc(item.created_at)
                      .local()
                      .format('H:mm a')}
                  </span>
                </small>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const NewComment = ({
  onCancel,
  onCreateFeedback,
  onCreateNew,
  clientId,
  isCreating,
}) => {
  const [value, changeValue] = useState('')
  const [valueError, changeValueError] = useState('')
  const [rating, changeRating] = useState(0)
  const [ratingError, setRatingError] = useState(false)

  const onSubmit = () => {
    if (rating > 0 && !ratingError && !!value)
      onCreateNew({ comment: value, vote: rating })
    else {
      setRatingError(prev =>
        prev ? prev : rating < 1 ? 'Please rate the contact' : '',
      )
      changeValueError(prev =>
        prev ? prev : !value ? 'This field is required' : '',
      )
    }
  }

  const onChangeReviewMessage = e => {
    const { value } = e.target
    changeValueError(value ? '' : 'This field is required')
    changeValue(value)
  }

  return (
    <div className='card new'>
      <div className='card-title'>
        <div className='d-flex flex-column align-items-center'>
          <Rating
            value={rating}
            name='rating'
            size='large'
            precision={0.5}
            onChange={(event, newValue) => {
              newValue = newValue < 1 ? 1 : newValue
              changeRating(newValue)
              setRatingError('')
            }}
          />
          {!!ratingError && (
            <div style={{ color: '#f5222d' }}>{ratingError}</div>
          )}
        </div>
      </div>
      <div className='card-body'>
        <TextField
          fullWidth
          value={value}
          label='Review'
          rows={4}
          variant='outlined'
          multiline
          onChange={onChangeReviewMessage}
          error={!!valueError}
          helperText={valueError}
        />
      </div>
      <div className='card-actions'>
        <MainButton type='cancel' title='Cancel' onClick={onCancel} />
        <MainButton title='Save' onClick={onSubmit} isFetching={isCreating} />
      </div>
    </div>
  )
}

const FeedbackModal = ({
  modal,
  close,
  feedback,
  onSaveFeedback,
  onCreateFeedback,
  onDeleteFeedback,
  clientId,
  me,
  // readOnly,
}) => {
  const [newComment, toggleNewComment] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [editComment, toggleEditComment] = useState(false)

  const onCreateNew = params => {
    setIsCreating(true)
    onCreateFeedback(clientId, params)
      .then(() => toggleNewComment(false))
      .finally(() => setIsCreating(false))
  }

  return (
    <Modal
      show={modal}
      size='lg'
      onHide={close}
      dialogClassName='feedback-modal'
      backdrop='static'
    >
      <Modal.Body>
        <div className='closeButtonWrapper'>
          <h4>Contact Rating</h4>
          <i
            className='mdi mdi-close mdi-24px'
            style={{ fontWeight: 800, cursor: 'pointer' }}
            onClick={close}
          />
        </div>
        {!!feedback &&
          !!feedback.length &&
          feedback.map((item, i) => (
            <Comment
              key={item.id}
              onSaveFeedback={onSaveFeedback}
              onDeleteFeedback={onDeleteFeedback}
              item={item}
              index={i}
              clientId={clientId}
              isCanEditDelete={item?.user_id === me?.id}
              toggleEditComment={() => toggleEditComment(!editComment)}
              newComment={newComment}
              // readOnly={readOnly}
            />
          ))}
        {/* {!feedback || (feedback && !feedback.length && !newComment && <Empty description='No feedbacks yet' />)} */}
        {(newComment || !feedback?.length) && (
          <NewComment
            onCreateFeedback={onCreateFeedback}
            onCreateNew={onCreateNew}
            onCancel={() => {
              if (!feedback.length) return close()
              toggleNewComment(false)
            }}
            clientId={clientId}
            isCreating={isCreating}
          />
        )}
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <div
            className='feedback-modal--buttons'
            style={{
              width: '250px',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            {/*    <button onClick={close} className='btn btn-custom-cancel' style={{ padding: '0.55rem .75rem' }}>
              Close
            </button> */}

            {/*   <button
              style={{ padding: '0.55rem .75rem' }}
              onClick={() => toggleNewComment(true)}
              disabled={newComment || editComment}
              className='btn btn-custom-info'
            >
              
            </button> */}

            <MainButton type='cancel' title='Close' onClick={close} />
            <MainButton
              title='Add new Comment'
              onClick={() => toggleNewComment(true)}
              disabled={newComment || editComment || !feedback?.length}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = state => ({
  me: state.orcatec.user.me,
})

export default connect(mapStateToProps, {})(FeedbackModal)
