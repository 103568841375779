import styled from 'styled-components'
import { getProgressStrokeColor } from '../../../helpers'
import { Progress } from 'antd'

interface Props {
  progress: {
    percent: number
    installed: number
    estimated: number
  }
  showTotals?: boolean
  unit: string | null
  onClick?: () => void
}

export const ProgressInfo = ({
  onClick,
  progress,
  unit,
  showTotals = true,
}: Props) => {
  return (
    <Wrapper onClick={onClick}>
      <Progress
        percent={progress.percent}
        strokeColor={getProgressStrokeColor(progress.percent)}
        showInfo={false}
        trailColor={progress.percent < 0 ? '#ff6f70' : undefined}
      />
      {showTotals && (
        <TotalInstalled>
          <span>{progress.percent}%</span>{' '}
          <span>
            ({progress.installed}/{progress.estimated}) {unit || 'Qty'}
          </span>
        </TotalInstalled>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 200px;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
  cursor: pointer;

  p {
    font-weight: 500;
  }
`

const TotalInstalled = styled.div`
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
