import React from 'react'
import { Renderer } from './components/Renderer'
import { DocProvider } from './state'
import Layout from '../ViewDoc/layout/Layout'
import ModalPortal from 'containers/MainContent/Orcatec/components/ModalPortal/ModalPortal'

const DocViewer = props => {
  return (
    props.isModalVisible && (
      <ModalPortal>
        <DocProvider {...props}>
          <Layout {...props}>
            <Renderer />
          </Layout>
        </DocProvider>
      </ModalPortal>
    )
  )
}

export default DocViewer
