import styled from 'styled-components'

export const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`
export const Section = styled.div``
export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
`
export const Settings = styled.div``
export const Footer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
`

export const GlobalSettings = styled.div``
export const HelperText = styled.p`
  font-size: 12px;
  line-height: 1.33;
  margin-top: 5px;
  color: #ef9494;
`

export const InfoPlate = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f1f2fd;
  border-radius: 4px;
  border-left: 4px solid #4688f1;
  padding: 6px 16px;
  align-items: center;

  & > div {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`
