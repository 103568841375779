import { IContact } from 'features/PublicEvent/types'
import React, { FC } from 'react'
import styled from 'styled-components'
import { Checkbox, Tooltip } from 'antd'

interface IProps {
  contact: IContact
  isMessagingUse: boolean
}

const EmailItem: FC<IProps> = ({
  contact,
  isMessagingUse,
  onChangeSelected,
}) => {
  return (
    <Item>
      <ItemTitle>{contact?.full_name || 'No name'}</ItemTitle>
      <ListContacts>
        {!!contact.emails.length
          ? contact.emails.map(item => (
              <ItemContact key={item.id}>
                <Checkbox
                  checked={item.checked}
                  onChange={e =>
                    onChangeSelected(e.target.checked, 'emails', item.id)
                  }
                />
                <p>{item.email}</p>
              </ItemContact>
            ))
          : 'No emails yet'}
        {contact.phones.map(item => (
          <ItemContact key={item.id}>
            <Checkbox
              disabled={!isMessagingUse}
              checked={item.checked}
              onChange={e =>
                onChangeSelected(e.target.checked, 'phones', item.id)
              }
            />
            <Tooltip
              title={!isMessagingUse ? 'Messaging service isn`t setup yet' : ''}
            >
              <p
                style={
                  !isMessagingUse
                    ? {
                        color: 'rgba(0, 0, 0, 0.25)',
                        cursor: 'not-allowed',
                      }
                    : {}
                }
              >
                {item.phone}
              </p>
            </Tooltip>
          </ItemContact>
        ))}
      </ListContacts>
    </Item>
  )
}

export const EmailCard: FC<IProps> = ({
  contact,
  isMessagingUse,
  onChangeSelected,
}) => {
  return (
    <EmailItem
      contact={contact}
      isMessagingUse={isMessagingUse}
      onChangeSelected={onChangeSelected}
    />
  )
}

const Item = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
  align-items: start;
`
const ItemTitle = styled.div``
const ListContacts = styled.div``
const ItemContact = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`
