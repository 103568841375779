import { messagingAPI } from 'api/MessagingAPI'
import { IExistingPhone } from '../types'
import { useLayoutEffect, useState } from 'react'
import { isMessagingSubmitted } from 'store/Orcatec/selectors/messaging'
import { useAppSelector } from 'store/Orcatec/hooks'

export const useMessagingPhones = () => {
  const isMessaging = useAppSelector(isMessagingSubmitted)
  const [phones, setPhones] = useState<IExistingPhone[]>([])
  const [phonesError, setPhonesError] = useState(false)
  const [phonesLoading, setPhonesLoading] = useState(false)

  const getPhones = () => {
    setPhonesLoading(true)
    messagingAPI
      .getAvailablePhoneNumbers<{ phones: IExistingPhone[] }>()
      .then(({ phones }) => setPhones(phones))
      .catch(e => setPhonesError(e))
      .finally(() => setPhonesLoading(false))
  }

  useLayoutEffect(() => {
    if (isMessaging) getPhones()
  }, [])

  return { phones, getPhones, phonesError, phonesLoading }
}
