import axiosOrcatec from 'api/axiosInstance'
import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import {
  ImportTableStatus,
  GlobalItem,
  ItemCategory,
  ItemUnit,
  Breadcrumb,
} from 'features/Items/types'
import { ProjectDiscount, ProjectRebate } from 'features/Project/types'
import moment from 'moment-timezone'

export interface ItemTableParams extends Partial<GeneralTableParams> {
  category_id?: number
}

const getCategories = (params?: { search?: string; category_id?: number }) =>
  axiosOrcatec
    .get<ItemCategory>('/setting/categories', { params })
    .then(res => res.data)

const getCategoryById = (id: string) =>
  axiosOrcatec.get<ItemCategory>(`/setting/categories/${id}`).then(res => ({
    ...res.data,
    subcategories: transformArrayToObj(res.data.subcategories),
  }))

const createCategory = (category: ItemCategory) =>
  axiosOrcatec
    .post<ItemCategory>(`/setting/categories`, category)
    .then(res => res.data)

const updateCategory = (category: ItemCategory) =>
  axiosOrcatec
    .put<ItemCategory>(`/setting/categories/${category.id}`, category)
    .then(res => res.data)

const deleteCategory = (category: ItemCategory) =>
  axiosOrcatec
    .delete(`/setting/categories/${category.id}`)
    .then(res => res.data)

const getItemsListByCategory = (params?: ItemTableParams) =>
  axiosOrcatec
    .get<{
      data: {
        items: GlobalItem[]
        categories: ItemCategory[]
        breadcrumbs: Breadcrumb[]
      }
      meta: Record<string, unknown>
    }>(`setting/price-pages/items`, {
      params: params,
    })
    .then(res => res.data)

const getItemsList = (params?: GeneralTableParams) =>
  axiosOrcatec
    .get<{ data: GlobalItem[]; meta: Record<string, unknown> }>(
      `setting/price-pages/items/list`,
      { params: params },
    )
    .then(res => res.data)

const exportGeneralItemsTable = (params: GeneralTableParams) =>
  axiosOrcatec
    .get(`setting/price-pages/items/list/export`, {
      params: params,
      responseType: 'blob',
    })
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Items${moment().format('MM_DD_YYYY')}.xlsx`,
      )
      document.body.appendChild(link)
      link.click()
    })

const importItemsTable = (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  return axiosOrcatec
    .post<{ status: ImportTableStatus }>(
      `setting/price-pages/items/import`,
      formData,
    )
    .then(res => res.data)
}

const getImportStatus = () =>
  axiosOrcatec
    .get(`setting/price-pages/items/import/status`)
    .then(res => res.data)

const getItemById = (itemId: number) =>
  axiosOrcatec
    .get<GlobalItem>(`/setting/price-pages/items/${itemId}`)
    .then(res => res.data)

const createItem = (item: GlobalItem) =>
  axiosOrcatec
    .post<GlobalItem>(`/setting/price-pages/items`, item)
    .then(res => res.data)

const duplicateItem = (item: GlobalItem) =>
  axiosOrcatec
    .post<GlobalItem>(`/setting/price-pages/items/copy/${item.id}`)
    .then(res => res.data)

const updateItem = (item: GlobalItem) =>
  axiosOrcatec
    .put<GlobalItem>(`/setting/price-pages/items/${item.id}`, item)
    .then(res => res.data)

const deleteItem = (data: { item_ids: number[] }) =>
  axiosOrcatec
    .delete(`/setting/price-pages/items/delete`, { data })
    .then(res => res.data)

const getItemUnits = () =>
  axiosOrcatec.get<ItemUnit[]>(`/setting/units`).then(res => res.data)

const createItemUnit = (unit: ItemUnit) =>
  axiosOrcatec.post<ItemUnit>(`/setting/units`, unit).then(res => res.data)

const deleteItemUnit = (unitId: number) =>
  axiosOrcatec.delete(`/setting/units/${unitId}`).then(res => res.data)

//Discounts && Rebates

const getDiscounts = (params?: GeneralTableParams) =>
  axiosOrcatec
    .get(`setting/price-pages/discounts`, { params })
    .then(res => res.data)

const createDiscount = (item: ProjectDiscount) =>
  axiosOrcatec
    .post<ProjectDiscount>(`/setting/price-pages/discounts`, item)
    .then(res => res.data)

const duplicateDiscount = (item: ProjectDiscount) =>
  axiosOrcatec
    .post<ProjectDiscount>(`/setting/price-pages/items/copy/${item.id}`)
    .then(res => res.data)

const updateDiscount = (item: ProjectDiscount) =>
  axiosOrcatec
    .put<ProjectDiscount>(`/setting/price-pages/discounts/${item.id}`, item)
    .then(res => res.data)

const deleteDiscount = (discount: ProjectDiscount) =>
  axiosOrcatec
    .delete(`/setting/price-pages/discounts/${discount.id}`)
    .then(res => res.data)

const getRebates = (params?: GeneralTableParams) =>
  axiosOrcatec
    .get(`/setting/price-pages/rebates`, { params })
    .then(res => res.data)

const createRebate = (item: ProjectRebate) =>
  axiosOrcatec
    .post<ProjectRebate>(`/setting/price-pages/rebates`, item)
    .then(res => res.data)

const duplicateRebate = (item: ProjectRebate) =>
  axiosOrcatec
    .post<ProjectRebate>(`/setting/price-pages/rebates/copy/${item.id}`)
    .then(res => res.data)

const updateRebate = (item: ProjectRebate) =>
  axiosOrcatec
    .put<ProjectRebate>(`/setting/price-pages/rebates/${item.id}`, item)
    .then(res => res.data)

const deleteRebate = (rebate: ProjectRebate) =>
  axiosOrcatec
    .delete(`/setting/price-pages/rebates/${rebate.id}`)
    .then(res => res.data)

const ItemsAPI = {
  getCategories,
  getCategoryById,
  createCategory,
  updateCategory,
  deleteCategory,
  getItemsListByCategory,
  getItemsList,
  exportGeneralItemsTable,
  importItemsTable,
  getImportStatus,
  getItemById,
  createItem,
  duplicateItem,
  updateItem,
  deleteItem,
  getItemUnits,
  createItemUnit,
  deleteItemUnit,
  getDiscounts,
  createDiscount,
  duplicateDiscount,
  updateDiscount,
  deleteDiscount,
  getRebates,
  createRebate,
  duplicateRebate,
  updateRebate,
  deleteRebate,
}

export default ItemsAPI
