import * as types from '../../types'
import moment from 'moment-timezone'
const initialState = {
  isOpen: false,
  edit: false,
  timeoff: {
    start_date: new Date(),
    end_date: new Date(),
    note: '',
    user_id: null,
    reason: '',
  },
}

export default (state = initialState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case types.modalTimeoff.TOGGLE_TIMEOFF_MODAL:
      newState.isOpen = !newState.isOpen
      newState.timeoff = {
        start_date: moment(action.time_start, 'hh').set('minutes', 0),
        end_date: moment(action.time_end === 24 ? 23 : action.time_end, 'hh').set(
          'minutes',
          action.time_end === 24 ? 59 : 0,
        ),
        note: '',
        user_id: null,
        reason: '',
      }
      newState.edit = false
      return newState
    case types.modalTimeoff.TOGGLE_EDIT_TIMEOFF_MODAL:
      newState.isOpen = !newState.isOpen
      newState.edit = true
      newState.timeoff = { ...action.item }
      return newState
    case types.modalTimeoff.CHANGE_TIMEOFF_FIELD:
      newState.timeoff[action.field] = action.value
      return newState
    default:
      return state
  }
}
