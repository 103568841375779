import SignatureBlock from 'containers/MainContent/Orcatec/Proposals/components/Form/SignatureBlock/SignatureBlock'
import ModalSignature from 'containers/MainContent/Orcatec/Proposals/components/PageProposalPreview/Signature/Signature'
import TermAndConditionsLink from 'containers/MainContent/Orcatec/components/TermAndConditionsLink/TermAndConditionsLink'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import {
  selectProject,
  selectProjectActiveTab,
} from 'features/Project/projectSelectors'
import { signProject } from 'features/Project/slices/projectSlice'
import { ProjectStatusGroup } from 'features/Project/types'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'

export const SignatureInfo = () => {
  const dispatch = useAppDispatch()

  const project = useAppSelector(selectProject)
  const currentTab = useAppSelector(selectProjectActiveTab)

  const [signatureModal, setSignatureModal] = useState(false)
  const [isSigning, setIsSigning] = useState(false)

  const contractInfo = {
    contract_date: project.contract_date,
    signature: project.signature || '',
    ip_client: project.ip_client || '',
    signature_date: project.signature_date || '',
    proposal_status_changed_by: project.proposal_status_changed_by || '',
    signatory_name: project.signatory_name,
  }

  const handleProjectSign = async (
    signature: string,
    signatoryName: string,
  ) => {
    setIsSigning(true)

    await dispatch(
      signProject({
        projectId: project.id,
        data: {
          signed_tab_id: currentTab.id,
          signatory_name: signatoryName,
          signature,
        },
      }),
    )
    setIsSigning(false)
    setSignatureModal(false)
  }

  return (
    <Wrapper>
      {!!project.signature && currentTab?.id === project.signed_tab_id && (
        <SignatureBlock
          contractInfo={contractInfo}
          projectStatus={project.status}
        />
      )}

      {!project.signature &&
        // require_signature &&
        (!project.signed_tab_id || currentTab?.id === project.signed_tab_id) &&
        [ProjectStatusGroup.Estimate, ProjectStatusGroup.Contract].includes(
          project.status,
        ) && (
          <MainButton
            title='Accept Project'
            onClick={() => setSignatureModal(prev => !prev)}
            style={{ margin: '0 auto' }}
            // disabled={this.props.isPreviewFromSettings}
          />
        )}

      {!!project?.term_and_conditions?.file0 && (
        <TermAndConditionsLink file={project.term_and_conditions?.file0} />
      )}

      {signatureModal && (
        <ModalSignature
          show
          close={() => setSignatureModal(prev => !prev)}
          onSubmit={handleProjectSign}
          termFile={project.term_and_conditions?.file0 || null}
          isProposalSigning={isSigning}
        />
      )}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  margin: 50px auto 10px;
`
