import { ProjectItemsGroup } from './components/ProjectItemsGroup/ProjectItemsGroup'
import { ProjectItemsTable } from './components/ProjectItemsTable/ProjectItemsTable'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { round } from 'helpers/Math'
import {
  selectMaterialsTotal,
  selectProjectGroupsBySection,
  selectProjectSettings,
} from '../projectSelectors'
import { priceToView } from 'helpers/thousandSeparator'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import { useEffect, useState } from 'react'
import { Button } from 'antd'
import {
  addItemsGroup,
  itemsGroupsReordered,
  selectGroupSlice,
} from '../slices/projectGroupsSlice'
import { IProjectItemsGroup } from '../types'
import ProjectItemsAPI, { ProjectGroupsAPI } from 'api/Project'
import {
  getItemUnitsList,
  reorderProjectItems,
} from '../slices/projectItemsSlice'
import { reorder } from 'helpers/reorder'

interface Props {
  sectionId: number
  disabled: boolean
  showPrice: boolean
}

export const ProjectItems = ({ sectionId, disabled, showPrice }: Props) => {
  const dispatch = useAppDispatch()

  const materialsSubtotal = useAppSelector(selectMaterialsTotal(sectionId))
  const groups = useAppSelector(selectProjectGroupsBySection(sectionId))
  const { status: groupStatus } = useAppSelector(selectGroupSlice)
  const projectSettings = useAppSelector(selectProjectSettings)

  const [newRowTriggered, setNewRorTriggered] = useState(false)

  useEffect(() => {
    dispatch(getItemUnitsList())
  }, [])

  const handleDragEnd = async (result: DropResult) => {
    const { destination, type, source } = result

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    if (type === 'droppableItem') {
      const { payload } = await dispatch(
        reorderProjectItems({ sectionId, result }),
      )

      if (payload?.length) ProjectItemsAPI.reorderItems(payload)
    } else if (type === 'droppableGroup') {
      const updatedGroups = reorder(
        groups,
        source.index,
        destination.index,
        'position',
      )

      dispatch(itemsGroupsReordered(updatedGroups))

      ProjectGroupsAPI.reorderGroups(updatedGroups)
    }
  }

  const handleGroupAdd = () => {
    dispatch(
      addItemsGroup({
        section_id: sectionId,
        name: 'Group',
        position: 0,
      } as IProjectItemsGroup),
    )
  }

  return (
    <Wrapper>
      <DragDropContext onDragEnd={handleDragEnd}>
        <DefaultGroup>
          <ProjectItemsTable
            groupId={null}
            sectionId={sectionId}
            disabled={disabled}
            onItemAdd={value => setNewRorTriggered(value)}
            isNewRowtriggered={newRowTriggered}
          />
        </DefaultGroup>

        <Droppable droppableId={`section-${sectionId}`} type='droppableGroup'>
          {provided => (
            <Groups {...provided.droppableProps} ref={provided.innerRef}>
              {groups?.map((group, index) => {
                return (
                  <Draggable
                    draggableId={`group-${group.id}`}
                    index={index}
                    key={group.id}
                    isDragDisabled={disabled}
                  >
                    {provided => (
                      <ProjectItemsGroup
                        key={group.id}
                        group={group}
                        provided={provided}
                        disabled={disabled}
                        sectionId={sectionId}
                        onGroupDeleted={() => setNewRorTriggered(false)}
                      >
                        <ProjectItemsTable
                          groupId={group.id}
                          sectionId={sectionId}
                          disabled={disabled}
                          onItemAdd={value => setNewRorTriggered(value)}
                          isNewRowtriggered={newRowTriggered}
                        />
                      </ProjectItemsGroup>
                    )}
                  </Draggable>
                )
              })}

              {provided.placeholder}
            </Groups>
          )}
        </Droppable>
      </DragDropContext>

      {!disabled && projectSettings?.show_groups && (
        <Button
          onClick={handleGroupAdd}
          style={{ marginBottom: 10 }}
          disabled={newRowTriggered || groupStatus === 'loading'}
        >
          + Add Group
        </Button>
      )}

      {!!showPrice && (
        <p
          style={{
            textAlign: 'end',
            display: 'grid',
            gridTemplateColumns: '1fr 120px',
            fontWeight: 500,
            fontSize: '1.15rem',
          }}
        >
          <span>Materials Subtotal:</span>{' '}
          {priceToView(round(materialsSubtotal))}
        </p>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Groups = styled.div``
const DefaultGroup = styled.div`
  margin-bottom: 10px;
  margin-left: 20px;
`
