import { useEffect, useState } from 'react'
import './TabSectionTab.scss'
import TableActionsNew from '../../../../../../../components/TableActionsNew'
// import { TextField } from '@material-ui/core'
import ToggleButton from '../../../../../../../components/buttons/ToggleButton/ToggleButton'
// import { thousandSeparator } from 'helpers/thousandSeparator'
import { priceToView } from '../../../../../../../../../../helpers/thousandSeparator'
import { DotsDargIcon } from '../../../../../../../components/Icons/CommonIcons'
import { Spin } from 'antd'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import {
  selectUserPermissionsByModule,
  selectIsPriceVisible,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  selectProject,
  selectProjectSettings,
} from 'features/Project/projectSelectors'
import {
  addProjectSection,
  deleteProjectSection,
  duplicateProjectSesction,
} from 'features/Project/slices/projectSectionsSlice'
import { TextField } from 'components/UIKit'
import { CheckOutlined } from '@ant-design/icons'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { ModalProposalSectionTemplateLoad } from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalProposalSectionTemplateLoad/ModalProposalSectionTemplateLoad'
import { ModalProposalSectionTemplate } from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalProposalSectionTemplate/ModalProposalSectionTemplate'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { postProposalTemplate } from 'api/Proposal'

// const inputProps = { maxLength: 50, autoFocus: true }

const TabSectionTab = ({
  title = '',
  isNewSection = false,
  // onDeleteSection,
  // onCopySection,
  // onAddSection,
  applySectionNewTitle,
  showSectionContent,
  toggleSectionContent,
  sectionSubtotal,
  sectionTotalAfter,
  dragHandleProps,
  isDraggable,
  freezeAll,
  isJustOneSection = false,
  isMyProposal,
  // toggleSaveTemplateSectionModal,
  // toggleLoadTemplateSectionModal,
  section_id,
  // onSubmit,
  sectionIdx,
}) => {
  const dispatch = useAppDispatch()

  const me = useAppSelector(selectAuthUser)
  const project = useAppSelector(selectProject)
  const priceIsVisible = useAppSelector(selectIsPriceVisible(project.user_id))
  const projectSettings = useAppSelector(selectProjectSettings)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )

  const [newTitle, setNewTitle] = useState(title)
  const [newTitleError, setNewTitleError] = useState('')
  const [editTitleMode, setEditTitleMode] = useState(false)
  const [showLoadTemplate, setShowLoadTemplate] = useState(false)
  const [showSaveTemplate, setShowSaveTemplate] = useState(false)

  useEffect(() => {
    if (!isNewSection && editTitleMode) {
      toggleEditMode()
    }
  }, [isNewSection])

  const toggleEditMode = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setNewTitle(!editTitleMode ? title : '')
    setNewTitleError('')
    setEditTitleMode(!editTitleMode)
  }

  const onChangeTitle = e => {
    const { value } = e.target
    setNewTitle(e.target.value)
    setNewTitleError(value ? '' : 'This field is required')
  }

  const applyNewTitle = () => {
    if (title !== newTitle) {
      if (newTitle) {
        applySectionNewTitle(newTitle)
        toggleEditMode()
      } else {
        return setNewTitleError('This field is required')
      }
    } else {
      toggleEditMode()
    }
  }

  const onBlur = () => {
    applyNewTitle()
  }

  const onCopyHeandler = () => {
    dispatch(duplicateProjectSesction(section_id))
  }

  const onDeleteHandle = () => {
    dispatch(deleteProjectSection({ id: section_id, sectionIdx }))
  }

  const tableActionProps = {
    todos: ['copyThin', 'edit', 'delete', 'save', 'upload'],
    callbacks: [
      onCopyHeandler,
      toggleEditMode,
      onDeleteHandle,
      // () => toggleSaveTemplateSectionModal(section_id),
      // () => toggleLoadTemplateSectionModal(section_id, sectionIdx),
      () => setShowSaveTemplate(true),
      () => setShowLoadTemplate(true),
    ],
    preloaders: [false, false, false, false, false],
    tooltips: [
      'Copy Section',
      'Edit section name',
      isJustOneSection ? 'Clear Section' : 'Delete Section',
      'Save as template',
      'Load section template',
    ],
    popConfirms: [
      '',
      '',
      isJustOneSection ? 'Are you sure you want to delete section?' : 'section',
    ],
    showDelete: isMyProposal,
    visible: [
      true,
      true,
      true,
      !!permissions[ProjectPermissions.PROJECT_CAN_CREATE_TEMPLATE],
      !!permissions[ProjectPermissions.PROJECT_CAN_READ_TEMPLATES] &&
        !freezeAll,
      // [ProjectStatus.Estimate, ProjectStatus.Canceled].includes(status),
    ],
  }

  const handleLoadSectionTemplate = async template_id => {
    await dispatch(
      addProjectSection({
        proposal_tab_id: project?.activeTabId,
        template_id,
        section_id,
        sectionIdx,
      }),
    )

    setShowLoadTemplate(false)
  }

  const saveProposalSectionTemplate = async (newTemplateName, sharedIds) => {
    const data = {
      user_id: me.id,
      owner_template_name: me.full_name,
      is_shared: sharedIds,
      type: 2, //2-proposal_section
      entity_id: section_id,
      title: newTemplateName,
    }
    await postProposalTemplate(data)

    setShowSaveTemplate(false)

    openNotificationWithIcon('success', { message: 'Section template saved!' })
  }

  return (
    <Spin spinning={isNewSection || !section_id} delay={0}>
      <div
        className='proposal-form-tabs_content-tab_section-wrapper-tab'
        onClick={() => toggleSectionContent(section_id, showSectionContent)}
      >
        <span
          className={
            'proposal-form-tabs_content-tab_section-wrapper-tab-drag_icon' +
            (isDraggable ? '' : ' d-none')
          }
          {...dragHandleProps}
        >
          <DotsDargIcon />
        </span>
        <div
          style={{
            display: 'flex',
            gap: 20,
            alignItems: 'center',
            width: '100%',
          }} /* className='d-flex flex-grow-1 flex-wrap align-items-center' */
        >
          {!freezeAll && (
            <TableActionsNew
              isNewSection={isNewSection}
              section_id={section_id}
              {...tableActionProps}
            />
          )}
          {!editTitleMode ? (
            <span className='proposal-form-tabs_content-tab_section-wrapper-tab-title'>
              {title}
            </span>
          ) : (
            <div
              className='proposal-form-tabs_content-tab_section-wrapper-tab-new_title'
              // onKeyUp={onKeyUp}
              onBlur={onBlur}
            >
              <TextField
                name='title'
                value={newTitle}
                onChange={onChangeTitle}
                autoFocus
                maxLength={100}
                onClick={e => e.stopPropagation()}
                error={newTitleError}
              />

              <CheckOutlined
                style={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
                onClick={e => {
                  e.stopPropagation()
                  onBlur()
                }}
              />
            </div>
          )}
          {priceIsVisible && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                marginRight: 20,
              }}
            >
              <div className='proposal-form-tabs_content-tab_section-wrapper-tab-total'>
                <p>Section Subtotal</p>
                <p>
                  {priceToView(sectionSubtotal)}
                  {/* ${thousandSeparator(sectionSubtotal)} */}
                </p>
              </div>
              <div className='proposal-form-tabs_content-tab_section-wrapper-tab-total'>
                <p>Section total after D&R</p>
                <p>
                  {priceToView(sectionTotalAfter)}
                  {/* ${thousandSeparator(sectionTotalAfter)} */}
                </p>
              </div>
            </div>
          )}
        </div>
        <ToggleButton
          show={showSectionContent}
          toggle={() => toggleSectionContent(section_id, showSectionContent)}
        />
      </div>

      {showSaveTemplate && (
        <ModalProposalSectionTemplate
          title={'Save section template'}
          modal
          onCancel={() => setShowSaveTemplate(false)}
          onSave={saveProposalSectionTemplate}
          me={me}
        />
      )}

      {showLoadTemplate && (
        <ModalProposalSectionTemplateLoad
          title={'Load section template'}
          modal
          onCancel={() => setShowLoadTemplate(false)}
          // setProposalTemplate={this.props.setProposalTemplate}
          // updateUserTemplate={this.props.updateUserTemplate}
          me={me}
          proposalSettings={projectSettings}
          // updateProposal={this.updateProposalCallback}
          proposalStatus={project.status ?? 1}
          sectionId={section_id}
          proposalForm={project}
          // initForm={this.initForm}
          activeTab={project.activeTab}
          // setSectionTemplate={this.props.setSectionTemplate}
          // onSubmit={this.onSubmit}
          onLoadSectionTemplate={handleLoadSectionTemplate}
          // onDeleteSection={this.props.onDeleteSection}
          sectionIdx={sectionIdx}
        />
      )}
    </Spin>
  )
}

export default TabSectionTab
