import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

export default function ModalPortal({ children }) {
  const modalRoot = document.querySelector('body')
  const element = document.createElement('div')

  useEffect(() => {
    modalRoot.appendChild(element)
    return () => modalRoot.removeChild(element)
  }, [element, modalRoot])

  const handleMouseDown = event => {
    event.stopPropagation()
  }

  return ReactDOM.createPortal(
    <div onMouseDown={handleMouseDown}>{children}</div>,
    element,
  )
}
