import { TextField, TextFieldProps } from '@material-ui/core'
import React, { useState } from 'react'
import { EditIcon } from '../../Icons/CommonIcons'
import { Label, Wrapper } from './InputField.styles'

export type InputFieldProps = Omit<TextFieldProps, 'error'> & {
  transform?: boolean
  disableEdit?: boolean
  value?: string
  error?: string
}

export const InputField = ({
  label,
  inputProps,
  fullWidth = true,
  transform,
  disableEdit,
  error,
  ...rest
}: InputFieldProps) => {
  const [isEditable, setIsEditable] = useState(!transform)

  const handleTextClick = () => {
    if (disableEdit) return

    setIsEditable(true)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!transform) return rest?.onBlur?.(e)

    setIsEditable(false)
  }

  return (
    <Wrapper>
      {!!label && <Label>{label}</Label>}
      {isEditable ? (
        <TextField
          {...rest}
          autoComplete='off'
          fullWidth={fullWidth}
          inputProps={{
            ...inputProps,
            autoComplete: 'off',
          }}
          onBlur={handleBlur}
          size='small'
          variant='outlined'
          autoFocus={transform && isEditable}
          helperText={error}
          error={!!error}
        />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <span>{rest?.value || '--'}</span>
          {!disableEdit && (
            <span
              onClick={handleTextClick}
              style={{ position: 'relative', top: -2 }}
            >
              <EditIcon size={12} />
            </span>
          )}
        </div>
      )}
    </Wrapper>
  )
}
