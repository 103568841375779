import { CustomerInformation } from 'containers/MainContent/Orcatec/Proposals/components/CustomerInformation/CustomerInformation'
import ErrorBoundary from 'containers/MainContent/Orcatec/components/ErrorBoundary/ErrorBoundary'
import {
  selectWorkOrder,
  updateWorkorderField,
} from 'features/Project/slices/projectWorkOrdersSlice'
import { Project } from 'features/Project/types'
import DocViewer from 'helpers/viewFIlesNew/ViewDoc'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'store/Orcatec/hooks'
import { Media } from 'types/Media'
import TabsLayout from '../TabsLayout'

interface State {
  documents: Media[]
  currentFileIdx: number
}
const initialState = { documents: [], currentFileIdx: 0 }

export const CustomerInfo = () => {
  const dispatch = useDispatch()
  const workOrder = useAppSelector(selectWorkOrder)

  const [state, setState] = useState<State>(initialState)
  const [docViewer, setDocViewer] = useState(false)

  const toggleOpenDocViewerModal = () => setDocViewer(!docViewer)

  const getFileForModalDocViewer = (
    documents: Media[],
    currentFileIdx: number,
  ) => {
    setState({ documents, currentFileIdx })
    toggleOpenDocViewerModal()
  }

  const handleCustomerInfoUpdate = (
    fieldsToUpdate: Record<keyof Project, unknown>,
  ) => dispatch(updateWorkorderField(fieldsToUpdate))

  return (
    <TabsLayout title="Customer's info">
      <ErrorBoundary>
        <CustomerInformation
          dataSource={workOrder}
          label='Information for customer'
          notesFieldName='info_for_customer'
          filesFieldName='files'
          getFileForModalDocViewer={getFileForModalDocViewer}
          onUpdate={handleCustomerInfoUpdate}
          filesLimit={100}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <CustomerInformation
          dataSource={workOrder}
          label='Information from customer'
          notesFieldName='info_from_customer'
          filesFieldName='files_from_customer'
          getFileForModalDocViewer={getFileForModalDocViewer}
          restrictUpload
          onUpdate={handleCustomerInfoUpdate}
          filesLimit={100}
        />
      </ErrorBoundary>

      {docViewer && (
        <DocViewer
          documents={state.documents}
          currentDocument={state.currentFileIdx}
          isModalVisible={true}
          handleCancel={toggleOpenDocViewerModal}
        />
      )}
    </TabsLayout>
  )
}
