
// SET_DOCUMENTS
export const SET_ALL_DOCUMENTS = 'SET_ALL_DOCUMENTS'

export const setDocument = (documents,activeIdx) => ({
  type: SET_ALL_DOCUMENTS,
  payload:{documents,activeIdx},
  
})


// SET_CURRENT_DOCUMENT
export const SET_CURRENT_DOCUMENT="SET_CURRENT_DOCUMENT"

export const setCurrentDocument=currentDocument=>({
  type:SET_CURRENT_DOCUMENT,
  currentDocument,
})

// SET_DOCUMENT_LOADING
export const SET_DOCUMENT_LOADING = 'SET_DOCUMENT_LOADING'

export const setDocumentLoading = value => ({
  type: SET_DOCUMENT_LOADING,
  value,
})


export const OPEN_SIDEBAR="OPEN_SIDEBAR"

export const openSideBarHeandle=()=>({
  type:OPEN_SIDEBAR,
})

// NEXT_DOCUMENT
export const NEXT_DOCUMENT = 'NEXT_DOCUMENT'

export const nextDocument = () => ({ type: NEXT_DOCUMENT })

// PREVIOUS_DOCUMENT
export const PREVIOUS_DOCUMENT = 'PREVIOUS_DOCUMENT'

export const previousDocument = () => ({
  type: PREVIOUS_DOCUMENT,
})

// UPDATE_CURRENT_DOCUMENT
export const UPDATE_CURRENT_DOCUMENT = 'UPDATE_CURRENT_DOCUMENT'

export const updateCurrentDocument = document => ({ type: UPDATE_CURRENT_DOCUMENT, document })

// SET_RENDERER_RECT
export const SET_RENDERER_RECT = 'SET_RENDERER_RECT'

export const setRendererRect = rect => ({
  type: SET_RENDERER_RECT,
  rect,
})

// SET_MAIN_CONFIG
export const SET_MAIN_CONFIG = 'SET_MAIN_CONFIG'

export const setMainConfig = config => ({
  type: SET_MAIN_CONFIG,
  config,
})


// SET_ACTIVE_IDX

export const SET_ACTIVE_IDX='SET_ACTIVE_IDX'


export const setActiveIdx = idx => ({
  type: SET_ACTIVE_IDX,
  idx,
})

export const CHANGE_DOC='CHANGE_DOC'


export const changeDoc = idx => ({
  type: CHANGE_DOC,
  idx,
})



export const ZOOM_IN='ZOOM_IN'


export const zoomIn = () => ({
  type: ZOOM_IN,
})

export const ZOOM_OUT='ZOOM_OUT'


export const zoomOut = () => ({
  type: ZOOM_OUT,
})


export const ROTATE_LEFT='ROTATE_LEFT'


export const rotateLeft = () => ({
  type: ROTATE_LEFT,
})


export const ROTATE_RIGHT='ROTATE_RIGHT'

export const rotateRight = () => ({
  type: ROTATE_RIGHT,
})



export const RESET_TRANSFORM ='RESET_TRANSFORM'

export const resetTransform = () => ({
  type: RESET_TRANSFORM,
})





// PDF------------
// SET_ZOOM_LEVEL
export const SET_ZOOM_LEVEL= "SET_ZOOM_LEVEL";

export const setZoomLevel = (value)=> ({
  type: SET_ZOOM_LEVEL,
  value,
});

// SET_PAGINATED
export const SET_PDF_PAGINATED= "SET_PDF_PAGINATED"

export const setPDFPaginated = (value)=> ({
  type: SET_PDF_PAGINATED,
  value,
})

// SET_NUM_PAGES
export const SET_NUM_PAGES = "SET_NUM_PAGES"

export const setNumPages = (value)=> ({
  type: SET_NUM_PAGES,
  value,
});

// SET_CURRENT_PAGE
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE"

export const setCurrentPage = (value)=> ({
  type: SET_CURRENT_PAGE,
  value,
})


export const SET_INITIAL_STATE = "SET_INITIAL_STATE"

export const setInitialState = ()=> ({
  type: SET_INITIAL_STATE,
  
})

export const SET_HEADER_TYPE = "SET_HEADER_TYPE"

export const setHeaderType = (headerType)=> ({
  type: SET_HEADER_TYPE,
  value:headerType,
  
})

export const SET_FILE_DATA= "SET_FILE_DATA"

export const setFileData= (fileData)=> ({
  type: SET_FILE_DATA,
  fileData,
  
})


export const SET_ERROR= "SET_ERROR"

export const setError= (isError,text)=> ({
  type: SET_ERROR,
  payload:{isError,text},
  
  
})







