import React, { useContext, useState } from 'react'
import { downloadByPablicLink } from 'api/Media'
import { downLoadFile } from 'helpers/downloadFile/downloadFile'
import { DocViewerContext } from '../state'
import { openFileInNewTab } from '../utils/openFileInTab'
import {
  openSideBarHeandle,
  changeDoc,
  zoomIn,
  zoomOut,
  rotateLeft,
  rotateRight,
  resetTransform,
} from '../state/actions'
import '../styles/layout.scss'
import classNames from 'classnames'
import { Tooltip } from 'antd'
import { ActionButton } from 'containers/MainContent/Orcatec/components/buttons/ActionButton/ActionButton'
import { checkUrlFormat } from '../utils/checkTypeFile'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

const Layout = ({ children, handleCancel }) => {
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(DocViewerContext)
  const {
    documents,
    currentDocument,
    isOpenSideBar,
    activeIdx,
    headerType,
    error,
  } = state

  const sideBarHeandler = () => {
    return dispatch(openSideBarHeandle())
  }

  const changeDocHeandle = idx => {
    return dispatch(changeDoc(idx))
  }

  const nextPageHeandle = () => {
    if (activeIdx + 1 < documents.length) {
      return dispatch(changeDoc(activeIdx + 1))
    }
  }

  const prevPageHeandle = () => {
    if (activeIdx !== 0) {
      return dispatch(changeDoc(activeIdx - 1))
    }
  }

  const rotateLeftHeandle = () => {
    return dispatch(rotateLeft())
  }

  const rotateRightHeandle = () => {
    return dispatch(rotateRight())
  }

  const zoomInHeandle = () => {
    return dispatch(zoomIn())
  }

  const zoomOutHeandle = () => {
    return dispatch(zoomOut())
  }

  const resetTransformHeandle = () => {
    return dispatch(resetTransform())
  }

  const openInNewTabHeandler = () => {
    return openFileInNewTab(
      currentDocument?.source || currentDocument?.linkToFile || currentDocument,
    )
  }

  const downloadMediaHeandle = async () => {
    try {
      setLoading(true)
      const { file } = await downloadByPablicLink(currentDocument?.source)
      await downLoadFile(file, currentDocument?.display_name)
    } catch (error) {
      openNotificationWithIcon('error', { message: 'Something went wrong!' })
    } finally {
      setLoading(false)
    }
  }

  const visibleBtn = headerType === 'image' || headerType === 'pdf'

  return (
    <div className='modal-container__active'>
      <div className='modal-container__inner'>
        <div className='modal-header'>
          <div className='title-wrapper'>
            <ActionButton
              type='menu'
              tooltipTitle='Open'
              actionHeandler={sideBarHeandler}
              differentIcon={isOpenSideBar}
              btnClassName={'controls'}
            />

            {!!currentDocument?.display_name && (
              <Tooltip placement='bottom' title={currentDocument?.display_name}>
                <p className='file-title'>
                  {currentDocument?.display_name?.length > 30
                    ? currentDocument?.display_name.slice(0, 30) + '...'
                    : currentDocument?.display_name}
                </p>
              </Tooltip>
            )}
          </div>
          <div className='page-controls__wrapper'>
            <div className='page-controls__center'>
              <ActionButton
                type='prev'
                tooltipTitle='Prev file'
                actionHeandler={prevPageHeandle}
                disabled={activeIdx === 0}
                btnClassName={'controls'}
              />

              <div className='pageNum__wrapper'>
                <span> {activeIdx + 1} </span>
                <span> / </span>
                <span> {documents?.length} </span>
              </div>
              <ActionButton
                type='next'
                tooltipTitle='Next file'
                actionHeandler={nextPageHeandle}
                disabled={activeIdx === documents?.length}
                btnClassName={'controls'}
              />
            </div>
            <div className='page-controls__right'></div>
          </div>
          <div className='controls-wrapper'>
            <ActionButton
              type='rotateRight'
              isVisible={visibleBtn}
              tooltipTitle='Rotate right'
              actionHeandler={rotateRightHeandle}
              btnClassName={'controls'}
            />

            <ActionButton
              type='rotateLeft'
              isVisible={visibleBtn}
              tooltipTitle='Rotate left'
              actionHeandler={rotateLeftHeandle}
              btnClassName={'controls'}
            />

            <ActionButton
              type='restore'
              isVisible={visibleBtn}
              tooltipTitle='Reset options'
              actionHeandler={resetTransformHeandle}
              btnClassName={'controls'}
            />

            <ActionButton
              type='zoomOut'
              isVisible={visibleBtn}
              tooltipTitle='Zoom Out'
              actionHeandler={zoomOutHeandle}
              btnClassName={'controls'}
            />

            <ActionButton
              type='zoomIn'
              isVisible={visibleBtn}
              tooltipTitle='Zoom In'
              actionHeandler={zoomInHeandle}
              btnClassName={'controls'}
            />

            <ActionButton
              type='download'
              isVisible={!!currentDocument?.source && !!currentDocument?.id}
              tooltipTitle='Download'
              actionHeandler={downloadMediaHeandle}
              btnClassName={'controls'}
              loading={loading}
            />

            <ActionButton
              type='openTab'
              isVisible={!error?.hasError}
              tooltipTitle='Open in new tab'
              actionHeandler={openInNewTabHeandler}
              btnClassName={'controls'}
            />

            <ActionButton
              type='close'
              tooltipTitle='Close'
              actionHeandler={handleCancel}
              btnClassName={'controls'}
            />
          </div>
        </div>
        <div className='wrapper'>
          <div
            className={classNames(
              isOpenSideBar
                ? 'modal-sidebar__active'
                : 'modal-sidebar__noactive',
              'modal-sidebar',
            )}
          >
            <div className='modal-sidebar__inner'>
              {!!documents &&
                documents?.map((doc, idx) => (
                  <div
                    key={idx}
                    className={classNames(
                      activeIdx === idx ? 'doc__active' : null,
                      'sidebar-image_wrapper',
                    )}
                  >
                    <div
                      onClick={() => changeDocHeandle(idx)}
                      className='image-backgound'
                    >
                      <div />
                      {
                        checkUrlFormat(doc?.source || doc?.linkToFile || doc)
                          ?.icon
                      }
                    </div>

                    {!!doc?.created_at && (
                      <p className='image-created__by'>
                        Created at: {doc?.created_at}
                      </p>
                    )}
                    {!!doc?.created_by && !doc?.created_at && (
                      <p className='image-created__by'>
                        Created by: {doc?.created_by}
                      </p>
                    )}
                    {!!doc?.linkToFile && (
                      <Tooltip placement='right' title={doc?.linkToFile}>
                        <p className='image-created__by'>
                          Link to file:{' '}
                          {doc?.linkToFile?.length > 30
                            ? doc?.linkToFile.slice(0, 30) + '...'
                            : doc?.linkToFile}
                        </p>
                      </Tooltip>
                    )}
                    {!doc?.created_by && !doc?.linkToFile && !doc?.created_at && (
                      <Tooltip placement='right' title={doc}>
                        <p className='image-created__by'>
                          Link to file:{' '}
                          {doc?.length > 30 ? doc?.slice(0, 30) + '...' : doc}
                        </p>
                      </Tooltip>
                    )}

                    <p className='image-name'>{doc?.display_name}</p>
                  </div>
                ))}
            </div>
          </div>
          <div className='modal-body'>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Layout
