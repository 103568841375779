import React from 'react'
import ReactDOM from 'react-dom'
import { Modal } from 'react-bootstrap'
import { Button } from 'antd'
import MainButton from '../../components/buttons/MainButton'
import './SureModalRedirect.scss'

const cancelButtonStyle = { color: '#191919', border: '1px solid #D6D6D6', marginRight: '10px' }

const SureModalRedirect = (message, callback) => {
  const container = document.createElement('div')
  container.setAttribute('custom-confirmation-navigation', '')
  document.body.appendChild(container)

  const closeModal = callbackState => () => {
    ReactDOM.unmountComponentAtNode(container)
    callback(callbackState)
  }
  ReactDOM.render(
    <Modal
      show={true}
      backdrop='static'
      onHide={closeModal(false)}
      className='confirm-redirect-modal'
      centered={true}
      dialogClassName='w-75'
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='w-100 d-flex justify-content-center'>
          <div className='confirm-redirect-modal-title'>{message}</div>
        </div>
        <div className='d-flex justify-content-center buttons'>
          {/* <Button
          variant='default'
          onClick={closeModal(false)}
          className='main-button user-cancel-btn'
          style={cancelButtonStyle}
        >
          Cancel
        </Button> */}
          <MainButton
            title='Cancel'
            type='cancel'
            onClick={closeModal(false)}
            className='main-button user-cancel-btn'
          />
          <MainButton title='Confirm' onClick={closeModal(true)} className='main-button' />
        </div>
      </Modal.Body>
    </Modal>,
    container,
  )
}
export default SureModalRedirect
