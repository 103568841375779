import React, { FC, useEffect, useState } from 'react'
import { IItem } from 'features/Project/types'
import { selectProjectContactIds } from 'features/Project/slices/projectContactsSlice'
import { useAppSelector } from 'store/Orcatec/hooks'
import { TableWrapper } from './Table.style'

import { Empty } from 'antd'

import { ContactContainer } from './components/ContactContainer/ContactContainer'

interface IProps {
  handleDetachContact: (item: IItem) => void
  freezed: boolean
  search: string
  onOpenForm: (item: IItem) => void
  handleSelectItem: (checked: boolean, item: IItem) => void
  handleSelectParentItem: (checked: boolean, item: IItem) => void
  handleUpdateProjectRelation: ({
    item,
    proposal_relation,
  }: {
    item: IItem
    proposal_relation: string
  }) => void
}

export const Table: FC<IProps> = ({
  handleDetachContact,
  freezed,
  onOpenForm,
  handleSelectItem,
  handleUpdateProjectRelation,
  search,
  handleSelectParentItem,
}) => {
  const [searchIsEmpty, setSearchIsEmpty] = useState(true)
  const contactIds = useAppSelector(selectProjectContactIds)

  useEffect(() => {
    const modalBody = document.querySelectorAll('.tree-visible')

    setSearchIsEmpty(!!modalBody?.length)
  }, [search])

  if (!contactIds?.length)
    return (
      <Empty style={{ marginTop: '28%' }} description='Contact list is empty' />
    )

  return (
    <TableWrapper>
      {!searchIsEmpty && (
        <Empty
          style={{ marginTop: '28%' }}
          description='No search results found'
        />
      )}
      {contactIds.map((item, index) => (
        <ContactContainer
          key={index}
          contactId={item.id}
          contactType={item.entity_type}
          onOpenForm={onOpenForm}
          handleSelectItem={handleSelectItem}
          handleUpdateProjectRelation={handleUpdateProjectRelation}
          handleDetachContact={handleDetachContact}
          freezed={freezed}
          search={search}
          handleSelectParentItem={handleSelectParentItem}
        />
      ))}
    </TableWrapper>
  )
}
