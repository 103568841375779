import React from 'react'
import moment from 'moment'

const Footer = props => (
  <footer className='footer'>
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>2019 - {new Date().getFullYear()} © Orcatec</div>
      </div>
    </div>
  </footer>
)

export default Footer
