import { useEffect, useState } from 'react'
import { Tooltip, Checkbox, Badge, Popconfirm, Button } from 'antd'
import ToggleButton from 'containers/MainContent/Orcatec/components/buttons/ToggleButton/ToggleButton'
// import InstallersInfoBlock from '../InstallersInfoBlock/InstallersInfoBlock'
import moment from 'moment-timezone'

import './styles.scss'
import RelatedJobs from 'containers/MainContent/Orcatec/Jobs/components/RelatedJobs'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'store/Orcatec/hooks'
import { getRelateJobs, setRelatedJobs } from 'store/Orcatec/actions/jobs'
import { selectRelatedJobs } from 'store/Orcatec/reducers/jobs'
import { useActiveUsers } from 'hooks/useActiveUsers'
import SelectField from 'containers/MainContent/Orcatec/components/Select'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { selectProjectSlice } from 'features/Project/slices/projectSlice'
import { InfoForWorkers } from '../../../../InfoForWorkers/InfoForWorkers'
import {
  selectProjectTabSlice,
  updateProjectTabField,
} from 'features/Project/slices/projectTabsSlice'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { InfoForWorkersOperation } from '../../../../InfoForWorkers/types'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'

const CalendarWithIcon = ({ onClick, disabled, count, title }) => (
  <div className='calendar'>
    <Tooltip title={disabled && 'All work had been completed'}>
      <Badge
        count={count}
        size='small'
        color='#4c53ee'
        style={{ pointerEvents: 'none' }}
      >
        <Button title={`+ Add ${title}`} disabled={disabled} onClick={onClick}>
          + Add {title}
        </Button>
      </Badge>
    </Tooltip>
  </div>
)

const InformationForWokers = ({
  currentTab,
  // onAddTabTag,
  // proposalForm,
  // deleteTabFile,
  // onDeleteTabTag,
  toggleFileModal,
  // onChangeTextField,
  // onChangeTabDetails,
  openInstallationDate,
  onChangeAllWorkComplited,
  getFileForModalDocViewer,
  onChangeLeadInstaller,
  jobCreated,
  handleChangeWorkCompletionDate,
}) => {
  const { READ, UPDATE } = usePermissionsByModule(InfoForWorkersOperation)
  const dictionary = useAppSelector(selectJobDictionary)
  const dispatch = useDispatch()
  const { relatedJobs } = useAppSelector(selectRelatedJobs)
  const { project, status: projectStatus } = useAppSelector(selectProjectSlice)
  const { projectTab } = useAppSelector(selectProjectTabSlice)
  const { users } = useActiveUsers()

  const [showContent, setShowContent] = useState(false)

  useEffect(() => {
    dispatch(getRelateJobs({ proposal_id: project.id }))

    return () => {
      dispatch(setRelatedJobs([]))
    }
  }, [])

  useEffect(() => {
    if (jobCreated) setShowContent(true)
  }, [jobCreated])

  const installerFiles = Array.isArray(currentTab.files) ? currentTab.files : []

  const toggleShowContent = () => setShowContent(!showContent)

  const openFileModal = () => {
    if (!UPDATE) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    toggleFileModal?.('tab', installerFiles)
  }

  //   const onChangeDescription = (e, idx) => {
  //     const value = { idx, text: e.target.value }
  //     onChangeTabDetails(value, 'file_description')
  //   }
  //
  //   const deleteFile = (id, idx) => {
  //     return deleteTabFile(idx, id)
  //   }

  const handleInformationForWorkersUpdate = filesToUpdate => {
    dispatch(updateProjectTabField(filesToUpdate))
  }

  return (
    <div className='information_for_workers'>
      <p className='label'>Information For Workers</p>
      <div className='information_for_workers__heading'>
        <ToggleButton show={showContent} toggle={toggleShowContent} />

        {/* proposalForm.status !== 1 &&  */ project.status !== 5 && (
          <>
            <CalendarWithIcon
              installationDates={project.installation_dates}
              onClick={openInstallationDate}
              disabled={!!project.all_work_completed}
              count={relatedJobs?.length}
              title={dictionary.singular}
            />

            {!relatedJobs.length ? (
              <Checkbox
                checked={project.all_work_completed}
                onChange={e => {
                  if (projectStatus === 'loading') return
                  onChangeAllWorkComplited(e)
                }}
                disabled={project.status === 3 || project.status === 4}
              >
                Work completed
              </Checkbox>
            ) : (
              <Popconfirm
                title={`Do you want to complete all project ${dictionary.singular}?`}
                disabled={
                  !!project.all_work_completed || projectStatus === 'loading'
                }
                onConfirm={() =>
                  onChangeAllWorkComplited({ target: { checked: true } }, true)
                }
                onCancel={() =>
                  onChangeAllWorkComplited({ target: { checked: true } }, false)
                }
                okText='Yes'
                cancelText='No'
              >
                <Checkbox
                  checked={project.all_work_completed}
                  onChange={e => {
                    if (!!project.all_work_completed) {
                      onChangeAllWorkComplited(e)
                    }
                  }}
                  disabled={project.status === 3 || project.status === 4}
                >
                  Work completed
                </Checkbox>
              </Popconfirm>
            )}
          </>
        )}
        {!!project?.all_work_completed && (
          <KeyboardDatePicker
            autoOk
            value={
              !!project?.date_of_completion
                ? moment.utc(project.date_of_completion)
                : null
            }
            label='Date of Completion'
            inputVariant='outlined'
            format='MM/DD/YYYY'
            size='small'
            InputLabelProps={{
              shrink: true,
            }}
            disableToolbar
            style={{ maxWidth: '200px' }}
            variant='inline'
            onChange={date => {
              if (!date?._isValid) return

              handleChangeWorkCompletionDate(
                project.id,
                moment(date).format('MM/DD/YYYY'),
              )
            }}
            cancelLabel=''
            okLabel=''
            inputProps={{
              readOnly: true,
            }}
          />
        )}

        {READ && (
          <Tooltip title='Attached files' className='attached-files'>
            <Badge
              count={installerFiles?.length}
              size='small'
              color='#4c53ee'
              style={{ pointerEvents: 'none' }}
              offset={[5, 0]}
            >
              <i className='fas fa-paperclip' onClick={openFileModal} />
            </Badge>
          </Tooltip>
        )}

        <SelectField
          value={project?.lead_installer_id}
          options={[
            { id: null, name: 'No lead Worker' },
            ...users?.map(user => ({ id: user?.id, name: user?.full_name })),
          ]}
          style={{ width: 150 }}
          label='Lead Worker'
          onChange={(e, { props }) => {
            onChangeLeadInstaller(e.target.value, props?.children)
          }}
        />

        {READ && (
          <p className='notes_for_installers'>
            {currentTab.notes_for_installers}
          </p>
        )}
      </div>

      {showContent && (
        <div className='information_for_workers__content'>
          <RelatedJobs proposalId={project.id} />

          <InfoForWorkers
            dataSource={projectTab}
            label='Notes for workers'
            notesFieldName='notes_for_installers'
            filesFieldName='files'
            getFileForModalDocViewer={getFileForModalDocViewer}
            onUpdate={handleInformationForWorkersUpdate}
            filesLimit={100}
          />

          {/*  <InstallersInfoBlock
            fieldValue={
              currentTab.notes_for_installers
                ? currentTab.notes_for_installers
                : ''
            }
            onChangeTextField={onChangeTextField}
            onChangeDescription={onChangeDescription}
            deleteFile={deleteFile}
            files={installerFiles}
            openFileModal={openFileModal}
            placeholder='Notes for workers'
            getFileForModalDocViewer={getFileForModalDocViewer}
          /> */}
        </div>
      )}
    </div>
  )
}

export default InformationForWokers
