import ErrorBoundary from 'containers/MainContent/Orcatec/components/ErrorBoundary/ErrorBoundary'
import { FormsBlock } from 'features/Forms/components/FormsBlock/FormsBlock'
import {
  CustomFormEntityType,
  CustomFormsOperation,
} from 'features/Forms/types'
import {
  selectProject,
  selectProjectSettings,
} from 'features/Project/projectSelectors'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompany } from 'store/Orcatec/selectors/company'
import { Companies } from 'types/Company'
import {
  getAttachedTemplatesToProject,
  getRelatedFormToProject,
  createRelatedFormToProject,
  // getCountFormsProject,
  // attachRelatedFormsToEvent,
  getCountFormsProject,
  attachRelatedFormsToProject,
} from 'api/CustomForms'
import styled from 'styled-components'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'

export const ProjectForms = () => {
  const project = useAppSelector(selectProject)
  const projectSettings = useAppSelector(selectProjectSettings)
  const company = useAppSelector(selectCompany)

  const { READ } = usePermissionsByModule(CustomFormsOperation)

  if (!READ || !project.id || !projectSettings.show_forms) return null

  return (
    <Wrapper>
      <Label>Project forms</Label>
      <ErrorBoundary>
        <FormsBlock
          key={project?.id}
          entityId={project?.id}
          entityType={CustomFormEntityType.PROJECT}
          createRelatedForm={createRelatedFormToProject}
          getRelatedForm={getRelatedFormToProject}
          getAttachedTemplate={getAttachedTemplatesToProject}
          onAttachTemplates={attachRelatedFormsToProject}
          getCount={getCountFormsProject}
          id={project?.id}
          showAllowableTemplates={Companies.SafetyNey === company?.id}
          chooseAllowableTemplates={Companies.SafetyNey === company?.id}
          showTemplates
          hideTitle
          // module={ModuleName.PROJECT}
        />
      </ErrorBoundary>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  position: relative;
`
const Label = styled.p`
  position: absolute;
  background-color: #fff;
  color: #19191980;
  letter-spacing: 0.01em;
  padding: 0 5px;
  top: -13px;
  left: 15px;
`
