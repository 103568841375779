import React, { useState, useEffect } from 'react'

export const useSetInitialSummary = (property = [], clients = [], organizations = []) => {
  const [initialSummaryProperties, setInitialSummaryProperties] = useState(null)
  const [initialSummaryClients, setInitialSummaryClients] = useState(null)

  useEffect(() => {
    let lastPosition = Math.max(...(Array.isArray(property) ? property.map(el => el.position) : []))
    property?.length
      ? setInitialSummaryProperties(() => {
          const arr = property?.reduce((acc, curr) => {
            if (curr.position === null) {
              curr.position = lastPosition + 1
              lastPosition = lastPosition + 1
            }
            return acc.concat({
              id: curr.property_relation_id,
              relation: curr.proposal_relation,
              address: curr.address,
              city: curr.city,
              postcode: curr.postcode,
              fullAddress: curr.full_address,
              unit: curr.unit,
              position: curr.position || -1,
              is_primary: !!(curr.position === 1),
            })
          }, [])
          return arr.sort((a, b) => a.position - b.position)
        })
      : setInitialSummaryProperties(null)
  }, [property])

  useEffect(() => {
    const allClients = [
      ...[...(clients?.length ? clients : [])],
      ...[...(organizations?.length ? organizations : [])],
      //...[...(organizations?.length && organizations.contacts?.length ? organizations.contacts : [])]
    ]
    let lastPosition = Math.max(...allClients.map(el => el.position))

    allClients?.length
      ? setInitialSummaryClients(() => {
          const arr = allClients?.reduce((acc, curr) => {
            if (curr.position === null) {
              curr.position = lastPosition + 1
              lastPosition = lastPosition + 1
            }
            return acc.concat({
              name: curr.name,
              relation: curr.proposal_relation,
              emails: curr.emails ? curr.emails : null,
              phones: curr.phones ? curr.phones : null,
              id: curr.client_relation_id || curr.organization_relation_id,
              position: curr.position || -1,
              is_primary: !!(curr.position === 1),
            })
          }, [])
          return arr.sort((a, b) => a.position - b.position)
        })
      : setInitialSummaryClients(null)
  }, [clients, organizations])

  return {
    properties: initialSummaryProperties,
    clients: initialSummaryClients,
  }
}
