import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react'

import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import { uploadReplyAttachment } from '../../../../../../api/supportTickets'
import { MAX_FILE_SIZE, MAX_UPLOAD_FILES } from '../../../../../../helpers/supportTickets'
import simpleNotify from '../../../../../../helpers/notifications/simpleNotify'

import './style.scss'

const AttachmentUploader = ({ fromRight, onStartUpload, onUploaded, onError, onRemove }, ref) => {
  const [attachments, setAttachments] = useState([])
  const [uploadingProcess, setUploadingProcess] = useState(false)
  const [error, serError] = useState('')

  const inputFileRef = useRef(null)

  useImperativeHandle(ref, () => ({
    clear: () => {
      setAttachments([])
      serError('')
    },
  }))

  const handleOpenSelectFileDialog = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click()
    }
  }

  const handleSelectFile = e => {
    const { target } = e
    const files = Array.from(target.files)
    target.value = ''

    if (files.length) {
      if (attachments.length >= MAX_UPLOAD_FILES) {
        serError('Attachments cannot be more then 10 files')
        return false
      }

      const file = files[0]
      if (file.size > MAX_FILE_SIZE) {
        serError('This file exceeds 50mb size limit')
        return false
      }

      setUploadingProcess(true)
      serError('')

      if (onStartUpload) {
        onStartUpload()
      }

      uploadReplyAttachment(file)
        .then(response => {
          const newAttachments = attachments.slice()
          const newAttachment = {
            id: response.data.id,
            file_name: file.name,
          }
          newAttachments.push(newAttachment)
          setAttachments(newAttachments)

          if (onUploaded) {
            onUploaded(newAttachment)
          }

          simpleNotify('success', 'File uploaded successfully')
        })
        .catch(error => {
          serError('Whoops, something went wrong')

          if (onError) {
            onError(error.response)
          }
        })
        .finally(() => {
          setUploadingProcess(false)
        })
    }
  }

  const handleRemoveAttachment = key => () => {
    const newAttachments = attachments.slice()

    if (newAttachments[key]) {
      const removed = newAttachments.splice(key, 1)
      setAttachments(newAttachments)

      if (onRemove) {
        onRemove(removed[0])
      }
    }
  }

  return (
    <div className={`ticket-attachments__container ${!!fromRight && 'from-right'}`}>
      <div className='ticket-attachment__upload'>
        <button className='btn btn-custom-info' onClick={handleOpenSelectFileDialog}>
          {uploadingProcess ? (
            <span className='spinner-border text-primary ticket-upload-attachment__icon' role='status' />
          ) : (
            <span className='ticket-upload-attachment__icon'>
              <CloudUploadIcon />
            </span>
          )}
          <span>Upload File</span>
        </button>
        <p className='ticket-upload-attachment__error'>{error}</p>
        <input ref={inputFileRef} type='file' onChange={handleSelectFile} />
      </div>
      <div className='ticket__attachments'>
        {attachments.map((item, idx) => (
          <div key={idx} className='ticket-attachment__item'>
            <div className='ticket-attachment__name'>{item.file_name}</div>
            <div className='ticket-attachment__remove' onClick={handleRemoveAttachment(idx)}>
              <HighlightOffIcon fontSize='small' />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default forwardRef(AttachmentUploader)
