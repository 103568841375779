const tagTextColor = '#FFFFFF'
const tagReverseTextColor = '#191919'

const getTagColor = status => {
  switch (status) {
    case 0:
      return '#F7F7F7'
    case 1:
      return '#F7F7F7'
    case 2:
      return '#2EBDA3'
    case 3:
      return '#4C53EF'
    case 4:
      return '#E63655'
    case 5:
      return '#B7B7B7'
    default:
      return
  }
}
const getIconColor = status => {
  switch (status) {
    case 0:
      return '#2e5bff'
    case 1:
      return '#2e5bff'
    case 2:
      return '#000000'
    case 3:
      return '#30be89'
    case 4:
      return '#eb5757'
    case 5:
      return '#DADADA'
    default:
      return
  }
}

const getIconStyle = status => {
  const color = getIconColor(status)
  return {
    // marginRight: '5px',
    // fontSize: '18px',
    color,
  }
}

const getStatusStyle = status => {
  const tagColor = getTagColor(status)
  const textColor =
    status === 0 || status === 1 ? tagReverseTextColor : tagTextColor
  return {
    backgroundColor: tagColor,
    border: `1px solid ${tagColor}`,
    color: textColor,
    width: '100%',
  }
}

export const proposalStatuses = {
  statuses: {
    // 0: {
    //   name: 'Estimate',
    //   style: getStatusStyle(0),
    //   icon: 'mdi mdi-chart-donut',
    //   iconStyle: getIconStyle(0),
    // },
    1: {
      name: 'Estimate',
      style: getStatusStyle(1),
      icon: 'mdi mdi-chart-donut',
      iconStyle: getIconStyle(1),
    },
    2: {
      name: 'Contract',
      style: getStatusStyle(2),
      icon: 'mdi mdi-progress-clock',
      iconStyle: getIconStyle(2),
    },
    3: {
      name: 'Completed',
      style: getStatusStyle(3),
      icon: 'mdi mdi-check-circle',
      iconStyle: getIconStyle(3),
    },
    4: {
      name: 'Cancelled',
      style: getStatusStyle(4),
      icon: 'mdi mdi-cancel',
      iconStyle: getIconStyle(4),
    },
    5: {
      name: 'Cold',
      style: getStatusStyle(5),
      icon: 'mdi mdi-snowflake',
      iconStyle: getIconStyle(5),
    },
  },
  getIsDisabled: (chosenStatusID, optionValueID) => {
    if (+chosenStatusID === 1) return optionValueID === 3 || optionValueID === 4
    if (+chosenStatusID === 2) return optionValueID === 5
    if (+chosenStatusID === 3)
      return optionValueID === 1 || optionValueID === 4 || optionValueID === 5
    if (+chosenStatusID === 4) return optionValueID !== 2
    if (+chosenStatusID === 5) return optionValueID !== 2 && optionValueID !== 1
  },
}

/* export const getProposalStatusName = statusId => {
  switch (+statusId) {
    case 1:
      return 'Estimate'
    case 2:
      return 'Contract'
    case 3:
      return 'Completed'
    case 4:
      return 'Canceled'
    case 5:
      return 'Cold'
  }
} */
