import styled from 'styled-components'

const Wrapper = styled.div`
  & .notes-wrapper .notes-list-wrapper {
    border: none;
  }
  /* & .notes-input-row {
    position: absolute;
    bottom: -40px;
    width: 100%;
  } */
`
const Container = styled.div`
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  margin-bottom: 10px;
  padding-bottom: 32px;
  & .deleted-text,
  .updated-text,
  .created-text {
    line-height: 1;
    i {
      font-size: 10px;
      color: #000000d9;
      opacity: 0.5;
    }
    .deleted-text__tooltip {
      cursor: pointer;
    }
  }
`
const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  & .notes-wrapper {
    width: 100%;
  }
  & .appointment-notes {
    width: 100%;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-self: end;
  margin-left: auto;
  min-height: 34px;
  & svg {
    cursor: pointer;
  }
`
const Title = styled.p`
  font-size: 1rem;
  font-weight: bold;
  margin: 15px 0;
`

const TooltipRow = styled.div`
  & .created-text {
    line-height: 0.8;
  }
  & i {
    font-size: 10px;
    color: #000000d9;
    opacity: 0.5;
  }
`

export { ButtonWrapper, Row, Container, Wrapper, Title, TooltipRow }
