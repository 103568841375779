// import ProjectItemsAPI from 'api/Project'
// import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useEffect, useState } from 'react'
import useDebounce from 'hooks/useDebounce'
import { ProjectDiscount } from 'features/Project/types'
import ProjectItemsAPI from 'api/Project'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

export const useItemsSearch = (
  params: {
    search?: string
  },
  isRebates?: boolean,
) => {
  const [data, setData] = useState<ProjectDiscount[]>([])
  const [loading, setLoading] = useState(false)

  const debouncedSearchValue = useDebounce(params.search, 300)

  useEffect(() => {
    const getData = async () => {
      setLoading(true)

      try {
        const { data } = isRebates
          ? await ProjectItemsAPI.searchRebates(params)
          : await ProjectItemsAPI.searchDiscounts(params)
        setData(data)
      } catch (error) {
        openNotificationWithIcon('error', {
          message: error.response?.data?.message,
        })
      } finally {
        setLoading(false)
      }
    }

    getData()
  }, [debouncedSearchValue])

  return {
    data,
    loading,
  }
}
