import { Tooltip } from 'antd'
import moment from 'moment-timezone'
import { useEffect, useRef, useState, FC } from 'react'

import { useAppSelector } from 'store/Orcatec/hooks'

import { EventKind, IAppointment } from 'types/Appointment'
import states from 'helpers/states'
import { cutString, getAmericanTime, getEventTimeStatus } from './utils'
import EventPopover from '../calendarV2/eventPopover/EventPopover'
import { EventWrapper } from './Event.style'
import { Job } from 'types/Jobs'

import { useDispatch } from 'react-redux'
import { setCurrentAppointmentId } from 'store/Orcatec/actions/appointment'

import { selectDispatchSettings } from 'features/Dispatch/dispatchSlice'
import {
  DispatchEvent,
  EventColorType,
  IEventContact,
} from 'features/Dispatch/types'
import { ToDoEvent } from 'features/ToDo/types'
import {
  ModuleName,
  SchedulePermissions,
} from 'features/Settings/UsersAndGroups'

import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'

interface EventContentProps {
  data: DispatchEvent
  isDragging: boolean
  onDublicate: (data: DispatchEvent) => void
  onCreateProposal: (data: DispatchEvent) => void
  handleDeleteAppointment: (data: DispatchEvent) => void
  tooltipLoading: boolean
  eventType: string
  heandleChangeStatusAppointment: (data: DispatchEvent) => void
  handleOpenContactModal?: (contact: IEventContact | null) => void
}

const EventContent: FC<EventContentProps> = ({
  data,
  onDublicate,
  onCreateProposal,
  // isDragging = false,
  handleDeleteAppointment,
  handleClickRfRModal,
  eventType,
  onSetEventData,
  onOpenAppointment,
  tooltipLoading,
  heandleChangeStatusAppointment,
  handleOpenContactModal,
  isSchedule,
  handleOpenPublicEventModal,
}) => {
  const [visible, changeVisible] = useState({ id: null, visible: false })
  const { matrix_address_type: matrixAddressType } = useAppSelector(
    selectDispatchSettings,
  )

  // const matrixAddressType: number = useAppSelector(
  //   state => state.orcatec.company.appointment_setting.matrix_address_type,
  // )
  const company = useAppSelector(state => state.orcatec.company)

  const isJob = (data: IAppointment | Job): data is Job =>
    !!(data as Job)?.proposal_id

  const onVisibleChange = visible => {
    changeVisible({ visible, id: data?.id })
  }

  // const isJob = data.kind === AppointmentKind.JOB
  // const isJob = (data: IAppointment | Job): data is Job => !!(data as Job)?.proposal_id
  return (
    <EventPopover
      onVisibleChange={onVisibleChange}
      visible={isSchedule ? false : visible}
      event={data}
      handleDeleteAppointment={handleDeleteAppointment}
      handleClickRfRModal={handleClickRfRModal}
      // getPropertyHeandle={getPropertyHeandle}
      eventType={eventType}
      onDublicate={onDublicate}
      onCreateProposal={onCreateProposal}
      onSetEventData={onSetEventData}
      onOpenAppointment={onOpenAppointment}
      isJob={isJob(data)}
      tooltipLoading={tooltipLoading}
      heandleChangeStatusAppointment={heandleChangeStatusAppointment}
      handleOpenContactModal={handleOpenContactModal}
      handleOpenPublicEventModal={handleOpenPublicEventModal}
    >
      <AccessControl
        module={ModuleName.SCHEDULE}
        scopes={[SchedulePermissions.SCHEDULE_CAN_READ_CLIENT_ADDRESS]}
        visible={!isSchedule}
      >
        <p className='zip'>
          {data.entity_type === 1 || data.kind === EventKind.JOB
            ? matrixAddressType === 3
              ? data?.city
                ? `${cutString(data.city, 10)}.., ` +
                  (states[data.state] || data.state)
                : ''
              : matrixAddressType === 2
              ? data?.city
                ? `${cutString(data.city, 12)}...`
                : ''
              : data?.postcode
              ? data.postcode
              : ''
            : 'Office'}
          {!!data.preferred_technician_id && <i className='fa fa-user' />}
        </p>
      </AccessControl>

      <AccessControl
        module={ModuleName.SCHEDULE}
        scopes={[SchedulePermissions.SCHEDULE_CAN_READ_CLIENT_NAME]}
        visible={!isSchedule}
      >
        {data.client_name && <p className='client-name'>{data.client_name}</p>}
      </AccessControl>

      <p className='event-type'>{data.appointment_type || '-'}</p>
      {data.date && <p className='event-date'>{data.date}</p>}

      {company.id === 264 ? (
        <div>{data?.relation}</div>
      ) : (
        (data.time_start || data.time_end) && (
          <p className='event-time'>
            {data.time_start === data.time_end
              ? getAmericanTime(data.time_start)
              : `${getAmericanTime(data.time_start)} - ${getAmericanTime(
                  data.time_end,
                )}`}
          </p>
        )
      )}
    </EventPopover>
  )
}

interface TodoEventProps {
  data: {
    due_date: string
    task_name: string
  }
}
const TodoEventContent: FC<TodoEventProps> = ({ data }) => (
  <div className={`todo-event ${getEventTimeStatus(data.due_date)}`}>
    <p className='task-name'>{data.task_name}</p>
    <p>{moment(data.due_date).format('MM/DD/YY, ha')}</p>
  </div>
)

interface EventProps {
  data: DispatchEvent | ToDoEvent
  index: number
  className?: string
  type: string
  onClick: (event: DispatchEvent | ToDoEvent) => void
  onDragStart: (e: React.MouseEvent, data: object, type: string) => void
  dragEventEnd: (e: React.MouseEvent, data: object, type: string) => void
  onDublicate: (event: DispatchEvent | Job) => void
  onCreateProposal: (event: DispatchEvent | Job) => void
  isJob: boolean
  handleClickRfRModal: () => void
  onSetEventData: (event: DispatchEvent | Job, eventType: string) => void
  onOpenAppointment: () => void
  handleDeleteAppointment: (event: DispatchEvent | Job) => void
  // getPropertyHeandle: (event: IAppointment | Job) => void
  visible: { visible: boolean; id: number }
  onVisibleChange: (visible: boolean) => void
  handleOpenContactModal?: (contact: IEventContact | null) => void
}
export const Event: FC<EventProps> = ({
  data,
  type,
  onClick,
  onDragStart,
  dragEventEnd,
  className,
  onDublicate,
  onCreateProposal,
  handleVisibleChange,
  handleDeleteAppointment,
  handleClickRfRModal,
  // getPropertyHeandle,
  eventType,
  onSetEventData,
  onOpenAppointment,
  tooltipLoading,
  heandleChangeStatusAppointment,
  handleOpenContactModal,
  isSchedule,
  handleOpenPublicEventModal,
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const currentAppointmentId = useAppSelector(
    state => state.orcatec.appointment.currentAppointmentId,
  )
  const { event_color_type } = useAppSelector(selectDispatchSettings)
  const colorByType = event_color_type === EventColorType['Event type']

  const eventRef = useRef() as React.MutableRefObject<HTMLDivElement>

  const dispatch = useDispatch()

  useEffect(() => {
    if (currentAppointmentId !== data?.id || !eventRef?.current) return

    eventRef?.current?.scrollIntoView(false)
  }, [])

  const handleHoverEvent = () => {
    if (currentAppointmentId === data?.id)
      dispatch(setCurrentAppointmentId(null))
  }

  const recurring = !!data.parent_id

  return (
    <EventWrapper
      backgroundColor={colorByType ? data?.background_color : null}
      isJob={data.kind === EventKind.JOB}
      isRecurring={recurring}
      className={`queue-event ${
        recurring && !colorByType ? getEventTimeStatus(data.date) : ''
      } ${className}`}
      onClick={() => onClick?.(data)}
      draggable={!!onDragStart}
      onDragStart={e => {
        setIsDragging(true)
        onDragStart(e, data, type)
      }}
      onDragEnd={e => {
        setIsDragging(false)
        dragEventEnd(e, data, type)
      }}
      ref={eventRef}
      onMouseOver={handleHoverEvent}
      status={data.status}
    >
      {/* <div className={getEventStatus(data)} style={{ width: '100%', height: '100%' }}> */}
      {type === 'todo_lists' ? (
        <TodoEventContent data={data} />
      ) : (
        <Tooltip
          className='tooltip'
          getPopupContainer={triggerNode =>
            triggerNode.parentNode?.parentNode?.parentNode?.parentNode
          }
          defaultVisible={true}
          title={`${currentAppointmentId === data.id ? 'Current' : ''}`}
        >
          <EventContent
            data={data}
            onDublicate={onDublicate}
            onCreateProposal={onCreateProposal}
            isDragging={isDragging}
            handleVisibleChange={handleVisibleChange}
            handleDeleteAppointment={handleDeleteAppointment}
            handleClickRfRModal={handleClickRfRModal}
            // getPropertyHeandle={getPropertyHeandle}
            eventType={eventType}
            onSetEventData={onSetEventData}
            onOpenAppointment={onOpenAppointment}
            tooltipLoading={tooltipLoading}
            heandleChangeStatusAppointment={heandleChangeStatusAppointment}
            handleOpenContactModal={handleOpenContactModal}
            isSchedule={isSchedule}
            handleOpenPublicEventModal={handleOpenPublicEventModal}
          />
        </Tooltip>
      )}
      {/* </div> */}
    </EventWrapper>
  )
}
