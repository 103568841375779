import React from 'react'
import { Form, Input, Button } from 'antd'
import { Companies } from 'types/Company'
import ReactHtmlParser from 'react-html-parser'

export const FeedbackForm = props => {
  const [form] = Form.useForm()

  const onFinish = values => {
    props.onSubmit(values.name, values.feedback)
  }

  return (
    <Form form={form} onFinish={onFinish} id='feedbackForm'>
      <div className='review__header'>
        {[Companies.ApplianceServiСenter].includes(
          props?.template?.company_id,
        ) ? (
          ReactHtmlParser(props?.template?.negative_rating_text)
        ) : (
          <h6>Please tell us about your experience with us</h6>
        )}
      </div>

      <Form.Item
        name='name'
        rules={[{ required: false, message: 'Please input your name' }]}
      >
        <Input type='text' placeholder='Your name' />
      </Form.Item>
      <Form.Item
        name='feedback'
        rules={[{ required: false, message: 'Please input your feedback' }]}
      >
        <Input.TextArea rows={5} placeholder='Leave your feedback' />
      </Form.Item>
      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          className='btn btn-outline-secondary send-feedback__btn'
        >
          Send
        </Button>
      </Form.Item>
    </Form>
  )
}
