import { useRef, useState, useEffect } from 'react'
import { FormRow } from 'features/Contacts/common/FormRow'
import styled from 'styled-components'
import { useAppSelector } from 'store/Orcatec/hooks'
import Row from 'containers/MainContent/Orcatec/Clients/components/Form/components/Row'
import PhoneRow from 'containers/MainContent/Orcatec/Clients/components/Form/components/PhoneRow'
import EmailRow from 'containers/MainContent/Orcatec/Clients/components/Form/components/EmailRow'
import { MenuItem, TextField } from '@material-ui/core'
import MembershipLabels from 'features/Membership/components/MembershipLabels'
import ClientsSuggestionsTable from 'containers/MainContent/Orcatec/Clients/components/Form/components/ClientsSuggestionsTable/ClientsSuggestionsTable'

import useSearch from 'containers/MainContent/Orcatec/components/SearchHook'
import { useDispatch } from 'react-redux'
import validator from 'validator'
import { getClientById, searchClientsV2 } from 'api/Client'
import {
  AddIcon,
  EyeIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import Notes from 'containers/MainContent/Orcatec/components/Notes'
import InputField from 'containers/MainContent/Orcatec/components/UI/InputField'
import {
  createContactFeedback,
  updateContactFeedback,
  deleteContactFeedback,
  attachContactToOrganization,
  attachOrganizationToContact,
} from 'api/Contacts/Contacts'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import FeedbackRating from 'containers/MainContent/Orcatec/Clients/components/Rating'
import FeedbackModal from '../../../../../../containers/MainContent/Orcatec/Clients/components/Feedback/index'
import { getCallDataByClient } from 'api/Calls'
import {
  addCalls,
  selectCurrentContact,
  getContact,
  createContact,
  updateContact,
  updatingContact,
  deleteContact,
  addMessagings,
  addContactToOrganization,
  addOrganizationToContact,
} from 'features/Contacts/slice/contactSlice'
import { messagingAPI } from 'api/MessagingAPI'

import { parsePhoneNumber } from 'features/Messaging/utils'
import { useQueryClient } from '@tanstack/react-query'
import { onChangeFieldAction } from 'features/Appointment/appointmentSlice'
import { Popconfirm } from 'antd'
import {
  selectModulePermissions,
  selectUserPermissionsByName,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ModuleName,
  ContactsPermissions,
} from 'features/Settings/UsersAndGroups'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

const InnerRowPhone = styled.div`
  margin-left: -31px;
  & .phone__main-group {
    display: flex;
    align-items: baseline;
    gap: 10px;
  }
  & .phoneWrapper {
    display: flex;
    align-items: center;
  }
  & .MuiFormControlLabel-label {
    font-size: 12px;
  }
  & .btn.delete {
    padding-left: 0;
    outline: 0;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  & label {
    margin-bottom: 0;
  }
  & .radio-group {
    padding-left: 10px;
  }
`
const InnerRowEmail = styled.div`
  margin-left: -31px;
  & .emailWrapper {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
  & .MuiFormControlLabel-label {
    font-size: 12px;
  }
  & .email-preference-wrapper {
    flex: 1 0 120px;
    padding-left: 10px;
    align-items: center;
  }
  & .btn.delete {
    padding-left: 0;
    outline: 0;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  & .formForEmailControls {
    margin-top: 0;
  }
  & label {
    margin-bottom: 0;
  }
`

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  margin: 10px 0;
`
const Wrapper = styled.div`
  & .color_gray {
    padding-left: 0;
    color: #707683;
    font-size: 14px;
    opacity: 1;
    line-height: 1.7;
  }
  .fullnameInput {
    width: 100%;
  }
`

const initialPhone = {
  value: {
    id: 0,
    phone: '',
    type: 'cell',
    preference: '',
  },
  error: '',
}
const initialEmail = {
  value: {
    id: 0,
    email: '',
    preference: false,
    send_promo: false,
    send_reminder: false,
  },
}
const initialFormValues = {
  relation: { value: '', error: '' },
  relations_with_property: [],
  name: { value: '', error: '' },
  website: { value: '', error: '' },
  industry: { value: '', error: '' },
  id: { value: '', error: '' },
  type: { value: 2 }, // value = 1 - organization | 2- individual  | 3 - contact
  phones: [initialPhone],
  emails: [initialEmail],
  note: {
    value: '',
    error: '',
  },
  tags: [],
  feedback_count: { value: 0, error: '' },
  vote: { value: 0, error: '' },
  client_properties: [],

  new_client_properties: [], // new objects
  deleted_client_properties: [], //id's
  client_associations: [],
  deleted_association_ids: [],
  new_client_associations: [], // new objects
  deleted_client_associations: [], //id's
  detached_organizations_ids: [],
  detached_contacts_ids: [],
  feedbacks: [],
  contacts: [],
  organizations: [],
  proposals: [],

  deleted_phone_ids: [],
  edited_phone_ids: [],
  deleted_email_ids: [],
  edited_email_ids: [],
}
export const ContactForm = ({
  contact,
  parentContact,
  openFromParent,
  isOrganization = false,
  isUpdateClient = false,
  organizationId = null,
  allreadyAttachedClients = [],
  onCancel,
}) => {
  const clientInputRow = useRef()
  const clientInputPhone = useRef()
  const clientInputEmail = useRef()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const userCanDelete = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.CONTACTS,
      ContactsPermissions.CONTACTS_CAN_DELETE,
    ),
  )
  const currentContact = useAppSelector(selectCurrentContact)
  const currentCompanyOwnerId = useAppSelector(
    state => state.orcatec.company.owner_id,
  )
  const tech = useAppSelector(state => state.orcatec.company.technicians)
  const {
    messaging: isMessagingPermission,

    call_tracking: isCallPermissions,
  } = useAppSelector(selectModulePermissions())

  const TYPE = parentContact?.type === 1 ? 2 : 1

  const [clientFormValues, setClientFormValues] = useState({
    ...initialFormValues,
    type: { value: parentContact ? TYPE : contact?.type ? contact.type : 2 },
    phones: [
      {
        value: {
          id: 0,
          phone: contact?.from_number
            ? parsePhoneNumber(contact?.from_number).slice(2)
            : '',
          type: 'cell',
          preference: 'voice',
        },
        error: '',
      },
    ],
  })

  const [clientFeedbacks, setClientFeedbacks] = useState([])
  const [feedbackModal, toggleFeedbackModal] = useState(false)
  // eslint-disable-next-line no-unused-vars

  const [showSuggestions, setShowSuggestions] = useState(false)
  // eslint-disable-next-line no-unused-vars

  const [searchField, setSearchField] = useState('')
  //const [searchBy, setSearchBy] = useState('')
  const [isPhoneValid, setIsPhoneValid] = useState(true)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [preloader, setPreloader] = useState(false)

  const [nameSearchValue, setNameSearchValue] = useState('')
  const [phoneSearchValue, setPhoneSearchValue] = useState('')
  const [emailSearchValue, setEmailSearchValue] = useState('')
  const [resultSearchValue, setResultSearchValue] = useState({
    field: '',
    value: '',
  })

  const requestFactory = () => {
    const searchType = {
      type: parentContact ? TYPE : clientFormValues.type.value === 1 ? 1 : 2,
    }
    return searchClientsV2({
      [resultSearchValue.field]: resultSearchValue.value,
      ...searchType,
    })
  }

  let findedClients = useSearch(
    resultSearchValue.value,
    requestFactory,
    200,
    'address',
    1,
    10,
  )

  if (!!findedClients && !Array.isArray(findedClients))
    findedClients = Object.values(findedClients)
  if (
    Array.isArray(findedClients) &&
    (organizationId || clientFormValues.id.value)
  ) {
    const id = clientFormValues.id.value
      ? clientFormValues.id.value
      : organizationId
    findedClients = findedClients?.filter(item => item.id !== id)
  }
  if (
    Array.isArray(findedClients) &&
    Array.isArray(allreadyAttachedClients) &&
    allreadyAttachedClients.length
  ) {
    findedClients = findedClients?.filter(
      client =>
        !allreadyAttachedClients.find(
          attachedClient => client.id === attachedClient.id,
        ),
    )
  }
  const checkIsValid = () => {
    const obj = JSON.parse(JSON.stringify(clientFormValues))
    const isEmails = obj.emails?.filter(item => item.value?.email?.length)
      .length
    let isValid = true
    if (
      obj.phones?.filter(item => item.error === true).length ||
      !isPhoneValid
    ) {
      isValid = false
    } /* else {
      const phones = obj.phones.filter(item => !!item.value.phone)
      if (phones.length > 0 && !!obj.phones[0]) {
        obj.phones[0].error = 'Please input phone number'
        isValid = false
      }
    } */
    if (
      obj.emails?.filter(item => item.error === true).length ||
      (isEmails && !isEmailValid)
    ) {
      isValid = false
    }

    // if (!obj.type.value && !organizationId && !isOrganization && !isAssociation && !isContact && !isClient) {
    //   obj.type.error = 'Please select type'
    //   isValid = false
    // }
    if (!obj.name.value) {
      obj.name.error = 'Please input user name'
      isValid = false
    }

    setClientFormValues(prev => (isValid ? prev : obj))
    return isValid
  }
  const prepareValuesToServer = formatedClient => {
    const state = formatedClient || clientFormValues
    const data = Object.keys(state).reduce((acc, curr) => {
      if (Array.isArray(state[curr])) {
        acc[curr] = state[curr].map(item => item?.value)
        return acc
      } else {
        if (state[curr]?.value) {
          acc[curr] = state[curr]?.value
        }
        return acc
      }
    }, {})

    if (!data.owner_user_id) data.owner_user_id = currentCompanyOwnerId

    // if (organizationId) {
    //   data.type = 3
    // }
    // if (isOrganization) {
    //   data.type = 1
    // }
    // if (isAssociation || isClient) {
    //   data.type = 2
    // }
    // if (isContact) {
    //   data.type = 3
    // }

    data.type = state.type.value

    if (Array.isArray(data.phones)) {
      data.phones = data.phones.reduce((acc, item) => {
        if (item.phone) {
          if (!item.preference) item.preference = 'voice'
          if (!item.entity_type) item.entity_type = data.type === 1 ? 1 : 2
          acc.push(item)
        } else if (item.id) {
          data.deleted_phone_ids.push(item.id)
        }
        return acc
      }, [])
    } else {
      data.phones = []
    }

    data.contact_role = data.relation
    if (Array.isArray(data.emails)) {
      data.emails = data.emails.reduce((acc, item) => {
        if (item.email) {
          if (!item.entity_type) item.entity_type = data.type === 1 ? 1 : 2
          acc.push(item)
        } else if (item.id) {
          data.deleted_email_ids.push(item.id)
        }
        return acc
      }, [])
      if (
        !!data.emails?.length &&
        data.emails?.every(email => !email.preference)
      ) {
        data.emails[0].preference = true
      }
    } else {
      data.emails = []
    }

    if (
      !!data.relations_with_property?.length &&
      typeof data.relations_with_property[0] === 'object' &&
      !Object.keys(data.relations_with_property[0])?.length
    )
      data.relations_with_property = []
    if (!!organizationId && !data.organization_id)
      data.organization_id = organizationId

    data.type = state.type.value

    data.is_individual = data.type === 2 || data.type === 3

    //if (!data.relations_with_property.length) delete data.relations_with_property
    return data
  }

  const fotmatingContact = res => {
    if (!res) return initialFormValues
    return Object.keys(res).reduce((acc, curr) => {
      if (Array.isArray(res[curr])) {
        if (res[curr].length) {
          acc[curr] = res[curr].map(item => ({
            value: item,
            error: '',
          }))
        } else {
          if (curr === 'phones') {
            acc[curr] = [
              {
                value: {
                  id: 0,
                  phone: '',
                  type: 'cell',
                  preference: '',
                },
                error: '',
              },
            ]
          } else if (curr === 'emails') {
            acc[curr] = [
              {
                value: {
                  id: 0,
                  email: '',
                  preference: false,
                  send_promo: false,
                  send_reminder: false,
                },
                error: '',
              },
            ]
          } else acc[curr] = []
        }
      } else {
        acc[curr] = { value: res[curr], error: '' }
      }
      return acc
    }, {})
  }

  const getClient = async (id, type) => {
    setPreloader(true)
    try {
      const { payload } = await dispatch(getContact({ id, type }))
      if (isCallPermissions) {
        const callsData = await getCallDataByClient(id)
        dispatch(addCalls(callsData))
      }
      if (isMessagingPermission) {
        const { chats } = await messagingAPI.getRelatedChats(type === 1, id)
        dispatch(addMessagings(chats))
      }

      const formatedClient = fotmatingContact(payload)
      setClientFeedbacks(payload.feedbacks)
      setClientFormValues(prev => ({ ...prev, ...formatedClient }))
    } catch (error) {
      console.error(error)
    } finally {
      setPreloader(false)
    }
  }

  const onChange = (e, i) => {
    let { name, value } = e.currentTarget
    const param = { ...clientFormValues }
    let isValid = true
    if (name === 'emails') {
      //setShowSuggestions(true)
      const isEmail = validator.isEmail(value)
      const isAlreadyExist =
        param[name].find(
          (item, idx) =>
            item.value.email === e.currentTarget.value && i !== idx,
        ) && value
      isValid = (isEmail && !isAlreadyExist) || !value
      if (!isEmail) param[name][i].error = 'Please input valid email address'
      if (isAlreadyExist) {
        param[name][i].error = 'That email is already exist - input another one'
      }
      const isEmailSecond = validator.isEmail(value) && !isAlreadyExist
      if (e.currentTarget.value.length !== 0) setIsEmailValid(isEmailSecond)

      /* if (i === 0)  */ setEmailSearchValue(value)
      if (param[name][i].value.id) {
        if (value) {
          param.deleted_email_ids = param.deleted_email_ids?.filter(
            item => item.value !== param[name][i].value.id,
          )
          const isExistInEdited =
            param.edited_phone_ids.findIndex(
              item => item.value === param[name][i].value.id,
            ) >= 0
          if (!isExistInEdited)
            param.edited_email_ids.push({ value: param[name][i].value.id })
        } else {
          param.edited_email_ids = param.edited_email_ids?.filter(
            item => item.value !== param[name][i].value.id,
          )
          const isExistInDeleted =
            param.deleted_email_ids.findIndex(
              item => item.value === param[name][i].value.id,
            ) >= 0
          if (!isExistInDeleted)
            param.deleted_email_ids.push({ value: param[name][i].value.id })
        }
      }
      //setSearchBy('email')
      //setSearchValue(e.currentTarget.value)
    }

    /* if (clientId) {
      if (name === 'emails') {
        checkEditingOfItem(name, i, param, isValid)
      }
    } */

    if (name === 'name') {
      value = value
        .trimStart()
        .replace(/\s/gis, ' ')
        .replace(/[!!@#$%^*(){}[\]"|,`/\\=?+<>]/gi, '')
      // .replace(/[^a-zA-Z'\s]/gi, '')
      isValid = !!value && isNaN(+value)
      param[name].error = isValid
        ? ''
        : !isNaN(+value)
        ? 'Please input valid user name'
        : 'Please input user name'

      setNameSearchValue(value)
      /* setSearchBy('name')
      setSearchValue(value) */
    }

    if (name === 'phones') {
      //setShowSuggestions(true)
      value = e.target.value
      if (value === '(XXX) XXX-XXXX') value = ''
      const x = value.replace(/\D/g, '')
      const isFullPhone = !x || x.length === 10
      const isAlreadyExist = isFullPhone
        ? !!param[name].find(
            (item, idx) => item.value.phone === value && i !== idx,
          ) && !!value
        : false

      param[name][i].error = isFullPhone
        ? !value
          ? 'Please input phone number'
          : false
        : 'Please input valid phone number'
      isValid = (isFullPhone && !isAlreadyExist) || !x
      if (isAlreadyExist)
        param[name][i].error =
          'That phone already exist. Please input another one'
      /* if (i === 0) { */
      setPhoneSearchValue(
        value
          .replace(/X/g, '')
          .replace(/-$/, '')
          .replace(/\s+$/, '')
          .replace(/\)$/, ''),
      )
      /*  } */
      setIsPhoneValid(isValid)
      if (param[name][i].value.id) {
        if (x) {
          param.deleted_phone_ids = param.deleted_phone_ids?.filter(
            item => item.value !== param[name][i].value.id,
          )
          const isExistInEdited =
            param.edited_phone_ids?.findIndex(
              item => item.value === param[name][i].value.id,
            ) >= 0
          if (!isExistInEdited)
            param.edited_phone_ids?.push({ value: param[name][i].value.id })
        } else {
          param.edited_phone_ids = param.edited_phone_ids?.filter(
            item => item.value !== param[name][i].value.id,
          )
          const isExistInDeleted =
            param.deleted_phone_ids.findIndex(
              item => item.value === param[name][i].value.id,
            ) >= 0
          if (!isExistInDeleted)
            param.deleted_phone_ids.push({ value: param[name][i].value.id })
        }
      }
    }

    const nameOfValue = name.replace(/.$/, '')
    if (typeof i === 'undefined') {
      param[name].value = value
      if (name === 'relation') {
        param.property_relation = value
      }
    } else {
      param[name][i].value[nameOfValue] = value
    }
    if (name !== 'notes') {
      if (e.currentTarget.value.length > 500) isValid = false
      if (!isValid) {
        console.warn('invalid')
      } else {
        e.currentTarget.classList.remove('has_error')
        e.currentTarget.classList.remove('av-invalid')
        e.currentTarget.classList.remove('is-invalid')
        e.currentTarget.classList.remove('is-pristine')

        e.currentTarget.classList.remove('is-touched')
        typeof i === 'undefined'
          ? (param[name].error = false)
          : (param[name][i].error = false)
      }
    }

    setSearchField(prev =>
      (name === 'name' || name === 'emails' || name === 'phones') && !!value
        ? name
        : prev,
    )

    setClientFormValues(param)
  }

  const addRow = type => {
    const param = { ...clientFormValues }
    let value
    if (type === 'phones') {
      value = {
        value: {
          id: 0,
          phone: '',
          type: 'cell',
          preference: '',
        },
        error: '',
      }
    }
    if (type === 'emails') {
      value = {
        value: {
          id: 0,
          email: '',
          preference: false,
          send_promo: false,
          send_reminder: false,
        },
        error: '',
      }
    }
    param[type].push(value)

    setClientFormValues(param)
  }
  const removeRow = (type, i) => {
    const param = { ...clientFormValues }

    if (param[type][i].value.id) {
      param[`edited_${type.replace(/s$/, '')}_ids`] = param[
        `edited_${type.replace(/s$/, '')}_ids`
      ].filter(item => item.value !== param[type][i].value.id)
      const isExist =
        param[`deleted_${type.replace(/s$/, '')}_ids`].findIndex(
          item => item.value === param[type][i].value.id,
        ) >= 0
      if (!isExist)
        param[`deleted_${type.replace(/s$/, '')}_ids`].push({
          value: param[type][i].value.id,
        })
    }
    if (param[type].length === 1) {
      // param[type][i].value[type.replace(/s$/, '')] = ''
      param[type][i] = type === 'phones' ? initialPhone : initialEmail
    } else {
      param[type].splice(i, 1)
    }

    setClientFormValues(param)
  }

  const onChangeSelect = (value, i) => {
    const param = { ...clientFormValues }
    param.phones[i].value.type = value
    //checkEditingOfItem('phones', i, param)

    setClientFormValues(param)
  }

  const onChangeType = e => {
    const { name, value } = e.target
    const param = { ...clientFormValues }
    param[name].value = value
    setClientFormValues(param)
    dispatch(updatingContact({ [name]: value }))
  }

  const handleChangePhonePref = (i, value) => {
    const form = JSON.parse(JSON.stringify(clientFormValues))
    if (form.phones[i].value.preference) {
      form.phones[i].value.preference = value
    }

    setClientFormValues(form)
  }

  const onCheck = (e, type, i, phone1, phone2) => {
    const param = { ...clientFormValues }
    switch (type) {
      case 'voice':
        param.phones[i].value.preference = e.target.checked
          ? 'voice'
          : phone2
          ? 'text'
          : null
        break
      case 'text':
        param.phones[i].value.preference = e.target.checked
          ? 'text'
          : phone1
          ? 'voice'
          : null
        break
      case 'send_promo':
        param.emails[i].value.send_promo = e.target.checked
        break
      case 'send_reminder':
        param.emails[i].value.send_reminder = e.target.checked
        break
      case 'preference': {
        if (!param.emails[i].value.preference) {
          param.emails.map(item => {
            if (item.value.preference) {
              item.value.preference = false
            }
          })
          param.emails[i].value.preference = e.target.checked
        } else {
          param.emails = param.emails.map(item => ({
            ...item,
            value: { ...item.value, preference: false },
          }))
        }
        break
      }
      default:
        break
    }

    setClientFormValues(param)
  }

  const useClientFromSuggestions = async client => {
    setPreloader(true)
    setShowSuggestions(false)

    try {
      if (parentContact && TYPE === 1) {
        await attachOrganizationToContactHandler(client)

        onCancel()
        return
      }
      if (parentContact && TYPE === 2) {
        await attachContactToOrganizationHandler(client)

        onCancel()
        return
      }

      const res = await getClientById(client.id)

      const formatedClient = fotmatingContact(res)

      const data = prepareValuesToServer(formatedClient)

      const { payload } = await dispatch(
        updateContact({
          contact: {
            ...data,
            phones: [
              ...data.phones,
              {
                id: 0,
                phone: contact?.from_number
                  ? parsePhoneNumber(contact?.from_number).slice(2)
                  : '',
                type: 'cell',
                preference: 'voice',
                entity_type: data?.type,
              },
            ],
          },
        }),
      )

      await getClient(payload.id, payload.type)

      setPreloader(false)
      setShowSuggestions(false)
      dispatch(onChangeFieldAction({ field: 'new', value: true }))
      queryClient.invalidateQueries({ queryKey: 'calls/incoming_table' })
    } catch (error) {
      console.error(error)
    } finally {
      setPreloader(false)
    }
  }

  const createContactHandler = async () => {
    const isValid = checkIsValid()
    if (!isValid) return

    setPreloader(true)
    const data = prepareValuesToServer()
    try {
      const { payload } = await dispatch(
        createContact({ contact: data, type: data.type }),
      )
      await getClient(payload.id, payload.type)
      openNotificationWithIcon('success', { message: 'Contact has been added' })
    } catch (error) {
      console.error(error)
    } finally {
      setPreloader(false)
      setShowSuggestions(false)
      dispatch(onChangeFieldAction({ field: 'new', value: true }))
      queryClient.invalidateQueries({ queryKey: 'calls/incoming_table' })
    }
  }

  const updateContactHandler = async () => {
    const data = prepareValuesToServer()

    setPreloader(true)
    try {
      const { payload } = await dispatch(updateContact({ contact: data }))
      const formatedClient = fotmatingContact(payload)
      setClientFeedbacks(formatedClient.feedbacks)
      setClientFormValues(prev => ({ ...prev, ...formatedClient }))
      openNotificationWithIcon('success', {
        message: 'Contact has been updated',
      })
    } catch (error) {
      console.error(error)
    } finally {
      setPreloader(false)
      setShowSuggestions(false)
      queryClient.invalidateQueries({ queryKey: 'calls/incoming_table' })
    }
  }

  const deleteContactHandler = async () => {
    // const data = prepareValuesToServer()

    setPreloader(true)
    try {
      await dispatch(
        deleteContact({
          contact: {
            id: clientFormValues.id.value,
            type: clientFormValues.type.value,
          },
        }),
      )
      setClientFormValues(initialFormValues)
    } catch (error) {
      console.error(error)
    } finally {
      setPreloader(false)
      setShowSuggestions(false)
      queryClient.invalidateQueries({ queryKey: 'calls/incoming_table' })
    }
  }
  const onCreateFeedback = async (id, data) => {
    setPreloader(true)
    const relevantData = {
      ...data,
      entity_type: clientFormValues.type.value,
    }
    try {
      const res = await createContactFeedback(id, relevantData)

      const user = tech.find(t => t.id === res.user_id)

      setClientFeedbacks(prev => [
        ...prev,
        { ...res, full_name: user?.full_name },
      ])
    } catch (error) {
      console.error(error)
    } finally {
      setPreloader(false)
    }
  }
  const onDeleteFeedback = async id => {
    setPreloader(true)
    try {
      await deleteContactFeedback(id)
      setClientFeedbacks(prev => prev.filter(p => p.id !== id))
    } catch (error) {
      console.error(error)
    } finally {
      setPreloader(false)
    }
  }
  const onSaveFeedback = async (id, data) => {
    setPreloader(true)

    try {
      const res = await updateContactFeedback(id, data)

      setClientFeedbacks(prev => prev.map(p => (p.id === res.id ? res : p)))
    } catch (error) {
      console.error(error)
    } finally {
      setPreloader(false)
    }
  }
  const onCloseFeedback = async () => {
    try {
      await getClient(clientFormValues.id.value, clientFormValues.type.value)
      toggleFeedbackModal(false)
    } catch (error) {
      console.error(error)
    } finally {
      setPreloader(false)
    }
  }

  const attachContactToOrganizationHandler = async contact => {
    try {
      if (!contact) {
        const isValid = checkIsValid()
        if (!isValid) return
        const data = prepareValuesToServer()
        const { payload } = await dispatch(
          createContact({ contact: data, type: data.type }),
        )
        const res = await attachContactToOrganization(
          parentContact.id,
          payload.id,
          contact?.relation_name || '',
        )
        dispatch(addContactToOrganization(res))
        // await getClient(parentContact.id, parentContact.type)
        onCancel()
        return
      }
      const res = await attachContactToOrganization(
        parentContact.id,
        contact.id,
        contact.relation_name,
      )

      dispatch(addContactToOrganization(res))
      // await getClient(parentContact.id, parentContact.type)
    } catch (error) {
      console.error(error)
    }
  }
  const attachOrganizationToContactHandler = async contact => {
    try {
      if (!contact) {
        const isValid = checkIsValid()
        if (!isValid) return
        const data = prepareValuesToServer()
        const { payload } = await dispatch(
          createContact({ contact: data, type: data.type }),
        )
        const dataToSave = {
          organization_id: payload.id,
          contact_role: contact?.relation_name || '',
        }
        const res = await attachOrganizationToContact(
          parentContact.id,
          dataToSave,
        )
        dispatch(addOrganizationToContact(res))
        // await getClient(parentContact.id, parentContact.type)
        onCancel()
        return
      }
      const data = {
        organization_id: contact.id,
        contact_role: contact?.relation_name || '',
      }
      const res = await attachOrganizationToContact(parentContact.id, data)

      dispatch(addOrganizationToContact(res))
      // await getClient(parentContact.id, parentContact.type)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (contact?.id) {
      getClient(contact?.id, contact?.type)
    }
  }, [contact])

  useEffect(() => {
    const resultSearchValue = nameSearchValue
    setShowSuggestions(!!resultSearchValue)
    setResultSearchValue({ field: 'name', value: resultSearchValue })
  }, [nameSearchValue])
  useEffect(() => {
    const resultSearchValue = phoneSearchValue
    setShowSuggestions(!!resultSearchValue)
    setResultSearchValue({ field: 'phone', value: resultSearchValue })
  }, [phoneSearchValue])
  useEffect(() => {
    const resultSearchValue = emailSearchValue
    setShowSuggestions(!!resultSearchValue)
    setResultSearchValue({ field: 'email', value: resultSearchValue })
  }, [emailSearchValue])
  useEffect(() => {
    if (!!findedClients && !!findedClients.length) {
      findedClients = []
      setShowSuggestions(false)
    }
  }, [clientFormValues.type.value])

  const canAddPhone =
    isPhoneValid &&
    clientFormValues.phones.every(
      item => item.value.phone.replace(/\D/g, '').length === 10,
    )
  const canAddEmail =
    isEmailValid &&
    clientFormValues.emails.every(
      item => !!item.value.email && validator.isEmail(item.value.email),
    )

  const isMemberships = !!currentContact?.memberships?.length
  return (
    <Wrapper>
      <FormRow loading={preloader} title='Type'>
        <TextField
          type='text'
          variant='outlined'
          fullWidth={true}
          size='small'
          select
          name={'type'}
          value={clientFormValues.type.value}
          onChange={onChangeType}
          disabled={!!clientFormValues?.id?.value || openFromParent}
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem value={1}>Organization</MenuItem>
          <MenuItem value={2}>Individual</MenuItem>
          {!!clientFormValues?.id?.value && (
            <MenuItem value={3}>Individual</MenuItem>
          )}
        </TextField>
      </FormRow>

      {openFromParent && (
        <FormRow title='Relation'>
          <TextField
            size='small'
            variant='outlined'
            className='form-control'
            value={clientFormValues.relation.value}
            name='relation'
            onChange={e => {
              onChange(e)
            }}
            autoComplete='new-password'
            fullWidth
            style={{ width: '100%' }}
          />
        </FormRow>
      )}

      {TYPE === 1 && openFromParent && (
        <FormRow title='Website'>
          <TextField
            size='small'
            variant='outlined'
            className='websiteInput'
            value={clientFormValues.website.value}
            name='website'
            onChange={e => onChange(e)}
            autoComplete='new-password'
            inputProps={{ maxLength: 250 }}
            style={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormRow>
      )}

      {TYPE === 1 && openFromParent && (
        <FormRow title='Industry'>
          <TextField
            size='small'
            variant='outlined'
            className='industryInput'
            value={clientFormValues.industry.value}
            name='industry'
            onChange={e => onChange(e)}
            inputProps={{ maxLength: 250 }}
            autoComplete='new-password'
            style={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormRow>
      )}

      {isMemberships && (
        <FormRow loading={preloader} title='Memberships'>
          <MembershipLabels memberships={currentContact?.memberships} />
        </FormRow>
      )}
      {clientFormValues?.id?.value && (
        <FormRow loading={preloader} title='Feedback'>
          <div style={{ display: 'flex', gap: '10px', cursor: 'pointer' }}>
            <FeedbackRating
              size='small'
              readOnly
              value={
                clientFormValues.vote.value ? clientFormValues.vote.value : 0
              }
              precision={0.5}
            />
            <div onClick={() => toggleFeedbackModal(true)}>
              <EyeIcon fill='black' />
            </div>
          </div>
        </FormRow>
      )}

      <FormRow loading={preloader} title='Full Name'>
        <Row
          maxLength={500}
          name={clientFormValues.name}
          website={clientFormValues.website}
          industry={clientFormValues.industry}
          relationValue={clientFormValues?.relation?.value}
          onChange={onChange}
          onChangeType={onChangeType}
          refProps={clientInputRow}
          type={clientFormValues.type}
          clientId={clientFormValues.id.value}
          // organizationId={organizationId}
          isOrganization={isOrganization}
          isAssociation={false}
          isAppointment={false}
          // isContact={isContact}
          // isClient={isClient}
          isShowType={false}
          isPropertyForm={false}
          isCalls={true}
        />
        {showSuggestions && !!findedClients && searchField === 'name' && (
          <div className='suggestionWrapper'>
            {findedClients.length ? (
              <>
                <ClientsSuggestionsTable
                  suggestions={findedClients}
                  onUseThisClient={useClientFromSuggestions}
                />
                <div style={{ marginBottom: '1.2rem' }} />
              </>
            ) : null}
          </div>
        )}
      </FormRow>

      <FormRow
        loading={preloader}
        title={
          <button
            className='btn add color_gray'
            disabled={!canAddPhone}
            onClick={() => {
              if (canAddPhone) addRow('phones')
            }}
          >
            Phones <AddIcon disabled={!canAddPhone} />
          </button>
        }
      >
        <InnerRowPhone>
          {clientFormValues.phones.map((item, i) => (
            <PhoneRow
              placeholder='XXX-XXX-XXXX'
              isLast={clientFormValues.phones.length - 1 === i}
              isOne={clientFormValues.phones.length === 1}
              key={i}
              item={item}
              refProps={clientInputPhone}
              index={i}
              onChange={e => onChange(e, i)}
              addRow={() => addRow('phones')}
              removeRow={() => removeRow('phones', i)}
              onCheckPhonePref={handleChangePhonePref}
              onChangeSelect={e => onChangeSelect(e, i)}
              isPhoneValid={isPhoneValid}
              setIsPhoneValid={setIsPhoneValid}
              isUpdate={isUpdateClient}
            />
          ))}
        </InnerRowPhone>

        {showSuggestions && !!findedClients && searchField === 'phones' && (
          <div className='suggestionWrapper'>
            {findedClients.length ? (
              <>
                <ClientsSuggestionsTable
                  suggestions={findedClients}
                  onUseThisClient={useClientFromSuggestions}
                />
                <div style={{ marginBottom: '2rem' }} />
              </>
            ) : null}
          </div>
        )}
      </FormRow>
      <FormRow
        loading={preloader}
        title={
          <button
            className='btn add color_gray'
            disabled={!canAddEmail}
            onClick={() => {
              if (canAddEmail) addRow('emails')
            }}
          >
            Emails <AddIcon disabled={!canAddEmail} />
          </button>
        }
      >
        <InnerRowEmail>
          {clientFormValues.emails.map((item, i) => (
            <EmailRow
              placeholder='example@mail.com'
              isLast={clientFormValues.emails.length - 1 === i}
              key={i}
              refProps={clientInputEmail}
              isOne={clientFormValues.emails.length === 1}
              item={item}
              index={i}
              onChange={e => onChange(e, i)}
              addRow={() => addRow('emails')}
              removeRow={() => removeRow('emails', i)}
              onCheck={onCheck}
              isEmailValid={isEmailValid}
              isUpdate={isUpdateClient}
            />
          ))}
        </InnerRowEmail>

        {showSuggestions && !!findedClients && searchField === 'emails' && (
          <div className='suggestionWrapper'>
            {findedClients.length ? (
              <>
                <ClientsSuggestionsTable
                  suggestions={findedClients}
                  onUseThisClient={useClientFromSuggestions}
                />
                <div style={{ marginBottom: '1.2rem' }} />
              </>
            ) : null}
          </div>
        )}
      </FormRow>
      <FormRow loading={preloader} title='Notes'>
        {clientFormValues.id?.value ? (
          <Notes
            label='Contact Notes'
            route={`/${
              clientFormValues?.type?.value === 1 ? 'organizations' : 'clients'
            }/${clientFormValues.id.value}/notes`}
          />
        ) : (
          <>
            <InputField
              size='small'
              variant='outlined'
              multiline
              maxRows={5}
              name='note'
              value={clientFormValues.note.value || ''}
              error={
                clientFormValues.note.value.length === 499 && {
                  note: ['Max length is 499 characters'],
                }
              }
              onChange={e => onChange(e)}
              inputProps={{
                maxLength: 499,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </>
        )}
      </FormRow>
      <ControlsWrapper>
        {clientFormValues?.id?.value && userCanDelete && (
          <Popconfirm
            onConfirm={deleteContactHandler}
            title='Are you sure to delete this contact?'
            okText='Yes'
            cancelText='No'
          >
            <MainButton type='delete' title='Delete' />
          </Popconfirm>
        )}
        {openFromParent ? (
          <MainButton
            onClick={
              TYPE === 2
                ? () => attachContactToOrganizationHandler(null)
                : () => attachOrganizationToContactHandler(null)
            }
            title={TYPE === 2 ? 'Attach contact' : 'Attach organization'}
          />
        ) : (
          <MainButton
            onClick={
              clientFormValues?.id?.value
                ? updateContactHandler
                : createContactHandler
            }
            title={clientFormValues?.id?.value ? 'Update' : 'Create'}
          />
        )}
      </ControlsWrapper>

      <FeedbackModal
        feedback={clientFeedbacks}
        modal={feedbackModal}
        clientId={clientFormValues.id.value}
        close={onCloseFeedback}
        onSaveFeedback={onSaveFeedback}
        onCreateFeedback={onCreateFeedback}
        onDeleteFeedback={onDeleteFeedback}
      />
    </Wrapper>
  )
}
