import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const SureModal = ({
  modal,
  close,
  apply,
  title = 'Are you sure?',
  isLoading,
  okTitle = 'Confirm',
  cancelTitle = 'Cancel',
}) => {
  return (
    <Modal show={modal} onHide={isLoading ? () => {} : close} backdrop='static'>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-center h3 text-center'>{title}</div>
        <div className='d-flex justify-content-around mt-5'>
          <Button variant='info' onClick={close} disabled={isLoading}>
            {cancelTitle}
          </Button>
          <Button variant='info' onClick={apply} disabled={isLoading}>
            <div className='d-flex align-items-center'>{okTitle}</div>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default SureModal
