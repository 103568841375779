import axiosOrcatec from './axiosInstance'

export const getUserTags = route => {
  return axiosOrcatec.get(`/${route}/tags`).then(res => res.data)
}
export const setTagsSync = (route, data) => {
  return axiosOrcatec.post(`/${route}/tags/sync`, data).then(res => res.data)
}
export const createTag = data => {
  return axiosOrcatec.post(`/tags`, data).then(res => res)
}
export const getAllTags = () => {
  return axiosOrcatec.get(`/tags`).then(res => res.data)
}

export const setTagColor = (id, data) => {
  return axiosOrcatec.post(`/tags/${id}/color`, data).then(res => res.data)
}
export const deleteTag = id => {
  return axiosOrcatec.delete(`/tags/${id}`).then(res => res.data)
}
