import { AppStateType } from 'store'
import {
  ProjectItem,
  IProjectItemsGroup,
  ProjectDiscount,
  ProjectDiscountType,
  ProjectRebate,
  Project,
  TaxChargeType,
  ProjectFee,
  ProjectStatusGroup,
  // IProjectTab,
} from './types'
import { createSelector } from '@reduxjs/toolkit'
import { round } from 'helpers/Math'
import { BalanceStatus, PaymentBalance, Proposal } from 'types/Proposal'
import moment from 'moment-timezone'
import { selectCompanyTimezone } from 'store/Orcatec/selectors/company'
// import projectTabsSlice from './slices/projectTabsSlice'

export const selectProjectTabsSlice = (state: AppStateType) =>
  state.orcatec.projectTabsSlice

export const selectProjectSectionsSlice = (state: AppStateType) =>
  state.orcatec.projectSectionsSlice

export const selectProjectItemsSlice = (state: AppStateType) =>
  state.orcatec.projectItemsSlice

export const selectProjectGroupsSlice = (state: AppStateType) =>
  state.orcatec.projectGroupsSlice

export const selectProjectDiscountsSlice = (state: AppStateType) =>
  state.orcatec.projectDiscountsSlice

export const selectProjectRebatesSlice = (state: AppStateType) =>
  state.orcatec.projectRebatesSlice

export const selectProjectTaxAndFeesSlice = (state: AppStateType) =>
  state.orcatec.projectTaxAndFeesSlice

export const selectProjectItems = (state: AppStateType): ProjectItem[] =>
  Object.values(state.orcatec.projectItemsSlice.items)

export const selectProjectFees = (state: AppStateType): ProjectFee[] =>
  state.orcatec.projectTaxAndFeesSlice.fees.ids.map(
    feeId => state.orcatec.projectTaxAndFeesSlice.fees.data[feeId],
  )

export const selectProjectProperiesCount = (state: AppStateType): number =>
  state?.orcatec.proposalForm.countProperties

export const selectProjectClientsCount = (state: AppStateType): number =>
  state?.orcatec.proposalForm.countClients

export const selectProjectOrganizationCount = (state: AppStateType): number =>
  state?.orcatec.proposalForm.countOrganizations

export const selectProjectDiscounts = (
  state: AppStateType,
): ProjectDiscount[] =>
  Object.values(state.orcatec.projectDiscountsSlice.discounts)

export const selectProjectRebates = (state: AppStateType): ProjectRebate[] =>
  Object.values(state.orcatec.projectRebatesSlice.rebates)

export const selectProjectGroups = (
  state: AppStateType,
): IProjectItemsGroup[] =>
  Object.values(state.orcatec.projectGroupsSlice.groups)

export const selectProject = (state: AppStateType): Project =>
  state.orcatec.projectSlice.project

const selectActiveTabId = (state: AppStateType) =>
  state.orcatec.projectSlice.project.activeTabId

export const selectProjectSections = createSelector(
  selectProjectSectionsSlice,
  ({ sections }) => sections.ids.map(id => sections.data[id]),
)

export const selectProjectSettings = createSelector(
  selectProject,
  project => project.setting,
)

export const selectProjectContractDate = createSelector(
  [selectProject, selectCompanyTimezone],
  (project: Proposal, companyTimezone) => {
    const contractDate = project.contract_date

    return contractDate ? moment.utc(contractDate).tz(companyTimezone) : null
  },
)

export const selectProjectDiscountsWithStatus = createSelector(
  [
    selectProjectDiscounts,
    selectProjectContractDate,
    selectCompanyTimezone,
    selectActiveTabId,
  ],
  (discounts, contractDate, timezone, activeTabId) => {
    const endOfExpirationDate = contractDate || moment().startOf('day')

    return discounts
      .filter(discount => discount.tab_id === activeTabId)
      .map(discount => {
        return {
          ...discount,
          active:
            !discount.expiration ||
            moment(discount.expiration)
              .endOf('day')
              .tz(timezone)
              .isAfter(endOfExpirationDate),
        }
      })
  },
)

export const selectProjectRebatesWithStatus = createSelector(
  [
    selectProjectRebates,
    selectProjectContractDate,
    selectCompanyTimezone,
    selectActiveTabId,
  ],
  (rebates, contractDate, timezone, activeTabId) => {
    const endOfExpirationDate = contractDate || moment().startOf('day')

    return rebates
      .filter(rebate => rebate.tab_id === activeTabId)
      .map(rebate => {
        return {
          ...rebate,
          active:
            !rebate.expiration ||
            moment(rebate.expiration)
              .endOf('day')
              .tz(timezone)
              .isAfter(endOfExpirationDate),
        }
      })
  },
)

export const selectProjectItemsBySection = (
  sectionId: number,
  groupId: number | null,
) =>
  createSelector(selectProjectItems, items =>
    Object.values(items)
      .filter(
        item => item.group_id === groupId && item.section_id === sectionId,
      )
      .sort((a, b) => a.position - b.position),
  )

export const selectProjectMaterialsItemWithSection = createSelector(
  [selectProjectItems, selectProjectSections],
  (items, sections) => {
    const sectionMap = sections.reduce((acc, section) => {
      acc[section.id] = { section_title: section.title, items: [] }
      return acc
    }, {})

    items.forEach(item => {
      if (item.is_material && sectionMap[item.section_id]) {
        sectionMap[item.section_id].items.push(item)
      }
    })

    return Object.values(sectionMap)
  },
)

export const selectProjectGroupsBySection = (sectionId: number) =>
  createSelector(selectProjectGroups, groups =>
    groups
      .filter(group => group.section_id === sectionId)
      .sort((a, b) => a.position - b.position),
  )

export const selectSectionDiscounts = (
  sectionId: number,
  groupId: number | null,
) =>
  createSelector(selectProjectDiscountsWithStatus, discounts =>
    discounts
      .filter(
        item => item.group_id === groupId && item.section_id === sectionId,
      )
      .sort((a, b) => a.position - b.position),
  )

export const selectSectionRebates = (
  sectionId: number,
  groupId: number | null,
) =>
  createSelector(selectProjectRebatesWithStatus, rebates =>
    rebates
      .filter(
        item => item.group_id === groupId && item.section_id === sectionId,
      )
      .sort((a, b) => a.position - b.position),
  )

export const selectProjectIsEditable = createSelector(
  selectProject,
  (project: Proposal) => project.status === 1,
)

const selectProjectTabSection = (sectionId: number) =>
  createSelector(selectProjectSections, sections =>
    sections?.find(section => section.id === sectionId),
  )

export const selectSectionStructure = (sectionId: number) =>
  createSelector(
    selectProjectTabSection(sectionId),
    section => section.structure,
  )

export const selectProjectActiveTab = createSelector(
  selectProjectTabsSlice,
  ({ projectTab }) => projectTab,
)

export const selectProjectPayment = (state: AppStateType) =>
  state.orcatec.modalPayment.payments

//Totals

const selectItemsForCalculations = createSelector(
  [selectProjectItems, selectActiveTabId, selectProjectSettings],
  (items, tabId, settings) =>
    items
      .filter(
        item =>
          item?.approved &&
          (settings?.multiple_choice_items ? item?.use_calculation : true) &&
          item?.tab_id === tabId,
      )
      .filter(item => (item.option_group_id ? item.checked_option : item)),
)

export const selectItemsTotal = (sectionId?: number) =>
  createSelector(selectItemsForCalculations, items =>
    items
      .filter(item => (sectionId ? item.section_id === sectionId : !!item))
      .reduce(
        (acc, currItem) =>
          (acc += (currItem.retail_price || 0) * (currItem.qty ?? 0)),
        0,
      ),
  )

export const selectItemsGroupTotal = (groupId: number | null) =>
  createSelector(selectItemsForCalculations, items =>
    items
      .filter(item => item.group_id === groupId)
      .reduce(
        (acc, currItem) =>
          (acc += (currItem.retail_price || 0) * (currItem.qty ?? 0)),
        0,
      ),
  )

export const selectGroupTotalAfterDiscount = (
  sectionId: number,
  groupId: number,
) =>
  createSelector(
    [
      selectItemsGroupTotal(groupId),
      selectSectionDiscounts(sectionId, groupId),
    ],
    (groupTotal, groupDiscounts) => {
      const totalAfter = groupDiscounts
        .filter(discount => discount.active)
        .filter(discount =>
          discount.option_group_id ? discount.checked_option : discount,
        )
        .reduce(
          (total, discount) =>
            total -
            (discount.discount_type === ProjectDiscountType.Amount
              ? discount.amount
              : (groupTotal * discount.amount) / 100),
          groupTotal,
        )
      return totalAfter
    },
  )

export const selectMaterialsTotal = (sectionId?: number) =>
  createSelector(selectItemsForCalculations, items => {
    return items
      .filter(item => item.is_material)
      .filter(item => (sectionId ? item.section_id === sectionId : !!item))
      .reduce(
        (acc, currItem) =>
          (acc += round((currItem.retail_price || 0) * (currItem.qty ?? 0))),
        0,
      )
  })

export const selectTaxableTotal = createSelector(
  selectItemsForCalculations,
  items =>
    items
      .filter(item => item.taxable)
      .reduce(
        (acc, currItem) =>
          (acc += (currItem.retail_price || 0) * (currItem.qty ?? 0)),
        0,
      ),
)

export const selectSectionRebatesTotal = (
  sectionId: number,
  groupId: number | null,
) =>
  createSelector(selectSectionRebates(sectionId, groupId), rebates => {
    return rebates
      ?.filter(rebate => rebate.active)
      ?.filter(rebate =>
        rebate.option_group_id ? rebate.checked_option : rebate,
      )
      ?.reduce((sum, item) => (sum += +item.amount), 0)
  })

export const selectSectionLabor = (sectionId: number) =>
  createSelector(selectProjectTabSection(sectionId), section =>
    section?.installation ? +section.installation : 0,
  )

export const selectGrossMarginAverage = (sectionId?: number) =>
  createSelector(selectItemsForCalculations, items => {
    const sectionItems = items.filter(item =>
      sectionId ? item.section_id === sectionId : !!item,
    )
    const grossMarginSum = sectionItems.reduce(
      (acc, currItem) =>
        (acc += currItem.gross_margin ? +currItem.gross_margin : 0),
      0,
    )

    return sectionItems?.length
      ? round(grossMarginSum / sectionItems.length)
      : 0
  })

export const selectSectionSubtotal = (sectionId: number) =>
  createSelector(
    [selectItemsTotal(sectionId), selectSectionLabor(sectionId)],
    (itemsTotal, laborTotal) => {
      const totals = itemsTotal + laborTotal

      return totals
    },
  )

export const selectSectionDiscountsTotal = (sectionId: number) =>
  createSelector(
    [selectSectionSubtotal(sectionId), selectProjectDiscountsWithStatus],
    (sectionSubtotal, discounts) => {
      const sum = discounts
        .filter(discount => discount.active)
        .filter(discount =>
          discount.option_group_id ? discount.checked_option : discount,
        )
        .filter(discount => discount.section_id === sectionId)
        .reduce(
          (total, discount) =>
            total +
            round(
              discount.discount_type === ProjectDiscountType.Amount
                ? discount.amount
                : (sectionSubtotal * discount.amount) / 100,
            ),
          0,
        )

      return sum
    },
  )

export const selectSectionTotalAfterDiscounts = (sectionId: number) =>
  createSelector(
    [selectSectionSubtotal(sectionId), selectSectionDiscounts(sectionId, null)],
    (sectionSubtotal, discounts) => {
      const sectionTotal = discounts
        .filter(discount => discount.active)
        .filter(discount =>
          discount.option_group_id ? discount.checked_option : discount,
        )
        .reduce(
          (total, discount) =>
            total -
            round(
              discount.discount_type === ProjectDiscountType.Amount
                ? discount.amount
                : (sectionSubtotal * discount.amount) / 100,
            ),
          sectionSubtotal,
        )
      return sectionTotal
    },
  )

export const selectInvestmentTotal = createSelector(
  (state: AppStateType) => state,
  selectProjectSections,
  (state, sections) => {
    const investmentTotal = sections?.reduce(
      (total, section) => (total += selectSectionSubtotal(section.id)(state)),
      0,
    )

    return investmentTotal
  },
)

export const selectProjectTotalAfterDiscounts = createSelector(
  (state: AppStateType) => state,
  selectProjectSections,
  (state, sections) => {
    const investmentTotal = sections?.reduce(
      (total, section) =>
        (total += selectSectionTotalAfterDiscounts(section.id)(state)),
      0,
    )

    return investmentTotal
  },
)

export const selectProjectDiscountsTotal = createSelector(
  (state: AppStateType) => state,
  selectProjectSections,
  (state, sections) => {
    const investmentTotal = sections?.reduce(
      (total, section) =>
        (total += selectSectionDiscountsTotal(section.id)(state)),
      0,
    )

    return investmentTotal
  },
)

// export const selectProjectDiscountsTotal = createSelector(
//   [selectActiveTabId, selectProjectDiscountsWithStatus],
//   (activeTabId, discounts) => {
//     return discounts
//       .filter(discount => discount.active)
//       .filter(discount => discount.tab_id === activeTabId)
//       .filter(discount =>
//         discount.option_group_id ? discount.checked_option : discount,
//       )
//       .reduce((sum, discount) => (sum += discount.amount), 0)
//   },
// )

export const selectProjectRebatesTotal = createSelector(
  [selectActiveTabId, selectProjectRebatesWithStatus],
  (activeTabId, rebates) => {
    return rebates
      .filter(rebate => rebate.active)
      .filter(rebate => rebate.tab_id === activeTabId)
      .filter(rebate =>
        rebate.option_group_id ? rebate.checked_option : rebate,
      )
      .reduce((sum, rebate) => (sum += rebate.amount), 0)
  },
)

//Taxes and Fees

export const selectProjectTaxTotal = createSelector(
  [
    selectProjectTaxAndFeesSlice,
    selectTaxableTotal,
    selectProjectTotalAfterDiscounts,
  ],
  ({ tax }, taxableItemsTotal, totalAfterDiscounts) => {
    if (!tax) return 0

    if (tax.type === TaxChargeType.CUSTOM) return tax.total

    const TAXABLE_TOTAL =
      tax?.type === TaxChargeType.MATERIALS
        ? taxableItemsTotal
        : totalAfterDiscounts

    return round(TAXABLE_TOTAL * (tax?.rate / 100), 3)
  },
)

export const selectProjectFeesTotal = createSelector(selectProjectFees, fees =>
  fees?.reduce((sum, fee) => (sum += +fee.price), 0),
)

// Payment

export const selectBalancesPaidTotal = createSelector(
  state => state.orcatec.modalPayment.payments,
  payment => {
    const paidBalances: PaymentBalance[] = payment?.payment_balances?.filter(
      (balance: PaymentBalance) => balance.status === BalanceStatus.Paid,
    )
    const sumOfPaidBalances = paidBalances?.reduce(
      (sum, balance) => (sum += +balance.amount),
      0,
    )

    return sumOfPaidBalances
  },
)

export const selectProjectTotal = createSelector(
  [
    selectProject,
    selectProjectActiveTab,
    selectProjectTotalAfterDiscounts,
    selectProjectTaxTotal,
    selectProjectFeesTotal,
  ],
  (project, signedTab, totalAfterDiscounts, taxTotal, feeSum) => {
    if (project.status === ProjectStatusGroup.Canceled) {
      return signedTab.cancellation_fees || 0
    }

    return round(totalAfterDiscounts + taxTotal + feeSum)
  },
)

interface ProjectContactsCount {
  properies: number
  clients: number
  organizations: number
  has_contacts: boolean
}

export const selectProjectContactsCount = createSelector(
  [
    selectProjectProperiesCount,
    selectProjectClientsCount,
    selectProjectOrganizationCount,
  ],
  (
    properies: number,
    clients: number,
    organizations: number,
  ): ProjectContactsCount => {
    return {
      properies,
      clients,
      organizations,
      has_contacts: !!properies || !!clients || !!organizations,
    }
  },
)

// Dates

/* export const selectProjectContractDate = createSelector(
  [selectProject, selectCompanyTimezone],
  (project: Proposal, companyTimezone) => {
    const contractDate = project.contract_date

    return contractDate ? moment.utc(contractDate).tz(companyTimezone) : null
  },
)
 */
