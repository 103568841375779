import useOnClickOutside from 'hooks/useOnClickOutside'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
// import MainButton from '../../buttons/MainButton'
import { EditIcon } from '../../Icons/CommonIcons'
import { Tooltip } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

interface Props {
  children: React.ReactNode
  value: string | number | JSX.Element
  disabled?: boolean
  editMode?: boolean
  closeOnBlur?: boolean
  disabledText?: string
  onSave: () => void
  onCancel?: () => void
  isError?: boolean
}

export const EditableBlock = ({
  children,
  value,
  onSave,
  onCancel,
  disabled,
  disabledText = 'Disabled',
  editMode,
  closeOnBlur,
  isError,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const contentRef = useRef(null)

  const [isEdit, setIsEdit] = useState(editMode)

  const handleTextClick = () => {
    if (disabled) return
    setIsEdit(true)
  }

  const handleSave = async (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    setLoading(true)
    try {
      await onSave?.()
      setIsEdit(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  const handleCancel = () => {
    onCancel?.()
    setIsEdit(false)
  }

  useOnClickOutside(contentRef, () => {
    if (closeOnBlur) handleCancel()
  })

  useEffect(() => {
    if (isError) {
      setTimeout(() => setIsEdit(true), 100)
    }
  }, [isError])

  return (
    <Wrapper>
      {isEdit ? (
        <Content
          ref={contentRef}
          className='editable-content'
          data-testid='content'
        >
          {children}

          {!editMode && (
            <Buttons>
              {/* <MainButton
                title='Save'
                onClick={handleSave}
                isFetching={loading}
              />
              <MainButton type='cancel' title='Cancel' onClick={handleCancel} /> */}
              <Tooltip title='Save' mouseLeaveDelay={0}>
                <CheckOutlined
                  style={{ color: 'green' }}
                  onClick={e => {
                    if (loading) return
                    handleSave(e)
                  }}
                />
              </Tooltip>
              <Tooltip title='Cancel' mouseLeaveDelay={0}>
                <CloseOutlined
                  style={{ color: 'red' }}
                  onClick={() => {
                    if (loading) return
                    handleCancel()
                  }}
                />
              </Tooltip>
            </Buttons>
          )}
        </Content>
      ) : (
        <>
          <Value className='editable-title'>{value || '--'}</Value>
          <Tooltip title={disabled ? disabledText : undefined}>
            <Edit
              onClick={handleTextClick}
              data-testid='edit-icon'
              disabled={!!disabled}
            >
              <EditIcon size={12} />
            </Edit>
          </Tooltip>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`

const Buttons = styled.div`
  display: flex;
  gap: 15px;
  padding-top: 8px;
  align-items: baseline;
  /* margin: 10px 0 20px; */
`

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  /* align-items: baseline; */
`
const Value = styled.p``
const Edit = styled.span<{ disabled: boolean }>`
  position: relative;
  top: -1px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  svg {
    path {
      fill: ${props => (props.disabled ? 'grey' : 'black')};
    }
  }
`
