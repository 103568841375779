import { CALL_STATUS, CALL_STATUS_COLOR } from "../constants"

const getCallsStatus = (status: number) => {
  return <p style={{ textAlign: 'left', color: CALL_STATUS_COLOR[status], borderRadius: 3 }}>{CALL_STATUS[status]}</p>
}
const fullLink = path => `${process.env.REACT_APP_API_MEDIA}${path}`
const toHoursAndMinutes = totalSeconds => {
  const totalMinutes = Math.floor(totalSeconds / 60)

  const seconds = totalSeconds % 60
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  return (
    <p>{`${hours ? hours + ' h' : ''} ${minutes ? minutes + ' m' : ''} ${seconds || seconds === 0 ? seconds + ' s' : ''
      }`}</p>
  )
}

export { getCallsStatus, fullLink, toHoursAndMinutes }