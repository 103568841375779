import { useEffect, useState } from 'react'

function useHashRouteToggle(modalHash: string) {
  const [isActive, toggleOpen] = useState(false)

  const toggleActive = (open: boolean) => {
    if (open) {
      window.location.assign(modalHash) // navigate to same url but with the specified hash
    } else {
      window.location.replace('#') // remove the hash
    }
  }

  useEffect(() => {
    // function for handling hash change in browser, toggling modal open
    const handleOnHashChange = () => {
      const isHashMatch = window.location.hash === modalHash
      toggleOpen(isHashMatch)
    }

    // event listener for hashchange event
    window.addEventListener('hashchange', handleOnHashChange)

    return () => window.removeEventListener('hashchange', handleOnHashChange)
  }, [modalHash])

  return [isActive, toggleActive] as const
}

export default useHashRouteToggle
