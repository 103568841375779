import React, { useState, useEffect } from 'react'
import './MediaPopap.scss'
import { InboxOutlined } from '@ant-design/icons'
import { Upload, Modal, message, Input, Progress } from 'antd'
import { upload, uploadByLink } from '../../../../../../../api/Media'
import Preloader from '../../../../components/Preloader'
import validator from 'validator'
import { Error } from '../../../../components/ErrorMessage/ErrorMessage'
import MainButton from '../../../../components/buttons/MainButton'
import { TextField } from '@material-ui/core'

const { Dragger } = Upload
let isWasUpload = false //for prevent beforeUpload every file in fileList

const MediaPopap = ({ showMediaPopap, toggleMediaPopap, setFieldValues, isAppointment }) => {
  const [urlInput, setUrlInput] = useState('')
  const [urlError, setUrlError] = useState('')
  const [uploadError, setUploadError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingByLink, setIsLoadingByLink] = useState(false)
  const [isLarge, setIsLarge] = useState(false)
  ///data for progressbar///
  const [filesToLoad, setFilesToLoad] = useState([])
  const [linkToLoad, setLinkToLoad] = useState('')
  const [uploadProggress, setUploadProggress] = useState([])

  const setProggress = idx => ({ percentCompleted, loaded, total }) => {
    const newUploadProggress = [...uploadProggress]
    newUploadProggress[idx].proggress = percentCompleted
    setUploadProggress(newUploadProggress)
  }
  ///data for progressbar///

  const uploadFiles = (files = []) => {
    setUploadError(false)
    setIsLoading(true)
    const promisses = []
    files?.forEach((file, idx) => {
      const data = new FormData()
      data.append('media', file)
      promisses.push(upload(data, setProggress(idx)))
    })
    Promise.all(promisses)
      .then(values => {
        const newValues = values.flat()
        if (isAppointment) {
          setFieldValues(newValues)
        } else {
          setFieldValues(prev => ({ ...prev, media: [...prev.media, ...newValues] }))
        }
        toggleMediaPopap()
        setUrlInput('')
        setIsLoading(false)
        setUploadProggress([])
        setFilesToLoad([])
        isWasUpload = false
      })
      .catch(() => {
        setUploadProggress([])
        setIsLoading(false)
        isWasUpload = false
      })
  }

  useEffect(() => {
    if (filesToLoad.length) {
      uploadFiles(filesToLoad)
    } else if (linkToLoad) {
      setIsLoadingByLink(true)
      const setProggressFnc = setProggress(0)
      uploadByLink(linkToLoad, setProggressFnc).then(response => {
        if (isAppointment) {
          setFieldValues(response)
        } else {
          setFieldValues(prev => ({ ...prev, media: [...prev.media, ...response] }))
        }
        setUrlInput('')
        setLinkToLoad('')
        setIsLoadingByLink(false)
        setUploadProggress([])
        toggleMediaPopap()
      })
    }
  }, [filesToLoad, linkToLoad])

  const onChangeUrlInput = e => {
    e.preventDefault()
    const { value } = e.target
    if (!value) setUrlError('')
    else {
      const isURL = validator.isURL(value, { require_protocol: true })
      if (!isURL) setUrlError('Please input valid link')
      else {
        const img = new Image()
        img.src = value
        img.onload = function() {
          setUrlError('')
        }
        img.onerror = function() {
          setUrlError("Can't load image from this link. Try another one")
        }
      }
    }
    //else setUrlError('')
    setUrlInput(value)
  }

  const uploadFilesByUrl = () => {
    if (urlInput && !urlError) {
      setUploadProggress(() => [{ name: urlInput, proggress: 0 }])
      setLinkToLoad(() => urlInput)
    }
  }

  const beforeUpload = (file, fileList) => {
    const isLt20M = file.size / 1024 / 1024 <= 20
    if (isLt20M) {
      if (!isWasUpload) {
        isWasUpload = true
        const isLt20M2 = fileList.every(file => file.size / 1024 / 1024 <= 20)
        if (!isLt20M2) {
          isWasUpload = false
          setIsLarge(true)
          setTimeout(() => {
            setIsLarge(false)
          }, 5000)
          // setUploadError(!(fileList.length > 1) ? 'File must smaller than 150Mb' : 'Each file must smaller than 150Mb')
        } else {
          const newUploadProggress = fileList.map(file => ({ name: file.name, proggress: 0 }))
          setUploadProggress(() => newUploadProggress)
          setFilesToLoad(() => fileList)
          // uploadFiles(fileList)
        }
      }
      return false
    } else {
      setIsLarge(true)
      setTimeout(() => {
        setIsLarge(false)
      }, 5000)
    }
  }

  return (
    <div className={`media-popap ${showMediaPopap ? 'media-popap-show' : ''}`}>
      <div className='notification'>
        {isLoading && (
          <>
            <div className='d-flex justify-content-center w-100 mb-3'>
              <Preloader />
            </div>
          </>
        )}
        {!isLoading && (
          <>
            <Dragger
              name='file'
              multiple={true}
              disabled={isLoadingByLink}
              beforeUpload={beforeUpload}
              showUploadList={false}
            >
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text mx-5'>Click or drag file to this area to upload</p>
              {isLarge && (
                <>
                  <span style={{ color: 'red', fontSize: '24px' }}>File is larger than 20Mb</span>
                  <br />
                  <span style={{ color: 'red', fontSize: '24px' }}>Please, try another file.</span>
                </>
              )}
              <p className='ant-upload-hint'>
                Support for a single or bulk upload.{' '}
                {/* Strictly prohibit from
            uploading company data or other band files */}
              </p>
            </Dragger>
            {!!uploadError && <Error>{uploadError}</Error>}
          </>
        )}

        {(isLoading || isLoadingByLink) &&
          uploadProggress.map((proggress, idx, arr) => (
            <div key={idx} className={'w-100' + (arr.length - 1 === idx ? '' : ' mb-3') + (idx === 0 ? '' : ' mt-3')}>
              <div>
                <Progress percent={proggress.proggress} status='active' />
              </div>
              <span className='d-block text-truncate'>{proggress.name}</span>
            </div>
          ))}

        {!isLoadingByLink ? (
          <>
            <div className='w-100 mt-4'>
              <TextField
                id='fileUrl'
                type='text'
                name='urlInput'
                value={urlInput}
                onChange={onChangeUrlInput}
                variant='outlined'
                error={!!urlError}
                helperText={urlError}
                fullWidth={true}
                size='small'
                placeholder='Or paste url of your media'
                disabled={isLoading}
              />
            </div>
            {/* <Input
              placeholder='Or paste url of your media'
              className='mt-4'
              value={urlInput}
              onChange={onChangeUrlInput}
              disabled={isLoading}
            />
            {!!urlError && <Error>{urlError}</Error>} */}
          </>
        ) : (
          <div className='d-flex justify-content-center w-100 mb-3'>
            <Preloader />
          </div>
        )}
        <div className='d-flex justify-content-between'>
          <MainButton type='danger' title='Close' onClick={toggleMediaPopap} />
          <MainButton
            title='Upload by url'
            onClick={uploadFilesByUrl}
            disabled={isLoading}
            isFetching={isLoadingByLink}
          />
        </div>
      </div>
    </div>
  )
}

export default MediaPopap
