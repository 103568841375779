import { Button as AtndButton, ButtonProps } from 'antd'
// import styled from 'styled-components'

interface Props extends ButtonProps {
  children: React.ReactNode
}

export const Button = ({ children, ...rest }: Props) => {
  return <AtndButton {...rest}>{children}</AtndButton>
}
/* 
const StyledButton = styled(AtndButton)`
  background: ${props => props.theme.button.primary};
  border-color: ${props => props.theme.button.primary};

  &:hover {
    background: ${props => props.theme.button.primaryHover};
    border-color: ${props => props.theme.button.primaryHover};
  } 
`
 */
