import { Button, Tooltip } from 'antd'
import { priceToView } from 'helpers/thousandSeparator'
import moment from 'moment-timezone'
import { BalanceStatus, PaymentBalance } from 'types/Proposal'
import { BalanceTransactions } from './BalanceTransactions'
import { useState } from 'react'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import styled from 'styled-components'

interface Props {
  data: PaymentBalance
  isCustomerView: boolean
  onPay?: (balance: PaymentBalance) => void
}

export const Balance = ({ data, onPay, isCustomerView }: Props) => {
  const [showTransaction, setShowTransactions] = useState(true)

  return (
    <div className='table-row' key={data.id}>
      <p>
        {!!data?.payment_transactions?.length && (
          <Arrow onClick={() => setShowTransactions(!showTransaction)}>
            {showTransaction ? <DownOutlined /> : <RightOutlined />}
          </Arrow>
        )}
        {data.name_balance}
      </p>

      <Tooltip title={data.description || undefined}>
        <p className={'payment-description'}>{data.description ?? ''}</p>
      </Tooltip>
      <p>{data.due_date ? moment(data.due_date).format('MM/DD/YYYY') : ''}</p>
      <p>{priceToView(data.amount ? data.amount : 0)}</p>
      <p>{BalanceStatus[data.status]}</p>
      {!!onPay && (
        <p>
          {canPay(data) && (
            <Button
              type='primary'
              size='small'
              onClick={e => {
                e.stopPropagation()
                onPay(data)
              }}
            >
              Pay
            </Button>
          )}
        </p>
      )}

      {!!data?.payment_transactions?.length && showTransaction && (
        <BalanceTransactions
          data={data?.payment_transactions}
          isCustomerView={isCustomerView}
        />
      )}
    </div>
  )
}

const Arrow = styled.span`
  margin-right: 5px;
  font-size: 14px;
  cursor: pointer;
  color: #1890ff;
`

const hasPending = (balance: PaymentBalance) =>
  balance?.payment_transactions?.some(
    tr => tr.transaction_status === 'Queued for Capture',
  )

const canPay = (balance: PaymentBalance) =>
  [BalanceStatus.Due, BalanceStatus.Overdue].includes(balance.status) &&
  (balance?.accept_card || balance?.can_pay_by_check) &&
  !hasPending(balance) &&
  balance?.amount > 0
