import * as types from '../types'

const feedback = (state = [], action) => {
  switch (action.type) {
    case types.feedback.GET_FEEDBACK:
      return action.response
    default:
      return state
  }
}

export default feedback
