import { ProjectPermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'
import { ModuleName } from '../../module_permissions/types'
import { NotesPermissions } from '../notes/types'

export const project = {
  project_can_read: {
    name: 'View Project',
    control_type: UniteType.THIRD_VIEW,
    description: 'Allows users to view project details',
    with_access_scope: true,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.PROJECT_CAN_READ,
    is_module: true,
    related_key: ['all'],
    children: [
      {
        name: 'Allow Access to Projects in Contract or Completed Status',
        permission_key: IKey.PROJECT_ACCESS_TO_CONTRACT,
        description:
          'If this setting is off, this user will not be able to access projects in "Contract" or "Completed" status',
        with_access_scope: false,
        with_access_checkbox: true,
        parent_permission_key: IKey.PROJECT_CAN_READ,
      },
    ],
  },

  project_can_create: {
    name: 'Create Project',
    control_type: UniteType.FIRST_VIEW,
    description: 'Allows users to create new projects',
    with_access_scope: true,
    with_access_checkbox: true,
    permission_key: IKey.PROJECT_CAN_CREATE,
    related_key: [],
    custom_access_scope: [
      {
        key: 1,
        label: 'For All Users ',
        color: 'white',
        activeColor: '#adf7b6',
      },
      {
        key: 2,
        label: 'Only Own ',
        activeColor: '#adf7b6',
        color: 'white',
      },
    ],
  },

  project_can_duplicate: {
    name: 'Duplicate Project',
    control_type: UniteType.FIRST_VIEW,
    description: 'Gives users the ability to duplicate existing projects',
    with_access_scope: true,
    with_access_checkbox: true,
    permission_key: IKey.PROJECT_CAN_DUPLICATE,
    related_key: [],
  },
  project_can_delete: {
    name: 'Delete Project',
    control_type: UniteType.FIRST_VIEW,
    description: 'Allows users to delete projects',
    with_access_scope: true,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.PROJECT_CAN_DELETE,
    related_key: [],
  },

  project_can_change_code: {
    name: 'Change Project ID',
    control_type: UniteType.FIRST_VIEW,
    description: 'Enables users to edit project ID',
    with_access_scope: true,
    with_access_checkbox: true,
    permission_key: IKey.PROJECT_CAN_CHANGE_CODE,
    related_key: [],
  },

  project_can_edit_contract: {
    name: 'Edit Contract',
    control_type: UniteType.FIRST_VIEW,
    description: 'Allows users to make changes to projects in Contract status',
    with_access_scope: true,
    with_access_checkbox: true,
    permission_key: IKey.PROJECT_CAN_EDIT_CONTRACT,
    related_key: [],
  },

  project_can_delete_progress_entry: {
    control_type: UniteType.THIRD_VIEW,
    name: 'Completion Progress',
    description:
      '"Completion Progress" indicating the extent of completion or progress in a task or project.',
    with_access_scope: false,
    with_access_checkbox: false,
    permission_key: IKey.PROJECT_CAN_DELETE_PROGRESS_ENTRY,
    children: [
      {
        name: 'Delete Item Progress Entry',
        description: 'Allows users to delete progress entry',
        with_access_scope: true,
        with_access_checkbox: true,
        permission_key: IKey.PROJECT_CAN_DELETE_PROGRESS_ENTRY,
        custom_access_scope: [
          {
            key: 1,
            label: 'All Entry ',
            customLabel: true,
            activeColor: '#adf7b6',
            color: 'white',
            disabled: false,
          },
          {
            key: 2,
            label: 'Own Entry ',
            customLabel: true,
            color: 'white',
            activeColor: '#adf7b6',
            disabled: false,
          },
        ],
      },
      {
        name: 'View Users in Progress Entry List ',
        description:
          'Allows to view all company users in tech list for progress entry',
        with_access_scope: true,
        with_access_checkbox: true,
        permission_key: IKey.PROJECT_CAN_READ_TECH_LIST_FOR_PROGRESS,
        custom_access_scope: [
          {
            key: 1,
            label: 'All ',
            activeColor: '#adf7b6',
            color: 'white',
            disabled: false,
          },
          {
            key: 2,
            label: 'Assigned to column ',
            color: 'white',
            activeColor: '#adf7b6',
            disabled: false,
          },
        ],
      },
    ],
  },

  project_can_read_price: {
    name: 'Display Price',
    control_type: UniteType.THIRD_VIEW,
    description: `Grants access to view project prices. Turning off this setting will affect users' access to project insights and their ability to send projects via email and text`,
    with_access_scope: true,
    with_access_checkbox: true,
    permission_key: IKey.PROJECT_CAN_READ_PRICE,
    related_key: [IKey.PROJECT_CAN_READ_COST],
    children: [
      {
        name: 'Show Cost & Gross Margin',
        permission_key: IKey.PROJECT_CAN_READ_COST,
        parent_permission_key: IKey.PROJECT_CAN_READ_PRICE,
        with_access_scope: false,
        with_access_checkbox: true,
        description: 'Gives the ability to view item cost and gross margin',
      },
    ],
  },

  project_can_change_status_from_estimate_to_contract: {
    name: 'Ability to Change Project Status',
    control_type: UniteType.THIRD_VIEW,
    description:
      'Allows changing the status of a project (e.g., from Estimate to Contract, etc.)',
    with_access_scope: false,
    with_access_checkbox: false,
    permission_key: IKey.PROJECT_CAN_CHANGE_STATUS_FROM_ESTIMATE_TO_CONTRACT,
    related_key: [
      IKey.PROJECT_CAN_CHANGE_STATUS_FROM_ESTIMATE_TO_CONTRACT,
      IKey.PROJECT_CAN_CHANGE_STATUS_FROM_CONTRACT_TO_COMPLETED,
      IKey.PROJECT_CAN_CHANGE_STATUS_FROM_COMPLETED_TO_CONTRACT,
      IKey.PROJECT_CAN_CHANGE_STATUS_FROM_CONTRACT_TO_ESTIMATE,
      IKey.PROJECT_CAN_CHANGE_STATUS_FROM_CONTRACT_TO_CANCELED,
    ],
    children: [
      {
        name: 'from Estimate to Contract',
        permission_key:
          IKey.PROJECT_CAN_CHANGE_STATUS_FROM_ESTIMATE_TO_CONTRACT,

        with_access_scope: true,
        with_access_checkbox: true,
      },
      {
        name: 'from Contract to Completed',
        permission_key:
          IKey.PROJECT_CAN_CHANGE_STATUS_FROM_CONTRACT_TO_COMPLETED,
        with_access_scope: true,
        with_access_checkbox: true,
      },
      {
        name: 'from Completed to Contract',
        permission_key:
          IKey.PROJECT_CAN_CHANGE_STATUS_FROM_COMPLETED_TO_CONTRACT,
        with_access_scope: true,
        with_access_checkbox: true,
      },
      {
        name: 'from Contract to Estimate',
        permission_key:
          IKey.PROJECT_CAN_CHANGE_STATUS_FROM_CONTRACT_TO_ESTIMATE,
        with_access_scope: true,
        with_access_checkbox: true,
      },
      {
        name: 'from Contract to Canceled',
        permission_key:
          IKey.PROJECT_CAN_CHANGE_STATUS_FROM_CONTRACT_TO_CANCELED,
        with_access_scope: true,
        with_access_checkbox: true,
      },
    ],
  },
  project_payment_module_can_change_status: {
    name: 'Progress Modules',
    control_type: UniteType.THIRD_VIEW,
    description:
      'Allows changing the status of  project modules (e.g., Payment module, etc.)',
    with_access_scope: false,
    with_access_checkbox: false,
    permission_key: IKey.PROJECT_PAYMENT_MODULE_CAN_CHANGE_STATUS,
    related_key: [
      IKey.PROJECT_PAYMENT_MODULE_CAN_CHANGE_STATUS,
      IKey.PROJECT_SUPPLY_MODULE_CAN_CHANGE_STATUS,
      IKey.PROJECT_CUSTOM_MODULE_1_CAN_CHANGE_STATUS,
      IKey.PROJECT_CUSTOM_MODULE_3_CAN_CHANGE_STATUS,
    ],
    children: [
      {
        name: 'Edit Payment Module',
        permission_key: IKey.PROJECT_PAYMENT_MODULE_CAN_CHANGE_STATUS,
        with_access_scope: true,
        with_access_checkbox: true,
      },
      {
        name: 'Edit Supplies Module',
        permission_key: IKey.PROJECT_SUPPLY_MODULE_CAN_CHANGE_STATUS,
        with_access_scope: true,
        with_access_checkbox: true,
      },
      {
        name: 'Edit Custom Module 1',
        permission_key: IKey.PROJECT_CUSTOM_MODULE_1_CAN_CHANGE_STATUS,
        with_access_scope: true,
        with_access_checkbox: true,
      },
      {
        name: 'Edit Custom Module 2',
        permission_key: IKey.PROJECT_CUSTOM_MODULE_2_CAN_CHANGE_STATUS,
        with_access_scope: true,
        with_access_checkbox: true,
      },
      {
        name: 'Edit Custom Module 3',
        permission_key: IKey.PROJECT_CUSTOM_MODULE_3_CAN_CHANGE_STATUS,
        with_access_scope: true,
        with_access_checkbox: true,
      },
    ],
  },

  project_can_create_delete_unit_qty: {
    name: 'Items',
    control_type: UniteType.THIRD_VIEW,
    permission_key: IKey.PROJECT_CAN_CREATE_DELETE_UNIT_QTY,
    description: 'Access to manage project items and quantities',
    with_access_scope: false,
    with_access_checkbox: false,
    related_key: [],
    children: [
      {
        name: 'Manage Item Unit',
        permission_key: IKey.PROJECT_CAN_CREATE_DELETE_UNIT_QTY,
        with_access_scope: false,
        with_access_checkbox: true,
        description: 'Allows to add/delete item unit of measure',
      },
      {
        name: 'View Item Description',
        permission_key: IKey.PROJECT_CAN_VIEW_ITEM_DESCRIPTON,
        with_access_scope: false,
        with_access_checkbox: true,
        description: 'Allows to to view item description',
      },
    ],
  },

  project_insights_can_read: {
    name: 'Project Insights',
    control_type: UniteType.THIRD_VIEW,
    permission_key: IKey.PROJECT_INSIGHTS_CAN_READ,
    description: 'Accesses insights related to a project',
    with_access_scope: false,
    with_access_checkbox: false,

    children: [
      {
        name: 'View Project Insights',
        permission_key: IKey.PROJECT_INSIGHTS_CAN_READ,
        with_access_scope: true,
        with_access_checkbox: true,
        related_key: [
          IKey.PROJECT_INSIGHTS_CAN_VIEW_EXPENSES,
          IKey.PROJECT_INSIGHTS_CAN_ADD_EXPENSES,
          IKey.PROJECT_INSIGHTS_CAN_EDIT_EXPENSES,
          IKey.PROJECT_INSIGHTS_CAN_DELETE_EXPENSES,
          IKey.PROJECT_INSIGHTS_CAN_READ_PAYOUTS,
          IKey.PROJECT_INSIGHTS_CAN_ADD_PAYOUTS,
          IKey.PROJECT_INSIGHTS_CAN_DELETE_PAYOUTS,
          IKey.PROJECT_INSIGHTS_CAN_EDIT_TIPS,
          IKey.PROJECT_INSIGHTS_CAN_CHANGE_LABOR_RATES,
          IKey.PROJECT_INSIGHTS_ITEMS_CAN_ADD,
          IKey.PROJECT_INSIGHTS_ITEMS_CAN_EDIT,
          IKey.PROJECT_INSIGHTS_ITEMS_CAN_DELETE,
          IKey.PROJECT_INSIGHTS_ITEMS_SUGGESTIONS_CAN_ADD,
          IKey.PROJECT_INSIGHTS_ITEMS_SUGGESTIONS_CAN_DELETE,
          IKey.PROJECT_INSIGHTS_CAN_EDIT_PAYOUTS,
        ],
      },

      {
        name: 'Expenses Items',
        with_access_scope: false,
        with_access_checkbox: false,
        permission_key: IKey.PROJECT_INSIGHTS_CAN_READ,
        description:
          'Manages ability to edit expenses items in Project Insights',
        children: [
          {
            name: 'Add Expenses',
            permission_key: IKey.PROJECT_INSIGHTS_ITEMS_CAN_ADD,
            with_access_scope: true,
            with_access_checkbox: true,
            parent_permission_key: IKey.PROJECT_INSIGHTS_CAN_READ,
          },
          {
            name: 'Edit Expenses',
            permission_key: IKey.PROJECT_INSIGHTS_ITEMS_CAN_EDIT,
            with_access_scope: true,
            with_access_checkbox: true,
            parent_permission_key: IKey.PROJECT_INSIGHTS_CAN_READ,
          },
          {
            name: 'Delete Expenses',
            permission_key: IKey.PROJECT_INSIGHTS_ITEMS_CAN_DELETE,
            with_access_scope: true,
            with_access_checkbox: true,
            parent_permission_key: IKey.PROJECT_INSIGHTS_CAN_READ,
          },
        ],
      },
      {
        name: 'Payouts',
        with_access_scope: false,
        with_access_checkbox: false,
        permission_key: IKey.PROJECT_INSIGHTS_CAN_READ_PAYOUTS,
        description: 'Allows to view and edit payouts',
        children: [
          {
            name: 'View Payouts',
            permission_key: IKey.PROJECT_INSIGHTS_CAN_READ_PAYOUTS,
            with_access_scope: true,
            with_access_checkbox: true,
            parent_permission_key: IKey.PROJECT_INSIGHTS_CAN_READ,
          },
          {
            name: 'Add Payouts',
            permission_key: IKey.PROJECT_INSIGHTS_CAN_ADD_PAYOUTS,
            with_access_scope: true,
            with_access_checkbox: true,
            parent_permission_key: IKey.PROJECT_INSIGHTS_CAN_READ,
          },
          {
            name: 'Edit Payouts',
            permission_key: IKey.PROJECT_INSIGHTS_CAN_EDIT_PAYOUTS,
            with_access_scope: true,
            with_access_checkbox: true,
            parent_permission_key: IKey.PROJECT_INSIGHTS_CAN_READ,
          },
          {
            name: 'Delete Payouts',
            permission_key: IKey.PROJECT_INSIGHTS_CAN_DELETE_PAYOUTS,
            with_access_scope: true,
            with_access_checkbox: true,
            parent_permission_key: IKey.PROJECT_INSIGHTS_CAN_READ,
          },
        ],
      },

      {
        name: 'Ability to Create New Expense Types',
        permission_key: IKey.PROJECT_INSIGHTS_ITEMS_SUGGESTIONS_CAN_ADD,
        with_access_scope: false,
        with_access_checkbox: true,
        parent_permission_key: IKey.PROJECT_INSIGHTS_CAN_READ,
      },
      {
        name: 'Ability to Delete Expense Types',
        permission_key: IKey.PROJECT_INSIGHTS_ITEMS_SUGGESTIONS_CAN_DELETE,
        with_access_scope: false,
        with_access_checkbox: true,
        parent_permission_key: IKey.PROJECT_INSIGHTS_CAN_READ,
      },
    ],
  },

  project_settings_can_read: {
    name: 'Project Settings',
    permission_key: IKey.PROJECT_SETTINGS_CAN_READ,
    control_type: UniteType.THIRD_VIEW,
    description: 'Accesses and edits project settings',
    with_access_scope: false,
    with_access_checkbox: false,

    children: [
      {
        name: 'View Project Settings',
        permission_key: IKey.PROJECT_SETTINGS_CAN_READ,
        with_access_scope: true,
        with_access_checkbox: true,
        related_key: [
          IKey.PROJECT_SETTINGS_CAN_EDIT_GENERAL,
          // IKey.PROJECT_SETTINGS_CAN_EDIT_SECTION,
          IKey.PROJECT_SETTINGS_CAN_EDIT_PAYMENT,
          IKey.PROJECT_SETTINGS_CAN_EDIT_RELATIONS,
          IKey.PROJECT_SETTINGS_CAN_EDIT_CUSTOMER_VIEW,
        ],
      },
      {
        name: 'General',
        with_access_scope: false,
        with_access_checkbox: false,
        permission_key: IKey.PROJECT_SETTINGS_CAN_EDIT_GENERAL,
        children: [
          {
            name: 'Edit General Project Settings',
            permission_key: IKey.PROJECT_SETTINGS_CAN_EDIT_GENERAL,
            with_access_scope: true,
            with_access_checkbox: true,
            parent_permission_key: IKey.PROJECT_SETTINGS_CAN_READ,
          },
        ],
      },
      // {
      //   name: 'Project Section Settings',
      //   with_access_scope: false,
      //   with_access_checkbox: false,
      //   permission_key: IKey.PROJECT_SETTINGS_CAN_EDIT_SECTION,
      //   children: [
      //     {
      //       name: 'Edit Project Section Settings',
      //       permission_key: IKey.PROJECT_SETTINGS_CAN_EDIT_SECTION,
      //       with_access_scope: true,
      //       with_access_checkbox: true,
      //       parent_permission_key: IKey.PROJECT_SETTINGS_CAN_READ,
      //     },
      //   ],
      // },
      {
        name: 'Payment Settings',
        with_access_scope: false,
        with_access_checkbox: false,
        permission_key: IKey.PROJECT_SETTINGS_CAN_EDIT_PAYMENT,
        children: [
          {
            name: 'Edit Project Payment Settings',
            permission_key: IKey.PROJECT_SETTINGS_CAN_EDIT_PAYMENT,
            with_access_scope: true,
            with_access_checkbox: true,
            parent_permission_key: IKey.PROJECT_SETTINGS_CAN_READ,
          },
        ],
      },
      {
        name: 'Project Details',
        permission_key: IKey.PROJECT_SETTINGS_CAN_EDIT_RELATIONS,
        children: [
          {
            name: 'Edit Project Details',
            permission_key: IKey.PROJECT_SETTINGS_CAN_EDIT_RELATIONS,
            with_access_scope: true,
            with_access_checkbox: true,
            parent_permission_key: IKey.PROJECT_SETTINGS_CAN_READ,
          },
        ],
      },
      {
        name: 'Customer View Settings',
        permission_key: IKey.PROJECT_SETTINGS_CAN_EDIT_CUSTOMER_VIEW,
        children: [
          {
            name: 'Edit Customer View Settings',
            permission_key: IKey.PROJECT_SETTINGS_CAN_EDIT_CUSTOMER_VIEW,
            with_access_scope: true,
            with_access_checkbox: true,
            parent_permission_key: IKey.PROJECT_SETTINGS_CAN_READ,
          },
        ],
      },
    ],
  },

  project_can_read_templates: {
    name: 'Project Templates ',
    control_type: UniteType.THIRD_VIEW,
    permission_key: IKey.PROJECT_CAN_READ_TEMPLATES,
    description:
      'Deals with project templates, including creation, editing, and deletion',
    with_access_scope: false,
    with_access_checkbox: false,

    children: [
      {
        name: 'Save Project as Template',
        permission_key: IKey.PROJECT_CAN_CREATE_TEMPLATE,
        with_access_scope: false,
        with_access_checkbox: true,
      },
      {
        name: 'View List of Project Templates',
        permission_key: IKey.PROJECT_CAN_READ_TEMPLATES,
        with_access_scope: true,
        with_access_checkbox: true,
        related_key: [
          IKey.PROJECT_CAN_EDIT_TEMPLATE,
          IKey.PROJECT_CAN_DELETE_TEMPLATE,
        ],
        custom_access_scope: [
          {
            key: 1,
            label: 'All Templates',
            customLabel: true,
            activeColor: '#adf7b6',
            color: 'white',
            // disabled: false,
          },
          {
            key: 2,
            label: 'Own + Shared Templates',
            customLabel: true,
            color: 'white',
            activeColor: '#adf7b6',
            // disabled: false,
          },
        ],
        children: [
          {
            name: 'Edit Project Template Name',
            permission_key: IKey.PROJECT_CAN_EDIT_TEMPLATE,
            parent_permission_key: IKey.PROJECT_CAN_READ_TEMPLATES,
            with_access_scope: true,
            with_access_checkbox: true,
            custom_access_scope: [
              {
                key: 1,
                label: 'All Templates',
                customLabel: true,
                activeColor: '#adf7b6',
                color: 'white',
                // disabled: false,
              },
              {
                key: 2,
                label: 'Own Templates',
                customLabel: true,
                color: 'white',
                activeColor: '#adf7b6',
                // disabled: false,
              },
            ],
          },
          {
            name: 'Delete Project Template',
            permission_key: IKey.PROJECT_CAN_DELETE_TEMPLATE,
            parent_permission_key: IKey.PROJECT_CAN_READ_TEMPLATES,
            with_access_scope: true,
            with_access_checkbox: true,
            custom_access_scope: [
              {
                key: 1,
                label: 'All Templates',
                customLabel: true,
                activeColor: '#adf7b6',
                color: 'white',
                // disabled: false,
              },
              {
                key: 2,
                label: 'Own Templates',
                customLabel: true,
                color: 'white',
                activeColor: '#adf7b6',
                // disabled: false,
              },
            ],
          },
        ],
      },
    ],
  },

  project_can_read_info_for_workers: {
    name: 'Show Info for workers',
    control_type: UniteType.THIRD_VIEW,
    description: 'Ability to see information for workers',
    with_access_scope: false,
    with_access_checkbox: true,
    permission_key: IKey.PROJECT_CAN_READ_INFO_FOR_WORKERS,
    related_key: [
      IKey.PROJECT_CAN_EDIT_INFO_FOR_WORKERS,
      IKey.PROJECT_CAN_DELETE_INFO_FOR_WORKERS,
    ],
    children: [
      {
        name: 'Edit Info for workers',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.PROJECT_CAN_EDIT_INFO_FOR_WORKERS,
        parent_permission_key: IKey.PROJECT_CAN_READ_INFO_FOR_WORKERS,
      },
      {
        name: 'Delete Files for workers',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.PROJECT_CAN_DELETE_INFO_FOR_WORKERS,
        parent_permission_key: IKey.PROJECT_CAN_READ_INFO_FOR_WORKERS,
      },
    ],
  },

  notes_can_read: {
    name: 'View Notes',
    with_access_scope: false,
    with_access_checkbox: true,
    control_type: UniteType.THIRD_VIEW,
    permission_key: NotesPermissions.NOTES_CAN_READ,
    parent_permission_key: NotesPermissions.NOTES_CAN_READ,
    description: '',
    is_module: false,
    related_key: [
      NotesPermissions.NOTES_CAN_CREATE,
      NotesPermissions.NOTES_CAN_DELETE,
      NotesPermissions.NOTES_CAN_EDIT,
    ],
    related_modules: ModuleName.NOTES,
    children: [
      {
        name: 'Create a New Note',
        with_access_scope: false,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: NotesPermissions.NOTES_CAN_CREATE,
        parent_permission_key: NotesPermissions.NOTES_CAN_READ,
        description: '',
        related_key: [],
      },
      {
        name: 'Edit Note',
        with_access_scope: true,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: NotesPermissions.NOTES_CAN_EDIT,
        parent_permission_key: NotesPermissions.NOTES_CAN_READ,
        description: '',
        related_key: [],
        custom_access_scope: [
          {
            key: 1,
            label: 'All Notes  ',
            activeColor: '#adf7b6',
            color: 'white',
            disabled: false,
          },
          {
            key: 2,
            label: 'Own Notes ',
            color: 'white',
            activeColor: '#adf7b6',
            disabled: false,
          },
        ],
      },
      {
        name: 'Delete Note',
        with_access_scope: true,
        with_access_checkbox: true,
        related_modules: ModuleName.NOTES,
        permission_key: NotesPermissions.NOTES_CAN_DELETE,
        parent_permission_key: NotesPermissions.NOTES_CAN_READ,
        description: '',
        related_key: [],
        custom_access_scope: [
          {
            key: 1,
            label: 'All Notes ',
            activeColor: '#adf7b6',
            color: 'white',
            disabled: false,
          },
          {
            key: 2,
            label: 'Own Notes ',
            color: 'white',
            activeColor: '#adf7b6',
            disabled: false,
          },
        ],
      },
    ],
  },

  project_can_read_custom_form: {
    name: 'Show Custom Forms',
    control_type: UniteType.THIRD_VIEW,
    description: 'Ability to use custom form module',
    with_access_scope: false,
    with_access_checkbox: true,
    permission_key: IKey.PROJECT_CAN_READ_CUSTOM_FORMS,
    related_key: [
      IKey.PROJECT_CAN_CREATE_CUSTOM_FORMS,
      IKey.PROJECT_CAN_EDIT_CUSTOM_FORMS,
      IKey.PROJECT_CAN_DELETE_CUSTOM_FORMS,
    ],
    // related_modules: ModuleName.FORMS,
    // main_key: FormsPermissions.FORMS_LIST_CAN_READ,
    children: [
      {
        name: 'Create Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.PROJECT_CAN_CREATE_CUSTOM_FORMS,
        parent_permission_key: IKey.PROJECT_CAN_READ_CUSTOM_FORMS,
      },
      {
        name: 'Edit Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.PROJECT_CAN_EDIT_CUSTOM_FORMS,
        parent_permission_key: IKey.PROJECT_CAN_READ_CUSTOM_FORMS,
      },
      {
        name: 'Delete Custom Forms',
        with_access_scope: false,
        with_access_checkbox: true,
        control_type: UniteType.FIRST_VIEW,
        permission_key: IKey.PROJECT_CAN_DELETE_CUSTOM_FORMS,
        parent_permission_key: IKey.PROJECT_CAN_READ_CUSTOM_FORMS,
      },
    ],
  },
}
