import React from 'react'
import NoRights from '../pages/NoRights/index'
import { Route } from 'react-router-dom'

export default function PrivateRoute({ component: Component, ...routerProps }) {
  return (
    <Route
      {...routerProps}
      render={props => (routerProps.isAccess ? <Component {...props} {...routerProps} /> : <NoRights {...props} />)}
    />
  )
}
