import styled from 'styled-components'

export const Wrapper = styled.div``
export const Content = styled.div`
  & > * {
    margin-bottom: 12px;
  }
`
export const Image = styled.div`
  width: 100%;
  height: 150px;
  border: 1px solid #e6e8ec;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  position: relative;
  top: 3px;
`
export const Data = styled.div`
  background-color: #fff;
  border: 1px solid #e6e8ec;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 20px;
`
export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 16px;
`
export const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
`

export const Button = styled.p`
  color: #1890ff;
  cursor: pointer;
  font-weight: 500;
`

export const Address = styled.p`
  padding: 4px 8px;
  background-color: #000000b3;
  font-size: 12px;
  color: #fff;
  position: absolute;
  bottom: -2px;
  left: 0;
  border-top-right-radius: 6px;
`

export const StyledIframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
  border-top-right-radius: '4px';
  border-top-left-radius: '4px';
`
