import { Button, Popover } from 'antd'
import { InfoPlate, Password, TextField } from 'components/UIKit'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'
import PasswordChecklist from 'react-password-checklist'
import {
  CheckOutlined,
  CloseOutlined,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { AuthAPI } from '../api'
import useQuery from 'hooks/useQuery'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

const emailValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email address')
    .nullable()
    .required('Email is required')
    .label('Email'),
})

const passwordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .max(32, 'Password cannot exceed more than 32 characters')
    .matches(
      /.*\p{Ll}.*/u,
      'Password must contain at least 1 lower case letter',
    )
    .matches(
      /.*\p{Lu}.*/u,
      'Password must contain at least 1 upper case letter',
    )
    // .matches(
    //   /[!@#$%^&*(),.?":{}|<>]/,
    //   'Password must contain at least 1 special character',
    // )
    .matches(/.*\d.*/, 'Password must contain at least 1 number'),

  password_confirmation: yup
    .string()
    .required('Confirm Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .max(32, 'Password cannot exceed more than 32 characters')
    .oneOf([yup.ref('password')], 'Passwords do not match'),
})

export const ForgotPassword = () => {
  const history = useHistory()
  const searchParams = useQuery()
  const token = searchParams.get('token')
  const queryEmail = searchParams.get('email')

  const [email, setEmail] = useState('')
  const [passwordValues, setPasswordValues] = useState({
    password: '',
    password_confirmation: '',
  })
  const [error, setError] = useState<Record<string, string> | null>(null)
  const [loading, setLoading] = useState(false)
  const [requestSent, setRequestSent] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [tokenExpired, setTokenExpired] = useState(false)

  useEffect(() => {
    const verifyToken = async () => {
      try {
        await AuthAPI.validateResetPasswordToken({
          token,
          email: queryEmail,
        })
      } catch (error) {
        openNotificationWithIcon('error', {
          message: 'This link is expired or had been used',
        })
        setTokenExpired(true)
      }
    }

    if (token) {
      verifyToken()
    }
  }, [token])

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError(null)

    setEmail(e.target.value)
  }

  const handleFormSend = async () => {
    setLoading(true)

    try {
      await emailValidationSchema.validate({ email })

      await AuthAPI.forgotPassword(email)
      setRequestSent(true)
    } catch (error) {
      setError(
        error?.errors
          ? { [error.path]: error.errors }
          : { email: error?.response?.data.message } || {
              email: 'Something went wrong',
            },
      )
    } finally {
      setLoading(false)
    }
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    if (error) setError(null)

    setPasswordValues(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handlePasswordSubmit = async () => {
    setLoading(true)

    try {
      await passwordValidationSchema.validate({
        password: passwordValues.password,
        password_confirmation: passwordValues.password_confirmation,
      })

      await AuthAPI.resetPassword({
        email: queryEmail,
        token: token,
        password: passwordValues.password,
        password_confirmation: passwordValues.password_confirmation,
      })
      setPasswordChanged(true)

      openNotificationWithIcon('success', {
        message: 'Password successfully changed',
      })
    } catch (error) {
      setError(
        error?.errors
          ? { [error.path]: error.errors }
          : { password: error?.response?.data.message } || {
              password: 'Something went wrong',
            },
      )
    } finally {
      setLoading(false)
    }
  }

  const handleEmailResend = async () => {
    if (loading) return

    setLoading(true)
    try {
      await AuthAPI.resendVerificationEmail({ email })

      history.push('/auth/confirm-email')
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const renderContent = () =>
    tokenExpired ? (
      <Content>
        <p style={{ textAlign: 'center' }}>
          This link is expired or had been used
        </p>
      </Content>
    ) : token ? (
      <>
        <Content>
          Please enter your new password
          <Form>
            <Popover
              placement='right'
              content={
                <PasswordChecklistStyled
                  rules={[
                    'minLength',
                    'lowercase',
                    'capital',
                    'number',
                    // 'specialChar',
                    // 'match',
                  ]}
                  minLength={6}
                  value={passwordValues.password}
                  valueAgain={passwordValues.password_confirmation}
                  messages={{
                    minLength: 'Length 6 - 32 symbols',
                    lowercase: 'Should include at least 1 lowercase',
                    capital: 'Should include at least 1 uppercase',
                    number: 'Should include at least 1 number',
                    // specialChar: 'Special symbol',
                  }}
                  // onChange={(a, b) => console.log(a, b)}
                  iconComponents={{
                    InvalidIcon: <CloseOutlined style={{ fontSize: 24 }} />,
                    ValidIcon: <CheckOutlined style={{ fontSize: 24 }} />,
                  }}
                />
              }
              trigger='focus'
            >
              <Password
                name='password'
                value={passwordValues.password}
                onChange={handlePasswordChange}
                label='Password'
                required
                error={error?.password}
                autoComplete='new-password'
              />
            </Popover>

            <Password
              name='password_confirmation'
              value={passwordValues.password_confirmation}
              onChange={handlePasswordChange}
              label='Confirm password'
              required
              error={error?.password_confirmation}
              autoComplete='off'
            />

            <Button
              block
              type='primary'
              onClick={handlePasswordSubmit}
              size='large'
              style={{ marginTop: 30 }}
              loading={loading}
              disabled={passwordChanged}
            >
              {passwordChanged
                ? 'Password successfully changed'
                : 'Send Request'}
            </Button>
          </Form>
        </Content>
      </>
    ) : (
      <>
        <Heading>Forgot your password?</Heading>

        {!!email &&
          error?.email === 'Please verify your email address to log in' && (
            <NotVerified>
              <InfoPlate type='warning'>
                Please verify your email to perform this action. Click{' '}
                <LinkText onClick={handleEmailResend}>here</LinkText> to resend
                the verification email.
              </InfoPlate>
            </NotVerified>
          )}

        <Content>
          Please enter your email address so we can send you a password reset
          link
          <Form>
            <TextField
              label='Email'
              required
              value={email}
              onChange={handleEmailChange}
              error={error?.email}
            />

            <Button
              block
              type='primary'
              onClick={handleFormSend}
              size='large'
              style={{ marginTop: 30 }}
              loading={loading}
              disabled={requestSent}
            >
              {requestSent ? 'Request has been sent' : 'Send Request'}
            </Button>
          </Form>
        </Content>
      </>
    )

  return (
    <Wrapper>
      {renderContent()}
      <Signin>
        <Link to='/auth/signin'>Back to Sign In</Link>
      </Signin>

      <Policy>
        This site is subject to{' '}
        <Link
          to={{
            pathname: 'https://orcatec.com/privacy_policy.pdf',
          }}
          target='_blank'
          rel='noopener noreferrer'
        >
          Privacy Policy
        </Link>{' '}
        and{' '}
        <Link
          to={{
            pathname: 'https://orcatec.com/terms_and_conditions.pdf',
          }}
          target='_blank'
          rel='noopener noreferrer'
        >
          Terms of use
        </Link>
      </Policy>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 450px;
  padding: 48px;
  border: 2px solid #165ed033;
  border-radius: 10px;
  background-color: #fff;

  @media (max-width: 450px) {
    width: 375px;
    padding: 16px;
  }
`
const Heading = styled.h4`
  margin-bottom: 1.2rem;
  /* margin-top: 1.5rem; */
`

const Content = styled.div``
const Form = styled.div`
  margin-top: 20px;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`

const Signin = styled.p`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
  color: #4d4d4d;
`

const PasswordChecklistStyled = styled(PasswordChecklist)`
  li {
    gap: 10px;
    align-items: center !important;
    margin-bottom: 8px !important;
  }
`

const NotVerified = styled.div`
  margin-bottom: 24px;
`

const LinkText = styled.span`
  color: #206fed;
  cursor: pointer;
  text-decoration: underline;
`

const Policy = styled.p`
  margin-top: 32px;
  color: #4d4d4d;

  a {
    font-weight: 500;
  }
`
