import React from 'react'
import {
  LocationIcon,
  OrganizationsIcon,
  UserIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { EntityType } from 'features/Project/types'
import { ClientType } from 'types/Client'

export const renderIcon = (entity_type: EntityType, type: ClientType) => {
  if (entity_type === EntityType.PROPERTY) return <LocationIcon />
  if (entity_type === EntityType.CLIENT) {
    if (type === ClientType.ORGANIZATION) {
      return <OrganizationsIcon />
    } else return <UserIcon />
  }
}
