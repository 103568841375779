import './TabStatus.scss'
import getStatus from 'containers/MainContent/Orcatec/Proposals/helpers/getTabStatus'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  selectProject,
  selectProjectActiveTab,
} from 'features/Project/projectSelectors'

const TabStatus = () => {
  const project = useAppSelector(selectProject)
  const currentTab = useAppSelector(selectProjectActiveTab)

  return (
    <div className='proposal-form-tabs_content-tab_status'>
      {getStatus(project, currentTab)}
    </div>
  )
}

export default TabStatus
