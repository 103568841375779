import TabActionButtonsBlock from './TabActionButtonsBlock'
import { connect } from 'react-redux'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectProjectSlice } from 'features/Project/slices/projectSlice'

const defaultAction = e => {
  e.preventDefault()
  e.stopPropagation()
}

const TabActionButtonsBlockContainer = ({
  //from redux
  // tabsQuantity = 0,
  // currentTabIdx = 0,
  //
  isTabContract,
  // onChangeTab = defaultAction,
  onMove,
  isTabLoading = false,
  onEditTabInfo = defaultAction,
  onCopyTab = defaultAction,
  onDeleteTab = defaultAction,
  isTabCopying = false,
  isTabDeleting = false,
  buttonsType = '',
  proposalStatus = 1,
  openEquipmentModal = defaultAction,
  openPaymentModal = defaultAction,
  openModuleOneModal = defaultAction,
  openModuleTwoModal = defaultAction,
  openModuleThreeModal = defaultAction,
  modulesData,
  equipmentModuleStatus,
}) => {
  const { project } = useAppSelector(selectProjectSlice)

  const { activeTab: activeTabIdx, tabs_info } = project

  const isLastTab = tabs_info?.length - 1 === activeTabIdx
  const isFirstTab = activeTabIdx === 0
  const isEnoughTabs = tabs_info?.length >= 5

  // const nextIdx = !isLastTab ? activeTabIdx + 1 : activeTabIdx
  // const prevIdx = !isFirstTab ? activeTabIdx - 1 : activeTabIdx

  // const onMoveRight = () => onChangeTab(nextIdx, tabs_info[activeTabIdx + 1].id)
  // const onMoveLeft = () => onChangeTab(prevIdx, tabs_info[activeTabIdx - 1].id)

  const onMoveRight = () => onMove?.(activeTabIdx + 1, activeTabIdx)
  const onMoveLeft = () => onMove?.(activeTabIdx - 1, activeTabIdx)

  const onEdit = () => onEditTabInfo(activeTabIdx)
  const onCopy = () => onCopyTab(activeTabIdx)
  const onDelete = () => onDeleteTab(activeTabIdx)

  const openEquipment = () => openEquipmentModal()
  const openPayment = () => openPaymentModal()
  const openModuleOne = () => openModuleOneModal()
  const openModuleTwo = () => openModuleTwoModal()
  const openModuleThree = () => openModuleThreeModal()

  return (
    <TabActionButtonsBlock
      right={onMoveRight}
      left={onMoveLeft}
      edit={onEdit}
      copy={onCopy}
      delete={onDelete}
      equipment={openEquipment}
      payment={openPayment}
      moduleOne={openModuleOne}
      moduleTwo={openModuleTwo}
      moduleThree={openModuleThree}
      isFirstTab={isFirstTab}
      isLastTab={isLastTab}
      isTabLoading={isTabLoading}
      isTabCopying={isTabCopying}
      isTabDeleting={isTabDeleting}
      buttonsType={buttonsType}
      modulesData={modulesData}
      proposalStatus={proposalStatus}
      equipmentModuleStatus={equipmentModuleStatus}
      isTabContract={isTabContract}
      isEnoughTabs={isEnoughTabs}
    />
  )
}

const mapStateToProps = state => ({
  proposalStatus: state.orcatec.projectSlice.project.status,
  // currentTabIdx: state.orcatec.proposalForm.activeTab,
  // tabsQuantity: state.orcatec.proposalForm.tabs_info?.length,
  equipmentModuleStatus:
    state.orcatec.subscription?.modules?.equipment_order?.status,
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TabActionButtonsBlockContainer)
