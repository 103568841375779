import { Company } from 'features/Settings/Subscription/types'
import { AppStateType } from '../../index'

import { State } from 'features/Settings/Dispatch/components/DictionaryTab/DictionaryTab'

export const selectCompany = (state: AppStateType): Company =>
  state.orcatec.company

export const sourcesForTableSelector = (state: AppStateType) =>
  state.orcatec?.company?.source
    ?.filter(s => s?.id !== null && !s?.deleted_at && s?.name !== '-')
    ?.map(s => ({ value: s.id, text: s.name }))
export const typesForTableSelector = (state: AppStateType) =>
  state.orcatec?.company?.types
    ?.filter(s => s?.id !== null && !s?.deleted_at && s?.name !== '-')
    ?.map(s => ({ value: s.id, text: s.name }))

export const selectCompanySources = (state: AppStateType) =>
  state.orcatec.company.source
export const selectCompanyTypes = (state: AppStateType) =>
  state.orcatec.company.types

export const selectCompanyTimezone = (state: AppStateType) =>
  state.orcatec.company.timezone || 'America/Los_Angeles'

export const selectCompanyDictionary = (state: AppStateType) =>
  state.orcatec.company.dictionary

export const selectJobDictionary = (state: AppStateType): State['job'] =>
  state.orcatec.company.dictionary.job

export const isEmailModuleAvaliable = (state: AppStateType) => {
  return state.orcatec?.company?.is_mail_client_available
}
