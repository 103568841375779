import React, { ReactChild } from 'react'
import { Modal } from 'antd'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'

interface IProps {
  children: ReactChild
  withModal: boolean
  toggleModal: () => void
  showModal: boolean
  modalClassName?: string
  onCancel?: () => void
  onSubmit?: () => void
  title?: string
  btnSubmitTitle?: string
  btnCancelTitle?: string
}
export const WithModal = ({
  children,
  withModal = false,
  toggleModal,
  showModal,
  modalClassName = 'modal__form',
  onCancel,
  onSubmit,
  title = '',
  btnSubmitTitle = 'Create',
  btnCancelTitle = 'Cancel',
}: IProps) => {
  return withModal ? (
    <Modal
      wrapClassName={modalClassName}
      width={'80%'}
      title={title}
      destroyOnClose
      afterClose={onCancel}
      style={{ minHeight: '500px' }}
      footer={
        !onSubmit && !onCancel ? null : (
          <div
            style={{ display: 'flex', gap: '20px', justifyContent: 'flex-end' }}
          >
            {!!onCancel && (
              <MainButton title={btnCancelTitle} onClick={onCancel} />
            )}
            {!!onSubmit && (
              <MainButton title={btnSubmitTitle} onClick={onSubmit} />
            )}
          </div>
        )
      }
      visible={showModal}
      onCancel={toggleModal}
    >
      {children}
    </Modal>
  ) : (
    <>{children}</>
  )
}
