import styled from 'styled-components'

export const Wrapper = styled.div`
  /* display: flex; */
  /* align-items: center; */
  /* gap: 10px; */
`
export const Label = styled.p`
  color: rgba(0, 0, 0, 0.54);
`
