import React, { useEffect, useState } from 'react'
import { Marker } from 'react-leaflet'
import moment from 'moment-timezone'
import L from 'leaflet'
import ReactDOMServer from 'react-dom/server'
import EmployeeTimedMarkerPopap from './../Popaps/EmployeeTimedMarkerPopap/EmployeeTimedMarkerPopap'
import ClockedIcon from '../Icons/ClockedIcon'

const ClockedMarker = ({ lat, lng, type, time, employee, withoutDuration = false, toTime = null }) => {
  const [duration, setDuration] = useState(null)

  useEffect(() => {
    if (!withoutDuration) {
      if (toTime) {
        setDuration(moment(toTime, 'HH:mm:ss').diff(moment(time, 'HH:mm:ss'), 'minutes'))
      } else {
        setDuration(moment().diff(moment(time, 'HH:mm:ss'), 'minutes'))

        const calculateDuration = () => {
          setDuration(moment().diff(moment(time, 'HH:mm:ss'), 'minutes'))
        }

        setInterval(calculateDuration, 60000)

        return () => {
          clearInterval(calculateDuration)
        }
      }
    }
  }, [])

  return (
    <Marker
      position={[Number(lat), Number(lng)]}
      icon={L.divIcon({
        iconSize: null,
        html: ReactDOMServer.renderToString(<ClockedIcon type={type} />),
      })}
      className='redborder'
    >
      <EmployeeTimedMarkerPopap
        className='popaps-without-content'
        type={type}
        time={moment(time, 'HH:mm:ss')
          .format('h:mma')
          .replace('m', '')}
        withoutDuration={withoutDuration}
        duration={duration}
        employee={employee}
      />
    </Marker>
  )
}

export default ClockedMarker
