import { getTodoByUser } from 'api/Todo'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { ToDoEvent } from '../types'

export const useActiveTodo = (ignore?: boolean) => {
  const [todoList, setTodoList] = useState<ToDoEvent[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const data: ToDoEvent[] = await getTodoByUser()

      setTodoList(data?.sort((a, b) => +moment(a.due_date).format('YYYYMMDD') - +moment(b.due_date).format('YYYYMMDD')))
    }

    if (!ignore) fetchData()
  }, [])

  return [todoList]
}
