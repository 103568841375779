// import {  useState } from 'react'
// import { getUnseenCountByAccIdThunk, NAME_IN_LS } from '../emailsSlice'
// import { getValueFromLS } from 'hooks/useLocalStorage'

// import { meSelector } from 'store/SuperAdmin/selectors'
// import { useAppSelector, useAppDispatch } from 'store/Orcatec/hooks'

export const useEmailsCount = (): { count: number } => {
  // const dispatch = useAppDispatch()
  // const me = useAppSelector(meSelector)
  // const account = getValueFromLS(NAME_IN_LS(me.id))

  // const [count, setCount] = useState<number>(0)

  // const accId = account?.accounts?.find(
  //   item => item?.mailbox === account?.account,
  // )?.id

  // useEffect(() => {
  //   const getCount = async (): Promise<void> => {
  //     const res = await dispatch(getUnseenCountByAccIdThunk(accId))

  //     setCount(res.payload.unseen_count)
  //   }

  //   if (accId) getCount()
  // }, [])

  return 0
}
