import React from 'react'
import { Menu, Radio, Dropdown, Checkbox, Divider } from 'antd'
import {
  PropertyIcon,
  OrganizationIcon,
  ClientIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import './AppointmentLocation.scss'

const AppointmentLocation = ({
  entities,
  entityType,
  changeEntityHeandler,
  checkLocation,
  heandleChangeLocation,
  isEmptyEntities,
}) => {
  const menu = entities && (
    <Menu>
      <Divider className='location-title' orientation='left'>
        {entityType === 1 ? 'Select client:' : 'Select property:'}
      </Divider>

      <Radio.Group className='appt-location__radio-group'>
        {entityType === 1 && !!entities?.clients?.length
          ? entities.clients?.map(({ id, type, name }) => (
              <Radio
                onChange={() => changeEntityHeandler(id, type === 1 ? 3 : 2)}
                key={id}
                value={id}
              >
                <Menu.Item
                  className='menu-item'
                  icon={<ClientIcon width={15} height={15} />}
                >
                  <p>{name}</p>
                </Menu.Item>
              </Radio>
            ))
          : null}

        {entityType === 2 && !!entities?.properties?.length
          ? entities.properties?.map(({ id, type, name, full_address }) => (
              <Radio
                onChange={() => changeEntityHeandler(id, 1)}
                key={id}
                value={id}
              >
                <Menu.Item
                  className='menu-item'
                  icon={<PropertyIcon width={15} height={15} />}
                >
                  <p>{full_address}</p>
                </Menu.Item>
              </Radio>
            ))
          : null}
        {entityType === 3 && !!entities?.properties?.length
          ? entities.properties?.map(({ id, type, name, full_address }) => (
              <Radio
                onChange={() => changeEntityHeandler(id, 1)}
                key={id}
                value={id}
              >
                <Menu.Item
                  className='menu-item'
                  icon={<PropertyIcon width={15} height={15} />}
                >
                  <p>{full_address}</p>
                </Menu.Item>
              </Radio>
            ))
          : null}
      </Radio.Group>
    </Menu>
  )
  return (
    <Dropdown disabled={isEmptyEntities} visible={checkLocation} overlay={menu}>
      <Checkbox
        name='entity_type'
        onChange={heandleChangeLocation}
        checked={entityType !== 1 || checkLocation}
      >
        Appointment is going to take place in my office
      </Checkbox>
    </Dropdown>
  )
}

export default AppointmentLocation
