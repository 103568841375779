import React, { useEffect, useState, FC } from 'react'
import { useDispatch } from 'react-redux'
import Autocomplete from 'containers/MainContent/Orcatec/components/Autocomplete'
import { AppointmentSource, ApptSourceProp } from 'types/Appointment'
import { Popconfirm, Tooltip } from 'antd'
import {
  deleteCompanySource,
  createCompanySource,
} from 'store/Orcatec/operation/settings/company'
import { useAppSelector } from 'store/Orcatec/hooks'
import { Error } from 'types/Error'
import styled from 'styled-components'

const sourceAndTypeStyle = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '14px',
  letterSpacing: '0.01em',
  color: ' #191919',
}
const Wrapper = styled.div`
  & input {
    padding: 6.5px 28px 6.5px 4px !important;
  }
`

export const AppointmentSources: FC<ApptSourceProp> = ({
  onChange,
  name,
  value,
  error,
  getFirst,
  required,
  label,
  setDefaultSourceId,
  disableCRUD,
  withoutLabel,
  disableClearable,
  ...rest
}) => {
  const [loading, setLoading] = useState(false)
  const [localError, setLocalError] = useState<Error | null>(null)

  const appSources: AppointmentSource[] = useAppSelector(
    state => state?.orcatec?.company?.source,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    setDefaultSourceId?.(appSources?.[0]?.id)
    // if (value === 0) {
    //   const defaultValue = appSources?.find(source => source.name === '-')
    //   onChange({ target: { name, value: defaultValue?.id } })

    //   setEdited?.(false)
    // }

    if (getFirst)
      onChange({ target: { name, value: appSources?.[0]?.id || null } })
  }, [])

  const handleChangeAppSource = async (
    event: React.ChangeEvent<HTMLInputElement>,
    value: any,
  ) => {
    if (localError) setLocalError(null)

    if (value?.id) return onChange({ target: { name, value: value.id } })
    if (!value) return onChange({ target: { name, value: null } })

    const newValue = value?.inputValue || value

    const existingSource = appSources?.find(source => source?.name === newValue)
    if (existingSource)
      return onChange({ target: { name, value: existingSource.id } })

    if (disableCRUD) {
      return setLocalError({
        [name]: 'Select source from list',
      })
    }

    try {
      setLoading(true)
      await dispatch(createCompanySource({ name: newValue })).then(
        createdSource => {
          onChange({ target: { name, value: createdSource.id } })
        },
      )
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleBlurEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const exitstingSource = appSources?.map(source => source?.name)
    // if (!value) {
    //   const defaultValue = appSources?.find(source => source.name === '-')
    //   onChange({ target: { name, value: defaultValue.id } })
    //   return
    // }
    if (!value || exitstingSource.includes(value)) return
    handleChangeAppSource(e, value)
  }

  const handleDeleteSource = async (
    e: React.MouseEvent<HTMLElement>,
    id: number,
  ) => {
    e.stopPropagation()
    dispatch(deleteCompanySource(id))
  }

  const filterOptions = (options, params) => {
    const filtered = options?.filter(
      option =>
        option?.id !== null && !option?.deleted_at && option?.name !== '-',
    )
    const filter = filtered?.filter(f =>
      f?.name?.toLowerCase()?.includes(params?.inputValue.toLowerCase()),
    )
    if (disableCRUD) return filter
    if (params?.inputValue !== '' && !filter?.length) {
      filter?.push({
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`,
      })
    }

    return filter
  }

  return (
    <Wrapper>
      <Autocomplete
        label={withoutLabel ? '' : label ?? 'Appointment Source'}
        loading={loading}
        name={name}
        value={value}
        error={localError || error}
        onChange={handleChangeAppSource}
        onBlur={handleBlurEvent}
        disableClearable={disableClearable}
        options={appSources}
        filterOptions={filterOptions}
        required={required}
        readOnly={disableCRUD}
        renderOption={(option: AppointmentSource) => (
          <div style={sourceAndTypeStyle}>
            <Tooltip title={option?.name} zIndex={9999} mouseLeaveDelay={0}>
              <Elipsis>{option?.name}</Elipsis>
            </Tooltip>
            {!!option?.id && !disableCRUD && (
              <Popconfirm
                title='Are you sure you want to delete this type?'
                overlayStyle={{
                  zIndex: 1400, //to show tooltip over dropdown
                }}
                onConfirm={e => {
                  if (!option.id) return
                  handleDeleteSource(e, option?.id)
                }}
              >
                <i
                  className='mdi mdi-delete p-1'
                  style={{ cursor: 'pointer', fontSize: '18px' }}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                />
              </Popconfirm>
            )}
          </div>
        )}
        {...rest}
      />
    </Wrapper>
  )
}

const Elipsis = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
