import { fromJS, isImmutable } from 'immutable'
import * as types from '../../types'

const initState = {
  data: [],
  page: '',
  limit: '',
  count: '',
  templates: {
    list: [],
    currentTemplate: {},
  },
}

export default (state = initState, action) => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case types.proposalTable.SET_PROPOSAL_TABLE:
      return { ...newState, ...action.res }
    case types.proposalTable.DELETE_PROPOSAL:
      newState.data = newState.data.filter(item => item.id !== action.id)
      return newState
    case types.proposalTable.PROPOSAL_SET_TEMPLATES:
      newState.templates.list = action.data
      return newState
    case types.proposalTable.PROPOSAL_SET_TEMPLATE:
      newState.templates.currentTemplate = action.data
      return newState
    default:
      return state
  }
}
