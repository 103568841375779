import { getValueFromLS } from 'hooks/useLocalStorage'

export const expandQueue = (key: string) => {
  const settings = getValueFromLS('matrixSettings')

  localStorage.setItem(
    'matrixSettings',
    JSON.stringify({
      ...settings,
      [key]: true,
    }),
  )
}
