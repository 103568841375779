import React from 'react'

const UserIcon = () => {
  return (
    <svg
      style={{ display: 'block', margin: '0 auto 4px' }}
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.2 3.5C4.2 1.95285 5.45285 0.7 7 0.7C8.54715 0.7 9.8 1.95285 9.8 3.5C9.8 5.04715 8.54715 6.3 7 6.3C5.45285 6.3 4.2 5.04715 4.2 3.5ZM0.7 11.375C0.7 11.0502 0.857136 10.7139 1.23997 10.3606C1.62757 10.0029 2.19795 9.6769 2.88422 9.40199C4.25787 8.8517 5.91938 8.575 7 8.575C8.08062 8.575 9.74213 8.8517 11.1158 9.40199C11.802 9.6769 12.3724 10.0029 12.76 10.3606C13.1429 10.7139 13.3 11.0502 13.3 11.375V13.3H0.7V11.375Z'
        stroke='white'
        strokeWidth='1.4'
      />
    </svg>
  )
}

export default UserIcon
