export const permissionList = {
  users: false,
  companies: false,
  subscriptions: false,
  support: false,
  emails: false,
  email_accounts: false,
  articles: false,
}

export const permissionTitles = {
  users: 'Users',
  companies: 'Companies',
  subscriptions: 'Subscription',
  support: 'Support',
  emails: 'Emails',
  email_accounts: 'Email Accounts',
  articles: 'Posts',
}
