import { Dispatch, SetStateAction, useEffect, useState } from 'react'

type SetValue<T> = Dispatch<SetStateAction<T>>

const getStorageValue = (key: string, defaultValue: any) => {
  const saved = localStorage.getItem(key) || JSON.stringify(defaultValue)
  const initial = JSON.parse(saved)
  return initial
}

export const getValueFromLS = (key: string) => {
  const value = localStorage.getItem(key)
  if (!value) return null
  return JSON.parse(value)
}
export const setValueToLS = (key: string, val: object) => {
  const storedValue = localStorage.getItem(key) || '{}'
  const parsedValue = JSON.parse(storedValue)
  // if (!value) return null
  localStorage.setItem(key, JSON.stringify({ ...parsedValue, ...val }))
}

const useLocalStorage = <T,>(
  key: string,
  defaultValue: T,
): [T, SetValue<T>] => {
  // const getStorageValue = (key: T, defaultValue: K): T => {
  //   const storedValue = localStorage.getItem(key)
  //   return storedValue !== null ? JSON.parse(storedValue) : defaultValue
  // }

  const [storedValue, setStoredValue] = useState<T>(() =>
    getStorageValue(key, defaultValue),
  )

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(storedValue))
  }, [key, storedValue])

  return [storedValue, setStoredValue]
}

export default useLocalStorage
