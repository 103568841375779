import { selectProjectSettings } from 'features/Project/projectSelectors'
import { selectProjectSlice } from 'features/Project/slices/projectSlice'
import { ProjectStatusGroup } from 'features/Project/types'
import { useAppSelector } from 'store/Orcatec/hooks'

const useProjectPreviewSettings = () => {
  const { project, editContract } = useAppSelector(selectProjectSlice)
  const projectSettings = useAppSelector(selectProjectSettings)

  const disabled =
    ![ProjectStatusGroup.Estimate, ProjectStatusGroup.Cold].includes(
      project.status,
    ) && !editContract

  const PROJECT_PREVIEW_SETTINGS = [
    {
      title: 'Contacts section',
      settings: [
        {
          title: 'Show tech contacts',
          description:
            'Technician contact information will be visible to customers',
          field_name: 'show_tech',
        },
      ],
    },
    {
      title: 'Table section',
      settings: [
        {
          title: 'Show QTY',
          description: 'Show quantity of items',
          field_name: 'show_items_qty',
        },
        {
          title: 'Show description only',
          description: 'Only item description will be visible',
          field_name: 'show_item_description_only',
        },
      ],
    },
    {
      title: 'Price section',
      settings: [
        {
          title: 'Show price per item',
          description: 'Display price per item in customer view',
          field_name: 'show_price',
          disabled: disabled,
        },
        {
          title: 'Show $0 prices',
          description:
            'When you turn off this option, only the name and description of items priced at $0 will show up for customers',
          field_name: 'hide_zero_price',
          disabled: !projectSettings?.show_price,
        },
      ],
    },
    {
      title: 'Contacts section',
      settings: [
        {
          title: 'Require signature',
          description:
            'To accept the project, clients must provide an electronic signature, their name, and agree to your Terms and Conditions',
          field_name: 'require_signature',
          disabled: project?.signature,
        },
      ],
    },
  ]

  return PROJECT_PREVIEW_SETTINGS
}

export default useProjectPreviewSettings
