import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProjectTabsAPI } from 'api/Project'
import { AppStateType } from 'store'
import { AsyncThunkAPI } from 'store/types'
import { IProjectTab } from '../types'
import { populateProjectTabWithEntities } from '../helpers'
import {
  getProject,
  projectFieldUpdated,
  // updateProjectField,
} from './projectSlice'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

const initialState: {
  status: 'idle' | 'loading' | 'updating' | 'error'
  projectTab: IProjectTab
} = {
  status: 'idle',
  projectTab: {},
}

const projectTabsSlice = createSlice({
  name: 'projectTab',
  initialState,
  reducers: {
    projectTabSet: (state, { payload }) => {
      state.projectTab = payload
    },
    projectTabFieldUpdated: (
      state,
      { payload }: PayloadAction<Record<keyof IProjectTab, unknown>>,
    ) => {
      state.projectTab = {
        ...state.projectTab,
        ...payload,
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getProjectTab.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getProjectTab.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.projectTab = payload
    })
    builder.addCase(getProjectTab.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })

    builder.addCase(addProjectTab.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(addProjectTab.fulfilled, state => {
      state.status = 'idle'
    })
    builder.addCase(addProjectTab.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })

    builder.addCase(updateProjectTabField.pending, state => {
      state.status = 'updating'
    })
    builder.addCase(updateProjectTabField.fulfilled, (state, { meta }) => {
      state.status = 'idle'
      state.projectTab = {
        ...state.projectTab,
        ...meta.arg,
      }
    })
    builder.addCase(updateProjectTabField.rejected, (state, action) => {
      state.error = action.payload
      state.status = 'error'
    })

    builder.addCase(copyProjectTab.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(copyProjectTab.fulfilled, state => {
      state.status = 'idle'
    })
    builder.addCase(copyProjectTab.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })

    builder.addCase(deleteProjectTab.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteProjectTab.fulfilled, state => {
      state.status = 'idle'
    })
    builder.addCase(deleteProjectTab.rejected, (state, action) => {
      state.error = action.payload?.errors || null
      state.status = 'error'
    })
  },
})

export const selectProjectTabSlice = (state: AppStateType) =>
  state.orcatec.projectTabsSlice

export default projectTabsSlice.reducer
export const {
  projectTabSet,
  projectTabFieldUpdated,
} = projectTabsSlice.actions

//Thunks

export const getProjectTab = createAsyncThunk<
  IProjectTab,
  { tabId: number; tabIdx: number }
>(
  'projectTab/getProjectTab',
  async ({ tabId, tabIdx }, { dispatch, rejectWithValue }) => {
    try {
      const tab = await ProjectTabsAPI.getTab(tabId)

      populateProjectTabWithEntities(tab, dispatch)
      dispatch(
        projectFieldUpdated({
          activeTab: tabIdx,
          activeTabId: tabId,
        }),
      )

      return tab
    } catch (error) {
      openNotificationWithIcon('error', {
        message: error.response?.data?.message || 'Project Tab not found',
      })
      return rejectWithValue(error.response.data)
    }
  },
)

export const addProjectTab = createAsyncThunk<
  IProjectTab,
  number,
  AsyncThunkAPI
>('project/addProjectTab', async (projectId, { dispatch, rejectWithValue }) => {
  try {
    const newTab = await ProjectTabsAPI.createTab({
      proposal_id: projectId,
      title: 'Tab Title',
    })

    await dispatch(getProject({ projectId }))

    return newTab
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const updateProjectTabField = createAsyncThunk<
  Partial<IProjectTab>,
  Partial<IProjectTab>,
  AsyncThunkAPI
>(
  'project/updateProjectTabField',
  async (fieldsToUpdate, { getState, dispatch, rejectWithValue }) => {
    const { activeTab } = getState().orcatec.projectSlice.project
    const { id: projectTabId } = getState().orcatec.projectTabsSlice.projectTab
    if (!projectTabId) return

    try {
      dispatch(projectTabFieldUpdated(fieldsToUpdate))
      return await ProjectTabsAPI.updateTabField(projectTabId, fieldsToUpdate)
    } catch (error) {
      dispatch(getProjectTab({ tabId: projectTabId, tabIdx: activeTab }))
      return rejectWithValue(error.response.data)
    }
  },
)

export const copyProjectTab = createAsyncThunk<
  IProjectTab,
  {
    projectId: number
    tabId: number
  },
  AsyncThunkAPI
>(
  'project/copyProjectTab',
  async ({ projectId, tabId }, { dispatch, rejectWithValue }) => {
    try {
      const newTab = await ProjectTabsAPI.copyTab(tabId)

      await dispatch(getProject({ projectId }))

      return newTab
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const deleteProjectTab = createAsyncThunk<
  IProjectTab,
  {
    projectId: number
    tabId: number
  },
  AsyncThunkAPI
>(
  'project/deleteProjectTab',
  async ({ projectId, tabId }, { dispatch, rejectWithValue }) => {
    if (!tabId) return
    try {
      const newTab = await ProjectTabsAPI.deleteTab(tabId)

      await dispatch(getProject({ projectId }))

      return newTab
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const updateInformationForWorkers = createAsyncThunk<
  IProjectTab,
  {
    projectId: number
    tabId: number
  },
  AsyncThunkAPI
>(
  'project/updateInformationForWorkers',
  async (files, { getState, dispatch, rejectWithValue }) => {
    const activeTab = getState().orcatec.projectTabsSlice.projectTab

    if (!activeTab?.id) return

    const fieldsToUpdate = {
      notes_for_installers: activeTab.notes_for_installers,
      files,
    }
    try {
      dispatch(projectTabFieldUpdated(fieldsToUpdate))
      return await ProjectTabsAPI.updateTabField(activeTab.id, fieldsToUpdate)
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)
