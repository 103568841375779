import axiosOrcatec from './axiosInstance'

/**
 * Fetch Client Feedback.
 *
 * @param clientId // to hoom
 * @param clientType // 1 - organization, 2 - others
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const getClientFeedback = (clientId, clientType) =>
  axiosOrcatec.get(`client-feedbacks/?entity_id=${clientId}&entity_type=${clientType}`).then(({ data }) => data)

/**
 * Create ClientFeedback.
 *
 * @param data
 * @return {*}
 */
export const createClientFeedback = (id, data) => {
  return axiosOrcatec.post(`client-feedbacks`, { ...data, entity_id: id }).then(response => response.data)
}

/**
 * Create ClientFeedback.
 *
 * @param id
 * @param data
 * @return {Promise<T | never>}
 */
export const updateClientFeedback = (id, data) => {
  return axiosOrcatec.put(`client-feedbacks/${id}`, data).then(response => response.data)
}

/**
 * Delete ClientFeedback.
 *
 * @param id
 * @param data
 * @return {*}
 */
export const deleteClientFeedback = id => {
  return axiosOrcatec.delete(`client-feedbacks/${id}`).then(response => response.data)
}
