import styled from 'styled-components'

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .MuiTextField-root {
    width: 280px;
  }

  .ant-picker {
  }

  .orcatec-timepicker {
    width: 230px;
    display: flex;
    flex-direction: column;
  }
`
