import { PaperClipOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
// import { ModalFilesUpload } from 'components/UI/ModalFIlesUpload/ModalFilesUpload'
import { ItemAttachment } from 'features/Items/types'
import DocViewer from 'helpers/viewFIlesNew/ViewDoc'
import { ViewFile } from 'helpers/viewFile/viewFile'
import { forwardRef, useState } from 'react'
import styled from 'styled-components'

interface Props {
  data: ItemAttachment[]
  disabled?: boolean
  imageSize?: number
  title?: string
  tooltip?: boolean
  onUpload?: (files: ItemAttachment[]) => void
  // onDelete?: (file: ItemAttachment) => void
}

const IMAGE_WIDTH = 60

export const Attachments = forwardRef<HTMLDivElement, Props>(
  ({ tooltip, data, disabled, onUpload, imageSize = IMAGE_WIDTH }) => {
    const [viewerModal, setViewerModal] = useState<{
      showDocViewerModal: boolean
      documents: ItemAttachment[]
      currentFileIdx: number | null
    }>({
      showDocViewerModal: false,
      documents: [],
      currentFileIdx: null,
    })

    const toggleOpenDocViewerModal = () => {
      setViewerModal(prev => ({
        ...prev,
        showDocViewerModal: !prev.showDocViewerModal,
      }))
    }

    const getFileForModalDocViewer = (
      files: ItemAttachment[],
      currentFileIdx: number,
    ) => {
      setViewerModal({
        documents: files,
        currentFileIdx,
        showDocViewerModal: true,
      })
    }

    const handleOpenFilesUpload = () => {
      onUpload?.()
    }

    return (
      <Wrapper>
        <List imageSize={imageSize}>
          {data
            ?.filter(image => !!image)
            ?.map((image: ItemAttachment, idx: number) => (
              <div
                onClick={e => {
                  if (e?.target?.classList?.contains('ant-tooltip-inner'))
                    return
                  getFileForModalDocViewer(data, idx)
                }}
                key={idx}
              >
                <ViewFile
                  file={image?.source || image?.linkToFile || image}
                  fileStyle={{
                    width: imageSize,
                    height: imageSize,
                    border: '1px solid #d9d9d9',
                    borderRadius: 8,
                    padding: 5,
                  }}
                  viewOnly={false}
                  tooltipPlacement={tooltip ? 'top' : undefined}
                  isLogo={undefined}
                  fileName={image?.display_name}
                />
              </div>
            ))}

          {!disabled && (
            <Tooltip title='Manage attachments' mouseLeaveDelay={0}>
              <Upload onClick={handleOpenFilesUpload}>
                <PaperClipOutlined style={{ fontSize: 18 }} />
              </Upload>
            </Tooltip>
          )}
        </List>

        {viewerModal?.showDocViewerModal && (
          <DocViewer
            documents={viewerModal.documents}
            currentDocument={viewerModal.currentFileIdx}
            isModalVisible={viewerModal.showDocViewerModal}
            handleCancel={toggleOpenDocViewerModal}
          />
        )}
      </Wrapper>
    )
  },
)

Attachments.displayName = 'Attachments'

const Wrapper = styled.div`
  width: 100%;
`
const List = styled.div<{ imageSize: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, ${props => props.imageSize}px);
  gap: 5px;
  min-height: ${props => props.imageSize}px;
`

const Upload = styled.div`
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: #2e5bff;
  }
`
