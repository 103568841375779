import { DashboardPermission as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'

export const dashboard = {
  dashboard_can_read: {
    name: 'View Dashboard',
    control_type: UniteType.FIRST_VIEW,
    description: '',
    with_access_scope: true,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.DASBOARD_CAN_READ,
    related_key: ['all'],
    is_module: true,
    custom_access_scope: [
      {
        key: 1,
        label: 'By All Users ',
        customLabel: true,
        activeColor: '#adf7b6',
        color: 'white',
        disabled: false,
      },
      {
        key: 2,
        label: 'Only Own ',
        customLabel: true,
        color: 'white',
        activeColor: '#adf7b6',
        disabled: false,
      },
    ],
  },
}
