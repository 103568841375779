import { Tooltip } from '@material-ui/core'
import { Tooltip as AntdTooltip } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import './style.scss'
import * as actions from 'store/Orcatec/actions'
import ToggleButton from 'containers/MainContent/Orcatec/components/buttons/ToggleButton/ToggleButton.jsx'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon.js'
import { Properties } from './Properties'
import { Clients } from './Clients'
import { Modals } from './Modal'
import {
  PropertiesSummary,
  ClientsSummary,
  OrganizationsSummary,
} from './Summary'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  useFormatAttachedProperty,
  useFormatAttachedClients,
  useFormatAttachedOrganizations,
  useCalculateQty,
  useSetInitialSummary,
} from './utils'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import {
  ClientIcon,
  PropertyIcon,
  OrganizationIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { useIsFreezed } from './utils'
import { createRelationProperty } from 'api/Client'
import { attachContact } from 'api/Organizations'
import { getValueFromItemByField } from 'containers/MainContent/Orcatec/components/ItemsTable/utilsV2'

import { Warning } from '@material-ui/icons'
export const ProposalContact = React.memo(({ isEditContract }) => {
  const dispatch = useDispatch()

  const freezedChange = useRef()
  const currentFormType = useRef()
  const clientTypeRef = useRef()
  const proposalForm = useSelector(state => state.orcatec.proposalForm)

  const { id: proposalId } = useParams()

  const [openModal, setOpenModal] = useState('')
  const [open, setOpen] = useState(false)
  // const [selectedType, setSelectedType] = useState('')
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [openTab, setOpenTab] = useState('')
  // const [clientModal, setClientModal] = useState(false)
  // const [propertyModal, setPropertyModal] = useState(false)
  const [currentPropertyId, setCurrentPropertyId] = useState('')
  const [currentClientId, setCurrentClientId] = useState('')
  const [parent, setParent] = useState(null)
  const [parentEntity, setParentEntity] = useState('')

  const [property, setProperty] = useFormatAttachedProperty([
    proposalForm.properties,
    proposalForm.clients,
    proposalForm.organizations,
  ])
  const [clients, setClients] = useFormatAttachedClients([
    proposalForm.properties,
    proposalForm.clients,
    proposalForm.organizations,
  ])
  const [organizations, setOrganizations] = useFormatAttachedOrganizations()
  const qty = useCalculateQty(property, clients, organizations)
  const summary = useSetInitialSummary(
    proposalForm?.properties,
    proposalForm?.clients,
    proposalForm?.organizations,
  )
  const freezed = useIsFreezed(isEditContract)

  const isOpen = useSelector(state => state.orcatec?.proposalContact?.isOpen)

  const parents = {
    clients: 'clients_parent',
    properties: 'properties_parent',
    organizations: 'organizations_parent',
  }

  useEffect(() => {
    if (isOpen && !open) setOpen(true)
  }, isOpen)

  useEffect(() => {
    for (let i = 0; i < Object.entries(parents)?.length; i++) {
      const [name, value] = Object.entries(parents)[i]

      if (proposalForm[value] && proposalForm?.[value]?.length) {
        setParentEntity(name)

        break
      }
      setParentEntity('')
    }
  }, [proposalForm])

  /*   useEffect(() => {
    Object.entries(parents).map(([name, value]) => {
      if (proposalForm[value] && proposalForm?.[value]?.length) {
        return setParentEntity(name)
      }
    })
  }, [proposalForm]) */

  /*   useEffect(() => {
    !selectedType && setSelectedType(proposalForm.chosen_state === 1 ? 'Properties' : 'Clients')
  }, [proposalForm]) */

  useEffect(() => {
    if (freezedChange.current)
      dispatch(actions.proposalForm.initProposalForm(proposalId, ''))
    else freezedChange.current = true
  }, [freezed])

  useEffect(() => {
    if (openTab === 'reloadForm' && currentFormType.current) {
      setOpenTab(currentFormType.current)
      currentFormType.current = null
    }
    setBtnDisabled(!!openTab)
  }, [openTab])

  const openNotification = (type, attachedType = 'item') => {
    if (type === 'save')
      return openNotificationWithIcon('success', {
        message: 'Your changes have been saved successfully',
        description: '',
        key: 'proposal-save-success',
      })
    if (type === 'attach')
      return openNotificationWithIcon('success', {
        message: `Property/Contact has been attached successfully`,
        description: '',
        key: 'proposal-save-success',
      })
    if (type === 'detach')
      return openNotificationWithIcon('success', {
        message: `${attachedType} have been deleted successfully`,
        description: '',
        key: 'proposal-save-success',
      })
    if (type === 'summary-save')
      return openNotificationWithIcon('success', {
        message: `Primary ${attachedType} updated successfully`,
        description: '',
        key: 'proposal-save-success',
      })
    if (type === 'default-save')
      return openNotificationWithIcon('success', {
        message: `Default view by ${attachedType} set successfully`,
        description: '',
        key: 'proposal-save-success',
      })
  }

  const handleOpenForm = (type, id, parent) => {
    if (parent) setParent(parent)

    setOpenModal(type)
    if (type === 'Properties') {
      // dispatch(actions.property.setCurrentItem({}))
      // setPropertyModal(true)
      setCurrentPropertyId(id)
      currentFormType.current = type
      setOpenTab('reloadForm')
    } else if (type === 'Clients' || type === 'Organizations') {
      // setClientModal(true)
      setCurrentClientId(id)
    } else if (type === 'Clients' && openTab === 'Clients') {
      dispatch(actions.organizations.removeCurrentOrganization())
      currentFormType.current = type
      setOpenTab('reloadForm')
    } else {
      // settrue(type)
      setOpen(true)
      setOpenTab(type)
    }
    dispatch(actions.client.removeCurrentClient())
  }

  const attachClientAfterSave = async clientData => {
    clientTypeRef.current = clientData.type !== 1 ? 'client' : 'organization'
    setOpenTab('')
    if (clientData.type === 1) {
      if (parent) {
        return await createRelationProperty({
          organization_id: clientData.id,
          property_id: parent?.id,
          note: '',
        })
        // return dispatch(actions.proposalContact.getAllProposalProperties(proposalId))
      }

      dispatch(
        actions.proposalContact.attachProposalOrganizationById(
          proposalId,
          clientData.id,
          handleAction,
        ),
      )
    } else {
      if (parent) {
        if (parent.contacts) {
          try {
            return await attachContact(parent?.id, clientData.id, '')
          } catch (error) {
            console.log(parent)
          }
          /* .then(res => res)
      .catch(e => {
        notification.error({ message: e?.response?.data?.name[0] })
      }) */
        }
        return await createRelationProperty({
          client_id: clientData.id,
          property_id: parent?.id,
          note: '',
        })
        // return dispatch(actions.proposalContact.getAllProposalProperties(proposalId))
      }

      dispatch(
        actions.proposalContact.attachProposalClientById(
          proposalId,
          clientData.id,
          handleAction,
        ),
      )
    }
  }

  const handleModalClose = () => {
    setOpenModal('')
    setParent(null)

    setCurrentPropertyId('')
    setCurrentClientId('')

    if (parentEntity === 'properties') {
      dispatch(actions.proposalContact.getAllProposalProperties(proposalId))
    } else if (!!parent) {
      dispatch(actions.proposalContact.getAllProposalClients(proposalId))
      dispatch(actions.proposalContact.getAllProposalOrganizations(proposalId))
    }
  }

  const handleAction = type => () => {
    openNotification(type, clientTypeRef.current)
    if (clientTypeRef.current) clientTypeRef.current = null
  }

  const handleSubmitPropertyForm = async ({ id: propertyId }) => {
    setCurrentPropertyId(propertyId)

    if (parent) {
      const parentId = parent.type === 1 ? 'organization_id' : 'client_id'

      return await createRelationProperty({
        [parentId]: parent?.id,
        property_id: propertyId,
        note: '',
      })
      // return dispatch(actions.proposalContact.getAllProposalProperties(proposalId))
    }

    dispatch(
      actions.proposalContact.attachProposalPropertyById(
        proposalId,
        propertyId,
        handleAction,
      ),
    )
  }

  const renderSelected = () => {
    if (parentEntity === 'properties') {
      return (
        <Properties
          proposalForm={proposalForm}
          currentTableData={property}
          setCurrentTableData={setProperty}
          qty={qty.properties}
          proposalId={proposalId}
          openNotification={openNotification}
          freezed={freezed}
          openTab={openTab}
          onOpenForm={handleOpenForm}
        />
      )
    } else {
      return (
        <Clients
          proposalForm={proposalForm}
          currentTableData={clients}
          setCurrentTableData={setClients}
          qty={qty.clients + qty.organizations}
          proposalId={proposalId}
          openNotification={openNotification}
          freezed={freezed}
          openTab={openTab}
          onOpenForm={handleOpenForm}
        />
      )
    }
  }

  const renderAddBtn = () => {
    return (
      <>
        <MainButton
          title={'Attach Property'}
          onClick={() => handleOpenForm('Properties')}
          className={`btn-set-default`}
        />
        <MainButton
          title={'Attach Contact'}
          onClick={() => handleOpenForm('Clients')}
          className={`btn-set-default`}
        />
      </>
    )
    // if (true === 'Properties')
    //   return (
    //     <MainButton title={'Add new Property'} onClick={() => handleOpenForm('Properties')} className='btn-set-default' />
    //   )
    // else return <MainButton title={'Add new Client'} onClick={() => handleOpenForm('Clients')} className='btn-set-default' />
  }

  const isProjectHasContacts =
    proposalForm.properties?.length ||
    proposalForm.clients?.length ||
    proposalForm.organizations?.length

  return (
    <>
      <Modals
        handleClientModalClose={handleModalClose}
        handlePropertyModalClose={handleModalClose}
        onSubmitPropertyForm={handleSubmitPropertyForm}
        // onSubmitClientForm={handleSubmitClientForm}
        propertyId={currentPropertyId}
        clientId={currentClientId}
        openModal={openModal}
        // clientModalId={clientModalId}
        clientType={'client'}
        attachClientAfterSave={attachClientAfterSave}
        mode={'add'}
        // propertyModalId={propertyModalId}
        // updateClientList={updateClientList}
      />
      <section className='proposal-contact-info'>
        <div className='proposal-contact-info__title'>
          <h2 className='proposal-contact-info__title-title'>
            Project Contacts
          </h2>
          {true && (
            <span
              className={`proposal-contact-info__title-button ${
                proposalForm.properties?.length ||
                proposalForm.clients?.length ||
                proposalForm.organizations?.length
                  ? ''
                  : 'proposal-contact-info__title-button--disabled'
              }`}
              onClick={e => e.stopPropagation()}
            >
              <ToggleButton show={open} toggle={() => setOpen(!open)} />
            </span>
          )}
          <div className='proposal-contact-info__title-qty-container'>
            <Tooltip placement={'top'} title={'Properties'}>
              <div className='proposal-contact-info__title-qty'>
                <PropertyIcon />
                <span className='proposal-contact-info__title-qty-count'>
                  {proposalForm.properties?.length}
                </span>
              </div>
            </Tooltip>
            <Tooltip placement={'top'} title={'Clients'}>
              <div className='proposal-contact-info__title-qty'>
                <ClientIcon />
                <span className='proposal-contact-info__title-qty-count'>
                  {proposalForm.clients?.length}
                </span>
              </div>
            </Tooltip>
            <Tooltip placement={'top'} title={'Organizations'}>
              <div className='proposal-contact-info__title-qty'>
                <OrganizationIcon />
                <span className='proposal-contact-info__title-qty-count'>
                  {proposalForm.organizations?.length || 0}
                </span>
              </div>
            </Tooltip>
          </div>
          <div className='proposal-contact-info__title-qty-container proposal-contact-info__title-qty-container--small'>
            <div className='proposal-contact-info__title-qty'>
              <PropertyIcon width={14} height={14} />
              <span className='proposal-contact-info__title-qty-count'>
                {proposalForm.properties?.length || 0}
              </span>
            </div>
            <div className='proposal-contact-info__title-qty'>
              <ClientIcon width={14} height={14} />
              <span className='proposal-contact-info__title-qty-count'>
                {proposalForm.clients?.length || 0}
              </span>
            </div>
            <div className='proposal-contact-info__title-qty'>
              <OrganizationIcon width={14} height={14} />
              <span className='proposal-contact-info__title-qty-count'>
                {proposalForm.organizations?.length}
              </span>
            </div>
          </div>

          {!parentEntity && (
            <div className='proposal-contact-info__title-add-btn'>
              {renderAddBtn()}
            </div>
          )}
          <div style={{ marginLeft: '20px' }}>
            {!isProjectHasContacts && (
              <AntdTooltip title='There are no contacts associated with this project. Please add contacts and properties to establish relationships and keep your network up to date.'>
                <Warning style={{ color: 'faad14', fontSize: '2rem' }} />
              </AntdTooltip>
            )}
          </div>
        </div>
        {open && parentEntity && renderSelected()}
      </section>
      <section className='proposal-contact-summary'>
        <ClientsSummary
          openNotification={openNotification}
          initialSummary={summary}
          freezed={freezed}
        />
        <PropertiesSummary
          openNotification={openNotification}
          initialSummary={summary}
          freezed={freezed}
        />
      </section>
    </>
  )
})
ProposalContact.displayName = 'ProposalContacts'
