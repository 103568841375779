import ErrorBoundary from 'containers/MainContent/Orcatec/components/ErrorBoundary/ErrorBoundary'
import TabsLayout from '../TabsLayout'
import { FormsBlock } from 'features/Forms/components/FormsBlock/FormsBlock'
import {
  CustomFormEntityType,
  CustomFormsOperation,
} from 'features/Forms/types'
import {
  getAttachedTemplatesToProject,
  getRelatedFormToProject,
  createRelatedFormToProject,
  // getCountFormsProject,
  // attachRelatedFormsToEvent,
  getCountFormsProject,
  attachRelatedFormsToProject,
} from 'api/CustomForms'
import { Companies } from 'types/Company'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompany } from 'store/Orcatec/selectors/company'
import { Result } from 'antd'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'

export const Forms = ({ workOrderId }) => {
  const { READ } = usePermissionsByModule(CustomFormsOperation)

  const company = useAppSelector(selectCompany)

  if (!READ) {
    return (
      <Result
        status='403'
        title='403'
        subTitle='Sorry, you have no permissions to perform this action.'
      />
    )
  }

  return (
    <TabsLayout title='Forms'>
      <ErrorBoundary>
        <FormsBlock
          entityId={workOrderId}
          entityType={CustomFormEntityType.PROJECT}
          createRelatedForm={createRelatedFormToProject}
          getRelatedForm={getRelatedFormToProject}
          getAttachedTemplate={getAttachedTemplatesToProject}
          onAttachTemplates={attachRelatedFormsToProject}
          getCount={getCountFormsProject}
          id={workOrderId}
          showAllowableTemplates={Companies.SafetyNey === company?.id}
          chooseAllowableTemplates={Companies.SafetyNey === company?.id}
          showTemplates
          hideTitle
          // module={ModuleName.PROJECT}
        />
      </ErrorBoundary>
    </TabsLayout>
  )
}
