import React from 'react'
import './style.scss'

const Boxes = ({ children, settings }) => {
  return (
    <div className='boxes'>
      {new Array(Number(settings.end_hour) - Number(settings.start_hour)).fill('').map((box, i) => (
        <div className='box' key={i} hour={i + Number(settings.start_hour)} />
      ))}
    </div>
  )
}

export default Boxes
