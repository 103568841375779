import React, { forwardRef, memo } from 'react'
import './style.scss'
import logo from '../../../CreateAppointmentV2/AppointmentForm/components/AppointmentPrintPDF/image/logo_soCall.png'
import { Input } from 'antd'
import classNames from 'classnames'

const { TextArea } = Input

const MATERIALS_CHECKED_OUT = 1
const MATERIALS_CHECKED_IN = 2
const BILLABLE_MATERIALS = 3
const EQUIPMENT_CHECK_OUT = 4
const initData = {
  appointment_id: null,
  customer_name: '',
  customer_relation: '',
  address: '',
  appointment_type: '',
  date: '',
  super_name: '',
  phone: '',
  preferred_tech: '',
  description: '',
  truck_number: '',
  truck_hours_onsite: '',
  pm: '',
  billed_by: '',
  fwo: '',
  foreman_work_break: [
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
    {
      foreman_log_sheet_id: 1,
      full_name: '',
      start: '',
      lunch_out: '',
      lunch_in: '',
      finish: '',
      total: '',
    },
  ],
  materials_checked_out: [
    {
      foreman_log_sheet_id: 1,
      type: MATERIALS_CHECKED_OUT,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 2,
      type: MATERIALS_CHECKED_OUT,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 3,
      type: MATERIALS_CHECKED_OUT,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 4,
      type: MATERIALS_CHECKED_OUT,
      note: '',
      qty: '',
    },
    // {
    //   foreman_log_sheet_id: 5,
    //   type: MATERIALS_CHECKED_OUT,
    //   note: '',
    //   qty: '',
    // },
  ],
  materials_checked_in: [
    {
      foreman_log_sheet_id: 1,
      type: MATERIALS_CHECKED_IN,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 2,
      type: MATERIALS_CHECKED_IN,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 3,
      type: MATERIALS_CHECKED_IN,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 4,
      type: MATERIALS_CHECKED_IN,
      note: '',
      qty: '',
    },
    // {
    //   foreman_log_sheet_id: 5,
    //   type: MATERIALS_CHECKED_IN,
    //   note: '',
    //   qty: '',
    // },
  ],
  billable_materials: [
    {
      foreman_log_sheet_id: 1,
      type: BILLABLE_MATERIALS,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 2,
      type: BILLABLE_MATERIALS,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 3,
      type: BILLABLE_MATERIALS,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 4,
      type: BILLABLE_MATERIALS,
      note: '',
      qty: '',
    },
    // {
    //   foreman_log_sheet_id: 5,
    //   type: BILLABLE_MATERIALS,
    //   note: '',
    //   qty: '',
    // },
  ],
  equipment_check_out: [
    {
      foreman_log_sheet_id: 1,
      type: EQUIPMENT_CHECK_OUT,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 2,
      type: EQUIPMENT_CHECK_OUT,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 3,
      type: EQUIPMENT_CHECK_OUT,
      note: '',
      qty: '',
    },
    {
      foreman_log_sheet_id: 4,
      type: EQUIPMENT_CHECK_OUT,
      note: '',
      qty: '',
    },
    // {
    //   foreman_log_sheet_id: 5,
    //   type: EQUIPMENT_CHECK_OUT,
    //   note: '',
    //   qty: '',
    // },
  ],
}

const Template = forwardRef(({ logSheetData }, ref) => {
  const data = { ...initData, ...logSheetData }
  const col = idx => {
    switch (idx) {
      case 0:
        return (
          <td>
            <div className='pdf-field'>
              <>
                <span style={{ lineHeight: '3.2' }}>
                  <span className='bold size16'>Truck#</span>
                </span>
                <span
                  style={{ paddingTop: '5px', paddingLeft: '5px' }}
                  className='bold semi_bigest__size'
                >
                  {data.truck_number}
                </span>
              </>
            </div>
          </td>
        )

      case 1:
        return (
          <td>
            <div className='pdf-field'>
              <>
                <span style={{ lineHeight: 2.5 }}>
                  <span className='bold size16'>Truck Hours Onsite:</span>
                </span>
                <span
                  style={{ paddingTop: '5px', paddingLeft: '5px' }}
                  className='bold semi_bigest__size'
                >
                  {data.truck_hours_onsite}
                </span>
              </>
            </div>
          </td>
        )

      case 2:
        return <td className='dark center bold'></td>

      case 3:
        return <td className='dark center bold'>Office Use Only</td>

      case 4:
        return (
          <tr className='intable'>
            <td style={{ width: '30%' }} className='dark bold size16 '>
              FWO
            </td>
            <td className='dark borderRightNone'>
              <div className='pdf-field'>
                <span
                  style={{ width: '100px' }}
                  className='bold semi_bigest__size'
                >
                  {data.fwo}
                </span>
              </div>
            </td>
          </tr>
        )

      case 5:
        return (
          <tr className='intable'>
            <td style={{ width: '30%' }} className='dark bold size16 '>
              PM
            </td>
            <td className='dark borderRightNone'>
              <div className='pdf-field'>
                <span
                  style={{ width: '100px' }}
                  className='bold semi_bigest__size'
                >
                  {data.pm}
                </span>
              </div>
            </td>
          </tr>
        )

      case 6:
        return (
          <tr className='intable'>
            <td
              style={{ width: '30%' }}
              className='dark bold size16 borderBottomNone '
            >
              Billed by{' '}
            </td>
            <td className='dark bold borderRightNone borderBottomNone'>
              <span
                style={{ width: '100px' }}
                className='bold semi_bigest__size '
              >
                {data.billed_by}
              </span>
            </td>
          </tr>
        )

      default:
        break
    }
  }

  return (
    <div ref={ref} className={classNames('pdf-wrapper-print')}>
      <div className='pdf-image__wrapper'>
        <img className='pdf-logo' src={logo} alt='pdf' />
        <p className='company-contact'>
          800-834-8682 951-277-2303 Fax. 12620 Bosley Lane Corona. CA 92883
        </p>
        <p className='company-contact__series'>
          CSLB #632882-A DIR #1000007012
        </p>
      </div>
      <div className='table-head'>
        <div className='table-head__text'>Daily Dispatch Log Sheet</div>
        <div className='table-responsive date-table'>
          <table>
            <tbody>
              <tr style={{ width: '300px', marginLeft: 'auto' }}>
                <td>
                  <span style={{ padding: '0 6px' }} className='bold'>
                    M
                  </span>
                  <span style={{ padding: '0 6px' }} className='bold'>
                    T
                  </span>
                  <span style={{ padding: '0 6px' }} className='bold'>
                    W
                  </span>
                  <span style={{ padding: '0 6px' }} className='bold'>
                    TH
                  </span>
                  <span style={{ padding: '0 6px' }} className='bold'>
                    F
                  </span>
                  <span style={{ padding: '0 6px' }} className='bold'>
                    S
                  </span>
                  <span style={{ padding: '0 6px' }} className='bold'>
                    SUN
                  </span>
                </td>
                <td style={{ width: '300px', marginLeft: 'auto' }}>
                  <span className='bold'>Date:</span>{' '}
                  <span className='bold bigest__size'>{data.date}</span>{' '}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='table-wrapper'>
        <div className='table-responsive heading-table'>
          <table>
            <tbody>
              <tr>
                <td>
                  <span className='bold'>Customer:</span>{' '}
                  <span className='bold bigest__size'>
                    {data.customer_name}
                  </span>{' '}
                </td>
                <td>
                  <div className='pdf-field'>
                    <div>
                      <span
                        style={{
                          width: '24%',
                          lineHeight: 1.1,
                          paddingTop: '8px',
                        }}
                      >
                        <span className='bold'>Super Name:</span>
                      </span>
                      <span
                        style={{ paddingTop: '10px' }}
                        className='bold bigest__size'
                      >
                        {data.super_name}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span className='bold'>Project: </span>
                  <span className='bold bigest__size'>
                    {data.customer_relation}
                  </span>{' '}
                </td>
                <td>
                  <div className='pdf-field'>
                    <span style={{ width: '30%', lineHeight: 3.2 }}>
                      <span className='bold'>Phone# :</span>{' '}
                    </span>

                    <span className='bold bigest__size'>{data.phone} </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span className='bold'>Tract #/Location:</span>{' '}
                  <span className='bold bigest__size'>{data.address}</span>
                </td>
                <td>
                  <span className='bold'>Foreman:</span>{' '}
                  <span className='bold bigest__size'>
                    {data.preferred_tech}
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan='2'>
                  <span className='bold'> Work Description: </span>
                  <span className='bigest__size bold'>
                    {data.appointment_type}
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan='2'>
                  <span
                    style={{
                      wordBreak: 'break-all',
                    }}
                    className='bold bigest__size'
                  >
                    {data?.description?.length > 170
                      ? data?.description?.slice(0, 170) + '...'
                      : data?.description}{' '}
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  className='description'
                  style={{ fontSize: '30px' }}
                  colspan='2'
                >
                  ALL JOBS REQUIRE PPE
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='table-responsive  work_break_wraper'>
          <table>
            <thead>
              <th>Emp. #Full Name</th>
              <th>Start</th>
              <th>Lunch Out</th>
              <th>Lunch In</th>
              <th>Finish</th>
              <th>Total</th>
              <th>Vehicle Information</th>
            </thead>
            <tbody>
              {data?.foreman_work_break
                ?.sort((a, b) => a?.id - b?.id)
                .map((el, idx) => (
                  <tr key={idx}>
                    <td width={'30%'}>
                      {idx === 0 ? (
                        <span style={{ paddingLeft: '10px' }} className='bold'>
                          {data.preferred_tech}
                        </span>
                      ) : (
                        <div className='pdf-field'>
                          <TextArea
                            name='full_name'
                            value={data.foreman_work_break[idx].full_name}
                            className='textarea_pdf widthFull'
                            maxLength={34}
                            rows={1}
                          />
                        </div>
                      )}
                    </td>
                    <td width={'10%'}>
                      <div className='pdf-field'>
                        <TextArea
                          name='start'
                          value={data.foreman_work_break[idx].start}
                          className='textarea_pdf widthFull'
                          maxLength={7}
                          rows={1}
                        />
                      </div>
                    </td>
                    <td width={'10%'}>
                      <div className='pdf-field'>
                        <TextArea
                          name='lunch_out'
                          value={data.foreman_work_break[idx].lunch_out}
                          className='textarea_pdf widthFull'
                          maxLength={7}
                          rows={1}
                        />
                      </div>
                    </td>
                    <td width={'10%'}>
                      <div className='pdf-field'>
                        <TextArea
                          name='lunch_in'
                          value={data.foreman_work_break[idx].lunch_in}
                          className='textarea_pdf widthFull'
                          maxLength={7}
                          rows={1}
                        />
                      </div>
                    </td>
                    <td width={'10%'}>
                      <div className='pdf-field'>
                        <TextArea
                          name='finish'
                          value={data.foreman_work_break[idx].finish}
                          className='textarea_pdf widthFull'
                          maxLength={7}
                          rows={1}
                        />
                      </div>
                    </td>
                    <td width={'10%'}>
                      <div className='pdf-field'>
                        <TextArea
                          name='total'
                          value={data.foreman_work_break[idx].total}
                          className='textarea_pdf widthFull'
                          maxLength={7}
                          rows={1}
                        />
                      </div>
                    </td>
                    {col(idx)}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='bottom-table'>
          <div className='left-table'>
            <div className='table-responsive'>
              <table>
                <thead>
                  <th>Materials Checked Out</th>
                  <th>Quantity</th>
                </thead>
                <tbody>
                  {data?.materials_checked_out
                    ?.sort((a, b) => a?.id - b?.id)
                    .map((el, idx) => (
                      <tr key={idx}>
                        <td>
                          <div className='pdf-field'>
                            <TextArea
                              name='note'
                              value={data.materials_checked_out[idx].note}
                              className='textarea_pdf widthFull'
                              maxLength={45}
                              rows={1}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='pdf-field'>
                            <TextArea
                              name='qty'
                              value={data.materials_checked_out[idx].qty}
                              className={classNames(
                                'textarea_pdf',
                                'widthFull',
                              )}
                              maxLength={5}
                              rows={1}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className='table-responsive'>
              <table>
                <thead>
                  <th>Materials Checked In</th>
                  <th>Quantity</th>
                </thead>
                <tbody>
                  {data.materials_checked_in
                    ?.sort((a, b) => a?.id - b?.id)
                    .map((el, idx) => (
                      <tr key={idx}>
                        <td>
                          <div className='pdf-field'>
                            <TextArea
                              name='note'
                              value={data.materials_checked_in[idx].note}
                              className='textarea_pdf widthFull'
                              maxLength={45}
                              rows={1}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='pdf-field'>
                            <TextArea
                              name='qty'
                              value={data.materials_checked_in[idx].qty}
                              className={classNames(
                                'textarea_pdf',
                                'widthFull',
                              )}
                              maxLength={5}
                              rows={1}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='right-table'>
            <div className='table-responsive'>
              <table>
                <thead>
                  <th>Billable Materials</th>
                  <th>Quantity</th>
                </thead>
                <tbody>
                  {data.billable_materials
                    ?.sort((a, b) => a?.id - b?.id)
                    .map((el, idx) => (
                      <tr key={idx}>
                        <td>
                          <div className='pdf-field'>
                            <TextArea
                              name='note'
                              value={data.billable_materials[idx].note}
                              className='textarea_pdf widthFull'
                              maxLength={45}
                              rows={1}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='pdf-field'>
                            <TextArea
                              name='qty'
                              value={data.billable_materials[idx].qty}
                              className={classNames(
                                'textarea_pdf',
                                'widthFull',
                              )}
                              maxLength={5}
                              rows={1}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className='table-responsive'>
              <table>
                <thead>
                  <th>Equipment Check Out</th>
                  <th>Quantity</th>
                </thead>
                <tbody>
                  {data.equipment_check_out
                    ?.sort((a, b) => a?.id - b?.id)
                    .map((el, idx) => (
                      <tr key={idx}>
                        <td>
                          <div className='pdf-field'>
                            <TextArea
                              name='note'
                              value={data.equipment_check_out[idx].note}
                              className='textarea_pdf widthFull'
                              maxLength={45}
                              rows={1}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='pdf-field'>
                            <TextArea
                              name='qty'
                              value={data?.equipment_check_out[idx]?.qty}
                              className={classNames(
                                'textarea_pdf',
                                'widthFull',
                              )}
                              maxLength={5}
                              rows={1}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

Template.displayName = 'Template'

export default memo(Template)
