import axiosOrcatec from '../../../api/axiosInstance'

export const updateSubscriptionForSA = (id, data) => {
  return axiosOrcatec
    .put(`/admin/subscriptions/${id}`, data)
    .then(response => response.data)
}

export const switchModuleForSA = (id, data) => {
  return axiosOrcatec
    .put(`/admin/subscriptions/switch-state-module/${id}`, data)
    .then(response => response.data)
}

export const changeCustomPrices = (id, data) =>
  axiosOrcatec
    .put(`/admin/subscriptions/update-prices/${id}`, data)
    .then(({ data }) => data)

export const getGlobalSubscriptionInfo = () =>
  axiosOrcatec
    .get(`/admin/subscriptions/total-fee-pre-month`)
    .then(({ data }) => data)
