import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setCancellationFees as setFees } from 'store/Orcatec/actions/proposal/proposalForm'
import {
  selectProjectActiveTab,
  selectProjectTotal,
} from 'features/Project/projectSelectors'
import NumberFormat from 'react-number-format'
import { TextField } from 'components/UIKit'
import { formatStringToNumber } from 'helpers/numbers'
import { priceToView } from 'helpers/thousandSeparator'
import { StyledEditIcon } from 'features/Project/Taxes/Taxes.style'
import { updateProjectTabField } from 'features/Project/slices/projectTabsSlice'

const CancellationFees = () => {
  const dispatch = useDispatch()

  const { cancellation_fees, contract_cancelled } = useSelector(
    selectProjectActiveTab,
  )

  const [editCancellationFees, setEditCancellationFees] = useState(false)
  const [cancellationFees, setCancellationFees] = useState(
    cancellation_fees || 0,
  )

  // useEffect(() => {
  //   setCancellationFees(cancellation_fees)
  // }, [cancellation_fees])

  const handleChangeCancellationFees = e => {
    const { value } = e.target

    setCancellationFees(value)
  }

  const handleCancellationFeesBlur = () => {
    setEditCancellationFees(false)

    dispatch(
      updateProjectTabField({
        cancellation_fees: cancellationFees,
      }),
    )
  }

  return (
    <table className='proposal-form-tabs_content-end_of_system-total_table'>
      <tbody>
        <tr>
          <td>Contract has been cancelled:</td>
          <td>-{priceToView(contract_cancelled)}</td>
        </tr>
        <tr>
          <td>
            <StyledEditIcon
              onClick={() => {
                setEditCancellationFees(true)
              }}
            />
            Cancellation fees:
          </td>
          <td>
            {editCancellationFees ? (
              <NumberFormat
                autoFocus
                thousandSeparator
                decimalScale={2}
                name='installation'
                customInput={TextField}
                value={cancellationFees}
                prefix='$'
                style={{ textAlign: 'right' }}
                onChange={e =>
                  handleChangeCancellationFees({
                    target: {
                      name: 'installation',
                      value: formatStringToNumber(e, true),
                    },
                  })
                }
                onBlur={handleCancellationFeesBlur}
                allowNegative={false}
              />
            ) : (
              <p
                style={{ padding: '1px 0' }}
                onClick={() => {
                  setEditCancellationFees(true)
                }}
              >
                {priceToView(cancellationFees)}
              </p>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default CancellationFees
