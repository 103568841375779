import React from 'react'

import { Tooltip } from 'antd'

const cutText = text => {
  if (text?.length < 20) return `, ${text}`

  return <Tooltip title={text}>{`, ${text.slice(0, 20)}...`}</Tooltip>
}

export const ContactItem = ({ item }) => {
  return (
    <div>
      <p>
        <b>{item?.name || item?.text}</b>
        {item?.proposal_relation ? cutText(item.proposal_relation) : ''}
      </p>
      {!!item?.emails?.length && (
        <div className='proposal-contact-summary__contacts'>
          <div className='proposal-contact-summary__contacts-type'>
            {item?.emails?.length > 1 ? 'Emails' : 'Email'}:
          </div>
          <div className='proposal-contact-summary__contacts-value'>
            {item?.emails.map((email, idx) => (
              <Tooltip title={email} key={idx}>
                <p>{email}</p>
              </Tooltip>
            ))}
          </div>
        </div>
      )}
      {!!item?.phones?.length &&
        item.phones.map((phone, idx) => (
          <div className='proposal-contact-summary__contacts' key={idx}>
            <div className='proposal-contact-summary__contacts-type'>
              {phone.type}:
            </div>
            <div className='proposal-contact-summary__contacts-value'>
              <p>{phone.phone}</p>
            </div>
          </div>
        ))}
    </div>
  )
}
