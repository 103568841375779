import React from 'react'

const IconTimeCards = () => (
  <svg width='18' height='22' viewBox='0 0 18 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.35'
      d='M15.6844 2.41403H14.868V0.8125H13.5073V2.41403H4.52673V0.8125H3.16604V2.41403H2.34963C1.2242 2.41403 0.308594 3.49169 0.308594 4.81632V18.9098C0.308594 20.2344 1.2242 21.312 2.34963 21.312H15.6844C16.8098 21.312 17.7254 20.2344 17.7254 18.9098V4.81632C17.7254 3.49169 16.8098 2.41403 15.6844 2.41403ZM16.3647 18.9098C16.3647 19.3513 16.0595 19.7105 15.6844 19.7105H2.34963C1.97449 19.7105 1.66928 19.3513 1.66928 18.9098V8.33968H16.3647V18.9098ZM16.3647 6.73815H1.66928V4.81632C1.66928 4.37478 1.97449 4.01555 2.34963 4.01555H3.16604V5.61708H4.52673V4.01555H13.5073V5.61708H14.868V4.01555H15.6844C16.0595 4.01555 16.3647 4.37478 16.3647 4.81632V6.73815Z'
      fill='white'
    />
  </svg>
)

export default IconTimeCards
