import { Button, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { AuthAPI } from '../api'
import { CheckCircleOutlined } from '@ant-design/icons'

enum Verified {
  PENDING,
  SUCCESS,
  FAILURE,
}

export const VerifyEmail = () => {
  const [loading, setLoading] = useState(false)
  const [verified, setVerified] = useState(Verified.PENDING)

  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const verifyEmail = async () => {
      setLoading(true)
      try {
        await AuthAPI.verifyEmail({ token: location.search.slice(1) })

        setVerified(Verified.SUCCESS)
      } catch (error) {
        setVerified(Verified.FAILURE)
      } finally {
        setLoading(false)
      }
    }

    if (location.search.slice(1)) verifyEmail()
  }, [])

  const text =
    verified === Verified.SUCCESS
      ? 'Email has been verified successfully!'
      : 'The verification link has been used or expired'

  const handleEmailResend = async () => {
    if (loading) return

    setLoading(true)
    try {
      await AuthAPI.resendVerificationEmail({ token: location.search.slice(1) })

      history.replace('/auth/confirm-email')
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Spin spinning={loading}>
      <Wrapper>
        <Img src='/assets/images/Auth/ve.svg' alt='Sent image' />

        {verified !== Verified.PENDING && (
          <Heading>
            <>
              <Icon>
                {verified === Verified.SUCCESS && (
                  <CheckCircleOutlined style={{ color: '#00c000' }} />
                )}
              </Icon>
              {text}
            </>
          </Heading>
        )}

        {verified === Verified.FAILURE && (
          <Buttons>
            <Link to='/auth/signin'>
              <Button>Back to login</Button>
            </Link>
            <Button onClick={handleEmailResend} type='primary'>
              Resend email
            </Button>
          </Buttons>
        )}

        {verified !== Verified.FAILURE && (
          <Signin>
            <Link to='/auth/signin'>
              <Button type='primary'>Sign In</Button>
            </Link>
          </Signin>
        )}
      </Wrapper>
    </Spin>
  )
}

const Wrapper = styled.div`
  width: 450px;
  padding: 48px;
  border: 2px solid #165ed033;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;

  @media (max-width: 450px) {
    width: 375px;
    padding: 16px;
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 50px;
`
const Heading = styled.h4`
  text-align: center;
  margin-bottom: 1.2rem;
  margin-top: 1.5rem;
  /* display: flex;
  align-items: center; */
  /* gap: 12px; */
`

const Icon = styled.span`
  margin-right: 12px;
`

const Img = styled.img``

const Signin = styled.p`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
  color: #4d4d4d;
`
