import * as types from './../../types/mail-client/messages'

export default (
  state = {
    isError: false,
    process: false,
    messages: [],
    perPage: 0,
    total: 0,
  },
  action,
) => {
  switch (action.type) {
    case types.MESSAGES_FETCH_START:
      return {
        ...state,
        isError: false,
        process: true,
      }
    case types.MESSAGES_FETCH_SUCCESS:
      return {
        ...state,
        isError: false,
        process: false,
        messages: action.payload.items,
        perPage: action.payload.perPage,
        total: action.payload.total,
      }
    case types.MESSAGES_FETCH_ERROR:
      return {
        ...state,
        isError: true,
        process: false,
        error: action.payload,
        messages: [],
      }
    case types.MESSAGES_MESSAGE_FAVORITE:
      const messages = state.messages.slice()
      const message = messages.find(item => {
        return item.id === action.payload.id
      })

      if (message) {
        message.flags.flagged = action.payload.flagged
      }

      return {
        ...state,
        messages,
      }
    default:
      return state
  }
}
