import React, { Component } from 'react'
import {
  // Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import './topbar.scss'
import { connect } from 'react-redux'
import * as actions from '../../../store/Orcatec/actions'
import ModalUserForm from '../../../containers/MainContent/Orcatec/Settings/components/users/ModalUserForm'
import Support from '../../../containers/MainContent/Orcatec/Support'
import ClockOutInBtn from '../../../containers/MainContent/Orcatec/components/ClockOutInBtn/ClockOutInBtn'
import { isUserFullSettings } from '../../../helpers/isUserFullSettings'
import { ViewFile } from '../../../helpers/viewFile/viewFile'

import { getConnector } from '../../../helpers/ws'
import CardModal from './CardModal/CardModal'
import withSubscription from '../../../hoc/withSubscription'
import { compose } from 'redux'
import moment from 'moment-timezone'
import { TimezonePicker } from 'containers/MainContent/Orcatec/TimeCards/components/TimezonePicker/TimezonePicker'
import CustomDatePicker from 'containers/MainContent/Orcatec/TimeCards/components/CustomDatePicker/CustomDatePicker'

import TimeCardList from 'containers/MainContent/Orcatec/TimeCards/components/TimeCardsList/TimeCardList'
import { logoutAccountManagerToAdmin } from '../../../api/User'
import { accountManagerLoginToCompany } from '../../../store/SuperAdmin/api/company'
import { separateSetUserAndCompany } from '../../../store/Orcatec/actions/settings/user'
import { withRouter } from 'react-router-dom'
import { openNotificationWithIcon } from '../../../helpers/notifications/openNotificationWithIcon'
import UserAvatar from '../Header/components/UserAvatar'

import Chat from 'features/Messaging/components/Chat'
import MainButton from '../../../containers/MainContent/Orcatec/components/buttons/MainButton'

import { Badge, Drawer, Dropdown, Menu, Modal } from 'antd'

const avaStyle = {
  width: '40px',
  height: '40px',
  cursor: 'pointer',
  backgroundSize: 'cover',
  borderRadius: '50%',
}

const initialTimeState = {
  startDate: moment()
    .startOf('week')
    .format('YYYY-MM-DD'),
  endDate: moment()
    .endOf('week')
    .format('YYYY-MM-DD'),
}
class TopBar extends Component {
  state = {
    chatVisible: false,
    chatID: null,
    dropdownOpenprofile: false,
    modalForm: false,
    supportModal: false,
    prevScrollpos: window.pageYOffset,
    visible: true,
    modalCard: false,
    isCardSaving: false,
    modalTimecards: false,
    timezone:
      JSON.parse(localStorage.getItem(this.props.me.parent_id))?.[
        this.props.me.id
      ]?.timezone || moment?.tz?.guess(),
    timeState: initialTimeState,
  }

  componentDidMount() {
    if (this?.props?.me && !this.props.me?.login_from_admin) {
      if (!isUserFullSettings(this?.props?.me)) {
        this.toggleFormModal()
      }
    }
    /* if (!this.props.justButton) { */
    window.addEventListener('scroll', this.handleScroll)
    /* } */
    // if (this.props.me.role_id === 1) {
    //   if (this.props.isCompanyFromAdmin && !this.props.subscriptionOff) {
    //     // this.toggleCardModal()
    //     debugger
    //     this.autoShowProfileSettings()
    //   } /* else {
    //     this.autoShowProfileSettings()
    //   } */
    // }

    this.props.fetchUnseenTicketsCount()

    const ws = getConnector()

    if (ws) {
      ws.private(`user.${this.props.me.id}`).listen(
        '.has.support.tickets.unseen',
        payload => {
          this.props.setUnseenMessagesCount((payload.tickets || []).length)
        },
      )
      // Income message notification
      // ws.private('new-sms-message-user.' + this.props.me.id).listen('.sms.message-created', ({ message }) => {
      //   this.setState(p => ({ ...p, chatID: message.chats?.[0] }))
      //   openNotificationWithIcon('success', {
      //     message: 'New message',
      //     description: `${message.message}`,
      //     duration: 10,
      //     btn: <MainButton title='Open Chat Modal' onClick={this.showChatModalVisible} />,
      //   })
      // })
    }
  }

  autoShowProfileSettings = () => {
    if (!isUserFullSettings(this.props.me)) {
      if (this.props.currentPage !== '/settings/info')
        this.props.history.push('/settings/info')
      this.toggleFormModal()
    }
  }

  onSubmitCard = () => {
    /////////////////////////////////////////////////////////
    this.toggleCardModal()
    this.autoShowProfileSettings()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.me.role_id !== this.props.me.role_id) {
      if (this.props.me.role_id === 1) {
        if (this.props.isCompanyFromAdmin) {
          this.toggleCardModal()
        } else {
          this.autoShowProfileSettings()
        }
      }
    }
  }

  componentWillUnmount() {
    /*  if (!this.props.justButton) { */
    window.removeEventListener('scroll', this.handleScroll)
    /* } */
  }

  /* componentDidMount() {
    if (this.props.me.role_id === 1) {
      if (!isUserFullSettings(this.props.me) && this.props.location.pathname === '/settings') {
        this.toggleFormModal()
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.me.role_id !== this.props.me.role_id) {
      if (this.props.me.role_id === 1) {
        if (!isUserFullSettings(this.props.me) && this.props.location.pathname === '/settings') {
          this.toggleFormModal()
        }
      }
    }
  } */

  toggleFormModal = () => {
    if (this.props.subscriptionStatus !== 'INACTIVE') {
      this.props.fetchCompany()
      this.setState(prev => ({ modalForm: !prev.modalForm }))
    }
  }

  toggleCardModal = () => {
    this.setState(prev => ({ modalCard: !prev.modalCard }))
  }

  toggleSupportModal = () => {
    this.setState(prev => ({ supportModal: !prev.supportModal }))
  }

  toggleProfile = () => {
    this.setState(prevState => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile,
    }))
  }

  logout = () => {
    window.localStorage.removeItem('auth')
    document.cookie = 'orcatecJWT= ;'
    this.props.history.push('/login')
    this.props.userLogout()
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state

    const currentScrollPos = window.pageYOffset
    const visible = prevScrollpos > currentScrollPos || currentScrollPos <= 0 //currentScrollPos <= 25

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    })
  }

  handleReturnToAdminPanel = () => {
    try {
      logoutAccountManagerToAdmin()
        .then(r => separateSetUserAndCompany(r.data, this.props.dispatch))
        .then(() => localStorage.removeItem('isFromSA'))
        .then(() => this.props.history.push('/companies'))
        .catch(error => console.log(error))
    } catch (e) {
      console.error(e)
    }
  }

  showChatModalVisible = () => {
    this.setState(p => ({ ...p, chatVisible: true }))
  }

  hideChatModalVisible = () => {
    this.setState(p => ({ ...p, chatVisible: false }))
  }

  diffInDays = (date1, date2, daysAdd = 0) => {
    const a = moment(date1).add(daysAdd, 'd')
    const b = moment(date2)
    return a.diff(b, 'days') + 1
  }

  handleSetTimeState = timeState => this.setState({ timeState })
  handleSetTimezone = timezone => this.setState({ timezone })
  toggleModal = () =>
    this.setState({ modalTimecards: !this.state.modalTimecards })

  render() {
    const { supportTickets } = this.props

    const {
      is_admin,
      login_from_admin,
      full_name,
      info: { profile_picture },
      background_color,
      ui_settings,
      paid_modules,
    } = this.props.me

    const items = [
      {
        key: 'Get Help',
        isVisible: !is_admin,
        title: <Badge count={supportTickets.unseenCount}>Get Help</Badge>,
        icon: <i className='mdi mdi-help-circle' />,
        onClickHandler: () => {
          this.toggleSupportModal()
          this.toggleProfile()
        },
      },
      {
        key: 'Profile Settings',
        isVisible: true,
        title: 'Profile Settings',
        icon: <i className='mdi mdi-account-circle m-r-5' />,
        onClickHandler: () => {
          this.toggleFormModal()
          this.toggleProfile()
        }, //custom function to ckech userRole, subscriptionOff and other things
      },
      {
        key: 'Timecards',
        isVisible: paid_modules?.time_cards,
        title: 'Timecards',
        icon: <i className='mdi mdi-av-timer m-r-5' />,
        onClickHandler: () => {
          this.toggleModal()
          // toggleTimecardsModal()
          this.toggleProfile()
        },
      },
      {
        key: 'Return to admin panel',
        isVisible: login_from_admin,
        title: 'Return to admin panel',
        icon: <i className='mdi mdi-logout' />,
        onClickHandler: () => {
          // returnToSA()
          this.handleReturnToAdminPanel()
          this.toggleProfile()
        },
      },
      {
        key: 'Logout',
        isVisible: !login_from_admin,
        title: 'Logout',
        icon: <i className='mdi mdi-power text-danger' />,
        onClickHandler: this.logout,
      },
    ]

    const dropdownMenu = (
      <Menu>
        {items.map(({ key, icon, isVisible, title, onClickHandler }) =>
          isVisible ? (
            <Menu.Item key={key} icon={icon} onClick={onClickHandler}>
              {title}
            </Menu.Item>
          ) : null,
        )}
      </Menu>
    )

    return (
      <>
        {/* <div
        id='topbar'
        className={
          'topbar' +
          (!this.state.visible && !this.props.isSidebarOpen ? ' topbar-hidden' : '')
        }
        style={{ zIndex: 888 }}
      > */}
        <div
          style={{ zIndex: 2 }}
          className={
            'topbar-left' +
            (this.props.isOpen ? '' : ' topbar-left-sidebar-closed') +
            (!this.state.visible && !this.props.isSidebarOpen
              ? ' topbar-nav-hidden'
              : '')
          }
        >
          {
            /* this.props.justButton && */ <button
              onClick={() => this.props.toggle(!this.props.isOpen)}
              className='button-menu-mobile waves-effect float-left px-0'
            >
              <i className='mdi mdi-menu' />
            </button>
          }
        </div>
        <div
          style={{ zIndex: 2 }}
          className={
            'topbar-right' +
            (this.props.isOpen ? '' : ' topbar-right-sidebar-closed') +
            (!this.state.visible ? ' topbar-nav-hidden' : '')
          }
        >
          <nav
            className={`${
              this.props.scrollDirection === 'down'
                ? 'hide-top__bar'
                : 'navbar-custom '
            }`}
          >
            {/* <div className={'navbar-custom--content'}>{this.props.content}</div> */}
            {this.props.subscriptionStatus !== 'INACTIVE' && (
              <div className='clock-out-btn-top'>
                <ClockOutInBtn />
              </div>
            )}
            {/* {this.props.subscriptionStatus === 'PENDING' && (
              <div className='pay-notification'>
                <p className='message'>{this.props.subscriptionMessage}</p>
                <p className='days_left'>
                  <span>{this.diffInDays(this.props.nextCharge, new Date(), 30)}</span>
                  days left
                </p>
              </div>
            )} */}
            {/* <ul className='navbar-right d-flex list-inline float-right mb-0 center-align'>
              <li className='dropdown notification-list'>
                <div className='dropdown notification-list nav-pro-img'>
                  <Dropdown
                    isOpen={this.state.dropdownOpenprofile}
                    toggle={this.toggleProfile}
                    className='vertical-navbar'
                  >
                    <DropdownToggle
                      className='dropdown-toggle testflag nav-link arrow-none waves-effect nav-user'
                      tag='a'
                    >
                      <div className='logined-user-name'>
                        {!this.state.dropdownOpenprofile &&
                        supportTickets.unseenCount > 0 ? (
                          <span className='user-unseen-tickets__counter'>
                            {supportTickets.unseenCount}
                          </span>
                        ) : null}
                        <span className='logined-user__name'>
                          {this.props.me.full_name}
                        </span>
                      </div>
                      {this.state.dropdownOpenprofile ? (
                        <i className='mdi mdi-chevron-up' />
                      ) : (
                        <i className='mdi mdi-chevron-down' />
                      )}
                      <ViewFile
                        file={
                          this.props.me.info &&
                          this.props.me.info.profile_picture
                            ? process.env.REACT_APP_API_MEDIA +
                              this.props.me.info.profile_picture
                            : '/assets/images/users/user-4.jpg'
                        }
                        fileStyle={avaStyle}
                        isLogo={true}
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      {!this.props.me.is_admin && (
                        <DropdownItem
                          onClick={this.toggleSupportModal}
                          className='dropdown-item__unseen-tickets'
                        >
                          {supportTickets.unseenCount > 0 ? (
                            <span className='user-unseen-tickets__counter'>
                              {supportTickets.unseenCount}
                            </span>
                          ) : null}
                          <i className='mdi mdi-help-circle m-r-5' /> Get help
                        </DropdownItem>
                      )}
                      <DropdownItem onClick={this.toggleFormModal}>
                        <i className='mdi mdi-account-circle m-r-5' /> Profile
                        Settings
                      </DropdownItem>
                      <DropdownItem onClick={this.toggleModal}>
                        <i className='mdi mdi-av-timer m-r-5' /> Timecards
                      </DropdownItem>
                      {this.props.me.login_from_admin && (
                        <DropdownItem onClick={this.handleReturnToAdminPanel}>
                          <i className='mdi mdi-logout' /> Return to admin panel
                        </DropdownItem>
                      )}
                      {!this.props.me.login_from_admin && (
                        <DropdownItem onClick={this.logout}>
                          <i className='mdi mdi-power text-danger' /> Logout
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </li>
            </ul> */}
            <div style={{ cursor: 'pointer' }} onClick={this.toggleProfile}>
              {' '}
              <Dropdown
                overlay={dropdownMenu}
                visible={this.state.dropdownOpenprofile}
                onVisibleChange={this.toggleProfile}
              >
                <UserAvatar
                  isCompactView={false}
                  profile_picture={profile_picture}
                  full_name={full_name}
                  background_color={background_color}
                />
              </Dropdown>
            </div>
          </nav>
        </div>
        {!!this.state.supportModal && (
          <Support onClose={this.toggleSupportModal} />
        )}

        <CardModal
          modal={this.state.modalCard}
          close={this.toggleCardModal}
          apply={this.onSubmitCard}
          isSaving={this.state.isCardSaving}
          updateSubscription={this.props.updateSubscription}
          fetchSubscription={this.props.fetchSubscription}
        />

        {this.state.modalForm && (
          <ModalUserForm
            // modal={this.state.modalForm}
            close={this.toggleFormModal}
            user={this.props.me}
            isProfileSettings={true}
            isMapFixingNeeded={true}
            currentPage={this.props.currentPage}
          />
        )}

        <Modal
          className='timecards-modal'
          visible={this.state.modalTimecards}
          onCancel={this.toggleModal}
          width='auto'
          footer={null}
          dialogClassName='timecard-modal'
          destroyOnClose
          title={
            <div className='modal-title'>
              <div className='header-wrapper-left'>
                <div className='modal-title h4'>{`Time card for: ${this.props.me?.full_name}`}</div>
              </div>
              <div className='header-wrapper-right d-flex'>
                <TimezonePicker
                  timezone={this.state.timezone}
                  setTimezone={this.handleSetTimezone}
                />
                <CustomDatePicker
                  timeState={this.state.timeState}
                  setTimeState={this.handleSetTimeState}
                />
              </div>
            </div>
          }
        >
          <TimeCardList
            timeState={this.state.timeState}
            modalValues={this.props.me}
            timezone={this.state.timezone}
            readOnly
          />
        </Modal>

        <Modal
          visible={this.state.chatVisible}
          onCancel={this.hideChatModalVisible}
          footer={null}
          className='chat-modal'
          destroyOnClose
          zIndex={10}
          style={{ top: 20 }}
        >
          <Chat chatID={this.state.chatID} />
        </Modal>
        {/* </div> */}
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  separateSetUserAndCompany: (data, dispatch) =>
    separateSetUserAndCompany(data, dispatch),
  fetchCompany: actions.company.fetchCompany,
  fetchUserByToken: actions.user.fetchUserByToken,
  fetchSubscription: actions.subscription.fetchSubscription,
  userLogout: actions.app.logout,
  setUserClockOut: actions.timecards.setUserClockOut,
  initializeApp: actions.app.initializeApp,
  fetchUnseenTicketsCount: actions.supportTickets.fetchUnseenTickets,
  setUnseenMessagesCount: actions.supportTickets.setUnseenMessagesCount,
  updateSubscription: actions.subscription.updateSubscription,
  dispatch,
})

export default compose(
  connect(
    state => ({
      me: state.orcatec.user.me,
      isCompanyFromAdmin: state.orcatec.company.made_admin,
      subscriptionOff: state.orcatec.company.subscription_off,
      supportTickets: state.orcatec.supportTickets,
      timeType: state.orcatec.timecards.currentTimeType.timeType,
      scrollDirection: state.orcatec.dispatchSlice.scrollDirection,

      // nextCharge: state.orcatec.company.subscription.next_charge,
    }),
    mapDispatchToProps,
  ),
  withSubscription,
  withRouter,
)(TopBar)
