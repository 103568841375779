export enum ProjectTemplateType {
  PROJECT = 1,
  SECTION = 2,
}

export interface IProjectTemplate {
  id: number
  title: string
  owner_template_name: string
  created_at: string
  is_shared: number[]
  user_id: number
}
