import { TodoPermissions as IKey } from './types'
import { UniteType } from 'features/Settings/UsersAndGroups/types'

export const todo = {
  todo_can_read: {
    name: 'View To Do List',
    control_type: UniteType.THIRD_VIEW,
    description: '',
    with_access_scope: false,
    with_access_checkbox: true,
    accordion: false,
    permission_key: IKey.TODO_CAN_READ,
    related_key: [],
    is_module: true,
    children: [
      {
        name: 'Delete To Do Task',

        description: '',
        with_access_scope: false,
        with_access_checkbox: true,
        accordion: false,
        permission_key: IKey.TODO_CAN_DELETE,
        parent_permission_key: IKey.TODO_CAN_READ,
        related_key: [],
      },
    ],
  },
}
