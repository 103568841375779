import { ActionType } from '../constants/mailClientConstants'
import { combineReducers } from 'redux'

const {
  MC_LOGIN_INIT,
  MC_LOGIN_SUCCESS,
  MC_LOGIN_FAILURE,
  MC_LOGIN_NEED_CHANGE_PASSWORD,
  MC_LOGIN_NEED_CHANGE_PASSWORD_INIT,
  MC_LOGIN_NEED_CHANGE_PASSWORD_FAILURE,
  MC_LOGOUT,
  MC_AUTH_CHECK,
} = ActionType

const mailClientSessionInitialState = {
  auth: false,
  authProceed: true,
  authErrors: null,
  needPasswordChange: false,
  mailbox: null,
}

const mailClientSession = (state = mailClientSessionInitialState, { type, payload }) => {
  switch (type) {
    case MC_AUTH_CHECK:
      return {
        ...state,
        auth: Boolean(payload),
        authProceed: false,
        authErrors: null,
        needPasswordChange: false,
        mailbox: payload,
      }
    case MC_LOGIN_INIT:
      return {
        ...state,
        ...mailClientSessionInitialState,
      }
    case MC_LOGIN_SUCCESS:
      return {
        ...state,
        auth: true,
        authProceed: false,
        authErrors: null,
        needPasswordChange: false,
        mailbox: payload.mailbox,
      }
    case MC_LOGIN_FAILURE:
      return {
        ...state,
        auth: false,
        authProceed: false,
        authErrors: payload,
        mailbox: null,
      }
    case MC_LOGIN_NEED_CHANGE_PASSWORD:
      return {
        ...state,
        auth: false,
        authProceed: false,
        authErrors: null,
        needPasswordChange: true,
        mailbox: null,
      }
    case MC_LOGIN_NEED_CHANGE_PASSWORD_INIT:
      return {
        ...state,
        auth: false,
        authProceed: true,
        authErrors: null,
        needPasswordChange: true,
        mailbox: null,
      }
    case MC_LOGIN_NEED_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        auth: false,
        authProceed: false,
        authErrors: payload,
        needPasswordChange: true,
        mailbox: null,
      }
    case MC_LOGOUT:
      return {
        ...state,
        auth: false,
        authProceed: false,
        authErrors: null,
        needPasswordChange: false,
        mailbox: null,
      }
    default:
      return state
  }
}

export default combineReducers({
  mailClientSession,
})
