import { Breadcrumb, ItemCategory } from 'features/Items/types'
import { CaretDownFilled } from '@ant-design/icons'
import { FolderIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { Spin } from 'antd'
import { useEffect, useRef, useState } from 'react'
import ItemsAPI from 'api/Items'
import Search from 'antd/lib/input/Search'
import useOnClickOutside from 'hooks/useOnClickOutside'

import styled from 'styled-components'

interface Props {
  categoryId?: number | null
  onChooseCategory: (categoryId: number | null) => void
}

export const CategorySelector = ({ categoryId, onChooseCategory }: Props) => {
  const [loading, setLoading] = useState(false)
  const [activeCategory, setActiveCategory] = useState<number | null>(null)
  const [showDropdown, setShowDropdown] = useState(false)
  const [search, setSearch] = useState('')
  const [categories, setCategories] = useState<ItemCategory[]>([])
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])

  const ref = useRef(null)
  useOnClickOutside(ref, () => setActiveCategory(null))

  const filteredCategories = categories.filter(cat =>
    cat.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  )

  useEffect(() => {
    const getCategory = async () => {
      setLoading(true)
      const data = await ItemsAPI.getCategories({
        category_id: categoryId ? +categoryId : undefined,
      })
      setLoading(false)

      setBreadcrumbs(data?.breadcrumbs)
      setCategories(data?.subcategories)
      setSearch('')

      if (categoryId) setActiveCategory(data?.breadcrumbs.length)
    }

    getCategory()
  }, [categoryId])

  return (
    <Wrapper>
      <Heading>Category:</Heading>

      <Content>
        <Location ref={ref}>
          {[{ id: null, name: 'Main' }, ...breadcrumbs].map((crumb, i) => (
            <LocationItem
              key={crumb.id}
              onClick={() => {
                onChooseCategory(crumb.id)
                setActiveCategory(i)
                setShowDropdown(true)
              }}
            >
              {crumb.name}
              {breadcrumbs.length === i && <Arrow />}

              {activeCategory === i && showDropdown && (
                <CatList onClick={e => e.stopPropagation()}>
                  <Spin spinning={loading}>
                    <Search
                      size='small'
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                    />
                    <hr style={{ margin: '10px 0' }} />

                    {!!categories.length ? (
                      <CatItems>
                        {filteredCategories.map(cat => (
                          <CatItem
                            key={cat.id}
                            onClick={() => {
                              if (!cat.id) return
                              onChooseCategory(cat.id)
                            }}
                          >
                            <FolderIcon
                              style={{ fontSize: 18, color: '#000' }}
                            />
                            {cat.name}
                          </CatItem>
                        ))}
                      </CatItems>
                    ) : (
                      <p
                        style={{
                          fontStyle: 'italic',
                          color: '#0000008a',
                          textAlign: 'center',
                        }}
                      >
                        No Subcategories yet
                      </p>
                    )}
                  </Spin>
                </CatList>
              )}
            </LocationItem>
          ))}
        </Location>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
`
const Arrow = styled(CaretDownFilled)`
  margin-left: 15px;
  font-size: 16px;
`
const Location = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  p {
    cursor: pointer;

    &::after {
      content: '>';
      margin: 0 10px;
    }

    &:last-child {
      &::after {
        content: '';
        margin: 0 10px;
      }
    }
  }
`
const Heading = styled.p`
  color: #0000008a;
`
const Content = styled.div``

const LocationItem = styled.p``

const CatList = styled.div`
  position: absolute;
  z-index: 1;
  background-color: #fff;
  width: 200px;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  padding: 10px;
  /* top: 20px; */
`

const CatItems = styled.div`
  max-height: 400px;
  overflow-y: auto;
`
const CatItem = styled.div`
  cursor: pointer;
  color: grey;
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 3px;

  span {
    align-self: flex-start;
  }

  &:hover {
    color: #000;
  }
`
