import * as types from '../../types'
import { fetchUsersListForProposals } from '../../../../api/User'
import { fetchPropertiesList } from '../../../../api/Property'
import { fetchAppointmentTypes } from '../../../../api/AppointmentType'
import { fetchAppointmentSources } from '../../../../api/AppointmentSource'
import {
  createProposal,
  updateProposal,
  fetchProposalCode,
  fetchProposalsList,
  deleteProposal,
  fetchProposalTemplates,
  fetchProposalTemplate,
  postProposalTemplate,
  putProposalTemplate,
  deleteProposalTemplate,
} from '../../../../api/Proposal'

/**
 * Set data form.
 *
 * @param data
 * @returns {{data: *, type: *}}
 */
export const setDataForm = data => {
  return {
    type: types.proposalTable.PROPOSAL_CORE_SET_DATA_FORM,
    data,
  }
}

export const setTamplates = response => {
  return {
    type: types.proposalTable.FEAT_PROPOSAL_TAMLATES,
    response,
  }
}
/**
 * Set item.
 *
 * @param item
 * @returns {{item: *, type: *}}
 */
export const setItem = item => {
  return {
    type: types.proposalTable.PROPOSAL_CORE_SET_ITEM,
    item,
  }
}

/**
 * Update create modal.
 *
 * @param modalData
 * @returns {{createModal: *, type: *}}
 */
export const updateCreateModal = modalData => {
  return {
    type: types.proposalTable.UPDATE_CREATE_MODAL,
    createModal: modalData,
  }
}

/**
 * Init Data.
 *
 * @returns {Function}
 */
export const initDataForm = () => {
  return (dispatch, getState) => {
    const userListPromise = new Promise((resolve, reject) => {
      fetchUsersListForProposals().then(response => {
        resolve(response)
      })
    })

    const propertiesListPromise = new Promise((resolve, reject) => {
      fetchPropertiesList(50, 1).then(response => {
        resolve(response.data)
      })
    })

    const appointmentTypesProperties = new Promise((resolve, reject) => {
      fetchAppointmentTypes().then(response => {
        resolve(response)
      })
    })

    const appointmentSources = new Promise((resolve, reject) => {
      fetchAppointmentSources().then(response => {
        resolve(response)
      })
    })

    Promise.all([userListPromise, propertiesListPromise, appointmentTypesProperties, appointmentSources]).then(
      ready => {
        const state = getState()
        const data = { ...state.orcatec.proposalTable.data }
        const dictionaries = { ...data.dictionaries }
        const createModal = { ...data.createModal }
        createModal.technicians = ready[0]
        createModal.properties = ready[1]
        createModal.appointmentTypes = ready[2]
        createModal.appointmentSources = ready[3]

        //init
        dictionaries.technicians = ready[0]
        dictionaries.properties = ready[1]
        dictionaries.appointmentTypes = ready[2]
        dictionaries.appointmentSources = ready[3]

        data.dictionaries = dictionaries
        data.createModal = createModal

        dispatch(setDataForm(data))
      },
    )
  }
}

export const getProposalCode = id => dispatch => {
  return fetchProposalCode(id).then(data => {
    dispatch(setItem({ code: data.code }))
  })
}

export const saveProposal = obj => dispatch => {
  return createProposal(obj)
}

/* export const getProposalTemlatesAction = () => dispatch => {
  return fetchProposalTemplates().then(response => {
    dispatch(setTamplates(response))
  })
} */

export const updateProposalAction = (id, obj) => dispatch => {
  return updateProposal(id, obj)
}

/**
 * Create or update proposal.
 *
 * @returns {Function}
 */
export const save = () => {
  return (dispatch, getState) => {
    const state = getState()
    const item = { ...state.orcatec.proposalTable.item }

    if (item.id === '') {
      createProposal(item).then(response => {})
    } else {
      updateProposal(item.id, item).then(response => {})
    }
  }
}
const setTableDate = res => {
  return {
    type: types.proposalTable.SET_PROPOSAL_TABLE,
    res,
  }
}

export const fetchProposalList = (page, limit) => dispatch => {
  return fetchProposalsList(page, limit).then(res => {
    if (!Array.isArray(res.data) && typeof res.data === 'object') res.data = Object.values(res.data)
    dispatch(setTableDate(res))
    return res
  })
}

const deleteProposalAction = id => {
  return {
    type: types.proposalTable.DELETE_PROPOSAL,
    id,
  }
}

export const deleteProprosal = id => dispatch => {
  return deleteProposal(id).then(() => {
    dispatch(deleteProposalAction(id))
  })
}

/////////////TEMPLATES//////////////////////

export const setProposalTemplates = data => ({
  type: types.proposalTable.PROPOSAL_SET_TEMPLATES,
  data,
})
export const setProposalTemplate = data => ({
  type: types.proposalTable.PROPOSAL_SET_TEMPLATE,
  data,
})

export const getProposalTemplates = () => (dispatch, getState) => {
  const userId = getState().orcatec.user.me.id
  return fetchProposalTemplates(userId).then(data => dispatch(setProposalTemplates(data)))
}

export const saveProposalTemplate = (title, is_shared) => (dispatch, getState) => {
  const proposal = getState().orcatec.proposalForm
  const me = getState().orcatec.user.me
  const data = {
    user_id: me.id,
    owner_template_name: me.full_name,
    is_shared: Array.isArray(is_shared) ? is_shared : [],
    type: 1, //1-proposal
    entity_id: proposal.id,
    title,
  }
  return postProposalTemplate(data).then(() => dispatch(getProposalTemplates()))
}

export const updateProposalTemplate = (id, title, is_shared, body) => (dispatch, getState) => {
  if (!body) body = getState().orcatec.proposalForm
  const me = getState().orcatec.user.me
  const templates = getState().orcatec.proposalTable.templates.list
  //const data = templates.find(template => template.id === id)

  const data = {
    user_id: me.id,
    owner_template_name: me.full_name,
    title,
    is_shared: Array.isArray(is_shared) ? is_shared : [],
    type: 1,
    body,
  }
  return putProposalTemplate(id, data).then(() => dispatch(getProposalTemplates()))
}

export const delProposalTemplate = id => (dispatch, getState) => {
  const me = getState().orcatec.user.me
  const template = getState().orcatec.proposalTable.templates.list.find(item => item.id === id)
  if (!template.is_shared && template.user_id === me.id) {
    return deleteProposalTemplate(id).then(() => dispatch(getProposalTemplates()))
  }
  const data = { ...template }
  if (!data.hidden) data.hidden = []
  data.hidden.push(me.id)
  return putProposalTemplate(id, data).then(() => dispatch(getProposalTemplates()))
}

/////////////TEMPLATES//////////////////////
