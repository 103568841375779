import * as types from '../../types'
import * as proposal from '../../../../api/settings/Proposal'
import * as industry from '../../../../api/settings/Industries'
import { removeIndustry } from './user'

export const setItem = item => {
  return {
    type: types.proposal.PROPOSAL_SET_ITEM,
    item,
  }
}

export const clearItem = () => {
  return {
    type: types.proposal.PROPOSAL_CLEAR_ITEM,
  }
}

const getProposalSettings = () => ({
  type: types.proposal.PROPOSAL_GET_ITEM,
})

export const fetchProposal = id => dispatch => {
  dispatch(getProposalSettings())
  return proposal.fetchProposal(id).then(response => {
    return dispatch(setItem(response))
  })
}

export const saveProposal = data => dispatch => {
  return proposal.saveProposal(data).then(response => {
    return dispatch(setItem(response))
  })
}

export const deleteIndustry = id => dispatch => {
  return industry.deleteIndustryById(id).then(response => {
    return proposal.fetchProposal().then(response => {
      dispatch(removeIndustry(id))
      return dispatch(setItem(response))
    })
  })
}
