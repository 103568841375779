import { FormCard } from './FormCard'
import styled from 'styled-components'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { CustomForm } from 'features/Forms/types'
import { useEffect, useMemo, useRef } from 'react'

interface IProps {
  relatedForm: CustomForm[]
  handleUpdateForm: (template: CustomForm) => void
  showRelatedInfo: boolean
  handleChangeSliderItemsCount: (count: number) => void
  isPublicView: boolean
}

export const RelatedForms = ({
  relatedForm,
  handleUpdateForm,
  showRelatedInfo,
  handleChangeSliderItemsCount,
  isPublicView = false,
}: IProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const scrollContainerRef = useRef<HTMLDivElement>(null)
  // const [isAtStart, setIsAtStart] = useState(true)
  // const [isAtEnd, setIsAtEnd] = useState(false)
  let scrollInterval: number

  /* useEffect(() => {
    const scrollContainer = scrollContainerRef.current

    if (!scrollContainer) return

    const handleScroll = () => {
      setIsAtStart(scrollContainer.scrollLeft === 0)
      setIsAtEnd(
        scrollContainer.scrollLeft + scrollContainer.clientWidth ===
          scrollContainer.scrollWidth,
      )
    }

    scrollContainer.addEventListener('scroll', handleScroll)

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll)
    }
  }, []) */

  const startScrolling = (direction: 'left' | 'right') => {
    const scrollContainer = scrollContainerRef.current
    const speed = 10 // Adjust the speed as needed

    const scroll = () => {
      if (!scrollContainer) return
      if (direction === 'right') {
        scrollContainer.scrollLeft += speed
      } else if (direction === 'left') {
        scrollContainer.scrollLeft -= speed
      }

      scrollInterval = requestAnimationFrame(scroll)
    }

    scroll()
  }

  const stopScrolling = () => {
    cancelAnimationFrame(scrollInterval)
  }

  const getCardsCount = () =>
    wrapperRef?.current?.clientWidth
      ? Math.trunc(wrapperRef?.current?.clientWidth / 300)
      : 3

  const cardsCount = useMemo(() => getCardsCount(), [
    wrapperRef?.current?.clientWidth,
  ])

  useEffect(() => {
    handleChangeSliderItemsCount?.(cardsCount)
  }, [cardsCount])

  return (
    <Wrapper ref={wrapperRef}>
      {relatedForm.length > cardsCount && (
        <Arrow
          onMouseDown={() => startScrolling('left')}
          onMouseUp={stopScrolling}
          onMouseLeave={stopScrolling}
          // disabled={isAtStart}
        >
          <LeftOutlined />
        </Arrow>
      )}
      <Content ref={scrollContainerRef}>
        {relatedForm?.map(form => (
          <FormCard
            key={form.id}
            data={form}
            onClick={handleUpdateForm}
            showRelatedInfo={showRelatedInfo}
            isPublicView={isPublicView}
          />
        ))}
      </Content>
      {relatedForm.length > cardsCount && (
        <Arrow
          onMouseDown={() => startScrolling('right')}
          onMouseUp={stopScrolling}
          onMouseLeave={stopScrolling}
          // disabled={isAtEnd}
        >
          <RightOutlined />
        </Arrow>
      )}
    </Wrapper>
  )
}

const Arrow = styled.div<{ disabled?: boolean }>`
  border: 1px solid #d6dbed;
  background-color: ${props => (props.disabled ? '#ededed' : 'fff')};
  padding: 9px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 18px;
  border-radius: 6px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
`

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`

const Content = styled.div`
  display: flex;
  gap: 5px;
  padding: 10px 0;
  overflow-x: scroll;
  width: 100%;
`

export const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 10px;

  h6 {
    margin: 0;
  }
`
