import React from 'react'

import './ModalCancelContract.scss'
import ModalWrapper from '../ModalWrapper/ModalWrapper'
import { TextField } from '@material-ui/core'

const inputProps = { maxLength: 500 }
const ModalCancelContract = props => {
  return (
    <>
      <ModalWrapper
        dialogClassName='cancel-contract-modal'
        modal={props.show}
        close={props.onHide}
        apply={props.onSave}
        isSaving={props.isStatusChanging}
        disableCancelBtn={false}
        disableOkBtn={false}
        cancelText='Cancel'
        okText='Confirm'
      >
        <div className='w-100 d-flex flex-column justify-content-center'>
          {props.type === 'to estimate' && (
            <>
              <div className='cancel-contract-modal-title'>Signature will be lost.</div>
              <div className='cancel-contract-modal-title'>Are you sure you want to proceed?</div>
            </>
          )}
          {props.type === 'to edit contract' && (
            <>
              <div className='cancel-contract-modal-title'>{props.isSignature && <>Signature will be lost.</>}</div>
              <div className='cancel-contract-modal-title'>Are you sure you want to edit this contract?</div>
            </>
          )}
          {props.type === 'to canceled' && (
            <>
              <div className='cancel-contract-modal-title mb-4'>Are you sure you want to cancel?</div>
              <TextField
                id='canceled_reason'
                size='small'
                multiline
                label='Reason for cancellation'
                rows='5'
                name='canceled_reason'
                value={props.cancelationReason}
                onChange={props.onChangeCancelationReason}
                variant='outlined'
                inputProps={inputProps}
                fullWidth
              />
            </>
          )}
        </div>
      </ModalWrapper>
    </>
  )
}

export default ModalCancelContract
