import { getJobs } from 'api/Job'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { AppStateType } from 'store'
import { Job } from 'types/Jobs'
import {
  FETCH_RELATED_JOBS,
  SET_ALL_JOBS_TO_COMPLETE,
  SET_RELATED_JOBS,
} from '../types/jobs'

export const fetchJobsStarted = () => ({ type: FETCH_RELATED_JOBS } as const)

export const setRelatedJobs = (relatedJobs: Job[]) =>
  ({
    type: SET_RELATED_JOBS,
    relatedJobs,
  } as const)

export const setAllJobsToComplete = (isWorkCompleted: boolean) =>
  ({
    type: SET_ALL_JOBS_TO_COMPLETE,
    isWorkCompleted,
  } as const)

//Thunks
export const getRelateJobs = (
  params,
): ThunkAction<void, AppStateType, unknown, AnyAction> => async dispatch => {
  dispatch(fetchJobsStarted())
  try {
    const relatedJobs = await getJobs(params)

    dispatch(setRelatedJobs(relatedJobs))
  } catch (error) {
    console.error(error.message)
  }
}
