import * as types from '../types'
import * as schedule from '../../../api/Schedule'

const startFetchingSchedule = () => {
  return {
    type: types.schedule.START_FETCHING_SCHEDULE,
  }
}
const endFetchingSchedule = () => {
  return {
    type: types.schedule.END_FETCHING_SCHEDULE,
  }
}

export const setSchedule = res => {
  return {
    type: types.schedule.SET_SCEDULE,
    res,
  }
}

export const setCurrentSchedule = res => {
  return {
    type: types.schedule.SET_CURRENT_SCEDULE,
    res,
  }
}

export const changeScheduleAppointmentStatus = (
  scheduleList,
  scheduleID,
  appointmentStatusID,
) => {
  const newSchedule = [...scheduleList.list]
  let result = newSchedule.map(schedule => {
    if (schedule.id === scheduleID)
      return { ...schedule, status: appointmentStatusID }
    else return schedule
  })

  return {
    type: types.schedule.CHANGE_SCHEDULE_APPOINTMENT_STATUS,
    newSchedule: result,
  }
}

export const fetchSchedule = date => dispatch => {
  dispatch(startFetchingSchedule())

  return schedule
    .fetchSchedule(date)
    .then(res => {
      dispatch(setSchedule(res))
    })
    .finally(() => dispatch(endFetchingSchedule()))
}

export const getScheduleById = id => dispatch => {
  return schedule.getScheduleById(id).then(res => {
    dispatch(setCurrentSchedule(res))
    return res
  })
}
