import { Button } from 'antd'
import SettingsRow from 'components/UI/SettingsRow'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import moment from 'moment-timezone'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompanyTimezone } from 'store/Orcatec/selectors/company'
import { selectProject } from '../projectSelectors'
import { useEffect, useState } from 'react'
import { ProjectAPI } from 'api/Project'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { ProjectStatusGroup } from '../types'

interface Props {
  visible: boolean
  onClose: () => void
  onChange: (showButton: boolean) => void
}

interface QBData {
  exported_at: string | null
  show_button: boolean
}

export const ProjectQuickbooks = ({ visible, onChange, onClose }: Props) => {
  const project = useAppSelector(selectProject)
  const companyTimezone = useAppSelector(selectCompanyTimezone)

  const [data, setData] = useState<QBData>({
    exported_at: null,
    show_button: false,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await ProjectAPI.getQuickbooksInfo(project.id)

        setData(res)
        onChange(res?.show_button)
      } catch (error) {
        openNotificationWithIcon('error', {
          message: error.response.data.message || 'Something went wrong',
        })
      }
    }

    if (project.id && project.status !== ProjectStatusGroup.Estimate) {
      getData()
    }
  }, [project?.status])

  const handleQuickbooksSync = async () => {
    try {
      setLoading(true)

      const res = await ProjectAPI.exportProjectToQB(project.id)

      setData(prev => ({
        ...prev,
        exported_at: res.exported_at,
      }))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      width={500}
      title='Quickbooks synchronization'
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <SettingsRow title='Last sync'>
        {data?.exported_at
          ? moment(data?.exported_at)
              .tz(companyTimezone)
              .format('MM/DD/YYYY [at] hh:mm a (z)')
          : 'Never'}
      </SettingsRow>
      <SettingsRow title='Sync now'>
        <Button size='small' onClick={handleQuickbooksSync} loading={loading}>
          Sync now
        </Button>
      </SettingsRow>
    </Modal>
  )
}
