import { FieldWorkOrders } from 'containers/MainContent/Orcatec/Proposals/components/PageProposalPreview/FieldWorkOrders/FieldWorkOrders'
import {
  selectProject,
  selectProjectActiveTab,
} from 'features/Project/projectSelectors'
import { getProject, signProject } from 'features/Project/slices/projectSlice'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'

const SoCalForm = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const project = useAppSelector(selectProject)
  const currentTab = useAppSelector(selectProjectActiveTab)

  const [signatureModal, setSignatureModal] = useState(false)
  const [isProposalSigning, setIsProposalSigning] = useState(false)

  useEffect(() => {
    if (project.id) {
      dispatch(getProject({ projectId: project.id }))
    }
  }, [])

  const useQuery = () => {
    return new URLSearchParams(history.location.search)
  }

  const search = useQuery()

  const isPrintNedeed = search.get('print')

  const handleProjectSign = async (
    signature: string,
    signatoryName: string,
  ) => {
    setIsProposalSigning(true)

    await dispatch(
      signProject({
        projectId: project.id,
        data: {
          signed_tab_id: currentTab.id,
          signatory_name: signatoryName,
          signature,
        },
      }),
    )
    setIsProposalSigning(false)
    setSignatureModal(false)
  }

  if (!currentTab.id) return null

  return (
    <FieldWorkOrders
      currentTab={currentTab}
      signatureModal={signatureModal}
      setSignatureModal={setSignatureModal}
      proposalForm={project}
      setSignature={handleProjectSign}
      isProposalSigning={isProposalSigning}
      isPrintNedeed={isPrintNedeed}
    />
  )
}

export default SoCalForm
