// Types of the ticket
export const NEED_HELP_TYPE = 1
export const OTHER_TYPE = 2
export const TECH_TYPE = 3

export const types = {
  1: 'Need help',
  2: 'Other',
  3: 'Technical issue',
}

// Statuses of the ticket
export const NEW_STATUS = 1
export const IN_PROGRESS_STATUS = 2
export const RESOLVED_STATUS = 3
export const RE_OPEN_STATUS = 4

export const statuses = {
  1: 'New',
  2: 'In progress',
  3: 'Resolved',
  4: 'Re-open',
}

// Validation Rules
export const MAX_FILE_SIZE = 5e7 // in bytes
export const MAX_UPLOAD_FILES = 10
export const MAX_BODY_LENGTH = 3000
export const MAX_SUBJECT_LENGTH = 100
