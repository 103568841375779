export const CALL_STATUS = {
  1: 'Started',
  2: 'Ringing',
  3: 'Answered',
  4: 'Busy',
  5: 'Cancelled',
  6: 'Unanswered',
  7: 'Disconnected',
  8: 'Rejected',
  9: 'Failed',
  10: 'Human/machine',
  11: 'Timeout',
  12: 'Completed',
  13: 'Record',
  14: 'Input',
  15: 'Transfer',
  16: 'Payment',
}
export const ACTIVE_CALL_STATUS = [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
export const CALL_STATUS_DROPDOWN = [
  { value: 1, text: 'Started' },
  { value: 2, text: 'Ringing' },
  { value: 3, text: 'Answered' },
  { value: 4, text: 'Busy' },
  { value: 5, text: 'Cancelled' },

  { value: 6, text: 'Unanswered' },
  { value: 7, text: 'Disconnected' },
  { value: 8, text: 'Rejected' },
  { value: 9, text: 'Failed' },

  { value: 10, text: 'Human/machine' },
  { value: 11, text: 'Timeout' },
  { value: 12, text: 'Completed' },
  { value: 13, text: 'Record' },

  { value: 14, text: 'Input' },
  { value: 15, text: 'Transfer' },
  { value: 16, text: 'Payment' },
]
export const CALL_STATUS_COLOR = {
  1: '#1b08c7',
  2: '#1b08c7',
  3: '#08c712',
  4: '#c7080e',
  5: '#c7080e',
  6: '#c7080e',
  7: '#c7080e',
  8: '#c7080e',
  9: '#c7080e',
  10: '#c7080e',
  11: '#c7080e',
  12: '#08c712',
  13: '#ffa600',
  14: '#c7080e',
  15: '#c7080e',
  16: '#c7080e',
}
