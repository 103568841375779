import './TabSectionContent.scss'
import { ProjectItems } from 'features/Project/Items'
import ProjectDiscounts from 'features/Project/Items/components/ProjectDiscounts'
import { priceToView } from '../../../../../../../../../../helpers/thousandSeparator'
import ProjectRebates from 'features/Project/Items/components/ProjectRebates'
import { CheckOutlined } from '@ant-design/icons'
import { TextArea, TextField } from 'components/UIKit'
import { useState } from 'react'
import NumberFormat from 'react-number-format'
import { formatStringToNumber } from 'helpers/numbers'
import { StyledEditIcon } from 'features/Project/Taxes/Taxes.style'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  selectProject,
  selectProjectSettings,
} from 'features/Project/projectSelectors'
import { ProjectStatusGroup } from 'features/Project/types'

const TabSectionContent = ({
  description,
  installation,
  sectionTotalAfter,
  onChangeSectionDescription,
  // onChangeSectionInstallation,
  sectionSubtotal,
  onBlurSectionInstallation,
  freezeAll,
  section_id,
  showPrice,
}) => {
  const { status } = useAppSelector(selectProject)
  const projectSettings = useAppSelector(selectProjectSettings)

  const [laborCost, setLaborCost] = useState(installation || 0)
  const [editLaborCost, setEditLaborCost] = useState(false)
  const [sectionDescription, setSectionDescription] = useState(
    description || '',
  )
  const [editSectionDescription, setEditSectionDescription] = useState(false)

  const handleSectionDescriptionChange = e => {
    const { value } = e.target

    setSectionDescription(value)
  }

  const handleSectionDescriptionBlur = e => {
    setEditSectionDescription(false)
    if (e.target.value === description) return

    onChangeSectionDescription(e)
  }

  const handleChangeLaborCost = e => {
    const { value } = e.target

    setLaborCost(value)
  }

  const onBlurPrice = () => {
    setEditLaborCost(false)
    if (!laborCost) setLaborCost(0)

    if (laborCost === installation) return

    onBlurSectionInstallation(laborCost || 0)
  }

  return (
    <div className='proposal-form-tabs_content-tab_section-wrapper-content'>
      <div
        className={
          'proposal-form-tabs_content-tab_section-wrapper-content-total_table_wrapper' +
          (freezeAll
            ? ' proposal-form-tabs_content-tab_section-wrapper-content-total_table_wrapper-freeze'
            : '')
        }
      >
        {freezeAll && <div className='cover' />}
        <p
          style={{
            // fontSize: '0.8rem',
            color: '#808080',
            lineHeight: 1.5,
          }}
        >
          Section Description{' '}
          {!freezeAll && (
            <StyledEditIcon
              style={{ position: 'relative', top: -2 }}
              onClick={() => {
                setEditSectionDescription(true)
              }}
            />
          )}
        </p>
        {editSectionDescription ? (
          <div style={{ position: 'relative' }}>
            <TextArea
              autoFocus
              name='description'
              value={sectionDescription}
              onChange={handleSectionDescriptionChange}
              onBlur={handleSectionDescriptionBlur}
              maxLength={9000}
            />

            <CheckOutlined
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
              onClick={e => {
                e.stopPropagation()
                handleSectionDescriptionBlur()
              }}
            />
          </div>
        ) : (
          <p
            style={{
              whiteSpace: 'pre-wrap',
              padding: '5px 12px',
              lineHeight: 1.5715,
              fontStyle: !sectionDescription ? 'italic' : 'initial',
              opacity: !sectionDescription ? 0.3 : 1,
            }}
            onClick={() => {
              if (freezeAll) return

              setEditSectionDescription(true)
            }}
          >
            {sectionDescription || `{Empty}`}
          </p>
        )}
      </div>

      <ProjectItems
        sectionId={section_id}
        disabled={freezeAll}
        showPrice={showPrice}
      />

      {/* {!isHideWarranty && (
        <div style={{ marginLeft: 20 }}>
          <h6>Warranty</h6>
          <p
            style={{
              fontStyle: 'italic',
              display: 'flex',
              gap: 5,
            }}
          >
            Warranties have been moved to general items
            <Tooltip title='Read more'>
              <a
                href='https://orcatec.com/tutorial/price-pages'
                target='_blank'
                rel='noreferrer'
              >
                <InfoCircleOutlined />
              </a>
            </Tooltip>
          </p>
        </div>
      )} */}
      <div
        className={
          'proposal-form-tabs_content-tab_section-wrapper-content-total_table_wrapper' +
          (freezeAll
            ? ' proposal-form-tabs_content-tab_section-wrapper-content-total_table_wrapper-freeze'
            : '')
        }
      >
        {freezeAll && <div className='cover' />}
        {showPrice && (
          <table className='proposal-form-tabs_content-tab_section-wrapper-content-total_table'>
            <tbody>
              {(projectSettings?.show_installation ||
                (!projectSettings.show_installation && !!laborCost) ||
                editLaborCost) && (
                <tr>
                  <td>
                    {!freezeAll && (
                      <StyledEditIcon
                        onClick={() => {
                          if (freezeAll) return
                          setEditLaborCost(true)
                        }}
                      />
                    )}
                    Additional Labor Cost:
                  </td>
                  <td>
                    {editLaborCost ? (
                      <NumberFormat
                        autoFocus
                        thousandSeparator
                        decimalScale={2}
                        name='installation'
                        customInput={TextField}
                        value={laborCost}
                        prefix='$'
                        style={{ textAlign: 'right' }}
                        onChange={e =>
                          handleChangeLaborCost({
                            target: {
                              name: 'installation',
                              value: formatStringToNumber(e, true),
                            },
                          })
                        }
                        onBlur={onBlurPrice}
                        onFocus={() => {
                          if (laborCost === 0) {
                            handleChangeLaborCost({
                              target: {
                                name: 'installation',
                                value: '',
                              },
                            })
                          }
                        }}
                        allowNegative={false}
                      />
                    ) : (
                      <p
                        style={{ padding: '1px 0' }}
                        onClick={() => {
                          if (freezeAll) return
                          setEditLaborCost(true)
                        }}
                      >
                        {priceToView(laborCost)}
                      </p>
                    )}
                  </td>
                </tr>
              )}

              <tr>
                <td>Section Subtotal:</td>
                <td>{priceToView(sectionSubtotal)}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      {/* <CustomDivider freezeAll={freezeAll} /> */}

      <div style={{ marginLeft: 20 }}>
        <ProjectDiscounts
          sectionId={section_id}
          disabled={freezeAll}
          showPrice={showPrice}
          restrictAdd={
            !projectSettings?.show_discounts ||
            status === ProjectStatusGroup.Completed
          }
        />
      </div>

      <div style={{ marginLeft: 20 }}>
        <ProjectRebates
          sectionId={section_id}
          disabled={freezeAll}
          showPrice={showPrice}
          restrictAdd={!projectSettings?.show_rebates}
        />
      </div>

      {sectionSubtotal !== sectionTotalAfter && (
        <div
          className={
            'proposal-form-tabs_content-tab_section-wrapper-content-total_table_wrapper' +
            (freezeAll
              ? ' proposal-form-tabs_content-tab_section-wrapper-content-total_table_wrapper-freeze'
              : '')
          }
        >
          {freezeAll && <div className='cover' />}
          <table className='proposal-form-tabs_content-tab_section-wrapper-content-total_table'>
            <tbody>
              {showPrice && (
                <tr>
                  <td>
                    Section total{' '}
                    {projectSettings?.show_discounts ||
                    projectSettings?.show_rebates
                      ? 'after'
                      : ''}{' '}
                    {projectSettings?.show_discounts ? 'Discounts' : ''}{' '}
                    {projectSettings?.show_rebates ? 'and Rebates:' : ''}
                  </td>
                  <td>{priceToView(sectionTotalAfter)}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default TabSectionContent
