import React, { ChangeEvent, useState, FC, useRef } from 'react'
import styled from 'styled-components'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { Input } from 'antd'
import useOnClickOutside from 'hooks/useOnClickOutside'

interface IProps {
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onSave: () => void
  viewOnly: boolean
  value: string
}

const btnTitle = (edit: boolean, value: string): string => {
  if (edit) return 'Save Comment'
  if (value) return 'Edit Comment'
  return '+Add Comment'
}

export const NotesBlock: FC<IProps> = ({
  onChange,
  onSave,
  value,
  viewOnly,
}) => {
  const ref = useRef()
  const [edit, setEdit] = useState(false)

  const onClickBth = () => {
    if (edit) {
      setEdit(false)
      onSave()
      return
    }
    setEdit(true)
  }

  const onBlur = () => {
    if (edit) {
      setEdit(false)
      onSave()
    }
  }

  useOnClickOutside(ref, onBlur)

  return (
    <Wrapper ref={ref}>
      <ButtonWrapper>
        {!viewOnly && (
          <MainButton
            onClick={onClickBth}
            type='custom-secondary'
            title={btnTitle(edit, value)}
          />
        )}
      </ButtonWrapper>
      <TextFieldWrapper>
        {edit ? (
          <Input.TextArea
            className='comment-field'
            maxLength={450}
            autoSize
            placeholder='Type...'
            name='info_from_customer'
            onChange={onChange}
            value={value}
            autoFocus={true}
          />
        ) : (
          <TextValuewrapper viewOnly={viewOnly}>{value}</TextValuewrapper>
        )}
      </TextFieldWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 140px;
`

const ButtonWrapper = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 1;
  & button {
    height: 20px;
  }
`

const TextFieldWrapper = styled.div`
  & .comment-field {
    text-indent: 115px;
    height: 140px !important;
    line-height: 1.25;
    font-size: 16px;
    border: none;
    /* word-break: break-all; */
    padding: 2px 11px;
    /* overflow: hidden; */
  }
`

const TextValuewrapper = styled.p<{ viewOnly: boolean }>`
  text-indent: ${props => (props.viewOnly ? '0px' : '115px')};
  height: 140px !important;
  line-height: 1.25;
  font-size: 16px;
  padding: 2px 11px;
  white-space: pre-line;
  overflow: hidden;
`
