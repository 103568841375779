import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AppStateType } from 'store'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  // credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as AppStateType).orcatec.authSlice.token

    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error?.status === 403) {
    console.error('sending refresh token')
    // send refresh token to get new access token

    const refreshResult = await baseQuery('/refresh', api, extraOptions)
    console.error(refreshResult)
    if (refreshResult?.data) {
      const user = api.getState().orcatec.authSlice.user
      // store the new token
      console.error('set credentials')
      api.dispatch({
        type: 'auth/credintialsSet',
        payload: { ...refreshResult?.data, user },
      })
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions)
    } else {
      console.error('logout')
      api.dispatch({ type: 'auth/logOut' })
    }
  }

  return result
}

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
})
