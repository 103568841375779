import {
  getAddresByRelation,
  getAddressByCompanyHash,
  getAddressByInput,
  getPublicAddress,
} from 'api/Property'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import useDebounce from 'hooks/useDebounce'
import useOnClickOutside from 'hooks/useOnClickOutside'
import React, { useEffect, useRef, useState } from 'react'
import SearchDropdown from './components/SearchDropdown'
import { PropertyAddress } from './types'
import { InputFieldV2 } from '../UI/InputField'

import { Wrapper } from './SearchAddress.styles'

type Props = {
  companyHash?: string
  disabled?: boolean
  initialValue?: string
  publicView?: boolean
  label?: string
  required?: boolean
  forPublicUse?: boolean
  onChooseAddress: (address: PropertyAddress) => void
  onClickManualInput?: () => void
  onChangeSearchValue?: (value: string) => void
  placeholder?: string
  resetFromParent?: boolean
  onError?: (error: boolean) => void
  searchByRelation?: boolean
}

export const SearchAddress = ({
  initialValue: value = '',
  label = 'Search Address',
  companyHash,
  disabled = false,
  required = false,
  onChangeSearchValue,
  forPublicUse = false,
  placeholder = '',
  searchByRelation,
  onChooseAddress,
  onError,
  ...rest
}: Props) => {
  const [searchText, setSearchText] = useState('')
  const [properties, setProperties] = useState<PropertyAddress[]>([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)

  const debouncedSearch = useDebounce(searchText)

  const ref = useRef(null)

  useEffect(() => {
    onError?.(error)
  }, [error])

  useEffect(() => {
    if (value) setSearchText(value)
  }, [value])

  useEffect(() => {
    if (rest?.resetFromParent) {
      setSearchText('')
    }
  }, [rest?.resetFromParent])

  useEffect(() => {
    if (debouncedSearch?.length > 1 && showDropdown) getAddress()
  }, [debouncedSearch, showDropdown])

  const getAddress = async () => {
    setLoading(true)

    const getAdresses = () =>
      forPublicUse
        ? getPublicAddress(debouncedSearch) // for search from public sear engines (pelias, openstreetmap, google)
        : companyHash
        ? getAddressByCompanyHash(companyHash, { address: debouncedSearch })
        : searchByRelation
        ? getAddresByRelation(debouncedSearch)
        : getAddressByInput(debouncedSearch)

    try {
      const { data } = await getAdresses()

      setProperties(data)
    } catch (error) {
      openNotificationWithIcon('error', { message: 'Something went wrong' })
    } finally {
      setLoading(false)
    }
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
    onChangeSearchValue?.(e.target.value)
  }

  const handleOpenDropdown = () => {
    if (disabled) return
    setShowDropdown(true)
  }

  const handleChooseAddress = (address: PropertyAddress) => {
    onChooseAddress?.(address)
    // setSearchText(address.address)
    setShowDropdown(false)
    setError(false)
  }

  const handleBlurSearchInput = () => {
    if (value !== searchText) {
      return setError(true)
    }

    setSearchText(value)
    setShowDropdown(false)
  }

  const handleCancelChanges = () => {
    setSearchText(value)
    setError(false)
    setShowDropdown(false)
  }

  useOnClickOutside(ref, handleBlurSearchInput)

  return (
    <Wrapper ref={ref}>
      <InputFieldV2
        label={label}
        required={required}
        value={searchText}
        onChange={handleChangeSearch}
        error={error}
        onFocus={handleOpenDropdown}
        disabled={disabled}
        placeholder={placeholder}
        name='address'
      />

      {(showDropdown || error) && (
        <SearchDropdown
          data={properties}
          loading={loading}
          onChooseAddress={handleChooseAddress}
          onCancelChanges={handleCancelChanges}
          error={error}
          message={
            searchText.length < 2
              ? 'Type at least 2 characters to start searching..'
              : 'Select address from the list'
          }
          {...rest}
        />
      )}
    </Wrapper>
  )
}
