export const getEventStatus = event => {
  switch (event.status) {
    case 2:
      return 'in_progress'
    case 5:
      return 'in_route'
    case 3:
      return 'completed'
    case 4:
      return 'canceled'
    default:
      return ''
  }
}
