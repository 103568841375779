import React from 'react'

import { Input, Button, Result } from 'antd'
import {
  resetPassword,
  validateResetPasswordToken,
} from '../../../../../api/Auth'
import MainButton from '../../components/buttons/MainButton'
import './style.scss'
import { BackToLoginBtn } from '../../Layouts/AuthLayout/BackToLoginBtn'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
class ResetPasswordForm extends React.Component {
  state = {
    //confirmDirty: false,
    password: '',
    password_error: '',
    password_show: false,
    password_confirmation: '',
    password_confirmation_show: false,
    password_confirmation_error: '',
    isFetching: false,
    is_token_expired: false,
    isChangedPaswordSuccess: false,
  }

  componentDidMount() {
    if (this.props.token && this.props.email) {
      validateResetPasswordToken({
        token: this.props.token,
        email: this.props.email,
      }).catch(error => {
        // this.props.resetForm()
        this.setState({ is_token_expired: true })
        // this.props.history.push('/login')
        openNotificationWithIcon('error', {
          message: 'Reset password link is expired or had been used',
        })
      })
    }
  }

  validateFieldsAndScroll = () => {
    const newState = { ...this.state }
    if (!!this.state.password_error || !!this.state.password_confirmation_error)
      return false
    const errors = []
    let newReg = new RegExp(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,32}$/g,
    )
    newState.password_error = newState.password
      ? newReg.test(newState.password)
        ? ''
        : 'Password must contain at least one capital letter, one regular letter, one digit and be minimum 6 symbols length'
      : 'Please enter your new password'
    errors.push(newState.password_error)
    newState.password_confirmation_error =
      newState.password_confirmation === newState.password
        ? ''
        : 'Passwords do not match'
    errors.push(newState.password_confirmation_error)
    if (errors.some(err => !!err)) {
      this.setState(newState)
      return false
    }
    return true
  }

  handleSubmit = e => {
    e.preventDefault()
    const isValid = this.validateFieldsAndScroll()
    if (isValid) {
      const data = {
        token: this.props.token,
        email: this.props.email,
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
      }
      resetPassword(data)
        .then(res => {
          // this.props.resetForm()
          this.props.history.push({
            pathname: '/login/credentials-changed',
            state: { name: 'password', credential: data.password },
          })
          // this.setState(prev => ({ ...prev, isChangedPaswordSuccess: true }))
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  /* handleConfirmBlur = e => {
    const { value } = e.target
    this.setState({ confirmDirty: this.state.confirmDirty || !!value })
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent')
    } else {
      callback()
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  } */

  onChangeField = e => {
    let { name, value } = e.target
    if (value.length > 32) value = value.substring(0, 32)
    const newState = { ...this.state }
    switch (name) {
      case 'password':
        const newReg = new RegExp(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,32}$/g,
        )
        newState[`${name}_error`] = value
          ? newReg.test(value)
            ? ''
            : 'Password must contain at least one capital letter, one regular letter, one digit and be minimum 8 symbols length'
          : 'Please enter your new password'
        newState.password_confirmation_error =
          !!newState.password_confirmation &&
          value !== newState.password_confirmation &&
          !newState[`${name}_error`]
            ? 'Passwords do not match'
            : ''
        break
      case 'password_confirmation':
        newState[`${name}_error`] =
          value === newState.password ? '' : 'Passwords do not match'
        break
      default:
        break
    }
    newState[name] = value
    this.setState(newState)
  }

  backToLogin = e => {
    e.preventDefault()
    this.props.resetForm()
    this.props.history.push('/login')
  }

  handleClickShowPassword = key => () => {
    this.setState({ [`${key}_show`]: !this.state[`${key}_show`] })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  render() {
    if (this.state.is_token_expired)
      return (
        <div className='expired-token-dialogue'>
          <div className='please-text'>
            This link is expired or had been used
          </div>

          <MainButton
            style={{
              margin: '0 auto',
            }}
            title='To login page'
            onClick={this.backToLogin}
          />
        </div>
      )

    if (this.state.isChangedPaswordSuccess) {
      return (
        <Result
          status='success'
          title='Password changed successfully'
          extra={[
            <MainButton
              style={{ margin: '0 auto' }}
              title='To login page'
              onClick={this.backToLogin}
              key='button'
            />,
          ]}
        />
      )
    }

    return (
      <>
        {/* <div className='please-text'>Please enter your new password.</div> */}

        <form
          className='custom-login-form reset-password-form new-password'
          onSubmit={this.handleSubmit}
          autoComplete='off'
          noValidate
        >
          <div>
            <TextField
              id='forgot-password'
              type={this.state.password_show ? 'text' : 'password'}
              label={'Password'}
              name='password'
              value={this.state.password}
              onChange={this.onChangeField}
              variant='outlined'
              error={!!this.state.password_error}
              helperText={this.state.password_error}
              fullWidth={true}
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={this.handleClickShowPassword('password')}
                      onMouseDown={this.handleMouseDownPassword}
                      edge='end'
                    >
                      {this.state.password_show ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <TextField
              id='forgot-new-password'
              type={this.state.password_confirmation_show ? 'text' : 'password'}
              label={'Confirm Password'}
              name='password_confirmation'
              value={this.state.password_confirmation}
              onChange={this.onChangeField}
              variant='outlined'
              error={!!this.state.password_confirmation_error}
              helperText={this.state.password_confirmation_error}
              fullWidth={true}
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={this.handleClickShowPassword(
                        'password_confirmation',
                      )}
                      onMouseDown={this.handleMouseDownPassword}
                      edge='end'
                    >
                      {this.state.password_confirmation_show ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <MainButton
            title='Set New Password'
            htmltype='submit'
            className='w-100 waves-effect waves-light main-button'
          />
          <BackToLoginBtn title='Cancel' onClick={this.backToLogin} />
        </form>
      </>
    )
  }
}

export default ResetPasswordForm
