import { Contact, EntityType, ProjectProperty } from 'features/Project/types'
import styled from 'styled-components'
import { ContactItem } from './ContactItem'
import { PropertyItem } from './PropertyItem'
import { useState } from 'react'
import { Button } from 'antd'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import { useAppDispatch } from 'store/Orcatec/hooks'
import {
  projectPropertiesSetPosition,
  setProjectClientsPosition,
} from 'features/Project/slices/projectContactsSlice'
import { RightOutlined, UpOutlined } from '@ant-design/icons'

interface Props {
  entityType: EntityType
  data: (Contact | ProjectProperty)[]
  disabled: boolean
  noContacts: boolean
  onAttach: () => void
}

export const ItemsList = ({
  data,
  entityType,
  onAttach,
  disabled,
  noContacts,
}: Props) => {
  const dispatch = useAppDispatch()

  const [showAll, setShowAll] = useState(false)

  const handleDragEnd = (result: DropResult) => {
    const { destination, source } = result

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    if (entityType === EntityType.CLIENT) {
      dispatch(
        setProjectClientsPosition({
          sourceIndex: source.index,
          destinationIndex: destination.index,
          entity_type: entityType,
        }),
      )
    } else {
      dispatch(
        projectPropertiesSetPosition({
          sourceIndex: source.index,
          destinationIndex: destination.index,
          entity_type: entityType,
        }),
      )
    }
  }

  const renderList = () => (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable
        isDropDisabled={disabled}
        direction='vertical'
        droppableId='projctContacts'
      >
        {provided => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            {data.slice(0, !showAll ? 3 : data.length).map((item, idx) => (
              <Draggable
                draggableId={`contact-${item.id}-${idx}`}
                key={`contact-${item.id}-${idx}`}
                index={idx}
                isDragDisabled={disabled}
              >
                {provided =>
                  entityType === EntityType.CLIENT ? (
                    <ContactItem
                      provided={provided}
                      key={`contact-${item.id}-${idx}`}
                      data={item as Contact}
                      disabled={disabled}
                      isPrimary={idx === 0}
                    />
                  ) : (
                    <PropertyItem
                      key={`contact-${item.id}-${idx}`}
                      data={item as ProjectProperty}
                      provided={provided}
                      disabled={disabled}
                      isPrimary={idx === 0}
                    />
                  )
                }
              </Draggable>
            ))}

            {provided.placeholder}

            {data.length > 3 && (
              <ShowAll onClick={() => setShowAll(!showAll)}>
                {showAll ? 'Hide' : 'Show'} {data.length - 3}
                {entityType === EntityType.CLIENT ? ' contacts' : ' properties'}
                {showAll ? '' : ' more'}
                {showAll ? (
                  <UpOutlined style={{ fontSize: 12 }} />
                ) : (
                  <RightOutlined style={{ fontSize: 12 }} />
                )}
              </ShowAll>
            )}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )

  return (
    <Wrapper>
      {noContacts ? null : data.length ? (
        <>{renderList()}</>
      ) : (
        <p style={{ marginBottom: 12, color: '#4D4D4D' }}>
          There are no{' '}
          {entityType === EntityType.CLIENT ? 'contacts' : 'properties'} yet
        </p>
      )}

      <Button type='primary' style={{ width: '100%' }} onClick={onAttach}>
        + Attach {entityType === EntityType.CLIENT ? 'contact' : 'property'}
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const List = styled.div`
  & > * {
    margin-bottom: 12px;
  }
`
const ShowAll = styled.p`
  color: #1890ff;
  margin-left: 32px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`
