import moment from 'moment-timezone'

export const formatRelativeDate = (dateString: string) => {
  const date = moment(dateString)

  const currentDate = moment()

  const diffInDays = currentDate.diff(date, 'days')

  if (diffInDays === 0) {
    return 'Today'
  } else if (diffInDays === 1) {
    return 'Yesterday'
  } else if (diffInDays > 1 && diffInDays <= 10) {
    return `${diffInDays} days ago`
  } else {
    return `${date.format('MM/DD/YY')} (${diffInDays} days ago)`
  }
}
