import styled from 'styled-components'

export const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`

export const TimeSection = styled.div`
  display: flex;
  gap: 20px;

  & > * {
    &:first-child {
      flex: 0 0 200px;
    }
  }
`
export const DateSection = styled.div`
  flex: 1 0 calc(100% - 220px);
`
export const DateRow = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;

  /* & > * {
    &:first-child {
      flex: 0 1 200px;
    }
    &:nth-child(2) {
      flex: 1 0 150px;
    }
    &:last-child {
      flex: 0 1 100px;
    }
  } */
`
