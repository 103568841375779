import { forwardRef } from 'react'

import { TextField, TextFieldProps } from '@material-ui/core'

import './styles.scss'

export type InputProps = Omit<TextFieldProps, 'error'> & {
  error?: { [key: string]: string }
}

export const InputField = forwardRef<HTMLDivElement, InputProps>(
  (
    {
      fullWidth = true,
      inputProps,
      name,
      helperText,
      error,
      className,
      ...rest
    },
    ref,
  ) => {
    return (
      <TextField
        name={name}
        autoComplete='off'
        className={`orcatec-input ${className ? className : ''}`}
        inputRef={ref}
        inputProps={{
          ...inputProps,
          autoComplete: 'off',
          // className: rest?.multiline ? classes.textarea : undefined,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!(name && error?.[name])}
        helperText={!!error && name ? error?.[name] : helperText}
        fullWidth={fullWidth}
        size='small'
        variant='outlined'
        {...rest}
      />
    )
  },
)

InputField.displayName = 'InputField'
