import moment from 'moment-timezone'
import './SignatureBlock.scss'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompanyTimezone } from 'store/Orcatec/selectors/company'
import { ProjectStatusGroup } from 'features/Project/types'

interface Props {
  contractInfo: {
    contract_date: string | null
    signatory_name: string
    signature_date: string | null
    ip_client: string
    signature: string | null
    proposal_status_changed_by: string | null
  }
  editProjectView?: boolean
  projectStatus: ProjectStatusGroup
}

const SignatureBlock = ({
  projectStatus,
  contractInfo,
}: // editProjectView,
Props) => {
  const companyTimezone = useAppSelector(selectCompanyTimezone)

  const signatoryName = contractInfo?.signatory_name
  const signatureDate = contractInfo?.signature_date
    ? moment(contractInfo?.signature_date)
        .tz(companyTimezone)
        .format('MM/DD/YYYY [at] h:mma (z)')
    : ''
  /*  const contractDate = contractInfo?.contract_date
    ? moment(contractInfo?.contract_date)
        .tz(companyTimezone)
        .format('MM/DD/YYYY [at] h:mma (z)')
    : '' */

  const ipOfClient = contractInfo?.ip_client
    ? ` (IP-${contractInfo?.ip_client})`
    : ''

  return (
    <div className='proposal-form-signature_wrapper'>
      {!!contractInfo?.signature ? (
        <div className='proposal-form-signature_wrapper-signature'>
          <img src={contractInfo?.signature} alt='customer signature' />
          <span className='proposal-form-signature_wrapper-signature_info'>
            signature
          </span>
        </div>
      ) : (
        <span className='proposal-form-signature_wrapper-no_signature_info'>
          {projectStatus === ProjectStatusGroup.Contract
            ? `Project is waiting for customer's signature`
            : ''}
        </span>
      )}

      {!!contractInfo.signature_date && (
        <div>
          Accepted{' '}
          {`${signatoryName ? 'by ' + signatoryName + '' : ''}
            ${signatureDate ? 'on ' + signatureDate + '' : ''}
            ${ipOfClient ? ipOfClient : ''}`}
        </div>
      )}

      {/*  {!!contractDate &&
        editProjectView &&
        !moment(contractInfo?.signature_date).isSame(
          moment(contractInfo?.contract_date),
        ) && (
          <div>{`Changed to "Contract" status ${!!contractInfo.proposal_status_changed_by &&
            `by ${contractInfo.proposal_status_changed_by} `}on ${contractDate}`}</div>
        )} */}
    </div>
  )
}

export default SignatureBlock
