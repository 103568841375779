import React, { useState } from 'react'
import { Checkbox, Popconfirm, Image, Tooltip } from 'antd'
import { DeleteIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import moment from 'moment-timezone'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import { ViewFile } from '../../../../../../../../../helpers/viewFile/viewFile'
import { TextAreaWithLabelAndError } from 'components/UIKit/TextField/TextField'

const MediaUnit = ({
  checked,
  onChangeCheckbox,
  item,
  files,
  onDellMedia,
  isProposalImage,
  onChange,
  current,
  showFileModalHeandle,
}) => {
  // const [visible, setVisible] = useState(false)

  const deleteMediaUnit = e => {
    if (!!e && !!e.stopPropagation) {
      e.stopPropagation()
    }
    onDellMedia(item.id)
  }

  const fileStyle = {
    width: '100px',
    height: '100px',
    cursor: 'zoom-in',
  }

  // const getFullLinkToFile = shortLink =>
  //   shortLink
  //     ? `${process.env.REACT_APP_API_MEDIA}${shortLink}`
  //     : 'http://via.placeholder.com/200x250'

  const path = item?.linkToFile || item
  return (
    <div className='items-container'>
      <Checkbox checked={checked} onChange={onChangeCheckbox} />
      <div className='image-info'>
        <div className='image-container'>
          {!!onDellMedia && (
            <Popconfirm
              placement='topLeft'
              title={`Are you sure you want to delete this image?`}
              onConfirm={deleteMediaUnit}
              okText='Yes'
              cancelText='No'
            >
              <div className='remove-icon'>
                <DeleteIcon />
              </div>
            </Popconfirm>
          )}

          {/* <Image
            loading='lazy'
            data-holder-rendered='true'
            className='img-thumbnail'
            alt='uploaded-file'
            src={getFullLinkToFile(item?.preview)}
            preview={{ visible: false }}
            onClick={()=>showFileModalHeandle([item],current)}
          /> */}

          <div onClick={() => showFileModalHeandle(files, current)}>
            <ViewFile file={item} fileStyle={fileStyle} />
          </div>
        </div>

        <div className='image-user-info'>
          {item?.created_by && (
            <p>
              {' '}
              Uploaded by: {item?.created_by} on{' '}
              {moment
                .utc(item?.created_at)
                .local()
                .format('MM/DD/YYYY hh:mm a')}
            </p>
          )}
          <p>
            {item?.display_name ? (
              item?.display_name
            ) : (
              <Tooltip title={path}>
                Link to: {path.length > 30 ? path.slice(0, 30) + '...' : path}
              </Tooltip>
            )}
          </p>
        </div>
      </div>
      <div className='image-description'>
        {!isProposalImage ? (
          <TextAreaWithLabelAndError
            label='Description'
            name='description'
            helperText='Max length - 2000 charactes'
            multiline
            inputProps={{ maxLength: 2000 }}
            value={item?.description}
            onChange={e => onChange(e, item?.id)}
          />
        ) : (
          // <InputField
          //   label='Description'
          //   name='description'
          //   helperText='Max length - 2000 charactes'
          //   multiline
          //   inputProps={{ maxLength: 2000 }}
          //   value={item?.description}
          //   onChange={e => onChange(e, item?.id)}
          // />
          <p>{item?.description}</p>
        )}
      </div>
    </div>
  )
}

export default MediaUnit
