import React, { useState } from 'react'
import styled from 'styled-components'
import { Spin, Tooltip, Checkbox } from 'antd'
import { getProposalPdf } from 'api/Proposal'
import { PdfIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { LoadingOutlined } from '@ant-design/icons'

const Container = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 170px;
  height: 36px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transition: all 0.2s;
    background-color: #cde4f9;
  }
  & svg {
    width: 30px;
    height: 30px;
  }
`

interface IProp {
  proposalId: number
  tabId: number
  onDelete: () => void
  onAttach: (tabId: number) => void
  title: string
  tabIds: number[]
  code: string
  onLoadAndOpenFileHeandler: () => void
  idx: number
  isMultipleTabs: boolean
}
const parsePdfName = (title, proposalId, idx, isMultipleTabs, code) => {
  const defaultTabTitle = 'Tab Title'
  const newSubStr = 'Option'

  if (!isMultipleTabs) return `${code}.pdf`

  if (title.includes(defaultTabTitle)) {
    return (
      `${proposalId} - ${title.replace(defaultTabTitle, newSubStr)} - ${idx +
        1}` + '.pdf'
    )
  }

  return `${proposalId}-${title}` + '.pdf'
}

const Attachment = ({
  proposalId,
  tabId,
  onAttach,
  tabIds,
  title,
  onLoadAndOpenFileHeandler,
  idx,
  code,
  isMultipleTabs,
}: IProp) => {
  const pdfName = parsePdfName(title, code, idx, isMultipleTabs, code)
  const [loading, setLoading] = useState(false)

  const openPdf = async () => {
    setLoading(true)
    try {
      if (!proposalId) return
      const dataURI = await getProposalPdf(proposalId, tabId)
      const blobURL = URL.createObjectURL(dataURI)
      onLoadAndOpenFileHeandler?.(blobURL, 0)
      //  window.open(blobURL, '_blank')
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Tooltip title='Attach PDF'>
        <Checkbox
          value={tabId}
          onChange={() => onAttach(tabId)}
          checked={tabIds?.includes(tabId)}
        ></Checkbox>
      </Tooltip>
      <Wrapper onClick={openPdf}>
        <div>
          <PdfIcon />
        </div>
        <Tooltip title={`Open ${pdfName} in new tab`}>
          <div>
            {pdfName.length > 15 ? pdfName.slice(0, 15) + '...' : pdfName}
          </div>
        </Tooltip>
        {loading && (
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 20, marginLeft: '10px' }}
                spin
              />
            }
          />
        )}
      </Wrapper>
    </Container>
  )
}

export default Attachment
