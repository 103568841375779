import styled from 'styled-components'

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 1rem;
  height: auto;
  padding: 5px 0;
  //margin-top: 1rem;

  & > div {
    width: calc(100% - 18px);
  }

  & > span {
    order: 2;
    //margin-left: auto;
    //margin-bottom: 5px;
    cursor: pointer;
    font-size: 18px;
  }
`
