import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../../../store/Orcatec/actions'
import moment from 'moment-timezone'
import './ClockOutInBtn.scss'
import { SmallPreloader } from '../Preloader/SmallPreloader'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

let intervalHeaderTime

const ClockOutInBtn = ({ setUserClockInOrOut, currentTimeType, me }) => {
  const [time, setTime] = useState()
  const { timeType, clock_in } = currentTimeType
  const [loading, setLoading] = useState()

  useEffect(() => {
    clearInterval(intervalHeaderTime)
    if (timeType === 1) {
      timefunc()
      intervalHeaderTime = setInterval(() => {
        timefunc()
      }, 10000)
    }
  }, [clock_in, timeType])

  const timefunc = () => {
    function getHours(dur) {
      return dur.asHours() < 10 ? `0${Math.floor(dur.asHours())}` : Math.floor(dur.asHours())
    }

    const now = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    const clockedInTime = moment(clock_in)
    const ms = moment(now).diff(clockedInTime) < 0 ? 0 : moment(now).diff(clockedInTime)
    const dur = moment.duration(ms)
    const fromatedTime = getHours(dur) + 'h ' + moment.utc(ms).format('mm') + 'm'

    setTime(fromatedTime)
  }

  const handleClick = () => {
    setLoading(true)
    if (me.use_clock_in_with_gps && timeType === 2) {
      openNotificationWithIcon('error', {
        message: 'Please use mobile app with geo location enabled',
        description: '',
        key: 'request-for-review-success',
      })
      setLoading(false)
    } else {
      if (loading) {
        return
      }
      setLoading(true)
      setUserClockInOrOut().then(() => setLoading(false))
    }
  }
  const string = `Current status:\xa0`
  return (
    <>
      {/* {timeType && ( */}
      <div className='divWrapper'>
        <button
          disabled={loading}
          className={`btn btn-clock btn-outline-${timeType === 1 ? 'danger' : 'primary'}`}
          onClick={handleClick}
          onDoubleClick={() => {}}
        >
          {timeType === 1 ? 'Clock out' : 'Clock in'}
        </button>
        {!loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <small className='small headerSmall'>{string}</small>
            {timeType === 1 ? (
              <small className='headerSmall blueSmall'>{`Clocked in ${time}`}</small>
            ) : (
              <small className='headerSmall redSmall'>Clocked out</small>
            )}
          </div>
        ) : (
          <SmallPreloader />
        )}
      </div>
      {/* )} */}
    </>
  )
}

const mapStateToProps = state => ({
  currentTimeType: state.orcatec.timecards.currentTimeType,
  me: state.orcatec.user.me,
  // currentTimeClockIn: state.orcatec.timecards.currentTimeType.clock_in,
})

export default connect(mapStateToProps, {
  setUserClockInOrOut: actions.timecards.setUserClockInOrOut,
})(ClockOutInBtn)
